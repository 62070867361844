const fields = [
  {
    id: "supplierName",
    fieldTypes: ["textfield"],
    label: "Name",
    value: ({ supplier }) => {
      // debugger;
      return supplier?.name;
    },
  },
  {
    id: "supplierId",
    fieldTypes: ["textfield"],
    label: "Id",
    value: ({ supplier }) => supplier?.id,
  },
  {
    id: "supplierLogo",
    fieldTypes: ["image"],
    label: "Logo",
    value: ({ supplier }) => supplier?.key,
  },
  {
    id: "supplierDefaultAddress",
    type: "string",
    isDate: true,
    fieldTypes: ["textfield"],
    label: "Default address full details",
    value: ({ supplier }) => {
      const firstAddress = supplier.addresses && supplier.addresses[0];
      if (!firstAddress) {
        return "";
      }
      const { houseName, streetName, streetNumber, postcode, county, city, country } = firstAddress;
      let address = "";
      if (houseName) {
        address += `${houseName}\n`;
      }
      if (streetName || streetNumber) {
        if (streetNumber) {
          address += `${streetNumber} `;
        }
        if (streetName) {
          address += `${streetName} `;
        }

        address += "\n";
      }

      if (city) {
        address += `${city}\n`;
      }
      if (county) {
        address += `${county}\n`;
      }
      if (postcode) {
        address += `${postcode}\n`;
      }
      if (country) {
        address += `${country}\n`;
      }
      return address;
    },
  },
];

export function getFields() {
  return fields;
}

export const label = "Supplier";
