const fields = [
  {
    id: "clientContactFullAddress",
    type: "string",
    isDate: true,
    fieldTypes: ["textfield"],
    label: "Full address details",
    value: ({ clientAddress }) => {
      if (!clientAddress) {
        return "";
      }
      const { houseName, streetName, streetNumber, postcode, county, city, country } = clientAddress;
      let address = "";
      if (houseName) {
        address += `${houseName}\n`;
      }
      if (streetName || streetNumber) {
        if (streetNumber) {
          address += `${streetNumber} `;
        }
        if (streetName) {
          address += `${streetName} `;
        }

        address += "\n";
      }

      if (city) {
        address += `${city}\n`;
      }
      if (county) {
        address += `${county}\n`;
      }
      if (postcode) {
        address += `${postcode}\n`;
      }
      if (country) {
        address += `${country}\n`;
      }
      return address;
    },
  },
];
export function getFields() {
  return fields;
}

export const label = "File";
