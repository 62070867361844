import React, { useState, useEffect } from "react";

import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Typography, Pagination } from "antd";
import { usePDF } from "@react-pdf/renderer";

import { Document as ViewerDocument, Page as ViewerPage } from "react-pdf";

import cx from "classnames";

export function ReportPreview({ document, layout, renderMode, onLoad, onError, onDataUri, renderKey }) {
  const [documentBlob, setDocumentBlob] = useState();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [{ loading, blob, error }, updatePdfInstance] = usePDF({ document });

  useEffect(() => {
    updatePdfInstance();
  }, [renderKey]); // eslint-disable-line

  useEffect(() => {
    setDocumentBlob(blob);
    if (blob) {
      convertBlobToDataUrl(blob);
    }
    function convertBlobToDataUrl(blob) {
      var reader = new FileReader();
      reader.onload = function (e) {
        if (onDataUri) {
          onDataUri(e.target.result);
        }
      };
      reader.readAsDataURL(blob);
    }
  }, [blob, onDataUri]);

  return (
    <div className={cx("pdf-renderer", layout)}>
      {pageCount > 0 && (
        <Pagination
          className="pagination"
          defaultCurrent={1}
          pageSize={1}
          current={currentPageNumber}
          onChange={(value) => setCurrentPageNumber(value)}
          total={pageCount}
          simple
        />
      )}
      <div className={cx("pdf-loader", { visible: !document && loading })}>
        <LoadingOutlined />
      </div>
      <div className={cx("pdf-error", { visible: error })}>
        <CloseCircleOutlined />

        <Typography.Text className="message"> Cannot generate PDF </Typography.Text>
        <Typography.Text className="reason"> {error} </Typography.Text>
      </div>
      {documentBlob && !error && (
        <ViewerDocument
          file={documentBlob}
          loading={null}
          onLoadSuccess={({ numPages }) => {
            if (numPages !== pageCount) {
              setPageCount(numPages);
              if (onLoad && typeof onLoad === "function") {
                onLoad();
              }
            }
          }}
          onLoadError={(e) => {
            console.error("load error:", e);
            if (onError && typeof onError === "function") {
              onError();
            }
          }}
        >
          <div
            style={{
              width: 596,
              height: 841,
              backgroundColor: "white",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
          {pageCount > 0 && <ViewerPage loading={null} pageNumber={currentPageNumber} renderMode={renderMode} />}
        </ViewerDocument>
      )}
    </div>
  );
}

export default React.memo(ReportPreview);
