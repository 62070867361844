import { message, Modal, Table, Button, Input } from "antd";
import axios from "axios";
import { useGetSetState } from "react-use";

import getS3File from "common/getS3File";
import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";

import "./ModalEditQuoteLineItemPreset.scss";

export function ModalEditQuoteLineItemPreset({
  onClose,
  templateDetails,
  fieldName,
  quoteLineItem,
  organisationDetails,
  apiUser,
  form,
  setItemList,
}) {
  const [getState, setState] = useGetSetState({
    updatedItem: { ...quoteLineItem },
  });

  async function save() {
    const formFilePublicUrl = await getS3File(templateDetails.key);
    let templateForm = (await axios.get(formFilePublicUrl)).data;

    let templateFormField = templateForm.fields[fieldName];
    if (!templateFormField.templateValues) {
      templateFormField.templateValues = [];
    }

    let itemInTemplateForm = templateFormField.templateValues.find(
      (templateValue) => templateValue.item.id === quoteLineItem.id
    );

    if (!itemInTemplateForm) {
      message.error("Failed to find item to update in list of presets");
      return;
    }

    const { updatedItem } = getState();

    for (let key in updatedItem) {
      itemInTemplateForm.item[key] = updatedItem[key];
    }

    try {
      await Storage.put(templateDetails.key, JSON.stringify(templateForm));
      setItemList(templateFormField.templateValues);
      onClose();
    } catch (e) {
      console.error(e);

      throw e;
    }
  }

  async function onItemChange(updatedFields) {
    const quoteLineItem = getState().updatedItem;
    setState({ updatedItem: { ...quoteLineItem, ...updatedFields } });
  }

  const { updatedItem } = getState();

  return (
    <Modal
      title={`Edit preset ${getSimpleLabel("quote")} line item`}
      open
      onCancel={onClose}
      className="modal-edit-quote-line-item-preset"
      onOk={save}
      okText="Save"
    >
      <Table
        pagination={{ pageSize: 1000, hideOnSinglePage: true }}
        dataSource={[updatedItem]}
        columns={[
          {
            title: "Title",
            key: "title",
            align: "left",
            render: (_, quoteLineItem) => {
              return (
                <Input
                  value={quoteLineItem.title}
                  onChange={(e) => {
                    onItemChange({ title: e.target.value });
                  }}
                />
              );
            },
          },
          {
            title: "Description",
            key: "description",
            align: "left",
            render: (_, quoteLineItem) => {
              return (
                <Input
                  value={quoteLineItem.description}
                  onChange={(e) => {
                    onItemChange({ description: e.target.value });
                  }}
                />
              );
            },
          },
          {
            title: getSimpleLabel("quote-quantity"),
            key: "quantity",
            width: 100,
            render: (_, quoteLineItem) => {
              return (
                <Input
                  value={quoteLineItem.quantity}
                  onChange={(e) => {
                    onItemChange({ quantity: e.target.value });
                  }}
                />
              );
            },
          },
          {
            title: getSimpleLabel("quote-unit-price"),
            key: "unit-price",
            width: 100,

            render: (_, quoteLineItem) => {
              return (
                <Input
                  value={quoteLineItem.unitPrice}
                  onChange={(e) => {
                    onItemChange({ unitPrice: e.target.value });
                  }}
                />
              );
            },
          },
          {
            title: "Amount",
            key: "amount",
            width: 100,
            render: (_, quoteLineItem) => {
              return (
                <Input
                  value={quoteLineItem.amount}
                  onChange={(e) => {
                    onItemChange({ amount: e.target.value });
                  }}
                />
              );
            },
          },
        ]}
      />
    </Modal>
  );
}

export default withSubscriptions({
  Component: ModalEditQuoteLineItemPreset,
  subscriptions: [],
});
