import { Modal } from "antd";

import { TaskDetailsPageWithSubscriptions } from "TaskDetailsPage/TaskDetailsPage";

import { CloseIcon } from "common/icons";

import "./TaskDetailsModal.scss";

type Props = {
  taskId: string;
  onClose: any;
};

export default function TaskDetailsModal({ taskId, onClose }: Props) {
  return (
    <Modal
      maskClosable={true}
      open={true}
      footer={null}
      className="task-details-modal"
      closeIcon={<></>}
      onCancel={onClose}
    >
      <TaskDetailsPageWithSubscriptions
        match={{ params: { taskId } }}
        isModal
        onDelete={onClose}
        onClick={() => {
          // console.log("on modal click");
        }}
      />
      <div className="modal-icons">
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClose(e);
          }}
        >
          <CloseIcon />
        </div>
      </div>
    </Modal>
  );
}
