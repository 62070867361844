import React, { useState, useEffect, useRef } from "react";
import { Form, Modal, Button, Input } from "antd";

import { Wrapper } from "@googlemaps/react-wrapper";

import { Map, render, formatAddress } from "../../Map/Map";

import _ from "lodash";

import { Address } from "common/types";

import "./AddressModal.scss";

type Props = {
  onClose?: any;
  onSubmit?: any;
  address?: Address | null;
  isSingle?: boolean;
  parent?: any;
  isEditingAddress: boolean;
  apiKey?: string;
  Marker?: any;
  addressCoordinates: any;
  fetchAndSetCoordinates?: any;
  usesMapWidget: boolean;
  fetchCoordinates?: any;
  formattedAddress: string;
};

export default function AddressModal({
  onClose,
  onSubmit,
  address,
  isSingle,
  parent,
  isEditingAddress,
  apiKey,
  Marker,
  addressCoordinates,
  fetchAndSetCoordinates,
  fetchCoordinates,
  formattedAddress,
  usesMapWidget,
}: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isDifferentSetOfCoordinates, setIsDifferentSetOfCoordinates] = useState(false);
  const [click, setClick] = useState<any>();
  const [zoom, setZoom] = useState<any>(18);
  const [center, setCenter] = useState<any>();
  const debouncedSetCoordinates = useRef(_.debounce((newAddress) => setCoordinates(newAddress), 500)).current;

  useEffect(() => {
    async function setCoordinates() {
      if (usesMapWidget) {
        if (addressCoordinates) {
          setCenter({ lat: addressCoordinates.lat, lng: addressCoordinates.lng });
          setClick({ lat: addressCoordinates.lat, lng: addressCoordinates.lng });
        } else {
          if (formattedAddress) {
            await fetchAndSetCoordinates(formattedAddress);
          } else {
            if (fetchCoordinates && typeof fetchCoordinates === "function") {
              const coordinates = await fetchCoordinates("London");

              setCenter(coordinates);
              setClick(coordinates);
            }
          }
        }
      }
    }

    setCoordinates();
  }, []); // eslint-disable-line

  function onIdle(mapAPI) {
    if (mapAPI.getCenter()) {
      setZoom(mapAPI.getZoom());
      setCenter(mapAPI.getCenter().toJSON());
    }
  }

  async function onClick(e) {
    setClick(e.latLng);
    setIsDifferentSetOfCoordinates(true);
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  function setCoordinates(newAddress) {
    setCenter(newAddress);
    setClick(newAddress);
  }

  async function onFormChange() {
    if (fetchCoordinates && typeof fetchCoordinates === "function") {
      const editedAddress = form.getFieldsValue();
      const formattedAddress = formatAddress(editedAddress);
      const newAddress = await fetchCoordinates(formattedAddress);

      debouncedSetCoordinates(newAddress);
    }
  }

  return (
    <Modal
      maskClosable={false}
      title={address ? "Edit address" : "Add address"}
      open={true}
      onCancel={onClose}
      footer={null}
      className="address-modal"
    >
      <Form
        {...layout}
        form={form}
        onChange={onFormChange}
        initialValues={{
          id: (address && address.id) || (isSingle && parent.id),
          streetName: address && address.streetName,
          streetNumber: address && address.streetNumber,
          houseName: address && address.houseName,
          postcode: address && address.postcode,
          county: address && address.county,
          city: address && address.city,
          country: address && address.country,
          latitude: center?.lat,
          longitude: center?.lng,
        }}
        onFinish={async (params) => {
          setIsLoading(true);

          if (onSubmit && typeof onSubmit === "function") {
            await onSubmit({
              addressDetails: { ...params },
              isEditingAddress,
              isDifferentSetOfCoordinates,
              click,
            });
          }

          setIsLoading(false);
          onClose();
        }}
      >
        <Form.Item
          label="Label"
          name="id"
          hidden={address || isSingle}
          rules={[
            {
              required: true,
              message: "You need to specify an id",
            },
          ]}
        >
          <Input data-cy="address-id-input" />
        </Form.Item>

        <Form.Item label="Street Number" name="streetNumber">
          <Input data-cy="street-number-input" />
        </Form.Item>

        <Form.Item label="House Name" name="houseName">
          <Input data-cy="house-name-input" />
        </Form.Item>

        <Form.Item label="Street Name" name="streetName">
          <Input data-cy="street-name-input" />
        </Form.Item>

        <Form.Item label="City" name="city">
          <Input data-cy="city-input" />
        </Form.Item>

        <Form.Item label="County" name="county">
          <Input data-cy="county-input" />
        </Form.Item>

        <Form.Item label="Postcode" name="postcode">
          <Input data-cy="postcode-input" />
        </Form.Item>

        <Form.Item label="Country" name="country">
          <Input data-cy="country-input" />
        </Form.Item>

        {usesMapWidget ? (
          <>
            <Form.Item label="Latitude">{center?.lat}</Form.Item>

            <Form.Item label="Longitude">{center?.lng}</Form.Item>

            <div className="google-maps-wrapper" style={{ height: "300px", width: "100%", margin: "0 auto" }}>
              <Wrapper apiKey={apiKey} render={render}>
                <Map
                  center={center}
                  onClick={onClick}
                  onIdle={onIdle}
                  zoom={zoom}
                  style={{ flexGrow: "1", height: "100%" }}
                >
                  {click ? <Marker position={click} /> : null}
                </Map>
              </Wrapper>
            </div>
          </>
        ) : null}

        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="address-modal-submit-button">
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}
