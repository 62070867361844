import { Link } from "react-router-dom";
import { message, Table, Tag, Button, Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { QUOTE_STATUSES } from "common/constants";
import { getLabel } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { isAuthorised } from "common/permissions";
import withSubscriptions from "common/withSubscriptions";
import { processIdForDisplay } from "common/helpers";
import { getSimpleLabel } from "common/labels";

import Card from "Card/Card";
import Input from "Input/Input";
import Avatar from "Avatar/Avatar";

import "./TaskQuotes.scss";

export function TaskQuotes({ task, quotes, users, refreshTaskDetails, organisationDetails }) {
  function removeQuoteLineItem(quoteLineItem) {
    Modal.confirm({
      title: `Confirm unlink ${getLabel({
        id: "quote",
        defaultValue: "quote",
      })} line item`,
      className: "confirm-remove-quote-line-item-modal",
      content: (
        <>
          Are you sure you want to remove{" "}
          {getLabel({
            id: "task",
            defaultValue: "task",
          })}{" "}
          <b>{task.title}</b> from this linked{" "}
          {getLabel({
            id: "quote",
            defaultValue: "quote",
          })}{" "}
          line item?
        </>
      ),
      onOk: async () => {
        await callGraphQLSimple({
          message: `Failed to unlink ${getLabel({
            id: "quote",
            defaultValue: "quote",
          })} line item`,
          mutation: "updateQuoteLineItem",
          variables: {
            input: {
              id: quoteLineItem.id,
              resultingTaskId: "nothing",
            },
          },
        });

        await callGraphQLSimple({
          message: `Failed to refresh ${getLabel({
            id: "quote",
            defaultValue: "quote",
          })}`,
          queryCustom: "updateQuote",
          variables: {
            input: {
              id: quoteLineItem.quoteId,
              itemSubscription: Math.floor(Math.random() * 100000),
            },
          },
        });

        refreshTaskDetails();
      },
    });
  }

  let amountColumn;
  if (organisationDetails.settings?.quote?.quotesInHours) {
    if (!organisationDetails.settings?.general?.hideFinancials || isAuthorised(["QUOTES.WRITE_AMOUNT"])) {
      amountColumn = {
        title: "Line item hours",
        key: "quote-line-item-hours",
        render: (_, row) => {
          return row.isHourly ? "Hourly rate" : row.quantity;
        },
      };
    }
  } else {
    if (!organisationDetails.settings?.general?.hideFinancials || isAuthorised(["QUOTES.WRITE_AMOUNT"])) {
      amountColumn = {
        title: "Line item amount (net)",
        key: "quote-line-item-amount",
        render: (_, row) => {
          return row.isHourly ? "Hourly rate" : window.formatCurrency("GBP", row.amount);
        },
      };
    }
  }

  const tableColumns = [
    {
      title: `${getSimpleLabel("Quote")} ID`,
      key: "quote",
      width: 130,
      render: (_, row) => {
        return (
          <Link to={row.linkPath} data-cy="quote-id-tag">
            <Tag className="quote-id-tag dark-tag">{processIdForDisplay(row.quote.id)}</Tag>
          </Link>
        );
      },
    },
    {
      title: `${getSimpleLabel("Quote")} status`,
      key: "quote-status",
      render: (_, row) => {
        return row.quoteStatus?.label;
      },
    },
    {
      title: `${getSimpleLabel("Quote")} title`,
      key: "quote-title",
      render: (_, row) => {
        return (
          <Link to={row.linkPath} data-cy="quote-title">
            {row.quote.title}
          </Link>
        );
      },
    },
    {
      title: "Line item title",
      key: "quote-line-item",
      render: (_, row) => {
        return (
          <Link to={row.linkPath} data-cy="quote-line-item-title">
            {row.title}
          </Link>
        );
      },
    },
    amountColumn,
    {
      title: "Line item progress (%)",
      key: "quote-line-item-progress",
      render: (_, row) => {
        return (
          <Input
            showBorder
            fireOnChangeWithoutBlurWithDebounce
            debounceDelay={1500}
            style={{ width: 100, textAlign: "right" }}
            defaultValue={row.progressPercent || "0"}
            onChange={async (value) => {
              let parsedValue = parseFloat(value);
              if (isNaN(parsedValue)) {
                message.error("Progress must be a number");
                return;
              }
              if (parsedValue < 0) {
                message.error("Progress must be a positive number");
                return;
              }

              await callGraphQLSimple({
                message: "Failed to update progress",
                mutation: "updateQuoteLineItem",
                variables: {
                  input: {
                    id: row.id,
                    progressPercent: parseFloat(value) || 0,
                  },
                },
              });
              message.success(`Progress updated: ${parsedValue}%`);
            }}
          />
        );
      },
    },
    {
      title: `${getSimpleLabel("Quote")} assigned to`,
      key: "quote-assignee",
      render: (_, row) => {
        return <Avatar user={row.assignedToDetails} showLabel />;
      },
    },
    {
      title: "",
      key: "quote-unlink",
      width: 220,
      render: (_, row) => {
        return (
          <Button
            icon={<CloseCircleOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              removeQuoteLineItem(row);
            }}
            className="remove-quote-line-item-button"
            data-cy="remove-quote-line-item-button"
            disabled={task.isFinished || task.isArchived}
          >
            Unlink {getLabel({ defaultValue: "quote", id: "quote" })} line item
          </Button>
        );
      },
    },
  ].filter((x) => x);
  const tableRows = task.quoteLineItems.items
    .filter((quoteLineItem) => {
      let quote = quotes.find((quote) => quote.id === quoteLineItem.quoteId);
      return !!quote;
    })
    .map((quoteLineItem) => {
      let quote = quotes.find((quote) => quote.id === quoteLineItem.quoteId);
      let assignedToDetails = users.find((user) => user.id === quote.assignedTo);
      let quoteStatus = QUOTE_STATUSES.find((status) => status.value === quote.status);
      const linkPath = `/quotes/${quoteLineItem.quoteId}?${task.quoteLineItems.items
        .map((lineItem) => `lineItem=${lineItem.id}`)
        .join("&")}`;

      return {
        ...quoteLineItem,
        quote,
        assignedToDetails,
        quoteStatus,
        linkPath,
      };
    });

  return (
    <>
      <Card
        title={`${getLabel({
          id: "Quote",
          defaultValue: "Quote",
        })} line items`}
        className="task-quotes-card"
      >
        <div className="items">
          <Table columns={tableColumns} dataSource={tableRows} pagination={{ pageSize: 10, hideOnSinglePage: true }} />
        </div>
      </Card>
    </>
  );
}

export default withSubscriptions({
  Component: TaskQuotes,
  subscriptions: ["organisationDetails", "quotes"],
});
