import moment from "moment";

import { getSimpleLabel } from "common/labels";

export function formatAddress(addressDetails) {
  if (!addressDetails) {
    return "No address specified";
  }

  const addressFields = ["streetNumber", "houseName", "streetName", "city", "postcode", "country"];
  return addressFields
    .map((field) => addressDetails[field])
    .filter((fieldValue) => fieldValue)
    .join(" ")
    .trim();
}

export function getTableColumns() {
  return [
    {
      title: `${getSimpleLabel("Project")} ID`,
      width: 110,
      fieldFunction: (project) => project.id,
    },
    {
      title: `${getSimpleLabel("Project")} title`,
      fieldFunction: (project) => (project.title ? project.title : null),
      width: 150,
    },
    {
      title: `${getSimpleLabel("Client")} name`,
      fieldFunction: (project) => (project.client?.name ? project.client?.name : null),
    },
    { title: "PO number", fieldFunction: (project) => project?.poNumber },
    { title: "Initials", fieldFunction: (project) => (project.initials ? project.initials : null) },
    {
      title: "Created at",
      fieldFunction: (item) => moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss"),
      width: 200,
    },
    {
      title: "Last updated at",
      fieldFunction: (item) => moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
      width: 200,
    },
    {
      title: "Assignee",
      fieldFunction: (project) => (project.assignedTo ? `'${project.assignedTo}'` : null),
    },
    {
      title: "Address",
      fieldFunction: (project) => (project.address ? `'${formatAddress(project.address)}'` : null),
    },
    {
      title: "Latitude",
      fieldFunction: (project) => project.addressCoordinates?.lat,
    },
    {
      title: "Longitude",
      fieldFunction: (project) => project.addressCoordinates?.lng,
    },
    {
      title: "Created by",
      fieldFunction: (project) => project.author,
    },
    {
      title: "Is archived",
      fieldFunction: (project) => project.isArchived,
    },
    {
      title: "Is finished",
      fieldFunction: (project) => project.isFinished,
    },
    {
      title: "Finished at",
      fieldFunction: (project) =>
        project.finishedAt ? moment(project.finishedAt).format("DD-MM-YYYY HH:mm:ss") : "N/A",
    },
    {
      title: "Archived at",
      fieldFunction: (project) =>
        project.archivedAt ? moment(project.archivedAt).format("DD-MM-YYYY HH:mm:ss") : "N/A",
    },
    {
      title: `Number of ${getSimpleLabel("tasks")}`,
      fieldFunction: (project) => project.tasks.length,
    },
    {
      title: `Number of ${getSimpleLabel("quotes")}`,
      fieldFunction: (project) => project.quotes.length,
    },
    {
      title: "Number of invoices",
      fieldFunction: (project) => project.invoices.length,
    },
    {
      title: "Number of purchase orders",
      fieldFunction: (project) => project.purchaseOrders.length,
    },
  ].filter((x) => x);
}
