import { useEffect } from "react";
import { Button, Spin } from "antd";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { useGetSetState } from "react-use";

import withSubscriptions from "common/withSubscriptions";
import fetchAndSetAdminTask from "./fetchAndSetAdminTask";
import { getSimpleLabel } from "common/labels";

import Card from "Card/Card";
import AddFileButton from "./AddFileButton/AddFileButton";

import "./NonTaskReports.scss";

interface Props {
  project?: any;
  client?: any;
  tasks: any;
  projects: any;
  clients: any;
  apiUser: any;
  organisationDetails: any;
  parentType: string;
}

interface State {
  adminTask?: any;
  isAddFileModalVisible: boolean;
}

export function NonTaskReports({
  organisationDetails,
  project,
  client,
  tasks,
  parentType,
  apiUser,
  location,
}: Props & RouteComponentProps) {
  const [getState, setState] = useGetSetState<State>({
    adminTask: undefined,
    isAddFileModalVisible: false,
  });

  useEffect(() => {
    fetchAndSetAdminTask({
      project,
      client,
      tasks,
      apiUser,
      organisationDetails,
      setState,
    });
  }, [project?.itemSubscription, client?.itemSubscription, organisationDetails?.itemSubscription]); // eslint-disable-line react-hooks/exhaustive-deps

  const { adminTask } = getState();

  if (!adminTask) {
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
        <Spin />
      </div>
    );
  }

  let cardTitle = "";
  let parentPath = "";
  let parentName = "";

  if (client) {
    parentPath = encodeURI(`${location.pathname}?clientTab=reports`);
    parentName = client.name;
    cardTitle = `${getSimpleLabel("Client")} reports`;
  } else if (project) {
    parentPath = encodeURI(`${location.pathname}?projectTab=reports`);
    parentName = project.title;
    cardTitle = `${getSimpleLabel("Project")} reports`;
  } else {
    parentPath = "/reports";
    parentName = "Organisation reports";
    cardTitle = "Organisation reports";
  }

  if (!adminTask || !adminTask.revisions || !adminTask.revisions.items) {
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
        <Spin />
      </div>
    );
  }

  let lastTaskRevision = adminTask.revisions.items.slice(-1)[0];
  if (!lastTaskRevision || !lastTaskRevision.files || !lastTaskRevision.files.items) {
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <Card title={cardTitle} className="non-task-reports">
        <div className="files-and-add-button">
          <AddFileButton
            adminTask={adminTask}
            parentType={parentType}
            refreshAdminTask={() =>
              fetchAndSetAdminTask({
                project,
                client,
                tasks,
                apiUser,
                organisationDetails,
                setState,
              })
            }
          />

          {adminTask.revisions.items
            .slice(-1)[0]
            .files.items.filter((file) => file.type === "REPORT")
            .map((file) => {
              return (
                <Link
                  to={`/tasks/${adminTask.id}/${file.type}/${file.id}?parentName=${parentName}&parentPath=${parentPath}`}
                  key={file.id}
                >
                  <Button>{file.name}</Button>
                </Link>
              );
            })}
        </div>
      </Card>
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: NonTaskReports,
    subscriptions: ["tasks", "apiUser", "organisationDetails"],
  })
);
