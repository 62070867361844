import moment from "moment";
import { Page, Image, StyleSheet, Text, Font, View } from "@react-pdf/renderer";
import { initialiseLambdaPdfInserts } from "common/documentRenderHelpers";

import OrganisationLogoCover from "./apollo-logo.png";
import BrandonMed from "ReportPage/Report/brandon-med.ttf";
import BrandonBold from "ReportPage/Report/brandon-bold.ttf";
import OstrichBlack from "ReportPage/Report/ostrich-black.ttf";
import MrsSaintDelafield from "ReportPage/Report/MrsSaintDelafield-Regular.ttf";
import AvertaRegular from "ReportPage/Report/averta-regular.ttf";

Font.registerHyphenationCallback((word) => [word]);

Font.register({
  family: "Averta",
  fonts: [
    {
      src: AvertaRegular,
      fontWeight: "normal",
    },
  ],
});

Font.register({
  family: "Ostrich",
  fonts: [
    {
      src: OstrichBlack,
      fontWeight: "normal",
    },
    {
      src: OstrichBlack,
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Brandon",
  fonts: [
    {
      src: BrandonMed,
      fontWeight: "normal",
    },
    {
      src: BrandonBold,
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "MrsSaintDelafield",
  fonts: [
    {
      src: MrsSaintDelafield,
      fontWeight: "normal",
    },
  ],
});

const MAX_FRONT_COVER_LENGTH = 23;

export const colors = {
  accentColor: "#19aae8",
  black: "#000",
  gray: "#444",
  midGray: "#666",
  accentColorDark: "#5b8aba",
  white: "#fff",
};

export const styles = StyleSheet.create({
  bold: { fontWeight: "bold" },
  pageTitle: {
    fontSize: 20,
    marginBottom: 15,
    fontWeight: "bold",
    fontFamily: "Ostrich",
  },
  pageSubtitle: { fontSize: 13, marginBottom: 15, fontFamily: "Ostrich" },
  sectionSubtitle: {
    fontSize: 15,
    marginBottom: 10,
    marginTop: 0,
    textDecoration: "underline",
    fontWeight: "bold",
    fontFamily: "Ostrich",
  },

  paragraph: { fontSize: 10, marginBottom: 5 },
  lastParagraph: { marginBottom: 30 },
  draInfo: { fontSize: 10 },
  "image_half-page": {
    width: "50%",
  },
  "image_third-page": {
    width: "33%",
  },
  image_half: {
    width: "50%",
  },
  image_third: {
    width: "33%",
  },
  "image_full-page": {
    width: "100%",
  },
  image_original: {},

  page: {
    paddingTop: 130,
    paddingRight: 50,
    paddingLeft: 50,
    paddingBottom: 45,
    fontFamily: "Brandon",
  },
  quotePage: {
    paddingTop: 100,
    paddingRight: 50,
    paddingLeft: 50,
    paddingBottom: 45,
    fontFamily: "Brandon",
  },

  organisationLogo: {
    position: "absolute",
    top: -100,
    right: 0,
    width: 120,
  },

  cover: {
    infoContainer: { paddingTop: 94 },
    image: {
      height: "795pt",
      position: "absolute",
      top: 24,
    },
    infoItem: {
      marginBottom: 10,
      color: "black",
      fontWeight: "bold",
      textAlign: "right",
      fontFamily: "Ostrich",
    },
    staticInfoItemLeft: {
      marginBottom: 10,
      fontSize: 11,
      color: "#123245",
      fontWeight: "normal",
      textAlign: "left",
    },
    staticInfoItemRight: {
      marginBottom: 10,
      fontSize: 11,
      color: "#123245",
      fontWeight: "normal",
      textAlign: "right",
    },
  },
  text: { fontSize: 10 },
  terms: {
    paragraph: { fontSize: 8, marginBottom: 6, lineHeight: 1.2 },
    heading: {
      fontSize: 8,
      color: colors.accentColor,
      fontWeight: "bold",
      marginTop: 6,
      marginBottom: 6,
    },
  },
});

export function getReportFrontCoverInfoItems({ reportType, task, calculationsFile, reportJsonData }) {
  const reportReference = getReportReference({
    calculationsFile,
    task,
    reportJsonData,
  });
  let infoItems = [reportReference, task.client.name, task.project.title, task.title];
  if (reportJsonData.fields.reportTitle?.value?.length > 0) {
    infoItems.push(reportJsonData.fields.reportTitle.value);
  } else {
    let suffix = reportType === "design" ? "DESIGN" : "CHECK";
    infoItems.push(`${suffix} CALCULATIONS`);
  }
  return infoItems;
}

export function getReportReference({ calculationsFile, task, reportJsonData }) {
  if (!calculationsFile) {
    return "";
  }

  if (!reportJsonData) {
    return null;
  }

  if (
    reportJsonData.fields.customTaskId &&
    reportJsonData.fields.customTaskId.value.length > 0 &&
    reportJsonData.fields.customTaskId.value !== " "
  ) {
    return reportJsonData.fields.customTaskId.value;
  }

  const firstSheet = calculationsFile.sheets.items[0];
  const latestSheetRevision = firstSheet.revisions.items.slice(-1)[0];
  const taskIdWithoutOrganisation = task.id.split("-").slice(1).join("-");
  const reference = `${taskIdWithoutOrganisation}-${calculationsFile.name}${latestSheetRevision.name}`;
  return reference;
}

export function PageFooter({ pageColor, includeTemplateDetails = false, colors, styles }) {
  return (
    <View
      style={{
        position: "absolute",
        top: 650,
        left: 0,
        color: pageColor === "white" ? "black" : "#123245",
      }}
    >
      <Text style={{ fontSize: 11, textAlign: "center", width: "100%" }}>
        428 Carlton Road, Carlton, Barnsley, S71 3HX
      </Text>
      <Text
        style={{
          fontSize: 11,
          textAlign: "center",
          width: "100%",
          marginTop: 5,
        }}
      >
        <Text style={{ color: "#6288BC" }}>tel:</Text> 01226 700 079 <Text style={{ color: "#6288BC" }}>web:</Text>{" "}
        apollocradles.co.uk
      </Text>
      <Text
        style={{
          fontSize: 11,
          textAlign: "center",
          width: "100%",
          marginTop: 5,
        }}
      >
        <Text style={{ color: "#6288BC" }}>VAT:</Text> GB 464286624
      </Text>
    </View>
  );
}

export function FrontCover({ infoItems = [], coverImage }) {
  let atLeastOneItemIsTooLong = infoItems.some((item) => item.length > MAX_FRONT_COVER_LENGTH);
  if (atLeastOneItemIsTooLong) {
    styles.cover.infoItem.fontSize = 45;
  } else {
    styles.cover.infoItem.fontSize = 55;
  }

  return (
    <Page size="A4" style={[styles.page, { paddingLeft: 66, paddingRight: 66, paddingTop: 80 }]}>
      <Text
        render={() => {
          initialiseLambdaPdfInserts();
          return null;
        }}
        fixed
      />
      <View style={{ position: "absolute", top: 0, left: 0 }}>
        {coverImage && (
          <Image
            allowDangerousPaths
            src={coverImage}
            style={{
              position: "absolute",
              width: 596,
              height: 842,
              backgroundColor: "#5192c2",
              top: 0,
              left: 0,
            }}
          />
        )}
      </View>
      <View style={{ position: "absolute", top: 30, left: 230 }}>
        <Image
          allowDangerousPaths
          src={OrganisationLogoCover}
          style={{
            height: "110pt",
          }}
        />
      </View>
      <View style={styles.cover.infoContainer}>
        {infoItems.map((infoItem, index) => (
          <Text key={index} style={styles.cover.infoItem}>
            {infoItem}
          </Text>
        ))}

        <Text
          style={{
            ...styles.cover.staticInfoItemRight,
            fontSize: 16,
            position: "absolute",
            top: 600,
            color: "white",
            left: 0,
            textAlign: "right",
            width: "100%",
          }}
        >
          {moment().format("MMM YYYY").toUpperCase()}
        </Text>

        <PageFooter pageColor="white" includeTemplateDetails colors={colors} styles={styles} />
      </View>
    </Page>
  );
}

export function RearCover({ coverImage }) {
  return (
    <Page size="A4" style={{ ...styles.page, padding: 0 }}>
      <View>
        <View
          style={{
            position: "absolute",
            top: 80,
            left: 65,
          }}
        >
          <PageFooter pageColor="white" includeTemplateDetails colors={colors} styles={styles} />
        </View>
      </View>
    </Page>
  );
}
