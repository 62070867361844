export default function updateChildrenForParent(currentObject, parentId, newChildren) {
  if (currentObject.custom_id === parentId) {
    return { ...currentObject, objects: newChildren };
  }

  if (Array.isArray(currentObject.objects)) {
    return {
      ...currentObject,
      objects: currentObject.objects.map((childObject) => updateChildrenForParent(childObject, parentId, newChildren)),
    };
  }

  return currentObject;
}
