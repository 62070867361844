import { Component } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Button } from "antd";

import { EXTERNAL_AUTH_PROVIDER_NAME } from "common/shared";
import DraughtHubLogo from "graphics/draughthub_logo.png";
import DraughtHubLogoSymbolOnly from "graphics/draughthub_logo_symbol_only.png";

import "./PublicNavigationBar.scss";

export default class PublicNavigationBar extends Component {
  checkUrlInterval = null;
  state = {
    isOnPublicUploadPage: false,
  };

  componentDidMount() {
    this.checkUrlInterval = setInterval(this.checkUrl, 500);
  }

  componentWillUnmount() {
    if (this.checkUrlInterval) {
      clearInterval(this.checkUrlInterval);
    }
  }

  checkUrl = () => {
    this.setState({ isOnPublicUploadPage: window.location.pathname === "/public-upload" });
  };

  onSignInWithAzureAdClick = () => {
    Auth.federatedSignIn({ customProvider: EXTERNAL_AUTH_PROVIDER_NAME });
  };

  onSignInWithDefaultProviderClick = () => {
    Auth.federatedSignIn();
  };

  render() {
    let signInButtons = (
      <Link to="/sign-in" className="button-container">
        <Button>Sign In </Button>
      </Link>
    );

    if (window.accountName === "EIS") {
      signInButtons = (
        <>
          <Button onClick={this.onSignInWithAzureAdClick} className="button-container" type="dark">
            Sign In with Enigma IS credentials
          </Button>

          <Link to="/sign-in" className="button-container">
            <Button>Sign In with DraughtHub credentials</Button>
          </Link>
        </>
      );
    }

    return (
      <div className="public-navigation-bar">
        <div className="inner-container">
          <div className="logo-container">
            <Link to="/">
              <img src={DraughtHubLogo} className="desktop-logo" alt="draughthub logo" />
              <img src={DraughtHubLogoSymbolOnly} className="mobile-logo" alt="draughthub logo" />
            </Link>
          </div>
          <div className="buttons">
            {signInButtons}

            {!this.state.isOnPublicUploadPage && (
              <div className="button-container" onClick={this.props.onContactClick}>
                <Button type="primary">Contact</Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
