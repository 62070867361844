import { useEffect, useState } from "react";
import cx from "classnames";

import { withRouter } from "react-router-dom";
import { Form, Modal, Button, Input, Typography, Select, notification } from "antd";
import withSubscriptions from "common/withSubscriptions";
import { removeSpecialCharactersFromForm } from "common/formValidators";
import { FILE_TYPES_READABLE, SHEET_NAMES_TO_IGNORE } from "common/constants";

import {
  getUppercaseStatus,
  performSheetOperation,
  isFileOpen,
  showFileIsOpenModal,
  generateSheetName,
} from "common/helpers";
import { getSheetDescription } from "common/naming";

import "./CreateSheetModal.scss";

export function CreateSheetModal({
  history,
  onClose,
  apiUser,
  sheetNamesToUseAsABase,
  taskRevision,
  task,
  organisationDetails,
  defaultNewSheetName,
  addSheetToApplicationOnly,
  updatedSheetList,
  onSubmit,
  file,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [defaultDescription, setDefaultDescription] = useState();
  const validBaseSheetOptions = (sheetNamesToUseAsABase || []).filter(
    (sheetName) => !SHEET_NAMES_TO_IGNORE.includes(sheetName)
  );

  useEffect(() => {
    if (defaultNewSheetName) {
      form.setFieldsValue({ sheetName: defaultNewSheetName });
    }

    if (!validBaseSheetOptions?.length) {
      Modal.error({
        title: "Cannot add sheet",
        content: (
          <Typography.Text>
            There are no valid sheets in {FILE_TYPES_READABLE[file.type]} we can use as a base
          </Typography.Text>
        ),
      });
    }

    getSheetDescription({
      organisation: task.organisation,
      task,
      file,
      sheetCount: 0,
    }).then((description) => setDefaultDescription(description));
  }, []); // eslint-disable-line

  async function checkSheetName(_, sheetName) {
    const sheetNameIsUsed = updatedSheetList.find((x) => x.name === sheetName);
    if (sheetNameIsUsed) {
      throw new Error("");
    }
  }

  async function checkSheetDescription(_, sheetDescription) {
    if (organisationDetails?.settings?.file?.sheetDescriptionsMustBeUnique) {
      const sheetDescriptionIsUsed = updatedSheetList.find((x) => x.description === sheetDescription);
      if (sheetDescriptionIsUsed) {
        throw new Error("");
      }
    }
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  if (!validBaseSheetOptions?.length) {
    return null;
  }

  if (defaultDescription === undefined) {
    return null;
  }

  return (
    <Modal
      maskClosable={false}
      title={
        <Typography.Text>
          Add a new sheet {addSheetToApplicationOnly && ` to ${FILE_TYPES_READABLE[file.type]} only`}
        </Typography.Text>
      }
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-sheet-modal"
    >
      <Form
        {...layout}
        form={form}
        initialValues={{
          sheetName: generateSheetName({
            organisation: task.organisation,
            task,
            file,
            sheetCount: updatedSheetList.length || 0,
          }),
          copySheetName: validBaseSheetOptions.slice(-1)[0],
          description: defaultDescription,
          status: organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions
            ? taskRevision.status
            : organisationDetails.fileStatuses[0].name,
        }}
        onFinish={onSubmit}
        onValuesChange={(values) => {
          removeSpecialCharactersFromForm({
            form,
            values,
            targetFields: ["sheetName", "description"],
          });
        }}
      >
        <Form.Item
          label={<>Copy from</>}
          name="copySheetName"
          rules={[
            {
              required: true,
              message: "You need to specify a sheet to copy from",
            },
          ]}
        >
          <Select placeholder="Choose a sheet" className="copy-from-filter" data-cy="copy-from-dropdown">
            {validBaseSheetOptions.map((sheetName, i) => (
              <Select.Option key={i} value={sheetName}>
                {sheetName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="New sheet name"
          name="sheetName"
          rules={[
            {
              required: true,
              message: "You need to specify a name for the new sheet",
            },
            addSheetToApplicationOnly
              ? () => true
              : {
                  required: true,
                  validator: checkSheetName,
                  message: "Name is already used",
                },
          ]}
        >
          <Input data-cy="sheet-name-input" disabled={!!defaultNewSheetName} />
        </Form.Item>

        {!addSheetToApplicationOnly && !organisationDetails.settings?.file?.hideSheetTitleInput && (
          <Form.Item
            label="Sheet title"
            name="description"
            rules={[
              {
                required: true,
                message: "Sheet title is required",
              },
              {
                required: true,
                validator: checkSheetDescription,
                message: "Title is already used",
              },
            ]}
          >
            <Input data-cy="drawing-title-input" />
          </Form.Item>
        )}

        {!addSheetToApplicationOnly && (
          <Form.Item
            label="Initial status"
            name="status"
            hidden={organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions}
          >
            <Select className="status-picker">
              {organisationDetails.fileStatuses.map((status) => (
                <Select.Option
                  value={status.name}
                  key={`option-state-${getUppercaseStatus(status.name).toLowerCase()}`}
                  className={cx("statuses-option", `option-status-${getUppercaseStatus(status.name).toLowerCase()}`)}
                >
                  {status.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default withRouter(
  withSubscriptions({
    Component: CreateSheetModal,
    subscriptions: ["organisationDetails"],
  })
);
