import { getFeesForClient } from "common/feeHelpers";

const fields = [
  {
    id: "quoteFees",
    fieldTypes: ["repeatFor"],
    label: "Each fee in quote fees",
    repeatForFieldName: "fee",
    value: ({ organisationDetails, quote, client }) => {
      const feesData = getFeesForClient({
        organisationDetails,
        quote,
        clientDetails: client,
        currency: "GBP",
      });

      return feesData;
    },
  },
  {
    id: "feeName",
    fieldTypes: ["textfield"],
    label: "Current fee - name",
    value: ({ fee }) => {
      if (!fee) {
        return "";
      } else {
        return fee.label;
      }
    },
  },
  {
    id: "feeValueWithCurrency",
    fieldTypes: ["textfield"],
    label: "Current fee - value with currency",
    value: ({ fee }) => {
      if (!fee) {
        return "";
      } else {
        return fee.valueWithCurrency;
      }
    },
  },
];

export function getFields({ organisationDetails }) {
  let extraFields = [];

  return [...fields, ...extraFields];
}

export const label = "Task";
