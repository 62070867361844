import { useEffect, useState } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Typography, Modal, Table, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { HAS_SHEETS, FILE_TYPES_DETAILS } from "common/shared";

import withSubscriptions from "common/withSubscriptions";
import { OpenIcon } from "common/icons";

import "./StartingFilesModal.scss";
import { getSimpleLabel } from "common/labels";

export function StartingFilesModal({ templateId, history, organisationDetails, onClose }) {
  const [startingFiles, setStartingFiles] = useState(undefined);
  const [templateDetails, setTemplateDetails] = useState(undefined);

  useEffect(() => {
    fetchAndSetStartingFiles(templateId);
    fetchAndSetTemplateDetails(templateId);

    async function fetchAndSetStartingFiles(templateId) {
      let startingFiles = [];
      let nextToken;

      while (true) {
        let response = (
          await window.callGraphQLSimple({
            message: "Failed to fetch starting files",
            query: "listFilesByOrganisation",
            variables: {
              limit: 1000,
              nextToken,
              organisation: organisationDetails.id,
              filter: {
                templateId: {
                  eq: templateId,
                },
                isStartingFile: {
                  eq: true,
                },
              },
            },
          })
        ).data.listFilesByOrganisation;
        startingFiles = [...startingFiles, ...response.items];
        nextToken = response.nextToken;

        if (!nextToken) {
          break;
        }
      }

      setStartingFiles(startingFiles);
    }

    async function fetchAndSetTemplateDetails(templateId) {
      const templateDetails = (
        await window.callGraphQLSimple({
          message: "Failed to fetch template details",
          query: "getTemplate",
          variables: {
            id: templateId,
          },
        })
      ).data.getTemplate;

      setTemplateDetails(templateDetails);
    }
  }, [organisationDetails.id, templateId]);

  let sheetsColumn;
  if (startingFiles && startingFiles.length > 0 && HAS_SHEETS[startingFiles[0].type]) {
    sheetsColumn = {
      title: "Sheets",
      key: "sheets",
      align: "center",
      width: 70,
      render: (_, file) => {
        return file.sheets.items.length;
      },
    };
  }

  return (
    <Modal open={true} onCancel={onClose} className="starting-files-modal" footer={null}>
      {!startingFiles || !templateDetails ? (
        <Typography.Text>
          <LoadingOutlined style={{ marginRight: "0.5rem" }} />
          Loading starting files...
        </Typography.Text>
      ) : (
        <Table
          title={() => {
            return (
              <>
                Starting files for {FILE_TYPES_DETAILS[templateDetails.type].label} template{" "}
                <b>{templateDetails.name}</b>: {startingFiles.length} item
                {startingFiles.length === 1 ? "" : "s"}
              </>
            );
          }}
          pagination={{ hideOnSinglePage: true, pageSize: 50 }}
          expandRowByClick={true}
          dataSource={startingFiles}
          columns={[
            {
              title: "Name",
              key: "name",
              align: "left",
              render: (_, file) => file.startingFileName || file.name,
            },
            {
              title: "Last modified",
              key: "lastModified",
              align: "center",
              width: 180,
              render: (_, file) => {
                const latestFileVersion = file.versions.items.slice(-1)[0];
                return moment(latestFileVersion.savedAt || latestFileVersion.createdAt).format("DD-MM-YYYY HH:mm:ss");
              },
            },
            sheetsColumn,
            {
              title: "",
              key: "actions",
              width: 100,
              align: "right",
              render: (_, file) => {
                return (
                  <div className="starting-file-actions">
                    <Button
                      type="primary"
                      icon={<OpenIcon />}
                      onClick={async () => {
                        const taskRevision = (
                          await window.callGraphQLSimple({
                            message: `Failed to fetch ${getSimpleLabel("task revision")} details`,
                            queryCustom: "getTaskRevision",
                            variables: {
                              id: file.taskRevisionId,
                            },
                          })
                        ).data.getTaskRevision;

                        window.open(
                          `/tasks/${taskRevision.taskId}/${file.type}/${file.id}?tab=${file.sheets.items[0].id}`,
                          "_blank"
                        );
                      }}
                    >
                      Go to file
                    </Button>
                  </div>
                );
              },
            },
          ].filter((x) => x)}
        />
      )}
    </Modal>
  );
}

export default withRouter(
  withSubscriptions({
    Component: StartingFilesModal,
    subscriptions: ["organisationDetails"],
  })
);
