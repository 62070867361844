import { useState, useEffect } from "react";
import { Tabs, Typography } from "antd";
import { withRouter } from "react-router-dom";

import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";

import ReviewSettingsPage from "./ReviewSettingsPage/ReviewSettingsPage";
import QuoteSettingsPage from "./QuoteSettingsPage/QuoteSettingsPage";
import TaskSettingsPage from "./TaskSettingsPage/TaskSettingsPage";
import GeneralSettingsPage from "./GeneralSettingsPage/GeneralSettingsPage";
import TimesheetSettingsPage from "./TimesheetSettingsPage/TimesheetSettingsPage";
import TimelineSettingsPage from "./TimelineSettingsPage/TimelineSettingsPage";
import InvoiceSettingsPage from "./InvoiceSettingsPage/InvoiceSettingsPage";
import PurchaseOrderSettingsPage from "./PurchaseOrderSettingsPage/PurchaseOrderSettingsPage";
import FileSettingsPage from "./FileSettingsPage/FileSettingsPage";
import RequestSettingsPage from "./RequestSettingsPage/RequestSettingsPage";
import ProjectSettingsPage from "./ProjectSettingsPage/ProjectSettingsPage";
import StandardFeesPage from "OrganisationPage/StandardFeesPage/StandardFeesPage";
import CustomLabelsPage from "OrganisationPage/CustomLabelsPage/CustomLabelsPage";
import Modules from "OrganisationPage/OrganisationSettingsPage/GeneralSettingsPage/Modules/Modules";

import "./OrganisationSettingsPage.scss";

export function OrganisationSettingsPage(props) {
  const [activeTab, setActiveTab] = useState("General");
  const { organisationDetails, history } = props;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      setActiveTab(tab);
    }
  }, []);

  useEffect(() => {
    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "ORGANISATION_SETTINGS",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
  }, []);

  return (
    <div className="organisation-settings-page">
      <Tabs
        className="tabs"
        activeKey={activeTab}
        onTabClick={(tabKey) => {
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.set("tab", tabKey);
          history.push(`settings?${urlParams.toString()}`);
          setActiveTab(tabKey);
        }}
      >
        <Tabs.TabPane tab={<Typography.Text data-cy="general-tab">General</Typography.Text>} key="General">
          <GeneralSettingsPage {...props} />
        </Tabs.TabPane>

        <Tabs.TabPane tab={<Typography.Text data-cy="modules-tab">Modules</Typography.Text>} key="Modules">
          <Modules {...props} />
        </Tabs.TabPane>

        <Tabs.TabPane tab={<Typography.Text data-cy="fees-tab">Fees</Typography.Text>} key="Fees">
          <StandardFeesPage {...props} parent={organisationDetails} parentType="organisation" />
        </Tabs.TabPane>

        <Tabs.TabPane tab={<Typography.Text data-cy="labels-tab">Labels</Typography.Text>} key="Labels">
          <CustomLabelsPage {...props} />
        </Tabs.TabPane>

        {organisationDetails.settings?.request?.usesRequests && (
          <Tabs.TabPane
            tab={<Typography.Text data-cy="requests-tab">{getSimpleLabel("Requests")}</Typography.Text>}
            key="Requests"
          >
            <RequestSettingsPage {...props} />
          </Tabs.TabPane>
        )}

        <Tabs.TabPane
          tab={<Typography.Text data-cy="tasks-tab">{getSimpleLabel("Tasks")}</Typography.Text>}
          key="Tasks"
        >
          <TaskSettingsPage {...props} />
        </Tabs.TabPane>

        {organisationDetails.usesFiles && (
          <Tabs.TabPane tab={<Typography.Text data-cy="files-tab">Files</Typography.Text>} key="Files">
            <FileSettingsPage {...props} />
          </Tabs.TabPane>
        )}

        <Tabs.TabPane
          tab={<Typography.Text data-cy="projects-tab">{getSimpleLabel("Projects")}</Typography.Text>}
          key="Projects"
        >
          <ProjectSettingsPage {...props} />
        </Tabs.TabPane>

        {organisationDetails.usesReviews && (
          <Tabs.TabPane
            tab={<Typography.Text data-cy="reviews-tab">Reviews</Typography.Text>}
            key="Reviews"
            data-cy="review-tab"
          >
            <ReviewSettingsPage {...props} />
          </Tabs.TabPane>
        )}

        {organisationDetails.settings?.timeline?.usesTimeline && (
          <Tabs.TabPane
            tab={<Typography.Text data-cy="timeline-tab">{getSimpleLabel("Timeline")}</Typography.Text>}
            key="Timeline"
          >
            <TimelineSettingsPage {...props} />
          </Tabs.TabPane>
        )}

        {organisationDetails.settings?.timesheet?.usesTimesheets && (
          <Tabs.TabPane tab={<Typography.Text data-cy="timesheets-tab">Timesheets</Typography.Text>} key="Timesheets">
            <TimesheetSettingsPage {...props} />
          </Tabs.TabPane>
        )}

        {organisationDetails.settings?.quote?.usesQuotes && (
          <Tabs.TabPane
            tab={<Typography.Text data-cy="quotes-tab">{getSimpleLabel("Quotes")}</Typography.Text>}
            key="Quotes"
          >
            <QuoteSettingsPage {...props} />
          </Tabs.TabPane>
        )}

        {organisationDetails.settings?.invoice?.usesInvoices && (
          <Tabs.TabPane tab={<Typography.Text data-cy="invoices-tab">Invoices</Typography.Text>} key="Invoices">
            <InvoiceSettingsPage {...props} />
          </Tabs.TabPane>
        )}
        {organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders && (
          <Tabs.TabPane
            tab={<Typography.Text data-cy="purchase-orders-tab">Purchase orders</Typography.Text>}
            key="Purchase orders"
          >
            <PurchaseOrderSettingsPage {...props} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: OrganisationSettingsPage,
    subscriptions: ["organisationDetails"],
  })
);
