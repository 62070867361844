const fields = [
  {
    id: "apiUserFullName",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Logged in user full name",
    value: ({ apiUser }) => {
      if (!apiUser) {
        return "";
      }

      return `${apiUser.firstName ? `${apiUser.firstName} ` : ""}${apiUser.lastName || ""}`;
    },
  },
  {
    id: "apiUserSignature",
    fieldTypes: ["signature"],
    label: "Logged in user signature",
    value: ({ apiUser }) => {
      if (!apiUser) {
        return undefined;
      }

      return {
        image: apiUser.signature,
        firstName: apiUser.firstName,
        lastName: apiUser.lastName,
      };
    },
  },
  {
    id: "apiUserPosition",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Logged in user position",
    value: ({ apiUser }) => {
      if (!apiUser) {
        return "";
      }

      return `${apiUser.position || ""}`;
    },
  },
  {
    id: "apiUserQualifications",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Logged in user qualifications",
    value: ({ apiUser }) => {
      if (!apiUser) {
        return "";
      }

      return `${apiUser.qualifications || ""}`;
    },
  },
  {
    id: "apiUserPhone1",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Logged in user phone 1",
    value: ({ apiUser }) => {
      if (!apiUser) {
        return "";
      }

      return `${apiUser.phone1 || ""}`;
    },
  },
  {
    id: "apiUserPhone2",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Logged in user phone 2",
    value: ({ apiUser }) => {
      if (!apiUser) {
        return "";
      }

      return `${apiUser.phone2 || ""}`;
    },
  },
  {
    id: "apiUserEmail",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Logged in user email",
    value: ({ apiUser }) => {
      if (!apiUser) {
        return "";
      }

      return `${apiUser.id || ""}`;
    },
  },
  {
    id: "apiUserId",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Logged in user ID",
    value: ({ apiUser }) => {
      if (!apiUser) {
        return "";
      }

      return `${apiUser.id || ""}`;
    },
  },
  {
    id: "apiUserPositionAndQualifications",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Logged in user position and qualifications",
    value: ({ apiUser }) => {
      if (!apiUser) {
        return "";
      }

      return `${apiUser.position ? `${apiUser.position} ` : ""}${apiUser.qualifications || ""}`;
    },
  },
];
export function getFields() {
  return fields;
}
