import { Typography, Dropdown, Button } from "antd";
import cx from "classnames";
import { CaretDownOutlined, CloseCircleOutlined, CommentOutlined, CheckCircleOutlined } from "@ant-design/icons";
import "./ReviewMobileActions.scss";

const MENU_ACTIONS_KEYS = {
  CANCEL_REVIEW: "CANCEL_REVIEW",
  CANCEL_APPROVAL: "CANCEL_APPROVAL",
  REQUEST_CHANGES: "REQUEST_CHANGES",
  WITH_COMMENTS: "WITH_COMMENTS",
  APPROVE: "APPROVE",
  COMMENTS_ADDRESSED: "COMMENTS_ADDRESSED",
  INFORMATION_REQUIRED: "INFORMATION_REQUIRED",
  REOPEN_REVIEW: "REOPEN_REVIEW",
};

export default function ReviewMobileActions({
  reviewStatus,
  userIsAuthor,
  userIsReviewer,
  taskRevision,
  submitReview,
  containerThis,
  approveReview,
  closeReview,
  notifyReviewer,
  taskRevisionIsLatest,
  confirmCancelApproval,
  reopenReview,
}) {
  let newItems = [];

  if (reviewStatus !== "CLOSED") {
    newItems.push({
      key: MENU_ACTIONS_KEYS.CANCEL_REVIEW,
      label: "Cancel review",
      disabled: reviewStatus === "SUCCESS" || taskRevision.isReadOnly,
    });
  } else {
    newItems.push({
      key: MENU_ACTIONS_KEYS.REOPEN_REVIEW,
      label: "Reopen review",
      disabled: taskRevision.isReadOnly,
    });
  }

  if (reviewStatus === "SUCCESS" && taskRevisionIsLatest) {
    newItems.push({
      key: MENU_ACTIONS_KEYS.CANCEL_APPROVAL,
      label: "Cancel approval",
    });
  }

  if (!["CLOSED", "SUCCESS"].includes(reviewStatus) && userIsReviewer) {
    newItems.push(
      {
        key: MENU_ACTIONS_KEYS.REQUEST_CHANGES,
        label: (
          <Typography.Text className="request-changes">
            <CloseCircleOutlined /> Request changes
          </Typography.Text>
        ),

        disabled: taskRevision.isReadOnly,
      },
      {
        key: MENU_ACTIONS_KEYS.WITH_COMMENTS,
        label: "With comments",
        icon: <CommentOutlined />,
        disabled: taskRevision.isReadOnly,
      },
      {
        key: MENU_ACTIONS_KEYS.APPROVE,
        label: "Approve",
        icon: <CheckCircleOutlined />,
      }
    );
  }

  if (reviewStatus !== "CLOSED" && userIsAuthor) {
    newItems.push(
      {
        key: MENU_ACTIONS_KEYS.COMMENTS_ADDRESSED,
        label: (
          <Typography.Text>
            <CheckCircleOutlined /> Comments addressed
          </Typography.Text>
        ),

        disabled: reviewStatus === "SUCCESS",
      },
      {
        key: MENU_ACTIONS_KEYS.INFORMATION_REQUIRED,
        label: "Information required",
        icon: <CommentOutlined />,
        disabled: reviewStatus === "SUCCESS",
      }
    );
  }

  const onClick = ({ key }) => {
    switch (key) {
      case MENU_ACTIONS_KEYS.CANCEL_REVIEW:
        closeReview();
        break;
      case MENU_ACTIONS_KEYS.CANCEL_APPROVAL:
        confirmCancelApproval();
        break;
      case MENU_ACTIONS_KEYS.REOPEN_REVIEW:
        reopenReview();
        break;
      case MENU_ACTIONS_KEYS.REQUEST_CHANGES:
        submitReview.call(containerThis, "CHANGES_REQUESTED");
        break;
      case MENU_ACTIONS_KEYS.WITH_COMMENTS:
        submitReview.call(containerThis, "WITH_COMMENTS");
        break;
      case MENU_ACTIONS_KEYS.APPROVE:
        approveReview();
        break;
      case MENU_ACTIONS_KEYS.COMMENTS_ADDRESSED:
        notifyReviewer.call(containerThis, "COMMENTS_ADDRESSED");
        break;
      case MENU_ACTIONS_KEYS.INFORMATION_REQUIRED:
        notifyReviewer.call(containerThis, "INFO_REQUIRED");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Dropdown menu={{ items: newItems, onClick }} overlayClassName={cx("review-options-menu")}>
        <Button type="primary">
          <CaretDownOutlined />
          Submit review
        </Button>
      </Dropdown>
    </>
  );
}
