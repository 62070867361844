import { Tag, Typography } from "antd";
import { Link } from "react-router-dom";
import { StarFilled } from "@ant-design/icons";
import { processIdForDisplay } from "common/helpers";

import "./TaskIdTag.scss";

export default function TaskIdTag({ task, includeTitle = true, style, includeLink = true }) {
  if (!task) {
    return null;
  }
  let idToDisplay = task.id;
  if (idToDisplay.includes("-TEMPLATES")) {
    idToDisplay = "TEMPLATE";
  }

  idToDisplay = processIdForDisplay(idToDisplay);

  let content = (
    <>
      <Tag className="task-id-tag dark-tag" style={style}>
        {task.client && task.client.isPriority && <StarFilled className="priority-marker" />} {idToDisplay}
      </Tag>
      {includeTitle && (task.title?.length > 50 ? `${task.title?.substring(0, 50)}...` : task.title)}
    </>
  );

  if (includeLink) {
    return <Link to={`/tasks/${task.id}`}>{content}</Link>;
  } else {
    return <Typography.Text>{content}</Typography.Text>;
  }
}
