import { callRest } from "common/apiHelpers";
import { message, notification, Typography } from "antd";
import { GENERIC_AVATAR_URL } from "common/constants";
import { PUBLIC_S3_BUCKET_URL } from "common/publicS3BucketUrl";
import { convertSlateToHTML } from "common/emailHelpers";
// import { getLabel } from "common/helpers";

export function getMentionedItems({ text, options }) {
  let parsedText;
  try {
    parsedText = JSON.parse(text);
  } catch (e) {
    // nothing, the text isn't JSON
  }
  if (parsedText) {
    return getMentionedItemsFromJSON({ parsedText });
  }

  return getMentionedItemsFromPlainText({ text, options });
}

function getMentionedItemsFromPlainText({ text, options }) {
  let mentionedItems = text.trim().match(/(\s|^)@[^\s]+/g);
  let validMentionedItems = mentionedItems
    ?.map((item) => {
      item = item.trim();
      if (item.startsWith("@")) {
        item = item.substring(1);
      }
      return item;
    })
    .filter((item) => {
      if (!item) {
        return false;
      }
      return options.includes(item);
    });
  return validMentionedItems;
}

function getMentionedItemsFromJSON({ parsedText }) {
  const mentionedItems = [];

  // Helper function to recursively traverse the nodes
  const traverseNodes = (nodes) => {
    nodes?.forEach((node) => {
      // If the node is an element and its type is "mention", extract the "id" from "data"
      if (node.type === "mention" && node.data && node.data.id) {
        mentionedItems.push(node.data.id); // Extract the "id" field
      }

      // If the node has children, recursively traverse them
      if (node.children) {
        traverseNodes(node.children);
      }
    });
  };

  // Start traversing the parsedText object
  traverseNodes(parsedText);

  return mentionedItems;
}

export async function sendNotificationsToMentionedPeople({
  textToSearch,
  mentionOptions,
  users,
  notificationText,
  link,
  apiUser,
}) {
  let mentionedItems = getMentionedItems({ text: textToSearch, options: mentionOptions });

  let notificationPromises = [];
  for (let i = 0; i < mentionedItems?.length; i++) {
    let user = users.find((user) => user.id === mentionedItems[i]);
    if (user) {
      notificationPromises.push(
        sendNotificationToUser({ user, notificationText, link, apiUser, messageBody: textToSearch })
      );
    }
  }
}

async function sendNotificationToUser({ user, notificationText, link, apiUser, messageBody }) {
  const notificationId = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  let userHasBeenNotified = false;
  let userHasNotificationsEnabled = false;

  if (!user.notificationSettings || user.notificationSettings.webAppMention !== false) {
    userHasNotificationsEnabled = true;
    await window.callGraphQLSimple({
      message: (
        <>
          Failed to send in-app notification to{" "}
          <b>
            {user.firstName} {user.lastName}
          </b>
        </>
      ),
      mutation: "createNotification",
      variables: {
        input: {
          id: notificationId,
          userId: user.id,
          organisation: user.organisation,
          link,
          type: "MENTION",
          message: notificationText,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
    userHasBeenNotified = true;
  }

  if (!user.notificationSettings || user.notificationSettings.emailMention !== false) {
    userHasNotificationsEnabled = true;

    let htmlMessageBody;
    let parsedMessageBody;
    try {
      parsedMessageBody = JSON.parse(messageBody);
    } catch (e) {}

    if (!parsedMessageBody) {
      htmlMessageBody = messageBody.replace(/\n/g, "<br/>");
    } else {
      htmlMessageBody = convertSlateToHTML(parsedMessageBody);
    }

    const emailParams = {
      type: "MENTION",
      subject: notificationText,
      message: `${notificationText}: <br/> ${htmlMessageBody}`,
      senderDetails: {
        firstName: apiUser.firstName,
        lastName: apiUser.lastName,
        avatar: apiUser.avatarKey ? `${PUBLIC_S3_BUCKET_URL}/public/${apiUser.avatarKey}` : GENERIC_AVATAR_URL,
        id: apiUser.id,
      },
      receiverDetails: {
        firstName: user.firstName,
        lastName: user.lastName,
        avatar: user.avatarKey ? `${PUBLIC_S3_BUCKET_URL}/public/${user.avatarKey}` : GENERIC_AVATAR_URL,
        id: user.id,
      },
      origin: window.location.origin,
      link: `${window.location.origin}${link}${link.includes("?") ? "&" : "?"}notificationId=${notificationId}`,
      id: notificationId,
    };
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
      userHasBeenNotified = true;
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Failed to send notification email to{" "}
            <b>
              {user.firstName} {user.lastName}
            </b>
            :
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (userHasBeenNotified) {
    message.success(`Notification sent to ${user.firstName} ${user.lastName}`);
  } else {
    if (!userHasNotificationsEnabled) {
      message.error(`${user.firstName} ${user.lastName} does not have notifications enabled`);
    }
  }
}
