import { Typography } from "antd";

import withSubscriptions from "common/withSubscriptions";
import { isAuthorised } from "common/permissions";
// import { Draggable } from "react-beautiful-dnd";

import DashboardItemTags from "DashboardItemTags/DashboardItemTags";
import Card from "Card/Card";
import Avatar from "Avatar/Avatar";
import ClientLogo from "ClientLogo/ClientLogo";
import TaskIdTag from "TaskIdTag/TaskIdTag";

import "./QuoteItem.scss";

export function QuoteItem({ quote, projects, clients, users, onClick, organisationDetails }) {
  const project = projects.find((x) => x.id === quote.projectId);
  const client = clients.find((x) => x.id === quote.clientId);

  if (!project) {
    return null;
  }

  const userData = users.find((x) => x.id === quote.assignedTo);

  let idTagProps = {};

  let quoteItemElement = (
    <div className="quote-item-container">
      <Card
        className="quote-item"
        attributes={{ "data-title": quote.title, "data-quote-id": quote.id }}
        onClick={() => {
          if (onClick && typeof onClick === "function") {
            onClick(quote.id);
          }
        }}
        data-cy="quote-item"
      >
        <Typography.Paragraph className="project-name">{project.title}</Typography.Paragraph>
        <div className="quote-initials-and-name">
          <Typography.Text level={4} className="quote-name">
            {quote.title}
          </Typography.Text>
        </div>
        {(!organisationDetails.settings?.general?.hideFinancials || isAuthorised(["QUOTES.WRITE_AMOUNT"])) && (
          <Typography.Text level={4} className="quote-subtotal">
            {window.formatCurrency("GBP", quote.subtotal)}
          </Typography.Text>
        )}

        <DashboardItemTags
          item={{ ...quote, client, project }}
          organisationDetails={organisationDetails}
          skipCatLevel={true}
          skipInstructed={true}
          skipCustomFields={true}
        />

        <div className="footer">
          <ClientLogo client={client} size="small" />

          <Typography.Paragraph className="task-id">
            <TaskIdTag task={{ ...quote, client, project }} includeTitle={false} {...idTagProps} />
          </Typography.Paragraph>
          <Avatar user={userData} className="assigned-to-avatar" />
        </div>
      </Card>
    </div>
  );

  // if (!draggable) {
  return quoteItemElement;
  // }

  // return (
  //   <Draggable key={id} draggableId={id} index={index} className={`draggable-task ${title.split(" ").join("_")}`}>
  //     {(provided) => {
  //       return (
  //         <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
  //           {taskItemElement}
  //         </div>
  //       );
  //     }}
  //   </Draggable>
  // );
}

export default withSubscriptions({
  Component: QuoteItem,
  subscriptions: ["users", "projects", "clients"],
});
