import { useState } from "react";
import { Modal, Form, Button, Space } from "antd";

import { bringTaskIntoSprint, getLabel } from "common/helpers";
import withSubscriptions from "common/withSubscriptions";

import SprintPicker from "../../SprintPicker/SprintPicker";
import { getSimpleLabel } from "common/labels";

export function SendToTargetSprintModal({ onClose, visible, sprints, tasks, taskId, organisationDetails }) {
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(targetSprint) {
    setIsLoading(true);
    const target = targetSprint["sprint-id"];
    bringTaskIntoSprint({ taskId: taskId, sprints, tasks, sprintId: target });

    setIsLoading(false);
    onClose();
  }

  return (
    <Modal
      title={`Send ${getSimpleLabel("task")} to a different ${getLabel({
        organisationDetails,
        id: "sprint",
        defaultValue: "sprint",
      })}`}
      open={visible}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="send-to-a-different-sprint"
    >
      <Form onFinish={onSubmit}>
        <Space direction="vertical" className="spread">
          <Form.Item label={getLabel({ organisationDetails, id: "Sprint", defaultValue: "Sprint" })} name="sprint-id">
            <SprintPicker sprints={sprints} />
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading} data-cy="custom-label-submit-button">
              Submit
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}

export default withSubscriptions({
  Component: SendToTargetSprintModal,
  subscriptions: ["organisationDetails"],
});
