import { useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { Link } from "react-router-dom";
import { PlusCircleOutlined, FileTextOutlined } from "@ant-design/icons";
import { createFileWithSheets, getUppercaseStatus } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";

import Card from "Card/Card";

import "./DocumentRegisterTab.scss";

export default function DocumentRegisterTab({ apiUser, task, organisationDetails }) {
  const [isLoading, setIsLoading] = useState(false);
  const latestTaskRevision = task.revisions.items.slice(-1)[0];
  const fileType = "REPORT";
  const templateId = `${organisationDetails.id}_${fileType}_document_register`;

  const templateDetails = organisationDetails.templates.items.find((template) => template.id === templateId);

  const documentRegisterFile = latestTaskRevision?.files.items.find(
    (x) => x.templateId === templateId && !x.isArchived
  );

  // eslint-disable-next-line
  useEffect(() => {
    if (documentRegisterFile) {
      setIsLoading(false);
    }
  });

  async function addDocumentRegister() {
    setIsLoading(true);
    await createFileWithSheets({
      name: "Document Register",
      fileType,
      sheetCount: 1,
      apiUser,
      task,
      doPublish: false,
      templateId,
      templateVersionNumber: templateDetails?.currentVersionNumber || 0,
      extension: "json",
      isHidden: true,
    });

    await callGraphQLSimple({
      message: "Failed to update task",
      queryName: "updateTask",
      variables: {
        input: {
          id: task.id,
          randomNumber: Math.floor(Math.random() * 1000000),
        },
      },
    });
  }

  return (
    <Card
      className="document-register-card"
      title={
        documentRegisterFile ? (
          <Link to={`/tasks/${task.id}/REPORT/${documentRegisterFile.id}`} key={documentRegisterFile.id}>
            <FileTextOutlined />
            Document register for {getSimpleLabel("task")} <b>{task.id}</b>
          </Link>
        ) : templateDetails ? (
          <Button
            icon={<PlusCircleOutlined />}
            type="primary"
            data-cy="add-document-register-button"
            onClick={addDocumentRegister}
            loading={isLoading}
          >
            {isLoading ? "Adding document register..." : "Add document register"}
          </Button>
        ) : (
          <Typography.Text>No template found for document register</Typography.Text>
        )
      }
    />
  );
}
