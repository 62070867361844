export default function isTimesheetBlockACall({ timesheetBlock, timesheetTags }) {
  if (
    timesheetBlock.tags?.some((tagId) => {
      let tag = timesheetTags?.find((tag) => tag.id === tagId);
      return tag?.label.toLowerCase().includes("call");
    })
  ) {
    return true;
  }

  let lowercaseDescription = timesheetBlock.description?.toLowerCase();

  if (timesheetBlock.taskId?.toLowerCase().includes("video") || timesheetBlock.taskId?.toLowerCase().includes("call")) {
    return true;
  }
  const phrasesToCheck = ["video call", "teams call", "google meet", "skype call", "on a call"];
  for (const phrase of phrasesToCheck) {
    if ((lowercaseDescription || "").includes(phrase)) {
      return true;
    }
  }

  return false;
}
