import { Select } from "antd";
import { getFieldsForEditorByDataSource } from "common/documentEditorDataSources/aggregator";
import { DOCUMENT_EDITOR_DATA_SOURCES } from "common/constants";
import { TEMPLATE_EDITOR_CONDITIONS } from "common/shared";
import { getVariables } from "../../../renderHelpers";
import DraughtHubInput from "Input/Input";
import { getRepeatFor } from "TemplateEditorPage/templateEditorVariableHelpers";
import InfoItem from "InfoItem/InfoItem";
import DataSourceOverrideOptions from "../../DataSourceOverrideOptions/DataSourceOverrideOptions";

import "./Condition.scss";

export default function Condition({
  condition,
  index,
  object,
  updateCondition,
  fileTypeDetails,
  form,
  organisationDetails,
}) {
  let conditionDetails =
    condition.custom_conditionalDisplayCondition &&
    TEMPLATE_EDITOR_CONDITIONS.find(
      (crtCondition) => crtCondition.value === condition.custom_conditionalDisplayCondition
    );

  const shouldDisplayTarget = conditionDetails?.hasTarget;

  return (
    <div className="condition">
      <InfoItem
        label="Target data source"
        value={
          <Select
            allowClear
            value={condition.custom_conditionalDisplayDataSource}
            placeholder="No option selected"
            showSearch
            filterOption={(searchValue, { label }) => {
              return label?.toLowerCase().includes(searchValue?.toLowerCase());
            }}
            onChange={(_, option) => {
              updateCondition({
                object,
                condition,
                index,
                fields: {
                  custom_conditionalDisplayDataSource: option?.value,
                  custom_conditionalDisplayDataSourceField: undefined,
                  custom_conditionalDisplayDataSourceOverride: undefined,
                },
              });
            }}
          >
            {fileTypeDetails.dataSources.map((dataSource) => {
              return (
                <Select.Option key={dataSource} value={dataSource} label={DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}>
                  {DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}
                </Select.Option>
              );
            })}
            <Select.Option key="object" value="object" label="This object">
              This object
            </Select.Option>
          </Select>
        }
      />
      <DataSourceOverrideOptions
        object={condition}
        dataSourceFieldName="custom_conditionalDisplayDataSource"
        dataSourceOverrideFieldName="custom_conditionalDisplayDataSourceOverride"
        fileTypeDetails={fileTypeDetails}
        form={form}
        organisationDetails={organisationDetails}
        onChange={({ dataSourceOverrideFieldName, value }) => {
          updateCondition({
            object,
            condition,
            index,
            fields: {
              [dataSourceOverrideFieldName]: value,
            },
          });
        }}
      />

      {condition.custom_conditionalDisplayDataSource && (
        <InfoItem
          label="Target field"
          value={
            <Select
              allowClear
              value={condition.custom_conditionalDisplayDataSourceField}
              placeholder="No option selected"
              showSearch
              filterOption={(searchValue, { label }) => {
                return label?.toLowerCase().includes(searchValue?.toLowerCase());
              }}
              onChange={(_, option) => {
                updateCondition({
                  object,
                  condition,
                  index,
                  fields: {
                    custom_conditionalDisplayDataSourceField: option?.value,
                  },
                });
              }}
            >
              {getFieldsForEditorByDataSource({
                repeatFor: getRepeatFor(object),
                dataSource: condition.custom_conditionalDisplayDataSource,
                form,
                organisationDetails,
              }).map((option) => {
                return (
                  <Select.Option key={option.value} value={option.value} label={option.label}>
                    {option.label}
                  </Select.Option>
                );
              })}
            </Select>
          }
        />
      )}
      {condition.custom_conditionalDisplayDataSourceField && (
        <InfoItem
          label="Condition"
          value={
            <Select
              allowClear
              value={condition.custom_conditionalDisplayCondition}
              placeholder="No option selected"
              showSearch
              filterOption={(searchValue, { label }) => {
                return label?.toLowerCase().includes(searchValue?.toLowerCase());
              }}
              onChange={(_, option) => {
                updateCondition({
                  object,
                  condition,
                  index,
                  fields: {
                    custom_conditionalDisplayCondition: option?.value,
                  },
                });
              }}
            >
              {TEMPLATE_EDITOR_CONDITIONS.map((option) => {
                return (
                  <Select.Option key={option.value} value={option.value} label={option.label}>
                    {option.label}
                  </Select.Option>
                );
              })}
            </Select>
          }
        />
      )}
      {shouldDisplayTarget && (
        <InfoItem
          label="Target value / values"
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({
                organisationDetails,
              }).map((variableName) => variableName.substring(1))}
              defaultValue={condition.custom_conditionalDisplayTarget}
              onChange={(value) => {
                updateCondition({
                  object,
                  condition,
                  index,
                  fields: {
                    custom_conditionalDisplayTarget: value,
                  },
                });
              }}
            />
          }
        />
      )}
    </div>
  );
}
