const fields = [
  {
    id: "quoteLineItemTitle",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Title",
    value: ({ quoteLineItem }) => quoteLineItem?.title || "",
  },
  {
    id: "quoteLineItemTitleAndDescription",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Title and description",
    value: ({ quoteLineItem }) => {
      let result = quoteLineItem?.title || "";
      if (quoteLineItem?.description) {
        result = result ? `${result}\n${quoteLineItem.description}` : quoteLineItem.description;
      }
      return result;
    },
  },
  {
    id: "quoteLineItemDescription",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Description",
    value: ({ quoteLineItem }) => quoteLineItem?.description || "",
  },
  {
    id: "quoteLineItemIsHourly",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Is hourly",
    value: ({ quoteLineItem }) => quoteLineItem?.isHourly,
  },

  {
    id: "quoteLineItemQuantity",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Quantity",
    value: ({ quoteLineItem }) => quoteLineItem?.quantity || "",
  },
  {
    id: "quoteLineItemCatLevel",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Authority level",
    value: ({ quoteLineItem, organisationDetails }) => {
      if (
        !quoteLineItem ||
        !organisationDetails ||
        quoteLineItem.authorityLevel === null ||
        quoteLineItem.authorityLevel === undefined
      ) {
        return "";
      }
      return getUserReadableCatLevel(organisationDetails, quoteLineItem.authorityLevel) || "";
    },
  },
  {
    id: "quoteLineItemTaxRate",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Tax rate",
    value: ({ quoteLineItem }) => quoteLineItem?.taxRate || "",
  },
  {
    id: "quoteLineUnitPrice",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Unit price",
    value: ({ quoteLineItem }) => {
      if (!quoteLineItem || quoteLineItem.unitPrice === null || quoteLineItem.unitPrice === undefined) {
        return "";
      }

      return global.formatCurrency("GBP", quoteLineItem.unitPrice);
    },
  },
  {
    id: "quoteLineItemAmount",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Amount (formatted)",
    value: ({ quoteLineItem }) => {
      if (!quoteLineItem || quoteLineItem.amount === null || quoteLineItem.amount === undefined) {
        return "";
      }

      return global.formatCurrency("GBP", quoteLineItem.amount);
    },
  },
  {
    id: "quoteLineItemAmountAsNumber",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Amount (number)",
    value: ({ quoteLineItem }) => {
      if (!quoteLineItem || quoteLineItem.amount === null || quoteLineItem.amount === undefined) {
        return "";
      }

      return parseFloat(quoteLineItem.amount);
    },
  },
  {
    id: "quoteLineItemAmountTax",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Tax amount (formatted)",
    value: ({ quoteLineItem }) => {
      if (!quoteLineItem || quoteLineItem.amountTax === null || quoteLineItem.amountTax === undefined) {
        return "";
      }

      return global.formatCurrency("GBP", quoteLineItem.amountTax);
    },
  },
  {
    id: "quoteLineItemAmountTaxAsNumber",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Tax amount",
    value: ({ quoteLineItem }) => {
      if (!quoteLineItem || quoteLineItem.amountTax === null || quoteLineItem.amountTax === undefined) {
        return "";
      }

      return parseFloat(quoteLineItem.amountTax || 0);
    },
  },
];

export function getUserReadableCatLevel(organisationDetails, level) {
  let value = "";
  let labelId = "";
  switch (level) {
    case 0:
      value = "Unknown";
      labelId = "cat-zero";
      break;
    case 1:
      value = "Cat 1";
      labelId = "cat-one";
      break;
    case 2:
      value = "Cat 2";
      labelId = "cat-two";
      break;
    case 3:
      value = "Cat 3";
      labelId = "cat-three";
      break;
    case null:
      value = "Not Set";
      labelId = "cat-null";
      break;

    default:
      throw new Error(`Cat level unknown: ${level}`);
  }

  value = getLabel({ organisationDetails, id: labelId, defaultValue: value });
  return value;
}

export function getLabel({ organisationDetails = undefined, id, defaultValue = "" }) {
  if (typeof window !== "undefined") {
    organisationDetails = organisationDetails || window.organisationDetails;
  }
  let value = defaultValue || id;
  if (organisationDetails && organisationDetails.labels && Array.isArray(organisationDetails.labels)) {
    for (let i = 0; i < organisationDetails.labels.length; i++) {
      const label = organisationDetails.labels[i];
      if (label.id === id && label.value) {
        value = label.value;
      }
    }
  }

  return value;
}

export function getFields() {
  return fields;
}
