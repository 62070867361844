import { useState } from "react";
import { Link } from "react-router-dom";
import Card from "../../Card/Card";
import { Typography, Tag } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { INVOICE_STATUSES } from "common/constants";
import CreateInvoiceModal from "Modals/CreateInvoiceModal/CreateInvoiceModal";

import Avatar from "Avatar/Avatar";

import "./ProjectInvoices.scss";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";

export default function ProjectInvoices({ project, users, apiUser, invoices }) {
  const invoicesInProject = invoices.filter((invoice) => invoice.projectId === project.id && !invoice.isArchived);
  const [isCreateInvoiceModalVisible, setIsCreateInvoiceModalVisible] = useState(false);

  return (
    <>
      <Card
        title="Invoices"
        className="project-invoices-card"
        actions={
          <ButtonWithPermissions
            type="primary"
            className="create-invoice"
            onClick={() => setIsCreateInvoiceModalVisible(true)}
            data-cy="create-invoice-button"
            permissions={["CREATE_INVOICE"]}
          >
            <PlusCircleOutlined /> Create invoice
          </ButtonWithPermissions>
        }
      >
        <div className="items">
          {invoicesInProject.map((invoice) => {
            let author = users.find((user) => user.id === invoice.author);
            let status = INVOICE_STATUSES.find((status) => status.value === invoice.status);
            return (
              <Link
                to={`/invoices/${invoice.id}`}
                className="invoice-item"
                data-cy="invoice-list-item"
                key={invoice.id}
              >
                <Tag className="invoice-id-tag dark-tag">{invoice.id}</Tag>
                {/* <Typography.Text className="title">{invoice.title}</Typography.Text> */}
                <Typography.Text className="total">
                  {window.formatCurrency(invoice.currency, invoice.subtotal)}
                </Typography.Text>

                <Typography.Text className="status">{status?.label}</Typography.Text>
                <Avatar user={author}> </Avatar>
              </Link>
            );
          })}
        </div>
      </Card>
      {isCreateInvoiceModalVisible && (
        <CreateInvoiceModal
          onClose={() => setIsCreateInvoiceModalVisible(false)}
          predefinedFields={{ projectId: project.id }}
        />
      )}
    </>
  );
}
