import { RouteComponentProps, withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { Spin, Button, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { getLabel } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";
import { ActivityItem, User } from "common/types";

import Card from "Card/Card";
import Textarea from "DocumentForm/Textarea/Textarea";
import ActivityItemComponent from "./ActivityItem/ActivityItem";

import "./Activity.scss";

interface ActivityProps extends RouteComponentProps {
  parentLabel: "Task" | "Project";
  users: User[];
  parentId: string;
  parentRecord: any;
  apiUser: any;
  clients: any;
}

export function Activity({ parentLabel, parentRecord, parentId, users, apiUser, clients }: ActivityProps) {
  const [activityItems, setActivityItems] = useState<ActivityItem[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [commentValue, setCommentValue] = useState<any>(undefined);
  const [commentAreaKey, setCommentAreaKey] = useState<number>(0);
  const [isAddingComment, setIsAddingComment] = useState<boolean>(false);

  useEffect(() => {
    fetchActivityItems();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchActivityItems() {
    let activityItems;
    if (parentLabel === "Task") {
      activityItems = (
        await callGraphQLSimple({
          message: "Failed to get activity items",
          query: "listTaskActivityItemsByTask",
          variables: {
            taskId: parentId,
            limit: 1000,
          },
        })
      ).data?.listTaskActivityItemsByTask?.items;
    } else {
      activityItems = (
        await callGraphQLSimple({
          message: "Failed to get activity items",
          query: "listActivityItemsByParent",
          variables: {
            parentId,
            limit: 1000,
          },
        })
      ).data?.listActivityItemsByParent?.items;
    }

    setActivityItems([...activityItems].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)));
    setIsLoading(false);
  }

  return (
    <Card
      className="activity-tab"
      title={`${getLabel({
        id: parentLabel,
        defaultValue: parentLabel,
      })} activity`}
      actions={
        <>
          {!isAddingComment && (
            <Button
              type="primary"
              className="add-comment-button"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setIsAddingComment(true);
              }}
            >
              Add comment
            </Button>
          )}
        </>
      }
    >
      {isAddingComment && (
        <div className="comment-area" key={commentAreaKey}>
          <Textarea
            onChange={(value) => {
              setCommentValue(value);
            }}
            basicFormattingOnly
          />
          <div className="comment-buttons">
            <Button
              type="primary"
              className="submit-comment-button"
              onClick={async () => {
                if (parentLabel === "Task") {
                  await callGraphQLSimple({
                    message: "Failed to add comment",
                    mutation: "createTaskActivityItem",
                    variables: {
                      input: {
                        type: "COMMENT",
                        taskId: parentId,
                        content: commentValue,
                        organisation: parentRecord.organisation,
                        author: apiUser.id,
                      },
                    },
                  });
                } else if (parentLabel === "Project") {
                  await callGraphQLSimple({
                    message: "Failed to add comment",
                    mutation: "createActivityItem",
                    variables: {
                      input: {
                        type: "COMMENT",
                        parentId,
                        content: commentValue,
                        organisation: parentRecord.organisation,
                        author: apiUser.id,
                      },
                    },
                  });
                }
                message.success("Comment added");
                setIsAddingComment(false);
                setCommentAreaKey((commentAreaKey) => commentAreaKey + 1);
                fetchActivityItems();
              }}
            >
              Submit comment
            </Button>
            <Button
              className="cancel-comment-button"
              onClick={() => {
                setIsAddingComment(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}

      {isLoading ? (
        <Spin />
      ) : (
        activityItems?.map((activityItem: ActivityItem, index: number) => {
          return (
            <ActivityItemComponent
              activityItem={activityItem}
              users={users}
              index={index}
              key={activityItem.id}
              fetchActivityItems={fetchActivityItems}
              parentLabel={parentLabel}
              apiUser={apiUser}
              clients={clients}
            />
          );
        })
      )}
    </Card>
  );
}

export default withRouter(
  withSubscriptions({
    Component: Activity,
    subscriptions: ["users", "clients"],
  })
);
