import { useRef, useEffect } from "react";
import { useGetSetState } from "react-use";
import cx from "classnames";

import "./ZoomableNew.scss";

const INTERTIA_FRICTION_COEFFICIENT = 0.85;
const MIN_SCALE = 0.1;
const MAX_SCALE = 20;

function ZoomableNew({ children, className, onChange, defaultScale, defaultPosition }) {
  const [getState, setState] = useGetSetState({
    isPanning: false,
    position: { x: 0, y: 0 },
    scale: 1,
    velocity: { x: 0, y: 0 },
  });

  useEffect(() => {
    if (defaultScale) {
      setState({ scale: defaultScale });
    }
  }, [defaultScale]);

  useEffect(() => {
    if (defaultPosition) {
      setState({ position: defaultPosition });
    }
  }, [defaultPosition]);

  const lastPositionRef = useRef({ x: 0, y: 0 });
  const inertiaRef = useRef(null);
  const containerRef = useRef(null);
  const childrenContainerRef = useRef(null);

  const state = getState();

  useEffect(() => {
    if (onChange && typeof onChange === "function") {
      onChange({ position: state.position, scale: state.scale, isPanning: state.isPanning });
    }
  }, [state.position, state.scale]);

  useEffect(() => {
    const zoomableElement = containerRef.current;

    // Attach the event listener
    zoomableElement.addEventListener("wheel", handleWheel, { passive: false });

    // Clean up
    return () => {
      zoomableElement.removeEventListener("wheel", handleWheel);
    };
  }, []);

  function handleWheel(e) {
    e.stopPropagation();
    e.preventDefault();

    const { scale, position } = getState();

    const rect = containerRef.current.getBoundingClientRect();

    let adjustedZoomStep = (e.deltaY / 500) * scale; // without this, the zooming isn't linear

    const newScale = scale - adjustedZoomStep;

    const cursorX = (e.clientX - rect.left - position.x) / scale;
    const cursorY = (e.clientY - rect.top - position.y) / scale;

    const newScaleConstrained = Math.min(Math.max(MIN_SCALE, newScale), MAX_SCALE);
    const newPosition = {
      x: position.x - cursorX * (newScaleConstrained - scale),
      y: position.y - cursorY * (newScaleConstrained - scale),
    };

    setState({
      scale: newScaleConstrained,
      position: newPosition,
    });
  }

  function startPanning(e) {
    if (e.button === 1 || (e.button === 0 && e.altKey)) {
      lastPositionRef.current = { x: e.clientX, y: e.clientY };
      setState({ isPanning: true, velocity: { x: 0, y: 0 } });
      if (inertiaRef.current) {
        cancelAnimationFrame(inertiaRef.current);
      }
    }
  }

  function stopPanning() {
    setState({ isPanning: false });
    applyInertia();
  }

  function pan(e) {
    const { isPanning, position } = getState();
    if (!isPanning) return;

    const dx = e.clientX - lastPositionRef.current.x;
    const dy = e.clientY - lastPositionRef.current.y;

    setState({
      position: { x: position.x + dx, y: position.y + dy },
      velocity: { x: dx, y: dy },
    });
    lastPositionRef.current = { x: e.clientX, y: e.clientY };
  }

  function applyInertia() {
    function animate() {
      const { velocity, position } = getState();
      if (Math.abs(velocity.x) > 0.1 || Math.abs(velocity.y) > 0.1) {
        setState({
          position: {
            x: position.x + velocity.x,
            y: position.y + velocity.y,
          },
          velocity: {
            x: velocity.x * INTERTIA_FRICTION_COEFFICIENT,
            y: velocity.y * INTERTIA_FRICTION_COEFFICIENT,
          },
        });
        inertiaRef.current = requestAnimationFrame(animate);
      }
    }
    inertiaRef.current = requestAnimationFrame(animate);
  }

  const { position, scale, isPanning } = getState();

  let cursor = undefined;

  if (isPanning) {
    cursor = "grabbing";
  }

  return (
    <div
      className={cx("zoomable-new", className)}
      onMouseDown={startPanning}
      onMouseUp={stopPanning}
      onMouseLeave={stopPanning}
      onMouseMove={pan}
      style={{
        cursor,
        overflow: "hidden",
        width: "100%",
        height: "100%",
        position: "relative",
        "--zoomable-scale": scale,
      }}
      ref={containerRef}
    >
      <div
        className="zoomable-new-content"
        ref={childrenContainerRef}
        style={{
          position: "absolute",
          left: position.x,
          top: position.y,
          transform: `scale(${scale})`,
          transformOrigin: "0 0",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default ZoomableNew;
