import { roundToDecimals } from "common/mathHelpers";

export function getFeesForClient({ quote, organisationDetails, clientDetails, currency, includeCurrency = true }) {
  const fees = buildCompleteFeeList({ quote, organisationDetails });

  if (clientDetails?.fees) {
    clientDetails.fees.forEach((clientFee) => {
      const existingDefaultFeeIndex = fees.findIndex((x) => x.id === clientFee.id);
      if (existingDefaultFeeIndex !== -1) {
        fees[existingDefaultFeeIndex] = clientFee;
      } else {
        fees.push(clientFee);
      }
    });
  }

  return fees.map((fee) => {
    let result = {
      ...fee,
    };

    if (includeCurrency) {
      result.valueWithCurrency = getExternalFeeDisplay({
        quote,
        currency,
        fee,
        organisationDetails,
        includeCurrency: true,
      });
    }
    result.value = getExternalFeeDisplay({ quote, organisationDetails, currency, fee, includeCurrency: false });

    return result;
  });
}

function buildCompleteFeeList({ quote, organisationDetails }) {
  const fees = JSON.parse(JSON.stringify(quote?.defaultFees || []));
  for (let organisationFee of organisationDetails.defaultFees || []) {
    let organisationFeeId = organisationFee.id;
    if (!fees.some((x) => x.id === organisationFeeId)) {
      fees.push(JSON.parse(JSON.stringify(organisationFee)));
    }
  }
  return fees;
}

export function getExternalFeeDisplay({ quote, organisationDetails, currency, fee, includeCurrency = true }) {
  let value = fee.value;
  if (fee.type === "RELATIVE") {
    const fees = buildCompleteFeeList({ quote, organisationDetails });
    let existingFee = fees.find((x) => x.id === fee.id);
    if (existingFee) {
      value =
        (roundToDecimals(fee.defaultPercentage) / 100) * roundToDecimals(existingFee.value) +
        roundToDecimals(fee.defaultPlus);
    } else if (fee.defaultPlus > 0) {
      value = roundToDecimals(fee.defaultPlus);
    }
  }

  if (!includeCurrency) {
    return value;
  }

  return global.formatCurrency(currency, value);
}

export function getInternalFeeDisplay({ fee }) {
  if (fee.type === "RELATIVE") {
    let defaultPlus = "";
    if (fee.defaultPlus > 0) {
      if (window.organisationDetails?.settings?.general?.hideFinancials) {
        defaultPlus = ` + ${fee.defaultPlus}`;
      } else {
        defaultPlus = ` + ${window.formatCurrency("GBP", fee.defaultPlus)}`;
      }
    } else if (fee.defaultPlus < 0) {
      if (window.organisationDetails?.settings?.general?.hideFinancials) {
        defaultPlus = ` - ${Math.abs(fee.defaultPlus)}`;
      } else {
        defaultPlus = ` - ${window.formatCurrency("GBP", Math.abs(fee.defaultPlus))}`;
      }
    }

    let defaultPercentage = "";
    if (fee.defaultPercentage !== 100) {
      defaultPercentage = ` * ${roundToDecimals(fee.defaultPercentage / 100)}`;
    }

    return `default${defaultPercentage}${defaultPlus}`;
  }

  if (window.organisationDetails?.settings?.general?.hideFinancials) {
    return `${fee.value}`;
  } else {
    return window.formatCurrency("GBP", fee.value);
  }
}
