import { useState, useEffect } from "react";
import { Radio, Form, Modal, Button, Input, InputNumber, Select } from "antd";
import { useForceUpdate } from "common/helpers";

import "./FeeModal.scss";

type Props = {
  onClose?: any;
  onSubmit?: any;
  fee?: any;
  organisationDetails?: any;
  existingOverrides?: [any];
  overridesAllowed?: boolean;
};

export default function FeeModal({
  onClose,
  onSubmit,
  fee,
  organisationDetails,
  existingOverrides,
  overridesAllowed = true,
}: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    setTimeout(() => {
      let formInitialState = {
        id: fee && fee.id,
        label: fee && fee.label,
        value: fee && fee.value,
        type: fee ? fee.type || "ABSOLUTE" : "ABSOLUTE",
        defaultPercentage: fee ? fee.defaultPercentage || 100 : 100,
        defaultPlus: fee ? fee.defaultPlus || 0 : 0,
        feeOverride: "",
      };

      form.setFieldsValue(formInitialState);
      forceUpdate();
    }, 0);
  }, []); // eslint-disable-line

  // async function onSubmit({ label, value }) {
  //   setIsLoading(true);

  //   form.resetFields();
  //   setIsLoading(false);
  //   onClose();
  // }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={fee ? "Edit fee" : "Add fee"}
      visible={true}
      onCancel={onClose}
      footer={null}
      className="fee-modal"
    >
      <Form
        {...layout}
        form={form}
        onFinish={(params) => {
          setIsLoading(true);

          if (onSubmit && typeof onSubmit === "function") {
            let overrideFeeDetails = (organisationDetails.defaultFees || []).find((x) => x.id === params.overrideFeeId);
            if (params.overrideFeeId) {
              params.id = overrideFeeDetails.id;
              params.label = overrideFeeDetails.label;
            } else {
              if (fee) {
                params.id = fee.id;
              } else {
                params.id = params.label.split(" ").join("_").toUpperCase();
              }
            }
            onSubmit(params);
          }
        }}
      >
        {!overridesAllowed || fee ? null : (
          <Form.Item
            label="Override existing fee"
            name="overrideFeeId"
            rules={[
              {
                required: true,
                message: "You must choose a fee to override",
              },
            ]}
          >
            <Select
              data-cy="fee-override-dropdown"
              onChange={(value) => {
                form.setFieldsValue({ feeOverride: value });
                forceUpdate();
              }}
            >
              {(organisationDetails.defaultFees || []).map((crtFee) => {
                const existingOverride =
                  existingOverrides && existingOverrides.find((override) => override.id === crtFee.id);
                return (
                  <Select.Option key={crtFee.id} value={crtFee.id} disabled={existingOverride}>
                    {crtFee.label}
                    {existingOverride && " (already overriden)"}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}

        {form.getFieldValue("overrideFeeId") ? null : (
          <Form.Item
            label="Label"
            name="label"
            rules={[
              {
                required: true,
                message: "You need to specify a label",
              },
            ]}
          >
            <Input data-cy="fee-label-input" />
          </Form.Item>
        )}

        {overridesAllowed && (
          <Form.Item label="Fee type" name="type">
            <Radio.Group
              onChange={(e) => {
                form.setFieldsValue({ type: e.target.value });
                forceUpdate();
              }}
            >
              <Radio value="ABSOLUTE" data-cy="fee-type-absolute">
                absolute value
              </Radio>
              <Radio
                value="RELATIVE"
                data-cy="fee-type-relative"
                disabled={(fee && !fee.overrideFeeId) || (!fee && !form.getFieldValue("feeOverride"))}
              >
                relative to default{" "}
                {(fee && !fee.overrideFeeId) || (!fee && !form.getFieldValue("feeOverride")) ? " (needs override)" : ""}
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {form.getFieldValue("type") !== "RELATIVE" ? (
          <Form.Item
            label="Value"
            name="value"
            rules={
              form.getFieldValue("type") === "ABSOLUTE"
                ? [
                    {
                      required: true,
                      message: "You need to specify a value",
                    },
                  ]
                : undefined
            }
          >
            <InputNumber data-cy="fee-value-input" />
          </Form.Item>
        ) : null}

        {form.getFieldValue("type") === "RELATIVE" ? (
          <Form.Item
            label="Percentage of default"
            name="defaultPercentage"
            rules={
              form.getFieldValue("type") === "RELATIVE"
                ? [
                    {
                      required: true,
                      message: "You need to specify a value",
                    },
                  ]
                : undefined
            }
          >
            <InputNumber data-cy="fee-percentage-input" />
          </Form.Item>
        ) : null}

        {form.getFieldValue("type") === "RELATIVE" ? (
          <Form.Item
            label="Fixed amount to add to default"
            name="defaultPlus"
            rules={
              form.getFieldValue("type") === "RELATIVE"
                ? [
                    {
                      required: true,
                      message: "You need to specify a value",
                    },
                  ]
                : undefined
            }
          >
            <InputNumber data-cy="fee-add-to-default-input" />
          </Form.Item>
        ) : null}
        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="fee-submit-button">
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}
