import moment from "moment";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY, TIMELINE_COLORS } from "common/constants";

export function taskIdToColor(taskId, colorsToUse = TIMELINE_COLORS) {
  let charSum = 0;

  for (let i = 0; i < taskId.length; i++) {
    charSum += taskId.charCodeAt(i);
  }

  let color = colorsToUse[charSum % colorsToUse.length];

  return color;
}

export function getTimelineBlockBounds(block) {
  let startDate = moment(block.startDate);
  let endDate;
  let startHours = block.startHours;
  let endHours;

  if (block.startHours + block.durationHours < TIMELINE_DEFAULT_HOURS_IN_A_DAY) {
    endDate = moment(block.startDate);
    endHours = block.startHours + block.durationHours;
  } else if (block.startHours + block.durationHours === TIMELINE_DEFAULT_HOURS_IN_A_DAY) {
    endDate = moment(block.startDate).add(1, "day");
    endHours = 0;
  } else {
    let hoursInFirstDay = TIMELINE_DEFAULT_HOURS_IN_A_DAY - block.startHours;
    let hoursAfterFirstDay = block.durationHours - hoursInFirstDay;

    endDate = moment(block.startDate).startOf("day");
    let daysAfterFirstDay = hoursAfterFirstDay / TIMELINE_DEFAULT_HOURS_IN_A_DAY;
    endDate.add(Math.ceil(daysAfterFirstDay), "days");

    endHours = hoursAfterFirstDay % TIMELINE_DEFAULT_HOURS_IN_A_DAY;
    if (daysAfterFirstDay === parseInt(daysAfterFirstDay)) {
      endHours = TIMELINE_DEFAULT_HOURS_IN_A_DAY;
    }
  }

  return {
    ...block,
    endDate,
    endHours,
    startDate,
    startHours,
    startDatePseudoTime: startDate.format("YYYY-MM-DD") + "T" + startHours + ":00:00.000Z",
    endDatePseudoTime: endDate.format("YYYY-MM-DD") + "T" + endHours + ":00:00.000Z",
    originalStartDatePseudoTime:
      moment(block.originalStartDate).format("YYYY-MM-DD") + "T" + block.originalStartHours + ":00:00.000Z",
  };
}

export function add1DPointsToBlock({ block, axisStartDate }) {
  const enhancedBlock = getTimelineBlockBounds(block);
  const { startDate, startHours, endDate, endHours } = enhancedBlock;

  let start1D = dateAndHoursToValue1D({ date: startDate, hours: startHours, axisStartDate });
  let end1D = dateAndHoursToValue1D({ date: endDate, hours: endHours, axisStartDate });
  return {
    ...enhancedBlock,
    start1D,
    end1D,
  };
}

export function dateAndHoursToValue1D({ date, hours, axisStartDate }) {
  if (!axisStartDate) {
    throw new Error("You must provide an axisStartDate");
  }
  return moment(date).diff(axisStartDate, "days") * TIMELINE_DEFAULT_HOURS_IN_A_DAY + hours;
}

export function value1DToDateAndHours({ value1D, axisStartDate }) {
  if (!axisStartDate) {
    throw new Error("You must provide an axisStartDate");
  }

  let date = moment(axisStartDate).add(Math.floor(value1D / TIMELINE_DEFAULT_HOURS_IN_A_DAY), "days");
  let hours = value1D % TIMELINE_DEFAULT_HOURS_IN_A_DAY;
  // if (value1D > 0 && hours === 0) {
  //   hours = TIMELINE_DEFAULT_HOURS_IN_A_DAY;
  //   date = moment(date).subtract(1, "day");
  // }
  return {
    date: date.format("YYYY-MM-DD"),
    hours,
  };
}
