/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://j6q6ummvh5eknixzx4s2xmqhgm.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "rest",
            "endpoint": "https://rudfuy4ob3.execute-api.eu-west-2.amazonaws.com/annelise",
            "region": "eu-west-2"
        },
        {
            "name": "restPublic",
            "endpoint": "https://edsb2vk5pa.execute-api.eu-west-2.amazonaws.com/annelise",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:573d2b68-e921-41d4-b854-ac7553de0bd7",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_0lWphkILq",
    "aws_user_pools_web_client_id": "37euf9oks6jti4na80tsbbj3ii",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "ADDRESS",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "draughthub-projects150006-annelise",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
