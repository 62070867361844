import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Form, Modal, Button, Radio, Input } from "antd";

import withSubscriptions from "common/withSubscriptions";
import {
  getUppercaseStatus,
  pointSheetsToLatestFileVersion,
  createFileVersionInApi,
  createSheetRevisionInApi,
  copyS3MainFile,
  isFileOpen,
  showFileIsOpenModal,
} from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSheetRevisionName } from "common/naming";
import { getLatestFileVersion, getLatestRevision, HAS_SHEETS } from "common/shared";

import Explanation from "Explanation/Explanation";

import "./CreateSheetRevisionModal.scss";

export function CreateSheetRevisionModal({
  onClose,
  apiUser,
  task,
  sheet,
  history,
  file,
  taskRevision,
  organisationDetails,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestedName, setSuggestedName] = useState();
  const [form] = Form.useForm();

  let predefinedStatus;
  if (sheet) {
    predefinedStatus = sheet.revisions.items.slice(-1)[0].status;
  } else if (file) {
    predefinedStatus = file.sheets.items[0].revisions.items.slice(-1)[0].status;
  } else if (taskRevision) {
    let firstFile = taskRevision.files.items[0];
    predefinedStatus = firstFile.sheets.items[0].revisions.items.slice(-1)[0].status;
  }
  const [status, setStatus] = useState(predefinedStatus);
  if (!taskRevision) {
    taskRevision = getLatestRevision(task);
  }

  useEffect(() => {
    form.setFieldsValue({ status });
  }, []);

  useEffect(() => {
    let firstSheetInSet;
    if (sheet) {
      firstSheetInSet = sheet;
    } else if (file) {
      firstSheetInSet = file.sheets.items[0];
    } else if (taskRevision) {
      let firstFile = taskRevision.files.items[0];
      firstSheetInSet = firstFile.sheets.items[0];
    }

    getSheetRevisionName({
      organisation: task.organisation,
      sheet: firstSheetInSet,
      newStatus: status,
      task,
    }).then((name) => {
      setSuggestedName(name);
    });
  }, [status]); // eslint-disable-line

  async function updateFileAndPointSheetsToLatestVersion({ file, oldFileVersion, newFileVersion }) {
    const updatedFile = (
      await callGraphQLSimple({
        message: "Could not retrieve file",
        queryName: "getFile",
        variables: {
          id: file.id,
        },
      })
    ).data.getFile;

    await pointSheetsToLatestFileVersion({
      apiUser,
      task,
      file: updatedFile,
      taskRevision,
    });

    await callGraphQLSimple({
      message: "Could not update file",
      queryName: "updateFile",
      variables: {
        input: {
          id: file.id,
          randomNumber: Math.floor(Math.random() * 100000),
        },
      },
    });

    // each time we create a sheet revision, we create a copy of the actual file
    await copyS3MainFile({
      history,
      task,
      apiUser,
      taskRevision,
      file: updatedFile,
      oldVersionNumber: oldFileVersion.versionNumber,
      newVersionNumber: newFileVersion.versionNumber,
      extension: file.extension,
      doPublish: true,
      oldFileVersion,
      newFileVersion,
    });
  }

  async function processFiles({ files, customName, description, status }) {
    let filePromises = [];
    for (let file of files) {
      filePromises.push(
        new Promise(async (resolve, reject) => {
          const oldFileVersion = getLatestFileVersion(file);

          const newFileVersionNumber = oldFileVersion.versionNumber + 1;

          let newFileVersion = (
            await createFileVersionInApi({
              file,
              task,
              taskRevision,
              apiUser,
              versionNumber: newFileVersionNumber,
            })
          ).data.createFileVersion;

          let targetSheets = file.targetSheets || file.sheets.items;

          let sheetPromises = [];
          for (let sheet of targetSheets) {
            sheetPromises.push(
              new Promise(async (resolve, reject) => {
                let sheetName = customName;
                if (!sheetName) {
                  sheetName = await getSheetRevisionName({
                    organisation: task.organisation,
                    file,
                    sheet,
                    task,
                    newStatus: status,
                  });
                }

                await createSheetRevisionInApi({
                  apiUser,
                  task,
                  sheet,
                  file,
                  fileVersion: newFileVersion,
                  taskRevision,
                  status: getUppercaseStatus(status),
                  name: sheetName,
                  description,
                });
                resolve();
              })
            );
          }

          await Promise.all(sheetPromises);

          await updateFileAndPointSheetsToLatestVersion({
            file,
            oldFileVersion,
            newFileVersion,
          });
          resolve();
        })
      );
    }

    await Promise.all(filePromises);
  }

  async function onSubmit({ customName, description, status }) {
    if (!sheet && !file && !taskRevision) {
      return;
    }

    let fileIsOpen = false;
    if (file) {
      fileIsOpen = await isFileOpen({ task, file });
    }
    if (fileIsOpen) {
      showFileIsOpenModal(file);
      return;
    }

    setIsLoading(true);

    let files;
    if (sheet) {
      files = [{ ...file, targetSheets: [sheet] }];
    } else if (file) {
      files = [file];
    } else if (taskRevision) {
      files = taskRevision.files.items;
    }

    await processFiles({
      files,
      customName,
      description,
      status,
    });

    setIsLoading(false);
    onClose();
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  let modalTitle = "Create revision";
  let suggestedNameExplanation = null;
  let customNameExplanation = null;

  if (sheet) {
    modalTitle = `Create revision for sheet ${sheet.name}`;
    customNameExplanation = (
      <span style={{ marginLeft: "0.5rem" }}>
        <Explanation
          title={"If you choose a custom name, that name will be used instead of the suggested one."}
          placement="right"
        />
      </span>
    );
  } else if (file) {
    if (HAS_SHEETS[file.type]) {
      modalTitle = `Create revision for all sheets in file`;
      suggestedNameExplanation = (
        <span style={{ marginLeft: "0.5rem" }}>
          <Explanation
            title={
              "If you go with the suggested name, DraughtHub will look at each sheet in the file and generate an appropriate name for the new revision of that sheet. The suggested name displayed is only the suggestion for the first sheet in the file."
            }
            placement="right"
          />
        </span>
      );
      customNameExplanation = (
        <span style={{ marginLeft: "0.5rem" }}>
          <Explanation
            title={
              "If you choose a custom name, that name will be used for each sheet in the file, instead of the suggested one."
            }
            placement="right"
          />
        </span>
      );
    } else {
      modalTitle = `Create revision for file`;
    }
  } else {
    modalTitle = `Create revision for all sheets and files`;
    suggestedNameExplanation = (
      <span style={{ marginLeft: "0.5rem" }}>
        <Explanation
          title={
            "If you go with the suggested name, DraughtHub will look at each sheet in each file and generate an appropriate name for the revision of that sheet. The suggested name displayed is only the suggestion for the first sheet in the first file."
          }
          placement="right"
        />
      </span>
    );
    customNameExplanation = (
      <span style={{ marginLeft: "0.5rem" }}>
        <Explanation
          title={
            "If you choose a custom name, that name will be used for each sheet in each file, instead of the suggested one."
          }
          placement="right"
        />
      </span>
    );
  }

  return (
    <Modal
      maskClosable={false}
      title={modalTitle}
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-sheet-revision-modal"
    >
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item label={<>Suggested name{suggestedNameExplanation}</>} name="suggestedName">
          <span>{suggestedName}</span>
        </Form.Item>
        <Form.Item
          label={<>Custom name (optional) {customNameExplanation}</>}
          name="customName"
          rules={[
            {
              validator: async (_, name) => {
                if (!name) {
                  return;
                }

                let nameIsAlreadyUsed = false;
                if (sheet) {
                  sheet.revisions.items.forEach((revision) => {
                    if (revision.name === name) {
                      nameIsAlreadyUsed = true;
                    }
                  });
                } else {
                  file.sheets.items.forEach((sheet) => {
                    sheet.revisions.items.forEach((revision) => {
                      if (revision.name === name) {
                        nameIsAlreadyUsed = true;
                      }
                    });
                  });
                }

                if (nameIsAlreadyUsed) {
                  throw new Error(`This name already used`);
                }
              },
            },
          ]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status"
          rules={[
            {
              required: true,
              message: "You must choose a status",
            },
          ]}
        >
          <Radio.Group
            className="sheet-revision-status-list"
            onChange={(e) => {
              form.setFieldsValue({ status: e.target.value });
              setStatus(e.target.value);
            }}
            value={status}
          >
            {organisationDetails.fileStatuses.map((status) => {
              return (
                <Radio value={status.name.toUpperCase().split(" ").join("_")} key={status.name}>
                  {status.name}
                </Radio>
              );
            })}
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default withRouter(
  withSubscriptions({
    Component: CreateSheetRevisionModal,
    subscriptions: ["organisationDetails"],
  })
);
