import moment from "moment";

import { getSimpleLabel } from "common/labels";
import getOrderedActiveUsers from "common/getOrderedActiveUsers";

import Avatar from "Avatar/Avatar";

export function getUserOptions({ organisationDetails, users, holidays, tasks, startDate, endDate, apiUser, taskId }) {
  let orderedActiveUsers = getOrderedActiveUsers({ users, organisationDetails, apiUser });

  let optionsForMultipleAssignedUsers = [];

  if (organisationDetails?.settings?.task?.allowMultipleUsersToBeAssignedToATask) {
    let tasksWithOverlappingDates;

    if (startDate && endDate) {
      tasksWithOverlappingDates = tasks.filter((task) => {
        if (task.id === taskId) {
          return false;
        }
        return (
          moment(task.startDate).isSameOrBefore(endDate, "day") && moment(task.endDate).isSameOrAfter(startDate, "day")
        );
      });
    }

    optionsForMultipleAssignedUsers = orderedActiveUsers.map((user, index) => {
      let isDisabled = false;
      let holidaysForUser = holidays.filter((holiday) => holiday.userId === user.id);
      let extraMessages = [];
      if (startDate && endDate) {
        let conflictingHolidays = holidaysForUser.filter((holiday) => {
          return holiday.days.some(({ day }) => {
            return moment(day).isBetween(startDate, endDate, "day", "[]");
          });
        });
        if (conflictingHolidays.length > 0) {
          isDisabled = true;
          let userIsSickInInterval = conflictingHolidays.some((holiday) => holiday.isSick);
          let userIsOnHolidayInInterval = conflictingHolidays.some((holiday) => !holiday.isSick);
          if (userIsSickInInterval) {
            extraMessages.push("Sick");
          }

          if (userIsOnHolidayInInterval) {
            extraMessages.push("On holiday");
          }
        }

        let userIsAlreadyAssignedToTaskInInterval = tasksWithOverlappingDates?.some((task) => {
          return task.assignedTo === user.id || task.assignedToUsers?.includes(user.id);
        });

        if (userIsAlreadyAssignedToTaskInInterval) {
          isDisabled = true;
          extraMessages.push(`Assigned to another ${getSimpleLabel("task")}`);
        }
      }

      let label = (
        <div className="multiple-assignee-user-item">
          <Avatar user={user} key={index} showLabel disabled={isDisabled} />
          <div className="extra-messages">{extraMessages.join(", ")}</div>
        </div>
      );

      return {
        label,
        value: user.id,
        disabled: isDisabled,
      };
    });
  }

  return optionsForMultipleAssignedUsers;
}
