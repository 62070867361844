import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Typography, Input, Checkbox, Dropdown, Select, Menu, Switch } from "antd";
import query from "query-string";
import { PlusCircleOutlined, SearchOutlined, DownOutlined } from "@ant-design/icons";
import moment from "moment";

import withSubscriptions from "common/withSubscriptions";
import { getMonthlyDateRangePresets, getLabel } from "common/helpers";
import { COOKIE_NAME_PURCHASE_ORDER_FILTERS } from "common/constants";
import { isAuthorised } from "common/permissions";

import DatePicker from "DatePicker/DatePicker";
import AvatarList from "AvatarList/AvatarList";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";
import CreatePurchaseOrderModal from "Modals/CreatePurchaseOrderModal/CreatePurchaseOrderModal";

import "./PurchaseOrderFilters.scss";

const COMBINED_FILTERS_BREAKPOINT = 1080;

export class PurchaseOrderFilters extends React.Component {
  state = {
    containsText: "",
    selectedUsers: [],
    supplierId: null,
    projectId: null,
    createdAtStart: null,
    createdAtEnd: null,
    includeActive: true,
    includeArchived: false,
    onlyReviewsRequested: false,
    onlyReviewsAssigned: false,
    onlyReviewsThatNeedAction: false,
    onlyPurchaseOrdersWithoutReview: false,
    onlyPurchaseOrdersInReview: false,
    onlyReviewedPurchaseOrders: false,
    isDropdownVisible: false,
    isCreatePurchaseOrderModalVisible: false,
    includeWithoutPONumber: true,
  };

  async componentDidMount() {
    const { location } = this.props;

    const queryParams = query.parse(location.search);
    let newState = {};

    const cookieValue = await window.localDatabase.getItem(
      `${COOKIE_NAME_PURCHASE_ORDER_FILTERS}-${this.props.organisationDetails.id}`
    );

    if (cookieValue) {
      try {
        const cookieValueParsed = JSON.parse(cookieValue);
        const { isDropdownVisible, isCreatePurchaseOrderModalVisible, ...existingFilters } = cookieValueParsed;
        newState = existingFilters;
      } catch (e) {
        console.error("Failed to parse local database value for PurchaseOrderFilters:", cookieValue);
      }
    }

    if (queryParams.containsText) {
      newState.containsText = queryParams.containsText;
    }

    if (queryParams.selectedUsers) {
      if (Array.isArray(queryParams.selectedUsers)) {
        newState.selectedUsers = queryParams.selectedUsers;
      } else {
        newState.selectedUsers = [queryParams.selectedUsers];
      }
    }

    if (newState.selectedUsers) {
      // eliminate users that are not found in the users array
      newState.selectedUsers = newState.selectedUsers.filter((userId) =>
        this.props.users.find((user) => user.id === userId)
      );
    }

    if (queryParams.includeWithoutPONumber) {
      newState.includeWithoutPONumber = queryParams.includeWithoutPONumber === "true";
    }

    if (queryParams.includeArchived) {
      newState.includeArchived = queryParams.includeArchived === "true";
    }

    if (queryParams.onlyReviewsRequested) {
      newState.onlyReviewsRequested = queryParams.onlyReviewsRequested === "true";
    }

    if (queryParams.onlyPurchaseOrdersInReview) {
      newState.onlyPurchaseOrdersInReview = queryParams.onlyPurchaseOrdersInReview === "true";
    }

    if (queryParams.onlyReviewedPurchaseOrders) {
      newState.onlyReviewedPurchaseOrders = queryParams.onlyReviewedPurchaseOrders === "true";
    }

    if (queryParams.onlyReviewsAssigned) {
      newState.onlyReviewsAssigned = queryParams.onlyReviewsAssigned === "true";
    }

    if (queryParams.onlyReviewsThatNeedAction) {
      newState.onlyReviewsThatNeedAction = queryParams.onlyReviewsThatNeedAction === "true";
    }

    if (queryParams.onlyPurchaseOrdersWithoutReview) {
      newState.onlyPurchaseOrdersWithoutReview = queryParams.onlyPurchaseOrdersWithoutReview === "true";
    }

    if (queryParams.supplierId) {
      newState.supplierId = queryParams.supplierId;
    }

    if (queryParams.projectId) {
      newState.projectId = queryParams.projectId;
    }

    this.setState(newState, this.onChange);
  }

  changeDateFilterRange = (dates, datesStr) => {
    let createdAtStart = null;
    let createdAtEnd = null;
    if (dates) {
      createdAtStart = datesStr[0];
      createdAtEnd = datesStr[1];
    }

    this.setState(
      {
        createdAtStart,
        createdAtEnd,
      },
      this.onChange
    );
  };

  onChange = () => {
    const {
      selectedUsers,
      containsText,
      supplierId,
      projectId,
      createdAtStart,
      createdAtEnd,
      includeActive,
      includeArchived,
      includeWithoutPONumber,
      onlyReviewsRequested,
      onlyReviewsAssigned,
      onlyReviewsThatNeedAction,
      onlyPurchaseOrdersWithoutReview,
      onlyPurchaseOrdersInReview,
      onlyReviewedPurchaseOrders,
    } = this.state;

    const { isCreatePurchaseOrderModalVisible, isDropdownVisible, ...stateForQueryParams } = this.state;

    const { history, location, apiUser } = this.props;

    const nonNullKeys = Object.keys(stateForQueryParams).filter(
      (keyName) =>
        stateForQueryParams[keyName] !== null &&
        stateForQueryParams[keyName] !== undefined &&
        stateForQueryParams[keyName] !== "" &&
        stateForQueryParams[keyName] !== false
    );
    const queryParams = {};
    nonNullKeys.forEach((keyName) => {
      queryParams[keyName] = stateForQueryParams[keyName];
    });
    const queryString = query.stringify(queryParams);

    history.replace(`${location.pathname}?${queryString}`);

    let filter = {
      callbacks: [],
    };

    if (!includeActive) {
      filter.callbacks.push((purchaseOrder) => purchaseOrder.isArchived);
    }

    if (!includeWithoutPONumber) {
      filter.callbacks.push((purchaseOrder) => purchaseOrder.poNumber);
    }

    if (!includeArchived) {
      filter.callbacks.push((purchaseOrder) => !purchaseOrder.isArchived);
    }

    if (supplierId) {
      filter.callbacks.push((purchaseOrder) => {
        return purchaseOrder.supplierId === supplierId;
      });
    }

    if (projectId) {
      filter.callbacks.push((purchaseOrder) => {
        return purchaseOrder.projectId === projectId;
      });
    }

    if (selectedUsers && selectedUsers.length > 0) {
      filter.callbacks.push((task) => {
        if (selectedUsers.includes("unassigned") && !task.assignedTo) {
          return true;
        } else if (selectedUsers.includes(task.assignedTo)) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (containsText) {
      filter.containsText = {
        contains: (containsText || "").toLowerCase(),
      };
    }

    if (onlyReviewsRequested) {
      filter.callbacks.push((purchaseOrder) => purchaseOrder.author === apiUser.id && purchaseOrder.isUnderReview);
    }

    if (onlyReviewsAssigned) {
      filter.callbacks.push((purchaseOrder) => {
        return purchaseOrder.checkedBy === apiUser.id && purchaseOrder.isUnderReview;
      });
    }

    if (onlyReviewsThatNeedAction) {
      filter.callbacks.push((purchaseOrder) => {
        if (
          purchaseOrder.isUnderReview &&
          (purchaseOrder.reviewStatus === "IN_PROGRESS" || purchaseOrder.reviewSecondaryStatus) &&
          purchaseOrder.checkedBy === apiUser.id
        ) {
          return true;
        }

        return false;
      });
    }

    if (onlyPurchaseOrdersWithoutReview) {
      filter.callbacks.push((purchaseOrder) => !purchaseOrder.reviewApprovedAt);
    }

    if (onlyPurchaseOrdersInReview) {
      filter.callbacks.push((purchaseOrder) => purchaseOrder.isUnderReview);
    }

    if (onlyReviewedPurchaseOrders) {
      filter.callbacks.push((purchaseOrder) => {
        return purchaseOrder.reviewApprovedAt;
      });
    }

    if (createdAtStart && createdAtEnd) {
      filter.callbacks.push((purchaseOrder) => {
        return (
          moment(createdAtStart, "DD-MM-YYYY").isSameOrBefore(purchaseOrder.createdAt) &&
          moment(createdAtEnd, "DD-MM-YYYY").isSameOrAfter(purchaseOrder.createdAt)
        );
      });
    }

    window.localDatabase.setItem(
      `${COOKIE_NAME_PURCHASE_ORDER_FILTERS}-${this.props.organisationDetails.id}`,
      JSON.stringify(this.state)
    );

    this.props.onChange(filter);
  };

  onUserSelected = (_, user) => {
    if (!user) {
      user = { id: "unassigned" };
    }
    let selectedUsers = this.state.selectedUsers || [];
    if (selectedUsers.includes(user.id)) {
      selectedUsers = selectedUsers.filter((x) => x !== user.id);
    } else {
      selectedUsers.push(user.id);
    }
    this.setState({ selectedUsers }, this.onChange);
  };

  render() {
    const {
      containsText,
      selectedUsers,
      includeArchived,
      includeActive,
      includeWithoutPONumber,
      isCreatePurchaseOrderModalVisible,
      isDropdownVisible,
      onlyReviewsRequested,
      onlyReviewsAssigned,
      onlyReviewsThatNeedAction,
      onlyPurchaseOrdersInReview,
      onlyReviewedPurchaseOrders,
      onlyPurchaseOrdersWithoutReview,
      supplierId,
      projectId,
    } = this.state;

    const { projects, suppliers, orderedActiveUsers, apiUser, purchaseOrders, windowWidth, windowHeight } = this.props;

    let dateRangePresets = getMonthlyDateRangePresets();
    let mobileFilters = null;
    if (windowWidth <= COMBINED_FILTERS_BREAKPOINT) {
      mobileFilters = (
        <>
          <Menu.Item>
            <Input
              className="filter-input"
              placeholder="Filter purchase order list"
              allowClear
              prefix={<SearchOutlined />}
              onChange={(e) => this.setState({ containsText: e.target.value }, this.onChange)}
              value={containsText}
            />
          </Menu.Item>
          <Menu.Item>
            <AvatarList
              users={orderedActiveUsers}
              onClick={this.onUserSelected}
              selectedUsers={selectedUsers}
              containerWidthToSubtract={800}
            />
          </Menu.Item>
        </>
      );
    }

    let createdAtStart = null;
    let createdAtEnd = null;
    if (
      this.state.createdAtStart &&
      this.state.createdAtEnd &&
      typeof this.state.createdAtStart === "string" &&
      typeof this.state.createdAtEnd === "string" &&
      this.state.createdAtStart.length <= 10 &&
      this.state.createdAtEnd.length <= 10
    ) {
      createdAtStart = moment(this.state.createdAtStart, "DD-MM-YYYY");
      createdAtEnd = moment(this.state.createdAtEnd, "DD-MM-YYYY");
    }

    const filterMenu = (
      <Menu>
        {mobileFilters}
        <Menu.Item key="include-active">
          <Checkbox
            checked={includeActive}
            onChange={(e) => this.setState({ includeActive: e.target.checked }, this.onChange)}
          >
            Include active
          </Checkbox>
        </Menu.Item>

        <Menu.Item key="include-archived">
          <Checkbox
            checked={includeArchived}
            onChange={(e) => this.setState({ includeArchived: e.target.checked }, this.onChange)}
          >
            Include archived
          </Checkbox>
        </Menu.Item>

        <Menu.Item key="include-without-po-number">
          <Checkbox
            checked={includeWithoutPONumber}
            onChange={(e) => this.setState({ includeWithoutPONumber: e.target.checked }, this.onChange)}
          >
            Include without PO number
          </Checkbox>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item key="created-in-range">
          <div className="created-in-range-container">
            <Typography.Text className="top-label">Created in range:</Typography.Text>
            <br />
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              dropdownClassName="task-created-date-range-picker"
              value={[
                typeof createdAtStart === "string" ? moment(createdAtStart) : createdAtStart,
                typeof createdAtEnd === "string" ? moment(createdAtEnd) : createdAtEnd,
              ]}
              onChange={this.changeDateFilterRange}
              ranges={dateRangePresets}
            />
          </div>
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item key="reviews-i-need-to-action">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={onlyReviewsThatNeedAction}
            onChange={(checked) => {
              let newState = { onlyReviewsThatNeedAction: checked };
              if (checked) {
                newState.onlyReviewsAssigned = true;
                newState.onlyReviewsRequested = false;
                newState.onlyPurchaseOrdersInReview = false;
                newState.onlyPurchaseOrdersWithoutReview = false;
                newState.onlyReviewedPurchaseOrders = false;
              }
              this.setState(newState, this.onChange);
            }}
          />
          <Typography.Text className="right-label">
            Only reviews I need to <br />
            take action on
          </Typography.Text>
        </Menu.Item>
        <Menu.Item key="what-i-need-to-review">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={onlyReviewsAssigned}
            onChange={(checked) => {
              let newState = { onlyReviewsAssigned: checked };
              if (checked) {
                newState.onlyReviewsRequested = false;
                newState.onlyPurchaseOrdersInReview = false;
                newState.onlyPurchaseOrdersWithoutReview = false;
                newState.onlyReviewedPurchaseOrders = false;
              } else {
                newState.onlyReviewsThatNeedAction = false;
              }
              this.setState(newState, this.onChange);
            }}
          />
          <Typography.Text className="right-label">Only where I am the reviewer</Typography.Text>
        </Menu.Item>
        <Menu.Item key="reviews-i-requested">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={onlyReviewsRequested}
            onChange={(checked) => {
              let newState = { onlyReviewsRequested: checked };
              if (checked) {
                newState.onlyReviewsThatNeedAction = false;
                newState.onlyReviewsAssigned = false;
                newState.onlyPurchaseOrdersInReview = false;
                newState.onlyPurchaseOrdersWithoutReview = false;
                newState.onlyReviewedPurchaseOrders = false;
              }
              this.setState(newState, this.onChange);
            }}
          />
          <Typography.Text className="right-label">Only reviews I requested</Typography.Text>
        </Menu.Item>
        <Menu.Item key="without-successful-review">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={onlyPurchaseOrdersWithoutReview}
            onChange={(checked) => {
              let newState = { onlyPurchaseOrdersWithoutReview: checked };
              if (checked) {
                newState.onlyReviewsThatNeedAction = false;
                newState.onlyReviewsAssigned = false;
                newState.onlyPurchaseOrdersInReview = false;
                newState.onlyReviewsRequested = false;
                newState.onlyReviewedPurchaseOrders = false;
              }
              this.setState(newState, this.onChange);
            }}
          />
          <Typography.Text className="right-label">Only purchase orders without a successful review</Typography.Text>
        </Menu.Item>

        <Menu.Item key="purchaseOrders-fully-reviewed">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={onlyReviewedPurchaseOrders}
            onChange={(checked) => {
              let newState = { onlyReviewedPurchaseOrders: checked };
              if (checked) {
                newState.onlyReviewsThatNeedAction = false;
                newState.onlyReviewsAssigned = false;
                newState.onlyPurchaseOrdersInReview = false;
                newState.onlyReviewsRequested = false;
                newState.onlyPurchaseOrdersWithoutReview = false;
              }
              this.setState(newState, this.onChange);
            }}
          />
          <Typography.Text className="right-label">Only purchase orders that are reviewed</Typography.Text>
        </Menu.Item>

        <Menu.Item key="all-under-review">
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={onlyPurchaseOrdersInReview}
            onChange={(checked) => {
              let newState = { onlyPurchaseOrdersInReview: checked };
              if (checked) {
                newState.onlyReviewsThatNeedAction = false;
                newState.onlyReviewsRequested = false;
                newState.onlyReviewsAssigned = false;
                newState.onlyReviewedPurchaseOrders = false;
              }
              this.setState(newState, this.onChange);
            }}
          />
          <Typography.Text className="right-label">All under review</Typography.Text>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item className="flex-menu-item" key="supplier">
          <Typography.Text className="left-label">Supplier:</Typography.Text>

          <Select
            showSearch
            allowClear
            placeholder="Choose a supplier"
            className="purchase-order-filter-supplier"
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={(supplier) => {
              this.setState({ supplier }, this.onChange);
            }}
            value={supplierId}
          >
            {suppliers.map((supplier, i) => (
              <Select.Option key={i} value={supplier.id}>
                {supplier.name}
              </Select.Option>
            ))}
          </Select>
        </Menu.Item>
        <Menu.Item className="flex-menu-item" key="project">
          <Typography.Text className="left-label">
            {getLabel({
              id: "Project",
              defaultValue: "Project",
            })}
            :
          </Typography.Text>

          <Select
            showSearch
            allowClear
            placeholder={`Choose a ${getLabel({
              id: "project",
              defaultValue: "project",
            })}`}
            className="purchase-order-filter-project"
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={(projectId) => {
              this.setState({ projectId }, this.onChange);
            }}
            value={projectId}
          >
            {projects
              .filter((project) => !project.isArchived && !project.id.includes("TEMPLATES"))
              .map((project, i) => (
                <Select.Option key={i} value={project.id}>
                  {project.title}
                </Select.Option>
              ))}
          </Select>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="purchase-order-filters">
        {windowWidth > COMBINED_FILTERS_BREAKPOINT && (
          <>
            <Input
              className="filter-input"
              placeholder="Filter purchase order list"
              allowClear
              prefix={<SearchOutlined />}
              onChange={(e) => this.setState({ containsText: e.target.value }, this.onChange)}
              value={containsText}
            />
            <AvatarList
              users={orderedActiveUsers}
              onClick={this.onUserSelected}
              selectedUsers={selectedUsers}
              containerWidthToSubtract={800}
            />
          </>
        )}
        <Dropdown
          trigger={["click"]}
          overlayClassName="purchase-order-filters-dropdown-overlay"
          overlay={filterMenu}
          open={isDropdownVisible}
          onVisibleChange={(isDropdownVisible) => this.setState({ isDropdownVisible })}
        >
          <Button className="filter-button">
            Filters
            <DownOutlined />
          </Button>
        </Dropdown>
        {isAuthorised(["EXPORT_PURCHASE_ORDERS"]) && (
          <Button className="export" onClick={this.props.export} data-cy="export-to-csv-button">
            Export to CSV
          </Button>
        )}

        {this.props.includeCreatePurchaseOrder === false ? null : (
          <div className="create-buttons">
            <ButtonWithPermissions
              type="primary"
              className="create-purchaseOrder"
              onClick={() => this.setState({ isCreatePurchaseOrderModalVisible: true })}
              data-cy="create-purchase-order-button"
              permissions={["CREATE_PURCHASE_ORDER"]}
            >
              <PlusCircleOutlined /> Create purchase order
            </ButtonWithPermissions>
          </div>
        )}

        {isCreatePurchaseOrderModalVisible && (
          <CreatePurchaseOrderModal
            onClose={() => this.setState({ isCreatePurchaseOrderModalVisible: false })}
            apiUser={apiUser}
            purchaseOrders={purchaseOrders}
          />
        )}
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: PurchaseOrderFilters,
    subscriptions: ["organisationDetails", "users", "suppliers", "projects"],
  })
);
