const fields = [
  {
    id: "objectText",
    fieldTypes: ["textfield"],
    label: "Object text",
    value: (params) => {
      let object = params.object;

      if (object.text === undefined || object.text === null || object.text === "") {
        return "";
      }

      // if object text is a number, return it as a proper number
      if (!isNaN(object.text)) {
        return parseFloat(object.text);
      }

      return object.text;
    },
  },
];

export function getFields() {
  let extraFields = [];

  return [...fields, ...extraFields];
}

export const label = "Object";
