import { useEffect } from "react";
import { useGetSetState } from "react-use";
import { Spin } from "antd";
import { withRouter } from "react-router-dom";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";

import RequestContent from "./RequestContent/RequestContent";
import RequestSidebar from "./RequestSidebar/RequestSidebar";

import "./RequestDetailsPage.scss";

interface Props {
  organisationDetails: any;
  apiUser: any;
  request: any;
  users: any;
  projects: any[];
  clients: any[];
  setBoxedLayout: (value) => {};
}

interface ContentProps extends Props {
  activityItems: any[];
}

type State = {
  activityItems: any[] | undefined;
};

export function RequestsDetailsPageContent({
  organisationDetails,
  apiUser,
  request,
  activityItems,
  users,
  projects,
  clients,
  setBoxedLayout,
}: ContentProps) {
  useEffect(() => {
    setBoxedLayout(false);
    return () => {
      setBoxedLayout(true);
    };
  }, []);
  return (
    <div className="request-details-page">
      <div className="request-details-page-inner-content">
        {activityItems && (
          <RequestContent
            request={request}
            activityItems={activityItems}
            users={users}
            apiUser={apiUser}
            organisationDetails={organisationDetails}
          />
        )}
        <RequestSidebar
          users={users}
          request={request}
          organisationDetails={organisationDetails}
          apiUser={apiUser}
          activityItems={activityItems}
          projects={projects}
          clients={clients}
        />
      </div>
    </div>
  );
}

export function RequestDetailsPage({
  organisationDetails,
  apiUser,
  request,
  users,
  projects,
  clients,
  ...otherProps
}: Props) {
  const [getState, setState] = useGetSetState<State>({ activityItems: undefined });
  useEffect(() => {
    fetchAndSetActivityItems();
  }, [request]); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchAndSetActivityItems() {
    if (!request) {
      return;
    }

    const activityItems = (
      await callGraphQLSimple({
        query: "listActivityItemsByParent",
        message: "Failed to list activity items",
        variables: {
          parentId: request.id,
          limit: 1000,
        },
      })
    ).data.listActivityItemsByParent.items;

    let activityItemsWithParsedContent = activityItems.map((activityItem) => {
      let parsedContent = activityItem.content;
      if (parsedContent) {
        try {
          parsedContent = JSON.parse(activityItem.content);
        } catch (e) {
          // nothing we can do, it means it's not JSON
        }
      }
      return {
        ...activityItem,
        content: parsedContent,
      };
    });

    setState({ activityItems: activityItemsWithParsedContent });
  }

  const state = getState();

  if (!state.activityItems) {
    return (
      <div className="request-details-page">
        <Spin />
      </div>
    );
  }

  return (
    <RequestsDetailsPageContent
      organisationDetails={organisationDetails}
      apiUser={apiUser}
      request={request}
      users={users}
      projects={projects}
      clients={clients}
      activityItems={state.activityItems}
      {...otherProps}
    />
  );
}

export default withRouter(
  withSubscriptions({
    Component: RequestDetailsPage,
    subscriptions: ["clients", "users", "projects", "request"],
  })
);
