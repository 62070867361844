import { message as antMessage } from "antd";
import axios from "axios";
import { Auth, API, graphqlOperation } from "aws-amplify";

import awsExports from "aws-exports";
import * as queries from "graphql/queries";
import * as queriesCustom from "graphql/queries_custom";
import * as mutations from "graphql/mutations";
import { captureError } from "common/helpers";
import { getCurrentSession } from "common/authHelpers";

export async function fetchCollection({ query, queryCustom, variables, collectionLabel }) {
  let items: any[] = [];

  while (true) {
    const response = await callGraphQLSimple({
      message: `Failed to fetch ${collectionLabel}`,
      query,
      queryCustom,
      variables,
    });
    if (response.errors) {
      throw response.errors;
    }
    let dataProperty = Object.keys(response.data).find((x) => x !== "errors");
    if (!dataProperty) {
      throw new Error(`Could not find data property in response for ${queryCustom || query}`);
    }
    let pageOfItems = response.data[dataProperty];
    items = [...items, ...pageOfItems.items];

    if (!pageOfItems.nextToken) {
      break;
    }

    variables.nextToken = pageOfItems.nextToken;
  }

  return items;
}

export interface CallGraphQLSimpleParams {
  message?: string | JSX.Element;
  queryName?: string;
  query?: string;
  queryCustom?: string;
  queryRaw?: string;
  mutation?: string;
  displayError?: boolean;
  variables?: any;
}

export async function callGraphQLSimple({
  message,
  queryName = undefined,
  queryRaw = undefined,
  query = undefined,
  queryCustom = undefined,
  mutation = undefined,
  displayError = true,
  variables,
  ...otherParams
}: CallGraphQLSimpleParams) {
  // delete all properties called __typename from variables, recursively
  // const deleteTypename = (obj: any) => {
  //   if (obj && typeof obj === "object") {
  //     delete obj.__typename;
  //     Object.keys(obj).forEach((key) => {
  //       deleteTypename(obj[key]);
  //     });
  //   }
  // };
  // deleteTypename(variables);
  let operationString = "";
  let messageToDisplay: string | JSX.Element | undefined = message;

  if (queryName) {
    operationString = queriesCustom[queryName] || queries[queryName] || mutations[queryName];
    if (!operationString) {
      throw new Error(
        `You specified a queryName of "${queryName}" but it does not match anything in queriesCustom, queries, or mutations`
      );
    }
  } else {
    if (queryRaw) {
      operationString = queryRaw!;
    } else {
      if ((otherParams as any)?.queries) {
        antMessage.error(
          `Failed to make GraphQL request "${(otherParams as any)?.queries}": specified "queries" instead of "query"`
        );
        return;
      }
      if ((otherParams as any)?.mutations) {
        antMessage.error(
          `Failed to make GraphQL request "${
            (otherParams as any)?.mutations
          }": specified "mutations" instead of "mutation"`
        );
        return;
      }
      if ((otherParams as any)?.queriesCustom) {
        antMessage.error(
          `Failed to make GraphQL request "${
            (otherParams as any)?.queriesCustom
          }": "queriesCustom" instead of "queryCustom"`
        );
        return;
      }

      let queryType = "";

      if (query) {
        operationString = queries[query];
        queryType = "queries";
      } else if (queryCustom) {
        operationString = queriesCustom[queryCustom];
        queryType = "custom queries";
      } else if (mutation) {
        operationString = mutations[mutation];
        queryType = "mutations";
      }

      if (!operationString) {
        messageToDisplay = `${message}. Could not find "${queryCustom || query || mutation}" in ${queryType}`;
        throw new Error(messageToDisplay);
      }
    }
  }

  // also delete any __typename proeprties from the operationString
  operationString = operationString.split("__typename").join("");

  const operation = graphqlOperation(operationString, variables);

  return await captureError({
    callback: async () => {
      await getCurrentSession();
      const response: any = await API.graphql(operation);
      if (response.errors) {
        throw response;
      } else {
        return response;
      }
    },
    message: messageToDisplay,
    displayError,
  });
}

export async function callRest({
  method,
  route,
  body = undefined,
  message = "",
  includeCredentials = true,
  isJson = true,
  isBlob = false,
  displayError = true,
}: {
  method: "GET" | "POST" | "PUT" | "DELETE";
  route: string;
  body?: any;
  message?: string | JSX.Element;
  includeCredentials?: boolean;
  isJson?: boolean;
  isBlob?: boolean;
  displayError?: boolean;
}) {
  if (!method) {
    throw new Error("Cannot call REST API, no method specified");
  }

  if (method && method.toUpperCase() !== "GET" && !body) {
    body = {};
  }

  if (body && typeof body === "object") {
    body.origin = window.location.origin;
  }

  let session: any = await getCurrentSession();

  if (includeCredentials && body && typeof body === "object") {
    const user = await Auth.currentAuthenticatedUser();

    if (!session) {
      throw new Error("You are not logged in");
    }

    body.accessToken = session.accessToken.jwtToken;
    body.refreshToken = session.refreshToken.token;
    body.idToken = session.idToken.jwtToken;
    body.cognitoClientId = user.pool.clientId;
    body.restUrl = getRestEndpoint();
    body.graphQLUrl = awsExports.aws_appsync_graphqlEndpoint;
  }

  return await captureError({
    callback: async () => {
      let response: any = null;
      try {
        let headers = {
          Authorization: `${session.idToken.jwtToken}`,
        };

        if (isJson) {
          headers["Content-Type"] = "application/json";
        }
        const axiosParams: any = {
          url: `${getRestEndpoint()}${route}`,
          method,
          data: body,
          headers,
        };
        if (isBlob) {
          axiosParams.responseType = "blob";
        }
        const rawResponse = await axios(axiosParams);
        response = rawResponse.data;

        if (response && response.error) {
          throw new Error(response.error);
        }

        return response;
      } catch (e) {
        throw e;
      }
    },
    message,
    displayError,
  });
}

export function getRestEndpoint() {
  if (!awsExports || !awsExports.aws_cloud_logic_custom) {
    throw new Error("Could not find REST API endpoint");
  }

  const restApiElement = awsExports.aws_cloud_logic_custom.find((x) => x.name === "rest");
  if (!restApiElement) {
    throw new Error("Could not find REST API endpoint");
  }
  return restApiElement.endpoint;
}
