import React from "react";
import { withRouter } from "react-router-dom";
import { Typography, Tag, Modal, Button } from "antd";
import { CommentOutlined, LoadingOutlined, CheckCircleFilled } from "@ant-design/icons";
import cx from "classnames";
import query from "query-string";

import { REVIEW_SECONDARY_STATUS_READABLE, REVIEW_STATUS_READABLE } from "common/constants";
import withSubscriptions from "common/withSubscriptions";
import { getSortedFiles } from "common/helpers";
import { submitReview } from "ReviewPage/reviewHelpers";
import { REVIEW_MOBILE_BREAKPOINT } from "common/constants";
import { fetchActivityItemsForRequest } from "common/shared";
import { getDetailsForFormAndTaskRevision } from "common/sharedRequestHelpers";

import ReviewConversation from "../ReviewConversation/ReviewConversation";
import ReviewSheet from "../ReviewSheet/ReviewSheet";
import ReviewRequestForm from "../ReviewRequestForm/ReviewRequestForm";
import ReviewFileList from "../ReviewFileList/ReviewFileList";
import HeaderReviewPage from "ReviewPage/HeaderReviewPage/HeaderReviewPage";

import "./ReviewPageContainer.scss";

export const MOBILE_SUBTAB_OPTIONS = {
  FILE_LIST: "File list",
  CANVAS: "Canvas",
  ACTIVITY: "Activity",
};

const DEFAULT_NAVIGATION_STATE_FOR_MOBILE = {
  isFileListOpen: true,
  isCanvasOpen: false,
  isActivityOpen: false,
};

const DEFAULT_NAVIGATION_STATE_FOR_DESKTOP = {
  isFileListOpen: true,
  isCanvasOpen: true,
  isActivityOpen: true,
};

export class ReviewPageContainer extends React.Component {
  state = {
    activeTab: null,
    updatedTaskRevision: null,
    publicAttachmentImageURLs: null,
    isSubmittingReview: false,
    activityItemsByRequest: undefined,
    externalReview: undefined,
    externalReviewId: undefined, // used to refresh the external review when it is updated
    ...DEFAULT_NAVIGATION_STATE_FOR_MOBILE,
  };

  _isMounted = false;

  async componentDidMount() {
    this.props.showPreloader();
    this._isMounted = true;

    this.props.setNoScroll(true);
    this.props.setBoxedLayout(false);
    this.props.setBackground(false);

    if (window.innerWidth > REVIEW_MOBILE_BREAKPOINT) {
      this.setState(DEFAULT_NAVIGATION_STATE_FOR_DESKTOP);
    } else {
      this.setState(DEFAULT_NAVIGATION_STATE_FOR_MOBILE);
    }

    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: this.props.task.id,
          projectId: this.props.task.projectId,
          fileId: "nothing",
          clientId: this.props.task.clientId,
          page: "REVIEW",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });

    if (!this.props.isExternalReview) {
      await this.fetchActivityDetailsForRequests();
    } else {
      this.setState({
        activityItemsByRequest: {},
      });
    }
    this.props.hidePreloader();

    this.switchToFirstSheetIfNeeded();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.setNoScroll(false);
    this.props.setBoxedLayout(true);
    this.props.setBackground(true);
  }

  componentDidUpdate(prevProps) {
    const queryString = query.parse(this.props.location.search);
    const activeTabFromUrl = queryString.tab;
    const { activeTab } = this.state;

    if (activeTabFromUrl !== activeTab) {
      this.setState({ activeTab: activeTabFromUrl });
    }

    if (!this.props.isExternalReview) {
      if (
        JSON.stringify(this.props.task.requestIds || []) !== JSON.stringify(prevProps.task.requestIds || []) ||
        this.props.task.itemSubscription !== prevProps.task.itemSubscription
      ) {
        this.fetchActivityDetailsForRequests();
      }
    }
  }

  switchToFirstSheetIfNeeded = () => {
    const { review } = this.props.taskRevision;
    const queryString = query.parse(this.props.location.search);
    const activeTabFromUrl = queryString.tab;

    if (!activeTabFromUrl && review && !review.reviewThread?.length) {
      const excludedFileTypes = this.props.organisationDetails.settings?.review?.excludedFileTypes || [];
      let sortedFiles = getSortedFiles({ taskRevision: this.props.taskRevision }).filter(
        (x) => !(excludedFileTypes || []).includes(x.type)
      );
      let firstSheetInFirstFile = sortedFiles[0]?.sheets?.items[0];
      this.props.history.replace(`${this.props.location.pathname}?tab=${firstSheetInFirstFile?.id}`);
    }
  };

  fetchActivityDetailsForRequests = async () => {
    const { task } = this.props;
    if (!task.requestIds || task.requestIds.length === 0) {
      this.setState({
        activityItemsByRequest: {},
      });
      return;
    }

    const activityDetailsForRequests = await Promise.all(task.requestIds.map(fetchActivityItemsForRequest));
    let activityItemsByRequest = {};
    for (let activityDetailsForRequest of activityDetailsForRequests) {
      activityItemsByRequest[activityDetailsForRequest.requestId] = activityDetailsForRequest.activityItems;
    }

    // the request form ID actually points to the ID of the activity item which recorded the task files being sent
    // and which also contains the ID of the external review

    let requestFormActivityItem = getDetailsForFormAndTaskRevision({
      activityItemsByRequest,
      taskRevisionId: this.props.taskRevision.id,
    });

    let activityItems = [];
    if (requestFormActivityItem) {
      activityItems = activityItemsByRequest[requestFormActivityItem.parentId];
    }

    let taskFilesSentActivityItem = activityItems.find((x) => x.id === requestFormActivityItem.content.formFileId);

    let externalReviewId = taskFilesSentActivityItem?.content?.reviewId;
    let externalReview;

    if (externalReviewId) {
      externalReview = (
        await window.callGraphQLSimple({
          query: "getReview",
          variables: {
            id: externalReviewId,
          },
        })
      ).data.getReview;

      if (externalReview) {
        externalReview.reviewThread = (externalReview?.reviewThread || []).map((reviewActivityItem) => {
          return {
            ...reviewActivityItem,
            isFromExternalReview: true,
          };
        });
      }

      this.setState({
        externalReviewId,
        externalReview,
      });
    }

    this.setState({
      activityItemsByRequest,
    });

    return {
      externalReview,
    };
  };

  closeReview = () => {
    submitReview.call(this, "CLOSED");
  };

  reopenReview = () => {
    submitReview.call(this, "IN_PROGRESS");
  };

  confirmCancelApproval = async () => {
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm cancel approval",
          maskClosable: true,
          content: <>Are you sure you want to cancel the approval for this review?</>,
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }
    submitReview.call(this, "IN_PROGRESS");
  };

  displayReviewStatus = () => {
    const { taskRevision } = this.props;

    let tag = null;
    if (taskRevision.reviewStatus) {
      tag = (
        <Tag color={REVIEW_STATUS_READABLE[taskRevision.reviewStatus].color} data-cy="review-status">
          {taskRevision.reviewStatus === "SUCCESS" && <CheckCircleFilled className="icon" />}
          {REVIEW_STATUS_READABLE[taskRevision.reviewStatus].label}
        </Tag>
      );
    }
    return tag;
  };

  displayReviewSecondaryStatus = () => {
    const { taskRevision } = this.props;

    let tag = null;
    if (taskRevision.reviewSecondaryStatus) {
      tag = (
        <Tag color={taskRevision.reviewSecondaryStatus === "INFO_REQUIRED" ? "orange" : "green"}>
          {REVIEW_SECONDARY_STATUS_READABLE[taskRevision.reviewSecondaryStatus]}
        </Tag>
      );
    }
    return tag;
  };

  isUserReviewer = () => {
    const { apiUser, taskRevision, request } = this.props;

    if(request) {
      return true;
    }

    const userIsReviewer = apiUser.id === taskRevision.checkedBy;

    return userIsReviewer;
  };

  approveReview = async () => {
    // if (!window.Cypress) {
    //   window.onbeforeunload = function (e) {
    //     return "Please do not leave the page until the review has been submitted";
    //   };
    // }
    this.setState({ isSubmittingReview: true });

    await submitReview.call(this, "SUCCESS");

    this.setState({ isSubmittingReview: false });
  };

  displayCurrentTab = ({ requestFormActivityItem }) => {
    const { apiUser, taskRevision, task, users, isExternalReview } = this.props;
    const { activeTab, isFileListOpen, isActivityOpen } = this.state;
    const { review } = taskRevision;

    const revisionName = taskRevision.name;

    if (!activeTab || activeTab.length === 0 || activeTab === "conversation") {
      return (
        <ReviewConversation
          task={task}
          review={review}
          apiUser={apiUser}
          revisionName={revisionName}
          taskRevision={taskRevision}
          reviewIsActive={this.isReviewActive()}
          externalReview={this.state.externalReview}
          request={this.props.request}
        />
      );
    }

    if (activeTab === "request-form") {
      return (
        <ReviewRequestForm
          task={task}
          taskRevision={taskRevision}
          apiUser={apiUser}
          requestFormActivityItem={requestFormActivityItem}
        />
      );
    }

    let sortedFiles = getSortedFiles({ taskRevision });

    for (let i = 0; i < sortedFiles.length; i++) {
      const file = sortedFiles[i];
      for (let j = 0; j < file.sheets.items.length; j++) {
        const sheet = file.sheets.items[j];
        if (activeTab === sheet.id) {
          return (
            <ReviewSheet
              key={sheet.id}
              file={file}
              task={task}
              taskRevision={taskRevision}
              sheet={sheet}
              review={review}
              externalReview={this.state.externalReview}
              users={users}
              apiUser={apiUser}
              isFileListOpen={isFileListOpen}
              isActivityOpen={isActivityOpen}
              onHideActivity={() => this.setState({ isActivityOpen: false })}
              onShowActivity={() => this.setState({ isActivityOpen: true })}
              reviewIsActive={this.isReviewActive()}
              isExternalReview={isExternalReview}
              request={this.props.request}
            />
          );
        }
      }
    }
  };

  isReviewActive = () => {
    const { taskRevision } = this.props;
    return (
      taskRevision.reviewStatus === "WITH_COMMENTS" ||
      taskRevision.reviewStatus === "CHANGES_REQUESTED" ||
      taskRevision.reviewStatus === "IN_PROGRESS"
    );
  };

  goToFileList = () => {
    this.setState({
      isFileListOpen: true,
      isCanvasOpen: false,
      isActivityOpen: false,
    });
  };

  goToCanvas = () => {
    this.setState({
      isFileListOpen: false,
      isCanvasOpen: true,
      isActivityOpen: false,
    });
  };

  goToActivity = () => {
    this.setState({
      isFileListOpen: false,
      isCanvasOpen: false,
      isActivityOpen: true,
    });
  };

  getCurrentMobileSubtab = () => {
    const { isCanvasOpen, isActivityOpen, isFileListOpen } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const selectedTab = urlParams.get("tab");

    if (selectedTab === "conversation") {
      if (isFileListOpen) {
        return MOBILE_SUBTAB_OPTIONS.FILE_LIST;
      } else {
        return MOBILE_SUBTAB_OPTIONS.CANVAS;
      }
    } else {
      if (isCanvasOpen) {
        return MOBILE_SUBTAB_OPTIONS.CANVAS;
      } else if (isActivityOpen) {
        return MOBILE_SUBTAB_OPTIONS.ACTIVITY;
      } else {
        return MOBILE_SUBTAB_OPTIONS.FILE_LIST;
      }
    }
  };

  render() {
    const { apiUser, taskRevision, task, users, organisationDetails, isExternalReview, request, activityItem } =
      this.props;
    const { isSubmittingReview, activityItemsByRequest } = this.state;
    const { review } = taskRevision;

    if (!apiUser || !review || !activityItemsByRequest) {
      return null;
    }

    let requestFormActivityItem = getDetailsForFormAndTaskRevision({
      activityItemsByRequest,
      taskRevisionId: taskRevision.id,
    });

    let basePath = `/tasks/${task.id}/review/${taskRevision.id}`;

    if (isExternalReview) {
      basePath = `/requests/${request.id}/review/${activityItem.id}`;
    }

    return (
      <div className={cx("review-page-container")}>
        <HeaderReviewPage
          task={task}
          taskRevision={taskRevision}
          apiUser={apiUser}
          containerThis={this}
          userIsReviewer={this.isUserReviewer()}
          approveReview={this.approveReview}
          getCurrentMobileSubtab={this.getCurrentMobileSubtab}
          goToFileList={this.goToFileList}
          goToCanvas={this.goToCanvas}
          goToActivity={this.goToActivity}
          isExternalReview={isExternalReview}
        />
        <div className="review-page-container-card">
          <ReviewFileList
            basePath={basePath}
            users={users}
            task={task}
            taskRevision={taskRevision}
            apiUser={apiUser}
            organisationDetails={organisationDetails}
            onHide={() => this.setState({ isFileListOpen: false })}
            onShow={() => this.setState({ isFileListOpen: true })}
            isOpen={this.state.isFileListOpen}
            onItemClick={this.goToCanvas}
            requestFormActivityItem={requestFormActivityItem}
            isExternalReview={isExternalReview}
            request={request}
          />
          <div className="selected-tab-content">{this.displayCurrentTab({ requestFormActivityItem })}</div>

          {window.innerWidth < REVIEW_MOBILE_BREAKPOINT && (
            <div
              className={cx("activity-button-container", {
                visible:
                  this.getCurrentMobileSubtab() === MOBILE_SUBTAB_OPTIONS.CANVAS &&
                  !window.location.href.includes("tab=conversation"),
              })}
            >
              <Button onClick={this.goToActivity}>
                <CommentOutlined />
                Comments
              </Button>
            </div>
          )}
        </div>

        {isSubmittingReview && (
          <Modal
            title="Updating review status"
            open={true}
            footer={null}
            maskClosable={false}
            closable={false}
            icon={<LoadingOutlined />}
          >
            <Typography.Text>Do not close this page while the review is being submitted.</Typography.Text>
          </Modal>
        )}
      </div>
    );
  }
}

export const ReviewPageWithMinimalSubscriptions = withSubscriptions({
  Component: ReviewPageContainer,
  subscriptions: ["users", "organisations"],
});

export default withRouter(
  withSubscriptions({
    Component: ReviewPageContainer,
    subscriptions: ["users", "organisations", "taskRevision", "task"],
  })
);
