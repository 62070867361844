import { useState, useEffect } from "react";
import moment from "moment";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { Select, Button, Modal, Typography, Table, Tooltip } from "antd";

import { isAuthorised } from "common/permissions";
import { User, Holiday } from "common/types";
import { callGraphQLSimple } from "common/apiHelpers";
import { getUserTimeOffDataForDisplay, deleteTimeOff } from "common/timeOffHelpers";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";
import withSubscriptions from "common/withSubscriptions";

import HolidayModal from "Modals/HolidayModal/HolidayModal";
import Card from "Card/Card";

import "./TimeOffCard.scss";

type Props = {
  apiUser: User;
  holidays: Holiday[];
  isSick: boolean;
  tableData: any;
  users: User[];
  userDetails: User;
  fetchAndSetHolidaysByUser: (params) => void;
};

export function TimeOffCard(props: Props) {
  const { apiUser, holidays, users, userDetails, isSick, fetchAndSetHolidaysByUser } = props;
  const [isHolidayModalVisible, setIsHolidayModalVisible] = useState(false);
  const [intervals, setIntervals] = useState<any>(undefined);
  const [isLoadingIntervals, setIsLoadingIntervals] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState<any>(null);

  let targetIntervals = intervals?.filter((interval) => {
    if (isSick) {
      return interval.type === "SICK_ALLOWANCE";
    } else {
      return interval.type === "HOLIDAY_ALLOWANCE";
    }
  });

  let validTimeOff: any[] = [];
  if (selectedInterval) {
    validTimeOff = holidays.filter(
      (holiday) =>
        holiday.userId === userDetails.id &&
        moment(holiday.startsAt).isBetween(selectedInterval.startDate, selectedInterval.endDate) &&
        (isSick ? holiday.isSick : !holiday.isSick)
    );
  }

  const tableData = getUserTimeOffDataForDisplay({
    holidays: validTimeOff,
    users,
    isSick,
    interval: selectedInterval,
  });

  useEffect(() => {
    fetchAndSetHolidaysByUser({ userId: userDetails.id });
    fetchAndSetIntervals();
  }, []);

  async function fetchAndSetIntervals() {
    setIsLoadingIntervals(true);
    const intervals = (
      await callGraphQLSimple({
        message: "Failed to fetch allowance intervals",
        query: "listIntervalsByOrganisation",
        variables: {
          organisation: userDetails.organisation,
          limit: 1000,
          filter: {
            type: {
              eq: isSick ? "SICK_ALLOWANCE" : "HOLIDAY_ALLOWANCE",
            },
          },
        },
      })
    ).data.listIntervalsByOrganisation.items;
    setIntervals(intervals.sort((a, b) => (a.startDate < b.startDate ? -1 : 1)));
    setIsLoadingIntervals(false);
    if (intervals && !selectedInterval) {
      let firstIntervalWeAreIn = intervals.find((interval) => {
        return moment().isBetween(interval.startDate, interval.endDate);
      });
      setSelectedInterval(firstIntervalWeAreIn || intervals[0]);
    }
  }

  const allowanceType = isSick ? "SICK" : "HOLIDAY";

  let countDaysPending = 0;
  let countDaysApproved = 0;
  let countDaysRemaining = 0;

  validTimeOff
    .filter((holiday) => {
      return moment(holiday.startsAt).isBetween(selectedInterval?.startDate, selectedInterval?.endDate);
    })
    .forEach((holiday) => {
      let days = holiday.days!;

      let dayCount = 0;

      days.forEach((day) => {
        if (day!.endHours) {
          const numberOfHours = day!.endHours - day!.startHours!;

          dayCount += numberOfHours / TIMELINE_DEFAULT_HOURS_IN_A_DAY;
        } else {
          dayCount += 1;
        }
      });

      if (holiday.status === "APPROVED") {
        countDaysApproved += dayCount;
      } else if (holiday.status !== "REJECTED") {
        countDaysPending += dayCount;
      }
    });

  const timeOffAllowanceForSelectedInterval = userDetails.allowances?.find(
    (allowance) => allowance?.intervalId === selectedInterval?.id && allowance?.type === allowanceType
  );

  if (timeOffAllowanceForSelectedInterval && timeOffAllowanceForSelectedInterval.allowance) {
    countDaysRemaining =
      timeOffAllowanceForSelectedInterval.allowance / TIMELINE_DEFAULT_HOURS_IN_A_DAY - countDaysApproved;
  } else {
    countDaysRemaining = 0;
  }

  async function confirmDeleteHoliday(holidayTableRow) {
    Modal.confirm({
      title: `Are you sure you want to delete this ${isSick ? "sick day" : "holiday"} request?`,
      content: (
        <>
          <Typography.Text>Start: {moment(holidayTableRow.startsAt).format("DD-MM-YYYY")}</Typography.Text>
          <br />
          <Typography.Text>End: {moment(holidayTableRow.endsAt).format("DD-MM-YYYY")}</Typography.Text>
          <br />
          <Typography.Text>Days: {holidayTableRow.numberOfDays}</Typography.Text>
        </>
      ),
      onOk: async () => {
        deleteTimeOff({
          holiday: holidayTableRow,
          apiUser,
          users,
        });
      },
    });
  }

  let canRequestTimeOff = false;
  if (isAuthorised(["TIME_OFF.MANAGE"])) {
    canRequestTimeOff = true;
  } else if (!isSick && isAuthorised(["TIME_OFF.REQUEST_OWN_HOLIDAYS"])) {
    canRequestTimeOff = true;
  } else if (isSick && isAuthorised(["TIME_OFF.REQUEST_OWN_SICK_DAYS"])) {
    canRequestTimeOff = true;
  }

  return (
    <Card
      className="time-off-card"
      title={isSick ? "Sick days" : "Holidays"}
      flexTitle
      wrapHeader
      withSpace
      actions={
        selectedInterval && (
          <div className="allowance-container">
            <Typography.Text className="current-interval-time-off-label">
              Pending in this interval: <b>{countDaysPending} days</b>
            </Typography.Text>
            <Typography.Text className="current-interval-time-off-label">
              Approved in this interval: <b>{countDaysApproved} days</b>
            </Typography.Text>
            {timeOffAllowanceForSelectedInterval && (
              <Typography.Text className="current-interval-time-off-label">
                Remaining allowance in this interval: <b>{countDaysRemaining} days</b>
              </Typography.Text>
            )}
            <Select
              placeholder="Choose an interval"
              value={selectedInterval?.id}
              onChange={(newIntervalId) => {
                setSelectedInterval(intervals.find((interval) => interval.id === newIntervalId));
              }}
            >
              {targetIntervals?.map((interval) => (
                <Select.Option key={interval.id} value={interval.id}>
                  {interval.name}
                </Select.Option>
              ))}
            </Select>
            {canRequestTimeOff && (
              <Button
                type="primary"
                data-cy="request-time-off-button"
                icon={<PlusCircleOutlined />}
                onClick={() => setIsHolidayModalVisible(true)}
              >
                Request {isSick ? "sick days" : "holiday"}
              </Button>
            )}
          </div>
        )
      }
    >
      <Table
        className="time-off-table"
        rowKey="id"
        loading={isLoadingIntervals}
        pagination={{ hideOnSinglePage: true, pageSize: 50 }}
        rowClassName={(holiday) => {
          if (holiday.temporalStatus === "Past") {
            return "holiday-past";
          } else if (holiday.temporalStatus === "Future") {
            return "holiday-future";
          } else {
            return "holiday-in-progress";
          }
        }}
        columns={[
          {
            title: "Requested on",
            align: "center",
            render: (_, holiday) => <p>{moment(holiday.createdAt).format("DD-MM-YYYY")}</p>,
          },
          {
            title: "Start Date",
            dataIndex: "startsAt",
            key: "startsAt",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-start-date" data-index={index}>
                {moment(value).format("DD-MM-YYYY")}
              </Typography.Text>
            ),
          },
          {
            title: "End Date",
            dataIndex: "endsAt",
            key: "endsAt",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-end-date" data-index={index}>
                {moment(value).format("DD-MM-YYYY")}
              </Typography.Text>
            ),
          },
          {
            title: "Days",
            key: "numberOfDays",
            dataIndex: "numberOfDays",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-number-of-days" data-index={index}>
                {value}
              </Typography.Text>
            ),
          },
          {
            title: "Approval",
            dataIndex: "approvalStatus",
            key: "approvalStatus",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-approval-status" data-index={index}>
                {value}
              </Typography.Text>
            ),
          },
          {
            title: "Status",
            key: "temporalStatus",
            dataIndex: "temporalStatus",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-temporal-status" data-index={index}>
                {value}
              </Typography.Text>
            ),
          },
          {
            title: "",
            key: "actions",
            render: (row) => {
              if (
                row.temporalStatus.toUpperCase() !== "FUTURE" ||
                /*row.status === "APPROVED" || */ !canRequestTimeOff
              ) {
                return null;
              }

              return (
                <Tooltip title="Delete holiday request">
                  <Button icon={<DeleteOutlined />} onClick={() => confirmDeleteHoliday(row)} />
                </Tooltip>
              );
            },
          },
        ]}
        dataSource={tableData}
      />
      {isHolidayModalVisible && (
        <HolidayModal
          onClose={() => setIsHolidayModalVisible(false)}
          isSick={isSick}
          onSubmit={() => {
            setIsHolidayModalVisible(false);
          }}
          userDetails={userDetails}
          targetIntervals={targetIntervals}
        />
      )}
    </Card>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TimeOffCard,
    subscriptions: ["organisationDetails", "users", "holidaysByUser"],
  })
);
