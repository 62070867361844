import { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import cx from "classnames";
import _ from "lodash";
import { DownOutlined, RightOutlined, DeleteOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Typography, Tabs, Button, Tag } from "antd";

import { withRouter } from "react-router-dom";
import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";
import { isAuthorised } from "common/permissions";
import { ALLOWANCE_TYPES } from "common/constants";

import Card from "Card/Card";
import Avatar from "Avatar/Avatar";
import InfoItem from "InfoItem/InfoItem";
import UserWorkingHours from "../UserWorkingHours/UserWorkingHours";
import WorkingHours from "WorkingHours/WorkingHours";
import CatLevelModal from "Modals/CatLevelModal/CatLevelModal";
import UsersAuthorityLevels from "UsersPage/UsersAuthorityLevels";
import UsersAllowance from "UserAllowances/UserAllowances";
import UserFeeLevels from "./UserFeeLevels";
import UserGroups from "./UserGroups";
import UserTeams from "./UserTeams";
import Input from "Input/Input";
import UserPermissions from "./UserPermissions";

import "./UserItem.scss";

export function UserItem({ apiUser, organisationDetails, userData, confirmUserDisable, groups, removeUserFromTeam }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDesignCatLevelModalVisible, setIsDesignCatLevelModalVisible] = useState(false);
  const [isReviewCatLevelModalVisible, setIsReviewCatLevelModalVisible] = useState(false);
  const [isCheckCatLevelModalVisible, setIsCheckCatLevelModalVisible] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);
  const [updatedFieldName, setUpdatedFieldName] = useState(null); // eslint-disable-line

  let isOwnUser = userData.id === apiUser.id;

  let canEditFinancialAuthority = isOwnUser
    ? isAuthorised(["USERS.EDIT_OWN_FINANCIAL_AUTHORITY_LEVELS"])
    : isAuthorised(["USERS.EDIT_OTHERS_FINANCIAL_AUTHORITY_LEVELS"]);

  const groupMembership = _.compact(groups.map((group) => (group?.members?.includes(userData.id) ? group : null)));

  let isExpandable =
    organisationDetails.usesDesignAuthority ||
    organisationDetails.usesReviewAuthority ||
    organisationDetails.settings?.quote?.usesQuotes ||
    organisationDetails.settings?.invoice?.usesInvoices ||
    organisationDetails.settings?.timeline?.usesTimeline;

  if (userData.isHidden) {
    return null;
  }

  function computeActionsForUser() {
    let actions = [];

    if (!isOwnUser && isAuthorised(["USERS.DISABLE_USER"])) {
      actions.push(<Button icon={<DeleteOutlined />} onClick={() => confirmUserDisable(userData)} />);
    }
    return actions;
  }

  async function changeAttribute({ fieldName, value }) {
    if (value === undefined) {
      value = null;
    }
    await callGraphQLSimple({
      message: "Failed to update user details",
      queryName: "updateUser",
      variables: {
        input: {
          id: userData.id,
          [fieldName]: value,
        },
      },
    });

    setUpdatedFieldName(fieldName);
    setTimeout(() => setUpdatedFieldName(null), 5000);
  }

  function displayExpandedContent(userData) {
    if (!isExpanded) {
      return null;
    }

    return (
      <div className="secondary-info">
        <Tabs>
          {(organisationDetails.usesDesignAuthority ||
            organisationDetails.usesReviewAuthority ||
            organisationDetails.settings?.quote?.usesQuotes) && (
            <Tabs.TabPane tab="Authority levels" key="authority-levels">
              <UsersAuthorityLevels
                organisationDetails={organisationDetails}
                userData={userData}
                changeAttribute={changeAttribute}
                canEditFinancialAuthority={canEditFinancialAuthority}
                apiUser={apiUser}
              />
            </Tabs.TabPane>
          )}
          {isAuthorised(["USERS.ALLOWANCES.VIEW"]) && (
            <Tabs.TabPane
              tab={<Typography.Text data-cy="time-off-allowances-tab">Allowances</Typography.Text>}
              key="allowances"
            >
              <div>
                {ALLOWANCE_TYPES.map((allowanceDefinition) => (
                  <UsersAllowance
                    userData={userData}
                    allowanceDefinition={allowanceDefinition}
                    setSelectedUser={setSelectedUser}
                    selectedUser={selectedUser}
                  />
                ))}
              </div>
            </Tabs.TabPane>
          )}
          {(organisationDetails.settings?.quote?.usesQuotes || organisationDetails.settings?.invoice?.usesInvoices) && (
            <Tabs.TabPane tab="Fee levels" key="fee-levels">
              <UserFeeLevels
                organisationDetails={organisationDetails}
                changeAttribute={changeAttribute}
                userData={userData}
              />
            </Tabs.TabPane>
          )}
          {isAuthorised(["IAM.VIEW", "IAM.EDIT_PERMISSIONS"]) && (
            <Tabs.TabPane tab="Permissions" key="permissions">
              <UserPermissions setSelectedUser={setSelectedUser} userData={userData} selectedUser={selectedUser} />
            </Tabs.TabPane>
          )}

          <Tabs.TabPane tab="Groups" key="groups">
            <UserGroups userData={userData} groupMembership={groupMembership} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Teams" key="teams">
            <UserTeams
              userData={userData}
              organisationDetails={organisationDetails}
              removeUserFromTeam={removeUserFromTeam}
            />
          </Tabs.TabPane>
          {organisationDetails.settings?.timeline?.usesTimeline && (
            <Tabs.TabPane
              tab={<Typography.Text data-cy="user-working-hours-tab">Working hours</Typography.Text>}
              key="working-hours"
            >
              {userData.id?.toLowerCase()?.includes("dragos") ? (
                <WorkingHours parent={userData} organisationDetails={organisationDetails} />
              ) : (
                <UserWorkingHours user={userData} />
              )}
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }

  function displayLastSeen() {
    if (!userData.lastOnlineAt) {
      return null;
    }

    const diff = moment().diff(userData.lastOnlineAt, "minutes");
    if (diff < 2) {
      return (
        <Tag color="green">
          <div data-cy="last-online-at-status">
            {" "}
            <CheckCircleOutlined /> Online
          </div>
        </Tag>
      );
    }

    return (
      <Tag color="#002f44" data-cy="last-online-at-status">
        Last seen {moment(userData.lastOnlineAt).fromNow()}
      </Tag>
    );
  }

  return (
    <Card
      className={cx("user-item", {
        "is-collapsed": !isExpanded,
      })}
      data-cy="user-item"
      attributes={{
        "data-user-id": userData.id,
      }}
      actions={computeActionsForUser()}
      title={
        <>
          {isExpandable && (
            <>
              {isExpanded ? (
                <Button
                  type="clear"
                  className="collapse-button"
                  onClick={() => setIsExpanded(false)}
                  data-cy="collapse-button"
                >
                  <DownOutlined />
                </Button>
              ) : (
                <Button
                  type="clear"
                  className="expand-button"
                  onClick={() => setIsExpanded(true)}
                  data-cy="expand-button"
                >
                  <RightOutlined />
                </Button>
              )}
            </>
          )}

          <div className="primary-info">
            <div className="avatar-container">
              <Link to={`/users/${userData.id}`}>
                <Avatar user={userData} size="normal" />
              </Link>
            </div>

            <Link to={`/users/${userData.id}`}>
              <InfoItem
                className="name-container"
                value={
                  <div className="user-details">
                    <Typography.Text className="name-text">
                      {userData.firstName} {userData.lastName}
                    </Typography.Text>

                    <div className="tags">
                      {userData.id === apiUser.id && <Tag color="#87d068">Me</Tag>}
                      {displayLastSeen()}
                      {groupMembership.map((group) => (
                        <Tag color="#19aae8">{group.name}</Tag>
                      ))}
                    </div>
                  </div>
                }
              />
            </Link>

            <InfoItem
              className="job-title"
              label="Job title"
              value={
                <Input
                  showBorder
                  fullWidth
                  defaultValue={userData.position}
                  onChange={(value) => changeAttribute({ fieldName: "position", value })}
                />
              }
            />
            <InfoItem
              className="qualifications"
              label="Qualifications"
              value={
                <Input
                  showBorder
                  fullWidth
                  defaultValue={userData.qualifications}
                  onChange={(value) => changeAttribute({ fieldName: "qualifications", value })}
                />
              }
            />
            <InfoItem label="Email" value={<Typography.Text data-cy="user-email">{userData.id}</Typography.Text>} />
          </div>
        </>
      }
    >
      {displayExpandedContent(userData)}

      {isDesignCatLevelModalVisible && (
        <CatLevelModal
          key={`user-design-cat-level-modal`}
          visible={isDesignCatLevelModalVisible}
          onClose={() => setIsDesignCatLevelModalVisible(false)}
          user={userData}
          authorityType="design"
          organisationDetails={organisationDetails}
        />
      )}
      {isReviewCatLevelModalVisible && (
        <CatLevelModal
          key={`user-review-cat-level-modal`}
          visible={isReviewCatLevelModalVisible}
          onClose={() => setIsReviewCatLevelModalVisible(false)}
          user={userData}
          authorityType="issue"
          organisationDetails={organisationDetails}
        />
      )}
      {isCheckCatLevelModalVisible && (
        <CatLevelModal
          key={`user-check-cat-level-modal`}
          visible={isCheckCatLevelModalVisible}
          onClose={() => setIsCheckCatLevelModalVisible(false)}
          user={userData}
          authorityType="check"
          organisationDetails={organisationDetails}
        />
      )}
    </Card>
  );
}

export default withRouter(
  withSubscriptions({
    Component: UserItem,
    subscriptions: ["groups", "users"],
  })
);
