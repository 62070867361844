import { useState } from "react";
import { Table, message, Button, Modal, Form } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { getSimpleLabel } from "common/labels";
import { callGraphQLSimple } from "common/apiHelpers";

import Card from "Card/Card";
import UsersFilter from "UsersFilter/UsersFilter";
import InfoItem from "InfoItem/InfoItem";
import Avatar from "Avatar/Avatar";

import "./SharingTab.scss";

export default function SharingTab({ parent, queryName, users }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  async function onFormSubmit({ userId }) {
    try {
      let newSharedWithUsers = [...(parent.sharedWithUsers || []), userId];
      newSharedWithUsers = [...new Set(newSharedWithUsers)];
      await callGraphQLSimple({
        queryName,
        variables: {
          input: {
            id: parent.id,
            sharedWithUsers: newSharedWithUsers,
          },
        },
      });
      setIsLoading(false);
      setIsModalOpen(false);
      form.resetFields();
    } catch (e) {
      message.error(`An error occurred while changing the sharing settings`);
    }
  }

  return (
    <>
      <Card
        title="Shared with"
        className="sharing-tab"
        actions={
          <Button icon={<PlusCircleOutlined />} type="primary" onClick={() => setIsModalOpen(true)}>
            Share with another user
          </Button>
        }
      >
        <Table
          dataSource={parent.sharedWithUsers || []}
          pagination={{ pageSize: 100, hideOnSinglePage: true }}
          columns={[
            {
              title: "User",
              key: "user",
              align: "left",
              render: (_, userId) => {
                return <Avatar user={users.find((user) => user.id === userId)} showLabel />;
              },
            },
            {
              title: "",
              key: "actions",
              align: "right",
              render: (_, userId) => {
                return (
                  <Button
                    onClick={() => {
                      Modal.confirm({
                        title: `Remove user from shared list`,
                        content: `Are you sure you want to remove this user from the shared list?`,
                        okText: "Yes, remove",
                        cancelText: "No, cancel",
                        onOk: () => {
                          let newSharedWithUsers = (parent.sharedWithUsers || []).filter((x) => x !== userId);
                          callGraphQLSimple({
                            queryName,
                            variables: {
                              input: {
                                id: parent.id,
                                sharedWithUsers: newSharedWithUsers,
                              },
                            },
                          });
                        },
                      });
                    }}
                    icon={<DeleteOutlined />}
                  >
                    Remove
                  </Button>
                );
              },
            },
          ]}
        />
      </Card>
      <Modal
        title={`Share with another user`}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form form={form} onFinish={onFormSubmit}>
          <Form.Item
            label={`Share with`}
            name="userId"
            rules={[
              {
                required: true,
                message: `You must choose someone`,
              },
            ]}
          >
            <UsersFilter
              orderedActiveUsers={users.filter((user) => !user.isDisabled)}
              excludeList={parent.sharedWithUsers || []}
            />
          </Form.Item>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" loading={isLoading} data-cy="sharing-tab-submit-button">
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
