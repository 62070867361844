import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Table, Button, Modal, message, Typography, Tooltip, Switch } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useGetSetState } from "react-use";

import withSubscriptions from "common/withSubscriptions";
import { createInvoice } from "common/invoiceHelpers";
import { processIdForDisplay, getExpandableParamsForTable } from "common/helpers";
import { getParentWithTimesheetBlocks } from "common/timesheetHelpers";
import { getFirstValidTemplateForType } from "common/templateHelpers";
import { getSimpleLabel } from "common/labels";

import DatePicker from "DatePicker/DatePicker";
import TimesheetBlocksTable from "TimesheetBlocksTable/TimesheetBlocksTable";
import ProjectIdTag from "ProjectIdTag/ProjectIdTag";
import InfoItem from "InfoItem/InfoItem";
import Card from "Card/Card";
import Input from "Input/Input";
import ClientLogo from "ClientLogo/ClientLogo";
import BatchInvoiceTimesheetActions from "./BatchInvoiceTimesheetActions/BatchInvoiceTimesheetActions";

import "./BatchCreateInvoicesPage.scss";

export function BatchCreateInvoicesPage({
  apiUser,
  organisationDetails,
  timesheetBlocks,
  tasks,
  quoteLineItems,
  projects,
  clients,
  fetchAndSetTimesheetBlocks,
  timesheetTags,
  users,
  history,
  setProps,
  context,
  quotes,
}) {
  const [includeOnHold, setIncludeOnHold] = useState(true);
  const [startDate, setStartDate] = useState(moment().subtract(1, "year"));
  const [endDate, setEndDate] = useState(moment().add(1, "day"));
  const [getState, setState] = useGetSetState({
    selectedTimesheetBlockIds: new Set(),
  });
  const [minimumHours, setMinimumHours] = useState(0);

  let timesheetBlocksToDisplay = timesheetBlocks.filter((timesheetBlock) => {
    if (!includeOnHold && timesheetBlock.invoicingStatus === "ON_HOLD") {
      return false;
    }

    if (timesheetBlock.invoicingStatus === "WRITE_OFF") {
      return false;
    }

    if (timesheetBlock.invoicingStatus === "INVOICED") {
      return false;
    }

    return true;
  });

  let tasksWithoutQuotes = [];

  tasksWithoutQuotes.sort();

  // const [frequency, setFrequency] = useState("monthly");

  const [groupBy, setGroupBy] = useState("project"); //eslint-disable-line
  const [isCreatingInvoices, setIsCreatingInvoices] = useState(false); //eslint-disable-line
  const [countInvoicesCreated, setCountInvoicesCreated] = useState(0); //eslint-disable-line
  const [countInvoicesToCreate, setCountInvoicesToCreate] = useState(0); //eslint-disable-line
  const [updateNumber, setUpdateNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "INVOICES_BATCH_CREATE",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchAndSetTimesheetBlocks({
      organisation: apiUser.organisation,
      startAt: startDate.toISOString(),
      endAt: endDate.toISOString(),
      filter: {
        invoicingStatus: {
          ne: "INVOICED",
        },
      },
    }).then(() => {
      setIsLoading(false);
    });
  }, [updateNumber, startDate, endDate]); //eslint-disable-line

  const tableRows = getParentWithTimesheetBlocks({
    projects,
    tasks,
    clients,
    timesheetBlocks: timesheetBlocksToDisplay,
    groupBy,
    quotes,
  }).filter((row) => {
    if (minimumHours > 0) {
      return (row.hours || 0) >= minimumHours;
    }
    return true;
  });

  const tableColumns = getTableColumns();

  function getTableColumns() {
    let columns = [];
    switch (groupBy) {
      case "project":
        columns.push({
          title: `${getSimpleLabel("Project")}`,
          key: "project",
          align: "left",
          render: (_, row) => (
            <>
              <ProjectIdTag project={{ ...row.project, client: row.client }} includeTitle={false} />

              {row.project?.title}
              {row.hasTimesheetBlocksWithoutQuote && (
                <Tooltip
                  title={`One or more of the timesheet blocks associated with this ${getSimpleLabel(
                    "project"
                  )} are not linked to any ${getSimpleLabel("quotes")}`}
                >
                  <ExclamationCircleFilled className="icon-has-timesheets-without-quote" />
                </Tooltip>
              )}
              {row.hasTimesheetBlocksLinkedToRejectedQuoteLineItems && (
                <Tooltip
                  title={`One or more of the timesheet blocks associated with this ${getSimpleLabel(
                    "project"
                  )} is linked to a rejected ${getSimpleLabel("quote")} line item`}
                >
                  <ExclamationCircleFilled className="icon-has-timesheets-linked-to-rejected-quote-line-items" />
                </Tooltip>
              )}
              {row.hasTimesheetBlocksLinkedToRejectedQuotes && (
                <Tooltip
                  title={`One or more of the timesheet blocks associated with this ${getSimpleLabel(
                    "project"
                  )} is linked to a rejected ${getSimpleLabel("quote")}`}
                >
                  <ExclamationCircleFilled className="icon-has-timesheets-linked-to-rejected-quotes" />
                </Tooltip>
              )}
              {row.hasTimesheetBlocksLinkedToFullyInvoicedQuotes && (
                <Tooltip
                  title={`One or more of the timesheet blocks associated with this ${getSimpleLabel(
                    "project"
                  )} is linked to a fully-invoiced ${getSimpleLabel(
                    "quote"
                  )}. This would only be allowed if all such timesheet blocks were marked as variations.`}
                >
                  <ExclamationCircleFilled className="icon-has-timesheets-linked-to-rejected-quotes" />
                </Tooltip>
              )}
              {row.hasTimesheetBlocksOnHold && (
                <Tooltip
                  title={`One or more of the timesheet blocks associated with this ${getSimpleLabel(
                    "project"
                  )} is on hold`}
                >
                  <ExclamationCircleFilled className="icon-has-timesheets-on-hold" />
                </Tooltip>
              )}
            </>
          ),
        });

        break;
      case "client":
        break;

      default:
        break;
    }

    columns.push({
      title: getSimpleLabel("Client"),
      key: "client",
      width: 120,
      render: (_, row) => {
        if (!row.client) {
          return null;
        }

        return <ClientLogo client={row.client} />;
      },
    });

    columns.push(
      {
        title: "Hours",
        key: "hours",
        dataIndex: "hours",
        width: 100,
      },
      {
        title: "Actions",
        key: "actions",
        width: 300,
        render: (_, row) => {
          const { selectedTimesheetBlockIds } = getState();

          return (
            <div className="actions">
              <BatchInvoiceTimesheetActions
                tableRow={row}
                triggerRefresh={() => setUpdateNumber((oldNumber) => oldNumber + 1)}
                selectedTimesheetBlockIds={selectedTimesheetBlockIds}
                deselectTimesheetBlocks={(ids) => {
                  const { selectedTimesheetBlockIds } = getState();
                  let newSelectedTimesheetBlockIds = new Set(
                    [...selectedTimesheetBlockIds].filter((id) => !ids.includes(id))
                  );

                  setState({
                    selectedTimesheetBlockIds: newSelectedTimesheetBlockIds,
                  });
                }}
              />
              {row.client && (
                <Button
                  type="primary"
                  data-cy="create-invoice-for-project-button"
                  data-project-id={row.project?.id}
                  onClick={async () => {
                    if (row.hasTimesheetBlocksWithoutQuote) {
                      message.error(
                        `One or more of the timesheet blocks in this ${getSimpleLabel(
                          "project"
                        )} are not associated with a quote line item`
                      );
                      return;
                    }
                    if (row.hasTimesheetBlocksOnHold) {
                      message.error({
                        content: `One or more of the timesheet blocks in this ${getSimpleLabel(
                          "project"
                        )} is on hold. If you want to continue, please disable the switch labelled "Include on hold"`,
                        duration: 10,
                      });
                      return;
                    }

                    if (row.hasTimesheetBlocksLinkedToRejectedQuoteLineItems) {
                      message.error({
                        content: `One or more of the timesheet blocks in this ${getSimpleLabel(
                          "project"
                        )} is linked to a rejected ${getSimpleLabel("quote")} line item`,
                        duration: 10,
                      });
                      return;
                    }

                    if (row.hasTimesheetBlocksLinkedToRejectedQuotes) {
                      message.error({
                        content: `One or more of the timesheet blocks in this ${getSimpleLabel(
                          "project"
                        )} is linked to a rejected ${getSimpleLabel("quote")}`,
                        duration: 10,
                      });
                      return;
                    }

                    if (row.hasTimesheetBlocksLinkedToFullyInvoicedQuotes) {
                      message.error({
                        content: `One or more of the timesheet blocks in this ${getSimpleLabel(
                          "project"
                        )} is linked to a fully-invoiced ${getSimpleLabel(
                          "quote"
                        )}. This would only be allowed if all such timesheet blocks were marked as variations.`,
                        duration: 10,
                      });
                      return;
                    }

                    await onCreateInvoiceClick(row, history);
                    setUpdateNumber((oldNumber) => oldNumber + 1);
                  }}
                >
                  Create invoice
                </Button>
              )}
            </div>
          );
        },
      }
    );

    return columns;
  }

  function displayDetailsPerProject(projectRow) {
    const { selectedTimesheetBlockIds } = getState();
    return (
      <TimesheetBlocksTable
        blocks={projectRow.timesheetBlocks}
        timesheetTags={timesheetTags}
        includeInvoicingStatus={false}
        includeCheckboxes
        onSelectRow={(selectedRow) => {
          setState({
            selectedTimesheetBlockIds: new Set([...selectedTimesheetBlockIds, selectedRow.id]),
          });
        }}
        onDeselectRow={(selectedRow) => {
          const newSelectedTimesheetBlockIds = new Set(selectedTimesheetBlockIds);
          newSelectedTimesheetBlockIds.delete(selectedRow.id);
          setState({
            selectedTimesheetBlockIds: newSelectedTimesheetBlockIds,
          });
        }}
        selectedRowIds={selectedTimesheetBlockIds}
        includeQuoteId
        includeOnHold
        triggerRefresh={(updatedBlock) => {
          setProps({
            context: {
              ...context,
              timesheetBlocks: timesheetBlocks.map((currentBlock) => {
                if (currentBlock.id === updatedBlock.id) {
                  return updatedBlock;
                }
                return currentBlock;
              }),
            },
          });
        }}
      />
    );
  }

  async function writeOffTime(tableRow) {
    await new Promise((resolve) => {
      Modal.confirm({
        title: "Confirm write off",
        content: (
          <>
            Are you sure you want to write off{" "}
            <b>
              {tableRow.hours} hour{tableRow.hours === 1 ? "" : "s"}
            </b>{" "}
            for <b>{tableRow.project?.title}</b>?
          </>
        ),
        onOk: async () => {
          let updatePromises = [];
          for (let i = 0; i < tableRow.timesheetBlocks.length; i++) {
            updatePromises.push(
              window.callGraphQLSimple({
                message: "Failed to write off timesheet block",
                mutation: "updateTimesheetBlock",
                variables: {
                  input: {
                    id: tableRow.timesheetBlocks[i].id,
                    invoicingStatus: "WRITE_OFF",
                    invoiceId: "nothing",
                  },
                },
              })
            );
            await new Promise((resolveTimout) => setTimeout(resolveTimout, 50));
          }
          await Promise.all(updatePromises);
          resolve();
        },
        onCancel: () => {
          resolve();
        },
      });
    });
  }

  function updateProgressMessage({ content, finished, invoiceId, noTemplate, error }) {
    let messageKey = `creating-invoice`;

    if (noTemplate) {
      message.error({
        content: "There is no invoice template defined for this organisation",
        key: messageKey,
      });
      return;
    } else if (error) {
      message.error({
        content: error,
        key: messageKey,
      });
      return;
    } else if (finished) {
      let messageDuration = window.Cypress ? 0.5 : 3;
      message.success({
        content: (
          <Typography.Text onClick={() => history.push(`/invoices/${invoiceId}`)} style={{ cursor: "pointer" }}>
            Invoice <b>{processIdForDisplay(invoiceId)}</b> created. <b>Click here to go to it</b>
          </Typography.Text>
        ),
        key: messageKey,
        duration: messageDuration,
      });
      setTimeout(() => {
        message.destroy(messageKey);
      }, messageDuration * 1000 + 100);
    } else if (content) {
      message.loading({ content, key: messageKey, duration: 0 });
    }
  }

  async function onCreateInvoiceClick(tableRow, history) {
    let newInvoiceId;
    let templateId;
    try {
      templateId = getFirstValidTemplateForType({ organisationDetails, type: "INVOICE" });
    } catch (e) {
      updateProgressMessage({ noTemplate: true });
      return;
    }

    let quoteLineItemsInProject = quoteLineItems.filter((quoteLineItem) => {
      return tableRow.timesheetBlocks.some((block) => !block.variation && block.quoteLineItemId === quoteLineItem.id);
    });

    let tasksForInvoicingInProject = tasks.filter((task) => {
      if (task.projectId !== tableRow.project.id) {
        return false;
      }

      let taskHasVariationTimesheetBlocks = tableRow.timesheetBlocks.some(
        (timesheetBlock) => timesheetBlock.variation && timesheetBlock.taskId === task.id
      );
      return taskHasVariationTimesheetBlocks;
    });

    updateProgressMessage({ content: "Creating invoice..." });

    try {
      newInvoiceId = await createInvoice({
        organisationId: apiUser.organisation,
        clientId: tableRow.client.id,
        projectId: tableRow.project.id,
        taskIds: tasksForInvoicingInProject.map((task) => task.id),
        quoteIds: quoteLineItemsInProject.map((quoteLineItem) => quoteLineItem.quoteId),
        quoteLineItemIds: quoteLineItemsInProject.map((quoteLineItem) => quoteLineItem.id),
        timesheetBlockIds: tableRow.timesheetBlocks.map((timesheetBlock) => timesheetBlock.id),
        templateId,
      });
    } catch (e) {
      updateProgressMessage({ error: `Failed to create invoice: ${e.message}` });
      return;
    }

    updateProgressMessage({
      finished: true,
      invoiceId: newInvoiceId,
    });
  }

  function displayDatePicker() {
    return (
      <InfoItem
        label="Date range"
        value={
          <DatePicker.RangePicker
            allowClear={false}
            format="DD-MM-YYYY"
            dropdownClassName="timeline-window-date-range-picker"
            onChange={([startDate, endDate]) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            value={[startDate, endDate]}
            ranges={{
              "This week": [moment().startOf("week"), moment()],
              "Last week": [moment().startOf("week").subtract(1, "weeks"), moment().subtract(1, "week").endOf("week")],
              "2 weeks ago": [
                moment().startOf("week").subtract(2, "weeks"),
                moment().endOf("week").subtract(2, "weeks"),
              ],
              "3 weeks ago": [
                moment().startOf("week").subtract(3, "weeks"),
                moment().endOf("week").subtract(3, "weeks"),
              ],
              "4 weeks ago": [
                moment().startOf("week").subtract(4, "weeks"),
                moment().endOf("week").subtract(4, "weeks"),
              ],
              [`This month (${moment().format("MMMM")})`]: [moment().startOf("month"), moment()],
              [`Last month (${moment().subtract(1, "month").format("MMMM")})`]: [
                moment().startOf("month").subtract(1, "month"),
                moment().endOf("month").subtract(1, "month").endOf("month"),
              ],
              YTD: [moment().startOf("year"), moment()],
              "Previous year": [
                moment().startOf("year").subtract(1, "year"),
                moment().endOf("year").subtract(1, "year").endOf("year"),
              ],
            }}
          />
        }
      />
    );
  }

  function displayMinimumHoursPicker() {
    return (
      <InfoItem
        label={`Only show ${getSimpleLabel("projects")} with at least`}
        value={
          <Input
            numerical
            showBorder
            onChange={(minimumHours) => {
              if (String(minimumHours) === "") {
                minimumHours = 0;
              }
              setMinimumHours(parseInt(minimumHours));
            }}
            fireOnChangeWithoutBlurWithDebounce
            debounceDelay={500}
            defaultValue={String(minimumHours)}
            suffix="hours"
            className="minimum-hours-input"
          />
        }
      />
    );
  }

  return (
    <div className="batch-create-invoices-page">
      <Card
        title={`Un-invoiced hours by ${getSimpleLabel("project")} - ${tableRows.length} ${getSimpleLabel("projects")}`}
        actions={
          <div className="project-card-actions">
            {displayMinimumHoursPicker()}
            {displayDatePicker()}
            <InfoItem
              label="Include on hold"
              value={<Switch checked={includeOnHold} onChange={(checked) => setIncludeOnHold(checked)} />}
            />
          </div>
        }
        stickyHeader
      >
        <Table
          dataSource={tableRows}
          columns={tableColumns}
          pagination={{ hideOnSinglePage: true, pageSize: 500 }}
          expandable={getExpandableParamsForTable({
            render: displayDetailsPerProject,
          })}
          loading={isLoading}
        />
      </Card>

      <Modal
        maskClosable={false}
        title={`Creating invoices: ${countInvoicesCreated} out of ${countInvoicesToCreate}`}
        open={isCreatingInvoices}
        footer={null}
        className="creating-invoices-modal"
      />
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: BatchCreateInvoicesPage,
    subscriptions: [
      "apiUser",
      "organisationDetails",
      "timesheetBlocks",
      "tasks",
      "clients",
      "projects",
      "quoteLineItems",
      "timesheetTags",
      "users",
      "quotes",
    ],
  })
);
