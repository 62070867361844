import { Auth } from "aws-amplify";

export default async function saveCache(app) {
  let user;
  try {
    user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
  } catch (e) {
    // nothing, user is not logged in
  }

  if (!user) {
    return;
  }

  const organisationIdForCachingData = user.signInUserSession.idToken.payload.organisation;

  let propsThatAreNotFunctions = {};
  for (let key in app.props) {
    if (typeof app.props[key] !== "function") {
      propsThatAreNotFunctions[key] = app.props[key];
    }
  }

  let cacheKey = `rootData-${organisationIdForCachingData}`;
  window.localDatabase.setItem(cacheKey, JSON.stringify(propsThatAreNotFunctions));
}
