import React from "react";
import smooth from "smooth-polyline";
import { DeleteOutlined, CopyOutlined } from "@ant-design/icons";
import { Tooltip, InputNumber, Input, Button } from "antd";
import cx from "classnames";
import { TwitterPicker } from "react-color";

import { TOOLS } from "../ReviewSheetToolbar/ReviewSheetToolbar";
import "./DrawArea.scss";

const COLOR_OPTIONS = [
  "#004c6d",
  "#ff0000",
  "#ffffff",
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
  "#9900EF",
];

const DEFAULT_ANNOTATION_BORDER_COLOR = "#0693E3";

const LEADER_LINE_TARGET_RADIUS_PIXELS = 2;
const DRAG_DELTA_THRESHOLD_PIXELS = 5;
const FREEHAND_DRAWING_INTERVAL_MILLISECONDS = 60;

let COLLECTION_BY_ITEM_TYPE = {
  TEXT: "textBoxes",
  DRAWING: "drawings",
  RECTANGLE: "rectangles",
  ELLIPSE: "ellipses",
  LINE: "lines",
  ARROW: "arrows",
};

export const INITIAL_DRAW_STATE = {
  clickCount: 0, // this is used for processes which require several clicks to complete, such as drawing a leader line
  lines: [],
  arrows: [],
  drawings: [],
  rectangles: [],
  ellipses: [],
  textBoxes: [],
  leaderLines: [],
  isDrawing: false,
  isResizing: false,
  isDragging: false,
  isAddingTextBox: false,
  dragTarget: null,
  selectedElement: null,
  dragHandlePointX: null,
  dragHandlePointY: null,
  resizeStartPoint: null,
  dragStartPoint: null,
  firstMouseDownPoint: null,
  isTextColorPickerVisible: false,
  isBackgroundColorPickerVisible: false,
  isBorderColorPickerVisible: false,
  selectedElements: null,
  isContextMenuVisible: false,
  isMouseDown: false,
};

export default class DrawArea extends React.Component {
  lastMouseMoveTime = Date.now();
  lastMouseX = 0;
  lastMouseY = 0;
  state = { ...INITIAL_DRAW_STATE };

  componentDidMount() {
    document.addEventListener("mouseup", this.handleMouseUp);
    if (this.props.initialDrawState) {
      this.setState(this.props.initialDrawState);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleMouseUp);
  }

  generateId = () => {
    return Date.now() + Math.floor(Math.random() * 1000);
  };

  onResizeStart = (e, textBox) => {
    e.stopPropagation();

    if (textBox.type === "TEXT") {
      this.setState({
        isResizing: true,
        selectedElement: textBox,
        resizeStartPoint: [textBox.x + textBox.width, textBox.y + textBox.height],
      });
    } else if (textBox.type === "LEADER_LINE") {
      this.setState({
        isResizing: true,
        selectedElement: textBox,
        resizeStartPoint: [textBox.textX + textBox.width, textBox.textY + textBox.height],
      });
    }
  };

  onDragStart = (e, element, targetType) => {
    const { scale } = this.props;
    e.stopPropagation();
    let newState = {
      isMouseDown: true,
      dragTarget: targetType,
      selectedElement: element,
    };
    newState.firstMouseDownPoint = [e.pageX, e.pageY];
    let boundingRect = e.target.getBoundingClientRect();
    switch (element.type) {
      case "LEADER_LINE":
        newState.dragStartPoint =
          targetType === "LEADER_LINE_TARGET" ? [element.pointX, element.pointY] : [element.textX, element.textY];
        break;

      case "ARROW":
        newState.dragStartPoint = targetType === "ARROW_HEAD" ? element.pointEnd : element.pointStart;
        break;

      case "LINE":
        newState.dragStartPoint = targetType === "LINE_START" ? element.pointStart : element.pointEnd;
        break;

      case "DRAWING":
        newState.dragStartPoint = [
          element.points[0][0] + (e.clientX - boundingRect.left) / scale,
          element.points[0][1] + (e.clientY - boundingRect.top) / scale,
        ];
        break;

      default:
        newState.dragStartPoint = [
          (element.x || 0) + (e.clientX - boundingRect.left) / scale,
          (element.y || 0) + (e.clientY - boundingRect.top) / scale,
        ];
        break;
    }
    this.setState(newState);
  };

  handleMouseDown = (mouseEvent) => {
    const target = mouseEvent.target;
    const { apiUser } = this.props;

    if (target.tagName === "TEXTAREA") {
      mouseEvent.stopPropagation();
      return;
    }

    this.setState({ selectedElement: null });

    if (mouseEvent.button !== 0) {
      // button 0 is the main click
      // button 1 is the mouse wheel
      // if this is triggered for any other button, we do not want to draw
      return;
    }

    const point = this.relativeCoordinatesForEvent(mouseEvent);
    const { activeTool } = this.props;
    const commonParams = {
      id: this.generateId(),
      author: apiUser.id,
    };
    if (activeTool === TOOLS.freehand) {
      this.setState({
        drawings: [
          ...this.state.drawings,
          {
            ...commonParams,
            type: "DRAWING",
            smooth: false,
            points: [point],
            backgroundColor: "#ffffff",
            borderColor: DEFAULT_ANNOTATION_BORDER_COLOR,
          },
        ],
        isDrawing: true,
      });
    } else if (activeTool === TOOLS.rectangle) {
      this.setState({
        rectangles: [
          ...this.state.rectangles,
          {
            ...commonParams,
            type: "RECTANGLE",
            x: point[0],
            y: point[1],
            width: 1,
            height: 1,
            backgroundColor: "#ffffff",
            borderColor: DEFAULT_ANNOTATION_BORDER_COLOR,
          },
        ],
        isDrawing: true,
      });
    } else if (activeTool === TOOLS.line) {
      this.setState({
        lines: [
          ...this.state.lines,
          {
            ...commonParams,
            type: "LINE",
            pointStart: point,
            pointEnd: point,
            backgroundColor: "#ffffff",
            borderColor: DEFAULT_ANNOTATION_BORDER_COLOR,
          },
        ],
        isDrawing: true,
      });
    } else if (activeTool === TOOLS.arrow) {
      this.setState({
        arrows: [
          ...this.state.arrows,
          {
            ...commonParams,
            type: "ARROW",
            pointStart: point,
            pointEnd: point,
            backgroundColor: DEFAULT_ANNOTATION_BORDER_COLOR,
          },
        ],
        isDrawing: true,
      });
    } else if (activeTool === TOOLS.ellipse) {
      this.setState({
        ellipses: [
          ...this.state.ellipses,
          {
            ...commonParams,
            type: "ELLIPSE",
            x: point[0],
            y: point[1],
            width: 1,
            height: 1,
            backgroundColor: "#ffffff",
            borderColor: DEFAULT_ANNOTATION_BORDER_COLOR,
          },
        ],
        isDrawing: true,
      });
    } else if (activeTool === TOOLS.text) {
      this.setState(
        {
          textBoxes: [
            ...this.state.textBoxes,
            {
              ...commonParams,
              type: "TEXT",
              x: point[0] + 3,
              y: point[1] - 10,
              width: 90,
              height: 1,
              content: "",
              backgroundColor: "#ffffff",
              borderColor: "#19aae8",
              fontSize: this.props.defaultFontSize,
            },
          ],
          isDrawing: true,
        },
        () => {
          setTimeout(() => {
            const newestTextbox = this.state.textBoxes[this.state.textBoxes.length - 1];
            try {
              document.querySelector(`.annotation-textbox[data-annotation-id='${newestTextbox.id}'] textarea`).focus();
            } catch (e) {
              // nothing we can do, it just means the element isn't there for some reason
            }
          }, 500);
          this.save();
        }
      );
    } else if (activeTool === TOOLS.leaderLine) {
      if (this.state.clickCount === 0) {
        this.setState(
          {
            clickCount: 1,
            leaderLines: [
              ...this.state.leaderLines,
              {
                ...commonParams,
                type: "LEADER_LINE",
                pointX: point[0] - LEADER_LINE_TARGET_RADIUS_PIXELS,
                pointY: point[1] - LEADER_LINE_TARGET_RADIUS_PIXELS,
                textX: point[0],
                textY: point[1] - 10,
                width: 100,
                height: 16,
                content: "",
                backgroundColor: "#ffffff",
                borderColor: "#ff0000",
                fontSize: this.props.defaultFontSize,
              },
            ],
            isDrawing: true,
          },
          this.save
        );
      } else if (this.state.clickCount === 1) {
        const oldLastItem = this.state.leaderLines[this.state.leaderLines.length - 1];
        const updatedLastItem = {
          ...oldLastItem,
          complete: true,
        };

        this.setState({
          clickCount: 2,
          leaderLines: [...this.state.leaderLines.slice(0, this.state.leaderLines.length - 1), updatedLastItem],
        });
      }
    }
  };

  onChange = ({ element, collection }) => {
    this.setState(
      {
        [collection]: this.state[collection].map((crtElement) => {
          if (crtElement.id === element.id) {
            return element;
          } else {
            return crtElement;
          }
        }),
      },
      this.save
    );
  };

  save = () => {
    this.props.onChange(this.state);
  };

  handleMouseMove = (mouseEvent) => {
    if (!this.state.isDrawing && !this.state.isResizing && !this.state.isDragging && !this.state.isMouseDown) {
      return;
    }

    if (this.state.firstMouseDownPoint) {
      let mouseMoveDelta = {
        x: this.state.firstMouseDownPoint[0] - mouseEvent.pageX,
        y: this.state.firstMouseDownPoint[1] - mouseEvent.pageY,
      };
      let maxDelta = Math.max(Math.abs(mouseMoveDelta.x), Math.abs(mouseMoveDelta.y));
      if (maxDelta > DRAG_DELTA_THRESHOLD_PIXELS && !this.state.isDragging) {
        this.setState({ isDragging: true }, () => {
          this.handleMouseMove(mouseEvent);
        });
      }
    }

    mouseEvent.stopPropagation();
    const now = Date.now();

    if (
      this.state.isDrawing &&
      this.props.activeTool === TOOLS.freehand &&
      now - this.lastMouseMoveTime < FREEHAND_DRAWING_INTERVAL_MILLISECONDS
    ) {
      return;
    }

    this.lastMouseMoveTime = now;
    const point = this.relativeCoordinatesForEvent(mouseEvent);
    if (this.state.isResizing) {
      const deltaX = point[0] - this.state.resizeStartPoint[0];
      const deltaY = point[1] - this.state.resizeStartPoint[1];
      let collection = this.state.selectedElement.type === "TEXT" ? "textBoxes" : "leaderLines";
      this.onChange({
        element: {
          ...this.state.selectedElement,
          width: this.state.selectedElement.width / this.props.documentSize + deltaX / this.props.documentSize,
          height: this.state.selectedElement.height / this.props.documentSize + deltaY / this.props.documentSize,
        },
        collection,
      });
      return;
    }
    if (this.state.isDragging) {
      let deltaX = point[0] - this.state.dragStartPoint[0];
      let deltaY = point[1] - this.state.dragStartPoint[1];
      if (!this.state.selectedElement) {
        return;
      }
      if (this.state.selectedElement.type === "LEADER_LINE") {
        if (this.state.dragTarget === "LEADER_LINE_TARGET") {
          this.onChange({
            element: {
              ...this.state.selectedElement,
              pointX: this.state.selectedElement.pointX + deltaX,
              pointY: this.state.selectedElement.pointY + deltaY,
            },
            collection: "leaderLines",
          });
        } else {
          this.onChange({
            element: {
              ...this.state.selectedElement,
              textX: this.state.selectedElement.textX + deltaX,
              textY: this.state.selectedElement.textY + deltaY,
            },
            collection: "leaderLines",
          });
        }
      } else {
        const collectionName = COLLECTION_BY_ITEM_TYPE[this.state.selectedElement.type];
        if (this.state.dragTarget === "LINE_START") {
          this.onChange({
            element: {
              ...this.state.selectedElement,
              pointStart: [
                this.state.selectedElement.pointStart[0] + deltaX,
                this.state.selectedElement.pointStart[1] + deltaY,
              ],
            },
            collection: collectionName,
          });
        } else if (this.state.dragTarget === "LINE_END") {
          this.onChange({
            element: {
              ...this.state.selectedElement,
              pointEnd: [
                this.state.selectedElement.pointEnd[0] + deltaX,
                this.state.selectedElement.pointEnd[1] + deltaY,
              ],
            },
            collection: collectionName,
          });
        } else if (this.state.dragTarget === "ARROW_HEAD") {
          this.onChange({
            element: {
              ...this.state.selectedElement,
              pointEnd: [
                this.state.selectedElement.pointEnd[0] + deltaX,
                this.state.selectedElement.pointEnd[1] + deltaY,
              ],
            },
            collection: collectionName,
          });
        } else if (this.state.dragTarget === "ARROW_ORIGIN") {
          this.onChange({
            element: {
              ...this.state.selectedElement,
              pointStart: [
                this.state.selectedElement.pointStart[0] + deltaX,
                this.state.selectedElement.pointStart[1] + deltaY,
              ],
            },
            collection: collectionName,
          });
        } else if (this.state.selectedElement.type === "DRAWING") {
          let deltaX = point[0] - this.state.dragStartPoint[0];
          let deltaY = point[1] - this.state.dragStartPoint[1];
          const newPoints = this.state.selectedElement.points.map((drawingPoint) => [
            drawingPoint[0] + deltaX,
            drawingPoint[1] + deltaY,
          ]);
          this.onChange({
            element: {
              ...this.state.selectedElement,
              points: newPoints,
            },
            collection: collectionName,
          });
        } else if (collectionName) {
          this.onChange({
            element: {
              ...this.state.selectedElement,
              x: this.state.selectedElement.x + deltaX,
              y: this.state.selectedElement.y + deltaY,
            },
            collection: collectionName,
          });
        }
      }
      return;
    }

    const { activeTool } = this.props;
    if (activeTool === TOOLS.freehand) {
      const oldLastItem = this.state.drawings[this.state.drawings.length - 1];
      const updatedLastItem = {
        ...oldLastItem,
        points: [...oldLastItem.points, point],
      };
      this.setState({
        drawings: [...this.state.drawings.slice(0, this.state.drawings.length - 1), updatedLastItem],
      });
    } else if (activeTool === TOOLS.line) {
      const oldLastItem = this.state.lines[this.state.lines.length - 1];
      const updatedLastItem = {
        ...oldLastItem,
        pointEnd: point,
      };
      this.setState({
        lines: [...this.state.lines.slice(0, this.state.lines.length - 1), updatedLastItem],
      });
    } else if (activeTool === TOOLS.arrow) {
      const oldLastItem = this.state.arrows[this.state.arrows.length - 1];
      const updatedLastItem = {
        ...oldLastItem,
        pointEnd: point,
      };
      this.setState({
        arrows: [...this.state.arrows.slice(0, this.state.arrows.length - 1), updatedLastItem],
      });
    } else if (activeTool === TOOLS.rectangle) {
      const oldLastItem = this.state.rectangles[this.state.rectangles.length - 1];
      const updatedLastItem = {
        ...oldLastItem,
        width: point[0] - oldLastItem.x,
        height: point[1] - oldLastItem.y,
      };
      this.setState({
        rectangles: [...this.state.rectangles.slice(0, this.state.rectangles.length - 1), updatedLastItem],
      });
    } else if (activeTool === TOOLS.text) {
      const oldLastItem = this.state.textBoxes[this.state.textBoxes.length - 1];
      const updatedLastItem = {
        ...oldLastItem,
        width: point[0] - oldLastItem.x,
        height: point[1] - oldLastItem.y,
      };
      this.setState({
        textBoxes: [...this.state.textBoxes.slice(0, this.state.textBoxes.length - 1), updatedLastItem],
      });
    } else if (activeTool === TOOLS.leaderLine) {
      const oldLastItem = this.state.leaderLines[this.state.leaderLines.length - 1];
      const updatedLastItem = {
        ...oldLastItem,
        textX: point[0] > oldLastItem.pointX ? point[0] : point[0] - oldLastItem.width * this.props.documentSize,
        textY: point[1] - 10,
      };

      this.setState({
        leaderLines: [...this.state.leaderLines.slice(0, this.state.leaderLines.length - 1), updatedLastItem],
      });
    } else if (activeTool === TOOLS.ellipse) {
      const oldLastItem = this.state.ellipses[this.state.ellipses.length - 1];
      if (oldLastItem) {
        const updatedLastItem = {
          ...oldLastItem,
          width: point[0] - oldLastItem.x,
          height: point[1] - oldLastItem.y,
        };
        this.setState({
          ellipses: [...this.state.ellipses.slice(0, this.state.ellipses.length - 1), updatedLastItem],
        });
      }
    }
  };

  handleMouseUp = (e) => {
    let newDrawings = [];
    const { activeTool, setActiveTool } = this.props;

    this.setState({
      isMouseDown: false,
      firstMouseDownPoint: null,
    });

    if (activeTool === TOOLS.freehand) {
      if (this.state.drawings.length > 0) {
        const latestItem = this.state.drawings[this.state.drawings.length - 1];
        if (latestItem.smooth) {
          return;
        }
        const oldLastItem = this.state.drawings[this.state.drawings.length - 1];

        const updatedLastItem = {
          ...oldLastItem,
          smooth: true,
          points: smooth(oldLastItem.points),
        };

        newDrawings = [...this.state.drawings.slice(0, this.state.drawings.length - 1), updatedLastItem];
        if (this.state.isDrawing) {
          this.setState(
            {
              drawings: newDrawings,
            },
            this.save
          );
        }
      }
    }

    if (this.state.isDrawing || this.state.isResizing || this.state.isDragging) {
      if (activeTool === TOOLS.leaderLine && this.state.clickCount === 2) {
        const newestLeaderLine = this.state.leaderLines[this.state.leaderLines.length - 1];
        document.querySelector(`.annotation-leader-line[data-annotation-id='${newestLeaderLine.id}'] textarea`).focus();
      }
      if (
        ![TOOLS.leaderLine].includes(activeTool) ||
        (activeTool === TOOLS.leaderLine && this.state.clickCount === 2)
      ) {
        this.setState(
          {
            isDrawing: false,
            isResizing: false,
            isDragging: false,
            dragTarget: null,
            selectedElement: null,
            resizeStartPoint: null,
            dragStartPoint: null,
            clickCount: 0,
          },
          this.save
        );

        setActiveTool(null);
      }
    }
  };

  relativeCoordinatesForEvent(mouseEvent) {
    const nativeEvent = mouseEvent.nativeEvent;

    const x = nativeEvent.offsetX;
    const y = nativeEvent.offsetY;
    return [x, y];
  }

  displayTextOptions = (textBox, collection) => {
    return (
      <div className="text-options-panel">
        <div className="inner-container">
          <div className="text-option-item">
            <span className="label">Font Size</span>
            <InputNumber
              min={5}
              max={40}
              value={textBox.fontSize || this.props.defaultFontSize}
              onChange={(fontSize) => {
                // we use a setTimeout to prevent an issue where a global event handler on 'mouse up'
                // likely interferes with pressing the up/down buttons
                setTimeout(() => {
                  this.onChange({
                    element: {
                      ...textBox,
                      fontSize,
                    },
                    collection,
                  });
                });
              }}
            />
          </div>
          <div className="text-option-item">
            <span className="label">Font Color</span>
            <div
              className="color-box"
              style={{ backgroundColor: textBox.color || "#000" }}
              onClick={() =>
                this.setState({
                  isTextColorPickerVisible: !this.state.isTextColorPickerVisible,
                  isBackgroundColorPickerVisible: false,
                  isBorderColorPickerVisible: false,
                })
              }
            />
            {this.state.isTextColorPickerVisible ? (
              <TwitterPicker
                onChange={(newColor) => {
                  this.onChange({
                    element: {
                      ...textBox,
                      color: newColor.hex,
                    },
                    collection,
                  });
                }}
                colors={[...COLOR_OPTIONS, "#000000"]}
              />
            ) : null}
          </div>
          <div className="text-option-item">
            <span className="label">Background</span>

            <div
              className={cx("color-box", {
                transparent: textBox.backgroundColor === "transparent",
              })}
              style={{ backgroundColor: textBox.backgroundColor || "#fff" }}
              onClick={() =>
                this.setState({
                  isBackgroundColorPickerVisible: !this.state.isBackgroundColorPickerVisible,
                  isTextColorPickerVisible: false,
                  isBorderColorPickerVisible: false,
                })
              }
            />
            {this.state.isBackgroundColorPickerVisible ? (
              <TwitterPicker
                onChange={(newColor) => {
                  this.onChange({
                    element: {
                      ...textBox,
                      backgroundColor: newColor.hex === "#123456" ? "transparent" : newColor.hex,
                    },
                    collection,
                  });
                }}
                colors={[...COLOR_OPTIONS, "#123456"]}
              />
            ) : null}
          </div>
          <div className="text-option-item">
            <span className="label">Border</span>
            <div
              className={cx("color-box", {
                transparent: textBox.borderColor === "transparent",
              })}
              style={{ backgroundColor: textBox.borderColor }}
              onClick={() =>
                this.setState({
                  isBorderColorPickerVisible: !this.state.isBorderColorPickerVisible,
                  isTextColorPickerVisible: false,
                  isBackgroundColorPickerVisible: false,
                })
              }
            />
            {this.state.isBorderColorPickerVisible ? (
              <TwitterPicker
                onChange={(newColor) => {
                  this.onChange({
                    element: {
                      ...textBox,
                      borderColor: newColor.hex === "#123456" ? "transparent" : newColor.hex,
                    },
                    collection,
                  });
                }}
                colors={[...COLOR_OPTIONS, "#123456"]}
              />
            ) : null}
          </div>
          <div className="buttons-section">
            <Button
              onMouseDown={(e) => {
                e.stopPropagation();
                this.onChange({
                  element: {
                    ...textBox,
                    deleted: true,
                  },
                  collection,
                });
              }}
            >
              Delete <DeleteOutlined />
            </Button>
            <Button
              onMouseDown={(e) => {
                e.stopPropagation();
                const duplicateElement = this.getDuplicateElement(textBox);
                this.setState(
                  {
                    [collection]: [...this.state[collection], duplicateElement],
                  },
                  this.save
                );
              }}
            >
              Duplicate <CopyOutlined />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  displayAnnotationOptions = (element, collection) => {
    return (
      <div className="annotation-options-panel">
        <div className="inner-container">
          {element.type !== "LINE" && collection !== "drawings" && (
            <div className="text-option-item">
              <>
                <span className="label">Background</span>
                <div className="input">
                  <div
                    className={cx("color-box", {
                      transparent: element.backgroundColor === "transparent",
                    })}
                    style={{ backgroundColor: element.backgroundColor || "#fff" }}
                    onClick={() =>
                      this.setState({
                        isBackgroundColorPickerVisible: !this.state.isBackgroundColorPickerVisible,
                        isBorderColorPickerVisible: false,
                      })
                    }
                  />
                  {this.state.isBackgroundColorPickerVisible ? (
                    <TwitterPicker
                      onChange={(newColor) => {
                        this.onChange({
                          element: {
                            ...element,
                            backgroundColor: newColor.hex === "#123456" ? "transparent" : newColor.hex,
                          },
                          collection,
                        });
                      }}
                      colors={[...COLOR_OPTIONS, "#123456"]}
                    />
                  ) : null}
                </div>
              </>
            </div>
          )}
          {element.type !== "ARROW" && (
            <div className="text-option-item">
              <span className="label">Border</span>
              <div className="input">
                <div
                  className={cx("color-box", {
                    transparent: element.borderColor === "transparent",
                  })}
                  style={{ backgroundColor: element.borderColor }}
                  onClick={() =>
                    this.setState({
                      isBorderColorPickerVisible: !this.state.isBorderColorPickerVisible,
                      isBackgroundColorPickerVisible: false,
                    })
                  }
                />
                {this.state.isBorderColorPickerVisible ? (
                  <TwitterPicker
                    onChange={(newColor) => {
                      this.onChange({
                        element: {
                          ...element,
                          borderColor: newColor.hex === "#123456" ? "transparent" : newColor.hex,
                        },
                        collection,
                      });
                    }}
                    colors={[...COLOR_OPTIONS, "#123456"]}
                  />
                ) : null}
              </div>
            </div>
          )}

          <div className="buttons-section">
            <Button
              onMouseDown={(e) => {
                e.stopPropagation();
                this.onChange({
                  element: {
                    ...element,
                    deleted: true,
                  },
                  collection,
                });
              }}
            >
              Delete <DeleteOutlined />
            </Button>
            <Button
              onMouseDown={(e) => {
                e.stopPropagation();
                const duplicateElement = this.getDuplicateElement(element);
                this.setState(
                  {
                    [collection]: [...this.state[collection], duplicateElement],
                  },
                  this.save
                );
              }}
            >
              Duplicate <CopyOutlined />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  getDuplicateElement = (element) => {
    const { apiUser } = this.props;
    const commonParams = {
      id: this.generateId(),
      author: apiUser.id,
    };
    switch (element.type) {
      case "DRAWING":
        return {
          ...element,
          ...commonParams,
          points: element.points.map((point) => [point[0] + 30, point[1] + 30]),
        };
      case "RECTANGLE":
        return {
          ...element,
          ...commonParams,
          x: element.x + 30,
          y: element.y + 30,
        };
      case "ELLIPSE":
        return {
          ...element,
          ...commonParams,
          x: element.x + 30,
          y: element.y + 30,
        };
      case "ARROW":
        return {
          ...element,
          ...commonParams,

          pointStart: [element.pointStart[0] + 30, element.pointStart[1] + 30],
          pointEnd: [element.pointEnd[0] + 30, element.pointEnd[1] + 30],
        };
      case "LINE":
        return {
          ...element,
          ...commonParams,

          pointStart: [element.pointStart[0] + 30, element.pointStart[1] + 30],
          pointEnd: [element.pointEnd[0] + 30, element.pointEnd[1] + 30],
        };
      case "TEXT":
        return {
          ...element,
          ...commonParams,
          x: element.x + 30,
          y: element.y + 30,
        };
      case "LEADER_LINE":
        return {
          ...element,
          ...commonParams,

          pointX: element.pointX + 30,
          pointY: element.pointY + 30,
          textX: element.textX + 30,
          textY: element.textY + 30,
        };
      default:
        return;
    }
  };

  onTextChange = (e, textBox, collection) => {
    this.onChange({
      element: {
        ...textBox,
        content: e.target.value,
      },
      collection,
    });
  };

  displayDrawing = () => {
    const { highlightedAnnotationId, scale, defaultFontSize, reviewIsActive, apiUser, users } = this.props;

    const { drawings, textBoxes, leaderLines, rectangles, lines, arrows, ellipses } = this.state;

    return (
      <>
        <svg className="drawing">
          {drawings
            .filter((x) => !x.deleted && !x.resolved)
            .map((drawing) => {
              const pathData =
                "M " +
                drawing.points
                  .map((p) => {
                    return `${p[0]} ${p[1]}`;
                  })
                  .join(" L ");

              return (
                <Tooltip
                  key={drawing.id}
                  trigger={reviewIsActive ? ["click"] : []}
                  title={this.displayAnnotationOptions(drawing, "drawings")}
                  overlayClassName="annotation-options-container"
                  onVisibleChange={() =>
                    this.setState({
                      isBackgroundColorPickerVisible: false,
                      isBorderColorPickerVisible: false,
                    })
                  }
                >
                  <path
                    className="path"
                    d={pathData}
                    onMouseDown={(e) => this.onDragStart(e, drawing)}
                    fill={"transparent"}
                    stroke={drawing.borderColor || "#19aae8"}
                    strokeWidth={0.5}
                  />
                </Tooltip>
              );
            })}
          {lines
            .filter((x) => !x.deleted)
            .map((line) => {
              return (
                <Tooltip
                  key={line.id}
                  trigger={reviewIsActive ? ["click"] : []}
                  title={this.displayAnnotationOptions(line, "lines")}
                  overlayClassName="annotation-options-container"
                  onVisibleChange={() =>
                    this.setState({
                      isBackgroundColorPickerVisible: false,
                      isBorderColorPickerVisible: false,
                    })
                  }
                >
                  <g
                    className={cx("svg-line", {
                      selected: this.state.selectedElement?.id === line.id,
                    })}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      this.setState({ selectedElement: line });
                    }}
                  >
                    <line
                      className="svg-annotation-line"
                      x1={line.pointStart[0]}
                      y1={line.pointStart[1]}
                      x2={line.pointEnd[0]}
                      y2={line.pointEnd[1]}
                      stroke={line.borderColor || DEFAULT_ANNOTATION_BORDER_COLOR}
                    />
                    <line
                      className="svg-annotation-line-hit-area"
                      x1={line.pointStart[0]}
                      y1={line.pointStart[1]}
                      x2={line.pointEnd[0]}
                      y2={line.pointEnd[1]}
                    />
                    <circle
                      cx={line.pointStart[0]}
                      cy={line.pointStart[1]}
                      r={3}
                      className="line-start-drag-handle"
                      onMouseDown={(e) => this.onDragStart(e, line, "LINE_START")}
                    />
                    <circle
                      cx={line.pointEnd[0]}
                      cy={line.pointEnd[1]}
                      r={3}
                      className="line-end-drag-handle"
                      onMouseDown={(e) => this.onDragStart(e, line, "LINE_END")}
                    />
                  </g>
                </Tooltip>
              );
            })}
          {leaderLines
            .filter((x) => !x.deleted && !x.resolved)
            .map((leaderLine) => {
              let x1 = leaderLine.pointX + LEADER_LINE_TARGET_RADIUS_PIXELS;
              let x2 =
                leaderLine.textX + leaderLine.width / 2 > leaderLine.pointX
                  ? leaderLine.textX
                  : leaderLine.textX + leaderLine.width * this.props.documentSize;
              let y1 = leaderLine.pointY + LEADER_LINE_TARGET_RADIUS_PIXELS * this.props.documentSize;

              let y2 = leaderLine.textY + (leaderLine.fontSize + 3 || defaultFontSize) * this.props.documentSize;

              return (
                <line
                  className="svg-leader-line"
                  key={`svg-leader-line-${leaderLine.id}`}
                  stroke={leaderLine.borderColor}
                  x1={x1}
                  y1={y1}
                  x2={x2}
                  y2={y2}
                  style={{ strokeWidth: this.props.documentSize }}
                />
              );
            })}
        </svg>
        {arrows
          .filter((x) => !x.deleted)
          .map((arrow) => {
            // angle in degrees
            let degrees =
              (Math.atan2(arrow.pointEnd[1] - arrow.pointStart[1], arrow.pointEnd[0] - arrow.pointStart[0]) * 180) /
              Math.PI;

            const distanceX = arrow.pointStart[0] - arrow.pointEnd[0];
            const distanceY = arrow.pointStart[1] - arrow.pointEnd[1];

            const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

            return (
              <Tooltip
                key={arrow.id}
                trigger={reviewIsActive ? ["click"] : []}
                title={this.displayAnnotationOptions(arrow, "arrows")}
                overlayClassName="annotation-options-container"
                onVisibleChange={() =>
                  this.setState({
                    isBackgroundColorPickerVisible: false,
                    isBorderColorPickerVisible: false,
                  })
                }
              >
                <div
                  key={arrow.id}
                  className={cx("annotation-arrow", {
                    selected: this.state.selectedElement?.id === arrow.id,
                  })}
                  style={{
                    top: arrow.pointStart[1],
                    left: arrow.pointStart[0],
                    transform: `rotate(${degrees}deg)`,
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    this.setState({ selectedElement: arrow });
                  }}
                >
                  <div
                    className="arrow-origin-drag-handle"
                    onMouseDown={(e) => this.onDragStart(e, arrow, "ARROW_ORIGIN")}
                  />
                  <div
                    className="arrow-body"
                    style={{
                      width: distance,
                      backgroundColor: arrow.backgroundColor || DEFAULT_ANNOTATION_BORDER_COLOR,
                    }}
                  />
                  <div
                    className="arrow-head"
                    style={{
                      borderLeftColor: arrow.backgroundColor || DEFAULT_ANNOTATION_BORDER_COLOR,
                    }}
                  >
                    <div
                      className="arrow-head-drag-handle"
                      onMouseDown={(e) => this.onDragStart(e, arrow, "ARROW_HEAD")}
                    />
                  </div>
                </div>
              </Tooltip>
            );
          })}
        {rectangles
          .filter((x) => !x.deleted && !x.resolved)
          .map((rectangle) => {
            return (
              <Tooltip
                key={rectangle.id}
                trigger={reviewIsActive ? ["click"] : []}
                title={this.displayAnnotationOptions(rectangle, "rectangles")}
                overlayClassName="annotation-options-container"
                onVisibleChange={() =>
                  this.setState({
                    isBackgroundColorPickerVisible: false,
                    isBorderColorPickerVisible: false,
                  })
                }
              >
                <div
                  className="annotation-rectangle"
                  style={{
                    top: rectangle.y,
                    left: rectangle.x,
                    width: rectangle.width + "px",
                    height: rectangle.height + "px",
                    borderColor: rectangle.borderColor || DEFAULT_ANNOTATION_BORDER_COLOR,
                  }}
                  onMouseDown={(e) => this.onDragStart(e, rectangle)}
                >
                  <div
                    className="annotation-rectangle-background"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: rectangle.backgroundColor || "transparent",
                      opacity: 0.7,
                      pointerEvents: "none",
                    }}
                  />
                </div>
              </Tooltip>
            );
          })}
        {ellipses
          .filter((x) => !x.deleted && !x.resolved)
          .map((ellipse) => {
            return (
              <Tooltip
                key={ellipse.id}
                trigger={reviewIsActive ? ["click"] : []}
                title={this.displayAnnotationOptions(ellipse, "ellipses")}
                overlayClassName="annotation-options-container"
                onVisibleChange={() =>
                  this.setState({
                    isBackgroundColorPickerVisible: false,
                    isBorderColorPickerVisible: false,
                  })
                }
              >
                <div
                  className="annotation-ellipse"
                  style={{
                    top: ellipse.y,
                    left: ellipse.x,
                    width: ellipse.width + "px",
                    height: ellipse.height + "px",
                    borderColor: ellipse.borderColor || DEFAULT_ANNOTATION_BORDER_COLOR,
                  }}
                  onMouseDown={(e) => this.onDragStart(e, ellipse)}
                >
                  <div
                    className="annotation-ellipse-background"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: ellipse.backgroundColor || "transparent",
                      opacity: 0.7,
                      pointerEvents: "none",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              </Tooltip>
            );
          })}
        {textBoxes
          .filter((x) => !x.deleted && !x.resolved)
          .map((textBox) => {
            const authorDetails = users.find((x) => x.id === textBox.author);
            return (
              <Tooltip
                key={textBox.id}
                trigger={!reviewIsActive || (textBox.author && apiUser.id !== textBox.author) ? [] : ["click"]}
                title={this.displayTextOptions(textBox, "textBoxes")}
                overlayClassName="text-options-container"
                onVisibleChange={() =>
                  this.setState({
                    isTextColorPickerVisible: false,
                    isBackgroundColorPickerVisible: false,
                    isBorderColorPickerVisible: false,
                  })
                }
              >
                <div
                  className={cx("annotation-textbox", {
                    highlight: highlightedAnnotationId === textBox.id,
                  })}
                  style={{
                    top: textBox.y + "px",
                    left: textBox.x + "px",
                    width: textBox.width,
                    transform: `scale(${this.props.documentSize})`,
                  }}
                  data-annotation-id={textBox.id}
                  onMouseEnter={() => this.props.onHighlightAnnotation(textBox.id)}
                  onMouseLeave={() => this.props.onHighlightAnnotation(null)}
                >
                  <div
                    className="textbox-background"
                    style={{
                      backgroundColor: textBox.backgroundColor || "#fff",
                    }}
                  />
                  <div className="textarea-scroll-to-marker" />
                  <Input.TextArea
                    className="textarea"
                    style={{
                      fontSize: (textBox.fontSize || defaultFontSize) + "px",
                      color: textBox.color || "#000",
                      borderColor: textBox.borderColor || "#004c6d",
                    }}
                    autoSize={{ minRows: 1 }}
                    value={textBox.content}
                    onChange={(e) => this.onTextChange(e, textBox, "textBoxes")}
                    disabled={!reviewIsActive || (textBox.author && apiUser.id !== textBox.author)}
                  />

                  {reviewIsActive && (!textBox.author || apiUser.id === textBox.author) && (
                    <>
                      <div
                        className="resize-area"
                        style={{ transform: `scaleX(${1 / scale})` }}
                        onMouseDown={(e) => this.onResizeStart(e, textBox)}
                      />
                      <div
                        className="drag-area-left"
                        style={{
                          transform: `scaleX(${1 / scale})`,
                          backgroundColor: textBox.borderColor || "#004c6d",
                        }}
                        onMouseDown={(e) => this.onDragStart(e, textBox)}
                      />
                      <div
                        className="drag-area-top"
                        style={{ transform: `scaleY(${1 / scale})` }}
                        onMouseDown={(e) => this.onDragStart(e, textBox)}
                      />
                      <div
                        className="drag-area-bottom"
                        style={{ transform: `scaleY(${1 / scale})` }}
                        onMouseDown={(e) => this.onDragStart(e, textBox)}
                      />
                    </>
                  )}
                  {authorDetails && (
                    <div className="author-marker">
                      {authorDetails.firstName} {authorDetails.lastName}
                    </div>
                  )}
                </div>
              </Tooltip>
            );
          })}
        {leaderLines
          .filter((x) => !x.deleted && !x.resolved)
          .map((leaderLine) => {
            let userCanEditComment = apiUser.isHidden || !leaderLine.author || apiUser.id === leaderLine.author;
            const authorDetails = users.find((x) => x.id === leaderLine.author);
            return (
              <div
                key={leaderLine.id}
                className="annotation-leader-line"
                data-annotation-id={leaderLine.id}
                data-value={leaderLine.content}
              >
                <div
                  className="leader-line-target"
                  style={{
                    top: leaderLine.pointY,
                    left: leaderLine.pointX,
                    "--background-color": leaderLine.borderColor,
                    transform: `scale(${this.props.documentSize})`,
                    transformOrigin: "50% 50%",
                  }}
                  onMouseDown={(e) => this.onDragStart(e, leaderLine, "LEADER_LINE_TARGET")}
                />

                <Tooltip
                  key={leaderLine.id}
                  trigger={!reviewIsActive || !userCanEditComment ? [] : ["click"]}
                  title={this.displayTextOptions(leaderLine, "leaderLines")}
                  overlayClassName="text-options-container"
                  onVisibleChange={() =>
                    this.setState({
                      isTextColorPickerVisible: false,
                      isBackgroundColorPickerVisible: false,
                      isBorderColorPickerVisible: false,
                    })
                  }
                >
                  <div
                    className={cx("annotation-textbox", {
                      highlight: highlightedAnnotationId === leaderLine.id,
                    })}
                    style={{
                      top: leaderLine.textY + "px",
                      left: leaderLine.textX + "px",
                      width: leaderLine.width,
                      transform: `scale(${this.props.documentSize})`,
                      transformOrigin: `0 0`,
                    }}
                    onMouseEnter={() => this.props.onHighlightAnnotation(leaderLine.id)}
                    onMouseLeave={() => this.props.onHighlightAnnotation(null)}
                  >
                    <div
                      className="textbox-background"
                      style={{
                        backgroundColor: leaderLine.backgroundColor || "#fff",
                      }}
                    />
                    <div className="textarea-scroll-to-marker" />
                    <Input.TextArea
                      className="textarea"
                      style={{
                        padding: 3,
                        fontSize: (leaderLine.fontSize || defaultFontSize) + "px",
                        color: leaderLine.color || "#000",
                        borderColor: leaderLine.borderColor || "#004c6d",
                      }}
                      autoSize={{ minRows: 1 }}
                      value={leaderLine.content}
                      onChange={(e) => this.onTextChange(e, leaderLine, "leaderLines")}
                      disabled={!reviewIsActive || !userCanEditComment}
                      key={leaderLine.fontSize}
                    />
                    {reviewIsActive && userCanEditComment && (
                      <>
                        <div
                          className="resize-area"
                          style={{ transform: `scaleX(${1 / scale})` }}
                          onMouseDown={(e) => this.onResizeStart(e, leaderLine)}
                        />

                        <div
                          className="drag-area-left"
                          style={{ transform: `scaleX(${1 / scale})` }}
                          onMouseDown={(e) => this.onDragStart(e, leaderLine, leaderLine, "LEADER_LINE_TEXTBOX")}
                        />
                        <div
                          className="drag-area-top"
                          style={{ transform: `scaleY(${1 / scale})` }}
                          onMouseDown={(e) => this.onDragStart(e, leaderLine, leaderLine, "LEADER_LINE_TEXTBOX")}
                        />
                        <div
                          className="drag-area-bottom"
                          style={{ transform: `scaleY(${1 / scale})` }}
                          onMouseDown={(e) => this.onDragStart(e, leaderLine, leaderLine, "LEADER_LINE_TEXTBOX")}
                        />
                      </>
                    )}
                    {authorDetails && (
                      <div className="author-marker">
                        {authorDetails.firstName} {authorDetails.lastName}
                      </div>
                    )}
                  </div>
                </Tooltip>
              </div>
            );
          })}
      </>
    );
  };

  render() {
    const { zoom, height } = this.props;
    let style = {};

    style.width = "100%";
    style.height = "100%";
    if (zoom !== undefined) {
      style.transform = `scale(${zoom})`;

      if (height === undefined) {
        style.width = `${100 / zoom}%`;
        style.height = `${100 / zoom}%`;
      }
    }

    if (height !== undefined) {
      style.height = height;
    }

    let areEventsDisabled = !!(
      this.state.isDrawing ||
      this.state.isResizing ||
      this.state.isDragging ||
      this.props.activeTool
    );

    return (
      <div
        className={cx("draw-area", {
          "disable-events": areEventsDisabled,
        })}
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        style={style}
      >
        {this.props.activeTool !== TOOLS.hideAnnotations ? this.displayDrawing() : null}
      </div>
    );
  }
}
