import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import "./Explanation.scss";

interface ExplanationProps {
  title: String | JSX.Element;
}

export default function Explanation({ title, ...propsForTooltip }: ExplanationProps) {
  let overlayInnerStyle = {
    width: Math.min(window.innerWidth - 50, 400),
  };

  return (
    <Tooltip title={title} overlayInnerStyle={overlayInnerStyle} {...propsForTooltip}>
      <QuestionCircleFilled className="explanation-icon" />
    </Tooltip>
  );
}
