import moment from "moment";

export function getReportTaskRevisions({ task, users, sheet, columns, useInitials = false }) {
  if (!task || !users) {
    return null;
  }
  columns = columns || [
    {
      title: "Revision",
      dataIndex: "name",
      key: "name",
      width: "50",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
    },
    {
      title: "Revision Date",
      dataIndex: "reviewAcceptDate",
      key: "reviewAcceptDate",
    },
    {
      title: "Reviewed By",
      dataIndex: "checkedBy",
      key: "checkedBy",
    },
  ];

  const dataSource = (sheet || task).revisions.items
    .filter((taskRevision) => !taskRevision.isArchived)
    .map((revision) => {
      let reviewerElement = null;
      let authorElement = null;

      const reviewerUserData = users.find((user) => user.id === revision.checkedBy);
      const authorUserData = users.find((user) => user.id === revision.author);
      if (reviewerUserData) {
        if (useInitials) {
          reviewerElement = `${reviewerUserData.firstName?.charAt(0)}${reviewerUserData.lastName?.charAt(0)}`;
        } else {
          reviewerElement = `${reviewerUserData.firstName} ${reviewerUserData.lastName}`;
        }
      }
      if (authorUserData) {
        if (useInitials) {
          authorElement = `${authorUserData.firstName?.charAt(0)}${authorUserData.lastName?.charAt(0)}`;
        } else {
          authorElement = `${authorUserData.firstName} ${authorUserData.lastName}`;
        }
      }

      return {
        ...revision,
        createdAt: moment(revision.createdAt).format("DD-MM-YYYY"),
        reviewAcceptDate: revision.reviewAcceptDate
          ? moment(revision.reviewAcceptDate).format("DD-MM-YYYY")
          : moment(revision.createdAt).format("DD-MM-YYYY"),
        checkedBy: reviewerElement,
        author: authorElement,
        name: revision.name,
      };
    });
  return { columns, data: dataSource };
}
