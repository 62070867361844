const fields = [
  {
    id: "userFullName",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Full name",
    value: ({ user }) => {
      if (!user) {
        return "";
      }

      return `${user.firstName ? `${user.firstName} ` : ""}${user.lastName || ""}`;
    },
  },
  {
    id: "userSignature",
    fieldTypes: ["signature"],
    label: "User signature",
    value: ({ user }) => {
      if (!user) {
        return undefined;
      }

      return {
        image: user.signature,
        firstName: user.firstName,
        lastName: user.lastName,
      };
    },
  },
  {
    id: "userPosition",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Position",
    value: ({ user }) => {
      if (!user) {
        return "";
      }

      return `${user.position || ""}`;
    },
  },
  {
    id: "userQualifications",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Qualifications",
    value: ({ user }) => {
      if (!user) {
        return "";
      }

      return `${user.qualifications || ""}`;
    },
  },
  {
    id: "userPhone1",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Phone 1",
    value: ({ user }) => {
      if (!user) {
        return "";
      }

      return `${user.phone1 || ""}`;
    },
  },
  {
    id: "userPhone2",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Phone 2",
    value: ({ user }) => {
      if (!user) {
        return "";
      }

      return `${user.phone2 || ""}`;
    },
  },
  {
    id: "userEmail",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Email",
    value: ({ user }) => {
      if (!user) {
        return "";
      }

      return `${user.id || ""}`;
    },
  },
  {
    id: "userId",
    type: "string",
    fieldTypes: ["textfield"],
    label: "ID",
    value: ({ user }) => {
      if (!user) {
        return "";
      }

      return `${user.id || ""}`;
    },
  },
  {
    id: "userPositionAndQualifications",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Position and qualifications",
    value: ({ user }) => {
      if (!user) {
        return "";
      }

      return `${user.position ? `${user.position} ` : ""}${user.qualifications || ""}`;
    },
  },
  {
    id: "userProfitAmountByTaskAssignee",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Profit amount by task assignee",
    value: ({ user, tasks }) => {
      if (!user) {
        return "";
      }

      if (!tasks) {
        tasks = [];
      }

      const userTasks = tasks.filter((task) => task.assignedTo === user.id);
      const amountBudget = userTasks.reduce((acc, task) => acc + task.amountBudget, 0);
      const amountSpent = userTasks.reduce((acc, task) => acc + task.amountSpent, 0);
      return amountBudget - amountSpent;
    },
  },
  {
    id: "userProfitPercentageByTaskAssignee",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Profit percentage by task assignee",
    value: ({ user, tasks }) => {
      if (!user) {
        return "";
      }

      if (!tasks) {
        tasks = [];
      }

      const userTasks = tasks.filter((task) => task.assignedTo === user.id);
      const amountBudget = userTasks.reduce((acc, task) => acc + task.amountBudget, 0);
      const amountSpent = userTasks.reduce((acc, task) => acc + task.amountSpent, 0);
      if (amountBudget === 0) {
        if (amountSpent === 0) {
          return 0;
        }
        return "";
      } else {
        return Math.round(((amountBudget - amountSpent) / amountBudget) * 100);
      }
    },
  },
  {
    id: "userProfitAmountByProjectAssignee",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Profit amount by project assignee",
    value: ({ user, projects }) => {
      if (!user) {
        return "";
      }

      if (!projects) {
        projects = [];
      }

      const userTasks = projects.filter((project) => project.assignedTo === user.id);
      const amountBudget = userTasks.reduce((acc, project) => acc + project.amountBudget, 0);
      const amountSpent = userTasks.reduce((acc, project) => acc + project.amountSpent, 0);
      return amountBudget - amountSpent;
    },
  },
  {
    id: "userProfitPercentageByProjectAssignee",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Profit percentage by project assignee",
    value: ({ user, projects }) => {
      if (!user) {
        return "";
      }

      if (!projects) {
        projects = [];
      }

      const userProjects = projects.filter((project) => project.assignedTo === user.id);
      const amountBudget = userProjects.reduce((acc, project) => acc + project.amountBudget, 0);
      const amountSpent = userProjects.reduce((acc, project) => acc + project.amountSpent, 0);
      if (amountBudget === 0) {
        if (amountSpent === 0) {
          return 0;
        }
        return "";
      } else {
        return Math.round(((amountBudget - amountSpent) / amountBudget) * 100);
      }
    },
  },
];
export function getFields() {
  return fields;
}
