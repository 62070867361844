import moment from "moment";
import { roundToDecimals, roundToQuarter } from "common/mathHelpers";

const fields = [
  {
    id: "clientName",
    fieldTypes: ["textfield"],
    label: "Name",
    value: ({ client }) => {
      return client?.name;
    },
  },
  {
    id: "clientInitials",
    fieldTypes: ["textfield"],
    label: "Initials",
    value: ({ client }) => {
      return client?.initials;
    },
  },
  {
    id: "clientId",
    fieldTypes: ["textfield"],
    label: "Id",
    value: ({ client }) => client?.id,
  },
  {
    id: "clientLogo",
    fieldTypes: ["image"],
    label: "Logo",
    value: ({ client }) => client?.key,
  },
  {
    id: "clientCreatedAtDate",
    fieldTypes: ["textfield"],
    label: "Creation date",
    isDate: true,
    value: ({ client, dateFormat }) => moment(client.createdAt).format(dateFormat),
  },
  {
    id: "clientTotalInvoiced",
    fieldTypes: ["textfield"],
    label: "Total invoiced amount",
    value: ({ client, invoices }) => {
      if (!client || !invoices) {
        return "";
      }

      let totalInvoiced = invoices
        .filter((invoice) => {
          return invoice.clientId === client.id;
        })
        .reduce((sum, invoice) => {
          return roundToDecimals(sum + invoice.total);
        }, 0);
      return totalInvoiced;
    },
  },
  {
    id: "clientTotalInvoicedWithoutTax",
    fieldTypes: ["textfield"],
    label: "Total invoiced amount without VAT",
    value: ({ client, invoices }) => {
      if (!client || !invoices) {
        return "";
      }

      let totalInvoicedWithoutTax = invoices
        .filter((invoice) => {
          return invoice.clientId === client.id;
        })
        .reduce((sum, invoice) => {
          return roundToDecimals(sum + invoice.subtotal);
        }, 0);
      return totalInvoicedWithoutTax;
    },
  },
  {
    id: "clientDefaultAddress",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Default address full details",
    value: ({ client }) => {
      const firstAddress = client.addresses && client.addresses[0];
      if (!firstAddress) {
        return "";
      }
      const { houseName, streetName, streetNumber, postcode, county, city, country } = firstAddress;
      let address = "";
      if (houseName) {
        address += `${houseName}\n`;
      }
      if (streetName || streetNumber) {
        if (streetNumber) {
          address += `${streetNumber} `;
        }
        if (streetName) {
          address += `${streetName} `;
        }

        address += "\n";
      }

      if (city) {
        address += `${city}\n`;
      }
      if (county) {
        address += `${county}\n`;
      }
      if (postcode) {
        address += `${postcode}\n`;
      }
      if (country) {
        address += `${country}\n`;
      }
      return address;
    },
  },
  {
    id: "timesheetBlocksInClient",
    fieldTypes: ["repeatFor"],
    label: "Each timesheet block in client",
    repeatForFieldName: "timesheetBlock",
    value: async ({ client }) => {
      if (!client || !client.timesheetBlocks) {
        return [];
      }

      return client.timesheetBlocks;
    },
  },
  {
    id: "invoicesInClient",
    fieldTypes: ["repeatFor"],
    label: "Each invoice in client",
    repeatForFieldName: "invoice",
    value: async ({ client, organisationDetails, invoices }) => {
      if (!client || !organisationDetails) {
        return [];
      }

      invoices =
        invoices || (await listInvoicesByClient({ organisation: organisationDetails.id, clientId: client.id }));
      return invoices;
    },
  },
  {
    id: "invoicesInClientTotalDue",
    label: "Total remaining due in invoices",

    value: async ({ client, organisationDetails, invoices }) => {
      if (!client || !organisationDetails) {
        return [];
      }

      invoices =
        invoices || (await listInvoicesByClient({ organisation: organisationDetails.id, clientId: client.id }));
      const totalDue = invoices.reduce((sum, invoice) => {
        return roundToDecimals(sum + roundToDecimals(invoice.total - invoice.amountPaid));
      }, 0);
      return totalDue;
    },
  },

  {
    id: "timesheetBlocksInClientTotalDuration",
    label: "Timesheet blocks - total duration",
    value: async ({ client }) => {
      if (!client || !client.timesheetBlocks) {
        return [];
      }

      return client.timesheetBlocks.reduce((sum, timesheetBlock) => {
        let durationHours = roundToQuarter(
          moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
        );
        return roundToQuarter(sum + durationHours);
      }, 0);
    },
  },
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  {
    id: "timesheetBlocksInClientDuration",
    label: "Timesheet block duration",
    value: async ({ organisationDetails, client }) => {
      if (!organisationDetails) {
        return [];
      }

      let timesheetBlocks = client.timesheetBlocks;
      const totalDuration = timesheetBlocks.reduce((sum, timesheetBlock) => {
        let durationHours = roundToQuarter(
          moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
        );
        return roundToQuarter(sum + durationHours);
      }, 0);

      return totalDuration;
    },
  },
  {
    id: "timesheetBlocksInClientSiteVisitCount",
    label: "Timesheet block site visit count",
    value: async ({ organisationDetails, client }) => {
      if (!organisationDetails) {
        return [];
      }

      let timesheetBlocks = client.timesheetBlocks;
      let timesheetBlocksAssociatedWithSiteVisits = timesheetBlocks.filter((timesheetBlock) => timesheetBlock.onSite);
      const individualSiteVisitsByProjectAndDate = [];
      timesheetBlocksAssociatedWithSiteVisits.forEach((timesheetBlock) => {
        const key = `${timesheetBlock.projectId}_${moment(timesheetBlock.startAt).format("YYYY-MM-DD")}`;
        if (!individualSiteVisitsByProjectAndDate.includes(key)) {
          individualSiteVisitsByProjectAndDate.push(key);
        }
      });
      return individualSiteVisitsByProjectAndDate.length;
    },
  },
  {
    id: "timesheetBlocksInClientSiteVisitDuration",
    label: "Timesheet block site visit duration",
    value: async ({ organisationDetails, client }) => {
      if (!organisationDetails) {
        return [];
      }

      let timesheetBlocks = client.timesheetBlocks;
      let timesheetBlocksAssociatedWithSiteVisits = timesheetBlocks.filter((timesheetBlock) => timesheetBlock.onSite);

      const totalDuration = timesheetBlocksAssociatedWithSiteVisits.reduce((sum, timesheetBlock) => {
        let durationHours = roundToQuarter(
          moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
        );
        return roundToQuarter(sum + durationHours);
      }, 0);

      return totalDuration;
    },
  },
  {
    id: "tasksInClientWithTimesheets",
    label: "Tasks with timesheet blocks",
    fieldTypes: ["repeatFor"],
    repeatForFieldName: "task",
    value: async ({ organisationDetails, client }) => {
      if (!organisationDetails) {
        return [];
      }

      let tasksWithTimesheetBlocks = [];
      let timesheetBlocks = client.timesheetBlocks;
      let tasks = client.tasks;

      if (tasks && tasks.length > 0) {
        for (let task of tasks) {
          let taskTimesheetBlocks = timesheetBlocks.filter((block) => block.taskId === task.id);
          if (taskTimesheetBlocks.length > 0) {
            tasksWithTimesheetBlocks.push({
              ...task,
              timesheetBlocks: taskTimesheetBlocks.map((block) => {
                return {
                  ...block,
                  task: tasks?.find((task) => task.id === block.taskId),
                };
              }),
            });
          }
        }
      }

      return tasksWithTimesheetBlocks;
    },
  },
  {
    id: "taskInClientCountWithTimesheets",
    label: "Task count with timesheet blocks",
    value: async ({ organisationDetails, client }) => {
      if (!organisationDetails) {
        return [];
      }

      let timesheetBlocks = client.timesheetBlocks;
      let tasks = client.tasks;

      let taskCount = 0;
      if (tasks && tasks.length > 0) {
        for (let task of tasks) {
          let taskTimesheetBlocks = timesheetBlocks.filter((block) => block.taskId === task.id);
          if (taskTimesheetBlocks.length > 0) {
            taskCount++;
          }
        }
      }

      return taskCount;
    },
  },
];

export function getFields() {
  return fields;
}

async function listInvoicesByClient({ clientId, organisation }) {
  let items = [];
  let params = {
    organisation,
    limit: 1000,
  };

  while (true) {
    let response;
    if (global.isBrowser) {
      response = await window.callGraphQLSimple({
        message: "Failed to list the invoices",
        queryCustom: "listInvoicesByOrganisation",
        variables: params,
        filter: {
          clientId: {
            eq: clientId,
          },
        },
      });
    } else {
      response = await global.callGraphQLSimple({
        message: "Failed to list the invoices",
        queryName: "listInvoicesByOrganisation",
        variables: params,
        filter: {
          clientId: {
            eq: clientId,
          },
        },
      });
    }

    const pageOfItems = response.data.listInvoicesByOrganisation.items;
    params.nextToken = response.data.listInvoicesByOrganisation.nextToken;
    items = [...items, ...pageOfItems];

    if (!params.nextToken) {
      break;
    }
  }

  items = items.filter((task) => !task.isArchived);

  return items;
}

export const label = "Client";
