import { useState } from "react";
import { Form, Modal, Button } from "antd";

import { getExcludedReviewerList } from "common/helpers";
import { annotateAllFilesInTaskRevision, submitReview } from "ReviewPage/reviewHelpers";
import withSubscriptions from "common/withSubscriptions";
import { getLatestRevision } from "common/shared";
import { sendReviewRequestNotification } from "common/notificationHelpers";
import { callGraphQLSimple } from "common/apiHelpers";

import UsersFilter from "UsersFilter/UsersFilter";

import "./RequestReviewModal.scss";

export function RequestReviewModal({ onClose, task, users, apiUser, visible, organisationDetails }) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const taskRevision = getLatestRevision(task);

  function closeModal() {
    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  async function onSubmit({ reviewer }) {
    setIsLoading(true);

    if (reviewer === apiUser.id) {
      try {
        await new Promise((resolve, reject) => {
          let confirmationModal = Modal.confirm({
            title: "Confirm reviewer",
            className: "review-yourself-modal",
            content: <>Are you sure you want to assign yourself as the reviewer?</>,
            onOk: () => {
              confirmationModal.destroy();
              resolve();
            },
            onCancel: () => {
              confirmationModal.destroy();
              reject();
            },
          });
        });
      } catch (e) {
        // it means the user has said no, so the promise got rejected
        setIsLoading(false);
        return;
      }
    }

    if (taskRevision.reviewStatus === "CLOSED") {
      const review = (
        await callGraphQLSimple({
          message: "Failed to fetch existing review review",
          queryName: "getReview",
          variables: {
            id: taskRevision.reviewId,
          },
        })
      ).data.getReview;

      submitReview.call(this, "IN_PROGRESS", {
        apiUser,
        review,
        task,
        taskRevision,
        users,
      });
    } else {
      await callGraphQLSimple({
        message: "Failed to start review",
        queryName: "updateReview",
        variables: {
          input: {
            id: taskRevision.reviewId,
            reviewThread: [
              { type: "START", createdAt: new Date().toISOString() },
              { type: "REVIEWER_SET", createdAt: new Date().toISOString(), content: reviewer },
            ],
          },
        },
      });

      await callGraphQLSimple({
        message: "Failed to request review",
        queryName: "updateTaskRevision",
        variables: {
          input: {
            id: taskRevision.id,
            checkedBy: reviewer,
            reviewStatus: "IN_PROGRESS",
          },
        },
      });

      callGraphQLSimple({
        message: "Failed to request review",
        queryName: "updateTask",
        variables: {
          input: {
            id: task.id,
            isUnderReview: true,
            checkedBy: reviewer,
            reviewStatus: "IN_PROGRESS",
            reviewSecondaryStatus: null,
          },
        },
      });

      sendReviewRequestNotification({
        users,
        reviewer,
        apiUser,
        taskId: task.id,
        taskTitle: task.title,
        clientName: task.client?.name,
        projectTitle: task.project?.title,
        taskRevisionName: taskRevision.name,
        taskRevisionDescription: taskRevision.description,
        taskRevisionId: taskRevision.id,
      });

      annotateAllFilesInTaskRevision({
        task,
        taskRevision,
        apiUser,
      });
    }

    closeModal();
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title="Request review"
      open={visible}
      onOk={onSubmit}
      onCancel={closeModal}
      footer={null}
      className="request-review-modal"
    >
      <Form {...layout} form={form} initialValues={{}} onFinish={onSubmit}>
        <Form.Item
          label="Reviewer"
          name="reviewer"
          rules={[
            {
              required: true,
              message: "You must choose a reviewer",
            },
          ]}
        >
          <UsersFilter
            excludeList={getExcludedReviewerList({
              task,
              users,
              allowUseOfCatZero: organisationDetails.settings?.task?.allowUseOfCatZero,
            })}
            includeUnassigned={false}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
            {isLoading ? "Requesting" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default withSubscriptions({
  Component: RequestReviewModal,
});
