import React from "react";

import { Form, Alert, Button, Input, Typography } from "antd";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import withSubscriptions from "common/withSubscriptions";
import query from "query-string";

import Card from "Card/Card";

import "./ChangePasswordPage.scss";

export class ChangePasswordPage extends React.Component {
  state = {
    error: null,
    isSubmitting: false,
    prefilledOldPassword: null,
  };

  componentDidMount() {
    const { location } = this.props;

    const queryParams = query.parse(location.search);

    if (queryParams.oldPassword) {
      this.setState({
        prefilledOldPassword: queryParams.oldPassword,
      });
    }
  }

  onSubmit = async ({ oldPassword, newPassword }) => {
    if (this.state.prefilledOldPassword) {
      oldPassword = this.state.prefilledOldPassword;
    }
    try {
      const { user, history } = this.props;

      this.setState({ isSubmitting: true });
      await Auth.changePassword(user, oldPassword, newPassword);

      setTimeout(() => {
        history.push("/");
      }, 1000);
    } catch (e) {
      this.setState({ error: e.message, isSubmitting: false });
      console.log("error when changing password:", e);
    }
  };

  render() {
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };

    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    return (
      <div className="change-password-page">
        <Card>
          <Typography.Title level={4} className="title">
            Change Password
          </Typography.Title>
          <Form {...layout} name="basic" initialValues={{ remember: true }} onFinish={this.onSubmit}>
            <Form.Item
              label="Old password"
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: "You must specify the old password",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[{ required: true, message: "You must choose a new password" }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={this.state.isSubmitting}>
                Submit
              </Button>
            </Form.Item>
          </Form>

          {this.state.error ? <Alert message={this.state.error} type="error" showIcon /> : null}
        </Card>
      </div>
    );
  }
}

export default withRouter(withSubscriptions({ Component: ChangePasswordPage }));
