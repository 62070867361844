import { withRouter } from "react-router-dom";
import withSubscriptions from "common/withSubscriptions";
import NonTaskReports from "../NonTaskReports/NonTaskReports";

export function OrganisationReportsPage() {
  return (
    <div>
      <NonTaskReports parentType="ORGANISATION" />
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: OrganisationReportsPage,
    subscriptions: ["organisationDetails"],
  })
);
