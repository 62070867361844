import React from "react";
import moment from "moment";
import { Checkbox, Result } from "antd";
import cx from "classnames";

import {
  FolderOutlined,
  MailOutlined,
  FileOutlined,
  FileImageOutlined,
  VideoCameraOutlined,
  FilePdfOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { calculateReadableSize } from "common/helpers";

export default class AttachmentListItem extends React.Component {
  dropRef = React.createRef();

  onDragEnter = (e) => {
    console.log("item drag enter");
    e.preventDefault();
    e.stopPropagation();
  };

  onDragLeave = (e) => {
    console.log("item drag leave");
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const { item, checked, enabled, onClick, onCheck, index, isSearchResult, realPrefix } = this.props;

    let sizeReadable = calculateReadableSize(item);

    let name = item.name;

    if (isSearchResult) {
      // include in the file name the part after the realPrefix
      name = item.key.substring(realPrefix.length);
    }

    return (
      <div
        className={cx("attachment-item")}
        ref={this.dropRef}
        data-cy="attachment-item"
        data-index={index}
        data-name={item.name}
        onClick={onClick}
      >
        <div className="attachment-content">
          {item.type === "ACCESS_DENIED" ? (
            <Result status="warning" title="You are not authorised to view this folder" />
          ) : (
            <>
              <div onClick={(e) => e.stopPropagation()} className={cx("select", { "not-visible": !enabled })}>
                <Checkbox
                  onChange={onCheck}
                  checked={checked}
                  disabled={!enabled}
                  data-cy="file-checkbox"
                  data-index={index}
                />
              </div>

              <span className={cx("name", `type-${(item.type || "").toLowerCase()}`)}>
                {item.type === "FOLDER" ? <FolderOutlined className="file-type-icon" /> : null}
                {item.type === "IMAGE" ? <FileImageOutlined className="file-type-icon" /> : null}
                {item.type === "VIDEO" ? <VideoCameraOutlined className="file-type-icon" /> : null}
                {item.type === "PDF" ? <FilePdfOutlined className="file-type-icon" /> : null}
                {item.type === "EMAIL" ? <MailOutlined className="file-type-icon" /> : null}
                {item.type === "OTHER" ? <FileOutlined className="file-type-icon" /> : null}
                <span className="file-name" data-cy="file-name" data-index={index}>
                  {name}
                </span>
              </span>
              <span className="file-size" data-cy="file-size">
                {item.type === "FOLDER" ? "-" : sizeReadable}
              </span>
              <span className="updated-at">
                {item.type === "FOLDER" ? "-" : moment(item.lastModified).format("MMM DD YYYY, HH:mm")}
              </span>
              <span className="created-at">
                {item.type === "FOLDER"
                  ? "-"
                  : moment(
                      item.versions ? item.versions[item.versions.length - 1].LastModified : item.lastModified
                    ).format("MMM DD YYYY, HH:mm")}
              </span>
            </>
          )}
        </div>
      </div>
    );
  }
}
