import { S3_URL_CACHE_MAX_AGE } from "common/constants";
import { callRest } from "common/apiHelpers";

export default async function getS3File(key, versionId, expires) {
  let EXTENSIONS_TO_CACHE = ["JPG", "JPEG", "PNG", "GIF", "TIFF"];
  if (key.indexOf("public/") !== 0) {
    key = `public/${key}`;
  }
  key = urlEncodeS3Key(key);

  for (let extension of EXTENSIONS_TO_CACHE) {
    if (key.toUpperCase().endsWith(extension)) {
      if (window.s3Urls[key]) {
        const keyData = window.s3Urls[key];
        let millisecondsSinceCreated = Date.now() - keyData.createdAt;
        if (millisecondsSinceCreated < S3_URL_CACHE_MAX_AGE) {
          return window.s3Urls[key].url;
        }
      }
      break;
    }
  }

  let route = `/s3?key=${key}`;
  if (versionId) {
    route += `&versionId=${versionId}`;
  }

  if (expires) {
    route += `&expires=${expires}`;
  }

  const signedUrl = await callRest({
    method: "GET",
    route,
    includeCredentials: false,
    isJson: false,
  });

  for (let extension of EXTENSIONS_TO_CACHE) {
    if (key.toUpperCase().endsWith(extension)) {
      window.s3Urls[key] = { url: signedUrl, createdAt: Date.now() };
      break;
    }
  }

  return signedUrl;
}

function urlEncodeS3Key(key) {
  key = encodeURI(key);

  // At this point, we have a key that has been encoded too aggressively by
  // ColdFusion. Now, we have to go through and un-escape the characters that
  // AWS does not expect to be encoded.
  // The following are "unreserved" characters in the RFC 3986 spec for Uniform
  // Resource Identifiers (URIs) - http://tools.ietf.org/html/rfc3986#section-2.3
  const encodings = {
    "!": "%21",
    "#": "%23",
    $: "%24",
    // "%": "%25",
    "&": "%26",
    "'": "%27",
    "(": "%28",
    ")": "%29",
    "*": "%2A",
    "+": "%2B",
    ",": "%2C",
    "-": "%2D",
    ".": "%2E",
    "/": "%2F",
    ":": "%3A",
    ";": "%3B",
    "=": "%3D",
    "?": "%3F",
    "@": "%40",
    "[": "%5B",
    "]": "%5D",
    _: "%5F",
    "~": "%7E",
  };

  Object.keys(encodings).forEach(
    (specialCharacter) => (key = key.split(specialCharacter).join(encodings[specialCharacter]))
  );

  // Technically, the "/" characters can be encoded and will work. However, if the
  // bucket name is included in this key, then it will break (since it will bleed
  // into the S3 domain: "s3.amazonaws.com%2fbucket"). As such, I like to unescape
  // the slashes to make the function more flexible. Plus, I think we can all agree
  // that regular slashes make the URLs look nicer.
  key = key.split("/").join("%2F");

  // This one isn't necessary; but, I think it makes for a more attactive URL.
  // --
  // NOTE: That said, it looks like Amazon S3 may always interpret a "+" as a
  // space, which may not be the way other servers work. As such, we are leaving
  // the "+"" literal as the encoded hex value, %2B.
  key = key.split("+").join("%20");

  return key;
}
