export default function addObjectToParent({ currentObject, parentId, child, newPosition }) {
  if (currentObject.custom_id === parentId) {
    // we want the child to reference the new parent
    child.group = currentObject;

    let newChildren = currentObject.objects ? [...currentObject.objects] : [];
    if (newPosition === undefined || newPosition === null) {
      newPosition = newChildren.length;
    } else if (newPosition !== undefined && newPosition !== null) {
      if (newPosition < 0) {
        newPosition = 0;
      } else if (newPosition > newChildren.length) {
        newPosition = newChildren.length;
      }
    }
    newChildren.splice(newPosition, 0, child);
    return { ...currentObject, objects: newChildren };
  }

  if (Array.isArray(currentObject.objects)) {
    return {
      ...currentObject,
      objects: currentObject.objects.map((childObject) =>
        addObjectToParent({ currentObject: childObject, parentId, child, newPosition })
      ),
    };
  }

  return currentObject;
}
