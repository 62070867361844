import hexToRgba from "hex-to-rgba";
import { TEMPLATE_EDITOR_FORMULA_FIELDS } from "common/shared";

export const MAX_INITIAL_IMAGE_WIDTH = 200;
export const MAX_INITIAL_IMAGE_HEIGHT = 200;
export const DARK_COLOR = "#73929c";
export const NEUTRAL_COLOR = "#95a7ad";

const COLOR_FIELDS = [
  "fill",
  "formula_fill",
  "stroke",
  "formula_stroke",
  "custom_fill",
  "custom_stroke",
  "custom_formula_fill",
  "custom_sectionFill",
  "custom_sectionStroke",
  "custom_textFill",
  "custom_formula_stroke",
  "custom_formula_sectionFill",
  "custom_formula_sectionStroke",
  "custom_formula_textFill",
];

export function getObjectMargins(object) {
  return {
    top: object.custom_marginTop || 0,
    right: object.custom_marginRight || 0,
    bottom: object.custom_marginBottom || 0,
    left: object.custom_marginLeft || 0,
  };
}

export function getObjectPadding(object, root) {
  if (object.custom_type === "page") {
    return getPagePadding(object, root);
  }

  return {
    top: object.custom_paddingTop || 0,
    right: object.custom_paddingRight || 0,
    bottom: object.custom_paddingBottom || 0,
    left: object.custom_paddingLeft || 0,
  };
}

export function getObjectAbsoluteCoordinates(object) {
  let top;
  let left;
  let right;
  let bottom;

  if (
    object.custom_absoluteRight !== undefined &&
    !isNaN(object.custom_absoluteRight) &&
    object.custom_absoluteRight !== null
  ) {
    right = object.custom_absoluteRight;
  } else {
    left = object.custom_absoluteLeft || 0;
  }

  if (
    object.custom_absoluteBottom !== undefined &&
    !isNaN(object.custom_absoluteBottom) &&
    object.custom_absoluteBottom !== null
  ) {
    bottom = object.custom_absoluteBottom;
  } else {
    top = object.custom_absoluteTop || 0;
  }

  return { top, left, right, bottom };
}

export function getPagePadding(pageObject, root) {
  if (pageObject.custom_hasCustomPadding) {
    return {
      top: pageObject?.custom_paddingTop || 0,
      right: pageObject?.custom_paddingRight || 0,
      bottom: pageObject?.custom_paddingBottom || 0,
      left: pageObject?.custom_paddingLeft || 0,
    };
  } else {
    return {
      top: root?.custom_defaultPagePaddingTop || 0,
      right: root?.custom_defaultPagePaddingRight || 0,
      bottom: root?.custom_defaultPagePaddingBottom || 0,
      left: root?.custom_defaultPagePaddingLeft || 0,
    };
  }
}

export async function initialiseFormulaBasedFields(parent) {
  TEMPLATE_EDITOR_FORMULA_FIELDS.forEach((formulaFieldName) => {
    let nonFormulaFieldName = formulaFieldName.split("formula_").join("");

    if (parent.hasOwnProperty(nonFormulaFieldName) && !parent.hasOwnProperty(formulaFieldName)) {
      parent[formulaFieldName] = String(parent[nonFormulaFieldName]);
    }
  });

  if (parent.objects) {
    for (let i = 0; i < parent.objects.length; i++) {
      await initialiseFormulaBasedFields(parent.objects[i]);
    }
  }
}

/**
 This function is used for 2 things:
 1. To return a list of possible variable names
 2. To replace all variables in a string with their values

 The reason it's done in a single function is to avoid having to duplicate the variable names in another place.
 */

export function getVariables(params) {
  const { pageObject, pagePadding, formulaValue, organisationDetails, type = "NUMBER" } = params || {};
  const PREDEFINED_VARIABLES = {
    $pagePaddingLeft: pagePadding?.left,
    $pagePaddingRight: pagePadding?.right,
    $pagePaddingTop: pagePadding?.top,
    $pagePaddingBottom: pagePadding?.bottom,
    $pagePaddingHorizontal: pagePadding?.left + pagePadding?.right,
    $pagePaddingVertical: pagePadding?.bottom + pagePadding?.top,
    $pageOuterHeight: pageObject?.custom_pageHeight,
    $pageOuterWidth: pageObject?.custom_pageWidth,
    $pageInnerWidth: pageObject?.custom_pageWidth - pagePadding?.left - pagePadding?.right,
    $pageInnerHeight: pageObject?.custom_pageHeight - pagePadding?.top - pagePadding?.bottom,
  };

  let userDefinedVariables = organisationDetails.variables.items.filter((variable) => variable.type === type);

  if (formulaValue === undefined) {
    if (type === "NUMBER") {
      return [...userDefinedVariables.map((variable) => `$${variable.name}`), "", ...Object.keys(PREDEFINED_VARIABLES)];
    } else if (type === "COLOR") {
      return userDefinedVariables.map((variable) => `$${variable.name}`);
    }
  } else {
    let remainingFormula = String(formulaValue || "");
    for (let variableName in PREDEFINED_VARIABLES) {
      remainingFormula = remainingFormula.replace(variableName, PREDEFINED_VARIABLES[variableName]);
    }

    userDefinedVariables.forEach((variable) => {
      remainingFormula = remainingFormula.replace(`$${variable.name}`, variable.value);
    });

    return remainingFormula;
  }
}

export function getValueBasedOnFormula({
  organisationDetails,
  object,
  pageObject,
  outputTemplate,
  formulaValue,
  fieldName,
  fileTypeDetails,
}) {
  if (
    ["formula_width", "custom_formula_width"].includes(fieldName) &&
    object.custom_calculateWidthFormulaAtDisplayTime
  ) {
    return 10;
  }
  if (
    ["formula_height", "custom_formula_height"].includes(fieldName) &&
    object.custom_calculateHeightFormulaAtDisplayTime
  ) {
    return 10;
  }
  // }
  let isNumerical = !COLOR_FIELDS.includes(fieldName);
  let remainingFormula = String(formulaValue || "");
  if (formulaValue === "") {
    return undefined;
  }

  const pagePadding = fileTypeDetails.isDocumentTemplate
    ? getPagePadding(pageObject, outputTemplate)
    : {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      };
  remainingFormula = getVariables({
    organisationDetails,
    pageObject,
    pagePadding,
    formulaValue,
    type: isNumerical ? "NUMBER" : "COLOR",
  });

  if (remainingFormula === undefined) {
    remainingFormula = "";
  }

  let percentages = (String(remainingFormula) || "").match(/\b\d+(?:%|percent\b)/);
  if (percentages) {
    percentages.forEach((percentageValue) => {
      let calculatedValue = calculatePercentage({
        object,
        pageObject,
        pagePadding,
        percentageValue,
        fieldName,
      });
      remainingFormula = remainingFormula.split(percentageValue).join(calculatedValue);
    });
  }

  if (isNumerical) {
    return parseFloat(remainingFormula);
  } else {
    return remainingFormula.trim();
  }
}

function calculatePercentage({ object, pageObject, pagePadding, percentageValue, fieldName }) {
  let referenceValue = 0;
  let fieldNameLowerCase = fieldName.toLowerCase();
  if (
    fieldNameLowerCase.includes("width") ||
    fieldNameLowerCase.includes("left") ||
    fieldNameLowerCase.includes("right")
  ) {
    referenceValue = pageObject?.custom_pageWidth || 0;
    if (object.custom_cssPosition !== "absolute") {
      referenceValue = referenceValue - pagePadding.left - pagePadding.right;
    }
  } else if (
    fieldNameLowerCase.includes("height") ||
    fieldNameLowerCase.includes("top") ||
    fieldNameLowerCase.includes("bottom")
  ) {
    referenceValue = pageObject?.custom_pageHeight || 0;
    if (object.custom_cssPosition !== "absolute") {
      referenceValue = referenceValue - pagePadding.top - pagePadding.bottom;
    }
  }

  referenceValue = Math.max(referenceValue, 0);

  let result = (parseFloat(percentageValue) / 100) * referenceValue;
  return result;
}

export function valueExists(value) {
  return value !== undefined && value !== null && !isNaN(value) && value !== "";
}

export function getPageObjectParent(object) {
  let pageObject;
  let parent = object;
  while (!pageObject && parent) {
    if (parent.custom_type === "page") {
      pageObject = parent;
    }
    parent = parent.group;
  }
  return pageObject;
}

export function getObjectStyle({ object, parent, root, index = 0, isDocumentTemplate = true, isWebPage }) {
  let padding = getObjectPadding(object, root);
  let style = {
    display: "flex",
    flexShrink: 0,
    flexDirection: object.custom_flexDirection || "column",
    paddingRight: padding.right,
    paddingLeft: padding.left,
    paddingTop: padding.top,
    paddingBottom: padding.bottom,
  };

  if (isWebPage) {
    style.gap = object.custom_gap || 0;
    if (style.flexDirection === "row") {
      style.flexWrap = "wrap";
    }
  }

  if (isDocumentTemplate) {
    if (object.custom_cssPosition === "absolute") {
      style = {
        ...style,
        position: "absolute",
        ...getObjectAbsoluteCoordinates(object),
      };
    } else if (
      object.custom_cssPosition === "relative" ||
      object.custom_cssPosition === "static" ||
      !object.custom_cssPosition
    ) {
      const margins = getObjectMargins(object);

      style = {
        ...style,
        position: "relative",
        marginRight: margins.right,
        marginLeft: margins.left,
        marginTop: margins.top,
        marginBottom: margins.bottom,
      };

      if (!isWebPage && index > 0) {
        if (parent?.custom_flexDirection === "row") {
          style.marginLeft += parent?.custom_gap || 0;
        } else {
          style.marginTop += parent?.custom_gap || 0;
        }
      }
    }
  } else {
    let top = object.top;
    let left = object.left;

    if (object.group && object.group.custom_id !== "root") {
      if (
        object.group.custom_isUsingOldSystem === undefined ||
        object.group.custom_isUsingOldSystem === null ||
        object.group.custom_isUsingOldSystem
      ) {
        top = `calc(50% + ${object.top}px)`;
        left = `calc(50% + ${object.left}px)`;
      }
    }

    style = {
      ...style,
      position: "absolute",
      top,
      left,
      width: object.width,
      height: object.height,
    };
  }

  if (object.textAlign) {
    style.textAlign = object.textAlign;
  } else {
    if (object.type === "text") {
      style.textAlign = "left";
    }
  }

  if (object.custom_sectionFill || object.custom_sectionBackgroundOpacity) {
    let opacity = object.custom_sectionBackgroundOpacity === undefined ? 100 : object.custom_sectionBackgroundOpacity;
    let fill = object.custom_sectionFill || "#fff";

    style.backgroundColor = `${hexToRgba(fill, String(parseFloat(opacity) / 100))}`;
  }

  if (object.custom_sectionStroke || object.custom_sectionStrokeWidth) {
    let stroke = object.custom_sectionStroke || "#000";
    let strokeWidth = object.custom_sectionStrokeWidth === undefined ? 0 : object.custom_sectionStrokeWidth;
    style.border = `${strokeWidth}px solid ${stroke}`;
  }

  if (object.custom_type !== "section" && object.custom_type !== "page") {
    if (object.custom_calculateWidthFormulaAtDisplayTime) {
      if (object.formula_width !== undefined && object.formula_width !== null) {
        object.width = object.formula_width;
      }
    } else if (object.hasOwnProperty("width") && object.width !== undefined && object.width !== null) {
      style.width = object.width;
    }
    if (object.custom_calculateHeightFormulaAtDisplayTime) {
      if (object.formula_height !== undefined && object.formula_height !== null) {
        style.height = object.formula_height;
      }
    } else if (parent.custom_flexDirection === "row" && object.custom_cssPosition !== "absolute") {
      // TODO: find a way to improve this, because if the child has a margin top/bottom, then it doesn't work as it should anymore
      style.height = "100%";
    } else if (
      object.hasOwnProperty("height") &&
      object.height !== undefined &&
      object.height !== null &&
      object.type !== "text"
    ) {
      style.height = object.height;
    }
  }

  if (object.custom_sectionMinWidth) {
    style.minWidth = object.custom_sectionMinWidth;
  }

  if (object.custom_sectionMinHeight) {
    style.minHeight = object.custom_sectionMinHeight;
  }

  if (object.rx) {
    style.borderRadius = object.rx;
  }

  if (object.custom_opacity !== undefined && object.custom_opacity !== null) {
    style.opacity = object.custom_opacity / 100;
  } else {
    style.opacity = 1;
  }

  if (object.custom_hideBackground) {
    style.backgroundColor = undefined;
    style.background = undefined;
  }

  if (object.angle) {
    style.transform = `rotate(${object.angle}deg)`;
    style.transformOrigin = "0 0";
  }

  if (object.type === "image" || object.type === "image_container") {
    style.height = undefined;
  }

  return style;
}

export function buildHierarchyKey(object) {
  let key = object.custom_id;
  let parent = object.group;

  while (parent && parent.custom_id) {
    key = `${parent.custom_id}-${parent.custom_name}-${key}`;
    parent = parent.group;
  }

  return key;
}

export function objectIsPartOfPageBorders(object) {
  if (object.custom_isPageBorder) {
    return true;
  } else if (object.group) {
    return objectIsPartOfPageBorders(object.group);
  } else {
    return false;
  }
}

export function objectIsPartOfPageNumbers(object) {
  if (object.custom_isPageNumber) {
    return true;
  } else if (object.group) {
    return objectIsPartOfPageNumbers(object.group);
  } else {
    return false;
  }
}
