import { Select, Button } from "antd";

import { FILE_TYPES_READABLE } from "common/constants";
import { getSimpleLabel } from "common/labels";

import InfoItem from "InfoItem/InfoItem";
import TaskPicker from "TaskPicker/TaskPicker";

export default function TemplateEditorPreviewPanelSection({
  updateTemplateField,
  template,
  previewTask,
  previewTaskRevision,
  previewFile,
  isPreviewVisible,
  isAnnotating,
  triggerAnnotate,
  pdfPreviewData,
  setIsPreviewVisible,
}) {
  function displayTaskRevision() {
    if (!previewTask) {
      return null;
    }

    return (
      <InfoItem
        label={getSimpleLabel("Task revision")}
        value={
          <Select
            value={previewTaskRevision ? template.previewTaskRevisionId : undefined}
            onChange={(value) =>
              updateTemplateField({
                fieldName: "previewTaskRevisionId",
                value,
              })
            }
          >
            {previewTask.revisions.items.map((revision) => (
              <Select.Option value={revision.id} key={revision.id}>
                {revision.name}
              </Select.Option>
            ))}
          </Select>
        }
      />
    );
  }

  function displayFile() {
    if (!previewTask || !previewTaskRevision) {
      return null;
    }

    return (
      <InfoItem
        label="File"
        value={
          <Select
            value={previewFile ? template.previewFileId : undefined}
            onChange={(value) => updateTemplateField({ fieldName: "previewFileId", value })}
          >
            {previewTaskRevision.files.items.map((file) => (
              <Select.Option value={file.id} key={file.id}>
                {FILE_TYPES_READABLE[file.type]} - {file.name}
              </Select.Option>
            ))}
          </Select>
        }
      />
    );
  }

  return (
    <>
      <InfoItem
        label="Task"
        value={
          <TaskPicker
            includeTaskId={false}
            value={template.previewTaskId}
            onChange={async (value) => {
              await updateTemplateField({
                fieldName: "previewFileId",
                value: null,
              });
              await updateTemplateField({
                fieldName: "previewTaskRevisionId",
                value: null,
              });
              await updateTemplateField({
                fieldName: "previewTaskId",
                value,
              });
            }}
          />
        }
      />
      {displayTaskRevision()}
      {displayFile()}

      <br />
      <InfoItem
        className="preview-buttons-container"
        value={
          <>
            {previewFile && (
              <Button type="primary" onClick={triggerAnnotate} loading={isAnnotating} disabled={isPreviewVisible}>
                {isAnnotating ? "Generating" : "Save and generate preview"}
              </Button>
            )}
            {isPreviewVisible ? (
              <Button onClick={() => setIsPreviewVisible(false)}>Hide preview</Button>
            ) : (
              <Button onClick={() => setIsPreviewVisible(true)} disabled={!pdfPreviewData}>
                Show latest preview
              </Button>
            )}
          </>
        }
      />
    </>
  );
}
