import { useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { getSimpleLabel } from "common/labels";
import withSubscriptions from "common/withSubscriptions";

import Card from "Card/Card";
import TaskPriorityList from "./TaskPriorityList/TaskPriorityList";
import TaskPriorityModal from "./TaskPriorityModal/TaskPriorityModal";

export function TaskPrioritiesCard({ organisationDetails, setProps, context }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const displayPriorities = () => {
    if (
      !organisationDetails.settings?.task?.priorities ||
      organisationDetails.settings?.task?.priorities.length === 0
    ) {
      return null;
    }

    return <TaskPriorityList organisationDetails={organisationDetails} setProps={setProps} context={context} />;
  };

  return (
    <Card
      withSpace
      title={`${getSimpleLabel("Task revision")} priority levels`}
      actions={
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => setIsModalVisible(true)}
          data-cy="add-priority-button"
        >
          Add priority level
        </Button>
      }
    >
      {displayPriorities()}
      <TaskPriorityModal visible={isModalVisible} onClose={() => setIsModalVisible(false)} />
    </Card>
  );
}

export default withSubscriptions({
  Component: TaskPrioritiesCard,
  subscriptions: [],
});
