import React from "react";
import Footer from "Footer/Footer";

import "./UserAgreement.scss";

export default class UserAgreement extends React.Component {
  componentDidMount() {
    this.props.setBoxedLayout(false);
    this.props.setBackground(false);
    this.setState({ isLoaded: true });
  }

  componentWillUnmount() {
    this.props.setBoxedLayout(true);
    this.props.setBackground(true);
  }

  render() {
    return (
      <div className="user-agreement">
        <div className="main-section">
          <div className="boxed-layout">
            <h1 className="page-title">End User Agreement</h1>
            <h3 className="page-subtitle">
              Here we outline the end user agreement in place when you and your company use DraughtHub.
            </h3>
            <br />
            <div className="agreement-details">
              <p>Last updated: February 6th, 2023.</p>
              <p>A signed End User Agreement between the Parties supersedes this document.</p>
              <br />

              <h2>
                <span className="c11 c9">Parties</span>
              </h2>
              <p className="c0">
                <span className="c1">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span className="c2">GoDragons</span>
                <span className="c1">, a company incorporated in England (registration number </span>
                <span className="c5 c12">12444445</span>
                <span className="c1">) having its registered office at </span>
                <span className="c5">Suite 1-3 24 Southwark Street, London, England, SE1 1TY (the &quot;</span>
                <span className="c2">Provider</span>
                <span className="c5">&quot;)</span>
                <span className="c1">; and</span>
              </p>
              <p className="c0">
                <span className="c1">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span className="c9 c5">Companies who decide to use DraughtHub (the &quot; </span>
                <span className="c2">Customer</span>
                <span className="c9 c5">&quot;).</span>
              </p>
              <h2>
                <span className="c11 c9">Agreement</span>
              </h2>
              <h3>
                <span className="c2">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Definitions</span>
              </h3>
              <p className="c0">
                <span className="c1">
                  1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In this Agreement, except to the extent expressly
                  provided otherwise:
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Account</span>
                <span className="c1">
                  &quot; means an account enabling a person to access and use the Hosted Services, including both
                  administrator accounts and user accounts;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Agreement</span>
                <span className="c1">
                  &quot; means this agreement including any Schedules, and any amendments to this Agreement from time to
                  time;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Business Day</span>
                <span className="c1">&quot; means any weekday other than a bank or public holiday in England;</span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Business Hours</span>
                <span className="c1">&quot; means the hours of 08:00 to 17:00 GMT/BST on a Business Day;</span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Charges</span>
                <span className="c1">&quot; means the following amounts:</span>
              </p>
              <p className="c4 c8">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the amounts as may be agreed in writing by the
                  parties;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Customer Confidential Information</span>
                <span className="c1">&quot; means:</span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;any information disclosed by the Customer to the
                  Provider during the{" "}
                </span>
                <span className="c5">developmental phase and free trial</span>
                <span className="c1">
                  &nbsp;(whether disclosed in writing, orally or otherwise) that at the time of disclosure:
                </span>
              </p>
              <p className="c4 c10">
                <span className="c1">
                  (i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;was marked or described as
                  &quot;confidential&quot;; or
                </span>
              </p>
              <p className="c4 c10">
                <span className="c1">
                  (ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;should have been reasonably understood by the
                  Provider to be confidential; and
                </span>
              </p>
              <p className="c3">
                <span className="c1">(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span className="c5">Customer Data uploaded directly to the Platform</span>
                <span className="c1">;</span>
              </p>
              <p className="c3 c6">
                <span className="c1"></span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Customer Data</span>
                <span className="c1">&quot; means</span>
                <span className="c5">&nbsp;</span>
                <span className="c1">
                  all data, works and materials: uploaded to or stored on the Platform by the Customer; transmitted by
                  the Platform at the instigation of the Customer; supplied by the Customer to the Provider for
                  uploading to, transmission by or storage on the Platform; or generated by the Platform as a result of
                  the use of the Hosted Services by the Customer (but excluding analytics data relating to the use of
                  the Platform and server log files);
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Customer Personal Data</span>
                <span className="c1">
                  &quot; means any Personal Data that is processed by the Provider on behalf of the Customer in relation
                  to this Agreement;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Data Protection Laws</span>
                <span className="c1">
                  &quot; means all applicable laws relating to the processing of Personal Data including, while it is in
                  force and applicable to Customer Personal Data, the{" "}
                </span>
                <span className="c5">Data Protection Act 2018</span>
                <span className="c1">;</span>
              </p>
              <p className="c4 c8">
                <span className="c5">&quot;</span>
                <span className="c2">Desktop Application</span>
                <span className="c5">&quot; means the desktop application known as </span>
                <span className="c5 c12">DraughtHub Link</span>
                <span className="c1">
                  &nbsp;for the Supported Operating Systems that is made available by the Provider through
                  www.draughthub.com;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Documentation</span>
                <span className="c1">
                  &quot; means the documentation for the Hosted Services produced by the Provider and delivered or made
                  available by the Provider to the Customer;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Force Majeure Event</span>
                <span className="c1">
                  &quot; means an event, or a series of related events, that is outside the reasonable control of the
                  party affected (including failures of the internet or any public telecommunications network, hacker
                  attacks, denial of service attacks, virus or other malicious software attacks or infections, power
                  failures, industrial disputes affecting any third party, changes to the law, disasters, explosions,
                  fires, floods, riots, terrorist attacks and wars);
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Hosted Services</span>
                <span className="c1">&quot; means </span>
                <span className="c5 c12">DraughtHub</span>
                <span className="c1">
                  , as specified in the Hosted Services Specification, which will be made available by the Provider to
                  the Customer as a service via the internet in accordance with this Agreement;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Hosted Services Defect</span>
                <span className="c1">
                  &quot; means a defect, error or bug in the Platform having an adverse effect on the appearance,
                  operation, functionality or performance
                </span>
                <span className="c5">&nbsp;</span>
                <span className="c1">
                  of the Hosted Services, but excluding any defect, error or bug caused by or arising as a result of:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;any act or omission of the Customer or any person
                  authorised by the Customer to use the Platform or Hosted Services;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;any use of the Platform or Hosted Services contrary
                  to the Documentation, whether by the Customer or by any person authorised by the Customer;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a failure of the Customer to perform or observe any
                  of its obligations in this Agreement; and/or
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;an incompatibility between the Platform or Hosted
                  Services and any other system, network, application, program, hardware or software not specified as
                  compatible in the Hosted Services Specification;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Hosted Services Specification</span>
                <span className="c1">
                  &quot; means the specification for the Platform and Hosted Services set out in Part 1 of Schedule 1
                  (Hosted Services particulars) and in the Documentation;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Intellectual Property Rights</span>
                <span className="c1">
                  &quot; means all intellectual property rights wherever in the world, whether registrable or
                  unregistrable, registered or unregistered, including any application or right of application for such
                  rights (and these &quot;intellectual property rights&quot; include copyright and related rights,
                  database rights, confidential information, trade secrets, know-how, business names, trade names, trade
                  marks, service marks, passing off rights, unfair competition rights, patents, petty patents, utility
                  models, semi-conductor topography rights and rights in designs);
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Maintenance Services</span>
                <span className="c1">
                  &quot; means the general maintenance of the Platform and Hosted Services, and the application of
                  Updates and Upgrades;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c5">&quot;</span>
                <span className="c2">Month Period</span>
                <span className="c1">
                  &quot; The period of time between billing, which will be on the same day of each month.
                </span>
              </p>
              <p className="c4 c8">
                <span className="c5">&quot;</span>
                <span className="c2">Organisation</span>
                <span className="c1">
                  &quot; is the term used on the platform for the Customer&rsquo;s company. It includes the Customer
                  Data and Users from the Customer&rsquo;s company.
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Personal Data</span>
                <span className="c1">
                  &quot; has the meaning given to it in the Data Protection Laws applicable in the United Kingdom from
                  time to time;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Platform</span>
                <span className="c1">
                  &quot; means the platform managed by the Provider and used by the Provider to provide the Hosted
                  Services, including the application and database software for the Hosted Services, the system and
                  server software used to provide the Hosted Services, and the computer hardware on which that
                  application, database, system and server software is installed;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Schedule</span>
                <span className="c1">&quot; means any schedule attached to the main body of this Agreement;</span>
              </p>
              <p className="c4 c8">
                <span className="c5">&quot;</span>
                <span className="c2">Seat</span>
                <span className="c1">
                  &quot; is a space for a User account in the Hosted Service, the number of seats will be decided at the
                  beginning of each Month Period and will be charged for even if not occupied by a User;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Services</span>
                <span className="c1">
                  &quot; means any services that the Provider provides to the Customer, or has an obligation to provide
                  to the Customer, under this Agreement;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Support Services</span>
                <span className="c1">
                  &quot; means support in relation to the use of, and the identification and resolution of errors in,
                  the Hosted Services, but shall not include the provision of training services;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c5">&quot;</span>
                <span className="c2">Supported Operating Systems</span>
                <span className="c1">&quot; means the current release from time to time of Windows 10.</span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Supported Web Browser</span>
                <span className="c1">
                  &quot; means the current release from time to time of Microsoft Edge, Mozilla Firefox, Google Chrome;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Term</span>
                <span className="c1">
                  &quot; means the duration that the Customer and the Provider agree that the Customer will use the
                  platform;
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Update</span>
                <span className="c1">
                  &quot; means a hotfix, patch or minor version update to any Platform software; and
                </span>
              </p>
              <p className="c4 c8">
                <span className="c1">&quot;</span>
                <span className="c2">Upgrade</span>
                <span className="c1">&quot; means a major version upgrade of any Platform software.</span>
              </p>
              <p className="c4 c8">
                <span className="c5">&quot;</span>
                <span className="c2">User</span>
                <span className="c1">
                  &quot; means someone who has an account within the Organisation in the Hosted Services.
                </span>
              </p>
              <h2>
                <span className="c2">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hosted Services</span>
              </h2>
              <p className="c0">
                <span className="c1">3.</span>
                <span className="c5">1</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upon agreement, the Provider will grant to the
                  Customer a worldwide, non-exclusive licence to use the Hosted Services by means of a Supported Web
                  Browser for
                </span>
                <span className="c5">&nbsp;</span>
                <span className="c1">
                  the internal business purposes of the Customer in accordance with the Documentation during the Term.
                </span>
              </p>
              <p className="c0">
                <span className="c1">3.</span>
                <span className="c5">2</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The licence granted by the Provider to the Customer
                  under Clause 3.1 is subject to the following limitations:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Hosted Services may only be used by the
                  officers, employees, agents and subcontractors of the Customer;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Hosted Services may only be used by the named
                  users identified in Schedule 1 (Hosted Services particulars), providing that the Customer may change,
                  add or remove a designated named user in accordance with the procedure set out therein; and
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Hosted Services must not be used at any point
                  in time by more than the number of{" "}
                </span>
                <span className="c5">Seats</span>
                <span className="c1">&nbsp;specified in Schedule 1 (Hosted Services particulars)</span>
                <span className="c5">,</span>
                <span className="c1">&nbsp;providing that the Customer may add or remove concurrent </span>
                <span className="c5">Seats</span>
                <span className="c1">&nbsp;in accordance with the procedure set out therein; and</span>
              </p>
              <p className="c3">
                <span className="c1">
                  (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Hosted Services user accounts must only be used
                  by a named person and must not be shared between people.
                </span>
              </p>
              <p className="c0">
                <span className="c1">3.</span>
                <span className="c5">3</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Except to the extent expressly permitted in this
                  Agreement or required by law on a non-excludable basis, the licence granted by the Provider to the
                  Customer under Clause 3.1 is subject to the following prohibitions:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Customer must not sub-license its right to
                  access and use the Hosted Services;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Customer must not permit any unauthorised
                  person to access or use the Hosted Services;
                </span>
              </p>
              <p className="c3">
                <span className="c1">(</span>
                <span className="c5">c</span>
                <span className="c1">
                  )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Customer must not make any alteration to the
                  Platform, except as permitted by the Documentation; and
                </span>
              </p>
              <p className="c3">
                <span className="c1">(</span>
                <span className="c5">d</span>
                <span className="c1">
                  )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Customer must not conduct or request that any
                  other person conduct any load testing or penetration testing on the Platform or Hosted Services
                  without the prior written consent of the Provider; and
                </span>
              </p>
              <p className="c3">
                <span className="c1">(</span>
                <span className="c5">e</span>
                <span className="c1">
                  )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Customer must not allow users to share accounts
                  or share seats on the platform.
                </span>
              </p>
              <p className="c0">
                <span className="c1">3.</span>
                <span className="c5">4</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer shall use reasonable endeavours,
                  including reasonable security measures relating to{" "}
                </span>
                <span className="c5">User</span>
                <span className="c1">&nbsp;</span>
                <span className="c5">a</span>
                <span className="c1">
                  ccount access details, to ensure that no unauthorised person may gain access to the Hosted Services
                  using a
                </span>
                <span className="c5">&nbsp;User a</span>
                <span className="c1">ccount.</span>
              </p>
              <p className="c0">
                <span className="c1">3.</span>
                <span className="c5">5</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall use reasonable endeavours to
                  maintain the availability of the Hosted Services to the Customer at the gateway between the public
                  internet and the network of the hosting services provider for the Hosted Services, but does not
                  guarantee 100% availability.
                </span>
              </p>
              <p className="c0">
                <span className="c1">3.</span>
                <span className="c5">6</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For the avoidance of doubt, downtime caused directly
                  or indirectly by any of the following shall not be considered a breach of this Agreement:
                </span>
              </p>
              <p className="c3">
                <span className="c1">(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a Force Majeure Event;</span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a fault or failure of the internet or any public
                  telecommunications network;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a fault or failure of the Customer&#39;s computer
                  systems or networks;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;any breach by the Customer of this Agreement;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;scheduled maintenance carried out in accordance
                  with this Agreement
                </span>
                <span className="c1">; or</span>
              </p>
              <p className="c3">
                <span className="c1">
                  (f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a fault or failure of Amazon Web Services.
                </span>
              </p>
              <p className="c0">
                <span className="c1">3.</span>
                <span className="c5">7</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer must comply with Schedule 2 (Acceptable
                  Use Policy), and must ensure that all persons using the Hosted Services with the authority of the
                  Customer or by means of a
                </span>
                <span className="c5">&nbsp;User a</span>
                <span className="c1">ccount comply with Schedule 2 (Acceptable Use Policy).</span>
              </p>
              <p className="c0">
                <span className="c1">3.</span>
                <span className="c5">8</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer must not use the Hosted Services in any
                  way that causes, or may cause, damage to the Hosted Services or Platform or impairment of the
                  availability or accessibility of the Hosted Services.
                </span>
              </p>
              <p className="c0">
                <span className="c1">3.</span>
                <span className="c5">9</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer must not use the Hosted Services:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;in any way that is unlawful, illegal, fraudulent or
                  harmful; or
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;in connection with any unlawful, illegal,
                  fraudulent or harmful purpose or activity.
                </span>
              </p>
              <p className="c0">
                <span className="c1">3.1</span>
                <span className="c5">0</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For the avoidance of doubt, the Customer has no right
                  to access the software code (including object code, intermediate code and source code) of the Platform
                  or reverse engineer it, either during or after the Term.
                </span>
              </p>
              <p className="c0">
                <span className="c1">3.1</span>
                <span className="c5">1</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider may suspend the provision of the Hosted
                  Services if any amount due to be paid by the Customer to the Provider under this Agreement is overdue,
                  and the Provider has given to the Customer 30 days&#39; written notice, following the amount becoming
                  overdue, of its intention to suspend the Hosted Services on this basis.
                </span>
              </p>
              <h2>
                <span className="c2">4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Maintenance Services</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall provide the Maintenance Services
                  to the Customer during the Term.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall where practicable give to the
                  Customer at least{" "}
                </span>
                <span className="c5">2</span>
                <span className="c1">&nbsp;</span>
                <span className="c5">d</span>
                <span className="c1">
                  ays&#39; prior written notice of scheduled Maintenance Services that are likely to affect the
                  availability of the Hosted Services or are likely to have a material negative impact upon the Hosted
                  Services, without prejudice to the Provider&#39;s other notice obligations under this main body of
                  this Agreement.{" "}
                </span>
                <span className="c5">
                  Any scheduled Maintenance Services that affect the availability of the Hosted Services will be
                  undertaken outside of Business Hours.
                </span>
              </p>
              <p className="c0">
                <span className="c1">4.</span>
                <span className="c5">3</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall provide the Maintenance Services in
                  accordance with the standards of skill and care reasonably expected from a service provider in the
                  Provider&#39;s industry.
                </span>
              </p>
              <p className="c0">
                <span className="c1">4.</span>
                <span className="c5">4</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider may suspend the provision of the
                  Maintenance Services if any amount due to be paid by the Customer to the Provider under this Agreement
                  is overdue, and the Provider has given to the Customer 30 days&#39; written notice, following the
                  amount becoming overdue, of its intention to suspend the Maintenance Services on this basis.
                </span>
              </p>
              <h2>
                <span className="c2">5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Support Services</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall provide the Support Services to
                  the Customer during the Term.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall make available to the Customer a
                  helpdesk in accordance with the provisions of this main body of this Agreement.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall provide the Support Services in
                  accordance with the standards of skill and care reasonably expected from a service provider in the
                  Provider&#39;s industry.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  5.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer may use the helpdesk for the purposes
                  of requesting and, where applicable, receiving the Support Services.
                </span>
              </p>
              <p className="c0">
                <span className="c1">5.</span>
                <span className="c5">5</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider may suspend the provision of the Support
                  Services if any amount due to be paid by the Customer to the Provider under this Agreement is overdue,
                  and the Provider has given to the Customer 30 days&#39; written notice, following the amount becoming
                  overdue, of its intention to suspend the Support Services on this basis.
                </span>
              </p>
              <p className="c4 c6">
                <span className="c2"></span>
              </p>
              <h2>
                <span className="c2">6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Customer Data</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upon agreement, the Customer will grant to the
                  Provider a non-exclusive licence to copy, reproduce, store, distribute, publish, export, adapt, edit
                  and translate the Customer Data to the extent reasonably required for the performance of the
                  Provider&#39;s obligations and the exercise of the Provider&#39;s rights under this Agreement. The
                  Customer also grants to the Provider the right to sub-license these rights to its hosting,
                  connectivity and telecommunications service providers, subject to any express restrictions elsewhere
                  in this Agreement.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer warrants to the Provider that the
                  Customer Data will not infringe the Intellectual Property Rights or other legal rights of any person,
                  and will not breach the provisions of any law, statute or regulation
                </span>
                <span className="c5">,</span>
                <span className="c1">&nbsp;in any jurisdiction and under any applicable law.</span>
              </p>
              <p className="c0">
                <span className="c1">
                  6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall create a back-up copy of the
                  Customer Data at least daily, shall ensure that each such copy is sufficient to enable the Provider to
                  restore the Hosted Services to the state they were in at the time the back-up was taken, and shall
                  retain and securely store each such copy for a minimum period of 30 days.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  6.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Within the period of 1 Business Day following
                  receipt of a written request from the Customer, the Provider shall use all reasonable endeavours to
                  restore to the Platform the Customer Data stored in any back-up copy created and stored by the
                  Provider in accordance with Clause{" "}
                </span>
                <span className="c5">6</span>
                <span className="c1">
                  .3. The Customer acknowledges that this process will overwrite the Customer Data stored on the
                  Platform prior to the restoration.
                </span>
              </p>
              <h2>
                <span className="c2">7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span className="c2">Desktop</span>
                <span className="c2">&nbsp;Application</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The parties acknowledge and agree that the use of
                  the{" "}
                </span>
                <span className="c5">Desktop </span>
                <span className="c1">
                  Application, the parties&#39; respective rights and obligations in relation to the{" "}
                </span>
                <span className="c5">Desktop </span>
                <span className="c1">
                  Application and any liabilities of either party arising out of the use of the{" "}
                </span>
                <span className="c5">Desktop </span>
                <span className="c1">Application shall be subject to </span>
                <span className="c5">these</span>
                <span className="c1">&nbsp;terms and condition</span>
                <span className="c5">s.</span>
              </p>
              <h2>
                <span className="c2">
                  8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No assignment of Intellectual Property Rights
                </span>
              </h2>
              <p className="c0">
                <span className="c1">
                  8.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nothing in this Agreement shall operate to assign
                  or transfer any Intellectual Property Rights from the Provider to the Customer, or from the Customer
                  to the Provider.
                </span>
              </p>
              <h2>
                <span className="c2">9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Charges</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer shall pay the Charges to the Provider
                  in accordance with this Agreement.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the Charges are based in whole or part upon the
                  time spent by the Provider performing the Services, the Provider must obtain the Customer&#39;s
                  written consent before performing Services that result in any estimate of time-based Charges given to
                  the Customer being exceeded or any budget for time-based Charges agreed by the parties being exceeded;
                  and unless the Customer agrees otherwise in writing, the Customer shall not be liable to pay to the
                  Provider any Charges in respect of Services performed in breach of this Clause{" "}
                </span>
                <span className="c5">9</span>
                <span className="c1">.2.</span>
              </p>
              <p className="c0">
                <span className="c1">
                  9.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All amounts stated in or in relation to this
                  Agreement are, unless the context requires otherwise, stated exclusive of any applicable value added
                  taxes, which will be added to those amounts and payable by the Customer to the Provider.
                </span>
              </p>
              <p className="c0">
                <span className="c1">9.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span className="c5">If a Fixed Price for the Term has not been agreed, t</span>
                <span className="c1">
                  he Provider may elect to vary any element of the Charges by giving to the Customer not less than 30
                  days&#39; written notice of the variation, providing that no such variation shall result in an
                  aggregate percentage increase in the relevant element of the Charges during the Term that exceeds{" "}
                </span>
                <span className="c5">2</span>
                <span className="c1">
                  % per annum over the percentage increase, during the same period, in the Retail Prices Index (all
                  items) published by the UK Office for National Statistics.
                </span>
              </p>
              <h2>
                <span className="c2">10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Payments</span>
              </h2>
              <p className="c0">
                <span className="c1">10.</span>
                <span className="c5">1</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer must pay the Charges to the Provider
                  before the commencement of the period to which they relate.
                </span>
              </p>
              <p className="c0">
                <span className="c1">10.</span>
                <span className="c5">2</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer must pay the Charges by debit card,
                  credit card, direct debit, or bank transfer (using such payment details as are notified by the
                  Provider to the Customer from time to time).
                </span>
              </p>
              <p className="c0">
                <span className="c1">10.</span>
                <span className="c5">3</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the Customer does not pay any amount properly due
                  to the Provider under this Agreement, the Provider may:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;charge the Customer interest on the overdue amount
                  at the rate of 8% per annum above the Bank of England base rate from time to time
                </span>
                <span className="c5">&nbsp;</span>
                <span className="c1">
                  (which interest will accrue daily until the date of actual payment and be compounded at the end of
                  each calendar month); or
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;claim interest and statutory compensation from the
                  Customer pursuant to the Late Payment of Commercial Debts (Interest) Act 1998.
                </span>
              </p>
              <h2>
                <span className="c2">
                  11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Provider&#39;s confidentiality obligations
                </span>
              </h2>
              <p className="c0">
                <span className="c1">11.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider must:</span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;keep the Customer Confidential Information strictly
                  confidential;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;not disclose the Customer Confidential Information
                  to any person without the Customer&#39;s prior written consent
                </span>
                <span className="c5">
                  , or consent given by a User sharing content of the Hosted Services to a third party;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;use the same degree of care to protect the
                  confidentiality of the Customer Confidential Information as the Provider uses to protect the
                  Provider&#39;s own confidential information of a similar nature, being at least a reasonable degree of
                  care;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;act in good faith at all times in relation to the
                  Customer Confidential Information; and
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;not use any of the Customer Confidential
                  Information for any purpose other than
                </span>
                <span className="c5">
                  &nbsp;preparing reports, publishing files, usage analytics, monitoring the Platform
                </span>
                <span className="c1">.</span>
              </p>
              <p className="c0">
                <span className="c1">11.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notwithstanding Clause 1</span>
                <span className="c5">1</span>
                <span className="c1">
                  .1, the Provider may disclose the Customer Confidential Information to the Provider&#39;s officers,
                  employees, professional advisers, insurers, agents, support sta
                </span>
                <span className="c5">ff</span>
                <span className="c1">&nbsp;and subcontractors</span>
                <span className="c5">&nbsp;</span>
                <span className="c1">
                  who have a need to access the Customer Confidential Information for the performance of their work with
                  respect to this Agreement and who are bound by a written agreement or professional obligation to
                  protect the confidentiality of the Customer Confidential Information.
                </span>
              </p>
              <p className="c0">
                <span className="c1">11.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This Clause 1</span>
                <span className="c5">1</span>
                <span className="c1">
                  &nbsp;imposes no obligations upon the Provider with respect to Customer Confidential Information that:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is known to the Provider before disclosure under
                  this Agreement and is not subject to any other obligation of confidentiality;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is or becomes publicly known through no act or
                  default of the Provider; or
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is obtained by the Provider from a third party in
                  circumstances where the Provider has no reason to believe that there has been a breach of an
                  obligation of confidentiality.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  11.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The restrictions in this Clause 1
                </span>
                <span className="c5">1</span>
                <span className="c1">
                  &nbsp;do not apply to the extent that any Customer Confidential Information is required to be
                  disclosed by any law or regulation, by any judicial or governmental order or request, or pursuant to
                  disclosure requirements relating to the listing of the stock of the Provider on any recognised stock
                  exchange.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  11.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The provisions of this Clause 1
                </span>
                <span className="c5">1</span>
                <span className="c1">&nbsp;shall continue in force</span>
                <span className="c5">&nbsp;</span>
                <span className="c1">for a period of </span>
                <span className="c5">7</span>
                <span className="c1">
                  &nbsp;years following the termination of this Agreement, at the end of which period they will cease to
                  have effect.
                </span>
              </p>
              <h2>
                <span className="c2">12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Data protection</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  12.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Each party shall comply with the Data Protection
                  Laws with respect to the processing of the Customer Personal Data.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer warrants to the Provider that it has
                  the legal right to disclose all Personal Data that it does in fact disclose to the Provider under or
                  in connection with this Agreement.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer shall only supply to the Provider,
                  and the Provider shall only process, in each case under or in relation to this Agreement, the Personal
                  Data of data subjects falling within the categories specified in Part 1 of Schedule 3 (Data processing
                  information) and of the types specified in Part 2 of Schedule 3 (Data processing information); and the
                  Provider shall only process the Customer Personal Data for the purposes specified in Part 3 of
                  Schedule 3 (Data processing information).
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall only process the Customer
                  Personal Data during the Term and for not more than 30 days following the end of the Term, subject to
                  the other provisions of this Clause 1
                </span>
                <span className="c5">2</span>
                <span className="c1">.</span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall only process the Customer
                  Personal Data on the documented instructions of the Customer (including with regard to transfers of
                  the Customer Personal Data to any place outside the European Economic Area), as set out in this
                  Agreement or any other document agreed by the parties in writing.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall promptly inform the Customer
                  if, in the opinion of the Provider, an instruction of the Customer relating to the processing of the
                  Customer Personal Data infringes the Data Protection Laws.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notwithstanding any other provision of this
                  Agreement, the Provider may process the Customer Personal Data if and to the extent that the Provider
                  is required to do so by law
                </span>
                <span className="c5">.</span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall ensure that persons authorised
                  to process the Customer Personal Data have committed themselves to confidentiality or are under an
                  appropriate statutory obligation of confidentiality.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider and the Customer shall each implement
                  appropriate technical and organisational measures to ensure an appropriate level of security for the
                  Customer Personal Data, including those measures specified in Part 4 of Schedule 3 (Data processing
                  information).
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider must not engage any third party to
                  process the Customer Personal Data without the prior specific or general written authorisation of the
                  Customer. In the case of a general written authorisation, the Provider shall inform the Customer at
                  least
                </span>
                <span className="c5">&nbsp;7 days</span>
                <span className="c1">
                  &nbsp;in advance of any intended changes concerning the addition or replacement of any third party
                  processor, and if the Customer objects to any such changes before their implementation, then
                </span>
                <span className="c5">&nbsp;</span>
                <span className="c1">the Customer may terminate this Agreement on </span>
                <span className="c5">7 </span>
                <span className="c1">
                  days&#39; written notice to the Provider, providing that such notice must be given within the period
                  of 7 days following the date that the Provider informed the Customer of the intended changes. The
                  Provider shall ensure that each third party processor is subject to the same legal obligations as
                  those imposed on the Provider by this Clause 1
                </span>
                <span className="c5">2</span>
                <span className="c1">.</span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From the agreed upon date, the Provider is
                  authorised by the Customer to engage, as sub-processors with respect to Customer Personal Data, the
                  third parties identified in Part 5 of Schedule 3 (Data processing information).
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.12&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall, insofar as possible and
                  taking into account the nature of the processing, take appropriate technical and organisational
                  measures to assist the Customer with the fulfilment of the Customer&#39;s obligation to respond to
                  requests exercising a data subject&#39;s rights under the Data Protection Laws.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.13&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall assist the Customer in
                  ensuring compliance with the obligations relating to the security of the processing of personal data,
                  the notification of personal data breaches to the supervisory authority, the communication of personal
                  data breaches to the data subject, data protection impact assessments and prior consultation in
                  relation to high-risk processing under the Data Protection Laws. The Provider shall report any
                  Personal Data breach relating to the Customer Personal Data to the Customer within{" "}
                </span>
                <span className="c5">72</span>
                <span className="c1">&nbsp;hour</span>
                <span className="c5">s</span>
                <span className="c1">
                  &nbsp;following the Provider becoming aware of the breach. The Provider may charge the Customer at its
                  standard time-based charging rates for any work performed by the Provider at the request of the
                  Customer pursuant to this Clause 1
                </span>
                <span className="c5">2</span>
                <span className="c1">.13.</span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.14&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall make available to the Customer
                  all information necessary to demonstrate the compliance of the Provider with its obligations under
                  this Clause 1
                </span>
                <span className="c5">2</span>
                <span className="c1">&nbsp;and the Data Protection Laws.</span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.15&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall, at the choice of the
                  Customer, delete or return all of the Customer Personal Data to the Customer after the provision of
                  services relating to the processing, and shall delete existing copies save to the extent that
                  applicable law requires storage of the relevant Personal Data.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.16&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall allow for and contribute to
                  audits, including inspections, conducted by the Customer or another auditor mandated by the Customer
                  in respect of the compliance of the Provider&#39;s processing of Customer Personal Data with the Data
                  Protection Laws and this Clause 1
                </span>
                <span className="c5">2</span>
                <span className="c1">
                  . The Provider may charge the Customer at its standard time-based charging rates for any work
                  performed by the Provider at the request of the Customer pursuant to this Clause 1
                </span>
                <span className="c5">2</span>
                <span className="c1">.16.</span>
              </p>
              <p className="c0">
                <span className="c1">
                  12.17&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If any changes or prospective changes to the Data
                  Protection Laws result or will result in one or both parties not complying with the Data Protection
                  Laws in relation to processing of Personal Data carried out under this Agreement, then the parties
                  shall use their best endeavours promptly to agree such variations to this Agreement as may be
                  necessary to remedy such non-compliance.
                </span>
              </p>
              <h2>
                <span className="c2">13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Warranties</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  13.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider warrants to the Customer that:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Provider has the legal right and authority to
                  enter into this Agreement and to perform its obligations under this Agreement;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Provider will comply with all applicable legal
                  and regulatory requirements applying to the exercise of the Provider&#39;s rights and the fulfilment
                  of the Provider&#39;s obligations under this Agreement; and
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Provider has or has access to all necessary
                  know-how, expertise and experience to perform its obligations under this Agreement.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  13.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider warrants to the Customer that:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Platform and Hosted Services will conform in al
                </span>
                <span className="c5">l</span>
                <span className="c1">&nbsp;respects with the Hosted Services Specification;</span>
              </p>
              <p className="c3">
                <span className="c1">(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span className="c5">any</span>
                <span className="c1">&nbsp;Hosted Services Defects with be resolved in a</span>
                <span className="c5">&nbsp;timely manner once brought to the attention of the Provider</span>
                <span className="c1">;</span>
              </p>
              <p className="c3">
                <span className="c1">(</span>
                <span className="c5">c</span>
                <span className="c1">
                  )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Platform will be free from viruses, worms, Trojan
                  horses, ransomware, spyware, adware and other malicious software programs; and
                </span>
              </p>
              <p className="c3">
                <span className="c1">(</span>
                <span className="c5">d</span>
                <span className="c1">
                  )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Platform will incorporate security features
                  reflecting the requirements of good industry practice.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  13.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider warrants to the Customer that the
                  Hosted Services, when used by the Customer in accordance with this Agreement, will not breach any
                  laws, statutes or regulations applicable under English law.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  13.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider warrants to the Customer that the
                  Hosted Services, when used by the Customer in accordance with this Agreement, will not infringe the
                  Intellectual Property Rights of any person in any jurisdiction and under any applicable law.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  13.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the Provider reasonably determines, or any
                  third party alleges, that the use of the Hosted Services by the Customer in accordance with this
                  Agreement infringes any person&#39;s Intellectual Property Rights, the Provider may at its own cost
                  and expense:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;modify the Hosted Services in such a way that they
                  no longer infringe the relevant Intellectual Property Rights; or
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;procure for the Customer the right to use the
                  Hosted Services in accordance with this Agreement.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  13.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer warrants to the Provider that it has
                  the legal right and authority to enter into this Agreement and to perform its obligations under this
                  Agreement.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  13.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All of the parties&#39; warranties and
                  representations in respect of the subject matter of this Agreement are expressly set out in this
                  Agreement. To the maximum extent permitted by applicable law, no other warranties or representations
                  concerning the subject matter of this Agreement will be implied into this Agreement or any related
                  contract.
                </span>
              </p>
              <h2>
                <span className="c2">
                  14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Acknowledgements and warranty limitations
                </span>
              </h2>
              <p className="c0">
                <span className="c1">
                  14.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer acknowledges that complex software is
                  never wholly free from defects, errors and bugs; and subject to the other provisions of this
                  Agreement, the Provider gives no warranty or representation that the Hosted Services will be wholly
                  free from defects, errors and bugs.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  14.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer acknowledges that complex software is
                  never entirely free from security vulnerabilities; and subject to the other provisions of this
                  Agreement, the Provider gives no warranty or representation that the Hosted Services will be entirely
                  secure.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  14.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer acknowledges that the Hosted Services
                  are designed to be compatible only with that software and those systems specified as compatible in the
                  Hosted Services Specification; and the Provider does not warrant or represent that the Hosted Services
                  will be compatible with any other software or systems.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  14.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer acknowledges that the Provider will
                  not provide any legal, financial, accountancy or taxation advice under this Agreement or in relation
                  to the Hosted Services; and, except to the extent expressly provided otherwise in this Agreement, the
                  Provider does not warrant or represent that the Hosted Services or the use of the Hosted Services by
                  the Customer will not give rise to any legal liability on the part of the Customer or any other
                  person.
                </span>
              </p>
              <h2>
                <span className="c2">
                  15.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Limitations and exclusions of liability
                </span>
              </h2>
              <p className="c0">
                <span className="c1">
                  15.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nothing in this Agreement will:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;limit or exclude any liability for death or
                  personal injury resulting from negligence;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;limit or exclude any liability for fraud or
                  fraudulent misrepresentation;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;limit any liabilities in any way that is not
                  permitted under applicable law; or
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;exclude any liabilities that may not be excluded
                  under applicable law.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  15.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The limitations and exclusions of liability set
                  out in this Clause 1
                </span>
                <span className="c5">5</span>
                <span className="c1">&nbsp;and elsewhere in this Agreement: </span>
              </p>
              <p className="c3">
                <span className="c1">(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;are subject to Clause 1</span>
                <span className="c5">5</span>
                <span className="c1">.1; and</span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;govern all liabilities arising under this Agreement
                  or relating to the subject matter of this Agreement, including liabilities arising in contract, in
                  tort (including negligence) and for breach of statutory duty, except to the extent expressly provided
                  otherwise in this Agreement.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  15.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall not be liable to the Customer
                  in respect of any losses arising out of a Force Majeure Event.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  15.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall not be liable to the Customer
                  in respect of any loss of profits or anticipated savings.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  15.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall not be liable to the Customer
                  in respect of any loss of revenue or income.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  15.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall not be liable to the Customer
                  in respect of any loss of use or production.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  15.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall not be liable to the Customer
                  in respect of any loss of business, contracts or opportunities.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  15.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall not be liable to the Customer
                  in respect of any loss or corruption of any data, database or software; providing that this Clause 1
                </span>
                <span className="c5">5</span>
                <span className="c1">
                  .8 shall not protect the Provider unless the Provider has fully complied with its obligations under
                  Clause{" "}
                </span>
                <span className="c5">6</span>
                <span className="c1">.3 and Clause </span>
                <span className="c5">6</span>
                <span className="c1">.4.</span>
              </p>
              <p className="c0">
                <span className="c1">15.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span className="c5">T</span>
                <span className="c1">
                  he Provider shall not be liable to the Customer in respect of any special, indirect or consequential
                  loss or damage.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  15.10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The liability of the Provider to the Customer
                  under this Agreement in respect of any event or series of related events shall not exceed the greater
                  of:
                </span>
              </p>
              <p className="c3">
                <span className="c1">(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span className="c5">&pound;5000</span>
                <span className="c1">; and</span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the total amount paid and payable by the Customer
                  to the Provider under this Agreement in the 12 month period preceding the commencement of the event or
                  events.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  15.11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The aggregate liability of the Provider to the
                  Customer under this Agreement shall not exceed the greater of:
                </span>
              </p>
              <p className="c3">
                <span className="c1">(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span className="c5">&pound;10000</span>
                <span className="c1">; and</span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the total amount paid and payable by the Customer
                  to the Provider under this Agreement in the 24 month period preceding the commencement of the event or
                  events.
                </span>
              </p>
              <h2>
                <span className="c2">16.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Force Majeure Event</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  16.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If a Force Majeure Event gives rise to a failure
                  or delay in either party performing any obligation under this Agreement (other than any obligation to
                  make a payment), that obligation will be suspended for the duration of the Force Majeure Event.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  16.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A party that becomes aware of a Force Majeure
                  Event which gives rise to, or which is likely to give rise to, any failure or delay in that party
                  performing any obligation under this Agreement, must:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;promptly notify the other; and
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;inform the other of the period for which it is
                  estimated that such failure or delay will continue.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  16.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A party whose performance of its obligations under
                  this Agreement is affected by a Force Majeure Event must take reasonable steps to mitigate the effects
                  of the Force Majeure Event.
                </span>
              </p>
              <h2>
                <span className="c2">17.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Termination</span>
              </h2>
              <p className="c0">
                <span className="c1">17.</span>
                <span className="c5">1</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Either party may terminate this Agreement immediately
                  by giving written notice of termination to the other party if the other party commits a material
                  breach of this Agreement.
                </span>
              </p>
              <p className="c0">
                <span className="c1">17.</span>
                <span className="c5">2</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Either party may terminate this Agreement immediately
                  by giving written notice of termination to the other party if:
                </span>
              </p>
              <p className="c3">
                <span className="c1">(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the other party:</span>
              </p>
              <p className="c4 c10">
                <span className="c1">(i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is dissolved;</span>
              </p>
              <p className="c4 c10">
                <span className="c1">
                  (ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ceases to conduct all (or substantially all) of
                  its business;
                </span>
              </p>
              <p className="c4 c10">
                <span className="c1">
                  (iii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is or becomes unable to pay its debts as they
                  fall due;
                </span>
              </p>
              <p className="c4 c10">
                <span className="c1">
                  (iv)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is or becomes insolvent or is declared insolvent;
                  or
                </span>
              </p>
              <p className="c4 c10">
                <span className="c1">
                  (v)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;convenes a meeting or makes or proposes to make any
                  arrangement or composition with its creditors;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;an administrator, administrative receiver,
                  liquidator, receiver, trustee, manager or similar is appointed over any of the assets of the other
                  party;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;an order is made for the winding up of the other
                  party, or the other party passes a resolution for its winding up (other than for the purpose of a
                  solvent company reorganisation where the resulting entity will assume all the obligations of the other
                  party under this Agreement); or
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; either party has served the other party 30
                  days&#39; written notice of its intention to end the agreement and the 30 days has passed and the
                  parties have been unable to come to a resolution.
                </span>
              </p>
              <h2>
                <span className="c2">18.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Effects of termination</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  18.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upon the termination of this Agreement, all of the
                  provisions of this Agreement shall cease to have effect, save that the following provisions of this
                  Agreement shall survive and continue to have effect (in accordance with their express terms or
                  otherwise indefinitely): Clauses 1, 3.11,{" "}
                </span>
                <span className="c5">6</span>
                <span className="c1">, </span>
                <span className="c5">9</span>
                <span className="c1">.2, </span>
                <span className="c5">9</span>
                <span className="c1">.4, 1</span>
                <span className="c5">0</span>
                <span className="c1">, 1</span>
                <span className="c5">1, 12</span>
                <span className="c1">, 14, 15</span>
                <span className="c5">, 18</span>
                <span className="c1">, 2</span>
                <span className="c5">0</span>
                <span className="c1">&nbsp;and 2</span>
                <span className="c5">1</span>
                <span className="c1">.</span>
              </p>
              <p className="c0">
                <span className="c1">
                  18.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Except to the extent that this Agreement expressly
                  provides otherwise, the termination of this Agreement shall not affect the accrued rights of either
                  party.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  18.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Within 30 days following the termination of this
                  Agreement for any reason:
                </span>
              </p>
              <p className="c3">
                <span className="c1">(</span>
                <span className="c5">a</span>
                <span className="c1">
                  )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Customer must pay to the Provider any Charges in
                  respect of Services provided to the Customer before the termination of this Agreement; and
                </span>
              </p>
              <p className="c3">
                <span className="c1">(</span>
                <span className="c5">b</span>
                <span className="c1">
                  )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Provider must refund to the Customer any Charges
                  paid by the Customer to the Provider in respect of Services that were to be provided to the Customer
                  after the termination of this Agreement,
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;without prejudice to the parties&#39; other legal
                  rights.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  18.4 Within 5 Business Days, the Provider must provide a copy of the final versions of all files that
                  were held in the platform, at the expense of the Customer.
                </span>
              </p>
              <h2>
                <span className="c2">19.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notices</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  19.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any notice from one party to the other party under
                  this Agreement must be given by one of the following methods (using the relevant contact details set
                  out in Clause{" "}
                </span>
                <span className="c5">19</span>
                <span className="c1">.2 and Part 3 of Schedule 1 (Hosted Services particulars)): </span>
              </p>
              <p className="c3">
                <span className="c1">(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span className="c1">
                  sent by email, in which case the notice shall be deemed to be received 1 Business Day following
                  sending,
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;providing that, if the stated time of deemed receipt
                  is not within Business Hours, then the time of deemed receipt shall be when Business Hours next begin
                  after the stated time.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  19.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider&#39;s contact details for notices
                  under this Clause{" "}
                </span>
                <span className="c5">19</span>
                <span className="c1">&nbsp;are as follows: contact@</span>
                <span className="c5">godragons.com</span>
                <span className="c1">.</span>
              </p>
              <p className="c0">
                <span className="c1">
                  19.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The addressee and contact details set out in
                  Clause{" "}
                </span>
                <span className="c5">19</span>
                <span className="c1">
                  .2 and Part 3 of Schedule 1 (Hosted Services particulars) may be updated from time to time by a party
                  giving written notice of the update to the other party in accordance with this Clause{" "}
                </span>
                <span className="c5">19</span>
                <span className="c1">.</span>
              </p>
              <h2>
                <span className="c2">20.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Subcontracting</span>
              </h2>
              <p className="c0">
                <span className="c1">20.</span>
                <span className="c5">1</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Subject to any express restrictions elsewhere in this
                  Agreement, the Provider may subcontract any of its obligations under this Agreement, providing that
                  the Provider must give to the Customer, promptly following the appointment of a subcontractor, a
                  written notice specifying the subcontracted obligations and identifying the subcontractor in question.
                </span>
              </p>
              <p className="c0">
                <span className="c1">20.</span>
                <span className="c5">2</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Provider shall remain responsible to the Customer
                  for the performance of any subcontracted obligations.
                </span>
              </p>
              <p className="c0">
                <span className="c1">20.</span>
                <span className="c5">3</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notwithstanding the provisions of this Clause 20 but
                  subject to any other provision of this Agreement, the Customer acknowledges and agrees that the
                  Provider may subcontract to any reputable third party hosting business the hosting of the Platform and
                  the provision of services in relation to the support and maintenance of elements of the Platform.
                </span>
              </p>
              <h2>
                <span className="c2">21.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;General</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  21.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No breach of any provision of this Agreement shall
                  be waived except with the express written consent of the party not in breach.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  21.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If any provision of this Agreement is determined
                  by any court or other competent authority to be unlawful and/or unenforceable, the other provisions of
                  this Agreement will continue in effect. If any unlawful and/or unenforceable provision would be lawful
                  or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the
                  provision will continue in effect (unless that would contradict the clear intention of the parties, in
                  which case the entirety of the relevant provision will be deemed to be deleted).
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  21.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This Agreement may not be varied except by a
                  written document signed by or on behalf of each of the parties.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  21.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Neither party may without the prior written
                  consent of the other party assign, transfer, charge, license or otherwise deal in or dispose of any
                  contractual rights or obligations under this Agreement.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  21.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This Agreement is made for the benefit of the
                  parties, and is not intended to benefit any third party or be enforceable by any third party. The
                  rights of the parties to terminate, rescind, or agree any amendment, waiver, variation or settlement
                  under or relating to this Agreement are not subject to the consent of any third party.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  21.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Subject to Clause 15.1, this Agreement shall
                  constitute the entire agreement between the parties in relation to the subject matter of this
                  Agreement, and shall supersede all previous agreements, arrangements and understandings between the
                  parties in respect of that subject matter.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  21.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This Agreement shall be governed by and construed
                  in accordance with English law.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  21.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The courts of England shall have exclusive
                  jurisdiction to adjudicate any dispute arising under or in connection with this Agreement.
                </span>
              </p>
              <h2>
                <span className="c2">22.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Interpretation</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  22.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In this Agreement, a reference to a statute or
                  statutory provision includes a reference to:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;that statute or statutory provision as modified,
                  consolidated and/or re-enacted from time to time; and
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;any subordinate legislation made under that statute
                  or statutory provision.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  22.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Clause headings do not affect the
                  interpretation of this Agreement.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  22.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;References in this Agreement to &quot;calendar
                  months&quot; are to the 12 named periods (January, February and so on) into which a year is divided.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  22.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In this Agreement, general words shall not be
                  given a restrictive interpretation by reason of being preceded or followed by words indicating a
                  particular class of acts, matters or things.
                </span>
              </p>
              <p className="c4 c6">
                <span className="c11 c9"></span>
              </p>
              <p className="c4 c6">
                <span className="c11 c9"></span>
              </p>
              <p className="c4 c6">
                <span className="c9 c11"></span>
              </p>
              <p className="c4 c6">
                <span className="c11 c9"></span>
              </p>
              <p className="c4 c6">
                <span className="c11 c9"></span>
              </p>
              <p className="c4 c6">
                <span className="c11 c9"></span>
              </p>
              <h2>
                <span className="c11 c9">Schedule 1 (Hosted Services particulars)</span>
              </h2>
              <h3>
                <span className="c2">
                  1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Specification of Hosted Services
                </span>
              </h3>
              <p>
                <span className="c5">The product is called DraughtHub. It is made available by the website </span>
                <span className="c5 c14">
                  <a
                    className="c15"
                    href="https://www.google.com/url?q=http://www.draughthub.com&amp;sa=D&amp;source=editors&amp;ust=1663246438256911&amp;usg=AOvVaw1T_WvjKRocLj3b4Nk_nTTL"
                  >
                    www.draughthub.com
                  </a>
                </span>
                <span className="c1">&nbsp;and the Desktop Application, DraughtHub Link.</span>
              </p>
              <p>
                <span className="c1">
                  DraughtHub makes the versioning and reviewing of CAD drawings, calculations, and reports easy and
                  collaborative. It stores backups of all the data in the AWS S3 cloud. With the Desktop Application,
                  users can work on CAD drawings and calculations on their Windows machines. Changes are synced to the
                  cloud when the files are saved. CAD drawings are published asynchronously on save.
                </span>
              </p>
              <p>
                <span className="c1">
                  DraughtHub is compatible with the current release from time to time of the operating system: Windows
                  10; and the current release from time to time of the web browsers: Microsoft Edge, Google Chrome and
                  Mozilla Firefox.
                </span>
              </p>
              <p className="c4 c6">
                <span className="c5 c12 c16"></span>
              </p>
              <h2>
                <span className="c2">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contractual notices</span>
              </h2>
              <p>
                <span className="c5">Any contractual notices must be sent by email to contact@godragons.com. </span>
              </p>
              <p className="c4 c6">
                <span className="c7"></span>
              </p>
              <h2>
                <span className="c11 c9">Schedule 2 (Acceptable Use Policy)</span>
              </h2>
              <h3>
                <span className="c2">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Introduction</span>
              </h3>
              <p className="c0">
                <span className="c1">
                  1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This acceptable use policy (the &quot;
                </span>
                <span className="c2">Policy</span>
                <span className="c1">&quot;) sets out the rules governing:</span>
              </p>
              <p className="c3">
                <span className="c1">(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the use of the website at</span>
                <span className="c5">&nbsp;www.draughthub.com</span>
                <span className="c1">
                  , any successor website, and the services available on that website or any successor website (the
                  &quot;
                </span>
                <span className="c2">Services</span>
                <span className="c1">&quot;);</span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the use of the Desktop Application, DraughtHub
                  Link, any successor application; and
                </span>
              </p>
              <p className="c3">
                <span className="c1">(</span>
                <span className="c5">c</span>
                <span className="c1">
                  )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the transmission, storage and processing of content
                  by you, or by any person on your behalf, using the Services (&quot;
                </span>
                <span className="c2">Content</span>
                <span className="c1">&quot;).</span>
              </p>
              <p className="c0">
                <span className="c1">
                  1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;References in this Policy to &quot;you&quot; are to
                  any customer for the Services and any individual user of the Services (and &quot;your&quot; should be
                  construed accordingly); and references in this Policy to &quot;us&quot; are to{" "}
                </span>
                <span className="c5">GoDragons</span>
                <span className="c1">
                  &nbsp;(and &quot;we&quot; and &quot;our&quot; should be construed accordingly).
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By using the Services, you agree to the rules set
                  out in this Policy.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We will ask for your express agreement to the terms
                  of this Policy before you{" "}
                </span>
                <span className="c5">move from the free trial to the paid DraughtHub Core feature set</span>
                <span className="c1">.</span>
              </p>
              <h2>
                <span className="c2">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;General usage rules</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not use the Services in any way that
                  causes, or may cause, damage to the Services or impairment of the availability or accessibility of the
                  Services.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not use the Services:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;in any way that is unlawful, illegal, fraudulent,
                  deceptive or harmful; or
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;in connection with any unlawful, illegal,
                  fraudulent, deceptive or harmful purpose or activity.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must ensure that all Content complies with the
                  provisions of this Policy.
                </span>
              </p>
              <h2>
                <span className="c2">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unlawful Content</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content must not be illegal or unlawful, must not
                  infringe any person&#39;s legal rights, and must not be capable of giving rise to legal action against
                  any person (in each case in any jurisdiction and under any applicable law).
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content, and the use of Content by us in any manner
                  licensed or otherwise authorised by you, must not:
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;be libellous or maliciously false;
                </span>
              </p>
              <p className="c3">
                <span className="c1">(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;be obscene or indecent;</span>
              </p>
              <p className="c3">
                <span className="c1">
                  (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;infringe any copyright, moral right, database
                  right, trade mark right, design right, right in passing off, or other intellectual property right;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;infringe any right of confidence, right of privacy
                  or right under data protection legislation;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;constitute negligent advice or contain any
                  negligent statement;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;constitute an incitement to commit a crime,
                  instructions for the commission of a crime or the promotion of criminal activity;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (g)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;be in contempt of any court, or in breach of any
                  court order;
                </span>
              </p>
              <p className="c3">
                <span className="c1">
                  (h)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;constitute a breach of racial or religious hatred
                  or discrimination legislation;
                </span>
              </p>
              <p className="c3">
                <span className="c1">(</span>
                <span className="c5">i</span>
                <span className="c1">
                  )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;constitute a breach of official secrets legislation;
                  or
                </span>
              </p>
              <p className="c3">
                <span className="c1">(</span>
                <span className="c5">j</span>
                <span className="c1">
                  )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;constitute a breach of any contractual obligation
                  owed to any person.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  3.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must ensure that Content is not and has never
                  been the subject of any threatened or actual legal proceedings or other similar complaint.
                </span>
              </p>
              <h2>
                <span className="c2">4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Graphic material</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content must be appropriate for all persons who
                  have access to or are likely to access the Content in question.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content must not depict violence in an explicit,
                  graphic or gratuitous manner.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content must not be pornographic or sexually
                  explicit.
                </span>
              </p>
              <h2>
                <span className="c2">5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Factual accuracy</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content must not be untrue, false, inaccurate or
                  misleading.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Statements of fact contained in Content and
                  relating to persons (legal or natural) must be true; and statements of opinion contained in Content
                  and relating to persons (legal or natural) must be reasonable, be honestly held and indicate the basis
                  of the opinion.
                </span>
              </p>
              <h2>
                <span className="c2">6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Negligent advice</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content must not consist of or contain any legal,
                  financial, investment, taxation, accountancy, or{" "}
                </span>
                <span className="c5">m</span>
                <span className="c1">
                  edical advice, and you must not use the Services to provide any legal, financial, investment,
                  taxation, accountancy, or medical advisory services.
                </span>
              </p>
              <p className="c0">
                <span className="c1">6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content must not </span>
                <span className="c5">knowingly </span>
                <span className="c1">
                  consist of or contain any advice, instructions or other information that may be acted upon and could,
                  if acted upon, cause death, illness or personal injury, damage to property, or any other loss or
                  damage.
                </span>
              </p>
              <p className="c4 c6">
                <span className="c2"></span>
              </p>
              <h2>
                <span className="c2">7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Etiquette</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content must be appropriate, civil and tasteful,
                  and accord with generally accepted standards of etiquette and behaviour{" "}
                </span>
                <span className="c5">in the workplace</span>
                <span className="c1">.</span>
              </p>
              <p className="c0">
                <span className="c1">
                  7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content must not be offensive, deceptive,
                  threatening, abusive, harassing, menacing, hateful, discriminatory or inflammatory.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  7.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content must not be liable to cause annoyance,
                  inconvenience or needless anxiety.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  7.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not use the Services to send any hostile
                  communication or any communication intended to insult, including such communications directed at a
                  particular person or group of people.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  7.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not use the Services for the purpose of
                  deliberately upsetting or offending others.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  7.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not unnecessarily flood the Services with
                  material relating to a particular subject or subject area, whether alone or in conjunction with
                  others.
                </span>
              </p>
              <p className="c0">
                <span className="c1">7.</span>
                <span className="c5">7</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must at all times be courteous and polite to other
                  users of the Services.
                </span>
              </p>
              <h2>
                <span className="c2">8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Marketing and spam</span>
              </h2>
              <p className="c0">
                <span className="c1">8.</span>
                <span className="c5">1</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content must not constitute or contain spam, and you
                  must not use the Services to store or transmit spam - which for these purposes shall include all
                  unlawful marketing communications and unsolicited commercial communications.
                </span>
              </p>
              <p className="c0">
                <span className="c1">8.</span>
                <span className="c5">2</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not send any spam or other marketing
                  communications to any person using any email address or other contact details made available through
                  the Services or that you find using the Services.
                </span>
              </p>
              <p className="c0">
                <span className="c1">8.</span>
                <span className="c5">3</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not use the Services to promote, host or
                  operate any chain letters, Ponzi schemes, pyramid schemes, matrix programs, multi-level marketing
                  schemes, &quot;get rich quick&quot; schemes or similar letters, schemes or programs.
                </span>
              </p>
              <p className="c0">
                <span className="c1">8.</span>
                <span className="c5">4</span>
                <span className="c1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not use the Services in any way which is
                  liable to result in the blacklisting of any of our IP addresses.
                </span>
              </p>
              <p className="c4 c6">
                <span className="c2"></span>
              </p>
              <h2>
                <span className="c2">9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Regulated businesses</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not use the Services for any purpose
                  relating to gambling, gaming, betting, lotteries, sweepstakes, prize competitions or any
                  gambling-related activity.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not use the Services for any purpose
                  relating to the offering for sale, sale or distribution of drugs or pharmaceuticals.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  9.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not use the Services for any purpose
                  relating to the offering for sale, sale or distribution of knives, guns or other weapons.
                </span>
              </p>
              <p className="c4 c6">
                <span className="c2"></span>
              </p>
              <h2>
                <span className="c2">10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Monitoring</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You acknowledge that we may actively monitor the
                  Content and the use of the Services.
                </span>
              </p>
              <h2>
                <span className="c2">11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Data mining</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  11.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not conduct any systematic or automated
                  data scraping, data mining, data extraction or data harvesting, or other systematic or automated data
                  collection activity, by means of or in relation to the Services.
                </span>
              </p>
              <h2>
                <span className="c2">12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hyperlinks</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  12.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not link to any material using or by
                  means of the Services that would, if it were made available through the Services, breach the
                  provisions of this Policy.
                </span>
              </p>
              <h2>
                <span className="c2">13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Harmful software</span>
              </h2>
              <p className="c0">
                <span className="c1">
                  13.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Content must not contain or consist of, and
                  you must not promote, distribute or execute by means of the Services, any viruses, worms, spyware,
                  adware or other harmful or malicious software, programs, routines, applications or technologies.
                </span>
              </p>
              <p className="c0">
                <span className="c1">
                  13.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Content must not contain or consist of, and
                  you must not promote, distribute or execute by means of the Services, any software, programs,
                  routines, applications or technologies that will or may have a material negative effect upon the
                  performance of a computer or introduce material security risks to a computer.
                </span>
              </p>
              <p className="c4 c6">
                <span className="c7"></span>
              </p>
              <h2>
                <span className="c11 c9">Schedule 3 (Data processing information)</span>
              </h2>
              <h3>
                <span className="c2">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Categories of data subject</span>
              </h3>
              <p>
                <span className="c1">
                  The personal information of the Users who make up the Organisation of the Customer will be processed.
                </span>
              </p>
              <h3>
                <span className="c2">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Types of Personal Data</span>
              </h3>
              <p>
                <span className="c5">Names, email addresses and telephone numbers.</span>
              </p>
              <h3>
                <span className="c2">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Purposes of processing</span>
              </h3>
              <p>
                <span className="c5">
                  This will be for monitoring the services, logs, for billing and for user management.
                </span>
              </p>
              <h3>
                <span className="c2">
                  4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Security measures for Personal Data
                </span>
              </h3>
              <p>
                <span className="c5">
                  Personal data is kept in AWS Cognito and AWS DynamoDB. Both cloud services have high security measures
                  and go through regular third party audits. We take security very seriously and have designed the
                  platform with security in mind. Users from an Organisation can only see other Users from the same
                  Organisation.
                </span>
              </p>
              <h3>
                <span className="c2">
                  5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sub-processors of Personal Data
                </span>
              </h3>
              <p>
                <span className="c1">Personal data is kept in AWS Cognito and AWS DynamoDB.</span>
              </p>
              <p>
                <span className="c1">
                  Logs are sent to Sentry and AWS Cloudwatch and they may contain personal information.
                </span>
              </p>
              <p className="c4 c6">
                <span className="c1"></span>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
