// import { useState } from "react";
import { Typography, Button, Modal } from "antd";

import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import FormattingRule from "./FormattingRule/FormattingRule";
import InfoItem from "InfoItem/InfoItem";

import "./ConditionalFormatting.scss";

export default function TextVariables({
  object,
  updateObject,
  fileTypeDetails,
  form,
  organisationDetails,
  isDataSection,
}) {
  let fieldName = "custom_conditionalFormattingRules";

  function displayRules() {
    if (!object[fieldName]) {
      return null;
    }

    return object[fieldName].map((rule, index) => {
      return (
        <div className="rule-item" key={index}>
          <div className="rule-details">
            <InfoItem label="Index" inline value={index + 1} />

            <FormattingRule
              key={index}
              rule={rule}
              index={index}
              object={object}
              fileTypeDetails={fileTypeDetails}
              updateRule={updateRule}
              form={form}
              organisationDetails={organisationDetails}
            />
          </div>

          <Button
            className="delete-rule-button"
            onClick={() => {
              onDeleteRuleClick({ index });
            }}
            icon={<DeleteOutlined />}
          />
        </div>
      );
    });
  }

  function updateRule({ rule, index, object, fields }) {
    let newRules = JSON.parse(JSON.stringify(object[fieldName] || []));

    newRules[index] = {
      ...rule,
      ...fields,
    };

    updateObject({
      objectId: object.custom_id,
      fields: {
        [fieldName]: newRules,
      },
    });
  }

  function onDeleteRuleClick({ index }) {
    Modal.confirm({
      title: `Are you sure you want to delete rule ${index + 1}?`,
      okText: "Yes, delete",
      cancelText: "No",
      onOk: () => {
        deleteRule(index);
      },
    });
  }

  function deleteRule(index) {
    const newRules = JSON.parse(JSON.stringify(object[fieldName] || []));

    newRules.splice(index, 1);
    updateObject({
      objectId: object.custom_id,
      fields: {
        [fieldName]: newRules,
      },
    });
  }

  function onAddRuleClick() {
    const newRules = JSON.parse(JSON.stringify(object[fieldName] || []));

    let newRule = {
      symbol: `$${newRules.length + 1}`,
    };

    if (isDataSection) {
      newRule.data = null;
    }

    newRules.push(newRule);
    updateObject({
      objectId: object.custom_id,
      fields: {
        [fieldName]: newRules,
      },
    });
  }

  return (
    <div className="conditional-formatting">
      <div className="section-header">
        <Typography.Text className="section-title">Conditional formatting</Typography.Text>
        <Button icon={<PlusCircleOutlined />} type="primary" onClick={onAddRuleClick}>
          Add formatting rule
        </Button>
      </div>
      {displayRules()}
    </div>
  );
}
