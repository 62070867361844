import React from "react";
import cx from "classnames";
import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import "./Signature.scss";

type Props = {
  user?: any;
  onEdit?: (e: React.MouseEvent) => void;
  selected?: boolean;
  className?: string;
  size?: string;
};

export default class Signature extends React.Component<Props> {
  render() {
    const { user, onEdit, selected, className, size = "normal" } = this.props;
    let signatureName: React.ReactNode | null = null;

    let initials = "X";
    if (user) {
      initials = `${user.firstName} ${user.lastName}`;
    }
    signatureName = (
      <span className="signature-name" data-cy="signature-name">
        {initials}
      </span>
    );

    return (
      <div
        data-testid={`signature-${user.id}`}
        className={cx("signature", size, className, {
          selected,
          "with-photo": user.signature,
          "without-photo": !user.signature,
        })}
      >
        {user.signature && (
          <div className="symbol">
            <img className="signature-image" src={user.signature} data-cy="signature-image" alt="" />
          </div>
        )}
        <Button icon={<UploadOutlined />} type="primary" onClick={onEdit}>
          Upload signature
        </Button>
      </div>
    );
  }
}
