import { Button } from "antd";

import { isAuthorised } from "common/permissions";

export default function ButtonWithPermissions(props) {
  const { permissions, children, disabled } = props;

  return (
    <Button {...props} disabled={!isAuthorised(permissions) || disabled}>
      {children}
    </Button>
  );
}
