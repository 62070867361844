import moment from "moment";

import { enhanceQuoteWithInvoicing } from "common/invoiceHelpers/sharedInvoiceHelpers";
import { isAuthorised } from "common/permissions";

export function getFilteredTasks(params) {
  const { tasks, clients, projects, filter, sortBy = "order" } = params;
  let targetText = null;

  if (!filter) {
    return tasks;
  }

  if (Object.keys(filter).length === 0) {
    return [];
  }

  if (filter.containsText) {
    targetText = filter.containsText.contains.toLowerCase();
  }

  let filteredTasks = tasks
    .filter((task) => {
      if (task.isHidden || task.isExternalReference) {
        return false;
      }

      task.client = clients.find((x) => x.id === task.clientId);
      task.project = projects.find((x) => x.id === task.projectId);

      if (!task.client || !task.project) {
        return false;
      }

      if (filter.callbacks && filter.callbacks.length) {
        let callbacksPassed = filter.callbacks.every((callback) => callback(task));
        if (!callbacksPassed) {
          return false;
        }
      }

      if (
        filter.createdAt &&
        (moment(task.createdAt).isBefore(filter.createdAt.between[0]) ||
          moment(task.createdAt).isAfter(filter.createdAt.between[1]))
      ) {
        return false;
      }

      if (
        filter.finishedAt &&
        (!task.finishedAt ||
          moment(task.finishedAt).isBefore(filter.finishedAt.between[0]) ||
          moment(task.finishedAt).isAfter(filter.finishedAt.between[1]))
      ) {
        return false;
      }

      if (
        filter.dueDate &&
        (!task.dueDate || task.dueDate < filter.dueDate.between[0] || task.dueDate > filter.dueDate.between[1])
      ) {
        return false;
      }

      if (targetText) {
        let matchesText = false;

        if (task.client.name.toLowerCase().includes(targetText)) {
          matchesText = true;
        } else if ((task.project.title || "").toLowerCase().includes(targetText)) {
          matchesText = true;
        } else if (task.title.toLowerCase().includes(targetText)) {
          matchesText = true;
        } else if ((task.subtitle || "").toLowerCase().includes(targetText)) {
          matchesText = true;
        } else if (task.id.toLowerCase().includes(targetText)) {
          matchesText = true;
        } else if ((task.assignedTo || "").toLowerCase().includes(targetText)) {
          matchesText = true;
        }
        if (!matchesText) {
          return false;
        }
      }

      return true;
    })
    .sort((a, b) => (a[sortBy] < b[sortBy] ? -1 : 1));

  return filteredTasks;
}

export function getFilteredProjects({ projects, filter }) {
  let targetText = null;

  if (Object.keys(filter).length === 0) {
    return [];
  }

  if (filter.containsText) {
    targetText = filter.containsText.contains.toLowerCase();
  }

  let filteredProjects = projects.filter((project) => {
    if (filter.callbacks && filter.callbacks.length) {
      let callbacksPassed = filter.callbacks.every((callback) => callback(project));
      if (!callbacksPassed) {
        return false;
      }
    }

    if (filter.assignedTo && project.assignedTo !== filter.assignedTo.eq) {
      return false;
    }

    if (targetText) {
      let matchesText = false;
      if (project.title.toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if (project.id.toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if ((project.assignedTo || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      }

      if (!matchesText) {
        return false;
      }
    }

    return true;
  });

  return filteredProjects;
}

export function getFilteredQuotes({ quotes, filter, clients, projects, apiUser }) {
  let targetText = null;

  if (Object.keys(filter).length === 0) {
    return [];
  }

  quotes = quotes.map(enhanceQuoteWithInvoicing);

  if (filter.containsText) {
    targetText = filter.containsText.contains.toLowerCase();
  }

  // const canSeeOthersQuotes = isAuthorised(["QUOTES.VIEW_OTHERS_QUOTES"]);
  let filteredQuotes = quotes.filter((quote) => {
    // if (!canSeeOthersQuotes && quote.assignedTo !== apiUser.id) {
    //   return false;
    // }

    quote.client = clients.find((x) => x.id === quote.clientId);
    quote.project = projects.find((x) => x.id === quote.projectId);
    if (filter.callbacks && filter.callbacks.length) {
      let callbacksPassed = filter.callbacks.every((callback) => callback(quote));
      if (!callbacksPassed) {
        return false;
      }
    }

    if (targetText) {
      let matchesText = false;
      if (quote.title.toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if (quote.id.toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if ((quote.assignedTo || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if ((quote.project?.title || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      }

      if (!matchesText) {
        return false;
      }
    }

    return true;
  });

  return filteredQuotes;
}

export function getFilteredInvoices({ invoices, filter, clients, projects }) {
  let targetText = null;

  if (Object.keys(filter).length === 0) {
    return [];
  }

  if (filter.containsText) {
    targetText = filter.containsText.contains.toLowerCase();
  }

  let filteredInvoices = invoices.filter((invoice) => {
    invoice.client = clients.find((x) => x.id === invoice.clientId);
    invoice.project = projects.find((x) => x.id === invoice.projectId);
    if (filter.callbacks && filter.callbacks.length) {
      let callbacksPassed = filter.callbacks.every((callback) => callback(invoice));
      if (!callbacksPassed) {
        return false;
      }
    }

    if (targetText) {
      let matchesText = false;
      // if (invoice.title.toLowerCase().includes(targetText)) {
      //   matchesText = true;
      // } else
      if (invoice?.id?.toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if ((invoice?.author || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if ((invoice?.project?.title || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if ((invoice?.client?.name || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      }

      if (!matchesText) {
        return false;
      }
    }

    return true;
  });

  return filteredInvoices;
}

export function getFilteredPurchaseOrders({ purchaseOrders, filter, suppliers, projects }) {
  let targetText = null;

  if (Object.keys(filter).length === 0) {
    return [];
  }

  if (filter.containsText) {
    targetText = filter.containsText.contains.toLowerCase();
  }

  let filteredPurchaseOrders = purchaseOrders.filter((purchaseOrder) => {
    purchaseOrder.supplier = suppliers.find((x) => x.id === purchaseOrder.supplierId);
    purchaseOrder.project = projects.find((x) => x.id === purchaseOrder.projectId);
    if (filter.callbacks && filter.callbacks.length) {
      let callbacksPassed = filter.callbacks.every((callback) => callback(purchaseOrder));
      if (!callbacksPassed) {
        return false;
      }
    }

    if (targetText) {
      let matchesText = false;
      if (purchaseOrder.title.toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if (purchaseOrder.id.toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if ((purchaseOrder.author || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if ((purchaseOrder.project?.title || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if ((purchaseOrder.supplier?.name || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      }

      if (!matchesText) {
        return false;
      }
    }

    return true;
  });

  return filteredPurchaseOrders;
}

export function getFilteredRequests({ requests, filter, clients, projects, apiUser }) {
  let targetText = null;

  if (Object.keys(filter).length === 0) {
    return [];
  }

  if (filter.containsText) {
    targetText = filter.containsText.contains.toLowerCase();
  }

  const canSeeOthersRequests = isAuthorised(["REQUESTS.VIEW_OTHERS_REQUESTS", "REQUESTS.EXECUTE"]);
  let filteredRequests = requests.filter((request) => {
    let projectDetails = clients.find((x) => x.id === request.clientId);
    let clientDetails = projects.find((x) => x.id === request.projectId);
    if (!canSeeOthersRequests && request.requestedBy !== apiUser.id) {
      if (clientDetails?.sharedWithUsers?.includes(apiUser.id)) {
        return true;
      }

      if (projectDetails?.sharedWithUsers?.includes(apiUser.id)) {
        return true;
      }

      return false;
    }

    request.client = clientDetails;
    request.project = projectDetails;

    if (filter.callbacks && filter.callbacks.length) {
      let callbacksPassed = filter.callbacks.every((callback) => callback(request));
      if (!callbacksPassed) {
        return false;
      }
    }

    if (targetText) {
      let matchesText = false;
      if ((request.title || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if ((request.id || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if ((request.assignedTo || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if ((request.project?.title || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      } else if ((request.latestFormName || "").toLowerCase().includes(targetText)) {
        matchesText = true;
      }

      if (!matchesText) {
        return false;
      }
    }

    return true;
  });

  return filteredRequests;
}
