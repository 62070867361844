import React from "react";
import { Button, Typography, Modal, Checkbox } from "antd";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import cx from "classnames";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";

import Card from "Card/Card";
import ClientContactModal from "Modals/ClientContactModal/ClientContactModal";

import "./ContactList.scss";

export class ContactList extends React.Component {
  state = {
    isContactModalVisible: false,
    selectedContact: null,
  };

  confirmDeleteContact = ({ contact, parent, queryName }) => {
    Modal.confirm({
      title: "Confirm contact delete",
      className: "delete-contact-modal",
      content: (
        <>
          Are you sure you want to delete contact <b>{contact.label}</b>?
        </>
      ),
      onOk: async () => {
        await callGraphQLSimple({
          message: "Could not delete contact",
          queryName,
          variables: {
            input: {
              id: parent.id,
              contacts: (parent.contacts || []).filter((x) => x.id !== contact.id),
            },
          },
        });
        this.setState({ isContactModalVisible: false });
      },
    });
  };

  onClientContactModalSubmit = async (contactDetails) => {
    const { selectedContact } = this.state;
    const { parent, queryName } = this.props;
    if (!selectedContact) {
      await callGraphQLSimple({
        message: "Could not add contact",
        queryName,
        variables: {
          input: {
            id: parent.id,
            contacts: [...(parent.contacts || []), contactDetails],
          },
        },
      });
    } else {
      await callGraphQLSimple({
        message: "Could not update contact",
        queryName,
        variables: {
          input: {
            id: parent.id,
            contacts: [
              ...(parent.contacts || []).map((contact) => {
                if (contact.id === contactDetails.id) {
                  return contactDetails;
                }

                return contact;
              }),
            ],
          },
        },
      });
    }

    this.setState({ isContactModalVisible: false, selectedContact: null });
  };

  reorder = (startIndex, endIndex) => {
    const { parent } = this.props;

    const result = Array.from(parent.contacts);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = async (result) => {
    // item dropped outside the list

    const { parent, queryName, setProps, context } = this.props;
    if (!result.destination) {
      return;
    }

    const reorderedContacts = this.reorder(result.source.index, result.destination.index);
    setProps({
      context: {
        ...context,
        organisation: {
          ...parent,
          contacts: reorderedContacts,
        },
        parent: {
          ...parent,
          contacts: reorderedContacts,
        },
      },
    });
    await callGraphQLSimple({
      message: "Could not reorder file statuses",
      queryName,
      variables: {
        input: {
          id: parent.id,
          contacts: reorderedContacts,
        },
      },
    });
  };

  updateProjectContacts = async (clientContacts, project) => {
    await callGraphQLSimple({
      message: `Could not update ${getSimpleLabel("project")} contacts`,
      queryName: "updateProject",
      variables: {
        input: {
          id: project.id,
          clientContacts,
        },
      },
    });
  };

  sortContacts = (contactList) => {
    return [...(contactList || [])].sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }

      if (a.id > b.id) {
        return 1;
      }

      return 0;
    });
  };

  render() {
    const { isContactModalVisible, selectedContact } = this.state;
    const { parent, queryName, isDisplayedOnProjectDetailsPage, client } = this.props;

    let title = null;
    if (isDisplayedOnProjectDetailsPage) {
      title = "Client contacts assigned to this project (select all that apply)";
    } else {
      title = "Contacts";
    }

    return (
      <Card
        title={title}
        className={cx("contact-list", "with-border")}
        withSpace
        actions={
          <>
            {isDisplayedOnProjectDetailsPage ? null : (
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => this.setState({ isContactModalVisible: true, selectedContact: undefined })}
                data-cy="add-contact-button"
                className="add-contact-button"
              >
                Add contact
              </Button>
            )}
          </>
        }
      >
        {isDisplayedOnProjectDetailsPage ? (
          <div className="client-contacts-list">
            <Checkbox.Group
              defaultValue={parent.clientContacts}
              onChange={(contacts) => this.updateProjectContacts(contacts, parent)}
            >
              {this.sortContacts(client?.contacts)?.map((contact) => {
                const contactFullName = `${contact.firstName || ""} ${contact.lastName || ""}`;
                const contactEmail = contact.email || "no email address";

                return (
                  <Checkbox className="client-contact-checkbox" value={contact.id} key={contact.id}>
                    <span className="client-contact-id">{contact.id}</span>
                    <br />
                    <span className="client-contact-full-name">
                      {contactFullName.trim() ? contactFullName : "No name set"} - {contactEmail}
                    </span>
                  </Checkbox>
                );
              })}
            </Checkbox.Group>
          </div>
        ) : (
          <div className="contact-list">
            {this.sortContacts(parent?.contacts).map((contact, index) => {
              return (
                <div
                  data-cy="contact-item"
                  className="contact"
                  data-contact-name={`${contact.firstName} ${contact.lastName}`}
                  data-contact-id={contact.id}
                >
                  <Typography.Text className="contact-id" data-cy="contact-id">
                    {contact.id}
                  </Typography.Text>
                  <Typography.Text className="contact-detail-item" data-cy="first-name">
                    <Typography.Text className="contact-detail-item-label">First Name:</Typography.Text>
                    {contact.firstName}
                  </Typography.Text>
                  <Typography.Text className="contact-detail-item" data-cy="last-name">
                    <Typography.Text className="contact-detail-item-label">Last Name:</Typography.Text>
                    {contact.lastName}
                  </Typography.Text>
                  <Typography.Text className="contact-detail-item" data-cy="first-name">
                    <Typography.Text className="contact-detail-item-label">Position:</Typography.Text>
                    {contact.position}
                  </Typography.Text>
                  <Typography.Text className="contact-detail-item" data-cy="email">
                    <Typography.Text className="contact-detail-item-label">Email:</Typography.Text>
                    {contact.email}
                  </Typography.Text>
                  <Typography.Text className="contact-detail-item" data-cy="phone">
                    <Typography.Text className="contact-detail-item-label">Phone:</Typography.Text>
                    {contact.phone}
                  </Typography.Text>
                  <Button
                    icon={<DeleteOutlined />}
                    className="delete-contact"
                    onClick={() => this.confirmDeleteContact({ contact, parent, queryName })}
                    data-cy="delete-contact-button"
                  />
                  <Button
                    icon={<EditOutlined />}
                    className="edit-contact"
                    onClick={() => this.setState({ selectedContact: contact, isContactModalVisible: true })}
                    data-cy="edit-contact-button"
                  />
                </div>
              );
            })}
          </div>
        )}
        {isContactModalVisible && (
          <ClientContactModal
            onClose={() => this.setState({ isContactModalVisible: false })}
            onSubmit={this.onClientContactModalSubmit}
            contact={selectedContact}
            parent={client || parent}
          />
        )}
      </Card>
    );
  }
}

export default withSubscriptions({
  Component: ContactList,
  subscriptions: ["organisationDetails"],
});
