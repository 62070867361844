import React, { useState, useEffect } from "react";

import { Tabs, Typography } from "antd";
import ThirdPartyAppList from "./ThirdPartyAppList/ThirdPartyAppList";
import XeroTab from "./XeroTab/XeroTab";
import QuickbooksTab from "ThirdPartyAppsPage/QuickbooksTab/QuickbooksTab";

import { withRouter } from "react-router-dom";
import withSubscriptions from "common/withSubscriptions";

type Integration = {
  id: string;
  authorisedAt: string;
};

export function ThirdPartyAppsPage(props: any) {
  const [activeTab, setActiveTab] = useState("Apps");
  const { organisationDetails, history } = props;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      setActiveTab(tab);
    }
  }, []);

  function isIntegrationEnabled(integrationId: string) {
    return organisationDetails.integrations?.find((integration: Integration) => integration?.id === integrationId);
  }

  return (
    <div className="third-party-apps-page">
      <Tabs
        className="tabs"
        activeKey={activeTab}
        onTabClick={(tabKey) => {
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.set("tab", tabKey);

          history.push(`apps?${urlParams.toString()}`);
          setActiveTab(tabKey);
        }}
      >
        <Tabs.TabPane tab={<Typography.Text data-cy="apps-tab">App list</Typography.Text>} key="Apps">
          <ThirdPartyAppList />
        </Tabs.TabPane>

        {isIntegrationEnabled("XERO") && (
          <Tabs.TabPane tab={<Typography.Text data-cy="xero-tab">Xero</Typography.Text>} key="Xero">
            <XeroTab />
          </Tabs.TabPane>
        )}

        {isIntegrationEnabled("QUICKBOOKS") && (
          <Tabs.TabPane tab={<Typography.Text data-cy="quickbooks-tab">Quickbooks</Typography.Text>} key="Quickbooks">
            <QuickbooksTab />
          </Tabs.TabPane>
        )}

        {isIntegrationEnabled("GOOGLE_DRIVE") && (
          <Tabs.TabPane
            tab={<Typography.Text data-cy="google-drive-tab">Google Drive</Typography.Text>}
            key="GoogleDrive"
          >
            <div>Google Drive page</div>
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: ThirdPartyAppsPage,
    subscriptions: ["organisationDetails"],
  })
);
