import { useState } from "react";
import { Link } from "react-router-dom";
import Card from "../../Card/Card";
import { Typography, Tag } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { QUOTE_STATUSES } from "common/constants";
import CreateQuoteModal from "Modals/CreateQuoteModal/CreateQuoteModal";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";

import Avatar from "Avatar/Avatar";

import "./ProjectQuotes.scss";
import { getLabel } from "common/helpers";

export default function ProjectQuotes({ quotes, project, users, apiUser, organisationDetails }) {
  const quotesInProject = quotes.filter((quote) => quote.projectId === project.id && !quote.isArchived);
  const [isCreateQuoteModalVisible, setIsCreateQuoteModalVisible] = useState(false);

  return (
    <>
      <Card
        title={getLabel({ id: "Quotes", defaultValue: "Quotes" })}
        className="project-quotes-card"
        actions={
          <ButtonWithPermissions
            type="primary"
            permissions={["CREATE_QUOTE"]}
            className="create-quote"
            onClick={() => setIsCreateQuoteModalVisible(true)}
            data-cy="create-quote-button"
          >
            <PlusCircleOutlined /> Create {getLabel({ id: "quote", defaultValue: "quote" })}
          </ButtonWithPermissions>
        }
      >
        <div className="items">
          {quotesInProject.map((quote) => {
            let assignedTo = users.find((user) => user.id === quote.assignedTo);
            let quoteStatus = QUOTE_STATUSES.find((status) => status.value === quote.status);
            let totalLabel;
            if (organisationDetails.settings?.quote?.quotesInHours) {
              let totalQuantity = quote.lineItems.items.reduce((acc, item) => acc + (item.quantity || 0), 0);
              totalLabel = `${totalQuantity} hour${totalQuantity === 1 ? "" : "s"}`;
            } else {
              totalLabel = window.formatCurrency("GBP", quote.subtotal);
            }
            return (
              <Link to={`/quotes/${quote.id}`} className="quote-item" data-cy="quote-list-item" key={quote.id}>
                <Tag className="quote-id-tag dark-tag">{quote.id}</Tag>
                <Typography.Text className="title">{quote.title}</Typography.Text>
                <Typography.Text className="total">{totalLabel}</Typography.Text>

                <Typography.Text className="status">{quoteStatus?.label}</Typography.Text>
                <Avatar user={assignedTo}> </Avatar>
              </Link>
            );
          })}
        </div>
      </Card>
      {isCreateQuoteModalVisible && (
        <CreateQuoteModal
          onClose={() => setIsCreateQuoteModalVisible(false)}
          apiUser={apiUser}
          predefinedFields={{ projectId: project.id }}
        />
      )}
    </>
  );
}
