import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { message } from "antd";
import { Link } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";

import withSubscriptions from "common/withSubscriptions";
import { INVOICE_STATUSES } from "common/constants";
import { downloadBase64, sanitiseCSVValue } from "common/helpers";
import { tableColumns } from "./tableColumns";
import { getFilteredInvoices } from "common/filterHelpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { getInvoicesThatNeedSecondReview, batchDownloadInvoices } from "common/invoiceHelpers/invoiceHelpers";
import { isAuthorised } from "common/permissions";

import Lane from "Lane/Lane";
import LaneContainer from "LaneContainer/LaneContainer";
import LazyLoadList from "../LazyLoadList/LazyLoadList";
import InvoiceFilters from "./InvoiceFilters/InvoiceFilters";
import InvoiceItem from "./InvoiceItem/InvoiceItem";

// import { createInitialResources } from "./invoicesTestHelpers";

import "./InvoicesPage.scss";

export class InvoicesPage extends React.Component {
  state = {
    filter: {},
  };

  componentDidMount() {
    this.props.setBoxedLayout(false);
    this.props.setNoScroll(true);
    this.props.setBackground(false);

    callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "INVOICES",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
    //   window.createTestResources = async () => {
    //     const organisationDetails = this.props.organisationDetails;

    //     const clientId = `${organisationDetails.id}-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    //     const project1Id = `${organisationDetails.id}-100`;
    //     const project2Id = `${organisationDetails.id}-101`;
    //     const task1Id = `${project1Id}-1`;
    //     const task2Id = `${project2Id}-2`;
    //     const task3Id = `${project2Id}-3`;
    //     const quoteId1 = `${project1Id}-Q1`;
    //     const quoteId2 = `${project2Id}-Q1`;
    //     const quoteId3 = `${project2Id}-Q2`;
    //     const quoteTemplateId = organisationDetails.templates?.items.find((template) => template.type === "QUOTE").id;
    //     const invoiceTemplateId = organisationDetails.templates?.items.find((template) => template.type === "INVOICE").id;

    //     const quoteLineItemLumpSumId = `${project1Id}-Q1-LI1`;
    //     const quoteLineItemHourlyId = `${project2Id}-Q1-LI1`;
    //     const quoteLineItemVariationId = `${project2Id}-Q1-VAR1`;

    //     await createInitialResources({
    //       window,
    //       organisationDetails,
    //       organisationId: organisationDetails.id,
    //       clientId,
    //       project1Id,
    //       project2Id,
    //       task1Id,
    //       task2Id,
    //       task3Id,
    //       quoteId1,
    //       quoteId2,
    //       quoteId3,
    //       quoteTemplateId,
    //       invoiceTemplateId,
    //       quoteLineItemLumpSumId,
    //       quoteLineItemHourlyId,
    //       quoteLineItemVariationId,
    //       skipTemplateCreation: true,
    //     });
    //   };
  }

  componentWillUnmount() {
    this.props.setBackground(true);
    this.props.setBoxedLayout(true);
    this.props.setNoScroll(false);
  }

  onDragEnd = () => {};

  displayLanes = (filteredInvoices) => {
    const { organisationDetails, invoices } = this.props;

    const viewType = "board";

    const laneElements = INVOICE_STATUSES.map((status) => {
      const items = filteredInvoices.filter((invoice) => invoice.status === status.value);
      const allItems = invoices.filter((invoice) => invoice.status === status.value);
      const totalForColumn = items.reduce((total, invoice) => total + invoice.subtotal, 0);
      let totalSuffix = "";
      if (isAuthorised(["INVOICES.VIEW_COLUMN_TOTALS"])) {
        totalSuffix = ` - ${window.formatCurrency("GBP", totalForColumn)}`;
      }

      const lane = {
        label: `${status.label}${totalSuffix}`,
        items,
        status: status.label,
        itemCount: items.length,
        allItemCount: allItems.length,
      };

      return (
        <Lane lane={lane} key={lane.label} viewType={viewType}>
          <LazyLoadList
            list={items}
            refreshOnChange={viewType}
            item={(invoice, index) => {
              return (
                <Link to={`/invoices/${invoice.id}`}>
                  <InvoiceItem invoice={invoice} key={invoice.id} organisationDetails={organisationDetails} />
                </Link>
              );
            }}
          />
        </Lane>
      );
    });

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <LaneContainer viewType={viewType}>{laneElements}</LaneContainer>
      </DragDropContext>
    );
  };

  export = async () => {
    const { projects, clients } = this.props;
    const { filter } = this.state;
    const messageKey = "exporting-invoices";
    message.loading({ content: "Generating CSV export...", duration: 0, key: messageKey });

    try {
      const columns = [...tableColumns];

      let csvContent = columns.map((column) => column.title).join(",") + "\n";
      const allInvoices = await this.fetchAllInvoices();
      let filteredInvoices = getFilteredInvoices({
        invoices: allInvoices,
        filter,
        clients,
        projects,
      }).sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));

      filteredInvoices.forEach((invoice) => {
        invoice.lineItems.items.forEach((lineItem) => {
          csvContent +=
            columns
              .map((column) => {
                if (column.fieldFunction) {
                  return sanitiseCSVValue(column.fieldFunction(lineItem, invoice));
                } else {
                  return sanitiseCSVValue(lineItem[column.dataIndex]);
                }
              })
              .join(",") + "\n";
        });
      });

      let base64CSV = `data:text/csv;base64,${btoa(unescape(encodeURIComponent(csvContent)))}`;
      await downloadBase64({
        base64String: base64CSV,
        fileName: `invoices ${moment().format("DD-MM-YYYY")}.csv`,
      });
      message.success({ content: "CSV export generated successfully", duration: 5, key: messageKey });
    } catch (e) {
      message.error({ content: "Failed to generate CSV export", duration: 5, key: messageKey });
    }
  };

  fetchAllInvoices = async () => {
    const { apiUser } = this.props;
    let result = [];
    let nextTokenToSend = undefined;
    while (true) {
      const response = (
        await callGraphQLSimple({
          message: "Failed to fetch invoices",
          queryCustom: "listInvoicesByOrganisationWithLineItems",
          variables: {
            organisation: apiUser.organisation,
            nextToken: nextTokenToSend,
          },
        })
      ).data.listInvoicesByOrganisation;

      result = [...result, ...response.items];
      if (response.nextToken) {
        nextTokenToSend = response.nextToken;
      } else {
        break;
      }
    }
    return result;
  };

  startBatchReview = () => {
    const { invoices, clients, projects, history } = this.props;

    let filteredInvoices = getInvoicesThatNeedSecondReview({
      invoices,
      clients,
      projects,
    });
    history.push(`/invoices/${filteredInvoices[0].id}/final-review`);
  };

  render() {
    const { invoices, clients, projects, organisationDetails, windowWidth, windowHeight } = this.props;
    const { filter } = this.state;

    let filteredInvoices = getFilteredInvoices({
      invoices,
      filter,
      clients,
      projects,
    });

    return (
      <div className="invoices-page">
        <InvoiceFilters
          onChange={(filter) => this.setState({ filter })}
          includeCreateInvoice
          export={this.export}
          batchDownload={() => batchDownloadInvoices({ clients, projects, filteredInvoices, organisationDetails })}
          startBatchReview={this.startBatchReview}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
        />

        {this.displayLanes(filteredInvoices)}
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: InvoicesPage,
    subscriptions: ["tasks", "projects", "clients", "invoices"],
  })
);
