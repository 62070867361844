import React from "react";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";
import { Storage } from "aws-amplify";
import cx from "classnames";
import query from "query-string";
import _ from "lodash";
import axios from "axios";
import { Alert, Button, Typography, Breadcrumb, Modal, notification, Dropdown, Menu, Switch, message } from "antd";
import prettyBytes from "pretty-bytes";
import cookie from "js-cookie";
import { FilePdfOutlined, LoadingOutlined, DeleteOutlined, CheckCircleFilled } from "@ant-design/icons";

import { fetchActivityItemsForRequest } from "common/shared";
import getS3File from "common/getS3File";
import { getDetailsForFormAndTaskRevision } from "common/sharedRequestHelpers";
import withSubscriptions from "common/withSubscriptions";
import {
  displayModalForNestedField,
  displayInsertAttachmentModal,
  displayInsertAttachmentPickerModal,
  displayReportUserListModal,
  displayModalContainingFields,
  displayFields,
  downloadReport,
  computeHiddenFormFields,
} from "./Report/reportHelpers";
import {
  getLatestFileVersion,
  getFilenameFromKey,
  KEY_TYPES,
  encodeKey,
  getTemplateFromOrganisation,
} from "common/shared";
import { getCat2Check, getDesignTaskForCat2Check, downloadSheetPDF } from "common/helpers";
import { callRest, callGraphQLSimple } from "common/apiHelpers";
import { replaceDynamicFormFields } from "common/sharedTemplateRenderHelpers";
import { updateDeletedFiles } from "common/taskHelpers";
import { changeFileNameAtDownloadTime } from "common/naming";
import * as spreadsheetHelpers from "./spreadsheetHelpers";
import { VERSION_VISIBLE_COUNT, FILE_TYPES_READABLE, DEFAULT_FILE_NAMES } from "common/constants";
import { getSimpleLabel } from "common/labels";

// import RawCloudFilesModal from "Modals/RawCloudFilesModal/RawCloudFilesModal";

import InfoItem from "InfoItem/InfoItem";
import Card from "Card/Card";
import Report from "./Report/Report";
import VersionsModal from "Modals/VersionsModal/VersionsModal";
import TaskIdTag from "TaskIdTag/TaskIdTag";
import DocumentDetailsModal from "Modals/DocumentDetailsModal/DocumentDetailsModal";
import ReportAdvancedActions from "./ReportAdvancedActions/ReportAdvancedActions";

import "./ReportPage.scss";
import "./reportHelpers.scss";

export class ReportPage extends React.Component {
  _isMounted = false;
  isAwaitingForReportPublish = false;
  publishReportModal = null;

  state = {
    userHasChangedSomething: false, // this is used to prevent the report saving straight after page load
    selectedField: null,
    areFieldsLoaded: false,
    dataUri: null,
    form: null,
    formPreview: null,
    fieldUnderEditName: null,
    fieldUnderEditSelectionStart: null,
    subFieldUnderEditIndex: null,
    fieldUnderEditValue: "",
    isInsertAttachmentsModalOpen: false,
    isAttachmentPickerOpen: false,
    isReportUserListModalOpen: false,
    savedAt: moment(),
    entirePdfName: null,
    isSaving: false,
    isSaveError: false,
    userIsCat2Checker: false,
    entireReportPdfVersions: null,
    isVersionsModalVisible: false,
    isSpecificPagesModalVisible: false,
    isPreviewOn: true,
    calculationsFile: null,
    reportReferenceCallback: null,
    taskRevision: null,
    isDownloading: false,
    isModalWithFieldsVisible: false,
    isDownloadButtonDisabled: false,
    isRawCloudFilesModalVisible: false,
    modalWithFieldsName: null,
    isHardcodedTemplate: false, // if the template is hardcoded, it means it wasn't created with the template editor
    templateDetails: null,
    numberForPreviewRefresh: 0,
    formIsVisible: false,
    hiddenFormFields: {},
    pdfPreviewData: undefined,
    requestForm: undefined,
    request: undefined,
    initialRequestForm: undefined,
    initialRequest: undefined,
  };

  constructor() {
    super();

    this.debouncedInnerSaveUserFields = _.debounce(this.saveUserFields, 1000);

    this.debouncedSaveUserFields = async () => {
      await computeHiddenFormFields.call(this);
      await this.debouncedInnerSaveUserFields();
    };
  }

  componentDidMount() {
    this.initReport();

    if (this.props.readOnly) {
      this.isReviewed = true;
    }

    this.revealFormContainer();
  }

  revealFormContainer = () => {
    setTimeout(() => {
      let formContainer = document.querySelector(".report-user-fields-container");
      if (!formContainer) {
        setTimeout(this.revealFormContainer, 1000);
        return;
      }

      formContainer.scrollTop = 0;

      this.setState({ formIsVisible: true });
    }, 200);
  };

  componentWillUnmount() {
    this._isMounted = false;
    if (this.props.setBoxedLayout) {
      this.props.setBoxedLayout(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.validationErrors) {
      let currentFieldNamesWithValidationErrors = this.props.validationErrors.map(
        (validationError) => validationError.fieldKey
      );
      let previousFieldNamesWithValidationErrors = prevProps.validationErrors.map(
        (validationError) => validationError.fieldKey
      );

      if (
        JSON.stringify(currentFieldNamesWithValidationErrors) !== JSON.stringify(previousFieldNamesWithValidationErrors)
      ) {
        setTimeout(() => {
          let targetElement = document.querySelector(
            `.field-label-inner[data-field-name="${currentFieldNamesWithValidationErrors[0]}"]`
          );

          if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
          }
        }, 300);
      }
    }
  }

  initReport = async () => {
    if (this.props.showPreloader) {
      this.props.showPreloader();
    }
    this._isMounted = true;
    if (this.props.setBoxedLayout) {
      this.props.setBoxedLayout(false);
    }

    const { task, tasks, apiUser, file, withoutTask, history } = this.props;

    const templateDetails = getTemplateFromOrganisation({
      organisationDetails: this.props.organisationDetails,
      templateId: this.props.file.templateId,
      fileType: "REPORT",
    });

    if (!templateDetails.key) {
      history?.replace && history.replace(window.location.pathname.replace("/REPORT/", "/REPORT-OLD/"));
      return;
    }

    const entirePdfName = withoutTask ? "" : await this.getEntirePdfName();

    let rootAttachmentsFolder;

    if (this.props.request) {
      rootAttachmentsFolder = await encodeKey({
        type: KEY_TYPES.REQUEST_FOLDER,
        data: {
          organisation: this.props.request.organisation,
          requestId: this.props.request.id,
        },
      });
    }

    const projectFolder = withoutTask
      ? rootAttachmentsFolder
      : await encodeKey({
          type: KEY_TYPES.PROJECT_FOLDER,
          data: {
            organisation: task.organisation,
            projectId: task.projectId,
          },
        });

    this.setState({
      projectFolder,
      entirePdfName,
      isHardcodedTemplate: !templateDetails?.key,
      templateDetails,
    });

    if (withoutTask) {
      await this.loadRequestAndRequestForm({ task: null, request: this.props.request, taskRevisionId: null });
      const form = await this.loadFormFile();

      this.setState(
        {
          form,
          formPreview: form,
        },
        async () => {
          await computeHiddenFormFields.call(this);
          this.props.hidePreloader();
        }
      );
      setTimeout(() => {
        const requestContent = document.querySelector(".request-content");
        if (requestContent) {
          requestContent.scrollTop = 0;
        }
      }, 100);
      return;
    }

    if (withoutTask) {
      await this.loadFormFile();
      await computeHiddenFormFields.call(this);
      this.props.hidePreloader();
    } else {
      const cat2Check = getCat2Check({ task, linkedTasksDetails: tasks });
      if (cat2Check) {
        this.setState({ userIsCat2Checker: cat2Check.assignedTo === apiUser.id });
      }
      const taskRevision = await this.loadTaskRevision();
      let firstTaskRevision;
      if (task && task.revisions && task.revisions.items && task.revisions.items.length > 0) {
        firstTaskRevision = task.revisions.items[0];
      }

      await Promise.all([
        this.loadRequestAndRequestForm({
          task: this.props.task,
          request: this.props.request,
          taskRevisionId: taskRevision.id,
        }),
        this.loadRequestAndRequestForm({
          task: this.props.task,
          taskRevisionId: firstTaskRevision.id,
          isForInitialRequest: true,
        }),
      ]);

      await Promise.all([this.loadFormFiles(taskRevision)]);

      await computeHiddenFormFields.call(this);
      this.props.hidePreloader();

      setTimeout(() => {
        const requestContent = document.querySelector(".request-content");
        if (requestContent) {
          requestContent.scrollTop = 0;
        }
      }, 100);

      const isPreviewDisabled = cookie.get("is-report-preview-disabled");
      if (isPreviewDisabled === "true" || templateDetails.outputType?.includes("SPREADSHEET")) {
        this.setState({ isPreviewOn: false });
      }

      callGraphQLSimple({
        displayError: false,
        mutation: "createAuditItem",
        variables: {
          input: {
            taskId: task.id,
            projectId: task.projectId,
            fileId: file.id,
            clientId: task.clientId,
            page: "REPORT",
            type: "PAGE_VIEW",
            userId: window.apiUser.id,
            organisation: window.apiUser.organisation,
          },
        },
      });
    }
  };

  loadRequestAndRequestForm = async ({ task, request, taskRevisionId, isForInitialRequest }) => {
    if (isForInitialRequest) {
      // debugger;
    }
    if (!request && (!task || !task.requestIds || !taskRevisionId)) {
      return;
    }

    return new Promise(async (resolve, reject) => {
      try {
        if (request) {
          if (isForInitialRequest) {
            this.setState({ initialRequest: request });
          } else {
            this.setState({ request });
          }
          resolve();
          return;
        }
        let activityItemsByRequest = {};

        const activityDetailsForRequests = await Promise.all(task.requestIds.map(fetchActivityItemsForRequest));
        for (let activityDetailsForRequest of activityDetailsForRequests) {
          activityItemsByRequest[activityDetailsForRequest.requestId] = activityDetailsForRequest.activityItems;
        }
        activityItemsByRequest = activityItemsByRequest;

        let requestFormActivityItem = getDetailsForFormAndTaskRevision({
          activityItemsByRequest: activityItemsByRequest,
          taskRevisionId,
        });

        if (!requestFormActivityItem) {
          resolve(undefined);
          return;
        }

        let requestFormFileDetails = (
          await callGraphQLSimple({
            queryCustom: "getFile",
            variables: {
              id: requestFormActivityItem.content.formFileId,
            },
          })
        ).data.getFile;

        request = (
          await callGraphQLSimple({
            query: "getRequest",
            variables: {
              id: requestFormActivityItem.parentId,
            },
          })
        ).data.getRequest;

        if (!requestFormFileDetails) {
          resolve(undefined);
        }

        const requestFormPublicFileUrl = await getS3File(requestFormFileDetails.versions.items.slice(-1)[0].key);
        const requestForm = (await axios.get(requestFormPublicFileUrl)).data;
        if (isForInitialRequest) {
          this.setState({ initialRequestForm: requestForm, initialRequest: request }, () => {
            resolve();
          });
        } else {
          this.setState({ requestForm, request }, () => {
            resolve();
          });
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  loadFormFiles = async (taskRevision) => {
    const { task, tasks } = this.props;

    const designTaskForCat2Check = getDesignTaskForCat2Check({
      task,
      linkedTasksDetails: tasks,
    });
    const cat2CheckTask = getCat2Check({ task, linkedTasksDetails: tasks });

    let linkedTaskDetails;

    if (designTaskForCat2Check) {
      linkedTaskDetails = (
        await callGraphQLSimple({
          queryCustom: "getTaskWithFilesWithoutSheets",
          message: `Failed to retrieve design ${getSimpleLabel("task")} for Cat 2 check`,
          variables: {
            id: designTaskForCat2Check.id,
          },
        })
      ).data.getTask;
    } else if (cat2CheckTask) {
      linkedTaskDetails = (
        await callGraphQLSimple({
          queryCustom: "getTaskWithFilesWithoutSheets",
          message: `Failed to retrieve Cat 2 check ${getSimpleLabel("task")}`,
          variables: {
            id: cat2CheckTask.id,
          },
        })
      ).data.getTask;
    }
    let filePromises = [];
    const mainForm = await this.loadFormFile({ taskRevision });

    let extraFormFileIdsList = [];

    for (let fieldName of Object.keys(mainForm.fields)) {
      let fieldDetails = mainForm.fields[fieldName];
      if (fieldDetails.useDynamicOptions && fieldDetails.optionsRepresentFormFiles) {
        let promisesForFormFiles = [];
        for (let option of fieldDetails.options) {
          if (option.value) {
            promisesForFormFiles.push(
              callGraphQLSimple({
                message: "Cannot fetch extra form file",
                queryCustom: "getFile",
                variables: {
                  id: option.value,
                },
              })
            );
          }
        }
        let formFileResponses = await Promise.all(promisesForFormFiles);
        let formFiles = formFileResponses.map((x) => x.data.getFile);
        let taskRevisionPromises = [];

        for (let formFile of formFiles) {
          taskRevisionPromises.push(
            callGraphQLSimple({
              message: `Cannot fetch ${getSimpleLabel("task revision")}`,
              queryCustom: "getTaskRevision",
              variables: {
                id: formFile.taskRevisionId,
              },
            })
          );
        }

        let taskRevisionResponses = await Promise.all(taskRevisionPromises);
        let taskRevisions = taskRevisionResponses.map((x) => x.data.getTaskRevision);

        for (let i = 0; i < formFiles.length; i++) {
          let formFile = formFiles[i];
          let taskRevision = taskRevisions[i];
          extraFormFileIdsList.push(formFile.id);
          filePromises.push(
            this.loadFormFile({
              file: formFile,
              task,
              taskRevision: taskRevision,
            })
          );
        }
      }
    }

    if (linkedTaskDetails) {
      const latestTaskRevisionForLinkedTask = linkedTaskDetails.revisions.items.slice(-1)[0];
      let linkedTaskReports = latestTaskRevisionForLinkedTask.files.items.filter((x) => x.type === "REPORT");

      for (let reportInLinkedTask of linkedTaskReports) {
        extraFormFileIdsList.push(reportInLinkedTask.id);
        filePromises.push(
          this.loadFormFile({
            file: reportInLinkedTask,
            task: linkedTaskDetails,
            taskRevision: latestTaskRevisionForLinkedTask,
          })
        );
      }
    }

    const extraFormsList = await Promise.all(filePromises);

    let extraFormsById = {};

    if (extraFormFileIdsList && extraFormFileIdsList.length > 0) {
      for (let i = 0; i < extraFormFileIdsList.length; i++) {
        let extraFormFileId = extraFormFileIdsList[i];
        extraFormsById[extraFormFileId] = extraFormsList[i];
      }
    }

    this.setState(
      {
        form: mainForm,
        formPreview: mainForm,
        extraForms: extraFormsById,
      },
      async () => {
        await computeHiddenFormFields.call(this);
      }
    );
  };

  setEntirePdfName = async () => {
    const { file, withoutTask } = this.props;
    const { calculationsFile, reportReferenceCallback } = this.state;

    if (withoutTask) {
      return;
    }

    if (calculationsFile && !reportReferenceCallback) {
      setTimeout(this.setEntirePdfName, 200);
      return;
    }

    const entirePdfName = await this.getEntirePdfName();
    const latestFileVersion = JSON.parse(JSON.stringify(file.versions.items.slice(-1)[0]));
    if (!latestFileVersion.exports[0].fileName || latestFileVersion.exports[0].fileName !== entirePdfName) {
      latestFileVersion.exports[0].fileName = entirePdfName;
      await callGraphQLSimple({
        message: "Could not update file version",
        queryName: "updateFileVersion",
        variables: {
          input: {
            id: latestFileVersion.id,
            exports: latestFileVersion.exports,
          },
        },
      });
    }
    this.setState({ entirePdfName });
  };

  getEntirePdfName = async () => {
    const { task, file } = this.props;
    const { calculationsFile, reportReferenceCallback, form } = this.state;
    const taskRevision = task.revisions.items.find((x) => x.id === file.taskRevisionId);
    const latestFileVersion = file.versions.items.slice(-1)[0];

    if (form?.fields?.customTaskId && form.fields.customTaskId.value) {
      return form.fields.customTaskId.value;
    }

    if (calculationsFile) {
      let reference = reportReferenceCallback({
        calculationsFile,
        task,
        reportJsonData: form,
      });
      return reference;
    }

    let firstSheet = file.sheets.items[0];
    let latestSheetRevision = firstSheet.revisions.items.slice(-1)[0];

    let changedFileName = await changeFileNameAtDownloadTime({
      organisation: file.organisation,
      fileName: getFilenameFromKey(latestFileVersion.exports[0].key),
      sheetRevisionName: taskRevision.name,
      sheetRevision: latestSheetRevision,
      file,
      type: KEY_TYPES.FILE_MAIN_EXPORT,
    });

    return changedFileName;
  };

  loadEntirePdfS3Versions = async () => {
    const { file } = this.props;
    let versions = [];
    for (let i = 0; i < file.versions.items.length; i++) {
      const fileVersion = file.versions.items[i];
      const key = fileVersion.exports[0].key;
      const versionsResponse = await callRest({
        method: "GET",
        route: `/s3-list-versions?prefix=${btoa(key)}`,
        includeCredentials: false,
      });
      versions.push(...versionsResponse.Versions.sort((a, b) => (a.LastModified < b.LastModified ? 1 : -1)));
    }
    this.setState({ entireReportPdfVersions: versions });
  };

  getFormFileKey = (file) => {
    return getLatestFileVersion(file).key.replace("public/", "");
  };

  loadFormFile = async (params) => {
    const { request, requestForm, initialRequest, initialRequestForm } = this.state;
    const { taskRevision } = params || {};
    let { file, task, users, organisationDetails } = this.props;

    if (params?.file) {
      file = params.file;
    }

    if (params?.task) {
      task = params.task;
    }
    const formFileKey = await this.getFormFileKey(file);
    const formFilePublicUrl = await getS3File(formFileKey);
    let form = (await axios.get(formFilePublicUrl)).data;

    await replaceDynamicFormFields({
      form,
      params: {
        request,
        requestForm,
        initialRequest,
        initialRequestForm,
        file,
        task,
        taskRevision,
        project: task?.project,
        users,
        organisationDetails,
      },
    });

    return form;
  };

  recalculateDynamicFormFields = async () => {
    const { form, taskRevision, request } = this.state;
    const { file, task, users, organisationDetails } = this.props;

    await replaceDynamicFormFields({
      form,
      params: {
        request,
        file,
        task,
        taskRevision,
        project: task?.project,
        users,
        organisationDetails,
      },
    });

    this.setState({ numberForPreviewRefresh: Math.floor(Math.random() * 100000) });
  };

  loadTaskRevision = async () => {
    const { file } = this.props;
    const taskRevision = (
      await callGraphQLSimple({
        message: `Failed to retrieve ${getSimpleLabel("task revision")}`,
        queryName: "getTaskRevision",
        variables: {
          id: file.taskRevisionId,
        },
      })
    ).data.getTaskRevision;

    this.setState({ taskRevision });
    return taskRevision;
  };

  saveUserFields = async () => {
    const { form } = this.state;
    const { file } = this.props;

    const formFileKey = await this.getFormFileKey(this.props.file);
    const fileVersion = getLatestFileVersion(file);
    this.setState({
      isSaving: true,
      isSaveError: false,
      userHasChangedSomething: true,
    });
    try {
      await Storage.put(formFileKey, JSON.stringify(form));
      await callGraphQLSimple({
        message: "Failed to update report savedAt date",
        queryName: "updateFileVersion",
        variables: {
          input: {
            id: fileVersion.id,
            savedAt: new Date().toISOString(),
          },
        },
      });
      this.setState({
        formPreview: form,
        savedAt: moment(),
        isSaving: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Failed to save report:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
      this.setState({ isSaving: false, isSaveError: true });
    }
  };

  downloadPDF = ({ versionId, readableDate, fileName, sheetRevisionName }) => {
    const { file, task } = this.props;
    const { form } = this.state;

    downloadSheetPDF({
      sheetRevision: file.sheets.items[0].revisions.items.slice(-1)[0],
      sheetRevisionName,
      file,
      task,
      customReference: form?.fields?.customTaskId?.value,
      versionId,
      readableDate,
      fileName,
    });
  };

  displayEntireReportPdfVersions = () => {
    if (!this.state.entireReportPdfVersions) {
      return (
        <Menu>
          <Menu.Item key="loading" className="file-version-item" disabled>
            <LoadingOutlined /> Loading...
          </Menu.Item>
        </Menu>
      );
    }

    let truncatedVersions = this.state.entireReportPdfVersions.slice(0, VERSION_VISIBLE_COUNT);
    let listIsTruncated = false;
    if (truncatedVersions.length < this.state.entireReportPdfVersions.length) {
      listIsTruncated = true;
    }

    if (truncatedVersions?.length === 0) {
      return (
        <Menu>
          <Menu.Item key="no-items" className="file-version-item" disabled>
            No versions found
          </Menu.Item>
        </Menu>
      );
    }

    return (
      <Menu>
        {truncatedVersions.map((version) => {
          let readableDate = moment(version.LastModified).format("DD MMMM YYYY, HH:mm:ss");
          return (
            <Menu.Item
              key={version.VersionId}
              className="file-version-item"
              onClick={() => this.downloadPDF({ versionId: version.VersionId, readableDate })}
            >
              <span className="date">{readableDate}</span> <span className="size">{prettyBytes(version.Size)}</span>
            </Menu.Item>
          );
        })}
        {listIsTruncated && (
          <Menu.Item
            key="all-versions"
            className="reveal-all-versions"
            onClick={() => this.setState({ isVersionsModalVisible: true })}
          >
            See all versions
          </Menu.Item>
        )}
      </Menu>
    );
  };

  onVersionClick = (version, readableDate) => {
    this.downloadPDF({ versionId: version.VersionId, readableDate });
  };

  confirmDeleteFile = async () => {
    const { taskRevision } = this.state;
    const { file, task, history, organisationDetails } = this.props;

    const taskPath = `/tasks/${task.id}`;

    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm delete file",
          maskClosable: true,
          content: (
            <>
              Are you sure you want to delete <b>{FILE_TYPES_READABLE[file.type]}</b>?
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    await callGraphQLSimple({
      message: "Could not delete file",
      queryName: "deleteFile",
      variables: {
        input: {
          id: file.id,
        },
      },
    });

    await callGraphQLSimple({
      mutation: "createTaskActivityItem",
      message: "Failed to record task activity item",
      variables: {
        input: {
          taskId: task.id,
          author: window.apiUser.id,
          organisation: organisationDetails.id,
          type: "LIFECYCLE_EVENT",
          content: JSON.stringify({
            fileId: file.id,
            fileType: file.type,
            fileName: file.name,
            type: "FILE_DELETED",
          }),
        },
      },
    });

    await updateDeletedFiles(taskRevision, file.type);

    await callGraphQLSimple({
      message: `Could not update ${getSimpleLabel("task")}`,
      queryName: "updateTask",
      variables: {
        input: {
          id: task.id,
          randomNumber: Math.floor(Math.random() * 100000),
        },
      },
    });

    history.push(taskPath);
  };

  checkAttachmentsStatus = async (attachments) => {
    const getAttachmentsStatusResponse = await callRest({
      route: "/check-attachments-status",
      method: "POST",
      body: {
        attachments,
      },
    });

    const keys = getAttachmentsStatusResponse.keys;

    if (getAttachmentsStatusResponse.code === "ENCRYPTED_ATTACHMENTS") {
      notification.error({
        message: (
          <Typography.Text>
            Failed to publish report.
            <br />
            <div className="encrypted-file-names-label">
              <b>The following attachments are encrypted: </b>
            </div>
            <div className="encrypted-file-names-container">
              {keys.map((fileName) => (
                <Typography.Text>{fileName}</Typography.Text>
              ))}
            </div>
          </Typography.Text>
        ),
        duration: 0,
      });
    }

    if (getAttachmentsStatusResponse.code === "DELETED_ATTACHMENTS") {
      Modal.warning({
        title: "The report contains deleted attachments, cannot proceed to download.",
        className: "report-warning-deleted-attachments-container-modal",
        content: (
          <div className="report-contains-deleted-attachments-modal">
            <div>Please remove the following attachments before retrying:</div>
            <div className="deleted-attachments-container">
              {keys.map((attachment) => (
                <div>
                  <Typography.Text>{attachment.split(`public/${this.props.organisationDetails.id}/`)}</Typography.Text>
                </div>
              ))}
            </div>
          </div>
        ),
      });
    }

    return getAttachmentsStatusResponse.code;
  };

  downloadSpreadsheet = async () => {
    const { templateDetails, formPreview, taskRevision } = this.state;
    const {
      apiUser,
      file,
      task,
      tasks,
      clients,
      users,
      organisationDetails,
      projects,
      quotes,
      invoices,
      purchaseOrders,
    } = this.props;
    const clientDetails = clients.find((x) => x.id === task.clientId);
    try {
      await spreadsheetHelpers.buildAndDownloadSpreadsheets({
        templateDetails,
        organisationDetails,
        form: formPreview,
        apiUser: apiUser,
        file: file,
        task: task,
        clientContact: clientDetails?.contacts?.find((x) => x.id === task.clientContact),
        clientAddress: clientDetails?.addresses?.find((x) => x.id === task.clientAddress),
        client: clients.find((x) => x.id === task.clientId),
        taskRevision,
        project: task.project,
        clients,
        tasks,
        projects,
        quotes,
        invoices,
        purchaseOrders,
        users,
      });
      this.setState({ isDownloading: false });
    } catch (e) {
      message.error(`Failed to download spreadsheet: ${e.message}`);
      this.setState({ isDownloading: false });
      // throw e;
    }
  };

  onDownloadEntireReportClick = async () => {
    const { templateDetails } = this.state;
    const { file, task, users, organisationDetails } = this.props;

    this.setState({ isDownloading: true });

    if (templateDetails.outputType?.includes("SPREADSHEET")) {
      this.downloadSpreadsheet();
      return;
    }

    await downloadReport({
      fileId: file.id,
      taskId: task.id,
      users,
      organisationDetails,
    });

    this.setState({ isDownloading: false });
  };

  onOpenEntireReportClick = async () => {
    const { templateDetails } = this.state;
    const { file, task, users, organisationDetails } = this.props;

    this.setState({ isDownloading: true });

    await downloadReport({
      fileId: file.id,
      taskId: task.id,
      users,
      organisationDetails,
    });

    this.setState({ isDownloading: false });

    this.downloadAndSetEntirePdfPreview(file.versions.items.slice(-1)[0].exports[0].key);
  };

  downloadAndSetEntirePdfPreview = async (fileKey) => {
    const pdfResponse = await Storage.get(fileKey.replace("public/", ""), {
      download: true,
    });
    const pdfData = await new Response(pdfResponse.Body).arrayBuffer();
    this.setState({ pdfPreviewData: pdfData });
  };

  displayBreadcrumbs = () => {
    const { organisationDetails, task, location } = this.props;

    const queryString = query.parse(location.search);
    const parentName = queryString.parentName;
    const parentPath = queryString.parentPath;

    let firstBreadcrumbContent = <Link to={`/projects/${task.projectId}`}>{task.project?.title}</Link>;
    if (parentName && parentPath) {
      firstBreadcrumbContent = <Link to={parentPath}>{parentName}</Link>;
    }

    return (
      <Breadcrumb>
        {organisationDetails?.settings?.task?.automaticallyCreateProject ? null : (
          <Breadcrumb.Item>{firstBreadcrumbContent}</Breadcrumb.Item>
        )}

        {!task.isHidden ? (
          <Breadcrumb.Item className="task-id-tag">
            <TaskIdTag task={task} />
          </Breadcrumb.Item>
        ) : null}
      </Breadcrumb>
    );
  };

  displayAlerts = () => {
    const { taskRevision } = this.state;
    const { task, tasks } = this.props;
    const designTaskForCat2Check = getDesignTaskForCat2Check({
      task,
      linkedTasksDetails: tasks,
    });
    const cat2CheckTask = getCat2Check({ task, linkedTasksDetails: tasks });

    return (
      <>
        {taskRevision.isReadOnly && (
          <Alert
            showIcon
            message={`This file belongs to a read-only ${getSimpleLabel(
              "task revision"
            )}. You cannot make changes to it.`}
            type="info"
          />
        )}
        {designTaskForCat2Check && (
          <Alert
            showIcon
            message={`This report is part of a Cat 2 check for: ${designTaskForCat2Check.title} (${designTaskForCat2Check.id})`}
            type="info"
          />
        )}
        {cat2CheckTask && (
          <Alert
            showIcon
            message={`This ${getSimpleLabel(
              "task"
            )} has a Cat 2 check. You are currently viewing the designer's report.`}
            type="info"
          />
        )}
      </>
    );
  };

  displayDownloadEntireReportButton = () => {
    const { templateDetails } = this.state;

    if (!templateDetails.outputType?.includes("SPREADSHEET") && this.state.isDownloadButtonDisabled) {
      return null;
    }

    let buttonContent = null;

    if (this.state.isDownloading) {
      buttonContent = (
        <span data-cy="download-report-button-downloading">
          <LoadingOutlined /> <span>Downloading...</span>
        </span>
      );
    } else {
      buttonContent = (
        <span data-cy="download-report-button">
          <FilePdfOutlined /> <span>Download</span>
        </span>
      );
    }

    return (
      <span key="entire-report" style={{ display: "flex", gap: "0.5rem" }}>
        {!templateDetails.outputType?.includes("SPREADSHEET") && (
          <Button disabled={this.state.isDownloading} onClick={this.onOpenEntireReportClick}>
            <FilePdfOutlined /> Open PDF
          </Button>
        )}
        <Dropdown.Button
          type="primary"
          disabled={this.state.isDownloading}
          overlayStyle={{ minWidth: "300px" }}
          overlay={this.displayEntireReportPdfVersions()}
          trigger={["click"]}
          placement="bottomLeft"
          onClick={this.onDownloadEntireReportClick}
          onOpenChange={(open) => {
            if (open) {
              setTimeout(() => {
                this.loadEntirePdfS3Versions();
              }, 300);
            }
          }}
        >
          {buttonContent}
        </Dropdown.Button>
      </span>
    );
  };

  displayReportActions = ({ shouldDisplayFields }) => {
    const { isPreviewOn, taskRevision, templateDetails } = this.state;

    const { task, file } = this.props;

    // let rawCloudFilesButton = (
    //   <Button
    //     onClick={() => this.setState({ isRawCloudFilesModalVisible: true })}
    //     className="cloud-file-history-button"
    //   >
    //     <EyeOutlined />
    //     View raw cloud storage
    //   </Button>
    // );

    const previewToggle =
      shouldDisplayFields && !templateDetails.outputType?.includes("SPREADSHEET") ? (
        <div className="preview-switch-container">
          <InfoItem
            label="Preview"
            value={
              <Switch
                size="small"
                checked={isPreviewOn}
                onChange={(isPreviewOn) => {
                  this.setState({ isPreviewOn });
                  cookie.set("is-report-preview-disabled", isPreviewOn ? "false" : "true", { expires: 99999 });
                }}
                className="preview-switch"
              />
            }
          />
        </div>
      ) : null;

    let documentTitle = "";

    if (task.isHidden) {
      documentTitle = file.name;
    } else {
      documentTitle = <>{file.name || DEFAULT_FILE_NAMES[file.type]}</>;
    }

    return (
      <Card
        title={
          <div className="page-title-container">
            {!taskRevision.isReadOnly && !task.isFinished && !task.isArchived && (
              <Button icon={<DeleteOutlined />} className="delete-file" onClick={this.confirmDeleteFile} />
            )}
            <div className="page-title">
              {shouldDisplayFields ? documentTitle : null}
              {!this.state.isSaving && !this.state.isSaveError && this.state.savedAt && (
                <span className="saved-at save-message">
                  Saved
                  <CheckCircleFilled />
                </span>
              )}
              {this.state.isSaving && <span className="is-saving save-message">Saving...</span>}
              {this.state.isSaveError && (
                <span className="is-save-error save-message">
                  Save failed (last successful save was on {this.state.savedAt.format("DD MMM YYYY HH:mm:ss")})
                </span>
              )}
            </div>
          </div>
        }
        actions={[
          previewToggle,
          <ReportAdvancedActions
            templateDetails={templateDetails}
            form={this.state.form}
            formFileKey={this.getFormFileKey(this.props.file)}
            apiUser={this.props.apiUser}
            task={this.props.task}
            taskRevision={taskRevision}
            file={this.props.file}
            organisationDetails={this.props.organisationDetails}
            isHardcodedTemplate={this.state.isHardcodedTemplate}
            refreshPreview={() => {
              this.setState({ numberForPreviewRefresh: Math.floor(Math.random() * 100000) });
            }}
            users={this.props.users}
          />,

          // rawCloudFilesButton,
          this.displayDownloadEntireReportButton(),
        ]}
      >
        {/* {this.displayCustomReferenceNumberField()} */}
        {displayFields.call(this, { showHiddenByModal: false })}
      </Card>
    );
  };

  displayVersionsModal = () => {
    if (!this.state.isVersionsModalVisible) {
      return null;
    }

    return (
      <VersionsModal
        versions={this.state.entireReportPdfVersions}
        onVersionClick={this.onVersionClick}
        onClose={() => this.setState({ isVersionsModalVisible: false })}
      />
    );
  };

  // displayRawCloudFilesModal = () => {
  //   const { isRawCloudFilesModalVisible, taskRevision } = this.state;
  //   const { file, task } = this.props;

  //   if (!isRawCloudFilesModalVisible) {
  //     return null;
  //   }
  //   return (
  //     <RawCloudFilesModal
  //       onClose={() => this.setState({ isRawCloudFilesModalVisible: false })}
  //       file={file}
  //       taskRevision={taskRevision}
  //       taskPath={`/tasks/${task.id}`}
  //       project={task.project}
  //     />
  //   );
  // };

  displayPreview = () => {
    const { isPreviewOn, taskRevision, formPreview, extraForms, requestForm, request } = this.state;
    const { apiUser, file, task, clients, users, windowWidth } = this.props;
    const clientDetails = clients.find((x) => x.id === task.clientId);

    if (!isPreviewOn || windowWidth < 1030) {
      return null;
    }

    return (
      <div className="report-container">
        <Report
          fileType="REPORT"
          apiUser={apiUser}
          disableDownloadButton={() => this.setState({ isDownloadButtonDisabled: true })}
          isDownloadButtonDisabled={this.state.isDownloadButtonDisabled}
          file={file}
          task={task}
          clientContact={clientDetails?.contacts?.find((x) => x.id === task.clientContact)}
          clientAddress={clientDetails?.addresses?.find((x) => x.id === task.clientAddress)}
          client={clients.find((x) => x.id === task.clientId)}
          requestForm={requestForm}
          request={request}
          initialRequest={this.state.initialRequest}
          initialRequestForm={this.state.initialRequestForm}
          taskRevision={taskRevision}
          project={task.project}
          users={users}
          previewData={formPreview}
          projectFolder={this.state.projectFolder}
          onCalculationsFileLoad={(calculationsFile) => {
            this.setState({ calculationsFile }, this.setEntirePdfName);
          }}
          setReportReferenceCallback={(reportReferenceCallback) => this.setState({ reportReferenceCallback })}
          key={this.state.numberForPreviewRefresh}
          extraForms={extraForms}
        />
      </div>
    );
  };

  displayPdfPreviewModal = () => {
    if (!this.state.pdfPreviewData) {
      return null;
    }

    const { pdfPreviewData } = this.state;
    const { file, windowWidth } = this.props;

    return (
      <DocumentDetailsModal
        attachment={{
          name: file.name,
          type: "PDF",
          lastModified: moment().toISOString(),
          key: file.versions.items.slice(-1)[0].exports[0].key,
        }}
        document={pdfPreviewData}
        onClose={() => this.setState({ pdfPreviewData: null })}
        windowWidth={windowWidth}
        allowDownload={false}
      />
    );
  };

  displayModals = () => {
    return (
      <>
        {displayInsertAttachmentPickerModal.call(this)}
        {displayInsertAttachmentModal.call(this)}
        {displayReportUserListModal.call(this)}
        {displayModalForNestedField.call(this)}
        {displayModalContainingFields.call(this)}
        {this.displayVersionsModal()}
        {/* {this.displayRawCloudFilesModal()} */}
        {this.displayPdfPreviewModal()}
      </>
    );
  };

  render() {
    const { formPreview, form, isPreviewOn, taskRevision, formIsVisible } = this.state;
    const { withoutTask } = this.props;

    if (!form || !formPreview || (!taskRevision && !withoutTask)) {
      return null;
    }

    let templateSettings = form.settings;
    let shouldDisplayFields = true;
    if (templateSettings && templateSettings.displayFields === false) {
      shouldDisplayFields = false;
    }

    return (
      <div
        className={cx("report-page", {
          "is-preview-on": isPreviewOn,
          "no-fields": !shouldDisplayFields,
        })}
      >
        <div className={cx("report-user-fields-container", { invisible: !formIsVisible })}>
          {withoutTask ? null : this.displayBreadcrumbs()}
          {withoutTask ? null : this.displayAlerts()}
          {withoutTask ? (
            <Card>{displayFields.call(this, { showHiddenByModal: false })}</Card>
          ) : (
            this.displayReportActions({ shouldDisplayFields })
          )}
        </div>

        {withoutTask ? null : this.displayPreview()}
        {this.displayModals()}
      </div>
    );
  }
}

export const ReportPageWithSubscriptions = withSubscriptions({
  Component: ReportPage,
  className: "report-page",
  subscriptions: ["task", "file", "tasks", "projects", "clients", "users", "quotes", "invoices", "purchaseOrders"],
});

export const ReportPageWithMinimalSubscriptions = withSubscriptions({
  Component: ReportPage,
  className: "report-page",
  subscriptions: ["file", "users", "organisationDetails"],
});

export default withRouter(ReportPageWithSubscriptions);
