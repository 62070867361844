import { Button, Modal, Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import moment from "moment";

import { roundToQuarter } from "common/mathHelpers";

export default function BatchInvoiceTimesheetActions({
  tableRow,
  selectedTimesheetBlockIds,
  triggerRefresh,
  deselectTimesheetBlocks,
}) {
  let rowTitle = tableRow.project?.title || tableRow.task?.title;

  let selectedTimesheetBlocks =
    selectedTimesheetBlockIds.size === 0
      ? tableRow.timesheetBlocks
      : tableRow.timesheetBlocks?.filter((timesheetBlock) => selectedTimesheetBlockIds.has(timesheetBlock.id));

  let totalDuration = selectedTimesheetBlocks?.reduce((sum, timesheetBlock) => {
    if (selectedTimesheetBlockIds.size !== 0 && !selectedTimesheetBlockIds.has(timesheetBlock.id)) {
      return sum;
    }
    let durationHours = moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true);
    return roundToQuarter(sum + durationHours);
  }, 0);

  const allTimesheetBlocksAreOnHold = selectedTimesheetBlocks?.every(
    (timesheetBlock) => timesheetBlock.invoicingStatus === "ON_HOLD"
  );

  let someBlocksAreSelected = selectedTimesheetBlockIds.size > 0;
  let messagePartAboutSelectedBlocks = someBlocksAreSelected ? "selected time blocks" : "all time blocks";

  function onFinish() {
    if (deselectTimesheetBlocks) {
      deselectTimesheetBlocks(selectedTimesheetBlocks.map((timesheetBlock) => timesheetBlock.id));
    }
    if (triggerRefresh) {
      triggerRefresh();
    }
  }

  async function confirmWriteOffTimesheetBlock() {
    await new Promise((resolve) => {
      Modal.confirm({
        title: "Confirm write off",
        content: (
          <>
            Are you sure you want to write off{" "}
            <b>
              {totalDuration} hour
              {totalDuration === 1 ? "" : "s"}
            </b>{" "}
            for <b>{rowTitle}</b>
          </>
        ),
        onOk: async () => {
          let updatePromises = [];
          for (let i = 0; i < selectedTimesheetBlocks.length; i++) {
            updatePromises.push(
              window.callGraphQLSimple({
                message: "Failed to write off timesheet block",
                mutation: "updateTimesheetBlock",
                variables: {
                  input: {
                    id: selectedTimesheetBlocks[i].id,
                    invoicingStatus: "WRITE_OFF",
                    invoiceId: "nothing",
                  },
                },
              })
            );
            await new Promise((resolveTimout) => setTimeout(resolveTimout, 50));
          }
          await Promise.all(updatePromises);

          onFinish();
          resolve();
        },
        onCancel: () => {
          resolve();
        },
      });
    });
  }

  async function confirmMarkTimesheetBlockAsInvoiced() {
    await new Promise((resolve) => {
      Modal.confirm({
        title: "Confirm action",
        content: (
          <>
            Are you sure you want to mark{" "}
            <b>
              {totalDuration} hour
              {totalDuration === 1 ? "" : "s"}
            </b>{" "}
            for <b>{rowTitle}</b> as previously invoiced ?
          </>
        ),
        onOk: async () => {
          let updatePromises = [];
          for (let i = 0; i < selectedTimesheetBlocks.length; i++) {
            updatePromises.push(
              window.callGraphQLSimple({
                message: "Failed to mark timesheet block as invoiced",
                mutation: "updateTimesheetBlock",
                variables: {
                  input: {
                    id: selectedTimesheetBlocks[i].id,
                    invoicingStatus: "INVOICED",
                    invoiceId: "nothing",
                  },
                },
              })
            );
            await new Promise((resolveTimout) => setTimeout(resolveTimout, 50));
          }
          await Promise.all(updatePromises);

          onFinish();
          resolve();
        },
        onCancel: () => {
          resolve();
        },
      });
    });
  }

  async function markTimesheetBlocksAsOnHold() {
    let updatePromises = [];
    for (let i = 0; i < selectedTimesheetBlocks.length; i++) {
      updatePromises.push(
        window.callGraphQLSimple({
          message: "Failed to put timesheet block on hold",
          mutation: "updateTimesheetBlock",
          variables: {
            input: {
              id: selectedTimesheetBlocks[i].id,
              invoicingStatus: "ON_HOLD",
              invoiceId: "nothing",
            },
          },
        })
      );
      await new Promise((resolveTimout) => setTimeout(resolveTimout, 50));
    }
    await Promise.all(updatePromises);

    onFinish();
  }

  async function markTimesheetBlocksAsOnNotHold() {
    let updatePromises = [];
    for (let i = 0; i < selectedTimesheetBlocks.length; i++) {
      updatePromises.push(
        window.callGraphQLSimple({
          message: "Failed to mark timesheet block as not on hold",
          mutation: "updateTimesheetBlock",
          variables: {
            input: {
              id: selectedTimesheetBlocks[i].id,
              invoicingStatus: null,
              invoiceId: "nothing",
            },
          },
        })
      );
      await new Promise((resolveTimout) => setTimeout(resolveTimout, 50));
    }
    await Promise.all(updatePromises);

    onFinish();
  }

  return (
    <Dropdown
      trigger="click"
      overlay={
        <Menu>
          <Menu.Item
            key="write-off"
            onClick={(e) => {
              confirmWriteOffTimesheetBlock();
            }}
          >
            Write off {messagePartAboutSelectedBlocks}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key="mark-as-invoiced"
            onClick={(e) => {
              confirmMarkTimesheetBlockAsInvoiced();
            }}
          >
            Mark {messagePartAboutSelectedBlocks} as previously invoiced
          </Menu.Item>

          <Menu.Divider />
          {allTimesheetBlocksAreOnHold ? (
            <Menu.Item
              key="put-on-hold"
              onClick={(e) => {
                markTimesheetBlocksAsOnNotHold();
              }}
            >
              Mark {messagePartAboutSelectedBlocks} as not on hold
            </Menu.Item>
          ) : (
            <Menu.Item
              key="put-on-hold"
              onClick={(e) => {
                markTimesheetBlocksAsOnHold();
              }}
            >
              Put {messagePartAboutSelectedBlocks} on hold
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Button icon={<DownOutlined />} onClick={(e) => e.stopPropagation()}>
        Actions
      </Button>
    </Dropdown>
  );
}
