import { Button } from "antd";
import { getInvoicesThatNeedSecondReview } from "common/invoiceHelpers/invoiceHelpers";
import Card from "Card/Card";

import "./InvoiceFinalReviewSummary.scss";

export default function InvoiceFinalReviewSummary({ history, invoice, invoices, clients, projects }) {
  let filteredInvoices = getInvoicesThatNeedSecondReview({ invoices, clients, projects });

  function goToNextInvoice() {
    let currentInvoiceIndex = filteredInvoices.findIndex((x) => x.id === invoice.id);
    let nextIndex = currentInvoiceIndex + 1;
    if (nextIndex > filteredInvoices.length - 1) {
      nextIndex = 0;
    }
    history.push(`/invoices/${filteredInvoices[nextIndex].id}/final-review`);
  }

  let title = `Performing batch review`;
  if (filteredInvoices.length === 1) {
    if (filteredInvoices[0]?.id === invoice.id) {
      title += ` (this is the last invoice to review)`;
    } else {
      title += ` (${filteredInvoices.length} invoice remaining)`;
    }
  } else if (filteredInvoices.length === 0) {
    title += ` (nothing to review)`;
  } else {
    title += ` (${filteredInvoices.length} invoices remaining)`;
  }
  return (
    <Card
      className="invoice-final-review-summary"
      title={title}
      withSpace
      actions={
        filteredInvoices.length === 0 ||
        (filteredInvoices.length === 1 && filteredInvoices[0]?.id === invoice.id) ? null : (
          <Button type="primary" onClick={goToNextInvoice}>
            Next invoice
          </Button>
        )
      }
    />
  );
}
