import React from "react";

import { Card as AntCard, Typography, Divider, Space } from "antd";

import cx from "classnames";

import "./Card.scss";

type Props = {
  children?: any;
  className?: string | undefined;
  title?: string | React.ReactNode | undefined;
  actions?: any;
  actionsPrefix?: any;
  attributes?: any;
  onClick?: (e: React.MouseEvent) => void;
  ref?: any;
  "data-cy"?: string;
  withSpace?: boolean;
  stickyHeader?: boolean;
  flexTitle?: boolean;
  noDivider?: boolean;
  wrapHeader?: boolean;
};

export default function Card({
  children,
  className,
  title,
  actions,
  actionsPrefix,
  attributes,
  onClick,
  ref,
  withSpace,
  stickyHeader,
  flexTitle,
  noDivider,
  wrapHeader,
  ...props
}: Props) {
  let titleElement: React.ReactNode | null = null;
  let actionsElement: React.ReactNode | null = null;
  let headerElement: React.ReactNode | null = null;
  let actionsPrefixElement: React.ReactNode | null = null;

  if (title) {
    titleElement = (
      <Typography.Title level={3} className="card-title">
        {title}
      </Typography.Title>
    );
  }

  if (actions) {
    actionsElement = <div className="card-actions">{actions}</div>;
  }
  if (actionsPrefix) {
    actionsPrefixElement = <div className="card-actions-prefix">{actionsPrefix}</div>;
  }
  if (titleElement || actionsElement) {
    headerElement = (
      <div className={cx("card-header", { "sticky-card-header": stickyHeader })} data-cy="card-header">
        <Space className="spread">
          {actionsPrefixElement}
          {titleElement || <div />}
          {actionsElement || <div />}
        </Space>
        <Divider />
      </div>
    );
  }

  let dataTitle = props["data-analytics"] || props["data-cy"];
  if (!dataTitle) {
    if (typeof title === "string" || typeof title === "number") {
      dataTitle = title;
    } else if (typeof className === "string") {
      dataTitle = className;
    }
  }

  return (
    <AntCard
      ref={ref}
      bordered={false}
      className={cx(
        "card",
        {
          interactive: onClick,
          "with-space": withSpace,
          "flex-title": flexTitle,
          "no-divider": noDivider,
          "wrap-header": wrapHeader,
        },
        className
      )}
      {...attributes}
      onClick={onClick}
      data-cy={props["data-cy"]}
      data-title={dataTitle}
    >
      {headerElement}
      {children}
    </AntCard>
  );
}
