import React from "react";

import { Button } from "antd";
import cx from "classnames";

// import DashboardVideo from "graphics/dashboard_video.mp4";
import DashboardImage from "graphics/dashboard_screenshot.png";
import QuoteImage from "graphics/quote_image.jpg";
import SecurityImage from "graphics/security_image.jpg";
import TimelineImage from "graphics/timeline_screenshot.png";
import TimelineFiltersImage from "graphics/timeline_filters_screenshot.png";
import TimelineUnplannedListImage from "graphics/timeline_unplanned_list_screenshot.png";
import PdfVersionsImage from "graphics/pdf_versions_screenshot.png";

import SheetImage from "graphics/sheets_screenshot.png";
import SheetRevisionsImage from "graphics/sheet_revisions_screenshot.png";
import CartridgeImage from "graphics/cartridge_screenshot.png";

import TaskRevisionsImage from "graphics/task_revisions_screenshot.png";
import ReviewSheetsImage from "graphics/review_sheets_screenshot.png";
import ReviewAnnotationImage from "graphics/review_annotation_screenshot.png";
import ReviewActivityImage from "graphics/review_activity_screenshot.png";
import Footer from "Footer/Footer";

import CranesImage from "graphics/cranes.jpg";

// import { PlayIcon } from "common/icons";

import BricsCADLogo from "graphics/bricscad_logo.png";
import AutoCADLogo from "graphics/autocad_logo.png";
import MathCADLogo from "graphics/mathcad_logo.png";
import RevitLogo from "graphics/revit_logo.png";

import "./LandingPage.scss";

export default class LandingPage extends React.Component {
  video1Ref = React.createRef();

  state = {
    scrollPosition: 0,
    isVideo1Playing: false,
    isLoaded: false,
  };

  componentDidMount() {
    this.props.setBoxedLayout(false);
    this.props.setBackground(false);
    this.props.setNoScroll(true);
    this.container = document.querySelector(".page-content > .inner-content");

    const isMobile = this.props.windowWidth < 900;
    if (!isMobile) {
      document.addEventListener("scroll", this.onScroll);
    }
    this.onScroll();
    this.setState({ isLoaded: true });
  }

  componentWillUnmount() {
    this.props.setBoxedLayout(true);
    this.props.setBackground(true);
    this.props.setNoScroll(false);
    document.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    this.setState({ scrollPosition: document.querySelector("html").scrollTop });
  };

  computeScrollProgress = ({ start, end, intervals }) => {
    const { scrollPosition } = this.state;

    let progress = 0;
    if (!scrollPosition || scrollPosition <= start) {
      progress = 0;
    } else if (scrollPosition > end) {
      progress = 1;
    } else {
      // progress = Math.round(((scrollPosition - start) / (end - start)) * 2) / 2;
    }

    const values = {};
    for (let transitionKey in intervals) {
      let difference = intervals[transitionKey][1] - intervals[transitionKey][0];
      values[transitionKey] = intervals[transitionKey][0] + difference * progress;
    }

    return { ...values, progress };
  };

  onPlayClick = (e) => {
    if (this.state.isVideo1Playing) {
      this.video1Ref.current.pause();
    } else {
      this.video1Ref.current.currentTime = 0;
      this.video1Ref.current.play();
    }
    this.setState({ isVideo1Playing: !this.state.isVideo1Playing });
  };

  displaySectionDashboard = () => {
    const { windowWidth } = this.props;
    const { isVideo1Playing } = this.state;
    const isMobile = windowWidth < 900;
    let containerStyle = {};
    let detailsStyle = {};

    let illustrationStyle = {};

    if (!isMobile) {
      const values = this.computeScrollProgress({
        start: 0,
        end: 100,
        intervals: {
          containerPaddingTop: [0, 70],
          illustrationMarginLeft: [0, 40],
          illustrationWidth: [100, 60],
          detailsLeft: [-100, 20],
          detailsOpacity: [0, 1],
          detailsTop: [100, 100],
        },
      });
      containerStyle = { paddingTop: values.containerPaddingTop };
      detailsStyle = {
        opacity: values.detailsOpacity,
        left: values.detailsLeft,
        top: values.detailsTop,
      };

      illustrationStyle = {
        marginLeft: values.illustrationMarginLeft + "%",
        width: values.illustrationWidth + "%",
      };
    }

    return (
      <div className={cx("image-with-details", { mobile: isMobile })} style={containerStyle}>
        <div className="illustration-container is-video" style={illustrationStyle} /*onClick={this.onPlayClick}*/>
          <img alt="" src={DashboardImage} className={cx("illustration", { hidden: isVideo1Playing })} />

          {/* <div className={cx("play-icon-container", { hidden: this.state.isVideo1Playing })}>
            <PlayIcon />
          </div> */}
        </div>
        <div className="details" style={detailsStyle}>
          <div className="item">
            <h2 className="title">Organise</h2>
            <p className="description">Create clients, projects, tasks and assign work to your team.</p>
          </div>

          <div className="item">
            <h2 className="title">Track</h2>
            <p className="description">Complete visibility of every piece of work at glance.</p>
          </div>

          <div className="item">
            <h2 className="title">Filter</h2>
            <p className="description">Filter tasks by assignee, task title, project title, due date and more.</p>
          </div>

          <div className="item">
            <h2 className="title">Powerful search</h2>
            <p className="description">Find what you need when you need it.</p>
          </div>
        </div>
      </div>
    );
  };

  displaySectionTimeline = () => {
    return (
      <div className="boxed-layout card-section">
        <div className="card-row">
          <div className="card-item">
            <div className="illustration-container">
              <img alt="" className="illustration" src={TimelineImage} />
            </div>
            <h2 className="title">Plan</h2>
            <p className="description">
              Make sure work gets done on time with DraughtHub's collaborative planning feature. Assign work to
              teammates with simple drag-and-drop interaction.
            </p>
          </div>

          <div className="card-item">
            <div className="illustration-container">
              <img alt="" className="illustration" src={TimelineFiltersImage} />
            </div>
            <h2 className="title">Analyse</h2>
            <p className="description">
              Look at planning data on a daily, weekly, even yearly basis, with intuitive zoom controls and date range
              selection.
            </p>
          </div>

          <div className="card-item">
            <div className="illustration-container">
              <img alt="" className="illustration" src={TimelineUnplannedListImage} />
            </div>
            <h2 className="title">Eliminate doubt</h2>
            <p className="description">
              Easily find tasks that are due in a given range (today, next week, next month) and plan them out. As you
              plan each task, it disappears from the list.
            </p>
          </div>
        </div>
      </div>
    );
  };

  displaySectionCAD = () => {
    return (
      <div className="boxed-layout card-section">
        <div className="card-row">
          <div className="card-item">
            <div className="illustration-container">
              <img alt="" className="illustration" src={CartridgeImage} />
            </div>
            <h2 className="title">Auto-Publish</h2>
            <p className="description">
              DraughtHub syncs your work to the cloud and publishes it every time you save. Using your template, we
              automatically annotate the published PDF with: task details, client logo, sheet numbers, revision history
              and more.
            </p>
          </div>

          <div className="card-item">
            <div className="illustration-container">
              <img alt="" className="illustration with-border" src={PdfVersionsImage} />
            </div>
            <h2 className="title">PDF version history</h2>
            <p className="description">
              All history is recorded and you can step back through time to access the PDF of any version.
            </p>
          </div>
        </div>
        <div className="card-row">
          <div className="card-item">
            <div className="illustration-container">
              <img alt="" className="illustration with-border" src={SheetImage} />
            </div>
            <h2 className="title">Manage sheets</h2>
            <p className="description">You can add, remove, and rename drawing sheets from the web interface.</p>
          </div>

          <div className="card-item">
            <div className="illustration-container">
              <img alt="" className="illustration" src={SheetRevisionsImage} />
            </div>
            <h2 className="title">Sheet revision history</h2>
            <p className="description">
              Add revisions to any sheet, change the status and easily access PDFs of old revisions.
            </p>
          </div>
        </div>
      </div>
    );
  };

  displaySectionReview = () => {
    return (
      <div className="boxed-layout card-section">
        <div className="card-row">
          <div className="card-item">
            <div className="illustration-container">
              <img alt="" className="illustration with-border" src={TaskRevisionsImage} />
            </div>
            <h2 className="title light">Task revisions</h2>
            <p className="description">
              Revision management made simple. You can quickly access old task revisions and reviews.
            </p>
          </div>

          <div className="card-item">
            <div className="illustration-container">
              <img alt="" className="illustration with-border" src={ReviewSheetsImage} />
            </div>
            <h2 className="title dark">Review</h2>
            <p className="description">
              Each revision goes through a review process before being finished. This includes everything: CAD files,
              calculations, reports.
            </p>
          </div>
        </div>
        <div className="card-row">
          <div className="card-item">
            <div className="illustration-container">
              <img alt="" className="illustration with-border" src={ReviewAnnotationImage} />
            </div>
            <h2 className="title medium">Annotate</h2>
            <p className="description">
              Make notes directly on the published PDF from within DraughtHub. Use leader lines, rectangles, arrows,
              freehand annotations and more, in your choice of colours and sizes.
            </p>
          </div>

          <div className="card-item">
            <div className="illustration-container">
              <img alt="" className="illustration with-border" src={ReviewActivityImage} />
            </div>
            <h2 className="title light">Collaborate</h2>
            <p className="description">
              Discuss feedback using the activity panel. Edit, resolve and reply to comments.
            </p>
          </div>
        </div>
      </div>
    );
  };

  displayIntegrations = () => {
    return (
      <div className="integrations with-dots">
        <h1 className="title">Integrations</h1>
        <div className="boxed-layout">
          <div className="applications">
            <div className="application">
              <img alt="" src={BricsCADLogo} />
            </div>
            <div className="application">
              <img alt="" src={AutoCADLogo} />
            </div>
            <div className="application">
              <img alt="" src={MathCADLogo} />
            </div>
            <div className="application">
              <img alt="" src={RevitLogo} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  displaySecurity = () => {
    return (
      <div className="statement-block">
        <div className="illustration-container" style={{ backgroundImage: `url('${SecurityImage}')` }} />
        <div className="text-container">
          <h2 className="title">Secure. Powerful. Simple.</h2>
          <h4 className="description">
            <b>All our data is stored in AWS Cloud, accessible 24/7, from around the world. </b>
            <br />
            <ul>
              {/* <li>30-day backup</li> */}
              <li>99.999999999% data durability over a given year</li>
              <li>Delete protection</li>
              <li>Role-based access</li>
            </ul>
          </h4>
        </div>
      </div>
    );
  };

  displayQuote = () => {
    return (
      <div className="statement-block quote-container">
        <div className="illustration-container" style={{ backgroundImage: `url('${QuoteImage}')` }} />

        <div className="text-container">
          <h2 className="title">What our clients say</h2>
          <h4 className="description">
            "I can confidently state that it has significantly reduced time on admin, allowing us to focus on the
            engineering. The DraughtHub team have been excellent to work with, and have understood our business and
            developed an excellent system. We would all rather work through DraughtHub than go back to the old way of
            working."
          </h4>
          <h4 className="description">
            <b>
              Angus Holdsworth <br />
              Managing Director at Andun Engineering Consultants
            </b>
          </h4>
        </div>
      </div>
    );
  };

  displayCustomisation = () => {
    return (
      <div className="customisation-container">
        <div className="boxed-layout">
          <div className="text-container">
            <h2 className="title">Customised for your workflow</h2>
            <h4 className="description">
              Our goal is to make DraughtHub work the way you want it to, not the other way around. We add customised
              features for each client free of charge and we work with you to create templates for CAD files,
              calculations and reports.
            </h4>
          </div>
          <div className="cta-container">
            <Button className="cta" type="primary" onClick={this.props.onContact}>
              Get in touch
            </Button>
          </div>
        </div>
      </div>
    );
  };

  displayCatLevels = () => {
    return (
      <div className="statement-block">
        <div className="illustration-container" style={{ backgroundImage: `url('${CranesImage}')` }} />
        <div className="text-container">
          <h2 className="title">Cat checks and authority matrix</h2>
          <h4 className="description">
            <b>Everything in DraughtHub can be controlled by an authority matrix. </b>
            <br />
            <ul>
              <li>
                Each user has an authority level for: <b>design</b>, <b>check</b>, and <b>review {"&"} issue</b>.
              </li>

              <li>
                Assigning tasks is based on the <b>design</b> or <b>check</b> authority level.
              </li>
              <li>
                Engineers can only perform reviews up to their assigned authority level for <b>review {"&"} issue</b>.
              </li>
              <li>We fully support the process of performing Cat I, II and III checks.</li>
            </ul>
          </h4>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="landing-page">
        <div className="main-section">
          <div className="boxed-layout">
            <h1 className="page-title">
              Automate the admin. <br /> Focus on the engineering.
            </h1>
            <h3 className="page-subtitle">Saving Civil Engineers one day a week.</h3>
            <div className="cta-container">
              <Button className="cta" type="primary" onClick={this.props.onContact}>
                Get in touch
              </Button>
            </div>

            {this.displaySectionDashboard()}
          </div>
        </div>

        {this.displayQuote()}
        {this.displaySectionTimeline()}

        {this.displaySecurity()}
        <br />
        <br />

        {this.displaySectionCAD()}

        {this.displayIntegrations()}

        {this.displaySectionReview()}

        {this.displayCatLevels()}

        {this.displayCustomisation()}
        <Footer />
      </div>
    );
  }
}
