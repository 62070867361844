import { Typography, Select } from "antd";

import { callGraphQLSimple } from "common/apiHelpers";
import { QUOTE_REJECTION_REASONS } from "common/constants";

import Card from "Card/Card";
import Input from "Input/Input";

import "./QuoteRejectionDetails.scss";

export default function QuoteRejectionDetails(props) {
  const { quote } = props;

  async function updateRejectionReason({ field, value }) {
    await callGraphQLSimple({
      message: "Failed to update quote rejection type",
      queryName: "updateQuote",
      variables: {
        input: {
          id: quote.id,
          rejectionDetails: {
            ...quote.rejectionDetails,
            [field]: value,
          },
        },
      },
    });
  }

  return (
    <Card className="rejection-details">
      <Typography.Text>Reason for rejection:</Typography.Text>
      <Select
        placeholder="Choose a reason"
        defaultValue={quote.rejectionDetails?.type}
        onChange={(value) => updateRejectionReason({ field: "type", value })}
      >
        {QUOTE_REJECTION_REASONS.map((reason) => {
          return (
            <Select.Option key={reason.value} value={reason.value}>
              {reason.label}
            </Select.Option>
          );
        })}
      </Select>

      <Typography.Text>Additional info:</Typography.Text>
      <Input
        data-cy="rejection-notes"
        defaultValue={quote.rejectionDetails?.notes}
        className="rejection-notes"
        onChange={(value) => updateRejectionReason({ field: "notes", value })}
        fullWidth
        multiLine
        showBorder
      />
    </Card>
  );
}
