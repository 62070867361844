import { useState } from "react";
import { Modal } from "antd";

import Attachments from "Attachments/Attachments";

import "./InsertAttachmentModal.scss";

export default function InsertAttachmentModal({
  onClose,
  apiUser,
  task,
  project,
  request,
  onSubmit,
  selectedItems,
  allowedFileTypes,
  defaultPath,
  defaultRelativePath,
}) {
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const [selectedSizes, setSelectedSizes] = useState({});

  function submitAttachments() {
    onSubmit(selectedAttachments, selectedDates, selectedSizes);
    onClose();
  }

  return (
    <Modal
      maskClosable={false}
      title="Choose attachments"
      open={true}
      onOk={submitAttachments}
      onCancel={onClose}
      className="insert-attachment-modal"
    >
      <Attachments
        task={task}
        project={project}
        request={request}
        apiUser={apiUser}
        defaultPath={defaultPath}
        defaultRelativePath={defaultRelativePath}
        selectedItems={selectedItems}
        allowedFileTypes={allowedFileTypes}
        onChange={(attachments, dates, sizes) => {
          setSelectedAttachments(attachments);
          setSelectedDates(dates);
          setSelectedSizes(sizes);
        }}
        isInModal={true}
      />
    </Modal>
  );
}
