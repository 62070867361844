import { TEMPLATE_EDITOR_SPACE_BETWEEN_PAGES } from "common/constants";

export default function getSizeForAllPages(pages) {
  let width = 0;
  let height = 0;

  pages.forEach((page, pageIndex) => {
    if (page.isHidden || page.visible === false) {
      return;
    }

    width += page.custom_pageWidth || 0;
    if (pageIndex < pages.length - 1) {
      width += TEMPLATE_EDITOR_SPACE_BETWEEN_PAGES;
    }
    if ((page.custom_pageHeight || 0) > height) {
      height = page.custom_pageHeight || 0;
    }
  });

  return {
    width,
    height,
  };
}
