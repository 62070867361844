import moment from "moment";
import { REPEATABLE_FIELDS } from "./form";

const fields = [];

// const REPEATABLE_FIELDS = ["checkbox-list", "attachmentPicker", "nestedFieldListWithModal", "nestedFieldListNoModal"];

export function calculateReadableSize(item) {
  let sizeReadable;
  let GB = Math.pow(1024, 3);
  let MB = Math.pow(1024, 2);
  let KB = 1024;

  if (item.size) {
    if (item.size > GB * 0.9) {
      sizeReadable = `${Math.round((item.size / GB) * 100) / 100}GB`;
    } else if (item.size > MB * 0.9) {
      sizeReadable = `${Math.round((item.size / MB) * 100) / 100}MB`;
    } else {
      sizeReadable = `${Math.floor(item.size / KB)}KB`;
    }
  }
  return sizeReadable;
}

export function getFields(params) {
  let { form, repeatFor, formElement, repeatElement, parentRepeat } = params;
  let formFields = [];

  // console.log("formElementChild getFields() formElement = ", formElement, "repeatElement = ", repeatElement);

  if (formElement) {
    switch (repeatElement.type) {
      case "checkbox-list":
        formFields = [
          {
            id: "checkbox-list-item-value",
            value: formElement,
            supportsSeparator: true,
          },
        ];
        break;
      case "attachmentPicker":
        if (typeof formElement !== "string" && params.undefined) {
          formElement = params.undefined;
        }

        formFields = [
          {
            id: "attachment-picker-item-path",
            value: formElement,
          },
          {
            id: "attachment-picker-item-name",
            value: typeof formElement === "string" && formElement.split("/").slice(-1)[0],
          },
          {
            id: "attachment-picker-item-name-without-extension",
            value: typeof formElement === "string" && formElement?.split("/").slice(-1)[0].split(".")[0],
          },
          {
            id: "attachment-picker-item-size",
            value:
              repeatElement.sizes && repeatElement.sizes[formElement]
                ? calculateReadableSize({
                    size: repeatElement.sizes[formElement],
                  })
                : "",
          },
          {
            id: "attachment-picker-item-last-modified",
            value:
              repeatElement.dates && repeatElement.dates[formElement]
                ? moment(repeatElement.dates[formElement]).format("DD-MM-YYYY")
                : "",
          },
        ];
        break;
      case "nestedFieldListWithModal":
      case "nestedFieldListNoModal":
        // console.log("repeatElement = ", repeatElement);
        for (let fieldId in formElement) {
          let formFieldToAdd = {
            ...repeatElement.fields[fieldId],
            id: fieldId,
            value: formElement[fieldId],
          };
          formFields.push(formFieldToAdd);
        }
        break;
      default:
        break;
    }

    return formFields;
  } else {
    if (!form) {
      return fields;
    }

    if (!repeatFor) {
      if (parentRepeat && parentRepeat.repeatForFieldName === "formElement") {
        console.log("!!!! on this branch");
        for (let fieldId in parentRepeat.fields) {
          let field = parentRepeat.fields[fieldId];

          if (REPEATABLE_FIELDS.includes(field.type)) {
            let newField = {
              ...field,
              label: `Each item in ${field.label}`,
              id: `repeat_${fieldId}`,
              type: field.type,
              fieldTypes: ["repeatFor"],
              repeatForFieldName: "formElementChild",
            };

            console.log("newField = ", newField);

            formFields.push(newField);
          }
        }

        let returnValue = [...fields, ...formFields];
        return returnValue;
      } else {
        return fields;
      }
    }

    if (repeatFor.startsWith("repeat_")) {
      repeatFor = repeatFor.replace("repeat_", "");
    }
    let targetContainerField = findFieldWithIdInTree(form, repeatFor);
    formFields = getFieldsForFieldType(targetContainerField);
  }

  return [...fields, ...formFields];
}

// write a function which searches a tree where each element has a "fields" property and returns the field with the given id
function findFieldWithIdInTree(tree, id) {
  if (tree.fields && tree.fields[id]) {
    return tree.fields[id];
  } else {
    for (let childId in tree.fields) {
      let field = findFieldWithIdInTree(tree.fields[childId], id);
      if (field) {
        return field;
      }
    }
  }
}

function getFieldsForFieldType(containerField) {
  let formFields = [];
  switch (containerField?.type) {
    case "checkbox-list":
      formFields = [
        {
          id: "checkbox-list-item-value",
          label: "Checkbox item",
        },
      ];
      break;
    case "attachmentPicker":
      formFields = [
        {
          id: "attachment-picker-item-name",
          label: "File name",
        },
        {
          id: "attachment-picker-item-name-without-extension",
          label: "File name without extension",
        },
        {
          id: "attachment-picker-item-path",
          label: "File path",
        },
        {
          id: "attachment-picker-item-size",
          label: "File size",
        },
        {
          id: "attachment-picker-item-last-modified",
          label: "Last modified",
        },
      ];
      break;
    case "nestedFieldListWithModal":
    case "nestedFieldListNoModal":
      for (let fieldId in containerField?.fields) {
        let field = containerField.fields[fieldId];

        formFields.push({
          ...field,
          id: fieldId,
          type: "string",
          fieldType: field.type,
        });
      }
      break;
    default:
      break;
  }
  return formFields;
}

export const label = "Form element child";
