import React from "react";
import { Button, Typography, Modal } from "antd";
import { EditOutlined, DeleteOutlined, PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import cx from "classnames";

import { callGraphQLSimple } from "common/apiHelpers";
import { getInternalFeeDisplay } from "common/feeHelpers";
import withSubscriptions from "common/withSubscriptions";

import Card from "Card/Card";
import FeeModal from "Modals/FeeModal/FeeModal";

import "./FeeList.scss";

export class FeeList extends React.Component {
  state = {
    isFeeModalVisible: false,
    selectedFee: null,
  };

  getMutationAndFieldName = () => {
    let queryName;
    let fieldName;
    const { parentType } = this.props;
    switch (parentType) {
      case "organisation":
        queryName = "updateOrganisation";
        fieldName = "defaultFees";
        break;
      case "client":
        queryName = "updateClient";
        fieldName = "fees";
        break;
      default:
        return;
    }
    return { queryName, fieldName };
  };

  confirmDeleteFee = (fee) => {
    Modal.confirm({
      title: "Confirm fee delete",
      className: "delete-fee-modal",
      content: (
        <>
          Are you sure you want to delete fee <b>{fee.label}</b>?
        </>
      ),
      onOk: async () => {
        const { parent } = this.props;
        const { queryName, fieldName } = this.getMutationAndFieldName();
        await callGraphQLSimple({
          message: "Could not delete fee",
          queryName,
          variables: {
            input: {
              id: parent.id,
              [fieldName]: (parent[fieldName] || []).filter((x) => x.id !== fee.id),
            },
          },
        });
        this.setState({ isFeeModalVisible: false });
      },
    });
  };

  addFee = async (feeDetails) => {
    const { parent } = this.props;
    const { queryName, fieldName } = this.getMutationAndFieldName();

    await callGraphQLSimple({
      message: "Could not add fee",
      queryName,
      variables: {
        input: {
          id: parent.id,
          [fieldName]: [...(parent[fieldName] || []), feeDetails],
        },
      },
    });
    this.setState({ isFeeModalVisible: false });
  };

  updateFee = async (feeDetails) => {
    if (feeDetails.type === "RELATIVE") {
      feeDetails.value = null;
    } else {
      feeDetails.defaultPercentage = null;
      feeDetails.defaultPlus = null;
    }

    const { parent } = this.props;
    const { queryName, fieldName } = this.getMutationAndFieldName();

    let newFees = (parent[fieldName] || []).map((crtFee) => {
      if (crtFee.id === feeDetails.id) {
        return feeDetails;
      }

      return crtFee;
    });

    await callGraphQLSimple({
      message: "Could not add fee",
      queryName,
      variables: {
        input: {
          id: parent.id,
          [fieldName]: newFees,
        },
      },
    });
    this.setState({ isFeeModalVisible: false });
  };

  deleteAllFeeOverrides = () => {
    const { parent } = this.props;
    const { queryName, fieldName } = this.getMutationAndFieldName();

    Modal.confirm({
      title: "Confirm deletion of all fee overrides",
      className: "delete-fee-modal",
      content: <>Are you sure you want to delete all fee overrides?</>,
      onOk: async () => {
        await callGraphQLSimple({
          message: "Could not delete fee overrides",
          queryName,
          variables: {
            input: {
              id: parent.id,
              [fieldName]: [],
            },
          },
        });
      },
    });
  };

  render() {
    const { isFeeModalVisible, selectedFee } = this.state;
    const { parent, parentType, organisationDetails } = this.props;
    const { fieldName } = this.getMutationAndFieldName();

    return (
      <Card
        title={parentType === "organisation" ? "Standard fees" : "Fee overrides"}
        className={cx("fee-list")}
        actions={
          <>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => this.setState({ isFeeModalVisible: true })}
              data-cy="add-fee-button"
            >
              Add fee
            </Button>
            {parentType === "client" && (
              <Button
                type="primary"
                data-cy="delete-all-fee-overrides-button"
                icon={<MinusCircleOutlined />}
                onClick={() => this.deleteAllFeeOverrides()}
              >
                Delete all fee overrides
              </Button>
            )}
          </>
        }
      >
        {(parent[fieldName] || []).map((fee, index) => {
          let overrideFeeDetails = null;
          if (fee.overrideFeeId) {
            overrideFeeDetails = (organisationDetails.defaultFees || []).find((x) => x.id === fee.overrideFeeId);
          }
          return (
            <div className="fee" key={fee.id} data-cy="fee-item" data-index={index}>
              <Typography.Text className={cx("label", { override: !!overrideFeeDetails })} data-cy="fee-label">
                {fee.label} {overrideFeeDetails && "(override)"}
              </Typography.Text>
              <Typography.Text className="value" data-cy="fee-value">
                {getInternalFeeDisplay({ fee })}
              </Typography.Text>

              {/* <Typography.Text className="override" data-cy="fee-override">
                {overrideFeeDetails ? `Overrides ${overrideFeeDetails.label}` : null}
              </Typography.Text> */}
              <Button
                icon={<EditOutlined />}
                className="edit-fee"
                data-cy="edit-fee-button"
                onClick={() => {
                  this.setState({
                    isFeeModalVisible: true,
                    selectedFee: fee,
                  });
                }}
              />
              <Button
                icon={<DeleteOutlined />}
                className="delete-fee"
                data-cy="delete-fee-button"
                onClick={() => this.confirmDeleteFee(fee)}
              />
            </div>
          );
        })}
        {isFeeModalVisible && (
          <FeeModal
            organisationDetails={organisationDetails}
            onClose={() => this.setState({ isFeeModalVisible: false, selectedFee: null })}
            existingOverrides={parent.fees?.filter((fee) => fee.overrideFeeId)}
            overridesAllowed={parentType !== "organisation"}
            onSubmit={async (fee) => {
              if (selectedFee) {
                await this.updateFee(fee);
              } else {
                await this.addFee(fee);
              }
              this.setState({ selectedFee: null });
            }}
            fee={selectedFee}
          />
        )}
      </Card>
    );
  }
}

export default withSubscriptions({
  Component: FeeList,
  subscriptions: ["organisationDetails"],
});
