import { useState } from "react";
import { Storage } from "aws-amplify";
import { Form, Modal, Button, Space } from "antd";

import Upload from "Upload/Upload";

import "./UploadFontModal.scss";

export default function UploadFontModal({ onClose, organisationDetails }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ file }) {
    console.log("file = ", file);
    setIsLoading(true);

    const fileName = `${organisationDetails.id}/fonts/${Date.now()}_${file.name.split(" ").join("-")}`;

    const { key } = await Storage.put(fileName, file);

    let name = file.name.split("-").join(" ").split(".")[0];

    await window.callGraphQLSimple({
      message: "Failed to upload font",
      mutation: "createVariable",
      variables: {
        input: {
          id: `${organisationDetails.id}_FONT_${name}`,
          organisation: organisationDetails.id,
          name,
          type: "FONT",
          value: key,
        },
      },
    });

    await window.callGraphQLSimple({
      message: "Failed to refresh organisation details",
      mutation: "updateOrganisation",
      variables: {
        input: {
          id: organisationDetails.id,
          itemSubscription: Math.floor(Math.random() * 1000000),
        },
      },
    });
    setIsLoading(false);
    onClose();
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title="Upload font"
      visible={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="upload-font-modal"
    >
      <Form {...layout} form={form} initialValues={{}} onFinish={onSubmit}>
        <Space direction="vertical" className="spread">
          <Form.Item
            name="file"
            label="Font"
            getValueFromEvent={(e, file) => file}
            rules={[
              { required: true, message: "You must choose a file first" },
              // {
              //   required: true,
              //   validator: checkImagePath,
              //   message: "Image must be either .jpg or .png",
              // },
            ]}
          >
            <Upload size="normal" />
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading} data-cy="submit-button">
              Submit
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}
