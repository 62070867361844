import React from "react";

import { Typography } from "antd";

import { withRouter } from "react-router-dom";
import withSubscriptions from "common/withSubscriptions";

import Card from "Card/Card";
import { QuickbooksLogo } from "./QuickbooksSvgAssets";
import { getQuickbooksStatus, displayQuickbooksActions } from "./quickbooksHelpers";
import { Organisation, Integration } from "common/types";

import moment from "moment";

import "./QuickbooksTab.scss";

type Props = {
  organisationDetails: Organisation;
};

export function QuickbooksTab(props: Props) {
  const { organisationDetails } = props;

  const quickbooksDetails: Integration | undefined = organisationDetails.integrations?.find(
    (integration) => integration?.id === "QUICKBOOKS"
  );
  const quickbooksStatus = getQuickbooksStatus(quickbooksDetails);

  return (
    <div className="quickbooks-tab">
      <Card title={<QuickbooksLogo />} withSpace>
        <div className="quickbooks-status">
          <Typography.Text>Status: {quickbooksStatus}</Typography.Text>
          {quickbooksStatus === "Active" && (
            <Typography.Text>
              {quickbooksDetails?.authorisedAt
                ? `Authorised at: ${moment(quickbooksDetails?.authorisedAt).format("YYYY-MM-DD HH:mm:ss")}`
                : null}
            </Typography.Text>
          )}
        </div>
        {displayQuickbooksActions(quickbooksStatus, organisationDetails)}
      </Card>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: QuickbooksTab,
    subscriptions: ["organisationDetails"],
  })
);
