import { useState } from "react";
import { Checkbox, Modal, Form, Button } from "antd";

import { getUserOptions } from "Modals/MultipleAssignedUsersModal/multipleAssignedUsersHelpers";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";
import { withRouter } from "react-router-dom";
import { getSimpleLabel } from "common/labels";

import "./MultipleAssignedUsersModal.scss";

export function MultipleAssignedUsersModal({
  taskId,
  tasks,
  organisationDetails,
  users,
  holidays,
  onClose,
  apiUser,
  startDate,
  endDate,
  assignedToUsers,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [error, setError] = useState();

  const initialValues = {
    assignedToUsers,
  };

  const optionsForMultipleAssignedUsers = getUserOptions({
    organisationDetails,
    users,
    holidays,
    tasks,
    startDate,
    endDate,
    apiUser,
    taskId,
  });

  async function onSubmit({ assignedToUsers }) {
    setIsLoading(true);
    setError(undefined);

    try {
      await callGraphQLSimple({
        message: "Failed to add allowance",
        queryCustom: "updateTask",
        variables: {
          input: {
            id: taskId,
            assignedToUsers,
          },
        },
      });

      setIsLoading(false);
      onClose();
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  }

  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 10,
      span: 16,
    },
  };

  return (
    <Modal
      open={true}
      title={getSimpleLabel("task-assigned-to-single")}
      maskClosable
      closable
      onCancel={onClose}
      footer={null}
      className="multiple-assigned-users-modal"
    >
      <Form {...layout} form={form} onFinish={onSubmit} initialValues={initialValues}>
        <Form.Item
          label={getSimpleLabel("task-assigned-to-multiple-users")}
          name="assignedToUsers"
          dependencies={["startDate", "endDate"]}
          // rules={[
          //   {
          //     validator: checkAssignedToMultipleUsers,
          //     message: "Some of the the chosen users are not available",
          //   },
          // ]}
        >
          <Checkbox.Group options={optionsForMultipleAssignedUsers} className="option-items" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="add-allowance-submit-button">
            {isLoading ? "Submitting..." : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default withRouter(
  withSubscriptions({
    Component: MultipleAssignedUsersModal,
    subscriptions: ["organisationDetails", "users", "tasks", "holidays"],
  })
);
