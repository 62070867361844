import React from "react";

import { Form, Alert, Button, Input, Typography, Space } from "antd";
import { SendOutlined, LeftOutlined } from "@ant-design/icons";
import { Auth } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";

import Card from "Card/Card";

import "./ResetPasswordPage.scss";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export class ResetPasswordPage extends React.Component {
  state = {
    sendCodeError: null,
    changePasswordError: null,
    feedbackSendCode: null,
    feedbackChangePassword: null,
    isSubmittingSendCode: false,
    isSubmittingChangePassword: false,
  };

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  onSendCode = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      this.setState({ isSubmittingSendCode: true, sendCodeError: null });
      const { email } = this.formRef.current.getFieldsValue();

      await Auth.forgotPassword(email);
      this.setState({
        feedbackSendCode: true,
        isSubmittingSendCode: false,
      });
    } catch (e) {
      this.setState({ sendCodeError: e.message, isSubmittingSendCode: false });
    }
  };

  onSubmit = async ({ code, password, email }) => {
    code = code.trim();
    password = password.trim();
    email = email.toLowerCase().trim();

    this.setState({ changePasswordError: null, feedbackChangePassword: null });
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      this.setState({ feedbackChangePassword: true });
    } catch (e) {
      this.setState({ changePasswordError: e.message });
    }
  };

  displaySendCodeForm = () => {
    return (
      <Card>
        <Typography.Title level={4} className="title">
          Reset your password
        </Typography.Title>

        <Form {...layout} name="basic" onFinish={this.onSubmit} autoComplete="off" ref={this.formRef}>
          <Form.Item label="Email" name="email" rules={[{ required: true, message: "Email cannot be empty" }]}>
            <Input />
          </Form.Item>

          <div style={{ paddingLeft: "calc(100% / 3" }}>
            {this.state.feedbackSendCode ? (
              <Alert message={"Code Sent"} type="success" showIcon className="send-code-success" />
            ) : (
              <Button
                htmlType="submit"
                className="send-code"
                onClick={this.onSendCode}
                loading={this.state.isSubmittingSendCode}
                icon={<SendOutlined />}
              >
                Send Code
              </Button>
            )}
          </div>
          <Form.Item
            label="Verification code"
            name="code"
            rules={[{ required: true, message: "Code cannot be empty" }]}
          >
            <Input autoComplete="off" placeholder="Input the code from your email" style={{ marginTop: "0.5rem" }} />
          </Form.Item>
          <Form.Item
            label="New password"
            name="password"
            rules={[{ required: true, message: "New password cannot be empty" }]}
          >
            <Input type="password" autoComplete="off" />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Change password
            </Button>
          </Form.Item>
        </Form>

        {this.state.sendCodeError ? <Alert message={this.state.sendCodeError} type="error" showIcon /> : null}

        {this.state.changePasswordError ? (
          <Alert message={this.state.changePasswordError} type="error" showIcon />
        ) : null}
        {this.state.feedbackChangePassword ? (
          <Alert message={"Password has been changed successfully"} type="success" showIcon />
        ) : null}
      </Card>
    );
  };

  render() {
    return (
      <div className="reset-password-page">
        <Space direction="vertical" className="main-space">
          {this.displaySendCodeForm()}

          <Link to="/sign-in">
            <Button icon={<LeftOutlined />} className="back-to-sign-in" loading={this.state.isSubmittingSendCode}>
              Back to Sign in
            </Button>
          </Link>
        </Space>
      </div>
    );
  }
}

export default withRouter(ResetPasswordPage);
