import findChildAndParent from "./findChildAndParent";
import updateChildrenForParent from "./updateChildrenForParent";

export default function deleteObject({ objectId, outputTemplate }) {
  let newOutputTemplate = { ...outputTemplate };
  // Extract the child and its current parent
  const { child, parent: oldParent } = findChildAndParent(outputTemplate, objectId);

  if (child) {
    // Remove the child from its old parent
    const newChildrenForOldParent = oldParent.objects.filter((obj) => obj.custom_id !== objectId);

    // Update the old parent's children
    newOutputTemplate = updateChildrenForParent({ ...outputTemplate }, oldParent.custom_id, newChildrenForOldParent);
  }

  return newOutputTemplate;
}
