import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import later from "@breejs/later";
import moment from "moment";
import { Modal, Button, Typography } from "antd";

import withSubscriptions from "common/withSubscriptions";

import "./ScheduledNotificationsManager.scss";

const INTERVAL_TIME = 1000 * 10;

export function ScheduledNotificationsManager({ organisationDetails, apiUser, history, match }) {
  // extract url params from query string

  useEffect(() => {
    later.date.localTime();
    checkScheduledNotifications();

    let interval = setInterval(checkScheduledNotifications, INTERVAL_TIME);
    return () => {
      clearInterval(interval);
    };
  }, []);

  async function checkScheduledNotifications() {
    const scheduledNotifications = organisationDetails?.settings?.general?.scheduledNotifications || [];

    if (!scheduledNotifications || scheduledNotifications.length === 0) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const scheduledNotificationId = urlParams.get("scheduledNotificationId");

    for (let scheduledNotification of scheduledNotifications) {
      try {
        let parsedText = later.parse.text(scheduledNotification.schedule);
        let parsedCron = later.parse.cron(scheduledNotification.schedule);
        let scheduleToUse = parsedText.schedules[0] ? parsedText : parsedCron;
        let parsedSchedule = later.schedule(scheduleToUse);

        const prevOccurence = parsedSchedule.prev(1);

        // if the previous occurence is less than 60 seconds ago, log the notification
        let delta = moment().diff(moment(prevOccurence));

        if (delta < INTERVAL_TIME) {
          // it means the notification is due
          if (scheduledNotificationId !== scheduledNotification.id) {
            await displayScheduledNotification(scheduledNotification);
          }
        }
      } catch (e) {
        console.error(
          "Error parsing scheduled notification:",
          scheduledNotification.name,
          e,
          scheduledNotification.schedule
        );
      }
    }
  }

  async function displayScheduledNotification(scheduledNotification) {
    let builtBody = [];
    let parsedBody = JSON.parse(scheduledNotification.body);
    let systemNotificationLink;

    let elementCounter = 0;
    let modalElement = null;
    for (let bodyElement in parsedBody) {
      switch (parsedBody[bodyElement].type) {
        case "text":
          builtBody.push(
            <Typography.Paragraph key={elementCounter}>{parsedBody[bodyElement].text}</Typography.Paragraph>
          );
          break;
        case "button":
          if (parsedBody[bodyElement].link) {
            systemNotificationLink = parsedBody[bodyElement].link;
          }
          builtBody.push(
            <Button
              key={elementCounter}
              type={parsedBody[bodyElement].buttonType}
              onClick={() => {
                if (modalElement) {
                  modalElement.destroy();
                }

                if (parsedBody[bodyElement].link) {
                  setTimeout(() => {
                    history.push(parsedBody[bodyElement].link);
                  }, 100);
                }
              }}
            >
              {parsedBody[bodyElement].text}
            </Button>
          );
          break;
        default:
          break;
      }
    }

    if (document.visibilityState === "visible") {
      modalElement = Modal.info({
        icon: null,
        title: scheduledNotification.title,
        content: <div className="notification-body">{builtBody}</div>,
        footer: null,
        className: "modal-no-buttons scheduled-notification-modal",
      });
    } else {
      const notification = new Notification(scheduledNotification.title, {});

      if (systemNotificationLink) {
        notification.onclick = function () {
          let url = `${window.location.origin}${systemNotificationLink}`;
          if (url.includes("?")) {
            url += "&";
          } else {
            url += "?";
          }
          url += `scheduledNotificationId=${scheduledNotification.id}`;
          window.open(url, "_blank");
        };
      }
    }
  }

  return null;
}

export default withRouter(
  withSubscriptions({
    Component: ScheduledNotificationsManager,
    subscriptions: ["organisationDetails"],
  })
);
