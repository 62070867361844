import moment from "moment";

import { WEEKDAY_OPTIONS } from "common/constants";

export function getWorkingHoursRuleForUserOnDate({ userId, groups, date, workingHoursRules }) {
  const momentDate = moment(date);

  const workingHoursRulesForUser = workingHoursRules.filter((rule) => rule.parentId === userId);
  const workingHoursRulesForUserThatCoverDate = workingHoursRulesForUser.filter(({ startDate, endDate }) => {
    return startDate <= date && date <= endDate;
  });
  const workingHoursRulesForUserForDayOfWeek = workingHoursRulesForUserThatCoverDate.filter(({ dayOfWeek }) => {
    const dayOfWeekText = momentDate.format("dddd");
    const weekdayDetails = WEEKDAY_OPTIONS.find((weekdayDetails) => weekdayDetails.label === dayOfWeekText);
    return dayOfWeek === weekdayDetails.value;
  });

  if (workingHoursRulesForUserForDayOfWeek.length) {
    return workingHoursRulesForUserForDayOfWeek[0];
  }

  const firstGroupForUser = groups.find((group) => group.members?.includes(userId));
  const workingHoursRulesForGroup = workingHoursRules.filter((rule) => rule.parentId === firstGroupForUser.id);
  const workingHoursRulesForGroupThatCoverDate = workingHoursRulesForGroup.filter(({ startDate, endDate }) => {
    return startDate <= date && date <= endDate;
  });
  const workingHoursRulesForGroupForDayOfWeek = workingHoursRulesForGroupThatCoverDate.filter(({ dayOfWeek }) => {
    const dayOfWeekText = momentDate.format("dddd");
    const weekdayDetails = WEEKDAY_OPTIONS.find((weekdayDetails) => weekdayDetails.label === dayOfWeekText);
    return dayOfWeek === weekdayDetails.value;
  });

  if (workingHoursRulesForGroupForDayOfWeek.length) {
    return workingHoursRulesForGroupForDayOfWeek[0];
  }
}

export function getTotalHoursForWorkingHoursRule(workingHoursRule) {
  if (!workingHoursRule) {
    return 0;
  }

  const startTimeMoment = moment(workingHoursRule.startTime, "HH:mm");
  const endTimeMoment = moment(workingHoursRule.endTime, "HH:mm");
  const duration = moment.duration(endTimeMoment.diff(startTimeMoment));
  const hours = duration.asHours();
  return hours;
}

export function getTotalBreakHoursForWorkingHoursRule(workingHoursRule) {
  if (!workingHoursRule) {
    return 0;
  }

  let hours = workingHoursRule.breaks?.reduce((total, { startTime, endTime }) => {
    const startTimeMoment = moment(startTime, "HH:mm");
    const endTimeMoment = moment(endTime, "HH:mm");
    const duration = moment.duration(endTimeMoment.diff(startTimeMoment));
    return total + duration.asHours();
  }, 0);

  return hours;
}
