import { useEffect, useState } from "react";
import { Table, Breadcrumb, Tag, Typography, Tabs } from "antd";
import { Link } from "react-router-dom";
import query from "query-string";
import { withRouter } from "react-router-dom";

import { getLabel, processIdForDisplay } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";

import StandardFeesPage from "OrganisationPage/StandardFeesPage/StandardFeesPage";
import Attachments from "Attachments/Attachments";
import { getSimpleLabel } from "common/labels";
import Explanation from "Explanation/Explanation";
import AddressList from "AddressList/AddressList";
import ContactList from "ContactList/ContactList";
import NonTaskReports from "NonTaskReports/NonTaskReports";
import ClientSidebar from "./ClientSidebar/ClientSidebar";
import ClientSummary from "./ClientSummary/ClientSummary";
import ClientActions from "./ClientActions/ClientActions";
import SharingTab from "SharingTab/SharingTab";
import Avatar from "Avatar/Avatar";

import "./ClientDetailsPage.scss";

function ClientDetailsPage(props) {
  const {
    organisationDetails,
    tasks,
    users,
    client,
    projects,
    windowWidth,
    setBoxedLayout,
    apiUser,
    history,
    location,
  } = props;
  const [activeTab, setActiveTab] = useState("projects");

  useEffect(() => {
    async function createAuditItem() {
      callGraphQLSimple({
        displayError: false,
        mutation: "createAuditItem",
        variables: {
          input: {
            taskId: "nothing",
            projectId: "nothing",
            fileId: "nothing",
            clientId: client.id,
            page: "CLIENT",
            type: "PAGE_VIEW",
            userId: apiUser.id,
            organisation: apiUser.organisation,
          },
        },
      });
    }

    createAuditItem();

    setBoxedLayout(false);

    const queryString = query.parse(location.search);
    const activeTab = queryString.clientTab;

    if (activeTab) {
      setActiveTab(activeTab);
    }

    return () => {
      setBoxedLayout(true);
    };
  }, []); //eslint-disable-line

  function displayProjectsTable() {
    if (!projects) {
      return null;
    }

    return (
      <Table
        columns={[
          {
            title: "ID",
            key: "id",
            align: "center",
            width: 100,
            render: (_, project) => {
              return (
                <Tag className="project-id-tag dark-tag" style={{ minWidth: "100px" }}>
                  {processIdForDisplay(project.id)}
                </Tag>
              );
            },
          },
          {
            title: getSimpleLabel("Tasks"),
            key: "numberOfTasks",
            align: "center",
            width: 50,
            render: (_, project) => {
              let tasksForProject = tasks.filter(
                (task) => task.projectId === project.id && !task.isHidden && !task.id.includes("TEMPLATES")
              );

              return tasksForProject.length;
            },
          },
          organisationDetails.usesProjectInitials && {
            title: "Initials",
            dataIndex: "initials",
            key: "initials",
            align: "left",
            width: 40,
          },
          {
            title: "Title",
            key: "title",
            align: "left",
            dataIndex: "title",
          },
          {
            title: "Senior engineer",
            key: "assignedTo",
            align: "left",
            width: 200,
            render: (_, project) => {
              let assigneeDetails = users.find((user) => user.id === project.assignedTo);
              if (!assigneeDetails) {
                return null;
              }
              return <Avatar user={assigneeDetails} showLabel />;
            },
          },
        ].filter((x) => x)}
        dataSource={projects.filter((project) => project.clientId === client.id).sort((a, b) => (a.id < b.id ? -1 : 1))}
        rowKey="id"
        pagination={{ pageSize: 50, hideOnSinglePage: true }}
        scroll={{ x: 700 }}
        onRow={(project) => {
          return {
            onClick: () => {
              history.push(`/projects/${project.id}`);
            },
          };
        }}
      />
    );
  }

  let layout = {};

  if (windowWidth > 850) {
    layout.mainContentWidth = "100%";
    layout.sidebarWidth = "300px";
  } else {
    layout.mainContentWidth = "100%";
    layout.sidebarWidth = 0;
  }

  const basePath = `/clients/${client.id}`;

  return (
    <div className="client-details-page">
      <div className="breadcrumb-bar">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`/clients`}>
              {getLabel({
                id: "Clients",
                defaultValue: "Clients",
              })}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{client.name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
        <div style={{ width: layout.mainContentWidth }}>
          <ClientSummary client={client} withSpace={layout.sidebarWidth === 0} />
          {layout.sidebarWidth === 0 && <ClientSidebar client={client} splitLayout={windowWidth > 500} />}
          <Tabs
            className="client-main-tabs"
            activeKey={activeTab}
            onTabClick={(tabKey) => {
              history.push(`${basePath}?clientTab=${tabKey}`);
              setActiveTab(tabKey);
            }}
          >
            <Tabs.TabPane
              tab={<Typography.Text data-cy="projects-tab">{getSimpleLabel("Projects")}</Typography.Text>}
              key="projects"
              data-cy="projects"
            >
              {displayProjectsTable()}
            </Tabs.TabPane>
            <Tabs.TabPane tab={<Typography.Text data-cy="fees-tab">Fees</Typography.Text>} key="fees" data-cy="fees">
              <StandardFeesPage parent={client} parentType="client" />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<Typography.Text data-cy="addresses-tab">Addresses</Typography.Text>}
              key="addresses"
              data-cy="addresses"
            >
              <AddressList parent={client} queryName="updateClient" />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<Typography.Text data-cy="contacts-tab">Contacts</Typography.Text>}
              key="contacts"
              data-cy="contacts"
            >
              <ContactList parent={client} queryName="updateClient" client={client} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Reports" key="reports" data-cy="reports">
              <NonTaskReports client={client} parentType="CLIENT" />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <Typography.Text data-cy="attachments-tab">
                  <>
                    {getSimpleLabel("client-details-attachments-tab")}{" "}
                    <Explanation
                      title={
                        <>
                          The "{getSimpleLabel("client-details-attachments-tab")}" tab at the {getSimpleLabel("client")}{" "}
                          level is meant to be used for storing non-project work, such as contracts, admin documents and
                          so on. <br /> If a file with the same name gets uploaded multiple times, all previous versions
                          are still available, therefore nothing gets overwritten.
                        </>
                      }
                    />
                  </>
                </Typography.Text>
              }
              key="attachments"
            >
              <Attachments client={client} apiUser={apiUser} />
            </Tabs.TabPane>
            {organisationDetails.settings?.request?.usesRequests && (
              <Tabs.TabPane
                tab={
                  <Typography.Text data-cy="actions-tab">
                    Sharing{" "}
                    <Explanation title={`For now, the sharing aspect only relates to ${getSimpleLabel("requests")}`} />
                  </Typography.Text>
                }
                key="sharing"
                data-cy="sharing"
              >
                <SharingTab
                  apiUser={apiUser}
                  parent={client}
                  queryName="updateClient"
                  organisationDetails={organisationDetails}
                  users={users}
                />
              </Tabs.TabPane>
            )}
            <Tabs.TabPane
              tab={<Typography.Text data-cy="actions-tab">Actions</Typography.Text>}
              key="actions"
              data-cy="actions"
            >
              <ClientActions apiUser={apiUser} client={client} />
            </Tabs.TabPane>
          </Tabs>
        </div>
        {layout.sidebarWidth !== 0 && (
          <div style={{ width: layout.sidebarWidth, flexShrink: 0 }}>
            <ClientSidebar client={client} splitLayout={windowWidth > 500} organisationDetails={organisationDetails} />
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: ClientDetailsPage,
    subscriptions: ["organisationDetails", "client", "tasks", "users", "projects"],
  })
);
