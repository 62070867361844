import { useState } from "react";
import { Form, Modal, Button, Alert, TreeSelect } from "antd";
import cx from "classnames";
import _ from "lodash";

import { callGraphQLSimple } from "common/apiHelpers";
import { PERMISSION_GROUPS } from "common/permissions";

import "./AddPermissionsModal.scss";

const { TreeNode } = TreeSelect;

export default function AddPermissionsModal({ onClose, selectedParent, parentType = "user", queryName }) {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [selectedPermissions, setSelectedPermissions] = useState();

  async function onSubmit() {
    setIsLoading(true);
    setError();

    try {
      let newPermissions = _.uniq([...(selectedParent.permissions || []), ...selectedPermissions]);

      await callGraphQLSimple({
        message: `Could not add permissions to the ${parentType}`,
        queryName,
        variables: {
          input: {
            id: selectedParent.id,
            permissions: newPermissions,
          },
        },
      });
      onClose();
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  }

  const onChange = (permissions) => {
    setSelectedPermissions(permissions);
  };

  function renderTreeNodes(treeData) {
    return treeData?.map((item) => {
      const isAlreadyAdded = selectedParent?.permissions?.includes(item.id);

      if (item?.children) {
        return (
          <TreeNode
            title={item.label}
            value={item.id}
            selectable={item.children.length === 0}
            className={cx({
              "already-added-permission-container": isAlreadyAdded,
            })}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      } else {
        return (
          <TreeNode
            title={item.label}
            value={item.id}
            className={cx({
              "already-added-permission-container": isAlreadyAdded,
            })}
            {...item}
          ></TreeNode>
        );
      }
    });
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={`Add permissions to ${parentType}`}
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className={`add-permissions-modal`}
    >
      {error ? (
        <>
          <Alert message={error} type="error" showIcon />
          <br />
        </>
      ) : null}
      <Form {...layout} form={form} onFinish={onSubmit}>
        <TreeSelect
          showSearch
          style={{
            width: "100%",
            marginBottom: "1rem",
          }}
          value={selectedPermissions}
          dropdownClassName="permissions-tree-dropdown"
          dropdownStyle={{
            maxHeight: window.innerHeight < 800 ? 300 : 600,
            overflow: "auto",
          }}
          placeholder={`Select permissions to add to the ${parentType}`}
          allowClear
          multiple
          onChange={onChange}
          suffixIcon={null}
          switcherIcon={null}
          filterTreeNode={(inputValue, item) => {
            if (item.value.toLowerCase().includes(inputValue.toLowerCase())) {
              return true;
            }

            if (item.title.toLowerCase().includes(inputValue.toLowerCase())) {
              return true;
            }

            return false;
          }}
        >
          {renderTreeNodes(PERMISSION_GROUPS)}
        </TreeSelect>

        {error ? <Alert message={error} type="error" showIcon /> : null}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
