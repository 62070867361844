import { getSimpleLabel } from "common/labels";

import "./TimelineLegend.scss";

export default function TimelineLegend({ windowWidth }) {
  const TIMELINE_LEGEND_ITEMS = [
    {
      color: "default",
      shortLabel: "Default",
    },
    {
      color: "finished",
      shortLabel: "Finished",
    },
    {
      color: "overdue",
      longLabel: "Will be overdue as planned",
      shortLabel: "Overdue",
    },
    {
      color: "due-on-the-day",
      longLabel: "Due on the same day as planned",
      shortLabel: "Due when planned",
    },
    {
      color: "unconfirmed",
      shortLabel: `${getSimpleLabel("Unconfirmed")}`,
    },
  ];

  return (
    <div className="timeline-legend">
      {TIMELINE_LEGEND_ITEMS.map((item, index) => {
        let labelToDisplay = item.shortLabel;
        if (windowWidth >= 1300 && item.longLabel) {
          labelToDisplay = item.longLabel;
        }

        return (
          <div className="timeline-legend-item" key={index}>
            <div className={`timeline-legend-item-color ${item.color}`} />
            <div className="timeline-legend-item-label">{labelToDisplay}</div>
          </div>
        );
      })}
      <div className="timeline-legend-item">
        <div className={`timeline-legend-item-color under-review default`} />
        <div className={`timeline-legend-item-color under-review due-on-the-day`} />
        <div className={`timeline-legend-item-color under-review overdue`} />
        <div className="timeline-legend-item-label">Under review</div>
      </div>
    </div>
  );
}
