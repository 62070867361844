import moment from "moment";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";

export default function getEndDateForTimelineBlock({ timelineBlock, dateFormat = "YYYY-MM-DD" }) {
  if (!timelineBlock) {
    return "";
  }

  // Destructure the timelineBlock for easier access
  const { startDate, startHours, durationHours } = timelineBlock;

  // Calculate the total hours and then divide by 8 to get the number of days (including fractional days)
  const totalHours = startHours + durationHours;
  const totalDays = Math.ceil(totalHours / TIMELINE_DEFAULT_HOURS_IN_A_DAY);

  // Use moment.js to add the totalDays to the startDate
  const endDate = moment(startDate)
    .add(totalDays - 1, "days")
    .format(dateFormat);

  return endDate;
}
