function cubicBezier(p1x, p2x) {
  const A = 1 - 3 * p2x + 3 * p1x;
  const B = 3 * p2x - 6 * p1x;
  const C = 3 * p1x;

  const calcBezier = (t) => ((A * t + B) * t + C) * t;
  const getSlope = (t) => 3 * A * t * t + 2 * B * t + C;

  function getTForX(x) {
    let aGuessT = x;
    for (let i = 0; i < 4; ++i) {
      const currentSlope = getSlope(aGuessT);
      if (currentSlope === 0) return aGuessT;
      const currentX = calcBezier(aGuessT) - x;
      aGuessT -= currentX / currentSlope;
    }
    return aGuessT;
  }

  return (x) => calcBezier(getTForX(x));
}

export default function smoothScrollTo({ element, to, axis = "y", duration }) {
  let propertyToScroll = "scrollTop";
  if (axis.toLowerCase() === "x") {
    propertyToScroll = "scrollLeft";
  }

  const start = element[propertyToScroll];
  const change = to - start;
  const startTime = performance.now();

  const bezierEase = cubicBezier(0, 0.58);

  const animateScroll = (currentTime) => {
    const timeElapsed = currentTime - startTime;
    const progress = Math.min(timeElapsed / duration, 1);
    const easedProgress = bezierEase(progress);

    element.scrollLeft = start + change * easedProgress;

    if (timeElapsed < duration) {
      requestAnimationFrame(animateScroll);
    }
  };

  requestAnimationFrame(animateScroll);
}
