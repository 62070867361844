import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";

import withSubscriptions from "common/withSubscriptions";
import { PURCHASE_ORDER_STATUSES } from "common/constants";
import Lane from "Lane/Lane";
import LaneContainer from "LaneContainer/LaneContainer";
import PurchaseOrderItem from "./PurchaseOrderItem/PurchaseOrderItem";
import PurchaseOrderFilters from "./PurchaseOrderFilters/PurchaseOrderFilters";
import { downloadBase64, sanitiseCSVValue } from "common/helpers";
import { getFilteredPurchaseOrders } from "common/filterHelpers";
import { isAuthorised } from "common/permissions";
import { tableColumns } from "./tableColumns";

import LazyLoadList from "../LazyLoadList/LazyLoadList";

import "./PurchaseOrdersPage.scss";

export class PurchaseOrdersPage extends React.Component {
  state = {
    filter: {},
  };
  componentDidMount() {
    this.props.setBoxedLayout(false);
    this.props.setNoScroll(true);
    this.props.setBackground(false);

    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "PURCHASE_ORDERS",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
  }

  componentWillUnmount() {
    this.props.setBackground(true);
    this.props.setBoxedLayout(true);
    this.props.setNoScroll(false);
  }

  onDragEnd = () => {};

  displayLanes = (filteredPurchaseOrders) => {
    const { history, organisationDetails, purchaseOrders } = this.props;

    const viewType = "board";

    const laneElements = PURCHASE_ORDER_STATUSES.map((status) => {
      const items = filteredPurchaseOrders.filter((purchaseOrder) => purchaseOrder.status === status.value);
      const allItems = purchaseOrders.filter((purchaseOrder) => purchaseOrder.status === status.value);
      const totalForColumn = items.reduce((total, purchaseOrder) => total + purchaseOrder.subtotal, 0);
      let totalSuffix = "";
      if (isAuthorised(["PURCHASE_ORDERS.VIEW_COLUMN_TOTALS"])) {
        totalSuffix = ` - ${window.formatCurrency("GBP", totalForColumn)}`;
      }

      const lane = {
        label: `${status.label}${totalSuffix}`,
        items,
        status: status.label,
        itemCount: items.length,
        allItemCount: allItems.length,
      };

      return (
        <Lane lane={lane} key={lane.label} viewType={viewType}>
          <LazyLoadList
            list={items}
            refreshOnChange={viewType}
            item={(purchaseOrder, index) => {
              return (
                <PurchaseOrderItem
                  purchaseOrder={purchaseOrder}
                  key={purchaseOrder.id}
                  organisationDetails={organisationDetails}
                  onClick={() => history.push(`/purchase-orders/${purchaseOrder.id}`)}
                />
              );
            }}
          />
        </Lane>
      );
    });

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <LaneContainer viewType={viewType}>{laneElements}</LaneContainer>
      </DragDropContext>
    );
  };

  export = async () => {
    const { purchaseOrders, projects, suppliers } = this.props;
    const { filter } = this.state;

    const columns = [...tableColumns];

    let csvContent = columns.map((column) => column.title).join(",") + "\n";
    let filteredPurchaseOrders = getFilteredPurchaseOrders({
      purchaseOrders,
      filter,
      suppliers,
      projects,
    }).sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));

    filteredPurchaseOrders.forEach((purchaseOrder) => {
      purchaseOrder.lineItems.items.forEach((lineItem) => {
        csvContent +=
          columns
            .map((column) => {
              if (column.fieldFunction) {
                return sanitiseCSVValue(column.fieldFunction(lineItem, purchaseOrder));
              } else {
                return sanitiseCSVValue(lineItem[column.dataIndex]);
              }
            })
            .join(",") + "\n";
      });
    });

    let base64CSV = `data:text/csv;base64,${btoa(unescape(encodeURIComponent(csvContent)))}`;
    await downloadBase64({
      base64String: base64CSV,
      fileName: `purchaseOrders ${moment().format("DD-MM-YYYY")}.csv`,
    });
  };

  render() {
    const { purchaseOrders, suppliers, projects } = this.props;
    const { filter } = this.state;

    let filteredPurchaseOrders = getFilteredPurchaseOrders({
      purchaseOrders,
      filter,
      suppliers,
      projects,
    });

    return (
      <div className="purchase-orders-page">
        <PurchaseOrderFilters
          onChange={(filter) => this.setState({ filter })}
          includeCreatePurchaseOrder
          export={this.export}
          windowWidth={this.props.windowWidth}
          windowHeight={this.props.windowHeight}
        />

        {this.displayLanes(filteredPurchaseOrders)}
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: PurchaseOrdersPage,
    subscriptions: ["tasks", "projects", "clients", "purchaseOrders", "suppliers"],
  })
);
