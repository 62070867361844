import { Typography, Button, Modal, message } from "antd";
import {
  SendOutlined,
  DeleteOutlined,
  FilePdfOutlined,
  EyeOutlined,
  SyncOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";

import { isAuthorised } from "common/permissions";
import withSubscriptions from "common/withSubscriptions";
import { createInvoice } from "common/invoiceHelpers";
import { enhanceQuoteWithInvoicing } from "common/invoiceHelpers/sharedInvoiceHelpers";
import { getLabel, processIdForDisplay } from "common/helpers";
import { getFirstValidTemplateForType } from "common/templateHelpers";

import Switch from "Switch/Switch";
import Card from "Card/Card";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";

import "./QuoteActions.scss";

export function QuoteActions({
  quote,
  isPreviewEnabled,
  isDownloadingQuote,
  isSendingQuote,
  onPreviewSwitch,
  restoreQuote,
  archiveQuote,
  deleteQuote,
  downloadQuote,
  sendQuote,
  reloadQuote,
  showApprovedPdf,
  windowWidth,
  updateFees,
  onRequestReviewClick,
  organisationDetails,
  history,
  hasApprovedPdf,
  hasUploadedPreview,
}) {
  const enhancedQuote = enhanceQuoteWithInvoicing(quote);

  let uninvoicedAmount = enhancedQuote.subtotal - enhancedQuote.invoicedAmount;

  async function createInvoiceFromQuote() {
    Modal.confirm({
      className: "confirm-invoice-creation-from-quote-modal",
      title: "Confirm invoice creation",
      maskClosable: true,
      content: (
        <>
          Are you sure you want to create an invoice from {getLabel({ id: "quote", defaultValue: "quote" })}{" "}
          <b>{quote.title}</b>?
        </>
      ),
      onOk: async () => {
        const messageKey = "creating-invoice-from-quote";
        message.loading({ content: "Creating invoice...", key: messageKey, duration: 0 });
        try {
          let templateId;
          try {
            templateId = getFirstValidTemplateForType({ organisationDetails, type: "INVOICE" });
          } catch (e) {
            message.error({ content: `Failed to create invoice. Reason: ${e.message}`, key: messageKey });
            console.error(e);
            return;
          }

          const newInvoiceId = await createInvoice({
            quoteLineItemIds: quote.lineItems.items.map((x) => x.id),
            organisationId: quote.organisation,
            projectId: quote.projectId,
            clientId: quote.clientId,
            quoteIds: [quote.id],
            timesheetBlocksForQuoteId: quote.id,
            templateId,
          });
          let messageDuration = window.Cypress ? 0.5 : 3;
          message.success({
            content: (
              <Typography.Text onClick={() => history.push(`/invoices/${newInvoiceId}`)} style={{ cursor: "pointer" }}>
                Invoice <b>{processIdForDisplay(newInvoiceId)}</b> created. <b>Click here to go to it</b>
              </Typography.Text>
            ),
            key: messageKey,
            duration: messageDuration,
          });
          setTimeout(() => {
            message.destroy(messageKey);
          }, messageDuration * 1000 + 100);
          reloadQuote();
        } catch (e) {
          message.error({ content: "Failed to create invoice", key: messageKey });
          console.error(e);
          return;
        }
      },
    });
  }

  function displayPreviewToggle() {
    if (organisationDetails.settings?.quote?.disablePreview && !isAuthorised(["QUOTES.WRITE_AMOUNT"])) {
      return null;
    }
    if (!quote.reviewApprovedAt) {
      return (
        <Switch
          disabled={windowWidth < 1000}
          checked={isPreviewEnabled && windowWidth >= 1000}
          onChange={onPreviewSwitch}
          className="preview-switch"
          label="Display preview"
        />
      );
    }

    return (
      <Button type="primary" onClick={showApprovedPdf} disabled={!hasApprovedPdf}>
        Show approved version
      </Button>
    );
  }

  let remainingOrFull = "";

  let hasHourlyRateLineItems = quote.lineItems.items.some((lineItem) => lineItem.isHourly);
  let amountNote;
  if (uninvoicedAmount > 0) {
    amountNote = uninvoicedAmount === 0 ? undefined : window.formatCurrency("GBP", uninvoicedAmount);
    if (enhancedQuote.invoicingStatus === "PARTIALLY_INVOICED") {
      remainingOrFull = " the remaining";
    } else if (enhancedQuote.invoicingStatus !== "FULLY_INVOICED") {
      remainingOrFull = " the full";
    }
  } else {
    let someQuoteLineItemsAreHourlyAndNotFullyInvoiced = quote.lineItems.items.some(
      (quoteLineItem) => quoteLineItem.isHourly && !quoteLineItem.isHourlyFullyInvoiced
    );
    if (someQuoteLineItemsAreHourlyAndNotFullyInvoiced) {
      remainingOrFull = " the remaining";
    }
  }
  let hourlyRateNote = hasHourlyRateLineItems ? "hourly charges" : undefined;
  const extraNotes = [amountNote, hourlyRateNote].filter((x) => x).join(" + ");

  let createInvoiceLabel = `Create invoice${remainingOrFull ? " for" : ""} ${remainingOrFull} ${extraNotes}`;

  return (
    <div className="quote-actions-container">
      <Card className="quote-actions" withSpace>
        <div className="actions-container">
          <div className="left-buttons">
            {enhancedQuote.isArchived ? (
              <Button onClick={restoreQuote} type="primary">
                Restore {getLabel({ id: "quote", defaultValue: "quote" })}
              </Button>
            ) : (
              <Button icon={<DeleteOutlined />} onClick={archiveQuote}>
                Archive {getLabel({ id: "quote", defaultValue: "quote" })}
              </Button>
            )}

            {organisationDetails.settings?.quote?.usesDelete ? (
              <Button onClick={deleteQuote} icon={<DeleteOutlined />}>
                Delete {getLabel({ id: "quote", defaultValue: "quote" })}
              </Button>
            ) : null}

            {displayPreviewToggle()}
          </div>

          {/* {review && (
            <Switch
              // disabled={enhancedQuote.isArchived}
              checkedChildren="Comments visible"
              unCheckedChildren="Comments hidden"
              checked={areCommentsVisible}
              onChange={onCommentsSwitch}
              className="comments-switch"
            />
          )} */}

          <div className="download-and-send">
            <Button
              icon={<SyncOutlined />}
              type="primary"
              onClick={updateFees}
              className="update-fees-button"
              data-cy="update-fees-button"
              disabled={enhancedQuote.status === "ACCEPTED" || enhancedQuote.reviewStatus === "SUCCESS"}
            >
              Update fees
            </Button>

            {organisationDetails.settings?.invoice?.usesInvoices &&
              enhancedQuote.status === "ACCEPTED" &&
              enhancedQuote.invoicingStatus !== "FULLY_INVOICED" && (
                <ButtonWithPermissions
                  icon={<PlusCircleOutlined />}
                  type="primary"
                  disabled={quote.lineItems.items.length === 0}
                  onClick={createInvoiceFromQuote}
                  className="create-invoice-button"
                  data-cy="create-invoice-button"
                  permissions={["CREATE_INVOICE"]}
                >
                  {createInvoiceLabel}
                </ButtonWithPermissions>
              )}

            {!enhancedQuote.reviews?.items?.length && (
              <Button
                data-cy="request-review-button"
                disabled={enhancedQuote.isArchived}
                icon={<EyeOutlined />}
                onClick={onRequestReviewClick}
                type="dark"
              >
                Request review
              </Button>
            )}
            {(!organisationDetails.settings?.quote?.disablePreview || isAuthorised(["QUOTES.WRITE_AMOUNT"])) && (
              <>
                <Button
                  disabled={
                    enhancedQuote.isArchived || (quote.reviewApprovedAt && !hasApprovedPdf && !hasUploadedPreview)
                  }
                  icon={<FilePdfOutlined />}
                  type="primary"
                  loading={!hasApprovedPdf && (!hasUploadedPreview || isDownloadingQuote)}
                  onClick={downloadQuote}
                  className="download-button"
                  data-cy={
                    !hasApprovedPdf && (!hasUploadedPreview || isDownloadingQuote)
                      ? "download-quote-button-not-ready"
                      : "download-quote-button"
                  }
                >
                  {isDownloadingQuote ? "Downloading..." : "Download"}
                </Button>
                <Button
                  icon={<SendOutlined />}
                  type="primary"
                  loading={isSendingQuote}
                  onClick={sendQuote}
                  disabled={enhancedQuote.isArchived || (quote.reviewApprovedAt && !hasApprovedPdf)}
                  className="send-button"
                  data-cy="send-quote-button"
                >
                  {isSendingQuote ? "Sending..." : "Send"}
                </Button>
              </>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: QuoteActions,
    subscriptions: ["apiUser", "organisationDetails"],
  })
);
