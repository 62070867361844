import { useEffect } from "react";
import { Spin, Result } from "antd";
import { useGetSetState } from "react-use";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { callRest } from "common/apiHelpers";

import PublicIssuedTaskFilesModal from "./PublicIssuedTaskFilesModal/PublicIssuedTaskFilesModal";
import PublicUrlQRCode from "./PublicUrlQRCode/PublicUrlQRCode";
import RequestFormExternalReviewPage from "pages/RequestFormExternalReviewPage/RequestFormExternalReviewPage";
import Card from "Card/Card";

type Props = {
  history: RouteComponentProps["history"];
  match: RouteComponentProps["match"];
};

type State = {
  data: any | undefined;
  error: string | undefined;
};
// eslint-disable-next-line no-empty-pattern
export function PublicUrlPage({ history, match }: Props) {
  const urlId = match.params.urlId;
  const [getState, setState] = useGetSetState<State>({ data: undefined, error: undefined });

  useEffect(() => {
    fetchInstructions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchInstructions() {
    let instructions;
    try {
      instructions = await callRest({
        method: "GET",
        route: `/instructions-for-url?urlId=${urlId}`,
        includeCredentials: false,
        displayError: false,
      });
    } catch (e) {
      if (e?.response?.data?.error === "Malformed data found in database") {
        setState({
          error: "MALFORMED_DATA_FOUND_IN_DATABASE",
        });
      } else {
        setState({
          error: e?.response?.data?.error,
        });
      }
    }

    if (instructions) {
      if (instructions.protection === "PASSWORD") {
        console.log("password needed");
        const data = await callRest({
          method: "GET",
          route: `/instructions-for-url?urlId=${urlId}&password=qwerty`,
          includeCredentials: false,
        });

        setState({ data });
      } else if (instructions.redirectTo) {
        history.push(instructions.redirectTo);
      } else if (instructions.dataForUrl) {
        setState({ data: instructions.dataForUrl });
      }
    }
  }

  const state = getState();
  const data = state.data;

  if (state.error) {
    let errorContent: any = null;
    if (state.error === "MALFORMED_DATA_FOUND_IN_DATABASE") {
      errorContent = (
        <Result
          status="error"
          title="Invalid URL"
          subTitle={
            <>
              The data associated with this URL appears to be invalid. <br />
              Please contact support.
            </>
          }
        />
      );
    } else {
      errorContent = (
        <Result
          status="error"
          title="An error has occured"
          subTitle={
            <>
              {state.error}. <br />
              Please contact support.
            </>
          }
        />
      );
    }

    return (
      <div style={{ margin: "0 auto" }}>
        <Card>{errorContent}</Card>
      </div>
    );
  }

  if (!data) {
    return <Spin />;
  }

  switch (data.content.type) {
    case "TASK_FILES_SENT":
      return <PublicIssuedTaskFilesModal data={data} />;
    case "QR_CODE":
      return <PublicUrlQRCode data={data} />;
    case "INFORMATION_SUBMITTED":
      return <RequestFormExternalReviewPage data={data} />;

    default:
      return null;
  }
}

export default withRouter(PublicUrlPage);
