import { Select } from "antd";

import { getUserReadableCatLevel } from "common/helpers";
import { isAuthorised } from "common/permissions";

import InfoItem from "InfoItem/InfoItem";
import Input from "Input/Input";

export default function UsersAuthorityLevels({
  organisationDetails,
  userData,
  changeAttribute,
  canEditFinancialAuthority,
  apiUser,
}) {
  let canEditEngineeringAuthority = false;
  if (apiUser?.id === userData?.id && isAuthorised(["USERS.EDIT_OWN_ENGINEERING_AUTHORITY_LEVELS"])) {
    canEditEngineeringAuthority = true;
  } else if (apiUser?.id !== userData?.id && isAuthorised(["USERS.EDIT_OTHERS_ENGINEERING_AUTHORITY_LEVELS"])) {
    canEditEngineeringAuthority = true;
  }

  return (
    <>
      {organisationDetails.usesDesignAuthority && (
        <InfoItem
          inline
          label="Can design"
          value={
            <Select
              className="cat-level-select"
              defaultValue={userData.catLevelDesign}
              onChange={(value) => changeAttribute({ fieldName: "catLevelDesign", value })}
              allowClear={organisationDetails.settings?.task?.allowUseOfCatZero}
              placeholder="Not set"
              disabled={!canEditEngineeringAuthority}
            >
              <Select.Option key={0} value={0}>
                {getUserReadableCatLevel(organisationDetails, 0)}
              </Select.Option>
              <Select.Option key={1} value={1}>
                {getUserReadableCatLevel(organisationDetails, 1)}
              </Select.Option>
              <Select.Option key={2} value={2}>
                {getUserReadableCatLevel(organisationDetails, 2)}
              </Select.Option>
              <Select.Option key={3} value={3}>
                {getUserReadableCatLevel(organisationDetails, 3)}
              </Select.Option>
            </Select>
          }
        />
      )}

      {organisationDetails.usesReviewAuthority && (
        <InfoItem
          inline
          label="Can review"
          value={
            <Select
              className="cat-level-select"
              defaultValue={userData.catLevelIssue}
              onChange={(value) => changeAttribute({ fieldName: "catLevelIssue", value })}
              allowClear={organisationDetails.settings?.task?.allowUseOfCatZero}
              placeholder="Not set"
              disabled={!canEditEngineeringAuthority}
            >
              <Select.Option key={0} value={0}>
                {getUserReadableCatLevel(organisationDetails, 0)}
              </Select.Option>
              <Select.Option key={1} value={1}>
                {getUserReadableCatLevel(organisationDetails, 1)}
              </Select.Option>
              <Select.Option key={2} value={2}>
                {getUserReadableCatLevel(organisationDetails, 2)}
              </Select.Option>
              <Select.Option key={3} value={3}>
                {getUserReadableCatLevel(organisationDetails, 3)}
              </Select.Option>
            </Select>
          }
        />
      )}
      {organisationDetails.settings?.quote?.usesQuotes && (
        <InfoItem
          inline
          label="Quote self-review limit"
          value={
            <Input
              defaultValue={userData.quoteCreationLimit || 0}
              showBorder={canEditFinancialAuthority}
              disabled={!canEditFinancialAuthority}
              prefix="£"
              onChange={(value) =>
                changeAttribute({
                  fieldName: "quoteCreationLimit",
                  value: parseInt(value),
                })
              }
            />
          }
        />
      )}
      {organisationDetails.settings?.quote?.usesQuotes && (
        <InfoItem
          inline
          label="Quote review limit"
          value={
            <Input
              defaultValue={userData.quoteReviewLimit || 0}
              showBorder={canEditFinancialAuthority}
              disabled={!canEditFinancialAuthority}
              prefix="£"
              onChange={(value) =>
                changeAttribute({
                  fieldName: "quoteReviewLimit",
                  value: parseInt(value),
                })
              }
            />
          }
        />
      )}
      {organisationDetails.settings?.invoice?.usesInvoices && (
        <InfoItem
          inline
          label="Invoice self-review limit"
          value={
            <Input
              defaultValue={userData.invoiceCreationLimit || 0}
              showBorder={canEditFinancialAuthority}
              disabled={!canEditFinancialAuthority}
              prefix="£"
              onChange={(value) =>
                changeAttribute({
                  fieldName: "invoiceCreationLimit",
                  value: parseInt(value),
                })
              }
            />
          }
        />
      )}
      {organisationDetails.settings?.invoice?.usesInvoices && (
        <InfoItem
          inline
          label="Invoice review limit"
          value={
            <Input
              defaultValue={userData.invoiceReviewLimit || 0}
              showBorder={canEditFinancialAuthority}
              disabled={!canEditFinancialAuthority}
              prefix="£"
              onChange={(value) =>
                changeAttribute({
                  fieldName: "invoiceReviewLimit",
                  value: parseInt(value),
                })
              }
            />
          }
        />
      )}
      {organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders && (
        <InfoItem
          inline
          label="Purchase order self-review limit"
          value={
            <Input
              defaultValue={userData.purchaseOrderCreationLimit || 0}
              showBorder={canEditFinancialAuthority}
              disabled={!canEditFinancialAuthority}
              prefix="£"
              onChange={(value) =>
                changeAttribute({
                  fieldName: "purchaseOrderCreationLimit",
                  value: parseInt(value),
                })
              }
            />
          }
        />
      )}
      {organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders && (
        <InfoItem
          inline
          label="Purchase order review limit"
          value={
            <Input
              defaultValue={userData.purchaseOrderReviewLimit || 0}
              showBorder={canEditFinancialAuthority}
              disabled={!canEditFinancialAuthority}
              prefix="£"
              onChange={(value) =>
                changeAttribute({
                  fieldName: "purchaseOrderReviewLimit",
                  value: parseInt(value),
                })
              }
            />
          }
        />
      )}
    </>
  );
}
