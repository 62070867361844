import { useState } from "react";
import { Modal, Form, Button, Space, Select, message, notification } from "antd";

import withSubscriptions from "common/withSubscriptions";
import { callRest, callGraphQLSimple } from "common/apiHelpers";

import "./SendPublicUploadEmail.scss";
import { getSimpleLabel } from "common/labels";

export function SendPublicUploadEmail({ onClose, task, project, client, visible, generatePublicUploadUrl, apiUser }) {
  const [isLoading, setIsLoading] = useState(false);

  const projectHasClientContacts = project.clientContacts && project.clientContacts.length > 0;
  const projectContacts = client.contacts?.filter((contact) => project.clientContacts?.includes(contact.id));

  function displayErrorModal() {
    if (task) {
      Modal.error({
        title: "No contacts found",
        content: `The ${getSimpleLabel("project")} associated with this ${getSimpleLabel("task")} has no contacts.`,
        onOk: onClose,
      });
      return;
    }
    Modal.error({
      title: "No contacts found",
      content: `This ${getSimpleLabel("project")} has no contacts.`,
      onOk: onClose,
    });
  }

  async function onSubmit(clientContact) {
    setIsLoading(true);

    const fullContactDetails = client.contacts?.find((contact) => contact.id === clientContact.id);

    let publicUploadUrl;
    try {
      publicUploadUrl = generatePublicUploadUrl();
    } catch (e) {
      return;
    }

    try {
      await callRest({
        route: "/sendPublicUploadEmail",
        method: "POST",
        body: {
          to: fullContactDetails.email,
          senderDetails: {
            firstName: window.apiUser.firstName,
            lastName: window.apiUser.lastName,
          },
          receiverDetails: fullContactDetails,
          link: publicUploadUrl,
          taskId: task?.id,
          projectId: project?.id,
          taskTitle: task?.title,
          projectTitle: project?.title,
          organisation: task?.organisation || project?.organisation,
        },
        includeCredentials: false,
      });
      callGraphQLSimple({
        mutation: "createActivityItem",
        variables: {
          input: {
            parentId: project.id,
            content: JSON.stringify({
              type: "PUBLIC_UPLOAD_EMAIL_SENT",
              clientContactDetails: fullContactDetails,
            }),
            type: "LIFECYCLE_EVENT",
            organisation: project.organisation,
            author: apiUser.id,
          },
        },
      });
      message.success(`Email sent to ${fullContactDetails.email}`);
    } catch (e) {
      notification.error(`Failed to send email`);
      console.error("error sending email = ", e);
    }

    setIsLoading(false);
    onClose();
  }

  return (
    <>
      {projectHasClientContacts ? (
        <Modal
          title={`Send direct upload email to a ${getSimpleLabel("client")} contact`}
          open={visible}
          onOk={onSubmit}
          onCancel={onClose}
          footer={null}
          className="send-public-upload-email-modal"
        >
          <Form onFinish={onSubmit}>
            <Space direction="vertical" className="spread">
              <Form.Item label={`${getSimpleLabel("Client")} contact`} name="id">
                <Select
                  placeholder={`Choose a ${getSimpleLabel("client")} contact`}
                  className="client-contacts-dropdown"
                >
                  {projectContacts?.map((contact, i) => (
                    <Select.Option
                      key={i}
                      disabled={!contact.email}
                      style={!contact.email ? { opacity: 0.5 } : null}
                      value={contact.id}
                    >
                      {contact.id} - {contact.email || "no email assigned"}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <div className="submit-container">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  data-cy="send-public-upload-email-submit-button"
                >
                  Submit
                </Button>
              </div>
            </Space>
          </Form>
          <br />
        </Modal>
      ) : (
        displayErrorModal()
      )}
    </>
  );
}

export default withSubscriptions({
  Component: SendPublicUploadEmail,
  subscriptions: ["organisationDetails"],
});
