import { getUppercaseStatus, createTaskInApi } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";

export default async function createAdminTask({
  project = undefined,
  client = undefined,
  organisationDetails,
  tasks,
  apiUser,
  setState,
}: {
  project?: any;
  client?: any;
  organisationDetails: any;
  tasks: any[];
  apiUser: any;
  setState: any;
}) {
  let projectId;
  let taskId;
  let clientId;
  if (project) {
    projectId = project.id;
    clientId = project.clientId;
  } else if (client) {
    projectId = `${organisationDetails.id}-client_docs_${client.id}`;
    clientId = client.id;
  } else {
    projectId = `${organisationDetails.id}-org_docs`;
    clientId = organisationDetails.id;
  }

  taskId = `${projectId}-ADMIN`;

  let adminTaskWithoutRevisions = tasks.find((x) => x.id === taskId);
  let adminTaskId = adminTaskWithoutRevisions?.id;
  if (!adminTaskWithoutRevisions) {
    const newAdminTask = await createTaskInApi({
      title: "_Admin_Hidden_",
      initials: "ADMIN",
      taskId,
      projectId,
      clientId,
      apiUser,
      status: getUppercaseStatus(organisationDetails.taskStatuses[0].name) || "",
      isHidden: true,
    });
    adminTaskId = newAdminTask.id;
  }
  const adminTask = (
    await callGraphQLSimple({
      message: `Failed to fetch admin ${getSimpleLabel("task")} containing documents`,
      queryCustom: "getTaskSimple",
      variables: {
        id: adminTaskId,
      },
    })
  ).data.getTask;
  setState({ adminTask });
}
