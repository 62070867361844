import React, { Component } from "react";
import cx from "classnames";

import { Typography } from "antd";

import { CloudUploadOutlined } from "@ant-design/icons";

import "./DragAndDrop.scss";

const DEFAULT_FILES_TO_IGNORE = [
  ".DS_Store", // OSX indexing file
  "Thumbs.db", // Windows indexing file
];

// map of common (mostly media types) mime types to use when the browser does not supply the mime type
const EXTENSION_TO_MIME_TYPE_MAP = {
  avi: "video/avi",
  gif: "image/gif",
  ico: "image/x-icon",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  mkv: "video/x-matroska",
  mov: "video/quicktime",
  mp4: "video/mp4",
  pdf: "application/pdf",
  png: "image/png",
  zip: "application/zip",
};

class DragAndDrop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drag: false,
      dragCounter: 0,
    };

    this.dropRef = React.createRef();

    this.dragResetInterval = setInterval(this.resetDrag, 5000);
  }

  resetDrag = () => {
    this.setState({
      drag: false,
      dragCounter: 0,
    });
  };

  componentWillUnmount() {
    if (this.dragResetInterval) {
      clearInterval(this.dragResetInterval);
    }
  }

  ////////////////////////////////////////////////////////////

  shouldIgnoreFile = (file) => {
    // remove files whose names start with a dot
    if (file.name.indexOf(".") === 0) {
      return true;
    }

    return DEFAULT_FILES_TO_IGNORE.indexOf(file.name) >= 0;
  };

  copyString = (aString) => {
    return ` ${aString}`.slice(1);
  };

  traverseDirectory = (entry) => {
    const reader = entry.createReader();
    // Resolved when the entire directory is traversed
    return new Promise((resolveDirectory) => {
      const iterationAttempts = [];
      const errorHandler = () => {};
      const _this = this;
      function readEntries() {
        // According to the FileSystem API spec, readEntries() must be called until
        // it calls the callback with an empty array.
        reader.readEntries((batchEntries) => {
          if (!batchEntries.length) {
            // Done iterating this particular directory
            resolveDirectory(Promise.all(iterationAttempts));
          } else {
            // Add a list of promises for each directory entry.  If the entry is itself
            // a directory, then that promise won't resolve until it is fully traversed.
            iterationAttempts.push(
              Promise.all(
                batchEntries.map((batchEntry) => {
                  if (batchEntry.isDirectory) {
                    return _this.traverseDirectory(batchEntry);
                  }
                  return Promise.resolve(batchEntry);
                })
              )
            );
            // Try calling readEntries() again for the same dir, according to spec
            readEntries();
          }
        }, errorHandler);
      }
      // initial call to recursive entry reader function
      readEntries();
    });
  };

  // package the file in an object that includes the fullPath from the file entry
  // that would otherwise be lost
  packageFile = (file, entry) => {
    let fileTypeOverride = "";
    // handle some browsers sometimes missing mime types for dropped files
    const hasExtension = file.name && file.name.lastIndexOf(".") !== -1;
    if (hasExtension && !file.type) {
      const fileExtension = (file.name || "").split(".").pop();
      fileTypeOverride = EXTENSION_TO_MIME_TYPE_MAP[fileExtension];
    }
    return {
      fileObject: file, // provide access to the raw File object (required for uploading)
      fullPath: entry ? this.copyString(entry.fullPath) : file.name,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      name: file.name,
      size: file.size,
      type: file.type ? file.type : fileTypeOverride,
      webkitRelativePath: file.webkitRelativePath,
    };
  };

  getFile = (entry) => {
    return new Promise((resolve) => {
      entry.file((file) => {
        resolve(this.packageFile(file, entry));
      });
    });
  };

  handleFilePromises = (promises, fileList) => {
    return Promise.all(promises).then((files) => {
      files.forEach((file) => {
        if (!this.shouldIgnoreFile(file)) {
          fileList.push(file);
        }
      });
      return fileList;
    });
  };

  getDataTransferFiles = (dataTransfer) => {
    const dataTransferFiles = [];
    const folderPromises = [];
    const filePromises = [];

    [].slice.call(dataTransfer.items).forEach((listItem) => {
      if (typeof listItem.webkitGetAsEntry === "function") {
        const entry = listItem.webkitGetAsEntry();

        if (entry) {
          if (entry.isDirectory) {
            folderPromises.push(this.traverseDirectory(entry));
          } else {
            filePromises.push(this.getFile(entry));
          }
        }
      } else {
        dataTransferFiles.push(listItem);
      }
    });

    if (folderPromises.length) {
      const flatten = (array) => array.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

      return Promise.all(folderPromises).then((fileEntries) => {
        const flattenedEntries = flatten(fileEntries);
        // collect async promises to convert each fileEntry into a File object
        flattenedEntries.forEach((fileEntry) => {
          filePromises.push(this.getFile(fileEntry));
        });
        return this.handleFilePromises(filePromises, dataTransferFiles);
      });
    } else if (filePromises.length) {
      return this.handleFilePromises(filePromises, dataTransferFiles);
    }
    return Promise.resolve(dataTransferFiles);
  };

  /**
   * This function should be called from both the onDrop event from your drag/drop
   * dropzone as well as from the HTML5 file selector input field onChange event
   * handler.  Pass the event object from the triggered event into this function.
   * Supports mix of files and folders dropped via drag/drop.
   *
   * Returns: an array of File objects, that includes all files within folders
   *   and subfolders of the dropped/selected items.
   */
  getDroppedOrSelectedFiles = (event) => {
    const dataTransfer = event.dataTransfer;

    if (dataTransfer && dataTransfer.items) {
      return this.getDataTransferFiles(dataTransfer).then((fileList) => {
        return Promise.resolve(fileList);
      });
    }
    const files = [];
    const dragDropFileList = dataTransfer && dataTransfer.files;
    const inputFieldFileList = event.target && event.target.files;
    const fileList = dragDropFileList || inputFieldFileList || [];

    // convert the FileList to a simple array of File objects
    for (let i = 0; i < fileList.length; i++) {
      files.push(this.packageFile(fileList[i]));
    }
    return Promise.resolve(files);
  };

  onDrop = async (e) => {
    const files = await this.getDroppedOrSelectedFiles(e);
    this.setState({ files });

    this.props.onChange(e, files);
  };

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ dragCounter: this.state.dragCounter + 1 });
    // if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
    this.setState({ drag: true });
    // }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ dragCounter: this.state.dragCounter - 1 });
    // if (this.state.dragCounter === 0) {
    this.setState({ drag: false });
    // }
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ drag: false });
    if (e.dataTransfer) {
      this.onDrop(e);
      e.dataTransfer.clearData();
      this.setState({ dragCounter: 0 });
    }
  };
  componentDidMount() {
    let div = this.dropRef.current;
    if (!this.props.disabled) {
      div.addEventListener("dragenter", this.handleDragIn);
      div.addEventListener("dragleave", this.handleDragOut);
      div.addEventListener("dragover", this.handleDrag);
      div.addEventListener("drop", this.handleDrop);
    }
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }
  render() {
    return (
      <div
        className={cx("drag-and-drop", {
          dragging: this.state.drag || this.state.dragCounter > 0,
        })}
        ref={this.dropRef}
      >
        {this.props.children}
        <div className="overlay">
          <Typography.Text className="instructions">
            <CloudUploadOutlined />
            Drop files here to upload
          </Typography.Text>
        </div>
      </div>
    );
  }
}
export default DragAndDrop;
