import moment from "moment";
import { Typography } from "antd";

import withSubscriptions from "common/withSubscriptions";

// import { Draggable } from "react-beautiful-dnd";

import DashboardItemTags from "DashboardItemTags/DashboardItemTags";
import Card from "Card/Card";
import Avatar from "Avatar/Avatar";
import ClientLogo from "ClientLogo/ClientLogo";
import TaskIdTag from "TaskIdTag/TaskIdTag";

import "./InvoiceItem.scss";
import { getSimpleLabel } from "common/labels";

export function InvoiceItem({ invoice, projects, clients, users, onClick, organisationDetails }) {
  const project = projects.find((x) => x.id === invoice.projectId);
  const client = clients.find((x) => x.id === invoice.clientId);

  const userData = users.find((x) => x.id === invoice.assignedTo);

  let idTagProps = {};

  let month = moment(invoice.invoiceDate || invoice.createdAt).format("MMM YYYY");

  let invoiceItemElement = (
    <div
      className="invoice-item-container"
      data-cy="invoice-item-container"
      data-invoice-id={invoice.id}
      data-subtotal={window.formatCurrency("GBP", invoice.subtotal)}
    >
      <Card
        className="invoice-item"
        attributes={{
          "data-title": invoice.title,
          "data-invoice-id": invoice.id,
        }}
        onClick={() => {
          if (onClick && typeof onClick === "function") {
            onClick(invoice.id);
          }
        }}
        data-cy="invoice-item"
      >
        <Typography.Paragraph className="project-name">
          {project?.title || `(${getSimpleLabel("project")} not found)`}
        </Typography.Paragraph>
        <div className="invoice-initials-and-name">
          <Typography.Text level={4} className="invoice-name">
            {month}
          </Typography.Text>
        </div>
        <Typography.Text level={4} className="invoice-subtotal">
          {window.formatCurrency("GBP", invoice.subtotal)}
        </Typography.Text>
        {
          <DashboardItemTags
            item={{ ...invoice, client, project }}
            organisationDetails={organisationDetails}
            skipCatLevel={true}
            skipInstructed={true}
            skipCustomFields={true}
            isInvoice
          />
        }

        <div className="footer">
          <ClientLogo client={client} size="small" />

          <Typography.Paragraph className="task-id">
            <TaskIdTag task={{ ...invoice, client, project }} includeTitle={false} {...idTagProps} />
          </Typography.Paragraph>
          <Avatar user={userData} className="assigned-to-avatar" />
        </div>
      </Card>
    </div>
  );

  // if (!draggable) {
  return invoiceItemElement;
  // }

  // return (
  //   <Draggable key={id} draggableId={id} index={index} className={`draggable-task ${title.split(" ").join("_")}`}>
  //     {(provided) => {
  //       return (
  //         <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
  //           {taskItemElement}
  //         </div>
  //       );
  //     }}
  //   </Draggable>
  // );
}

export default withSubscriptions({
  Component: InvoiceItem,
  subscriptions: ["users", "projects", "clients"],
});
