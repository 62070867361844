import awsExports from "aws-exports";

// we can have more than one environment per account, e.g. one for dev, one for live
const ACCOUNT_GRAPHQL_APP_IDS = {
  EIS: ["j6q6ummvh5eknixzx4s2xmqhgm"],
};

export default function getAccountName() {
  for (let key in ACCOUNT_GRAPHQL_APP_IDS) {
    const orgInitials = key.toLowerCase();
    if (window.location.hostname.includes(`${orgInitials}-`) || window.location.hostname.includes(`${orgInitials}.`)) {
      return key;
    }
  }

  if (window.location.hostname.includes("localhost")) {
    // if we're on localhost, the graphql endpoint is a reliable way to check which account we're in
    for (let key in ACCOUNT_GRAPHQL_APP_IDS) {
      for (let id of ACCOUNT_GRAPHQL_APP_IDS[key]) {
        if (awsExports.aws_appsync_graphqlEndpoint.includes(id)) {
          return key;
        }
      }
    }
  }

  return "DEFAULT";
}
