import { Modal, Button, Typography, message } from "antd";
import { CloseIcon } from "common/icons";

import { signOutSpecificUser, switchToUser } from "common/authHelpers";

import Avatar from "Avatar/Avatar";

import "./LoggedInAccounts.scss";

type Props = {
  loggedInUsers: any[];
  alternateUsers: any[];
  cognitoIdentityKey: string;
};

export default function LoggedInAccounts({ loggedInUsers, alternateUsers, cognitoIdentityKey }: Props) {
  async function onAlternateAccountClick(e, user) {
    if (
      e.target.classList.contains("sign-out-of-alternate-account-button") ||
      e.target.tagName === "svg" ||
      e.target.tagName === "path"
    ) {
      return;
    }

    message.loading("Switching accounts...");

    await switchToUser({ cognitoUsername: user.Username, cognitoIdentityKey });

    window.location.href = window.location.origin;
  }

  function onSignOutClick(e, user) {
    Modal.confirm({
      title: `Are you sure you want to sign out of ${user.displayName}?`,
      okText: "Yes, sign out",
      onOk: () => {
        signOutSpecificUser({ cognitoUsername: user.Username, cognitoIdentityKey, user });
      },
    });
  }

  if (!loggedInUsers || !alternateUsers) {
    return null;
  }

  return (
    <div className="logged-in-accounts">
      {[...alternateUsers]
        .sort((a, b) => {
          if (a.displayName < b.displayName) {
            return -1;
          } else if (a.displayName > b.displayName) {
            return 1;
          } else {
            return 0;
          }
        })
        .map((user) => {
          const { userDetails, email, displayName } = user;

          return (
            <div
              onClick={(e) => {
                onAlternateAccountClick(e, user);
              }}
              className="alternative-user-item"
              key={user.Username}
            >
              {userDetails && <Avatar size="normal" user={userDetails} />}
              <div className="alternative-user-details">
                <Typography.Text className="user-name">{displayName}</Typography.Text>
                <Typography.Text className="user-email">{email}</Typography.Text>
              </div>
              <Button
                type="clear"
                icon={<CloseIcon />}
                className="sign-out-of-alternate-account-button"
                onClick={(e) => onSignOutClick(e, user)}
              />
            </div>
          );
        })}
    </div>
  );
}
