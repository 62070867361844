import moment from "moment";
import { processIdForDisplay, getReadableStatus, getUserReadableCatLevel } from "common/helpers";
import { isAuthorised } from "common/permissions";
import { formatCurrency } from "common/shared";

import BudgetBar from "BudgetBar/BudgetBar";
import Avatar from "Avatar/Avatar";
import { getSimpleLabel } from "common/labels";

export function getTableColumns(users) {
  return [
    {
      title: `${getSimpleLabel("Task")} ID`,
      width: 140,
      fieldFunction: ({ task }) => processIdForDisplay(task.id),
      sorter: {
        compare: (a, b) => (a.id < b.id ? -1 : 1),
      },
    },
    {
      title: `${getSimpleLabel("Task")} title`,
      dataIndex: "title",
      width: 200,
      sorter: {
        compare: (a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1),
      },
    },
    {
      title: `${getSimpleLabel("Client")} name`,
      fieldFunction: ({ task }) => task.client.name,
      dataIndex: "client",
      // sorter: {
      //   compare: (a, b) => (a.client.name.toLowerCase() < b.client.name.toLowerCase() ? -1 : 1),
      // },
    },
    {
      title: `${getSimpleLabel("Project")} title`,
      fieldFunction: ({ task }) => task.project.title,
      onFilter: (value, record) => record.name.includes(value),
      width: 200,
      // sorter: {
      //   compare: (a, b) => (a.project.title.toLowerCase() < b.project.title.toLowerCase() ? -1 : 1),
      // },
    },
    isAuthorised(["TASK.VIEW_BUDGET_BAR"]) && {
      title: "Budget used %",
      width: 120,
      fieldFunction: ({ task }) => {
        if (!task.budget && task.spent) {
          return "";
        }
        return Math.round((task.spent / task.budget) * 100);
      },
      sorter: {
        compare: (a, b) => {
          let aBudgetUsed = 0;
          let bBudgetUsed = 0;
          if (a.spent) {
            aBudgetUsed = Math.round((a.spent / a.budget) * 100);
          }
          if (b.spent) {
            bBudgetUsed = Math.round((b.spent / b.budget) * 100);
          }
          return aBudgetUsed < bBudgetUsed ? -1 : 1;
        },
      },
      render: (_, task) => {
        if (task.spent && !task.budget) {
          return <>∞</>;
        }

        return (
          <BudgetBar
            hideFinancials={window.organisationDetails.settings?.general?.hideFinancials}
            size="large"
            amountSpent={task.spent}
            amountBudget={task.budget}
            includePercentage
            includeLabel={false}
            includeBudgetUsedLabel={false}
          />
        );
      },
    },
    isAuthorised(["TASK.VIEW_BUDGET_BAR"]) && {
      title: "Spent",
      width: 120,
      fieldFunction: ({ task }) => {
        if (!task.spent) {
          return 0;
        }
        return task.spent;
      },
      sorter: {
        compare: (a, b) => ((a.spent || 0) < (b.spent || 0) ? -1 : 1),
      },
      render: (_, task) => {
        if (window.organisationDetails.settings?.quote?.quotesInHours) {
          return `${task.spent || 0} hour${task.spent === 1 ? "" : "s"}`;
        }

        return formatCurrency("GBP", task.spent);
      },
    },
    isAuthorised(["TASK.VIEW_BUDGET_BAR"]) && {
      title: "Budget",
      width: 120,
      fieldFunction: ({ task }) => {
        if (!task.budget) {
          return 0;
        }
        return task.budget;
      },
      sorter: {
        compare: (a, b) => ((a.budget || 0) < (b.budget || 0) ? -1 : 1),
      },
      render: (_, task) => {
        if (window.organisationDetails.settings?.quote?.quotesInHours) {
          return `${task.budget || 0} hour${task.budget === 1 ? "" : "s"}`;
        }
        return formatCurrency("GBP", task.budget);
      },
    },
    {
      title: "Assigned to",
      dataIndex: "assignedTo",
      width: 230,
      sorter: {
        compare: (a, b) => ((a.assignedTo || "").toLowerCase() < (b.assignedTo || "").toLowerCase() ? -1 : 1),
      },
      align: "left",
      render: (_, task) => {
        if (users) {
          return <Avatar user={users.find((user) => user.id === task.assignedTo)} showLabel />;
        } else {
          return task.assignedTo || "";
        }
      },
    },
    {
      title: "Reviewed by",
      dataIndex: "checkedBy",
      width: 230,
      sorter: {
        compare: (a, b) => ((a.checkedBy || "").toLowerCase() < (b.checkedBy || "").toLowerCase() ? -1 : 1),
      },
      align: "left",
      render: (_, task) => {
        if (users) {
          return <Avatar user={users.find((user) => user.id === task.checkedBy)} showLabel />;
        } else {
          return task.checkedBy || "";
        }
      },
    },
    {
      title: "Status",
      fieldFunction: ({ task }) => {
        const result = getReadableStatus(task.status);
        return result;
      },
      width: 140,
      sorter: {
        compare: (a, b) => ((a.status || "").toLowerCase() < (b.status || "").toLowerCase() ? -1 : 1),
      },
    },
    {
      title: "Due date",
      width: 110,
      fieldFunction: ({ task }) => (task.dueDate ? moment(task.dueDate).format("DD-MM-YYYY") : ""),
      sorter: {
        compare: (a, b) => ((a.dueDate || "") < (b.dueDate || "") ? -1 : 1),
      },
    },

    {
      title: "Finished at",
      fieldFunction: ({ task }) => (task.finishedAt ? moment(task.finishedAt).format("DD-MM-YYYY") : ""),
      width: 200,
      sorter: {
        compare: (a, b) => ((a.finishedAt || "") < (b.finishedAt || "") ? -1 : 1),
      },
    },

    {
      title: "Created at",
      fieldFunction: ({ task }) => (task.createdAt ? moment(task.createdAt).format("DD-MM-YYYY") : ""),
      width: 200,
      sorter: {
        compare: (a, b) => ((a.createdAt || "") < (b.createdAt || "") ? -1 : 1),
      },
    },
    {
      title: "Last updated at",
      fieldFunction: ({ task }) => (task.updatedAt ? moment(task.updatedAt).format("DD-MM-YYYY") : ""),
      width: 200,
      sorter: {
        compare: (a, b) => ((a.updatedAt || "") < (b.updatedAt || "") ? -1 : 1),
      },
    },

    { title: "Is finished", fieldFunction: ({ task }) => (task.isFinished ? "yes" : "no"), dataIndex: "isFinished" },
    { title: "Is archived", fieldFunction: ({ task }) => (task.isArchived ? "yes" : "no"), dataIndex: "isArchived" },
    {
      title: "Archived at",
      fieldFunction: ({ task }) => (task.archivedAt ? moment(task.archivedAt).format("DD-MM-YYYY") : ""),
      width: 200,
      sorter: {
        compare: (a, b) => ((a.archivedAt || "") < (b.archivedAt || "") ? -1 : 1),
      },
    },
    {
      title: "Under review",
      width: 90,
      fieldFunction: ({ task }) => (task.isUnderReview ? "yes" : "no"),
    },
    {
      title: "CAT level",
      fieldFunction: ({ task, organisationDetails }) => getUserReadableCatLevel(organisationDetails, task.catLevel),
    },
    {
      title: "Created by",
      width: 200,
      align: "left",
      sorter: {
        compare: (a, b) => ((a.author || "") < (b.author || "") ? -1 : 1),
      },

      render: (_, task) => {
        if (users) {
          return <Avatar user={users.find((user) => user.id === task.author)} showLabel />;
        } else {
          return task.author || "";
        }
      },
    },
  ].filter((x) => x);
}
