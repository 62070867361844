import { Component } from "react";
import { Tag, Select, Typography } from "antd";
import cx from "classnames";
import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";

import "./RequestPicker.scss";
import { processIdForDisplay } from "common/helpers";
/**
 * excludeList: [String] - represents a list of user IDs (email addresses) which should not be shown in the list of options
 **/
export class RequestPicker extends Component {
  compare = (expected, actual) => {
    return expected.toLowerCase().split(" ").join("").includes(actual.toLowerCase().split(" ").join(""));
  };

  filterOption = (targetText, { request, tasksForRequest, value }) => {
    try {
      if (value === null) {
        return true;
      }

      if (this.compare(request.title, targetText)) {
        return true;
      } else if (this.compare(request.id, targetText)) {
        return true;
      } else if (this.compare(request.assignedTo || "", targetText)) {
        return true;
      } else {
        for (const task of tasksForRequest) {
          if (this.compare(task.title, targetText)) {
            return true;
          } else if (this.compare(task.id, targetText)) {
            return true;
          } else if (this.compare(task.assignedTo || "", targetText)) {
            return true;
          }
        }
      }

      return false;
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  render() {
    const { excludeList, value, placeholder, suffixIcon, disabled, requests, tasks, allowClear = true } = this.props;

    let filteredRequests = requests
      .filter((x) => !x.isHidden && !x.id.includes("-TEMPLATES"))
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    if (excludeList) {
      filteredRequests = filteredRequests.filter((x) => !excludeList.includes(x.id));
    }

    const extraProps = {
      value,
    };

    if (this.props["data-cy"]) {
      extraProps["data-cy"] = this.props["data-cy"];
    }

    if (placeholder) {
      extraProps.placeholder = placeholder;
    } else {
      extraProps.placeholder = `Select ${getSimpleLabel("request")}`;
    }

    if (suffixIcon !== undefined) {
      extraProps.suffixIcon = suffixIcon;
    }

    return (
      <div className="task-picker">
        <Select
          showSearch
          className={cx("task-picker", this.props.className, {
            "active-on-hover": this.props.activateOnHover,
          })}
          filterOption={this.filterOption}
          onChange={this.props.onChange}
          disable={disabled}
          allowClear={allowClear}
          optionLabelProp="label"
          listHeight={450}
          {...extraProps}
        >
          {filteredRequests.map((request) => {
            const tasksForRequest = tasks.filter((task) => task.requestIds?.includes(request.id));
            return (
              <Select.Option
                value={request.id}
                key={`request-option-${request.id}`}
                className={cx("request-picker-option")}
                request={request}
                tasksForRequest={tasksForRequest}
                label={
                  <Typography.Text className="request-picker-option-label">
                    <Tag className="dark-tag">{processIdForDisplay(request.id)}</Tag>
                    <Typography.Text className="request-picker-option-label-request-title">
                      {request.title}
                    </Typography.Text>
                  </Typography.Text>
                }
              >
                <div className="request-picker-option-inner">
                  <Tag className="dark-tag">{processIdForDisplay(request.id)}</Tag>
                  <Typography.Text className="request-title">{request.title}</Typography.Text>
                </div>
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  }
}

export default withSubscriptions({
  Component: RequestPicker,
  subscriptions: ["tasks", "requests"],
});
