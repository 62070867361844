import { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { message } from "antd";
import moment from "moment";

import { callRest, callGraphQLSimple } from "common/apiHelpers";
import { Organisation, Integration } from "common/types";
import withSubscriptions from "common/withSubscriptions";

import "./QuickbooksCallbackPage.scss";

type Props = {
  organisationDetails: Organisation;
};

export function QuickbooksCallbackPage({ organisationDetails }: Props) {
  const [isQuickbooksAuthorised, setIsQuickbooksAuthorised] = useState(false);
  const [hasError, setHasError] = useState(false);

  const currentDate = moment().toISOString();

  useEffect(() => {
    sendTokenToApi().then(() => {
      setTimeout(() => {
        if (!hasError) {
          setIsQuickbooksAuthorised(true);
        }
      }, 1000);
    });
  }, []); // eslint-disable-line

  async function sendTokenToApi() {
    const messageKey = "quickbooks-callback";
    message.loading({
      content: "Connecting to QuickBooks...",
      key: messageKey,
      duration: 0,
    });
    try {
      const URLParams = new URLSearchParams(window.location.search);
      const quickbooksCode = URLParams.get("code");
      const realmId = URLParams.get("realmId");

      await callRest({
        route: "/quickbooks-callback",
        method: "POST",
        body: {
          organisationId: organisationDetails.id,
          code: quickbooksCode,
          realmId,
          url: window.location.href,
        },
        includeCredentials: false,
      });

      const isQuickbooksIntegrationEnabled = organisationDetails.integrations?.find(
        (integration: Integration) => integration?.id === "QUICKBOOKS"
      );

      if (isQuickbooksIntegrationEnabled) {
        await callGraphQLSimple({
          message: "Failed to authorise the Quickbooks integration",
          queryName: "updateOrganisation",
          variables: {
            input: {
              id: organisationDetails.id,
              integrations: [...(organisationDetails.integrations || [])].map((integration: Integration) => {
                if (integration?.id !== "QUICKBOOKS") {
                  return integration;
                }
                return {
                  ...integration,
                  authorisedAt: currentDate,
                };
              }),
            },
          },
        });

        await callGraphQLSimple({
          message: "Failed to update the accountancy organisation ID",
          queryName: "updateOrganisation",
          variables: {
            input: {
              id: organisationDetails.id,
              accountancyOrganisationId: realmId,
            },
          },
        });
      }

      message.success({
        content: "Quickbooks integration set up successfully",
        key: messageKey,
      });
    } catch (err: any) {
      setHasError(true);

      if (err.response.data) {
        message.error({
          content: <>Error connecting to Quickbooks. Reason: {err.response.data}</>,
          key: messageKey,
        });
      } else {
        message.error({
          content: "Error connecting to Quickbooks",
          key: messageKey,
        });
      }

      return <Redirect to={`/organisation/apps`} />;
    }
  }

  if (isQuickbooksAuthorised) {
    return <Redirect to={`/organisation/apps`} />;
  }
}

export default withRouter(
  withSubscriptions({
    Component: QuickbooksCallbackPage,
    subscriptions: ["organisationDetails"],
  })
);
