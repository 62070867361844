import { useState } from "react";
import { Typography, Dropdown, Menu } from "antd";
import { FieldTimeOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import { Draggable } from "react-beautiful-dnd";
import moment from "moment";

import { stringToColor, getReadableStatus, bringTaskIntoSprint, getLabel } from "common/helpers";
import withSubscriptions from "common/withSubscriptions";
import { truncateText } from "common/shared";

import DashboardItemTags from "DashboardItemTags/DashboardItemTags";
import TaskDetailsModal from "Modals/TaskDetailsModal/TaskDetailsModal";
import Avatar from "Avatar/Avatar";
import ClientLogo from "ClientLogo/ClientLogo";
import TaskIdTag from "TaskIdTag/TaskIdTag";
import SendToTargetSprintModal from "../SprintsPage/SendToTargetSprintModal/SendToTargetSprintModal";

import "./TaskListItem.scss";

export function TaskListItem(props) {
  const [isTaskDetailsModalVisible, setIsTaskDetailsModalVisible] = useState(false);
  const [isSendToTargetSprintModalVisible, setIsSendToTargetSprintModalVisible] = useState(false);

  const {
    history,
    index,
    dueDate,
    users,
    title,
    id,
    status,
    assignedTo,
    order,
    isExternalReference,
    card,
    includeClientLogo = true,
    includeProjectTitle = true,
    includeDueDate = true,
    draggable,
    sprints,
    tasks,
    projects,
    clients,
    projectId,
    clientId,
    isConfirmed,
    sprintId,
    organisationDetails,
    windowWidth,
    includeActions = true,
    revisions,
    taskRevisionId,
    openAsPreview = true,
  } = props;

  const project = projects.find((x) => x.id === projectId);
  const client = clients.find((x) => x.id === clientId);
  const assignedToDetails = users.find((x) => x.id === assignedTo);
  const activeSprint = sprints.find((x) => x.isActive);
  const taskRevision = taskRevisionId ? revisions?.items?.find((x) => x.id === taskRevisionId) : null;

  function displayProjectBadge() {
    return (
      <div
        className="project-badge"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          history.push(`/projects/${project.id}`);
        }}
        style={{
          backgroundColor: stringToColor({
            string: project.id,
            lightness: 45,
            saturation: 75,
          }),
        }}
      >
        {truncateText(project.title)}
      </div>
    );
  }

  let taskItemElement = (
    <Dropdown
      trigger={includeActions ? ["contextMenu"] : []}
      key={`dropdown-for-task-${id}`}
      overlayClassName="task-list-item-context-menu"
      overlay={
        <Menu>
          {activeSprint ? (
            <Menu.Item
              onClick={() => bringTaskIntoSprint({ taskId: id, sprints, tasks })}
              icon={<FieldTimeOutlined />}
              key="move-to-active-sprint"
            >
              Move to {getLabel({ organisationDetails, id: "active sprint", defaultValue: "active sprint" })}
            </Menu.Item>
          ) : null}
          {sprintId !== sprints[0].id && (
            <Menu.Item
              onClick={() => bringTaskIntoSprint({ taskId: id, sprints, tasks, sprintId: sprints[0].id })}
              icon={<FieldTimeOutlined />}
              key="move-to-backlog"
            >
              Move to {sprints[0].name}
            </Menu.Item>
          )}
          <Menu.Item
            onClick={() => setIsSendToTargetSprintModalVisible(true)}
            icon={<FieldTimeOutlined />}
            key="move-to-different-sprint"
          >
            Move to a different {getLabel({ organisationDetails, id: "sprint", defaultValue: "sprint" })}
          </Menu.Item>
          {isSendToTargetSprintModalVisible && (
            <SendToTargetSprintModal
              organisationDetails={organisationDetails}
              visible={isSendToTargetSprintModalVisible}
              onClose={() => setIsSendToTargetSprintModalVisible(false)}
              sprints={sprints}
              tasks={tasks}
              taskId={id}
            />
          )}
        </Menu>
      }
    >
      <div
        className={cx("task-list-item", {
          card,
          unconfirmed: !isConfirmed && organisationDetails.settings?.general?.usesTaskConfirmation,
        })}
        onClick={(e) => {
          e.stopPropagation();
          if (openAsPreview) {
            setIsTaskDetailsModalVisible(true);
          } else {
            history.push(`/tasks/${id}`);
          }
        }}
        data-cy="task-list-item"
      >
        {!isExternalReference && <TaskIdTag task={{ ...props, client, project }} includeTitle={false} />}
        <div className="title-and-tags">
          {windowWidth <= 1200 && displayProjectBadge()}
          <Typography.Text className="title">
            {title}
            {taskRevision ? ` - revision ${taskRevision.name}` : null}
            {window.isDebug ? <span style={{ marginLeft: 10, color: "#aaa" }}>{order}</span> : null}
          </Typography.Text>
          {!isExternalReference && <DashboardItemTags item={{ ...props, client, project }} includeLinks />}
        </div>
        {includeDueDate && dueDate && (
          <Typography.Text className="due-date">
            Due on <br />
            {moment(dueDate).format("DD MMM YYYY")}
          </Typography.Text>
        )}
        {windowWidth > 1200 && includeProjectTitle && displayProjectBadge()}

        {includeClientLogo && organisationDetails?.id !== "BYRNE" && (
          <div className="client-logo-container">
            <ClientLogo client={client} />
          </div>
        )}
        {!isExternalReference && (
          <>
            {organisationDetails?.id !== "BYRNE" && (
              <Typography.Text className="status">{getReadableStatus(status)}</Typography.Text>
            )}
            <Avatar
              user={assignedToDetails}
              showLabel={organisationDetails?.id === "BYRNE"}
              noWrap={organisationDetails?.id === "BYRNE"}
            />
          </>
        )}
      </div>
    </Dropdown>
  );

  let taskDetailsModal = !!isTaskDetailsModalVisible && (
    <TaskDetailsModal
      taskId={id}
      onClose={() => {
        setIsTaskDetailsModalVisible(false);
      }}
    />
  );

  if (!draggable) {
    return (
      <>
        {taskItemElement}
        {taskDetailsModal}
      </>
    );
  }

  return (
    <>
      <Draggable key={id} draggableId={id} index={index} className={`draggable-task ${title.split(" ").join("_")}`}>
        {(provided) => {
          return (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              {taskItemElement}
            </div>
          );
        }}
      </Draggable>
      {taskDetailsModal}
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TaskListItem,
    subscriptions: ["users", "sprints", "tasks", "clients", "projects"],
  })
);
