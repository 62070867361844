import { Checkbox } from "antd";
import Card from "Card/Card";
import { withRouter } from "react-router-dom";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";
import { THIRD_PARTY_APPS } from "common/thirdPartyApps";

import "./ThirdPartyAppList.scss";

export function ThirdPartyAppList(props) {
  const { organisationDetails } = props;

  const checkedSettings = organisationDetails.integrations?.map((integration) => integration.id);

  async function updateThirdPartyAppIntegrations({ organisationDetails, checked, integrationId }) {
    const isIntegrationEnabled = organisationDetails.integrations?.find(
      (integration) => integration.id === integrationId
    );

    if (isIntegrationEnabled && !checked) {
      await callGraphQLSimple({
        message: "Could not disable integration",
        queryName: "updateOrganisation",
        variables: {
          input: {
            id: organisationDetails.id,
            integrations: organisationDetails.integrations?.filter((integration) => integration.id !== integrationId),
          },
        },
      });
    }

    if (!isIntegrationEnabled && checked) {
      await callGraphQLSimple({
        message: "Could not enable integration",
        queryName: "updateOrganisation",
        variables: {
          input: {
            id: organisationDetails.id,
            integrations: [...(organisationDetails.integrations || []), { id: integrationId }],
          },
        },
      });
    }
  }

  return (
    <div className="third-party-apps-tab">
      <Card withSpace className="third-party-app-list" title="Choose third party apps">
        <Checkbox.Group defaultValue={checkedSettings}>
          {THIRD_PARTY_APPS.map((integration) => {
            const { NAME, DESCRIPTION, id } = integration;

            return (
              <Checkbox
                className="third-party-app-checkbox"
                value={id}
                key={id}
                onChange={async (e) => {
                  const checked = e.target.checked;
                  await updateThirdPartyAppIntegrations({ organisationDetails, checked, integrationId: id });
                }}
              >
                <span className="third-party-app-name">{NAME}</span>
                <br />
                <span className="third-party-app-description">{DESCRIPTION}</span>
              </Checkbox>
            );
          })}
        </Checkbox.Group>
      </Card>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: ThirdPartyAppList,
    subscriptions: ["organisationDetails"],
  })
);
