import React from "react";
import DC_Default from "./ClientQuoteDocuments/DC/QuoteDefault";
import AWD_Default from "./ClientQuoteDocuments/AWD/QuoteDefault";
import AEC_Default from "./ClientQuoteDocuments/AEC/QuoteDefault";
import DocumentOutput from "DocumentOutput/DocumentOutput";
import { getTemplateFromOrganisation } from "common/shared";

import "ReportPage/Report/Report.scss";

export function QuoteDocument(props) {
  const QUOTES = {
    DC: {
      default: DC_Default,
      DC_QUOTE_default: DC_Default,
    },
    AWD: {
      default: AWD_Default,
      AWD_QUOTE_default: AWD_Default,
    },
    AWD2: {
      default: AWD_Default,
      AWD2_QUOTE_default: AWD_Default,
    },
    AEC: {
      default: AEC_Default,
      AEC_QUOTE_default: AEC_Default,
    },
    AEC2: {
      default: AEC_Default,
      AEC2_QUOTE_default: AEC_Default,
    },
  };

  const templateDetails = getTemplateFromOrganisation({
    organisationDetails: props.organisationDetails,
    templateId: props.quote.templateId,
    fileType: "QUOTE",
  });

  let extraProps = {};

  let ReportComponent = null;
  if (templateDetails?.key) {
    ReportComponent = DocumentOutput;
    const { quote, formPreview, numberForPreviewRefresh } = props;
    const clientDetails = props.clients.find((client) => client.id === quote.clientId);
    const projectDetails = props.projects.find((project) => project.id === quote.projectId);

    extraProps = {
      project: projectDetails,
      client: clientDetails,
      clientContact: clientDetails.contacts?.find((x) => x.id === quote.clientContact),
      clientAddress: clientDetails.addresses?.find((x) => x.id === quote.clientAddress),
      previewData: formPreview,
      templateDetails: templateDetails,
      numberForRefresh: numberForPreviewRefresh,
    };
  } else {
    let organisationName = "AEC";
    if (QUOTES.hasOwnProperty(props.organisationDetails.id)) {
      organisationName = props.organisationDetails.id;
    }

    let defaultTemplatesForOrganisation = QUOTES[organisationName];

    ReportComponent = defaultTemplatesForOrganisation[props.quote.templateId || "default"];
  }

  return <ReportComponent {...extraProps} {...props} templateDetails={templateDetails} />;
}

export default React.memo(QuoteDocument);
