import moment from "moment";

import { KEY_TYPES } from "../shared";

import { getFullDetailsForNaming } from "./namingHelpers";

export async function getFileName({ data, type }) {
  // log({
  //   level: "DEBUG",
  //   message: "START",
  //   method: "AWD:getFileName",
  //   details: {
  //     data,
  //     type,
  //   },
  // });

  if (data.fileType.toUpperCase() === "QUOTE") {
    return data.quoteId;
  }

  if (data.fileType.toUpperCase() === "INVOICE") {
    return data.invoiceId;
  }

  if (data.fileType.toUpperCase() === "PURCHASE_ORDER") {
    return data.purchaseOrderId;
  }

  let { fileTypeCount, taskIdWithoutOrg, task, file } = await getFullDetailsForNaming(data, type);

  if (type === KEY_TYPES.SHEET_REFERENCE) {
    return taskIdWithoutOrg;
  }

  let sheet;
  let fileNumber;

  if (type === KEY_TYPES.FILE_SHEET_EXPORT || type === KEY_TYPES.FILE_SHEET_EXPORT_RAW) {
    sheet = file.sheets.items.find((x) => x.name === data.sheetName);
  }

  let suffix = "";

  switch (data.fileType.toUpperCase()) {
    case "AUTOCAD":
    case "REVIT":
    case "BRICSCAD":
      suffix += `${file.name}`;
      if (sheet) {
        taskIdWithoutOrg += `-${sheet.description}XxX`;
      }
      break;
    case "MATHCAD":
    case "EXCEL":
    case "WORD":
    case "BLUEBEAM":
      fileNumber = String(1000 + fileTypeCount).substring(1);
      taskIdWithoutOrg += `-${fileNumber}`;
      if (type.toUpperCase().includes("EXPORT")) {
        taskIdWithoutOrg += "XxX";
      }
      suffix += `${task.client?.name} ${task.project?.title} ${task.title}`;
      break;
    case "REPORT":
    case "APP_PAGE":
      fileNumber = String(1000 + fileTypeCount).substring(1);
      taskIdWithoutOrg += `-${fileNumber}XxX`;
      suffix += `${task?.client?.name} ${task?.project?.title} ${task?.title}`;
      break;
    case "POWERPOINT":
      fileNumber = "SK" + String(200 + fileTypeCount);
      taskIdWithoutOrg += `-${fileNumber}XxX`;
      suffix += `${task.client?.name} ${task.project?.title} ${task.title}`;
      break;
    case "DESIGNERS RISK ASSESSMENT":
      suffix = `DRA ${data.taskRevisionName}`;
      break;
    default:
      throw new Error(`Unknown file type:`, data.fileType.toUpperCase());
  }

  let result = `${taskIdWithoutOrg} ${suffix}`;

  if (type?.includes("SHEET") && data.sheetName) {
    result += ` ${data.sheetName}`;
  }

  // log({
  //   level: "DEBUG",
  //   message: "END",
  //   method: "AWD:getFileName",
  //   details: {
  //     result,
  //   },
  // });
  return result;
}

async function changeFileNameAtDownloadTime(data) {
  let { fileName, sheetRevisionName, file, type, task, invoice, projects, clients } = data;
  if (type === KEY_TYPES.INVOICE) {
    let date = moment(invoice.createdAt);

    const projectDetails = projects.find((x) => x.id === invoice.projectId);
    const clientDetails = clients.find((x) => x.id === invoice.clientId);

    return `${clientDetails?.name} ${projectDetails?.title} ${date.format("MMM YYYY")} ${invoice.id}.pdf`;
  }

  if (file?.type === "AUTOCAD") {
    let fileNameParts = fileName.split(" ");
    fileNameParts.splice(1, 1, `${task.client?.name} ${task.project?.title} ${task.title}`);
    fileName = fileNameParts.join(" ");
  }

  switch (type) {
    case KEY_TYPES.FILE_MAIN_EXPORT:
    case KEY_TYPES.FILE_MAIN_EXPORT_RAW:
      if (fileName.includes("XxX")) {
        return fileName.split("XxX").join(sheetRevisionName || "");
      }
      return fileName;

    case KEY_TYPES.FILE_SHEET_EXPORT:
    case KEY_TYPES.FILE_SHEET_EXPORT_RAW:
      return fileName.split("XxX").join(sheetRevisionName);

    default:
      return fileName;
  }
  // return result;
}

function changeSheetReference({ sheet, file, referenceNumber }) {
  return `${referenceNumber}-${sheet.description}`;
}

function getProjectId({ organisation, extraOffset }) {
  const yearNumber = parseInt(moment().format("YY"));
  const projectNumber =
    yearNumber * 1000 +
    parseInt(organisation.projectIdOffset || 0) +
    parseInt(organisation.projectCount || 0) +
    1 +
    parseInt(extraOffset);
  return `${organisation.id}-${projectNumber}`;
}

async function getTaskId({ organisation, projectDetails, extraOffset }) {
  const taskNumber = parseInt(projectDetails.taskCount || 0) + 1 + parseInt(extraOffset);
  let taskNumberAsString = taskNumber >= 10 ? `${taskNumber}` : `0${taskNumber}`;
  return `${projectDetails.id}-${taskNumberAsString}`;
}

function getQuoteId({ organisation, projectDetails, extraOffset }) {
  const quoteNumber = parseInt(projectDetails.quoteCount || 0) + 1 + parseInt(extraOffset);
  let quoteNumberAsString = quoteNumber >= 10 ? `${quoteNumber}` : `0${quoteNumber}`;
  return `${projectDetails.id}-Q${quoteNumberAsString}`;
}

function getInvoiceId({ organisation, projectDetails, extraOffset }) {
  const invoiceNumber = parseInt(projectDetails.invoiceCount || 0) + 1 + parseInt(extraOffset);
  let invoiceNumberAsString = invoiceNumber >= 10 ? `${invoiceNumber}` : `0${invoiceNumber}`;
  return `${projectDetails.id}-INV${invoiceNumberAsString}`;
}

function getPurchaseOrderId({ organisation, projectDetails, extraOffset }) {
  const poNumber = parseInt(projectDetails.purchaseOrderCount || 0) + 1 + parseInt(extraOffset);
  return `${projectDetails.id}-PO${poNumber}`;
}

function getSheetDescription({ task, file, taskRevision, sheetCount }) {
  const fileType = file.type;
  let sheetCountForFileType = sheetCount;
  const latestTaskRevision = taskRevision || task.revisions.items[task.revisions.items.length - 1];

  if (latestTaskRevision && latestTaskRevision.files && latestTaskRevision.files.items) {
    latestTaskRevision.files.items.forEach((crtFile) => {
      if (crtFile.type !== fileType || crtFile.isArchived) {
        return;
      }

      sheetCountForFileType += crtFile.sheets.items.filter((x) => x.includeInPublish).length;
    });
  }

  if (file.type === "EXCEL") {
    return String(1001 + sheetCountForFileType).substring(1);
  }
  return String(101 + sheetCountForFileType);
}

function getTaskRevisionName({ task }) {
  const possibleNames = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  if (!task.revisions || !task.revisions.items || task.revisions.items.length === 0) {
    return possibleNames[0];
  }
  const nonArchivedRevisions = task.revisions.items.filter((x) => !x.isArchived);
  const latestTaskRevision = nonArchivedRevisions[nonArchivedRevisions.length - 1];

  let nameIndexOfLatestTaskRevision = possibleNames.findIndex((x) => x === latestTaskRevision.name);
  if (nameIndexOfLatestTaskRevision === -1) {
    return null;
  }

  let nextName = null;
  while (!nextName && nameIndexOfLatestTaskRevision < possibleNames.length - 1) {
    nextName = possibleNames[nameIndexOfLatestTaskRevision + 1];
    // eslint-disable-next-line
    if (nonArchivedRevisions.find((x) => x.name === nextName)) {
      nextName = null;
      nameIndexOfLatestTaskRevision++;
    }
  }

  return nextName;
}

function getSheetRevisionName({ sheet }) {
  const possibleNames = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  if (!sheet || !sheet.revisions || !sheet.revisions.items || sheet.revisions.items.length === 0) {
    return possibleNames[0];
  }
  const nonArchivedRevisions = sheet.revisions.items.filter((x) => !x.isArchived);
  const latestSheetRevision = nonArchivedRevisions[nonArchivedRevisions.length - 1];

  const nameIndexOfLatestSheetRevision = possibleNames.findIndex((x) => x === latestSheetRevision.name);
  if (nameIndexOfLatestSheetRevision === -1) {
    return null;
  }
  return possibleNames[nameIndexOfLatestSheetRevision + 1];
}

async function getFrontendFileName({ organisationDetails, task, taskRevision, templateId, fileType }) {
  const filesOfType = taskRevision.files.items.filter((x) => x.type === fileType);
  let fileTypeCount = filesOfType.length;
  const deletedFilesByType = JSON.parse(taskRevision.deletedFilesByType || "{}");
  if (deletedFilesByType[fileType]) {
    fileTypeCount += deletedFilesByType[fileType];
  }
  let result = String(1000 + fileTypeCount + 1).substring(1);
  return result;
}

async function getTimelineBlockColor({ task, timelineBlock }) {
  if (timelineBlock.taskId === "HOLIDAY") {
    return "#000000"; // orange
  }

  if (timelineBlock.taskId === "SICK DAY") {
    return "#02700e"; // forest green
  }

  if (task?.titleLowerCase.includes("university") || timelineBlock.taskId?.toLowerCase().includes("university")) {
    return "#fc79aa"; // hot pink
  }
}

function isTimelineBlockFixed({ timelineBlock, task }) {
  if (task?.titleLowerCase?.includes("site visit") || timelineBlock.taskId === "SITE VISIT") {
    return true;
  }

  return false;
}

const functions = {
  getFileName,
  changeFileNameAtDownloadTime,
  changeSheetReference,
  getProjectId,
  getTaskId,
  getQuoteId,
  getInvoiceId,
  getPurchaseOrderId,
  getSheetDescription,
  getTaskRevisionName,
  getSheetRevisionName,
  getFrontendFileName,
  getTimelineBlockColor,
  isTimelineBlockFixed,
};

export default functions;
