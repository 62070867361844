import { useState } from "react";
import moment from "moment";
import { Popover, Modal, Typography, Select, InputNumber, Button, Tooltip } from "antd";
import { StarFilled, LeftOutlined, RightOutlined, PlusOutlined } from "@ant-design/icons";
import cx from "classnames";
import { withRouter, Link } from "react-router-dom";

import DatePicker from "DatePicker/DatePicker";
import { processIdForDisplay, getLabel } from "common/helpers";
import { getInvoicingStatusTag } from "common/invoiceHelpers/invoiceHelpers";
import { enhanceQuoteWithInvoicing } from "common/invoiceHelpers/sharedInvoiceHelpers";
import { getSimpleLabel } from "common/labels";
import { isAuthorised } from "common/permissions";

import Input from "Input/Input";
import Explanation from "Explanation/Explanation";
import ClientLogo from "ClientLogo/ClientLogo";
import Card from "Card/Card";
import Avatar from "Avatar/Avatar";
import UsersFilter from "UsersFilter/UsersFilter";
import { QUOTE_STATUSES, CURRENCIES } from "common/constants";
import ReviewTarget from "ReviewTarget/ReviewTarget";
import ClientContactModal from "Modals/ClientContactModal/ClientContactModal";
import AddressModal from "Modals/AddressModal/AddressModal";
import CreateQuoteRevisionModal from "Modals/CreateQuoteRevisionModal/CreateQuoteRevisionModal";
import TaskIdTag from "TaskIdTag/TaskIdTag";

import "./QuoteMetadata.scss";

export function QuoteMetadata(props) {
  const {
    activeStatusClassName,
    quote,
    organisationDetails,
    clientDetails,
    isCalculating,
    changeAttribute,
    debouncedChangeAttribute,
    users,
    clients,
    quotes,
    history,
    addLineItemChangeToQueue,
  } = props;
  const [isClientContactModalVisible, setIsClientContactModalVisible] = useState();
  const [isClientAddressModalVisible, setIsClientAddressModalVisible] = useState();
  const [isCreateQuoteRevisionModalVisible, setIsCreateQuoteRevisionModalVisible] = useState(false);

  const enhancedQuote = enhanceQuoteWithInvoicing(quote);

  const client = clients.find((client) => client.id === quote.clientId);

  let quotesInProject = quotes.filter((crtQuote) => crtQuote.projectId === quote.projectId && !crtQuote.isArchived);

  async function onStatusChange(newStatus) {
    if (newStatus === "REJECTED") {
      let rejectedQuoteLineItems = quote.lineItems.items.filter((item) => item.isRejected);
      if (rejectedQuoteLineItems.length) {
        try {
          await new Promise((resolve, reject) => {
            Modal.confirm({
              title: `Are you sure you want to reject this ${getSimpleLabel("quote")}?`,
              content: `There are ${rejectedQuoteLineItems.length} items that have already been marked as rejected. These will be returned to their normal state because the entire quote wil get marked as rejected.`,
              okText: "Yes",
              cancelText: "No",
              onOk: () => {
                resolve();
              },
              onCancel: () => {
                reject();
              },
            });
          });
        } catch (e) {
          // the user did not want to proceed
          return;
        }
      }
      await changeAttribute({ fieldName: "status", value: newStatus });
      for (let item of quote.lineItems.items) {
        if (!item.isRejected) {
          continue;
        }
        addLineItemChangeToQueue({
          fieldName: "isRejected",
          id: item.id,
          value: false,
          includeRecalculation: true,
        });
      }
    } else {
      await changeAttribute({ fieldName: "status", value: newStatus });
    }
  }

  async function goToPreviousQuote() {
    const quoteIndexInProject = quotesInProject.findIndex((x) => x.id === quote.id);
    const previousQuote = quotesInProject[quoteIndexInProject - 1];
    if (previousQuote) {
      history.push(`/quotes/${previousQuote.id}`);
    } else {
      history.push(`/quotes/${quotesInProject.slice(-1)[0].id}`);
    }
  }

  async function goToNextQuote() {
    const quoteIndexInProject = quotesInProject.findIndex((x) => x.id === quote.id);
    const nextQuote = quotesInProject[quoteIndexInProject + 1];
    if (nextQuote) {
      history.push(`/quotes/${nextQuote.id}`);
    } else {
      history.push(`/quotes/${quotesInProject[0].id}`);
    }
  }

  function getExcludedAssigneeList() {
    return [];
  }

  function isDisabled(quote) {
    if (quote.status === "ACCEPTED" || quote.reviewStatus === "SUCCESS" || quote.isArchived) {
      return true;
    }

    return false;
  }

  async function onClientContactModalSubmit(contactDetails) {
    await window.callGraphQLSimple({
      message: `Failed to add ${getSimpleLabel("client")} contact`,
      queryCustom: "updateClient",
      variables: {
        input: {
          id: client.id,
          contacts: [...(client.contacts || []), contactDetails],
        },
      },
    });

    setIsClientContactModalVisible(false);

    changeAttribute({
      fieldName: "clientContact",
      value: contactDetails.id,
    });
  }

  async function onClientAddressModalSubmit({ addressDetails }) {
    await window.callGraphQLSimple({
      message: "Failed to create address",
      queryCustom: "updateClient",
      variables: {
        input: {
          id: client.id,
          addresses: [...(client.addresses || []), addressDetails],
        },
      },
    });

    setIsClientAddressModalVisible(false);

    changeAttribute({
      fieldName: "clientAddress",
      value: addressDetails.id,
    });
  }

  function displayId() {
    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">
          {getLabel({ id: "Quote", defaultValue: "Quote" })} ID:
        </Typography.Paragraph>
        <Typography.Paragraph className="item-value item-value-static">
          {client.isPriority ? <StarFilled className="priority-marker" /> : null}
          {processIdForDisplay(quote.id)}
        </Typography.Paragraph>
      </div>
    );
  }

  function displayInvoicingStatus() {
    if (!organisationDetails.settings?.invoice?.usesInvoices) {
      return null;
    }

    let manuallyInvoicedAmount = enhancedQuote.lineItems.items.reduce((acc, item) => {
      return acc + (item.manuallyInvoicedAmount || 0);
    }, 0);

    let invoiceLineItemsWithDuplicates = enhancedQuote.lineItems.items.reduce((acc, item) => {
      return acc.concat(item.invoiceLineItems.items);
    }, []);

    let invoiceLineItems = invoiceLineItemsWithDuplicates.filter((item, index, self) => {
      return index === self.findIndex((x) => x.invoiceId === item.invoiceId);
    });

    let shouldShowInvoicingPopover = !!manuallyInvoicedAmount || invoiceLineItems.length > 0;

    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">Invoicing status:</Typography.Paragraph>
        <Typography.Paragraph className="item-value item-value-static" data-cy="overall-quote-invoicing-status">
          <Popover
            title={<Typography.Text>Invoices containing this {getSimpleLabel("quote")}</Typography.Text>}
            trigger={shouldShowInvoicingPopover ? "hover" : ""}
            content={
              <div className="invoices-containing-quote-line-item">
                {!!manuallyInvoicedAmount && (
                  <span className="quote-line-item-invoice-item">
                    <Typography.Text className="line-item-amount-invoiced">
                      {window.formatCurrency("GBP", manuallyInvoicedAmount)}
                    </Typography.Text>
                    <Typography.Text className="line-item-title" style={{ marginLeft: "0.5rem" }}>
                      Invoiced outside of DraughtHub
                    </Typography.Text>
                  </span>
                )}
                {invoiceLineItems.map((invoiceLineItem) => {
                  return (
                    <Link className="quote-line-item-invoice-item" to={`/invoices/${invoiceLineItem.invoiceId}`}>
                      <TaskIdTag
                        includeTitle={false}
                        task={{
                          id: invoiceLineItem.invoiceId,
                          client: quote.client,
                        }}
                        includeLink={false}
                      />
                      <Typography.Text className="line-item-amount-invoiced">
                        {window.formatCurrency("GBP", invoiceLineItem.amount)}
                      </Typography.Text>
                      <Typography.Text className="line-item-title" style={{ marginLeft: "0.5rem" }}>
                        {invoiceLineItem.title}
                      </Typography.Text>
                    </Link>
                  );
                })}
              </div>
            }
          >
            {getInvoicingStatusTag({
              invoicingStatus: enhancedQuote.invoicingStatus,
              total: enhancedQuote.total,
              invoicedAmount: enhancedQuote.invoicedAmount,
            })}
          </Popover>
        </Typography.Paragraph>
      </div>
    );
  }

  function displayProject() {
    return (
      <Link className="metadata-item clickable" to={`/projects/${quote.projectId}`}>
        <Typography.Paragraph className="item-label">
          {getLabel({ id: "Project", defaultValue: "Project" })}:
        </Typography.Paragraph>
        <Typography.Paragraph className="item-value">{quote.project.title}</Typography.Paragraph>
      </Link>
    );
  }

  function displayClient() {
    return (
      <Link className="metadata-item clickable" to={`/clients/${clientDetails.id}`}>
        <Typography.Paragraph className="item-label">
          {getLabel({
            id: "Client",
            defaultValue: "Client",
          })}
          :
        </Typography.Paragraph>
        <ClientLogo client={quote.client} size="small" />
      </Link>
    );
  }

  function displayAssignedTo() {
    const canSeeOthersQuotes = isAuthorised(["QUOTES.VIEW_OTHERS_QUOTES"]);
    let fieldIsDisabled = quote.isArchived || quote.status === "ACCEPTED";

    if (!canSeeOthersQuotes) {
      fieldIsDisabled = true;
    }
    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">Assigned to:</Typography.Paragraph>
        <Typography.Paragraph className="item-value">
          {fieldIsDisabled ? (
            <Avatar user={users.find((x) => x.id === quote.assignedTo)} showLabel={true} />
          ) : (
            <UsersFilter
              className="assigned-to-picker"
              activateOnHover={true}
              value={quote.assignedTo}
              onChange={(value) => changeAttribute({ fieldName: "assignedTo", value })}
              excludeList={getExcludedAssigneeList()}
              suffixIcon={null}
              maxLabelLength={22}
              data-cy="assigned-to-picker"
            />
          )}
        </Typography.Paragraph>
      </div>
    );
  }

  function displayStatus() {
    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">Status:</Typography.Paragraph>
        <Select
          disabled={quote.isArchived}
          onChange={(value) => {
            onStatusChange(value);
          }}
          value={quote.status}
          data-cy="quote-status-picker"
        >
          {QUOTE_STATUSES.map((status) => {
            const clientContactDetails = clientDetails?.contacts?.find((x) => x?.id === quote?.clientContact);
            let isDisabled = false;
            let label = status.label;

            if (status.value === "SENT" && !clientContactDetails) {
              isDisabled = true;
              label = `Sent - needs ${getSimpleLabel("client")} contact`;
            }

            return (
              <Select.Option
                key={status.value}
                value={status.value}
                disabled={isDisabled}
                style={isDisabled ? { opacity: "0.5" } : { opacity: 1 }}
              >
                {label}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  }

  function displayValidFrom() {
    if (organisationDetails.settings?.quote?.isValidFromHidden) {
      return null;
    }

    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">Valid from:</Typography.Paragraph>
        <DatePicker
          format="DD-MM-YYYY"
          data-cy="valid-from"
          defaultValue={quote.validFrom && moment(quote.validFrom)}
          disabled={isDisabled(quote)}
          onChange={(value) =>
            changeAttribute({
              fieldName: "validFrom",
              value: moment(value).startOf("day"),
            })
          }
        />
      </div>
    );
  }

  function displayValidUntil() {
    if (organisationDetails.settings?.quote?.isValidUntilHidden) {
      return null;
    }
    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">Valid until:</Typography.Paragraph>
        <DatePicker
          format="DD-MM-YYYY"
          data-cy="valid-until"
          defaultValue={quote.validUntil && moment(quote.validUntil)}
          disabled={isDisabled(quote)}
          onChange={(value) =>
            changeAttribute({
              fieldName: "validUntil",
              value: moment(value).endOf("day"),
            })
          }
        />
      </div>
    );
  }

  function displayClientAddress() {
    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">
          <Explanation
            title={`You can add a ${getSimpleLabel(
              "client"
            )} address to the quote. This is usually the physical address of the office which is supposed to receive the ${getSimpleLabel(
              "quote"
            )}. It only affects what is displayed on the ${getSimpleLabel("quote")} document.`}
          />{" "}
          {getSimpleLabel("Client")} address:
        </Typography.Paragraph>
        <ReviewTarget name="clientAddress" {...props} visible={quote.isUnderReview}>
          <Select
            value={quote.clientAddress}
            disabled={isDisabled(quote)}
            data-cy="client-address-dropdown"
            onChange={(value) => {
              if (value === "ADD_NEW") {
                setIsClientAddressModalVisible(true);
                return;
              }
              changeAttribute({
                fieldName: "clientAddress",
                value,
              });
            }}
            showSearch
            filterOption={(searchValue, { label }) => {
              return label?.toLowerCase().includes(searchValue?.toLowerCase());
            }}
          >
            <Select.Option key="add-new" value="ADD_NEW">
              <Typography.Text>
                <b>
                  <PlusOutlined /> Add new {getSimpleLabel("client")} address
                </b>
              </Typography.Text>
            </Select.Option>
            {(clientDetails.addresses || []).map((address) => {
              return (
                <Select.Option
                  key={address.id}
                  value={address.id}
                  label={`${address.id} ${address.houseName} ${address.streetNumber} ${address.streetName} ${address.city} ${address.county} ${address.country} ${address.postcode}`}
                >
                  {address.id}
                </Select.Option>
              );
            })}
          </Select>
        </ReviewTarget>
      </div>
    );
  }

  function displayClientContact() {
    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">
          <Explanation
            title={`You can add a ${getSimpleLabel("client")} contact to the ${getSimpleLabel(
              "quote"
            )}. Their details will be displayed on the ${getSimpleLabel(
              "quote"
            )}, as well as be used to know where to send the ${getSimpleLabel(
              "quote"
            )}. You can also choose to send the ${getSimpleLabel(
              "quote"
            )} to other people after clicking the "Send" button`}
          />{" "}
          {getSimpleLabel("Client")} contact:
        </Typography.Paragraph>
        <ReviewTarget name="client-contact" {...props} visible={quote.isUnderReview}>
          <Select
            data-cy="client-contact-dropdown"
            allowClear
            popupClassName="client-contact-dropdown"
            value={quote.clientContact}
            disabled={isDisabled(quote)}
            onChange={(value) => {
              if (value === "ADD_NEW") {
                setIsClientContactModalVisible(true);
                return;
              }
              changeAttribute({
                fieldName: "clientContact",
                value,
              });
            }}
            showSearch
            filterOption={(searchValue, { label }) => {
              return label?.toLowerCase().includes(searchValue?.toLowerCase());
            }}
          >
            <Select.Option key="add-new" value="ADD_NEW">
              <Typography.Text>
                <b>
                  <PlusOutlined /> Add new {getSimpleLabel("client")} contact
                </b>
              </Typography.Text>
            </Select.Option>
            {(clientDetails.contacts || []).map((contact, i) => {
              return (
                <Select.Option
                  key={i}
                  value={contact.id}
                  label={`${contact.firstName} ${contact.lastName} (${contact.id})`}
                >
                  {contact.firstName} {contact.lastName} ({contact.id})
                </Select.Option>
              );
            })}
          </Select>
        </ReviewTarget>
      </div>
    );
  }

  function displayCustomReference() {
    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">
          <Explanation
            title={
              "Sometimes, your clients might request that you display their specific reference number on the quote. If that happens, you can add it here. Otherwise, you can leave it blank, and the quote will display the reference number generated by DraughtHub."
            }
          />{" "}
          Custom reference:
        </Typography.Paragraph>
        <ReviewTarget name="quote-reference" {...props} visible={quote.isUnderReview}>
          <Input
            className="item-value"
            defaultValue={quote.reference}
            disabled={isDisabled(quote)}
            onChange={(value) => changeAttribute({ fieldName: "reference", value })}
            showBorder
            fullWidth
          />
        </ReviewTarget>
      </div>
    );
  }

  function displayCurrencyPicker() {
    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">Currency:</Typography.Paragraph>
        <Select
          data-cy="currency-picker"
          value={quote.currency}
          disabled={quote.status === "ACCEPTED" || quote.isArchived}
          onChange={(value) => changeAttribute({ fieldName: "currency", value })}
        >
          {CURRENCIES.map((currency) => {
            return (
              <Select.Option key={currency.value} value={currency.value}>
                {currency.value}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  }

  function displayTaxInclusive() {
    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">Amounts are:</Typography.Paragraph>
        <ReviewTarget name="taxInclusive" {...props} visible={quote.isUnderReview}>
          <Select
            disabled={isDisabled(quote)}
            value={quote.taxInclusive}
            data-cy="tax-dropdown"
            onChange={(value) => {
              changeAttribute({
                fieldName: "taxInclusive",
                value,
              });
            }}
          >
            <Select.Option key="true" value={true}>
              Tax Inclusive
            </Select.Option>
            <Select.Option key="false" value={false}>
              Tax Exclusive
            </Select.Option>
          </Select>
        </ReviewTarget>
      </div>
    );
  }

  function displayTaxRate() {
    if (organisationDetails.settings?.general?.hideFinancials) {
      return null;
    }

    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">Tax rate:</Typography.Paragraph>
        <ReviewTarget name="taxRate" {...props} visible={quote.isUnderReview}>
          <InputNumber
            min={0}
            disabled={isCalculating || isDisabled(quote)}
            defaultValue={quote.taxRate}
            onChange={(newValue) =>
              debouncedChangeAttribute({
                fieldName: "taxRate",
                value: newValue,
                includeRecalculation: true,
              })
            }
          />
        </ReviewTarget>
      </div>
    );
  }

  function displayPONumber() {
    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">
          <Explanation
            title={
              <>
                The PO number is only displayed on an invoice if either of the following is true:
                <ul>
                  <li>the invoice is created directly from this quote</li>
                  <li>a line item from this quote is added to the invoice</li>
                </ul>
                Note: you can also set a PO number at the{" "}
                {getLabel({
                  id: "project",
                  defaultValue: "project",
                })}{" "}
                level, which will be used for all invoices as a default
              </>
            }
            // placement="bottom"
          />{" "}
          PO number:
        </Typography.Paragraph>
        <ReviewTarget name="po-number" {...props} visible={quote.isUnderReview}>
          <Input
            className="item-value"
            defaultValue={quote.poNumber}
            disabled={quote.isArchived}
            onChange={(value) => changeAttribute({ fieldName: "poNumber", value })}
            showBorder
            fullWidth
          />
        </ReviewTarget>
      </div>
    );
  }

  function displayIsTenderWin() {
    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">
          <Explanation
            title={`This should be set to \"Yes\" when your ${getSimpleLabel(
              "client"
            )} is tendering for the overall project which encompasses the piece you're quoting for. Currently has no effect on anything, it's just to keep track of this information`}
          />{" "}
          Is tender win:
        </Typography.Paragraph>
        <ReviewTarget name="isTenderWin" {...props} visible={quote.isUnderReview}>
          <Select
            disabled={isDisabled(quote)}
            value={quote.isTenderWin}
            data-cy="tax-dropdown"
            defaultValue={false}
            onChange={(value) => {
              changeAttribute({
                fieldName: "isTenderWin",
                value,
              });
            }}
          >
            <Select.Option key="yes" value={true}>
              Yes
            </Select.Option>
            <Select.Option key="no" value={false}>
              No
            </Select.Option>
          </Select>
        </ReviewTarget>
      </div>
    );
  }

  function displayRevision() {
    return (
      <div className="metadata-item">
        <Typography.Paragraph className="item-label">
          Revision name: {quote.currentRevisionName || "(none)"}
        </Typography.Paragraph>
        <Typography.Paragraph className="item-value">
          <Button
            type="primary"
            onClick={() => {
              setIsCreateQuoteRevisionModalVisible(true);
            }}
          >
            Add revision
          </Button>
        </Typography.Paragraph>
      </div>
    );
  }

  return (
    <>
      <Card className={cx("metadata-card", activeStatusClassName)}>
        <div className="next-previous-buttons">
          <Tooltip
            title={`Go to previous ${getLabel({
              id: "quote",
              defaultValue: "quote",
            })} in project`}
            placement="topLeft"
          >
            <Button
              type="clear2"
              icon={<LeftOutlined />}
              className="button-previous"
              onClick={goToPreviousQuote}
              // disabled={!previousQuote}
            />
          </Tooltip>
          <Tooltip
            title={`Go to next ${getLabel({
              id: "quote",
              defaultValue: "quote",
            })} in project`}
            placement="topRight"
          >
            <Button
              type="clear2"
              icon={<RightOutlined />}
              className="button-next"
              onClick={goToNextQuote}
              // disabled={!nextQuote}
            />
          </Tooltip>
        </div>
        <div className="metadata-container">
          {displayId()}
          {displayInvoicingStatus()}
          {displayProject()}
          {displayClient()}
          {displayAssignedTo()}
          {displayStatus()}
          {displayValidFrom()}
          {displayValidUntil()}
          {displayClientAddress()}
          {displayClientContact()}
          {displayCustomReference()}
          {/* {displayCurrencyPicker()} */}
          {/* {displayTaxInclusive()} */}
          {displayTaxRate()}
          {displayPONumber()}
          {displayIsTenderWin()}
          {displayRevision()}
        </div>
      </Card>
      {isClientContactModalVisible && (
        <ClientContactModal
          onClose={() => setIsClientContactModalVisible(false)}
          onSubmit={onClientContactModalSubmit}
          parent={client}
        />
      )}
      {isClientAddressModalVisible && (
        <AddressModal onClose={() => setIsClientAddressModalVisible(false)} onSubmit={onClientAddressModalSubmit} />
      )}
      {isCreateQuoteRevisionModalVisible && (
        <CreateQuoteRevisionModal
          quote={quote}
          onClose={() => setIsCreateQuoteRevisionModalVisible(false)}
          onSubmit={() => setIsCreateQuoteRevisionModalVisible(false)}
        />
      )}
    </>
  );
}

export default withRouter(QuoteMetadata);
