import { getOrderedFieldKeys } from "ReportPage/Report/reportHelpers";
import { Input as AntInput, Select, Checkbox } from "antd";
import { callGraphQLSimple } from "common/apiHelpers";
import { isConditionMet } from "common/sharedTemplateRenderHelpers";

import ReviewTarget from "ReviewTarget/ReviewTarget";

export function getCustomLineItemFieldColumns() {
  const { quote } = this.props;
  const { form } = this.state;
  let lineItemFields = JSON.parse(JSON.stringify(form.fields || {}));
  for (let fieldKey in lineItemFields) {
    const fieldDetails = lineItemFields[fieldKey];
    if (!fieldDetails.isForLineItems) {
      delete lineItemFields[fieldKey];
    }
  }

  const orderedLineItemFieldKeys = getOrderedFieldKeys(lineItemFields);

  let orderedLineItemFieldElements = [];

  orderedLineItemFieldElements = orderedLineItemFieldKeys.map((fieldName) => {
    let renderFunction = null;
    let fieldData = lineItemFields[fieldName];
    let width = undefined;

    switch (fieldData.type) {
      case "dropdown":
        width = 150;
        renderFunction = ({ lineItem, index }) =>
          displayDropdown.call(this, { lineItem, index, fieldName, fieldData, quote });
        break;
      case "checkbox":
        width = 100;
        renderFunction = ({ lineItem, index }) =>
          displayCheckbox.call(this, { lineItem, index, fieldName, fieldData, quote });
        break;
      case "textarea":
        width = 170;
        renderFunction = ({ lineItem, index }) =>
          displayTextarea.call(this, { lineItem, index, fieldName, fieldData, quote });
        break;
      case "textfield":
        width = 170;
        renderFunction = ({ lineItem, index }) =>
          displayTextfield.call(this, { lineItem, index, fieldName, fieldData, quote });
        break;
      default:
        return null;
    }

    return {
      title: fieldData.label,
      render: (_, lineItem, index) => {
        if (fieldData.conditionalDisplayDataSourceField) {
          let isHidden = isCustomLineItemFieldHidden({ fieldData, lineItem });
          if (isHidden) {
            return "-";
          }
        }
        return renderFunction({ lineItem, index });
      },
      width,
    };
  });

  return orderedLineItemFieldElements;
}

function isCustomLineItemFieldHidden({ fieldData, lineItem }) {
  let lineItemCustomFields = lineItem.customFields || [];

  let targetField = lineItemCustomFields.find(
    (lineItemCustomField) => lineItemCustomField.id === fieldData.conditionalDisplayDataSourceField
  );

  if (!targetField) {
    targetField = { value: undefined };
  }

  let elementShouldBeDisplayed = isConditionMet({
    expectedValue: fieldData.conditionalDisplayTarget,
    realValue: targetField.value,
    condition: fieldData.conditionalDisplayCondition,
  });

  return !elementShouldBeDisplayed;
}

export async function changeLineItemCustomField({ fieldName, lineItem, value }) {
  const updatedLineItemCustomFields = JSON.parse(JSON.stringify(lineItem.customFields || []));
  let targetCustomFieldInLineItem = updatedLineItemCustomFields.find((field) => field.id === fieldName);
  if (!targetCustomFieldInLineItem) {
    targetCustomFieldInLineItem = { id: fieldName };
    updatedLineItemCustomFields.push(targetCustomFieldInLineItem);
  }

  targetCustomFieldInLineItem.value = value;

  console.log("targetCustomFieldInLineItem = ", targetCustomFieldInLineItem);

  await callGraphQLSimple({
    message: `Failed to update line item`,
    mutation: "updateQuoteLineItem",
    variables: {
      input: {
        id: lineItem.id,
        customFields: updatedLineItemCustomFields,
      },
    },
  });

  this.refreshQuote();
}

function displayDropdown({ lineItem, index, fieldName, fieldData, quote }) {
  let fieldValue = lineItem.customFields?.find((customField) => customField.id === fieldName)?.value;

  return (
    <ReviewTarget
      name={`quote-line-item-${fieldName}-${lineItem.id}`}
      lineItemIndex={index}
      {...this}
      {...this.props}
      visible={quote.isUnderReview}
    >
      <Select
        value={fieldValue}
        disabled={this.isDisabled(quote)}
        data-cy={`${fieldName}-dropdown`}
        onChange={(value) =>
          this.debouncedChangeLineItemCustomField({
            fieldData,
            fieldName,
            value: value ? value : null,
            lineItem,
          })
        }
        placeholder={fieldData.placeholder}
        allowClear
      >
        {fieldData.options.map((option) => {
          return (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          );
        })}
      </Select>
    </ReviewTarget>
  );
}

function displayCheckbox({ lineItem, index, fieldName, fieldData, quote }) {
  let fieldValue = lineItem.customFields?.find((customField) => customField.id === fieldName)?.value;

  return (
    <ReviewTarget
      name={`quote-line-item-${fieldName}-${lineItem.id}`}
      lineItemIndex={index}
      {...this}
      {...this.props}
      visible={quote.isUnderReview}
    >
      <Checkbox
        disabled={this.isDisabled(quote)}
        onChange={(e) => {
          let value = e.target.checked;
          this.debouncedChangeLineItemCustomField({
            fieldData,
            fieldName,
            value: value ? value : null,
            lineItem,
          });
        }}
        checked={fieldValue}
      />
    </ReviewTarget>
  );
}

function displayTextarea({ lineItem, index, fieldName, fieldData, quote }) {
  let fieldValue = lineItem.customFields?.find((customField) => customField.id === fieldName)?.value;

  return (
    <ReviewTarget
      name={`quote-line-item-${fieldName}-${lineItem.id}`}
      lineItemIndex={index}
      {...this}
      {...this.props}
      visible={quote.isUnderReview}
    >
      <AntInput.TextArea
        id={`quote-line-item-${fieldName}-${lineItem.id}`}
        disabled={this.isDisabled(quote)}
        onChange={(e) => {
          let value = e.target.value;
          this.debouncedChangeLineItemCustomField({
            fieldData,
            fieldName,
            value: value ? value : null,
            lineItem,
          });
        }}
        defaultValue={fieldValue}
      />
    </ReviewTarget>
  );
}

function displayTextfield({ lineItem, index, fieldName, fieldData, quote }) {
  let fieldValue = lineItem.customFields?.find((customField) => customField.id === fieldName)?.value;

  return (
    <ReviewTarget
      name={`quote-line-item-${fieldName}-${lineItem.id}`}
      lineItemIndex={index}
      {...this}
      {...this.props}
      visible={quote.isUnderReview}
    >
      <AntInput
        id={`quote-line-item-${fieldName}-${lineItem.id}`}
        disabled={this.isDisabled(quote)}
        onChange={(e) => {
          let value = e.target.value;
          this.debouncedChangeLineItemCustomField({
            fieldData,
            fieldName,
            value: value ? value : null,
            lineItem,
          });
        }}
        defaultValue={fieldValue}
      />
    </ReviewTarget>
  );
}
