import React from "react";
import FooterComponent from "./FooterComponent";

import "./Footer.scss";

export class Footer extends React.Component {
  render() {
    return (
      <FooterComponent
        columns={[
          {
            title: "Policies",
            items: [
              {
                title: "Cookie Policy",
                url: "/cookie-policy",
                description: "",
                openExternal: false,
              },
              {
                title: "Privacy Policy",
                url: "/privacy-policy",
                description: "",
                openExternal: false,
              },
              {
                title: "User Agreement",
                url: "/user-agreement",
                description: "",
                openExternal: false,
              },
            ],
          },
        ]}
        className="landing-footer"
        bottom="Made by GoDragons Ltd"
        bottomHRef="https://godragons.com"
        theme="dark"
      />
    );
  }
}

export default Footer;
