import { Modal, Button } from "antd";
import moment from "moment";
import prettyBytes from "pretty-bytes";
import { DownloadOutlined } from "@ant-design/icons";

import "./VersionsModal.scss";

type Props = {
  versions: any[];
  onClose: any;
  onVersionClick: any;
};

export default function VersionsModal({ versions, onVersionClick, onClose }: Props) {
  return (
    <Modal
      maskClosable={false}
      title="File versions"
      visible={true}
      footer={null}
      className="versions-modal"
      onCancel={onClose}
    >
      {versions.map((version) => {
        let readableDate = moment(version.LastModified).format("DD MMMM YYYY, HH:mm:ss");
        return (
          <div
            className="version-item"
            key={version.VersionId}
            onClick={() => onVersionClick({ ...version, key: version.Key, versionId: version.VersionId }, readableDate)}
          >
            <span className="date">{readableDate}</span>
            <span className="size">{prettyBytes(version.Size)}</span>

            {/* @ts-ignore */}
            <Button type="clear" icon={<DownloadOutlined />}></Button>
          </div>
        );
      })}
    </Modal>
  );
}
