import moment from "moment";

export const tableColumns = [
  {
    title: `User`,
    fieldFunction: (holiday, { users }) => {
      let user = users.find((x) => x.id === holiday.userId);
      if (!user) {
        return "";
      }

      return `${user.firstName || ""} ${user.lastName || ""}`.trim();
    },
  },
  {
    title: "Start date",
    fieldFunction: (holiday) => {
      return moment(holiday.startDate).format("DD-MM-YYYY");
    },
  },
  {
    title: "End date",
    fieldFunction: (holiday) => {
      return moment(holiday.endDate).format("DD-MM-YYYY");
    },
  },
  {
    title: "Number of days",
    fieldFunction: (holiday) => {
      return holiday.days?.length;
    },
  },
  {
    title: "Status",
    fieldFunction: (holiday) => {
      return holiday.status;
    },
  },
  {
    title: "Requested at",
    fieldFunction: (holiday) => {
      return holiday.createdAt ? moment(holiday.createdAt).format("DD-MM-YYYY HH:mm") : "";
    },
  },
  {
    title: "Number of hours",
    fieldFunction: (holiday) => {
      return (holiday.days || [])?.reduce((acc, day) => acc + ((day.endHours || 0) - (day.startHours || 0)), 0);
    },
  },
  {
    title: "Approved by",
    fieldFunction: (holiday, { users }) => {
      let userDetails = holiday.approvedBy ? users.find((x) => x.id === holiday.approvedBy) : null;

      if (!userDetails) {
        return "";
      }

      return `${userDetails.firstName || ""} ${userDetails.lastName || ""}`.trim();
    },
  },
  {
    title: "Approved at",
    fieldFunction: (holiday) => {
      return holiday.approvedAt ? moment(holiday.approvedAt).format("DD-MM-YYYY HH:mm") : "";
    },
  },
  {
    title: "Rejected by",
    fieldFunction: (holiday, { users }) => {
      let userDetails = holiday.approvedBy ? users.find((x) => x.id === holiday.rejectedBy) : null;

      if (!userDetails) {
        return "";
      }

      return `${userDetails.firstName || ""} ${userDetails.lastName || ""}`.trim();
    },
  },
  {
    title: "Rejected at",
    fieldFunction: (holiday) => {
      return holiday.rejectedAt ? moment(holiday.rejectedAt).format("DD-MM-YYYY HH:mm") : "";
    },
  },
  {
    title: "Message",
    fieldFunction: (holiday) => {
      return holiday.message;
    },
  },
];
