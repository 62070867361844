import { useState } from "react";
import { Modal, Button, Table, Typography } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";

import { callGraphQLSimple } from "common/apiHelpers";
import { isAuthorised } from "common/permissions";
import { findFullPermissionDetails, PERMISSION_GROUPS } from "common/permissions";

import InfoItem from "InfoItem/InfoItem";
import AddPermissionsModal from "Modals/AddPermissionsModal/AddPermissionsModal";

export default function UserPermissions({ setSelectedUser, userData, selectedUser }) {
  const [isAddPermissionsModalVisible, setIsAddPermissionsModalVisible] = useState(false);

  async function deleteUserPermission(user, permission) {
    Modal.confirm({
      title: "Confirm permission delete",
      content: (
        <>
          Are you sure you want to remove the permission <b>{permission?.label}</b> from{" "}
          <b>
            {user.firstName} {user.lastName}?
          </b>
        </>
      ),
      onOk: async () =>
        await callGraphQLSimple({
          message: "Could not delete the user permission",
          queryName: "updateUser",
          variables: {
            input: {
              id: user.id,
              permissions: user?.permissions?.filter((permissionId) => permissionId !== permission.id),
            },
          },
        }),
    });
  }

  return (
    <>
      <InfoItem
        value={
          <div className="permissions-table-wrapper">
            <div>
              {isAuthorised(["IAM.EDIT_PERMISSIONS"]) && (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsAddPermissionsModalVisible(true);
                    setSelectedUser(userData);
                  }}
                  className="add-user-permissions-button"
                  icon={<PlusCircleOutlined />}
                >
                  Add permissions
                </Button>
              )}
            </div>
            <Table
              className="permissions-table"
              pagination={{ hideOnSinglePage: true }}
              expandIconColumnIndex={-1}
              showHeader={false}
              columns={[
                {
                  key: "permission",
                  dataIndex: "label",
                  align: "left",
                  render: (_, row) => {
                    return <Typography.Text>{row?.label || row.id}</Typography.Text>;
                  },
                },
                {
                  align: "right",
                  render: (_, row) => {
                    return (
                      <>
                        {isAuthorised(["IAM.EDIT_PERMISSIONS"]) && (
                          <Button onClick={() => deleteUserPermission(userData, row)} icon={<DeleteOutlined />} />
                        )}
                      </>
                    );
                  },
                },
              ]}
              dataSource={userData.permissions?.map((permission) => {
                return {
                  ...(findFullPermissionDetails(permission, PERMISSION_GROUPS) || {}),
                  id: permission,
                };
              })}
            />
          </div>
        }
      />
      {isAddPermissionsModalVisible && selectedUser && (
        <AddPermissionsModal
          onClose={() => {
            setIsAddPermissionsModalVisible(false);
          }}
          selectedParent={selectedUser}
          queryName="updateUser"
          parentType={"user"}
        />
      )}
    </>
  );
}
