import { Checkbox, Typography } from "antd";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";
import { PURCHASE_ORDER_SETTINGS } from "common/settings";
import { getGraphQLInput } from "OrganisationPage/OrganisationSettingsPage/settingsHelpers";

import Card from "Card/Card";
import Input from "Input/Input";
import InfoItem from "InfoItem/InfoItem";

import "./PurchaseOrderSettingsPage.scss";

function PurchaseOrderSettingsPage({ organisationDetails }) {
  const checked = PURCHASE_ORDER_SETTINGS.filter((setting) => {
    return _.get(organisationDetails, setting.SCHEMA_FIELD);
  });

  const checkedSettings = checked.map((x) => x.SCHEMA_FIELD);

  const nonTextSettings = PURCHASE_ORDER_SETTINGS.filter((setting) => setting.INPUT_TYPE !== "text");
  const textSettings = PURCHASE_ORDER_SETTINGS.filter((setting) => setting.INPUT_TYPE === "text");

  return (
    <div className="purchase-orders-settings-page">
      <Card withSpace title="Purchase order settings">
        <Checkbox.Group defaultValue={checkedSettings}>
          {nonTextSettings.map((setting) => {
            const { NAME, DESCRIPTION, SCHEMA_FIELD } = setting;

            return (
              <Checkbox
                className="setting-checkbox"
                value={SCHEMA_FIELD}
                key={SCHEMA_FIELD}
                onChange={async (e) => {
                  const input = getGraphQLInput({
                    organisationDetails: { settings: organisationDetails.settings || {} },
                    checked: e.target.checked,
                    module: setting,
                  });
                  await callGraphQLSimple({
                    message: "Could not update setting",
                    queryName: "updateOrganisation",
                    variables: {
                      input: {
                        id: organisationDetails.id,
                        ...input,
                      },
                    },
                  });
                }}
              >
                <span className="setting-name">{NAME}</span>
                <br />
                <span className="setting-description">{DESCRIPTION}</span>
              </Checkbox>
            );
          })}
        </Checkbox.Group>
        {nonTextSettings?.length > 0 && textSettings?.length > 0 && <br />}
        {textSettings.map((setting) => {
          const { NAME, DESCRIPTION, SCHEMA_FIELD } = setting;
          return (
            <InfoItem
              key={SCHEMA_FIELD}
              label={NAME}
              value={<Typography.Text className="setting-description">{DESCRIPTION}</Typography.Text>}
              extraContent={
                <Input
                  defaultValue={organisationDetails.settings?.purchaseOrder[SCHEMA_FIELD]}
                  showBorder
                  fullWidth
                  onChange={async (value) => {
                    await callGraphQLSimple({
                      message: "Could not update purchase order setting",
                      queryName: "updateOrganisation",
                      variables: {
                        input: {
                          id: organisationDetails.id,
                          settings: {
                            ...organisationDetails?.settings,
                            purchaseOrder: {
                              ...(organisationDetails.settings?.purchaseOrder || {}),
                              [SCHEMA_FIELD]: value,
                            },
                          },
                        },
                      },
                    });
                  }}
                />
              }
            />
          );
        })}
      </Card>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: PurchaseOrderSettingsPage,
    subscriptions: ["organisationDetails"],
  })
);
