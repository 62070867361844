import { Typography } from "antd";
import "./TemplatePageHeader.scss";

export default function TemplatePageHeader({ page }) {
  return (
    <div className="template-page-header">
      <Typography.Text className="template-page-title">{page.custom_name}</Typography.Text>
    </div>
  );
}
