import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";

import withSubscriptions from "common/withSubscriptions";
import { QUOTE_STATUSES } from "common/constants";
import { isAuthorised } from "common/permissions";
import { downloadBase64, sanitiseCSVValue } from "common/helpers";
import { getFilteredQuotes } from "common/filterHelpers";
import { tableColumns } from "./tableColumns";

import Lane from "Lane/Lane";
import LaneContainer from "LaneContainer/LaneContainer";
import QuoteItem from "./QuoteItem/QuoteItem";
import QuoteFilters from "./QuoteFilters/QuoteFilters";
import LazyLoadList from "../LazyLoadList/LazyLoadList";

import "./QuotesPage.scss";

export class QuotesPage extends React.Component {
  state = {
    isCreateQuoteModalVisible: false,
    filter: {},
  };
  componentDidMount() {
    this.props.setBoxedLayout(false);
    this.props.setNoScroll(true);
    this.props.setBackground(false);

    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "QUOTES",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
  }

  componentWillUnmount() {
    this.props.setBackground(true);
    this.props.setBoxedLayout(true);
    this.props.setNoScroll(false);
  }

  onDragEnd = () => {};

  displayLanes = () => {
    const { apiUser, quotes, history, clients, projects, organisationDetails, invoices } = this.props;
    const { filter } = this.state;
    const viewType = "board";

    let filteredQuotes = getFilteredQuotes({
      quotes,
      filter,
      clients,
      projects,
      invoices,
      apiUser,
    });

    const laneElements = QUOTE_STATUSES.map((quoteStatus) => {
      const items = filteredQuotes.filter((quote) => quote.status === quoteStatus.value);
      const allItems = quotes.filter((quote) => quote.status === quoteStatus.value);
      const totalForColumn = items.reduce((total, quote) => total + quote.subtotal, 0);

      let label = quoteStatus.label;
      if (!organisationDetails.settings?.general?.hideFinancials || isAuthorised(["QUOTES.VIEW_COLUMN_TOTALS"])) {
        let totalSuffix = "";
        if (isAuthorised(["QUOTES.VIEW_COLUMN_TOTALS"])) {
          totalSuffix = ` - ${window.formatCurrency("GBP", totalForColumn)}`;
        }
        label += totalSuffix;
      }

      const lane = {
        label,
        items,
        status: quoteStatus.label,
        itemCount: items.length,
        allItemCount: allItems.length,
      };

      return (
        <Lane lane={lane} key={lane.label} viewType={viewType}>
          <LazyLoadList
            list={items}
            refreshOnChange={viewType}
            item={(quote, index) => {
              return (
                <Link to={`/quotes/${quote.id}`}>
                  <QuoteItem
                    quote={quote}
                    key={quote.id}
                    organisationDetails={organisationDetails}
                    onClick={() => history.push(`/quotes/${quote.id}`)}
                  />
                </Link>
              );
            }}
          />
        </Lane>
      );
    });

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <LaneContainer viewType={viewType}>{laneElements}</LaneContainer>
      </DragDropContext>
    );
  };

  export = async () => {
    const { quotes, projects, clients, invoices } = this.props;
    const { filter } = this.state;

    const columns = [...tableColumns];

    let csvContent = columns.map((column) => column.title).join(",") + "\n";
    let filteredQuotes = getFilteredQuotes({
      quotes,
      filter,
      clients,
      projects,
      invoices,
    }).sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));

    filteredQuotes.forEach((quote) => {
      quote.lineItems.items.forEach((lineItem) => {
        csvContent +=
          columns
            .map((column) => {
              if (column.fieldFunction) {
                return sanitiseCSVValue(column.fieldFunction(lineItem, quote));
              } else {
                return sanitiseCSVValue(lineItem[column.dataIndex]);
              }
            })
            .join(",") + "\n";
      });
    });

    let base64CSV = `data:text/csv;base64,${btoa(unescape(encodeURIComponent(csvContent)))}`;
    await downloadBase64({
      base64String: base64CSV,
      fileName: `quotes ${moment().format("DD-MM-YYYY")}.csv`,
    });
  };

  render() {
    const { quotes } = this.props;

    return (
      <div className="quotes-page">
        <QuoteFilters
          onChange={(filter) => this.setState({ filter })}
          includeCreateQuote
          quotes={quotes}
          export={this.export}
        />

        {this.displayLanes()}
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: QuotesPage,
    subscriptions: ["tasks", "projects", "clients", "quotes", "invoices"],
  })
);
