// Map.js
import React, { useRef, useEffect, cloneElement } from "react";

export function Map({ center, zoom, style, children }) {
  const ref = useRef(null);
  const [map, setMap] = React.useState(null);

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom,
        })
      );
    }
  }, [ref, map]);

  // Update map center when `center` prop changes
  useEffect(() => {
    if (map) {
      map.setCenter(center);
    }
  }, [map, center]);

  return (
    <div ref={ref} style={style}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // Ensure `map` is passed after it's initialized
          return React.cloneElement(child, { map });
        }
        return child;
      })}
    </div>
  );
}
