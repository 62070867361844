import React from "react";
import { Tooltip } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import cx from "classnames";

import "./ReviewTarget.scss";

export default class ReviewTarget extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  onClick = () => {
    const { name, lineItemIndex } = this.props;
    if (this.props.openCommentBox && typeof this.props.openCommentBox === "function") {
      this.props.openCommentBox(name, lineItemIndex);
    }
  };

  render() {
    const { children, name, visible, onClick } = this.props;
    return (
      <div
        className={cx("review-target", { visible })}
        ref={this.ref}
        data-name={name}
        onClick={visible ? onClick : undefined}
      >
        {children}
        {visible && (
          <Tooltip title="Leave comment" mouseEnterDelay={0.7}>
            <div
              className="review-button-container"
              onClick={this.onClick}
              data-cy="review-target-button"
              data-name={name}
            >
              <CommentOutlined />
            </div>
          </Tooltip>
        )}
      </div>
    );
  }
}
