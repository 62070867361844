export default function calculateSizes(currentObject) {
  if (!Array.isArray(currentObject.objects)) {
    return currentObject;
  } else if (currentObject.objects.length === 0) {
    return {
      ...currentObject,
      width: 0,
      height: 0,
    };
  }

  // Process the children and calculate their dimensions
  let width = 0;
  let height = 0;
  const newChildren = currentObject.objects.map((child) => {
    const processedChild = calculateSizes(child);
    // Calculate the maximum extents
    width = Math.max(width, processedChild.left + processedChild.width);
    height = Math.max(height, processedChild.top + processedChild.height);
    return processedChild;
  });

  // Update the dimensions of the current object
  return {
    ...currentObject,
    objects: newChildren,
    width,
    height,
  };
}
