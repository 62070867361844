import { Tag } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

import { REVIEW_STATUS_READABLE } from "common/constants";

import "./ReviewPrimaryStatus.scss";

export default function ReviewPrimaryStatus({ taskRevision }) {
  let tag = null;
  if (taskRevision.reviewStatus) {
    tag = (
      <Tag
        color={REVIEW_STATUS_READABLE[taskRevision.reviewStatus].color}
        data-cy="review-status"
        className="review-primary-status"
      >
        {taskRevision.reviewStatus === "SUCCESS" && <CheckCircleFilled className="icon" />}
        {REVIEW_STATUS_READABLE[taskRevision.reviewStatus].label}
      </Tag>
    );
  }
  return tag;
}
