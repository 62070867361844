import moment from "moment";

export function calculateReadableSize(item) {
  let sizeReadable;
  let GB = Math.pow(1024, 3);
  let MB = Math.pow(1024, 2);
  let KB = 1024;

  if (item.size) {
    if (item.size > GB * 0.9) {
      sizeReadable = `${Math.round((item.size / GB) * 100) / 100}GB`;
    } else if (item.size > MB * 0.9) {
      sizeReadable = `${Math.round((item.size / MB) * 100) / 100}MB`;
    } else {
      sizeReadable = `${Math.floor(item.size / KB)}KB`;
    }
  }
  return sizeReadable;
}

const fields = [
  {
    id: "attachmentKey",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Attachment path",
    value: ({ attachment }) => {
      if (!attachment) {
        return "";
      }
      return attachment.Key;
    },
  },
  {
    id: "attachmentLastModified",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Last modified",
    value: ({ attachment, dateFormat }) => {
      if (!attachment) {
        return "";
      }
      return moment(attachment.LastModified).format(dateFormat);
    },
  },
  {
    id: "attachmentSize",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Attachment size",
    value: ({ attachment }) => {
      if (!attachment) {
        return "";
      }
      const result = calculateReadableSize({ ...attachment, size: attachment.Size });
      return result;
    },
  },
];
export function getFields() {
  return fields;
}
