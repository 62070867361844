import { useState, useEffect } from "react";

import { Typography, Tag } from "antd";
import { withRouter, Link } from "react-router-dom";
import cx from "classnames";

import withSubscriptions from "common/withSubscriptions";
import { IS_NEW_THRESHOLD } from "common/constants";

import Card from "Card/Card";

import "./PurchaseOrderListItem.scss";

export function PurchaseOrderListItem({ purchaseOrder, users, match, clients, suppliers }) {
  // const supplier = suppliers.find((x) => x.id === purchaseOrder.supplierId);
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    const createdAtTimestamp = new Date(purchaseOrder.createdAt).getTime();
    const difference = Date.now() - createdAtTimestamp;
    if (difference < IS_NEW_THRESHOLD) {
      setIsNew(true);
    }
  }, [purchaseOrder.createdAt]);

  console.log(purchaseOrder);
  return (
    <>
      <Link to={`/purchase-orders/${purchaseOrder.id}`}>
        <Card
          className={cx("purchase-order-list-item", {
            new: isNew,
          })}
          attributes={{ "data-title": purchaseOrder.title }}
        >
          <Tag className="purchase-order-id-tag dark-tag">{purchaseOrder.id}</Tag>
          <Typography.Text className="purchase-order-total">
            {window.formatCurrency("GBP", purchaseOrder.total)}
          </Typography.Text>
          <Typography.Text className="purchase-order-title">{purchaseOrder.title}</Typography.Text>
        </Card>
      </Link>
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: PurchaseOrderListItem,
    subscriptions: ["users", "tasks", "clients", "organisationDetails", "suppliers"],
  })
);
