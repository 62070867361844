export default function getLengthFields(fields) {
  let result = [];

  for (let field of fields) {
    let updatedLabel = "Length of ";

    if (!field.label) {
      continue;
    }
    if (field.label === field.label.toUpperCase()) {
      updatedLabel += field.label;
    } else {
      let firstLetter = field.label[0];
      let secondLetter = field.label[1];

      if (secondLetter && secondLetter === secondLetter.toUpperCase()) {
        updatedLabel += firstLetter;
      } else {
        updatedLabel += firstLetter.toLowerCase();
      }
      updatedLabel += field.label.slice(1);
    }

    result.push({
      ...field,
      id: field.id + "Length",
      label: updatedLabel,
      value: async (params) => {
        // debugger;
        let fieldValue;
        if (typeof field.value === "function") {
          fieldValue = await field.value(params);
        } else {
          fieldValue = field.value;
        }
        if (typeof fieldValue === "string" || fieldValue instanceof String || Array.isArray(fieldValue)) {
          return fieldValue.length || 0;
        } else {
          return 0;
        }
      },
    });
  }
  return result;
}
