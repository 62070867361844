import { Drawer, Typography, Checkbox, Select } from "antd";
import cookie from "js-cookie";
import { CloseIcon } from "common/icons";
import {
  COOKIE_NAME_TIMELINE_SHOW_TASK_IDS,
  COOKIE_NAME_TIMELINE_SHOW_TASK_TITLES,
  COOKIE_NAME_TIMELINE_SHOW_PROJECT_TITLES,
  COOKIE_NAME_TIMELINE_DEFAULT_DATE_RANGE,
  COOKIE_NAME_TIMELINE_USER_ROWS,
  TIMELINE_DEFAULT_DATE_RANGES_OPTIONS,
} from "common/constants";
import { getSimpleLabel } from "common/labels";

import ProjectPicker from "ProjectPicker/ProjectPicker";
import TaskPicker from "TaskPicker/TaskPicker";
import AvatarList from "AvatarList/AvatarList";

import "./TimelineSettings.scss";

export default function TimelineSettings({
  visible,
  onClose,
  filterClientId,
  filterProjectId,
  filterTaskId,
  showPseudoTasks,
  showTaskIDs,
  showTaskTitles,
  showProjectTitles,
  clients,
  defaultDateRange,
  setState,
  changePlanningDateRange,
  windowWidth,
  userRows,
  validUsers,
  organisationDetails,
}) {
  return (
    <Drawer
      open={visible}
      size={windowWidth < 1000 ? undefined : "large"}
      onClose={onClose}
      closeIcon={<CloseIcon />}
      className="timeline-settings"
      title={`${getSimpleLabel("Timeline")} filters - only apply to you`}
    >
      <div key="default-date-range">
        <Typography.Text className="setting-label">Default date range</Typography.Text>

        <Select
          placeholder="Choose a default date range"
          onChange={(value) => {
            setState({
              defaultDateRange: TIMELINE_DEFAULT_DATE_RANGES_OPTIONS[value],
            });
            changePlanningDateRange(TIMELINE_DEFAULT_DATE_RANGES_OPTIONS[value]);
            cookie.set(COOKIE_NAME_TIMELINE_DEFAULT_DATE_RANGE, value);
          }}
          defaultValue={defaultDateRange}
          className="timeline-default-date-range"
        >
          {Object.keys(TIMELINE_DEFAULT_DATE_RANGES_OPTIONS).map((optionKey, i) => (
            <Select.Option key={optionKey}>{optionKey}</Select.Option>
          ))}
        </Select>
      </div>

      <div className="flex-menu-item" key="user-rows">
        <Typography.Text className="left-label setting-label">
          Only show the following rows (if none are selected, all will be displayed):
        </Typography.Text>
        <AvatarList
          users={validUsers.filter((user) => user.id !== "unassigned" && !user.isStockItem)}
          minAvatarsToDisplay={11}
          onClick={(_, user) => {
            let newUserRows = JSON.parse(JSON.stringify(userRows || []));
            if (newUserRows.includes(user.id)) {
              newUserRows = newUserRows.filter((x) => x !== user.id);
            } else {
              newUserRows.push(user.id);
            }
            cookie.set(COOKIE_NAME_TIMELINE_USER_ROWS, JSON.stringify(newUserRows));
            setState({ userRows: newUserRows });
          }}
          selectedUsers={userRows}
          includeUnassigned={false}
          // containerWidthToSubtract={this.props.avatarListWidthToSubtract}
        />
      </div>

      <div className="flex-menu-item" key="only-show-blocks-for-client">
        <Typography.Text className="left-label setting-label">
          Only show time blocks for {getSimpleLabel("client")}:
        </Typography.Text>

        <Select
          showSearch
          allowClear
          placeholder={`Choose a ${getSimpleLabel("client")}`}
          autoComplete="off"
          className="timeline-filter-client"
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          onChange={(filterClientId) => {
            setState({ filterClientId });
            filterClientId
              ? cookie.set("timeline-filter-client-id", filterClientId)
              : cookie.remove("timeline-filter-client-id", filterClientId);
          }}
          value={filterClientId}
        >
          {clients.map((client, i) => (
            <Select.Option key={i} value={client.id}>
              {client.name}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div className="flex-menu-item" key="only-show-blocks-for-project">
        <Typography.Text className="left-label setting-label">
          Only show time blocks for {getSimpleLabel("project")}:
        </Typography.Text>

        <ProjectPicker
          onChange={(filterProjectId) => {
            setState({ filterProjectId });
            filterProjectId
              ? cookie.set("timeline-filter-project-id", filterProjectId)
              : cookie.remove("timeline-filter-project-id", filterProjectId);
          }}
          value={filterProjectId}
          data-cy="project-id-dropdown"
          allowClear
        />
      </div>

      <div className="flex-menu-item" key="only-show-blocks-for-task">
        <Typography.Text className="left-label setting-label">
          Only show time blocks for {getSimpleLabel("task")}:
        </Typography.Text>

        <TaskPicker
          onChange={(filterTaskId) => {
            setState({ filterTaskId });
            filterTaskId
              ? cookie.set("timeline-filter-task-id", filterTaskId)
              : cookie.remove("timeline-filter-task-id", filterTaskId);
          }}
          value={filterTaskId}
          data-cy="task-id-dropdown"
          allowClear
        />
      </div>
      <div className="flex-menu-item" key="show-special-tasks">
        <div className="special-task-wrapper">
          <Typography.Text className="left-label setting-label">
            Show special {getSimpleLabel("tasks")}
            :
            <Checkbox
              checked={showPseudoTasks}
              onChange={(e) => {
                setState({ showPseudoTasks: e.target.checked });
                cookie.set("timeline-show-pseudo-tasks", e.target.checked);
              }}
            />
          </Typography.Text>
        </div>
      </div>
      <div className="flex-menu-item" key="show-task-ids">
        <div>
          <Typography.Text className="left-label setting-label">
            Show {getSimpleLabel("task")} IDs:
            <Checkbox
              checked={showTaskIDs}
              onChange={(e) => {
                setState({ showTaskIDs: e.target.checked });
                cookie.set(COOKIE_NAME_TIMELINE_SHOW_TASK_IDS, e.target.checked);
              }}
            />
          </Typography.Text>
        </div>
      </div>
      <div className="flex-menu-item" key="show-task-titles">
        <div>
          <Typography.Text className="left-label setting-label">
            Show {getSimpleLabel("task")} titles:
            <Checkbox
              checked={showTaskTitles}
              onChange={(e) => {
                setState({ showTaskTitles: e.target.checked });
                cookie.set(COOKIE_NAME_TIMELINE_SHOW_TASK_TITLES, e.target.checked);
              }}
            />
          </Typography.Text>
        </div>
      </div>
      {!organisationDetails?.settings?.task?.automaticallyCreateProject && (
        <div className="flex-menu-item" key="show-project-titles">
          <div>
            <Typography.Text className="left-label setting-label">
              Show {getSimpleLabel("project")} titles:
              <Checkbox
                checked={showProjectTitles}
                onChange={(e) => {
                  setState({ showProjectTitles: e.target.checked });
                  cookie.set(COOKIE_NAME_TIMELINE_SHOW_PROJECT_TITLES, e.target.checked);
                }}
              />
            </Typography.Text>
          </div>
        </div>
      )}
    </Drawer>
  );
}
