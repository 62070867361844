import React, { useState, useEffect } from "react";

import { Wrapper } from "@googlemaps/react-wrapper";
import { Map, Marker, render } from "../Map/Map";

import "./MapWrapper.scss";

export default function MapWrapper({ address, isReadOnly = false, width, height, coordinates, onChange }) {
  const [center, setCenter] = useState();
  const [markerPosition, setMarkerPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [zoom, setZoom] = useState(16);
  const apiKey = window.secrets?.GOOGLE_MAPS;

  useEffect(() => {
    setMarkerPosition(coordinates);
    if (isReadOnly || !center) {
      setCenter(coordinates);
    }
  }, [JSON.stringify(coordinates)]); // eslint-disable-line

  useEffect(() => {
    setMarkerPosition(coordinates);
    setCenter(coordinates);
  }, [address]); // eslint-disable-line

  function onIdle(mapAPI) {
    if (mapAPI.getCenter()) {
      setZoom(mapAPI.getZoom());
      // setCenter(mapAPI.getCenter().toJSON());
    }
  }

  async function onClick(e) {
    setMarkerPosition(e.latLng);

    if (isReadOnly === false) {
      onChange(e.latLng);
    }
  }

  function getMapProps() {
    let mapProps = {
      center,
      zoom,
      style: {
        flexGrow: "1",
        height: "100%",
      },
    };

    if (!isReadOnly) {
      mapProps = {
        ...mapProps,
        onClick,
        onIdle,
      };
    }

    return mapProps;
  }

  return (
    <div className="google-maps-wrapper" style={{ width, height }}>
      <Wrapper apiKey={apiKey} render={render}>
        {center && (
          <Map {...getMapProps()}>
            {markerPosition ? <Marker position={markerPosition} /> : null}
            {/* {center ? <Marker position={center} /> : null} */}
          </Map>
        )}
      </Wrapper>
    </div>
  );
}
