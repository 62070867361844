import { Spin } from "antd";
import cx from "classnames";

import "./OverallSpinner.scss";

export default function OverallSpinner({ contained = false, visible = true }) {
  return (
    <div className={cx("overall-spinner", { contained, visible })}>
      <div className="overall-spinner-inner-container">
        <Spin />
      </div>
    </div>
  );
}
