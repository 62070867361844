import moment from "moment";
import { processIdForDisplay } from "common/shared";
import { roundToQuarter } from "common/mathHelpers";

const fields = [
  {
    id: "timesheetBlockUserFullName",
    fieldTypes: ["textfield"],
    label: "User full name",
    value: ({ timesheetBlock, users }) => {
      if (!timesheetBlock) {
        return "";
      }
      let user = users.find((x) => x.id === timesheetBlock.userId);
      if (!user) {
        return "";
      }
      return `${user.firstName ? `${user.firstName} ` : ""}${user.lastName || ""}`;
    },
  },
  {
    id: "timesheetBlockClientName",
    fieldTypes: ["textfield"],
    label: "Client name",
    value: ({ timesheetBlock }) => {
      if (!timesheetBlock || !timesheetBlock.client) {
        return "";
      }
      return timesheetBlock.client.name;
    },
  },
  {
    id: "timesheetBlockProjectTitle",
    fieldTypes: ["textfield"],
    label: "Project title",
    value: ({ timesheetBlock }) => {
      if (!timesheetBlock || !timesheetBlock.project) {
        return "";
      }
      return timesheetBlock.project.title;
    },
  },
  {
    id: "timesheetBlockProjectId",
    fieldTypes: ["textfield"],
    label: "Project ID",
    value: ({ timesheetBlock }) => {
      if (!timesheetBlock || !timesheetBlock.project) {
        return "";
      }
      return timesheetBlock.project.id;
    },
  },
  {
    id: "timesheetBlockTaskTitle",
    fieldTypes: ["textfield"],
    label: "Task title",
    value: ({ timesheetBlock }) => {
      if (!timesheetBlock || !timesheetBlock.task) {
        return "";
      }
      return timesheetBlock.task.title;
    },
  },
  {
    id: "timesheetBlockTags",
    fieldTypes: ["textfield"],
    label: "Tags",
    value: ({ timesheetBlock }) => {
      if (!timesheetBlock) {
        return "";
      }
      return timesheetBlock.tagsReadable;
    },
  },
  {
    id: "timesheetBlockTagsAndPseudoTaskTitle",
    fieldTypes: ["textfield"],
    label: "Tags and special task title",
    value: ({ timesheetBlock }) => {
      if (!timesheetBlock) {
        return "";
      }

      let result = [...(timesheetBlock.tagsReadable || [])];

      if (!timesheetBlock.task) {
        result.unshift(
          timesheetBlock.taskId
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        );
      }

      return result;
    },
  },
  {
    id: "timesheetBlockTaskId",
    fieldTypes: ["textfield"],
    label: "Task ID",
    value: ({ timesheetBlock }) => {
      if (!timesheetBlock || !timesheetBlock.task) {
        return "";
      }
      return timesheetBlock.task.id;
    },
  },
  {
    id: "timesheetBlockTaskRevisionName",
    fieldTypes: ["textfield"],
    label: "Task revision name",
    value: ({ timesheetBlock }) => {
      if (!timesheetBlock || !timesheetBlock.task) {
        return "";
      }

      let taskRevision = timesheetBlock.task.revisions.items?.find((x) => x.id === timesheetBlock.taskRevisionId);

      if (!taskRevision) {
        return "";
      }

      return taskRevision.name;
    },
  },
  {
    id: "timesheetBlockFirstRequestID",
    fieldTypes: ["textfield"],
    label: "First request ID",
    value: async ({ timesheetBlock }) => {
      let task = timesheetBlock?.task;
      let taskId = task?.id;

      if (!taskId || !task.requestIds || task.requestIds.length === 0) {
        return "";
      }

      return processIdForDisplay(task.requestIds[0]);
    },
  },
  {
    id: "timesheetBlockUserDate",
    fieldTypes: ["textfield"],
    label: "Date",
    isDate: true,
    value: ({ timesheetBlock, dateFormat }) => {
      if (!timesheetBlock) {
        return "";
      }
      return moment(timesheetBlock.startAt).format(dateFormat);
    },
  },
  {
    id: "timesheetBlockDurationHours",
    fieldTypes: ["textfield"],
    label: "Duration (hours)",
    value: ({ timesheetBlock }) => {
      if (!timesheetBlock) {
        return "";
      }
      let durationHours = roundToQuarter(
        moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
      );
      return durationHours;
    },
  },
  {
    id: "timesheetBlockDescription",
    fieldTypes: ["textfield"],
    label: "Description",
    value: ({ timesheetBlock }) => {
      if (!timesheetBlock) {
        return "";
      }

      return timesheetBlock.description || "";
    },
  },
];

export function getFields({ organisationDetails }) {
  let extraFields = [];
  if (organisationDetails?.customFields && organisationDetails.customFields.length > 0) {
    organisationDetails.customFields.forEach((fieldDefinition) => {
      extraFields.push({
        id: `timesheet_customTaskField_${fieldDefinition.id}`,
        fieldTypes: ["textfield"],
        label: `Task - ${fieldDefinition.label}`,
        value: ({ timesheetBlock }) => {
          if (!timesheetBlock || !timesheetBlock.task) {
            return "";
          }
          let task = timesheetBlock.task;
          if (!task || !task.customFields) {
            return "";
          }
          let targetField = task.customFields.find((field) => field.id === fieldDefinition.id);
          if (!targetField) {
            return "";
          }
          return targetField.value;
        },
      });
    });
  }

  return [...fields, ...extraFields];
}

export const label = "Timesheet block";
