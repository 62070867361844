import { useState } from "react";
import { withRouter } from "react-router-dom";
import { Typography, Form, Modal, Input, Button, Row, Col, Select, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { useForceUpdate, displayErrorMessage, getLabel, processIdForDisplay } from "common/helpers";
import { getQuoteId } from "common/naming";
import withSubscriptions from "common/withSubscriptions";
import { QUOTE_STATUSES, CREATE_RETRY_LIMIT, CURRENCIES, DEFAULT_TAX_RATE } from "common/constants";
import { copyQuoteTemplate } from "QuoteDetailsPage/quoteHelpers";
import { getSimpleLabel } from "common/labels";
import { callGraphQLSimple } from "common/apiHelpers";

import CreateProjectModal from "CreateProjectModal/CreateProjectModal";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";
import ProjectPicker from "ProjectPicker/ProjectPicker";

import "./CreateQuoteModal.scss";

export function CreateQuoteModal({
  onClose,
  organisationDetails,
  apiUser,
  predefinedFields = {},
  projects,
  clients,
  users,
  history,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateProjectModalVisible, setIsCreateProjectModalVisible] = useState(false);
  const [form] = Form.useForm();

  let oldDefaultTemplateId = `${organisationDetails.id}_QUOTE_default`;
  let defaultTemplateId;

  const templatesForFileType = organisationDetails.templates.items.filter(
    // (template) => (template.isLive || !template.key) && !template.isDeprecated
    // TODO: re-add the restriction for template to be live
    (template) => template.type === "QUOTE" && !template.isDeprecated
  );

  let hasOldDefaultTemplate = templatesForFileType.find((x) => x.id === oldDefaultTemplateId);
  if (hasOldDefaultTemplate) {
    defaultTemplateId = oldDefaultTemplateId;
  } else {
    defaultTemplateId = templatesForFileType.find((x) => x.isDefault)?.id || templatesForFileType[0]?.id;
  }

  // we need to force the component to re-render when we change some form values
  const forceUpdate = useForceUpdate();

  async function onSubmit(params) {
    let { projectId, title, description, currency, extraOffset = 0, templateId } = params;
    if (!templateId) {
      templateId = defaultTemplateId;
    }
    setIsLoading(true);

    try {
      const project = projects.find((x) => x.id === projectId);

      if (!project) {
        message.error(
          <>
            {getLabel({
              id: "Project",
              defaultValue: "Project",
            })}{" "}
            <b>{params.projectId}</b> not found
          </>
        );
        setIsLoading(false);
        return;
      }

      const client = clients.find((client) => client.id === project.clientId);
      if (!client) {
        message.error(
          <>
            {getLabel({
              id: "Client",
              defaultValue: "Client",
            })}{" "}
            <b>{project.clientId}</b> not found
          </>
        );
        setIsLoading(false);
        return;
      }
      let quoteId = await getQuoteId({
        organisation: apiUser.organisation,
        projectDetails: project,
        extraOffset,
      });

      let projectClientContacts = project.clientContacts;

      let defaultClientContact;

      if (client.contacts?.length > 0) {
        if (projectClientContacts && projectClientContacts.length > 0) {
          defaultClientContact = projectClientContacts[0];
        } else {
          defaultClientContact = client.contacts[0]?.id;
        }
      }

      let taxRate = DEFAULT_TAX_RATE;
      if (organisationDetails.settings?.general?.hideFinancials) {
        taxRate = 0;
      }

      const newQuote = (
        await callGraphQLSimple({
          displayError: false,
          queryName: "createQuote",
          variables: {
            input: {
              id: quoteId,
              organisation: apiUser.organisation,
              author: apiUser.id,
              clientId: project.clientId,
              projectId,
              title,
              status: QUOTE_STATUSES[0].value,
              description,
              currency,
              taxInclusive: false,
              total: 0,
              subtotal: 0,
              totalTax: 0,
              taxRate,
              clientContact: defaultClientContact,
              clientAddress: client.addresses && client.addresses[0] && client.addresses[0].id,
              assignedTo: apiUser.id,
              contactIdsToIncludeInCC: ["reviewer"],
              contactIdsToIncludeInBCC: organisationDetails.settings?.quote?.defaultQuoteBccEmails || ["myself"],
              defaultFees: organisationDetails?.defaultFees,
              poNumber: project.poNumber,
              templateId,
              team: project.team,
            },
          },
        })
      ).data.createQuote;

      await copyQuoteTemplate({
        organisationDetails,
        templateId,
        newQuote,
        apiUser,
        clients,
        users,
        project,
      });

      await callGraphQLSimple({
        message: `Failed to update ${getSimpleLabel("project")}`,
        queryName: "updateProject",
        variables: {
          input: {
            id: projectId,
            quoteCount: (newQuote.project.quoteCount || 0) + extraOffset + 1,
          },
        },
      });
      message.success({
        content: (
          <Typography.Text onClick={() => history.push(`/quotes/${newQuote.id}`)} style={{ cursor: "pointer" }}>
            {getSimpleLabel("Quote")} {processIdForDisplay(newQuote.id)} created. <b>Click here to go to it</b>
          </Typography.Text>
        ),
        duration: window.Cypress ? 0.5 : 5,
      });
      onClose();
    } catch (e) {
      console.log("quote error: e = ", e);
      const errorType = e?.errors && e.errors[0]?.errorType;
      console.log("extraOffset = ", extraOffset);
      console.log("CREATE_RETRY_LIMIT = ", CREATE_RETRY_LIMIT);
      // debugger;
      if (errorType === "DynamoDB:ConditionalCheckFailedException" && extraOffset <= CREATE_RETRY_LIMIT) {
        console.log("not showing error");
        return await onSubmit({
          ...params,
          extraOffset: extraOffset + 1,
        });
      } else {
        console.log(
          `error creating ${getLabel({
            id: "quote",
            defaultValue: "quote",
          })}:`,
          e
        );
        displayErrorMessage(
          e,
          `Failed to create ${getLabel({
            id: "quote",
            defaultValue: "quote",
          })}`
        );
        setIsLoading(false);
        onClose();
        throw e;
      }
    }
  }

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={`Create ${getLabel({
        id: "quote",
        defaultValue: "quote",
      })} ${
        predefinedFields.projectId
          ? `in ${getLabel({
              id: "project",
              defaultValue: "project",
            })}`
          : ""
      }`}
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-quote-modal full-screen-on-mobile"
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          currency: CURRENCIES[0].value,
          templateId: defaultTemplateId,
          ...predefinedFields,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: `You must specify a ${getLabel({
                id: "quote",
                defaultValue: "quote",
              })} title`,
            },
          ]}
        >
          <Input
            autoComplete="off"
            className="quote-title"
            data-cy="quote-title-input"
            onChange={(e) => {
              form.setFieldsValue({ title: e.target.value });
              forceUpdate();
              form.validateFields(["title"]);
            }}
          />
        </Form.Item>
        <Form.Item label="Description" name="description" rules={[]}>
          <Input.TextArea rows={1} autoComplete="off" className="quote-description" data-cy="quote-description-input" />
        </Form.Item>

        <Form.Item
          name="projectId"
          label={getLabel({
            id: "Project",
            defaultValue: "Project",
          })}
          rules={[
            {
              required: true,
              message: `${getLabel({
                id: "Quotes",
                defaultValue: "Quotes",
              })} have to be part of a ${getLabel({
                id: "project",
                defaultValue: "project",
              })}`,
            },
          ]}
        >
          <Row gutter={16}>
            <Col xs={24} sm={16}>
              <ProjectPicker
                onChange={(projectId) => {
                  form.setFieldsValue({ projectId });
                  forceUpdate();
                  if (form.getFieldValue("title")) {
                    form.validateFields(["title"]);
                  }
                }}
                value={form.getFieldValue("projectId")}
                data-cy="project-id-dropdown"
                predefinedFields={predefinedFields}
              />
            </Col>
            <Col xs={24} sm={8}>
              <ButtonWithPermissions
                type="primary"
                className="create-project"
                onClick={() => setIsCreateProjectModalVisible(true)}
                permissions={["CREATE_PROJECT"]}
                data-cy="create-project-button"
                disabled={predefinedFields.projectId}
              >
                <PlusCircleOutlined /> Create{" "}
                {getLabel({
                  id: "project",
                  defaultValue: "project",
                })}
              </ButtonWithPermissions>
            </Col>
          </Row>
        </Form.Item>
        {/* {hasOldDefaultTemplate && window.location.hostname !== "localhost" ? null : ( */}
        <Form.Item
          label="Template"
          name="templateId"
          rules={[
            {
              required: true,
              message: "You have to choose a template",
            },
          ]}
        >
          <Select>
            {(templatesForFileType || []).map((template) => {
              return (
                <Select.Option key={template.id} value={template.id}>
                  {template.name}
                  {!template.isLive && template.key ? " (draft)" : ""}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {/* )} */}

        <Form.Item label="Currency" name="currency" rules={[]} hidden>
          <Select data-cy="currency-picker-dropdown">
            {CURRENCIES.map((currency) => {
              return (
                <Select.Option key={currency.value} value={currency.value}>
                  {currency.value}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="create-quote-submit-modal-button">
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
      {isCreateProjectModalVisible && (
        <CreateProjectModal
          apiUser={apiUser}
          organisation={apiUser.organisation}
          onClose={() => setIsCreateProjectModalVisible(false)}
          onSave={(project) => {
            form.setFieldsValue({ projectId: project.id });
            forceUpdate();
          }}
        />
      )}
    </Modal>
  );
}

export default withRouter(
  withSubscriptions({
    Component: CreateQuoteModal,
    subscriptions: ["clients", "users", "projects"],
    displayPreloader: false,
  })
);
