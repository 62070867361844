import React from "react";
import { Table, Button, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import withSubscriptions from "common/withSubscriptions";
import { getLabel } from "common/helpers";
import { callRest } from "common/apiHelpers";
import { getDataSourceForTable } from "common/asyncJobHelpers";

import "./QueuePage.scss";

const INITIAL_PAGE_SIZE = 30;

export class QueuePage extends React.Component {
  _isMounted = false;

  state = {
    filter: {},
    isLoading: true,
    valueForRender: 0,
  };

  componentDidMount() {
    this.props.setBoxedLayout(false);
    this.props.setNoScroll(true);

    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "ASYNC_JOBS",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
    this.renderInterval = setInterval(() => {
      this.setState({ valueForRender: Math.random() });
    }, 1000);

    this.loadNextPageOfItems();
  }

  componentWillUnmount() {
    this.props.setBoxedLayout(true);
    this.props.setNoScroll(false);
    if (this.renderInterval) {
      clearInterval(this.renderInterval);
    }
  }

  loadNextPageOfItems = () => {
    const { context, fetchAndSetAsyncJobs, organisationDetails } = this.props;
    const { asyncJobsNextToken } = context;

    this.setState({ isLoading: true });

    fetchAndSetAsyncJobs({
      organisation: organisationDetails.id,
      limit: INITIAL_PAGE_SIZE,
      nextToken: asyncJobsNextToken,
      addToList: true,
    }).then(() => {
      this.setState({ isLoading: false });
    });
  };

  onRowClick = ({ row, rowIndex, e }) => {
    if (!e.target) {
      return;
    }
    let targetClassName = e.target.className;
    if (targetClassName?.startsWith && targetClassName?.startsWith("ant-dropdown")) {
      return;
    }

    const { history } = this.props;
    if (row?.task) {
      history.push(`/tasks/${row.task.id}/${row.fileType}/${row.fileId}`);
    }
  };

  cancelAllPendingJobs = async () => {
    const { asyncJobs } = this.props;

    let messageKey = "cancel-pending-jobs";
    let count = 0;
    try {
      for (let job of asyncJobs) {
        if (job.status === "PENDING") {
          count++;
          await window.callGraphQLSimple({
            message: "Failed to cancel async job",
            queryCustom: "updateAsyncJob",
            variables: {
              input: {
                id: job.id,
                status: "CANCELLED",
                createdAt: job.createdAt,
              },
            },
          });
          message.loading({
            content: `Cancelled job: ${count} / ${asyncJobs.length}`,
            key: messageKey,
            duration: 0,
          });
        }
      }
    } catch (e) {
      message.error({ content: `Failed to cancel job`, key: messageKey });
    }
    message.success({
      content: `Successfully cancelled ${count} jobs`,
      key: messageKey,
      duration: 5,
    });
  };

  cancelJob = async (row) => {
    await window.callGraphQLSimple({
      message: "Failed to cancel async job",
      queryCustom: "updateAsyncJob",
      variables: {
        input: {
          id: row.id,
          status: "CANCELLED",
          createdAt: row.originalCreatedAt,
        },
      },
    });
  };

  retryJob = async (row) => {
    const messageKey = "retry-job";
    try {
      message.loading({ content: "Retrying job", key: messageKey, duration: 0 });
      await callRest({
        method: "POST",
        route: `/async-job/retry`,
        includeCredentials: false,
        body: {
          asyncJobId: row.id,
          reason: "Clicked on individual retry button",
        },
      });
      message.success({ content: "Successfully retried job", key: messageKey });
    } catch (e) {
      message.error({ content: "Failed to retry job", key: messageKey });
    }
  };

  displayActionBar = () => {
    const { context, apiUser } = this.props;

    const { asyncJobsNextToken } = context;

    return (
      <div className="action-bar">
        {(apiUser.isHidden || window.location.hostname === "localhost") && (
          <Button icon={<CloseCircleOutlined />} onClick={this.cancelAllPendingJobs}>
            Cancel all pending jobs
          </Button>
        )}
        {asyncJobsNextToken && (
          <Button type="primary" onClick={this.loadNextPageOfItems}>
            Load more
          </Button>
        )}
      </div>
    );
  };

  render() {
    const { asyncJobs, context, tasks, projects, users, windowHeight, apiUser } = this.props;
    const { asyncJobsNextToken } = context;

    let dataSource = getDataSourceForTable({
      asyncJobs,
      tasks,
      projects,
      users,
    });

    let consumerColumn;
    let retryColumn;
    let jobIdColumn;

    if (window.apiUser.isHidden || window.location.hostname === "localhost") {
      consumerColumn = {
        title: "Consumer",
        dataIndex: "consumerId",
        width: 100,
        key: "consumer-id",
        render: (consumerId) => {
          return consumerId ? consumerId.split("\\")[0] : "";
        },
      };
      retryColumn = {
        title: "",
        key: "retry",
        width: 100,
        render: (_, row) => {
          return (
            !["PENDING", "IN_PROGRESS"].includes(row.status) && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  this.retryJob(row);
                }}
              >
                Retry
              </Button>
            )
          );
        },
      };
      jobIdColumn = {
        title: "Job ID",
        dataIndex: "id",
        width: 300,
        key: "job-id",
      };
    }

    let tableColumns = [
      {
        title: getLabel({
          id: "Project",
          defaultValue: "Project",
        }),
        dataIndex: "projectTitle",
        align: "left",
        width: 200,
      },
      {
        title: getLabel({
          id: "Task",
          defaultValue: "Task",
        }),
        dataIndex: "taskIdTag",
        align: "left",
        width: 350,
      },
      { title: "Progress", dataIndex: "progressPercent", width: 160 },
      {
        title: "File",
        dataIndex: "fileTypeReadable",
        width: 120,
        render: (_, row) => {
          return <Link to={`/tasks/${row.taskId}/${row.fileType}/${row.fileId}`}>{row.fileTypeReadable}</Link>;
        },
      },

      { title: "Type", dataIndex: "type", width: 100 },
      {
        title: "Author",
        dataIndex: "userAvatar",
        width: 250,
        align: "left",
      },
      { title: "Status", dataIndex: "statusTag", width: 120, align: "center" },
      consumerColumn,
      retryColumn,
      jobIdColumn,
      { title: "Created", dataIndex: "createdAtFormatted", width: 140 },
      { title: "Started", dataIndex: "startedAtFormatted", width: 140 },
      { title: "Finished", dataIndex: "finishedAtFormatted", width: 140 },
      {
        title: (
          <>
            Duration
            <br />
            (hh:mm:ss)
          </>
        ),
        dataIndex: "durationFormatted",
        width: 100,
      },

      {
        title: "",
        width: 120,
        render: (_, row) => {
          return (
            ["PENDING", "IN_PROGRESS"].includes(row.status) && (
              <Button
                icon={<CloseCircleOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  this.cancelJob(row);
                }}
              >
                Cancel
              </Button>
            )
          );
        },
      },
    ].filter((column) => column);

    return (
      <div className="queue-page">
        {this.displayActionBar()}

        <Table
          scroll={{
            y: windowHeight - (asyncJobsNextToken ? 150 : 100),
            x: 1500,
          }}
          pagination={{ pageSize: 1000000, hideOnSinglePage: true }}
          dataSource={dataSource}
          loading={this.state.isLoading}
          columns={tableColumns}
          rowKey="id"
          onRow={(row, rowIndex) => {
            return {
              onClick: (e) => {
                this.onRowClick({ row, rowIndex, e });
              }, // click row
              onDoubleClick: (event) => {}, // double click row
              onContextMenu: (event) => {}, // right button click row
              onMouseEnter: (event) => {}, // mouse enter row
              onMouseLeave: (event) => {}, // mouse leave row
            };
          }}
        />
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: QueuePage,
    subscriptions: ["users", "projects", "organisations", "asyncJobs"],
  })
);
