function isTimelineBlockFixed({ timelineBlock, task }) {
  if (task?.titleLowerCase?.startsWith("*") || timelineBlock.taskId?.toLowerCase().includes("meeting")) {
    return true;
  }

  return false;
}

const functions = {
  isTimelineBlockFixed,
};

export default functions;
