import React, { useEffect, useState } from "react";
import { Typography, message, Form, Modal, Button, Input, Select, InputNumber, Checkbox } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { DOCUMENT_FORM_FIELD_TYPES, DOCUMENT_EDITOR_DATA_SOURCES } from "common/constants";
import { getFieldsForEditorByDataSource } from "common/documentEditorDataSources/aggregator";
import Textarea from "DocumentForm/Textarea/Textarea";
import { TEMPLATE_EDITOR_CONDITIONS } from "common/shared";
import { useForceUpdate } from "common/helpers";

import Attachments from "Attachments/Attachments";
import Explanation from "Explanation/Explanation";

import "./FormFieldModal.scss";

export default function FormFieldModal({
  onClose,
  onSubmit,
  field,
  modalContainerField,
  nestedContainerField,
  fileTypeDetails,
  form,
  organisationDetails,
  apiUser,
}) {
  const [antForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (field) {
      antForm.setFieldsValue({
        type: field.type,
      });
      forceUpdate();
    }
  }, [field]); // eslint-disable-line

  function displayFieldOptions() {
    let optionCount = antForm.getFieldValue("optionCount") || 1;
    const fieldType = antForm.getFieldValue("type");
    let content = null;
    let extraContent = null;
    let nestedContainerFieldContent = null;

    switch (fieldType) {
      case "projectPicker":
        content = (
          <Form.Item label="(Optional) Predefined client id" name="predefinedClientId">
            <Input />
          </Form.Item>
        );
        break;
      case "dynamicSectionList":
        content = (
          <>
            <Form.Item label="Button text" name="buttonText">
              <Input placeholder='Default is "Add item"' />
            </Form.Item>
            <Form.Item label="Title for subsections" name="titleForSubsections">
              <Input placeholder="Default is the name of the field" />
            </Form.Item>
            <Form.Item
              label="Section items are always expanded"
              name="sectionItemsAreAlwaysExpanded"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </>
        );
        break;
      case "section":
        content = (
          <>
            <Form.Item label="Compact fields" name="compactFields" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item label="Always expanded" name="isAlwaysExpanded" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </>
        );
        break;
      case "button":
        content = (
          <Form.Item label="Resulting file name suffix" name="resultingFileNameSuffix">
            <Input />
          </Form.Item>
        );
        break;
      case "textfield":
        content = (
          <Form.Item label="Default content" name="value">
            <Input />
          </Form.Item>
        );
        break;
      case "number":
        content = (
          <Form.Item label="Default value" name="value">
            <InputNumber />
          </Form.Item>
        );
        break;
      case "textarea":
        const isEmailText = field?.id === "emailText";
        let parsedFieldValue;

        let useNewEditor = true;

        if (isEmailText) {
          useNewEditor = false;
        } else {
          try {
            if (antForm.getFieldValue("value")) {
              if (typeof antForm.getFieldValue("value") === "string") {
                if (antForm.getFieldValue("value").length === 0) {
                  useNewEditor = false;
                } else {
                  parsedFieldValue = JSON.parse(antForm.getFieldValue("value"));
                }
              } else {
                parsedFieldValue = antForm.getFieldValue("value");
              }
            }
          } catch (e) {
            console.error("error:", e);
            console.log("error:", e);
            useNewEditor = false;
          }
        }
        content = (
          <>
            <Form.Item label="Default content" name="value">
              {useNewEditor ? (
                <Textarea
                  defaultValue={parsedFieldValue}
                  onChange={(newValue) => {
                    antForm.setFieldsValue({
                      value: newValue,
                    });
                    forceUpdate();
                  }}
                />
              ) : (
                <Input.TextArea autoSize={{ minRows: 3 }} />
              )}
            </Form.Item>
            <Form.Item
              label={
                <>
                  Text only <br />
                  (no attachments or page breaks)
                </>
              }
              name="textOnly"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              label={
                <>
                  Basic formatting only <br />
                  (no font size or colour)
                </>
              }
              name="basicFormattingOnly"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </>
        );
        break;
      case "checkbox":
        content = (
          <Form.Item label="Checked by default" name="value" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        );
        break;
      case "attachmentUploader":
        content = (
          <>
            <Form.Item label="Default absolute path" name="defaultPath">
              <Input />
            </Form.Item>
            <Form.Item label="Default relative path" name="defaultRelativePath">
              <Input />
            </Form.Item>
            <Form.Item label="Allow download" name="allowDownload" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item
              label={
                <>
                  Presentation-only <br /> (no actions are possible)
                </>
              }
              name="presentationOnly"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </>
        );
        break;
      case "attachmentPicker":
        content = (
          <>
            <Form.Item
              label="Attachment content will be displayed in the report"
              name="renderedInReport"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>

            <Form.Item label="Allow preview" name="allowPreview" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item
              label={
                <>
                  Read-only
                  <br />
                  (does not allow changing the files)
                </>
              }
              name="readOnly"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item label="Default absolute path" name="defaultPath">
              <Input />
            </Form.Item>
            <Form.Item label="Default relative path" name="defaultRelativePath">
              <Input />
            </Form.Item>
            <Form.Item label="Pre-selected files" name="value">
              <Attachments
                apiUser={apiUser}
                isDocumentLibrary={true}
                selectedItems={antForm.getFieldValue("value")}
                onChange={(attachments, dates, sizes) => {
                  antForm.setFieldsValue({
                    value: attachments,
                  });
                }}
                isInModal={false}
              />
            </Form.Item>
          </>
        );
        break;
      case "modalWithFields":
        content = (
          <Form.Item label="Button text" name="buttonText">
            <Input placeholder='Default is "Edit"' />
          </Form.Item>
        );
        break;
      case "checkbox-list":
      case "radio-list":
      case "dropdown":
        extraContent = (
          <>
            {fieldType === "checkbox-list" && (
              <Form.Item label="Editable" name="editable" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            )}

            <Form.Item label="Use dynamic options" name="useDynamicOptions" valuePropName="checked">
              <Checkbox
                onChange={(e) => {
                  antForm.setFieldsValue({
                    useDynamicOptions: e.target.checked,
                  });
                  if (!e.target.checked) {
                    antForm.setFieldsValue({
                      dataSource: undefined,
                      dataSourceField: undefined,
                    });
                  }
                  forceUpdate();
                }}
              />
            </Form.Item>
            {antForm.getFieldValue("useDynamicOptions") && (
              <Form.Item
                label="The options represent form files"
                name="optionsRepresentFormFiles"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            )}
            <Form.Item
              label="Take file name from selected option"
              name="takeFileNameFromSelectedOption"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </>
        );
        if (antForm.getFieldValue("useDynamicOptions")) {
          content = (
            <>
              {fieldType === "radio-list" && (
                <Form.Item label="Auto-select first option" name="autoSelectFirstOption" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              )}
              <Form.Item
                label="Data source"
                name="dataSource"
                rules={[
                  {
                    required: true,
                    message: "For dynamic options, you have to specify a data source",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="No option selected"
                  showSearch
                  filterOption={(searchValue, { label }) => {
                    return label?.toLowerCase().includes(searchValue?.toLowerCase());
                  }}
                  onChange={(_, option) => {
                    antForm.setFieldsValue({
                      dataSource: option?.value,
                      dataSourceField: undefined,
                    });
                    forceUpdate();
                  }}
                >
                  {fileTypeDetails.dataSources.map((dataSource) => {
                    return (
                      <Select.Option
                        key={dataSource}
                        value={dataSource}
                        label={DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}
                      >
                        {DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {antForm.getFieldValue("dataSource") && (
                <Form.Item
                  label="Data source field"
                  name="dataSourceField"
                  rules={[
                    {
                      required: true,
                      message: "For dynamic options, you have to specify a field to use from the data source",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="No option selected"
                    showSearch
                    filterOption={(searchValue, { label }) => {
                      return label?.toLowerCase().includes(searchValue?.toLowerCase());
                    }}
                    onChange={(_, option) => {
                      antForm.setFieldsValue({
                        dataSourceField: option?.value,
                      });
                      forceUpdate();
                    }}
                  >
                    {getFieldsForEditorByDataSource({
                      dataSource: antForm.getFieldValue("dataSource"),
                      form,
                      fieldType: "repeatFor",
                      organisationDetails,
                    }).map((option) => {
                      return (
                        <Select.Option key={option.value} value={option.value} label={option.label}>
                          {option.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              {fieldType === "dropdown" && fileTypeDetails.label?.toLowerCase().includes("request") && (
                <Form.Item
                  label="Selected option assigns the request to a user"
                  name="assignsTheRequestToUser"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              )}
            </>
          );
        } else {
          content = (
            <>
              {Array(optionCount)
                .fill(null)
                .map((_, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Form.Item label={`Option ${index + 1}`} name={`option-${index}`}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={`Option ${index + 1} is pre-selected`}
                        name={`option-${index}-selected`}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </React.Fragment>
                  );
                })}
              <Form.Item label="Number of options" name="optionCount">
                <InputNumber
                  min={1}
                  placeholder="1"
                  onChange={(value) => {
                    antForm.setFieldsValue({ optionCount: value });
                    forceUpdate();
                  }}
                />
              </Form.Item>
            </>
          );
        }
        break;
      case "nestedFieldListWithModal":
        content = (
          <Form.Item label="Do not allow adding items to list" name="doNotAllowList" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        );
        break;
      default:
        break;
    }

    if (nestedContainerField && nestedContainerField.id !== field?.id) {
      nestedContainerFieldContent = (
        <Form.Item label="Use as default field for search" name="useAsDefaultKeyName" valuePropName="checked">
          <Checkbox />
        </Form.Item>
      );
    }

    return (
      <>
        {extraContent}
        {content}
        {nestedContainerFieldContent}
      </>
    );
  }

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };

  let initialValues = {
    optionCount: 1,
    conditionalDisplayDataSource: "form",
    editable: field?.editable !== undefined && field?.editable !== null ? field.editable : true,
    allowDownload: field?.allowDownload !== undefined && field?.allowDownload !== null ? field.allowDownload : true,
  };

  if (nestedContainerField) {
    if (nestedContainerField.defaultKeyName && nestedContainerField.defaultKeyName === field?.id) {
      initialValues.useAsDefaultKeyName = true;
    }
  }

  if (field) {
    initialValues = {
      ...initialValues,
      ...field,
    };

    if (field.options && Array.isArray(field.options)) {
      field.options.forEach((option, index) => {
        initialValues[`option-${index}`] = option.label;
        initialValues[`option-${index}-selected`] = (field.value || []).includes(option.value);
      });
      initialValues.optionCount = field.options.length;
    }
  }

  let title;

  if (field) {
    title = "Edit field";
    if (modalContainerField) {
      title += ` from "${modalContainerField.label}"`;
    }
    if (nestedContainerField) {
      title += ` from "${nestedContainerField.label}"`;
    }
  } else {
    title = "Add field";
    if (modalContainerField) {
      title += ` to "${modalContainerField.label}"`;
    }
    if (nestedContainerField) {
      title += ` to "${nestedContainerField.label}"`;
    }
  }

  if (field?.id) {
    title += ` (field ID: ${field.id})`;
    title = (
      <>
        {title}{" "}
        <Button
          type="clear"
          style={{ position: "relative", top: "1px" }}
          icon={<CopyOutlined />}
          onClick={async () => {
            await navigator.clipboard.writeText(field.id);
            message.success({
              content: <Typography.Text>Field ID copied to clipboard</Typography.Text>,
            });
          }}
        />
      </>
    );
  }

  const fieldType = antForm.getFieldValue("type");
  const fieldTypeDetails = DOCUMENT_FORM_FIELD_TYPES.find((fieldTypeDetails) => fieldTypeDetails.value === fieldType);

  let parsedInlineDescription;
  let parsedTooltipDescription;

  try {
    if (antForm.getFieldValue("inlineDescription") && antForm.getFieldValue("inlineDescription").length > 0) {
      parsedInlineDescription = JSON.parse(antForm.getFieldValue("inlineDescription"));
    }
  } catch (e) {
    // nothing
  }

  try {
    if (antForm.getFieldValue("tooltipDescription") && antForm.getFieldValue("tooltipDescription").length > 0) {
      parsedTooltipDescription = JSON.parse(antForm.getFieldValue("tooltipDescription"));
    }
  } catch (e) {
    // nothing
  }

  return (
    <Modal maskClosable={false} title={title} open={true} onCancel={onClose} footer={null} className="form-field-modal">
      <div
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        <Form
          {...layout}
          form={antForm}
          initialValues={initialValues}
          onFinish={(params) => {
            setIsLoading(true);
            if (onSubmit && typeof onSubmit === "function") {
              onSubmit({ ...field, ...params });
            }
          }}
        >
          <Form.Item label="Type" name="type" rules={[{ required: true, message: "Each field must have a type" }]}>
            <Select
              onChange={(type) => {
                antForm.setFieldsValue({ type });
                forceUpdate();
              }}
              showSearch
              filterOption={(searchValue, { label }) => {
                return label?.toLowerCase().includes(searchValue?.toLowerCase());
              }}
            >
              {[...DOCUMENT_FORM_FIELD_TYPES]
                .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))
                .map(({ value, label }) => {
                  return (
                    <Select.Option key={value} value={value} label={label}>
                      {label}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item label="Name" name="label" rules={[{ required: true, message: "Each field must have a name" }]}>
            <Input />
          </Form.Item>
          {["textfield", "textarea", "dropdown", "number"].includes(antForm.getFieldValue("type")) && (
            <Form.Item label="Placeholder" name="placeholder">
              <Input />
            </Form.Item>
          )}
          {displayFieldOptions()}

          {fileTypeDetails.hasLineItems && (
            <Form.Item
              label="This field is for line items"
              name="isForLineItems"
              valuePropName="checked"
              onChange={(e) => {
                antForm.setFieldsValue({ isForLineItems: e.target.checked });
                forceUpdate();
              }}
            >
              <Checkbox />
            </Form.Item>
          )}
          <Form.Item
            label="Hide field"
            name="isHidden"
            valuePropName="checked"
            onChange={(e) => {
              antForm.setFieldsValue({ isHidden: e.target.checked });
              forceUpdate();
            }}
          >
            <Checkbox />
          </Form.Item>
          {fieldTypeDetails?.canBeMandatory && (
            <Form.Item
              label="Is mandatory"
              name="isMandatory"
              valuePropName="checked"
              onChange={(e) => {
                antForm.setFieldsValue({ isMandatory: e.target.checked });
                forceUpdate();
              }}
            >
              <Checkbox />
            </Form.Item>
          )}
          <Form.Item
            label="Has inline description"
            name="hasInlineDescription"
            valuePropName="checked"
            onChange={(e) => {
              antForm.setFieldsValue({ hasInlineDescription: e.target.checked });
              forceUpdate();
            }}
          >
            <Checkbox />
          </Form.Item>

          {antForm.getFieldValue("hasInlineDescription") && (
            <Form.Item label="Inline description" name="inlineDescription" valuePropName="checked">
              <Textarea
                defaultValue={parsedInlineDescription}
                onChange={(newValue) => {
                  antForm.setFieldsValue({
                    inlineDescription: newValue,
                  });
                  forceUpdate();
                }}
              />
            </Form.Item>
          )}

          <Form.Item
            label="Has tooltip description"
            name="hasTooltipDescription"
            valuePropName="checked"
            onChange={(e) => {
              antForm.setFieldsValue({ hasTooltipDescription: e.target.checked });
              forceUpdate();
            }}
          >
            <Checkbox />
          </Form.Item>

          {antForm.getFieldValue("hasTooltipDescription") && (
            <Form.Item label="Tooltip description" name="tooltipDescription" valuePropName="checked">
              <Textarea
                defaultValue={parsedTooltipDescription}
                onChange={(newValue) => {
                  antForm.setFieldsValue({
                    tooltipDescription: newValue,
                  });
                  forceUpdate();
                }}
              />
            </Form.Item>
          )}

          {fieldTypeDetails?.hasAllowClear && (
            <Form.Item label="Allow selected value to be cleared" name="allowClear" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          )}

          <Form.Item
            label="Use conditional display"
            name="usesConditionalDisplay"
            valuePropName="checked"
            onChange={(e) => {
              antForm.setFieldsValue({
                usesConditionalDisplay: e.target.checked,
              });
              forceUpdate();
            }}
          >
            <Checkbox />
          </Form.Item>

          {antForm.getFieldValue("usesConditionalDisplay") && (
            <>
              <Form.Item label="Conditional display - data source" name="conditionalDisplayDataSource">
                <Select
                  placeholder="No option selected"
                  showSearch
                  filterOption={(searchValue, { label }) => {
                    return label?.toLowerCase().includes(searchValue?.toLowerCase());
                  }}
                  onChange={(value) => {
                    antForm.setFieldsValue({
                      conditionalDisplayDataSource: value,
                      conditionalDisplayDataSourceField: undefined,
                      conditionalDisplayCondition: undefined,
                      conditionalDisplayTarget: undefined,
                    });
                    forceUpdate();
                  }}
                >
                  {fileTypeDetails.dataSources.map((dataSource) => {
                    return (
                      <Select.Option
                        key={dataSource}
                        value={dataSource}
                        label={DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}
                      >
                        {DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="Conditional display - target" name="conditionalDisplayDataSourceField">
                <Select
                  allowClear
                  placeholder="No option selected"
                  onChange={(_, option) => {
                    antForm.setFieldsValue({
                      conditionalDisplayDataSourceField: option?.value,
                    });
                    forceUpdate();
                  }}
                  showSearch
                  filterOption={(searchValue, { label }) => {
                    return label?.toLowerCase().includes(searchValue?.toLowerCase());
                  }}
                >
                  {getFieldsForEditorByDataSource({
                    dataSource: antForm.getFieldValue("conditionalDisplayDataSource"),
                    form,
                    organisationDetails,
                  }).map((option) => {
                    return (
                      <Select.Option key={option.value} value={option.value} label={option.label}>
                        {option.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {antForm.getFieldValue("conditionalDisplayDataSourceField") && (
                <>
                  <Form.Item label="Condition" name="conditionalDisplayCondition">
                    <Select
                      allowClear
                      placeholder="No option selected"
                      onChange={(_, option) => {
                        antForm.setFieldsValue({
                          conditionalDisplayCondition: option?.value,
                        });
                        forceUpdate();
                      }}
                      showSearch
                      filterOption={(searchValue, { label }) => {
                        return label?.toLowerCase().includes(searchValue?.toLowerCase());
                      }}
                    >
                      {TEMPLATE_EDITOR_CONDITIONS.map((option) => {
                        return (
                          <Select.Option key={option.value} value={option.value} label={option.label}>
                            {option.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </>
              )}
              {antForm.getFieldValue("conditionalDisplayCondition") &&
                TEMPLATE_EDITOR_CONDITIONS.find(
                  (condition) => condition.value === antForm.getFieldValue("conditionalDisplayCondition")
                )?.hasTarget && (
                  <Form.Item label="Target value / values" name="conditionalDisplayTarget">
                    <Input />
                  </Form.Item>
                )}
            </>
          )}
          <Form.Item
            label={
              <>
                Triggers form refresh <span style={{ marginRight: "0.3rem" }} />
                <Explanation
                  title={
                    "Whenever this field changes, it should trigger the re-computation of any dynamic data fields within the form"
                  }
                />
              </>
            }
            name="triggersFormUpdate"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading} data-cy="field-modal-submit-button">
              Submit
            </Button>
          </div>
        </Form>
      </div>
      <br />
    </Modal>
  );
}
