import React from "react";
import cx from "classnames";
import { Spin } from "antd";
import { HomeOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";

import "./Zoomable.scss";

export default class Zoomable extends React.Component {
  state = {
    optimalScale: 1,
    scale: 1,
    optimalX: 0,
    optimalY: 0,
    contentX: 0,
    contentY: 0,
    centerContentX: 0,
    centerContentY: 0,
    contentXOnStart: 0,
    contentYOnStart: 0,
    mouseXOnStart: null,
    mouseYOnStart: null,
    isMouseDown: false,
    contentWidth: 0,
    contentHeight: 0,
    isAnimating: false,
  };

  constructor(props) {
    super(props);

    this.contentRef = React.createRef();
    this.zoomableRef = React.createRef();
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("mouseup", this.onMouseUp);
    window.addEventListener("mousemove", this.onMouseMove);
    this.zoomableRef.current.addEventListener("wheel", this.onWheel);
    this.computeScale();
  }

  componentWillUnmount() {
    window.removeEventListener("mouseup", this.onMouseUp);
    window.removeEventListener("mousemove", this.onMouseMove);
    this.zoomableRef.current.removeEventListener("wheel", this.onWheel);
  }

  componentDidUpdate(prevProps) {
    const oldOnChange = JSON.stringify(prevProps.refreshOnChange);
    const newOnChange = JSON.stringify(this.props.refreshOnChange);
    if (oldOnChange !== newOnChange) {
      this.computeScale();
    }
  }

  onHome = () => {
    this.computeScale(true);
  };

  onPlus = () => {
    const { scale } = this.state;
    const newScale = scale + 0.1;
    this.zoomAtCoordinates({ percentX: 50, percentY: 50, newScale, animated: true });
  };

  onMinus = () => {
    const { scale, optimalScale } = this.state;
    const newScale = Math.max(scale - 0.1, optimalScale);
    this.zoomAtCoordinates({ percentX: 50, percentY: 50, newScale, animated: true });
  };

  onWheel = (e) => {
    e.preventDefault();

    const { contentWidth, contentHeight, scale } = this.state;
    let delta = e.deltaY;
    let newScale = this.state.scale;

    const content = this.contentRef.current;
    newScale -= delta / 100;
    newScale = Math.max(this.state.optimalScale, newScale);

    let contentBounds = content.getBoundingClientRect();
    let mouseContentX = (e.clientX - contentBounds.left) / scale;
    let mouseContentY = (e.clientY - contentBounds.top) / scale;

    let mouseContentPercentX = mouseContentX / contentWidth;
    let mouseContentPercentY = mouseContentY / contentHeight;

    this.zoomAtCoordinates({
      percentX: mouseContentPercentX * 100,
      percentY: mouseContentPercentY * 100,
      newScale,
      animated: false,
    });
  };

  zoomAtCoordinates = ({ percentX, percentY, newScale, animated = false }) => {
    percentX /= 100;
    percentY /= 100;
    const { contentX, contentY, contentWidth, contentHeight, scale } = this.state;
    let newContentX = contentX;
    let newContentY = contentY;
    let addedWidth = contentWidth * newScale - contentWidth * scale;
    let addedHeight = contentHeight * newScale - contentHeight * scale;
    newContentX -= addedWidth * percentX;
    newContentY -= addedHeight * percentY;

    if (animated) {
      this.doAnimation();
    }
    this.setState({ scale: newScale, contentX: newContentX, contentY: newContentY });
  };

  onMouseUp = () => {
    this.setState({ isMouseDown: false });
  };

  onMouseMove = (e) => {
    const { isMouseDown, contentXOnStart, contentYOnStart, mouseXOnStart, mouseYOnStart } = this.state;
    const { active } = this.props;
    if (!isMouseDown || !active) {
      return;
    }

    const mouseX = e.pageX;
    const mouseY = e.pageY;

    this.setState({
      contentX: contentXOnStart + (mouseX - mouseXOnStart),
      contentY: contentYOnStart + (mouseY - mouseYOnStart),
    });
  };

  onMouseDown = (e) => {
    const { contentX, contentY } = this.state;
    this.setState({
      isMouseDown: true,
      mouseXOnStart: e.pageX,
      mouseYOnStart: e.pageY,
      contentXOnStart: contentX,
      contentYOnStart: contentY,
    });
  };

  /**
   * Computes the optimal scale and optimal x and y values.
   * @param {*} animated
   * @returns
   */
  computeScale = (animated = false) => {
    const content = this.contentRef?.current;
    const container = this.props.containerRef?.current;

    if (!content || !container) {
      setTimeout(this.computeScale, 100);
      return;
    }

    const containerBounds = container.getBoundingClientRect();
    const contentWidth = content.scrollWidth;
    const contentHeight = content.scrollHeight;
    const optimalScaleX = containerBounds.height / contentHeight;
    const optimalScaleY = containerBounds.width / contentWidth;
    const optimalScale = Math.min(optimalScaleX, optimalScaleY);

    const centerContentX = containerBounds.width / 2 - (contentWidth * optimalScale) / 2;
    const centerContentY = containerBounds.height / 2 - (contentHeight * optimalScale) / 2;

    if (animated) {
      this.doAnimation();
    }

    this.setState({
      optimalScale,
      scale: optimalScale,
      contentWidth,
      contentHeight,
      centerContentX,
      centerContentY,
      contentX: centerContentX,
      contentY: centerContentY,
    });
  };

  doAnimation = () => {
    this.setState({ isAnimating: true });
    setTimeout(() => {
      this.setState({ isAnimating: false });
    }, 400);
  };

  getContentStyle = () => {
    const { scale, contentX, contentY, contentWidth, contentHeight } = this.state;
    return {
      transform: `scale(${scale})`,
      top: `${contentY}px`,
      left: `${contentX}px`,
      width: contentWidth || "auto",
      height: contentHeight || "auto",
    };
  };

  render() {
    const { scale, optimalScale, isAnimating } = this.state;
    const { content, className, isLoaded, active } = this.props;

    return (
      <div
        className={cx("zoomable", { active, "not-loaded": !isLoaded }, className)}
        ref={this.zoomableRef}
        onMouseDown={this.onMouseDown}
      >
        {!isLoaded && (
          <div className="zoomable-spinner">
            <Spin />
          </div>
        )}
        <div className="zoom-controls">
          <div className="zoom-control-group">
            <div className="zoom-button enabled" onClick={this.onHome}>
              <HomeOutlined />
            </div>
          </div>
          <div className="zoom-control-group">
            <div className="zoom-button enabled" onClick={this.onPlus}>
              <PlusOutlined />
            </div>
            <div
              className={cx("zoom-button", "zoom-control-with-separator", {
                disabled: scale <= optimalScale,
                enabled: scale > optimalScale,
              })}
              onClick={this.onMinus}
            >
              <MinusOutlined />
            </div>
          </div>
        </div>
        <div
          className={cx("zoomable-inner", { animated: isAnimating })}
          ref={this.contentRef}
          style={this.getContentStyle()}
        >
          {content(scale)}
        </div>
      </div>
    );
  }
}

export const MemoZoomable = React.memo(Zoomable, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.refreshOnChange) === JSON.stringify(nextProps.refreshOnChange);
});
