import moment from "moment";

import { roundToQuarter } from "common/mathHelpers";
import { enhanceQuoteWithInvoicing } from "common/invoiceHelpers/sharedInvoiceHelpers";
import { getSimpleLabel } from "./labels";

export function getParentWithTimesheetBlocks({
  timesheetBlocks,
  projects,
  tasks,
  clients,
  groupBy = "project",
  quotes,
}) {
  let rows = [];
  let projectsWithUninvoicedTime = {};
  let timesheetBlocksWithoutProjects = [];
  let clientsWithUninvoicedTime = {};

  for (let block of timesheetBlocks) {
    if (!block.billable) {
      continue;
    }

    if (block.invoicingStatus === "WRITE_OFF") {
      continue;
    }

    const task = tasks.find((x) => x.id === block.taskId);
    let project;
    if (task) {
      project = projects.find((x) => x.id === task.projectId);
    }

    block = { ...block, project, projectId: project?.id };

    if (!project) {
      timesheetBlocksWithoutProjects.push(block);
      continue;
    }

    if (!projectsWithUninvoicedTime[block.projectId]) {
      projectsWithUninvoicedTime[block.projectId] = {
        timesheetBlocks: [],
      };
    }

    if (!clientsWithUninvoicedTime[block.projectId]) {
      clientsWithUninvoicedTime[block.projectId] = {
        timesheetBlocks: [],
      };
    }

    projectsWithUninvoicedTime[block.projectId].timesheetBlocks.push(block);
    clientsWithUninvoicedTime[block.projectId].timesheetBlocks.push(block);
  }

  switch (groupBy) {
    case "project":
      Object.keys(projectsWithUninvoicedTime).forEach((projectId) => {
        let project = projects.find((x) => x.id === projectId);
        let client = clients.find((x) => x.id === project.clientId);
        let newRow = {
          sortKey: projectId,
          key: projectId,
          project,
          client,
          timesheetBlocks: projectsWithUninvoicedTime[projectId].timesheetBlocks,
        };

        newRow.hours = newRow.timesheetBlocks.reduce((sum, block) => {
          let durationHours = moment(block.endAt).diff(moment(block.startAt), "hours", true);
          return roundToQuarter(sum + durationHours);
        }, 0);
        newRow.hours = roundToQuarter(newRow.hours);

        rows.push(newRow);
      });

      break;
    case "client":
      Object.keys(clientsWithUninvoicedTime).forEach((clientId) => {
        let client = clients.find((x) => x.id === clientId);
        let newRow = {
          sortKey: client.name.toLowerCase(),
          key: clientId,
          client,
          timesheetBlocks: clientsWithUninvoicedTime[clientId].timesheetBlocks,
        };

        newRow.hours = newRow.timesheetBlocks.reduce((sum, block) => {
          let durationHours = moment(block.endAt).diff(moment(block.startAt), "hours", true);
          return roundToQuarter(sum + durationHours);
        }, 0);
        newRow.hours = roundToQuarter(newRow.hours);

        rows.push(newRow);
      });
      break;
    default:
      break;
  }

  rows.forEach((row) => {
    row.hasTimesheetBlocksWithoutQuote = row.timesheetBlocks.some((block) => {
      if (block.variation) {
        return false;
      }

      if (!block.quoteLineItemId || !block.quoteId) {
        return true;
      }

      let quote = quotes.find((x) => x.id === block.quoteId);
      let quoteLineItem = quote?.lineItems.items.find((x) => x.id === block.quoteLineItemId);
      if (!quote || !quoteLineItem) {
        return true;
      }
      return false;
    });

    row.hasTimesheetBlocksLinkedToRejectedQuoteLineItems = row.timesheetBlocks.some((block) => {
      if (!block.quoteLineItemId || !block.quoteId) {
        return false;
      }

      let quote = quotes.find((x) => x.id === block.quoteId);
      let quoteLineItem = quote?.lineItems.items.find((x) => x.id === block.quoteLineItemId);

      if (!quote || !quoteLineItem) {
        return false;
      }

      return quoteLineItem.isRejected;
    });

    row.hasTimesheetBlocksLinkedToRejectedQuotes = row.timesheetBlocks.some((block) => {
      if (!block.quoteLineItemId || !block.quoteId) {
        return false;
      }

      let quote = quotes.find((x) => x.id === block.quoteId);
      let quoteLineItem = quote?.lineItems.items.find((x) => x.id === block.quoteLineItemId);

      if (!quote || !quoteLineItem) {
        return false;
      }

      return quote.status === "REJECTED";
    });

    row.hasTimesheetBlocksLinkedToFullyInvoicedQuotes = row.timesheetBlocks.some((block) => {
      if (block.variation) {
        return false;
      }
      if (!block.quoteLineItemId || !block.quoteId) {
        return false;
      }

      let quote = quotes.find((x) => x.id === block.quoteId);

      if (!quote) {
        return false;
      }
      const enhancedQuote = enhanceQuoteWithInvoicing(quote);

      return enhancedQuote?.invoicingStatus === "FULLY_INVOICED";
    });

    row.hasTimesheetBlocksOnHold = row.timesheetBlocks.some((block) => {
      return block.invoicingStatus === "ON_HOLD";
    });
  });

  if (timesheetBlocksWithoutProjects.length > 0) {
    let newRow = {
      key: `No ${getSimpleLabel("project")}`,

      project: {
        title: `No ${getSimpleLabel("project")}`,
      },
      timesheetBlocks: timesheetBlocksWithoutProjects,
    };
    newRow.hours = newRow.timesheetBlocks.reduce((sum, block) => {
      let durationHours = moment(block.endAt).diff(moment(block.startAt), "hours", true);
      return roundToQuarter(sum + durationHours);
    }, 0);
    newRow.hours = roundToQuarter(newRow.hours);

    rows.push(newRow);
  }

  return rows.sort((a, b) => (a.sortKey < b.sortKey ? -1 : 1));
}
