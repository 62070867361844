import React, { useState, useEffect } from "react";
import cx from "classnames";
import moment from "moment";
import { Typography, Dropdown, Menu, Button, Checkbox } from "antd";
import { DownOutlined } from "@ant-design/icons";
import DailyLogItem from "./DailyLogItem/DailyLogItem";

import "./DailyLog.scss";

export function DailyLog({
  organisationDetails,
  auditItems,
  tasks,
  projects,
  clients,
  users,
  viewerIsOwner,
  windowWidth,
}) {
  const [timeStart, setTimeStart] = useState(moment().startOf("day")); // eslint-disable-line
  const [timeEnd, setTimeEnd] = useState(moment().endOf("day")); // eslint-disable-line
  const [includePageViews, setIncludePageViews] = useState(true);
  const [includeSaves, setIncludeSaves] = useState(true);
  const [includePublishes, setIncludePublishes] = useState(true);
  const [includeLocation, setIncludeLocation] = useState(
    !!organisationDetails.settings?.timesheet?.recordLatLongEveryXMinutes
  );
  const containerRef = React.useRef();
  const [containerWidth, setContainerWidth] = useState(undefined);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    setContainerWidth(containerRef.current.offsetWidth);
  }, [windowWidth]);

  let filteredAuditItems = auditItems.filter((auditItem) => {
    if (!includePageViews && auditItem.type === "PAGE_VIEW") {
      return false;
    }

    if (!includeLocation && auditItem.type === "LOCATION") {
      return false;
    }

    if (!includeSaves && auditItem.type === "SAVE") {
      return false;
    }

    if (!includePublishes && auditItem.type === "PUBLISH") {
      return false;
    }

    return true;
  });

  let auditItemsInTimeRange = filteredAuditItems.filter((auditItem) => {
    let targetStartHour = parseFloat(timeStart.format("k"));
    if (targetStartHour === 24) {
      targetStartHour = 0;
    }
    let targetStartMinute = parseFloat(timeStart.format("m"));
    targetStartHour += targetStartMinute / 60;

    let targetEndHour = parseFloat(timeEnd.format("k"));
    let targetEndMinute = parseFloat(timeEnd.format("m"));
    targetEndHour += targetEndMinute / 60;

    let itemHour = parseFloat(moment(auditItem.createdAt).format("k"));
    let itemMinute = parseFloat(moment(auditItem.createdAt).format("m"));

    itemHour += itemMinute / 60;

    return itemHour >= targetStartHour && itemHour <= targetEndHour;
  });

  return (
    <div
      className={cx("daily-log", {
        "read-only": !viewerIsOwner,
        compact: containerWidth !== undefined && containerWidth < 400,
      })}
      data-cy="daily-log"
      ref={containerRef}
    >
      <Typography.Text className="title" data-cy="title">
        Daily log{" "}
        <Dropdown
          trigger="click"
          overlay={
            <Menu>
              <Menu.Item key="page-views">
                <Checkbox checked={includePageViews} onChange={(e) => setIncludePageViews(e.target.checked)}>
                  Include page views
                </Checkbox>
              </Menu.Item>
              {organisationDetails.settings?.timesheet?.recordLatLongEveryXMinutes && (
                <Menu.Item key="location">
                  <Checkbox checked={includeLocation} onChange={(e) => setIncludeLocation(e.target.checked)}>
                    Include location updates
                  </Checkbox>
                </Menu.Item>
              )}
              <Menu.Item key="saves">
                <Checkbox checked={includeSaves} onChange={(e) => setIncludeSaves(e.target.checked)}>
                  Include saves
                </Checkbox>
              </Menu.Item>
              <Menu.Item key="publishes">
                <Checkbox checked={includePublishes} onChange={(e) => setIncludePublishes(e.target.checked)}>
                  Include publishes
                </Checkbox>
              </Menu.Item>
            </Menu>
          }
        >
          <Button icon={<DownOutlined />}>Filters</Button>
        </Dropdown>
        {/* <TimePicker.RangePicker
          value={[timeStart, timeEnd]}
          format="HH:mm"
          minuteStep={30}
          onChange={(timeRange) => {
            if (!timeRange) {
              setTimeStart(moment(targetDate).startOf("day"));
              setTimeEnd(moment(targetDate).endOf("day"));
            } else {
              setTimeStart(timeRange[0]);
              setTimeEnd(timeRange[1]);
            }
          }}
        /> */}
      </Typography.Text>

      <div className="log-items-container">
        {auditItemsInTimeRange?.map((auditItem) => {
          return (
            <div
              draggable
              key={auditItem.id}
              onDragStart={(e) => {
                e.dataTransfer.setData("audit-item", JSON.stringify(auditItem));
                e.dataTransfer.setData("task-id", auditItem.taskId);
                e.dataTransfer.setData("draggable-type", "task");
              }}
              data-task-id={auditItem.taskId}
              data-cy="log-item"
            >
              <DailyLogItem auditItem={auditItem} tasks={tasks} projects={projects} clients={clients} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default React.memo(DailyLog);
