import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { Modal, Button, Typography, Divider, Space, message, Row, Col } from "antd";
import { withRouter } from "react-router-dom";

import { isAuthorised } from "common/permissions";
import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";

import Card from "Card/Card";

import "./SupplierActions.scss";

export function SupplierActions(props) {
  const { supplier, history, purchaseOrders } = props;

  async function confirmSupplierDelete() {
    const purchaseOrdersInSupplier = purchaseOrders.filter((x) => x.supplierId === supplier.id);
    if (purchaseOrdersInSupplier.length !== 0) {
      let modal = Modal.error({
        title: "Cannot delete supplier",
        className: "cannot-delete-supplier-modal",
        content: (
          <>
            <b>{supplier.name}</b> has the following purchase orders associated to it, and thus cannot be deleted:
            <ul className="purchase-orders-which-use-supplier">
              {purchaseOrdersInSupplier.map((purchaseOrder) => (
                <li
                  key={purchaseOrder.id}
                  onClick={() => {
                    modal.destroy();
                    history.push(`/purchase-orders/${purchaseOrder.id}`);
                  }}
                >
                  {purchaseOrder.title}
                </li>
              ))}
            </ul>
          </>
        ),
      });
      return;
    }

    Modal.confirm({
      title: "Confirm supplier delete",
      icon: <ExclamationCircleOutlined />,
      className: "delete-supplier-modal",
      content: (
        <>
          Are you sure you want to delete <b>{supplier.name}</b>?
        </>
      ),
      onOk: () => deleteSelectedSupplier(supplier),
    });
  }

  async function deleteSelectedSupplier(supplier) {
    try {
      await callGraphQLSimple({
        message: "Could not delete supplier",
        queryName: "deleteSupplier",
        variables: {
          input: {
            id: supplier.id,
          },
        },
      });

      history.push(`/suppliers`);
    } catch (e) {
      message.error(
        <Typography.Text>
          Supplier <b>{supplier.name}</b> could not be deleted: <b>{e.message}</b>
        </Typography.Text>
      );
    }
  }

  return (
    <Card className="supplier-actions">
      <Typography.Title level={4}>
        <Space>
          <Typography.Text>Supplier actions</Typography.Text>
        </Space>
      </Typography.Title>
      <Divider />

      <>
        <Row align="middle">
          <Col span={16}>
            <Typography.Paragraph className="main-label">Delete this supplier</Typography.Paragraph>
            <Typography.Paragraph className="description">
              Once you delete a supplier, there is no going back. Please be certain.
            </Typography.Paragraph>
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Button
              disabled={!isAuthorised(["SUPPLIER.DELETE"])}
              icon={<DeleteOutlined />}
              type="danger"
              onClick={confirmSupplierDelete}
              data-cy="delete-supplier-button"
            >
              Delete supplier
            </Button>
          </Col>
        </Row>
      </>
    </Card>
  );
}

export default withRouter(
  withSubscriptions({
    Component: SupplierActions,
    subscriptions: ["organisationDetails", "supplier", "tasks", "users", "projects"],
  })
);
