import Attachments from "Attachments/Attachments";
import withSubscriptions from "common/withSubscriptions";
import { withRouter } from "react-router-dom";
import React from "react";
import "./DocumentLibraryPage.scss";

export class DocumentLibraryPage extends React.Component {
  componentDidMount() {
    this.props.setBoxedLayout(false);

    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "DOCUMENT_LIBRARY",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
  }

  componentWillUnmount() {
    this.props.setBoxedLayout(true);
  }

  render() {
    const { apiUser, windowWidth, windowHeight } = this.props;

    return (
      <div className="document-library-page">
        <Attachments
          apiUser={apiUser}
          isDocumentLibrary={true}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
          includeCopyLinkButton
        />
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: DocumentLibraryPage,
    subscriptions: ["organisationDetails", "windowWidth", "windowHeight"],
  })
);
