import { withRouter } from "react-router-dom";

import { Select } from "antd";
import { Project, User, Client } from "common/types";

import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";

type Props = {
  apiUser: User;
  clients: Client[];
  projects: Project[];
  onChange: (projectId: string) => void;
  predefinedFields: any;
  value: any;
  allowClear?: boolean;
  disabled?: boolean;
};

export function ProjectPicker(props: Props) {
  const { apiUser, clients, projects, predefinedFields, onChange, allowClear = true, disabled } = props;
  const filteredProjects = projects.filter(
    (x) => x.organisation === apiUser.organisation && !x.isArchived && !x.isFinished && !x.id.includes("TEMPLATES")
  );

  return (
    <Select
      showSearch
      data-cy={props["data-cy"] || "project-selector"}
      placeholder={`Choose a ${getSimpleLabel("project")}`}
      className="project-selector"
      disabled={predefinedFields?.projectId || disabled}
      defaultValue={predefinedFields?.projectId}
      value={props.value}
      filterOption={(input, option) => {
        return option?.children ? option.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0 : false;
      }}
      onChange={onChange}
      allowClear={allowClear}
    >
      {filteredProjects.map((project, i) => {
        const client = clients.find((x) => x.id === project.clientId);

        if (!client) {
          return null;
        }

        return (
          <Select.Option key={i} value={project.id}>
            {project.title} ({client.name})
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default withRouter(
  withSubscriptions({
    Component: ProjectPicker,
    subscriptions: ["projects", "clients"],
  })
);
