import { useEffect } from "react";
import { useGetSetState } from "react-use";
import moment from "moment";
import { message, Table, Button, Modal, TimePicker, Form, Select, Switch } from "antd";
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { formatTime, formatDuration } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { WEEKDAY_OPTIONS } from "common/constants";

import InfoItem from "InfoItem/InfoItem";
import DatePicker from "DatePicker/DatePicker";

import "./WorkingHours.scss";

export default function WorkingHours({ parent, organisationDetails }) {
  const [getState, setState] = useGetSetState({
    workingHours: [],
    isModalOpen: false,
    isSubmitting: false,
    selectedRule: null,
    isShowingOldRules: false,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    fetchAndSetWorkingHours();
  }, []);

  useEffect(() => {
    const { selectedRule, isModalOpen } = getState();

    if (isModalOpen) {
      if (selectedRule) {
        setTimeout(() => {
          let totalInitialBreakTime;

          if (selectedRule.breaks) {
            totalInitialBreakTime = selectedRule.breaks.reduce((acc, curr) => {
              return acc + moment(curr.endTime, "HH:mm").diff(moment(curr.startTime, "HH:mm"), "minutes");
            }, 0);

            // format the number of minutes as a dayjs object
            totalInitialBreakTime = dayjs().hour(0).minute(totalInitialBreakTime);
          }

          form.setFieldsValue({
            dayOfWeek: selectedRule.dayOfWeek,
            startDate: moment(selectedRule.startDate),
            endDate: moment(selectedRule.endDate),
            startTime: dayjs(selectedRule.startTime, "HH:mm"),
            endTime: dayjs(selectedRule.endTime, "HH:mm"),
            totalBreakTime: totalInitialBreakTime,
          });
        }, 0);
      } else {
        form.setFieldsValue({
          dayOfWeek: 1,
          startDate: moment(),
          endDate: moment().add(10, "year"),
          startTime: dayjs("09:00", "HH:mm"),
          endTime: dayjs("18:00", "HH:mm"),
          totalBreakTime: dayjs("01:00", "HH:mm"),
        });
      }
    }
  }, [getState().isModalOpen]);

  async function fetchAndSetWorkingHours() {
    const workingHours = (
      await callGraphQLSimple({
        query: "listWorkingHoursRules",
        variables: {
          limit: 1000,
          filter: {
            parentId: {
              eq: parent.id,
            },
          },
        },
      })
    ).data.listWorkingHoursRules.items;
    setState({ workingHours });
  }

  async function confirmDeleteRule(rule) {
    let totalBreakTime = 0;
    let formattedTotalBreakTime = "-";

    if (rule.breaks) {
      totalBreakTime = rule.breaks.reduce((acc, curr) => {
        return acc + moment(curr.endTime, "HH:mm").diff(moment(curr.startTime, "HH:mm"), "minutes");
      }, 0);
      let breakDuration = moment.duration(totalBreakTime, "minutes");
      formattedTotalBreakTime = formatDuration(breakDuration);
    }

    Modal.confirm({
      title: "Are you sure you want to delete this working hours rule?",
      content: (
        <>
          <InfoItem label="Day of the week" value={moment().day(rule.dayOfWeek).format("dddd")} />
          <InfoItem label="Start date" value={moment(rule.startDate).format("DD-MM-YYYY")} />
          <InfoItem label="End date" value={moment(rule.endDate).format("DD-MM-YYYY")} />
          <InfoItem label="Start time" value={rule.startTime} />
          <InfoItem label="End time" value={rule.endTime} />
          <InfoItem label="Total break time" value={formattedTotalBreakTime} />
        </>
      ),
      onOk: async () => {
        try {
          await callGraphQLSimple({
            message: "Failed to delete working hours rule",
            mutation: "deleteWorkingHoursRule",
            variables: {
              input: {
                id: rule.id,
              },
            },
          });
          fetchAndSetWorkingHours();
        } catch (e) {
          console.error(e);
        }
      },
    });
  }

  async function onSubmitModal({ startDate, endDate, startTime, endTime, totalBreakTime, dayOfWeek }) {
    let startTimeFormatted = startTime.format("HH:mm");
    let endTimeFormatted = endTime.format("HH:mm");
    // if start date is before end date, display error
    if (moment(endDate).isBefore(moment(endTime))) {
      message.error("Start date cannot be after the end date");
      return;
    }

    if (endTimeFormatted < startTimeFormatted) {
      message.error("Start time cannot be after the end time");
      return;
    }

    if (endTimeFormatted === startTimeFormatted) {
      message.error("Start time and end time cannot be the same");
      return;
    }

    const totalBreakTimeMinutes = totalBreakTime ? totalBreakTime.hour() * 60 + totalBreakTime.minute() : 0;
    let totalDurationMinutes = moment(endTimeFormatted, "HH:mm").diff(moment(startTimeFormatted, "HH:mm"), "minutes");
    if (totalBreakTimeMinutes > totalDurationMinutes) {
      message.error("Break time cannot be longer than the total working hours");
      return;
    }
    if (totalBreakTimeMinutes === totalDurationMinutes) {
      message.error("Break time cannot be equal to the total working hours");
      return;
    }

    setState({ isSubmitting: true });

    let variables = {
      input: {
        organisation: organisationDetails.id,
        parentId: parent.id,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        startTime: startTimeFormatted,
        endTime: endTimeFormatted,
        breaks: totalBreakTime
          ? [
              {
                id: "1",
                startTime: "10:00",
                endTime: moment().hour(10).minute(0).add(totalBreakTimeMinutes, "minute").format("HH:mm"),
              },
            ]
          : null,
        dayOfWeek,
      },
    };

    if (getState().selectedRule) {
      variables.input.id = getState().selectedRule.id;
    }

    try {
      await callGraphQLSimple({
        message: "Failed to save working hours rule",
        mutation: getState().selectedRule ? "updateWorkingHoursRule" : "createWorkingHoursRule",
        variables,
      });
      form.resetFields();
      setState({ isModalOpen: false, isSubmitting: false, selectedRule: null });
      fetchAndSetWorkingHours();
    } catch (e) {
      setState({ isSubmitting: false });
    }
  }

  const { workingHours, isSubmitting, selectedRule, isModalOpen, isShowingOldRules } = getState();

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  let modalTitle = selectedRule ? "Edit rule" : "Add rule";

  let tableDataSource = [
    ...workingHours.sort((a, b) => {
      if (a.startDate < b.startDate) {
        return -1;
      }

      if (a.startDate > b.startDate) {
        return 1;
      }

      if (a.dayOfWeek < b.dayOfWeek) {
        return -1;
      }

      if (a.dayOfWeek > b.dayOfWeek) {
        return 1;
      }

      if (a.endDate < b.endDate) {
        return -1;
      }

      if (a.endDate > b.endDate) {
        return 1;
      }

      return 0;
    }),
  ];
  if (!isShowingOldRules) {
    tableDataSource = tableDataSource.filter((rule) => moment(rule.endDate).isAfter(moment()));
  }

  return (
    <>
      <div className="working-hours">
        <Table
          rowKey="id"
          title={() => {
            return (
              <div className="table-header">
                <Button
                  icon={<PlusCircleOutlined />}
                  type="primary"
                  onClick={() => {
                    setState({ isModalOpen: true });
                  }}
                >
                  Add rule
                </Button>
                <InfoItem
                  label="Show past rules"
                  inline
                  value={
                    <Switch
                      size="small"
                      checked={isShowingOldRules}
                      onChange={(checked) => {
                        setState({ isShowingOldRules: checked });
                      }}
                    />
                  }
                />
              </div>
            );
          }}
          dataSource={tableDataSource}
          columns={[
            {
              title: "Day of the week",
              dataIndex: "dayOfWeek",
              key: "dayOfWeek",
              render: (dayOfWeek) => moment().day(dayOfWeek).format("dddd"),
            },
            {
              title: "Time including breaks",
              key: "timeIncludingBreaks",
              render: (_, rule) => {
                let totalTime = moment(rule.endTime, "HH:mm").diff(moment(rule.startTime, "HH:mm"), "minutes");
                let duration = moment.duration(totalTime, "minutes");
                return formatDuration(duration);
              },
            },
            {
              title: "Work duration",
              key: "timeExcludingBreaks",
              render: (_, rule) => {
                let totalTime = moment(rule.endTime, "HH:mm").diff(moment(rule.startTime, "HH:mm"), "minutes");
                let totalBreakTime = 0;
                if (rule.breaks) {
                  totalBreakTime = rule.breaks.reduce((acc, curr) => {
                    return acc + moment(curr.endTime, "HH:mm").diff(moment(curr.startTime, "HH:mm"), "minutes");
                  }, 0);
                }

                let duration = moment.duration(totalTime - totalBreakTime, "minutes");
                return formatDuration(duration);
              },
            },
            {
              title: "Break duration",
              key: "breakTime",
              render: (_, rule) => {
                let totalBreakTime = 0;
                if (rule.breaks) {
                  totalBreakTime = rule.breaks.reduce((acc, curr) => {
                    return acc + moment(curr.endTime, "HH:mm").diff(moment(curr.startTime, "HH:mm"), "minutes");
                  }, 0);
                }

                let duration = moment.duration(totalBreakTime, "minutes");
                return formatDuration(duration);
              },
            },
            {
              title: "Start date",
              dataIndex: "startDate",
              key: "startDate",
              render: (startDate) => moment(startDate).format("DD-MM-YYYY"),
            },
            {
              title: "End date",
              dataIndex: "endDate",
              key: "endDate",
              render: (endDate) => moment(endDate).format("DD-MM-YYYY"),
            },
            {
              title: "Start Time",
              dataIndex: "startTime",
              key: "startTime",
            },
            {
              title: "End Time",
              dataIndex: "endTime",
              key: "endTime",
            },
            {
              title: "",
              key: "actions",
              render: (_, rule) => {
                return (
                  <div className="row-actions">
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => {
                        setState({
                          isModalOpen: true,
                          selectedRule: rule,
                        });
                      }}
                    />
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        confirmDeleteRule(rule);
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          pagination={{ pageSize: 100, hideOnSinglePage: true }}
        />
      </div>
      <Modal
        title={modalTitle}
        footer={null}
        open={isModalOpen}
        onCancel={() => {
          form.resetFields();
          setState({ isModalOpen: false, selectedRule: null });
        }}
      >
        <Form layout="vertical" form={form} onFinish={onSubmitModal}>
          <Form.Item
            label="Day of the week"
            name="dayOfWeek"
            rules={[
              {
                required: true,
                message: "You must specify a day of the week",
              },
            ]}
          >
            <Select>
              {WEEKDAY_OPTIONS.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Start date"
            name="startDate"
            rules={[
              {
                required: true,
                message: "You must specify a start date",
              },
            ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            label="End date"
            name="endDate"
            rules={[
              {
                required: true,
                message: "You must specify an end date",
              },
            ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            label="Start time"
            name="startTime"
            rules={[
              {
                required: true,
                message: "You must specify a start time",
              },
            ]}
          >
            <TimePicker
              format="HH:mm"
              minuteStep={15}
              onSelect={(timeValue) => {
                form.setFieldsValue({ startTime: timeValue });
              }}
              renderExtraFooter={null}
              popupClassName="time-picker-no-ok-button"
            />
          </Form.Item>
          <Form.Item
            label="End time"
            name="endTime"
            rules={[
              {
                required: true,
                message: "You must specify an end time",
              },
            ]}
          >
            <TimePicker
              format="HH:mm"
              minuteStep={15}
              onSelect={(timeValue) => {
                form.setFieldsValue({ endTime: timeValue });
              }}
              renderExtraFooter={null}
              popupClassName="time-picker-no-ok-button"
            />
          </Form.Item>
          <Form.Item label="Total break time" name="totalBreakTime">
            <TimePicker
              showSecond={false}
              format={formatTime}
              minuteStep={15}
              showNow={false}
              placeholder="Select break duration"
              style={{ width: 250 }}
              onSelect={(timeValue) => {
                form.setFieldsValue({ totalBreakTime: timeValue });
              }}
              renderExtraFooter={null}
              popupClassName="time-picker-no-ok-button"
            />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
