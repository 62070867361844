import moment from "moment";
import { downloadAttachment } from "common/helpers";

export function handleCreationOfNewItem({ entityNameUpperCase, newItem }) {
  if (entityNameUpperCase === "Notification" && newItem.userId === window.apiUser.id) {
    handleNewNotification(newItem);
  }
}

async function handleNewNotification(notification) {
  if (notification.type === "ATTACHMENTS_READY") {
    let fileName = "attachments";
    if (notification.parentId) {
      fileName += `-${notification.parentId}`;
    }

    fileName += `-${moment().format("DD-MMM-YYYY")}.zip`;

    if (document.visibilityState === "visible") {
      window.callGraphQLSimple({
        mutation: "deleteNotification",
        variables: {
          input: {
            id: notification.id,
          },
        },
      });

      await downloadAttachment({ key: notification.link }, fileName);
    }
  } else {
    new Notification("Notification", { body: notification.message });
  }
}
