import moment from "moment";
import { message, Typography, notification, Tag } from "antd";
import { callGraphQLSimple } from "common/apiHelpers";
import { RRule } from "rrule";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";
import { sendTimeOffApprovalNotification, sendTimeOffDeletionNotification } from "common/notificationHelpers";

export function getUserTimeOffDataForDisplay({ users, holidays, interval, isSick }) {
  let validHolidays = [...holidays].sort((a, b) => (a.startsAt < b.startsAt ? -1 : 1));

  if (isSick) {
    validHolidays = validHolidays.filter((holiday) => holiday.isSick);
  } else {
    validHolidays = validHolidays.filter((holiday) => !holiday.isSick);
  }

  if (interval) {
    validHolidays = validHolidays.filter((holiday) => {
      return moment(holiday.startsAt).isBetween(interval.startDate, interval.endDate);
    });
  }

  let tableData = validHolidays.map((holiday) => {
    let temporalStatus = "";
    if (moment(holiday.startsAt).isBefore(moment(), "day")) {
      if (moment(holiday.endsAt).isAfter(moment(), "day")) {
        temporalStatus = "In progress";
      } else {
        temporalStatus = "Past";
      }
    } else {
      temporalStatus = "Future";
    }

    let approvalStatusTag = null;
    switch (holiday.status) {
      case "PENDING":
        approvalStatusTag = <Tag color="orange">Pending</Tag>;
        break;
      case "APPROVED":
        const approvedByUser = users.find((x) => x.id === holiday.approvedBy);
        approvalStatusTag = (
          <Tag color="green">
            Approved by {approvedByUser?.firstName} {approvedByUser?.lastName}
          </Tag>
        );
        break;
      case "REJECTED":
        const rejectedByUser = users.find((x) => x.id === holiday.rejectedBy);
        approvalStatusTag = (
          <Tag color="red">
            Rejected by {rejectedByUser?.firstName} {rejectedByUser?.lastName}
          </Tag>
        );
        break;
      default:
        break;
    }

    let numberOfDays = 0;

    holiday.days.forEach((day) => {
      if (day.endHours) {
        const numberOfHours = day.endHours - day.startHours;

        numberOfDays += numberOfHours / TIMELINE_DEFAULT_HOURS_IN_A_DAY;
      } else {
        numberOfDays += 1;
      }
    });

    return {
      ...holiday,
      key: holiday.userId,
      numberOfDays,
      dayList: holiday.days,
      approvalStatus: approvalStatusTag,
      temporalStatus,
      originalHoliday: holiday,
    };
  });

  return tableData;
}

export function getWorkingAndNonWorkingDaysForUser({ user, startDate, endDate }) {
  let nonWorkingDays = [];
  let workingDays = [];
  (user.workingHours || [])
    .filter((rule) => rule.repeatPattern?.length > 0)
    .forEach((rule) => {
      try {
        const rruleOptions = RRule.parseText(rule.repeatPattern);
        const startDateUTC = moment(startDate).add(moment().utcOffset(), "minutes").utc();
        const endDateUTC = moment(endDate).add(moment().utcOffset(), "minutes").utc();

        rruleOptions.dtstart = startDateUTC.toDate();
        rruleOptions.until = endDateUTC.toDate();
        const rrule = new RRule(rruleOptions);

        const occurences = rrule.all();
        workingDays.push(...occurences.map((dateObj) => moment(dateObj).format("YYYY-MM-DD")));
      } catch (e) {
        notification.error({
          message: (
            <Typography.Text>
              Failed to parse working hours rule:
              <br />
              <b>{rule.repeatPattern}</b>
            </Typography.Text>
          ),
        });
      }
    });
  return {
    workingDays,
    nonWorkingDays,
  };
}

export async function approveTimeOff({ holiday, apiUser, users }) {
  const label = holiday.isSick ? "sick day" : "holiday";
  let messageKey = "approving-holiday";
  message.loading({
    content: `Approving ${label}...`,
    key: messageKey,
    duration: 0,
  });
  try {
    await callGraphQLSimple({
      message: `Failed to approve ${label}`,
      mutation: "updateHoliday",
      variables: {
        input: {
          id: holiday.id,
          approvedAt: new Date().toISOString(),
          approvedBy: apiUser.id,
          status: "APPROVED",
        },
      },
    });

    let days = holiday.dayList || holiday.days;

    for (let day of days) {
      let endHours = 8;

      if (day.endHours) {
        endHours = day.endHours;
      }

      let inputForCreateTimesheetBlock = {
        invoiceId: "nothing",
        quoteId: "nothing",
        projectId: "nothing",
        taskId: label,
        userId: holiday.userId,
        organisation: holiday.organisation,
        startAt: moment(day.day).startOf("day").add(9, "hour").toISOString(),
        endAt: moment(day.day)
          .startOf("day")
          .add(9 + endHours, "hour")
          .toISOString(),
      };

      await callGraphQLSimple({
        message: "Failed to create timesheet block",
        mutation: "createTimesheetBlock",
        variables: {
          input: inputForCreateTimesheetBlock,
        },
      });
    }

    message.success({
      content: `${holiday.isSick ? "Sick day" : "Holiday"} approved and timesheet auto-filled`,
      key: messageKey,
      duration: 5,
    });
  } catch (e) {
    try {
      await callGraphQLSimple({
        message: `Failed to approve ${label}`,
        mutation: "updateHoliday",
        variables: {
          input: {
            id: holiday.id,
            approvedAt: null,
            approvedBy: null,
            status: "PENDING",
          },
        },
      });
    } catch (e) {
      // nothing we can do
    }

    message.error({
      content: `Failed to approve ${label}`,
      key: messageKey,
      duration: 5,
    });
    console.error(e);
  }
  await sendTimeOffApprovalNotification({
    requesterId: holiday.userId,
    approvedById: apiUser.id,
    users,
    isSick: holiday.isSick,
    startsAt: moment(holiday.startsAt).startOf("day").add(12, "hours").format("DD-MM-YYYY"),
    endsAt: moment(holiday.endsAt).startOf("day").add(12, "hours").format("DD-MM-YYYY"),
  });
}

export async function deleteTimeOff({ holiday, apiUser, users }) {
  await window.callGraphQLSimple({
    message: `Failed to delete ${holiday.isSick ? "sick day" : "holiday"}`,
    mutation: "deleteHoliday",
    variables: {
      input: {
        id: holiday.id,
      },
    },
  });

  let holidayTimesheetBlocks = (
    await callGraphQLSimple({
      message: "Failed to fetch timesheet blocks",
      queryName: "listTimesheetBlocksByTask",
      variables: {
        taskId: "holiday",
        limit: 1000,
        startAt: {
          between: [
            moment(holiday.startsAt).startOf("day").toISOString(),
            moment(holiday.endsAt).endOf("day").toISOString(),
          ],
        },
      },
    })
  ).data.listTimesheetBlocksByTask.items;

  holidayTimesheetBlocks = holidayTimesheetBlocks.filter((block) => block.userId === holiday.userId);

  let deletePromises = [];

  holidayTimesheetBlocks.forEach((block) => {
    deletePromises.push(
      callGraphQLSimple({
        message: "Failed to delete timesheet block",
        queryName: "deleteTimesheetBlock",
        variables: {
          input: {
            id: block.id,
          },
        },
      })
    );
  });

  await Promise.all(deletePromises);

  await sendTimeOffDeletionNotification({
    requesterId: holiday.userId,
    deletedById: apiUser.id,
    users,
    isSick: holiday.isSick,
    startsAt: moment(holiday.startsAt).startOf("day").add(12, "hours").format("DD-MM-YYYY"),
    endsAt: moment(holiday.endsAt).startOf("day").add(12, "hours").format("DD-MM-YYYY"),
    timeOffStatus: holiday.status,
  });
}
