import { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { CaretDownOutlined } from "@ant-design/icons";

import { Dropdown, Drawer, Menu } from "antd";
import cx from "classnames";
import { MenuIcon } from "common/icons";
import CreateTaskModal from "CreateTaskModal/CreateTaskModal";
import withSubscriptions from "common/withSubscriptions";
import { CloseIcon } from "common/icons";
import { isAuthorised } from "common/permissions";

import "./Navigation.scss";
import { getSimpleLabel } from "common/labels";

export function Navigation({
  apiUser,
  user,
  organisationDetails,
  windowWidth,
  history,
  shouldHaveLeftMargin,
  isMobile,
}) {
  const [isCreateTaskModalVisible, setIsCreateTaskModalVisible] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const path = history.location.pathname;

  if (!apiUser) {
    return null;
  }

  function getAdminMenuItems() {
    const adminMenuItems = [];

    if (apiUser.isHidden || window.location.hostname === "localhost") {
      adminMenuItems.push({
        key: "monitoring",
        label: (
          <Link
            to="/monitoring"
            className={cx("nav-item", {
              selected: path.startsWith("/monitoring"),
            })}
            data-cy="navigation-monitoring"
          >
            <span className="navigation-item-label">Monitoring</span>
          </Link>
        ),
      });
    }

    if (isAuthorised(["USERS.VIEW"])) {
      adminMenuItems.push({
        key: "users",
        label: (
          <Link
            to="/users"
            className={cx("nav-item", {
              selected: path.startsWith("/users"),
            })}
            data-cy="navigation-users"
          >
            <span className="navigation-item-label">Users</span>
          </Link>
        ),
      });
    }

    if (organisationDetails.settings?.timesheet?.usesTimesheets && isAuthorised(["TIMESHEETS.VIEW"])) {
      adminMenuItems.push({
        key: "timesheets",
        label: (
          <Link
            to="/timesheets"
            className={cx("nav-item", {
              selected: path.startsWith("/timesheets"),
            })}
            data-cy="navigation-timesheets"
          >
            <span className="navigation-item-label">Timesheets</span>
          </Link>
        ),
      });
    }

    if (isAuthorised(["TIME_OFF.VIEW"]) && organisationDetails.settings?.general?.usesTimeOff) {
      adminMenuItems.push({
        key: "Time off",
        label: (
          <Link
            to="/timeoff"
            className={cx("nav-item", {
              selected: path.startsWith("/timeoff"),
            })}
            data-cy="navigation-time-off"
          >
            <span className="navigation-item-label">Time off</span>
          </Link>
        ),
      });
    }

    if (isAuthorised(["ANALYTICS.VIEW"])) {
      adminMenuItems.push({
        key: "analytics",
        label: (
          <Link
            to="/analytics"
            className={cx("nav-item", {
              selected: path.startsWith("/analytics"),
            })}
            data-cy="navigation-analytics"
          >
            <span className="navigation-item-label">Analytics</span>
          </Link>
        ),
      });
    }

    if (isAuthorised(["ORGANISATION_DOCUMENTS.VIEW"])) {
      adminMenuItems.push({
        key: "reports",
        label: (
          <Link
            to="/reports"
            className={cx("nav-item", {
              selected: path.startsWith("/reports"),
            })}
            data-cy="navigation-reports"
          >
            <span className="navigation-item-label">Reports</span>
          </Link>
        ),
      });
    }

    if (isAuthorised(["MANAGE_GLOBAL_CONTACTS"])) {
      adminMenuItems.push({
        key: "organisation-contacts",
        label: (
          <Link
            to={`/contacts`}
            className={cx("nav-item", {
              selected: path.startsWith("/contacts"),
            })}
            data-cy="navigation-contacts"
          >
            <span className="navigation-item-label">Contacts</span>
          </Link>
        ),
      });
    }

    if (isAuthorised(["ORGANISATION_SETTINGS.VIEW"])) {
      adminMenuItems.push({
        key: "organisation-apps",
        label: (
          <Link
            to={`/organisation/apps`}
            className={cx("nav-item", {
              selected: path.startsWith("/organisation/apps"),
            })}
            data-cy="navigation-apps"
          >
            <span className="navigation-item-label">Third party apps</span>
          </Link>
        ),
      });
    }

    if (isAuthorised(["MANAGE_TEMPLATES"])) {
      adminMenuItems.push({
        key: "organisation-templates",
        label: (
          <Link
            to={`/organisation/templates`}
            className={cx("nav-item", {
              selected: path.startsWith("/organisation/templates"),
            })}
            data-cy="navigation-templates"
          >
            <span className="navigation-item-label">Templates</span>
          </Link>
        ),
      });
    }

    if (isAuthorised(["ORGANISATION_SETTINGS.VIEW"])) {
      adminMenuItems.push({
        key: "organisation-settings",
        label: (
          <Link
            to={`/organisation/settings`}
            className={cx("nav-item", {
              selected: path.startsWith("/organisation/settings"),
            })}
            data-cy="navigation-settings"
          >
            <span className="navigation-item-label">Settings</span>
          </Link>
        ),
      });
    }

    return adminMenuItems;
  }

  const adminMenuItems = getAdminMenuItems();
  let userHasSimpleMobileView = isAuthorised(["SIMPLE_MOBILE_VIEW"], undefined, true);

  let content = (
    <>
      {isAuthorised(["DASHBOARD.VIEW"]) && (
        <Link
          to="/tasks"
          className={cx("nav-item", { selected: path === "/" || path.startsWith("/tasks") })}
          data-cy="navigation-board"
        >
          <span className="navigation-item-label">{getSimpleLabel("Tasks")}</span>
        </Link>
      )}

      {organisationDetails.settings?.timeline?.usesTimeline && isAuthorised(["TIMELINE.VIEW"]) && (
        <Link
          to="/timeline"
          className={cx("nav-item", {
            selected: path.startsWith("/timeline"),
          })}
          data-cy="navigation-timeline"
        >
          <span className="navigation-item-label">{getSimpleLabel("Timeline")}</span>
        </Link>
      )}

      {organisationDetails.usesSprints && isAuthorised(["SPRINTS.VIEW"]) && (
        <Link
          to="/sprints"
          className={cx("nav-item", {
            selected: path.startsWith("/sprints"),
          })}
          data-cy="navigation-sprints"
        >
          <span className="navigation-item-label">{getSimpleLabel("Sprints")}</span>
        </Link>
      )}

      {organisationDetails.settings?.quote?.usesQuotes && isAuthorised(["QUOTES.VIEW"]) && (
        <Link
          to="/quotes"
          className={cx("nav-item", { selected: path.startsWith("/quotes") })}
          data-cy="navigation-quotes"
        >
          <span className="navigation-item-label">{getSimpleLabel("Quotes")}</span>
        </Link>
      )}

      {organisationDetails.settings?.invoice?.usesInvoices && isAuthorised(["INVOICES.VIEW"]) && (
        <Link
          to="/invoices"
          className={cx("nav-item", {
            selected: path.startsWith("/invoices"),
          })}
          data-cy="navigation-invoices"
        >
          <span className="navigation-item-label">{getSimpleLabel("Invoices")}</span>
        </Link>
      )}

      {organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders && isAuthorised(["PURCHASE_ORDERS.VIEW"]) && (
        <Link
          to="/purchase-orders"
          className={cx("nav-item", {
            selected: path.startsWith("/purchase-orders"),
          })}
          data-cy="navigation-purchase-orders"
        >
          <span className="navigation-item-label">{getSimpleLabel("Purchase orders")}</span>
        </Link>
      )}

      {organisationDetails.settings?.supplier?.usesSuppliers && isAuthorised(["SUPPLIERS.VIEW"]) && (
        <Link
          to="/suppliers"
          className={cx("nav-item", {
            selected: path.startsWith("/suppliers"),
          })}
          data-cy="navigation-suppliers"
        >
          <span className="navigation-item-label">{getSimpleLabel("Suppliers")}</span>
        </Link>
      )}

      {!organisationDetails.settings?.task?.automaticallyCreateProject && isAuthorised(["PROJECTS.VIEW"]) && (
        <Link
          to="/projects"
          className={cx("nav-item", { selected: path.startsWith("/projects") })}
          data-cy="navigation-projects"
        >
          <span className="navigation-item-label">{getSimpleLabel("Projects")}</span>
        </Link>
      )}

      {organisationDetails.settings?.documentLibrary?.usesDocumentLibrary &&
        isAuthorised(["DOCUMENT_LIBRARY.VIEW"]) && (
          <Link
            to="/document-library"
            className={cx("nav-item", {
              selected: path.startsWith("/document-library"),
            })}
            data-cy="navigation-document-library"
          >
            <span className="navigation-item-label">{getSimpleLabel("Library")}</span>
          </Link>
        )}

      {isAuthorised(["CLIENTS.VIEW"]) && (
        <Link
          to="/clients"
          className={cx("nav-item", { selected: path.startsWith("/clients") })}
          data-cy="navigation-clients"
        >
          <span className="navigation-item-label">{getSimpleLabel("Clients")}</span>
        </Link>
      )}
      {organisationDetails.settings?.request?.usesRequests && isAuthorised(["REQUESTS.VIEW"]) && (
        <Link
          to="/requests"
          className={cx("nav-item", { selected: path.startsWith("/requests") })}
          data-cy="navigation-requests"
        >
          <span className="navigation-item-label">{getSimpleLabel("Requests")}</span>
        </Link>
      )}

      {organisationDetails.settings?.stock?.usesStock && isAuthorised(["STOCK.VIEW_STOCK_PAGE"]) && (
        <Link
          to="/stock"
          className={cx("nav-item", { selected: path.startsWith("/stock") })}
          data-cy="navigation-stock"
        >
          <span className="navigation-item-label">{getSimpleLabel("Stock")}</span>
        </Link>
      )}
      {organisationDetails.usesFiles && isAuthorised(["QUEUE.VIEW"]) && (
        <Link
          to="/queue"
          className={cx("nav-item", { selected: path.startsWith("/queue") })}
          data-cy="navigation-queue"
        >
          <span className="navigation-item-label">Queue</span>
        </Link>
      )}
      {userHasSimpleMobileView &&
        isMobile &&
        organisationDetails.settings?.timesheet?.usesTimesheets &&
        isAuthorised(["USER_TIMESHEET.VIEW"]) && (
          <Link to="/user-timesheet" className={cx("nav-item")} data-cy="navigation-timesheet">
            <span className="navigation-item-label">Timesheet</span>
          </Link>
        )}
      {userHasSimpleMobileView &&
        isMobile &&
        organisationDetails.settings.general?.usesTimeOff &&
        isAuthorised(["TIME_OFF.REQUEST_OWN_HOLIDAYS"]) && (
          <Link to="/account?tab=holidays" className={cx("nav-item")} data-cy="navigation-holidays">
            <span className="navigation-item-label">Holidays</span>
          </Link>
        )}
      {userHasSimpleMobileView &&
        isMobile &&
        organisationDetails.settings.general?.usesTimeOff &&
        isAuthorised(["TIME_OFF.REQUEST_OWN_SICK_DAYS"]) && (
          <Link to="/account?tab=sickDays" className={cx("nav-item")} data-cy="navigation-sick-days">
            <span className="navigation-item-label">Sick days</span>
          </Link>
        )}

      {adminMenuItems.length > 0 &&
        (isMobile && !userHasSimpleMobileView ? (
          <Menu className="admin-menu" items={adminMenuItems} />
        ) : (
          <Dropdown overlayClassName="navigation-dropdown-menu" menu={{ items: adminMenuItems }} trigger="click">
            <span className={cx("nav-item")} data-cy="navigation-admin">
              <span className="navigation-item-label">
                Admin <CaretDownOutlined />
              </span>
            </span>
          </Dropdown>
        ))}
    </>
  );

  function toggleDrawer() {
    setIsDrawerVisible((visible) => !visible);
  }

  return (
    <>
      <div
        className={cx("navigation", {
          "with-left-margin": shouldHaveLeftMargin,
        })}
      >
        {isMobile && !userHasSimpleMobileView ? (
          <div className="menu-button" onClick={toggleDrawer}>
            <MenuIcon />
            <Drawer className="navigation-drawer" visible={isDrawerVisible} placement="left" closeIcon={<CloseIcon />}>
              {content}
            </Drawer>
          </div>
        ) : (
          content
        )}
      </div>
      {isCreateTaskModalVisible && (
        <CreateTaskModal onClose={() => setIsCreateTaskModalVisible(false)} apiUser={apiUser} />
      )}
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: Navigation,
    subscriptions: ["organisationDetails"],
  })
);
