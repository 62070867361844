import { Tag } from "antd";
import { REVIEW_SECONDARY_STATUS_READABLE } from "common/constants";

import "./ReviewSecondaryStatus.scss";

export default function ReviewSecondaryStatus({ taskRevision }) {
  let tag = null;
  if (taskRevision.reviewSecondaryStatus) {
    tag = (
      <Tag
        color={taskRevision.reviewSecondaryStatus === "INFO_REQUIRED" ? "orange" : "green"}
        className="review-secondary-status"
      >
        {REVIEW_SECONDARY_STATUS_READABLE[taskRevision.reviewSecondaryStatus]}
      </Tag>
    );
  }
  return tag;
}
