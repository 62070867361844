import {
  FontColorsOutlined,
  FontSizeOutlined,
  HighlightOutlined,
  EyeOutlined,
  ArrowRightOutlined,
  PlusOutlined,
  MinusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import cx from "classnames";
import DismissableTooltip, { hasAlreadyDisplayedTooltip } from "DismissableTooltip/DismissableTooltip";
import { REVIEW_MOBILE_BREAKPOINT } from "common/constants";

import "./ReviewSheetToolbar.scss";
import { getSimpleLabel } from "common/labels";

export const TOOLS = {
  text: "TEXT",
  leaderLine: "LEADER_LINE",
  freehand: "FREEHAND",
  rectangle: "RECTANGLE",
  ellipse: "ELLIPSE",
  line: "LINE",
  arrow: "ARROW",
  hideAnnotations: "HIDE_ANNOTATIONS",
  zoomPlus: "ZOOM_PLUS",
  zoomMinus: "ZOOM_MINUS",
  fitToWidth: "FIT_TO_WIDTH",
  fitToHeight: "FIT_TO_HEIGHT",
  // pageScroll: "PAGE_SCROLL",
  refresh: "REFRESH",
  highlightDifferences: "HIGHLIGHT_DIFFERENCES",
};

export const tools = [
  {
    name: TOOLS.text,
    icon: <FontSizeOutlined />,
    tooltip: "Create textbox",
    availableOn: ["SCROLLABLE", "ZOOMABLE"],
  },
  {
    name: TOOLS.leaderLine,
    icon: <FontColorsOutlined />,
    tooltip: "Create leader line",
    availableOn: ["SCROLLABLE", "ZOOMABLE"],
  },
  {
    name: TOOLS.freehand,
    icon: <HighlightOutlined />,
    tooltip: "Draw freehand",
    availableOn: ["SCROLLABLE", "ZOOMABLE"],
  },
  {
    name: TOOLS.rectangle,
    icon: <div className="icon icon-rectangle" />,
    tooltip: "Draw rectangle",
    availableOn: ["SCROLLABLE", "ZOOMABLE"],
  },
  {
    name: TOOLS.ellipse,
    icon: <div className="icon icon-ellipse" />,
    tooltip: "Draw ellipse",
    availableOn: ["SCROLLABLE", "ZOOMABLE"],
  },
  {
    name: TOOLS.line,
    icon: <div className="icon icon-line" />,
    tooltip: "Draw line",
    availableOn: ["SCROLLABLE", "ZOOMABLE"],
  },
  {
    name: TOOLS.arrow,
    icon: <ArrowRightOutlined />,
    tooltip: "Draw arrow",
    availableOn: ["SCROLLABLE", "ZOOMABLE"],
  },
  {
    name: TOOLS.zoomPlus,
    icon: <PlusOutlined />,
    tooltip: "Zoom in",
    alwaysUsable: true,
    clickOnly: true,
    availableOn: ["SCROLLABLE"],
  },
  {
    name: TOOLS.zoomMinus,
    icon: <MinusOutlined />,
    tooltip: "Zoom out",
    alwaysUsable: true,
    clickOnly: true,
    availableOn: ["SCROLLABLE"],
  },
  // {
  //   name: TOOLS.fitToWidth,
  //   icon: <ArrowsAltOutlined />,
  //   tooltip: "Fit to viewport - width",
  //   alwaysUsable: true,
  //   availableOn: ["SCROLLABLE"],
  //   clickOnly: true,
  // },
  // {
  //   name: TOOLS.fitToHeight,
  //   icon: <ShrinkOutlined />,
  //   tooltip: "Fit to viewport - height",
  //   alwaysUsable: true,
  //   availableOn: ["SCROLLABLE"],
  //   clickOnly: true,
  // },
  // {
  //   name: TOOLS.pageScroll,
  //   icon: <PageScrollIcon />,
  //   tooltip: "Toggle page-by-page scroll",
  //   alwaysUsable: true,
  //   availableOn: ["SCROLLABLE"],
  // },
  {
    name: TOOLS.hideAnnotations,
    icon: <EyeOutlined />,
    tooltip: "Hide annotations",
    alwaysUsable: true,
    availableOn: ["SCROLLABLE", "ZOOMABLE"],
  },
  {
    name: TOOLS.refresh,
    icon: <SyncOutlined />,
    tooltip: "Refresh document",
    alwaysUsable: true,
    availableOn: ["SCROLLABLE", "ZOOMABLE"],
    clickOnly: true,
    desktopOnly: true,
  },
  // {
  //   name: TOOLS.highlightDifferences,
  //   icon: <DiffIcon />,
  //   tooltip: `Highlight differences since previous ${getSimpleLabel("task revision")}`,
  //   alwaysUsable: true,
  //   availableOn: ["ZOOMABLE"],
  //   clickOnly: true,
  //   dismissableTooltip: {
  //     title: (
  //       <>
  //         You can now automatically highlight differences for this sheet since the last{" "}
  //         {getSimpleLabel("task revision")}. <br />
  //         <br />
  //         <b>Only works for CAD drawings for now.</b>
  //       </>
  //     ),
  //     cookie: "dismissable-review-cad-diff",
  //     placement: "bottom",
  //   },
  // },
];

export default function ReviewSheetToolbar({
  activeTool,
  onChange,
  onToolClick,
  enabled,
  contentType,
  enabledTools = [],
  disabledTools = [],
}) {
  function displayTool({ name, icon, tooltip, clickOnly, alwaysUsable, availableOn, dismissableTooltip, desktopOnly }) {
    let isDisabled = (!enabled && !alwaysUsable) || !availableOn.includes(contentType) || disabledTools.includes(name);
    if (isDisabled) {
      return null;
    }
    if (desktopOnly && window.innerWidth <= REVIEW_MOBILE_BREAKPOINT) {
      return null;
    }
    let button = (
      <div
        onClick={() => {
          if (clickOnly) {
            onToolClick(name);
          } else {
            onToolClick(name);
            onChange(activeTool === name ? null : name);
          }
        }}
        className={cx("tool", name, {
          active: activeTool === name || enabledTools.includes(name),
          disabled: isDisabled,
        })}
      >
        {icon}
      </div>
    );

    let content = button;
    if (dismissableTooltip) {
      if (hasAlreadyDisplayedTooltip(dismissableTooltip.cookie)) {
        content = button;
      } else {
        content = <DismissableTooltip {...dismissableTooltip}>{button}</DismissableTooltip>;
      }
    }

    return (
      <Tooltip placement="bottom" title={tooltip} key={`tool-${name}`} mouseEnterDelay={0.5}>
        {content}
      </Tooltip>
    );
  }

  return <div className={cx("review-sheet-toolbar")}>{tools.map((tool) => displayTool(tool))}</div>;
}
