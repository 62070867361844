import { Select } from "antd";

import InfoItem from "InfoItem/InfoItem";
import Input from "Input/Input";
import Condition from "../../Conditions/Condition/Condition";

import "./FormattingRule.scss";

const POSSIBLE_FIELDS = [
  {
    label: "Background color",
    fieldName: "custom_textFill",
    type: "COLOR",
  },

  {
    label: "Font color",
    fieldName: "fill",
    type: "COLOR",
    supportedOn: ({ object }) => {
      return object.type === "text";
    },
  },
  {
    label: "Font size",
    fieldName: "fontSize",
    type: "NUMBER",
    supportedOn: ({ object }) => {
      return object.type === "text";
    },
  },
  {
    label: "Border color",
    fieldName: "stroke",
    type: "COLOR",
  },
  {
    label: "Border thickness",
    fieldName: "strokeWidth",
    type: "NUMBER",
  },
  {
    label: "Background opacity %",
    fieldName: "custom_sectionBackgroundOpacity",
    type: "NUMBER",
    supportedOn: ({ object }) => {
      return object.custom_type === "section";
    },
  },
  {
    label: "Padding top",
    fieldName: "custom_paddingTop",
    type: "NUMBER",
  },
  {
    label: "Padding bottom",
    fieldName: "custom_paddingBottom",
    type: "NUMBER",
  },
  {
    label: "Padding left",
    fieldName: "custom_paddingLeft",
    type: "NUMBER",
  },
  {
    label: "Padding right",
    fieldName: "custom_paddingRight",
    type: "NUMBER",
  },

  {
    label: "Space above",
    fieldName: "custom_marginTop",
    type: "NUMBER",
  },
  {
    label: "Space below",
    fieldName: "custom_marginBottom",
    type: "NUMBER",
  },
  {
    label: "Space to the left",
    fieldName: "custom_marginLeft",
    type: "NUMBER",
  },
  {
    label: "Space to the right",
    fieldName: "custom_marginRight",
    type: "NUMBER",
  },
];

export default function FormattingRule({
  rule,
  index,
  updateRule,
  object,
  fileTypeDetails,
  form,
  organisationDetails,
}) {
  let inputElement = null;

  let fieldDetails = POSSIBLE_FIELDS.find((field) => field.fieldName === rule.custom_fieldName);
  //   console.log("fieldDetails = ", fieldDetails);
  //   console.log("rule = ", rule);

  switch (fieldDetails?.type) {
    case "COLOR":
      inputElement = (
        <Input
          defaultValue={rule.custom_fieldValue}
          showBorder
          fullWidth
          placeholder="e.g. #000000"
          onChange={(inputValue) => {
            updateRule({
              object,
              rule,
              index,
              fields: {
                custom_fieldValue: inputValue,
              },
            });
          }}
        />
      );
      break;
    case "NUMBER":
      inputElement = (
        <Input
          defaultValue={rule.custom_fieldValue}
          showBorder
          fullWidth
          placeholder="e.g. 0"
          onChange={(inputValue) => {
            updateRule({
              object,
              rule,
              index,
              fields: {
                custom_fieldValue: inputValue,
              },
            });
          }}
        />
      );
      break;

    default:
      break;
  }

  return (
    <div className="formatting-rule">
      <InfoItem
        label="Property"
        value={
          <Select
            value={rule.custom_fieldName}
            placeholder="Background color, font size, etc."
            onChange={(value) => {
              updateRule({
                object,
                rule,
                index,
                fields: {
                  custom_fieldName: value,
                },
              });
            }}
          >
            {POSSIBLE_FIELDS.filter((fieldDefinition) => {
              if (!fieldDefinition.supportedOn) {
                return true;
              }

              return fieldDefinition.supportedOn({ object });
            }).map((field, index) => {
              return (
                <Select.Option key={index} value={field.fieldName}>
                  {field.label}
                </Select.Option>
              );
            })}
          </Select>
        }
      />
      {fieldDetails && <InfoItem label="Value" value={inputElement} />}
      <Condition
        condition={rule}
        index={index}
        object={object}
        fileTypeDetails={fileTypeDetails}
        updateCondition={(params) => {
          updateRule({
            ...params,
            rule: params.condition,
          });
        }}
        form={form}
        organisationDetails={organisationDetails}
      />
    </div>
  );
}
