import { message, Tag } from "antd";
import { downloadZip } from "client-zip";

import { downloadBlob, getReadableStatus } from "common/helpers";
import { callRest, callGraphQLSimple } from "common/apiHelpers";
import { REVIEW_STATUS_READABLE } from "common/constants";
import { KEY_TYPES } from "common/shared";
import getS3File from "common/getS3File";
import { changeFileNameAtDownloadTime } from "common/naming";
import { getFilteredInvoices } from "common/filterHelpers";
import { isInvoiceFullyApproved } from "common/invoiceHelpers/sharedInvoiceHelpers";
// import { recalculateAmounts } from "common/invoiceHelpers";

export function getInvoicingStatusTag({ invoicingStatus, total, invoicedAmount, invoiceLineItems }) {
  if (!invoicingStatus) {
    if (!invoicedAmount) {
      if (invoiceLineItems?.length) {
        invoicingStatus = "PARTIALLY_INVOICED";
      } else {
        invoicingStatus = "NOT_INVOICED";
      }
    } else if (invoicedAmount < total) {
      invoicingStatus = "PARTIALLY_INVOICED";
    } else {
      invoicingStatus = "INVOICED";
    }
  }

  let formattedInvoicedAmount = window.formatCurrency("GBP", invoicedAmount);

  if (invoicingStatus === "FULLY_INVOICED" || invoicingStatus === "INVOICED") {
    let label = `Fully invoiced ${formattedInvoicedAmount}`;

    return <Tag color="#19aae8">{label}</Tag>;
  } else if (invoicingStatus === "PARTIALLY_INVOICED") {
    let label = `Partially invoiced (${formattedInvoicedAmount})`;
    return <Tag color="#ffbc0d">{label}</Tag>;
  } else {
    return <Tag color="#9db6bf">Not invoiced</Tag>;
  }
}

export async function recordActivityItem({ invoice, type, author, users, content }) {
  const reviewerDetails = users.find((user) => user.id === invoice.checkedBy);

  if (!content) {
    switch (type) {
      case "CREATED":
        content = `Invoice created`;
        break;
      case "STATUS_CHANGED":
        content = `Invoice status changed to ${getReadableStatus(invoice.status)}`;
        break;
      case "TOTAL_CHANGED":
        content = `Invoice total changed`;
        break;
      case "SENT":
        content = `Invoice status manually to "Sent"`;
        break;
      case "ACCEPTED":
        content = `Invoice approved`;
        break;
      case "REJECTED":
        content = `Invoice rejected`;
        break;
      case "VIEWED":
        content = `Invoice viewed`;
        break;
      case "REVIEW_ACCEPTED":
        content = `Invoice review approved`;
        break;
      case "REVIEW_REJECTED":
        content = `Invoice review rejected - ${REVIEW_STATUS_READABLE[invoice.reviewStatus].label}`;
        break;
      case "REVIEW_CLOSED":
        content = `Invoice review closed`;
        break;
      case "REVIEW_OPENED":
        content = `Invoice review opened. Reviewer is ${reviewerDetails.firstName} ${reviewerDetails.lastName}`;
        break;
      default:
        break;
    }
    content = content || type;
  }

  await callGraphQLSimple({
    message: "Failed to record activity item",
    mutation: "createInvoiceActivityItem",
    variables: {
      input: {
        invoiceId: invoice.id,
        total: invoice.total,
        type,
        organisation: invoice.organisation,
        author,
        content,
      },
    },
  });

  await callGraphQLSimple({
    queryCustom: "updateInvoice",
    variables: {
      input: {
        id: invoice.id,
        itemSubscription: Math.floor(Math.random() * 100000),
      },
    },
  });
}

export function getInvoicesThatNeedSecondReview({ invoices, clients, projects }) {
  let filteredInvoices = getFilteredInvoices({
    invoices,
    filter: {
      // we simulate the filter for "Only invoices that need second review"
      callbacks: [(invoice) => invoice.reviewApprovedAt && !invoice.secondReviewApprovedAt],
    },
    clients,
    projects,
  });

  return filteredInvoices;
}

export async function batchDownloadInvoices({ clients, projects, filteredInvoices, organisationDetails }) {
  let messageKey = "batch-download-invoices";

  try {
    if (filteredInvoices.length === 0) {
      message.info({ content: "There are no invoices to download" });
      return;
    }

    let filesForZip = [];

    message.loading({ content: "Downloading invoices ...", key: messageKey, duration: 0 });

    let counter = 0;

    for (let invoice of filteredInvoices) {
      counter++;
      if (!invoice.fileKey) {
        message.error(`Skipping invoice ${invoice.id} because it is corrupted`);
        continue;
      }

      if (!isInvoiceFullyApproved({ invoice, organisationDetails })) {
        await callRest({
          route: "/build-document",
          method: "POST",
          body: {
            organisationId: invoice.organisation,
            invoiceId: invoice.id,
            projectId: invoice.projectId,
            clientId: invoice.clientId,
          },
          includeCredentials: false,
        });
      }

      const fileName = await changeFileNameAtDownloadTime({
        organisation: invoice.organisation,
        fileName: invoice.fileKey.split("/").slice(-1)[0],
        type: KEY_TYPES.INVOICE,
        invoice,
        clients,
        projects,
      });
      const fileKey = invoice.fileKey.replace("public/", "").replace(".json", ".pdf");

      const invoicePDF = await fetch(await getS3File(fileKey));

      filesForZip.push({
        name: fileName,
        input: invoicePDF,
      });
      message.loading({
        content: `Downloading invoices ... ${counter} / ${filteredInvoices.length}`,
        key: messageKey,
        duration: 0,
      });
    }
    message.loading({ content: "Building archive ...", key: messageKey, duration: 0 });
    const resultArchive = await downloadZip(filesForZip).blob();

    let fileName = "DraughtHub invoices.zip";

    await downloadBlob({
      blob: resultArchive,
      fileName,
    });
    message.success({ content: "Invoices downloaded successfully", key: messageKey, duration: 5 });
  } catch (e) {
    console.error(e);
    message.error({ content: `Failed to download invoices: ${e.message}`, key: messageKey, duration: 5 });
  }
}

// export async function removeTimesheetBlockFromInvoice({ timesheetBlock, includeApproval = true }) {
//   if (includeApproval) {
//     try {
//       await new Promise((resolve, reject) => {
//         Modal.confirm({
//           title: "Confirm removal",
//           content: <>Are you sure you want to remove this timesheet block from the invoice? </>,
//           onOk: () => {
//             resolve();
//           },
//           onCancel: () => {
//             reject();
//           },
//         });
//       });
//     } catch (e) {
//       // nothing, the user just said no
//       return;
//     }
//   }

//   await callGraphQLSimple({
//     message: "Failed to update timesheet block",
//     mutation: "updateTimesheetBlock",
//     variables: {
//       input: {
//         id: timesheetBlock.id,
//         invoicingStatus: null,
//         invoiceId: "nothing",
//       },
//     },
//   });
// }
