import { useState } from "react";

import { Form, Modal, Button, Input } from "antd";

import "./CreateTagModal.scss";
import withSubscriptions from "common/withSubscriptions";

export function CreateTagModal({ onClose, visible, apiUser, organisationDetails, timesheetTags }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ label }) {
    setIsLoading(true);

    await window.callGraphQLSimple({
      message: "Failed to create timesheet tag",
      mutation: "createTimesheetTag",
      variables: {
        input: {
          organisation: organisationDetails.id,
          label,
        },
      },
    });

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  return (
    <Modal
      maskClosable={false}
      title="Add new tag"
      visible={visible}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="add-new-tag-modal"
    >
      <Form form={form} initialValues={{}} onFinish={onSubmit}>
        <Form.Item
          label="Tag"
          name="label"
          rules={[
            {
              required: true,
              message: "This field cannot be empty",
            },
            {
              validator: async (_, label) => {
                if (!label) {
                  return;
                }
                if (!form.getFieldValue("label")) {
                  return;
                }

                if (timesheetTags.find((item) => item.label === form.getFieldValue("label"))) {
                  throw new Error("You cannot add the same tag twice.");
                }
              },
            },
          ]}
        >
          <Input data-cy="new-tag-label-input" />
        </Form.Item>

        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="add-new-tag-button">
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}

export default withSubscriptions({
  Component: CreateTagModal,
  subscriptions: ["organisationDetails"],
});
