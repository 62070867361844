import { Typography, Select } from "antd";

import { callGraphQLSimple } from "common/apiHelpers";
import { TaskRevision } from "common/types";
import { isAuthorised } from "common/permissions";

type Props = {
  taskRevision: TaskRevision;
  organisationDetails: any;
};

export default function TaskRevisionPriority({ taskRevision, organisationDetails }: Props) {
  async function onChange(priorityId) {
    await callGraphQLSimple({
      message: "Failed to update priority",
      queryCustom: "updateTaskRevision",
      variables: {
        input: {
          id: taskRevision.id,
          priorityId,
        },
      },
    });

    await callGraphQLSimple({
      message: "Failed to update priority",
      queryCustom: "updateTask",
      variables: {
        input: {
          id: taskRevision.taskId,
          collectionSubscription: Math.floor(Math.random() * 100000),
        },
      },
    });
  }

  let shouldDisplayPicker = isAuthorised(["TASK_DETAILS.EDIT_PRIORITY"]);

  let currentPriority = organisationDetails.settings?.task?.priorities?.find((x) => x.id === taskRevision.priorityId);

  return (
    <>
      {!shouldDisplayPicker ? (
        currentPriority ? (
          currentPriority.name
        ) : (
          "Not Set"
        )
      ) : (
        <Select
          onChange={onChange}
          style={{ width: "100%" }}
          placeholder="Not set"
          className="active-on-hover"
          suffixIcon={null}
          defaultValue={taskRevision.priorityId}
        >
          {organisationDetails.settings?.task?.priorities?.map((priority) => (
            <Select.Option key={priority.id} value={priority.id}>
              {priority.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </>
  );
}
