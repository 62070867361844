import cx from "classnames";
import "./LaneContainer.scss";

export default function LaneContainer({ viewType, children }) {
  return (
    <div className={cx("lane-container", (viewType || "").toLowerCase())}>
      <div
        className={cx("lanes", (viewType || "").toLowerCase())}
        style={viewType === "BOARD" ? { minWidth: children.length * 220 } : undefined}
      >
        {children}
      </div>
    </div>
  );
}
