import { useState } from "react";
import { message, Modal, Input, Button } from "antd";
import { callGraphQLSimple } from "common/apiHelpers";
import { sendRequestMoreInformationRequestedNotification } from "common/notificationHelpers";

type Props = {
  apiUser: any;
  request: any;
  formFileId: string;
  formName: string;
  users: any;
  formCreationActivityItem: any;
};

export default function RequestInformationButton({
  request,
  apiUser,
  users,
  formFileId,
  formName,
  formCreationActivityItem,
}: Props) {
  const [isMoreInformationModalVisible, setIsMoreInformationModalVisible] = useState<boolean>(false);
  const [moreInformationMessageContent, setMoreInformationMessageContent] = useState<string>("");

  async function onMoreInformationModalSubmit() {
    if (!moreInformationMessageContent) {
      message.error("Please include a message");
      return;
    }

    const messageKey = "request-more-information";
    message.loading({ content: "Requesting more information...", key: messageKey, duration: 0 });

    try {
      setIsMoreInformationModalVisible(false);
      setMoreInformationMessageContent("");
      await callGraphQLSimple({
        mutation: "updateRequest",
        message: `Failed to update status`,
        variables: {
          input: {
            id: request.id,
            status: "MORE_INFORMATION_REQUESTED",
          },
        },
      });
    } catch (e) {
      message.error({
        content: "Failed to request more information",
        key: messageKey,
      });
      return;
    }

    try {
      await sendRequestMoreInformationRequestedNotification({
        users,
        request,
        apiUser,
        messageContent: moreInformationMessageContent,
        formName,
        formFileId,
        receiverId: formCreationActivityItem.author,
      });
    } catch (e) {
      message.error({
        content: "Failed to notify requester via email",
        key: messageKey,
      });
      try {
        await callGraphQLSimple({
          mutation: "updateRequest",
          displayError: false,
          variables: {
            input: {
              id: request.id,
              status: request.status,
            },
          },
        });
      } catch (e) {
        // nothing we can do about it
      }

      return;
    }

    try {
      await callGraphQLSimple({
        mutation: "createActivityItem",
        message: "Failed to record activity item",
        variables: {
          input: {
            parentId: request.id,
            author: apiUser.id,
            content: JSON.stringify({
              type: "MORE_INFORMATION_REQUESTED",
              message: moreInformationMessageContent,
              formFileId,
              formName,
            }),
            organisation: request.organisation,
          },
        },
      });
    } catch (e) {
      message.error({
        content: "Notification sent and status updated, but failed to create activity item",
        key: messageKey,
      });
    }

    try {
      await callGraphQLSimple({
        mutation: "updateRequest",
        displayError: false,
        variables: {
          input: {
            id: request.id,
            itemSubscription: Math.floor(Math.random() * 1000000000),
          },
        },
      });
    } catch (e) {
      window.location.reload();
    }

    message.success({
      content: "Notification sent and status updated",
      key: messageKey,
    });

    setIsMoreInformationModalVisible(false);
    setMoreInformationMessageContent("");
  }

  return (
    <>
      <Button type="primary" onClick={() => setIsMoreInformationModalVisible(true)}>
        Request more information
      </Button>
      <Modal
        title="Include a message for the requester"
        open={isMoreInformationModalVisible}
        onCancel={() => {
          setIsMoreInformationModalVisible(false);
          setMoreInformationMessageContent("");
        }}
        onOk={onMoreInformationModalSubmit}
      >
        <Input.TextArea
          value={moreInformationMessageContent}
          onChange={(e) => setMoreInformationMessageContent(e.target.value)}
        />
      </Modal>
    </>
  );
}
