import { useEffect } from "react";
import { useGetSetState } from "react-use";
import cx from "classnames";

import "./Tabs.scss";

export default function Tabs({ items, className, activeTab: activeTabProp, onTabClick, tabBarExtraContent = null }) {
  const [getState, setState] = useGetSetState({
    activeTab: items[0]?.id,
  });

  useEffect(() => {
    if (activeTabProp !== undefined && activeTabProp !== null && items.some((item) => item.id === activeTabProp)) {
      setState({ activeTab: activeTabProp });
    }
  }, [activeTabProp]);

  if (!items || !items.length) {
    return null;
  }

  const { activeTab } = getState();

  let activeItem;
  if (activeTab !== undefined) {
    activeItem = items.find((item) => item.id === activeTab);
  }

  let activeItemContent;
  if (activeItem && activeItem.content) {
    activeItemContent = activeItem.content;
  }

  let extraContentRight = null;
  if (tabBarExtraContent) {
    if (tabBarExtraContent.right) {
      extraContentRight = tabBarExtraContent.right;
    }
  }

  return (
    <div className={cx("tabs", className)}>
      <div className="tabs-header">
        <div className="tab-titles-container">
          {items.map((item) => (
            <div
              key={item.id}
              className={cx("tab-title", { active: activeTab === item.id })}
              onClick={() => {
                if (activeTabProp === undefined && activeTab !== null) {
                  setState({ activeTab: item.id });
                }
                if (onTabClick && typeof onTabClick === "function") {
                  onTabClick(item.id);
                }
              }}
            >
              {item.title}
            </div>
          ))}
          {extraContentRight && <div className="extra-content-right">{extraContentRight}</div>}
        </div>
        <div className="tabs-bottom-border" />
      </div>
      {activeItemContent && <div className="tabs-content">{activeItemContent}</div>}
    </div>
  );
}
