import withSubscriptions from "common/withSubscriptions";
import { withRouter } from "react-router-dom";

import QuoteMainSettings from "./QuoteMainSettings/QuoteMainSettings";
import QuoteHiddenLineItemColumns from "./QuoteHiddenLineItemColumns/QuoteHiddenLineItemColumns";

import "./QuoteSettingsPage.scss";

function QuoteSettingsPage(props) {
  return (
    <div className="quote-settings">
      <QuoteMainSettings {...props} />
      <QuoteHiddenLineItemColumns {...props} />
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: QuoteSettingsPage,
    subscriptions: ["organisationDetails"],
  })
);
