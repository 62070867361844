import { useState, useEffect } from "react";
import { Form, Modal, Button, Input } from "antd";
import { useForceUpdate } from "common/helpers";

import "./AttachmentTemplateModal.scss";

export default function AttachmentTemplateModal({ onClose, onSubmit, attachmentTemplate }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    setTimeout(() => {
      let formInitialState = {
        id: attachmentTemplate && attachmentTemplate.id,
        name: attachmentTemplate && attachmentTemplate.name,
        path: attachmentTemplate && attachmentTemplate.path,
      };

      form.setFieldsValue(formInitialState);
      forceUpdate();
    }, 0);
  }, []); // eslint-disable-line

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={attachmentTemplate ? "Edit attachment template" : "Add attachment template"}
      visible={true}
      onCancel={onClose}
      footer={null}
      className="attachment-template-modal"
    >
      <Form
        {...layout}
        form={form}
        onFinish={(params) => {
          setIsLoading(true);

          if (onSubmit && typeof onSubmit === "function") {
            onSubmit(params);
          }
          onClose();
        }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "You need to specify a name",
            },
          ]}
        >
          <Input data-cy="attachment-template-name-input" />
        </Form.Item>
        <Form.Item
          label="Path"
          name="path"
          rules={[
            {
              required: true,
              message: "You need to specify a path",
            },
          ]}
        >
          <Input data-cy="attachment-template-path-input" />
        </Form.Item>

        <div className="submit-container">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            data-cy="attachment-template-modal-submit-button"
          >
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}
