import { useRef, useEffect, useState } from "react";
import { message, Typography, Button, Modal } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { LexoRank } from "lexorank";

import { callGraphQLSimple } from "common/apiHelpers";
import { createInvoiceLineItemFromQuoteLineItem } from "common/invoiceHelpers/sharedInvoiceHelpers";
import { createInvoiceLineItemFromTask } from "common/invoiceHelpers/sharedInvoiceHelpers";
import { recalculateInvoiceAmounts } from "common/invoiceHelpers";

import Card from "Card/Card";
import AddQuoteLineItemToInvoiceLineItemModal from "Modals/AddQuoteLineItemToInvoiceLineItemModal/AddQuoteLineItemToInvoiceLineItemModal";
import AddTimesheetBlockToInvoiceModal from "./AddTimesheetBlockToInvoiceModal/AddTimesheetBlockToInvoiceModal";
import InvoiceLineItemsTable from "./InvoiceLineItemsTable/InvoiceLineItemsTable";

import "./InvoiceLineItemsCard.scss";

import { deleteInvoiceLineItem } from "common/invoiceHelpers";
import { getSimpleLabel } from "common/labels";

export default function InvoiceLineItemsCard(props) {
  const {
    invoice,
    project,
    invoiceForDisplay,
    refreshInvoice,
    organisationDetails,
    isUnderReview,
    isDisabled,
    users,
    clients,
    tasks,
    quotes,
    setProps,
    context,
    apiUser,
    numberForPreviewRefresh,
  } = props;

  const [isAddingNewLineItem, setIsAddingNewLineItem] = useState(false);
  const [numberForLineItemsTableRefresh, setNumberForLineItemsTableRefresh] = useState(0);

  const invoiceRef = useRef();
  useEffect(() => {
    invoiceRef.current = invoice;
  });

  const [selectedInvoiceLineItem, setSelectedInvoiceLineItem] = useState();
  const [isAddQuoteModalVisible, setIsAddQuoteModalVisible] = useState(false);
  const [isTimesheetBlockModalVisible, setIsAddTimesheetBlockModalVisible] = useState(false);
  const [deletedLineItemIds, setDeletedLineItemIds] = useState([]);

  async function handleClickOnAddTimesheetBlockToInvoice(timesheetBlock) {
    let options = [];
    let confirmModal;
    if (timesheetBlock.variation) {
      let invoiceLineItemWithSameTaskIndex =
        timesheetBlock.variation &&
        invoice.lineItems.items.findIndex((invoiceLineItem) => invoiceLineItem.taskId === timesheetBlock.taskId);
      if (invoiceLineItemWithSameTaskIndex !== -1) {
        let targetInvoiceLineItem = invoice.lineItems.items[invoiceLineItemWithSameTaskIndex];
        let trimmedTitle = targetInvoiceLineItem.title.substring(0, 20);
        if (targetInvoiceLineItem.title.length > trimmedTitle) {
          trimmedTitle += "...";
        }
        options.push(
          <Button
            key="existing-task"
            type="primary"
            onClick={() => {
              addTimesheetBlockToInvoice(timesheetBlock);
              confirmModal.destroy();
              setIsAddQuoteModalVisible(false);
            }}
          >
            Add to line item "{trimmedTitle}" (it's linked to the same {getSimpleLabel("task")} and covers variations)
          </Button>
        );
      } else {
        options.push(
          <Button
            key="new-task"
            type="primary"
            onClick={() => {
              createNewLineItemLinkedToTaskItemAndAddTimesheetBlock(timesheetBlock);
              confirmModal.destroy();
              setIsAddQuoteModalVisible(false);
            }}
          >
            Create new line item linked to the timesheet block's {getSimpleLabel("task")} (this is a variation)
          </Button>
        );
      }
    } else {
      if (timesheetBlock.quoteLineItemId === "nothing" || !timesheetBlock.quoteLineItemId) {
        message.error({
          content: `This timesheet block is not linked to a ${getSimpleLabel(
            "quote"
          )} line item and is also not marked as a variation, so it cannot be added to the invoice`,
          duration: 10,
        });
        return;
      }

      let invoiceLineItemWithSameQuoteLineItemIndex = invoice.lineItems.items.findIndex(
        (invoiceLineItem) => invoiceLineItem.quoteLineItemId === timesheetBlock.quoteLineItemId
      );

      if (invoiceLineItemWithSameQuoteLineItemIndex !== -1) {
        let targetInvoiceLineItem = invoice.lineItems.items[invoiceLineItemWithSameQuoteLineItemIndex];
        let trimmedTitle = targetInvoiceLineItem.title.substring(0, 20);
        if (targetInvoiceLineItem.title.length > trimmedTitle) {
          trimmedTitle += "...";
        }
        let hourlyMention = null;
        if (targetInvoiceLineItem.quoteLineItem.isHourly) {
          hourlyMention = "hourly";
        } else {
          hourlyMention = "fixed price";
        }
        options.push(
          <Button
            key="existing-quote-line-item"
            type="primary"
            onClick={() => {
              addTimesheetBlockToInvoice(timesheetBlock);
              confirmModal.destroy();
              setIsAddQuoteModalVisible(false);
            }}
          >
            Add to line item "{trimmedTitle}" (same {hourlyMention} quote line item)
          </Button>
        );
      } else {
        options.push(
          <Button
            key="new-line-item"
            type="primary"
            onClick={() => {
              createNewLineItemLinkedToQuoteLineItemAndAddTimesheetBlock(timesheetBlock);
              confirmModal.destroy();
              setIsAddQuoteModalVisible(false);
            }}
          >
            Create new line item linked to the timesheet block's {getSimpleLabel("quote")} line item
          </Button>
        );
      }
    }

    confirmModal = Modal.confirm({
      className: "modal-options-for-adding-timesheet-block-to-invoice",
      title: `How do you want this timesheet block to be added to the invoice?`,
      content: (
        <>
          <div className="modal-custom-footer">
            {options}

            <Button onClick={() => confirmModal.destroy()}>Cancel</Button>
          </div>
        </>
      ),

      footer: null,
    });
  }

  async function createNewLineItemLinkedToQuoteLineItemAndAddTimesheetBlock(timesheetBlock) {
    const messageKey = "creating-invoice-line-item-from-quote-line-item";

    let quote = quotes.find((quote) => quote.id === timesheetBlock.quoteId);

    if (!quote) {
      message.error({
        content: `Failed to create invoice line item - could not find ${getSimpleLabel("quote")}`,
        key: messageKey,
      });

      return;
    }

    let quoteLineItem = quote.lineItems.items.find(
      (quoteLineItem) => quoteLineItem.id === timesheetBlock.quoteLineItemId
    );

    const invoiceLineItemDetails = createInvoiceLineItemFromQuoteLineItem({
      organisationDetails,
      client: clients.find((x) => x.id === quote.clientId),
      quote,
      quoteLineItemId: quoteLineItem.id,
      invoice,
      invoiceLineItem: {},
      timesheetBlocks: [timesheetBlock],
    });

    invoiceLineItemDetails.order = getNewLineItemOrder();

    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          className: "modal-confirm-creation-of-invoice-line-item",
          title: "Confirm creation of invoice line item",
          content: (
            <>
              <Typography.Text>
                <b>Title:</b> {invoiceLineItemDetails.title}
              </Typography.Text>
              <br />
              <Typography.Text>
                <b>Description:</b> {invoiceLineItemDetails.description}
              </Typography.Text>
              <br />
              <Typography.Text>
                <b>Quantity:</b> {invoiceLineItemDetails.quantity}
              </Typography.Text>
              <br />
              <Typography.Text>
                <b>Unit price:</b> {window.formatCurrency("GBP", invoiceLineItemDetails.unitPrice)}
              </Typography.Text>
              <br />
              <Typography.Text>
                <b>Amount:</b> {window.formatCurrency("GBP", invoiceLineItemDetails.amount)}
              </Typography.Text>
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing to do other than to abort, the user selected "cancel"
      return;
    }

    message.loading({
      content: `Creating invoice line item linked to ${getSimpleLabel("quote")} line item...`,
      key: messageKey,
      duration: 0,
    });

    try {
      await callGraphQLSimple({
        message: "Failed to create invoice line item",
        mutation: "createInvoiceLineItem",
        variables: {
          input: invoiceLineItemDetails,
        },
      });
      message.success({
        content: "Invoice line item created successfully",
        key: messageKey,
        duration: window.Cypress ? 0.5 : 2,
      });
    } catch (e) {
      message.error({
        content: `Failed to create invoice line item - ${e.message}`,
        key: messageKey,
      });
      return;
    }

    await addTimesheetBlockToInvoice(timesheetBlock);
  }

  async function createNewLineItemLinkedToTaskItemAndAddTimesheetBlock(timesheetBlock) {
    const messageKeyCreateFromTask = "creating-invoice-line-item-from-task";

    const task = tasks.find((task) => task.id === timesheetBlock.taskId);

    if (!task) {
      message.error({
        content: `Failed to create invoice line item - could not find ${getSimpleLabel("task")}`,
        key: messageKeyCreateFromTask,
      });

      return;
    }

    const invoiceLineItemDetails = createInvoiceLineItemFromTask({
      organisationDetails,
      client: clients.find((x) => x.id === task.clientId),
      quotes,
      task,
      invoice,
      invoiceLineItem: {},
      timesheetBlocks: [timesheetBlock],
    });

    invoiceLineItemDetails.order = getNewLineItemOrder();

    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          className: "modal-confirm-creation-of-invoice-line-item",
          title: "Confirm creation of invoice line item",
          content: (
            <>
              <Typography.Text>
                <b>Title:</b> {invoiceLineItemDetails.title}
              </Typography.Text>
              <br />
              <Typography.Text>
                <b>Description:</b> {invoiceLineItemDetails.description}
              </Typography.Text>
              <br />
              <Typography.Text>
                <b>Amount:</b> {window.formatCurrency("GBP", invoiceLineItemDetails.amount)}
              </Typography.Text>
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing to do other than to abort, the user selected "cancel"
      return;
    }

    message.loading({
      content: `Creating invoice line item linked to ${getSimpleLabel("task")}...`,
      key: messageKeyCreateFromTask,
      duration: 0,
    });

    try {
      await callGraphQLSimple({
        message: "Failed to create invoice line item",
        mutation: "createInvoiceLineItem",
        variables: {
          input: invoiceLineItemDetails,
        },
      });
      message.success({
        content: "Invoice line item created successfully",
        key: messageKeyCreateFromTask,
        duration: window.Cypress ? 0.5 : 2,
      });
      await addTimesheetBlockToInvoice(timesheetBlock);
    } catch (e) {
      message.error({
        content: `Failed to create invoice line item - ${e.message}`,
        key: messageKeyCreateFromTask,
      });
      return;
    }
  }

  async function addTimesheetBlockToInvoice(timesheetBlock) {
    setIsAddTimesheetBlockModalVisible(false);
    const messageKey = "adding-timesheet-block-to-invoice";
    message.loading({
      content: "Adding timesheet block to invoice...",
      key: messageKey,
      duration: 0,
    });
    try {
      await callGraphQLSimple({
        message: "Failed to add timesheet block to invoice",
        mutation: "updateTimesheetBlock",
        variables: {
          input: {
            id: timesheetBlock.id,
            invoiceId: invoice.id,
            invoicingStatus: "INVOICED",
          },
        },
      });
      await recalculateInvoiceAmounts({ invoiceId: invoice.id });
      await refreshInvoice();

      message.success({
        content: "Timesheet block added to invoice",
        key: messageKey,
        duration: window.Cypress ? 0.5 : 2,
      });
    } catch (e) {
      message.destroy(messageKey);
    }
  }

  async function addLineItem() {
    setIsAddingNewLineItem(true);
    let newLineItem = {
      id: `${Date.now()}-${Math.random() * 10000}-${Math.random() * 1000000}`,
      invoiceId: invoice.id,
      organisation: invoice.organisation,
      title: "",
      description: "",
      quantity: 1,
      unitPrice: 0,
      discountPercent: 0,
      taxRate: invoice.taxRate,
      amount: 0,
      taxAmount: 0,
      quoteLineItemId: "nothing",
      order: getNewLineItemOrder(),
    };

    await callGraphQLSimple({
      message: "Failed to create line item",
      mutation: "createInvoiceLineItem",
      variables: {
        input: newLineItem,
      },
    });

    await refreshInvoice();
    await recalculateInvoiceAmounts({ invoiceId: invoice.id });

    setNumberForLineItemsTableRefresh((oldValue) => oldValue + 1);

    setTimeout(() => {
      setIsAddingNewLineItem(false);
    }, 500);
  }

  function getNewLineItemOrder() {
    const lastInvoiceLineItem = [...invoice.lineItems.items].sort((a, b) => (a.order < b.order ? -1 : 1)).slice(-1)[0];
    if (!lastInvoiceLineItem) {
      const newInvoiceLineItemOrder = LexoRank.middle();
      return newInvoiceLineItemOrder.toString();
    } else {
      if (lastInvoiceLineItem.order) {
        const lastInvoiceLineItemOrder = LexoRank.parse(lastInvoiceLineItem.order);
        const newInvoiceLineItemOrder = lastInvoiceLineItemOrder.genNext();
        return newInvoiceLineItemOrder.toString();
      }
    }

    return undefined;
  }

  async function confirmDeleteLineItem(lineItem, index) {
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm delete line item",
          className: "delete-line-item-modal",
          okText: "Delete",
          maskClosable: true,
          content: (
            <>
              Are you sure you want to delete line item{" "}
              <b>
                {index} {lineItem.title ? `- ${lineItem.title}` : ""}
              </b>
              ?
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    setDeletedLineItemIds([...deletedLineItemIds, lineItem.id]);
    try {
      await deleteInvoiceLineItem({
        invoiceId: invoice.id,
        lineItemId: lineItem.id,
      });
      await refreshInvoice();
      await recalculateInvoiceAmounts({ invoiceId: invoice.id });
    } catch (e) {
      setDeletedLineItemIds(deletedLineItemIds.filter((x) => x !== lineItem.id));
    }

    setNumberForLineItemsTableRefresh((oldValue) => oldValue + 1);
  }

  function onAddTimesheetBlockClick() {
    setIsAddTimesheetBlockModalVisible(true);
  }

  const formattedSubtotal = window.formatCurrency("GBP", invoice.subtotal);
  const formattedTotalTax = window.formatCurrency("GBP", invoice.totalTax);
  const formattedTotal = window.formatCurrency("GBP", invoice.total);

  return (
    <Card
      stickyHeader
      wrapHeader
      title="Line items"
      className="invoice-line-items-card"
      data-cy="invoice-line-items-card"
      withSpace
      actions={[
        <Button icon={<PlusCircleOutlined />} onClick={() => onAddTimesheetBlockClick()}>
          Add timesheet block
        </Button>,
        <Button
          icon={<PlusCircleOutlined />}
          onClick={addLineItem}
          disabled={isDisabled}
          type="primary"
          className="add-new-line-item-button"
          data-cy="add-new-line-item-button"
          loading={isAddingNewLineItem}
        >
          Add new line item
        </Button>,
      ]}
    >
      <InvoiceLineItemsTable
        key={numberForLineItemsTableRefresh}
        invoiceForDisplay={invoiceForDisplay}
        invoice={invoice}
        refreshInvoice={refreshInvoice}
        isUnderReview={isUnderReview}
        openCommentBox={props.openCommentBox}
        isDisabled={isDisabled}
        setSelectedInvoiceLineItem={setSelectedInvoiceLineItem}
        setIsAddQuoteModalVisible={setIsAddQuoteModalVisible}
        confirmDeleteLineItem={confirmDeleteLineItem}
        organisationDetails={organisationDetails}
        invoiceRef={invoiceRef}
        deletedLineItemIds={deletedLineItemIds}
        numberForPreviewRefresh={numberForPreviewRefresh}
      />
      <div className="total-container">
        <div className="total-inner-container">
          <div className="total-item">
            <Typography.Text className="label">Subtotal </Typography.Text>
            <Typography.Text className="value" data-cy="subtotal">
              {formattedSubtotal}
            </Typography.Text>
          </div>
          <div className="total-item">
            <Typography.Text className="label">Total VAT {invoice.taxRate}% </Typography.Text>
            <Typography.Text className="value" data-cy="total-tax">
              {formattedTotalTax}
            </Typography.Text>
          </div>
          <div className="total-item grand-total-item">
            <Typography.Text className="label">Total </Typography.Text>
            <Typography.Text className="value" data-cy="total">
              {formattedTotal}
            </Typography.Text>
          </div>
        </div>
      </div>

      {selectedInvoiceLineItem && isAddQuoteModalVisible && (
        <AddQuoteLineItemToInvoiceLineItemModal
          quotes={quotes}
          clients={clients}
          invoice={invoice}
          organisationDetails={organisationDetails}
          invoiceLineItem={selectedInvoiceLineItem}
          onClose={() => {
            setSelectedInvoiceLineItem(undefined);
            setIsAddQuoteModalVisible(false);
            refreshInvoice();
          }}
        />
      )}

      {isTimesheetBlockModalVisible && (
        <AddTimesheetBlockToInvoiceModal
          projectId={project.id}
          visible={true}
          onClose={() => {
            setIsAddTimesheetBlockModalVisible(false);
          }}
          onTimesheetBlockSelected={handleClickOnAddTimesheetBlockToInvoice}
          refreshInvoice={refreshInvoice}
        />
      )}
    </Card>
  );
}
