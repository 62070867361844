import { useState } from "react";
import { Form, Modal, Button, Input, Space, Select } from "antd";
import cx from "classnames";

import CUSTOM_LABELS from "common/labels";
import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";

import "./CreateCustomLabelModal.scss";

export function CreateCustomLabelModal({ onClose, visible, apiUser, organisationDetails }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ content, id }) {
    setIsLoading(true);

    await callGraphQLSimple({
      message: "Could not create custom label",
      queryName: "updateOrganisation",
      variables: {
        input: {
          id: apiUser.organisation,
          labels: [...(organisationDetails?.labels || []), { id, value: content }],
        },
      },
    });

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  return (
    <Modal
      maskClosable={false}
      title="Create custom label"
      open={visible}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-custom-label-modal"
    >
      <Form form={form} onFinish={onSubmit}>
        <Space direction="vertical" className="spread">
          <Form.Item
            label="Label ID"
            name="id"
            rules={[
              {
                required: true,
                message: "Each label must have an ID",
              },
            ]}
          >
            <Select data-cy="custom-label-id-dropdown" showSearch>
              {CUSTOM_LABELS.map((label) => label.id)
                .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
                .map((label) => {
                  const isLabelAlreadyUsed = !!organisationDetails?.labels?.find((x) => x.id === label);
                  return (
                    <Select.Option
                      key={label}
                      value={label}
                      disabled={isLabelAlreadyUsed}
                      className={cx({ "option-disabled": isLabelAlreadyUsed })}
                      data-cy={`custom-label-${label}`}
                    >
                      {label}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Content"
            name="content"
            rules={[
              {
                required: true,
                message: "Each label must have content",
              },
            ]}
          >
            <Input data-cy="custom-label-name-input" />
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading} data-cy="custom-label-submit-button">
              Submit
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}

export default withSubscriptions({
  Component: CreateCustomLabelModal,
  subscriptions: ["organisationDetails"],
});
