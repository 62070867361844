import { Text, View, Page, StyleSheet } from "@react-pdf/renderer";

export const colors = { andunGreen: "#18405B", black: "#000", gray: "#102E42" };

export const styles = StyleSheet.create({
  bold: { fontWeight: "bold" },
  pageTitle: { fontSize: 20, marginBottom: 25, fontWeight: "bold" },
  pageSubtitle: { fontSize: 13, marginBottom: 15 },
  engineeringStatementTitle: {
    fontSize: 15,
    marginBottom: 8,
    textAlign: "center",
    fontWeight: "bold",
  },
  sectionTitle: {
    fontSize: 17,
    marginBottom: 10,
    marginTop: 20,
    fontWeight: "bold",
  },
  sectionSubtitle: {
    fontSize: 15,
    marginBottom: 20,
    marginTop: 20,
    textDecoration: "underline",
    fontWeight: "bold",
  },
  paragraph: { fontSize: 10, marginBottom: 10 },
  pilingPlatformGuidanceParagraph: { fontSize: 7.5, marginBottom: 7 },
  "image_half-page": {
    width: "50%",
  },
  "image_third-page": {
    width: "33%",
  },
  image_half: {
    width: "50%",
  },
  image_third: {
    width: "33%",
  },
  "image_full-page": {
    width: "100%",
  },
  image_original: {},

  page: {
    paddingTop: 80,
    paddingRight: 66,
    paddingLeft: 24,
    paddingBottom: 45,
    fontFamily: "Averta",
  },

  cover: {
    infoContainer: { paddingLeft: 70, paddingTop: 94 },
    image: {
      height: "795pt",
      position: "absolute",
      top: -56,
      left: -25,
    },
    infoItem: { marginBottom: 24, fontSize: 17 },
  },
});

export function EndCover({ colors, styles }) {
  return (
    <Page size="A4" style={styles.page} wrap>
      <View>
        <Text style={[styles.paragraph, { margin: 5, marginTop: 20 }]}>
          <Text style={[styles.bold]}>Registration No:</Text> 111222333
        </Text>

        <Text style={[styles.paragraph, { margin: 5 }]}>
          <Text style={[styles.bold]}>Email:</Text> <Text style={{ color: colors.andunGreen }}>contact@demo.com</Text>
        </Text>
        <Text style={[styles.paragraph, { margin: 5 }]}>
          <Text style={[styles.bold]}>Telephone:</Text> 0123 45678
        </Text>
        <Text style={[styles.paragraph, { margin: 5 }]}>
          <Text style={[styles.bold]}>Website:</Text> <Text style={{ color: colors.andunGreen }}>www.demo.com</Text>
        </Text>
        <Text style={[styles.paragraph, { margin: 5 }]}>
          <Text style={[styles.bold]}>Head Office:</Text> Fictive House, 1 Dream lane, England, 1AA 2BB
        </Text>
      </View>
    </Page>
  );
}
