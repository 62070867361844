import { useState, useEffect } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { notification, Form, Modal, Button, message, Checkbox, InputNumber, Input } from "antd";

import { Holiday } from "common/types";
import { useForceUpdate } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import getOrderedActiveUsers from "common/getOrderedActiveUsers";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";
import { approveTimeOff, getWorkingAndNonWorkingDaysForUser } from "common/timeOffHelpers";
import { sendTimeOffRequestNotification } from "common/notificationHelpers";
import withSubscriptions from "common/withSubscriptions";
import { isAuthorised } from "common/permissions";

import DatePicker from "DatePicker/DatePicker";
import Avatar from "Avatar/Avatar";
import HolidayConflicts from "common/HolidayConflicts/HolidayConflicts";

import "./HolidayModal.scss";
const { TextArea } = Input;

type Props = {
  onClose: () => void;
  onSubmit?: any;
  holiday?: Holiday;
  isSick: boolean;
  userDetails: any;
  users: any;
  apiUser: any;
  multipleUsers?: boolean;
  organisationDetails: any;
  targetIntervals: any;
  holidays: any;
};

export function HolidayModal({
  onClose,
  onSubmit: onSubmitProp,
  users,
  apiUser,
  userDetails,
  holiday,
  isSick,
  multipleUsers,
  organisationDetails,
  targetIntervals,
  holidays,
}: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestingPartialHoliday, setIsRequestingPartialHoliday] = useState(false);

  const forceUpdate = useForceUpdate();
  const label = isSick ? "sick days" : "holiday";

  let orderedActiveUsers = getOrderedActiveUsers({ users, organisationDetails, apiUser });
  let validUsers = [...orderedActiveUsers];

  useEffect(() => {
    setTimeout(() => {
      let formInitialState = {};

      if (holiday) {
        formInitialState = {
          ...holiday,
        };
      }

      form.setFieldsValue(formInitialState);
      forceUpdate();
    }, 0);
  }, []); // eslint-disable-line

  async function onSubmit(params) {
    const { targetUsers, isRequestingPartialHoliday, message } = params;

    let startDate;
    let endDate;
    let startHours;
    let endHours;
    moment.locale("en-gb");

    if (isRequestingPartialHoliday) {
      startDate = endDate = params.date;
      startHours = params.startHours;
      endHours = params.endHours;
    } else {
      const { dates } = params;
      const [startsAt, endsAt] = dates;

      startDate = startsAt;
      endDate = endsAt;
      startHours = 0;
      endHours = 8;
    }

    if (multipleUsers) {
      for (let i = 0; i < targetUsers.length; i++) {
        let user = users.find((user) => user.id === targetUsers[i]);
        await createHolidayForUser({ startDate, endDate, userDetails: user, startHours, endHours, message });
      }
    } else {
      await createHolidayForUser({ startDate, endDate, userDetails, startHours, endHours, message });
    }

    if (onSubmitProp && typeof onSubmitProp === "function") {
      onSubmitProp();
    }
  }

  async function createHolidayForUser({ startDate, endDate, userDetails, startHours, endHours, message }) {
    const { workingDays } = getWorkingAndNonWorkingDaysForUser({
      user: userDetails,
      startDate,
      endDate,
    });

    let days = workingDays.map((date) => {
      return {
        day: moment(date).startOf("day").add(12, "hours").toISOString(),
        startHours,
        endHours,
      };
    });

    let workingDaysBeforeStartDate = days.filter((day) => moment(day.day).isBefore(startDate, "day"));
    let workingDaysAfterEndDate = days.filter((day) => moment(day.day).isAfter(endDate, "day"));

    if (workingDaysBeforeStartDate.length) {
      notification.error({
        message:
          "Some of the working days in this holiday are before the start date. This is due to an application error. Please contact the DraughtHub support team.",
        duration: 0,
      });
      return;
    }

    if (workingDaysAfterEndDate.length) {
      notification.error({
        message:
          "Some of the working days in this holiday are after the end date. This is due to an application error. Please contact the DraughtHub support team.",
        duration: 0,
      });
      return;
    }

    const holiday = (
      await callGraphQLSimple({
        message: "Failed to request time off",
        queryName: "createHoliday",
        variables: {
          input: {
            organisation: userDetails.organisation,
            userId: userDetails.id,
            startsAt: startDate.startOf("day").add(12, "hours").toISOString(),
            endsAt: endDate.startOf("day").add(12, "hours").toISOString(),
            days,
            status: "PENDING",
            isSick,
            message,
          },
        },
      })
    ).data.createHoliday;

    // if a manager is requesting time off for someone else, approve it by default
    if (isAuthorised(["TIME_OFF.MANAGE"]) && apiUser.id !== userDetails.id) {
      await approveTimeOff({ holiday, apiUser, users });
    } else {
      await sendTimeOffRequestNotification({
        requesterId: userDetails.id,
        users,
        startsAt: startDate.startOf("day").add(12, "hours").format("DD-MM-YYYY"),
        endsAt: endDate.startOf("day").add(12, "hours").format("DD-MM-YYYY"),
        isSick,
      });
    }
  }

  const [startDate, endDate] = form.getFieldValue("dates") || [];
  let fakeTargetHoliday: any = {
    startsAt: startDate?.toISOString(),
    endsAt: endDate?.toISOString(),
    days: [],
  };

  if (!multipleUsers && startDate && endDate) {
    const { workingDays } = getWorkingAndNonWorkingDaysForUser({
      user: userDetails,
      startDate,
      endDate,
    });

    let days = workingDays.map((date) => {
      return {
        day: moment(date).startOf("day").add(12, "hours").toISOString(),
        startHours: 0,
        endHours: TIMELINE_DEFAULT_HOURS_IN_A_DAY,
      };
    });

    fakeTargetHoliday.days = days;
  }

  return (
    <Modal
      maskClosable={false}
      title={`${holiday ? "Edit" : "Request"} ${label}`}
      open={true}
      onCancel={onClose}
      footer={null}
      className="holiday-modal full-screen-on-mobile"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={async (params) => {
          setIsLoading(true);

          if (onSubmit && typeof onSubmit === "function") {
            if (!isRequestingPartialHoliday) {
              let startAndEndDateFitWithinASingleInterval = targetIntervals?.some((interval) => {
                let startDateIsInside = moment(params.dates[0]).isBetween(
                  interval.startDate,
                  interval.endDate,
                  "day",
                  "[]"
                );
                let endDateIsInside = moment(params.dates[1]).isBetween(
                  interval.startDate,
                  interval.endDate,
                  "day",
                  "[]"
                );
                return startDateIsInside && endDateIsInside;
              });
              if (!startAndEndDateFitWithinASingleInterval) {
                message.error(
                  `The start and end date of the request must be in the same allowance interval (usually this means the same year)`
                );
                setIsLoading(false);
                return;
              }
            }

            await onSubmit({ ...params, isSick });
            onClose();
          }
        }}
      >
        {isRequestingPartialHoliday ? (
          <>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "You need to choose a date",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" data-cy="time-off-partial-date-picker" />
            </Form.Item>

            <Form.Item label="Start hours" name="startHours" data-cy="time-off-start-hours" initialValue={0}>
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item label="End hours" name="endHours" data-cy="time-off-end-hours" initialValue={8}>
              <InputNumber min={0} max={TIMELINE_DEFAULT_HOURS_IN_A_DAY} />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            label="Dates"
            name="dates"
            rules={[
              {
                required: true,
                message: "You need to choose dates",
              },
            ]}
            data-cy="time-off-date-picker-form-item"
          >
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              data-cy="time-off-date-picker"
              onChange={(dates) => {
                form.setFieldsValue({ dates });
                forceUpdate();
              }}
              popupClassName="holiday-request-date-picker"
            />
          </Form.Item>
        )}

        <Form.Item label="Request less than a day" name="isRequestingPartialHoliday" valuePropName="checked">
          <Checkbox
            onChange={(event) => setIsRequestingPartialHoliday(event.target.checked)}
            data-cy="partial-time-off-checkbox"
          />
        </Form.Item>
        <Form.Item label="Leave a message (optional)" name="message">
          <TextArea rows={2} />
        </Form.Item>

        {multipleUsers && (
          <Form.Item label="Users" name="targetUsers" valuePropName="value">
            <Checkbox.Group>
              {validUsers.map((user) => (
                <Checkbox key={user?.id} value={user.id}>
                  <Avatar user={user} showLabel />
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        )}

        <HolidayConflicts
          users={users}
          holidays={holidays}
          targetHoliday={fakeTargetHoliday}
          firstTableTitle={"Conflicts with pending requests"}
          secondTableTitle={"Conflicts with approved requests"}
          hideIfEmpty
        />

        <div className="submit-container">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            data-cy={isSick ? "sick-day-submit-button" : "holiday-submit-button"}
          >
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}

export default withRouter(
  withSubscriptions({
    Component: HolidayModal,
    subscriptions: ["organisationDetails", "users", "holidays"],
  })
);
