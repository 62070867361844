import { useState, useEffect, useRef } from "react";
import { Typography, Button, Modal, Checkbox } from "antd";
import { PlusCircleOutlined, SendOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { fillInDynamicInfo } from "common/documentRenderHelpers";
import { callRest, callGraphQLSimple } from "common/apiHelpers";

import PdfRenderer from "ReportPage/PdfRenderer";
import Input from "Input/Input";

import "./SendDocumentModal.scss";

export function SendPurchaseOrderModal(props) {
  const {
    onClose,
    purchaseOrder,
    form,
    clients,
    supplier,
    users,
    changePurchaseOrderAttribute,
    setSendStatus,
    apiUser,
    approvedPdfData,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [includeInCC, setIncludeInCC] = useState([]);

  const upToDatePurchaseOrder = useRef(purchaseOrder);

  const assignee = users.find((x) => x.id === purchaseOrder.assignedTo);
  const client = clients.find((x) => x.id === purchaseOrder.clientId);
  const supplierContact = supplier.contacts?.find((x) => x.id === purchaseOrder.supplierContact);
  const supplierAddress = supplier.addresses?.find((x) => x.id === purchaseOrder.supplierAddress);
  let assignedToDetails = users.find((user) => user.id === purchaseOrder.assignedTo);
  let reviewerDetails = users.find((user) => user.id === purchaseOrder.checkedBy);

  let emailContentWithDynamicInfo = form?.fields?.emailText?.value
    ? fillInDynamicInfo(form?.fields?.emailText?.value, {
        ...props,
        supplierContact,
        supplierAddress,
        assignee,
      })
    : "";
  let htmlEmailContent = emailContentWithDynamicInfo
    .split("\n")
    .map((paragraph) => `<p>${paragraph && paragraph.length > 0 ? paragraph : "&nbsp;"}</p>`)
    .join("");

  useEffect(() => {
    setIncludeInCC(purchaseOrder.contactIdsToIncludeInCC || []);
  }, []); // eslint-disable-line

  useEffect(() => {
    upToDatePurchaseOrder.current = purchaseOrder;
  }, [purchaseOrder]);

  function closeModal() {
    setIsLoading(false);
    onClose();
  }

  function processContact(contact) {
    if (contact?.id) {
      const contactDetails = client.contacts?.find((x) => x.id === contact.id);
      return contactDetails?.email;
    } else if (contact === "reviewer") {
      return reviewerDetails.id;
    } else if (contact === "myself") {
      return apiUser.id;
    } else {
      const contactDetails = client.contacts?.find((x) => x.id === contact);
      return contactDetails?.email || contact;
    }
  }

  async function onSubmit() {
    sendPurchaseOrder();
  }

  async function sendPurchaseOrder() {
    setIsLoading(true);
    try {
      let bcc = (purchaseOrder.contactIdsToIncludeInBCC || []).map(processContact);
      let cc = (purchaseOrder.contactIdsToIncludeInCC || []).map(processContact);
      let extraCC = (purchaseOrder.extraEmailsToIncludeInCC || []).map(({ email }) => email).filter((email) => email);
      let extraBCC = (purchaseOrder.extraEmailsToIncludeInBCC || []).map(({ email }) => email).filter((email) => email);

      cc = [...cc, ...extraCC];
      bcc = [...bcc, ...extraBCC];
      await callRest({
        route: "/sendPurchaseOrderEmail",
        method: "POST",
        body: {
          assignedToEmail: assignedToDetails.id,
          purchaseOrderTitle: purchaseOrder.title,
          to: supplierContact.email,
          cc,
          bcc,
          replyTo: assignedToDetails.id,
          content: htmlEmailContent,
          attachments: [
            {
              key: purchaseOrder.exports[0].key,
              s3VersionId: purchaseOrder.exports[0].latestS3VersionId,
              fileName: purchaseOrder.exports[0].fileName,
            },
          ],
        },
        includeCredentials: false,
      });

      let activityItemContent = "Sent";
      if (supplierContact?.firstName || supplierContact?.lastName) {
        activityItemContent += ` to ${supplierContact?.firstName || ""} ${supplierContact.lastName} at ${
          supplierContact.email
        }`;
      } else {
        activityItemContent += ` to ${supplierContact.email}`;
      }

      if (cc.length > 0) {
        activityItemContent += ` with CC to ${cc.join(", ")}`;
      }

      if (bcc.length > 0) {
        activityItemContent += ` with BCC to ${bcc.join(", ")}`;
      }

      await changePurchaseOrderAttribute({
        fieldName: "status",
        value: "SENT",
        activityItemContent,
      });
      setSendStatus("SUCCESS");
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setSendStatus("ERROR");
      setIsLoading(false);
    }
    closeModal();
  }

  async function onIncludeInCCChange(e, contact) {
    let newContactIdsToIncludeInCC;

    if (e.target.checked) {
      newContactIdsToIncludeInCC = [...(includeInCC || []), contact.id || contact];
    } else {
      newContactIdsToIncludeInCC = (includeInCC || []).filter((x) => x !== (contact.id || contact));
    }

    setIncludeInCC(newContactIdsToIncludeInCC);

    await callGraphQLSimple({
      message: "Failed to save CC recipients",
      queryName: "updatePurchaseOrder",
      variables: {
        input: {
          id: purchaseOrder.id,
          contactIdsToIncludeInCC: newContactIdsToIncludeInCC,
        },
      },
    });
  }

  async function onIncludeInBCCChange(e, contact) {
    let newContactIdsToIncludeInBCC;

    if (e.target.checked) {
      newContactIdsToIncludeInBCC = [...(purchaseOrder.contactIdsToIncludeInBCC || []), contact.id || contact];
    } else {
      newContactIdsToIncludeInBCC = (purchaseOrder.contactIdsToIncludeInBCC || []).filter(
        (x) => x !== (contact.id || contact)
      );
    }

    console.log("newContactsToIncludeInBCC", newContactIdsToIncludeInBCC);
    await callGraphQLSimple({
      message: "Failed to save BCC recipients",
      queryName: "updatePurchaseOrder",
      variables: {
        input: {
          id: purchaseOrder.id,
          contactIdsToIncludeInBCC: newContactIdsToIncludeInBCC,
        },
      },
    });
  }

  function displayCC() {
    return (
      <div className="section">
        <Typography.Text className="section-title">
          {" "}
          CC:{" "}
          <Button
            style={{ marginLeft: "0.5rem", marginTop: "1rem" }}
            icon={<PlusCircleOutlined />}
            onClick={() => {
              let updatedExtraEmails = JSON.parse(JSON.stringify(purchaseOrder.extraEmailsToIncludeInCC || []));
              updatedExtraEmails.push({
                id: `${Date.now()}-${Math.floor(Math.random() * 100000)}`,
                email: "",
              });
              changePurchaseOrderAttribute({
                fieldName: "extraEmailsToIncludeInCC",
                value: updatedExtraEmails,
              });
            }}
          >
            Add new
          </Button>
        </Typography.Text>
        <ul className="email-cc-option-list">
          <li value="reviewer" className="cc-option-reviewer">
            <Checkbox checked={includeInCC.includes("reviewer")} onChange={(e) => onIncludeInCCChange(e, "reviewer")}>
              <span>Purchase order reviewer</span>
              <span>
                {reviewerDetails.firstName} {reviewerDetails.lastName}
              </span>
              <span>{reviewerDetails.id}</span>
            </Checkbox>
          </li>
          {client.contacts
            .filter((contact) => contact?.id && purchaseOrder.clientContact !== contact?.id)
            .map((contact, i) => (
              <li key={i} value={contact.id}>
                <Checkbox checked={includeInCC.includes(contact.id)} onChange={(e) => onIncludeInCCChange(e, contact)}>
                  <span>{contact.id}</span>
                  <span>
                    {contact.firstName} {contact.lastName}
                  </span>
                  <span>{contact.email}</span>
                </Checkbox>
              </li>
            ))}
        </ul>
        {(purchaseOrder.extraEmailsToIncludeInCC || []).map((extraEmail) => {
          return (
            <div key={extraEmail.id} value={extraEmail.value} className="extra-email" style={{ marginTop: "0.5rem" }}>
              <Input
                showBorder
                fullWidth
                placeholder="Type an email address"
                defaultValue={extraEmail.email}
                onChange={(email) => onChangeExtraCC(email, extraEmail.id)}
                fireOnChangeWithoutBlurWithDebounce
              />
            </div>
          );
        })}
      </div>
    );
  }

  async function onChangeExtraCC(email, id) {
    const updatedExtraEmails = JSON.parse(JSON.stringify(upToDatePurchaseOrder.current.extraEmailsToIncludeInCC || []));
    updatedExtraEmails.find((x) => x.id === id).email = email;
    changePurchaseOrderAttribute({
      fieldName: "extraEmailsToIncludeInCC",
      value: updatedExtraEmails,
    });
  }
  async function onChangeExtraBCC(email, id) {
    const updatedExtraEmails = JSON.parse(
      JSON.stringify(upToDatePurchaseOrder.current.extraEmailsToIncludeInBCC || [])
    );
    updatedExtraEmails.find((x) => x.id === id).email = email;
    changePurchaseOrderAttribute({
      fieldName: "extraEmailsToIncludeInBCC",
      value: updatedExtraEmails,
    });
  }

  function displayBCC() {
    return (
      <div className="section">
        <Typography.Text className="section-title">
          {" "}
          BCC:{" "}
          <Button
            style={{
              marginLeft: "0.5rem",
              marginBottom: "0.5rem",
              marginTop: "1rem",
            }}
            icon={<PlusCircleOutlined />}
            onClick={() => {
              let updatedExtraEmails = JSON.parse(JSON.stringify(purchaseOrder.extraEmailsToIncludeInBCC || []));
              updatedExtraEmails.push({
                id: `${Date.now()}-${Math.floor(Math.random() * 100000)}`,
                email: "",
              });
              changePurchaseOrderAttribute({
                fieldName: "extraEmailsToIncludeInBCC",
                value: updatedExtraEmails,
              });
            }}
          >
            Add new
          </Button>
        </Typography.Text>
        <Checkbox
          defaultChecked={(purchaseOrder.contactIdsToIncludeInBCC || [])?.includes("myself")}
          onChange={(e) => onIncludeInBCCChange(e, "myself")}
        >
          <span>Me</span>
          <span>
            {apiUser.firstName} {apiUser.lastName}
          </span>
          <span>{apiUser.id}</span>
        </Checkbox>
        {(purchaseOrder.extraEmailsToIncludeInBCC || []).map((extraEmail) => {
          return (
            <div key={extraEmail.id} value={extraEmail.value} className="extra-email" style={{ marginTop: "0.5rem" }}>
              <Input
                showBorder
                fullWidth
                placeholder="Type an email address"
                defaultValue={extraEmail.email}
                onChange={(email) => onChangeExtraBCC(email, extraEmail.id)}
                fireOnChangeWithoutBlurWithDebounce
              />
            </div>
          );
        })}
      </div>
    );
  }

  if (!approvedPdfData) {
    return null;
  }

  return (
    <Modal
      maskClosable={false}
      title="Are you sure you want to send this purchase order?"
      open={true}
      onOk={onSubmit}
      onCancel={closeModal}
      footer={null}
      className="send-document-modal"
    >
      <div className="email-details">
        <div className="section">
          <Typography.Text className="section-title"> From:</Typography.Text>
          <Typography.Text className="section-content">purchaseorders@draughthub.com</Typography.Text>
        </div>
        <div className="section">
          <Typography.Text className="section-title"> Reply to:</Typography.Text>
          <Typography.Text className="section-content">
            {assignedToDetails.firstName} {assignedToDetails.lastName} ({assignedToDetails.id})
          </Typography.Text>
        </div>
        <div className="section">
          <Typography.Text className="section-title"> To:</Typography.Text>
          <Typography.Text className="section-content">
            {supplierContact.firstName} {supplierContact.lastName} ({supplierContact.email})
          </Typography.Text>
        </div>
        {displayCC()}
        {displayBCC()}
        <div className="section">
          <Typography.Text className="section-title"> Purchase order total:</Typography.Text>
          <Typography.Text className="section-content">
            {window.formatCurrency("GBP", purchaseOrder.total)}
          </Typography.Text>
        </div>
        <div className="section">
          <Typography.Text className="section-title"> Email preview:</Typography.Text>
          <div className="email-preview" dangerouslySetInnerHTML={{ __html: htmlEmailContent }} />
        </div>
        <div className="buttons">
          <Button onClick={closeModal} disabled={isLoading}>
            No, cancel
          </Button>
          <Button onClick={onSubmit} type="primary" loading={isLoading}>
            <SendOutlined />
            {isLoading ? "Sending..." : "Yes, send"}
          </Button>
        </div>
      </div>
      <PdfRenderer fileData={approvedPdfData} includePagination={true} renderMode="canvas" />
    </Modal>
  );
}

export default withSubscriptions({
  Component: SendPurchaseOrderModal,
  subscriptions: ["clients", "users"],
});
