import { Component } from "react";
import { Select, Typography, Tag } from "antd";
import cx from "classnames";
import { processIdForDisplay } from "common/helpers";

import DashboardItemTags from "DashboardItemTags/DashboardItemTags";
import withSubscriptions from "common/withSubscriptions";

import "./PurchaseOrderPicker.scss";
/**
 * excludeList: [String] - represents a list of user IDs (email addresses) which should not be shown in the list of options
 **/
export class PurchaseOrderPicker extends Component {
  compare = (expected, actual) => {
    return expected.toLowerCase().split(" ").join("").includes(actual.toLowerCase().split(" ").join(""));
  };

  filterOption = (targetText, { purchaseOrder, project, client, value }) => {
    try {
      if (value === null) {
        return true;
      }

      if (client?.name.toLowerCase().includes(targetText)) {
        return true;
      } else if ((project.title || "").toLowerCase().includes(targetText)) {
        return true;
      } else if (purchaseOrder.title.toLowerCase().includes(targetText)) {
        return true;
      } else if ((purchaseOrder.subtitle || "").toLowerCase().includes(targetText)) {
        return true;
      } else if (purchaseOrder.id.toLowerCase().includes(targetText)) {
        return true;
      } else if ((purchaseOrder.assignedTo || "").toLowerCase().includes(targetText)) {
        return true;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  render() {
    const {
      excludeList,
      value,
      placeholder,
      suffixIcon,
      apiUser,
      disabled,
      purchaseOrders,
      projects,
      clients,
      allowClear = true,
      organisationDetails,
    } = this.props;

    let filteredPurchaseOrders = purchaseOrders.filter((x) => !x.isHidden && !x.id.includes("-TEMPLATES"));

    if (excludeList) {
      filteredPurchaseOrders = filteredPurchaseOrders.filter((x) => !excludeList.includes(x.id));
    }

    const extraProps = {
      value,
    };

    if (this.props["data-cy"]) {
      extraProps["data-cy"] = this.props["data-cy"];
    }

    if (placeholder) {
      extraProps.placeholder = placeholder;
    } else {
      extraProps.placeholder = "Select purchase order";
    }

    if (suffixIcon !== undefined) {
      extraProps.suffixIcon = suffixIcon;
    }

    return (
      <div className="purchase-order-picker">
        <Select
          showSearch
          className={cx("purchase-order-picker", this.props.className, {
            "active-on-hover": this.props.activateOnHover,
          })}
          filterOption={this.filterOption}
          onChange={this.props.onChange}
          disable={disabled}
          allowClear={allowClear}
          optionLabelProp="label"
          listHeight={600}
          {...extraProps}
        >
          {filteredPurchaseOrders.map((purchaseOrder) => {
            const project = projects.find((x) => x.id === purchaseOrder.projectId);
            const client = clients.find((x) => x.id === purchaseOrder.clientId);
            return (
              <Select.Option
                value={purchaseOrder.id}
                key={`purchase-order-option-${purchaseOrder.id}`}
                className={cx("purchase-order-picker-option", `option-user-${purchaseOrder.id.split("@")[0]}`)}
                client={client}
                project={project}
                purchaseOrder={purchaseOrder}
                label={
                  <Typography.Text className="purchase-order-picker-option-label">
                    <Tag className="dark-tag">{processIdForDisplay(purchaseOrder.id)}</Tag>
                    <Typography.Text className="purchase-order-picker-option-label-purchase-order-title">
                      {purchaseOrder.title}
                    </Typography.Text>
                  </Typography.Text>
                }
              >
                <div className="purchase-order-picker-option-inner">
                  <Typography.Text className="project-title">
                    {client.name} / {project.title}
                  </Typography.Text>
                  <Typography.Text className="purchase-order-title"> {purchaseOrder.title}</Typography.Text>
                  <div className="tags-container">
                    <Tag className="dark-tag">{processIdForDisplay(purchaseOrder.id)}</Tag>
                    <DashboardItemTags
                      item={{ ...purchaseOrder, client, project }}
                      organisationDetails={organisationDetails}
                    />
                  </div>
                </div>
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  }
}

export default withSubscriptions({
  Component: PurchaseOrderPicker,
  subscriptions: ["purchaseOrders", "projects", "clients", "organisationDetails"],
});
