import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Auth, Storage } from "aws-amplify";
import Card from "Card/Card";
import DragAndDrop from "DragAndDrop/DragAndDrop";
import UploadProgressView from "Attachments/UploadProgressView/UploadProgressView";
import { callRest } from "common/apiHelpers";
import { sendPublicUploadNotification } from "common/notificationHelpers";

import { notification, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { PUBLIC_S3_BUCKET_URL } from "common/publicS3BucketUrl";

import "./PublicUploadPage.scss";

// if the user uploads files more often than this, do not send an activity item again
const ACTIVITY_EVENT_DELAY_THRESHOLD = 1000 * 3600 * 30;

export class PublicUploadPage extends React.Component {
  filesUploadedCount = 0;

  state = {
    files: [],
    pendingFiles: [],
    lastActivityEventSentAt: null,
  };

  componentDidMount = async () => {
    await this.getCredentials();
    this.getOrganisationLogo();
  };

  getCredentials = async (tryCount = 0) => {
    console.log("getCredentials()");
    try {
      await Auth.currentCredentials();
    } catch (e) {
      if (tryCount < 10) {
        console.log("retrying to get credentials");
        setTimeout(() => this.getCredentials(tryCount + 1), 1000);
      }
    }
  };

  getQuery = () => {
    const { location } = this.props;
    const queryParams = queryString.parse(location.search);
    const details = queryParams.details;
    const parsedDetails = JSON.parse(atob(details));
    return parsedDetails;
  };

  getKey = (localKey) => {
    const query = this.getQuery();
    let fileKey = `${query.organisation.toUpperCase()}/projects/${query.project.toUpperCase()}/attachments/`;
    if (query.task) {
      fileKey += `${query.task.toUpperCase()}/`;
    }
    if (query.attachmentsPrefix) {
      let prefix = query.attachmentsPrefix;
      if (query.task) {
        prefix = prefix.split(`${query.task}/`).join("");
      } else if (query.project) {
        prefix = prefix.split(`${query.project}/`).join("");
      }

      fileKey += prefix;
    }
    fileKey += `Public uploads/${moment().format("DD-MM-YYYY")}/`;
    fileKey += localKey;

    return fileKey.split("//").join("/");
  };

  onFileInputChange = (e) => {
    let augmentedFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const rawFile = e.target.files[i];
      augmentedFiles.push({
        size: rawFile.size,
        fullPath: `/${rawFile.name}`,
        name: rawFile.name,
        fileObject: rawFile,
      });
    }
    this.onFilesDropped(e, augmentedFiles);
  };

  createActivityEvent = async () => {
    const { lastActivityEventSentAt } = this.state;

    const query = this.getQuery();

    if (lastActivityEventSentAt && Date.now() - lastActivityEventSentAt < ACTIVITY_EVENT_DELAY_THRESHOLD) {
      return;
    }

    this.setState({ lastActivityEventSentAt: Date.now() });

    const parentType = query.task ? "task" : "project";
    const parentId = query.task ? query.task : query.project;
    // const message = `There is a new public attachment upload on the ${parentType}: ${parentId}`;

    // if (parentType === "project") {
    //   await callGraphQLSimple({
    //     displayError: false,
    //     mutation: "createActivityItem",
    //     variables: {
    //       input: {
    //         parentId,
    //         organisation: query.organisation,
    //         type: "PUBLIC_UPLOAD",
    //       },
    //     },
    //   });
    // } else {
    //   await callGraphQLSimple({
    //     displayError: false,
    //     mutation: "createTaskActivityItem",
    //     variables: {
    //       input: {
    //         taskId: query.task,
    //         organisation: query.organisation,
    //         type: "PUBLIC_UPLOAD",
    //       },
    //     },
    //   });
    // }

    await sendPublicUploadNotification({
      type: "PUBLIC_UPLOAD",
      parentId,
      parentType,
      taskTitle: query.taskTitle,
      projectTitle: query.projectTitle,
      userId: query.email,
    });
  };

  onFilesDropped = async (_, attachments) => {
    this.createActivityEvent();
    attachments.forEach((attachment) => (attachment.fullPath = attachment.fullPath.substring(1)));

    this.setState({
      pendingFiles: [...this.state.pendingFiles, ...attachments],
    });

    try {
      attachments.forEach(async (attachmentContainer, i) => {
        const attachment = attachmentContainer.fileObject;

        let fileKey = this.getKey(attachmentContainer.fullPath);

        const _this = this;
        Storage.put(fileKey, attachment, {
          contentType: attachment.type,
          progressCallback(progress) {
            _this.setState({
              [`progress-${attachmentContainer.fullPath}`]: progress,
            });
          },
        }).catch((e) => {
          _this.setState({ [`error-${attachmentContainer.fullPath}`]: true });
        });
      });
    } catch (e) {
      console.error("error:", e);
      notification.error({
        message: (
          <Typography.Text>
            Could not upload:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  };

  getOrganisationLogo = async () => {
    const query = this.getQuery();
    try {
      let response = await callRest({
        method: "GET",
        route: `/organisation-logo/${query.organisation}`,
        includeCredentials: false,
      });

      if (response) {
        this.setState({ organisationLogo: `${PUBLIC_S3_BUCKET_URL}/public/${response}` });
      }
    } catch (e) {
      console.error("error:", e);
    }
  };

  render() {
    const { pendingFiles } = this.state;
    let query = this.getQuery();

    let uploadTitleLines = [];
    let title = null;
    if (query.firstName || query.lastName) {
      title = `${query.firstName || ""} ${query.lastName || ""} is inviting you to upload files`;
    } else {
      title = "You are invited to upload files";
    }
    if (query.client) {
      uploadTitleLines.push(
        <>
          <span className="item-label">Client:</span>{" "}
          <span className="item-value" data-cy="client-name">
            {query.client}
          </span>
        </>
      );
    }
    if (query.project) {
      uploadTitleLines.push(
        <>
          <span className="item-label">Project ID:</span>{" "}
          <span className="item-value" data-cy="project-id">
            {query.project}
          </span>
        </>
      );
    }
    if (query.projectTitle) {
      uploadTitleLines.push(
        <>
          <span className="item-label">Project Title:</span>{" "}
          <span className="item-value" data-cy="project-title">
            {query.projectTitle}
          </span>
        </>
      );
    }
    if (query.task) {
      uploadTitleLines.push(
        <>
          <span className="item-label">Task ID:</span> <span className="item-value">{query.task}</span>
        </>
      );
    }
    if (query.taskTitle) {
      uploadTitleLines.push(
        <>
          <span className="item-label">Task Title:</span> <span className="item-value">{query.taskTitle}</span>
        </>
      );
    }

    let uploadFilesTitle = (
      <>
        <Typography.Text className="upload-title">{title}</Typography.Text>
      </>
    );

    return (
      <div className="public-upload-page">
        <Card title={uploadFilesTitle}>
          <div className="card-content">
            <div className="upload-details-container">
              {this.state.organisationLogo && (
                <div className="organisation-logo-container">
                  <img src={this.state.organisationLogo} alt="organisation logo" />{" "}
                </div>
              )}
              {uploadTitleLines.map((line, i) => (
                <Typography.Text key={i} className="upload-details-line">
                  {line}
                </Typography.Text>
              ))}
            </div>
            <input
              type="file"
              onChange={this.onFileInputChange}
              multiple="multiple"
              id="upload-button"
              style={{ display: "none" }}
            />
            <label htmlFor="upload-button" className="upload-container">
              <DragAndDrop onChange={this.onFilesDropped}>
                <div className="upload-instructions-container">
                  <UploadOutlined className="upload-icon" />
                  <Typography.Text className="upload-instructions">
                    Click to select files or just drop them here
                  </Typography.Text>
                </div>
              </DragAndDrop>
            </label>
          </div>
          <UploadProgressView
            pendingFiles={pendingFiles}
            parentState={this.state}
            uploadIsFinished={false}
            includeOverallProgress={false}
            failedUploads={[]}
          />
        </Card>
      </div>
    );
  }
}

export default withRouter(PublicUploadPage);
