import moment from "moment";

import { roundToQuarter } from "common/mathHelpers";
import isTimesheetBlockASiteVisit from "common/isTimesheetBlockASiteVisit";
import isTimesheetBlockACall from "common/isTimesheetBlockACall";

const fields = [
  {
    id: "dayText",
    fieldTypes: ["textfield"],
    label: "Date",
    isDate: true,
    value: ({ day, dateFormat }) => {
      if (!day) {
        return "";
      }
      return moment(day).format(dateFormat);
    },
  },
  {
    id: "dayLinkToUserTimesheet",
    fieldTypes: ["textfield"],
    label: "Link to user timesheet for this day",
    value: ({ day, user }) => {
      if (!day) {
        return "";
      }
      return `${window.location.origin}/user-timesheet/${user.id}/${day}`;
    },
  },
  {
    id: "dayHoursSpentOnSiteVisitsOrVideoCalls",
    fieldTypes: ["textfield"],
    label: "Hours spent on site visits or video calls",
    value: (params) => {
      const { day, timesheetBlocks, timesheetTags } = params;

      if (!day) {
        return "";
      }

      let totalHours = 0;

      timesheetBlocks.forEach((timesheetBlock) => {
        if (moment(timesheetBlock.startAt).isSame(day, "day")) {
          if (
            isTimesheetBlockASiteVisit({ timesheetBlock, timesheetTags }) ||
            isTimesheetBlockACall({ timesheetBlock, timesheetTags })
          ) {
            let durationHours = roundToQuarter(
              moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
            );
            totalHours = roundToQuarter(totalHours + durationHours);
          }
        }
      });

      return `${totalHours}`;
    },
  },
  {
    id: "dayHoursSpentOnOnHoliday",
    fieldTypes: ["textfield"],
    label: "Hours spent sick or on holiday",
    value: ({ day, timesheetBlocks, timesheetTags }) => {
      if (!day) {
        return "";
      }

      let totalHours = 0;

      timesheetBlocks.forEach((timesheetBlock) => {
        if (moment(timesheetBlock.startAt).isSame(day, "day")) {
          if (
            timesheetBlock.taskId?.toLowerCase().includes("holiday") ||
            timesheetBlock.taskId?.toLowerCase().includes("sick")
          ) {
            let durationHours = roundToQuarter(
              moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
            );
            totalHours = roundToQuarter(totalHours + durationHours);
          }
        }
      });

      return `${totalHours}`;
    },
  },
  {
    id: "dayHoursUnbillableButWorkedOnDesign",
    fieldTypes: ["textfield"],
    label: "Hours unbillable, but worked on design",
    value: ({ day, timesheetBlocks, timesheetTags }) => {
      if (!day) {
        return "";
      }

      let totalHours = 0;

      timesheetBlocks.forEach((timesheetBlock) => {
        if (moment(timesheetBlock.startAt).isSame(day, "day")) {
          if (
            timesheetBlock.taskId?.toLowerCase().includes("holiday") ||
            timesheetBlock.taskId?.toLowerCase().includes("sick") ||
            isTimesheetBlockACall({ timesheetBlock, timesheetTags }) ||
            isTimesheetBlockASiteVisit({ timesheetBlock, timesheetTags })
          ) {
            return;
          }

          if (!timesheetBlock.billable) {
            let durationHours = roundToQuarter(
              moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
            );
            totalHours = roundToQuarter(totalHours + durationHours);
          }
        }
      });

      return `${totalHours}`;
    },
  },
  {
    id: "dayHoursBillableWorkedOnDesignReadyForBilling",
    fieldTypes: ["textfield"],
    label: "Hours billable, worked on design, ready for billing",
    value: ({ day, tasks, tasksWithRevisions, timesheetBlocks, timesheetTags }) => {
      if (!day) {
        return "";
      }

      if (!tasks) {
        return "";
      }

      let totalHours = 0;

      timesheetBlocks.forEach((timesheetBlock) => {
        if (moment(timesheetBlock.startAt).isSame(day, "day")) {
          if (
            timesheetBlock.taskId?.toLowerCase().includes("holiday") ||
            timesheetBlock.taskId?.toLowerCase().includes("sick") ||
            isTimesheetBlockACall({ timesheetBlock, timesheetTags }) ||
            isTimesheetBlockASiteVisit({ timesheetBlock, timesheetTags }) ||
            !timesheetBlock.billable
          ) {
            return;
          }

          let taskDetails = tasksWithRevisions.find((task) => task.id === timesheetBlock.taskId);

          if (!taskDetails || !taskDetails.revisions?.items.length) {
            // console.log("cannot find task details for task id", timesheetBlock.taskId);
            return;
          }

          let taskRevisionDetails = taskDetails.revisions.items.find(
            (revision) => revision.id === timesheetBlock.taskRevisionId
          );

          if (!taskRevisionDetails) {
            return;
          }

          if (taskRevisionDetails.reviewAcceptDate) {
            let durationHours = roundToQuarter(
              moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
            );
            totalHours = roundToQuarter(totalHours + durationHours);
          }
        }
      });

      return `${totalHours}`;
    },
  },
  {
    id: "dayHoursBillableWorkedOnDesignNotReadyForBilling",
    fieldTypes: ["textfield"],
    label: "Hours billable, worked on design, not ready for billing",
    value: ({ day, tasks, tasksWithRevisions, timesheetBlocks, timesheetTags }) => {
      if (!day) {
        return "";
      }

      if (!tasks) {
        return "";
      }

      let totalHours = 0;

      timesheetBlocks.forEach((timesheetBlock) => {
        if (moment(timesheetBlock.startAt).isSame(day, "day")) {
          if (
            timesheetBlock.taskId?.toLowerCase().includes("holiday") ||
            timesheetBlock.taskId?.toLowerCase().includes("sick") ||
            isTimesheetBlockACall({ timesheetBlock, timesheetTags }) ||
            isTimesheetBlockASiteVisit({ timesheetBlock, timesheetTags }) ||
            !timesheetBlock.billable
          ) {
            return;
          }

          let includeInTotal = false;
          let taskDetails = tasksWithRevisions.find((task) => task.id === timesheetBlock.taskId);

          if (!taskDetails || !taskDetails.revisions?.items.length) {
            includeInTotal = true;
          } else {
            let taskRevisionDetails = taskDetails.revisions.items.find(
              (revision) => revision.id === timesheetBlock.taskRevisionId
            );

            if (!taskRevisionDetails) {
              includeInTotal = true;
            } else {
              if (!taskRevisionDetails.reviewAcceptDate) {
                let durationHours = roundToQuarter(
                  moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
                );
                totalHours = roundToQuarter(totalHours + durationHours);
              }
            }
          }

          if (includeInTotal) {
            let durationHours = roundToQuarter(
              moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
            );
            totalHours = roundToQuarter(totalHours + durationHours);
          }
        }
      });

      return `${totalHours}`;
    },
  },
  {},
];

export function getFields({ organisationDetails }) {
  let extraFields = [];

  return [...fields, ...extraFields];
}

export const label = "Day";
