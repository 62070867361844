import { useState } from "react";
import { Form, Modal, Button, Alert } from "antd";

import { useForceUpdate } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";

import UsersFilter from "UsersFilter/UsersFilter";

import "./AddUserToTeamModal.scss";

export default function AddUserToTeamModal({ onClose, users, selectedTeam }) {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const forceUpdate = useForceUpdate();

  async function onSubmit(formFields) {
    setIsLoading(true);
    setError();

    let userDetails = users.find((x) => x.id === formFields.userToBeAdded);

    try {
      await callGraphQLSimple({
        message: "Could not add user to team",
        queryName: "updateUser",
        variables: {
          input: {
            id: userDetails.id,
            teams: [...(userDetails.teams || []), selectedTeam.id],
          },
        },
      });
      onClose();
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title="Add user to team"
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="add-user-to-team-modal"
    >
      {error ? (
        <>
          <Alert message={error} type="error" showIcon />
          <br />
        </>
      ) : null}
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item label="User to be added" name="userToBeAdded">
          <UsersFilter
            activateOnHover={false}
            onChange={(target) => {
              form.setFieldsValue({ userToBeAdded: target });
              forceUpdate();
            }}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
        {error ? <Alert message={error} type="error" showIcon /> : null}
      </Form>
    </Modal>
  );
}
