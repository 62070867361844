import React from "react";
import { Auth } from "aws-amplify";
import { Route, Switch, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import cx from "classnames";

import awsExports from "aws-exports";
import { Context } from "common/context";
import { callRest } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";
import linkApi from "common/link";
import { PUBLIC_USER_EMAIL, PUBLIC_USER_PASSWORD, REFRESH_S3_INTERVAL_DELAY } from "common/constants";
import { isAuthorised } from "common/permissions";
import getOrderedActiveUsers from "common/getOrderedActiveUsers";
import { checkForAlternateUsersAndSwitchToFirstOne, getCurrentUserOrganisationId } from "common/authHelpers";
import saveCache from "common/saveCache";

import Analytics from "Analytics/Analytics";
import AutomationListener from "AutomationListener/AutomationListener";
import ScheduledNotificationsManager from "ScheduledNotificationsManager/ScheduledNotificationsManager";
import SecureRoute from "SecureRoute/SecureRoute";
import PublicNavigationBar from "PublicNavigationBar/PublicNavigationBar";
import DashboardPage from "DashboardPage/DashboardPage";
import TimelinePage from "TimelinePage/TimelinePage";
import PublicUploadPage from "PublicUploadPage/PublicUploadPage";
import LandingPage from "LandingPage/LandingPage";
import PrivacyPolicy from "PrivacyPolicy/PrivacyPolicy";
import UserAgreement from "UserAgreement/UserAgreement";
import ContactModal from "Modals/ContactModal/ContactModal";
import LoadingWrapper from "LoadingWrapper/LoadingWrapper";
import NavigationBar from "NavigationBar/NavigationBar";
import AccountPage from "AccountPage/AccountPage";
import UsersPage from "UsersPage/UsersPage";
import TaskDetailsPage from "TaskDetailsPage/TaskDetailsPage";
import ProjectDetailsPage from "ProjectDetailsPage/ProjectDetailsPage";
import SignInPage from "SignInPage/SignInPage";
import ResetPasswordPage from "ResetPasswordPage/ResetPasswordPage";
import ChangePasswordPage from "ChangePasswordPage/ChangePasswordPage";
import ReviewPage from "ReviewPage/ReviewPageContainer/ReviewPageContainer";
import RequestReviewPage from "RequestReviewPage/RequestReviewPage";
import ReportPage from "ReportPage/ReportPage";
import ReportPageBrowserRendering from "ReportPage/ReportPageBrowserRendering";
import FileDetailsPage from "FileDetailsPage/FileDetailsPage";
import FileDetailsPageNew from "FileDetailsPage/FileDetailsPageNew";
import UnauthorisedPage from "UnauthorisedPage/UnauthorisedPage";
import XeroCallbackPage from "XeroCallbackPage/XeroCallbackPage";
import QuickbooksCallbackPage from "QuickbooksCallbackPage/QuickbooksCallbackPage";
import DocumentViewerPage from "DocumentViewerPage/DocumentViewerPage";
import OrganisationReportsPage from "OrganisationReportsPage/OrganisationReportsPage";
import RequestsPage from "pages/RequestsPage/RequestsPage";
import RequestDetailsPage from "pages/RequestDetailsPage/RequestDetailsPage";

import PublicUrlPage from "pages/PublicUrlPage/PublicUrlPage";
import ClientsPage from "ClientsPage/ClientsPage";
import SprintsPage from "SprintsPage/SprintsPage";
import ProjectsPage from "ProjectsPage/ProjectsPage";
import QueuePage from "QueuePage/QueuePage";
import OrganisationSettingsPage from "OrganisationPage/OrganisationSettingsPage/OrganisationSettingsPage";
import ThirdPartyAppsPage from "ThirdPartyAppsPage/ThirdPartyAppsPage";
import TemplatesPage from "OrganisationPage/TemplatesPage/TemplatesPage";
import QuotesPage from "QuotesPage/QuotesPage";
import InvoicesPage from "InvoicesPage/InvoicesPage";
import PurchaseOrdersPage from "PurchaseOrdersPage/PurchaseOrdersPage";
import SuppliersPage from "SuppliersPage/SuppliersPage";
import PurchaseOrderDetailsPage from "PurchaseOrderDetailsPage/PurchaseOrderDetailsPage";
import BatchCreateInvoicesPage from "BatchCreateInvoicesPage/BatchCreateInvoicesPage";
import QuoteDetailsPage from "QuoteDetailsPage/QuoteDetailsPage";
import InvoiceDetailsPage from "InvoiceDetailsPage/InvoiceDetailsPage";
import CookiePolicyModal from "Modals/CookiePolicyModal/CookiePolicyModal";
import CookiePolicy from "CookiePolicy/CookiePolicy";
import ClickCapture from "ClickCapture/ClickCapture";
import { PageProps, RouterProps } from "common/props";
import DocumentLibraryPage from "DocumentLibraryPage/DocumentLibraryPage";
import ClientDetailsPage from "ClientDetailsPage/ClientDetailsPage";
import SupplierDetailsPage from "SupplierDetailsPage/SupplierDetailsPage";
import UserTimesheetPage from "UserTimesheetPage/UserTimesheetPage";
import CalendarPage from "pages/CalendarPage/CalendarPage";
import TimesheetsPage from "TimesheetsPage/TimesheetsPage";
import TimeOffPage from "TimeOffPage/TimeOffPage";
import AnnotationEditorPage from "TemplateEditorPage/TemplateEditorPage";
import AnalyticsPage from "AnalyticsPage/AnalyticsPage";
import MonitoringPage from "MonitoringPage/MonitoringPage";
import LinearIndefinitePreloader from "LinearIndefinitePreloader/LinearIndefinitePreloader";
import StockItemsPage from "StockItemsPage/StockItemsPage";
import StockItemDetailsPage from "StockItemDetailsPage/StockItemDetailsPage";
import SimpleMobileHomePage from "SimpleMobileHomePage/SimpleMobileHomePage";
import PublicUploadShortUrlPage from "PublicUploadShortUrlPage/PublicUploadShortUrlPage";
import ContactsPage from "ContactsPage/ContactsPage";

import "./Generic.scss";
import "./Common.scss";
import "./App.scss";
import "./Screenshot.scss";

type State = {
  user: any;
  shouldDisplayBackground: boolean;
  shouldDisplayBoxedLayout: boolean;
  shouldBeNoScroll: boolean;
  shouldHaveLeftMargin: boolean;
  shouldHaveRightMargin?: boolean;
  isLoading: boolean;
  isUserLoggedIn?: boolean;
  isContactModalVisible: boolean;
  loggedInUsers?: any;
  cognitoIdentityKey?: string;
  windowWidth: number;
  windowHeight: number;
};

type Props = {
  users: any[];
  organisations: any[];
  reloadData: any;
  clients: any[];
  fetchAndSetTasks: (any) => any;
  fetchAndSetClients: (any) => any;
  fetchAndSetQuotes: (any) => any;
  fetchAndSetInvoices: (any) => any;
  groups: any[];
  isLoadingInitialData: boolean;
  hasRestoredCachedData: boolean;
};

export class App extends React.Component<Props & RouterProps, State> {
  refreshCognitoSessionInterval: any = undefined;
  refreshS3Interval: any = undefined;
  saveCacheInterval: any = undefined;

  state = {
    user: null,
    secrets: [],
    shouldDisplayBackground: true,
    shouldDisplayBoxedLayout: true,
    shouldBeNoScroll: false,
    shouldHaveLeftMargin: true,
    shouldHaveRightMargin: true,
    isLoading: true,
    isUserLoggedIn: undefined,
    isContactModalVisible: false,
    loggedInUsers: undefined,
    cognitoIdentityKey: undefined,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  };

  constructor(props) {
    super(props);
    window.appComponent = this;
  }

  async componentDidMount() {
    window.appHistory = this.props.history;
    this.refreshCognitoSessionInterval = setInterval(
      this.refreshCognitoSession,
      1000 * 60 * 10 // 10 minutes
    );

    this.checkUserAndInitialise();

    window.checkUserAndInitialise = this.checkUserAndInitialise;
    window.reloadData = this.reloadRootApplicationData;
    this.refreshS3Interval = setInterval(this.initialiseS3, REFRESH_S3_INTERVAL_DELAY);
    window.addEventListener("resize", this.onWindowResize);
    window.listLoggedInUsers = () => this.listLoggedInUsers();
    window.users = this.props.users;
    window.groups = this.props.groups;

    this.saveCacheInterval = setInterval(() => {
      saveCache(this);
    }, 15000);
  }

  async componentWillUnmount() {
    if (this.saveCacheInterval) {
      clearInterval(this.saveCacheInterval);
    }
    if (this.refreshS3Interval) {
      clearInterval(this.refreshS3Interval);
    }

    window.removeEventListener("resize", this.onWindowResize);
    await saveCache(this);
  }

  onWindowResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  };

  checkUserAndInitialise = async () => {
    if (Object.keys(localStorage).length === 0) {
      this.setState({ isLoading: false, isUserLoggedIn: false }, async () => {
        await this.signInPublicUser();
      });
      return;
    }

    let user;

    try {
      user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      const organisationId = await getCurrentUserOrganisationId(user);

      if (organisationId === "PUBLIC") {
        const successfullySwitchedToAnAlternateUser = await checkForAlternateUsersAndSwitchToFirstOne();
        if (successfullySwitchedToAnAlternateUser) {
          window.location.reload();
          return;
        }
      }

      this.setState({ user, isUserLoggedIn: true });
      try {
        await linkApi.updateCredentials();
      } catch (e) {
        // nothing, this fails when Link is not running
      }

      if (user.signInUserSession.idToken.payload.email === PUBLIC_USER_EMAIL) {
        this.setState({ isLoading: false });
        return;
      }

      window.cognitoIdToken = user.signInUserSession.idToken.jwtToken;
    } catch (e) {
      console.error("checkUserAndInitialise() e = ", e);
      const successfullySwitchedToAnAlternateUser = await checkForAlternateUsersAndSwitchToFirstOne();
      // wait a second
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (successfullySwitchedToAnAlternateUser) {
        window.location.reload();
      } else {
        this.setState({ isLoading: false, isUserLoggedIn: false }, async () => {
          await this.signInPublicUser();
        });
      }
    }

    if (user) {
      await Promise.all([this.initialiseS3(), this.initialiseCognito(), this.getSecretsFromSecretsManager()]);
      let organisation = await getCurrentUserOrganisationId();

      this.setState({ isLoading: false });

      window.refreshTasks = () => {
        this.props.fetchAndSetTasks({ organisation });
      };
      window.refreshClients = () => {
        this.props.fetchAndSetClients({ organisation });
      };
      window.refreshInvoices = () => {
        this.props.fetchAndSetInvoices({ organisation });
      };
      this.listLoggedInUsers();
    }
  };

  listLoggedInUsers = async () => {
    let currentUser;
    try {
      currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
    } catch (e) {
      console.error("no logged in user, cannot list logged in users");
    }
    if (currentUser) {
      const currentUserDetails = this.props.users?.find(
        (x) => x.id === currentUser.signInUserSession.idToken.payload.email
      );
      const keyPrefix = currentUser.keyPrefix;

      if (currentUserDetails) {
        localStorage.setItem(
          `${currentUserDetails.id}.userDetails`,
          JSON.stringify({
            firstName: currentUserDetails.firstName,
            lastName: currentUserDetails.lastName,
            avatarKey: currentUserDetails.avatarKey,
            organisation: currentUserDetails.organisation,
          })
        );
      }

      let loggedInUsers: any[] = [];
      for (let key in localStorage) {
        if (key.startsWith(keyPrefix) && key.includes("userData")) {
          let userDetails = JSON.parse(localStorage[key]);
          loggedInUsers.push(userDetails);
        }
      }

      localStorage.setItem("DraughtHub.cognitoIdentityKey", keyPrefix);

      this.setState({ loggedInUsers, cognitoIdentityKey: keyPrefix });
    } else {
      this.setState({ loggedInUsers: [], cognitoIdentityKey: undefined });
    }
  };

  componentDidUpdate = () => {
    const { users, clients } = this.props;
    const { user } = this.state;

    if (!user || !users) {
      return;
    }

    if (clients) {
      window.dataIsLoaded = true;
    }

    window.users = this.props.users;
    window.groups = this.props.groups;
  };

  signInPublicUser = async () => {
    await Auth.signIn({
      username: PUBLIC_USER_EMAIL,
      password: PUBLIC_USER_PASSWORD,
    });

    this.checkUserAndInitialise();
  };

  refreshCognitoSession = async () => {
    try {
      await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
    } catch (e) {
      // nothing to do
    }
  };

  setBackground = (value) => {
    this.setState({ shouldDisplayBackground: value });
  };

  setBoxedLayout = (value) => {
    this.setState({ shouldDisplayBoxedLayout: value });
  };

  setLeftMargin = (value) => {
    this.setState({ shouldHaveLeftMargin: value });
  };

  setRightMargin = (value) => {
    this.setState({ shouldHaveRightMargin: value });
  };

  setNoScroll = (value) => {
    this.setState({ shouldBeNoScroll: value });
  };

  initialiseS3 = async () => {
    const credentials = await Auth.currentCredentials();
    const S3 = window.AWS.S3;

    const essentialCredentials = Auth.essentialCredentials(credentials);
    const s3Client = new S3({
      apiVersion: "2013-04-01",
      credentials: essentialCredentials,
      region: awsExports.aws_user_files_s3_bucket_region,
    });
    window.s3 = s3Client;
  };

  initialiseCognito = async () => {
    const credentials = await Auth.currentCredentials();
    const essentialCredentials = Auth.essentialCredentials(credentials);
    const cognitoClient = new window.AWS.CognitoIdentityServiceProvider({
      apiVersion: "2016-04-18",
      credentials: essentialCredentials,
      region: awsExports.aws_user_files_s3_bucket_region,
    });
    window.cognito = cognitoClient;
  };

  getSecretsFromSecretsManager = async () => {
    try {
      if (window.accountName === "EIS") {
        return;
      }
      const response = await callRest({
        method: "GET",
        route: "/get-secrets-from-secrets-manager",
      });

      window.secrets = response;
    } catch (err) {
      console.error("error when getting secrets from Secrets Manager = ", err);
    }
  };

  loadFeatureFlagsFromCookies = () => {
    // const featureNewReport = cookie.get("feature-new-report");
    // if (featureNewReport === "true") {
    //   window.featureNewReport = true;
    // } else {
    //   window.featureNewReport = false;
    // }
  };

  reloadRootApplicationData = async () => {
    await this.clearAllSubscriptions();

    // just an extra precaution to make sure that subscriptions have actually been removed
    await new Promise((resolve) => setTimeout(resolve, 200));
    this.props.reloadData();
  };

  clearAllSubscriptions = async () => {
    for (let propertyName in window) {
      if (propertyName.indexOf("subscription") === 0) {
        if (!window[propertyName].unsubscribe || typeof window[propertyName].unsubscribe !== "function") {
          console.error("Encountered fake subscription:", propertyName);
          continue;
        }
        try {
          await window[propertyName].unsubscribe();
        } catch (e) {
          console.error("ERROR all::failed to unsubscribe from ", propertyName, e);
          // nothing to do
        }
      }
    }
  };

  displayHomeOrLandingPage = ({ apiUser, userHasSimpleMobileView, isMobile, pageProps }) => {
    if (!apiUser) {
      return <LandingPage {...pageProps} onContact={() => this.setState({ isContactModalVisible: true })} />;
    }
    if (userHasSimpleMobileView && isMobile) {
      return (
        <SimpleMobileHomePage
          {...pageProps}
          {...this.state}
          users={this.props.users}
          loggedInUsers={this.state.loggedInUsers}
          cognitoIdentityKey={this.state.cognitoIdentityKey}
          reloadData={this.reloadRootApplicationData}
        />
      );
    } else {
      return <DashboardPage {...pageProps} />;
    }
  };

  render() {
    const {
      user,
      shouldDisplayBackground,
      shouldDisplayBoxedLayout,
      shouldBeNoScroll,
      isLoading,
      windowWidth,
      windowHeight,
      isContactModalVisible,
    } = this.state;

    const { isLoadingInitialData } = this.props;

    const pageProps: PageProps = {
      username: (user as any)?.username,
      user,
      setBackground: this.setBackground,
      setBoxedLayout: this.setBoxedLayout,
      setRightMargin: this.setRightMargin,
      setLeftMargin: this.setLeftMargin,
      setNoScroll: this.setNoScroll,
      windowWidth,
      windowHeight,
    };

    const userEmail = (user as any)?.signInUserSession?.idToken?.payload?.email;
    const apiUser = this.props.users?.find((x) => x.id === userEmail);

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Automate the admin. Focus on the engineering. Saving Civil Engineers one day a week."
          />
          <title>DraughtHub</title>
        </Helmet>
        <LoadingWrapper
          isLoading={isLoading || (!apiUser && userEmail !== PUBLIC_USER_EMAIL)}
          useLogo={true}
          content={() => {
            if (window.selectedTeams?.length === 0) {
              window.selectedTeams = apiUser?.teams || [];
            }

            const organisationDetails = this.props.organisations?.find((x) => x.id === apiUser?.organisation);
            let users =
              this.props.users &&
              this.props.users.filter((user) => {
                // allow hidden users to see themselves
                return !user.isHidden || (user.isHidden && user.id === apiUser?.id);
              });

            const groups = this.props.groups;

            let orderedActiveUsers = getOrderedActiveUsers({ users, organisationDetails, apiUser });

            const organisationDetailsProp = {
              ...organisationDetails,
              users,
              groups,
            };
            window.organisationDetails = organisationDetailsProp;
            this.loadFeatureFlagsFromCookies();

            let userHasSimpleMobileView = isAuthorised(["SIMPLE_MOBILE_VIEW"], undefined, true);
            let isMobile = windowWidth <= 850;

            return (
              <div
                className={cx("app", {
                  "with-background": shouldDisplayBackground,
                  "with-boxed-layout": shouldDisplayBoxedLayout,
                  "with-right-margin": this.state.shouldHaveRightMargin,
                  "with-left-margin": this.state.shouldHaveLeftMargin,
                  "no-scroll": shouldBeNoScroll,
                  "logged-out": !apiUser,
                })}
              >
                <Context.Provider
                  value={{
                    ...this.props,
                    windowWidth,
                    windowHeight,
                    organisationDetails: organisationDetailsProp,
                    apiUser,
                    users,
                    orderedActiveUsers,
                  }}
                >
                  <Analytics apiUser={apiUser} organisationDetails={organisationDetails} />
                  {apiUser && <AutomationListener />}
                  {apiUser && <ScheduledNotificationsManager />}

                  {apiUser ? (
                    <NavigationBar
                      {...pageProps}
                      {...this.state}
                      isMobile={isMobile}
                      users={this.props.users}
                      loggedInUsers={this.state.loggedInUsers}
                      cognitoIdentityKey={this.state.cognitoIdentityKey}
                      reloadData={this.reloadRootApplicationData}
                      userHasSimpleMobileView={userHasSimpleMobileView}
                    />
                  ) : (
                    <PublicNavigationBar onContactClick={() => this.setState({ isContactModalVisible: true })} />
                  )}

                  {apiUser && <ClickCapture apiUser={apiUser} />}
                  {apiUser && isLoadingInitialData && <LinearIndefinitePreloader position="bottom" />}
                  {apiUser && isLoadingInitialData && <LinearIndefinitePreloader position="top" />}

                  <div className="page-content">
                    {!window.Cypress && apiUser && !apiUser.hasAgreedToCookies && (
                      <CookiePolicyModal apiUser={apiUser} />
                    )}
                    <div className="inner-content">
                      <Switch>
                        <Route path="/" exact>
                          {this.displayHomeOrLandingPage({
                            apiUser,
                            userHasSimpleMobileView,
                            isMobile,
                            pageProps,
                          })}
                        </Route>
                        <SecureRoute path="/tasks" exact>
                          {isAuthorised(["DASHBOARD.VIEW"]) ? <DashboardPage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>

                        <Route path="/privacy-policy" exact>
                          <PrivacyPolicy
                            {...pageProps}
                            onContact={() => this.setState({ isContactModalVisible: true })}
                          />
                        </Route>
                        <Route path="/user-agreement" exact>
                          <UserAgreement
                            {...pageProps}
                            onContact={() => this.setState({ isContactModalVisible: true })}
                          />
                        </Route>

                        <Route path="/cookie-policy" exact>
                          <CookiePolicy
                            {...pageProps}
                            onContact={() => this.setState({ isContactModalVisible: true })}
                          />
                        </Route>
                        <Route path="/public-upload" exact>
                          <PublicUploadPage key={this.props.match.path} {...pageProps} />
                        </Route>
                        <Route path="/url/:urlId" exact>
                          <PublicUrlPage key={this.props.match.path} {...pageProps} />
                        </Route>
                        <Route path="/public-upload-url/:urlId" exact>
                          <PublicUploadShortUrlPage key={this.props.match.path} {...pageProps} />
                        </Route>
                        <Route path="/document-viewer/:fileKey/:page?">
                          <DocumentViewerPage {...pageProps} />
                        </Route>
                        <Route path="/sign-in">
                          <SignInPage
                            {...pageProps}
                            onSubmit={() => {
                              this.props.reloadData();
                            }}
                          />
                        </Route>
                        <Route path="/reset-password">
                          <ResetPasswordPage {...pageProps} />
                        </Route>
                        <SecureRoute path="/change-password">
                          <ChangePasswordPage {...pageProps} />
                        </SecureRoute>

                        <SecureRoute path="/requests" exact>
                          {isAuthorised(["REQUESTS.VIEW"]) ? <RequestsPage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>
                        <SecureRoute path="/requests/:requestId/review/:activityItemId" exact>
                          {isAuthorised(["REQUESTS.VIEW", "FULL.READ"]) ? (
                            <RequestReviewPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/requests/:requestId" exact>
                          {isAuthorised(["REQUESTS.VIEW"]) ? (
                            <RequestDetailsPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>

                        <SecureRoute path="/timeline" exact>
                          {isAuthorised(["TIMELINE.VIEW"]) ? <TimelinePage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>
                        <SecureRoute path="/clients" exact>
                          {isAuthorised(["CLIENTS.VIEW"]) ? <ClientsPage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>
                        <SecureRoute path="/suppliers" exact>
                          {isAuthorised(["SUPPLIERS.VIEW"]) ? <SuppliersPage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>
                        <SecureRoute path="/purchase-orders" exact>
                          {isAuthorised(["PURCHASE_ORDERS.VIEW"]) ? (
                            <PurchaseOrdersPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>

                        <SecureRoute path="/sprints" exact>
                          {isAuthorised(["SPRINTS.VIEW"]) ? <SprintsPage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>
                        <SecureRoute path="/projects" exact>
                          {isAuthorised(["PROJECTS.VIEW"]) ? <ProjectsPage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>
                        <SecureRoute path="/document-library" exact>
                          {isAuthorised(["DOCUMENT_LIBRARY.VIEW"]) ? (
                            <DocumentLibraryPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/queue" exact>
                          {isAuthorised(["QUEUE.VIEW"]) ? <QueuePage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>
                        <SecureRoute path="/quotes" exact>
                          {isAuthorised(["QUOTES.VIEW"]) ? <QuotesPage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>
                        <SecureRoute path="/invoices" exact>
                          {isAuthorised(["INVOICES.VIEW"]) ? <InvoicesPage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>

                        <SecureRoute path="/invoices/batch-create" exact>
                          {isAuthorised(["INVOICES.VIEW_BATCH_CREATE_PAGE"]) ? (
                            <BatchCreateInvoicesPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/quotes/:quoteId" exact>
                          {isAuthorised(["QUOTE_DETAILS.VIEW"]) ? (
                            <QuoteDetailsPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/invoices/:invoiceId">
                          {isAuthorised(["INVOICE_DETAILS.VIEW"]) ? (
                            <InvoiceDetailsPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/purchase-orders/:purchaseOrderId">
                          {isAuthorised(["PURCHASE_ORDERS_DETAILS.VIEW"]) ? (
                            <PurchaseOrderDetailsPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/quotes/:quoteId" exact>
                          {isAuthorised(["QUOTE_DETAILS.VIEW"]) ? (
                            <QuoteDetailsPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/tasks/:taskId" exact>
                          {isAuthorised(["TASK_DETAILS.VIEW"]) ? (
                            <TaskDetailsPage key={this.props.match.path} {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/clients/:clientId" exact>
                          {isAuthorised(["CLIENT_DETAILS.VIEW"]) ? (
                            <ClientDetailsPage key={this.props.match.path} {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/suppliers/:supplierId" exact>
                          {isAuthorised(["SUPPLIER_DETAILS.VIEW"]) ? (
                            <SupplierDetailsPage key={this.props.match.path} {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>

                        <SecureRoute path="/projects/:projectId" exact>
                          {isAuthorised(["PROJECT_DETAILS.VIEW"]) ? (
                            <ProjectDetailsPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/tasks/:taskId/review/:taskRevisionId" exact>
                          {isAuthorised(["REVIEW.VIEW"]) ? <ReviewPage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>

                        <SecureRoute path="/tasks/:taskId/BRICSCAD/:fileId" exact>
                          {isAuthorised(["FILE_DETAILS.VIEW"]) ? (
                            <>
                              {apiUser?.featureFlags?.batchEditSheets ? (
                                <FileDetailsPageNew {...pageProps} />
                              ) : (
                                <FileDetailsPage {...pageProps} />
                              )}
                            </>
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/tasks/:taskId/AUTOCAD/:fileId">
                          {isAuthorised(["FILE_DETAILS.VIEW"]) ? (
                            <>
                              {apiUser?.featureFlags?.batchEditSheets ? (
                                <FileDetailsPageNew {...pageProps} />
                              ) : (
                                <FileDetailsPage {...pageProps} />
                              )}
                            </>
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/tasks/:taskId/MATHCAD/:fileId" exact>
                          {isAuthorised(["FILE_DETAILS.VIEW"]) ? (
                            <>
                              {apiUser?.featureFlags?.batchEditSheets ? (
                                <FileDetailsPageNew {...pageProps} />
                              ) : (
                                <FileDetailsPage {...pageProps} />
                              )}
                            </>
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/tasks/:taskId/EXCEL/:fileId" exact>
                          {isAuthorised(["FILE_DETAILS.VIEW"]) ? (
                            <>
                              {apiUser?.featureFlags?.batchEditSheets ? (
                                <FileDetailsPageNew {...pageProps} />
                              ) : (
                                <FileDetailsPage {...pageProps} />
                              )}
                            </>
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/tasks/:taskId/WORD/:fileId" exact>
                          {isAuthorised(["FILE_DETAILS.VIEW"]) ? (
                            <>
                              {apiUser?.featureFlags?.batchEditSheets ? (
                                <FileDetailsPageNew {...pageProps} />
                              ) : (
                                <FileDetailsPage {...pageProps} />
                              )}
                            </>
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/tasks/:taskId/BLUEBEAM/:fileId" exact>
                          {isAuthorised(["FILE_DETAILS.VIEW"]) ? (
                            <>
                              {apiUser?.featureFlags?.batchEditSheets ? (
                                <FileDetailsPageNew {...pageProps} />
                              ) : (
                                <FileDetailsPage {...pageProps} />
                              )}
                            </>
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/tasks/:taskId/POWERPOINT/:fileId" exact>
                          {isAuthorised(["FILE_DETAILS.VIEW"]) ? (
                            <>
                              {apiUser?.featureFlags?.batchEditSheets ? (
                                <FileDetailsPageNew {...pageProps} />
                              ) : (
                                <FileDetailsPage {...pageProps} />
                              )}
                            </>
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/tasks/:taskId/REVIT/:fileId" exact>
                          {isAuthorised(["FILE_DETAILS.VIEW"]) ? (
                            <>
                              {apiUser?.featureFlags?.batchEditSheets ? (
                                <FileDetailsPageNew {...pageProps} />
                              ) : (
                                <FileDetailsPage {...pageProps} />
                              )}
                            </>
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/tasks/:taskId/REPORT/:fileId/:sheetId?" exact>
                          {isAuthorised(["REPORT.VIEW"]) ? (
                            <>
                              {apiUser?.featureFlags?.serverPoweredReportPublish ? (
                                <ReportPage {...pageProps} />
                              ) : (
                                <ReportPageBrowserRendering {...pageProps} />
                              )}
                            </>
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/tasks/:taskId/REPORT-OLD/:fileId/:sheetId?" exact>
                          {isAuthorised(["REPORT.VIEW"]) ? (
                            <>
                              <ReportPageBrowserRendering {...pageProps} />
                            </>
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>

                        <SecureRoute exact path="/users">
                          {isAuthorised(["USERS.VIEW"]) ? <UsersPage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>
                        <SecureRoute exact path="/users/:userId">
                          {isAuthorised(["ACCOUNT.VIEW"]) ? <AccountPage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>

                        <SecureRoute exact path="/analytics">
                          {isAuthorised(["ANALYTICS.VIEW"]) ? <AnalyticsPage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>

                        <SecureRoute exact path="/contacts">
                          {isAuthorised(["MANAGE_GLOBAL_CONTACTS"]) ? (
                            <ContactsPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>

                        <SecureRoute exact path="/templates/annotation-editor/:fileType/:templateId">
                          {isAuthorised(["ANNOTATION_EDITOR.VIEW"]) ? (
                            <AnnotationEditorPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>

                        <SecureRoute path="/user-timesheet/:userId?/:date?">
                          {isAuthorised(["USER_TIMESHEET.VIEW"]) ? (
                            <UserTimesheetPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>

                        <SecureRoute path="/user-calendar/:userId/:startDate?">
                          <CalendarPage {...pageProps} />
                        </SecureRoute>

                        <SecureRoute path="/timesheets">
                          {isAuthorised(["USER_TIMESHEET.VIEW", "TIMESHEETS.VIEW"]) ? (
                            <TimesheetsPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>

                        <SecureRoute path="/timeoff">
                          {isAuthorised(["TIME_OFF.VIEW"]) ? <TimeOffPage {...pageProps} /> : <UnauthorisedPage />}
                        </SecureRoute>

                        <SecureRoute path="/account">
                          {isAuthorised(["ACCOUNT.VIEW"]) ? (
                            <AccountPage {...pageProps} isOwnAccountPage={true} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>

                        <SecureRoute path="/organisation/settings">
                          {isAuthorised(["ORGANISATION_SETTINGS.VIEW"]) ? (
                            <OrganisationSettingsPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>

                        <SecureRoute path="/organisation/templates">
                          {isAuthorised(["ORGANISATION_SETTINGS.VIEW"]) ? (
                            <TemplatesPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>

                        <SecureRoute path="/organisation/apps">
                          {isAuthorised(["ORGANISATION_SETTINGS.VIEW"]) ? (
                            <ThirdPartyAppsPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>

                        <SecureRoute path="/reports">
                          {isAuthorised(["ORGANISATION_DOCUMENTS.VIEW"]) ? (
                            <OrganisationReportsPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/stock">
                          {isAuthorised(["STOCK.VIEW_STOCK_PAGE"]) ? (
                            <StockItemsPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/stock-items/:stockItemId">
                          {isAuthorised(["STOCK.VIEW_STOCK_ITEM_DETAILS_PAGE"]) ? (
                            <StockItemDetailsPage {...pageProps} />
                          ) : (
                            <UnauthorisedPage />
                          )}
                        </SecureRoute>
                        <SecureRoute path="/monitoring">
                          <MonitoringPage />
                        </SecureRoute>
                        <SecureRoute path="/xero-callback">
                          <XeroCallbackPage {...pageProps} />
                        </SecureRoute>
                        <SecureRoute path="/quickbooks-callback">
                          <QuickbooksCallbackPage {...pageProps} />
                        </SecureRoute>
                      </Switch>
                    </div>
                    {isContactModalVisible && (
                      <ContactModal onClose={() => this.setState({ isContactModalVisible: false })} />
                    )}
                  </div>
                </Context.Provider>
              </div>
            );
          }}
        />
        <div id="search-background-portal-target" />
        {window.location.hostname === "localhost" && (
          <div className="environment-name-display">
            <span>{awsExports.aws_user_files_s3_bucket.split("-").slice(-1)[0]}</span>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: App,
    isRoot: true,
  })
);
