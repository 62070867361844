import React from "react";
import cx from "classnames";
import _ from "lodash";
import { Mentions } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import TextareaAutosize from "react-textarea-autosize";

import "./Input.scss";

type Props = {
  placeholder?: string;
  defaultValue?: string;
  className?: string;
  onChange?: any;
  onKeyUp?: any;
  onEnterCreateSubtask?: any;
  onEnter?: any;
  fullWidth?: boolean;
  flexGrow?: boolean;
  disabled?: any;
  multiLine?: boolean;
  hidden?: boolean;
  allowEnter?: boolean;
  centered?: boolean;
  showBorder?: boolean;
  "data-cy"?: string;
  autoFocus?: boolean;
  prefix?: any;
  suffix?: any;
  numerical?: boolean;
  style?: object;
  fireOnChangeWithoutBlur?: boolean;
  fireOnChangeWithoutBlurWithDebounce?: boolean;
  debounceDelay?: number;
  allowDecimals?: boolean;
  allowClear?: boolean;
  useAutoComplete?: boolean;
  autoCompleteOptions?: any;
  id?: string;
  minRows?: number;
  memoize?: boolean;
  onClick?: (e) => void;
  ignoreChangesToDefaultValue?: boolean;
};

export class Input extends React.Component<Props> {
  state = {
    value: "",
    oldValue: "",
    randomId: "", // used to assign a random ID to each input, so it can be uniquely identified
  };
  inputRef: any = React.createRef<any>();

  constructor(props) {
    super(props);

    if (props.onChange) {
      /* @ts-ignore */
      this.debouncedTriggerOnChange = _.debounce(props.onChange, this.props.debounceDelay || 1000);
    }
  }

  componentDidMount() {
    let newStateValues = {
      value:
        this.props.defaultValue !== undefined && this.props.defaultValue !== null
          ? String(this.props.defaultValue)
          : "",
      oldValue:
        this.props.defaultValue !== undefined && this.props.defaultValue !== null
          ? String(this.props.defaultValue)
          : "",
      randomId:
        this.props.id !== undefined && this.props.id !== null ? this.props.id : `${Date.now()}-${window.randomUUID()}`,
    };

    this.setState(newStateValues);

    if (this.props.autoFocus) {
      this.inputRef.current.focus();
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.defaultValue !== oldProps.defaultValue && !this.props.ignoreChangesToDefaultValue) {
      /* @ts-ignore */
      if (Number.isNaN(this.props.defaultValue) && Number.isNaN(oldProps.defaultValue)) {
        return;
      }
      this.setState({
        value: this.props.defaultValue,
        oldValue: this.props.defaultValue,
      });
    }
  }

  blur = () => {
    if (this.inputRef.current !== undefined) {
      (this.inputRef.current as any).blur();
    }
  };

  // todo: move this to the Subtasks component
  handleSubtasks = (e) => {
    const { onEnterCreateSubtask } = this.props;
    let subtasksItems = document.querySelectorAll(".subtask-item .input.item-title input");
    if (subtasksItems && subtasksItems.length > 0) {
      subtasksItems.forEach((subtask, index) => {
        if (e.target === subtask) {
          if (index === subtasksItems.length - 1) {
            onEnterCreateSubtask();
          } else {
            (subtasksItems[index + 1] as HTMLElement).focus();
          }
        }
      });
    }
  };

  handleEnterKey = (e, { valueWithoutTrailingNewLine }) => {
    const { multiLine, allowEnter = true } = this.props;
    if (!allowEnter) {
      this.setState({ oldValue: valueWithoutTrailingNewLine }, this.blur);
      e.preventDefault();
      return;
    }
    if (!multiLine || (multiLine && !allowEnter)) {
      this.setState({ oldValue: valueWithoutTrailingNewLine }, this.blur);
      this.handleSubtasks(e);
    }
    if (this.props.onEnter) {
      this.props.onEnter(e);
      this.blur();
    }
  };

  handleEscapeKey = (e, { valueWithoutTrailingNewLine, oldValue }) => {
    this.setState({ value: oldValue }, this.blur);
  };

  render() {
    const {
      className,
      onChange,
      fullWidth,
      flexGrow,
      disabled,
      multiLine,
      placeholder,
      centered = false,
      showBorder,
      prefix,
      suffix,
      numerical,
      allowDecimals = false,
      style,
      useAutoComplete,
      autoCompleteOptions,
      allowEnter,
      allowClear,
      minRows,
      onClick,
    } = this.props;

    const { value, oldValue, randomId } = this.state;

    let valueWithoutTrailingNewLine = value;
    if (value === undefined || value === null) {
      valueWithoutTrailingNewLine = "";
    }
    if (String(value || "").endsWith("\n")) {
      valueWithoutTrailingNewLine = valueWithoutTrailingNewLine.substring(0, valueWithoutTrailingNewLine.length - 1);
    }

    let containerProps = {
      className: cx("input", "input-container", className, {
        "full-width": fullWidth,
        "flex-grow": flexGrow,
        enabled: !disabled,
        disabled,
        centered,
        "show-border": showBorder,
        "with-prefix": !!prefix,
        "with-suffix": !!suffix || allowClear,
      }),
    };

    let styleToPass = { ...style };
    if (styleToPass.hasOwnProperty("minHeight")) {
      delete (styleToPass as any).minHeight;
    }

    let elementProps = {
      ref: this.inputRef,
      placeholder,
      disabled,
      style: styleToPass,
      onClick,
      onKeyPress: (e) => {
        e.stopPropagation();
      },
      onKeyDown: (e) => {
        e.stopPropagation();
      },
      onKeyUp: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onKeyUp && typeof this.props.onKeyUp === "function") {
          this.props.onKeyUp(e);
        }
        if (e.key === "Enter") {
          this.handleEnterKey(e, { valueWithoutTrailingNewLine });
        }
        if (e.key === "Escape") {
          this.handleEscapeKey(e, { valueWithoutTrailingNewLine, oldValue });
        }
      },
      onChange: (e) => {
        let validPart = e;
        if (typeof validPart === "object") {
          validPart = e.target.value;
        }
        if (numerical) {
          validPart = validPart.replace(/[^0-9.]/g, "");
          if (validPart && !allowDecimals) {
            try {
              validPart = parseInt(validPart);
            } catch (e) {
              validPart = "";
            }
          }
        }
        this.setState({ value: String(validPart) }, () => {
          if (this.props.fireOnChangeWithoutBlur) {
            onChange(this.state.value);
          }
          if (this.props.fireOnChangeWithoutBlurWithDebounce) {
            /* @ts-ignore */
            this.debouncedTriggerOnChange(this.state.value);
          }
        });
      },
      onBlur: () => {
        this.setState({ oldValue: valueWithoutTrailingNewLine });
        if (onChange) {
          onChange(valueWithoutTrailingNewLine);
        }
      },
    };

    if (this.props["data-cy"]) {
      elementProps["data-cy"] = this.props["data-cy"];
    }
    for (let propName in this.props) {
      if (propName.startsWith("data-")) {
        elementProps[propName] = this.props[propName];
      }
    }

    if (useAutoComplete) {
      // (elementProps as any).open = true;
    }

    let prefixElement: any = null;
    if (prefix) {
      prefixElement = <div className="prefix">{prefix}</div>;
    }

    return multiLine ? (
      <div {...containerProps}>
        <TextareaAutosize
          id={randomId}
          {...elementProps}
          value={value as any}
          minRows={minRows || (allowEnter ? 2 : 1)}
        />
      </div>
    ) : (
      <div {...containerProps}>
        {prefixElement}
        {useAutoComplete ? (
          <Mentions {...elementProps} value={value as any} prefix="$">
            {autoCompleteOptions.map((option) => (
              <Mentions.Option value={option}>{option}</Mentions.Option>
            ))}
          </Mentions>
        ) : (
          <input id={randomId} {...elementProps} value={value as any} />
        )}

        {(suffix || (allowClear && value)) && (
          <div className="suffix">
            {suffix}
            {allowClear && value && (
              <span
                className="clear-button"
                onClick={() => {
                  this.setState({ value: "" });
                  if (onChange) {
                    onChange("");
                  }
                }}
              >
                <CloseCircleFilled />
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default React.memo(Input, (prevProps, nextProps) => {
  if (prevProps.memoize || nextProps.memoize) {
    const PROPS_TO_MONITOR = ["className", "disabled"];
    for (let propName of PROPS_TO_MONITOR) {
      if (prevProps[propName] !== nextProps[propName]) {
        return false;
      }
    }
    return true;
  }
  return false;
});
