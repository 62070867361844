import { useState, useEffect } from "react";
import { Button, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Wrapper } from "@googlemaps/react-wrapper";
import axios, { Method } from "axios";
import _ from "lodash";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";
import { Address, Project, ProjectAddressCoordinates } from "common/types";

import AddressModal from "Modals/AddressModal/AddressModal";
import { Map, Marker, render, formatAddress, Nullable, Coordinates } from "../../Map/Map";
import Card from "Card/Card";

import "./ProjectAddress.scss";

type Props = {
  project: Project;
};

type GeocodeResponse = {
  data?: {
    results?: Array<{
      geometry: {
        location: Coordinates;
      };
    }>;
    status: "OK" | "REQUEST_DENIED" | "ZERO_RESULTS";
    error_message: any;
  };
};

export function ProjectAddress(props: Props) {
  const { project } = props;
  const [click, setClick] = useState<Partial<Coordinates>>({}); // eslint-disable-line
  const [zoom, setZoom] = useState<number>(18);
  const [center, setCenter] = useState<Coordinates>({
    lat: 0,
    lng: 0,
  });
  const [apiKey, setApiKey] = useState<string | null>();
  const [isAddressModalVisible, setIsAddressModalVisible] = useState<boolean>(false);
  const address: Address | Nullable = project.address;
  const addressCoordinates: ProjectAddressCoordinates | Nullable = project.addressCoordinates;
  const formattedAddress = formatAddress(address);

  useEffect(() => {
    if (addressCoordinates && addressCoordinates.lat && addressCoordinates.lng) {
      setCenter({ lat: addressCoordinates.lat, lng: addressCoordinates.lng });
      setClick({ lat: addressCoordinates.lat, lng: addressCoordinates.lng });
    }
  }, [address, formattedAddress, addressCoordinates]);

  async function onAddressModalSubmit(params) {
    const { addressDetails, isDifferentSetOfCoordinates, click } = params;
    const projectAddress = project.address;
    let shouldFetchCoordinates = !_.isEqual(projectAddress, addressDetails);

    if (isDifferentSetOfCoordinates && _.isEqual(projectAddress, addressDetails)) {
      const coordinates = {
        lat: click.lat(),
        lng: click.lng(),
      };

      setCenter(coordinates);
      await updateProjectAddress({ addressDetails, coordinates });
    } else if (shouldFetchCoordinates) {
      let currentAddress = formatAddress(addressDetails);

      if (!currentAddress) {
        currentAddress = "London";
      }

      const coordinates = await fetchCoordinates(currentAddress);
      await updateProjectAddress({ addressDetails, coordinates });
    }

    setIsAddressModalVisible(false);
  }

  useEffect(() => {
    const googleMapsApiKey = window.secrets?.GOOGLE_MAPS;

    if (!googleMapsApiKey) {
      return;
    }

    setApiKey(googleMapsApiKey);
  }, []);

  async function updateProjectAddress({ addressDetails, coordinates }) {
    await callGraphQLSimple({
      message: "Could not add address",
      queryName: "updateProject",
      variables: {
        input: {
          id: project.id,
          address: addressDetails,
          addressCoordinates: coordinates,
        },
      },
    });
  }

  async function fetchCoordinates(formattedAddress: string) {
    let coordinates: Coordinates = { lat: 0, lng: 0 };

    const config = {
      method: "GET" as Method,
      url: `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=${apiKey}`,
    };
    await axios(config).then(async (response: GeocodeResponse): Promise<Coordinates | void> => {
      if (!response.data || !response.data.results || response.data.results.length === 0) {
        console.log("No response data");
        return;
      }

      if (response.data.status === "OK") {
        const results = response.data.results;

        coordinates = results[0].geometry.location;
      }
    });

    return coordinates;
  }

  async function fetchAndSetCoordinates(formattedAddress: string) {
    const coordinates: Coordinates = await fetchCoordinates(formattedAddress);

    if (coordinates.lat && coordinates.lng) {
      setCenter({ lat: coordinates.lat, lng: coordinates.lng });

      await callGraphQLSimple({
        message: "Could not add address coordinates",
        queryName: "updateProject",
        variables: {
          input: {
            id: project.id,
            addressCoordinates: coordinates,
          },
        },
      });
    }
  }

  return (
    <Card
      title="Project address"
      className="project-address-page"
      actions={
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => setIsAddressModalVisible(true)}
          data-cy="add-address-button"
        >
          {address || project.addressCoordinates ? "Edit address" : "Set address"}
        </Button>
      }
    >
      {address ? (
        <div>
          <div className="project-address-details">
            <Typography.Text className="address-detail-item" data-cy="street-number">
              <Typography.Text className="address-detail-item-label">Street Number:</Typography.Text>
              {address?.streetNumber}
            </Typography.Text>
            <Typography.Text className="address-detail-item" data-cy="house-name">
              <Typography.Text className="address-detail-item-label">House Name:</Typography.Text>
              {address?.houseName}
            </Typography.Text>
            <Typography.Text className="address-detail-item" data-cy="street-name">
              <Typography.Text className="address-detail-item-label">Street Name:</Typography.Text>
              {address?.streetName}
            </Typography.Text>
            <Typography.Text className="address-detail-item" data-cy="city">
              <Typography.Text className="address-detail-item-label">City:</Typography.Text>
              {address?.city}
            </Typography.Text>
            <Typography.Text className="address-detail-item" data-cy="county">
              <Typography.Text className="address-detail-item-label">County:</Typography.Text>
              {address?.county}
            </Typography.Text>
            <Typography.Text className="address-detail-item" data-cy="postcode">
              <Typography.Text className="address-detail-item-label">Postcode:</Typography.Text>
              {address?.postcode}
            </Typography.Text>
            <Typography.Text className="address-detail-item" data-cy="country">
              <Typography.Text className="address-detail-item-label">Country:</Typography.Text>
              {address?.country}
            </Typography.Text>
            <Typography.Text className="address-detail-item" data-cy="latitude">
              <Typography.Text className="address-detail-item-label">Latitude:</Typography.Text>
              {project?.addressCoordinates?.lat}
            </Typography.Text>
            <Typography.Text className="address-detail-item" data-cy="longitude">
              <Typography.Text className="address-detail-item-label">Longitude:</Typography.Text>
              {project?.addressCoordinates?.lng}
            </Typography.Text>
          </div>

          {apiKey ? (
            <div className="google-maps-wrapper">
              <Wrapper apiKey={apiKey} render={render}>
                <Map
                  center={center}
                  zoom={zoom}
                  style={{ flexGrow: "1", height: "100%" }}
                  onIdle={() => {}}
                  onClick={() => {}}
                >
                  {center ? <Marker position={center} /> : null}
                </Map>
              </Wrapper>
            </div>
          ) : null}
        </div>
      ) : null}

      {isAddressModalVisible && (
        <AddressModal
          isSingle={true}
          parent={project}
          onClose={() => setIsAddressModalVisible(false)}
          onSubmit={onAddressModalSubmit}
          address={project.address}
          isEditingAddress={true}
          apiKey={apiKey}
          Marker={Marker}
          fetchAndSetCoordinates={fetchAndSetCoordinates}
          fetchCoordinates={fetchCoordinates}
          addressCoordinates={addressCoordinates}
          formattedAddress={formattedAddress}
          usesMapWidget={true}
        />
      )}
    </Card>
  );
}

export default withSubscriptions({
  Component: ProjectAddress,
  subscriptions: ["organisationDetails"],
});
