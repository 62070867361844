import cx from "classnames";
import { BoardIcon, ListIcon, TableIcon } from "common/icons";

import "./DashboardViewTypes.scss";

export default function DashboardViewTypes({ viewType, changeViewType }) {
  return (
    <div className="dashboard-view-types" data-cy="dashboard-view-types">
      <div className="button-container">
        <button
          className={cx("view-board", { selected: viewType === "BOARD" })}
          onClick={() => changeViewType("BOARD")}
        >
          <BoardIcon /> Board
        </button>
      </div>
      {/* <div className="button-container">
        <button className={cx("view-list", { selected: viewType === "LIST" })} onClick={() => changeViewType("LIST")}>
          <ListIcon />
          List
        </button>
      </div> */}
      <div className="button-container">
        <button
          className={cx("view-table", {
            selected: viewType === "TABLE",
          })}
          onClick={() => changeViewType("TABLE")}
        >
          <TableIcon />
          Table
        </button>
      </div>
    </div>
  );
}
