import moment from "moment";
import { Modal, Typography } from "antd";

import { callGraphQLSimple } from "common/apiHelpers";

export async function getHistoricData({ organisationDetails, startDate, endDate }) {
  const historicData = (
    await callGraphQLSimple({
      message: "Failed to retrieve historic data",
      queryName: "listHistoricDataByOrganisation",
      variables: {
        organisation: organisationDetails.id,
        limit: 1000,
        date: {
          between: [startDate.subtract(1, "day"), endDate],
        },
      },
    })
  ).data.listHistoricDataByOrganisation.items;

  return historicData;
}

export function group({ dates, period, startDate, endDate }) {
  let result = {};
  let date = moment(startDate);

  const formattedEndDate = endDate.format("YYYY-MM-DD");

  while (date.isSameOrBefore(moment(endDate).endOf(period))) {
    let formattedDate = date.format("YYYY-MM-DD");

    result[formattedDate] = {
      ...dates[formattedDate],
    };

    date.add(1, period);
  }

  result[formattedEndDate] = dates[formattedEndDate];

  return result;
}

export function displayOrganisationDetails(data, dataType) {
  return (
    <div className="organisation-details-list">
      {Object.keys(data).map((key) => {
        return (
          <div className="organisation-details-list-item">
            <Typography.Text className="organisation-detail-list-item-title" data-cy={`${dataType}-${key}-item-title`}>
              {capitalise(String(key))}:
            </Typography.Text>
            <Typography.Text
              className="organisation-detail-list-item-amount"
              data-cy={`${dataType}-${key}-item-amount`}
            >
              {capitalise(String(data[key]))}
            </Typography.Text>
          </div>
        );
      })}
    </div>
  );
}

export function capitalise(string) {
  switch (string) {
    case "underReview": {
      return "Under review";
    }
    case "noCatLevel": {
      return "No CAT level";
    }
    case "withSignature": {
      return "With signature";
    }
    default: {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
}

export function formatDates(groupedData) {
  let newChartData = [];

  if (!groupedData) {
    return null;
  }

  Object.keys(groupedData).forEach((date) => {
    const groupedDataForDate = groupedData[date];

    if (!groupedDataForDate) return;

    const structuredChartData = Object.entries(groupedDataForDate);
    structuredChartData.forEach((dataArray) => {
      const [category, value] = dataArray;

      newChartData.push({
        day: date,
        value: value,
        category: capitalise(category),
      });
    });
  });

  return newChartData;
}

export function processHistoricData(historicData, dataType) {
  const dates = {};
  switch (dataType) {
    case "task": {
      historicData.forEach((currentDate) => {
        dates[currentDate.date] = {
          all: currentDate.tasksTotal,
          finished: currentDate.tasksFinished,
          // archived: currentDate.tasksArchived,
          active: currentDate.tasksOpen,
        };
      });
      break;
    }
    case "project": {
      historicData.forEach((currentDate) => {
        dates[currentDate.date] = {
          all: currentDate.projectsTotal,
          // archived: currentDate.projectsArchived,
          active: currentDate.projectsTotal - currentDate.projectsArchived,
        };
      });
      break;
    }
    case "user": {
      historicData.forEach((currentDate) => {
        dates[currentDate.date] = {
          all: currentDate.usersTotal,
        };
      });
      break;
    }
    case "client": {
      historicData.forEach((currentDate) => {
        dates[currentDate.date] = {
          all: currentDate.clientsTotal,
        };
      });
      break;
    }
    default: {
      console.log("Historic data could not be loaded and this function can't process anything");
    }
  }

  return dates;
}
