import { useState } from "react";

import { Dropdown, Menu, Typography } from "antd";
import { getLabel } from "common/helpers";
import { PlusOutlined } from "@ant-design/icons";
import { getSimpleLabel } from "common/labels";
import { isAuthorised } from "common/permissions";
// import { PlusIcon } from "common/icons";

import CreateTaskModal from "CreateTaskModal/CreateTaskModal";
import CreateProjectModal from "CreateProjectModal/CreateProjectModal";
import CreateClientModal from "Modals/CreateClientModal/CreateClientModal";
import CreateQuoteModal from "Modals/CreateQuoteModal/CreateQuoteModal";
import CreateInvoiceModal from "Modals/CreateInvoiceModal/CreateInvoiceModal";
import CreateSupplierModal from "Modals/CreateSupplierModal/CreateSupplierModal";
import CreatePurchaseOrderModal from "Modals/CreatePurchaseOrderModal/CreatePurchaseOrderModal";
import PlaceRequestModal from "Modals/PlaceRequestModal/PlaceRequestModal";

import "./QuickCreateMenu.scss";
// import DismissableTooltip from "DismissableTooltip/DismissableTooltip";

export default function QuickCreateMenu(props) {
  const { organisationDetails } = props;
  const [isCreateTaskModalVisible, setIsCreateTaskModalVisible] = useState(false);
  const [isCreateProjectModalVisible, setIsCreateProjectModalVisible] = useState(false);
  const [isCreateClientModalVisible, setIsCreateClientModalVisible] = useState(false);
  const [isCreateQuoteModalVisible, setIsCreateQuoteModalVisible] = useState(false);
  const [isCreateInvoiceModalVisible, setIsCreateInvoiceModalVisible] = useState(false);
  const [isCreateSupplierModalVisible, setIsCreateSupplierModalVisible] = useState(false);
  const [isCreatePurchaseOrderModalVisible, setIsCreatePurchaseOrderModalVisible] = useState(false);
  const [isPlaceRequestModalVisible, setIsPlaceRequestModalVisible] = useState(false);

  function displayOverlay() {
    let menuItems = [];

    if (organisationDetails.settings?.request?.usesRequests && isAuthorised(["CREATE_REQUEST"])) {
      menuItems.push(
        <Menu.Item key="request" onClick={() => setIsPlaceRequestModalVisible(true)}>
          <Typography.Text className="quick-create-button-menu-item">{getSimpleLabel("Request")}</Typography.Text>
        </Menu.Item>
      );
    }

    if (isAuthorised(["CREATE_TASK"])) {
      menuItems.push(
        <Menu.Item key="task" onClick={() => setIsCreateTaskModalVisible(true)}>
          <Typography.Text className="quick-create-button-menu-item">{getSimpleLabel("Task")}</Typography.Text>
        </Menu.Item>
      );
    }

    if (!organisationDetails.settings?.task?.automaticallyCreateProject && isAuthorised(["CREATE_PROJECT"])) {
      menuItems.push(
        <Menu.Item key="project" onClick={() => setIsCreateProjectModalVisible(true)}>
          <Typography.Text className="quick-create-button-menu-item">{getSimpleLabel("Project")}</Typography.Text>
        </Menu.Item>
      );
    }

    if (isAuthorised(["CREATE_CLIENT"])) {
      menuItems.push(
        <Menu.Item key="client" onClick={() => setIsCreateClientModalVisible(true)}>
          <Typography.Text className="quick-create-button-menu-item">{getSimpleLabel("Client")}</Typography.Text>
        </Menu.Item>
      );
    }

    if (organisationDetails.settings?.supplier?.usesSuppliers && isAuthorised(["CREATE_SUPPLIER"])) {
      menuItems.push(
        <Menu.Item key="supplier" onClick={() => setIsCreateSupplierModalVisible(true)}>
          <Typography.Text className="quick-create-button-menu-item">{getSimpleLabel("Supplier")}</Typography.Text>
        </Menu.Item>
      );
    }

    if (organisationDetails.settings?.quote?.usesQuotes && isAuthorised(["CREATE_QUOTE"])) {
      menuItems.push(
        <Menu.Item key="quote" onClick={() => setIsCreateQuoteModalVisible(true)}>
          <Typography.Text className="quick-create-button-menu-item">{getSimpleLabel("Quote")}</Typography.Text>
        </Menu.Item>
      );
    }

    if (organisationDetails.settings?.invoice?.usesInvoices && isAuthorised(["CREATE_INVOICE"])) {
      menuItems.push(
        <Menu.Item key="invoice" onClick={() => setIsCreateInvoiceModalVisible(true)}>
          <Typography.Text className="quick-create-button-menu-item">{getSimpleLabel("Invoice")}</Typography.Text>
        </Menu.Item>
      );
    }

    if (organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders && isAuthorised(["CREATE_PURCHASE_ORDER"])) {
      menuItems.push(
        <Menu.Item key="purchase-order" onClick={() => setIsCreatePurchaseOrderModalVisible(true)}>
          <Typography.Text className="quick-create-button-menu-item">
            {getSimpleLabel("Purchase order")}
          </Typography.Text>
        </Menu.Item>
      );
    }

    if (menuItems.length === 0) {
      return null;
    }

    return <Menu className="quick-create-button-menu">{menuItems}</Menu>;
  }

  let dropdownOverlay = displayOverlay();

  return (
    <div className="quick-create-menu">
      {dropdownOverlay && (
        <Dropdown overlay={dropdownOverlay} overlayClassName="quick-create-menu-overlay" trigger={["click"]}>
          <PlusOutlined />
        </Dropdown>
      )}
      {isCreateTaskModalVisible && (
        <CreateTaskModal onClose={() => setIsCreateTaskModalVisible(false)} apiUser={window.apiUser} />
      )}
      {isCreateProjectModalVisible && (
        <CreateProjectModal onClose={() => setIsCreateProjectModalVisible(false)} apiUser={window.apiUser} />
      )}
      {isCreateClientModalVisible && (
        <CreateClientModal onClose={() => setIsCreateClientModalVisible(false)} apiUser={window.apiUser} />
      )}
      {isCreateQuoteModalVisible && (
        <CreateQuoteModal onClose={() => setIsCreateQuoteModalVisible(false)} apiUser={window.apiUser} />
      )}
      {isCreateInvoiceModalVisible && <CreateInvoiceModal onClose={() => setIsCreateInvoiceModalVisible(false)} />}
      {isCreateSupplierModalVisible && (
        <CreateSupplierModal onClose={() => setIsCreateSupplierModalVisible(false)} apiUser={window.apiUser} />
      )}
      {isCreatePurchaseOrderModalVisible && (
        <CreatePurchaseOrderModal
          onClose={() => setIsCreatePurchaseOrderModalVisible(false)}
          apiUser={window.apiUser}
        />
      )}
      {isPlaceRequestModalVisible && <PlaceRequestModal onClose={() => setIsPlaceRequestModalVisible(false)} />}
    </div>
  );
}
