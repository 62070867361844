import HierarchyUserItem from "./HierarchyUserItem/HierarchyUserItem";

import "./UserHierarchy.scss";

export default function UserHierarchy({ users }) {
  let topLevelUsers = users.filter((user) => !user.managerIds || !user.managerIds?.length);

  return (
    <div className="user-hierarchy">
      {topLevelUsers.map((user) => (
        <HierarchyUserItem user={user} users={users} key={user.id} />
      ))}
    </div>
  );
}
