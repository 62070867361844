import React from "react";
import * as Sentry from "@sentry/react";
import { Result, Button } from "antd";

import "./ErrorBoundary.scss";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("There has been an error:", error);
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status="500"
          title="Error"
          subTitle="Sorry, something went wrong."
          extra={
            <Button type="primary" onClick={() => window.location.reload()}>
              Reload page
            </Button>
          }
        />
      );
    }

    return this.props.children;
  }
}
