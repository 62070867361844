import { callGraphQLSimple } from "common/apiHelpers";

export function extractEmailsFromSlateValue(nodes) {
  let allText = "";

  // Traverse through all nodes
  function traverseNodes(node) {
    if (node.text) {
      allText += node.text + " "; // Concatenate text content
    }

    if (node.children) {
      node.children.forEach(traverseNodes); // Recursively handle children
    }
  }

  nodes.forEach(traverseNodes);

  // Regular expression for matching email addresses
  const emailRegex =
    /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:\\[\x00-\x7F]|[^\\"])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}|(?:\[(?:[0-9]{1,3}\.){3}[0-9]{1,3}\]))/g;

  // Match all email addresses in the text
  const emails = allText.match(emailRegex);

  return emails || []; // Return the array of emails or an empty array if none
}

export async function recordContacts({ contacts, parentId }) {
  let promises = [];
  let validContacts = [];

  contacts.forEach((contact) => {
    if (!contact) {
      return;
    }
    if (typeof contact === "string") {
      if (!contact.length) {
        return;
      }
      validContacts.push({
        email: contact,
      });
    } else {
      if (!contact.email || !contact.email.length) {
        return;
      }
      validContacts.push(contact);
    }
  });
  validContacts.forEach((contact) => {
    promises.push(
      new Promise(async (resolve, reject) => {
        try {
          let id = `${window.organisationDetails.id}_${contact.email}`;

          const existingContact = (
            await callGraphQLSimple({
              queryName: "getSeparateContact",
              variables: {
                id,
              },
            })
          ).data.getSeparateContact;

          if (existingContact) {
            resolve();
            return;
          }

          await callGraphQLSimple({
            message: `Failed to record contact ${contact.name || contact.email}`,
            queryName: "createSeparateContact",
            variables: {
              input: {
                id,
                organisation: window.organisationDetails.id,
                parentId: parentId || window.organisationDetails.id,
                ...contact,
              },
            },
          });

          resolve();
        } catch (e) {
          reject(e);
        }
      })
    );
  });

  await Promise.all(promises);
}

export async function fetchSeparateContacts({ parentId }) {
  let results = [];
  let nextToken = null;
  while (true) {
    const response = (
      await callGraphQLSimple({
        queryName: "listContactsByParent",
        variables: {
          limit: 1000,
          parentId,
          nextToken,
        },
      })
    ).data;

    results = results.concat(response.listContactsByParent.items);
    nextToken = response.listContactsByParent.nextToken;

    if (!nextToken) {
      break;
    }
  }

  return results;
}

function serializeNode(node) {
  if (node.text !== undefined) {
    // Handle text formatting (e.g., bold, italic, etc.)
    let text = node.text;
    if (node.bold) {
      text = `<strong>${text}</strong>`;
    }
    if (node.italic) {
      text = `<em>${text}</em>`;
    }
    if (node.underline) {
      text = `<u>${text}</u>`;
    }
    return text;
  }

  // Handle block elements (e.g., paragraph)
  switch (node.type) {
    case "paragraph":
      return `<p>${node.children.map(serializeNode).join("")}</p>`;
    case "mention":
      // Assuming mention has a "data" field with "id" for example purposes
      return `<strong style="margin-right: 4px">${node.data.name}</strong>`;
    case "heading-one":
      return `<h1>${node.children.map(serializeNode).join("")}</h1>`;
    case "heading-two":
      return `<h2>${node.children.map(serializeNode).join("")}</h2>`;
    // Add more cases here for other types of nodes you support
    default:
      return node.children ? node.children.map(serializeNode).join("") : "";
  }
}

export function convertSlateToHTML(slateContent) {
  return slateContent.map(serializeNode).join("");
}
