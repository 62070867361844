import { useEffect, useState, useRef } from "react";
import cx from "classnames";
import { Spin, Result, Button, Typography } from "antd";
import { Link } from "react-router-dom";
import DraughtHubLogo from "../graphics/draughthub_logo_symbol_only.png";

import "./LoadingWrapper.scss";

type Props = {
  content: any;
  isLoading: any;
  error?: any;
  loadingClassName?: string;
  displayPreloader?: boolean;
  shouldDisplayPreloader?: boolean;
  message?: any;
  useLogo?: boolean;
};

const THRESHOLD_FOR_DISPLAYING_SLOW_MESSAGE = 2000;

export default function LoadingWrapper({
  content,
  isLoading,
  error,
  loadingClassName,
  displayPreloader = true,
  shouldDisplayPreloader = false,
  message,
  useLogo = false,
}: Props) {
  const [isTakingALongTime, setIsTakingALongTime] = useState(false);
  const prevIsLoadingRef = useRef(isLoading);
  const loadingTimerRef = useRef<any>();

  useEffect(() => {
    prevIsLoadingRef.current = isLoading;
    loadingTimerRef.current = setTimeout(() => {
      setIsTakingALongTime(true);
    }, THRESHOLD_FOR_DISPLAYING_SLOW_MESSAGE);
  }, []);

  const prevIsLoading = prevIsLoadingRef.current;

  useEffect(() => {
    // You can now compare prevIsLoading with isLoading
    if (!useLogo) {
      return;
    }
    if (prevIsLoading !== isLoading && !isLoading) {
      clearTimeout(loadingTimerRef.current);
      setIsTakingALongTime(false);
    }
  }, [isLoading, prevIsLoading]);

  if (isLoading) {
    if (!displayPreloader) {
      return null;
    }
    return (
      <div className={cx("loading-screen", loadingClassName, { "with-logo": useLogo })}>
        {useLogo ? (
          <>
            <img src={DraughtHubLogo} alt="" />
          </>
        ) : (
          <Spin />
        )}
        {message && <Typography.Text className="loading-screen-message">{message}</Typography.Text>}
        {isTakingALongTime && (
          <Typography.Text className="loading-screen-message">Please wait, we are loading the data...</Typography.Text>
        )}
      </div>
    );
  }

  if (error) {
    console.log("error = ", error);
    console.log("content = ", content);
    return (
      <Result
        status={error}
        title={error === 404 ? error : "That doesn't look right"}
        subTitle="It seems like something has gone wrong. Please try again later."
        extra={
          <Link to="/">
            <Button type="primary">Back to Dashboard</Button>
          </Link>
        }
      />
    );
  }

  return (
    <>
      {shouldDisplayPreloader && (
        <div className={cx("loading-screen", loadingClassName)}>
          <Spin />
        </div>
      )}

      {content()}
    </>
  );
}
