import React, { useEffect } from "react";
import FeeList from "FeeList/FeeList";

function StandardFeesPage(props) {
  const { parent, parentType } = props;
  useEffect(() => {
    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "STANDARD_FEES",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
  }, []);
  return (
    <div style={{ marginTop: "1rem" }}>
      <FeeList parent={parent} parentType={parentType} />
    </div>
  );
}

export default StandardFeesPage;
