import { useState } from "react";
import { Form, Modal, Button, notification, Typography } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { useForceUpdate, getLabel } from "common/helpers";

import CreatePurchaseOrderModal from "Modals/CreatePurchaseOrderModal/CreatePurchaseOrderModal";
import PurchaseOrderPicker from "Pickers/PurchaseOrderPicker/PurchaseOrderPicker";

import "./AddQuoteLineItemToPurchaseOrderModal.scss";

export function AddQuoteLineItemToPurchaseOrderModal({
  onClose,
  onSubmit,
  apiUser,
  visible,
  purchaseOrders,
  projectId,
  predefinedCreatePurchaseOrderFields,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreatePurchaseOrderModalVisible, setIsCreatePurchaseOrderModalVisible] = useState(false);
  const forceUpdate = useForceUpdate();

  async function onSubmitInner({ purchaseOrderId }) {
    try {
      setIsLoading(true);
      onSubmit({ purchaseOrderId });
    } catch (e) {
      console.error(e);
      notification.error({
        message: (
          <>
            <Typography.Text>
              Could not add{" "}
              {getLabel({
                id: "quote",
                defaultValue: "quote",
              })}{" "}
              line item to purchase order
            </Typography.Text>
            <br />
            <Typography.Text>Reason: {e.message || JSON.stringify(e)}</Typography.Text>
          </>
        ),
        duration: 0,
      });
      setIsLoading(false);
    }
  }

  function checkPurchaseOrder(_, purchaseOrderId) {
    return new Promise((resolve, reject) => {
      if (!purchaseOrderId) {
        return reject("");
      }
      const parts = purchaseOrderId.split("-");
      if (parts.length !== 3) {
        return reject("");
      }

      return resolve();
    });
  }

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 19,
    },
  };

  const purchaseOrdersInProject = purchaseOrders.filter(
    (x) => !x.isExternalReference && x.projectId === projectId && !x.isArchived && !x.quoteId
  );

  let submitLabel = "Submit";
  if (isLoading) {
    submitLabel = "Submitting...";
  }

  return (
    <Modal
      maskClosable={false}
      title={`Add ${getLabel({
        id: "quote",
        defaultValue: "quote",
      })} line item to purchase order`}
      open={visible}
      onOk={onSubmitInner}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      footer={null}
      className="add-quote-line-item-to-purchase-order-modal"
    >
      <Form {...layout} form={form} initialValues={{}} onFinish={onSubmitInner}>
        <Form.Item
          label="Choose purchase order"
          className="choose-purchase-order-form-item"
          name="purchaseOrderId"
          rules={[
            {
              required: true,
              message: "You must choose a purchase order",
              validator: checkPurchaseOrder,
            },
          ]}
        >
          <PurchaseOrderPicker
            includeProjectName={false}
            includePurchaseOrderId={false}
            purchaseOrders={purchaseOrdersInProject}
            value={form.getFieldValue("purchaseOrderId")}
            onSearch={() => {
              form.setFieldsValue({ purchaseOrderId: "" });
              forceUpdate();
            }}
            onChange={(purchaseOrderId) => {
              form.setFieldsValue({ purchaseOrderId });
              forceUpdate();
            }}
          />

          <Button
            type="primary"
            className="create-purchase-order"
            onClick={() => setIsCreatePurchaseOrderModalVisible(true)}
            data-cy="add-quote-line-item-create-new-purchase-order-button"
          >
            <PlusCircleOutlined /> Create new
          </Button>
        </Form.Item>

        <div className="submit-container">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            data-cy="add-quote-line-item-to-purchase-order-modal-submit-button"
          >
            {submitLabel}
          </Button>
        </div>
      </Form>
      <br />
      {isCreatePurchaseOrderModalVisible && (
        <CreatePurchaseOrderModal
          predefinedFields={predefinedCreatePurchaseOrderFields}
          onClose={() => setIsCreatePurchaseOrderModalVisible(false)}
          apiUser={apiUser}
          onSave={(purchaseOrder) => {
            form.setFieldsValue({ purchaseOrderId: purchaseOrder.id });
            forceUpdate();
          }}
        />
      )}
    </Modal>
  );
}

export default withSubscriptions({
  Component: AddQuoteLineItemToPurchaseOrderModal,
  subscriptions: ["purchaseOrders", "projects", "sprints", "clients"],
});
