// import { useState } from "react";
import { Typography, Button, Modal } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";

import DynamicInformationForVariable from "./DynamicInformationForVariable/DynamicInformationForVariable";
import InfoItem from "InfoItem/InfoItem";
import Input from "Input/Input";

import "./TextVariables.scss";

export default function TextVariables({
  object,
  updateObject,
  fileTypeDetails,
  form,
  organisationDetails,
  isDataSection,
  displayParametersForObject,
}) {
  let fieldName = "custom_variables";
  if (isDataSection) {
    fieldName = "custom_dataFields";
  }
  function displayVariables() {
    if (!object[fieldName]) {
      return null;
    }

    return object[fieldName].map((variable, index) => {
      return (
        <div className="text-variable-item" key={index}>
          <div className="variable-details">
            {isDataSection ? (
              <InfoItem
                label="Name"
                inline
                value={
                  <Input
                    defaultValue={variable.symbol}
                    onChange={(name) => {
                      updateVariable({ variable, index, object, fields: { symbol: name } });
                    }}
                    fireOnChangeWithoutBlurWithDebounce
                  />
                }
              />
            ) : (
              <InfoItem label="Symbol" inline value={variable.symbol} />
            )}
            <DynamicInformationForVariable
              variable={variable}
              index={index}
              object={object}
              fileTypeDetails={fileTypeDetails}
              updateVariable={updateVariable}
              form={form}
              organisationDetails={organisationDetails}
              displayParametersForObject={displayParametersForObject}
            />
          </div>

          <Button
            className="delete-variable-button"
            onClick={() => {
              onDeleteVariablecClick({ variable, index });
            }}
            icon={<DeleteOutlined />}
          />
        </div>
      );
    });
  }

  function updateVariable({ variable, index, object, fields }) {
    let newVariables = JSON.parse(JSON.stringify(object[fieldName] || []));

    newVariables[index] = {
      ...variable,
      ...fields,
    };
    updateObject({
      objectId: object.custom_id,
      fields: {
        [fieldName]: newVariables,
      },
    });
  }

  function onDeleteVariablecClick({ variable, index }) {
    Modal.confirm({
      title: `Are you sure you want to delete ${variable.symbol}?`,
      okText: "Yes, delete",
      cancelText: "No",
      onOk: () => {
        deleteVariable(index);
      },
    });
  }

  function deleteVariable(index) {
    const newVariables = JSON.parse(JSON.stringify(object[fieldName] || []));

    newVariables.splice(index, 1);
    updateObject({
      objectId: object.custom_id,
      fields: {
        [fieldName]: newVariables,
      },
    });
  }

  function onAddVariableClick() {
    const newVariables = JSON.parse(JSON.stringify(object[fieldName] || []));

    let newVariable = {
      symbol: `$${newVariables.length + 1}`,
    };

    if (isDataSection) {
      newVariable.data = null;
    }

    newVariables.push(newVariable);
    updateObject({
      objectId: object.custom_id,
      fields: {
        [fieldName]: newVariables,
      },
    });
  }

  return (
    <div className="text-variables">
      <div className="section-header">
        <Typography.Text className="section-title">{isDataSection ? "Parameters" : "Variables"}</Typography.Text>
        <Button icon={<PlusCircleOutlined />} type="primary" onClick={onAddVariableClick}>
          Add {isDataSection ? "parameter" : "variable"}
        </Button>
      </div>
      {displayVariables()}
    </div>
  );
}
