import { Typography } from "antd";
import {
  getFieldsForEditorByDataSource,
  getFieldLabelById,
  getFieldDetailsById,
} from "common/documentEditorDataSources/aggregator";

export function getParentRepeatForField({ object, form, organisationDetails }) {
  if (!object?.group) {
    return undefined;
  }
  const parentRepeatForContainerField = getParentRepeatForField({ object: object.group, form, organisationDetails });
  let parentRepeatDataSource = object.group.custom_repeatForDataSource;
  let parentRepeatForId = object.group.custom_repeatFor;
  let parentRepeatForField;
  if (parentRepeatDataSource) {
    let fieldsInRepeatDataSource = getFieldsForEditorByDataSource({
      parentRepeat: parentRepeatForContainerField,
      dataSource: parentRepeatDataSource,
      form,
      fieldType: "repeatFor",
      organisationDetails,
    });
    parentRepeatForField = fieldsInRepeatDataSource.find((field) => field.id === parentRepeatForId);
  }
  if (!parentRepeatForField) {
    parentRepeatForField = parentRepeatForContainerField;
  }
  return parentRepeatForField;
}

export function getRepeatFor(object) {
  let parent = object;
  let repeatFor;
  while (parent.group && !repeatFor) {
    repeatFor = parent.group.custom_repeatFor;
    parent = parent.group;
  }
  return repeatFor;
}

export function displayNoteAboutParentRepeat({ object, form, organisationDetails }) {
  let note = null;

  // console.log("object = ", object);
  if (object.custom_name === "Image container") {
    // console.log("object.group = ", object.group);
    // console.log("object.group.group = ", object.group.group);
    // console.log("object.group.group.group = ", object.group.group.group);
    // debugger;
  }

  if (!object.group) {
    return note;
  }
  const parentRepeatForField = getParentRepeatForField({ object, form, organisationDetails });

  if (parentRepeatForField) {
    note = (
      <Typography.Text className="parent-repeat-note">
        Note: parent is repeated for{" "}
        {`${parentRepeatForField.label[0].toLowerCase()}${parentRepeatForField.label.substring(1)}`}
      </Typography.Text>
    );
  }

  return note;
}
