import { useState } from "react";
import cx from "classnames";
import { Form, Modal, Button, Input, Select, Popover } from "antd";
import { useForceUpdate } from "common/helpers";
import { HexColorPicker } from "react-colorful";

import "./VariableModal.scss";

export default function VariableModal({ onClose, organisationDetails, variable }) {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const forceUpdate = useForceUpdate();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ type, name, value }) {
    setIsLoading(true);

    if (variable) {
      await window.callGraphQLSimple({
        message: "Failed to edit variable",
        mutation: "updateVariable",
        variables: {
          input: {
            id: variable.id,
            type,
            value,
          },
        },
      });
    } else {
      await window.callGraphQLSimple({
        message: "Failed to create variable",
        mutation: "createVariable",
        variables: {
          input: {
            id: `${organisationDetails.id}_${type}_${name}`,
            organisation: organisationDetails.id,
            name,
            type,
            value,
          },
        },
      });
    }

    await window.callGraphQLSimple({
      message: "Failed to refresh organisation details",
      mutation: "updateOrganisation",
      variables: {
        input: {
          id: organisationDetails.id,
          itemSubscription: Math.floor(Math.random() * 1000000),
        },
      },
    });
    setIsLoading(false);
    onClose();
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  function ValueInput({ isColorPickerOpen, setIsColorPickerOpen, value, onChange }) {
    return (
      <>
        <Input
          className={cx({
            "color-input": form.getFieldValue("type") === "COLOR",
          })}
          value={value}
          onChange={onChange}
        />

        {form.getFieldValue("type") === "COLOR" && (
          <Popover
            trigger="click"
            content={
              <div className="color-picker-overlay">
                <HexColorPicker
                  color={form.getFieldValue("value")}
                  onChange={(newColor) => {
                    onChange(newColor);
                  }}
                />
              </div>
            }
            placement="bottomRight"
            visible={isColorPickerOpen}
            onVisibleChange={setIsColorPickerOpen}
          >
            <div className="color-preview" style={{ backgroundColor: form.getFieldValue("value") }} />
          </Popover>
        )}
      </>
    );
  }

  return (
    <Modal
      maskClosable={false}
      title={variable ? "Edit variable" : "Create variable"}
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="variable-modal"
    >
      <Form
        {...layout}
        form={form}
        initialValues={{
          name: variable?.name,
          type: variable?.type,
          value: variable?.value,
        }}
        onFinish={onSubmit}
      >
        {!variable && (
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: "You must choose a name" },
              {
                validator: async (_, name) => {
                  if (!name) {
                    return;
                  }

                  let variableNameAlreadyExists = organisationDetails.variables.items.find(
                    (variable) => variable.name === name
                  );

                  if (variableNameAlreadyExists) {
                    throw new Error("Variable name already exists");
                  }

                  if (String(name).includes(" ")) {
                    throw new Error("Variable names cannot contain spaces");
                  }

                  if (String(name).includes("$")) {
                    throw new Error("Variable names cannot the $ symbol");
                  }

                  return;
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: "You must choose a type" }]}
          dependencies={["value"]}
        >
          <Select
            onChange={(value) => {
              forceUpdate();
              form.setFieldsValue({ type: value });
            }}
          >
            <Select.Option value="COLOR">Color</Select.Option>
            <Select.Option value="NUMBER">Number</Select.Option>
            <Select.Option value="AUTOMATION">Automation</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="value"
          label="Value"
          dependencies={["type"]}
          rules={[
            { required: true, message: "You must choose a value" },
            {
              validator: async (_, value) => {
                if (form.getFieldValue("type") === "COLOR") {
                  if (!value) {
                    return;
                  }
                  let isValidHexColor = /^#([0-9A-F]{3}){1,2}$/i.test(value);
                  if (!isValidHexColor) {
                    throw new Error("Color value must be a valid hexadecimal color");
                  }
                }
              },
            },
          ]}
        >
          <ValueInput isColorPickerOpen={isColorPickerOpen} setIsColorPickerOpen={setIsColorPickerOpen} />
        </Form.Item>

        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="submit-button">
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}
