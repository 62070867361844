import cx from "classnames";
import moment from "moment";
import { useRef, useEffect, useState } from "react";
import { Tag, Dropdown, message, Button } from "antd";
import { withRouter } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { recalculateInvoiceAmounts } from "common/invoiceHelpers";
import { TIMESHEET_HOUR_HEIGHT, FILE_TYPES_READABLE } from "common/constants";
import { callGraphQLSimple } from "common/apiHelpers";

import TimesheetBlock from "../TimesheetBlock/TimesheetBlock";
import TimesheetBlockModal from "Modals/TimesheetBlockModal/TimesheetBlockModal";
import InfoItem from "InfoItem/InfoItem";
import RecordingSymbol from "reusableComponents/RecordingSymbol/RecordingSymbol";

import "./TimesheetCanvas.scss";

export function TimesheetCanvas({
  context,
  targetDate,
  organisationDetails,
  snapCoefficientHoursEdit,
  snapCoefficientHoursCreate,
  timesheetBlocks,
  tasks,
  projects,
  targetUserId,
  viewerIsOwner,
  setPropsForPage,
  hours,
  users,
  timesheetTags,
  quotes,
  clients,
  allBlocksAreApproved,
  isUsingClockInClockOut,
}) {
  const [isTimesheetBlockModalVisible, setIsTimesheetBlockModalVisible] = useState(false);
  const [newTimesheetBlock, setNewTimesheetBlock] = useState();
  const [targetTimesheetBlock, setTargetTimesheetBlock] = useState();
  const [isResizingBlock, setIsResizingBlock] = useState(false);
  const [isDraggingBlock, setIsDraggingBlock] = useState(false);
  const userTimesheetBlocks = timesheetBlocks.filter((timesheetBlock) => {
    if (timesheetBlock.userId !== targetUserId) {
      return false;
    }
    if (!moment(timesheetBlock.startAt).isSame(targetDate, "day")) {
      return false;
    }

    return true;
  });

  const user = users.find((x) => x.id === targetUserId);
  const timesheetCanvasRef = useRef();
  const [blockInClipboard, setBlockInClipboard] = useState(undefined);
  const [numberForRefresh, setNumberForRefresh] = useState(0);

  const refreshEverySecondIntervalRef = useRef();

  useEffect(() => {
    window.addEventListener("mouseup", onMouseUp);

    if (isUsingClockInClockOut) {
      refreshEverySecondIntervalRef.current = setInterval(() => {
        setNumberForRefresh((prev) => prev + 1);
      }, 1000);
    }

    return () => {
      window.removeEventListener("mouseup", onMouseUp);
      if (refreshEverySecondIntervalRef.current) {
        clearInterval(refreshEverySecondIntervalRef.current);
      }
    };
  }, []);

  function onMouseUp() {
    setIsResizingBlock(false);
    setIsDraggingBlock(false);

    setTimeout(() => {
      setIsResizingBlock(false);
      setIsDraggingBlock(false);
    }, 300);
  }

  async function onDrop(e) {
    const draggableType = e.dataTransfer.getData("draggable-type");
    onMouseUp();
    if (!draggableType || draggableType === "") {
      return;
    }

    if (draggableType === "timesheet-block") {
      await moveBlockOnDrop(e);
    }

    if (draggableType === "task" || draggableType === "pseudo-task") {
      await createBlockOnDrop(e, draggableType);
    }
  }

  async function moveBlockOnDrop(e) {
    const blockId = e.dataTransfer.getData("block-id");
    let dropY = getLocalY(e);

    let targetStartHours = dropY / TIMESHEET_HOUR_HEIGHT;
    let timesheetBlock = timesheetBlocks.find((x) => x.id === blockId);
    if (!timesheetBlock) {
      return;
    }

    if (timesheetBlock.invoiceId && timesheetBlock.invoiceId !== "nothing") {
      const invoice = (
        await window.callGraphQLSimple({
          message: "Failed to fetch invoice details",
          queryCustom: "getInvoice",
          variables: {
            id: timesheetBlock.invoiceId,
          },
        })
      ).data.getInvoice;

      if (invoice.reviewStatus === "SUCCESS") {
        message.error("This timesheet block is linked to an already-approved invoice, so you cannot move it.");
        setIsDraggingBlock(false);
        return;
      }
    }

    let blockStartHours = moment(timesheetBlock.startAt).diff(
      moment(timesheetBlock.startAt).startOf("day"),
      "hours",
      true
    );

    let deltaHours =
      Math.floor((targetStartHours - blockStartHours) / snapCoefficientHoursEdit) * snapCoefficientHoursEdit;
    let newStartAt = moment(timesheetBlock.startAt);
    newStartAt.add(deltaHours, "hours");
    let newEndAt = moment(timesheetBlock.endAt);
    newEndAt.add(deltaHours, "hours");

    let newTimesheetBlocks = timesheetBlocks.map((crtBlock) => {
      if (crtBlock.id !== timesheetBlock.id) {
        return crtBlock;
      }
      return {
        ...crtBlock,
        startAt: newStartAt?.toISOString() || timesheetBlock.startAt,
        endAt: newEndAt?.toISOString() || timesheetBlock.endAt,
      };
    });

    setPropsForPage({
      context: {
        ...context,
        timesheetBlocks: newTimesheetBlocks,
      },
    });

    window.callGraphQLSimple({
      message: "Failed to update timesheet block",
      mutation: "updateTimesheetBlock",
      variables: {
        input: {
          id: timesheetBlock.id,
          startAt: newStartAt || timesheetBlock.startAt,
          endAt: newEndAt || timesheetBlock.endAt,
        },
      },
    });

    setIsDraggingBlock(false);
  }

  async function createBlockOnDrop(e) {
    const taskId = e.dataTransfer.getData("task-id");
    if (!taskId || taskId === "") {
      return;
    }
    let dropY = getLocalY(e);
    let startHours = dropY / TIMESHEET_HOUR_HEIGHT;

    let auditItem = e.dataTransfer.getData("audit-item") ? JSON.parse(e.dataTransfer.getData("audit-item")) : undefined;
    let timelineBlock = e.dataTransfer.getData("timeline-block")
      ? JSON.parse(e.dataTransfer.getData("timeline-block"))
      : undefined;

    let predefinedDescription;

    if (auditItem) {
      if (auditItem.fileId !== "nothing") {
        predefinedDescription = FILE_TYPES_READABLE[auditItem.fileType]
          ? `Worked on the ${FILE_TYPES_READABLE[auditItem.fileType]} file`
          : "";
      } else if (auditItem.page === "REVIEW") {
        predefinedDescription = `Review`;
      } else if (auditItem.page === "TIMELINE") {
        predefinedDescription = `Planning`;
      } else if (auditItem.page === "USER_TIMESHEET") {
        predefinedDescription = `Fill out timesheet`;
      }
    }

    let roundedStartHours =
      Math.floor(startHours * (1 / snapCoefficientHoursCreate)) / (1 / snapCoefficientHoursCreate);

    let newTimesheetBlockDetails = {
      startAt: moment(targetDate).startOf("day").add(roundedStartHours, "hours"),
      endAt: moment(targetDate)
        .startOf("day")
        .add(roundedStartHours + 1, "hours"),
      taskId,
      userId: targetUserId,
      organisation: organisationDetails.id,
      description: predefinedDescription,
      extraDetails: {
        fileId: auditItem?.fileId,
      },
    };

    if (timelineBlock && timelineBlock.durationHours !== null && timelineBlock.durationHours !== undefined) {
      newTimesheetBlockDetails.endAt = moment(newTimesheetBlockDetails.startAt).add(
        timelineBlock.durationHours,
        "hours"
      );
    }

    setNewTimesheetBlock(newTimesheetBlockDetails);
    setIsTimesheetBlockModalVisible(true);
  }

  function getLocalY(e) {
    const timesheetCanvasBounds = timesheetCanvasRef.current.getBoundingClientRect();

    return Math.round(e.pageY - timesheetCanvasBounds.top);
  }

  function onDragOver(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  async function onCreate(timesheetBlock) {
    if (timesheetBlock.extraDetails) {
      delete timesheetBlock.extraDetails;
    }
    if (timesheetBlock.feeRole && !feeRoleMatchesAnExistingOne(timesheetBlock)) {
      message.error(
        "The fee level selected is not valid (this is likely a technical issue). Please try again or contact our support team."
      );
      return;
    }

    let latLong;
    if (isUsingClockInClockOut) {
      latLong = await getLatLong();
    }

    await window.callGraphQLSimple({
      message: "Failed to create timesheet block",
      mutation: "createTimesheetBlock",
      variables: {
        input: {
          ...timesheetBlock,
          invoiceId: "nothing",
          quoteId: timesheetBlock.quoteId?.length > 0 ? timesheetBlock.quoteId : "nothing",
          projectId: timesheetBlock.projectId?.length > 0 ? timesheetBlock.projectId : "nothing",
          taskId: timesheetBlock.taskId?.length > 0 ? timesheetBlock.taskId : "nothing",
          startAt: timesheetBlock.startAt.toISOString(),
          endAt: timesheetBlock.endAt.toISOString(),
          latLongStart: latLong,
        },
      },
    });
    setIsTimesheetBlockModalVisible(false);
    setNewTimesheetBlock();
    setTargetTimesheetBlock();
  }

  async function getLatLong() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          resolve(JSON.stringify({ latitude, longitude }));
        },
        function (error) {
          console.error("Error Code = " + error.code + " - " + error.message);
          message.error(`Failed to get your location: ${error.message}`);
          reject(error);
        }
      );
    });
  }

  async function onEdit(timesheetBlock) {
    if (timesheetBlock.extraDetails) {
      delete timesheetBlock.extraDetails;
    }
    const { createdAt, updatedAt, expenses, ...input } = timesheetBlock;

    if (timesheetBlock.feeRole && !feeRoleMatchesAnExistingOne(timesheetBlock)) {
      message.error(
        "The fee level selected is not valid (this is likely a technical issue). Please try again or contact our support team."
      );
      return;
    }

    let latLong;
    if (isUsingClockInClockOut) {
      latLong = await getLatLong();
    }

    if (!isUsingClockInClockOut) {
      input.isRecording = false;
    }

    await window.callGraphQLSimple({
      message: "Failed to update timesheet block",
      mutation: "updateTimesheetBlock",
      variables: {
        input: {
          ...input,
          taskId: input.taskId?.length > 0 ? input.taskId : "nothing",
          quoteId: input.quoteId?.length > 0 ? input.quoteId : "nothing",
          projectId: input.projectId?.length > 0 ? input.projectId : "nothing",
          startAt: timesheetBlock.startAt.toISOString(),
          endAt: timesheetBlock.endAt.toISOString(),
          latLongEnd: latLong,
        },
      },
    });

    if (timesheetBlock.invoiceId && timesheetBlock.invoiceId !== "nothing") {
      await recalculateInvoiceAmounts({ invoiceId: timesheetBlock.invoiceId });
    }

    setIsTimesheetBlockModalVisible(false);
    setNewTimesheetBlock();
    setTargetTimesheetBlock();
  }

  function feeRoleMatchesAnExistingOne(timesheetBlock) {
    let feeRole = timesheetBlock.feeRole;

    let matchingFeeRole = organisationDetails.defaultFees.find((x) => x.id === feeRole);
    if (!matchingFeeRole) {
      let clientDetails = clients.find((x) => x.id === timesheetBlock.clientId);
      matchingFeeRole = clientDetails.fees?.find((x) => x.id === feeRole);
    }

    return !!matchingFeeRole;
  }

  function onClockIn() {
    let newTimesheetBlockDetails = {
      startAt: moment(),
      endAt: moment(targetDate).clone().add(10, "years"),
      userId: targetUserId,
      organisation: organisationDetails.id,
    };

    setNewTimesheetBlock(newTimesheetBlockDetails);
    setIsTimesheetBlockModalVisible(true);
  }

  async function onClockOut(latestTimesheetBlockForDate) {
    setTargetTimesheetBlock({
      ...latestTimesheetBlockForDate,
      startAt: moment(latestTimesheetBlockForDate.startAt),
      endAt: moment(),
    });
    setIsTimesheetBlockModalVisible(true);
    // await callGraphQLSimple({
    //   message: "Failed to update timesheet block",
    //   mutation: "updateTimesheetBlock",
    //   variables: {
    //     input: {
    //       id: latestTimesheetBlockForDate.id,
    //       endAt: moment().toISOString(),
    //       isRecording: false,
    //     },
    //   },
    // });
  }

  let content = null;
  let sortedTimesheetBlocksForDate = timesheetBlocks.sort((a, b) => (a.startAt < b.startAt ? -1 : 1));
  let latestTimesheetBlockForDate = timesheetBlocks.length > 0 ? sortedTimesheetBlocksForDate.slice(-1)[0] : null;
  if (isUsingClockInClockOut) {
    let isClockedIn = timesheetBlocks.some((block) => block.userId === targetUserId && block.isRecording);

    let timeSpentSoFarToday = timesheetBlocks.reduce((acc, crt) => {
      if (crt.userId !== targetUserId) {
        return acc;
      }

      let startAt = moment(crt.startAt);
      let endAt = moment(crt.endAt);

      if (crt.isRecording) {
        endAt = moment();
      }

      let duration = moment.duration(endAt.diff(startAt));
      return acc + duration.asMilliseconds();
    }, 0);

    // let timeSpentByFeeLevel = timesheetBlocks.reduce((acc, crt) => {
    //   if (crt.userId !== targetUserId) {
    //     return acc;
    //   }

    //   let startAt = moment(crt.startAt);
    //   let endAt = moment(crt.endAt);

    //   if (crt.isRecording) {
    //     endAt = moment();
    //   }

    //   let duration = moment.duration(endAt.diff(startAt));

    //   if (!crt.feeRole) {
    //     return acc;
    //   }

    //   if (!acc[crt.feeRole]) {
    //     acc[crt.feeRole] = 0;
    //   }

    //   acc[crt.feeRole] += duration.asMilliseconds();
    //   return acc;
    // }, {});

    // let clockedInFeeLevel = latestTimesheetBlockForDate
    //   ? organisationDetails.defaultFees.find((x) => x.id === latestTimesheetBlockForDate.feeRole)
    //   : undefined;

    let clockedInOnTask = latestTimesheetBlockForDate
      ? tasks.find((x) => x.id === latestTimesheetBlockForDate.taskId)
      : undefined;

    let timeSinceClockedIn = moment.utc(moment().diff(moment(latestTimesheetBlockForDate?.startAt))).format("HH:mm:ss");

    content = (
      <>
        <div className="clock-in-out-view">
          {isClockedIn && (
            <div style={{ marginBottom: "1rem" }}>
              <Tag className="accent-tag">
                <RecordingSymbol white />
                Clocked in on "{clockedInOnTask ? clockedInOnTask.title : "Unknown"}" for {timeSinceClockedIn}
              </Tag>
            </div>
          )}
          <InfoItem
            label="Time spent in total today"
            value={<strong>{moment.utc(timeSpentSoFarToday).format("HH:mm:ss")}</strong>}
          />

          {/* {Object.keys(timeSpentByFeeLevel).map((feeRole) => {
            let feeRoleDetails = organisationDetails.defaultFees.find((x) => x.id === feeRole);
            return (
              <InfoItem
                key={feeRole}
                label={`Time spent as ${feeRoleDetails?.label}`}
                value={<strong>{moment.utc(timeSpentByFeeLevel[feeRole]).format("HH:mm:ss")}</strong>}
              />
            );
          })} */}

          {isClockedIn ? (
            <div>
              <Button onClick={() => onClockOut(latestTimesheetBlockForDate)}>Clock out</Button>
            </div>
          ) : (
            <div>
              <Button type="primary" onClick={onClockIn}>
                Clock in
              </Button>
            </div>
          )}
        </div>
      </>
    );
  } else {
    content = (
      <>
        {userTimesheetBlocks?.map((block) => (
          <TimesheetBlock
            timesheetBlocks={timesheetBlocks}
            snapCoefficientHours={snapCoefficientHoursEdit}
            key={block.id}
            tasks={tasks}
            setPropsForPage={setPropsForPage}
            context={context}
            projects={projects}
            viewerIsOwner={viewerIsOwner}
            timesheetBlock={block}
            timesheetTags={timesheetTags}
            onDragStart={() => setIsDraggingBlock(true)}
            onDragEnd={() => setIsDraggingBlock(false)}
            onResizeStart={() => setIsResizingBlock(true)}
            onResizeEnd={() => setIsResizingBlock(false)}
            organisationDetails={organisationDetails}
            onCopy={(timesheetBlock) => {
              setBlockInClipboard(timesheetBlock);
            }}
            onOpen={() => {
              setTargetTimesheetBlock({
                ...block,
                startAt: moment(block.startAt),
                endAt: moment(block.endAt),
              });
              setIsTimesheetBlockModalVisible(true);
            }}
          />
        ))}

        {!allBlocksAreApproved && (
          <div className="empty-hours-container">
            {hours.map((_, i) => (
              <Dropdown
                key={i}
                menu={{
                  items: [
                    {
                      key: "paste",
                      label: "Paste",
                      onClick: () => {
                        onCreate({
                          ...blockInClipboard,
                          createdAt: undefined,
                          updatedAt: undefined,
                          expenses: undefined,
                          id: undefined,
                          startAt: moment(targetDate).startOf("day").set({ hour: i }),
                          endAt: moment(targetDate)
                            .startOf("day")
                            .set({ hour: i + 1 }),
                          userId: targetUserId,
                          organisation: organisationDetails.id,
                        });
                      },
                    },
                    {
                      key: "new",
                      label: "Add new",
                      onClick: () => {
                        setNewTimesheetBlock({
                          startAt: moment(targetDate).startOf("day").set({ hour: i }),
                          endAt: moment(targetDate)
                            .startOf("day")
                            .set({ hour: i + 1 }),
                          userId: targetUserId,
                          organisation: organisationDetails.id,
                          taskId: "nothing",
                        });
                        setIsTimesheetBlockModalVisible(true);
                      },
                    },
                  ],
                }}
                trigger={blockInClipboard ? ["contextMenu", "click"] : []}
              >
                <div
                  className="empty-hour-container"
                  style={{ top: i * 64 + 3 }}
                  onClick={() => {
                    if (blockInClipboard) {
                      return;
                    }

                    setNewTimesheetBlock({
                      startAt: moment(targetDate).startOf("day").set({ hour: i }),
                      endAt: moment(targetDate)
                        .startOf("day")
                        .set({ hour: i + 1 }),
                      userId: targetUserId,
                      organisation: organisationDetails.id,
                      taskId: "nothing",
                    });

                    setIsTimesheetBlockModalVisible(true);
                  }}
                >
                  <PlusCircleOutlined />
                </div>
              </Dropdown>
            ))}
          </div>
        )}
      </>
    );
  }

  return (
    <div
      className={cx("timesheet-canvas", {
        "is-resizing-block": isResizingBlock,
        "is-dragging-block": isDraggingBlock,
      })}
      onDrop={onDrop}
      onDragOver={onDragOver}
      data-cy="timesheet-canvas"
      ref={timesheetCanvasRef}
    >
      {content}

      {isTimesheetBlockModalVisible && (
        <TimesheetBlockModal
          tasks={tasks}
          timesheetBlock={newTimesheetBlock || targetTimesheetBlock}
          isNew={newTimesheetBlock}
          onCreate={onCreate}
          onEdit={onEdit}
          viewerIsOwner={viewerIsOwner}
          targetDate={targetDate}
          onClose={() => {
            setIsTimesheetBlockModalVisible(false);
            setNewTimesheetBlock();
            setTargetTimesheetBlock();
          }}
          organisationDetails={organisationDetails}
          user={user}
          timesheetTags={timesheetTags}
          quotes={quotes}
          clients={clients}
          isUsingClockInClockOut={isUsingClockInClockOut}
        />
      )}
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TimesheetCanvas,
    subscriptions: ["organisationDetails", "timesheetTags", "quotes", "clients"],
  })
);
