import cx from "classnames";
import { Typography } from "antd";

import "./InfoItem.scss";

export default function InfoItem({
  label,
  value,
  inline = false,
  className = "",
  extraContent = null,
  onClick = undefined,
  includeColon = true,
  fullWidth = false,
  noBottomSpace = false,
}) {
  let weNeedColon = includeColon;

  if (typeof label === "string" && label.endsWith(":")) {
    weNeedColon = false;
  }

  return (
    <div
      className={cx("info-item", className, {
        inline,
        clickable: onClick,
        "full-width": fullWidth,
        "no-bottom-space": noBottomSpace,
      })}
      onClick={onClick}
    >
      <div className="main-content">
        {label && (
          <div className="label-container">
            <Typography.Text className="label">
              {label}
              {weNeedColon && ":"}
            </Typography.Text>
          </div>
        )}
        <div className="value-container">
          {typeof value === "string" || typeof value === "number" ? (
            <Typography.Text className="static-value">{value}</Typography.Text>
          ) : (
            value
          )}
        </div>
      </div>
      {extraContent && <div className="extra-content">{extraContent}</div>}
    </div>
  );
}
