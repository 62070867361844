import { useState, useEffect, useRef } from "react";
import { message, Dropdown, Menu } from "antd";
import cx from "classnames";
import { useGetSetState } from "react-use";

import Input from "Input/Input";
import { HyperFormula, AlwaysSparse } from "hyperformula";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import { LETTERS, DEFAULT_SPREADSHEET_CELL_WIDTH, DOCUMENT_FORM_SPREADSHEET_DEFAULT_COLUMNS } from "common/constants";

import "./Spreadsheet.scss";

const hyperformulaOptions = {
  licenseKey: "gpl-v3",
  evaluateNullToZero: true,
  chooseAddressMappingPolicy: new AlwaysSparse(),
};

export default function Spreadsheet({ data, onChange, isReadOnly }) {
  const hyperformulaInstance = useRef();
  const [getState, setState] = useGetSetState({
    selectedCellAddress: undefined,
    resizeColumnOperation: {},
    highlightedCellAddresses: [],
  });

  const state = getState();

  const hf = hyperformulaInstance.current;

  const { rowCount, columnCount } = data;

  useEffect(() => {
    hyperformulaInstance.current = HyperFormula.buildEmpty(hyperformulaOptions);
    hyperformulaInstance.current.addSheet("0");
    window.addEventListener("click", onWindowClick);
    window.addEventListener("mouseup", onWindowMouseUp);
    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("click", onWindowClick);
      window.removeEventListener("mouseup", onWindowMouseUp);
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    hyperformulaInstance.current.setCellContents(
      {
        row: 0,
        col: 0,
        sheet: 0,
      },
      data.rows
    );
  }, [data]);

  function onWindowClick(e) {
    setState({
      selectedCellAddress: undefined,
      highlightedCellAddresses: [],
    });
  }

  function onWindowMouseUp() {
    const { resizeColumnOperation } = getState();
    if (resizeColumnOperation.isActive && resizeColumnOperation.col) {
      setTimeout(() => saveColumnResize(resizeColumnOperation), 0);
    }
    setState({
      resizeColumnOperation: {},
    });
  }

  function saveColumnResize({ col, deltaX }) {
    const newData = JSON.parse(
      JSON.stringify({
        ...data,
      })
    );

    if (!newData.columns) {
      newData.columns = {};
    }

    if (!newData.columns[col]) {
      newData.columns[col] = {};
    }

    if (!newData.columns[col].width) {
      newData.columns[col].width = DEFAULT_SPREADSHEET_CELL_WIDTH;
    }

    newData.columns[col].width += deltaX;
    onChange(JSON.stringify(newData));
  }

  function onMouseMove(e) {
    const { resizeColumnOperation } = getState();

    if (resizeColumnOperation.isActive) {
      handleMouseMoveWhileResizingColumn(e, resizeColumnOperation);
    }
  }

  function handleMouseMoveWhileResizingColumn(e, resizeColumnOperation) {
    let newResizeColumnOperation = JSON.parse(JSON.stringify(resizeColumnOperation));
    if (!resizeColumnOperation.initialX) {
      newResizeColumnOperation.initialX = e.screenX;
    }

    newResizeColumnOperation.deltaX = e.screenX - resizeColumnOperation.initialX;
    setState({
      resizeColumnOperation: newResizeColumnOperation,
    });
  }

  function onCellChange({ row, col, value }) {
    if (value === "") {
      value = null;
    }
    hyperformulaInstance.current.setCellContents(
      {
        row,
        col,
        sheet: 0,
      },
      value
    );

    onInternalChange();
  }

  function onInternalChange(params) {
    const { columnCountDelta } = params || {};
    const newRows = hyperformulaInstance.current.getSheetSerialized(0);
    const newDataStringified = JSON.stringify({
      ...data,
      rows: newRows,
      columnCount: (data.columnCount || DOCUMENT_FORM_SPREADSHEET_DEFAULT_COLUMNS) + (columnCountDelta || 0),
    });

    const oldDataStringified = JSON.stringify(data);

    if (newDataStringified !== oldDataStringified) {
      onChange(newDataStringified);
    }
  }

  function onClick(e) {
    e.stopPropagation();
    if (e.target.dataset) {
      const attributes = e.target.dataset;
      if (attributes.cy === "cell") {
        setState({
          highlightedCellAddresses: [{ row: parseInt(attributes.row), col: parseInt(attributes.col) }],
        });
        const { selectedCellAddress } = getState();
        if (
          selectedCellAddress &&
          (selectedCellAddress.row !== parseInt(attributes.row) || selectedCellAddress.col !== parseInt(attributes.col))
        ) {
          setState({
            selectedCellAddress: undefined,
          });
        }
        e.stopPropagation();
      }
    }
  }

  function onDoubleClick(e) {
    e.stopPropagation();
    if (isReadOnly) {
      return;
    }

    if (e.target.dataset) {
      const attributes = e.target.dataset;
      if (attributes.type === "cell") {
        setState({
          selectedCellAddress: {
            row: parseInt(attributes.row),
            col: parseInt(attributes.col),
          },
        });
      }
    }
  }

  function onPaste(e) {
    if (isReadOnly) {
      return;
    }

    let startCol = 0;
    let startRow = 0;
    if (e.target.dataset) {
      const attributes = e.target.dataset;
      if (attributes.type === "cell") {
        startCol = parseInt(attributes.col);
        startRow = parseInt(attributes.row);
      }
    }
    const pastedText = e.clipboardData.getData("Text");
    let pastedRows = pastedText.split(/\r?\n/);
    pastedRows = pastedRows.map((row) =>
      row.split("\t").map((cell) => {
        if (cell === "") {
          return null;
        }
        return cell;
      })
    );
    hyperformulaInstance.current.setCellContents(
      {
        row: startRow,
        col: startCol,
        sheet: 0,
      },
      pastedRows
    );
    onInternalChange();
  }

  async function onCopy() {
    const { highlightedCellAddresses } = getState();

    const highlightedCellAddress = highlightedCellAddresses[0];
    if (highlightedCellAddress) {
      try {
        const cellValue = hf.getCellValue({
          ...highlightedCellAddress,
          sheet: 0,
        });
        navigator.clipboard
          .writeText(cellValue)
          .then(() =>
            message.success({
              content: "Cell value copied",
            })
          )
          .catch((e) =>
            message.error({
              content: "Failed to copy cell value to clipboard",
            })
          );
      } catch (e) {
        message.error({
          content: "Failed to copy cell value to clipboard",
        });
      }
    }
    return highlightedCellAddresses;
  }

  if (!hf) {
    return null;
  }

  function insertColumnToLeft({ col }) {
    hf.addColumns(0, [col, 1]);
    onInternalChange({ columnCountDelta: 1 });
  }

  function insertColumnToRight({ col }) {
    hf.addColumns(0, [col + 1, 1]);
    onInternalChange({ columnCountDelta: 1 });
  }

  function deleteColumn({ col }) {
    hf.removeColumns(0, [col, 1]);
    onInternalChange({ columnCountDelta: -1 });
  }

  function getCellStyle({ col, row }) {
    const { resizeColumnOperation } = state;
    let style = {
      width: DEFAULT_SPREADSHEET_CELL_WIDTH,
    };

    if (data.columns) {
      if (data.columns[col]) {
        const columnDefinition = data.columns[col];
        if (
          columnDefinition.hasOwnProperty("width") &&
          columnDefinition.width !== undefined &&
          columnDefinition.width !== null
        ) {
          style.width = columnDefinition.width;
        }
      }
    }

    if (
      resizeColumnOperation.isActive &&
      resizeColumnOperation.col === col &&
      resizeColumnOperation.deltaX !== undefined
    ) {
      style.width += resizeColumnOperation.deltaX;
    }

    return style;
  }

  function displayHeader() {
    return (
      <div className="row row-header" data-cy="row-header">
        <div className={cx("cell", "cell-top-left")} />
        {new Array(columnCount).fill(null).map((_, col) => {
          let columnName = LETTERS[col];
          return (
            <Dropdown
              key={columnName}
              trigger={["contextMenu"]}
              overlay={
                <Menu
                  items={[
                    {
                      key: "add-col-left",
                      label: "Insert 1 col to the left",
                      icon: <PlusOutlined />,
                      disabled: col === 0,
                      onClick: () => {
                        insertColumnToLeft({ col });
                      },
                    },
                    {
                      key: "add-col-right",
                      label: "Insert 1 col to the right",
                      icon: <PlusOutlined />,
                      onClick: () => {
                        insertColumnToRight({ col });
                      },
                    },
                    {
                      key: "delete-col",
                      label: `Delete col ${columnName}`,
                      icon: <DeleteOutlined />,
                      onClick: () => {
                        deleteColumn({ col });
                      },
                    },
                  ]}
                />
              }
            >
              <div className={cx("cell")} data-col={col} style={getCellStyle({ col, row: -1 })}>
                {columnName}
                {(window.location.hostname === "localhost" || window.apiUser.isHidden) && col < columnCount - 1 && (
                  <div
                    className="cell-resize-right"
                    onMouseDown={(e) => {
                      setState({
                        resizeColumnOperation: {
                          isActive: true,
                          initialX: e.screenX,
                          col,
                        },
                      });
                    }}
                  />
                )}
              </div>
            </Dropdown>
          );
        })}
      </div>
    );
  }

  function displayBody() {
    return new Array(rowCount).fill(null).map((_, row) => {
      return (
        <div className="row" data-cy="row" data-row={row} key={row}>
          <div className={cx("cell", "cell-row-number")}>{row + 1}</div>
          {new Array(columnCount).fill(null).map((_, col) => {
            const cellisHighlighted = state.highlightedCellAddresses.find(
              (address) => address.col === col && address.row === row
            );
            const cellAddress = { sheet: 0, row, col };
            const cellType = hf.getCellType(cellAddress);
            const cellIsSelected =
              state.selectedCellAddress &&
              state.selectedCellAddress.row === row &&
              state.selectedCellAddress.col === col;
            return (
              <div
                key={col}
                className={cx("cell", {
                  "cell-highlighted": cellisHighlighted,
                  "cell-selected": cellIsSelected,
                  "cell-formula": cellType === "FORMULA",
                })}
                data-cy="cell"
                data-type="cell"
                data-col={col}
                data-row={row}
                style={getCellStyle({ col, row })}
              >
                <div className="cell-content">
                  {cellIsSelected ? (
                    <Input
                      defaultValue={
                        cellType === "FORMULA" ? hf.getCellFormula(cellAddress) : hf.getCellValue(cellAddress)
                      }
                      onChange={(value) => {
                        onCellChange({ row, col, value });
                        const { selectedCellAddress } = getState();

                        if (
                          selectedCellAddress &&
                          (selectedCellAddress.row !== row || selectedCellAddress.col !== col)
                        ) {
                          setState({
                            selectedCellAddress: undefined,
                          });
                        }
                      }}
                      showBorder
                      autoFocus
                    />
                  ) : (
                    hf.getCellValue(cellAddress)
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  }

  return (
    <div
      className={cx("spreadsheet", { "spreadsheet-read-only": isReadOnly })}
      onClick={(e) => onClick(e)}
      onDoubleClick={(e) => onDoubleClick(e)}
      onPaste={onPaste}
      onCopy={onCopy}
    >
      {displayHeader()}
      {displayBody()}
    </div>
  );
}
