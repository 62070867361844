import React from "react";
import moment from "moment";
import { Progress, Typography } from "antd";
import cx from "classnames";
import withSubscriptions from "common/withSubscriptions";
import { Draggable } from "react-beautiful-dnd";

import { isAuthorised } from "common/permissions";

import BudgetBar from "BudgetBar/BudgetBar";
import Card from "Card/Card";
import Avatar from "Avatar/Avatar";
import DashboardItemTags from "DashboardItemTags/DashboardItemTags";
import ClientLogo from "ClientLogo/ClientLogo";
import TaskIdTag from "TaskIdTag/TaskIdTag";
import InfoItem from "InfoItem/InfoItem";

import "./TaskItem.scss";

export function TaskItem(props) {
  const {
    index,
    organisationDetails,
    onClick,
    projects,
    clients,
    includeTags = true,
    draggable = true,
    includeDueDate,
    users,
    task,
    apiUser,
    isTimelinePage,
    timelineBlocks,
    isUnplannedTaskList,
    planningStartDate,
    planningEndDate,
  } = props;
  const { id, initials, title, assignedTo, dueDate, idColor, projectId, clientId, isConfirmed } = task;

  let quotesInHours = organisationDetails.settings?.quote?.quotesInHours;

  let spent;
  let budget;
  if (quotesInHours) {
    spent = task.hoursSpent;
    budget = task.hoursBudget;
  } else {
    spent = task.amountSpent;
    budget = task.amountBudget;
  }

  const project = projects.find((x) => x.id === projectId);
  const client = clients.find((x) => x.id === clientId);

  if (!project) {
    return null;
  }

  const userData = users.find((x) => x.id === assignedTo);

  let idTagProps = {};

  if (idColor) {
    idTagProps.style = {
      backgroundColor: idColor,
    };
  }

  let progressBarColor = "#19aae8";
  if (task.subtaskProgress && task.subtaskProgress >= 100) {
    progressBarColor = "#44bd32";
  }

  let teamElement = null;
  if (task.team && organisationDetails?.settings?.general?.usesTeams && apiUser.teams?.length > 1) {
    let teamDetails = organisationDetails.teams?.find((x) => x.id === task.team);

    teamElement = <InfoItem label="Team" value={teamDetails?.label || "[Team has been deleted]"} inline />;
  }

  let isTaskCardDraggable = draggable;
  let taskRevisionElements = null;
  if (isTimelinePage && task.revisions && organisationDetails.settings?.timeline?.planTaskRevisionsInsteadOfTasks) {
    isTaskCardDraggable = false;
    let revisionsToDisplay = task.revisions.items;

    if (isUnplannedTaskList) {
      revisionsToDisplay = revisionsToDisplay.filter((taskRevision) => {
        let revisionIsPlanned = timelineBlocks.some((block) => {
          return (
            block.taskRevisionId === taskRevision.id &&
            moment(block.startDate).isSameOrAfter(planningStartDate) &&
            moment(block.startDate).isBefore(planningEndDate)
          );
        });

        return !revisionIsPlanned;
      });
    }

    taskRevisionElements = (
      <div className="task-revisions">
        {revisionsToDisplay.map((taskRevision) => {
          return (
            <div
              key={taskRevision.id}
              className="task-revision-item"
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData("task-id", task.id);
                e.dataTransfer.setData("task-revision-id", taskRevision.id);
                e.dataTransfer.setData("draggable-type", "task-revision");
              }}
            >
              <Typography.Text>{taskRevision.name}</Typography.Text>
            </div>
          );
        })}
      </div>
    );
  }

  let taskItemElement = (
    <div
      className={cx("task-item-container", {
        unconfirmed: !isConfirmed && organisationDetails.settings?.general?.usesTaskConfirmation,
        "display-task-revisions": taskRevisionElements,
        "no-task-revisions": !taskRevisionElements,
      })}
    >
      <Card
        className={cx("task-item")}
        attributes={{ "data-task-title": title, "data-task-id": id }}
        onClick={() => onClick(id)}
        data-cy="task-item"
      >
        {teamElement}
        {task.subtaskProgress ? (
          <div className="task-progress-bar">
            <Typography.Text className="static-label">Subtasks done: </Typography.Text>
            <Progress percent={task.subtaskProgress} strokeColor={progressBarColor} size="small" format={() => ""} />
            <Typography.Text className="progress-label">{task.subtaskProgress}%</Typography.Text>
          </div>
        ) : null}
        {!isAuthorised(["TASK.VIEW_BUDGET_BAR"]) || !spent ? null : (
          <BudgetBar
            hideFinancials={organisationDetails.settings?.quote?.quotesInHours}
            size="large"
            amountSpent={spent}
            amountBudget={budget}
            includePercentage
            includeLabel={false}
          />
        )}
        <div className="project-and-task-title">
          {!organisationDetails.settings?.task?.automaticallyCreateProject && (
            <Typography.Paragraph className="project-name">{project.title}</Typography.Paragraph>
          )}
          <div className="task-initials-and-name">
            <Typography.Text className="task-initials">{initials ? `${initials} - ` : ""}</Typography.Text>
            <Typography.Text level={4} className="task-name">
              {title}
            </Typography.Text>
          </div>
        </div>
        {includeDueDate && (
          <Typography.Text className="due-date">
            {dueDate ? <>Due on {moment(dueDate).format("DD MMM YYYY")}</> : "No due date"}
          </Typography.Text>
        )}
        {taskRevisionElements}
        {includeTags && (
          <DashboardItemTags
            item={{ ...task, client, project }}
            organisationDetails={organisationDetails}
            includeLinks
          />
        )}

        <div className="footer">
          <ClientLogo client={client} size="small" />

          <Typography.Paragraph className="task-id">
            <TaskIdTag task={{ ...task, client, project }} includeTitle={false} {...idTagProps} />
          </Typography.Paragraph>
          <Avatar user={userData} className="assigned-to-avatar" />
        </div>
      </Card>
    </div>
  );

  if (!isTaskCardDraggable) {
    return taskItemElement;
  }

  return (
    <Draggable key={id} draggableId={id} index={index} className={`draggable-task ${title.split(" ").join("_")}`}>
      {(provided) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            {taskItemElement}
          </div>
        );
      }}
    </Draggable>
  );
}

export default withSubscriptions({
  Component: React.memo(TaskItem),
  subscriptions: ["users", "projects", "clients"],
});
