import { Checkbox } from "antd";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";

import Card from "Card/Card";

import { FILE_SETTINGS } from "common/settings";
import { getGraphQLInput } from "OrganisationPage/OrganisationSettingsPage/settingsHelpers";

import "./FileSettingsPage.scss";

function FileSettingsPage({ organisationDetails }) {
  const checked = FILE_SETTINGS.filter((setting) => {
    return _.get(organisationDetails, setting.SCHEMA_FIELD);
  });

  const checkedSettings = checked.map((x) => x.SCHEMA_FIELD);

  return (
    <div className="file-settings-page">
      <Card withSpace title="File settings">
        <Checkbox.Group defaultValue={checkedSettings}>
          {FILE_SETTINGS.map((setting) => {
            const { NAME, DESCRIPTION, SCHEMA_FIELD } = setting;

            return (
              <Checkbox
                className="setting-checkbox"
                value={SCHEMA_FIELD}
                key={SCHEMA_FIELD}
                onChange={async (e) => {
                  const input = getGraphQLInput({
                    organisationDetails: {
                      settings: organisationDetails.settings || {},
                    },
                    checked: e.target.checked,
                    module: setting,
                  });
                  await callGraphQLSimple({
                    message: "Could not update setting",
                    queryName: "updateOrganisation",
                    variables: {
                      input: {
                        id: organisationDetails.id,
                        ...input,
                      },
                    },
                  });
                }}
              >
                <span className="setting-name">{NAME}</span>
                <br />
                <span className="setting-description">{DESCRIPTION}</span>
              </Checkbox>
            );
          })}
        </Checkbox.Group>
      </Card>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: FileSettingsPage,
    subscriptions: ["organisationDetails"],
  })
);
