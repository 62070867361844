import RequestIssuedTaskFilesModal from "pages/RequestDetailsPage/RequestIssuedTaskFilesModal/RequestIssuedTaskFilesModal";

import "./RequestActivityItemDetails.scss";

type Props = {
  request: any;
  activityItem: any;
  users;
  organisationDetails: any;
  apiUser: any;
  onClose: () => void;
};

export default function RequestActivityItemDetails(props: Props) {
  const { activityItem, onClose } = props;
  switch (activityItem.content?.type) {
    case "TASK_FILES_SENT":
      return <RequestIssuedTaskFilesModal {...props} onClose={onClose} />;

    default:
      return null;
  }
}
