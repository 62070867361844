import { useState } from "react";
import { Button, Table, Modal, Typography } from "antd";
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Card from "Card/Card";
import VariableModal from "Modals/VariableModal/VariableModal";

import "./TemplateVariables.scss";

export default function TemplateVariables({ organisationDetails }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState();

  function editVariable(row) {
    setSelectedVariable(row);
    setIsModalVisible(true);
  }

  async function confirmDeleteVariable(variable) {
    Modal.confirm({
      title: "Confirm variable delete",
      content: (
        <>
          Are you sure you want to delete <b>{variable.name}</b>?
        </>
      ),
      onOk: async () => {
        window.callGraphQLSimple({
          message: "Failed to delete variable",
          mutation: "deleteVariable",
          variables: {
            input: {
              id: variable.id,
            },
          },
        });

        await window.callGraphQLSimple({
          message: "Failed to refresh organisation details",
          mutation: "updateOrganisation",
          variables: {
            input: {
              id: organisationDetails.id,
              itemSubscription: Math.floor(Math.random() * 1000000),
            },
          },
        });
      },
    });
  }

  return (
    <>
      <Card
        title="Variables"
        withSpace
        className="template-variables"
        actions={
          <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setIsModalVisible(true)}>
            Add variable
          </Button>
        }
      >
        <Table
          pagination={{ hideOnSinglePage: true, pageSize: 50 }}
          rowKey="id"
          dataSource={organisationDetails.variables.items.filter(
            (variable) => variable.type === "NUMBER" || variable.type === "COLOR"
          )}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
              align: "left",
            },
            {
              title: "Type",
              dataIndex: "type",
              key: "type",
              align: "left",
              render: (type) => `${type[0]}${type.substring(1).toLowerCase()}`,
            },
            {
              title: "Value",
              dataIndex: "value",
              key: "value",
              align: "left",
              render: (_, row) => {
                if (row.type === "COLOR") {
                  return (
                    <div className="color-display">
                      <div className="color-preview" style={{ backgroundColor: row.value }} />
                      <Typography.Text className="label">{row.value}</Typography.Text>
                    </div>
                  );
                } else {
                  return <Typography.Text className="label">{row.value}</Typography.Text>;
                }
              },
            },
            {
              key: "actions",
              align: "right",
              width: "80",
              render: (_, row) => (
                <div className="action-buttons">
                  <Button type="clear" icon={<EditOutlined />} onClick={() => editVariable(row)} />
                  <Button type="clear" icon={<DeleteOutlined />} onClick={() => confirmDeleteVariable(row)} />
                </div>
              ),
            },
          ]}
        />
      </Card>
      {isModalVisible && (
        <VariableModal
          organisationDetails={organisationDetails}
          onClose={() => {
            setIsModalVisible(false);
            setSelectedVariable();
          }}
          variable={selectedVariable}
        />
      )}
    </>
  );
}
