import { useEffect, useState } from "react";
import { Form, Modal, Button, Alert, Select, Spin } from "antd";

import { callRest } from "common/apiHelpers";

export default function SelectExistingContactModal({
  onClose,
  sendInvoiceToXero,
  sendInvoiceToQuickbooks,
  accountancyIntegration,
  invoice,
}) {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [contactList, setContactList] = useState([]);
  const [isFetchingContacts, setIsFetchingContacts] = useState(false);

  function displayContactOptions() {
    if (accountancyIntegration.id === "XERO") {
      if (!isFetchingContacts && contactList?.length > 0) {
        return contactList.map((contact) => (
          <Select.Option key={contact.contactID} value={contact.name}>
            {contact.name}
          </Select.Option>
        ));
      }

      if (!isFetchingContacts && contactList?.length === 0) {
        return <Select.Option>No contacts found</Select.Option>;
      }
    }

    if (accountancyIntegration.id === "QUICKBOOKS") {
      if (!isFetchingContacts && contactList?.length > 0) {
        return contactList?.map((contact) => (
          <Select.Option key={contact.Id} value={contact.Id}>
            {contact.DisplayName}
          </Select.Option>
        ));
      }

      if (!isFetchingContacts && contactList?.length === 0) {
        return <Select.Option>No contacts found</Select.Option>;
      }
    }
  }

  useEffect(() => {
    async function getContacts() {
      setIsFetchingContacts(true);

      const getContactListResponse = await callRest({
        route: `/get-${accountancyIntegration.id.toLowerCase()}-contacts`,
        method: "POST",
        body: {
          organisationId: invoice.organisation,
        },
        includeCredentials: false,
      });

      let contactList = [];

      if (accountancyIntegration.id === "XERO") {
        contactList = getContactListResponse?.body?.contacts;
      }

      if (accountancyIntegration.id === "QUICKBOOKS") {
        contactList = getContactListResponse?.QueryResponse?.Customer;
      }

      setContactList(contactList);
      setIsFetchingContacts(false);
    }

    getContacts();
  }, []); // eslint-disable-line

  async function onSubmit(formFields) {
    setIsLoading(true);
    setError();

    if (accountancyIntegration.id === "XERO") {
      try {
        await sendInvoiceToXero({ name: formFields.accountancyContact });

        onClose();
      } catch (e) {
        setError(e.message);
        setIsLoading(false);
      }
    }

    if (accountancyIntegration.id === "QUICKBOOKS") {
      try {
        await sendInvoiceToQuickbooks({ Id: formFields.accountancyContact });

        onClose();
      } catch (e) {
        setError(e.message);
        setIsLoading(false);
      }
    }
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  return (
    <Modal
      title="Choose existing accountancy contact"
      visible={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="select-existing-contact-modal"
    >
      {error ? (
        <>
          <Alert message={error} type="error" showIcon />
          <br />
        </>
      ) : null}
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item label="Accountancy contact" name="accountancyContact">
          <Select
            notFoundContent={isFetchingContacts ? <Spin size="small" /> : null}
            showSearch
            filterOption={(input, option) => (option?.value || "").toLowerCase().includes(input.toLowerCase())}
          >
            {displayContactOptions()}
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
        {error ? <Alert message={error} type="error" showIcon /> : null}
      </Form>
    </Modal>
  );
}
