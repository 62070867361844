import { Tag, Modal, Menu, Dropdown, Button, Typography, message, notification } from "antd";
import moment from "moment";
import uniqid from "uniqid";
import {
  LoadingOutlined,
  CheckCircleOutlined,
  CommentOutlined,
  CloseCircleOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";

import { callGraphQL, checkIfS3FileHasChangedSince } from "common/helpers";
import { callRest, callGraphQLSimple } from "common/apiHelpers";
import { REVIEW_STATUS_READABLE, REVIEW_SECONDARY_STATUS_READABLE } from "common/constants";
import { recordActivityItem } from "common/invoiceHelpers/invoiceHelpers";
import { doAmountsMatch } from "common/invoiceHelpers/sharedInvoiceHelpers";
import {
  sendInvoiceReviewRequestNotification,
  sendInvoiceReviewResultNotification,
  sendNotificationToInvoiceReviewer,
} from "common/notificationHelpers";

import Card from "Card/Card";
import Avatar from "Avatar/Avatar";
import UsersFilter from "UsersFilter/UsersFilter";

import "./InvoiceReviewSummary.scss";

export default function InvoiceReviewSummary({
  invoice,
  getInvoiceForDisplay,
  apiUser,
  users,
  clients,
  potentialReviewers,
  usesDoubleInvoiceReview,
  userIsDoubleReviewer,
  onSubmitReviewEnd,
  reviewFullyApprovedAt,
  recalculateInvoiceAmountsViaApi,
  dateTimeToCheckFormAgainst,
}) {
  let review = invoice.reviews.items[0];
  const reviewStatus = invoice.reviewStatus;
  const userIsAuthor = invoice.assignedTo === apiUser.id;
  const userIsReviewer = review && invoice.checkedBy === apiUser.id;
  const usersForDropdown = [...potentialReviewers];

  if (invoice.checkedBy && !usersForDropdown.some((user) => user.id === invoice.checkedBy)) {
    usersForDropdown.unshift(users.find((user) => user.id === invoice.checkedBy));
  }

  async function approveReview() {
    let reviewerInvoiceReviewLimit = apiUser.invoiceReviewLimit;
    let reviewerInvoiceCreationLimit = apiUser.invoiceCreationLimit;

    let authorityLevelToUse = invoice.author === apiUser.id ? reviewerInvoiceCreationLimit : reviewerInvoiceReviewLimit;

    let exceedsWording = `exceeds the review authority of the reviewer`;

    if (invoice.author === apiUser.id) {
      exceedsWording = `exceeds your self-review authority`;
    }

    if (!authorityLevelToUse || authorityLevelToUse < invoice.total) {
      Modal.info({
        title: "Insufficient review authority",
        maskClosable: true,
        content: (
          <>
            The total value of this invoice <b>({window.formatCurrency("GBP", invoice.total)})</b> {exceedsWording}{" "}
            <b>({window.formatCurrency("GBP", authorityLevelToUse || 0)})</b>.
          </>
        ),
      });
      return;
    }
    submitReview("SUCCESS");
  }
  async function notifyReviewer(reviewSecondaryStatus) {
    await callGraphQLSimple({
      message: "Failed to record review status",
      queryName: "updateInvoice",
      variables: {
        input: {
          id: invoice.id,
          reviewSecondaryStatus,
        },
      },
    });

    recordActivityItem({
      invoice: { ...invoice, reviewStatus, reviewSecondaryStatus },
      type: "REVIEW_ACTIVITY",
      content: `Reviewer notified: ${REVIEW_SECONDARY_STATUS_READABLE[reviewSecondaryStatus]}`,
      author: apiUser.id,
      clients,
      users,
    });

    sendNotificationToInvoiceReviewer({
      users,
      apiUser,
      reviewer: invoice.checkedBy,
      invoiceId: invoice.id,
      clientName: invoice.client?.name,
      projectTitle: invoice.project?.title,
      reviewSecondaryStatusReadable: REVIEW_SECONDARY_STATUS_READABLE[reviewSecondaryStatus],
    });
  }

  async function confirmCancelApproval() {
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm cancel approval",
          maskClosable: true,
          content: <>Are you sure you want to cancel the approval for this review?</>,
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }
    submitReview("IN_PROGRESS");
  }

  async function reopenReview() {
    submitReview("IN_PROGRESS");
  }

  async function closeReview() {
    submitReview("CLOSED");
  }

  async function submitReview(reviewStatus) {
    const messageKey = "submit-invoice-review";

    message.loading({
      content: "Checking for a newer version...",
      key: messageKey,
      duration: 0,
    });

    if (!window.Cypress) {
      try {
        if (
          await checkIfS3FileHasChangedSince({
            key: invoice.fileKey,
            dateTimeToCompareAgainst: dateTimeToCheckFormAgainst,
          })
        ) {
          message.error({
            content: "This version of the invoice is out of date, please refresh and try again.",
            key: messageKey,
            duration: 5,
          });
          return;
        }
      } catch (e) {
        message.error({
          content: "Failed to check for a newer version of the invoice, please refresh and try again.",
          key: messageKey,
        });
        return;
      }
    }

    message.destroy(messageKey);

    const loadingModal = Modal.info({
      title: "Updating review status",
      maskClosable: false,
      closable: false,
      icon: <LoadingOutlined />,
      footer: null,
      className: "modal-no-buttons",
      content: (
        <>
          <Typography.Text style={{ display: "block", marginBottom: "1rem" }}>
            Do not leave this page while we submit your review.
            <br /> We are generating a new PDF. This may take a few seconds.
          </Typography.Text>
        </>
      ),
    });

    try {
      await new Promise((resolve) => setTimeout(resolve, 200));
      await recalculateInvoiceAmountsViaApi({ displayMessage: false });
      await new Promise((resolve) => setTimeout(resolve, 200));
    } catch (e) {
      message.error("Failed to recalculate invoice amounts, aborting review submission");
      onSubmitReviewEnd();
      return;
    }

    if (reviewStatus === "SUCCESS") {
      if (!(await amountsMatchAndCanContinue())) {
        await callGraphQLSimple({
          message: "Failed to update review status",
          queryName: "updateInvoice",
          variables: {
            input: {
              id: invoice.id,
              isUnderReview: true,
              reviewSecondaryStatus: null,
              reviewStatus: "IN_PROGRESS",
              reviewApprovedAt: null,
              secondReviewApprovedAt: null,
            },
          },
        });
        loadingModal.destroy();
        onSubmitReviewEnd();
        return;
      }
    }

    let updateInvoiceInput = {
      id: invoice.id,
      isUnderReview: reviewStatus !== "SUCCESS" && reviewStatus !== "CLOSED",
      reviewSecondaryStatus: null,
      reviewStatus: reviewStatus,
    };

    if (!userIsDoubleReviewer) {
      if (reviewStatus === "SUCCESS") {
        updateInvoiceInput.reviewApprovedAt = moment().toISOString();
      } else {
        updateInvoiceInput.reviewApprovedAt = null;
        updateInvoiceInput.secondReviewApprovedAt = null;
      }
    } else {
      if (reviewStatus === "SUCCESS") {
        if (invoice.reviewStatus !== "SUCCESS") {
          updateInvoiceInput.reviewApprovedAt = moment().toISOString();
        }
        updateInvoiceInput.secondReviewApprovedAt = moment().toISOString();
      } else {
        updateInvoiceInput.reviewApprovedAt = null;
        updateInvoiceInput.secondReviewApprovedAt = null;
      }
    }

    await callGraphQLSimple({
      message: "Failed to update review status",
      queryName: "updateInvoice",
      variables: {
        input: updateInvoiceInput,
      },
    });

    await callGraphQLSimple({
      message: "Failed to submit review",
      queryName: "updateReview",
      variables: {
        input: {
          id: review.id,
          reviewThread: [
            ...review.reviewThread,
            {
              id: uniqid(),
              type: "STATUS_CHANGE",
              createdAt: new Date().toISOString(),
              content: reviewStatus,
              author: apiUser.id,
            },
          ],
        },
      },
    });

    let type = "REVIEW_REJECTED";
    if (reviewStatus === "SUCCESS") {
      type = "REVIEW_ACCEPTED";
    } else if (reviewStatus === "CLOSED") {
      type = "REVIEW_CLOSED";
    } else if (reviewStatus === "IN_PROGRESS") {
      type = "REVIEW_OPENED";

      // if (!userIsReviewer) {
      sendInvoiceReviewRequestNotification({
        users,
        apiUser,
        reviewer: invoice.checkedBy,
        invoiceId: invoice.id,
        clientName: invoice.client?.name,
        projectTitle: invoice.project?.title,
      });
      // }
    }

    sendInvoiceReviewResultNotification({
      users,
      apiUser,
      invoiceId: invoice.id,
      clientName: invoice.client?.name,
      projectTitle: invoice.project?.title,
      reviewStatusReadable: REVIEW_STATUS_READABLE[reviewStatus].label,
      invoiceAssignedTo: invoice.assignedTo,
    });

    recordActivityItem({
      invoice: { ...invoice, reviewStatus },
      type,
      author: apiUser.id,
      clients,
      users,
    });

    if (reviewStatus === "SUCCESS") {
      try {
        await callRest({
          route: "/build-document",
          method: "POST",
          body: {
            organisationId: invoice.organisation,
            invoiceId: invoice.id,
            projectId: invoice.projectId,
            clientId: invoice.clientId,
          },
          includeCredentials: false,
        });
        loadingModal.destroy();
      } catch (e) {
        loadingModal.destroy();
        message.destroy(messageKey);
      }
    } else {
      message.success({
        content: "Review status updated",
        key: messageKey,
      });
      loadingModal.destroy();
    }
    onSubmitReviewEnd();
  }

  async function amountsMatchAndCanContinue() {
    const upToDateInvoice = (
      await callGraphQLSimple({
        message: "Failed to get up to date invoice",
        queryCustom: "getInvoice",
        variables: { id: invoice.id },
      })
    ).data.getInvoice;

    const invoiceForDisplay = getInvoiceForDisplay({ invoice: upToDateInvoice });

    try {
      doAmountsMatch({ invoiceForDisplay });
      return true;
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            <b>There is a problem with this invoice.</b>
            <br /> {e.message}
            <br />
            <br />
            We have put the invoice back in review.
            <br />
            <br />
            <u onClick={() => window.location.reload()} style={{ cursor: "pointer" }}>
              Click here to refresh the page and trigger a recalculation.
            </u>
          </Typography.Text>
        ),
        duration: 0,
      });
      return false;
    }
  }

  function displayReviewerOptions() {
    return (
      <Menu className="review-options-menu">
        <Menu.Item
          key="1"
          className="request-changes"
          onClick={() => submitReview("CHANGES_REQUESTED")}
          data-cy="request-changes-button"
        >
          <CloseCircleOutlined /> Request changes
        </Menu.Item>

        <Menu.Item
          key="0"
          className="with-comments"
          onClick={() => submitReview("WITH_COMMENTS")}
          data-cy="with-comments-button"
        >
          <CommentOutlined /> With comments
        </Menu.Item>
        <Menu.Item key="3" className="approve" onClick={approveReview} data-cy="approve-review-button">
          <CheckCircleOutlined /> Approve
        </Menu.Item>
      </Menu>
    );
  }

  function displayAuthorOptions() {
    return (
      <Menu className="review-options-menu">
        <Menu.Item key="0" onClick={() => notifyReviewer("COMMENTS_ADDRESSED")}>
          <CheckCircleOutlined /> Comments addressed
        </Menu.Item>

        <Menu.Item key="1" onClick={() => notifyReviewer("INFO_REQUIRED")}>
          <CommentOutlined /> Information required
        </Menu.Item>
      </Menu>
    );
  }

  async function changeReviewer(newReviewer) {
    sendInvoiceReviewRequestNotification({
      users,
      apiUser,
      reviewer: newReviewer,
      invoiceId: invoice.id,
      clientName: invoice.client?.name,
      projectTitle: invoice.project?.title,
    });

    await callGraphQLSimple({
      message: "Failed to request review",
      queryName: "updateInvoice",
      variables: {
        input: {
          id: invoice.id,
          checkedBy: newReviewer,
        },
      },
    });
  }

  function isSubmitReviewButtonVisible() {
    if (
      reviewStatus === "CLOSED" ||
      (!userIsDoubleReviewer && (!userIsReviewer || invoice.reviewApprovedAt)) ||
      invoice.secondReviewApprovedAt
    ) {
      return false;
    }

    return true;
  }

  let reviewStatusLabel = REVIEW_STATUS_READABLE[invoice.reviewStatus]?.label;

  if (invoice.reviewStatus === "SUCCESS" && usesDoubleInvoiceReview) {
    if (invoice.secondReviewApprovedAt) {
      reviewStatusLabel = `Second stage - ${reviewStatusLabel}`;
    } else {
      reviewStatusLabel = `First stage - ${reviewStatusLabel}`;
    }
  }

  return (
    <Card
      className="invoice-review-summary"
      withSpace
      title={<Typography.Text data-cy="invoice-review-summary-title">Invoice review summary</Typography.Text>}
      actions={
        <div className="actions">
          <div className="section">
            {reviewStatus === "CLOSED" ? (
              <Button className="reopen-review" onClick={reopenReview} type="primary" data-cy="reopen-review-button">
                Reopen review
              </Button>
            ) : reviewStatus !== "SUCCESS" ? (
              <Button className="close-review" onClick={closeReview} data-cy="cancel-review-button">
                Cancel review
              </Button>
            ) : null}

            {reviewStatus === "SUCCESS" && (
              <Button type="primary" onClick={confirmCancelApproval} data-cy="cancel-approval-button">
                <span>Cancel approval</span>
              </Button>
            )}

            {isSubmitReviewButtonVisible() && (
              <Dropdown
                overlay={displayReviewerOptions()}
                overlayClassName="review-revision-dropdown"
                trigger={["click"]}
              >
                <Button type="primary" data-cy="submit-review-button">
                  <CaretDownOutlined />
                  <span>Submit review</span>
                </Button>
              </Dropdown>
            )}

            {reviewStatus !== "CLOSED" && reviewStatus !== "SUCCESS" && userIsAuthor ? (
              <Dropdown
                overlay={displayAuthorOptions()}
                overlayClassName="review-revision-dropdown"
                trigger={["click"]}
              >
                <Button type="primary">
                  <CaretDownOutlined />
                  <span>Notify reviewer</span>
                </Button>
              </Dropdown>
            ) : null}
          </div>
        </div>
      }
    >
      <div className="content">
        <div className="review-metadata-item">
          <Typography.Text className="item-label">Reviewer</Typography.Text>
          {!invoice.isFinished && !invoice.isArchived && !invoice.reviewApprovedAt ? (
            <UsersFilter
              className="reviewer-picker"
              value={invoice.checkedBy}
              onChange={changeReviewer}
              orderedActiveUsers={usersForDropdown}
              includeUnassigned={false}
            />
          ) : (
            <Avatar user={users.find((x) => x.id === invoice.checkedBy)} showLabel={true} />
          )}
        </div>
        <div className="review-metadata-item">
          <Typography.Text className="item-label">Review status</Typography.Text>
          <div className="item-value">
            {REVIEW_STATUS_READABLE[invoice.reviewStatus] && (
              <Tag color={REVIEW_STATUS_READABLE[invoice.reviewStatus].color} data-cy="review-status-tag">
                {reviewStatusLabel}
              </Tag>
            )}
            {invoice.reviewSecondaryStatus && (
              <Tag
                color={invoice.reviewSecondaryStatus === "INFO_REQUIRED" ? "orange" : "green"}
                data-cy="review-secondary-status-tag"
              >
                {REVIEW_SECONDARY_STATUS_READABLE[invoice.reviewSecondaryStatus]}
              </Tag>
            )}
          </div>
        </div>
        <div className="review-metadata-item">
          <Typography.Text className="item-label">Review started</Typography.Text>
          <Typography.Text className="item-value">
            {moment(review.createdAt).format("DD MMM YYYY - HH:mm:ss")}
          </Typography.Text>
        </div>
        {reviewFullyApprovedAt && (
          <div className="review-metadata-item">
            <Typography.Text className="item-label">Review approved</Typography.Text>
            <Typography.Text className="item-value">
              {moment(reviewFullyApprovedAt).format("DD MMM YYYY - HH:mm:ss")}
            </Typography.Text>
          </div>
        )}
      </div>
    </Card>
  );
}
