import axios from "axios";
import { Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { callRest } from "common/apiHelpers";
import { downloadBlob } from "common/helpers";
import getS3File from "common/getS3File";

export default function ActivityItemPDFButton({ parent, activityItem }) {
  const fetchLastPdf = async () => {
    let fileName = parent.fileKey.split("/").pop().split(".json").join("_raw.pdf");

    let pdfKey = parent.fileKey.split(".json").join("_raw.pdf");
    let encodedPdfKey = btoa(pdfKey);
    try {
      const response = await callRest({
        method: "GET",
        route: `/s3-list-versions?prefix=${encodedPdfKey}`,
        includeCredentials: false,
      });

      const activityCreatedAt = activityItem.createdAt;
      let selectedVersion = null;
      let versions = response.Versions;
      for (let i = response.Versions.length - 1; i >= 0; i--) {
        const versionDate = versions[i].LastModified;
        if (versionDate > activityCreatedAt) {
          selectedVersion = versions[i];
          break;
        }
      }

      if (!selectedVersion) {
        message.error(`No valid PDF version found`);
      } else {
        let publicUrl = await getS3File(pdfKey, selectedVersion.VersionId);
        const fileBlob = (
          await axios({
            url: publicUrl,
            method: "GET",
            responseType: "blob", // Important
          })
        ).data;

        await downloadBlob({
          blob: fileBlob,
          fileName,
        });
      }
    } catch (error) {
      console.error("error", error);
      message.error(`No valid PDF version found`);
    }
  };
  return (
    <Button
      onClick={async (e) => {
        e.preventDefault();
        await fetchLastPdf();
      }}
    >
      <DownloadOutlined />
    </Button>
  );
}
