import { Component } from "react";
import { Select } from "antd";
import cx from "classnames";

import withSubscriptions from "common/withSubscriptions";
import { isAuthorised } from "common/permissions";

import Avatar from "Avatar/Avatar";
import FormTextButton from "reusableComponents/FormTextButton/FormTextButton";

import "./UsersFilter.scss";
/**
 * excludeList: [String] - represents a list of user IDs (email addresses) which should not be shown in the list of options
 **/
export class UsersFilter extends Component {
  compare = (expected, actual) => {
    return expected.toLowerCase().split(" ").join("").includes(actual.toLowerCase().split(" ").join(""));
  };

  filterOption = (input, option) => {
    const { orderedActiveUsers } = this.props;

    if (option.value === null) {
      return input === option.value;
    }
    const emailMatches = this.compare(option.value, input);
    if (emailMatches) {
      return true;
    }
    const emailWithoutSymbolMatches = this.compare(option.value.split("@").join(""), input);
    if (emailWithoutSymbolMatches) {
      return true;
    }
    const targetUser = orderedActiveUsers.find((x) => x.id === option.value);
    const nameMatches = this.compare(targetUser.firstName + targetUser.lastName, input);
    if (nameMatches) {
      return true;
    }
    const reverseNameMatches = this.compare(targetUser.lastName + targetUser.firstName, input);
    return reverseNameMatches;
  };

  render() {
    const {
      excludeList,
      value,
      placeholder,
      includeUnassigned = true,
      suffixIcon,
      maxLabelLength,
      apiUser,
      showAssignToMe = false,
      orderedActiveUsers,
      disabled,
    } = this.props;

    let filteredUsers = orderedActiveUsers;

    if (excludeList) {
      filteredUsers = filteredUsers.filter((x) => !excludeList.includes(x.id));
    }

    filteredUsers = filteredUsers.filter((user) => {
      if (isAuthorised(["WITHOUT_DENY.EXCLUDE_FROM_FILTERS"], user, true)) {
        return false;
      }
      return true;
    });

    const extraProps = {
      value,
    };

    if (this.props["data-cy"]) {
      extraProps["data-cy"] = this.props["data-cy"];
    }

    if (placeholder) {
      extraProps.placeholder = placeholder;
    } else {
      extraProps.placeholder = "Select user";
    }

    if (suffixIcon !== undefined) {
      extraProps.suffixIcon = suffixIcon;
    }

    return (
      <div className="users-filter-container">
        <Select
          showSearch
          className={cx("users-filter", this.props.className, {
            "active-on-hover": this.props.activateOnHover,
          })}
          filterOption={this.filterOption}
          onChange={this.props.onChange}
          popupClassName={`${this.props["data-cy"]}-popup`}
          disabled={disabled}
          {...extraProps}
        >
          {includeUnassigned ? (
            <Select.Option value={null} key="users-option-unassigned" className="option-user-unassigned">
              <div className="avatar-container">
                <Avatar showLabel={true} maxLabelLength={maxLabelLength} />
              </div>
            </Select.Option>
          ) : null}
          {filteredUsers
            .filter((x) => x)
            .map((x) => (
              <Select.Option
                value={x.id}
                key={`users-option-${x.id}`}
                className={cx("users-filter-option", `option-user-${x.id.split("@")[0]}`)}
              >
                <div className="avatar-container">
                  <Avatar user={x} showLabel={true} disableUserCard maxLabelLength={maxLabelLength} />
                </div>
              </Select.Option>
            ))}
        </Select>
        {showAssignToMe && (
          <FormTextButton className="assign-to-me" onClick={() => this.props.onChange(apiUser.id)}>
            Assign to me
          </FormTextButton>
        )}
      </div>
    );
  }
}

export default withSubscriptions({
  Component: UsersFilter,
  subscriptions: [],
});
