const fields = [
  {
    id: "purchaseOrderLineItemTitle",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Title",
    value: ({ purchaseOrderLineItem }) => purchaseOrderLineItem?.title || "",
  },
  {
    id: "purchaseOrderLineItemTitleAndDescription",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Title and description",
    value: ({ purchaseOrderLineItem }) => {
      let result = purchaseOrderLineItem?.title || "";
      if (purchaseOrderLineItem?.description) {
        result = result ? `${result}\n${purchaseOrderLineItem.description}` : purchaseOrderLineItem.description;
      }
      return result;
    },
  },
  {
    id: "purchaseOrderLineItemDescription",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Description",
    value: ({ purchaseOrderLineItem }) => purchaseOrderLineItem?.description || "",
  },
  {
    id: "purchaseOrderLineItemSupplierQuoteId",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Supplier quote number",
    value: ({ purchaseOrderLineItem }) => purchaseOrderLineItem?.supplierQuoteId || "",
  },
  {
    id: "purchaseOrderLineItemQuantity",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Quantity",
    value: ({ purchaseOrderLineItem }) => purchaseOrderLineItem?.quantity || "",
  },
  {
    id: "purchaseOrderLineItemTaxRate",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Tax rate",
    value: ({ purchaseOrderLineItem }) => purchaseOrderLineItem?.taxRate || "",
  },
  {
    id: "purchaseOrderLineUnitPrice",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Unit price",
    value: ({ purchaseOrderLineItem }) => {
      if (
        !purchaseOrderLineItem ||
        purchaseOrderLineItem.unitPrice === null ||
        purchaseOrderLineItem.unitPrice === undefined
      ) {
        return "";
      }

      return global.formatCurrency("GBP", purchaseOrderLineItem.unitPrice);
    },
  },
  {
    id: "purchaseOrderLineItemAmount",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Amount",
    value: ({ purchaseOrderLineItem }) => {
      if (
        !purchaseOrderLineItem ||
        purchaseOrderLineItem.amount === null ||
        purchaseOrderLineItem.amount === undefined
      ) {
        return "";
      }

      return global.formatCurrency("GBP", purchaseOrderLineItem.amount);
    },
  },
  {
    id: "purchaseOrderLineItemAmountTax",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Tax amount",
    value: ({ purchaseOrderLineItem }) => {
      if (
        !purchaseOrderLineItem ||
        purchaseOrderLineItem.amountTax === null ||
        purchaseOrderLineItem.amountTax === undefined
      ) {
        return "";
      }

      return global.formatCurrency("GBP", purchaseOrderLineItem.amountTax);
    },
  },
];
export function getFields() {
  return fields;
}
