import OrganisationActions from "./OrganisationActions/OrganisationActions";
import MainSettings from "./MainSettings/MainSettings";

export default function GeneralSettingsPage(props) {
  const { organisationDetails } = props;

  return (
    <div>
      <MainSettings organisationDetails={organisationDetails} />
      <OrganisationActions organisationDetails={organisationDetails} />
    </div>
  );
}
