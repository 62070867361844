import React from "react";
import { Button, Dropdown, Menu, Modal, Typography } from "antd";
import {
  CheckCircleOutlined,
  CaretDownOutlined,
  CloseCircleOutlined,
  CommentOutlined,
  LoadingOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";

const DESKTOP_MENU_ACTIONS_KEYS = {
  COMMENTS_ADDRESSED: "COMMENTS_ADDRESSED",
  INFO_REQUIRED: "INFO_REQUIRED",
  REQUEST_CHANGES: "REQUEST_CHANGES",
  WITH_COMMENTS: "WITH_COMMENTS",
  APPROVE: "APPROVE",
};

export default function ReviewDesktopActions({
  taskRevision,
  reviewStatus,
  taskRevisionIsLatest,
  userIsReviewer,
  userIsAuthor,
  submitReview,
  approveReview,
  closeReview,
  notifyReviewer,
  confirmCancelApproval,
  reopenReview,
  containerThis,
}) {
  let notifyReviewerItems = [];
  notifyReviewerItems.push(
    {
      key: DESKTOP_MENU_ACTIONS_KEYS.COMMENTS_ADDRESSED,
      label: (
        <Typography.Text className="review-options-menu">
          <CheckCircleOutlined />
          Comments addressed
        </Typography.Text>
      ),
    },
    {
      key: DESKTOP_MENU_ACTIONS_KEYS.INFO_REQUIRED,
      label: (
        <Typography.Text className="review-options-menu">
          <CommentOutlined />
          Information required
        </Typography.Text>
      ),
    }
  );

  let reviewerOptionsItems = [];
  reviewerOptionsItems.push(
    {
      key: DESKTOP_MENU_ACTIONS_KEYS.REQUEST_CHANGES,
      label: (
        <Typography.Text className="request-changes">
          <CloseCircleOutlined /> Request changes
        </Typography.Text>
      ),
    },
    {
      key: DESKTOP_MENU_ACTIONS_KEYS.WITH_COMMENTS,
      label: (
        <Typography.Text className="with-comments">
          <CommentOutlined /> With comments
        </Typography.Text>
      ),
    },
    {
      key: DESKTOP_MENU_ACTIONS_KEYS.APPROVE,
      label: (
        <Typography.Text className="approve">
          <CheckCircleOutlined /> Approve
        </Typography.Text>
      ),
    }
  );

  function notifyReviewerOnClick({ key }) {
    // debugger;
    switch (key) {
      case DESKTOP_MENU_ACTIONS_KEYS.COMMENTS_ADDRESSED:
        notifyReviewer.call(containerThis, "COMMENTS_ADDRESSED");
        break;
      case DESKTOP_MENU_ACTIONS_KEYS.INFO_REQUIRED:
        notifyReviewer.call(containerThis, "INFO_REQUIRED");
        break;
      default:
        break;
    }
  }
  function reviewerOptionsOnClick({ key }) {
    switch (key) {
      case DESKTOP_MENU_ACTIONS_KEYS.REQUEST_CHANGES:
        submitReview.call(containerThis, "CHANGES_REQUESTED");
        break;
      case DESKTOP_MENU_ACTIONS_KEYS.WITH_COMMENTS:
        submitReview.call(containerThis, "WITH_COMMENTS");
        break;
      case DESKTOP_MENU_ACTIONS_KEYS.APPROVE:
        approveReview();
        break;
      default:
        break;
    }
  }

  return (
    <>
      {reviewStatus === "CLOSED" ? (
        <Button disabled={taskRevision.isReadOnly} className="reopen-review" onClick={reopenReview} type="primary">
          Reopen review
        </Button>
      ) : (
        <Button
          disabled={reviewStatus === "SUCCESS" || taskRevision.isReadOnly}
          className="close-review"
          onClick={closeReview}
        >
          Cancel review
        </Button>
      )}

      {reviewStatus === "SUCCESS" && taskRevisionIsLatest && (
        <Button type="primary" onClick={confirmCancelApproval}>
          <span>Cancel approval</span>
        </Button>
      )}

      {reviewStatus !== "CLOSED" && userIsReviewer && reviewStatus !== "SUCCESS" && (
        <Dropdown
          menu={{ items: reviewerOptionsItems, onClick: reviewerOptionsOnClick }}
          overlayClassName="review-revision-dropdown"
          trigger={["click"]}
        >
          <Button type="primary">
            <CaretDownOutlined />
            <span>Submit review</span>
          </Button>
        </Dropdown>
      )}

      {reviewStatus !== "CLOSED" && userIsAuthor ? (
        <Dropdown
          disabled={reviewStatus === "SUCCESS"}
          menu={{ items: notifyReviewerItems, onClick: notifyReviewerOnClick }}
          overlayClassName="review-revision-dropdown"
          trigger={["click"]}
        >
          <Button type="primary">
            <CaretDownOutlined />
            <span>Notify reviewer</span>
          </Button>
        </Dropdown>
      ) : null}
    </>
  );
}
