import { useEffect } from "react";
import moment from "moment";
import { Spin, Calendar } from "antd";
import { withRouter, useHistory } from "react-router-dom";
import { useGetSetState } from "react-use";
import cx from "classnames";
import day from "dayjs";

import { taskIdToColor, add1DPointsToBlock, dateAndHoursToValue1D } from "TimelinePage/timelineHelpers";

import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";
import withSubscriptions from "common/withSubscriptions";
import getEndDateForTimelineBlock from "common/getEndDateForTimelineBlock";

import TaskDetailsModal from "Modals/TaskDetailsModal/TaskDetailsModal";
import UsersFilter from "UsersFilter/UsersFilter";
import Card from "Card/Card";

import "./CalendarPage.scss";
import { getSimpleLabel } from "common/labels";

export function CalendarPage({ users, organisationDetails, tasks, projects, clients, match }) {
  const [getState, setState] = useGetSetState({
    timelineBlocks: undefined,
    selectedTaskId: undefined,
  });

  const history = useHistory();
  const userDetails = users.find((u) => u.id === match.params.userId);

  let startDate = match.params.startDate;
  let userId = match.params.userId;

  useEffect(() => {
    fetchTimelineBlocks();
  }, [startDate]); // eslint-disable-line

  async function fetchTimelineBlocks() {
    const timelineBlocks = (
      await window.callGraphQLSimple({
        message: "Failed to fetch planned activity",
        query: "listTimelineBlocksByUserAndDate",
        variables: {
          limit: 1000,
          userId,
          startDate: {
            between: [
              moment(startDate).subtract(1, "week").toISOString(),
              moment(startDate).add(1, "month").toISOString(),
            ],
          },
        },
      })
    ).data.listTimelineBlocksByUserAndDate.items;

    let timelineBlocksWithEndDates = timelineBlocks.map((block) => ({
      ...block,
      endDate: getEndDateForTimelineBlock({ timelineBlock: block }),
    }));

    setState({
      timelineBlocks: timelineBlocksWithEndDates,
    });
  }

  function onPanelChange(value, mode) {
    const valueFormatted = value.format("YYYY-MM-DD");
    history.push(`/user-calendar/${userId}/${valueFormatted}`);
  }

  function displayDateCell(date) {
    const { timelineBlocks } = getState();
    const cellDate = date.format("YYYY-MM-DD");

    const blocksOnThisDate = timelineBlocks.filter((block) => {
      const blockStartDate = moment(block.startDate).format("YYYY-MM-DD");
      const blockEndDate = block.endDate ? moment(block.endDate).format("YYYY-MM-DD") : blockStartDate;
      return cellDate >= blockStartDate && cellDate <= blockEndDate;
    });

    let axisStartDate = moment(startDate);

    return (
      <div className="date-cell">
        {blocksOnThisDate.map((block, index) => {
          const task = tasks.find((t) => t.id === block.taskId);
          const project = projects.find((p) => p.id === task?.projectId);
          let customClassNames = [];
          let dueDate = task?.dueDate;
          let blockIsOverdue = false;
          let blockIsDueOnTheDay = false;
          let color;

          if (dueDate) {
            let dueDate1D = dateAndHoursToValue1D({
              date: dueDate,
              hours: TIMELINE_DEFAULT_HOURS_IN_A_DAY,
              axisStartDate,
            });

            const blockBounds = add1DPointsToBlock({ block, axisStartDate });
            blockIsOverdue = blockBounds.end1D > dueDate1D;
            if (blockBounds.endHours === 0) {
              blockBounds.endDate.subtract(1, "day");
            }
            if (moment(dueDate).isSame(blockBounds.endDate, "day")) {
              blockIsDueOnTheDay = true;
            }
          }

          if (block.userChosenColor && block.userChosenColor !== "undefined" && block.userChosenColor !== "null") {
            color = block.userChosenColor;
          } else {
            if (organisationDetails.settings?.timeline?.usesColoredBlocks) {
              customClassNames.push("default");

              if (task?.isUnderReview) {
                customClassNames.push("under-review");
              }
              if (blockIsOverdue) {
                customClassNames.push("overdue");
              }
              if (blockIsDueOnTheDay) {
                customClassNames.push("due-on-the-day");
              }
              if (task?.isArchived) {
                customClassNames.push("archived");
              }
              if (task?.isFinished) {
                customClassNames.push("finished");
              }
            } else {
              color = taskIdToColor(block.taskId);
            }
            if (!task && !block.isPseudoTask) {
              color = "#ddd";
            }
          }

          return (
            <div
              className={cx("calendar-block", customClassNames, {
                clickable: !!task,
                unconfirmed: task && !task.isConfirmed && organisationDetails.settings?.general?.usesTaskConfirmation,
                "pseudo-task":
                  block.isPseudoTask &&
                  (!block.userChosenColor || block.userChosenColor === "undefined" || block.userChosenColor === "null"),

                new: moment(block.createdAt).isAfter(moment().subtract(10, "seconds")),
              })}
              key={index}
              onClick={() => {
                if (task) {
                  setState({ selectedTaskId: task.id });
                }
              }}
            >
              <div
                className="background"
                style={{
                  backgroundColor: customClassNames.length > 0 ? undefined : color,
                }}
              />
              <div className="block-info">
                <span className="task-id">{block.taskId}</span>
                {project && <span className="project-title">{project.title}</span>}
                {task && <span className="task-title">{task.title}</span>}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  const { timelineBlocks, selectedTaskId } = getState();

  return (
    <div className="calendar-page">
      <Card
        title={
          <div className="calendar-card-title">
            {getSimpleLabel("Timeline")} for{" "}
            <UsersFilter
              activateOnHover={false}
              showAssignToMe={false}
              onChange={(newUserId) => {
                history.push(`/user-calendar/${newUserId}/${startDate}`);
              }}
              includeUnassigned={false}
              value={userId}
            />
          </div>
        }
      >
        {!timelineBlocks ? (
          <div className="preloader-container">
            <Spin />
          </div>
        ) : (
          <Calendar
            dateCellRender={displayDateCell}
            mode="month"
            onPanelChange={onPanelChange}
            value={day(startDate)}
          />
        )}
      </Card>
      {selectedTaskId && (
        <TaskDetailsModal
          taskId={selectedTaskId}
          onClose={() => {
            setState({ selectedTaskId: undefined });
          }}
        />
      )}
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: CalendarPage,
    subscriptions: ["users", "tasks", "projects", "clients", "organisationDetails"],
  })
);
