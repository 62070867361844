import { Component } from "react";
import { Select, Typography } from "antd";
import cx from "classnames";
import _ from "lodash";

import { getSimpleLabel } from "common/labels";
import withSubscriptions from "common/withSubscriptions";

import TaskIdTag from "TaskIdTag/TaskIdTag";
import DashboardItemTags from "DashboardItemTags/DashboardItemTags";

import "./TaskPicker.scss";
/**
 * excludeList: [String] - represents a list of user IDs (email addresses) which should not be shown in the list of options
 **/

const MAX_RESULTS_TO_SHOW = 25;
export class TaskPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasksWithMetadata: [],
      filteredTasks: [],
      searchValue: "",
    };

    this.debouncedFilterTasks = _.debounce(this.filterTasks, 300);
  }

  componentDidMount() {
    this.computeTasksWithMetadata();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.tasks !== this.props.tasks ||
      prevProps.projects !== this.props.projects ||
      prevProps.clients !== this.props.clients ||
      prevProps.excludeList !== this.props.excludeList ||
      prevProps.value !== this.props.value
    ) {
      this.computeTasksWithMetadata();
    }
  }

  computeTasksWithMetadata = () => {
    let tasksWithMetadata = this.props.tasks
      .filter((x) => !x.isHidden && !x.id.includes("-TEMPLATES"))
      .map((task) => this.getTaskMetadata(task));

    if (this.props.excludeList) {
      tasksWithMetadata = tasksWithMetadata.filter((x) => !this.props.excludeList.includes(x.id));
    }

    this.setState(
      {
        tasksWithMetadata,
      },
      this.filterTasks
    );
  };

  filterTasks = (value) => {
    if (value === undefined) {
      value = this.state.searchValue;
    } else {
      this.setState({ searchValue: value });
    }

    value = value.trim().toLowerCase();

    const { tasksWithMetadata } = this.state;
    const filteredTasks = tasksWithMetadata.filter((task) => {
      return this.filterOption(value, task);
    });

    this.setState({ filteredTasks });
  };

  getTaskMetadata = (task) => {
    const project = this.props.projects.find((x) => x.id === task.projectId);
    const client = this.props.clients.find((x) => x.id === task.clientId);
    return { ...task, project, client };
  };

  compare = (expected, actual) => {
    return expected.toLowerCase().split(" ").join("").includes(actual.toLowerCase().split(" ").join(""));
  };

  filterOption = (targetText, { project, client, value, ...task }) => {
    if (!targetText) {
      return true;
    }
    try {
      // if (value === null) {
      //   return true;
      // }

      if (client?.name.toLowerCase().includes(targetText)) {
        return true;
      } else if ((project?.title || "").toLowerCase().includes(targetText)) {
        return true;
      } else if (task.title.toLowerCase().includes(targetText)) {
        return true;
      } else if ((task.subtitle || "").toLowerCase().includes(targetText)) {
        return true;
      } else if (task.id.toLowerCase().includes(targetText)) {
        return true;
      } else if ((task.assignedTo || "").toLowerCase().includes(targetText)) {
        return true;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  render() {
    const { value, placeholder, suffixIcon, disabled, allowClear = true, organisationDetails, listHeight } = this.props;

    const { filteredTasks } = this.state;

    const extraProps = {
      value,
    };

    if (this.props["data-cy"]) {
      extraProps["data-cy"] = this.props["data-cy"];
    }

    if (placeholder) {
      extraProps.placeholder = placeholder;
    } else {
      extraProps.placeholder = `Select ${getSimpleLabel("task")}`;
    }

    if (suffixIcon !== undefined) {
      extraProps.suffixIcon = suffixIcon;
    }

    let slicedTasks = filteredTasks.slice(0, MAX_RESULTS_TO_SHOW);

    if (this.props.value && !slicedTasks.find((x) => x.id === this.props.value)) {
      let selectedTask = this.props.tasks.find((x) => x.id === this.props.value);
      if (selectedTask) {
        slicedTasks = [this.getTaskMetadata(selectedTask), ...slicedTasks];
      }
    }

    let options = slicedTasks.map((taskWithMetadata) => {
      let label = null;

      label = (
        <div className="task-picker-option-inner">
          <Typography.Text className="project-title">
            {taskWithMetadata.client?.name} / {taskWithMetadata.project?.title}
          </Typography.Text>
          <Typography.Text className="task-title"> {taskWithMetadata.title}</Typography.Text>
          <div className="tags-container">
            <TaskIdTag task={taskWithMetadata} includeTitle={false} includeLink={false} />
            <DashboardItemTags item={taskWithMetadata} organisationDetails={organisationDetails} />
          </div>
        </div>
      );

      return {
        label,
        selectedLabel: <Typography.Text className="task-title"> {taskWithMetadata.title}</Typography.Text>,
        value: taskWithMetadata.id,
        className: cx("task-picker-option", `option-user-${taskWithMetadata.id.split("@")[0]}`),
      };
    });

    if (filteredTasks.length > MAX_RESULTS_TO_SHOW) {
      options.push({
        label: (
          <b>
            {filteredTasks.length - MAX_RESULTS_TO_SHOW} more result
            {filteredTasks.length - MAX_RESULTS_TO_SHOW === 1 ? "" : "s"} not displayed
          </b>
        ),
        value: undefined,
        className: "task-picker-option",
        disabled: true,
      });
    }

    return (
      <div className="task-picker">
        <Select
          showSearch
          className={cx("task-picker", this.props.className, {
            "active-on-hover": this.props.activateOnHover,
          })}
          filterOption={() => true}
          onChange={this.props.onChange}
          disable={disabled}
          allowClear={allowClear}
          listHeight={listHeight ? listHeight : window.innerHeight > 800 ? 350 : 200}
          {...extraProps}
          virtual={false}
          onSearch={this.debouncedFilterTasks}
          onDropdownVisibleChange={(isOpen) => {
            if (this.props.onDropdownVisibleChange) {
              this.props.onDropdownVisibleChange(isOpen);
            }
          }}
          optionLabelProp="selectedLabel"
          options={options}
        />
      </div>
    );
  }
}

export default withSubscriptions({
  Component: TaskPicker,
  subscriptions: ["tasks", "projects", "clients", "organisationDetails"],
});
