import { Table, Button, Modal, message } from "antd";
import { EditOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useGetSetState } from "react-use";

import { callGraphQLSimple } from "common/apiHelpers";
import { recordContacts } from "common/emailHelpers";
import withSubscriptions from "common/withSubscriptions";

import ClientContactModal from "Modals/ClientContactModal/ClientContactModal";

import "./ContactsPage.scss";

export function ContactsPage({ apiUser, organisationDetails, separateContacts }) {
  const [getState, setState] = useGetSetState({
    selectedContact: null,
    isAddingContact: false,
  });

  async function onModalSubmit(contactDetails) {
    let messageKey = "adding-contact";
    const { isAddingContact, selectedContact } = getState();
    try {
      message.loading({
        content: "Adding contact...",
        key: messageKey,
        duration: 0,
      });

      if (isAddingContact) {
        await recordContacts({
          contacts: [
            {
              ...contactDetails,
              phone: undefined,
            },
          ],
          parentId: apiUser.organisation,
        });
      } else {
        await callGraphQLSimple({
          queryName: "updateSeparateContact",
          variables: {
            input: {
              id: selectedContact.id,
              ...contactDetails,
              phone: undefined,
            },
          },
        });
      }
      setState({
        isAddingContact: false,
        selectedContact: null,
      });
      message.success({
        content: "Contact added",
        key: messageKey,
      });
    } catch (e) {
      message.error({
        content: "Failed to add contact",
        key: messageKey,
      });
    }
  }

  function confirmDeleteContact(contact) {
    Modal.confirm({
      title: "Delete contact",
      content: `Are you sure you want to delete contact ${contact.email}?`,
      onOk: () => {
        callGraphQLSimple({
          queryName: "deleteSeparateContact",
          variables: {
            input: {
              id: contact.id,
            },
          },
        });
      },
    });
  }

  const { isAddingContact, selectedContact } = getState();

  return (
    <>
      <div className="contacts-page">
        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          style={{ marginBottom: "1rem" }}
          onClick={() => {
            setState({ isAddingContact: true });
          }}
        >
          Add contact
        </Button>
        <Table
          pagination={{ hideOnSinglePage: true, pageSize: 500 }}
          columns={[
            {
              title: "Email",
              dataIndex: "email",
            },
            {
              title: "First name",
              dataIndex: "firstName",
            },
            {
              title: "Last name",
              dataIndex: "lastName",
            },
            {
              title: "",
              render: (_, contact) => {
                return (
                  <div className="actions">
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => {
                        setState({ selectedContact: contact });
                      }}
                    />
                    <Button icon={<DeleteOutlined />} onClick={() => confirmDeleteContact(contact)} />
                  </div>
                );
              },
            },
          ]}
          dataSource={separateContacts}
        />
      </div>
      {isAddingContact || selectedContact ? (
        <ClientContactModal
          onClose={() => {
            setState({ isAddingContact: false, selectedContact: null });
          }}
          onSubmit={onModalSubmit}
          contact={selectedContact}
          fieldsToHide={["id", "position", "phone"]}
        />
      ) : null}
    </>
  );
}

export default withSubscriptions({ Component: ContactsPage, subscriptions: ["separateContacts"] });
