import cx from "classnames";
import { Link } from "react-router-dom";
import { Tag } from "antd";
import withSubscriptions from "common/withSubscriptions";
import { getLabel } from "common/helpers";
import { isTaskOverdue, isTaskDueToday, isTaskNew } from "common/helpers";
import { TASK_RELATIONSHIPS, REVIEW_SECONDARY_STATUS_READABLE, REVIEW_STATUS_READABLE } from "common/constants";
import { processIdForDisplay } from "common/helpers";
import { getSimpleLabel } from "common/labels";

import "./DashboardItemTags.scss";

export function DashboardItemTags({
  item,
  isInvoice,
  organisationDetails,
  skipCatLevel,
  skipInstructed,
  skipCustomFields,
  includeLinks,
}) {
  let usesDoubleInvoiceReview = organisationDetails.settings?.invoice?.usesDoubleInvoiceReview;
  let isNew = isTaskNew(item);
  let isOverdue = isTaskOverdue(item);
  let isDueToday = isTaskDueToday(item);
  let isBlocked =
    item.linkedTasks && item.linkedTasks.length && item.linkedTasks.find((x) => x.relationship === "BLOCKED_BY");
  let isFullyInvoiced = item.invoicingStatus === "FULLY_INVOICED";
  let isPartiallyInvoiced = item.invoicingStatus === "PARTIALLY_INVOICED";
  let rejectedLineItems = item.lineItems?.items?.filter((lineItem) => lineItem.isRejected);
  let isPaid = item.total && item.total === item.amountPaid;
  let isPartiallyPaid = item.amountPaid > 0 && item.amountPaid < item.total;

  let customFieldTags = [];

  item.linkedTasks?.forEach((linkedTask) => {
    const relationshipDetails = TASK_RELATIONSHIPS.find((x) => x.value === linkedTask.relationship);

    if (includeLinks) {
      customFieldTags.push(
        <Link to={`/tasks/${linkedTask.taskId}`} key={`${linkedTask.relationship}-${linkedTask.taskId}`}>
          <Tag color={relationshipDetails.color}>
            {relationshipDetails.label}: {processIdForDisplay(linkedTask.taskId)}
          </Tag>
        </Link>
      );
    } else {
      customFieldTags.push(
        <Tag key={`${linkedTask.relationship}-${linkedTask.taskId}`} color={relationshipDetails.color}>
          {relationshipDetails.label}: {processIdForDisplay(linkedTask.taskId)}
        </Tag>
      );
    }
  });

  if (!skipCustomFields) {
    (organisationDetails.customFields || []).forEach((fieldDefinition) => {
      if (fieldDefinition.showAsTag === false) {
        return;
      }
      let itemField = (item.customFields || []).find((x) => x.id === fieldDefinition.id);

      if (fieldDefinition.options) {
        if (fieldDefinition.type === "CHECKBOX_LIST") {
          if (!itemField || !itemField.value) {
            return;
          }
          const parsedSelectedOptions = JSON.parse(itemField.value);
          parsedSelectedOptions.forEach((selectedOption) => {
            const optionDefinition = fieldDefinition.options.find((x) => x.value === selectedOption);
            if (optionDefinition.showAsTag) {
              customFieldTags.push(
                <Tag key={optionDefinition.label} color={optionDefinition.color || "#004c6d"}>
                  {optionDefinition.tagLabel || optionDefinition.label}
                </Tag>
              );
            }
          });
          return;
        }
        let selectedOption = itemField && fieldDefinition.options.find((x) => x.value === itemField.value);
        if (!selectedOption && ["RADIO_LIST"].includes(fieldDefinition.type)) {
          selectedOption = fieldDefinition.options[0];
        }
        if (selectedOption.showAsTag === false) {
          return;
        }

        if (selectedOption) {
          customFieldTags.push(
            <Tag key={selectedOption.label} color={selectedOption.color || "#004c6d"}>
              {selectedOption.tagLabel || selectedOption.label}
            </Tag>
          );
        }
      } else {
        let tagLabel = fieldDefinition.tagLabel || fieldDefinition.label;

        if (
          itemField &&
          ((itemField.value && itemField.value !== "") ||
            (fieldDefinition.defaultValue && fieldDefinition.defaultValue !== ""))
        ) {
          if (fieldDefinition.displayTagIfEmpty) {
            return;
          }
          if (fieldDefinition.type === "CHECKBOX") {
            if (
              (fieldDefinition.displayTagIfChecked && itemField.value) ||
              (!fieldDefinition.displayTagIfChecked && !itemField.value)
            ) {
              customFieldTags.push(
                <Tag key={fieldDefinition.id} color={fieldDefinition.color}>
                  {tagLabel}
                </Tag>
              );
            }
          } else {
            customFieldTags.push(
              <Tag key={fieldDefinition.id} color={fieldDefinition.color}>
                {["CHECKBOX"].includes(fieldDefinition.type) ? tagLabel : `${tagLabel}: ${itemField.value}`}
              </Tag>
            );
          }
        } else if (fieldDefinition.displayTagIfEmpty) {
          customFieldTags.push(
            <Tag key={fieldDefinition.id} color={fieldDefinition.color}>
              {tagLabel}
            </Tag>
          );
        } else if (fieldDefinition.defaultValue) {
          customFieldTags.push(
            <Tag key={fieldDefinition.id} color={fieldDefinition.color}>
              {`${tagLabel}: ${fieldDefinition.defaultValue}`}
            </Tag>
          );
        } else {
          if (fieldDefinition.type === "CHECKBOX") {
            if (!fieldDefinition.displayTagIfChecked) {
              customFieldTags.push(
                <Tag key={fieldDefinition.id} color={fieldDefinition.color}>
                  {tagLabel}
                </Tag>
              );
            }
          }
        }
      }
    });
  }

  function displayNoCatLevelTag() {
    if (skipCatLevel) {
      return null;
    }

    if (!organisationDetails.usesDesignAuthority && !organisationDetails.usesReviewAuthority) {
      return null;
    }

    if (organisationDetails.settings?.task?.allowUseOfCatZero) {
      if (item.catLevel !== null) {
        return null;
      }
    } else {
      if (item.catLevel) {
        return null;
      }
    }

    return (
      <Tag
        style={{
          borderColor: "#dee6e9",
          backgroundColor: "#fff",
          color: "#bfc9ce",
        }}
      >
        {getSimpleLabel("No Cat Level")}
      </Tag>
    );
  }

  const hasTags =
    item.isUnderReview ||
    item.isFinished ||
    item.isArchived ||
    item.reviewSecondaryStatus ||
    !item.catLevel ||
    isNew ||
    isOverdue ||
    isDueToday ||
    isBlocked ||
    isPartiallyInvoiced ||
    isFullyInvoiced ||
    customFieldTags.length > 0 ||
    rejectedLineItems?.length > 0 ||
    isPaid ||
    isPartiallyPaid ||
    customFieldTags.length > 0;

  // {item.isUnderReview && !item.reviewStatus ? (
  //   <Tag color="blue" className="tag-under-review">
  //     Review in progress
  //   </Tag>
  // ) : null}

  let reviewStatusLabel = REVIEW_STATUS_READABLE[item.reviewStatus]?.label;

  if (isInvoice && item.reviewStatus === "SUCCESS" && usesDoubleInvoiceReview) {
    if (item.secondReviewApprovedAt) {
      reviewStatusLabel = `Second stage - ${reviewStatusLabel}`;
    } else {
      reviewStatusLabel = `First stage - ${reviewStatusLabel}`;
    }
  }

  return (
    <div className={cx("dashboard-item-tags", { empty: !hasTags })} data-cy="dashboard-item-tags">
      {isNew ? <Tag color="#3f9ac2">New</Tag> : null}
      {isOverdue ? <Tag color="rgb(230,4,7)">Overdue</Tag> : null}
      {isDueToday ? <Tag color="#8b60f1">Due Today</Tag> : null}

      {isPaid ? <Tag color="#19aae8">Fully paid</Tag> : null}
      {isPartiallyPaid ? <Tag color="#ffbc0d">Partially paid</Tag> : null}

      {item.isFinished ? <Tag color="green">Finished</Tag> : null}
      {item.isArchived ? <Tag color="#003a54">Archived</Tag> : null}
      {
        /*item.isUnderReview && */ item.reviewStatus && !item.reviewSecondaryStatus ? (
          <Tag color={REVIEW_STATUS_READABLE[item.reviewStatus].color} className="tag-under-review">
            {reviewStatusLabel}
          </Tag>
        ) : null
      }
      {item.reviewSecondaryStatus ? (
        <Tag color={item.reviewSecondaryStatus === "INFO_REQUIRED" ? "orange" : "green"}>
          {REVIEW_SECONDARY_STATUS_READABLE[item.reviewSecondaryStatus]}
        </Tag>
      ) : null}
      {customFieldTags}
      {isBlocked ? <Tag color="#7f9eac">Blocked</Tag> : null}
      {isPartiallyInvoiced ? <Tag color="#ffbc0d">Partially Invoiced</Tag> : null}
      {isFullyInvoiced ? <Tag color="#19aae8">Fully Invoiced</Tag> : null}
      {rejectedLineItems?.length > 0 ? (
        <Tag color="#8b60f1">
          {rejectedLineItems.length} line item{rejectedLineItems.length === 1 ? "" : "s"} rejected
        </Tag>
      ) : null}

      {organisationDetails.settings?.task?.displayTaskEstimatesAsTag &&
      organisationDetails.settings?.task?.usesTaskEstimates &&
      item.estimatedHours ? (
        <Tag color="green">
          <div data-cy="estimated-hours" data-title={item.title}>
            Estimated hours: {item.estimatedHours}
          </div>
        </Tag>
      ) : null}

      {displayNoCatLevelTag()}
      {organisationDetails.settings?.general?.usesTaskConfirmation &&
      !skipInstructed &&
      !item.isConfirmed &&
      organisationDetails.settings?.quote?.usesQuotes ? (
        <Tag color="#aaa">{getLabel({ organisationDetails, id: "Unconfirmed", defaultValue: "Unconfirmed" })}</Tag>
      ) : null}
    </div>
  );
}

export default withSubscriptions({
  Component: DashboardItemTags,
  subscriptions: ["organisationDetails"],
});
