import { useState } from "react";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Select, Typography, Popover, Button } from "antd";
import ClientContactModal from "Modals/ClientContactModal/ClientContactModal";
import InfoItem from "InfoItem/InfoItem";

import "./ClientContactPicker.scss";

export default function ClientContactPicker({ client, value, disabled, onSubmit }) {
  const [isClientContactModalVisible, setIsClientContactModalVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState();

  async function onClientContactModalSubmit(contactDetails) {
    if (!selectedContact) {
      await window.callGraphQLSimple({
        message: "Failed to add client contact",
        queryCustom: "updateClient",
        variables: {
          input: {
            id: client.id,
            contacts: [...(client.contacts || []), contactDetails],
          },
        },
      });
    } else {
      let newContacts = [
        ...(client.contacts || []).map((contact) => {
          if (contact.id === contactDetails.id) {
            return contactDetails;
          }

          return contact;
        }),
      ];
      await window.callGraphQLSimple({
        message: "Failed to edit client contact",
        queryCustom: "updateClient",
        variables: {
          input: {
            id: client.id,
            contacts: newContacts,
          },
        },
      });
    }

    setIsClientContactModalVisible(false);
    onSubmit(contactDetails.id);
  }

  function onAddClick() {
    setSelectedContact(undefined);
    setIsClientContactModalVisible(true);
  }

  let contactFullDetails = value && client.contacts?.find((x) => x.id === value);

  return (
    <div className="client-contact-picker">
      <Popover
        trigger={contactFullDetails ? ["hover", "click"] : []}
        placement="top"
        title={
          <div className="client-contact-details-popover-title">
            <Typography.Text>{contactFullDetails?.id}</Typography.Text>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedContact(contactFullDetails);
                setIsClientContactModalVisible(true);
              }}
            >
              Edit
            </Button>
          </div>
        }
        content={
          <div className="client-contact-details-popover-content">
            <InfoItem inline label="First name" value={contactFullDetails?.firstName} />
            <InfoItem inline label="Last name" value={contactFullDetails?.lastName} />
            <InfoItem inline label="Position" value={contactFullDetails?.position} />
            <InfoItem inline label="Email" value={contactFullDetails?.email} />
            <InfoItem inline label="Phone" value={contactFullDetails?.phone} />
          </div>
        }
      >
        <Select
          data-cy="client-contact-dropdown"
          allowClear
          popupClassName="client-contact-dropdown"
          value={value}
          placeholder="Choose a contact"
          disabled={disabled}
          onChange={(value) => {
            onSubmit(value);
          }}
        >
          {(client.contacts || []).map((contact, i) => {
            return (
              <Select.Option key={i} value={contact.id}>
                {contact.id}
              </Select.Option>
            );
          })}
        </Select>
      </Popover>
      <Button icon={<PlusCircleOutlined />} className="add-button" onClick={onAddClick}>
        Add
      </Button>
      {isClientContactModalVisible && (
        <ClientContactModal
          onClose={() => setIsClientContactModalVisible(false)}
          onSubmit={onClientContactModalSubmit}
          parent={client}
          contact={selectedContact}
        />
      )}
    </div>
  );
}
