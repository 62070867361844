import moment from "moment";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { Typography, Button } from "antd";

import "./TargetDate.scss";

export default function TargetDate({ targetDate, setTargetDate }) {
  let label = "";
  let difference = moment(targetDate).startOf("day").diff(moment().startOf("day"), "days");
  switch (difference) {
    case 0:
      label = "Today";
      break;
    case -1:
      label = "Yesterday";
      break;
    case 1:
      label = "Tomorrow";
      break;
    default:
      if (difference > 0) {
        label = `In ${difference} days`;
      } else {
        label = `${-difference} days ago`;
      }
  }
  return (
    <div className="target-date">
      <Button
        icon={<LeftOutlined />}
        onClick={() => setTargetDate((oldTargetDate) => moment(oldTargetDate).subtract(1, "day"))}
      />

      <Typography.Text className="target-date-label">{label}</Typography.Text>
      <Button
        icon={<RightOutlined />}
        onClick={() => setTargetDate((oldTargetDate) => moment(oldTargetDate).add(1, "day"))}
      />
    </div>
  );
}
