import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useGetSetState } from "react-use";
import cx from "classnames";

import InfoItem from "InfoItem/InfoItem";

import "./DynamicSectionList.scss";

export default function DynamicSectionList({
  fieldName,
  fieldData,
  taskRevision,
  userIsCat2Checker,
  isFormEditor,
  validationErrors,
  isReviewed,
  removeUserListItem,
  setState: setParentState,
  form,
  templateDetails,
  saveUserFields,
  onReportUserListSubmit,
  windowWidth,
  labelFunction,
}) {
  const [getState, setState] = useGetSetState({
    isAddItemFromListModalOpen: false,
    isManageItemListModalOpen: false,
  });

  function addItem() {
    let newForm = JSON.parse(JSON.stringify(form));

    let fieldDataInNewForm = newForm.fields[fieldName];

    const placeholderSectionField = newForm.fields[`placeholderForDynamicSectionList-${fieldName}`];
    let childrenCount = fieldDataInNewForm.childrenCount || 0;
    let newFields = [];

    let newSectionField = JSON.parse(JSON.stringify(placeholderSectionField));
    delete newSectionField.isPlaceholderForDynamicSectionList;

    newSectionField.id = `${fieldName}-section-${childrenCount + 1}`;
    newSectionField.label = `${fieldDataInNewForm.titleForSubsections || fieldDataInNewForm.label}`;
    newSectionField.adminOrder = parseFloat(`${fieldDataInNewForm.adminOrder}.0${childrenCount + 1}`);
    newSectionField.parentDynamicSectionList = fieldName;
    newFields.push(newSectionField);

    fieldDataInNewForm.childrenCount = childrenCount + 1;

    for (let childFieldName in newForm.fields) {
      let childFieldDetails = newForm.fields[childFieldName];
      if (childFieldDetails.parentSection !== placeholderSectionField.id) {
        continue;
      }

      newFields.push({
        ...childFieldDetails,
        id: `${childFieldName}-${childrenCount + 1}`,
        parentSection: newSectionField.id,
      });
    }

    for (let newField of newFields) {
      newForm.fields[newField.id] = newField;
    }

    setParentState(
      {
        form: newForm,
      },
      saveUserFields
    );
  }

  function displayTopLevelActionButtons() {
    if (taskRevision.isReadOnly || userIsCat2Checker || isReviewed) {
      return null;
    }
    return (
      <div className="top-level-actions">
        <Button icon={<PlusCircleOutlined />} onClick={() => addItem()}>
          {fieldData.buttonText || "Add item"}
        </Button>
      </div>
    );
  }

  function displayItems() {
    return null;
  }

  return (
    <>
      <div
        className={cx("input-group", "dynamic-section-list", {
          "is-not-form-editor": !isFormEditor,
        })}
        key={fieldName}
      >
        <InfoItem
          includeColon={false}
          label={labelFunction()}
          value={displayTopLevelActionButtons()}
          extraContent={<ul className="user-list-options">{displayItems()}</ul>}
        />
      </div>
    </>
  );
}
