export function getTaskIdWithoutOrg(data) {
  let taskIdWithoutOrg = data.taskId;
  if (data.taskId && data.taskId.split("-").length > 2) {
    try {
      taskIdWithoutOrg = data.taskId.split("-").slice(1).join("-");
    } catch (e) {
      // nothing, it must mean that the taskId is a custom value, which we'll use as it is
    }
  }
  return taskIdWithoutOrg;
}

export async function getFullDetailsForNaming(data, type) {
  let taskIdWithoutOrg = getTaskIdWithoutOrg(data);
  let task = data.task;
  let file = data.file;
  let taskRevision;
  let filesOfSameType;
  let fileTypeCount = 0;
  let sameTemplateCount = 0;
  let templateId = data.templateId || data.file?.templateId;

  let organisationDetails;

  if (global.isBrowser) {
    organisationDetails = (
      await global.callGraphQLSimple({
        message: "Failed to load organisation details",
        queryCustom: "getOrganisation",
        variables: {
          id: data.organisation || data.file?.organisation || data.task?.organisation,
        },
      })
    ).data.getOrganisation;
  } else {
    organisationDetails = (
      await global.nodeCallAppSync({
        query: global.queriesAutoGenerated.getOrganisation,
        variables: {
          id: data.organisation || data.file?.organisation || data.task?.organisation,
        },
        logOutput: false,
      })
    ).data.getOrganisation;
  }

  if (!data.hasOwnProperty("fileIndex")) {
    if (global.isBrowser) {
      task = (
        await global.callGraphQLSimple({
          message: "Failed to load task details",
          queryCustom: "getTaskSimple",
          variables: {
            id: data.taskId,
          },
        })
      ).data.getTask;
    } else {
      task = (
        await global.nodeCallAppSync({
          query: global.queries.getTaskSimple,
          variables: {
            id: data.taskId,
          },
          logOutput: false,
        })
      ).data.getTask;
    }

    taskRevision = task?.revisions?.items?.find((x) => x.name === data.taskRevisionName);
    let fileInTask;
    if (taskRevision) {
      fileInTask = taskRevision?.files.items.find((x) => x.id === data.fileId);
      try {
        filesOfSameType = taskRevision.files.items.filter(
          (x) => x.createdAt < fileInTask.createdAt && !x.isArchived && x.type === data.fileType.toUpperCase()
        );
      } catch (e) {
        console.log("Error in getFullDetailsForNaming", e);
        console.log("fileInTask = ", fileInTask);
        throw e;
      }
      fileTypeCount = filesOfSameType.length + 1;
      sameTemplateCount = filesOfSameType.filter(
        (x) => x.createdAt < fileInTask.createdAt && !x.isArchived && x.templateId === templateId
      ).length;
      if (templateId) {
      }
      const deletedFilesByType = JSON.parse(taskRevision.deletedFilesByType || "{}");
      const deletedFilesByTemplate = JSON.parse(taskRevision.deletedFilesByTemplate || "{}");
      if (deletedFilesByType[data.fileType.toUpperCase()]) {
        fileTypeCount += deletedFilesByType[data.fileType.toUpperCase()];
      }
      if (deletedFilesByTemplate[templateId]) {
        sameTemplateCount += deletedFilesByTemplate[templateId];
      }
    }
  } else {
    fileTypeCount = data.fileIndex + 1;
    sameTemplateCount = data.fileIndex + 1;
  }

  if (!data.hasOwnProperty("sheetIndex")) {
    if (global.isBrowser) {
      file = (
        await global.callGraphQLSimple({
          message: "Failed to load file details",
          queryCustom: "getFile",
          variables: {
            id: data.fileId,
          },
        })
      ).data.getFile;
    } else {
      file = (
        await global.nodeCallAppSync({
          query: global.queries.getFile,
          variables: {
            id: data.fileId,
          },
          logOutput: false,
        })
      ).data.getFile;
    }
  }

  return {
    fileTypeCount,
    sameTemplateCount,
    file,
    task,
    taskIdWithoutOrg,
    taskRevision,
    organisationDetails,
  };
}
