import { useEffect, useRef, useState } from "react";

import cx from "classnames";
import { Typography, Button, Table, Modal } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";

import ReviewTarget from "ReviewTarget/ReviewTarget";
import Input from "Input/Input";
import Card from "Card/Card";

import "./PurchaseOrderLineItemsCard.scss";

export default function PurchaseOrderLineItemsCard(props) {
  const { purchaseOrder, refreshPurchaseOrder, isUnderReview, isDisabled, recalculateAmounts } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const purchaseOrderRef = useRef();
  const [deletedLineItemIds, setDeletedLineItemIds] = useState([]);

  useEffect(() => {
    purchaseOrderRef.current = purchaseOrder;
  });

  async function changeLineItem({ fieldName, id, value, includeRecalculation }) {
    const updatedPurchaseOrder = JSON.parse(JSON.stringify(purchaseOrderRef.current));
    const targetLineItem = updatedPurchaseOrder.lineItems.items.find((x) => x.id === id);
    const oldValue = targetLineItem[fieldName];
    targetLineItem[fieldName] = value;

    if (includeRecalculation) {
      recalculateAmounts(updatedPurchaseOrder);
    }

    try {
      await window.callGraphQLSimple({
        message: "Failed to update line item",
        mutation: "updatePurchaseOrderLineItem",
        variables: {
          input: {
            id,
            [fieldName]: value,
          },
        },
      });

      if (!includeRecalculation) {
        refreshPurchaseOrder();
      }
    } catch (e) {
      targetLineItem[fieldName] = oldValue;
      await recalculateAmounts(updatedPurchaseOrder);
      console.error(e);
      // window.location.reload();
    }
  }

  async function addLineItem() {
    let newLineItem = {
      id: `${Date.now()}-${Math.random() * 10000}-${Math.random() * 1000000}`,
      purchaseOrderId: purchaseOrder.id,
      organisation: purchaseOrder.organisation,
      title: "",
      supplierQuoteId: "",
      description: "",
      quantity: 1,
      unitPrice: 0,
      discountPercent: 0,
      taxRate: null,
      amount: 0,
      taxAmount: 0,
    };

    await window.callGraphQLSimple({
      message: "Failed to create line item",
      mutation: "createPurchaseOrderLineItem",
      variables: {
        input: newLineItem,
      },
    });
    const updatedPurchaseOrder = JSON.parse(JSON.stringify(purchaseOrderRef.current));
    updatedPurchaseOrder.lineItems.items.push(newLineItem);
    recalculateAmounts(updatedPurchaseOrder);
  }

  async function confirmDeleteLineItem(lineItem, index) {
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm delete line item",
          className: "delete-line-item-modal",
          okText: "Delete",
          maskClosable: true,
          content: (
            <>
              Are you sure you want to delete line item{" "}
              <b>
                {index} {lineItem.title ? `- ${lineItem.title}` : ""}
              </b>
              ?
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    setDeletedLineItemIds([...deletedLineItemIds, lineItem.id]);
    const updatedPurchaseOrder = JSON.parse(JSON.stringify(purchaseOrderRef.current));
    updatedPurchaseOrder.lineItems.items = updatedPurchaseOrder.lineItems.items.filter((x) => x.id !== lineItem.id);
    await recalculateAmounts(updatedPurchaseOrder);

    await window.callGraphQLSimple({
      message: "Failed to delete line item",
      mutation: "deletePurchaseOrderLineItem",
      variables: {
        input: {
          id: lineItem.id,
        },
      },
    });
  }

  const formattedSubtotal = window.formatCurrency("GBP", purchaseOrder.subtotal);
  const formattedTotalTax = window.formatCurrency("GBP", purchaseOrder.totalTax);
  const formattedTotal = window.formatCurrency("GBP", purchaseOrder.total);

  const lineItemsColumns = [
    {
      title: "",
      dataIndex: "item",
      width: 40,
      align: "center",
      render: (_, __, index) => {
        return <Typography.Text data-cy="line-item-index">{index + 1}</Typography.Text>;
      },
    },
    {
      title: "Supplier quote number",
      dataIndex: "supplierQuoteId",
      width: 200,
      align: "center",
      render: (value, item, index) => {
        let defaultValue = value;
        return (
          <>
            <ReviewTarget
              name={`purchase-order-line-item-supplier-quote-id-${item.id}`}
              lineItemIndex={index}
              {...props}
              visible={purchaseOrder.isUnderReview}
              onClick={(e) => e.stopPropagation()}
            >
              <Input
                id={`purchase-order-line-item-supplier-quote-id-${item.id}`}
                multiLine
                defaultValue={defaultValue}
                showBorder
                fullWidth
                disabled={isDisabled}
                data-cy="line-item-supplier-quote-id-input"
                fireOnChangeWithoutBlurWithDebounce
                onChange={(newValue) => {
                  if (item.supplierQuoteId !== newValue) {
                    changeLineItem({
                      fieldName: "supplierQuoteId",
                      id: item.id,
                      value: newValue,
                    });
                  }
                }}
              />
            </ReviewTarget>
          </>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 400,
      render: (value, item, index) => {
        let defaultValue = value;
        return (
          <>
            <ReviewTarget
              name={`purchase-order-line-item-title-${item.id}`}
              lineItemIndex={index}
              {...props}
              visible={purchaseOrder.isUnderReview}
              onClick={(e) => e.stopPropagation()}
            >
              <Input
                id={`purchase-order-line-item-title-${item.id}`}
                multiLine
                defaultValue={defaultValue}
                showBorder
                fullWidth
                disabled={isDisabled}
                data-cy="line-item-title-input"
                fireOnChangeWithoutBlurWithDebounce
                onChange={(newValue) => {
                  if (item.title !== newValue) {
                    changeLineItem({
                      fieldName: "title",
                      id: item.id,
                      value: newValue,
                    });
                  }
                }}
              />
            </ReviewTarget>
          </>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (value, item, index) => {
        let defaultValue = value;
        return (
          <ReviewTarget
            name={`purchase-order-line-item-description-${item.id}`}
            lineItemIndex={index}
            {...props}
            visible={purchaseOrder.isUnderReview}
            onClick={(e) => e.stopPropagation()}
          >
            <Input
              id={`purchase-order-line-item-description-${item.id}`}
              multiLine
              defaultValue={defaultValue}
              fullWidth
              showBorder
              disabled={isDisabled}
              data-cy="line-item-description-input"
              fireOnChangeWithoutBlurWithDebounce
              onChange={(newValue) => {
                if (item.description !== newValue) {
                  changeLineItem({
                    fieldName: "description",
                    id: item.id,
                    value: newValue,
                  });
                }
              }}
            />
          </ReviewTarget>
        );
      },
    },

    {
      title: "Quantity",
      width: isUnderReview ? 130 : 90,
      render: (_, item, index) => {
        return (
          <ReviewTarget
            name={`purchase-order-line-item-quantity-${item.id}`}
            lineItemIndex={index}
            {...props}
            visible={purchaseOrder.isUnderReview}
            onClick={(e) => e.stopPropagation()}
          >
            <Input
              id={`purchase-order-line-item-quantity-${item.id}`}
              min={1}
              fullWidth
              showBorder
              numerical
              allowDecimals
              disabled={isDisabled}
              defaultValue={item.quantity}
              data-cy="line-item-quantity-input"
              fireOnChangeWithoutBlurWithDebounce
              onChange={async (newValue) => {
                if (String(newValue).length === 0) {
                  newValue = "1";
                }

                if (item.quantity !== newValue) {
                  await changeLineItem({
                    fieldName: "quantity",
                    id: item.id,
                    value: parseFloat(newValue),
                    includeRecalculation: true,
                  });
                  console.log("item = ", item);
                }
              }}
            />
          </ReviewTarget>
        );
      },
    },

    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      width: isUnderReview ? 140 : 100,
      render: (_, item, index) => {
        return (
          <ReviewTarget
            name={`purchase-order-line-item-unit-price-${item.id}`}
            lineItemIndex={index}
            {...props}
            visible={purchaseOrder.isUnderReview}
            onClick={(e) => e.stopPropagation()}
          >
            <Input
              id={`purchase-order-line-item-unit-price-${item.id}`}
              fullWidth
              showBorder
              data-cy="line-item-unit-price-input"
              disabled={isDisabled}
              defaultValue={item.unitPrice || "0"}
              fireOnChangeWithoutBlurWithDebounce
              onChange={async (newValue) => {
                if (item.unitPrice !== newValue) {
                  await changeLineItem({
                    fieldName: "unitPrice",
                    id: item.id,
                    value: parseFloat(newValue),
                    includeRecalculation: true,
                  });
                }
              }}
            />
          </ReviewTarget>
        );
      },
    },

    {
      title: "Amount",
      dataIndex: "",
      width: 100,
      align: "right",
      className: "amount-column",
      render: (_, lineItem) => {
        let text = window.formatCurrency("GBP", lineItem.amount);

        return <Typography.Text data-cy="line-item-amount">{text}</Typography.Text>;
      },
    },
    {
      title: "",
      width: 100,

      render: (_, item, index) => {
        return (
          <div className="actions">
            <Button
              icon={<DeleteOutlined />}
              data-cy="delete-line-item-button"
              disabled={isDisabled}
              onClick={(e) => {
                e.stopPropagation();
                confirmDeleteLineItem(item, index + 1);
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Card
      title="Line items"
      className="purchase-order-line-items-card"
      data-cy="purchase-order-line-items-card"
      withSpace
    >
      <Button
        icon={<PlusCircleOutlined />}
        onClick={addLineItem}
        disabled={isDisabled}
        type="primary"
        className="add-new-line-item-button"
        data-cy="add-new-line-item-button"
      >
        Add new item
      </Button>
      <Table
        rowClassName={(lineItem) => {
          let isHighlighted = false;
          let classes = [];
          if (urlParams.has("lineItem")) {
            if (urlParams.getAll("lineItem").includes(lineItem.id)) {
              isHighlighted = true;
            }
          }
          if (isHighlighted) {
            classes.push("line-item-highlighted");
          }

          return cx(classes);
        }}
        rowKey="id"
        dataSource={purchaseOrder.lineItems.items.filter((item) => !deletedLineItemIds.includes(item.id))}
        columns={lineItemsColumns}
        pagination={{ hideOnSinglePage: true, pageSize: 500 }}
      />
      <div className="total-container">
        <div className="total-inner-container">
          <div className="total-item">
            <Typography.Text className="label">Subtotal </Typography.Text>
            <Typography.Text className="value" data-cy="subtotal">
              {formattedSubtotal}{" "}
            </Typography.Text>
          </div>
          <div className="total-item">
            <Typography.Text className="label">Total VAT {purchaseOrder.taxRate}% </Typography.Text>
            <Typography.Text className="value" data-cy="total-tax">
              {formattedTotalTax}{" "}
            </Typography.Text>
          </div>
          <div className="total-item grand-total-item">
            <Typography.Text className="label">Total </Typography.Text>
            <Typography.Text className="value" data-cy="total">
              {formattedTotal}{" "}
            </Typography.Text>
          </div>
        </div>
      </div>
    </Card>
  );
}
