import { roundToDecimals } from "common/mathHelpers";
import { getSimpleLabel } from "common/labels";

export function getFormattedAmount({ quote, lineItem, currency, withCurrencySymbol = false }) {
  let value = roundToDecimals(lineItem.amount);
  if (quote.taxInclusive) {
    value = roundToDecimals(value + roundToDecimals(lineItem.taxAmount));
  }
  if (withCurrencySymbol) {
    return window.formatCurrency(currency, value);
  }

  return new Intl.NumberFormat("en-EN", {
    style: "currency",
    currency,
  })
    .formatToParts(roundToDecimals(value))
    .filter((part) => part.type !== "currency")
    .map((part) => part.value)
    .join("");
}

// export async function calculateTaskFinancials(taskId) {
//   // 1. make query for getTaskWithFinancialsFields
//   // 2. add up quote line items amounts and set value of amountBudget
//   // 3. (not now) add up amounts from timesheet blocks and set value of amountSpent

//   let task = (
//     await window.callGraphQLSimple({
//       message: `Failed to fetch ${getSimpleLabel("task")} financials`,
//       queryCustom: "getTaskWithFinancials",
//       variables: {
//         id: taskId,
//       },
//     })
//   ).data.getTask;

//   let amountBudget = task.quoteLineItems.items.reduce((sum, currentAmount) => {
//     return sum + roundToDecimals(currentAmount.amount);
//   }, 0);

//   await window.callGraphQLSimple({
//     message: `Failed to update ${getSimpleLabel("task")} financials`,
//     queryCustom: "updateTask",
//     variables: {
//       input: {
//         id: taskId,
//         amountBudget,
//       },
//     },
//   });
// }
