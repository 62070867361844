import { Select, Tag } from "antd";

import withSubscriptions from "common/withSubscriptions";

import { getLabel } from "common/helpers";

import "./SprintPicker.scss";

export function SprintPicker({ sprints, organisationDetails, value, onChange }) {
  const activeSprints = sprints.filter((sprint) => !sprint.isFinished);

  return (
    <Select
      className="sprint-picker"
      showSearch
      value={value}
      onChange={onChange}
      placeholder={`Select a ${getLabel({
        organisationDetails,
        id: "sprint",
        defaultValue: "sprint",
      })}`}
    >
      {activeSprints.map((sprint) => (
        <Select.Option key={sprint.id} className="sprint-picker-option">
          {sprint.name}{" "}
          {sprint.isActive ? (
            <Tag color="green">
              {getLabel({
                organisationDetails,
                id: "Sprint-Active",
                defaultValue: "Active",
              })}
            </Tag>
          ) : null}
        </Select.Option>
      ))}
    </Select>
  );
}

export default withSubscriptions({
  Component: SprintPicker,
  subscriptions: ["organisationDetails"],
});
