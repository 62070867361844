import { useState } from "react";
import { Modal, Button, Table } from "antd";
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";

import { getSimpleLabel } from "common/labels";
import { callGraphQLSimple } from "common/apiHelpers";

import Card from "Card/Card";
import CreateStockItemMaintenanceModal from "Modals/CreateStockItemMaintenanceModal/CreateStockItemMaintenanceModal";

export default function StockItemMaintenanceTab({ stockItem }) {
  const [isMaintenanceModalOpen, setIsMaintenanceModalOpen] = useState(false);
  const [selectedMaintenanceInterval, setSelectedMaintenanceInterval] = useState(undefined);

  async function deleteMaintenanceInterval(id) {
    const response = await callGraphQLSimple({
      mutation: "updateStockItem",
      variables: {
        input: {
          id: stockItem.id,
          maintenanceIntervals: (stockItem.maintenanceIntervals || []).filter((interval) => {
            return interval.id !== id;
          }),
        },
      },
    });
    if (response.errors) {
      console.error("Error deleting maintenance interval", response.errors);
      return;
    }
  }

  return (
    <>
      <Card
        className="stock-item-maintenance-tab"
        title={`${getSimpleLabel("Maintenance periods")}`}
        actions={
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setSelectedMaintenanceInterval(undefined);
              setIsMaintenanceModalOpen(true);
            }}
          >
            Add {getSimpleLabel("maintenance period")}
          </Button>
        }
      >
        <Table
          pagination={{ pageSize: 50, hideOnSinglePage: true }}
          rowKey="id"
          columns={[
            {
              title: "Name",
              dataIndex: "content",
              align: "left",
            },
            {
              title: "Start date",
              dataIndex: "startDate",
              align: "left",
              render: (date) => moment(date).format("DD-MM-YYYY"),
            },
            {
              title: "End date",
              dataIndex: "endDate",
              align: "left",
              render: (date) => moment(date).format("DD-MM-YYYY"),
            },
            {
              title: "",
              render: (maintenanceInterval) => {
                return (
                  <div>
                    {/* <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => {
                        setSelectedMaintenanceInterval(maintenanceInterval);
                        setIsMaintenanceModalOpen(true);
                      }}
                    /> */}
                    <Button
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: "Are you sure you want to delete this maintenance interval?",
                          onOk: () => {
                            deleteMaintenanceInterval(maintenanceInterval.id);
                          },
                          onCancel: () => {},
                        });
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          dataSource={stockItem.maintenanceIntervals || []}
        />
      </Card>
      {isMaintenanceModalOpen && (
        <CreateStockItemMaintenanceModal onClose={() => setIsMaintenanceModalOpen(false)} stockItem={stockItem} />
      )}
    </>
  );
}
