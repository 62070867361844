export default function changeObjectOrder({ childId, newPosition, newRelativePosition, outputTemplate }) {
  // Create a new structure with the reordered child
  const newOutputTemplate = reorderChild({ currentObject: outputTemplate, childId, newPosition, newRelativePosition });

  // Set the updated template in the state
  return newOutputTemplate;
}

function reorderChild({ currentObject, childId, newPosition, newRelativePosition }) {
  if (Array.isArray(currentObject.objects)) {
    const childIndex = currentObject.objects.findIndex((obj) => obj.custom_id === childId);

    if (childIndex !== -1) {
      // Found the child in the current parent object, perform the reorder
      const newChildren = [...currentObject.objects];
      const [childToMove] = newChildren.splice(childIndex, 1);

      if (newPosition !== undefined) {
        newChildren.splice(newPosition, 0, childToMove);
      } else if (newRelativePosition !== undefined) {
        newChildren.splice(childIndex + newRelativePosition, 0, childToMove);
      }

      return { ...currentObject, objects: newChildren };
    } else {
      // Continue to search in the children
      const newChildren = currentObject.objects.map((child) =>
        reorderChild({ currentObject: child, childId, newPosition, newRelativePosition })
      );

      return { ...currentObject, objects: newChildren };
    }
  }

  // Return the object unchanged if it has no children
  return currentObject;
}
