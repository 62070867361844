import React from "react";
import InfoItem from "InfoItem/InfoItem";
import { Select } from "antd";

export default function UserFeeLevels({ changeAttribute, organisationDetails, userData }) {
  return (
    <InfoItem
      inline
      label="Fee levels"
      value={
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Select one or more"
          defaultValue={userData.feeRoles || undefined}
          onChange={(value) => {
            changeAttribute({ fieldName: "feeRoles", value });
          }}
        >
          {organisationDetails.defaultFees?.map((fee, index) => {
            return (
              <Select.Option value={fee.id} key={index}>
                {fee.label}
              </Select.Option>
            );
          })}
        </Select>
      }
    />
  );
}
