import { Text, View, Image, Page, StyleSheet } from "@react-pdf/renderer";
import NewCoverGrayBand from "./end_cover_gray_band.jpg";

export const colors = { andunGreen: "#0d9e80", black: "#000", gray: "#d0dce2" };

export const styles = StyleSheet.create({
  bold: { fontWeight: "bold" },
  pageTitle: { fontSize: 20, marginBottom: 25, fontWeight: "bold" },
  pageSubtitle: { fontSize: 13, marginBottom: 15 },
  engineeringStatementTitle: { fontSize: 15, marginBottom: 8, textAlign: "center", fontWeight: "bold" },
  sectionTitle: { fontSize: 17, marginBottom: 10, marginTop: 20, fontWeight: "bold" },
  sectionSubtitle: {
    fontSize: 15,
    marginBottom: 20,
    marginTop: 20,
    textDecoration: "underline",
    fontWeight: "bold",
  },
  paragraph: { fontSize: 10, marginBottom: 10 },
  pilingPlatformGuidanceParagraph: { fontSize: 7.5, marginBottom: 7 },
  "image_half-page": {
    width: "50%",
  },
  "image_third-page": {
    width: "33%",
  },
  image_half: {
    width: "50%",
  },
  image_third: {
    width: "33%",
  },
  "image_full-page": {
    width: "100%",
  },
  image_original: {},

  page: {
    paddingTop: 80,
    paddingRight: 66,
    paddingLeft: 24,
    paddingBottom: 55,
    fontFamily: "Averta",
  },

  cover: {
    infoContainer: { paddingLeft: 200, paddingTop: 94 },
    image: {
      height: "795pt",
      position: "absolute",
      top: -56,
      left: -25,
    },
    infoItem: { marginBottom: 24, fontSize: 10 },
  },
});

export function EndCover({ colors, styles, endOfReportImageUrl, useNewVersion }) {
  if (useNewVersion) {
    return (
      <Page size="A4" style={{ ...styles.page, paddingLeft: 0, paddingRight: 0 }} wrap>
        <View
          style={{
            paddingLeft: styles.page.paddingLeft,
            paddingRight: styles.page.paddingRight,
          }}
        >
          <Image
            src={endOfReportImageUrl}
            style={{ width: 450, height: 402, marginTop: 10, marginBottom: 15, marginLeft: 50 }}
          />
          <Text style={[styles.paragraph, { margin: 5, marginTop: 20 }]}>
            <Text style={[styles.bold]}>Registration No:</Text> 11928533
          </Text>

          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Email:</Text>{" "}
            <Text style={{ color: colors.andunGreen }}>office@andun.co.uk</Text>
          </Text>
          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Telephone:</Text> 01245 360194
          </Text>
          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Website:</Text>{" "}
            <Text style={{ color: colors.andunGreen }}>www.andun.co.uk</Text>
          </Text>
          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Head Office:</Text> Monarch House, Unit 6 Drakes Lane Industrial Estate,
            Boreham, Chelmsford, Essex, CM3 3BE
          </Text>
          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Stoke on Trent Office:</Text> 8 Kings Street, Newcastle-Under-Lyme, ST5 1EL
          </Text>
          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Nottingham Office:</Text> Unit 1C, Lower Enterprise Road, Enterprise Village,
            Old Dalby, LE14 3AY
          </Text>
          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Edinburgh Office:</Text> 6/214 Dryden Road, Loanhead, Midlothian, EH20 9LZ
          </Text>
          <Image src={NewCoverGrayBand} style={{ width: 596, position: "absolute", top: 657, left: 0 }} />
        </View>
      </Page>
    );
  } else {
    return (
      <Page size="A4" style={styles.page} wrap>
        <View>
          <Image src={endOfReportImageUrl} style={{ width: 500, height: 490, marginTop: 10 }} />
          <Text style={[styles.paragraph, { margin: 5, marginTop: 20 }]}>
            <Text style={[styles.bold]}>Registration No:</Text> 11928533
          </Text>

          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Email:</Text>{" "}
            <Text style={{ color: colors.andunGreen }}>office@andun.co.uk</Text>
          </Text>
          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Telephone:</Text> 01245 360194
          </Text>
          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Website:</Text>{" "}
            <Text style={{ color: colors.andunGreen }}>www.andun.co.uk</Text>
          </Text>
          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Head Office:</Text> Monarch House, Unit 6 Drakes Lane Industrial Estate,
            Boreham, Chelmsford, Essex, CM3 3BE
          </Text>
          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Stoke on Trent Office:</Text> 8 Kings Street, Newcastle-Under-Lyme, ST5 1EL
          </Text>
          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Nottingham Office:</Text> Unit 1C, Lower Enterprise Road, Enterprise Village,
            Old Dalby, LE14 3AY
          </Text>
          <Text style={[styles.paragraph, { margin: 5 }]}>
            <Text style={[styles.bold]}>Edinburgh Office:</Text> 6/214 Dryden Road, Loanhead, Midlothian, EH20 9LZ
          </Text>
        </View>
      </Page>
    );
  }
}
