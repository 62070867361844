export default function addGroupField(currentObject, parentObject = null) {
  // Add 'group' property to the current object
  let updatedObject = currentObject;
  if (parentObject) {
    updatedObject = { ...currentObject, group: parentObject };
  }

  // If the current object has children, recursively add 'group' to each child
  if (Array.isArray(updatedObject.objects)) {
    updatedObject.objects = updatedObject.objects.map((child) => addGroupField(child, updatedObject));
  }

  return updatedObject;
}
