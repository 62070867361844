import { useState, memo } from "react";
import { message, Tooltip, Select, InputNumber, Checkbox, Tag, Modal, Popover, Button, Typography, Table } from "antd";
import { EditOutlined, CheckOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import cx from "classnames";
import axios from "axios";

import getS3File from "common/getS3File";
import { CloseIcon } from "common/icons";
import { getOrderedFieldKeys } from "ReportPage/Report/reportHelpers";
import { processIdForDisplay, getUserReadableCatLevel } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { getInvoicingStatusTag } from "common/invoiceHelpers/invoiceHelpers";
import { enhanceQuoteWithInvoicing } from "common/invoiceHelpers/sharedInvoiceHelpers";
import { getCustomLineItemFieldColumns } from "QuoteDetailsPage/customLineItemFields";
import { getSimpleLabel } from "common/labels";
import { getFormattedAmount } from "common/financialHelpers";
import { isAuthorised } from "common/permissions";

import ReviewTarget from "ReviewTarget/ReviewTarget";
import TaskIdTag from "TaskIdTag/TaskIdTag";
import Input from "Input/Input";
import UnlinkTaskFromQuoteLineItemButton from "QuoteDetailsPage/UnlinkTaskFromQuoteLineItemButton/UnlinkTaskFromQuoteLineItemButton";

export function QuoteLineItemsTable({
  quote,
  addLineItemChangeToQueue,
  quoteIsDisabled,
  organisationDetails,
  quoteProps,
  changeLineItem,
  isUnderReview,
  form,
  templateDetails,
  isCreatingTaskFromLineItemId,
  isCreatingPurchaseOrderFromLineItemId,
  changeLineItemCustomFieldsOldWay,
  quoteDetailsPageThis,
  calculateAmounts,
  refreshQuote,
  windowWidth,
  apiUser,
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const [selectedLineItemForEditingAmount, setSelectedLineItemForEditingAmount] = useState(undefined);
  const [amountForSelectedLineItem, setAmountForSelectedLineItem] = useState(undefined);

  const enhancedQuote = enhanceQuoteWithInvoicing(quote);

  async function confirmDeleteLineItem(lineItem, indexToDisplay) {
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm delete line item",
          className: "delete-line-item-modal",
          okText: "Delete",
          maskClosable: true,
          content: (
            <>
              Are you sure you want to delete line item{" "}
              <b>
                {indexToDisplay} {lineItem.title ? `- ${lineItem.title}` : ""}
              </b>
              ?{" "}
              {lineItem.resultingTaskId && lineItem.resultingTaskId !== "nothing" ? (
                <>
                  <br />
                  <br />
                  This will also archive {getSimpleLabel("task")} <b>{lineItem.resultingTaskId}</b>.
                  <br />
                  <br />
                  If you want to delete the line item without archiving the {getSimpleLabel("task")}, you need to unlink
                  the {getSimpleLabel("task")} first.
                </>
              ) : (
                ""
              )}
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    if (lineItem.invoiceLineItems.items?.length > 0) {
      message.error("You can't delete a line item that has been invoiced.");
      return;
    }

    await callGraphQLSimple({
      message: "Failed to delete line item",
      queryName: "deleteQuoteLineItem",
      variables: {
        input: {
          id: lineItem.id,
        },
      },
    });
    // if quote line item had a resulting task ID, update task financials

    if (lineItem.resultingTaskId && lineItem.resultingTaskId !== "nothing") {
      try {
        await callGraphQLSimple({
          message: `Failed to update resulting ${getSimpleLabel("task")}`,
          queryName: "updateTask",
          variables: {
            input: {
              id: lineItem.resultingTaskId,
              isArchived: true,
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    }

    if (lineItem.resultingPurchaseOrderId && lineItem.resultingPurchaseOrderId !== "nothing") {
      try {
        await callGraphQLSimple({
          message: "Failed to update resulting purchase order",
          queryName: "updatePurchaseOrder",
          variables: {
            input: {
              id: lineItem.resultingPurchaseOrderId,
              quoteId: null,
              itemSubscription: Math.floor(Math.random() * 100000),
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
    const updatedQuote = (
      await callGraphQLSimple({
        message: `Failed to retrieve ${getSimpleLabel("quote")} details`,
        queryName: "getQuote",
        variables: {
          id: quote.id,
        },
      })
    ).data.getQuote;
    calculateAmounts({ quote: updatedQuote });
  }

  function confirmAddItemToPresets(item) {
    Modal.confirm({
      title: "Add to presets",
      content: (
        <>
          Are you sure you want to add this item to presets?
          <br />
          <b>{item.title}</b>
        </>
      ),
      onOk: () => {
        addItemToPresets({
          fieldName: "lineItems",
          item,
        });
      },
    });
  }

  async function addItemToPresets({ fieldName, item }) {
    let itemToAdd = JSON.parse(JSON.stringify(item));
    let fieldsToDelete = [
      "createdAt",
      "updatedAt",
      "resultingTaskId",
      "resultingPurchaseOrderId",
      "invoicedAmount",
      "invoicingStatus",
      "isRejected",
      "manuallyInvoicedAmount",
      "progressPercent",
      "isHourlyFullyInvoiced",
      "invoiceLineItems",
      "progressPercent",
      "isHourlyFullyInvoiced",
      "isRejected",
      "requestIds",
    ];

    for (let fieldToDelete of fieldsToDelete) {
      delete itemToAdd[fieldToDelete];
    }

    try {
      const formFilePublicUrl = await getS3File(templateDetails.key);
      let templateForm = (await axios.get(formFilePublicUrl)).data;

      if (!templateForm.fields[fieldName]) {
        templateForm.fields[fieldName] = {};
      }

      let templateFormField = templateForm.fields[fieldName];
      if (!templateFormField.templateValues) {
        templateFormField.templateValues = [];
      }
      templateFormField.templateValues.push({
        item: itemToAdd,
        addedFromPath: window.location.pathname,
      });

      try {
        await Storage.put(templateDetails.key, JSON.stringify(templateForm));
      } catch (e) {
        console.error(e);
        throw e;
      }

      message.success(`${getSimpleLabel("Quote")} line item added to presets`);
    } catch (e) {
      console.error("Error when adding to presets:", e);
      message.error("Failed to add field to presets");
    }
  }

  function getManuallyInvoicedColumn() {
    if (!isAuthorised(["QUOTES.WRITE_MANUALLY_INVOICED"])) {
      return;
    }

    return {
      title: "Amount invoiced outside of DraughtHub",
      width: 130,
      align: "center",
      render: (_, item) => {
        return (
          <InputNumber
            id={`quote-line-item-manually-invoiced-amount-${item.id}`}
            min={0}
            data-cy="line-item-manually-invoiced-amount"
            defaultValue={item.manuallyInvoicedAmount || 0}
            onChange={(newValue) =>
              addLineItemChangeToQueue({
                fieldName: "manuallyInvoicedAmount",
                id: item.id,
                value: newValue,
                includeRecalculation: true,
              })
            }
          />
        );
      },
    };
  }

  function getItemIndexColumn() {
    return {
      title: "",
      dataIndex: "item",
      width: 40,
      render: (_, __, index) => {
        return <Typography.Text data-cy="line-item-index">{index + 1}</Typography.Text>;
      },
    };
  }

  function getActionsColumn() {
    return {
      title: "Actions",
      width: 120,
      render: (_, item, index) => {
        let deleteButton = null;
        let approveButton = null;
        let rejectButton = null;
        let markAsFullyInvoicedButton = null;
        let markAsNotFullyInvoicedButton = null;

        if (!quoteIsDisabled) {
          deleteButton = (
            <Button
              icon={<DeleteOutlined />}
              data-cy="delete-line-item-button"
              onClick={() => confirmDeleteLineItem(item, index + 1)}
            >
              Delete
            </Button>
          );
        }

        if (item.isRejected) {
          approveButton = (
            <Button
              icon={<CheckOutlined />}
              data-cy="accept-line-item-button"
              disabled={quote.isArchived}
              onClick={() => {
                addLineItemChangeToQueue({
                  fieldName: "isRejected",
                  id: item.id,
                  value: false,
                  includeRecalculation: true,
                });
              }}
            >
              Approve
            </Button>
          );
        } else {
          rejectButton = (
            <Button
              icon={<CloseIcon />}
              data-cy="reject-line-item-button"
              disabled={quote.isArchived}
              onClick={() => {
                addLineItemChangeToQueue({
                  fieldName: "isRejected",
                  id: item.id,
                  value: true,
                  includeRecalculation: true,
                });
              }}
            >
              Reject
            </Button>
          );
        }

        if ((quote.status === "SENT" || quote.status === "ACCEPTED") && !item.isRejected && item.isHourly) {
          if (!item.isHourlyFullyInvoiced) {
            markAsFullyInvoicedButton = (
              <Button
                data-cy="mark-line-item-as-fully-invoiced-button"
                disabled={quote.isArchived}
                onClick={() => {
                  addLineItemChangeToQueue({
                    fieldName: "isHourlyFullyInvoiced",
                    id: item.id,
                    value: true,
                    includeRecalculation: false,
                  });
                }}
              >
                Mark as fully invoiced
              </Button>
            );
          } else {
            markAsNotFullyInvoicedButton = (
              <Button
                data-cy="mark-line-item-as-not-fully-invoiced-button"
                disabled={quote.isArchived}
                onClick={() => {
                  addLineItemChangeToQueue({
                    fieldName: "isHourlyFullyInvoiced",
                    id: item.id,
                    value: false,
                    includeRecalculation: false,
                  });
                }}
              >
                Mark as not fully invoiced
              </Button>
            );
          }
        }

        return (
          <div className="line-item-actions">
            {deleteButton}
            {approveButton}
            {rejectButton}
            {markAsFullyInvoicedButton}
            {markAsNotFullyInvoicedButton}
          </div>
        );
      },
    };
  }

  function getInvoicingStatusColumn() {
    if (!organisationDetails.settings?.invoice?.usesInvoices) {
      return null;
    }

    return {
      title: "Invoicing status",
      dataIndex: "item",
      width: 120,
      render: (_, quoteLineItem) => {
        return (
          <div className="line-item-tags">
            <Popover
              title={<Typography.Text>Invoices containing this {getSimpleLabel("quote")} line item</Typography.Text>}
              trigger={
                quoteLineItem.invoiceLineItems.items.length > 0 || quoteLineItem.manuallyInvoicedAmount ? "hover" : ""
              }
              content={
                <div className="invoices-containing-quote-line-item">
                  {quoteLineItem.manuallyInvoicedAmount && (
                    <span className="quote-line-item-invoice-item">
                      <Typography.Text className="line-item-amount-invoiced">
                        {window.formatCurrency("GBP", quoteLineItem.manuallyInvoicedAmount)}
                      </Typography.Text>
                      <Typography.Text className="line-item-title" style={{ marginLeft: "0.5rem" }}>
                        Invoiced outside of DraughtHub
                      </Typography.Text>
                    </span>
                  )}
                  {quoteLineItem.invoiceLineItems.items.map((invoiceLineItem) => {
                    return (
                      <Link className="quote-line-item-invoice-item" to={`/invoices/${invoiceLineItem.invoiceId}`}>
                        <TaskIdTag
                          includeTitle={false}
                          task={{
                            id: invoiceLineItem.invoiceId,
                            client: quote.client,
                          }}
                          includeLink={false}
                        />
                        <Typography.Text className="line-item-amount-invoiced">
                          {window.formatCurrency("GBP", invoiceLineItem.amount)}
                        </Typography.Text>
                        <Typography.Text className="line-item-title" style={{ marginLeft: "0.5rem" }}>
                          {invoiceLineItem.title}
                        </Typography.Text>
                      </Link>
                    );
                  })}
                </div>
              }
            >
              {getInvoicingStatusTag({
                invoiceLineItems: quoteLineItem.invoiceLineItems.items,
                invoicingStatus: quoteLineItem.invoicingStatus,
                invoicedAmount: quoteLineItem.invoicedAmount,
              })}
            </Popover>
            {quoteLineItem.isRejected && <Tag color="#ff4d4f">Rejected</Tag>}
          </div>
        );
      },
    };
  }

  function getHourlyColumn() {
    let hourlyColumnIsHidden = organisationDetails.settings?.quote?.hiddenLineItemColumns?.includes("isHourly");

    if (hourlyColumnIsHidden) {
      return null;
    }

    return {
      title: `${getSimpleLabel("quote-hourly")}`,
      dataIndex: "isHourly",
      width: 80,
      render: (_, item) => {
        return (
          <Checkbox
            disabled={quoteIsDisabled}
            defaultChecked={item.isHourly}
            data-cy="line-item-hourly-checkbox"
            onChange={(e) =>
              addLineItemChangeToQueue({
                fieldName: "isHourly",
                id: item.id,
                value: e.target.checked,
                includeRecalculation: true,
              })
            }
          />
        );
      },
    };
  }

  function getTitleColumn() {
    return {
      title: "Title",
      dataIndex: "title",
      width: windowWidth > 800 ? 200 : 150,
      render: (value, item, index) => {
        return (
          <ReviewTarget
            name={`quote-line-item-title-${item.id}`}
            lineItemIndex={index}
            {...quoteDetailsPageThis}
            {...quoteProps}
            visible={quote.isUnderReview}
          >
            <Input
              multiLine
              defaultValue={value}
              showBorder
              fullWidth
              disabled={quoteIsDisabled}
              fireOnChangeWithoutBlurWithDebounce
              debounceDelay={500}
              minRows={3}
              data-cy="line-item-title-input"
              memoize
              onChange={(newValue) =>
                changeLineItem({
                  fieldName: "title",
                  id: item.id,
                  value: newValue,
                })
              }
            />
          </ReviewTarget>
        );
      },
    };
  }

  function getDescriptionColumn() {
    if (organisationDetails.settings?.quote?.hiddenLineItemColumns?.includes("description")) {
      return null;
    }

    return {
      title: "Description",
      dataIndex: "description",
      width: windowWidth > 800 ? 250 : 200,
      render: (value, item, index) => {
        return (
          <div style={{ position: "relative", top: "3px" }}>
            <ReviewTarget
              name={`quote-line-item-description-${item.id}`}
              lineItemIndex={index}
              {...quoteDetailsPageThis}
              {...quoteProps}
              visible={quote.isUnderReview}
            >
              <Input
                multiLine
                fullWidth
                defaultValue={value}
                showBorder
                disabled={quoteIsDisabled}
                fireOnChangeWithoutBlurWithDebounce
                debounceDelay={500}
                minRows={3}
                memoize
                data-cy="line-item-description-input"
                onChange={(newValue) =>
                  changeLineItem({
                    fieldName: "description",
                    id: item.id,
                    value: newValue,
                  })
                }
              />
            </ReviewTarget>
          </div>
        );
      },
    };
  }

  function getQuantityColumn() {
    if (organisationDetails.settings?.quote?.hiddenLineItemColumns?.includes("quantity")) {
      return null;
    }

    const canSeeOthersQuotes = isAuthorised(["QUOTES.VIEW_OTHERS_QUOTES"]);
    if (!canSeeOthersQuotes && quote.assignedTo !== apiUser.id) {
      return null;
    }

    return {
      title: getSimpleLabel("quote-quantity"),
      dataIndex: "quantity",
      width: 90,
      render: (_, item, index) => {
        if (item.isHourly) {
          return "-";
        }
        return (
          <ReviewTarget
            name={`quote-line-item-quantity-${item.id}`}
            lineItemIndex={index}
            {...quoteDetailsPageThis}
            {...quoteProps}
            visible={quote.isUnderReview}
          >
            <Input
              id={`quote-line-item-quantity-${item.id}`}
              disabled={quoteIsDisabled}
              defaultValue={item.quantity}
              ignoreChangesToDefaultValue
              data-cy="line-item-quantity-input"
              showBorder
              numerical
              fireOnChangeWithoutBlurWithDebounce
              debounceDelay={500}
              onChange={(newValue) => {
                if (String(newValue) === String(item.quantity)) {
                  return;
                }

                if (isNaN(newValue)) {
                  message.error("Please enter a valid number");
                  return;
                }

                if (parseInt(newValue) < 0) {
                  message.error(`${getSimpleLabel("quote-quantity")} cannot be negative`);
                  return;
                }

                addLineItemChangeToQueue({
                  fieldName: "quantity",
                  id: item.id,
                  value: parseInt(newValue),
                  includeRecalculation: true,
                });
              }}
            />
          </ReviewTarget>
        );
      },
    };
  }

  function getUnitPriceColumn() {
    if (organisationDetails.settings?.quote?.hiddenLineItemColumns?.includes("unitPrice")) {
      return null;
    }

    if (organisationDetails.settings?.general?.hideFinancials && !isAuthorised(["QUOTES.WRITE_AMOUNT"])) {
      return null;
    }

    return {
      title: getSimpleLabel("quote-unit-price"),
      dataIndex: "unitPrice",
      width: 110,
      render: (_, item, index) => {
        if (item.isHourly) {
          return "-";
        }
        return (
          <ReviewTarget
            name={`quote-line-item-unit-price-${item.id}`}
            lineItemIndex={index}
            {...quoteDetailsPageThis}
            {...quoteProps}
            visible={quote.isUnderReview}
          >
            <Input
              id={`quote-line-item-unit-price-${item.id}`}
              data-cy="line-item-unit-price-input"
              disabled={quoteIsDisabled}
              fireOnChangeWithoutBlurWithDebounce
              debounceDelay={500}
              showBorder
              numerical
              allowDecimals
              ignoreChangesToDefaultValue
              defaultValue={item.unitPrice || 0}
              onChange={(newValue) => {
                if (String(newValue) === String(item.unitPrice)) {
                  return;
                }

                if (String(newValue) === "") {
                  newValue = 0;
                }

                addLineItemChangeToQueue({
                  fieldName: "unitPrice",
                  id: item.id,
                  value: parseFloat(newValue),
                  includeRecalculation: true,
                });
              }}
            />
          </ReviewTarget>
        );
      },
    };
  }

  function getAuthorityLevelColumn() {
    if (organisationDetails.settings?.quote?.hiddenLineItemColumns?.includes("authorityLevel")) {
      return null;
    }

    return {
      title: getSimpleLabel("quote-authority-level"),
      dataIndex: "authorityLevel",
      width: 110,
      render: (_, item, index) => {
        return (
          <ReviewTarget
            name={`quote-line-item-authority-level-${item.id}`}
            lineItemIndex={index}
            {...quoteDetailsPageThis}
            {...quoteProps}
            visible={quote.isUnderReview}
          >
            <Select
              className="cat-level-select"
              defaultValue={item.authorityLevel || 0}
              disabled={quoteIsDisabled}
              data-cy="line-item-authority-level-dropdown"
              onChange={(newValue) =>
                changeLineItem({
                  fieldName: "authorityLevel",
                  id: item.id,
                  value: newValue,
                  includeRecalculation: false,
                })
              }
            >
              <Select.Option key={0} value={0}>
                {getUserReadableCatLevel(organisationDetails, 0)}
              </Select.Option>
              <Select.Option key={1} value={1}>
                {getUserReadableCatLevel(organisationDetails, 1)}
              </Select.Option>
              <Select.Option key={2} value={2}>
                {getUserReadableCatLevel(organisationDetails, 2)}
              </Select.Option>
              <Select.Option key={3} value={3}>
                {getUserReadableCatLevel(organisationDetails, 3)}
              </Select.Option>
            </Select>
          </ReviewTarget>
        );
      },
    };
  }

  function getCheckPriceColumn() {
    if (organisationDetails.settings?.quote?.hiddenLineItemColumns?.includes("checkPrice")) {
      return null;
    }

    return {
      title: getSimpleLabel("quote-check-price"),
      dataIndex: "checkPrice",
      width: isUnderReview ? 150 : 120,
      render: (_, item, index) => {
        if (item.isHourly) {
          return "-";
        }
        return (
          <ReviewTarget
            name={`quote-line-item-check-price-${item.id}`}
            lineItemIndex={index}
            {...quoteDetailsPageThis}
            {...quoteProps}
            visible={quote.isUnderReview}
          >
            <InputNumber
              id={`quote-line-item-check-price-${item.id}`}
              min={0}
              data-cy="line-item-check-price-input"
              disabled={quoteIsDisabled}
              defaultValue={item.checkPrice || 0}
              onChange={(newValue) =>
                addLineItemChangeToQueue({
                  fieldName: "checkPrice",
                  id: item.id,
                  value: newValue,
                  includeRecalculation: true,
                })
              }
            />
          </ReviewTarget>
        );
      },
    };
  }

  function getOrderedLineItemFieldColumns() {
    const orderedLineItemFieldKeys = getOrderedFieldKeys(form.lineItemFields || {});

    let orderedLineItemFieldElements = [];
    if (Object.keys(form.lineItemFields || {}).length > 0) {
      orderedLineItemFieldElements = orderedLineItemFieldKeys.map((fieldName) => {
        const fieldData = form.lineItemFields[fieldName];
        let renderFunction = null;
        switch (fieldData.type) {
          case "dropdown":
            renderFunction = (_, item, index) => {
              const lineItemCustomFields = form.lineItems[index];
              return (
                <ReviewTarget
                  name={`quote-line-item-${fieldName}-${item.id}`}
                  lineItemIndex={index}
                  {...quoteDetailsPageThis}
                  {...quoteProps}
                  visible={quote.isUnderReview}
                >
                  <Select
                    value={lineItemCustomFields && lineItemCustomFields[fieldName]}
                    disabled={quoteIsDisabled}
                    data-cy={`${fieldName}-dropdown`}
                    onChange={(value) =>
                      changeLineItemCustomFieldsOldWay({
                        fieldData,
                        fieldName,
                        value,
                        id: item.id,
                      })
                    }
                    placeholder={fieldData.placeholder}
                  >
                    {fieldData.options.map((option) => {
                      return (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </ReviewTarget>
              );
            };

            break;
          default:
            return null;
        }
        return {
          title: fieldData.label,
          render: renderFunction,
        };
      });
    }
    return orderedLineItemFieldElements;
  }

  function getAmountColumn() {
    if (organisationDetails.settings?.general?.hideFinancials && !isAuthorised(["QUOTES.WRITE_AMOUNT"])) {
      return null;
    }

    return {
      title: "Amount",
      dataIndex: "",
      width: isAuthorised(["QUOTES.WRITE_AMOUNT"]) && !quoteIsDisabled ? 150 : 100,
      className: "amount-column",
      render: (_, lineItem) => {
        let text = "";
        let editButton = null;
        if (lineItem.isHourly) {
          text = "Hourly rate";
        } else {
          text = getFormattedAmount({ quote, lineItem, currency: "GBP" });
          if (!quoteIsDisabled && isAuthorised(["QUOTES.WRITE_AMOUNT"], null, true)) {
            if (selectedLineItemForEditingAmount?.id === lineItem?.id) {
              return (
                <div style={{ display: "flex", gap: "0.5rem", justifyContent: "flex-end" }}>
                  <InputNumber
                    id={`quote-line-item-amount-${lineItem.id}`}
                    disabled={quoteIsDisabled}
                    defaultValue={lineItem.amount}
                    data-cy="line-item-amount-input"
                    onChange={(newValue) => setAmountForSelectedLineItem(newValue)}
                  />
                  <Button
                    icon={<CheckOutlined />}
                    onClick={() => {
                      if (amountForSelectedLineItem !== undefined) {
                        addLineItemChangeToQueue({
                          fieldName: "amount",
                          id: lineItem.id,
                          value: amountForSelectedLineItem || 0,
                          includeRecalculation: true,
                        });
                      }

                      setSelectedLineItemForEditingAmount();
                      setAmountForSelectedLineItem();
                    }}
                    data-cy="close-line-item-amount-button"
                  />
                </div>
              );
            } else {
              editButton = (
                <Button
                  icon={<EditOutlined />}
                  onClick={() => setSelectedLineItemForEditingAmount(lineItem)}
                  data-cy="edit-line-item-amount-button"
                  style={{ marginLeft: "0.5rem" }}
                />
              );
            }
          }
        }

        return (
          <Typography.Text data-cy="line-item-amount">
            {text}
            {editButton}
          </Typography.Text>
        );
      },
    };
  }

  function getAddToColumn() {
    return {
      title: "",
      width: 200,
      className: "create-task-column",
      render: (_, item) => {
        if (item.resultingTaskId && item.resultingTaskId !== "nothing") {
          return (
            <>
              <Link to={`/tasks/${item.resultingTaskId}`}>
                <Button data-cy="line-item-go-to-task-button" className="line-item-go-to-task-button">
                  Go to {getSimpleLabel("task")} <br />{" "}
                  <TaskIdTag
                    includeTitle={false}
                    task={{
                      id: item.resultingTaskId,
                      client: quote.client,
                    }}
                  />
                </Button>
              </Link>
              <UnlinkTaskFromQuoteLineItemButton
                taskId={item.resultingTaskId}
                quoteLineItemId={item.id}
                changeLineItem={changeLineItem}
                quoteLineItemTitle={item.title}
                refreshQuote={refreshQuote}
              />
              {isAuthorised(["MANAGE_TEMPLATES"]) && templateDetails.key && (
                <Tooltip title="Add to presets">
                  <Button
                    icon={<PlusOutlined />}
                    className="add-item-to-template"
                    onClick={() => confirmAddItemToPresets(item)}
                    style={{ marginTop: "0.5rem" }}
                  >
                    Add to presets
                  </Button>
                </Tooltip>
              )}
            </>
          );
        }

        if (item.resultingPurchaseOrderId && item.resultingPurchaseOrderId !== "nothing") {
          return (
            <Link to={`/purchase-orders/${item.resultingPurchaseOrderId}`}>
              <Button data-cy="line-item-go-to-purchase-order-button" className="line-item-go-to-task-button">
                Go to purchase order <br />{" "}
                <Tag className="dark-tag">{processIdForDisplay(item.resultingPurchaseOrderId)}</Tag>
              </Button>
            </Link>
          );
        }

        return (
          <div className="buttons-container">
            <Button
              type="primary"
              disabled={quote.isArchived}
              loading={isCreatingTaskFromLineItemId === item.id}
              onClick={() => {
                quoteDetailsPageThis.setState({
                  selectedLineItemId: item.id,
                  isAddToTaskModalVisible: true,
                });
              }}
              data-cy="line-item-add-to-task-button"
            >
              Add to {getSimpleLabel("task")}
            </Button>
            <Button
              disabled={quote.isArchived}
              loading={isCreatingPurchaseOrderFromLineItemId === item.id}
              onClick={() => {
                quoteDetailsPageThis.setState({
                  selectedLineItemId: item.id,
                  isAddQuoteLineItemToPurchaseOrderModalVisible: true,
                });
              }}
              data-cy="line-item-add-to-purchase-order-button"
            >
              Add to purchase order
            </Button>
            {isAuthorised(["MANAGE_TEMPLATES"]) && (
              <Tooltip title="Add to presets">
                <Button
                  icon={<PlusOutlined />}
                  className="add-item-to-template"
                  onClick={() => confirmAddItemToPresets(item)}
                >
                  Add to presets
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
    };
  }

  let lineItemsColumns = [
    getItemIndexColumn(),
    getManuallyInvoicedColumn(),
    getActionsColumn(),
    getInvoicingStatusColumn(),
    getTitleColumn(),
    getDescriptionColumn(),
    getHourlyColumn(),
    getQuantityColumn(),
    getUnitPriceColumn(),
    getAuthorityLevelColumn(),
    getCheckPriceColumn(),
    ...getCustomLineItemFieldColumns.call(quoteDetailsPageThis),
    ...getOrderedLineItemFieldColumns(),

    getAmountColumn(),
    getAddToColumn(),
  ].filter((x) => x);

  return (
    <Table
      rowKey="id"
      rowClassName={(lineItem) => {
        let isHighlighted = false;
        if (urlParams.has("lineItem")) {
          if (urlParams.getAll("lineItem").includes(lineItem.id)) {
            isHighlighted = true;
          }
        }

        return cx({
          "line-item-highlighted": isHighlighted,
          rejected: lineItem.isRejected,
        });
      }}
      dataSource={enhancedQuote.lineItems.items}
      columns={lineItemsColumns}
      pagination={{ hideOnSinglePage: true, pageSize: 500 }}
      tableLayout="fixed"
      bordered={true}
    />
  );
}

export default memo(QuoteLineItemsTable, (prevProps, nextProps) => {
  const fieldsToCheckOnProps = ["isUnderReview", "isDisabled", "numberForPreviewRefresh"];

  for (let field of fieldsToCheckOnProps) {
    if (prevProps[field] !== nextProps[field]) {
      return false;
    }
  }

  let fieldsToCheckOnQuote = [
    "status",
    "reviewStatus",
    "isArchived",
    "reviewApprovedAt",
    "reviewSecondaryStatus",
    "itemSubscription",
    "assignedTo",
    "author",
    "randomNumber",
    "collectionSubscription",
    "taxInclusive",
    "taxRate",
    "currency",
  ];

  for (let field of fieldsToCheckOnQuote) {
    if (prevProps.quote[field] !== nextProps.quote[field]) {
      return false;
    }
  }

  return true;
});
