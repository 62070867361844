import React from "react";
import { Button, Typography, Divider, Progress, message } from "antd";
import cx from "classnames";

import "./UploadProgressView.scss";

export default class UploadProgressView extends React.Component {
  render() {
    const { pendingFiles, parentState, includeOverallProgress = true, failedUploads = [] } = this.props;
    const overallTotal = pendingFiles.reduce((sum, file) => sum + file.size, 0);
    const overallLoaded = pendingFiles.reduce((sum, file, i) => {
      const progress = parentState[`progress-${file.fullPath}`];
      const loadedBytes = progress ? progress.loaded : 0;
      return sum + loadedBytes;
    }, 0);
    let overallProgress = Math.floor((overallLoaded / overallTotal) * 100);
    let warningProps = {};
    let uploadMessage = "";
    const failedUploadsFileNames = failedUploads?.map((file) => file?.name);

    let overallStatus = "normal";
    if (overallProgress === 100) {
      overallStatus = "success";
    }

    if (failedUploads?.length > 0 && pendingFiles.length > 0) {
      overallStatus = "warning";
      warningProps.strokeColor = "#ffbc0d";
      warningProps.style = {
        color: "#ffbc0d",
      };
      warningProps.status = "exception";
      warningProps.fill = "#ffbc0d";
      warningProps.color = "#ffbc0d";
    }

    // This means all the uploads have failed
    if (failedUploads?.length > 0 && pendingFiles.length === 0) {
      overallProgress = 100;
      overallStatus = "exception";
    }

    if (overallProgress === 100) {
      if (overallStatus === "success") {
        uploadMessage = "Upload is finished";
      } else if (overallStatus === "warning") {
        uploadMessage = "Upload is finished, but some files have failed to upload";
      } else if (overallStatus === "exception") {
        uploadMessage = "All files have failed to upload";
      }
    } else {
      uploadMessage = "Uploading...";
    }

    if (overallStatus === "success" && overallProgress === 100) {
      message.success("All files have been uploaded");
      if (this.props.onDone && typeof this.props.onDone === "function") {
        this.props.onDone();
        return null;
      }
    }

    let filesWithProgress = [...pendingFiles]
      .map((item, i) => {
        const hasError = parentState[`error-${item.fullPath}`];
        const progress = parentState[`progress-${item.fullPath}`];
        const progressPercent = progress ? Math.floor((progress.loaded / progress.total) * 100) : 0;
        return {
          ...item,
          progressPercent,
          hasError: !!hasError,
        };
      })
      .sort((a, b) => {
        if (a.hasError && !b.hasError) {
          return -1;
        }
        if (!a.hasError && b.hasError) {
          return 1;
        }
        if (a.progressPercent === b.progressPercent) {
          return a.fullPath.toLowerCase() < b.fullPath.toLowerCase() ? -1 : 1;
        }
        return b.progressPercent - a.progressPercent;
      });

    return (
      <div className="upload-progress-view">
        {includeOverallProgress && (
          <div className={cx("overall-progress", overallStatus)}>
            <Progress type="circle" percent={overallProgress} width={80} status={overallStatus} {...warningProps} />
            <Typography.Text className={cx("message", overallStatus)}>{uploadMessage}</Typography.Text>
            {overallProgress === 100 && (
              <Button type="primary" onClick={this.props.onDone} data-cy="done-button">
                Done
              </Button>
            )}
            <Divider />
          </div>
        )}

        {[...filesWithProgress, ...failedUploads].map((file, i) => {
          let status;
          let strokeColor = {
            "0%": "#69c5ec",
            "100%": "#1590c5",
          };
          if (file.progressPercent === 100) {
            status = "success";
            strokeColor = {
              "0%": "#94d475",
              "100%": "#52c41a",
            };
          } else if (file.progressPercent === 0) {
            status = "normal";
          } else {
            status = "active";
          }
          if (file.hasError) {
            strokeColor = "#ff0000";
            status = "exception";
          }
          if (failedUploadsFileNames.includes(file.name)) {
            strokeColor = {
              "0%": "#ff4d4f",
              "100%": "#ff4d4f",
            };
            status = "exception";
          }

          return (
            <div className="pending-file-item" key={i}>
              <Typography.Text className="file-name">{file.fullPath}</Typography.Text>
              <Progress
                className="progress-bar"
                strokeColor={strokeColor}
                status={status}
                percent={file.hasError ? 100 : file.progressPercent}
              />
            </div>
          );
        })}
      </div>
    );
  }
}
