import { Typography, Menu, Dropdown } from "antd";
import cx from "classnames";

import { isAuthorised } from "common/permissions";
import useWindowWidth from "common/hooks/useWindowWidth";

import Avatar from "Avatar/Avatar";

import "./AvatarList.scss";

export const AVATAR_WIDTH = 32;
const MIN_AVATARS_TO_DISPLAY = 2;
const MAX_AVATARS_TO_DISPLAY = 30;

export default function AvatarList({
  users,
  onClick,
  selectedUsers,
  containerWidthToSubtract = 0,
  minAvatarsToDisplay = undefined,
  maxAvatarsToDisplay = undefined,
  includeUnassigned = true,
}) {
  const windowWidth = useWindowWidth();

  if (!users || users.length === 0) {
    return null;
  }

  let unassignedUser;
  if (includeUnassigned) {
    unassignedUser = { id: "unassigned" };
  }

  let usersToDisplay = [users[0], unassignedUser, ...users.slice(1)].filter((user) => {
    if (!user) {
      return false;
    }

    if (isAuthorised(["WITHOUT_DENY.EXCLUDE_FROM_FILTERS"], user, true)) {
      return false;
    }

    return true;
  });

  let avatarsToDisplay = minAvatarsToDisplay || MIN_AVATARS_TO_DISPLAY;
  if (windowWidth > 850 && containerWidthToSubtract) {
    const avatarContainerMaxWidth = windowWidth - containerWidthToSubtract; // to account for the buttons and paddings
    avatarsToDisplay = Math.max(
      minAvatarsToDisplay || MIN_AVATARS_TO_DISPLAY,
      Math.min(maxAvatarsToDisplay || MAX_AVATARS_TO_DISPLAY, Math.floor(avatarContainerMaxWidth / AVATAR_WIDTH))
    );
  }

  let trimmedUserList = usersToDisplay;
  let othersList = [];

  if (trimmedUserList.length > avatarsToDisplay) {
    trimmedUserList = usersToDisplay.slice(0, avatarsToDisplay);
    othersList = usersToDisplay.slice(avatarsToDisplay);
  }

  let othersElement = null;
  let dropdownMenuElement;
  if (othersList.length > 0) {
    let atLeastOneInOthersIsSelected = othersList.some((user) => selectedUsers?.includes(user.id));
    dropdownMenuElement = (
      <Menu>
        {othersList.map((user) => {
          let additionalMenuProps = {};
          if (onClick && typeof onClick === "function") {
            additionalMenuProps.onClick = onClick;
          }
          if (selectedUsers?.includes(user.id)) {
            additionalMenuProps.selected = true;
          }
          return (
            <Menu.Item key={user ? user.id : "unassigned"}>
              <Avatar
                user={user}
                {...additionalMenuProps}
                showLabel={true}
                disableUserCard={true}
                showHoverAnimation={false}
                className="in-dropdown"
              />
            </Menu.Item>
          );
        })}
      </Menu>
    );
    othersElement = (
      <Dropdown overlay={dropdownMenuElement} overlayClassName="avatar-list-dropdown-menu-overlay">
        <div className={cx("others-container", { "is-selected": atLeastOneInOthersIsSelected })}>
          <Typography.Text className="others">
            <span className="plus-sign">+</span>
            {othersList.length}
          </Typography.Text>
        </div>
      </Dropdown>
    );
  }

  const list = trimmedUserList.map((user) => {
    let additionalAvatarProps = {};
    if (onClick && typeof onClick === "function") {
      additionalAvatarProps.onClick = onClick;
    }
    if (selectedUsers && selectedUsers.includes(user.id)) {
      additionalAvatarProps.selected = true;
    }
    return (
      <Avatar key={`avatar-user-${user ? user.id : "unassigned"}`} user={user} {...additionalAvatarProps} withBorder />
    );
  });
  return (
    <div className="avatar-list" data-cy="avatar-list">
      {list}
      {othersElement}
    </div>
  );
}
