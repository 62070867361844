import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Form, Modal, Button, Radio, Typography, Input } from "antd";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";

import { getQuoteRevisionName } from "common/naming";

import "./CreateQuoteRevisionModal.scss";

export function CreateQuoteRevisionModal({ onClose, apiUser, quote, organisationDetails }) {
  const [isLoading, setIsLoading] = useState(false);
  const [defaultName, setDefaultName] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    getQuoteRevisionName({
      organisation: quote.organisation,
      quote,
    }).then((name) => {
      setDefaultName(name);
      form.setFieldsValue({ name });
    });
  }, []); // eslint-disable-line

  async function onSubmit({ name }) {
    setIsLoading(true);
    await callGraphQLSimple({
      message: "Failed to record activity item",
      mutation: "createQuoteActivityItem",
      variables: {
        input: {
          quoteId: quote.id,
          total: quote.total,
          type: "REVISION_CREATED",
          organisation: quote.organisation,
          author: apiUser.id,
          content: name,
        },
      },
    });

    await callGraphQLSimple({
      queryCustom: "updateQuote",
      variables: {
        input: {
          id: quote.id,
          currentRevisionName: name,
        },
      },
    });

    onClose();
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={<Typography.Text>Create revision for {getSimpleLabel("quote")}</Typography.Text>}
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-quote-revision-modal"
    >
      <Form
        {...layout}
        form={form}
        initialValues={{
          name: defaultName,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Revision"
          name="name"
          rules={[
            {
              required: true,
              message: "You must specify a revision name",
            },
          ]}
        >
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default withRouter(
  withSubscriptions({
    Component: CreateQuoteRevisionModal,
    subscriptions: ["organisationDetails"],
  })
);
