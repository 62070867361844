import moment from "moment";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";

export default function getNumberOfHoursForTimelineBlockOnDay({ timelineBlock, targetDate }) {
  let realDurationHours;
  let blockStartDate = moment(timelineBlock.startDate);

  let durationOnFirstDay = TIMELINE_DEFAULT_HOURS_IN_A_DAY - timelineBlock.startHours;
  let durationAfterFirstDay = timelineBlock.durationHours - durationOnFirstDay;
  let durationAfterFirstDayInDays = Math.ceil(durationAfterFirstDay / TIMELINE_DEFAULT_HOURS_IN_A_DAY);

  let blockEndDate = moment(blockStartDate).add(durationAfterFirstDayInDays, "days");

  if (blockEndDate.isBefore(targetDate, "day")) {
    return null;
  } else if (moment(timelineBlock.startDate).isAfter(targetDate, "day")) {
    return null;
  } else if (moment(timelineBlock.startDate).isBefore(targetDate, "day")) {
    if (moment(blockEndDate).isAfter(targetDate, "day")) {
      realDurationHours = TIMELINE_DEFAULT_HOURS_IN_A_DAY;
    } else {
      let daysToSubtract = targetDate.diff(blockStartDate, "days");
      durationAfterFirstDay -= (daysToSubtract - 1) * TIMELINE_DEFAULT_HOURS_IN_A_DAY;
      realDurationHours = durationAfterFirstDay;
    }
  } else {
    if (timelineBlock.startHours + timelineBlock.durationHours > TIMELINE_DEFAULT_HOURS_IN_A_DAY) {
      realDurationHours = TIMELINE_DEFAULT_HOURS_IN_A_DAY - timelineBlock.startHours;
    } else {
      realDurationHours = timelineBlock.durationHours;
    }
  }

  return realDurationHours;
}
