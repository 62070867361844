import { useState } from "react";
import { Select, Modal, Form, Button, InputNumber, Alert } from "antd";
import moment from "moment";

import { callGraphQLSimple } from "common/apiHelpers";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";

type Props = {
  user: any;
  onClose: () => void;
  allowanceType: "HOLIDAY" | "SICK";
  isEditingAllowance: boolean;
  selectedAllowance?: Allowance;
  targetIntervals: any[];
};

type Allowance = {
  allowance: number;
  type: "HOLIDAY" | "SICK";
  intervalId: string;
  year: number;
};

type FormValues = Omit<Allowance, "type">;

export default function AddAllowanceModal({
  user,
  onClose,
  allowanceType,
  isEditingAllowance,
  selectedAllowance,
  targetIntervals,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [error, setError] = useState<any>();

  let firstIntervalWeAreIn = targetIntervals.find((interval) => {
    return moment().isBetween(interval.startDate, interval.endDate);
  });

  async function onSubmit() {
    setIsLoading(true);
    setError(undefined);

    const formValues: FormValues = form.getFieldsValue();
    formValues.allowance = formValues.allowance * TIMELINE_DEFAULT_HOURS_IN_A_DAY;

    try {
      let allowances: Allowance[] = [];

      if (isEditingAllowance) {
        allowances = user.allowances.map((allowance: Allowance) => {
          let itemMatches = false;
          if (selectedAllowance?.intervalId) {
            itemMatches = allowance?.intervalId === selectedAllowance?.intervalId;
          } else {
            itemMatches = allowance?.year === selectedAllowance?.year;
          }
          if (itemMatches && allowance?.type === allowanceType) {
            return { ...formValues, type: selectedAllowance!.type };
          }

          return allowance;
        });
      } else {
        if (!user.allowances || user.allowances.length === 0) {
          allowances.push({ ...formValues, type: allowanceType });
        } else {
          allowances = [...(user.allowances || []), { ...formValues, type: allowanceType }];
        }
      }

      await callGraphQLSimple({
        message: "Failed to add allowance",
        mutation: "updateUser",
        variables: {
          input: {
            id: user.id,
            allowances,
          },
        },
      });

      setIsLoading(false);
      onClose();
    } catch (e: any) {
      setError(e.message);
      setIsLoading(false);
    }
  }

  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 10,
      span: 16,
    },
  };

  return (
    <Modal
      open={true}
      title={`${isEditingAllowance ? "Edit" : "Add"} ${allowanceType.toLowerCase()} allowance`}
      maskClosable
      closable
      onCancel={onClose}
      footer={null}
    >
      <Form
        {...layout}
        form={form}
        onFinish={onSubmit}
        initialValues={{
          intervalId: selectedAllowance?.intervalId! || firstIntervalWeAreIn?.id! || targetIntervals[0]?.id,
          allowance: selectedAllowance ? selectedAllowance.allowance / TIMELINE_DEFAULT_HOURS_IN_A_DAY : 0,
        }}
      >
        <Form.Item
          label="Interval"
          name="intervalId"
          rules={[
            {
              validator: async (_, intervalId) => {
                if (!intervalId) {
                  throw new Error("Please select an interval");
                }
                const intervalIsAlreadyUsed = user.allowances?.find(
                  (allowance: Allowance) => allowance?.intervalId === intervalId && allowance?.type === allowanceType
                );

                if (intervalIsAlreadyUsed && !isEditingAllowance) {
                  throw new Error("There is already an allowance for the specified interval");
                }
              },
              required: true,
            },
          ]}
        >
          <Select>
            {targetIntervals.map((interval: any) => (
              <Select.Option key={interval.id} value={interval.id}>
                {interval.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Number of days" name="allowance">
          <InputNumber min={0} data-cy="allowance-amount-input" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="add-allowance-submit-button">
            {isLoading ? "Submitting..." : "Submit"}
          </Button>
        </Form.Item>

        {error ? <Alert message={error} type="error" showIcon /> : null}
      </Form>
    </Modal>
  );
}
