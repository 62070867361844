import { useState } from "react";
import { Button, Table, Modal } from "antd";
// import { Storage } from "aws-amplify";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import Card from "Card/Card";
import UploadFontModal from "Modals/UploadFontModal/UploadFontModal";

import "./TemplateFonts.scss";

export default function TemplateFonts({ organisationDetails, apiUser }) {
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  // await Storage.put(newTemplateDetails.key, fileToUpload);

  async function confirmDeleteFont(variable) {
    Modal.confirm({
      title: "Confirm font delete",
      content: (
        <>
          Are you sure you want to delete <b>{variable.name}</b>?
        </>
      ),
      onOk: async () => {
        window.callGraphQLSimple({
          message: "Failed to delete font",
          mutation: "deleteVariable",
          variables: {
            input: {
              id: variable.id,
            },
          },
        });

        await window.callGraphQLSimple({
          message: "Failed to refresh organisation details",
          mutation: "updateOrganisation",
          variables: {
            input: {
              id: organisationDetails.id,
              itemSubscription: Math.floor(Math.random() * 1000000),
            },
          },
        });
      },
    });
  }

  return (
    <>
      <Card
        title="Fonts"
        withSpace
        className="template-fonts"
        actions={
          <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setIsUploadModalVisible(true)}>
            Add font
          </Button>
        }
      >
        <Table
          pagination={{ hideOnSinglePage: true, pageSize: 50 }}
          rowKey="id"
          showHeader={false}
          dataSource={organisationDetails.variables.items.filter((variable) => variable.type === "FONT")}
          columns={[
            { title: "Name", dataIndex: "name", key: "name", align: "left" },
            {
              key: "actions",
              align: "right",
              width: "60",
              render: (_, row) => (
                <Button type="clear" icon={<DeleteOutlined />} onClick={() => confirmDeleteFont(row)} />
              ),
            },
          ]}
        />
      </Card>
      {isUploadModalVisible && (
        <UploadFontModal organisationDetails={organisationDetails} onClose={() => setIsUploadModalVisible(false)} />
      )}
    </>
  );
}
