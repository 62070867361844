import { useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Context } from "common/context";

import { Route } from "react-router-dom";

export default function SecureRoute({ children, ...props }) {
  const location = useLocation();
  const history = useHistory();
  const { apiUser } = useContext(Context);

  useEffect(() => {
    const path = location.pathname;
    if (!apiUser && path !== "/sign-in") {
      console.log("redirecting to sign in page");
      history.push("/sign-in");
    }
  }, [location, apiUser, history]);

  return <Route {...props}>{children}</Route>;
}
