import { useState } from "react";

import { Typography, Select, InputNumber, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import UsersFilter from "UsersFilter/UsersFilter";
import Input from "Input/Input";
import ClientLogo from "ClientLogo/ClientLogo";
import Card from "Card/Card";
import cx from "classnames";
import { processIdForDisplay, getLabel } from "common/helpers";
import { getSimpleLabel } from "common/labels";
import Avatar from "Avatar/Avatar";
import { PURCHASE_ORDER_STATUSES } from "common/constants";
import ReviewTarget from "ReviewTarget/ReviewTarget";
import ClientContactModal from "Modals/ClientContactModal/ClientContactModal";
import AddressModal from "Modals/AddressModal/AddressModal";

import "./PurchaseOrderMetadata.scss";

export default function PurchaseOrderMetadata(props) {
  const {
    purchaseOrder,
    clientDetails,
    changeAttribute,
    debouncedChangeAttribute,
    users,
    clients,
    isDisabled,
    sortedSupplierContacts,
    projects,
    supplier,
  } = props;

  const client = clients.find((client) => client.id === purchaseOrder.clientId);

  const [isSupplierContactModalVisible, setIsSupplierContactModalVisible] = useState();
  const [isSupplierAddressModalVisible, setIsSupplierAddressModalVisible] = useState();

  function getExcludedAssigneeList() {
    return [];
  }

  async function onSupplierContactModalSubmit(contactDetails) {
    await window.callGraphQLSimple({
      message: "Failed to add supplier contact",
      mutation: "updateSupplier",
      variables: {
        input: {
          id: supplier.id,
          contacts: [...(supplier.contacts || []), contactDetails],
        },
      },
    });

    setIsSupplierContactModalVisible(false);

    changeAttribute({
      fieldName: "supplierContact",
      value: contactDetails.id,
    });
  }

  async function onSupplierAddressModalSubmit({ addressDetails }) {
    await window.callGraphQLSimple({
      message: "Failed to create address",
      mutation: "updateSupplier",
      variables: {
        input: {
          id: supplier.id,
          addresses: [...(supplier.addresses || []), addressDetails],
        },
      },
    });

    setIsSupplierAddressModalVisible(false);

    changeAttribute({
      fieldName: "supplierAddress",
      value: addressDetails.id,
    });
  }

  const project = projects.find((x) => x.id === purchaseOrder.projectId);

  return (
    <>
      <Card className={cx("metadata-card")}>
        <div className="metadata-container">
          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Purchase order ID:</Typography.Paragraph>
            <Typography.Paragraph className="item-value item-value-static">
              {processIdForDisplay(purchaseOrder.id)}
            </Typography.Paragraph>
          </div>
          <Link className="metadata-item clickable" to={`/projects/${purchaseOrder.projectId}`}>
            <Typography.Paragraph className="item-label">Project:</Typography.Paragraph>
            <Typography.Paragraph className="item-value">{project.title}</Typography.Paragraph>
          </Link>
          <Link className="metadata-item clickable" to={`/clients/${clientDetails.id}`}>
            <Typography.Paragraph className="item-label">
              {getLabel({
                id: "Client",
                defaultValue: "Client",
              })}
              :
            </Typography.Paragraph>
            <ClientLogo client={client} size="small" />
          </Link>
          <Link className="metadata-item clickable" to={`/suppliers/${supplier.id}`}>
            <Typography.Paragraph className="item-label">Supplier:</Typography.Paragraph>
            <ClientLogo client={supplier} size="small" />
          </Link>
          {purchaseOrder.quoteId && (
            <div className="metadata-item">
              <Typography.Paragraph className="item-label">{getSimpleLabel("Quote")}:</Typography.Paragraph>
              <Link to={`/quotes/${purchaseOrder.quoteId}`}>
                <Tag className="dark-tag">{processIdForDisplay(purchaseOrder.quoteId)}</Tag>
              </Link>
            </div>
          )}
          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Assigned to:</Typography.Paragraph>
            <Typography.Paragraph className="item-value">
              {purchaseOrder.isArchived || purchaseOrder.status === "ACCEPTED" ? (
                <Avatar user={users.find((x) => x.id === purchaseOrder.assignedTo)} showLabel={true} />
              ) : (
                <UsersFilter
                  className="assigned-to-picker"
                  activateOnHover={true}
                  value={purchaseOrder.assignedTo}
                  onChange={(value) => changeAttribute({ fieldName: "assignedTo", value })}
                  excludeList={getExcludedAssigneeList()}
                  suffixIcon={null}
                  maxLabelLength={22}
                  data-cy="assigned-to-picker"
                />
              )}
            </Typography.Paragraph>
          </div>
          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Status:</Typography.Paragraph>
            <Select
              disabled={purchaseOrder.isArchived}
              onChange={(value) => changeAttribute({ fieldName: "status", value })}
              value={purchaseOrder.status}
              data-cy="purchase-order-status-picker"
            >
              {PURCHASE_ORDER_STATUSES.map((status) => {
                const supplierContactDetails = supplier?.contacts?.find(
                  (x) => x?.id === purchaseOrder?.supplierContact
                );
                let isDisabled = false;
                let label = status.label;

                if (status.value === "SENT" && !supplierContactDetails) {
                  isDisabled = true;
                  label = "Sent - needs supplier contact";
                }

                return (
                  <Select.Option
                    key={status.value}
                    value={status.value}
                    disabled={isDisabled}
                    style={isDisabled ? { opacity: "0.5" } : { opacity: 1 }}
                  >
                    {label}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          {/* {organisationDetails.settings?.purchaseOrder?.isValidFromHidden !== true && (
            <div className="metadata-item">
              <Typography.Paragraph className="item-label">Valid from:</Typography.Paragraph>
              <DatePicker
                format="DD-MM-YYYY"
                data-cy="valid-from"
                defaultValue={purchaseOrder.validFrom && moment(purchaseOrder.validFrom)}
                disabled={isDisabled}
                onChange={(value) =>
                  changeAttribute({
                    fieldName: "validFrom",
                    value: moment(value).startOf("day"),
                  })
                }
              />
            </div>
          )}
          {organisationDetails.settings?.purchaseOrder?.isValidUntilHidden !== true && (
            <div className="metadata-item">
              <Typography.Paragraph className="item-label">Valid until:</Typography.Paragraph>
              <DatePicker
                format="DD-MM-YYYY"
                data-cy="valid-until"
                defaultValue={purchaseOrder.validUntil && moment(purchaseOrder.validUntil)}
                disabled={isDisabled}
                onChange={(value) =>
                  changeAttribute({
                    fieldName: "validUntil",
                    value: moment(value).endOf("day"),
                  })
                }
              />
            </div>
          )} */}

          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Supplier address:</Typography.Paragraph>
            <ReviewTarget name="supplierAddress" {...props} visible={purchaseOrder.isUnderReview}>
              <Select
                value={purchaseOrder.supplierAddress}
                disabled={isDisabled}
                data-cy="supplier-address-dropdown"
                onChange={(value) => {
                  if (value === "ADD_NEW") {
                    setIsSupplierAddressModalVisible(true);
                    return;
                  }
                  changeAttribute({
                    fieldName: "supplierAddress",
                    value,
                  });
                }}
              >
                <Select.Option key="add-new" value="ADD_NEW">
                  <Typography.Text>
                    <b>
                      <PlusOutlined /> Add new supplier address
                    </b>
                  </Typography.Text>
                </Select.Option>
                {(supplier.addresses || []).map((address) => {
                  return (
                    <Select.Option key={address.id} value={address.id}>
                      {address.id}
                    </Select.Option>
                  );
                })}
              </Select>
            </ReviewTarget>
          </div>

          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Supplier contact:</Typography.Paragraph>
            <ReviewTarget name="supplier-contact" {...props} visible={purchaseOrder.isUnderReview}>
              <Select
                data-cy="supplier-contact-dropdown"
                value={purchaseOrder.supplierContact}
                disabled={isDisabled}
                popupClassName="client-contact-dropdown"
                onChange={(value) => {
                  if (value === "ADD_NEW") {
                    setIsSupplierContactModalVisible(true);
                    return;
                  }
                  changeAttribute({
                    fieldName: "supplierContact",
                    value,
                  });
                }}
              >
                <Select.Option key="add-new" value="ADD_NEW">
                  <Typography.Text>
                    <b>
                      <PlusOutlined /> Add new supplier contact
                    </b>
                  </Typography.Text>
                </Select.Option>
                {sortedSupplierContacts?.map((contact, i) => {
                  return (
                    <Select.Option key={i} value={contact.id}>
                      {contact.firstName} {contact.lastName} ({contact.id})
                    </Select.Option>
                  );
                })}
              </Select>
            </ReviewTarget>
          </div>

          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Reference:</Typography.Paragraph>
            <ReviewTarget name="purchase-order-reference" {...props} visible={purchaseOrder.isUnderReview}>
              <Input
                className="item-value"
                defaultValue={purchaseOrder.reference}
                disabled={isDisabled}
                onChange={(value) => changeAttribute({ fieldName: "reference", value })}
                showBorder
                fullWidth
              />
            </ReviewTarget>
          </div>

          {/* <div className="metadata-item">
          <Typography.Paragraph className="item-label">Currency:</Typography.Paragraph>
          <Select
            data-cy="currency-picker"
            value={purchaseOrder.currency}
            disabled={purchaseOrder.status === "ACCEPTED"  || purchaseOrder.isArchived}
            onChange={(value) => changeAttribute({ fieldName: "currency", value })}
          >
            {CURRENCIES.map((currency) => {
              return (
                <Select.Option key={currency.value} value={currency.value}>
                  {currency.value}
                </Select.Option>
              );
            })}
          </Select>
        </div> */}
          {/* <div className="metadata-item">
            <Typography.Paragraph className="item-label">Amounts are:</Typography.Paragraph>
            <ReviewTarget name="taxInclusive" {...props} visible={purchaseOrder.isUnderReview}>
              <Select
                disabled={isDisabled}
                value={purchaseOrder.taxInclusive}
                data-cy="tax-dropdown"
                onChange={(value) => {
                  changeAttribute({
                    fieldName: "taxInclusive",
                    value,
                  });
                }}
              >
                <Select.Option key="true" value={true}>
                  Tax Inclusive
                </Select.Option>
                <Select.Option key="false" value={false}>
                  Tax Exclusive
                </Select.Option>
              </Select>
            </ReviewTarget>
          </div> */}
          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Tax rate:</Typography.Paragraph>
            <ReviewTarget name="taxRate" {...props} visible={purchaseOrder.isUnderReview}>
              <InputNumber
                min={0}
                disabled={isDisabled}
                defaultValue={purchaseOrder.taxRate}
                onChange={(newValue) =>
                  debouncedChangeAttribute({
                    fieldName: "taxRate",
                    value: newValue,
                    includeRecalculation: true,
                  })
                }
              />
            </ReviewTarget>
          </div>
        </div>
      </Card>
      {isSupplierContactModalVisible && (
        <ClientContactModal
          onClose={() => setIsSupplierContactModalVisible(false)}
          onSubmit={onSupplierContactModalSubmit}
          parent={supplier}
        />
      )}
      {isSupplierAddressModalVisible && (
        <AddressModal onClose={() => setIsSupplierAddressModalVisible(false)} onSubmit={onSupplierAddressModalSubmit} />
      )}
    </>
  );
}
