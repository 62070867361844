import { Select } from "antd";
import InfoItem from "InfoItem/InfoItem";
import { getFieldsForEditorByDataSource } from "common/documentEditorDataSources/aggregator";

export default function DataSourceOverrideOptions({
  object,
  dataSourceFieldName,
  dataSourceOverrideFieldName,
  fileTypeDetails,
  form,
  organisationDetails,
  onChange,
}) {
  let dataSourceOverrideLabel = "";
  let dataSourceOverrideOptions =
    ["file", "form"].includes(object[dataSourceFieldName]) &&
    getFieldsForEditorByDataSource({
      dataSource: "form",
      form,
      organisationDetails,
    }).filter((field) => {
      if (
        field.dataSourceField?.startsWith("files_") ||
        (field.id.startsWith("files_") && !field.fieldTypes?.includes("repeatFor"))
      ) {
        return true;
      }

      return false;
    });

  if (
    !fileTypeDetails.isDocumentTemplate ||
    !object[dataSourceFieldName] ||
    !dataSourceOverrideOptions ||
    dataSourceOverrideOptions?.length === 0
  ) {
    return null;
  }

  let dataSource = fileTypeDetails.dataSources.find((dataSource) => dataSource === object[dataSourceFieldName]);

  if (dataSource) {
    dataSourceOverrideLabel = dataSource.toLowerCase();
  }

  return (
    <InfoItem
      label={`Which ${dataSourceOverrideLabel}? (optional)`}
      includeColon={false}
      value={
        <Select
          allowClear
          value={object[dataSourceOverrideFieldName]}
          placeholder="Default"
          showSearch
          filterOption={(searchValue, { label }) => {
            return label?.toLowerCase().includes(searchValue?.toLowerCase());
          }}
          onChange={(_, option) => {
            if (onChange && typeof onChange === "function") {
              onChange({ dataSourceOverrideFieldName, value: option?.value });
            }
          }}
        >
          {dataSourceOverrideOptions.map((option, i) => {
            return (
              <Select.Option key={i} value={option.value} label={option.label}>
                {option.label}
              </Select.Option>
            );
          })}
        </Select>
      }
    />
  );
}
