import moment from "moment";
import { Typography } from "antd";
import DatePicker from "DatePicker/DatePicker";
import { callGraphQLSimple } from "common/apiHelpers";
import { TaskRevision } from "common/types";
import { isAuthorised } from "common/permissions";

type Props = {
  taskRevision: TaskRevision;
};

export default function TaskRevisionRequestedDate({ taskRevision }: Props) {
  async function onChange(momentDate, stringDate) {
    let formattedDate;

    if (stringDate.length === 0) {
      formattedDate = null;
    } else {
      formattedDate = momentDate.format("YYYY-MM-DD");
    }

    await callGraphQLSimple({
      message: "Failed to update requested date",
      queryCustom: "updateTaskRevision",
      variables: {
        input: {
          id: taskRevision.id,
          requestedDate: formattedDate,
        },
      },
    });

    await callGraphQLSimple({
      message: "Failed to update requested date",
      queryCustom: "updateTask",
      variables: {
        input: {
          id: taskRevision.taskId,
          collectionSubscription: Math.floor(Math.random() * 100000),
        },
      },
    });
  }

  let shouldDisplayPicker = !taskRevision.isReadOnly && isAuthorised(["TASK_DETAILS.EDIT_REQUESTED_DATE"]);

  return (
    <>
      {!shouldDisplayPicker ? (
        taskRevision.requestedDate ? (
          moment(taskRevision.requestedDate).format("DD MMM YYYY")
        ) : (
          "Not Set"
        )
      ) : (
        <DatePicker
          key={taskRevision.requestedDate}
          format="DD-MM-YYYY"
          className="active-on-hover"
          defaultValue={taskRevision.requestedDate ? moment(taskRevision.requestedDate) : undefined}
          onChange={onChange}
          placeholder="Not Set"
          suffixIcon={null}
        />
      )}
    </>
  );
}
