import { useEffect, useState } from "react";
import { Form, Modal, Button } from "antd";

import moment from "moment";

import withSubscriptions from "common/withSubscriptions";

import TimesheetBlocksTable from "TimesheetBlocksTable/TimesheetBlocksTable";

import "./AddTimesheetBlockToInvoiceModal.scss";
import { getSimpleLabel } from "common/labels";

export function AddTimesheetBlockToInvoiceModal({
  onClose,
  apiUser,
  visible,
  projectId,
  fetchAndSetTimesheetBlocks,
  timesheetBlocks,
  onTimesheetBlockSelected,
  refreshInvoice,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadTimesheetBlocks();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function loadTimesheetBlocks() {
    setIsLoading(true);
    await fetchAndSetTimesheetBlocks({
      organisation: apiUser.organisation,
      startAt: moment().subtract(100, "year").toISOString(),
      endAt: moment().add(1, "year").toISOString(),

      filter: {
        projectId: {
          eq: projectId,
        },
        invoiceId: {
          eq: "nothing",
        },
        invoicingStatus: {
          attributeExists: false,
        },
      },
    });
    setIsLoading(false);
  }

  return (
    <Modal
      maskClosable={false}
      title={`Add timesheet block to invoice - showing all un-invoiced timesheet blocks for ${getSimpleLabel(
        "project"
      )} ${projectId}`}
      open={visible}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      footer={null}
      className="add-timesheet-block-to-invoice-modal"
    >
      <TimesheetBlocksTable
        triggerRefresh={refreshInvoice}
        customActions={(row, index) => (
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              onTimesheetBlockSelected(row.timesheetBlock);
            }}
            data-cy="add-timesheet-block-to-invoice-button"
            data-row-index={index}
          >
            Add to invoice
          </Button>
        )}
        blocks={timesheetBlocks}
        includeInvoicingStatus={false}
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default withSubscriptions({
  Component: AddTimesheetBlockToInvoiceModal,
  subscriptions: ["tasks", "projects", "sprints", "clients", "timesheetBlocks"],
});
