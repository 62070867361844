import React from "react";
import { Button, Table, Modal } from "antd";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";

import Card from "Card/Card";
import AttachmentTemplateModal from "Modals/AttachmentTemplateModal/AttachmentTemplateModal";

import "./AttachmentTemplateList.scss";

export class AttachmentTemplateList extends React.Component {
  state = {
    isAttachmentTemplateModalVisible: false,
    selectedAttachmentTemplate: null,
  };

  onAttachmentTemplateModalSubmit = async (newAttachmentTemplate) => {
    const { organisationDetails, type } = this.props;
    const { selectedAttachmentTemplate } = this.state;

    let newAttachmentTemplates;

    if (selectedAttachmentTemplate) {
      newAttachmentTemplates = (organisationDetails?.settings?.[type]?.attachmentTemplates || []).map(
        (attachmentTemplate) => {
          return attachmentTemplate.id === newAttachmentTemplate.id ? newAttachmentTemplate : attachmentTemplate;
        }
      );
    } else {
      newAttachmentTemplates = [
        ...(organisationDetails?.settings?.[type]?.attachmentTemplates || []),
        {
          ...newAttachmentTemplate,
          id: `${newAttachmentTemplate.name.replace(/[^a-z0-9]/gi, "").toLowerCase()}-${Date.now()}`,
        },
      ];
    }

    await window.callGraphQLSimple({
      message: "Failed to save attachment template",
      mutation: "updateOrganisation",
      variables: {
        input: {
          id: organisationDetails.id,
          settings: {
            ...organisationDetails.settings,
            [type]: {
              ...organisationDetails.settings?.[type],
              attachmentTemplates: newAttachmentTemplates,
            },
          },
        },
      },
    });
  };

  confirmDeleteAttachmentTemplate = async (attachmentTemplate) => {
    const { organisationDetails, type } = this.props;
    Modal.confirm({
      title: "Confirm attachment template delete",
      content: (
        <>
          Are you sure you want to delete attachment template <b>{attachmentTemplate.name}</b>?
        </>
      ),
      onOk: async () => {
        await window.callGraphQLSimple({
          message: "Failed to delete attachment template",
          mutation: "updateOrganisation",
          variables: {
            input: {
              id: organisationDetails.id,
              settings: {
                ...organisationDetails.settings,
                [type]: {
                  ...organisationDetails.settings?.[type],
                  attachmentTemplates: (organisationDetails?.settings?.[type]?.attachmentTemplates || []).filter(
                    (x) => x.id !== attachmentTemplate.id
                  ),
                },
              },
            },
          },
        });
      },
    });
  };

  render() {
    const { isAttachmentTemplateModalVisible, selectedAttachmentTemplate } = this.state;
    const { organisationDetails, type } = this.props;

    const attachmentTemplates = organisationDetails?.settings?.[type]?.attachmentTemplates || [];

    return (
      <Card
        withSpace
        title="Attachment templates"
        className="attachment-template-list"
        actions={
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            data-cy="create-attachment-template-button"
            onClick={() => this.setState({ isAttachmentTemplateModalVisible: true })}
            disabled={attachmentTemplates?.length >= 1}
          >
            Add attachment template
          </Button>
        }
      >
        <Table
          columns={[
            { title: "Name", dataIndex: "name", key: "name", align: "left" },
            { title: "Path", dataIndex: "path", key: "path", align: "left" },
            {
              title: "",
              key: "actions",
              width: 20,
              render: (_, attachmentTemplate) => {
                return (
                  <Button
                    type="clear"
                    icon={<EditOutlined />}
                    onClick={() => {
                      this.setState({
                        isAttachmentTemplateModalVisible: true,
                        selectedAttachmentTemplate: attachmentTemplate,
                      });
                    }}
                  />
                );
              },
            },
            {
              title: "",
              key: "delete",
              width: 20,
              render: (_, attachmentTemplate) => {
                return (
                  <Button
                    type="clear"
                    icon={<DeleteOutlined />}
                    onClick={() => this.confirmDeleteAttachmentTemplate(attachmentTemplate)}
                  />
                );
              },
            },
          ]}
          pagination={{ hideOnSinglePage: true, pageSize: 50 }}
          dataSource={organisationDetails.settings?.[type]?.attachmentTemplates}
        />

        {isAttachmentTemplateModalVisible && (
          <AttachmentTemplateModal
            organisationDetails={organisationDetails}
            onClose={() => this.setState({ isAttachmentTemplateModalVisible: false, selectedAttachmentTemplate: null })}
            attachmentTemplate={selectedAttachmentTemplate}
            onSubmit={this.onAttachmentTemplateModalSubmit}
          />
        )}
      </Card>
    );
  }
}

export default withSubscriptions({
  Component: AttachmentTemplateList,
  subscriptions: ["organisationDetails"],
});
