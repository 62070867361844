import { useState } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Typography, Form, Modal, Input, Button, Select, Checkbox } from "antd";

import withSubscriptions from "common/withSubscriptions";
import { useForceUpdate } from "common/helpers";
import { createRequest, addFormToRequest } from "common/requestHelpers";
import { getSimpleLabel } from "common/labels";
import { callGraphQLSimple } from "common/apiHelpers";
import { isAuthorised } from "common/permissions";

import DatePicker from "DatePicker/DatePicker";
import RequestPicker from "reusableComponents/RequestPicker/RequestPicker";

import "./PlaceRequestModal.scss";

const THRESHOLD_FOR_REQUESTED_DATE_JUSTIFICATION_DAYS = 7;

export function PlaceRequestModal({ history, onClose, organisationDetails, users, apiUser, predefinedFields = {} }) {
  const [formCreate] = Form.useForm();
  const [formChange] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  // we need to force the component to re-render when we change some form values
  const forceUpdate = useForceUpdate();

  const [selectedOption, setSelectedOption] = useState(undefined);

  async function onSubmitCreate(params) {
    try {
      setIsLoading(true);
      const newRequest = await createRequest({ organisationDetails, users, apiUser, ...params });
      history.push(`/requests/${newRequest.id}`);
      onClose();
    } catch (e) {
      console.error(e);
      // nothing we can do, the error message should have been displayed already
    }
    setIsLoading(false);
  }

  async function onSubmitChange(params) {
    if (params?.toBeApprovedBy?.includes(",") || params?.toBeApprovedBy?.includes(" ")) {
      formChange.setFields([
        {
          name: "toBeApprovedBy",
          errors: ["Please provide only one email address"],
        },
      ]);
      forceUpdate();
      return;
    }

    try {
      setIsLoading(true);

      const request = (
        await callGraphQLSimple({
          message: `Cannot fetch ${getSimpleLabel("request")} details`,
          query: "getRequest",
          variables: {
            id: params.requestId,
          },
        })
      ).data.getRequest;

      await addFormToRequest({
        apiUser,
        organisationDetails,
        formType: "REQUEST_CHANGE",
        formName: `Change ${getSimpleLabel("request")} form - ${params.description}`,
        requestedForDate: params.requestedForDate,
        requestedForDateReason: params.requestedForDateReason,
        priorityId: params.priorityId,
        toBeApprovedBy: params.toBeApprovedBy,
        request,
        users,
      });
      history.push(`/requests/${params.requestId}`);
      onClose();
    } catch (e) {
      console.error(e);
      // nothing we can do, the error message should have been displayed already
    }
    setIsLoading(false);
  }

  let title = `Place ${getSimpleLabel("request")}`;

  if (selectedOption === "CREATE") {
    title = `Create ${getSimpleLabel("request")}`;
  } else if (selectedOption === "CHANGE") {
    title = `Change ${getSimpleLabel("request")}`;
  }

  let shouldDisplayRequestedForReasonInput = false;
  let requestedForDate;
  if (selectedOption === "CREATE") {
    requestedForDate = formCreate.getFieldValue("requestedForDate");
  } else if (selectedOption === "CHANGE") {
    requestedForDate = formChange.getFieldValue("requestedForDate");
  }
  if (
    requestedForDate &&
    requestedForDate.diff(moment().startOf("day"), "days") < THRESHOLD_FOR_REQUESTED_DATE_JUSTIFICATION_DAYS
  ) {
    shouldDisplayRequestedForReasonInput = true;
  }

  let commonFormItems = (
    <>
      <Form.Item
        label={getSimpleLabel("request-desired-completion-date")}
        name="requestedForDate"
        rules={[
          {
            required: true,
            message: `You must choose date`,
          },
        ]}
      >
        <DatePicker
          format="DD-MM-YYYY"
          className="date-picker"
          data-cy="requested-for-date"
          onChange={(newDate) => {
            let currentForm;

            if (selectedOption === "CREATE") {
              currentForm = formCreate;
            } else if (selectedOption === "CHANGE") {
              currentForm = formChange;
            }

            currentForm.setFieldsValue({ requestedForDate: newDate });
            forceUpdate();
            currentForm.validateFields(["requestedForDateReason"]);
          }}
        />
      </Form.Item>

      {shouldDisplayRequestedForReasonInput && (
        <Form.Item
          label="Reason for desired date"
          name="requestedForDateReason"
          rules={[
            {
              required: true,
              message: `When the desired date is less than ${THRESHOLD_FOR_REQUESTED_DATE_JUSTIFICATION_DAYS} days away, you must provide a reason`,
            },
          ]}
        >
          <Input autoComplete="off" data-cy="requested-for-date-reason-input" />
        </Form.Item>
      )}

      {organisationDetails.settings?.task?.usesPriority && (
        <Form.Item
          label="Priority"
          name="priorityId"
          rules={[
            {
              required: true,
              message: "You must choose a priority",
            },
          ]}
        >
          <Select style={{ width: "100%" }} placeholder="Not set">
            {organisationDetails.settings?.task?.priorities?.map((priority) => (
              <Select.Option key={priority.id} value={priority.id}>
                {priority.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </>
  );

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={true}
      onCancel={onClose}
      footer={null}
      className="place-request-modal full-screen-on-mobile"
    >
      {!selectedOption && (
        <div className="option-chooser">
          <Button type="primary" onClick={() => setSelectedOption("CREATE")}>
            Make new request
          </Button>
          <Typography.Text>or</Typography.Text>
          <Button type="primary" onClick={() => setSelectedOption("CHANGE")}>
            Change existing request
          </Button>
        </div>
      )}

      {selectedOption === "CREATE" && (
        <Form
          form={formCreate}
          initialValues={{
            ...predefinedFields,
          }}
          layout="vertical"
          onFinish={onSubmitCreate}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: `You must choose a title`,
              },
            ]}
          >
            <Input
              autoComplete="off"
              className="request-title"
              data-cy="request-title-input"
              onChange={(e) => {
                formCreate.setFieldsValue({ title: e.target.value });
                forceUpdate();
                formCreate.validateFields(["title"]);
              }}
            />
          </Form.Item>
          {commonFormItems}

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading} data-cy="create-request-submit-modal-button">
              {isLoading ? "Creating..." : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      )}
      {selectedOption === "CHANGE" && (
        <Form
          form={formChange}
          initialValues={{
            ...predefinedFields,
          }}
          layout="vertical"
          onFinish={onSubmitChange}
        >
          <Form.Item
            label={`Choose ${getSimpleLabel("request")}`}
            name="requestId"
            rules={[
              {
                required: true,
                message: `You must choose a ${getSimpleLabel("request")} to change`,
              },
            ]}
          >
            <RequestPicker placeholder={`Select ${getSimpleLabel("request")} to change`} />
          </Form.Item>
          <Form.Item
            label="Short description of the change"
            name="description"
            rules={[
              {
                required: true,
                message: `You must write a short description of the change`,
              },
            ]}
          >
            <Input
              autoComplete="off"
              className="change-description"
              data-cy="change-description-input"
              onChange={(e) => {
                formChange.setFieldsValue({ description: e.target.value });
                forceUpdate();
              }}
            />
          </Form.Item>
          {commonFormItems}
          {isAuthorised(["REQUESTS.EXECUTE"]) && <Form.Item label="Needs external approval" name="needsExternalApproval" valuePropName="checked">
            <Checkbox
              onChange={(e) => {
                formChange.setFieldsValue({
                  needsExternalApproval: e.target.checked,
                });
                forceUpdate();
              }}
            />
          </Form.Item>}
          <Form.Item
            label="To be approved by (one email address)"
            name="toBeApprovedBy"
            hidden={!formChange.getFieldValue("needsExternalApproval")}
            rules={
              formChange.getFieldValue("needsExternalApproval")
                ? [
                    {
                      required: true,
                      message: `You must write an email address of the person who should approve the change`,
                    },
                  ]
                : undefined
            }
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading} data-cy="change-request-submit-modal-button">
              {isLoading ? "Sending..." : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}

export default withRouter(
  withSubscriptions({
    Component: PlaceRequestModal,
    subscriptions: ["clients", "users", "projects"],
    displayPreloader: false,
  })
);
