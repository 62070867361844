import { withRouter, RouteComponentProps } from "react-router-dom";

import { Request } from "common/types";
import { getOpenRequestFormFileIds, getSubmittedRequestFormFileIds } from "common/requestHelpers";

import RequestFormCard from "../RequestFormCard/RequestFormCard";
import RequestActivity from "../RequestActivity/RequestActivity";

import "./RequestContent.scss";

type Props = {
  request: Request;
  activityItems: any[];
  users: any;
  apiUser: any;
  organisationDetails: any;
};

export function RequestContent({
  request,
  activityItems,
  users,
  apiUser,
  organisationDetails,
}: Props & RouteComponentProps) {
  const openFormFileIds = getOpenRequestFormFileIds({ activityItems });
  const submittedFormFileIds = getSubmittedRequestFormFileIds({ activityItems });

  const formCountTotal = openFormFileIds.length + submittedFormFileIds.length;

  return (
    <div className="request-content">
      {openFormFileIds.map((formFileId) => (
        <RequestFormCard
          key={formFileId}
          formFileId={formFileId}
          request={request}
          activityItems={activityItems}
          isSubmitted={false}
          formCountTotal={formCountTotal}
        />
      ))}
      {submittedFormFileIds.map((formFileId) => (
        <RequestFormCard
          key={formFileId}
          formFileId={formFileId}
          request={request}
          activityItems={activityItems}
          isSubmitted={true}
          formCountTotal={formCountTotal}
        />
      ))}

      <RequestActivity
        request={request}
        activityItems={activityItems}
        users={users}
        organisationDetails={organisationDetails}
        apiUser={apiUser}
      />
    </div>
  );
}

export default withRouter(RequestContent);
