import { useState } from "react";
import { withRouter } from "react-router-dom";
import { Typography, Form, Modal, Button, Row, Col, Select, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { useForceUpdate, displayErrorMessage, getLabel, processIdForDisplay } from "common/helpers";
import { CREATE_RETRY_LIMIT, CURRENCIES } from "common/constants";
import withSubscriptions from "common/withSubscriptions";
import { createInvoice } from "common/invoiceHelpers";

import CreateProjectModal from "CreateProjectModal/CreateProjectModal";
import ProjectPicker from "ProjectPicker/ProjectPicker";

import "./CreateInvoiceModal.scss";

export function CreateInvoiceModal({
  onClose,
  organisationDetails,
  apiUser,
  predefinedFields = {},
  projects,
  clients,
  history,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateProjectModalVisible, setIsCreateProjectModalVisible] = useState(false);
  const [form] = Form.useForm();

  const templatesForFileType = organisationDetails.templates.items.filter(
    // (template) => (template.isLive || !template.key) && !template.isDeprecated
    // TODO: re-add the restriction for template to be live
    (template) => template.type === "INVOICE" && !template.isDeprecated
  );

  // we need to force the component to re-render when we change some form values
  const forceUpdate = useForceUpdate();

  async function onSubmit(params) {
    const { projectId, templateId, extraOffset = 0 } = params;
    setIsLoading(true);
    const messageKey = "create-single-invoice";
    message.loading({
      content: "Creating invoice...",
      key: messageKey,
      duration: 0,
    });
    try {
      const project = projects.find((x) => x.id === projectId);

      if (!project) {
        message.error(
          <>
            {getLabel({
              id: "Project",
              defaultValue: "Project",
            })}{" "}
            <b>{params.projectId}</b> not found
          </>
        );
        setIsLoading(false);
        return;
      }

      const client = clients.find((client) => client.id === project.clientId);
      if (!client) {
        message.error(
          <>
            {getLabel({
              id: "Client",
              defaultValue: "Client",
            })}{" "}
            <b>{project.clientId}</b> not found
          </>
        );
        setIsLoading(false);
        return;
      }
      const invoiceId = await createInvoice({
        organisationId: apiUser.organisation,
        projectId: project.id,
        templateId,
        clientId: client.id,
      });
      message.success({
        content: (
          <Typography.Text onClick={() => history.push(`/invoices/${invoiceId}`)} style={{ cursor: "pointer" }}>
            Invoice <b>{processIdForDisplay(invoiceId)}</b> created. <b>Click here to go to it</b>
          </Typography.Text>
        ),
        key: messageKey,
        duration: window.Cypress ? 0.5 : 3,
      });

      onClose();
    } catch (e) {
      console.log("invoice error: e = ", e);
      const errorType = e?.errors && e.errors[0]?.errorType;
      console.log("extraOffset = ", extraOffset);
      console.log("CREATE_RETRY_LIMIT = ", CREATE_RETRY_LIMIT);
      // debugger;
      if (errorType === "DynamoDB:ConditionalCheckFailedException" && extraOffset <= CREATE_RETRY_LIMIT) {
        console.log("not showing error");
        return await onSubmit({
          ...params,
          extraOffset: extraOffset + 1,
        });
      } else {
        console.log("error creating invoice:", e);
        message.error({
          content: "Failed to create invoice",
          key: messageKey,
        });
        setIsLoading(false);
        onClose();
        throw e;
      }
    }
  }

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  let defaultTemplateId = templatesForFileType.find((x) => x.isDefault)?.id || templatesForFileType[0]?.id;

  return (
    <Modal
      maskClosable={false}
      title={`Create invoice ${predefinedFields.projectId ? "in project" : ""}`}
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-invoice-modal full-screen-on-mobile"
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          currency: CURRENCIES[0].value,
          templateId: defaultTemplateId,
          ...predefinedFields,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          name="projectId"
          label={getLabel({
            id: "Project",
            defaultValue: "Project",
          })}
          rules={[
            {
              required: true,
              message: `Invoices have to be part of a ${getLabel({
                id: "project",
                defaultValue: "project",
              })}`,
            },
          ]}
        >
          <Row gutter={16}>
            <Col xs={24} sm={16}>
              <ProjectPicker
                disabled={predefinedFields.projectId}
                onChange={(projectId) => {
                  form.setFieldsValue({ projectId });
                  forceUpdate();
                  if (form.getFieldValue("title")) {
                    form.validateFields(["title"]);
                  }
                }}
                value={form.getFieldValue("projectId")}
                predefinedFields={predefinedFields}
                data-cy="project-id-dropdown"
              />
            </Col>
            <Col xs={24} sm={8}>
              <Button
                type="primary"
                className="create-project"
                onClick={() => setIsCreateProjectModalVisible(true)}
                data-cy="create-project-button"
                disabled={predefinedFields.projectId}
              >
                <PlusCircleOutlined /> Create{" "}
                {getLabel({
                  id: "project",
                  defaultValue: "project",
                })}
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label="Template"
          name="templateId"
          rules={[
            {
              required: true,
              message: "You have to choose a template",
            },
          ]}
        >
          <Select>
            {(templatesForFileType || []).map((template) => {
              return (
                <Select.Option key={template.id} value={template.id}>
                  {template.name}
                  {!template.isLive && template.key ? " (draft)" : ""}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Currency" name="currency" rules={[]} hidden>
          <Select data-cy="currency-picker-dropdown">
            {CURRENCIES.map((currency) => {
              return (
                <Select.Option key={currency.value} value={currency.value}>
                  {currency.value}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="create-invoice-submit-modal-button">
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
      {isCreateProjectModalVisible && (
        <CreateProjectModal
          apiUser={apiUser}
          organisation={apiUser.organisation}
          onClose={() => setIsCreateProjectModalVisible(false)}
          onSave={(project) => {
            form.setFieldsValue({ projectId: project.id });
            forceUpdate();
          }}
        />
      )}
    </Modal>
  );
}

export default withRouter(
  withSubscriptions({
    Component: CreateInvoiceModal,
    subscriptions: ["clients", "projects", "organisationDetails", "apiUser"],
    displayPreloader: false,
  })
);
