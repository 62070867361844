import React from "react";
import moment from "moment";
import { getReportTaskRevisions } from "ReportPage/ReportTaskRevisions/ReportTaskRevisions";
import { LoadingOutlined } from "@ant-design/icons";
import { Typography, notification } from "antd";
import axios from "axios";
import { Text, View, Image, Document, StyleSheet, Font } from "@react-pdf/renderer";
import { getCat2Check, fetchAndSetLinkedTasks } from "common/helpers";
import { getAttachmentFullKeyFromLocalKey } from "common/documentRenderHelpers";
import getS3File from "common/getS3File";

import {
  getLatestFileVersion,
  getFilenameFromKey,
  getLatestRevision,
  buildFileName,
  getAttachmentTypeFromKey,
} from "common/shared";
import { PDF_INSERT_MARKER } from "common/constants";
import { getImagesFromReport } from "ReportPage/Report/reportHelpers";
import { displayPdfTextarea, displayPdfAttachment, initialiseLambdaPdfInserts } from "common/documentRenderHelpers";
import Page from "ReportPage/Report/ReportPdfPage";

import ReportPreview from "ReportPage/ReportPreview";

import AvertaRegular from "ReportPage/Report/averta-regular.ttf";
import AvertaBold from "ReportPage/Report/averta-bold.ttf";
import MrsSaintDelafield from "ReportPage/Report/MrsSaintDelafield-Regular.ttf";

import { EndCover } from "./DC_ReportCommonComponents";

import { Table } from "ReportPage/PdfTable";

import "./ReportDefault.scss";

Font.registerHyphenationCallback((word) => [word]);

Font.register({
  family: "Averta",
  fonts: [
    {
      src: AvertaRegular,
      fontWeight: "normal",
    },
    {
      src: AvertaBold,
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "MrsSaintDelafield",
  fonts: [
    {
      src: MrsSaintDelafield,
      fontWeight: "normal",
    },
  ],
});

const colors = { andunGreen: "#18405B", black: "#000", gray: "#102E42" };

const styles = StyleSheet.create({
  bold: { fontWeight: "bold" },
  pageTitle: { fontSize: 20, marginBottom: 25, fontWeight: "bold" },
  pageSubtitle: { fontSize: 13, marginBottom: 15 },
  sectionTitle: { fontSize: 15, marginBottom: 10, marginTop: 20 },
  engineeringStatementTitle: {
    fontSize: 15,
    marginBottom: 8,
    textAlign: "center",
    fontWeight: "bold",
  },
  imageCaption: {
    fontSize: 10,
    textAlign: "left",
    display: "block",
    marginTop: 5,
  },
  sectionSubtitle: {
    fontSize: 12,
    marginBottom: 10,
    marginTop: 10,
    textDecoration: "underline",
    fontWeight: "bold",
  },
  paragraph: { fontSize: 10, marginBottom: 10 },
  "image_half-page": {
    width: "50%",
  },
  "image_third-page": {
    width: "33%",
  },
  image_half: {
    width: "50%",
  },
  image_third: {
    width: "33%",
  },
  "image_full-page": {
    width: "100%",
  },
  image_original: {},

  page: {
    paddingTop: 80,
    paddingRight: 66,
    paddingLeft: 24,
    paddingBottom: 45,
    fontFamily: "Averta",
  },
  borderTop: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: 24,
    backgroundColor: colors.andunGreen,
  },
  borderRight: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 24,
    height: "100vh",
    backgroundColor: colors.andunGreen,
  },
  borderRightGray: {
    position: "absolute",
    top: 24,
    right: 24,
    width: 18,
    height: "100vh",
    backgroundColor: "#534b46",
  },
  borderBottom: {
    position: "absolute",
    bottom: 0,
    right: 24,
    width: "100vw",
    height: 24,
    backgroundColor: colors.andunGreen,
  },
  andunLogo: {
    position: "absolute",
    top: 36,
    right: 54,
    width: 110,
  },

  cover: {
    infoContainer: { paddingLeft: 70, paddingTop: 94 },
    image: {
      height: "795pt",
      position: "absolute",
      top: -56,
      left: -25,
    },
    infoItem: { marginBottom: 24, fontSize: 17 },
  },
});

export class ReportEngineeringStatement extends React.Component {
  state = {
    isLoading: true,
    designersRiskAssessmentImageUrl: null,
    endOfReportImageUrl: null,
    attachmentImages: null,
    defaultRiskAssessmentReference: null,
  };

  async componentDidMount() {
    await fetchAndSetLinkedTasks.call(this);
    const { taskRevision, task } = this.props;
    const reportFile = this.props.file;
    let newState = {
      defaultRiskAssessmentReference: await buildFileName({
        organisation: task.organisation,
        fileId: reportFile.id,
        taskId: task.id,
        fileType: "DESIGNERS RISK ASSESSMENT",
        taskRevisionName: taskRevision.name,
        clientInitials: task.client.initials,
        projectInitials: task.project.initials,
        taskInitials: task.initials,
      }),
    };
    const endOfReportImageUrl = await getS3File("report_images/andun_end_of_report_optimised_bw.jpg");
    // if we don't receive the preview data, we load it ourselves
    // this is useful on pages that don't already have that data to pass it down to us
    if (!this.props.previewData) {
      newState.reportJsonData = await this.loadForm({
        file: reportFile,
      });
    } else {
      newState.reportJsonData = this.props.previewData;
    }

    try {
      newState.attachmentImages = await this.retrieveImages(newState.reportJsonData);
      this.setState({
        ...newState,
        endOfReportImageUrl,
        isLoading: false,
      });
    } catch (e) {
      // nothing, we already show an error message in the function which actually failed
    }
  }

  async componentDidUpdate(prevProps) {
    // if we don't have this block of code, the preview won't update when the user changes something
    // only used on the report page
    if (this.props.previewData !== prevProps.previewData) {
      try {
        const attachmentImages = await this.retrieveImages(this.props.previewData);
        this.setState({
          reportJsonData: this.props.previewData,
          attachmentImages,
          isLoading: false,
        });
      } catch (e) {
        // nothing, we already show an error message in the function which actually failed
      }
    }
  }

  loadForm = async ({ file }) => {
    const fileKey = getLatestFileVersion(file).key.replace("public/", "");
    const filePublicURL = await getS3File(fileKey);
    const form = (await axios.get(filePublicURL)).data;
    return form;
  };
  retrieveImages = async (reportJsonData) => {
    let imagesInReport = getImagesFromReport(reportJsonData);
    const attachmentImages = this.state.attachmentImages || {};
    let imageKeys = imagesInReport
      .map((image) => image.localKey)
      // we only want to fetch the images we don't already have
      .filter((localKey) => !attachmentImages || !attachmentImages.hasOwnProperty(localKey));

    if (!imageKeys || imageKeys.length === 0) {
      return attachmentImages;
    }

    try {
      const imagePromises = [];
      for (let i = 0; i < imageKeys.length; i++) {
        const localKey = imageKeys[i];
        const fullKey = getAttachmentFullKeyFromLocalKey({
          projectFolder: this.props.projectFolder,
          localKey,
        });
        imagePromises.push(
          getS3File(fullKey).catch(() => {
            throw new Error(fullKey);
          })
        );
      }
      let images = await Promise.all(imagePromises);
      imageKeys.forEach((key, i) => {
        attachmentImages[key] = images[i];
      });
    } catch (e) {
      console.error("error:", e);
      notification.error({
        message: (
          <Typography.Text>
            Failed to retrieve image:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
      throw e;
    }

    return attachmentImages;
  };

  displayTaskRevisionHistory = (taskRevisionsData) => {
    const cell_style = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 0 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: `${95 / taskRevisionsData.columns.length}%`,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: row === 0 ? colors.andunGreen : colors.gray,
        padding: "5pt 5pt",
        fontSize: 10,
      };

      let specificStyle = {
        backgroundColor: row === 0 ? colors.andunGreen : "#fff",
        color: row === 0 ? "#fff" : "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    return <Table columns={taskRevisionsData.columns} data={taskRevisionsData.data} style_function={cell_style} />;
  };

  displaySignatures = () => {
    const { users, taskRevision, task } = this.props;
    const DESIGNER_SIGNATURE_HEIGHT = 26;

    const designer = users.find((x) => x.id === taskRevision.author);

    let signature = null;

    if (designer) {
      if (designer.signature) {
        signature = (
          <Image
            src={designer.signature}
            style={{
              left: 0,
              top: 0,
              height: DESIGNER_SIGNATURE_HEIGHT,
              width: DESIGNER_SIGNATURE_HEIGHT * (designer.signatureWidth / designer.signatureHeight),
            }}
          />
        );
      } else {
        signature = (
          <Text style={{ fontFamily: "MrsSaintDelafield", top: 0 }}>
            {designer.firstName} {designer.lastName}
          </Text>
        );
      }
    }

    const reviewedAt = taskRevision.reviewAcceptDate ? moment(taskRevision.reviewAcceptDate).format("DD/MM/YY") : "";

    const cat2CheckTask = getCat2Check({
      task,
      linkedTasksDetails: this.state.linkedTasksDetails,
    });
    let cat2CheckLatestRevision;
    if (cat2CheckTask) {
      cat2CheckLatestRevision = getLatestRevision(cat2CheckTask);
    }

    let checkerId;
    if (cat2CheckTask) {
      checkerId = cat2CheckTask.assignedTo;
    }

    let checkerSignature = null;
    let checker = users.find((x) => x.id === checkerId);

    let cat2CheckReviewedAt = "";

    if (cat2CheckTask) {
      if (cat2CheckTask.isFinished) {
        cat2CheckReviewedAt = moment(cat2CheckLatestRevision.reviewAcceptDate).format("DD/MM/YY");
      }
    }

    if (checker && cat2CheckReviewedAt && cat2CheckTask.isFinished) {
      if (checker.signature) {
        checkerSignature = (
          <Image
            src={checker.signature}
            style={{
              left: 0,
              top: -15,
              height: DESIGNER_SIGNATURE_HEIGHT,
              width: DESIGNER_SIGNATURE_HEIGHT * (checker.signatureWidth / checker.signatureHeight),
            }}
          />
        );
      } else {
        checkerSignature = (
          <Text style={{ fontFamily: "MrsSaintDelafield", top: -20 }}>
            {checker.firstName} {checker.lastName}
          </Text>
        );
      }
    }
    return (
      <View>
        <View style={{ marginTop: 20 }}>
          {cat2CheckTask && (
            <Text
              style={{
                ...styles.paragraph,
                fontWeight: "bold",
                marginBottom: 4,
              }}
              minPresenceAhead={30}
            >
              Designer:
            </Text>
          )}
          {!designer && (
            <Text style={{ ...styles.paragraph, marginBottom: 4 }} minPresenceAhead={30}>
              Designer Not set
            </Text>
          )}
          <Text style={{ ...styles.paragraph, fontWeight: "bold" }} minPresenceAhead={30}>
            {designer?.firstName} {designer?.lastName}
          </Text>
          {designer?.qualifications && designer.qualifications.length > 0 ? (
            <Text style={{ ...styles.paragraph, marginTop: 0, fontWeight: "bold" }} minPresenceAhead={30}>
              {designer?.qualifications}
            </Text>
          ) : null}

          {designer && (
            <Text style={{ ...styles.paragraph, marginTop: 0, fontWeight: "bold" }} minPresenceAhead={30}>
              Date: {reviewedAt}
            </Text>
          )}

          <View>{signature}</View>
        </View>
        {cat2CheckTask && (
          <View style={{ marginTop: 20 }}>
            <Text
              style={{
                ...styles.paragraph,
                fontWeight: "bold",
                marginBottom: 4,
              }}
              minPresenceAhead={30}
            >
              Cat 2 checker:
            </Text>
            {!checker && (
              <Text style={{ ...styles.paragraph, marginBottom: 4 }} minPresenceAhead={30}>
                Checker Not set
              </Text>
            )}
            <Text style={{ ...styles.paragraph, fontWeight: "bold" }} minPresenceAhead={30}>
              {checker?.firstName} {checker?.lastName}
            </Text>
            {checker?.qualifications && checker.qualifications.length > 0 ? (
              <Text
                style={{
                  ...styles.paragraph,
                  marginTop: 0,
                  fontWeight: "bold",
                }}
                minPresenceAhead={30}
              >
                {checker?.qualifications}
              </Text>
            ) : null}
            {checker && (
              <Text
                style={{
                  ...styles.paragraph,
                  marginTop: 0,
                  fontWeight: "bold",
                }}
                minPresenceAhead={30}
              >
                Date: {cat2CheckReviewedAt}
              </Text>
            )}
            <View>{checkerSignature}</View>
          </View>
        )}
      </View>
    );
  };

  displayAttachmentNames = () => {
    const { reportJsonData } = this.state;
    return (
      <View>
        <Text style={[styles.sectionSubtitle]} minPresenceAhead={30}>
          Enc.
        </Text>
        {Array.isArray(reportJsonData.fields.attachments.value)
          ? reportJsonData.fields.attachments.value.map((key, i) => (
              <View>
                {reportJsonData.fields.attachments.labels &&
                reportJsonData.fields.attachments.labels[key] &&
                reportJsonData.fields.attachments.labels[key] !== "" ? (
                  <Text style={{ ...styles.paragraph, fontWeight: "bold" }} key={i}>
                    {reportJsonData.fields.attachments.labels[key]}
                  </Text>
                ) : (
                  <Text />
                )}
                <Text style={[styles.paragraph]} key={i}>
                  {getFilenameFromKey(key, true)}
                </Text>
              </View>
            ))
          : null}
      </View>
    );
  };

  displayAttachments = () => {
    const { reportJsonData, attachmentImages } = this.state;

    const attachments = reportJsonData.fields.attachments.value;
    return attachments.map((key, i) => {
      const type = getAttachmentTypeFromKey(key);

      if (type === "PDF") {
        return displayPdfAttachment({
          elementKey: i,
          key,
          fieldName: "supportingInformation",
        });
      } else {
        return (
          <Page size="A4" style={styles.page} key={Math.random()}>
            <View key={i}>
              <Image style={{ width: "100%" }} src={attachmentImages[key]} />
            </View>
          </Page>
        );
      }
    });
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.isStatic && nextState.isLoading === this.state.isLoading && this.props.zoom === nextProps.zoom) {
      return false;
    }
    return true;
  };

  displayCheckCertificate = () => {
    const { task, users } = this.props;
    const { reportJsonData } = this.state;
    let signedDesignCheckCertificateField = reportJsonData.fields.signedDesignCheckCertificate;

    if (signedDesignCheckCertificateField?.value && signedDesignCheckCertificateField.value.length > 0) {
      return (
        <Text
          render={({ pageNumber }) => {
            const attachmentLabel = signedDesignCheckCertificateField.value[0] + PDF_INSERT_MARKER;
            window.pdfPageNumbers[attachmentLabel] = {
              deletePage: true,
              pageNumber,
              key: signedDesignCheckCertificateField.value[0].replace("public/", ""),
            };
            return null;
          }}
          fixed
        />
      );
    }

    let designer = users.find((x) => x.id === task.assignedTo);

    const cell_style = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 1 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: col === 0 ? "20%" : "80%",
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding: "5pt 5pt",
        fontSize: 10,
      };

      let specificStyle = {
        backgroundColor: "#fff",
        color: "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    const DESIGNER_SIGNATURE_HEIGHT = 30;
    let signature = null;

    if (task.assignedTo && designer) {
      if (designer.signature) {
        signature = (
          <Image
            src={designer.signature}
            style={{
              height: DESIGNER_SIGNATURE_HEIGHT,
              width: DESIGNER_SIGNATURE_HEIGHT * (designer.signatureWidth / designer.signatureHeight),
            }}
          />
        );
      } else {
        signature = (
          <Text style={{ fontFamily: "MrsSaintDelafield" }}>
            {designer.firstName} {designer.lastName}
          </Text>
        );
      }
    }

    return (
      <>
        <Text style={[styles.sectionSubtitle]}>Design Check</Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Checking Organisation:</Text>
        </Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Design Check Undertaken:</Text> Cat {task.catLevel}
        </Text>

        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Does the design comply with the brief:</Text>{" "}
        </Text>

        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>If no, how does it differ from the design brief?</Text>
        </Text>
        <Text style={[styles.paragraph]}></Text>

        <Table
          style={{ marginTop: 50 }}
          includeHeader={false}
          columns={[
            {
              title: "name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "value",
              dataIndex: "value",
              key: "value",
            },
          ]}
          data={[
            {
              name: "Name",
              value: "",
            },
            { name: "Title", value: "" },
            {
              name: "Signature",
              value: "",
            },
            { name: "Date", value: "" },
            {
              name: "",
              value:
                "To be signed by the Temporary Works checker or other person authorised* to sign on behalf of the organisation responsible for the Checking of the Temporary Works.",
            },
          ]}
          style_function={cell_style}
        />

        <Table
          style={{ marginTop: 50 }}
          includeHeader={false}
          columns={[
            {
              title: "name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "value",
              dataIndex: "value",
              key: "value",
            },
          ]}
          data={[
            {
              name: "Name",
              value: designer ? `${designer.firstName} ${designer.lastName}` : "",
            },
            { name: "Title", value: designer?.position },
            {
              name: "Signature",
              value: signature,
            },
            { name: "Date", value: moment().format("DD/MM/YY") },
            {
              name: "",
              value:
                "To be signed by the Temporary Works designer or other person authorised* to sign on behalf of the organisation responsible for the Design of the Temporary Works.",
            },
          ]}
          style_function={cell_style}
        />
      </>
    );
  };

  displayDocument = () => {
    const { task, file, users, taskRevision } = this.props;
    const { isLoading, reportJsonData } = this.state;
    const reportFile = this.props.file;

    window.pdfPageNumbers = {};
    window.pdfPageNumbersToDownload = {};

    window.lambdaPdfAssets = [
      {
        bucket: "draughthub-public-assets",
        name: "organisationLogo",
        key: "DC/dc-logo.png",
      },
    ];

    if (!task || !users || !taskRevision || isLoading) {
      return (
        <div className="report-preloader">
          <LoadingOutlined />
        </div>
      );
    }

    const reviewer = users.find((x) => x.id === taskRevision.checkedBy);
    const reviewedAt = taskRevision.reviewAcceptDate ? moment(taskRevision.reviewAcceptDate).format("DD/MM/YY") : null;

    const taskRevisionsData = getReportTaskRevisions({ task, users });

    const REVIEWER_SIGNATURE_HEIGHT = 26;

    let signature = null;

    if (reviewedAt && reviewer) {
      if (reviewer.signature) {
        signature = (
          <Image
            src={reviewer.signature}
            style={{
              left: 110,
              top: -5,
              height: REVIEWER_SIGNATURE_HEIGHT,
              width: REVIEWER_SIGNATURE_HEIGHT * (reviewer.signatureWidth / reviewer.signatureHeight),
            }}
          />
        );
      } else {
        signature = (
          <Text style={{ fontFamily: "MrsSaintDelafield", top: -10, left: 110 }}>
            {reviewer.firstName} {reviewer.lastName}
          </Text>
        );
      }
    }

    let customTaskId = null;
    if (
      reportJsonData.fields.customTaskId &&
      reportJsonData.fields.customTaskId.value.length > 0 &&
      reportJsonData.fields.customTaskId.value !== " "
    ) {
      customTaskId = reportJsonData.fields.customTaskId.value;
    }

    let reportReference = "";

    if (reportFile) {
      if (customTaskId) {
        reportReference = customTaskId;
      } else {
        reportReference = `${reportFile.sheets.items[0].autoGeneratedReferenceNumber} ${taskRevision.name}`;
      }
    }

    let reportType = [];
    if (Array.isArray(reportJsonData.fields.reportType.value)) {
      reportType = reportJsonData.fields.reportType.value;
    }

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text
            render={() => {
              initialiseLambdaPdfInserts();
              return null;
            }}
            fixed
          />
          {/* <Image allowDangerousPaths src={CoverImage} style={styles.cover.image} /> */}
          <View style={styles.cover.infoContainer}>
            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Client:</Text> {task.client.name}
            </Text>
            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Task Number:</Text> {customTaskId ? customTaskId : task.id}
            </Text>
            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Project Title:</Text> {task.project.title}
            </Text>
            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Task Title :</Text> {task.title}
            </Text>
            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Design Risk Category:</Text> Cat {task.catLevel}
            </Text>
            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Report Reference:</Text> {reportReference}
            </Text>

            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Reviewed By:</Text> {reviewer?.firstName} {reviewer?.lastName}
            </Text>
            {reviewer?.qualifications && reviewer.qualifications.length > 0 ? (
              <Text style={{ ...styles.cover.infoItem, marginTop: -20 }}>
                {/* we duplicate the label so that the qualifications align correctly */}
                <Text style={{ ...styles.bold, opacity: 0 }}>Reviewed By:</Text> {reviewer?.qualifications}
              </Text>
            ) : null}
            <View>{signature}</View>
          </View>
        </Page>
        <Page size="A4" style={styles.page} wrap>
          <Text style={styles.pageTitle} break>
            Document Revision History
          </Text>
          {this.displayTaskRevisionHistory(taskRevisionsData)}
        </Page>

        <Page size="A4" style={styles.page} wrap>
          <Text style={{ ...styles.engineeringStatementTitle, marginTop: 15 }}>
            {task.project.title} - {task.title}
          </Text>

          <Text style={{ ...styles.engineeringStatementTitle, marginBottom: 30 }}>
            {file.templateId === "engineering_statement" ? "Engineering Statement" : reportJsonData.fields.title?.value}
          </Text>
          {reportJsonData.fields.content.value.map((section) =>
            displayPdfTextarea({
              fieldName: "content",
              sectionId: section.id,
              targetReportJsonData: reportJsonData,
              suffix: "design",
              attachmentImages: this.state.attachmentImages,
              styles,
              projectFolder: this.props.projectFolder,
            })
          )}
          {(!reportType || !reportType.includes("cat3_requested")) && (
            <View>
              {this.displaySignatures()}
              {reportJsonData.fields.attachments.value.length > 0 && this.displayAttachmentNames()}
            </View>
          )}
        </Page>
        {(!reportType || !reportType.includes("cat3_requested")) &&
          Array.isArray(reportJsonData.fields.attachments.value) &&
          reportJsonData.fields.attachments.value.length > 0 &&
          this.displayAttachments()}
        {reportType && reportType.includes("cat3_requested") && (
          <Page size="A4" style={styles.page}>
            {this.displayCheckCertificate(reportType)}
          </Page>
        )}
        {reportType && reportType.includes("cat3_requested") && reportJsonData.fields.attachments.value.length > 0 && (
          <Page size="A4" style={styles.page}>
            {this.displayAttachmentNames()}
            {Array.isArray(reportJsonData.fields.attachments.value) &&
              reportJsonData.fields.attachments.value.length > 0 &&
              this.displayAttachments()}
          </Page>
        )}

        <EndCover colors={colors} styles={styles} endOfReportImageUrl={this.state.endOfReportImageUrl} />
      </Document>
    );
  };

  render() {
    const { task, users, taskRevision, layout = "default", renderMode } = this.props;
    const { isLoading } = this.state;

    window.pdfPageNumbers = {};
    window.pdfPageNumbersToDownload = {};

    if (!task || !users || !taskRevision || isLoading) {
      return (
        <div className="report-preloader">
          <LoadingOutlined />
        </div>
      );
    }

    return (
      <div className="report report-DC">
        <ReportPreview
          document={this.displayDocument()}
          layout={layout}
          renderMode={renderMode}
          renderKey={JSON.stringify(this.state.reportJsonData)}
          onDataUri={this.props.onDataUri}
        />
      </div>
    );
  }
}

export default React.memo(ReportEngineeringStatement);
