import { useState } from "react";
import { Tag, Button, Modal } from "antd";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import cx from "classnames";

import { callGraphQLSimple } from "common/apiHelpers";

import Avatar from "Avatar/Avatar";
import ThisComponent from "./HierarchyUserItem";
import HierarchyUserModal from "./HierarchyUserModal";

export default function HierarchyUserItem({ user, users, managerId }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  async function onAddUser({ userId }) {
    let userDetails = users.find((crtUser) => crtUser.id === userId);

    await callGraphQLSimple({
      message: "Failed to add user as subordinate",
      mutation: "updateUser",
      variables: {
        input: {
          id: userId,
          managerIds: [...(userDetails.managerIds || []), user.id],
        },
      },
    });
    setIsModalVisible(false);
  }

  async function confirmRemoveUser() {
    const managerUserDetails = users.find((crtUser) => crtUser.id === managerId);
    Modal.confirm({
      title: `Remove ${user.firstName} ${user.lastName} as subordinate of ${managerUserDetails.firstName} ${managerUserDetails.lastName}?`,
      onOk: async () => {
        await callGraphQLSimple({
          message: "Failed to remove user",
          mutation: "updateUser",
          variables: {
            input: {
              id: user.id,
              managerIds: user.managerIds.filter((crtUser) => crtUser !== managerId),
            },
          },
        });
      },
    });
  }

  let directSubordinates = users.filter((subordinate) => {
    return subordinate.managerIds?.includes(user.id);
  });

  return (
    <div
      className={cx("user-container", {
        "has-subordinates": directSubordinates.length > 0,
      })}
    >
      <div className="user-details">
        <Avatar user={user} showLabel />
        {user.position && <Tag>{user.position}</Tag>}
        <Button
          className="add-user-button"
          icon={<PlusCircleOutlined />}
          onClick={() => setIsModalVisible(true)}
          type="primary"
        >
          Add subordinate
        </Button>
        {managerId && <CloseCircleOutlined className="remove-user-button" onClick={() => confirmRemoveUser()} />}
      </div>
      {directSubordinates.length > 0 && (
        <div className="direct-subordinates">
          {directSubordinates.map((crtUser) => (
            <ThisComponent user={crtUser} users={users} key={crtUser.id} managerId={user.id} />
          ))}
        </div>
      )}
      {isModalVisible && (
        <HierarchyUserModal
          users={users}
          onClose={() => setIsModalVisible(false)}
          onSubmit={onAddUser}
          managerUser={user}
          directSubordinates={directSubordinates}
        />
      )}
    </div>
  );
}
