import moment from "moment";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";

export default function getTimelineBlocksOnDay({ timelineBlocks, targetDate }) {
  let targetBlocks = timelineBlocks.filter((block) => {
    let blockStartDate = moment(block.startDate);
    let blockDurationOnFirstDay = Math.min(
      block.durationHours - block.startHours,
      TIMELINE_DEFAULT_HOURS_IN_A_DAY - block.startHours
    );
    let blockDurationInDays = (block.durationHours - blockDurationOnFirstDay) / TIMELINE_DEFAULT_HOURS_IN_A_DAY;
    let blockEndDate = moment(blockStartDate).add(blockDurationInDays, "days");

    let isValidStartDate = blockStartDate.isSameOrBefore(targetDate, "day");
    let isValidEndDate = blockEndDate.isSameOrAfter(targetDate, "day");
    let isInRange = isValidStartDate && isValidEndDate;

    return isInRange;
  });

  return targetBlocks;
}
