export function getRowAndColumnFromAddress(address) {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x));
  const possibleColumnNames = [...alphabet, ...alphabet.map((letter) => `A${letter}`)];
  let columnName = possibleColumnNames.find((x) => address.includes(x));
  let columnIndex = possibleColumnNames.findIndex((x) => address.includes(x));
  if (columnName) {
    const rowNumber = address.split(columnName).join("");
    return { row: rowNumber - 1, column: columnIndex };
  }
}
