import { Typography } from "antd";
import { callGraphQLSimple } from "common/apiHelpers";
import DatePicker from "DatePicker/DatePicker";
import { TaskRevision } from "common/types";
import { isAuthorised } from "common/permissions";
import moment from "moment";

type Props = {
  taskRevision: TaskRevision;
};

export default function TaskRevisionFinishedAt({ taskRevision }: Props) {
  async function onChange(value) {
    for (let file of taskRevision?.files?.items || []) {
      for (let sheet of file?.sheets?.items || []) {
        const latestSheetRevision = sheet?.revisions?.items.slice(-1)[0];

        if (latestSheetRevision && latestSheetRevision.reviewAcceptDate) {
          let differenceInAcceptDateTime = Math.abs(
            moment(taskRevision.reviewAcceptDate).diff(moment(latestSheetRevision.reviewAcceptDate), "seconds")
          );
          if (differenceInAcceptDateTime <= 60) {
            await callGraphQLSimple({
              message: "Failed to update date",
              mutation: "updateSheetRevision",
              variables: {
                input: {
                  id: latestSheetRevision.id,
                  reviewAcceptDate: value || null,
                },
              },
            });
          }
        }
      }
    }

    await callGraphQLSimple({
      message: "Failed to update date",
      queryCustom: "updateTaskRevision",
      variables: {
        input: {
          id: taskRevision.id,
          reviewAcceptDate: value || null,
        },
      },
    });

    await callGraphQLSimple({
      message: "Failed to update date",
      queryCustom: "updateTask",
      variables: {
        input: {
          id: taskRevision.taskId,
          randomNumber: Math.floor(Math.random() * 100000),
        },
      },
    });
  }

  let canEditDate = isAuthorised(["FULL.READ_WRITE", "TASK_DETAILS.UPDATE_TASK_REVISION_FINISHED_AT"]);

  if (!canEditDate && !taskRevision.reviewAcceptDate) {
    return null;
  }

  return (
    <>
      <DatePicker
        key={taskRevision.reviewAcceptDate}
        format="DD-MM-YYYY"
        className="active-on-hover"
        disabled={!canEditDate}
        defaultValue={taskRevision.reviewAcceptDate ? moment(taskRevision.reviewAcceptDate) : undefined}
        onChange={onChange}
        placeholder="Not Set"
        suffixIcon={null}
      />
    </>
  );
}
