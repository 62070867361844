import { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { Typography, Spin, message, Modal } from "antd";
import moment from "moment";

import { callGraphQLSimple, callRest } from "common/apiHelpers";
import { Organisation, Integration } from "common/types";
import withSubscriptions from "common/withSubscriptions";

import "./XeroCallbackPage.scss";

type Props = {
  organisationDetails: Organisation;
};

export function XeroCallbackPage({ organisationDetails }: Props) {
  const [isXeroAuthorised, setIsXeroAuthorised] = useState(false);
  const [hasError, setHasError] = useState(false);

  let callbackUrl = `${window.location.pathname}${window.location.search}`;
  const currentDate = moment().toISOString();

  useEffect(() => {
    sendTokenToApi().then(() => {
      setTimeout(() => {
        if (!hasError) {
          setIsXeroAuthorised(true);
        }
      }, 1000);
    });
  }, []); // eslint-disable-line

  async function sendTokenToApi() {
    try {
      await callRest({
        route: "/xero-callback",
        method: "POST",
        body: {
          callbackUrl,
          organisationId: organisationDetails.id,
        },
        includeCredentials: false,
      });

      const isXeroIntegrationEnabled: Integration | undefined = organisationDetails?.integrations?.find(
        (integration: Integration) => integration?.id === "XERO"
      );

      if (isXeroIntegrationEnabled) {
        await callGraphQLSimple({
          message: "Failed to authorise the Xero integration",
          queryName: "updateOrganisation",
          variables: {
            input: {
              id: organisationDetails.id,
              integrations: [...(organisationDetails.integrations || [])].map((integration: Integration) => {
                if (integration?.id !== "XERO") {
                  return integration;
                }
                return {
                  ...integration,
                  authorisedAt: currentDate,
                };
              }),
            },
          },
        });
        message.success("Xero has been authorised successfully.");
      }
    } catch (err: any) {
      setHasError(true);

      if (err.response?.data) {
        Modal.warning({
          title: "Couldn't authorise the Xero integration",
          content: <div>Reason: {err.response?.data}</div>,
        });
      } else {
        message.warning("Failed to authorise the Xero integration");
      }

      return <Redirect to={"/organisation/apps"} />;
    }
  }

  if (isXeroAuthorised) {
    return <Redirect to={"/organisation/apps"} />;
  }

  return (
    <div className="xero-callback-page">
      <Spin />
      <Typography.Text className="xero-callback-message">We're initialising your Xero integration...</Typography.Text>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: XeroCallbackPage,
    subscriptions: ["organisationDetails"],
  })
);
