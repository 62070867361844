import React from "react";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { Typography, notification } from "antd";
import { Page, Text, View, Document } from "@react-pdf/renderer";
import awsExports from "aws-exports";

import { getAttachmentFullKeyFromLocalKey } from "common/documentRenderHelpers";
import getS3File from "common/getS3File";

import { getImagesFromReport } from "ReportPage/Report/reportHelpers";
import { displayPdfTextarea, displayPdfAttachment } from "common/documentRenderHelpers";
import { Table } from "ReportPage/PdfTable";
import ReportPreview from "ReportPage/ReportPreview";
import CoverBackground from "./quote-cover.jpg";
import RearCoverImage from "ReportPage/Report/ClientReports/AWD/rear-cover-image.png";
import { getFeesForClient } from "common/feeHelpers";
import { FrontCover, RearCover, colors, styles } from "ReportPage/Report/ClientReports/AWD/AWD_CommonReportComponents";

export class QuoteDefaultAWD extends React.Component {
  state = {
    isLoading: false,
    attachmentImages: null,
  };

  retrieveImages = async (reportJsonData) => {
    let imagesInReport = getImagesFromReport(reportJsonData);
    const attachmentImages = this.state.attachmentImages || {};
    let imageKeys = imagesInReport
      .map((image) => image.localKey)
      // we only want to fetch the images we don't already have
      .filter((localKey) => !attachmentImages || !attachmentImages.hasOwnProperty(localKey));

    if (!imageKeys || imageKeys.length === 0) {
      return attachmentImages;
    }

    try {
      const imagePromises = [];
      for (let i = 0; i < imageKeys.length; i++) {
        const localKey = imageKeys[i];
        const fullKey = getAttachmentFullKeyFromLocalKey({
          projectFolder: this.props.projectFolder,
          localKey,
        });
        imagePromises.push(
          getS3File(fullKey).catch(() => {
            throw new Error(fullKey);
          })
        );
      }
      let images = await Promise.all(imagePromises);
      imageKeys.forEach((key, i) => {
        attachmentImages[key] = images[i];
      });
    } catch (e) {
      console.log("error:", e);
      notification.error({
        message: (
          <Typography.Text>
            Failed to retrieve image:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
      throw e;
    }

    return attachmentImages;
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.isStatic && nextState.isLoading === this.state.isLoading && this.props.zoom === nextProps.zoom) {
      return false;
    }
    return true;
  };

  getQuoteFrontCoverInfoItems = () => {
    const { quote, users } = this.props;
    let infoItems = [quote.client.name, quote.project.title, quote.title, quote.reference || quote.id];
    if (quote.assignedTo) {
      let quoteAssignee = users.find((x) => x.id === quote.assignedTo);
      infoItems.push(`${quoteAssignee.firstName} ${quoteAssignee.lastName}`);
    }
    infoItems.push(moment(quote.reviewApprovedAt || undefined).format("DD/MM/YYYY"));
    return infoItems;
  };

  displayWatermark = () => {
    const { quote } = this.props;
    if (quote.reviewStatus === "SUCCESS") {
      return null;
    }

    return (
      <View
        fixed
        style={{
          position: "absolute",
          top: "200",
          left: "0",
          transform: "rotate(45deg)",
          opacity: 0.05,
        }}
      >
        <Text style={{ fontSize: 100 }}>UNAPPROVED</Text>
      </View>
    );
  };

  displayTermsAndConditions = () => {
    const { form, quote } = this.props;

    return (
      <Page size="A4" style={styles.quotePage} wrap>
        {this.displayWatermark()}
        <View>
          <Text style={{ ...styles.terms.paragraph }}>ALAN WHITE DESIGN LTD</Text>
          <Text style={{ ...styles.terms.paragraph, marginBottom: 20 }}>
            TERMS AND CONDITIONS FOR THE PROVISION OF SERVICES
          </Text>

          {form.fields?.termsAndConditions?.value === "as-per-contract" ? (
            <Text style={{ ...styles.terms.paragraph, marginTop: -10 }}>As per contract</Text>
          ) : (
            <>
              <Text style={{ ...styles.terms.heading }}>1. DEFINITIONS</Text>
              <Text style={{ ...styles.terms.paragraph }}>
                “You / Your” means our “Customer” being the person, firm or company to whom we are to provide services
                in accordance with these Terms.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                “We / Us / Our” means Alan White Design Limited (Company Registration No. SC349820 whose Registered
                Office is located at 17-19 Hill Street, Kilmarnock, Scotland, KA3 1HA.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                “Terms” means the terms and conditions that are set out in this document and includes any other terms
                and conditions set out in any letter referred to in and appended to these terms and conditions or
                varying these terms and conditions, signed by both You and Us.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                “Services” means all the activities that We agree to undertake for You pursuant to these Terms.
              </Text>

              <Text style={{ ...styles.terms.heading }}>2. NOTICES</Text>

              <Text style={{ ...styles.terms.paragraph }}>
                Any Notices, that are required to be issued by either You or Us relating to these Terms or Services
                provided shall be given in writing and either delivered personally or sent by recorded delivery post to
                the address given in these terms.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                Such notices shall only be effective when delivered personally by You or Us or when placed in the mail
                if mailed in the manner provided above.
              </Text>

              <Text style={{ ...styles.terms.heading }}>3. GENERAL</Text>

              <Text style={{ ...styles.terms.paragraph }}>
                We undertake to perform all services set out in this agreement on the basis of the Terms contained
                herein only.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                No variation of these Terms shall be binding unless agreed in writing by both You and Us.
              </Text>

              <Text style={{ ...styles.terms.heading }}>4. SERVICES</Text>

              <Text style={{ ...styles.terms.paragraph }}>
                We undertake to provide such Services (the “Services”) as would be expected of an experienced Structural
                Engineer familiar with the delivery of Building and Civil Engineering projects in a professional manner
                and We will perform the services with reasonable skill and care.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                Our services shall be limited to those set out in Schedule(s) attached to these Terms.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                These services are, however, provided subject to the following:
              </Text>
              <Text style={{ ...styles.terms.paragraph, paddingLeft: 30 }}>
                We may, if appropriate and where agreed in writing by Us and You, secure performance of any or all
                services by instructing one or more other persons, firms or companies (whether as a sub-consultant or in
                any other capacity) upon such terms as we consider appropriate. In these circumstances no additional fee
                shall be payable by You in the absence of prior agreement to such additional fee but You shall be liable
                to pay all fees and other sums payable to Us as if all services had been performed by Us.
              </Text>
              <Text style={{ ...styles.terms.paragraph, paddingLeft: 30 }}>
                You agree that We shall not be responsible for the performance of any third party engaged or arranged by
                You to provide any services for We are to rely.
              </Text>

              <Text style={{ ...styles.terms.heading }}>5. INFORMATION PROVIDED BY YOU</Text>

              <Text style={{ ...styles.terms.paragraph }}>
                You undertake that all information provided by You and / or Your professional advisors regarding any
                services undertaken is accurate and factually correct and You acknowledge that We will rely upon this
                information to perform our services.
              </Text>

              <Text style={{ ...styles.terms.heading }}>6. INSURANCE</Text>

              <Text style={{ ...styles.terms.paragraph }}>
                We shall take out a policy of Professional Indemnity Insurance with a limit of indemnity of £500,000
                (Five Hundred Thousand Pounds) for any one occurrence or series of occurrences arising out of any one
                event (except for pollution and contamination) which shall be in the aggregate and maintain such
                insurance for a period of one year from the date of completion of the services providing such insurance
                remains available in the market on reasonable rates and terms.
              </Text>

              <Text style={{ ...styles.terms.heading }}>7. FEES</Text>

              <Text style={{ ...styles.terms.paragraph }}>
                Fees will be charged as set out in Our proposal, Our terms or Our proposals or the attached Schedule.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                In the event of a change in the scope of Our services, We reserve the right to adjust Our fees
                accordingly.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                If this appointment is terminated by You or Us, We shall be entitled to charge a fair and reasonable
                proportion of Our fees in accordance with clause 7 below.
              </Text>

              <Text style={{ ...styles.terms.heading }}>8. PAYMENT</Text>

              <Text style={{ ...styles.terms.paragraph }}>
                We will invoice You for Our fees as when is appropriate, or on a weekly basis in arrears, or in
                accordance with a payment schedule or rates and prices agreed between You and Us.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                Any disbursements or expenses that are not included within Our fees will be invoiced to You after the
                expenditure is incurred.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                VAT will be payable where applicable at the rate prevailing at the time of invoicing on all fees,
                expenses and other disbursements.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                If after 14 days of the date of Our invoice where You have not paid it in full, We reserve the right to
                charge You interest on any unpaid invoice at the rate of 8% per annum above the base lending rate of the
                Bank of England Base Rate calculated on a daily basis.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                Should you fail to make payment in accordance with the terms agreed, we shall after giving written
                Notice suspend the performance of our services. In any of these circumstances We shall not be liable for
                any delays, losses or expenses resulting from such suspension.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                In the event of You becoming Insolvency and / or entering into Administration; Your Directors expressly
                agree that they shall be personally or jointly and severally responsible and liable for payment of any
                and all outstanding invoice / fees and / or expenses owed to Us at the time Your insolvency occurs.
              </Text>

              <Text style={{ ...styles.terms.heading }}>9. COPYRIGHT</Text>

              <Text style={{ ...styles.terms.paragraph }}>
                Copyright in any information, documents or other material provided by You to Us will remain Your
                property.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                The copyright of all documents originated by Us remains Our property and shall not be used by You for
                any other purpose other than that associated with Our services to You under these Terms.
              </Text>

              <Text style={{ ...styles.terms.heading }}>10. DISPUTES</Text>

              <Text style={{ ...styles.terms.paragraph }}>
                If You have a complaint in respect of Our performance and the Services provided and without prejudice to
                any other remedy available under this appointment You shall be entitled to have access to Our complaints
                handling procedure, written copies of which are available from Us on request.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                Both You and We agree to try to resolve any complaint prior to engaging in any dispute resolution
                procedure or any other method of resolving a dispute available herein or otherwise available which is
                considered the cost effective.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                Notwithstanding, any attempt to negotiate, mediate or any other means of resolving a dispute, these
                Terms expressly provide for any dispute to be decided by Adjudication. The rules shall be those
                contained in the latest version of the Scheme for Construction Contracts (Scotland) Regulations.
              </Text>

              <Text style={{ ...styles.terms.heading }}>11. TERMINATION</Text>

              <Text style={{ ...styles.terms.paragraph }}>
                This appointment may be terminated by either of us by giving the other 14 days written notice subject to
                clause below.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                This appointment may be terminated by Us by giving You 14 days notice in writing for the following
                reasons:
              </Text>
              <Text style={{ ...styles.terms.paragraph, paddingLeft: 30 }}>
                If, as a result of circumstances outside the control of You or Us it becomes impossible to perform the
                services within a reasonable period, or
              </Text>
              <Text style={{ ...styles.terms.paragraph, paddingLeft: 30 }}>
                If You do not fulfil Your obligations, or
              </Text>
              <Text style={{ ...styles.terms.paragraph, paddingLeft: 30 }}>
                You have not made a payment by the date of any sum payable by You to Us.
              </Text>

              <Text style={{ ...styles.terms.heading }}>12. LIABILITY</Text>

              <Text style={{ ...styles.terms.paragraph }}>
                Liability for any negligent failure by Us to carry out Our duties under these Terms shall be strictly
                limited to such liability as is covered by the terms of Our Professional Indemnity Insurance Policy.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                Our Maximum Liability is also limited to such a sum as it would be equitable for Us to pay having regard
                to the extent of Our responsibility for any loss or damage suffered by You on the basis that all other
                consultants, contractors and subcontractors who also have a liability shall be deemed to have provided
                contractual undertakings to You on terms no less onerous than these Terms and shall be deemed to have
                paid to You such sums as it would be just and equitable for them to pay having regard to the extent of
                their responsibility for any such loss or damage and in no event shall Our liability exceed the fees
                paid to Us.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                Where in this appointment We are under an obligation to ensure events occur which are under the direct
                control of others and, due to others, We are unable to comply with that obligation, then We will be
                liable to You only in the event that We have failed to use reasonable endeavours to ensure the
                occurrence of the event.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                We do not warrant the work of others, save that nothing in this clause shall affect Our responsibility
                for Our sub-consultants if any. Notwithstanding this, We are required to inform You in writing of the
                action We have taken to resolve the matter and recommend to You a further course of action to ensure the
                event does not reoccur.
              </Text>

              <Text style={{ ...styles.terms.heading }}>13. LAW</Text>

              <Text style={{ ...styles.terms.paragraph }}>
                The Terms of this Appointment shall be governed by and construed in all respects in accordance with
                Scottish Law.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                We and You irrevocably submit to the exclusive jurisdiction of the Scottish Courts in relation to any
                dispute or proceedings arising out of or in connection with these Terms subject to the operation of
                clause 9.
              </Text>

              <Text style={{ ...styles.terms.heading }}>14. THIRD PARTY RIGHTS</Text>
              <Text style={{ ...styles.terms.paragraph }}>
                This agreement does not purport to, or confer, any benefit to any person and / or class of persons and /
                or business entity that is not expressly described, referred to or named herein.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                The application of the Contracts (Third Party Rights) (Scotland) Act 2017 is expressly excluded; as is
                the Scots common law principle of ‘jus quaesitum tertio’
              </Text>
              <Text style={{ ...styles.terms.paragraph }}></Text>
              <Text style={{ ...styles.terms.heading }} minPresenceAhead={20}>
                15. ASSIGNMENT
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                Neither Party shall assign sub-let or otherwise transfer any obligation or benefit under this Agreement
                without the prior written consent of the other Party which consent shall not unreasonably be delayed or
                withheld.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                Assignment shall be by absolute legal assignment only and only to a party taking on the entire role of
                the Consultant or Client as the case may be in relation to the project
              </Text>

              <Text style={{ ...styles.terms.heading }}>16. CONFIDENTIALITY</Text>
              <Text style={{ ...styles.terms.paragraph }}>
                Both You and We agree that we shall only use, copy or otherwise replicate any information for no other
                purposes than those envisaged under this Agreement. Both You and We agree not to use such information
                for any other purpose whatsoever.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                You and We agreed to ensure all Information of our respective business affairs confidential and shall
                not directly or indirectly disclose, publish, transfer, disseminate, copy or permit to be disclosed the
                same to any third party for any reason without the prior written consent of the Disclosing Party.
              </Text>

              <Text style={{ ...styles.terms.heading }}>17. ENTIRE AGREEMENT</Text>
              <Text style={{ ...styles.terms.paragraph }}>
                These Terms constitutes the entire agreement between You and Us in respect of the subject matter and
                supersedes all prior correspondence, negotiations, understandings, discussions and agreements, either
                written or oral, between You and Us with respect to its subject matter.
              </Text>

              <Text style={{ ...styles.terms.paragraph }}>
                These Terms may not be altered, amended or modified except where agreed by You and Us in the form of a
                supplemental written agreement signed by both You and Us.
              </Text>
              <Text style={{ ...styles.terms.paragraph }}>
                It is agreed and understood that any alteration, amendment or modification of these Terms contained in
                e-mail exchanges or correspondence between You and Us shall not be effective unless and until reduced in
                the form of such a supplemental written agreement signed by You and Us..
              </Text>
              <Text style={{ ...styles.terms.heading }}>18. AGREEMENT</Text>
              <View
                style={{
                  ...styles.terms.paragraph,
                  position: "relative",
                  height: 15,
                }}
              >
                <View style={{ position: "absolute", left: 0 }}>
                  <Text style={{ ...styles.terms.paragraph }}>Prepared By: </Text>
                </View>
                <View style={{ position: "absolute", left: 80 }}>
                  <Text style={{ ...styles.terms.paragraph }}>Malachy Ryan </Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.terms.paragraph,
                  position: "relative",
                  height: 15,
                }}
              >
                <View style={{ position: "absolute", left: 0 }}>
                  <Text style={{ ...styles.terms.paragraph }}>Position: </Text>
                </View>
                <View style={{ position: "absolute", left: 80 }}>
                  <Text style={{ ...styles.terms.paragraph }}>Director </Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.terms.paragraph,
                  position: "relative",
                  height: 15,
                }}
              >
                <View style={{ position: "absolute", left: 0 }}>
                  <Text style={{ ...styles.terms.paragraph }}>Date: </Text>
                </View>
                <View style={{ position: "absolute", left: 80 }}>
                  <Text style={{ ...styles.terms.paragraph }}>
                    {moment(quote.reviewApprovedAt || undefined).format("DD MMMM YYYY")}{" "}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.terms.paragraph,
                  position: "relative",
                  height: 30,
                  marginTop: 0,
                }}
              >
                <View style={{ position: "absolute", left: 0 }}>
                  <Text style={{ ...styles.terms.paragraph, fontWeight: "bold" }}>Signed as Deed </Text>
                  <Text style={{ ...styles.terms.paragraph, fontWeight: "bold" }}>on YOUR behalf: </Text>
                </View>
                <View style={{ position: "absolute", left: 80 }}>
                  <View
                    style={{
                      width: 100,
                      height: 1,
                      backgroundColor: colors.black,
                      position: "absolute",
                      left: 0,
                      top: 25,
                    }}
                  ></View>
                </View>
              </View>
              {["Company Name", "Address", "Name", "Position", "Witness to Signature", "Name", "Date"].map(
                (label, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                        ...styles.terms.paragraph,
                        position: "relative",
                        height: 20,
                      }}
                    >
                      <View style={{ position: "absolute", left: 0 }}>
                        <Text style={{ ...styles.terms.paragraph }}>{label}</Text>
                      </View>
                      <View style={{ position: "absolute", left: 80 }}>
                        <View
                          style={{
                            width: 100,
                            height: 1,
                            backgroundColor: colors.black,
                            position: "absolute",
                            left: 0,
                            top: 15,
                          }}
                        ></View>
                      </View>
                    </View>
                  );
                }
              )}
            </>
          )}
        </View>

        <Text style={{ ...styles.terms.heading }}>
          {form.fields?.termsAndConditions?.value === "as-per-contract" ? null : "19."} FEE and SCOPE OF SERVICE
        </Text>
        <Text style={{ ...styles.terms.paragraph }}>As the attached quote and Schedule A</Text>

        <Text style={{ ...styles.terms.heading, marginTop: 10 }}>SCHEDULE A</Text>
        {this.displayFees()}
      </Page>
    );
  };

  displayFees = () => {
    const { quote, organisationDetails, clients } = this.props;

    const clientDetails = clients.find((client) => client.id === quote.clientId);

    const feesColumns = [
      {
        title: "Additional Fees",
        dataIndex: "label",
        width: 120,
      },
      {
        title: "Rates and Prices",
        dataIndex: "valueWithCurrency",
        width: 200,
      },
    ];

    const feesData = getFeesForClient({
      organisationDetails,
      quote,
      clientDetails,
      currency: "GBP",
    });
    feesData.push(
      {
        label: "Mileage",
        valueWithCurrency: "£0.60 per mile or part thereof",
        skipSuffix: true,
      },
      {
        label: "Expenses",
        valueWithCurrency: "Cost plus 15%",
        skipSuffix: true,
      }
    );

    const cellStyleFunction = (row, col) => {
      const cellStyle = {
        width: feesColumns[col].width || `${95 / feesColumns.length}%`,
        borderWidth: 0,
        fontWeight: "bold",
        borderStyle: "solid",
        backgroundColor: row === 0 ? "#f7f7f7" : "#fff",
        // borderRightWidth: row === 0 ? 1 : 0,
        // borderLeftWidth: row === 0 && col === 0 ? 1 : 0,
        borderBottomWidth: row === 0 ? 1 : 0,
        borderColor: "#f1f1f1",
        padding: "2pt 5pt",
        fontSize: 8,
        color: colors.black,
      };

      return cellStyle;
    };

    return (
      <View style={{ marginTop: 0 }}>
        <Table
          style={{ marginTop: 5 }}
          includeHeader={true}
          columns={feesColumns}
          data={feesData.map((fee, index) => {
            return {
              ...fee,
              valueWithCurrency: `${fee.valueWithCurrency} ${fee.skipSuffix ? "" : "per hour or part thereof + VAT"}`,
            };
          })}
          style_function={cellStyleFunction}
        />
      </View>
    );
  };

  displayCaseStudies = () => {
    const { form } = this.props;

    const caseStudies = form.fields.caseStudies?.value;
    if (!caseStudies) {
      return null;
    }
    return caseStudies.map((key, i) => {
      return displayPdfAttachment({
        elementKey: i,
        key,
        label: `${key.split("public/AWD/Document Library/").join("")}`,
      });
    });
  };

  displayDocument = () => {
    const { quote, clients, form } = this.props;

    const client = clients.find((x) => x.id === quote.clientId);
    let addressDetails = (client.addresses || []).find((x) => x.id === quote.clientAddress);

    window.lambdaPdfAssets = [
      {
        bucket: "draughthub-public-assets",
        name: "organisationLogo",
        key: "AWD/awd-logo.png",
      },
      {
        bucket: awsExports.aws_user_files_s3_bucket,
        name: "clientLogo",
        key: "public/" + quote.client.key,
        width: 110,
        height: 35,
      },
    ];

    // this is used to mark pages where we do not want page borders, logo or other markers to be applied
    window.lambdaPdfPageNumbersToSkipBorders = [0, -1];

    window.pdfPageNumbersToDownload = {};

    let lineItemColumns = [
      {
        title: "Description",
        dataIndex: "descriptionAndTitle",
        width: 350,
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        width: 30,
      },
      {
        title: "Unit Price",
        dataIndex: "unitPrice",
        width: 60,
      },
      {
        title: "VAT",
        dataIndex: "taxRate",
        width: 25,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        width: 60,
      },
    ];

    const cell_style = (row, col) => {
      const COLUMN_ALIGNMENT = {
        0: "left",
        1: "center",
        2: "center",
        3: "center",
        4: "right",
      };

      const cellStyle = {
        width: lineItemColumns[col].width || `${95 / lineItemColumns.length}%`,
        borderWidth: 0,
        fontWeight: "bold",
        borderStyle: "solid",
        backgroundColor: row === 0 ? "#f7f7f7" : "#fff",
        // borderRightWidth: row === 0 ? 1 : 0,
        // borderLeftWidth: row === 0 && col === 0 ? 1 : 0,
        // borderTopWidth: row === 0 ? 1 : 0,
        borderBottomWidth: row === 0 ? 1 : 0,
        borderColor: "#f1f1f1",
        padding: "5pt 5pt",
        fontSize: 10,
        textAlign: COLUMN_ALIGNMENT.hasOwnProperty(col) ? COLUMN_ALIGNMENT[col] : "left",
        color: colors.black,
      };

      return cellStyle;
    };

    let lineItemsForTable = quote.lineItems.items
      .filter((lineItem) => !lineItem.isRejected)
      .map((lineItem, index) => {
        return {
          ...lineItem,
          item: index,
          descriptionAndTitle: (
            <>
              <Text>{lineItem.title}</Text>
              <Text>{lineItem.description}</Text>
            </>
          ),
          taxRate: `${quote.taxRate}%`,
          unitPrice: window.formatCurrency("GBP", lineItem.unitPrice),
          amount: window.formatCurrency("GBP", lineItem.amount),
        };
      });

    let atLeastOneLineItemIsHourly = quote.lineItems.items.some((x) => x.isHourly);
    let hourlyRateMention = atLeastOneLineItemIsHourly ? (
      <Text style={{ ...styles.text, textAlign: "right", fontWeight: "bold" }}> + Hourly Rate</Text>
    ) : null;

    return (
      <Document>
        <FrontCover
          approvedAt={quote.reviewApprovedAt}
          infoItems={this.getQuoteFrontCoverInfoItems()}
          coverImage={CoverBackground}
        />
        {displayPdfAttachment({
          key: "public/AWD/Document Library/AWD quote template - presentation page.pdf",
          label: "Presentation page",
          hasBorders: true,
        })}
        {this.displayCaseStudies()}
        <Page size="A4" style={styles.quotePage} wrap>
          {this.displayWatermark()}
          <View>
            <Text style={{ ...styles.pageTitle, marginTop: 0 }} break>
              Quote
            </Text>
            <View
              style={{
                backgroundColor: colors.black,
                width: "100%",
                height: 2,
                marginTop: -10,
                marginBottom: 20,
              }}
            />
            <View style={{ position: "relative", height: 130 }}>
              <View style={{ position: "absolute", top: 0, left: 0 }}>
                <Text style={{ ...styles.text, fontWeight: "bold" }}>{client.name}</Text>
                <Text style={{ ...styles.text, fontWeight: "bold" }}>
                  {addressDetails?.streetNumber} {addressDetails?.streetName}
                </Text>
                {addressDetails?.houseName ? <Text style={styles.text}>{addressDetails?.houseName}</Text> : null}
                <Text style={{ ...styles.text, fontWeight: "bold" }}>{addressDetails?.city}</Text>
                <Text style={{ ...styles.text, fontWeight: "bold" }}>{addressDetails?.postcode}</Text>
              </View>
              <View style={{ position: "absolute", top: 0, left: 200 }}>
                <Text style={[styles.text, styles.bold]}>Quote Date</Text>
                <Text style={styles.text}>{moment(quote.reviewApprovedAt || undefined).format("DD MMM YYYY")}</Text>

                <Text style={[styles.text, styles.bold, { marginTop: 10 }]}>Expiry</Text>
                <Text style={styles.text}>
                  {moment(quote.reviewApprovedAt || undefined)
                    .add(30, "day")
                    .format("DD MMM YYYY")}
                </Text>

                <Text style={[styles.text, styles.bold, { marginTop: 10 }]}>Quote Number</Text>
                <Text style={styles.text}>{quote.id}</Text>
              </View>
              <View style={{ position: "absolute", top: 0, right: 0 }}>
                <Text style={[styles.text, styles.bold]}>Alan White Design Limited</Text>
                <Text style={[styles.text, styles.bold]}>17-19 Hill Street</Text>
                <Text style={[styles.text, styles.bold]}>Kilmarnock</Text>
                <Text style={[styles.text, styles.bold]}>East Ayrshire</Text>
                <Text style={[styles.text, styles.bold]}>KA3 1HA</Text>
                <Text style={[styles.text, styles.bold]}>UNITED KINGDOM</Text>
              </View>
            </View>

            <Text style={[styles.text, styles.bold, { fontSize: 13, marginBottom: 10 }]}>
              {quote.title.toUpperCase()}
            </Text>
            <Table
              style={{ marginTop: 10, border: "1px solid #fff" }}
              includeHeader={true}
              columns={lineItemColumns}
              data={lineItemsForTable}
              style_function={cell_style}
            />
            {form.fields?.notes?.value !== undefined &&
              form.fields?.notes?.value !== null &&
              form.fields?.notes?.value.length !== 0 && (
                <View style={{ paddingLeft: 5, marginTop: 10, marginBottom: -20 }}>
                  <Text
                    style={{
                      ...styles.text,
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Notes:
                  </Text>
                  {displayPdfTextarea({
                    fieldName: "notes",
                    targetReportJsonData: form,
                    attachmentImages: this.state.attachmentImages,
                    styles,
                    displayTitle: false,
                    projectFolder: this.props.projectFolder,
                  })}
                </View>
              )}
            <View style={{ marginTop: 10, textAlign: "right" }}>
              <View style={{ position: "relative", height: 18 }}>
                <View style={{ position: "absolute", right: 65 }}>
                  <Text
                    style={{
                      ...styles.text,
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    Subtotal
                  </Text>
                </View>
                <View style={{ position: "absolute", right: 0 }}>
                  <Text
                    style={{
                      ...styles.text,
                      textAlign: "right",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    {window.formatCurrency("GBP", quote.subtotal)}
                  </Text>
                </View>
              </View>
              <View style={{ position: "relative", height: 18 }}>
                <View style={{ position: "absolute", right: 65 }}>
                  <Text style={{ ...styles.text, textAlign: "right" }}>Total VAT {quote.taxRate}%</Text>
                </View>
                <View style={{ position: "absolute", right: 0 }}>
                  <Text style={{ ...styles.text, textAlign: "right" }}>
                    {window.formatCurrency("GBP", quote.totalTax)}
                  </Text>
                </View>
              </View>
              <View style={{ position: "relative", height: 18 }}>
                <View style={{ position: "absolute", right: 65 }}>
                  <Text
                    style={{
                      ...styles.text,
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    Quote Total GBP
                  </Text>
                </View>
                <View
                  style={{
                    position: "absolute",
                    right: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Text
                    style={{
                      ...styles.text,
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    {window.formatCurrency("GBP", quote.total)}
                  </Text>
                  {hourlyRateMention}
                </View>
              </View>
            </View>
          </View>
        </Page>
        {this.displayTermsAndConditions()}
        <RearCover coverImage={RearCoverImage} />
      </Document>
    );
  };

  render() {
    const { quote, layout = "default", renderMode } = this.props;
    const { isLoading } = this.state;

    if (!quote || isLoading) {
      return (
        <div className="report-preloader">
          <LoadingOutlined />
        </div>
      );
    }

    return (
      <div className="report report-AWD">
        <ReportPreview
          document={this.displayDocument()}
          layout={layout}
          renderMode={renderMode}
          renderKey={JSON.stringify(quote)}
          onDataUri={this.props.onDataUri}
        />
      </div>
    );
  }
}

export default React.memo(QuoteDefaultAWD);
