import { useEffect } from "react";
import { Spin, Button, message } from "antd";
import { useGetSetState } from "react-use";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import cx from "classnames";

import getS3File from "common/getS3File";
import { CloseIcon } from "common/icons";

import DocumentDetailsModal from "Modals/DocumentDetailsModal/DocumentDetailsModal";

import "./FilePreview.scss";

type Props = {
  attachments: any[];
  initiallySelectedAttachment: any;
  onClose: () => void;
  organisationId: string;
  allowedTypes?: string[];
};

type State = {
  selectedAttachment: any;
  publicFileUrl?: string;
  fileData: any;
  selectedItemIndex?: number;
  isPreloaderVisible: boolean;
};

export default function FilePreview({
  attachments,
  initiallySelectedAttachment,
  onClose,
  organisationId,
  allowedTypes = ["IMAGE"],
}: Props) {
  const validAttachments = attachments.filter((attachment) => allowedTypes.includes(attachment.type));

  const [getState, setState] = useGetSetState<State>({
    selectedAttachment: initiallySelectedAttachment,
    publicFileUrl: undefined,
    fileData: undefined,
    selectedItemIndex: undefined,
    isPreloaderVisible: false,
  });

  const state = getState();

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!state.selectedAttachment) {
      return;
    }

    getAndSetPublicFileUrl(state.selectedAttachment.key);

    async function getAndSetPublicFileUrl(key) {
      // const type = getAttachmentTypeFromKey(key);
      const messageKey = "loading-attachment-preview";

      setState({ isPreloaderVisible: true, fileData: undefined });

      const selectedItemIndex = getIndexOfAttachment(state.selectedAttachment, validAttachments);

      // if (type === "PDF") {
      message.loading({ content: "Loading preview", key: messageKey, duration: 0 });
      // }
      try {
        const publicFileUrl = await getS3File(key.replace("public/", ""));

        const fileDataBlob = (await axios.get(publicFileUrl, { responseType: "blob" })).data;
        const fileData = await new Response(fileDataBlob).arrayBuffer();
        setState({ publicFileUrl, fileData, selectedItemIndex, isPreloaderVisible: false });
        message.destroy(messageKey);
      } catch (e) {
        console.error("Error when loading file preview:", e);
        message.error({ content: "Error loading attachment", key: messageKey, duration: 5 });
        setState({ isPreloaderVisible: false });
      }
    }
  }, [state.selectedAttachment]); // eslint-disable-line react-hooks/exhaustive-deps

  function onKeyDown(event) {
    if (event.key === "ArrowLeft") {
      onPreviousClick();
    } else if (event.key === "ArrowRight") {
      onNextClick();
    } else if (event.key === "Escape") {
      onClose();
    }
  }

  async function onPreviousClick() {
    const { selectedItemIndex } = getState();

    if (selectedItemIndex === 0) {
      return;
    }
    setState({
      selectedAttachment: validAttachments[selectedItemIndex - 1],
    });
  }

  async function onNextClick() {
    const { selectedItemIndex } = getState();

    if (selectedItemIndex === validAttachments.length - 1) {
      return;
    }

    setState({
      selectedAttachment: validAttachments[selectedItemIndex + 1],
    });
  }

  function getIndexOfAttachment(attachment, validAttachments) {
    return validAttachments.findIndex((a) => a.key === attachment.key);
  }

  function displayContent() {
    switch (state.selectedAttachment.type) {
      case "IMAGE":
        return displayImage();
      case "PDF":
        return displayPdf();
      default:
        return displayImage();
    }
  }

  function displayImage() {
    return <img src={publicFileUrl} alt="" className="main-container" />;
  }

  function displayPdf() {
    if (!state.fileData) {
      return null;
    }
    return (
      <DocumentDetailsModal
        open={true}
        attachment={state.selectedAttachment}
        document={state.fileData}
        onClose={onClose}
        // displayInModal={false}
        // windowWidth={windowWidth}
        // windowHeight={windowHeight}
      />
    );
  }

  const { publicFileUrl, selectedItemIndex, isPreloaderVisible, selectedAttachment } = getState();

  if (!validAttachments || !publicFileUrl || selectedItemIndex === undefined) {
    return null;
  }

  return (
    <div className={cx("file-preview", `file-preview-${state.selectedAttachment.type}`)}>
      <div className="file-preview-mask" onClick={onClose} />
      <Button className="close-file-preview-button" icon={<CloseIcon />} type="clear" onClick={onClose} />

      {isPreloaderVisible ? (
        <div className="spinner-container">
          <Spin />
        </div>
      ) : (
        displayContent()
      )}
      <Button
        type="clear"
        icon={<LeftCircleOutlined />}
        className="file-preview-button-previous"
        onClick={onPreviousClick}
        disabled={selectedItemIndex <= 0}
      />
      <Button
        type="clear"
        icon={<RightCircleOutlined />}
        className="file-preview-button-next"
        onClick={onNextClick}
        disabled={selectedItemIndex >= validAttachments.length - 1}
      />

      <div className="file-preview-footer">
        <span className="counter">
          {selectedItemIndex + 1} / {validAttachments.length}
        </span>
        <span className="selected-file-path">{selectedAttachment.key.replace(`public/${organisationId}/`, "")}</span>
      </div>
    </div>
  );
}
