export function isSlateValueEmpty(value) {
  let nodes;
  if (typeof value === "string") {
    if (value === "") {
      return true;
    }
    try {
      nodes = JSON.parse(value);
    } catch (e) {
      throw new Error("Text editor isn't empty, but it's not valid either");
    }
  } else {
    nodes = value;
  }
  // If the nodes is undefined, null, or an empty array, consider it empty
  if (!nodes || nodes.length === 0) {
    return true;
  }

  // Iterate through all the nodes in the editor nodes
  for (const node of nodes) {
    // Check if the node has children and the children contain non-empty text
    if (node.children && node.children.length > 0) {
      // Check each child node
      for (const child of node.children) {
        // If it's a text node and contains non-empty text, the editor is not empty
        if (child.text && child.text.trim() !== "") {
          return false;
        }

        // If it's an element node (e.g., inline elements) with non-empty text inside, return false
        if (child.children && child.children.some((grandchild) => grandchild.text && grandchild.text.trim() !== "")) {
          return false;
        }
      }
    }
  }

  // If no non-empty text nodes were found, consider the editor empty
  return true;
}
