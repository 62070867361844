import { Button, message } from "antd";

import { callGraphQLSimple, callRest } from "common/apiHelpers";
import { Organisation, Integration } from "common/types";

import QuickbooksButton from "./QuickbooksButton/QuickbooksButton.jsx";

import moment from "moment";

export async function getQuickbooksAuthUri(organisationId?: string) {
  const messageKey = "get-quickbooks-auth-uri";
  message.loading({
    content: "Connecting to Quickbooks...",
    key: messageKey,
    duration: 0,
  });
  try {
    const authUri = await callRest({
      route: "/get-quickbooks-auth-uri",
      method: "POST",
      body: {
        organisationId,
      },
      includeCredentials: false,
    });
    window.location.href = authUri;
  } catch (err: any) {
    if (err.response.data) {
      message.error({
        content: <>Error connecting to Quickbooks. Reason: {err.response.data}</>,
        key: messageKey,
      });
    } else {
      message.error({
        content: "Error connecting to Quickbooks",
        key: messageKey,
      });
    }
  }
}

export async function disconnectQuickbooks(organisationDetails: Organisation) {
  const messageKey = "disconnect-quickbooks";
  message.loading({
    content: "Disconnecting Quickbooks...",
    key: messageKey,
    duration: 0,
  });
  await callRest({
    route: "/disconnect-quickbooks",
    method: "POST",
    body: {
      organisationId: organisationDetails.id,
    },
    includeCredentials: false,
  });

  await callGraphQLSimple({
    message: "Failed to authorise the Quickbooks integration",
    queryName: "updateOrganisation",
    variables: {
      input: {
        id: organisationDetails.id,
        integrations: [...(organisationDetails.integrations || [])].map((integration: Integration) => {
          if (integration.id !== "QUICKBOOKS") {
            return integration;
          }
          return {
            ...integration,
            authorisedAt: null,
          };
        }),
      },
    },
  });

  await callGraphQLSimple({
    message: "Failed to update the accountancy organisation ID",
    queryName: "updateOrganisation",
    variables: {
      input: {
        id: organisationDetails.id,
        accountancyOrganisationId: null,
      },
    },
  });

  message.success({
    content: "Quickbooks integration removed.",
    key: messageKey,
  });
}

export function getQuickbooksStatus(quickbooksDetails?: Integration) {
  if (quickbooksDetails && quickbooksDetails?.authorisedAt) {
    const expirationDate = moment(quickbooksDetails?.authorisedAt).add(1, "month").toISOString();

    if (moment().isAfter(expirationDate)) {
      return "Expired";
    } else {
      return "Active";
    }
  } else {
    return "Not connected";
  }
}

export function displayQuickbooksActions(quickbooksStatus: string, organisationDetails: Organisation) {
  return (
    <div className="quickbooks-actions">
      {quickbooksStatus === "Active" ? null : (
        <QuickbooksButton onClick={async () => await getQuickbooksAuthUri(organisationDetails.id)} />
      )}
      {quickbooksStatus === "Active" ? (
        <Button type="primary" onClick={async () => await disconnectQuickbooks(organisationDetails)}>
          Disconnect Quickbooks
        </Button>
      ) : null}
    </div>
  );
}
