import { getSimpleLabel } from "common/labels";
import moment from "moment";

export const tableColumns = [
  { title: `${getSimpleLabel("Client")} name`, fieldFunction: (_, quote) => quote.client.name },
  { title: "PO number", fieldFunction: (_, quote) => quote.poNumber },
  {
    title: `${getSimpleLabel("Project")} title`,
    fieldFunction: (_, quote) => quote.project.title,
    onFilter: (value, record) => record.name.includes(value),
  },
  { title: "Line item ID", width: 110, fieldFunction: (item) => item.id },
  { title: `${getSimpleLabel("Quote")} ID`, width: 110, fieldFunction: (_, quote) => quote.id },
  { title: `${getSimpleLabel("Quote")} title`, fieldFunction: (_, quote) => quote.title, width: 150 },
  { title: "Line item title", dataIndex: "title", width: 150 },
  {
    title: `${getSimpleLabel("Quote")} status`,
    fieldFunction: (_, quote) => quote.status?.split("_")?.join(" "),
    width: 170,
  },
  {
    title: `${getSimpleLabel("Quote")} line item rejected`,
    fieldFunction: (item, quote) => {
      if (item.isRejected) {
        return "REJECTED";
      } else {
        return "";
      }
    },
    width: 170,
  },
  {
    title: "Invoicing status",
    fieldFunction: (item) => {
      if (!item.invoicingStatus) {
        return "NOT INVOICED";
      }
      return item.invoicingStatus?.split("_")?.join(" ");
    },
    width: 170,
  },
  {
    title: "Amount invoiced in total",
    fieldFunction: (item) => item.invoicedAmount || 0,
    width: 170,
  },
  {
    title: "Amount invoiced manually",
    fieldFunction: (item) => item.manuallyInvoicedAmount || 0,
    width: 170,
  },
  {
    title: "Is fully invoiced manually",
    fieldFunction: (item) => (item.isManuallyFullyInvoiced ? "yes" : "no"),
    width: 100,
  },
  {
    title: "Hourly line item - fully invoiced",
    fieldFunction: (item) => (item.isHourlyFullyInvoiced ? "yes" : "no"),
    width: 100,
  },

  {
    title: "Design fee",
    width: 110,
    fieldFunction: (item) => {
      return item.unitPrice || 0;
    },
  },
  {
    title: "Quantity",
    width: 110,
    fieldFunction: (item) => item.quantity,
  },
  {
    title: "Design check fee",
    width: 110,
    fieldFunction: (item) => {
      return item.checkPrice || 0;
    },
  },
  {
    title: "Amount without tax",
    width: 110,
    fieldFunction: (item) => {
      return item.amount || 0;
    },
  },
  {
    title: "Tax amount",
    width: 110,
    fieldFunction: (item) => {
      return item.taxAmount || 0;
    },
  },
  {
    title: "Amount with tax",
    width: 110,
    fieldFunction: (item) => {
      return item.amount + item.taxAmount || 0;
    },
  },
  {
    title: "Is hourly",
    fieldFunction: (item) => (item.isHourly ? "yes" : "no"),
    width: 100,
  },
  {
    title: "Created at",
    fieldFunction: (item) => moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss"),
    width: 200,
  },
  {
    title: "Last updated at",
    fieldFunction: (item) => moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
    width: 200,
  },
  {
    title: "Is under review",
    fieldFunction: (_, quote) => (quote.isUnderReview ? "yes" : "no"),
  },
  {
    title: "Has been approved",
    fieldFunction: (_, quote) => (quote.reviewStatus === "SUCCESS" ? "yes" : "no"),
  },
  {
    title: "Client contact name",
    fieldFunction: (_, quote) => {
      const fullContactDetails = quote.client?.contacts?.find((contact) => contact.id === quote.clientContact);

      const contactFullName = `${fullContactDetails?.firstName || ""} ${fullContactDetails?.lastName || ""}`;

      return contactFullName;
    },
  },
  {
    title: "Client contact email",
    fieldFunction: (_, quote) => {
      const fullContactDetails = quote.client?.contacts?.find((contact) => contact.id === quote.clientContact);

      return fullContactDetails?.email;
    },
  },
];
