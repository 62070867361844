import { useState } from "react";
import { Button, Input, message, Modal, Form } from "antd";
import moment from "moment";

import { callGraphQLSimple } from "common/apiHelpers";

import DatePicker from "DatePicker/DatePicker";

import "./TimeOffAllowanceModal.scss";

export default function TimeOffAllowanceModal({ onClose, isSick, interval, apiUser, onSubmit }) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  async function onSubmitInner({ name, startDate, endDate }) {
    setIsLoading(true);

    if (interval) {
      try {
        await callGraphQLSimple({
          mutation: "updateInterval",
          variables: {
            input: {
              id: interval.id,
              startDate: startDate.format("YYYY-MM-DD"),
              endDate: endDate.format("YYYY-MM-DD"),
              name,
            },
          },
        });
        message.success(`Interval updated: ${name}`);
      } catch (e) {
        message.error(`Failed to update interval: ${e.message}`);
      }
    } else {
      try {
        await callGraphQLSimple({
          mutation: "createInterval",
          variables: {
            input: {
              organisation: apiUser.organisation,
              startDate: startDate.format("YYYY-MM-DD"),
              endDate: endDate.format("YYYY-MM-DD"),
              name,
              type: isSick ? "SICK_ALLOWANCE" : "HOLIDAY_ALLOWANCE",
            },
          },
        });
        message.success(`Interval created: ${name}`);
      } catch (e) {
        message.error(`Failed to create interval: ${e.message}`);
      }
    }

    setIsLoading(false);
    onClose();
    onSubmit();
  }

  return (
    <Modal
      title="Add allowance interval"
      className="time-off-allowance-modal"
      open={true}
      onCancel={() => {
        onClose();
      }}
      footer={null}
    >
      <Form
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 16,
        }}
        form={form}
        onFinish={onSubmitInner}
        initialValues={{
          startDate: interval?.startDate ? moment(interval.startDate) : undefined,
          endDate: interval?.endDate ? moment(interval.endDate) : undefined,
          name: interval?.name,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "You need to choose a name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Start date"
          name="startDate"
          rules={[
            {
              required: true,
              message: "You need to choose a start date",
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item
          label="End date"
          name="endDate"
          rules={[
            {
              required: true,
              message: "You need to choose an end date",
            },
          ]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
