import React from "react";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { Text, View, Document } from "@react-pdf/renderer";
import { notification } from "antd";

import { callGraphQLSimple } from "common/apiHelpers";
import getS3File from "common/getS3File";

import { getLatestFileVersion } from "common/shared";
import { getSheetReferenceNumber } from "common/naming";
import { initialiseLambdaPdfInserts } from "common/documentRenderHelpers";

import Page from "ReportPage/Report/ReportPdfPage";
import ReportPreview from "ReportPage/ReportPreview";
import { colors, styles } from "./DC_ReportCommonComponents";
import { Table } from "ReportPage/PdfTable";

import { DATE_INTRODUCED_SHEET_REVIEW_ACCEPT_DATE } from "common/milestoneDates";
import "./ReportDefault.scss";

let hasAlreadyDisplayedAlertAboutMissingData = false;

export class ReportDocumentRegister extends React.Component {
  state = {
    isLoading: true,
    designersRiskAssessmentImageUrl: null,
    attachmentImages: null,
    defaultRiskAssessmentReference: null,
    completeTask: null,
  };

  async componentDidMount() {
    let newState = {};
    const reportFile = this.props.file;
    // if we don't receive the preview data, we load it ourselves
    // this is useful on pages that don't already have that data to pass it down to us
    if (!this.props.previewData) {
      newState.reportJsonData = await this.loadForm({
        file: reportFile,
      });
    } else {
      newState.reportJsonData = this.props.previewData;
    }

    const { taskRevision } = this.props;
    let filePromises = taskRevision.files.items
      // .filter((file) => file.type !== "REPORT")
      .map((file) =>
        callGraphQLSimple({
          message: "Failed to fetch file details",
          queryName: "getFile",
          variables: {
            id: file.id,
          },
        })
      );

    newState.files = (await Promise.all(filePromises)).map((getFileResponse) => getFileResponse.data.getFile);

    const completeTask = (
      await window.callGraphQLSimple({
        message: "Failed to retrieve task details",
        queryCustom: "getTaskWithFiles",
        variables: {
          id: taskRevision.taskId,
        },
      })
    ).data.getTask;

    newState.completeTask = completeTask;

    try {
      this.setState({
        ...newState,
        isLoading: false,
      });
    } catch (e) {
      // nothing, we already show an error message in the function which actually failed
    }
  }

  async componentDidUpdate(prevProps) {
    // if we don't have this block of code, the preview won't update when the user changes something
    // only used on the report page
    if (this.props.previewData !== prevProps.previewData) {
      try {
        this.setState({
          reportJsonData: this.props.previewData,
          isLoading: false,
        });
      } catch (e) {
        // nothing, we already show an error message in the function which actually failed
      }
    }
  }

  loadForm = async ({ file }) => {
    const fileKey = getLatestFileVersion(file).key.replace("public/", "");
    const filePublicURL = await getS3File(fileKey);
    const form = (await axios.get(filePublicURL)).data;
    return form;
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.isStatic && nextState.isLoading === this.state.isLoading && this.props.zoom === nextProps.zoom) {
      return false;
    }
    return true;
  };

  displayDocument = () => {
    const { task, users, taskRevision } = this.props;
    const { isLoading, completeTask, files } = this.state;

    window.pdfPageNumbers = {};
    window.pdfPageNumbersToDownload = {};

    window.lambdaPdfAssets = [
      {
        bucket: "draughthub-public-assets",
        name: "organisationLogo",
        key: "DC/dc-logo.png",
      },
    ];

    if (!task || !users || !taskRevision || isLoading || !completeTask) {
      return (
        <div className="report-preloader">
          <LoadingOutlined />
        </div>
      );
    }

    const REGISTER_COLUMNS = [
      {
        title: "",
        dataIndex: "label",
        key: "label",
        width: "190",
      },
    ];

    const ISSUED_BY_COLUMNS = [
      {
        title: "",
        dataIndex: "label",
        key: "label",
        width: "190",
      },
    ];

    const DOCUMENT_TRANSMITTAL_COLUMNS = [
      {
        title: "",
        dataIndex: "label",
        key: "label",
        width: "190",
      },
    ];

    let reports = {};

    for (let taskRevision of completeTask.revisions.items) {
      for (let file of taskRevision.files.items) {
        if (file.type === "REPORT" && file.templateId !== "document_register") {
          let reportReference = file.sheets.items[0].autoGeneratedReferenceNumber;
          if (!reports.hasOwnProperty(reportReference)) {
            reports[reportReference] = {
              id: file.id,
              reference: reportReference,
              file,
              revisions: { items: [] },
            };
          }
          if (taskRevision.reviewAcceptDate) {
            reports[reportReference].revisions.items.push({
              name: taskRevision.name,
              reviewAcceptDate: taskRevision.reviewAcceptDate,
              checkedBy: taskRevision.checkedBy,
            });
          }
        }
      }
    }

    let sheets = [];

    for (let reportReference in reports) {
      sheets.push(reports[reportReference]);
    }
    for (let file of files) {
      if (file.type !== "REPORT") {
        for (let sheet of file.sheets.items) {
          sheets.push({ ...sheet, file });
        }
      }
    }

    sheets = sheets.filter((sheet) => !sheet.excludeFromRegister);

    let dates = [];

    sheets.forEach((sheet) => {
      sheet.revisions.items.forEach((revision) => {
        if (
          moment(revision.createdAt).isBefore(DATE_INTRODUCED_SHEET_REVIEW_ACCEPT_DATE) &&
          !hasAlreadyDisplayedAlertAboutMissingData
        ) {
          hasAlreadyDisplayedAlertAboutMissingData = true;
          notification.error({
            message: (
              <>
                This task contains sheet revisions created before{" "}
                {moment(DATE_INTRODUCED_SHEET_REVIEW_ACCEPT_DATE).format("DD MMMM YYYY")}. Before this date, we were not
                recording when sheet revisions were reviewed, so data is likely to be missing.
              </>
            ),
            duration: 0,
          });
        }
        if (revision.reviewAcceptDate) {
          let formattedDate = moment(revision.reviewAcceptDate).toISOString();
          if (!dates.includes(formattedDate)) {
            dates.push(formattedDate);
          }
        }
      });
    });

    dates = dates.sort((a, b) => (a < b ? -1 : 1)).map((date) => moment(date).format("DD-MM-YYYY HH:mm"));

    // dates = dates.map((date) => date.split(" ")[0]);

    dates.forEach((date) => {
      REGISTER_COLUMNS.push({
        title: "",
        dataIndex: date,
        key: date,
        width: 25,
      });

      ISSUED_BY_COLUMNS.push({
        title: "",
        dataIndex: date,
        key: date,
        width: 25,
      });

      DOCUMENT_TRANSMITTAL_COLUMNS.push({
        title: "",
        dataIndex: date,
        key: date,
        width: 25,
      });
    });

    let issuedByRows = [{ label: "" }];

    let registerRows = [...sheets]
      // .sort((a, b) => (a.revisions.items[0].realCreatedAt < b.revisions.items[0].realCreatedAt ? -1 : 1))
      .map((sheet) => {
        const rowData = {
          label:
            sheet.file.type === "REPORT"
              ? sheet.reference
              : getSheetReferenceNumber({
                  sheet,
                  sheetRevision: sheet.revisions.items.slice(-1)[0],
                  file: sheet.file,
                  organisationDetails: window.organisationDetails,
                }),
        };
        dates.forEach((date, dateIndex) => {
          let targetRevisionName = "";

          let targetRevision = sheet.revisions.items.find(
            (revision) => moment(revision.reviewAcceptDate).format("DD-MM-YYYY HH:mm") === date
          );
          let targetRevisionDate;
          if (targetRevision) {
            targetRevisionDate = moment(targetRevision.reviewAcceptDate).format("DD-MM-YYYY HH:mm");
            if (!issuedByRows[0][targetRevisionDate]) {
              let reviewerDetails = users.find((x) => x.id === targetRevision.checkedBy);
              const initials = `${reviewerDetails.firstName[0].toUpperCase()}${reviewerDetails.lastName[0].toUpperCase()}`;
              issuedByRows[0][targetRevisionDate] = initials;
            }
          }

          if (targetRevision) {
            targetRevisionName = targetRevision.name;
          }
          rowData[date] = targetRevisionName;
        });
        return rowData;
      });

    let transmittalRows = [
      {
        label: "Day",
      },
      {
        label: "Month",
      },
      {
        label: "Year",
      },
    ];

    dates.forEach((date) => {
      let dateParts = date.split("-");
      transmittalRows[0][date] = dateParts[0];
      transmittalRows[1][date] = dateParts[1];
      transmittalRows[2][date] = dateParts[2].split(" ")[0].substring(2);
    });

    const getCellStyleDocumentRegister = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 1 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: REGISTER_COLUMNS[col].width,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: row === 0 ? colors.gray : colors.gray,
        padding: col === 0 ? "1pt 5pt" : "1pt",
        fontSize: 10,
        textAlign: col === 0 ? "right" : "center",
      };

      let specificStyle = {
        backgroundColor: row === 0 ? colors.accentColorDark : "#fff",
        color: row === 0 ? "#fff" : "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };
    const getCellStyleDocumentTransmittal = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 1 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: DOCUMENT_TRANSMITTAL_COLUMNS[col].width,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: row === 0 ? colors.gray : colors.gray,
        padding: col === 0 ? "1pt 5pt" : "1pt",
        fontSize: 10,
        textAlign: col === 0 ? "right" : "center",
      };

      let specificStyle = {
        backgroundColor: row === 0 ? colors.accentColorDark : "#fff",
        color: row === 0 ? "#fff" : "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    return (
      <Document>
        <Page size="A4" style={styles.page} wrap>
          <Text
            render={() => {
              initialiseLambdaPdfInserts();
              return null;
            }}
            fixed
          />
          <View>
            <Text style={styles.pageTitle}>
              <Text style={styles.bold}>Document Register & Transmittal Record</Text>
            </Text>
            <Text style={{ ...styles.pageSubtitle, marginBottom: 5 }}>
              Project No.: <Text style={styles.bold}>{task.projectId} </Text>
            </Text>
            <Text style={{ ...styles.pageSubtitle, marginBottom: 5 }}>
              Task No.: <Text style={styles.bold}>{task.id}</Text>
            </Text>
            <Text style={{ ...styles.pageSubtitle, marginBottom: 5 }}>
              Client name: <Text style={styles.bold}>{task.client.name} </Text>
            </Text>
            <Text style={{ ...styles.pageSubtitle, marginBottom: 5 }}>
              Project title: <Text style={styles.bold}>{task.title} </Text>
            </Text>
            <Text style={{ ...styles.pageSubtitle, marginBottom: 5 }}>
              Task title: <Text style={styles.bold}>{task.project.title}</Text>
            </Text>

            <Text style={{ ...styles.pageSubtitle, marginBottom: 5 }}>
              Date: <Text style={styles.bold}>{moment().format("DD-MMMM-YYYY").split("-").join(" ")}</Text>
            </Text>
          </View>

          <View>
            <Text
              style={{
                ...styles.bold,
                marginTop: 20,
                marginBottom: 2,
              }}
            >
              Document Register
            </Text>
            <Table
              columns={REGISTER_COLUMNS}
              data={registerRows}
              includeHeader={false}
              style_function={getCellStyleDocumentRegister}
            />
          </View>
          <View>
            <Text
              style={{
                ...styles.bold,
                marginTop: 20,
                marginBottom: 2,
              }}
            >
              Issued By
            </Text>
            <Table
              columns={ISSUED_BY_COLUMNS}
              data={issuedByRows}
              includeHeader={false}
              style_function={getCellStyleDocumentRegister}
            />
          </View>
          <Text style={{ ...styles.bold, marginBottom: 2, marginTop: 7 }}>Document Transmittal</Text>
          <View>
            <Table
              columns={DOCUMENT_TRANSMITTAL_COLUMNS}
              data={transmittalRows}
              includeHeader={false}
              style_function={getCellStyleDocumentTransmittal}
            />
          </View>
        </Page>
      </Document>
    );
  };

  render() {
    const { task, users, taskRevision, layout = "default", renderMode } = this.props;
    const { isLoading } = this.state;

    window.pdfPageNumbers = {};
    window.pdfPageNumbersToDownload = {};

    if (!task || !users || !taskRevision || isLoading) {
      return (
        <div className="report-preloader">
          <LoadingOutlined />
        </div>
      );
    }

    return (
      <div className="report report-AEC">
        <ReportPreview
          document={this.displayDocument()}
          layout={layout}
          renderMode={renderMode}
          renderKey={JSON.stringify(this.state.reportJsonData)}
          onDataUri={this.props.onDataUri}
        />
      </div>
    );
  }
}

export default React.memo(ReportDocumentRegister);
