import { useState, useEffect } from "react";
import cx from "classnames";
import { Typography, Modal } from "antd";
import {
  LoadingOutlined,
  FilePdfOutlined,
  VideoCameraOutlined,
  FileOutlined,
  FileTextOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import DragAndDrop from "DragAndDrop/DragAndDrop";

import "./Upload.scss";

export default function Upload({ onChange, className, size = "normal", fullWidth, placeholder }) {
  const [thumbnail, setThumbnail] = useState();
  const [fileType, setFileType] = useState();
  const [fileName, setFileName] = useState();
  // const [fileSize, setFileSize] = useState();
  const [wasFileAdded, setWasFileAdded] = useState(false);
  const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(false);
  const [randomComponentId, setRandomComponentId] = useState();

  useEffect(() => {
    const newRandomId = `${Date.now()}-${Math.floor(Math.random() * 100000)}`;
    setRandomComponentId(newRandomId);
  }, []);

  function onFilesDropped(e, attachments) {
    if (attachments.length > 1) {
      Modal.error({
        closable: true,
        maskClosable: true,
        title: <>Too many files at once</>,
        content: <>You can only upload 1 file at a time, but you have tried to upload {attachments.length} files.</>,
      });
      return;
    }

    onFileChange({
      target: {
        files: attachments.map((attachment) => attachment.fileObject),
      },
    });
  }

  function onFileChange(e) {
    const file = e.target.files[0];
    setFileType(file?.type);
    setFileName(file?.name);
    // setFileSize(file?.size);

    var reader = new FileReader();

    reader.onloadend = function () {
      setIsLoadingThumbnail(false);
      setThumbnail(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setIsLoadingThumbnail(true);
      setWasFileAdded(true);
    } else {
      setIsLoadingThumbnail(false);
      setThumbnail(null);
      setWasFileAdded(false);
    }

    onChange(e, file);
  }

  function displayThumbnail() {
    if (!thumbnail) {
      return null;
    }

    if (fileType.includes("image")) {
      return <img src={thumbnail} className="thumbnail" alt="" />;
    }

    const fileTypeIcons = {
      pdf: <FilePdfOutlined />,
      mp4: <VideoCameraOutlined />,
      text: <FileTextOutlined />,
    };

    let icon = <FileOutlined />;
    for (let crtType in fileTypeIcons) {
      if (fileType.includes(crtType)) {
        icon = fileTypeIcons[crtType];
      }
    }

    return (
      <div className="thumbnail-icon-container">
        {icon}
        <Typography.Text className="file-name">{fileName}</Typography.Text>
      </div>
    );
  }

  if (randomComponentId === undefined) {
    return null;
  }

  return (
    <div className={cx("upload-container", className, { "full-width": fullWidth })}>
      <DragAndDrop onChange={onFilesDropped}>
        <label htmlFor={`upload-${randomComponentId}`}>
          <div className={cx("upload-box", { "with-placeholder": placeholder }, size)}>
            <input
              type="file"
              id={`upload-${randomComponentId}`}
              className="hidden-input"
              onChange={onFileChange}
              data-cy="upload-input"
            />

            <div className="content">
              {!wasFileAdded ? (
                <div
                  className={cx("initial-message", {
                    "with-placeholder": placeholder,
                  })}
                >
                  <UploadOutlined className="upload-icon" />
                  <Typography.Text className="message">
                    Click here to choose a file <br /> or use drag and drop
                  </Typography.Text>
                </div>
              ) : null}
              {isLoadingThumbnail ? (
                <>
                  <LoadingOutlined />
                  <Typography.Text>Processing...</Typography.Text>
                </>
              ) : null}
              {displayThumbnail()}

              {!thumbnail && !isLoadingThumbnail && placeholder ? (
                <img src={placeholder} className="placeholder" alt="" />
              ) : null}
            </div>
          </div>
        </label>
      </DragAndDrop>
    </div>
  );
}
