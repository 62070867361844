import { useState, useEffect } from "react";
import { message, Table, Tag } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import withSubscriptions from "common/withSubscriptions";
import { callRest } from "common/apiHelpers";

import "./MonitoringPage.scss";

export function MonitoringPage() {
  const [consumerMonitoringStatus, setConsumerMonitoringStatus] = useState();

  useEffect(() => {
    getMonitoringStatus();
    // const frontendRefreshInterval = setInterval(() => setNumberForRefresh(Math.random()), 500);
    const pingInterval = setInterval(getMonitoringStatus, 2000);
    return () => {
      clearInterval(pingInterval);
      // clearInterval(frontendRefreshInterval);
    };
  }, []);

  async function getMonitoringStatus() {
    try {
      const response = await callRest({
        method: "GET",
        route: `/consumerMonitoringStatus`,
        includeCredentials: false,
      });

      setConsumerMonitoringStatus(response);
    } catch (e) {
      message.error("Failed to get consumer monitoring status");
      console.error(e);
    }
  }

  return (
    <div className="monitoring-page">
      <Table
        dataSource={[...(consumerMonitoringStatus || [])].sort((a, b) =>
          (a.lastPing || 0) > (b.lastPing || 0) ? -1 : 1
        )}
        loading={!consumerMonitoringStatus}
        pagination={{ hideOnSinglePage: true, pageSize: 50 }}
        rowClassName={(row) => row.lastAlertStatus}
        columns={[
          {
            title: "Computer name",
            dataIndex: "computerName",
            key: "computer-name",
            align: "left",
          },
          {
            title: "Consumer type",
            dataIndex: "consumerType",
            key: "consumer-type",
            align: "left",
          },
          {
            title: "Last ping ",
            dataIndex: "lastPing",
            key: "last-ping-ago",
            align: "left",
            render: (lastPing) => {
              if (parseInt(lastPing) > Date.now()) {
                return "now";
              }
              return moment.unix(lastPing / 1000).fromNow();
            },
          },
          {
            title: "Last ping timestamp",
            dataIndex: "lastPing",
            key: "last-ping-timestamp",
            align: "left",
            render: (lastPing) => moment.unix(lastPing / 1000).format("DD MMM YYYY HH:mm:ss"),
          },
          {
            title: "Status",
            dataIndex: "lastAlertStatus",
            key: "online-status",
            render: (lastAlertStatus) => {
              switch (lastAlertStatus) {
                case "ONLINE":
                  return <Tag color="green">Online</Tag>;
                case "OFFLINE":
                  return <Tag color="red">Offline</Tag>;
                default:
                  return <Tag color="gray">Unknown</Tag>;
              }
            },
          },
        ]}
      />
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: MonitoringPage,
    subscriptions: ["organisationDetails"],
  })
);
