import { useState } from "react";
import { Modal, Button, Typography } from "antd";

import "./AccountancyContactModal.scss";

export default function AccountancyContactModal({
  onClose,
  createNewAccountancyContact,
  setIsSelectExistingContactVisible,
  accountancyIntegrationFullDetails,
}) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      closable={true}
      onCancel={onClose}
      visible={true}
      onSubmit={createNewAccountancyContact}
      title={`No matching contact found in ${accountancyIntegrationFullDetails.NAME}`}
      footer={
        <div className="accountancy-contact-modal-footer">
          <Button
            type="primary"
            loading={isLoading}
            onClick={async () => {
              setIsLoading(true);

              await createNewAccountancyContact();

              setIsLoading(false);
              onClose();
            }}
          >
            Create New Contact
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onClose();
              setIsSelectExistingContactVisible(true);
            }}
          >
            Select Existing Contact
          </Button>
        </div>
      }
      className="accountancy-contact-modal"
    >
      <div className="accountancy-contact-modal-message">
        <Typography.Text>How do you want to proceed?</Typography.Text>
      </div>
    </Modal>
  );
}
