import React from "react";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { Typography, notification } from "antd";
import { Page, Text, View, Image, Document, StyleSheet, Font } from "@react-pdf/renderer";
import awsExports from "aws-exports";

import { getUserReadableCatLevel } from "common/helpers";
import { getAttachmentFullKeyFromLocalKey } from "common/documentRenderHelpers";
import getS3File from "common/getS3File";
import { getFeesForClient } from "common/feeHelpers";

import { getImagesFromReport } from "ReportPage/Report/reportHelpers";
import { displayPdfTextarea, displayPdfAttachment, initialiseLambdaPdfInserts } from "common/documentRenderHelpers";

import ReportPreview from "ReportPage/ReportPreview";

import BrandonMed from "ReportPage/Report/brandon-med.ttf";
import BrandonBold from "ReportPage/Report/brandon-bold.ttf";
import OstrichBlack from "ReportPage/Report/ostrich-black.ttf";
import MrsSaintDelafield from "ReportPage/Report/MrsSaintDelafield-Regular.ttf";
import AvertaRegular from "ReportPage/Report/averta-regular.ttf";

import { Table } from "ReportPage/PdfTable";
import { getFormattedAmount } from "common/financialHelpers";

Font.registerHyphenationCallback((word) => [word]);

Font.register({
  family: "Averta",
  fonts: [
    {
      src: AvertaRegular,
      fontWeight: "normal",
    },
  ],
});

Font.register({
  family: "Ostrich",
  fonts: [
    {
      src: OstrichBlack,
      fontWeight: "normal",
    },
    {
      src: OstrichBlack,
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Brandon",
  fonts: [
    {
      src: BrandonMed,
      fontWeight: "normal",
    },
    {
      src: BrandonBold,
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "MrsSaintDelafield",
  fonts: [
    {
      src: MrsSaintDelafield,
      fontWeight: "normal",
    },
  ],
});

const colors = {
  accentColor: "#19aae8",
  black: "#000",
  gray: "#444",
  accentColorDark: "#5b8aba",
  white: "#fff",
  red: "#FF0000",
};

const styles = StyleSheet.create({
  bold: { fontWeight: "bold" },
  pageTitle: {
    fontSize: 20,
    marginBottom: 25,
    fontWeight: "bold",
    fontFamily: "Ostrich",
  },
  pageSubtitle: { fontSize: 13, marginBottom: 15, fontFamily: "Ostrich" },
  sectionTitle: {
    fontSize: 14,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  sectionSubtitle: {
    fontSize: 12,
    fontWeight: "bold",
  },
  paragraph: { fontSize: 12, marginBottom: 10 },
  termsAndConditionsParagraph: { fontSize: 8.5, marginBottom: 7 },
  lastParagraph: { marginBottom: 30 },
  draInfo: { fontSize: 10 },
  "image_half-page": {
    width: "50%",
  },
  "image_third-page": {
    width: "33%",
  },
  image_half: {
    width: "50%",
  },
  image_third: {
    width: "33%",
  },
  "image_full-page": {
    width: "100%",
  },
  image_original: {},

  page: {
    paddingTop: 80,
    paddingRight: 60,
    paddingLeft: 30,
    paddingBottom: 45,
    fontFamily: "Brandon",
  },
});

export class QuoteDefaultAEC extends React.Component {
  state = {
    isLoading: false,
    attachmentImages: null,
  };

  async componentDidMount() {
    const { form } = this.props;
    let attachmentImages = await this.retrieveImages(form);
    this.setState({ attachmentImages });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.form !== prevProps.form) {
      const attachmentImages = await this.retrieveImages(this.props.form);
      this.setState({
        attachmentImages,
      });
    }
  }

  retrieveImages = async (reportJsonData) => {
    let imagesInReport = getImagesFromReport(reportJsonData);
    const attachmentImages = this.state.attachmentImages || {};
    let imageKeys = imagesInReport
      .map((image) => image.localKey)
      // we only want to fetch the images we don't already have
      .filter((localKey) => !attachmentImages || !attachmentImages.hasOwnProperty(localKey));

    if (!imageKeys || imageKeys.length === 0) {
      return attachmentImages;
    }

    try {
      const imagePromises = [];
      for (let i = 0; i < imageKeys.length; i++) {
        const localKey = imageKeys[i];
        const fullKey = getAttachmentFullKeyFromLocalKey({
          projectFolder: this.props.projectFolder,
          localKey,
        });
        imagePromises.push(
          getS3File(fullKey).catch(() => {
            throw new Error(fullKey);
          })
        );
      }
      let images = await Promise.all(imagePromises);
      imageKeys.forEach((key, i) => {
        attachmentImages[key] = images[i];
      });
    } catch (e) {
      console.log("error:", e);
      notification.error({
        message: (
          <Typography.Text>
            Failed to retrieve image:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
      throw e;
    }

    return attachmentImages;
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.isStatic && nextState.isLoading === this.state.isLoading && this.props.zoom === nextProps.zoom) {
      return false;
    }
    return true;
  };

  displayTermsAndConditions = () => {
    const { quote } = this.props;

    return (
      <View>
        <Text style={{ ...styles.sectionTitle, marginBottom: 20 }}>
          Andun Engineering Consultants Ltd: Terms of Business
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          1. The parties hereto shall be Andun Engineering Consultants Ltd (AEC) and the person, persons, corporate
          entity or organisation <Text style={styles.bold}>{quote.client.name}</Text> to whom is addressed the offer
          letter (the offer).
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          2. The Offer, any written acceptances from the Client, and these terms of business which were included with
          the offer shall together constitute the contract (the Agreement).
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          3. The schedules attaching to these terms of business contain at A: a description of the services to be
          provided by AEC (the services), at B: a programme of works, C: a fee, D: a schedule of payment and at E: a
          scale of rates to be used when time charge fees apply or in the circumstances described in clause 5 herein.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          4. AEC shall exercise reasonable skill and care in the performance of the services.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          5. If any additional services are carried out or services re executed or additional costs are incurred by AEC,
          AEC shall be entitled to additional payment calculated on the basis of the rates set out in schedule E hereto
          and clauses 19 to 22 inclusive herein shall apply thereto.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          6. The Client shall provide AEC without charge and within reasonable time all pertinent data and information
          and give such assistance and make such decisions as shall reasonably be required for the carrying out by AEC
          of the services and AEC and the Client shall exercise all reasonable expedition and despatch in carrying out
          the provisions of the Agreement.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          7. Notwithstanding anything to the contrary within the agreement the total liability of AEC under or in
          connection with the Agreement for all claims whether in contract or in tort, in negligence or for breach of
          statutory duty or otherwise shall be limited to the lower of (i) ten times the fee paid, (ii) one million
          pound sterling.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          Without prejudice to any other exclusion or limitation of liability, damages, loss, expense or costs the
          liability of AEC if any for any loss or damage (‘the loss and damage') under this Agreement shall not exceed
          such sum as it would be just and equitable for AEC to pay having regard to the extent of AEC's responsibility
          for the loss or damage and on the assumptions that:
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          (i) all other consultants, contractors, sub-contractors, project managers and advisers engaged in connection
          with the client have provided contractual undertakings on terms no less onerous than those set out in any
          Client agreement in respect of the carrying out of their obligations in connection with the client;
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          (ii) there are no exclusions of or limitations of liability nor joint insurance or coinsurance provisions
          between the client and any other party referred to in this clause and any such other party who is responsible
          to any extent for the loss and damage is contractually liable for the loss and damage; and
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          (iii) all the parties referred to in this clause have paid to the client such proportion of the loss and
          damage which it would be just and equitable for them to pay having regard to the extent of their
          responsibility for the loss and damage.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          8. Except where it is expressly stated in schedule A hereto that one or more of the matters referred to in
          this clause 8 comprise the whole or part of the services AEC shall not have any duty to consider such matters
          as influencing any aspect of the services to be performed by AEC under the Agreement nor shall AEC be
          responsible under the Agreement or otherwise for:
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          8a. advising as to the actual or possible presence of pollution or contamination or as to the risks of such
          matters having occurred, being present or occurring in the future (herein called “ pollution and contamination
          matters”);and/or as the case may be
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          8b. advising on matters which wholly, partly, directly or indirectly arise out of or result from asbestos
          (including without limitation the costs of testing for, monitoring, abatement, mitigation, waste removal,
          remediation or disposal of any asbestos or product or waste that contains asbestos) (herein called “asbestos
          matters”); and/or as the case may be.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          8c. designing or advising on or otherwise taking measures to prevent or mitigate the effect of any act of
          terrorism or any action that may be taken in controlling, preventing, suppressing or in any way relating to an
          act of terrorism (herein called “terrorism matters); and the liability of AEC under or in connection with the
          Agreement whether in contract or in tort, in negligence or for breach of statutory duty or otherwise for any
          claim which may arise out of or in connection with pollution and contamination matters, and/or asbestos
          matters and/or terrorism matters as the case may be is excluded
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          9. Where it is expressly stated in schedule A hereto that the services comprise in whole or in part:
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          9a. pollution and contamination matters and/or as the case may be asbestos matters then without prejudice to
          the generality of clause 7 herein the total liability of AEC under or in connection with the Agreement for any
          claims arising out of or in connection with pollution and contamination matters and/or as the case may be
          asbestos matters whether in contract or in tort, in negligence or for breach of statutory duty or otherwise
          shall in each case not exceed in aggregate the lesser of the sum of £25,000 (twenty five thousand pounds
          sterling) or the amount, if any recoverable under any specific professional indemnity insurance policy taken
          out by AEC and in force at the time the claim or claims is or are made.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          9b. terrorism matters the total liability of AEC under or in connection with the Agreement for all such claims
          arising out of or in connection with terrorism matters shall not exceed in aggregate the sum of £10,000 (ten
          thousand pounds sterling).
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          10. If the Client is an individual no exclusion or limitation of liability under the Agreement shall affect
          any liability for death or personal injury to the Client.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          11. Save in respect of death or personal injury the Client shall look only to AEC and not to any employee,
          officer or director of AEC for redress if the Client considers that there has been any breach of the
          Agreement. The Client agrees not to pursue any claim in contract, tort or statute (including negligence)
          against any employee, officer of director of AEC whether named expressly in the Agreement or not as a result
          of him or her carrying out AEC's obligations under or in connection with the Agreement.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          12. AEC shall maintain professional indemnity insurance from the commencement of the services until six years
          from the date of completion of the services in an amount sufficient to cover AEC's liabilities under the
          Agreement for any one occurrence or series of occurrences arising out of the Agreement and provided always
          that such insurance is available at commercially reasonable rates and subject to all exceptions, exclusions
          and limitations to the scope of cover that are commonly included in such insurance at the time the insurance
          is taken out or renewed as the case may be.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          13. No action or proceedings under or in respect of the Agreement, whether in contract or tort, in negligence
          or for breach of statutory duty or otherwise, shall be commenced against AEC after the expiry of six years
          from the date of completion of the services or such earlier date as may be prescribed by law.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          14. The Agreement may be terminated forthwith in the event of insolvency by either party. Notice of
          termination must be given in writing to the party which is insolvent by the other party.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          15. If circumstances arise for which AEC is not responsible and which AEC consider make it irresponsible to
          perform all or any part of the services then AEC shall be entitled to terminate the Agreement by giving three
          calendar days' notice in writing in respect of all or such part of the services.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          16. All reports, drawings, specifications, bills of quantities, calculations and other similar documents
          prepared and provided by AEC under the Agreement are the property and copyright of AEC. Provided that all sums
          due from the Client to AEC however arising and whether related to this agreement or any other have been paid,
          AEC shall have a licence to copy and use such documents excluding calculations for purposes only relating to
          the project, works or brief for which the services are being provided. AEC shall not be liable for the use by
          any person of such documents for any purpose other than that for which the same were prepared by or on behalf
          of AEC.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          17. Neither the Client nor AEC shall assign the benefit or in any way transfer the obligations of the
          Agreement or any part thereof. Termination of the Agreement shall not prejudice or affect the accrued rights
          or claims of either party to the Agreement.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          18. The Client or AEC may publish alone or in conjunction with any other person any articles photographs or
          other illustrations relating to the project provided proper acknowledgement is made to the parties to the
          Agreement.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          19. Unless stated otherwise in Schedule D hereto invoices shall be rendered monthly. This will be pro rata to
          the value of any lump sum agreement.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          20. Payments under the Agreement are due on the date of invoice (“the Due Date”) and the final date for
          payment shall be thirty calendar days after the Due Date. In the event of any disputed item the final date for
          payment of the undisputed part of the invoice shall be thirty calendar days after the due date.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          21. Where time charge fees apply the amount due for each person at each payment interval shall be the sum
          calculated by multiplying the hour or daily rates applicable to the person concerned by the number of hours or
          days spent by such person during the interval in question.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          22. Save in respect of the benefits or rights conferred on any employee, officer or director of AEC pursuant
          to clause 11 herein nothing in the Agreement confers or purports to confer on any third party any benefit or
          any right to enforce any term of the Agreement under the Contracts (Rights of Third Parties) Act 1999.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          23. Any notice in writing under the Agreement shall be deemed to be duly given if it is delivered by hand
          against a signed receipt, by special delivery, by recorded delivery, or by courier. For the avoidance of doubt
          notices sent by ordinary first or second class post or by email shall be deemed not to have been duly given.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          24. The Agreement shall be governed by English law, and the parties agree to submit to the non exclusive
          jurisdiction of the English courts.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          25. A person who is not party to this agreement shall have no right under the contracts (Rights of third
          parties) act 1999 to enforce any terms under this agreement.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          26. Until all sums due from the Client to AEC however arising and whether relating to this agreement or any
          other have been paid AL shall be entitled to a lien over all of the Client's documents in AEC's possession.
        </Text>
        <Text style={styles.termsAndConditionsParagraph}>
          27. Fees and charges specified in this agreement do not include any amount arising in respect of VAT ( or
          other applicable taxes), which if applicable, shall be payable by the Client and added to such fees at the
          rate in force at the time they become due.
        </Text>

        <Text style={styles.termsAndConditionsParagraph}></Text>
      </View>
    );
  };

  displayDynamicIntro = () => {
    const { form } = this.props;

    let hasCVs = form.fields.CVs?.value?.length > 0;
    let hasCaseStudies = form.fields.caseStudies?.value?.length > 0;

    if (!hasCVs && !hasCaseStudies) {
      return null;
    }

    return (
      <View>
        {hasCaseStudies && (
          <Text style={{ ...styles.paragraph }}>
            Please see an example of similar work undertaken previously for the case study.
          </Text>
        )}
        {hasCVs && (
          <Text style={{ ...styles.paragraph }}>
            CV included for the senior engineer/director who will look after your project.
          </Text>
        )}
      </View>
    );
  };

  displayCover = () => {
    const { quote, users, clients } = this.props;
    const client = clients.find((x) => x.id === quote.clientId);

    let addressDetails = (client.addresses || []).find((x) => x.id === quote.clientAddress);
    let contactDetails = (client.contacts || []).find((x) => x.id === quote.clientContact);

    let quoteAssignee = users.find((user) => user.id === quote.assignedTo);

    let signature = null;
    const SIGNATURE_HEIGHT = 30;

    if (quoteAssignee) {
      if (quoteAssignee.signature) {
        signature = (
          <Image
            src={quoteAssignee.signature}
            style={{
              height: SIGNATURE_HEIGHT,
              width: SIGNATURE_HEIGHT * (quoteAssignee.signatureWidth / quoteAssignee.signatureHeight),
            }}
          />
        );
      } else {
        signature = (
          <Text style={{ fontFamily: "MrsSaintDelafield", fontSize: 20 }}>
            {quoteAssignee.firstName} {quoteAssignee.lastName}
          </Text>
        );
      }
    }

    return (
      <>
        <View style={{ textAlign: "right" }}>
          <Text style={{ ...styles.paragraph }}>
            Job Number:{" "}
            <Text>
              {quote.reference || quote.id}
              {quote.currentRevisionName || ""}
            </Text>
          </Text>
        </View>
        <View style={{ marginTop: -30, display: "flex" }}>
          <Text
            style={{
              ...styles.paragraph,
              marginTop: 0,
            }}
          >
            FAO
          </Text>
          <Text
            style={{
              ...styles.paragraph,
              marginTop: -10,
            }}
          >
            {contactDetails?.firstName} {contactDetails?.lastName}
          </Text>
          <Text
            style={{
              ...styles.paragraph,
              marginTop: -10,
            }}
          >
            {client.name}
          </Text>

          <Text
            style={{
              ...styles.paragraph,
              marginTop: -10,
            }}
          >
            {addressDetails?.houseName}
          </Text>

          <Text
            style={{
              ...styles.paragraph,
              marginTop: -10,
            }}
          >
            {addressDetails?.streetNumber ? `${addressDetails?.streetNumber} ` : ""}
            {addressDetails?.streetName}
          </Text>
          <Text
            style={{
              ...styles.paragraph,
              marginTop: -10,
            }}
          >
            {addressDetails?.city}
          </Text>
          <Text
            style={{
              ...styles.paragraph,
              marginTop: -10,
            }}
          ></Text>
          <Text
            style={{
              ...styles.paragraph,
              marginTop: -10,
            }}
          ></Text>
          <Text
            style={{
              ...styles.paragraph,
              marginTop: -10,
            }}
          >
            {addressDetails?.postcode}
          </Text>
          <Text
            style={{
              ...styles.paragraph,
              marginTop: 20,
            }}
          >
            Issued on: {moment(quote.reviewApprovedAt || undefined).format("DD/MM/YYYY")}
          </Text>
          <Text
            style={{
              ...styles.paragraph,
              marginTop: -10,
            }}
          >
            Valid until:{" "}
            {moment(quote.reviewApprovedAt || undefined)
              .add(30, "days")
              .format("DD/MM/YYYY")}
          </Text>
          <Text
            style={{
              ...styles.paragraph,
              marginTop: 20,
              marginBottom: 30,
            }}
          >
            Dear {contactDetails?.firstName},
          </Text>
        </View>
        {this.displayTextArea("intro", { skipLastParagraphMargin: true })}
        {this.displayDynamicIntro()}
        {this.displayTextArea("introPart2")}
        <View>
          <Text
            style={{
              ...styles.paragraph,
              marginTop: 0,
            }}
          >
            Your Sincerely
          </Text>

          {signature}

          <Text
            style={{
              ...styles.paragraph,
              marginTop: 10,
            }}
          >
            {quoteAssignee?.firstName} {quoteAssignee?.lastName}
          </Text>
          <Text
            style={{
              ...styles.paragraph,
              marginTop: -10,
            }}
          >
            {quoteAssignee?.qualifications}
          </Text>
          <Text
            style={{
              ...styles.paragraph,
              marginTop: -10,
            }}
          >
            For and on behalf of Andun Engineering Consultants Ltd
          </Text>
        </View>
      </>
    );
  };

  displayScheduleAB = () => {
    const { quote, projects } = this.props;

    const projectData = projects.find((project) => project.id === quote.projectId);

    return (
      <Page wrap style={styles.page}>
        {this.displayWatermark()}
        <View>
          <Text style={{ ...styles.sectionSubtitle }}>
            <Text>Location:</Text> <Text style={{ fontWeight: "normal" }}>{projectData.title}</Text>
          </Text>
          <Text style={{ ...styles.sectionSubtitle }}>
            <Text>Job Number:</Text>{" "}
            <Text style={{ fontWeight: "normal" }}>
              {quote.reference || `${quote.id}${quote.currentRevisionName || ""}`}
            </Text>
          </Text>
          <Text style={{ ...styles.sectionSubtitle }}>
            <Text>Job:</Text> <Text style={{ fontWeight: "normal" }}>{quote.title}</Text>
          </Text>
        </View>

        <Text style={{ ...styles.sectionTitle, marginTop: 20 }}>
          Schedule A: A description of the service to be provided is:
        </Text>

        <Text style={{ ...styles.sectionSubtitle }}>Scope of Work:</Text>
        {this.displayTextArea("scopeOfWork")}
        <Text style={{ ...styles.sectionSubtitle, marginTop: 20 }}>Deliverables:</Text>
        {this.displayTextArea("deliverables")}

        <Text style={{ ...styles.sectionTitle, marginTop: 20 }}>Schedule B: The programme of work is:</Text>
        {this.displayTextArea("programmeOfWork")}

        <View style={{ marginTop: 20, textAlign: "center" }}>
          <Text
            style={{
              ...styles.sectionSubtitle,
              fontSize: 15,
            }}
          >
            CONTINUED OVERLEAF
          </Text>
        </View>
      </Page>
    );
  };

  displayCaseStudies = () => {
    const { form } = this.props;

    const caseStudies = form.fields.caseStudies?.value;
    if (!caseStudies) {
      return null;
    }
    return caseStudies.map((key, i) => {
      return displayPdfAttachment({
        elementKey: i,
        key,
        label: `${key.split("public/AEC/Document Library/Case Studies/").join("")}`,
      });
    });
  };

  displayCVs = () => {
    const { form } = this.props;

    const caseStudies = form.fields.CVs?.value;
    if (!caseStudies) {
      return null;
    }
    return caseStudies.map((key, i) => {
      return displayPdfAttachment({
        elementKey: i,
        key,
        label: `${key.split("public/AEC/Document Library/CVs/").join("")}`,
      });
    });
  };

  displayScheduleCD = () => {
    const { quote, organisationDetails } = this.props;

    let lineItemColumns = [
      {
        title: "Item",
        dataIndex: "item",
        width: 30,
      },
      {
        title: "Title",
        dataIndex: "title",
        width: 110,
      },
      {
        title: "Description",
        dataIndex: "description",
        width: 200,
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        width: 70,
      },
      {
        title: "Design Fee",
        dataIndex: "unitPrice",
        width: 100,
      },
      {
        title: "CAT Check Level",
        dataIndex: "authorityLevel",
        width: 90,
      },
      {
        title: "Design Check Fee",
        dataIndex: "checkPrice",
        width: 100,
      },

      {
        title: "Total",
        dataIndex: "formattedAmountToDisplay",
        width: 80,
      },
    ];

    const cellStyleFunction = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 0 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const COLUMN_ALIGNMENT = {
        0: "center",
        1: "left",
        2: "left",
        3: "center",
        4: "right",
        5: "center",
        6: "right",
        7: "right",
      };

      const baseStyle = {
        width: lineItemColumns[col].width || `${95 / lineItemColumns.length}%`,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding: "3pt 5pt",
        fontSize: 10,
        textAlign: COLUMN_ALIGNMENT.hasOwnProperty(col) ? COLUMN_ALIGNMENT[col] : "left",
      };

      let specificStyle = {
        backgroundColor: "#fff",
        color: "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    let lineItemsForTable = quote.lineItems.items
      .filter((lineItem) => !lineItem.isRejected)
      .map((lineItem, index) => {
        let result = {
          ...lineItem,
          item: index + 1,
          unitPrice: window.formatCurrency("GBP", lineItem.unitPrice),
          authorityLevel:
            lineItem.authorityLevel === 0
              ? "Not Applicable"
              : getUserReadableCatLevel(organisationDetails, lineItem.authorityLevel || 0),
          checkPrice: window.formatCurrency("GBP", lineItem.checkPrice),

          formattedAmountToDisplay: getFormattedAmount({
            quote,
            lineItem,
            currency: "GBP",
            withCurrencySymbol: true,
          }),
        };
        if (lineItem.isHourly) {
          result.quantity = "-";
          result.unitPrice = "-";
          result.checkPrice = "-";
          result.formattedAmountToDisplay = "Hourly rate";
        }
        return result;
      });

    let atLeastOneLineItemIsHourly = quote.lineItems.items.some((x) => x.isHourly);
    let hourlyRateMention = atLeastOneLineItemIsHourly ? " + Hourly Rate" : "";

    return (
      <Page wrap style={styles.page} size={[841.89, 595.28]}>
        {this.displayWatermark()}

        <Text style={{ ...styles.sectionTitle, marginTop: -25 }}>Schedule C: The fee is:</Text>
        {this.displayTextArea("fee")}

        <View style={{ marginTop: 10 }}>
          <Table
            style={{ marginTop: 10 }}
            includeHeader={true}
            columns={lineItemColumns}
            data={lineItemsForTable}
            style_function={cellStyleFunction}
          />
        </View>
        <View style={{ marginTop: 20, textAlign: "right" }}>
          <Text style={{ ...styles.sectionSubtitle, fontSize: 15, marginRight: 3 }}>
            Total: {window.formatCurrency("GBP", quote.subtotal)}
            {hourlyRateMention}
          </Text>
          <Text style={{ ...styles.sectionSubtitle, marginRight: 3 }}>
            Total including VAT @ {quote.taxRate}%: {window.formatCurrency("GBP", quote.total)}
            {hourlyRateMention}
          </Text>
        </View>
        <View style={{ marginTop: 20, textAlign: "center" }}>
          <Text
            style={{
              ...styles.sectionSubtitle,
              fontSize: 15,
              color: colors.red,
            }}
          >
            THIS QUOTE IS VALID UNTIL {moment().add(30, "days").format("DD MMMM, YYYY").toUpperCase()}
          </Text>
        </View>

        <Text style={{ ...styles.sectionTitle, marginTop: 20 }}>Schedule D: The schedule of payments:</Text>
        {this.displayTextArea("scheduleOfPayments")}

        <View style={{ marginTop: 20, textAlign: "center" }}>
          <Text
            style={{
              ...styles.sectionSubtitle,
              fontSize: 15,
            }}
          >
            CONTINUED OVERLEAF
          </Text>
        </View>
      </Page>
    );
  };

  displayAssumptionsAndNotesPage = () => {
    return (
      <Page wrap style={styles.page}>
        {this.displayWatermark()}

        <Text style={{ ...styles.sectionTitle, marginTop: 20 }}>Assumptions:</Text>
        {this.displayTextArea("assumptions")}

        <Text style={{ ...styles.sectionTitle, marginTop: 20 }}>Notes:</Text>
        {this.displayTextArea("notes")}

        <View style={{ marginTop: 20, textAlign: "center" }}>
          <Text
            style={{
              ...styles.sectionSubtitle,
              fontSize: 15,
            }}
          >
            SEE OVERLEAF FOR TERMS AND CONDITIONS
          </Text>
        </View>
      </Page>
    );
  };

  displayTextArea = (fieldName, options) => {
    const { form, projectFolder } = this.props;
    const { attachmentImages } = this.state;

    return displayPdfTextarea({
      targetReportJsonData: form.fields,
      styles,
      displayTitle: false,
      fieldName,
      projectFolder,
      attachmentImages,
      props: this.props,
      options,
    });
  };

  displayFees = () => {
    const { quote, organisationDetails, clients } = this.props;

    const clientDetails = clients.find((client) => client.id === quote.clientId);

    const feesColumns = [
      {
        title: "Resource",
        dataIndex: "label",
        width: 120,
      },
      {
        title: "Hourly Rate",
        dataIndex: "valueWithCurrency",
        width: 80,
      },
    ];

    const feesData = getFeesForClient({
      organisationDetails,
      quote,
      clientDetails,
      currency: "GBP",
    });

    const cellStyleFunction = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 0 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: feesColumns[col].width || `${95 / feesColumns.length}%`,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding: "3pt 5pt",
        fontSize: 10,
      };

      let specificStyle = {
        backgroundColor: "#fff",
        color: "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    return (
      <>
        <Text style={{ ...styles.sectionTitle, marginBottom: 5, marginTop: 10 }}>Schedule E:</Text>
        {this.displayTextArea("notesOnFees")}

        <Text style={{ ...styles.sectionSubtitle, marginTop: 10, marginBottom: 0 }}>AEC Rates:</Text>
        <Table
          style={{ marginTop: 5 }}
          includeHeader={true}
          columns={feesColumns}
          data={feesData.map((fee, index) => {
            return {
              ...fee,
              label: `${index + 1} ${fee.label}`,
            };
          })}
          style_function={cellStyleFunction}
        />
      </>
    );
  };

  displayWatermark = () => {
    const { quote } = this.props;
    if (quote.reviewStatus === "SUCCESS") {
      return null;
    }

    return (
      <View
        fixed
        style={{
          position: "absolute",
          top: "200",
          left: "0",
          transform: "rotate(45deg)",
          opacity: 0.05,
        }}
      >
        <Text style={{ fontSize: 100 }}>UNAPPROVED</Text>
      </View>
    );
  };

  displayDocument = () => {
    const { quote } = this.props;

    window.lambdaPdfAssets = [
      {
        bucket: "draughthub-public-assets",
        name: "organisationLogo",
        key: "AEC/aec-logo.png",
      },
      {
        bucket: awsExports.aws_user_files_s3_bucket,
        name: "clientLogo",
        key: "public/" + quote.client.key,
        width: 110,
        height: 35,
      },
    ];

    // this is used to mark pages where we do not want page borders, logo or other markers to be applied
    window.lambdaPdfPageNumbersToSkipBorders = [];

    window.pdfPageNumbersToDownload = {};

    return (
      <Document>
        <Page wrap style={styles.page}>
          {this.displayWatermark()}
          <Text
            render={() => {
              initialiseLambdaPdfInserts();
              return null;
            }}
            fixed
          />
          {this.displayCover()}
        </Page>

        {this.displayCaseStudies()}
        {this.displayScheduleAB()}
        {this.displayScheduleCD()}
        {this.displayAssumptionsAndNotesPage()}
        {this.displayCVs()}
        <Page wrap style={styles.page}>
          {this.displayWatermark()}
          {this.displayTermsAndConditions()}
          {this.displayFees()}
        </Page>
      </Document>
    );
  };

  render() {
    const { quote, layout = "default", renderMode } = this.props;
    const { isLoading } = this.state;

    if (!quote || isLoading || !this.state.attachmentImages) {
      return (
        <div className="report-preloader">
          <LoadingOutlined />
        </div>
      );
    }

    return (
      <div className="report report-AWD">
        <ReportPreview
          document={this.displayDocument()}
          layout={layout}
          renderMode={renderMode}
          renderKey={JSON.stringify(quote)}
          onDataUri={this.props.onDataUri}
        />
      </div>
    );
  }
}

export default React.memo(QuoteDefaultAEC);
