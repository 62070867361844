import { Modal, Form, Select, Button } from "antd";
import Avatar from "Avatar/Avatar";

export default function HierarchyUserModal({ users, managerUser, onClose, directSubordinates, onSubmit }) {
  let managers = [];

  // recursively find the managers and the managers of the managers
  function findManagers(managerIds) {
    managerIds?.forEach((managerId) => {
      let manager = users.find((user) => user.id === managerId);
      if (manager) {
        managers.push(manager);
        if (manager.managerIds) {
          findManagers(manager.managerIds);
        }
      }
    });
  }

  findManagers(managerUser.managerIds);

  let validUsers = users.filter((user) => {
    if (directSubordinates.some((subordinate) => subordinate.id === user.id)) {
      return false;
    }
    if (managers.some((manager) => manager.id === user.id)) {
      return false;
    }

    if (user.id === managerUser.id) {
      return false;
    }

    return true;
  });

  return (
    <div className="user-container">
      <Modal
        title={`Add subordinate for ${managerUser?.firstName} ${managerUser?.lastName}`}
        open={true}
        onCancel={onClose}
        footer={null}
      >
        <Form onFinish={onSubmit}>
          <Form.Item
            label="User"
            name="userId"
            rules={[
              {
                required: true,
                message: "You must choose a user",
              },
            ]}
          >
            <Select
              allowClear
              placeholder="No user selected"
              showSearch
              filterOption={(searchValue, { label }) => {
                return label?.toLowerCase().includes(searchValue?.toLowerCase());
              }}
            >
              {validUsers.map((user) => {
                return (
                  <Select.Option key={user.id} value={user.id} label={`${user.firstName} ${user.lastName} ${user.id}`}>
                    <Avatar user={user} showLabel />
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
