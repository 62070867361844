import { Typography, Progress, Tag, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import moment from "moment";
import Avatar from "Avatar/Avatar";
import TaskIdTag from "TaskIdTag/TaskIdTag";
import { FILE_TYPES_READABLE, ASYNC_JOB_STATUSES, ASYNC_JOB_TYPES } from "common/constants";

export function getDataSourceForTable({ asyncJobs, tasks, projects, users }) {
  return [...asyncJobs]
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    .map((job) => {
      let task = undefined;
      let project = undefined;
      let author = undefined;

      for (let crtTask of tasks) {
        if (crtTask.id === job.taskId) {
          task = crtTask;
          break;
        }
      }

      if (task) {
        for (let crtProject of projects) {
          if (crtProject.id === task.projectId) {
            project = crtProject;
            break;
          }
        }
      }

      for (let crtUser of users) {
        if (crtUser.id === job.userId) {
          author = crtUser;
          break;
        }
      }

      let barColor = "#9eb7c2";
      if (job.status === "FAILED") {
        barColor = "#ff4d4f";
      } else {
        barColor = job.progressPercent === 100 ? "#4cc23a" : "#19aae8";
      }

      let status = ASYNC_JOB_STATUSES.find((x) => x.value === job.status);

      let daysAgoCreated = moment().diff(job.createdAt, "day");
      let daysAgoStarted = moment().diff(job.startedAt, "day");
      let daysAgoFinished = moment().diff(job.finishedAt, "day");

      let formattedCreatedAt;
      let startedAtFormatted;
      let finishedAtFormatted;

      if (daysAgoCreated < 2) {
        formattedCreatedAt = `${moment(job.createdAt).calendar(null, {
          sameDay: "[Today]",
          nextDay: "[Tomorrow]",
          nextWeek: "dddd",
          lastDay: "[Yesterday]",
          lastWeek: "dddd",
          sameElse: "DD/MM/YYYY",
        })}, ${moment(job.createdAt).format("HH:mm:ss")}`;
      } else {
        formattedCreatedAt = moment(job.createdAt).format("DD MMM, HH:mm:ss");
      }

      if (job.startedAt && job.startedAt !== "0001-01-01T00:00:00Z") {
        if (daysAgoStarted < 2) {
          startedAtFormatted = `${moment(job.startedAt).calendar(null, {
            sameDay: "[Today]",
            nextDay: "[Tomorrow]",
            nextWeek: "dddd",
            lastDay: "[Yesterday]",
            lastWeek: "dddd",
            sameElse: "DD/MM/YYYY",
          })}, ${moment(job.startedAt).format("HH:mm:ss")}`;
        } else {
          startedAtFormatted = moment(job.startedAt).format("DD MMM, HH:mm:ss");
        }
      }

      if (job.finishedAt && job.finishedAt !== "0001-01-01T00:00:00Z") {
        if (daysAgoFinished < 2) {
          finishedAtFormatted = `${moment(job.finishedAt).calendar(null, {
            sameDay: "[Today]",
            nextDay: "[Tomorrow]",
            nextWeek: "dddd",
            lastDay: "[Yesterday]",
            lastWeek: "dddd",
            sameElse: "DD/MM/YYYY",
          })}, ${moment(job.finishedAt).format("HH:mm:ss")}`;
        } else {
          finishedAtFormatted = moment(job.finishedAt).format("DD MMM, HH:mm:ss");
        }
      }

      let duration = null;
      if (startedAtFormatted) {
        if (job.status === "IN_PROGRESS") {
          duration = moment.duration(moment() - moment(job.startedAt));
        } else {
          duration = moment.duration(moment(job.finishedAt || job.updatedAt) - moment(job.startedAt));
        }
      }
      let durationFormatted = null;
      if (duration) {
        durationFormatted = moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
      }

      let messages = [];
      if (job.error) {
        messages.push(`Error: ${job.error}`);
      }
      if (job.retriedTimes > 0) {
        let retryTimesLabel;
        if (job.retriedTimes === 1) {
          retryTimesLabel = "1st";
        } else if (job.retriedTimes === 2) {
          retryTimesLabel = "2nd";
        } else if (job.retriedTimes === 3) {
          retryTimesLabel = "3rd";
        } else {
          retryTimesLabel = `${job.retriedTimes}th`;
        }
        messages.push(`${retryTimesLabel} retry. Reason: ${job.retryReason}`);
      }

      return {
        ...job,
        originalJob: job,
        taskIdTag: task && <TaskIdTag task={task} />,
        taskId: task?.id,
        key: job.id,
        taskTitle: task?.title,
        projectTitle: project?.title,
        userAvatar: (
          <Avatar user={!author || author.isHidden ? "draughthub" : author} showLabel={author && !author.isHidden} />
        ),
        fileTypeReadable: FILE_TYPES_READABLE[job.fileType],
        originalCreatedAt: job.createdAt,
        createdAtFormatted: formattedCreatedAt,
        startedAtFormatted,
        finishedAtFormatted,
        durationFormatted,
        statusTag: (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Tag color={status.color}>{status.label}</Tag>
            <div style={{ display: "flex", gap: "0.2rem" }}>
              {messages && messages.length > 0 && (
                <Tooltip
                  title={
                    <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                      {messages.map((message) => (
                        <Typography.Text>{message}</Typography.Text>
                      ))}
                    </div>
                  }
                >
                  <QuestionCircleFilled />
                </Tooltip>
              )}
            </div>
          </div>
        ),
        type: ASYNC_JOB_TYPES.find((x) => x.value === job.type).label,
        progressPercent: (
          <Progress
            percent={Math.round(job.progressPercent)}
            trailColor={"#e7f1f4"}
            status="normal"
            strokeColor={barColor}
          />
        ),
      };
    });
}
