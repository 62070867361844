import getS3File from "common/getS3File";

/**
 This function searches for all image URLs. They are S3 signed URLs which expire shortly after being created, 
 which means each time, before we try to load the images, we have to generate updated URLs. 
 **/
export async function replaceImageUrlsWithFreshImages(parent) {
  if (parent.text && parent.type === "text") {
    let parsedContent;

    if (parent.text && parent.text?.startsWith && parent.text?.startsWith("[")) {
      try {
        parsedContent = JSON.parse(parent.text);
        let imageNodes = parsedContent.filter((node) => node.type === "attachment" && node.attachment.type === "IMAGE");
        for (let imageNode of imageNodes) {
          imageNode.attachment.publicUrl = await getS3File(imageNode.attachment.key);
        }
        parent.text = JSON.stringify(parsedContent);
      } catch (e) {
        // nothing, it's not valid JSON
      }
    }
  } else if (parent.custom_imageKey) {
    parent.src = await getS3File(parent.custom_imageKey);
  } else if (parent.custom_fileKeys) {
    let updatedKeysPromises = parent.custom_fileKeys.map((key) => {
      if (!key) {
        return undefined;
      }
      try {
        if (key.toLowerCase().endsWith(".pdf")) {
          return key;
        } else {
          return getS3File(key);
        }
      } catch (e) {
        console.log("error when replacing key: ", e);
        console.log("key = ", key);
        return undefined;
      }
    });
    parent.files = await Promise.all(updatedKeysPromises);
  }

  if (parent.objects) {
    for (let i = 0; i < parent.objects.length; i++) {
      await replaceImageUrlsWithFreshImages(parent.objects[i]);
    }
  }
}

export async function replaceImageUrlsWithFreshImagesInForm(parent) {
  for (let fieldName in parent?.fields) {
    let field = parent.fields[fieldName];
    if (field.type === "textarea" && field.value?.startsWith("[")) {
      try {
        const parsedContent = JSON.parse(field.value);
        let imageNodes = parsedContent.filter((node) => node.type === "attachment" && node.attachment.type === "IMAGE");
        for (let imageNode of imageNodes) {
          imageNode.attachment.publicUrl = await getS3File(imageNode.attachment.key);
          // console.log("replaced image:", imageNode.attachment.key, "with:", imageNode.attachment.publicUrl);
        }
        field.value = JSON.stringify(parsedContent);
      } catch (e) {
        // nothing, it's not valid JSON
      }
    }
  }
}
