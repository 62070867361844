import { useState } from "react";
import { Storage } from "aws-amplify";
import { Button, Form, Modal, Typography, Checkbox, notification } from "antd";
import withSubscriptions from "common/withSubscriptions";
import { readAndCompressImage } from "browser-image-resizer";
import { DeleteOutlined, UploadOutlined, PlusCircleOutlined } from "@ant-design/icons";
import DragAndDrop from "DragAndDrop/DragAndDrop";
import { createClientInApi } from "common/helpers";

import "./BatchCreateClientModal.scss";

export function BatchCreateClientModal({ onClose, organisationDetails }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);

  async function onFilesDropped(_, logos) {
    logos.forEach((logo) => {
      logo.clientId = `${Date.now()}${Math.floor(Math.random() * 100000)}`;
      logo.fullPath = logo.fullPath.substring(1);
      logo.name = logo.name.split(".").slice(0, -1).join(".");
      logo.thumbnail = URL.createObjectURL(logo.fileObject);
    });
    let progressReset = {};
    for (let propertyName in this.state) {
      if (propertyName.indexOf("progress-") === 0) {
        progressReset[propertyName] = undefined;
      }
    }
    setClients([
      ...clients,
      ...logos.filter((logo) => {
        let clientAlreadyAdded = clients.find((x) => x.fullPath === logo.fullPath);
        return !clientAlreadyAdded;
      }),
    ]);
  }

  async function onSubmit() {
    try {
      setIsLoading(true);
      for (let i = 0; i < clients.length; i++) {
        const client = clients[i];
        await createClient(client);
      }
      setIsLoading(false);
      form.resetFields();
      onClose();
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: <Typography.Text>Could not create client: {e}</Typography.Text>,
        duration: 0,
      });
    }
  }

  async function createClient(client) {
    if (!client.fileObject) {
      await triggerCreateClient(client);
    } else {
      // if we have a logo, then we need to upload it to S3 before
      // proceeding to create the client record in the database
      // const extension = getExtensionFromKey(client.fileObject.name);
      // if (!["png", "jpg", "jpeg"].includes(extension.toLowerCase())) {
      //   alert("Only .png, .jpg and .jpeg formats are allowed");
      //   setIsLoading(false);
      //   return;
      // }
      const resizedLogo = await readAndCompressImage(client.fileObject, {
        quality: 0.8,
        maxWidth: 500,
        maxHeight: 500,
        mimeType: client.fileObject.type,
      });
      const fileName = `${organisationDetails.id}/clients/${Date.now()}${Math.floor(
        Math.random() * 10000
      )}_${client.fileObject.name.split(" ").join("-")}`;
      const { key } = await Storage.put(fileName, resizedLogo, {
        contentType: "image/png",
      });

      await triggerCreateClient({ ...client, key });
    }
  }

  async function triggerCreateClient(client) {
    await createClientInApi({
      ...client,
      organisation: organisationDetails.id,
    });
  }

  return (
    <Modal
      maskClosable={false}
      title="Batch create clients"
      visible={true}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      footer={null}
      className="batch-create-client-modal"
    >
      <DragAndDrop onChange={onFilesDropped}>
        <div className="upload-instructions-container">
          <UploadOutlined className="upload-icon" />
          <Typography.Text className="upload-instructions">Upload client logos</Typography.Text>
        </div>
      </DragAndDrop>

      <Typography.Text className="clients-title">Clients</Typography.Text>
      <ul className="clients-container">
        <li className="client-items-header">
          <Typography.Text className="header-name">Name</Typography.Text>
          <Typography.Text className="header-priority">Priority</Typography.Text>
          <Typography.Text className="header-logo">Logo</Typography.Text>
          <Typography.Text className="header-actions">Actions</Typography.Text>
        </li>
        {clients &&
          clients.map((client, i) => {
            return (
              <li className="client-item" key={i}>
                <div className="name-container">
                  <input
                    value={client.name}
                    onChange={(e) => {
                      setClients(
                        clients.map((crtClient) => {
                          if (crtClient.clientId !== client.clientId) {
                            return crtClient;
                          }
                          return {
                            ...crtClient,
                            name: e.target.value,
                          };
                        })
                      );
                    }}
                  />
                </div>
                <div className="priority-container">
                  <Checkbox
                    onChange={(e) => {
                      setClients(
                        clients.map((crtClient) => {
                          if (crtClient.clientId !== client.clientId) {
                            return crtClient;
                          }
                          return {
                            ...crtClient,
                            isPriority: e.target.checked,
                          };
                        })
                      );
                    }}
                    checked={client.isPriority}
                  />
                </div>
                <div className="logo-container">{client.thumbnail && <img src={client.thumbnail} alt="" />}</div>
                <div className="actions-container">
                  <Button
                    onClick={() => {
                      setClients(
                        clients.filter((crtClient) => {
                          return crtClient.clientId !== client.clientId;
                        })
                      );
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </div>
              </li>
            );
          })}
      </ul>
      <Button
        onClick={() =>
          setClients([
            ...clients,
            {
              name: "",
              isPriority: "",
              clientId: `${Date.now()}${Math.floor(Math.random() * 100000)}`,
            },
          ])
        }
      >
        <PlusCircleOutlined /> Add row
      </Button>
      <div className="submit-container">
        <Button type="primary" onClick={onSubmit} loading={isLoading}>
          {isLoading ? "Creating" : "Submit"}
        </Button>
      </div>
    </Modal>
  );
}

export default withSubscriptions({
  Component: BatchCreateClientModal,
  subscriptions: ["organisationDetails"],
});
