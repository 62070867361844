import React from "react";
import { Typography } from "antd";
import TaskItem from "DashboardPage/TaskItem/TaskItem";
import { taskIdToColor } from "TimelinePage/timelineHelpers";
import cx from "classnames";
import LazyLoadList from "LazyLoadList/LazyLoadList";

import "./AccessedTaskList.scss";
import { getSimpleLabel } from "common/labels";

export class AccessedTaskList extends React.Component {
  state = {
    selectedTab: "ACCESSED",
  };

  async componentDidUpdate(_, prevState) {
    if (prevState.selectedTab !== this.state.selectedTab) {
      this.resetTasksToDisplay();
    }
  }

  resetTasksToDisplay = () => {
    document.querySelector(".accessed-task-list").scrollTop = 0;
  };

  render() {
    const { organisationDetails, onOpenTask, tasks, accessedTasks, viewerIsOwner } = this.props;
    const { selectedTab } = this.state;

    let tasksToDisplay = tasks;

    if (selectedTab === "ACCESSED") {
      tasksToDisplay = accessedTasks;
    }

    return (
      <div className={cx("accessed-task-list", { "read-only": !viewerIsOwner })} data-cy="accessed-task-list">
        <div className="tab-container">
          <Typography.Text
            className={cx("tab", { selected: selectedTab === "ACCESSED" })}
            onClick={() => this.setState({ selectedTab: "ACCESSED" })}
            data-cy="tab-accessed-button"
          >
            Accessed ({accessedTasks.length} items)
          </Typography.Text>
          <div className="separator" />
          <Typography.Text
            className={cx("tab", { selected: selectedTab === "ALL" })}
            onClick={() => this.setState({ selectedTab: "ALL" })}
            data-cy="tab-all-button"
          >
            All {getSimpleLabel("tasks")} ({tasks.length} items)
          </Typography.Text>
        </div>

        <LazyLoadList
          key={selectedTab}
          list={tasksToDisplay}
          item={(task, index) => {
            return (
              <div
                draggable
                key={task.id}
                onDragStart={(e) => {
                  var dragImageElement = e.currentTarget.querySelector(".project-and-task-title");
                  if (!dragImageElement) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                  e.dataTransfer.setDragImage(dragImageElement, 0, 0);
                  e.dataTransfer.setData("task-id", task.id);
                  e.dataTransfer.setData("draggable-type", "task");
                }}
                data-task-id={task.id}
                data-cy="unplanned-task-item"
                data-task-title={task.title}
              >
                <TaskItem
                  task={task}
                  index={index}
                  organisationDetails={organisationDetails}
                  draggable={false}
                  idColor={taskIdToColor(task.id)}
                  includeDueDate={true}
                  onClick={onOpenTask}
                />
              </div>
            );
          }}
        />
      </div>
    );
  }
}

export default React.memo(AccessedTaskList);
