import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import getS3File from "common/getS3File";

import Zoomable from "Zoomable/Zoomable";
import PdfRenderer from "ReportPage/PdfRenderer";

import "./DocumentViewerPage.scss";

export function DocumentViewerPage({ match, windowWidth, windowHeight }) {
  const containerRef = useRef();
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const [_, setPageCount] = useState(false); // eslint-disable-line
  const [currentPageNumber, setCurrentPageNumber] = useState(false);
  const [randomNumberForZoomableRefresh, setRandomNumberForZoomableRefresh] = useState(Math.random());
  const [document, setDocument] = useState();

  useEffect(() => {
    fetchFile(match.params.fileKey);
  }, [match.params.fileKey]);

  function onDocumentLoad({ pageCount }) {
    setPageCount(pageCount);
    setTimeout(() => {
      setIsDocumentLoaded(true);
      setTimeout(() => {
        setCurrentPageNumber(parseInt(match.params.page) || 1);
        setRandomNumberForZoomableRefresh(Math.random());
      }, 2000);
    }, 1500);
  }

  async function fetchFile(fileKey) {
    const decodedFileKey = decodeURIComponent(fileKey);
    const publicPdfUrl = await getS3File(decodedFileKey.replace("public/", ""));
    const pdfDataBlob = (await axios.get(publicPdfUrl, { responseType: "blob" })).data;

    const pdfData = await new Response(pdfDataBlob).arrayBuffer();
    setDocument(pdfData);
  }

  function displayContent() {
    return (
      <div className="zoomable-content" ref={containerRef}>
        {document && (
          <PdfRenderer
            fileData={document}
            currentPageNumber={currentPageNumber}
            includePagination={false}
            renderMode="canvas"
            onLoad={onDocumentLoad}
          />
        )}
      </div>
    );
  }

  return (
    <div className="zoomable-container" ref={containerRef}>
      <Zoomable
        key={document}
        isLoaded={document && isDocumentLoaded}
        refreshOnChange={[windowWidth, windowHeight, document, isDocumentLoaded, randomNumberForZoomableRefresh]}
        active={true}
        containerRef={containerRef}
        content={displayContent}
      />
    </div>
  );
}

export default withRouter(DocumentViewerPage);
