import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import { getUppercaseStatus } from "common/helpers";
import { changeTaskStatus } from "common/changeTaskStatus";

export async function recalculateTaskEstimatedHours(taskId) {
  const task = (
    await callGraphQLSimple({
      message: `Failed to load ${getSimpleLabel("task")}`,
      queryName: "getTaskSimple",
      variables: {
        id: taskId,
      },
    })
  ).data.getTask;

  const taskEstimatedHours = task.revisions.items.reduce((total, revision) => {
    return total + revision.estimatedHours;
  }, 0);

  await callGraphQLSimple({
    message: `Failed to update ${getSimpleLabel("task")}`,
    queryName: "updateTask",
    variables: {
      input: {
        id: taskId,
        estimatedHours: taskEstimatedHours,
      },
    },
  });
}

export async function updateDeletedFiles(taskRevision, fileType) {
  const deletedFilesByType = JSON.parse(taskRevision.deletedFilesByType || "{}");
  if (!deletedFilesByType.hasOwnProperty(fileType)) {
    deletedFilesByType[fileType] = 0;
  }
  deletedFilesByType[fileType]++;

  await callGraphQLSimple({
    message: `Failed to update ${getSimpleLabel("task revision")}`,
    queryName: "updateTaskRevision",
    variables: {
      input: {
        id: taskRevision.id,
        deletedFilesByType: JSON.stringify(deletedFilesByType),
      },
    },
  });
}

export async function checkTasksBasedOnStatus({ tasks, organisationDetails }) {
  const atLeastOneStatusMeansDone = (organisationDetails.taskStatuses || []).find((x) => x.type === "DONE");

  if (!atLeastOneStatusMeansDone) {
    return;
  }

  for (let task of tasks) {
    if (task.isHidden) {
      continue;
    }
    let statusDetails = (organisationDetails.taskStatuses || []).find(
      (statusDetails) => getUppercaseStatus(statusDetails.name) === getUppercaseStatus(task.status)
    );

    let statusMeansDone = false;
    if (statusDetails && statusDetails.type === "DONE") {
      statusMeansDone = true;
    }

    if (!!task.isFinished !== statusMeansDone) {
      console.log("Task status does not match isFinished", task.id, task.status, task.isFinished, statusMeansDone);
      await changeTaskStatus({
        taskId: task.id,
        status: task.status,
        organisationDetails,
        authorIsUnknown: true,
        recordActivity: false,
      });
    }
  }
}
