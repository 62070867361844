import moment from "moment";

import { roundToDecimals } from "common/mathHelpers";
import { getFeesForClient } from "common/feeHelpers";
import { INVOICE_DEFAULT_EXPECT_PAYMENT_AFTER_DAYS } from "common/constants";

export function getInvoiceTotals({ invoiceLineItems }) {
  let subtotal = 0;
  let totalTax = 0;

  invoiceLineItems.forEach((invoiceLineItem) => {
    subtotal = roundToDecimals(subtotal + roundToDecimals(invoiceLineItem.amount));
    totalTax = roundToDecimals(totalTax + roundToDecimals(invoiceLineItem.taxAmount));
  });

  let total = roundToDecimals(subtotal) + roundToDecimals(totalTax);

  return { subtotal, totalTax, total };
}

export function enhanceQuoteWithInvoicing(quote) {
  if (!quote) {
    return quote;
  }
  let newQuote = JSON.parse(JSON.stringify(quote));

  let quoteNonHourlyInvoicedAmount = 0;
  let quoteTotalInvoicedAmount = 0;

  let allHourlyLineItemsAreFullyInvoiced = true;
  let allLineItemsAreHourly = newQuote.lineItems?.items.length > 0;

  for (let i = 0; i < newQuote.lineItems?.items.length; i++) {
    const quoteLineItem = newQuote.lineItems?.items[i];
    let quoteLineItemInvoicedAmount = quoteLineItem.manuallyInvoicedAmount;

    for (let j = 0; j < quoteLineItem.invoiceLineItems.items.length; j++) {
      const invoiceLineItem = quoteLineItem.invoiceLineItems.items[j];
      quoteLineItemInvoicedAmount = roundToDecimals(
        quoteLineItemInvoicedAmount + roundToDecimals(invoiceLineItem.amount)
      );
    }

    if (!quoteLineItem.isHourly) {
      quoteNonHourlyInvoicedAmount = roundToDecimals(
        quoteNonHourlyInvoicedAmount + roundToDecimals(quoteLineItemInvoicedAmount)
      );
    }
    quoteTotalInvoicedAmount = roundToDecimals(quoteTotalInvoicedAmount + roundToDecimals(quoteLineItemInvoicedAmount));

    let quoteLineItemInvoicingStatus = null;

    if (quoteLineItem.isHourly) {
      if (quoteLineItem.isHourlyFullyInvoiced || quoteLineItem.isManuallyFullyInvoiced) {
        quoteLineItemInvoicingStatus = "FULLY_INVOICED";
      } else {
        // we don't want line items which have been rejected by the client to affect the invoicing status of the overall quote
        if (!quoteLineItem.isRejected) {
          allHourlyLineItemsAreFullyInvoiced = false;
        }
        if (quoteLineItemInvoicedAmount > 0 || quoteLineItem.invoiceLineItems?.items.length) {
          quoteLineItemInvoicingStatus = "PARTIALLY_INVOICED";
        }
      }
    } else {
      allLineItemsAreHourly = false;
      if (quoteLineItemInvoicedAmount > 0) {
        if (quoteLineItemInvoicedAmount >= quoteLineItem.amount) {
          quoteLineItemInvoicingStatus = "FULLY_INVOICED";
        } else {
          quoteLineItemInvoicingStatus = "PARTIALLY_INVOICED";
        }
      } else {
        if (quoteLineItem.invoiceLineItems.items.length) {
          quoteLineItemInvoicingStatus = "PARTIALLY_INVOICED";
        }
      }
    }

    quoteLineItem.invoicedAmount = quoteLineItemInvoicedAmount;
    quoteLineItem.invoicingStatus = quoteLineItemInvoicingStatus;
  }

  let quoteInvoicingStatus = null;

  if (newQuote.subtotal === 0 && allLineItemsAreHourly && allHourlyLineItemsAreFullyInvoiced) {
    quoteInvoicingStatus = "FULLY_INVOICED";
  } else if (quoteTotalInvoicedAmount > 0) {
    if (quoteNonHourlyInvoicedAmount >= newQuote.subtotal && allHourlyLineItemsAreFullyInvoiced) {
      quoteInvoicingStatus = "FULLY_INVOICED";
    } else {
      quoteInvoicingStatus = "PARTIALLY_INVOICED";
    }
  } else {
    let someQuoteLineItemsArePartiallyInvoiced = newQuote.lineItems.items.some(
      (quoteLineItem) => quoteLineItem.invoicingStatus === "PARTIALLY_INVOICED"
    );
    if (someQuoteLineItemsArePartiallyInvoiced) {
      quoteInvoicingStatus = "PARTIALLY_INVOICED";
    }
  }

  newQuote.invoicedAmount = quoteTotalInvoicedAmount;
  newQuote.invoicingStatus = quoteInvoicingStatus;

  return newQuote;
}

export function getTimesheetBlocksForTask({ timesheetBlocks, taskId }) {
  return timesheetBlocks.filter(
    (timesheetBlock) =>
      timesheetBlock.taskId === taskId &&
      timesheetBlock.billable &&
      timesheetBlock.variation &&
      !["WRITE_OFF", "ON_HOLD"].includes(timesheetBlock.invoicingStatus)
  );
}

export function getTimesheetBlocksForQuoteLineItem({ timesheetBlocks, quoteLineItemId }) {
  return timesheetBlocks.filter(
    (timesheetBlock) =>
      timesheetBlock.quoteLineItemId === quoteLineItemId &&
      timesheetBlock.billable &&
      !timesheetBlock.variation &&
      !["WRITE_OFF", "ON_HOLD"].includes(timesheetBlock.invoicingStatus)
  );
}

export function getTimesheetBlocksForInvoiceLineItem({ timesheetBlocks, invoiceLineItem }) {
  return timesheetBlocks.filter((timesheetBlock) => {
    if (!timesheetBlock.billable) {
      return false;
    }
    if (invoiceLineItem.taskId) {
      return timesheetBlock.taskId === invoiceLineItem.taskId && timesheetBlock.variation;
    } else if (invoiceLineItem.quoteLineItemId) {
      return timesheetBlock.quoteLineItemId === invoiceLineItem.quoteLineItemId && !timesheetBlock.variation;
    } else {
      return false;
    }
  });
}

export function createInvoiceLineItemFromTask({
  organisationDetails,
  client,
  task,
  quotes,
  invoice,
  invoiceLineItem = {},
  timesheetBlocks,
}) {
  if (!task) {
    throw new Error("Task not found, cannot create invoice line item");
  }
  if (!invoice) {
    throw new Error("Invoice not found, cannot create invoice line item");
  }
  if (!client) {
    throw new Error("Client not found, cannot create invoice line item");
  }
  if (!organisationDetails) {
    throw new Error("Organisation details not found, cannot create invoice line item");
  }
  if (!timesheetBlocks) {
    throw new Error("Timesheet blocks not found, cannot create invoice line item");
  }

  let timesheetBlocksForTask = getTimesheetBlocksForTask({
    timesheetBlocks,
    taskId: task.id,
  });

  let newInvoiceLineItem = {
    ...invoiceLineItem,
    title: task.title,
    organisation: organisationDetails.id,
    invoiceId: invoice.id,
    quoteLineItemId: "nothing",
    taskId: task.id,
    taxRate: invoice.taxRate,
  };

  delete newInvoiceLineItem.quoteLineItem;
  delete newInvoiceLineItem.updatedAt;
  delete newInvoiceLineItem.createdAt;

  let amount = 0;

  timesheetBlocksForTask.forEach((timesheetBlock) => {
    let durationHours = moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true);
    const quote = quotes.find((quote) => quote.id === timesheetBlock.quoteId);

    try {
      const feesData = getFeesForClient({
        organisationDetails,
        clientDetails: client,
        quote,
        currency: "GBP",
      });

      let feeRoleForTimesheet = feesData.find((x) => x.id === timesheetBlock.feeRole);
      if (!feeRoleForTimesheet) {
        throw new Error("Fee role not found for timesheet block, cannot calculate invoice line item amount");
      }
      amount = roundToDecimals(amount + roundToDecimals(feeRoleForTimesheet.value * roundToDecimals(durationHours)));
    } catch (e) {
      console.log("Error processing timesheet blocks for task:", e);
      throw e;
    }
  });

  newInvoiceLineItem = {
    ...newInvoiceLineItem,
    amount,
    taxAmount: roundToDecimals((newInvoiceLineItem.taxRate / 100) * amount),
    unitPrice: amount,
    quantity: 1,
  };

  return newInvoiceLineItem;
}

export function createInvoiceLineItemFromQuoteLineItem({
  organisationDetails,
  client,
  quote,
  quoteLineItemId,
  invoice,
  invoiceLineItem,
  timesheetBlocks,
}) {
  if (!quote) {
    throw new Error("Quote not found, cannot create invoice line item");
  }
  if (!invoice) {
    throw new Error("Invoice not found, cannot create invoice line item");
  }
  if (!client) {
    throw new Error("Client not found, cannot create invoice line item");
  }
  if (!organisationDetails) {
    throw new Error("Organisation details not found, cannot create invoice line item");
  }
  if (!timesheetBlocks) {
    throw new Error("Timesheet blocks not found, cannot create invoice line item");
  }

  const enhancedQuote = enhanceQuoteWithInvoicing(quote);
  const enhancedQuoteLineItem = enhancedQuote.lineItems.items.find((x) => x.id === quoteLineItemId);
  let timesheetBlocksForQuoteLineItem = getTimesheetBlocksForQuoteLineItem({ timesheetBlocks, quoteLineItemId });

  let newInvoiceLineItem = {
    ...invoiceLineItem,
    title: enhancedQuoteLineItem.title,
    description: enhancedQuoteLineItem.description,
    organisation: organisationDetails.id,
    invoiceId: invoice.id,
    quoteLineItemId: enhancedQuoteLineItem.id,
    taxRate: invoice.taxRate,
  };

  delete newInvoiceLineItem.quoteLineItem;
  delete newInvoiceLineItem.updatedAt;
  delete newInvoiceLineItem.createdAt;

  if (!enhancedQuoteLineItem.isHourly) {
    let unInvoicedAmount = roundToDecimals(enhancedQuoteLineItem.amount) - (enhancedQuoteLineItem.invoicedAmount || 0);
    if (unInvoicedAmount > roundToDecimals(enhancedQuoteLineItem.amount)) {
      unInvoicedAmount = roundToDecimals(enhancedQuoteLineItem.amount);
    }

    let quantity = 0;
    if (unInvoicedAmount > 0) {
      quantity =
        unInvoicedAmount /
        (roundToDecimals(enhancedQuoteLineItem.unitPrice || 0) +
          roundToDecimals(enhancedQuoteLineItem.checkPrice || 0));
    }

    newInvoiceLineItem = {
      ...newInvoiceLineItem,
      amount: unInvoicedAmount,
      taxAmount: roundToDecimals((newInvoiceLineItem.taxRate / 100) * unInvoicedAmount),
      unitPrice:
        roundToDecimals(enhancedQuoteLineItem.unitPrice || 0) + roundToDecimals(enhancedQuoteLineItem.checkPrice || 0),
      quantity,
      discountPercent: enhancedQuoteLineItem.discountPercent,
    };
  } else {
    let amount = 0;
    const feesData = getFeesForClient({
      organisationDetails,
      quote,
      clientDetails: client,
      currency: "GBP",
    });

    timesheetBlocksForQuoteLineItem.forEach((timesheetBlock) => {
      let durationHours = moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true);
      let feeRoleForTimesheet = feesData.find((x) => x.id === timesheetBlock.feeRole);
      if (!feeRoleForTimesheet) {
        throw new Error("Fee role not found for timesheet block, cannot calculate invoice line item amount");
      }

      let amountForTimesheetBlock = roundToDecimals(feeRoleForTimesheet.value * roundToDecimals(durationHours));
      amount = roundToDecimals(amount + amountForTimesheetBlock);
    });

    newInvoiceLineItem = {
      ...newInvoiceLineItem,
      amount,
      taxAmount: roundToDecimals((newInvoiceLineItem.taxRate / 100) * amount),
      unitPrice: amount,
      quantity: 1,
    };
  }
  return newInvoiceLineItem;
}

export function doAmountsMatch({ invoiceForDisplay }) {
  try {
    const { subtotal, totalTax, total } = getInvoiceTotals({
      invoiceLineItems: invoiceForDisplay.lineItems.items,
    });

    if (subtotal !== invoiceForDisplay.subtotal) {
      throw new Error("Invoice subtotal does not match the sum of invoice line item amounts.");
    }
    if (totalTax !== invoiceForDisplay.totalTax) {
      throw new Error("Invoice total tax does not match the sum of invoice line item tax amounts.");
    }
    if (total !== invoiceForDisplay.total) {
      throw new Error("Invoice total does not match the sum of invoice line item amounts and tax amounts.");
    }

    const someInvoiceLineItemsHaveNoTaxRate = invoiceForDisplay.lineItems.items.some(
      (invoiceLineItem) => invoiceLineItem.taxRate === undefined || invoiceLineItem.taxRate === null
    );

    if (someInvoiceLineItemsHaveNoTaxRate) {
      throw new Error("Some invoice line items have no tax rate, cannot calculate invoice totals.");
    }

    const someInvoiceLineItemsHaveADifferentTaxRate = invoiceForDisplay.lineItems.items.some(
      (invoiceLineItem) => invoiceLineItem.taxRate !== invoiceForDisplay.taxRate
    );

    if (someInvoiceLineItemsHaveADifferentTaxRate) {
      throw new Error("Some invoice line items have a different tax rate, cannot calculate invoice totals.");
    }

    for (let invoiceLineItem of invoiceForDisplay.lineItems.items) {
      if (invoiceLineItem.taskId || invoiceLineItem.quoteLineItem?.isHourly) {
        let timesheetBlocksForInvoiceLineItem = getTimesheetBlocksForInvoiceLineItem({
          timesheetBlocks: invoiceForDisplay.timesheetBlocks.items,
          invoiceLineItem,
        });

        let totalAmountsFromTimesheetBlocks = timesheetBlocksForInvoiceLineItem.reduce((total, timesheetBlock) => {
          return roundToDecimals(total + roundToDecimals(timesheetBlock.amount));
        }, 0);

        if (totalAmountsFromTimesheetBlocks !== invoiceLineItem.amount) {
          throw new Error(
            `Invoice line item amount does not match total amount from timesheet blocks: ${invoiceLineItem.title} (${invoiceLineItem.id}).`
          );
        }
      } else {
        if (invoiceLineItem.amount !== invoiceLineItem.unitPrice * invoiceLineItem.quantity) {
          throw new Error(
            `Invoice line item amount does not match unit price * quantity: ${invoiceLineItem.title} (${invoiceLineItem.id}).`
          );
        }
      }
    }
  } catch (e) {
    console.error(e);
    throw e;
  }

  return true;
}

export function getInvoiceForDisplay({ invoice, client, quotes, organisationDetails, timesheetBlocks, tasks }) {
  let timesheetBlocksForDisplay = timesheetBlocks
    .filter((timesheetBlock) => timesheetBlock.billable)
    .map((timesheetBlock) => {
      let durationHours = moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true);

      const quote = quotes.find((x) => x.id === timesheetBlock.quoteId);

      if (timesheetBlock.quoteId && timesheetBlock.quoteId !== "nothing" && !quote) {
        throw new Error(`Could not find quote for timesheet block, cannot build invoice for display`);
      }

      if (!client) {
        throw new Error(`Could not find client for timesheet block, cannot build invoice for display`);
      }

      let feesData = getFeesForClient({
        organisationDetails,
        quote,
        clientDetails: client,
        currency: "GBP",
      });

      let feeRoleForTimesheet = feesData.find((x) => x.id === timesheetBlock.feeRole);

      if (!feeRoleForTimesheet) {
        throw new Error(`Could not find fee role for timesheet block, cannot build invoice for display`);
      }

      return {
        ...timesheetBlock,
        date: moment(timesheetBlock.startAt).format("DD-MM-YYYY"),
        hours: durationHours,
        amount: roundToDecimals(feeRoleForTimesheet.value * roundToDecimals(durationHours)),
        description: timesheetBlock.description,
        feeRoleReadable: feeRoleForTimesheet.label,
        rateWithoutCurrency: feeRoleForTimesheet.value,
        rate: feeRoleForTimesheet ? global.formatCurrency("GBP", feeRoleForTimesheet.value) : "",
      };
    });

  return {
    ...invoice,
    lineItems: {
      items: [
        ...invoice.lineItems.items.map((invoiceLineItem) => {
          let quoteLineItem;
          if (invoiceLineItem.quoteLineItem) {
            let quote = quotes.find((x) => x.id === invoiceLineItem.quoteLineItem.quoteId);
            quote = enhanceQuoteWithInvoicing(quote);
            quoteLineItem = {
              ...(invoiceLineItem.quoteLineItem || {}),
              ...quote?.lineItems?.items.find((x) => x.id === invoiceLineItem.quoteLineItem.id),
              quote,
            };
          }

          let task;
          if (invoiceLineItem.taskId) {
            task = tasks.find((task) => task.id === invoiceLineItem.taskId);
          }

          return {
            ...invoiceLineItem,
            task,
            quoteLineItem,
          };
        }),
      ],
    },
    timesheetBlocks: {
      items: timesheetBlocksForDisplay,
    },
  };
}

export function isInvoiceFullyApproved({ organisationDetails, invoice }) {
  return organisationDetails.settings?.invoice?.usesDoubleInvoiceReview
    ? invoice.secondReviewApprovedAt
    : invoice.reviewApprovedAt;
}

export function getInvoicePaymentTerms({ invoice, client, organisationDetails }) {
  if (!invoice) {
    if (!client) {
      if (!organisationDetails) {
        return INVOICE_DEFAULT_EXPECT_PAYMENT_AFTER_DAYS;
      }

      if (
        organisationDetails?.settings?.invoice?.defaultExpectPaymentAfterDays !== undefined &&
        organisationDetails?.settings?.invoice?.defaultExpectPaymentAfterDays !== null
      ) {
        return organisationDetails?.settings?.invoice?.defaultExpectPaymentAfterDays;
      }

      return INVOICE_DEFAULT_EXPECT_PAYMENT_AFTER_DAYS;
    }

    if (client?.defaultExpectPaymentAfterDays !== undefined && client?.defaultExpectPaymentAfterDays !== null) {
      return client.defaultExpectPaymentAfterDays;
    }

    if (
      organisationDetails?.settings?.invoice?.defaultExpectPaymentAfterDays !== undefined &&
      organisationDetails?.settings?.invoice?.defaultExpectPaymentAfterDays !== null
    ) {
      return organisationDetails?.settings?.invoice?.defaultExpectPaymentAfterDays;
    }

    return INVOICE_DEFAULT_EXPECT_PAYMENT_AFTER_DAYS;
  }

  if (invoice.expectPaymentAfterDays !== undefined && invoice.expectPaymentAfterDays !== null) {
    return invoice.expectPaymentAfterDays;
  }

  if (client?.defaultExpectPaymentAfterDays !== undefined && client?.defaultExpectPaymentAfterDays !== null) {
    return client.defaultExpectPaymentAfterDays;
  }

  if (
    organisationDetails?.settings?.invoice?.defaultExpectPaymentAfterDays !== undefined &&
    organisationDetails?.settings?.invoice?.defaultExpectPaymentAfterDays !== null
  ) {
    return organisationDetails?.settings?.invoice?.defaultExpectPaymentAfterDays;
  }

  return INVOICE_DEFAULT_EXPECT_PAYMENT_AFTER_DAYS;
}
