import getLengthFields from "./dataSourceHelpers/getLengthFields";

import { getFields as getDayFields } from "./day";
import { getFields as getGeneralFields } from "./general";
import { getFields as getQuoteFields } from "./quote";
import { getFields as getQuoteLineItemFields } from "./quoteLineItem";
import { getFields as getInvoiceFields } from "./invoice";
import { getFields as getInvoiceLineItemFields } from "./invoiceLineItem";
import { getFields as getPurchaseOrderFields } from "./purchaseOrder";
import { getFields as getPurchaseOrderLineItemFields } from "./purchaseOrderLineItem";
import { getFields as getClientFields } from "./client";
import { getFields as getSupplierFields } from "./supplier";
import { getFields as getFileFields } from "./file";
import { getFields as getFormFields } from "./form";
import { getFields as getFormElementFields } from "./formElement";
import { getFields as getFormElementChildFields } from "./formElementChild";
import { getFields as getProjectFields } from "./project";
import { getFields as getSheetFields } from "./sheet";
import { getFields as getTaskFields } from "./task";
import { getFields as getTaskRevisionFields } from "./taskRevision";
import { getFields as getAttachmentFields } from "./attachment";
import { getFields as getClientContactFields } from "./clientContact";
import { getFields as getClientAddressFields } from "./clientAddress";
import { getFields as getSupplierContactFields } from "./supplierContact";
import { getFields as getSupplierAddressFields } from "./supplierAddress";
import { getFields as getOrganisationFields } from "./organisation";
import { getFields as getUserFields } from "./user";
import { getFields as getApiUserFields } from "./apiUser";
import { getFields as getQuoteFeesFields } from "./quoteFees";
import { getFields as getTimesheetBlockFields } from "./timesheetBlock";
import { getFields as getTimelineBlockFields } from "./timelineBlock";
import { getFields as getObjectFields } from "./object";
import { getFields as getRequestFields } from "./request";

/////////////////////////// organisation-specific data sources //////////////////////
import { getFields as getRDXFields } from "./clientSpecific/RDX";
import { getFields as getBYRNEFields } from "./clientSpecific/BYRNE";
import { getFields as getPSDFields } from "./clientSpecific/PSD";

export function getFieldsForEditorByDataSource(params) {
  const { dataSource } = params;

  if (!dataSource) {
    return [];
  }

  let fields = getFieldsForAllDataSources(params);

  if (!params.includeValue) {
    fields = fields.map((field) => ({
      ...field,
      value: field.id,
    }));
  }

  if (params.fieldType && params.fieldType === "repeatFor") {
    fields = fields.filter((field) => field.fieldTypes?.includes(params.fieldType));
  }

  return fields;
}

export async function getFieldValue(params) {
  const { dataSource } = params;
  if (!dataSource) {
    return null;
  }

  let fieldDetails;
  try {
    let possibleFields = getFieldsForAllDataSources(params);

    fieldDetails = possibleFields.find((field) => field.id === params.fieldId);

    let value;
    if (fieldDetails) {
      if (typeof fieldDetails.value === "function") {
        value = await fieldDetails.value(params);
      } else {
        value = fieldDetails.value;
      }

      if (fieldDetails.type === "radio-list") {
        let valueIsAmongOptions = true;
        if (fieldDetails.options) {
          valueIsAmongOptions = fieldDetails.options.some((option) => option.value === value);
        }

        if (fieldDetails.autoSelectFirstOption && fieldDetails.options && fieldDetails.options.length > 0) {
          if (!value) {
            value = fieldDetails.options[0].value;
          } else {
            if (!valueIsAmongOptions) {
              value = fieldDetails.options[0].value;
            }
          }
        } else {
          if (!valueIsAmongOptions) {
            value = undefined;
          }
        }
      } else {
        if (
          !value &&
          fieldDetails.type !== "checkbox-list" &&
          fieldDetails.autoSelectFirstOption &&
          fieldDetails.options &&
          fieldDetails.options.length > 0
        ) {
          value = fieldDetails.options[0].value;
        }
      }
    }

    return value;
  } catch (e) {
    console.error("Error with getFieldValue() params = ", params);
    console.error("Error with getFieldValue() fieldDetails = ", fieldDetails);
    console.error("Error with getFieldValue() error = ", e);
    throw e;
  }
}

function getFieldsForAllDataSources(params) {
  const { dataSource } = params;

  let mainFields = [];

  switch (dataSource) {
    case "day":
      mainFields = getDayFields(params);
      break;
    case "general":
      mainFields = getGeneralFields(params);
      break;
    case "client":
      mainFields = getClientFields(params);
      break;
    case "supplier":
      mainFields = getSupplierFields(params);
      break;
    case "file":
      mainFields = getFileFields(params);
      break;
    case "invoice":
      mainFields = getInvoiceFields(params);
      break;
    case "invoiceLineItem":
      mainFields = getInvoiceLineItemFields(params);
      break;
    case "purchaseOrder":
      mainFields = getPurchaseOrderFields(params);
      break;
    case "purchaseOrderLineItem":
      mainFields = getPurchaseOrderLineItemFields(params);
      break;
    case "quote":
      mainFields = getQuoteFields(params);
      break;
    case "quoteLineItem":
      mainFields = getQuoteLineItemFields(params);
      break;
    case "form":
      mainFields = getFormFields(params);
      break;
    case "formElement":
      mainFields = getFormElementFields(params);
      break;
    case "formElementChild":
      // console.log("FORM ELEMENT CHILD");
      mainFields = getFormElementChildFields(params);
      break;
    case "project":
      mainFields = getProjectFields(params);
      break;
    case "sheet":
      mainFields = getSheetFields(params);
      break;
    case "task":
      mainFields = getTaskFields(params);
      break;
    case "taskRevision":
      mainFields = getTaskRevisionFields(params);
      break;
    case "attachment":
      mainFields = getAttachmentFields(params);
      break;
    case "clientContact":
      mainFields = getClientContactFields(params);
      break;
    case "clientAddress":
      mainFields = getClientAddressFields(params);
      break;
    case "supplierContact":
      mainFields = getSupplierContactFields(params);
      break;
    case "supplierAddress":
      mainFields = getSupplierAddressFields(params);
      break;
    case "organisation":
      mainFields = getOrganisationFields(params);
      break;
    case "user":
      mainFields = getUserFields(params);
      break;
    case "apiUser":
      mainFields = getApiUserFields(params);
      break;
    case "quoteFees":
      mainFields = getQuoteFeesFields(params);
      break;
    case "timesheetBlock":
      mainFields = getTimesheetBlockFields(params);
      break;
    case "timelineBlock":
      mainFields = getTimelineBlockFields(params);
      break;
    case "formula":
      mainFields = [];
      break;
    case "object":
      mainFields = getObjectFields(params);
      break;
    case "request":
      mainFields = getRequestFields(params);
      break;
    case "RDX":
      mainFields = getRDXFields(params);
      break;
    case "BYRNE":
      mainFields = getBYRNEFields(params);
      break;
    case "PSD":
      mainFields = getPSDFields(params);
      break;
    default:
      throw new Error(`Unknown data source: ${dataSource}`);
  }

  let lengthFields = getLengthFields(mainFields);

  return [...mainFields, ...lengthFields];
}

export function getFieldLabelById({ dataSource, id, ...params }) {
  const fields = getFieldsForEditorByDataSource({ dataSource, ...params });
  const targetField = fields.find((field) => field.id === id);
  return targetField?.label || "";
}

export function getFieldDetailsById({ includeValue = false, dataSource, id, ...params }) {
  const fields = getFieldsForEditorByDataSource({
    includeValue,
    dataSource,
    ...params,
  });
  const targetField = fields.find((field) => field.id === id || id === `repeat_${field.id}`);
  return targetField;
}

export function getFormFieldValue(form, fieldId) {
  if (!form?.fields) {
    return "";
  }

  let targetField = form.fields[fieldId];
  if (!targetField) {
    return "";
  }

  return targetField.value || "";
}
