import { message, Input, Modal, Button } from "antd";
import { useGetSetState } from "react-use";
import moment from "moment";

import { callGraphQLSimple } from "common/apiHelpers";
import {
  sendRequestInformationSubmittedNotification,
  sendRequestFormReceivedNotification,
  sendNotificationToExternalApproverAboutInformationSubmitted,
} from "common/notificationHelpers";
import { processIdForDisplay } from "common/helpers";
import { getSimpleLabel } from "common/labels";
import { isFormValid } from "common/formValidators";
import { buildReport } from "ReportPage/Report/reportHelpers";

import "./SendToEngineerButton.scss";

type Props = {
  request: any;
  apiUser: any;
  formFileId: string;
  users: any;
  formName: string;
  setValidationErrors: (validationErrors) => void;
  formCreationActivityItem: any;
  organisationDetails: any;
};

export default function SendToEngineerButton({
  apiUser,
  request,
  formFileId,
  formName,
  users,
  setValidationErrors,
  formCreationActivityItem,
  organisationDetails,
}: Props) {
  const [getState, setState] = useGetSetState({
    isSendMessageModalOpen: false,
    moreInformationMessageContent: "",
  });

  async function onClick() {
    const messageKey = "validating-request-form";
    message.loading({ content: `Validating ${getSimpleLabel("request")} form...`, key: messageKey, duration: 10 });

    let validationResult;
    try {
      validationResult = await isFormValid({ fileId: formFileId });
      if (validationResult?.isValid) {
        setValidationErrors([]);
        message.destroy(messageKey);
      } else {
        setValidationErrors(validationResult.validationErrors);
        message.error({
          content: "There are validation errors in the form",
          key: messageKey,
        });

        return;
      }
    } catch (e) {
      message.error({
        content: `Encountered an error while trying to to validate ${getSimpleLabel("request")} form`,
        key: messageKey,
      });
    }

    setState({ isSendMessageModalOpen: true });
  }

  async function submit() {
    const { moreInformationMessageContent } = getState();
    if (!moreInformationMessageContent) {
      message.error("Please include a message");
      return;
    }

    const messageKey = "send-information";
    message.loading({ content: "Submitting information...", key: messageKey, duration: 0 });
    setState({ isSendMessageModalOpen: false });

    let newRequestStatus = "INFORMATION_SUBMITTED";
    let toBeApprovedBy = formCreationActivityItem?.content?.toBeApprovedBy;
    let formPdfSignedUrl;
    if (toBeApprovedBy) {
      newRequestStatus = "AWAITING_APPROVAL";
      let buildReportResponse;
      try {
        buildReportResponse = await buildReport({
          fileId: formFileId,
          taskId: null,
          requestId: request.id,
          users,
          organisationDetails,
        });
      } catch (e) {
        message.error("Failed to generate form PDF");
        return;
      }
      const { signedUrl } = buildReportResponse || {};

      if (!signedUrl) {
        message.error({
          content: "Failed to generate form PDF",
          key: messageKey,
          duration: 3000,
        });
        setTimeout(() => {
          message.destroy();
        }, 3000);
        return;
      } else {
        formPdfSignedUrl = signedUrl;
      }
    }

    try {
      setState({ isSendMessageModalOpen: false, moreInformationMessageContent: "" });
      await callGraphQLSimple({
        mutation: "updateRequest",
        message: `Failed to update status`,
        variables: {
          input: {
            id: request.id,
            status: newRequestStatus,
          },
        },
      });
    } catch (e) {
      message.error({
        content: `Failed to update ${getSimpleLabel("request")} status`,
        key: messageKey,
      });
      return;
    }

    let activityItem = (
      await callGraphQLSimple({
        mutation: "createActivityItem",
        message: "Failed to record activity item",
        variables: {
          input: {
            parentId: request.id,
            author: apiUser.id,
            content: JSON.stringify({
              type: "INFORMATION_SUBMITTED",
              message: moreInformationMessageContent,
              formFileId,
              formName,
              requestTitle: request.title,
              toBeApprovedBy: formCreationActivityItem?.content?.toBeApprovedBy,
              sharing: {
                type: "PUBLIC",
                expiresAt: moment()
                  .add(60 * 60 * 24 * 365, "seconds") // 365 days
                  .toISOString(),
              },
            }),
            organisation: request.organisation,
          },
        },
      })
    ).data.createActivityItem;

    try {
      if (toBeApprovedBy) {
        let fileName = `${processIdForDisplay(request.id)} - ${request.title} - ${formName}.pdf`;
        await sendNotificationToExternalApproverAboutInformationSubmitted({
          request,
          formName,
          messageContent: moreInformationMessageContent,
          toBeApprovedBy,
          apiUser,
          activityItemId: activityItem.id,
          attachments: [
            {
              signedUrl: formPdfSignedUrl,
              fileName,
            },
          ],
        });
      } else {
        if (request.assignedTo && request.assignedTo !== "nothing") {
          await sendRequestInformationSubmittedNotification({
            users,
            request,
            apiUser,
            formName,
            messageContent: moreInformationMessageContent,
            formFileId,
          });
        }

        await sendRequestFormReceivedNotification({
          users,
          request,
          apiUser,
          formName,
          formFileId,
          receiverId: formCreationActivityItem.author,
        });
      }

      message.success({ content: "Information submitted", key: messageKey });
    } catch (e) {
      message.error({
        content: "Failed to notify engineer",
        key: messageKey,
      });
      try {
        await callGraphQLSimple({
          mutation: "updateRequest",
          displayError: false,
          variables: {
            input: {
              id: request.id,
              status: request.status,
            },
          },
        });
      } catch (e) {
        // nothing we can do about it
      }
    }

    try {
      await callGraphQLSimple({
        mutation: "updateRequest",
        displayError: false,
        variables: {
          input: {
            id: request.id,
            itemSubscription: Math.floor(Math.random() * 100000),
          },
        },
      });
    } catch (e) {
      // nothing we can do about it
    }
    setState({ isSendMessageModalOpen: false, moreInformationMessageContent: "" });
  }

  const state = getState();

  let noteAboutApproval: any = null;
  if (formCreationActivityItem?.content?.toBeApprovedBy) {
    noteAboutApproval = (
      <p className="note-about-approval">
        Reminder: this form will be sent to <b>{formCreationActivityItem.content.toBeApprovedBy}</b> for approval.
      </p>
    );
  }

  return (
    <>
      <Button type="primary" onClick={onClick}>
        Submit information
      </Button>
      <Modal
        title="Include a message"
        open={state.isSendMessageModalOpen}
        onCancel={() => {
          setState({ isSendMessageModalOpen: false, moreInformationMessageContent: "" });
        }}
        onOk={submit}
        className="send-to-engineer-include-a-message-modal"
      >
        <Input.TextArea
          value={state.moreInformationMessageContent}
          onChange={(e) => setState({ moreInformationMessageContent: e.target.value })}
        />
        {noteAboutApproval}
      </Modal>
    </>
  );
}
