import { getSimpleLabel } from "common/labels";
import { INVOICE_DEFAULT_EXPECT_PAYMENT_AFTER_DAYS } from "common/constants";

export const MODULES = [
  {
    NAME: "Delete",
    DESCRIPTION: "Ability to permanently delete tasks, projects and clients",
    SCHEMA_FIELD: "usesDelete",
  },
  {
    NAME: "Quotes",
    DESCRIPTION: "Create and send quotes to your clients for work to be undertaken",
    SCHEMA_FIELD: "settings.quote.usesQuotes",
  },
  {
    NAME: "Invoices",
    DESCRIPTION: "Create and send invoices to your clients for work that has been undertaken",
    SCHEMA_FIELD: "settings.invoice.usesInvoices",
  },
  {
    NAME: "Suppliers",
    DESCRIPTION: "Create and manage suppliers. Suppliers are needed if you want to issue purchase orders.",
    SCHEMA_FIELD: "settings.supplier.usesSuppliers",
  },
  {
    NAME: "Purchase orders",
    DESCRIPTION: "Create and send purchase orders to your suppliers for work or materials that you need.",
    SCHEMA_FIELD: "settings.purchaseOrder.usesPurchaseOrders",
  },
  {
    NAME: "Timesheets",
    DESCRIPTION: "Track time against tasks. Works with or without quotes and invoices.",
    SCHEMA_FIELD: "settings.timesheet.usesTimesheets",
  },
  {
    NAME: "Timeline",
    DESCRIPTION:
      "Plan work across your entire organisation and see what's coming next using Gantt charts and a drag-and-drop interface",
    SCHEMA_FIELD: "settings.timeline.usesTimeline",
  },
  {
    NAME: "Calendar",
    DESCRIPTION: "View planned tasks in a calendar view",
    SCHEMA_FIELD: "settings.calendar.usesCalendar",
  },
  {
    NAME: "Library",
    DESCRIPTION: "A place to store non-project specific files - technical documents, books, case studies, etc.",
    SCHEMA_FIELD: "settings.documentLibrary.usesDocumentLibrary",
  },
  {
    NAME: "Document Register",
    DESCRIPTION: "Document which tracks revisions to files and sheets",
    SCHEMA_FIELD: "settings.general.usesDocumentRegister",
  },
  {
    NAME: "Requests",
    DESCRIPTION: "Allows external users to request work from your organisation",
    SCHEMA_FIELD: "settings.request.usesRequests",
  },
  {
    NAME: "Sprints",
    DESCRIPTION: "Logical grouping of tasks, e.g. by priority, type or category",
    SCHEMA_FIELD: "usesSprints",
  },
  {
    NAME: "Teams",
    DESCRIPTION: "Teams allow you to group together users and projects",
    SCHEMA_FIELD: "settings.general.usesTeams",
  },
  {
    NAME: `Confirmed tasks`,
    DESCRIPTION: `If enabled, tasks can exist in two states: confirmed and unconfirmed, which changes the way they are displayed throughout the app`,
    SCHEMA_FIELD: "settings.general.usesTaskConfirmation",
  },
  {
    NAME: `Task revisions`,
    DESCRIPTION: `Allows you to create revisions of each task. Each task revision can have files and undergo a review.`,
    SCHEMA_FIELD: "usesRevisions",
  },
  {
    NAME: "Files",
    DESCRIPTION: `Allows you to store and manage your work directly in DraughtHub: CAD, Excel, PowerPoint, reports, etc. as part of task revisions.`,
    SCHEMA_FIELD: "usesFiles",
  },
  {
    NAME: "Reviews",
    DESCRIPTION:
      "Give your team a standardised way of reviewing and approving work that is produced through DraughtHub.",
    SCHEMA_FIELD: "usesReviews",
  },
  {
    NAME: "Auto publish to PDF",
    DESCRIPTION:
      "Every time you open a file from DraughtHub on your local computer and then save it, we publish it to PDF.",
    SCHEMA_FIELD: "hasAutoPublish",
  },
  {
    NAME: "Design authority",
    DESCRIPTION: "Set appropriate authority levels for which users can be assigned to each task.",
    SCHEMA_FIELD: "usesDesignAuthority",
  },
  {
    NAME: "Review authority",
    DESCRIPTION: "Set appropriate authority levels for which users can review each task",
    SCHEMA_FIELD: "usesReviewAuthority",
  },
  {
    NAME: "Client initials",
    DESCRIPTION: "Choose whether clients have a field for initials",
    SCHEMA_FIELD: "usesClientInitials",
  },
  {
    NAME: "Project initials",
    DESCRIPTION: "Choose whether projects have a field for initials",
    SCHEMA_FIELD: "usesProjectInitials",
  },
  {
    NAME: "Task initials",
    DESCRIPTION: "Choose whether tasks have a field for initials",
    SCHEMA_FIELD: "usesTaskInitials",
  },
  {
    NAME: "Time off",
    DESCRIPTION: "Allows you to track holidays and sick days for your team",
    SCHEMA_FIELD: "settings.general.usesTimeOff",
  },
  {
    NAME: "Upload from Google Drive",
    DESCRIPTION: "Allows you to upload files directly from Google Drive",
    SCHEMA_FIELD: "settings.general.usesUploadFromGoogleDrive",
  },
  {
    NAME: getSimpleLabel("Stock"),
    DESCRIPTION: "Allows you to create and manage tools, equipment, materials and other stock items",
    SCHEMA_FIELD: "settings.stock.usesStock",
  },
];

export const GENERAL_SETTINGS = [
  {
    NAME: "Hide financials",
    DESCRIPTION:
      "If enabled, financial information will be hidden all throughout the platform: line item amounts, tax amounts, subtotals, totals, etc.",
    SCHEMA_FIELD: "settings.general.hideFinancials",
  },
  {
    NAME: "Hide organisation initials from IDs",
    DESCRIPTION:
      "If enabled, organisation initials will not be displayed in task/project/quote/invoice/purchase order IDs anywhere in the app.",
    SCHEMA_FIELD: "settings.general.hideOrganisationIdInTags",
  },
  {
    NAME: "Hide users by default",
    DESCRIPTION:
      "If enabled, users will not be shown in the user list, in filters, and on the timeline by default - they will only do so if they have the permission to have that user displayed. If this setting is not enabled, all users will show up in the aforementioned places automatically.",
    SCHEMA_FIELD: "settings.general.requirePermissionToDisplayUsers",
  },
];

export const QUOTE_SETTINGS = [
  {
    NAME: "Calculate budgets in hours instead of currency",
    SCHEMA_FIELD: "quotesInHours",
  },
  {
    NAME: 'Hide "Valid from" field',
    DESCRIPTION: 'Whether the "Valid from" field is hidden',
    SCHEMA_FIELD: "isValidFromHidden",
  },
  {
    NAME: 'Hide "Valid until" field',
    DESCRIPTION: 'Whether the "Valid until" field is hidden',
    SCHEMA_FIELD: "isValidUntilHidden",
  },
  {
    NAME: "Email address to send quotes from",
    DESCRIPTION: "If not provided, the default one will be used.",
    SCHEMA_FIELD: "emailAddressToSendQuotesFrom",
    INPUT_TYPE: "text",
  },
  {
    NAME: "Default BCC quote recipient (comma-separated)",
    DESCRIPTION: "Email address that will receive a BCC copy of each quote.",
    SCHEMA_FIELD: "defaultQuoteBccEmails",
    INPUT_TYPE: "text",
  },
  {
    NAME: "Disable quote preview",
    DESCRIPTION: "If this setting is enabled, the toggle that enables the quote preview to be displayed is hidden.",
    SCHEMA_FIELD: "disablePreview",
  },
  {
    NAME: "Task title defaults to quote title",
    DESCRIPTION:
      "If enabled, when a task gets created from a quote line item, the task title will default to the quote title. Otherwise, the task title will default to the quote line item title.",
    SCHEMA_FIELD: "tasksCreatedFromQuoteDefaultToQuoteTitle",
  },
  {
    NAME: "Task estimated hours defaults to line item amount",
    DESCRIPTION:
      "If enabled, when a task gets created from a quote line item, the number of estimated hours on teh task will default to the amount on the line item. Otherwise, the task estimated hours will not be pre-filled.",
    SCHEMA_FIELD: "tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount",
  },
  {
    NAME: "Default line item unit price",
    DESCRIPTION: "If not set, it will default to zero.",
    SCHEMA_FIELD: "defaultUnitPrice",
    INPUT_TYPE: "text",
  },

  {
    NAME: "Delete",
    DESCRIPTION: "Ability to delete quotes",
    SCHEMA_FIELD: "usesDelete",
  },
];

export const PROJECT_SETTINGS = [
  {
    NAME: "Hide checkbox for custom project number at creation time",
    SCHEMA_FIELD: "hideCustomProjectNumberAtCreationTime",
  },
  {
    NAME: "Hide assigned to at creation time",
    SCHEMA_FIELD: "hideAssignedToAtCreationTime",
  },
  {
    NAME: "Hide client at creation time",
    SCHEMA_FIELD: "hideClientAtCreationTime",
  },
  {
    NAME: "Make checkbox for custom number be on by default",
    SCHEMA_FIELD: "customNumberCheckboxOnByDefault",
  },
];

export const TASK_SETTINGS = [
  {
    NAME: "Use priority levels",
    DESCRIPTION: "If enabled, users can set priority levels for each individual task revision.",
    SCHEMA_FIELD: "settings.task.usesPriority",
  },
  {
    NAME: "Automatically create Cat 2 check task",
    DESCRIPTION:
      "At task creation time, if the authority level is set to Cat 2, automatically create a Cat 2 check task and link it to the original task.",
    SCHEMA_FIELD: "settings.task.createsCat2Check",
  },
  {
    NAME: "Do not automatically create attachment folders for each task",
    DESCRIPTION:
      "If this is enabled, attachment folders will not be automatically created for each task and the default attachment folder used will be the project-level one.",
    SCHEMA_FIELD: "settings.task.dontCreateTaskFolders",
  },
  {
    NAME: "Task revisions are synced with file/sheet revisions",
    DESCRIPTION:
      "If enabled, creating a task revision automatically creates a sheet revision for each sheet in each file, or if the file has no sheets, then it creates a revision on the file itself. Enabling this setting also disables the ability to individually add a revision for a given sheet.",
    SCHEMA_FIELD: "settings.task.taskRevisionsAreSyncedWithSheetRevisions",
  },
  {
    NAME: "Use task estimates",
    DESCRIPTION: "If enabled, users can set task-level estimates in hours.",
    SCHEMA_FIELD: "settings.task.usesTaskEstimates",
  },
  {
    NAME: "Use task revision estimates",
    DESCRIPTION: "If enabled, users can set task revision-level estimates in hours.",
    SCHEMA_FIELD: "settings.task.useTaskRevisionEstimates",
  },
  {
    NAME: "Use due dates on task revisions",
    DESCRIPTION:
      "If enabled, users can set due dates at the task revision level. Otherwise, they can only do so at the task level.",
    SCHEMA_FIELD: "settings.task.useDueDatesOnTaskRevisions",
  },

  {
    NAME: "Use task revision requested date",
    DESCRIPTION: "If enabled, users can set the date when each individual task revision was requested by the client.",
    SCHEMA_FIELD: "settings.task.usesRequestedDate",
  },
  {
    NAME: "Task assignee is mandatory",
    DESCRIPTION:
      "If enabled, users must assign a task to someone before they can create it. Otherwise, they can leave it unassigned. Additionally, if enabled, users cannot unassign a task once it has been assigned.",
    SCHEMA_FIELD: "settings.task.isTaskAssigneeMandatory",
  },
  {
    NAME: "Task due date is mandatory",
    DESCRIPTION:
      "If enabled, users must set a due date for a task before they can create it. Otherwise, they can leave it unset. Additionally, if enabled, users cannot remove a due date once it has been set.",
    SCHEMA_FIELD: "settings.task.isTaskDueDateMandatory",
  },
  {
    NAME: "Display task estimated hours as a tag",
    DESCRIPTION: "If enabled, the estimated hours will be displayed as a tag on the task.",
    SCHEMA_FIELD: "settings.task.displayTaskEstimatesAsTag",
  },
  {
    NAME: "Allow use of CAT 0",
    DESCRIPTION:
      "If enabled, users can set the authority level of tasks to be CAT 0. If disabled, the only options are CAT 1, 2 and 3.",
    SCHEMA_FIELD: "settings.task.allowUseOfCatZero",
  },
  {
    NAME: "Automatically create projects when creating tasks",
    DESCRIPTION:
      "If enabled, the app will automatically create a project when a task is created. The project will have the same title as the task.",
    SCHEMA_FIELD: "settings.task.automaticallyCreateProject",
  },
  {
    NAME: "Display view type picker on tasks page",
    DESCRIPTION: "If checked, it displays a choice between Board and Table views",
    SCHEMA_FIELD: "settings.dashboard.displayViewTypePicker",
  },
  {
    NAME: "Hide option to copy tasks at task creation time",
    SCHEMA_FIELD: "settings.task.hideCopyTaskAtCreationTime",
  },
  {
    NAME: "Hide option to set custom task number at task creation time",
    SCHEMA_FIELD: "settings.task.hideCustomTaskNumberAtCreationTime",
  },
  {
    NAME: "Hide task due dates",
    SCHEMA_FIELD: "settings.task.hideTaskDueDates",
  },
  {
    NAME: 'Hide "Raw cloud storage" tab',
    SCHEMA_FIELD: "settings.task.hideRawCloudStorageTab",
  },
  {
    NAME: "Allow multiple live task revisions (usually used for task stages, instead of revisions)",
    SCHEMA_FIELD: "settings.task.allowMultipleLiveTaskRevisions",
  },
  {
    NAME: "Do not allow the creation of new task revisions (usually used for task stages, instead of revisions)",
    SCHEMA_FIELD: "settings.task.cannotCreateNewTaskRevisions",
  },
  {
    NAME: "Copying a task also copies all task revisions, not just the latest one",
    SCHEMA_FIELD: "settings.task.copyTaskAlsoCopiesAllTaskRevisions",
  },
  {
    NAME: "Task ID to copy by default",
    SCHEMA_FIELD: "settings.task.taskIdToCopyByDefault",
    INPUT_TYPE: "text",
  },
  {
    NAME: "Tasks have start and end dates",
    SCHEMA_FIELD: "settings.task.usesStartAndEndDates",
  },
  {
    NAME: "Allow multiple users to be assigned to a task",
    SCHEMA_FIELD: "settings.task.allowMultipleUsersToBeAssignedToATask",
  },
  {
    NAME: "Allow multiple stock items to be assigned to a task",
    SCHEMA_FIELD: "settings.task.allowMultipleStockItemsToBeAssignedToATask",
  },
];

export const TIMESHEET_SETTINGS = [
  {
    NAME: "Allow timesheets to be locked",
    SCHEMA_FIELD: "settings.timesheet.usesReview",
  },
  {
    NAME: "Tags are mandatory",
    DESCRIPTION: "If unchecked, users can record timesheet activity without specifying tags",
    SCHEMA_FIELD: "settings.timesheet.areTagsMandatory",
  },
  {
    NAME: "Description is mandatory",
    DESCRIPTION: "If unchecked, users can record timesheet activity without specifying an activity description",
    SCHEMA_FIELD: "settings.timesheet.isDescriptionMandatory",
  },
  {
    NAME: "Timesheet blocks can be assigned to task revisions",
    DESCRIPTION: "If unchecked, timesheet blocks can only be assigned to tasks, not individual task revisions",
    SCHEMA_FIELD: "settings.timesheet.canAssignTimesheetBlocksToTaskRevisions",
  },
  {
    NAME: "Billable timesheet blocks mandatory requirements",
    DESCRIPTION:
      "If checked, billable timesheet blocks must have a quote line item associated with them or be marked as a variation",
    SCHEMA_FIELD: "settings.timesheet.requireQuoteOrVariationForBillableTimesheetBlocks",
  },
  {
    NAME: "Record GPS coordinates every X minutes",
    SCHEMA_FIELD: "settings.timesheet.recordLatLongEveryXMinutes",
    INPUT_TYPE: "text",
  },
  {
    NAME: "Time off requests can be for less than a day",
    SCHEMA_FIELD: "settings.timesheet.usesPartDayTimeOff",
  },
];

export const TIMELINE_SETTINGS = [
  {
    NAME: "Timeline blocks interact with one another",
    DESCRIPTION:
      "If checked, timeline blocks push each other out of the way, respecting restricted intervals such as holidays and working hours",
    SCHEMA_FIELD: "settings.timeline.usesPhysicalBlockInteraction",
  },
  {
    NAME: "Timeline gaps get filled automatically",
    DESCRIPTION: "If checked, timeline blocks automatically move to the left to fill any gaps",
    SCHEMA_FIELD: "settings.timeline.usesGapFilling",
  },
  {
    NAME: "Timeline blocks have meaningful colors",
    DESCRIPTION:
      "If unchecked, timeline blocks have a random color based on the ID of the task. If checked, timeline blocks will have meaningful colors: green for finished, blue for under review, red for overdue, purple for due on the date, gray for archived.",
    SCHEMA_FIELD: "settings.timeline.usesColoredBlocks",
  },
  {
    NAME: "Display current user at the top of the timeline",
    DESCRIPTION:
      "If checked, the user at the top of the timeline page is always the user who is looking at the page. If unchecked, the order of the users is the same as that on the users page.",
    SCHEMA_FIELD: "settings.timeline.shouldDisplayOwnUserAtTheTop",
  },
  {
    NAME: "Plan task revisions instead of tasks",
    SCHEMA_FIELD: "settings.timeline.planTaskRevisionsInsteadOfTasks",
  },
];

export const INVOICE_SETTINGS = [
  {
    NAME: "2-stage review proces for reviews",
    DESCRIPTION:
      "If checked, an invoice is only considered successfully reviewed if it goes through a 2-stage review process (the second review is usually performed by a director). If unchecked, the review process consists of a single step.",
    SCHEMA_FIELD: "settings.invoice.usesDoubleInvoiceReview",
  },
  {
    NAME: "Use percentage instead of quantity on invoice line items",
    DESCRIPTION:
      "If checked, the app will display a percentage input instead of a quantity input for setting invoice line item amounts.",
    SCHEMA_FIELD: "settings.invoice.usesPercentagesInsteadOfQuantity",
  },
  {
    NAME: "Email address to send invoices from",
    DESCRIPTION: "If not provided, the default one will be used.",
    SCHEMA_FIELD: "emailAddressToSendInvoicesFrom",
    INPUT_TYPE: "text",
  },
  {
    NAME: "Default payment terms (days)",
    SCHEMA_FIELD: "defaultExpectPaymentAfterDays",
    INPUT_TYPE: "text",
    DEFAULT_VALUE: INVOICE_DEFAULT_EXPECT_PAYMENT_AFTER_DAYS,
  },
];

export const PURCHASE_ORDER_SETTINGS = [
  {
    NAME: "Email address to send purchase orders from",
    DESCRIPTION: "If not provided, the default one will be used.",
    SCHEMA_FIELD: "emailAddressToSendPurchaseOrdersFrom",
    INPUT_TYPE: "text",
  },
];

export const FILE_SETTINGS = [
  {
    NAME: 'Hide "Sheet title" input',
    DESCRIPTION: "If checked, the input will not be visible on the file details page",
    SCHEMA_FIELD: "settings.file.hideSheetTitleInput",
  },
  {
    NAME: 'Hide "Include in publish" toggle',
    DESCRIPTION: "If checked, the toggle will not be visible on the file details page",
    SCHEMA_FIELD: "settings.file.hideIncludeInPublishSwitch",
  },
  {
    NAME: "Hide custom reference number inputs",
    DESCRIPTION:
      'If checked, both the "Custom file reference number" and "Custom sheet reference number" inputs will be hidden',
    SCHEMA_FIELD: "settings.file.hideCustomReference",
  },
  {
    NAME: 'Hide "Include in Document Register" toggle',
    DESCRIPTION: "If checked, the toggle will not be visible on the file details page",
    SCHEMA_FIELD: "settings.file.hideIncludeInDocumentRegister",
  },
  {
    NAME: 'Hide "Auto-fill title block" toggle',
    DESCRIPTION: "If checked, the toggle will not be visible on the file details page",
    SCHEMA_FIELD: "settings.file.hideAnnotateSwitch",
  },
  {
    NAME: "Hide Xrefs section",
    DESCRIPTION: "If checked, the Xrefs section will not be visible on the file details page",
    SCHEMA_FIELD: "settings.file.hideXref",
  },
];

export const REQUEST_SETTINGS = [
  {
    NAME: "Task revisions need a request form before sending for review",
    DESCRIPTION: "",
    SCHEMA_FIELD: "settings.task.needsRequestForTaskRevisionReview",
  },
  {
    NAME: "Forms can only be added to 1 task at a time",
    SCHEMA_FIELD: "settings.request.formsCanOnlyBeAddedToOneTask",
  },
  {
    NAME: "After receiving a design package, internal clients can leave a review and request changes",
    SCHEMA_FIELD: "settings.request.usesClientReview",
  },
  {
    NAME: "If a request form is associated with a task revision, display it in the review for that task revision",
    SCHEMA_FIELD: "settings.request.displayRequestFormInTaskReview",
  },
  {
    NAME: "Allow request form PDFs to be downloaded",
    SCHEMA_FIELD: "settings.request.allowRequestFormPdfDownload",
  },
  {
    NAME: "Allow external users to perform reviews",
    SCHEMA_FIELD: "settings.request.allowExternalReviews",
  },
  {
    NAME: "When a task is assigned, also assign the latest request linked to it, if one exists",
    SCHEMA_FIELD: "settings.request.reassigningTaskAlsoReassignsRequest",
  },
];
