import React from "react";
import moment from "moment";
import { Form, Alert, Button, Input, Typography } from "antd";
import { Auth } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import linkApi from "common/link";
import cookie from "js-cookie";
import query from "query-string";

import { loadApiUserAndOrganisationAndSelectedTeams, getCurrentUserId } from "common/authHelpers";

import { PUBLIC_USER_EMAIL } from "common/constants";
import { callGraphQLSimple } from "common/apiHelpers";

import Card from "Card/Card";

import "./SignInPage.scss";

export class SignInPage extends React.Component {
  state = {
    error: null,
    isSubmitting: false,
  };

  componentDidMount() {
    let defaultEmail = null;
    let defaultPassword = null;
    const queryParams = query.parse(this.props.location.search);

    if (queryParams.email) {
      defaultEmail = queryParams.email;
    }

    if (queryParams.password) {
      defaultPassword = queryParams.password;
    }

    if (defaultEmail && defaultPassword) {
      this.onSubmit({
        email: defaultEmail,
        password: defaultPassword,
        isPrefilled: true,
      });
    }
  }

  onSubmit = async ({ email, password, isPrefilled = false }) => {
    email = email.toLowerCase().trim();
    password = password.trim();

    if (email === PUBLIC_USER_EMAIL) {
      alert("You cannot log in with the public user");
      return;
    }

    try {
      this.setState({ isSubmitting: true });
      const result = await Auth.signIn({
        username: email,
        password,
      });

      if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
        if (isPrefilled) {
          window.location.href = `${window.location.protocol}//${window.location.host}/change-password?oldPassword=${password}`;
        } else {
          this.props.history.push(`/change-password`);
        }
      } else {
        cookie.set("refresh-token-expiry", moment().add(29, "day").toISOString());
        linkApi.updateCredentials();

        const userId = await getCurrentUserId();
        await callGraphQLSimple({
          message: "Could not retrieve user details",
          queryName: "getUser",
          variables: {
            id: userId,
          },
        });

        await loadApiUserAndOrganisationAndSelectedTeams();

        await this.props.onSubmit();
        this.props.history.push("/");
      }
    } catch (e) {
      this.setState({ error: e.message, isSubmitting: false });
      console.log("error when signing in:", e);
    }
  };

  render() {
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };

    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    return (
      <div className="sign-in-page">
        <Card>
          <Typography.Title level={4} className="title">
            Sign in
          </Typography.Title>
          <Form {...layout} name="basic" initialValues={{ remember: true }} onFinish={this.onSubmit}>
            <Form.Item label="Email" name="email" rules={[{ required: true, message: "Email cannot be empty" }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Password cannot be empty" }]}
            >
              <Input.Password className="sentry-mask-sensitive" />
            </Form.Item>
            <Typography.Paragraph className="forgot-password">
              Forgot your password? <Link to="/reset-password">Reset password</Link>
            </Typography.Paragraph>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={this.state.isSubmitting}>
                Submit
              </Button>
            </Form.Item>
          </Form>

          {this.state.error ? <Alert message={this.state.error} type="error" showIcon /> : null}
        </Card>
      </div>
    );
  }
}

export default withRouter(SignInPage);
