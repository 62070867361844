import "./FormTextButton.scss";

interface FormTextButtonProps {
  children: any;
  onClick: (e) => void;
}

export default function FormTextButton({ children, onClick }: FormTextButtonProps) {
  return (
    <button
      className="form-text-button"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  );
}
