import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { callRest } from "common/apiHelpers";

function PublicUploadShortUrlPage({ history, match }) {
  useEffect(() => {
    async function getUrlDetails() {
      let response = await callRest({
        route: `/public-upload-url/${match.params.urlId}`,
        method: "GET",
        includeCredentials: false,
      });
      console.log("response:", response);
      history.replace(response);
    }

    getUrlDetails();
  }, []);
  return null;
}

export default withRouter(PublicUploadShortUrlPage);
