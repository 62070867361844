import { CopyLinkIcon } from "common/icons";
import { message, Tooltip } from "antd";
import "./CopyLinkButton.scss";

export default function CopyLinkButton({
  url,
  tooltipContent = "Copy link to this page",
  successMessageContent = "Link copied",
}) {
  return (
    <Tooltip title={tooltipContent} placement="bottom">
      <div
        className="copy-link-button"
        onClick={async () => {
          await navigator.clipboard.writeText(url || window.location.href);
          message.success({
            content: successMessageContent,
          });
        }}
      >
        <CopyLinkIcon />
      </div>
    </Tooltip>
  );
}
