import { useState } from "react";
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  EditOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { Modal, Button, Typography, Divider, Space, message, Row, Col } from "antd";
import { withRouter } from "react-router-dom";

import { isAuthorised } from "common/permissions";
import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";
import { archiveProject } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";

import Card from "Card/Card";
import ChangeClientModal from "Modals/ChangeClientModal/ChangeClientModal";
import TaskIdTag from "TaskIdTag/TaskIdTag";

import "./ProjectActions.scss";

export function ProjectActions({ apiUser, project, history, organisationDetails, tasks, clients }) {
  const [isChangeClientModalVisible, setIsChangeClientModalVisible] = useState(false);

  function confirmDelete() {
    let tasksInProject = tasks.filter((task) => task.projectId === project.id);
    let tasksLinkedToRequests = tasksInProject.filter((task) => task.requestIds?.length > 0);

    if (tasksLinkedToRequests?.length > 0) {
      Modal.error({
        title: `Cannot delete ${getSimpleLabel("project")}`,
        icon: <ExclamationCircleOutlined className="danger" />,
        okText: "I understand",
        content: (
          <>
            At least one of the {getSimpleLabel("tasks")} in this {getSimpleLabel("project")} is linked to a{" "}
            {getSimpleLabel("request")}:
            <ul>
              {tasksLinkedToRequests.map((task) => (
                <li
                  key={task.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(`/tasks/${task.id}`, "_blank");
                  }}
                >
                  <b>
                    <TaskIdTag
                      includeLink={false}
                      task={{
                        ...task,
                        client: clients.find((x) => x.id === task.clientId),
                        project,
                      }}
                    />{" "}
                    {task.title}
                  </b>
                </li>
              ))}
            </ul>
            <br />
            <b>You can however go back and archive the {getSimpleLabel("project")} instead.</b>
          </>
        ),
      });
      return;
    }

    Modal.confirm({
      title: `Delete ${getSimpleLabel("project")}`,
      icon: <ExclamationCircleOutlined className="danger" />,
      content: (
        <>
          Are you sure you want to delete <b>{project.title}</b>?
          <br />
          <Typography.Text className="danger">
            Once deleted, a {getSimpleLabel("project")} is gone forever.
          </Typography.Text>
        </>
      ),
      onOk: async () => {
        await callGraphQLSimple({
          message: `Could not delete ${getSimpleLabel("project")}`,
          queryName: "deleteProject",
          variables: {
            input: {
              id: project.id,
            },
          },
        });

        message.success(
          <Typography.Text>
            <b>{project.title}</b> has been deleted
          </Typography.Text>
        );

        history.push("/projects");
      },
    });
  }

  function confirmArchive() {
    Modal.confirm({
      title: `Archive ${getSimpleLabel("project")}`,
      content: (
        <>
          Are you sure you want to archive <b>{project.title}</b>?
        </>
      ),
      onOk: async () => {
        archiveProject({ projectId: project.id });
      },
    });
  }

  function confirmNotArchive() {
    Modal.confirm({
      title: `Restore ${getSimpleLabel("project")}`,
      icon: <CheckCircleOutlined />,
      content: (
        <>
          Are you sure you want to restore {getSimpleLabel("project")} <b>{project.title}</b>?
        </>
      ),
      onOk: async () => {
        await callGraphQLSimple({
          message: `Failed to restore ${getSimpleLabel("project")}`,
          queryName: "updateProject",
          variables: {
            input: {
              id: project.id,
              isArchived: false,
            },
          },
        });

        message.success(
          <Typography.Text>
            <b>{project.title}</b> has been restored
          </Typography.Text>
        );
      },
    });
  }

  function displayArchiveActions() {
    if (project.isArchived) {
      return (
        <>
          <Row align="middle">
            <Col span={16}>
              <Typography.Paragraph className="main-label">Restore {getSimpleLabel("project")}</Typography.Paragraph>
              <Typography.Paragraph className="description">
                This will put the {getSimpleLabel("project")} back on the "{getSimpleLabel("projects")}" page and people
                will be able to make changes to it again, however it will not automatically restore any of its{" "}
                {getSimpleLabel("tasks")}.
              </Typography.Paragraph>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Button
                icon={<FolderOpenOutlined />}
                onClick={confirmNotArchive}
                type="danger"
                className="mark-as-not-archived"
              >
                Restore
              </Button>
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row align="middle">
            <Col span={16}>
              <Typography.Paragraph className="main-label">
                Archive this {getSimpleLabel("project")}
              </Typography.Paragraph>
              <Typography.Paragraph className="description">
                Mark this {getSimpleLabel("project")} as archived and read-only. It will also automatically archive all
                its containing {getSimpleLabel("tasks")}.
                <br />
                This action can be reversed.
              </Typography.Paragraph>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Button
                disabled={!isAuthorised(["PROJECT.ARCHIVE"])}
                icon={<FolderOpenOutlined />}
                type="danger"
                onClick={confirmArchive}
              >
                Archive {getSimpleLabel("project")}
              </Button>
            </Col>
          </Row>
        </>
      );
    }
  }

  return (
    <Card className="project-actions">
      <Typography.Title level={4}>
        <Space>
          <Typography.Text>{getSimpleLabel("project")} actions</Typography.Text>
        </Space>
      </Typography.Title>
      <Divider />

      {organisationDetails.usesDelete && isAuthorised(["PROJECT.DELETE"]) && (
        <>
          <Row align="middle">
            <Col span={16}>
              <Typography.Paragraph className="main-label">
                Delete this {getSimpleLabel("project")}
              </Typography.Paragraph>
              <Typography.Paragraph className="description">
                Once you delete a {getSimpleLabel("project")}, there is no going back. Please be certain.
              </Typography.Paragraph>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Button icon={<DeleteOutlined />} type="danger" onClick={confirmDelete}>
                Delete {getSimpleLabel("project")}
              </Button>
            </Col>
          </Row>
          <Divider />
        </>
      )}

      {displayArchiveActions()}

      <Divider />

      <>
        <Row align="middle">
          <Col span={16}>
            <Typography.Paragraph className="main-label">Change {getSimpleLabel("project")}</Typography.Paragraph>
            <Typography.Paragraph className="description">
              Change the {getSimpleLabel("project")}
              's current {getSimpleLabel("client")}.
            </Typography.Paragraph>
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Button
              icon={<EditOutlined />}
              type="danger"
              onClick={() => setIsChangeClientModalVisible(true)}
              disabled={!isAuthorised(["PROJECT.CHANGE_CLIENT"])}
            >
              Change {getSimpleLabel("client")}
            </Button>
          </Col>
        </Row>
      </>
      {isChangeClientModalVisible ? (
        <ChangeClientModal onClose={() => setIsChangeClientModalVisible(false)} project={project} />
      ) : null}
    </Card>
  );
}

export default withRouter(
  withSubscriptions({
    Component: ProjectActions,
    subscriptions: ["organisationDetails", "tasks", "clients"],
  })
);
