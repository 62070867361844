import AppPageCard from "CustomApplicationPage/AppPageCard/AppPageCard";
import InfoItem from "InfoItem/InfoItem";

import "./SimpleStat.scss";

export default function ({ label, value, style }) {
  let styleToUse = { ...(style || {}) };
  if (styleToUse.width) {
    styleToUse.width += 32;
  }

  return (
    <div className="simple-stat" style={styleToUse}>
      <AppPageCard>
        <InfoItem label={label} value={value} />
      </AppPageCard>
    </div>
  );
}
