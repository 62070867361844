import { Typography } from "antd";
import cx from "classnames";
import {
  FileImageOutlined,
  FolderOpenOutlined,
  EditOutlined,
  StarOutlined,
  QrcodeOutlined,
  BarChartOutlined,
} from "@ant-design/icons";

import {
  ImageIcon,
  ImageIconStatic,
  SignatureIcon,
  CircleIcon,
  RectangleIcon,
  TextIcon,
  PageIcon,
  UploadIcon,
  PaperclipIcon,
} from "common/icons";

import "./ObjectPlaceholder.scss";

export default function ObjectPlaceholder({ type, object, parentStyle }) {
  let icon = null;
  let text = null;
  switch (type) {
    case "image":
      icon = <FileImageOutlined />;
      text = "Upload static image";
      break;
    case "image_container":
      icon = <FileImageOutlined />;
      text = `${object.custom_name}`;
      break;
    case "signature":
      icon = <SignatureIcon />;
      text = `${object.custom_name}`;
      break;
    case "qr-code":
      icon = <QrcodeOutlined />;
      text = "QR code";
      break;
    case "dynamic_file":
      icon = <PaperclipIcon />;
      text = `${object.custom_name}`;
      break;
    case "component":
      text = `${object.custom_name}`;
      switch (object.custom_componentType) {
        case "simple-stat":
          icon = <EditOutlined />;
          break;

        default:
          icon = <BarChartOutlined />;
          break;
      }
      break;

    default:
      break;
  }

  let size = undefined;

  if (parentStyle) {
    if (parentStyle.width !== undefined || parentStyle.height !== undefined) {
      if (parentStyle.width < 40 || parentStyle.height < 40) {
        size = "very-small";
      } else if (parentStyle.width < 100 || parentStyle.height < 100) {
        size = "small";
      }
    }
  }

  return (
    <div className={cx("object-placeholder", `${type.toLowerCase()}-placeholder`, size)}>
      {icon}
      <Typography.Text className="placeholder-text">{text}</Typography.Text>
    </div>
  );
}
