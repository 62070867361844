import React from "react";

import { ConnectToQuickbooksButton, ConnectToQuickbooksButtonOnHover } from "../QuickbooksSvgAssets";

import "./QuickbooksButton.scss";

export default function QuickbooksButton(props) {
  return (
    <div className="connect-to-quickbooks-button-wrapper" {...props}>
      <ConnectToQuickbooksButton />
      <ConnectToQuickbooksButtonOnHover />
    </div>
  );
}
