import { isAuthorised } from "common/permissions";

export default function getOrderedActiveUsers({ users, organisationDetails, apiUser }) {
  const activeUsers = users && users.filter((user) => !user.isDisabled);
  let orderedActiveUsers = activeUsers && activeUsers.sort((a, b) => (a.order < b.order ? -1 : 1));

  if (organisationDetails?.settings?.timeline?.shouldDisplayOwnUserAtTheTop && apiUser && !apiUser.isHidden) {
    orderedActiveUsers = [apiUser, ...activeUsers.filter((user) => user.id !== apiUser.id)];
  }

  if (organisationDetails?.settings?.general?.usesTeams && orderedActiveUsers) {
    orderedActiveUsers = orderedActiveUsers.filter((user) => {
      if (!user.teams) {
        return true;
      }
      let userHasAtLeastOneOfTheSelectedTeams = (user.teams || []).some((userTeam) =>
        window.selectedTeams?.includes(userTeam)
      );

      return userHasAtLeastOneOfTheSelectedTeams;
    });
  }

  if (organisationDetails?.settings?.general?.requirePermissionToDisplayUsers && orderedActiveUsers) {
    orderedActiveUsers = orderedActiveUsers.filter((crtUser) => {
      return isAuthorised(["USERS.MAKE_USER_VISIBLE"], crtUser);
    });
  }

  // console.log("orderedActiveUsers = ", orderedActiveUsers);

  return orderedActiveUsers;
}
