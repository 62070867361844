import { Switch as AntSwitch } from "antd";
import cx from "classnames";
import "./Switch.scss";

export default function Switch({ label, className, size, ...props }) {
  return (
    <div className={cx("switch", className, size)}>
      {label && <label>{label}</label>}
      <AntSwitch {...props} size={size} />
    </div>
  );
}
