import { Table, Button, Modal, Typography } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";

import InfoItem from "InfoItem/InfoItem";
import Input from "Input/Input";

import "./UserWorkingHours.scss";

export default function UserWorkingHours({ user }) {
  async function addRule() {
    let newRule = {
      id: String(Date.now()) + String(Math.floor(Math.random() * 1000)),
      repeatPattern: "",
      startTime: 0,
      endTime: 0,
    };
    await window.callGraphQLSimple({
      message: "Failed to update working hours",
      mutation: "updateUser",
      variables: {
        input: {
          id: user.id,
          workingHours: [...(user.workingHours || []), newRule],
        },
      },
    });
  }

  async function editRule({ id, fieldName, value }) {
    await window.callGraphQLSimple({
      message: "Failed to update working hours",
      mutation: "updateUser",
      variables: {
        input: {
          id: user.id,
          workingHours: (user.workingHours || []).map((rule) => {
            if (rule.id !== id) {
              return rule;
            }
            return {
              ...rule,
              [fieldName]: value,
            };
          }),
        },
      },
    });
  }

  async function confirmDeleteRule(ruleRow) {
    Modal.confirm({
      title: "Are you sure you want to delete this working hours rule?",
      content: (
        <>
          <Typography.Text>{ruleRow.repeatPattern}</Typography.Text>
        </>
      ),
      onOk: async () => {
        await window.callGraphQLSimple({
          message: "Failed to update working hours",
          mutation: "updateUser",
          variables: {
            input: {
              id: user.id,
              workingHours: (user.workingHours || []).filter((rule) => rule.id !== ruleRow.id),
            },
          },
        });
      },
    });
  }

  const tableColumns = [
    {
      title: "",
      key: "rule",
      render: (_, row) => {
        return (
          <Input
            showBorder
            fullWidth
            defaultValue={row.repeatPattern}
            fireOnChangeWithoutBlurWithDebounce
            debounceDelay={2000}
            data-cy="user-working-hours-input"
            placeholder='e.g. "every weekday" or "every Monday, Tuesday and Friday"'
            onChange={(value) => {
              editRule({ id: row.id, fieldName: "repeatPattern", value });
            }}
          />
        );
      },
    },
    {
      title: "",
      key: "actions",
      render: (row) => {
        return <Button icon={<DeleteOutlined />} onClick={() => confirmDeleteRule(row)} />;
      },
    },
  ];

  const tableRows = user.workingHours;

  return (
    <InfoItem
      className="user-working-hours"
      inline
      label="Working hours"
      value={
        <Button icon={<PlusCircleOutlined />} type="primary" data-cy="add-working-hours-button" onClick={addRule}>
          Add working hours
        </Button>
      }
      extraContent={
        <Table
          rowKey="id"
          columns={tableColumns}
          dataSource={tableRows}
          pagination={{ hideOnSinglePage: true, pageSize: 50 }}
          showHeader={false}
        />
      }
    />
  );
}
