import { useEffect, useRef } from "react";
import { detect } from "detect-browser";

import { callGraphQLSimple } from "common/apiHelpers";

import OnlineStatusRecorder from "OnlineStatusRecorder/OnlineStatusRecorder";

export default function Analytics({ apiUser, organisationDetails }) {
  const locationRecordingIntervalRef = useRef(null);

  // const location = useLocation();

  const browser = useRef(detect());

  useEffect(() => {
    setUpLocationRecording();

    return () => {
      if (locationRecordingIntervalRef.current) {
        clearInterval(locationRecordingIntervalRef.current);
      }
    };
  }, []);

  function setUpLocationRecording() {
    if (!organisationDetails?.settings?.timesheet?.recordLatLongEveryXMinutes) {
      return;
    }

    if (isNaN(organisationDetails?.settings?.timesheet?.recordLatLongEveryXMinutes)) {
      return;
    }

    recordLocationCoordinates();

    locationRecordingIntervalRef.current = setInterval(
      recordLocationCoordinates,
      organisationDetails?.settings?.timesheet?.recordLatLongEveryXMinutes * 60 * 1000
    );
  }

  function recordLocationCoordinates() {
    if (!navigator.geolocation) {
      return;
    }
    navigator.geolocation.getCurrentPosition(
      async function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        await callGraphQLSimple({
          displayError: false,
          mutation: "createAuditItem",
          variables: {
            input: {
              taskId: "nothing",
              projectId: "nothing",
              fileId: "nothing",
              userId: apiUser?.id,
              clientId: "nothing",
              content: JSON.stringify({
                latitude,
                longitude,
              }),
              type: "LOCATION",
              organisation: apiUser?.organisation,
            },
          },
        });
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  }

  // useEffect(() => {
  //   window.addEventListener("click", onClick);

  //   return () => {
  //     window.removeEventListener("click", onClick);
  //   };
  // }, []); //eslint-disable-line

  // useEffect(() => {
  //   recordEvent("PAGE_VIEW", {});
  // }, [location.pathname]); //eslint-disable-line

  function recordUserOnlineStatus() {
    recordEvent("ONLINE");
  }

  // function onClick(e) {
  //   const clickEventData = getClickEventData(e);

  //   recordEvent("CLICK", clickEventData);
  // }

  function recordEvent(eventType, eventData = {}) {
    if (window.location.href.endsWith("/analytics?tab=audit")) {
      return;
    }

    const extraEventData = getExtraEventData();
    const input = {
      organisation: apiUser?.organisation || "PUBLIC",
      parentId: "APP",
      userId: apiUser?.id || "PUBLIC",
      parentType: "APP",
      eventType,
      data: JSON.stringify({
        ...eventData,
        ...extraEventData,
      }),
    };

    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAnalytics",
      variables: {
        input,
      },
    });
  }

  function getExtraEventData() {
    let extraEventData = {
      location: {
        pathname: window.location.pathname,
        search: window.location.search,
      },
      browser: browser.current,
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };

    return extraEventData;
  }

  // function getClickEventData(e) {
  //   const eventData = {};
  //   const parents = getParentList(e.target, [e.target]);

  //   if (e.target.textContent) {
  //     eventData.element = e.target.textContent;
  //     eventData.textContent = e.target.textContent;
  //   }

  //   const nonAntDesignClasses = parents.map((x) => getUsefulClassNames({ className: x.className })).filter((x) => x);
  //   eventData.classList = nonAntDesignClasses;
  //   eventData.dataAttributes = parents
  //     .map((x) => {
  //       let dataAttributes = [];
  //       for (let attribute in x.dataset) {
  //         dataAttributes.push(x.dataset[attribute]);
  //       }
  //       return dataAttributes;
  //     })
  //     .filter((x) => x.length > 0)
  //     .join(" ");
  //   const firstButtonInTree = parents.find((x) => x.tagName === "BUTTON");

  //   if (firstButtonInTree) {
  //     eventData.targetType = "BUTTON";
  //     eventData.element = firstButtonInTree.textContent;
  //     return eventData;
  //   }

  //   const firstDropdownInTree = parents.find((x) => x.classList.contains("ant-select"));
  //   if (firstDropdownInTree) {
  //     eventData.targetType = "DROPDOWN";
  //     eventData.element = getUsefulClassNames({
  //       className: firstDropdownInTree.className,
  //     });
  //     return eventData;
  //   }

  //   const firstFileInputInTree = parents.find((x) => x.tagName === "INPUT" && x.type === "file");
  //   if (firstFileInputInTree) {
  //     eventData.targetType = `INPUT-FILE`;

  //     const firstLabelInTree = parents.find((x) => x.tagName === "LABEL");
  //     if (firstLabelInTree) {
  //       eventData.element = firstLabelInTree.textContent;
  //     } else {
  //       eventData.element = [
  //         ...getUsefulClassNames({ className: firstFileInputInTree.className }),
  //         firstFileInputInTree.id,
  //       ]
  //         .filter((x) => x)
  //         .join(" ");
  //     }
  //     return eventData;
  //   }

  //   const firstInputInTree = parents.find((x) => x.classList.contains("input"));
  //   if (firstInputInTree) {
  //     eventData.targetType = `INPUT`;
  //     if (firstInputInTree.placeholder) {
  //       eventData.placeholder = firstInputInTree.placeholder;
  //     }
  //     const firstLabelInTree = parents.find((x) => x.tagName === "LABEL");
  //     if (firstLabelInTree) {
  //       eventData.element = firstLabelInTree.textContent;
  //     } else {
  //       eventData.element = [...getUsefulClassNames({ className: firstInputInTree.className }), firstInputInTree.id]
  //         .filter((x) => x)
  //         .join(" ");
  //     }
  //     return eventData;
  //   }

  //   const firstTextareaInTree = parents.find((x) => x.tagName === "TEXTAREA");
  //   if (firstTextareaInTree) {
  //     eventData.targetType = `INPUT-${firstTextareaInTree.type.toUpperCase()}`;
  //     if (firstTextareaInTree.placeholder) {
  //       eventData.placeholder = firstTextareaInTree.placeholder;
  //     }

  //     eventData.element = [...getUsefulClassNames({ className: firstTextareaInTree.className }), firstTextareaInTree.id]
  //       .filter((x) => x)
  //       .join(" ");

  //     return eventData;
  //   }

  //   const firstCardInTree = parents.find((x) => x.className && x.className.includes && x.className?.includes("card"));
  //   if (firstCardInTree) {
  //     const cardTitle = firstCardInTree.dataset.title;
  //     if (typeof cardTitle === "string") {
  //       eventData.card = cardTitle;
  //     }
  //     eventData.targetType = "CARD";
  //     return eventData;
  //   }

  //   return eventData;
  // }

  function getUsefulClassNames({ className, toIgnore = [] }) {
    if (!className || typeof className.split !== "function") {
      return "";
    }
    toIgnore = [...toIgnore, "active-on-hover"];
    return className
      .split(" ")
      .filter((x) => {
        return !x.startsWith("ant-") && !x.startsWith("css-dev-only-") && !toIgnore.includes(x);
      })
      .join(" ");
  }

  function getParentList(element, parents = []) {
    // debugger;
    if (!element.parentElement) {
      return parents;
    }
    return getParentList(element.parentElement, [...parents, element.parentElement]);
  }

  return (
    <>{apiUser && <OnlineStatusRecorder apiUser={apiUser} recordAnalyticsEvent={() => recordUserOnlineStatus()} />}</>
  );
}
