import { memo, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Column } from "@ant-design/charts";
import { Typography } from "antd";

import withSubscriptions from "common/withSubscriptions";
import DatePicker from "DatePicker/DatePicker";
import { COLORS } from "common/constants";
import { getLabel } from "common/helpers";

import Card from "Card/Card";

import moment from "moment";

import "./FinancialsChart.scss";

function FinancialsChart(props) {
  const { organisationDetails, quotes, invoices } = props;
  const [quotesChartData, setQuotesChartData] = useState([]);
  const [invoicesChartData, setInvoicesChartData] = useState([]);
  const [startDate, setStartDate] = useState(moment(organisationDetails.createdAt));
  const [endDate, setEndDate] = useState(moment().endOf("day"));

  useEffect(() => {
    loadChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  function processFinancialData(records, type) {
    let processedMonthlyRecords = [];

    const validRecords = records.filter((record) => {
      if (type === "Invoices" && record.status === "SENT") {
        return (
          moment(record.invoiceDate || record.createdAt).isSameOrAfter(startDate) &&
          moment(record.invoiceDate || record.createdAt).isSameOrBefore(endDate)
        );
      }

      if (type === "Quotes" && record.status === "ACCEPTED") {
        return moment(record.createdAt).isSameOrAfter(startDate) && moment(record.createdAt).isSameOrBefore(endDate);
      }

      return false;
    });

    for (const record of validRecords) {
      const month = `${moment(record.invoiceDate || record.createdAt).format("MMMM")} ${moment(
        record.invoiceDate || record.createdAt
      ).format("YYYY")}`;
      const currentMonthRecord = processedMonthlyRecords.find((item) => item.month === month);

      if (!currentMonthRecord) {
        processedMonthlyRecords.push({
          month,
          type,
          date: `${moment(record.invoiceDate || record.createdAt).format("YYYY/MM")} ${moment(
            record.invoiceDate || record.createdAt
          ).format("YYYY")}`,
          value: record.subtotal,
        });
      } else {
        const updatedRecord = {
          ...currentMonthRecord,
          value: currentMonthRecord.value + record.subtotal,
        };

        const updatedMonthlyRecords = processedMonthlyRecords.map((record) => {
          if (record.month === month) {
            return updatedRecord;
          }

          return record;
        });

        processedMonthlyRecords = updatedMonthlyRecords;
      }
    }

    return [...processedMonthlyRecords].sort((a, b) => {
      return a.date < b.date ? -1 : 1;
    });
  }

  function loadChartData() {
    const quotesData = processFinancialData(quotes, "Quotes");
    const invoicesData = processFinancialData(invoices, "Invoices");

    setQuotesChartData(quotesData);
    setInvoicesChartData(invoicesData);
  }

  function handleDateChange(value) {
    if (value) {
      const [start, end] = value;

      const startDate = moment(start).startOf("day");
      const endDate = moment(end).endOf("day");

      setStartDate(startDate);
      setEndDate(endDate);
    }
  }

  const chartConfig = {
    xField: "month",
    yField: "value",
    seriesField: "type",
    legend: false,
    color: [COLORS[2]],
    animation: false,
    tooltip: {
      customContent: (title, items) => {
        return (
          <div className="chart-simple-tooltip">
            <Typography.Text>{title} </Typography.Text>
            {items[0] && <Typography.Text>{window.formatCurrency("GBP", parseFloat(items[0].value))}</Typography.Text>}
          </div>
        );
      },
    },
    yAxis: {
      label: {
        formatter: (value) => window.formatCurrency("GBP", parseFloat(value)),
      },
    },
  };

  return (
    <>
      <Card
        withSpace
        title={`Financials for ${organisationDetails.id}`}
        className="analytics-financials-chart-card"
        actions={
          <div className="actions">
            <DatePicker.RangePicker
              className="financials-action"
              dropdownClassName="date-range-picker"
              defaultValue={[startDate, endDate]}
              onChange={handleDateChange}
              ranges={{
                "Last month": [moment().subtract(1, "months"), moment()],
                "Last 6 months": [moment().subtract(6, "months"), moment()],
                "Last 12 months": [moment().subtract(12, "months"), moment()],
                "Since the beginning": [moment(organisationDetails.createdAt), moment()],
              }}
            />
          </div>
        }
      >
        <div className="charts-container">
          <div className="chart-wrapper">
            <div className="chart-title">
              Accepted{" "}
              {getLabel({
                id: "quotes",
                defaultValue: "quotes",
              })}{" "}
              by creation date
            </div>
            <Column {...chartConfig} data={quotesChartData} color={COLORS[2]} className="chart" />
          </div>
          <div className="chart-wrapper">
            <div className="chart-title">Sent invoices by invoice date</div>
            <Column {...chartConfig} data={invoicesChartData} color={COLORS[0]} className="chart" />
          </div>
        </div>
      </Card>
    </>
  );
}

export default memo(
  withRouter(
    withSubscriptions({
      Component: FinancialsChart,
      subscriptions: ["organisationDetails", "quotes", "invoices"],
    })
  ),
  () => true // never re-render based on props
);
