import { useState } from "react";
import { Form, Modal, Button, Input, message, Typography } from "antd";

import axios from "axios";
import { Task, User } from "common/types";

import "./ContactModal.scss";

type Props = {
  onClose?: any;
  task?: Task;
  user?: User;
  authorityType?: any;
  users?: User[];
  visible?: any;
};

export default function ContactModal({ onClose }: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({
    firstName,
    lastName,
    companyName,
    phoneNumber,
    emailAddress,
    messageBody,
    referralSource,
    referredBy,
  }) {
    setIsLoading(true);

    const requestBody = `
    DraughtHub contact form message:
    First Name: ${firstName}
    Last Name: ${lastName}
    Email: ${emailAddress}
    Company Name: ${companyName || ""}
    Phone Number: ${phoneNumber || ""}
    Referral Source: ${referralSource || ""}
    Referred By: ${referredBy || ""}
    Message: ${messageBody || ""}
    `;

    try {
      await axios({
        method: "POST",
        url: "https://xfngqz4xu3.execute-api.eu-west-2.amazonaws.com/dev/contact",
        data: [requestBody],
      });
    } catch (e) {
      // nothing we can do for now - it always throws an error, despite working properly
    }

    message.success(
      <Typography.Text>
        <b>Your message has been sent</b>
      </Typography.Text>
    );
    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={<b>Get in touch</b>}
      visible={true}
      onCancel={onClose}
      footer={null}
      className="contact-modal"
    >
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item
          label="First name"
          name="firstName"
          className="first-name"
          rules={[
            {
              required: true,
              message: "First name is mandatory",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Last name"
          name="lastName"
          className="last-name"
          rules={[
            {
              required: true,
              message: "Last name is mandatory",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email address"
          name="emailAddress"
          className="email-address"
          rules={[{ required: true, message: "Email address is mandatory" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Company name" name="companyName" className="company-name">
          <Input />
        </Form.Item>

        <Form.Item label="Phone number" name="phoneNumber" className="phone-number">
          <Input />
        </Form.Item>

        <Form.Item label="Referred by" name="referredBy" className="referred-by">
          <Input />
        </Form.Item>

        <Form.Item label="How did you hear about us" name="referralSource" className="referral-source">
          <Input />
        </Form.Item>

        <Form.Item
          label="Message"
          name="messageBody"
          className="message-body"
          // rules={[{ required: true, message: "Message is mandatory" }]}
        >
          <Input.TextArea autoSize={{ minRows: 4 }} />
        </Form.Item>

        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}
