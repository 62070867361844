import { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import { Typography, Progress, message } from "antd";

import { getSimpleLabel } from "common/labels";
import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";

import Card from "Card/Card";
import DashboardItemTags from "DashboardItemTags/DashboardItemTags";
import CatLevelModal from "Modals/CatLevelModal/CatLevelModal";
import Input from "Input/Input";
import TaskDescriptionOld from "./TaskDescriptionOld";
import TaskDescription from "./TaskDescription";

import "./TaskSummary.scss";

export function TaskSummary({ task, users, organisationDetails }) {
  const [isCatLevelModalVisible, setIsCatLevelModalVisible] = useState(false);
  let initialDescriptionNewEditor = useRef();
  let useNewEditor = useRef();

  if (useNewEditor.current === undefined) {
    let initialDescriptionNewEditorTemp;
    let useNewEditorTemp = true;
    try {
      if (task.subtitle && task.subtitle !== "") {
        initialDescriptionNewEditorTemp = JSON.parse(task.subtitle);
      }
    } catch (e) {
      useNewEditorTemp = false;
    }

    useNewEditor.current = useNewEditorTemp;
    initialDescriptionNewEditor.current = initialDescriptionNewEditorTemp;
  }

  async function changeTaskInitials(initials) {
    if (!initials) {
      message.error(`${getSimpleLabel("Task")} initials cannot be empty`);
      return;
    }

    await callGraphQLSimple({
      message: "Failed to update initials",
      queryCustom: "updateTask",
      variables: {
        input: {
          id: task.id,
          initials,
        },
      },
    });
  }

  async function changeTitle(title) {
    if (task.title === title) {
      return;
    }

    if (!title) {
      message.error(`${getSimpleLabel("Task")} title cannot be empty`);
      return;
    }

    await callGraphQLSimple({
      message: "Failed to update title",
      queryName: "updateTask",
      variables: {
        input: {
          id: task.id,
          title,
          titleLowerCase: title.toLowerCase(),
        },
      },
    });

    await callGraphQLSimple({
      mutation: "createTaskActivityItem",
      message: `Failed to record ${getSimpleLabel("task")} activity item`,
      variables: {
        input: {
          taskId: task.id,
          author: window.apiUser.id,
          organisation: task.organisation,
          type: "TITLE_EDITED",
          content: JSON.stringify({
            newTitle: title,
          }),
        },
      },
    });
  }

  let activeStatusClassName = null;
  if (task.isArchived) {
    activeStatusClassName = "archived";
  } else if (task.isFinished) {
    activeStatusClassName = "finished";
  }

  const progress = task.subtaskProgress;
  let progressBarColor = "#19aae8";
  if (progress >= 100) {
    progressBarColor = "#44bd32";
  }

  return (
    <>
      <Card
        data-cy="task-details-summary"
        className={cx("task-summary", activeStatusClassName)}
        title={
          <>
            <div className="title-and-initials">
              {organisationDetails.usesTaskInitials && (
                <Input
                  className="task-initials"
                  defaultValue={task.initials}
                  allowEnter={false}
                  onChange={changeTaskInitials}
                  showBorder={!task.initials}
                  placeholder={task.initials ? "" : "Initials"}
                />
              )}
              <Input
                data-cy="task-details-title"
                defaultValue={task.title}
                className="task-title"
                onChange={changeTitle}
                flexGrow
                disabled={task.isArchived || task.isFinished}
                multiLine
                allowEnter={false}
              />
            </div>

            <Typography.Paragraph className="task-description-label">
              {getSimpleLabel("Task description")}:
            </Typography.Paragraph>

            {useNewEditor.current === false ? (
              <TaskDescriptionOld task={task} />
            ) : (
              <TaskDescription task={task} defaultValue={initialDescriptionNewEditor.current} />
            )}

            <DashboardItemTags item={task} />
            {progress ? (
              <div className="subtasks-progress">
                <Typography.Text className="label">Progress</Typography.Text>
                <Progress
                  percent={progress}
                  strokeColor={progressBarColor}
                  data-cy="subtasks-progress-bar"
                  data-progress={progress}
                />
              </div>
            ) : null}
          </>
        }
      />

      <CatLevelModal
        key={`cat-level-modal-${task.catLevel}`}
        visible={isCatLevelModalVisible}
        onClose={() => setIsCatLevelModalVisible(false)}
        task={task}
        users={users}
        organisationDetails={organisationDetails}
      />
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TaskSummary,
    subscriptions: ["organisationDetails"],
  })
);
