import { Auth } from "aws-amplify";
import { message } from "antd";
import cookie from "js-cookie";

import { callGraphQLSimple } from "./apiHelpers";
// import * as Sentry from "@sentry/browser";

import { COOKIE_NAME_SELECTED_TEAMS } from "common/constants";

export async function loadApiUserAndOrganisationAndSelectedTeams() {
  let user;
  // debugger;
  try {
    user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
  } catch (e) {
    // nothing we can do, it means the user is not authenticated
  }
  if (user) {
    const userEmail = user?.signInUserSession?.idToken?.payload?.email;
    let organisation;
    if (user.attributes) {
      organisation = user.attributes.address;
    } else {
      organisation = user.signInUserSession.idToken.payload.organisation;
    }

    const [apiUserResponse, organisationDetailsResponse] = await Promise.all([
      callGraphQLSimple({
        message: "Failed to load the details for the current user",
        query: "getUser",
        variables: { id: userEmail },
      }),
      callGraphQLSimple({
        message: "Failed to load organisation details",
        queryCustom: "getOrganisation",
        variables: { id: organisation },
      }),
    ]);
    const apiUser = apiUserResponse.data.getUser;
    const organisationDetails = organisationDetailsResponse.data.getOrganisation;

    window.apiUser = apiUser;
    // Sentry.setUser({ id: apiUser.id });
    window.organisationDetails = organisationDetails;
  } else {
    window.apiUser = null;
    // Sentry.setUser(null);
  }

  const selectedTeamsInCookie = cookie.get(COOKIE_NAME_SELECTED_TEAMS);
  if (selectedTeamsInCookie) {
    window.selectedTeams = JSON.parse(selectedTeamsInCookie);
  } else {
    window.selectedTeams = [];
  }
}

export function signOutSpecificUser({ cognitoUsername, cognitoIdentityKey, user }) {
  let organisationId = user?.userDetails?.organisation;
  try {
    window.localDatabase.removeItem(`rootData-${organisationId}`);
    for (let key in localStorage) {
      if (key.startsWith(`${cognitoIdentityKey}.${cognitoUsername}`)) {
        localStorage.removeItem(key);
      }
    }
    if (window.listLoggedInUsers && typeof window.listLoggedInUsers === "function") {
      window.listLoggedInUsers();
    }
    message.success("User signed out");
  } catch (error) {
    message.error("Failed to sign out user");
    console.error("error signing out: ", error);
  }
}

export function signOutAllUsers({ cognitoIdentityKey }) {
  message.loading("Signing out all users...");
  try {
    for (let key in localStorage) {
      if (key.startsWith(cognitoIdentityKey)) {
        localStorage.removeItem(key);
      }
    }
    localStorage.removeItem("DraughtHub.cognitoIdentityKey");
    window.indexedDB.deleteDatabase("draughthub-settings");

    window.location.href = window.location.origin;
  } catch (error) {
    message.error("Failed to sign out all users");
    console.error("error signing out: ", error);
  }
}

export async function switchToUser({ cognitoUsername, cognitoIdentityKey }) {
  localStorage.setItem(`${cognitoIdentityKey}.LastAuthUser`, cognitoUsername);
  await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });
}

export function getLoggedInUsernames({ cognitoIdentityKey }) {
  const loggedInUsersWithDuplicates = [];
  for (let key in localStorage) {
    if (
      key.startsWith(cognitoIdentityKey) &&
      !key.includes("LastAuthUser") &&
      !key.includes("DraughtHub.cognitoIdentityKey")
    ) {
      let keyParts = key.split(".");
      let username = keyParts.slice(2).slice(0, -1).join(".");
      loggedInUsersWithDuplicates.push(username);
    }
  }
  const loggedInUsers = [...new Set(loggedInUsersWithDuplicates)];

  return loggedInUsers;
}

export async function checkForAlternateUsersAndSwitchToFirstOne() {
  const cognitoIdentityKey = localStorage.getItem("DraughtHub.cognitoIdentityKey");
  let thereAreAlternateUsers = false;

  if (cognitoIdentityKey) {
    let loggedInUsernames = getLoggedInUsernames({ cognitoIdentityKey });

    if (loggedInUsernames.length > 0) {
      while (loggedInUsernames.length > 0) {
        const firstUsername = loggedInUsernames[0];

        try {
          await switchToUser({ cognitoUsername: firstUsername, cognitoIdentityKey });
          const organisationId = await getCurrentUserOrganisationId();

          if (organisationId !== "PUBLIC") {
            thereAreAlternateUsers = true;
          }
          break;
        } catch (e) {
          console.log("failed to switch to user:", firstUsername);
          console.error(e);
          signOutSpecificUser({ cognitoUsername: firstUsername, cognitoIdentityKey });
        }
        await new Promise((resolve) => setTimeout(resolve, 500));
        loggedInUsernames = getLoggedInUsernames({ cognitoIdentityKey });
      }
    }
  }
  if (thereAreAlternateUsers) {
    return true;
  } else {
    return false;
  }
}

export async function getCurrentSession() {
  let session;
  let tryCount = 0;
  while (!session && tryCount < 5) {
    tryCount++;
    try {
      session = await Auth.currentSession();
    } catch (e) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  }
  return session;
}

export async function getCurrentUserOrganisationId(user) {
  let organisationId;
  user =
    user ||
    (await Auth.currentAuthenticatedUser({
      bypassCache: true,
    }));

  if (!user) {
    throw new Error("Cannot get organisation ID of current user: no current user");
  }

  if (user.attributes) {
    organisationId = user.attributes.address;
  } else if (user.signInUserSession) {
    organisationId = user.signInUserSession.idToken.payload.organisation;
  }

  if (!organisationId) {
    throw new Error("Cannot get organisation ID of current user: no organisation ID");
  }

  return organisationId;
}

export async function getCurrentUserId() {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });

  if (!user) {
    throw new Error("Cannot get ID of current user: no current user");
  }

  return user.signInUserSession.idToken.payload.email;
}

export async function isExternalUser() {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });

  if (user.signInUserSession.idToken.payload.identities) {
    return true;
  } else {
    return false;
  }
}

export async function getProviderNameForCurrentUser() {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });

  if (user.signInUserSession.idToken.payload.identities) {
    return user.signInUserSession.idToken.payload.identities[0].providerName;
  } else {
    return null;
  }
}
