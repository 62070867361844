import React from "react";
import _ from "lodash";

import LiveReviewComment from "LiveReviewComment/LiveReviewComment";

import "./DocumentReview.scss";

const NEW_COMMENT_ID = "new-comment";
const MAX_TRIES = 20;

export default class DocumentReview extends React.Component {
  state = {
    isPositionCalculationFinished: false,
    elementPositions: null,
    updateNumber: 0,
    areCommentsAnimated: false,
  };
  containerRef = React.createRef();
  calculatePositionsInterval = null;

  constructor(props) {
    super(props);
    this.throttledCalculatePositions = _.throttle(this.calculatePositions, 300);
  }

  componentDidMount() {
    this.calculatePositions();

    this.calculatePositionsInterval = setInterval(() => {
      this.calculatePositions();
    }, 5000);
    let userInputContainer = document.querySelector(".user-input-container");
    if (userInputContainer) {
      userInputContainer.addEventListener("scroll", this.throttledCalculatePositions);
    }
  }

  componentWillUnmount() {
    if (this.calculatePositionsInterval) {
      clearInterval(this.calculatePositionsInterval);
    }

    let userInputContainer = document.querySelector(".user-input-container");
    if (userInputContainer) {
      userInputContainer.removeEventListener("scroll", this.throttledCalculatePositions);
    }
  }

  componentDidUpdate(_, nextState) {
    if (this.state.updateNumber === nextState.updateNumber) {
      this.calculatePositions();
    }
  }

  calculatePositions = async (tryCount) => {
    tryCount = tryCount || 0;

    const containerElement = this.containerRef.current;
    if (!containerElement) {
      setTimeout(this.calculatePositions, 100);
      return;
    }

    const commentElements = containerElement.querySelectorAll(".live-review-comment");
    let elementPositions = [];
    for (let i = 0; i < commentElements.length; i++) {
      const commentElement = commentElements[i];
      if (commentElement.dataset.id === NEW_COMMENT_ID) {
        continue;
      }
      const desiredTop = commentElement.dataset.top;
      const height = commentElement.clientHeight;
      elementPositions.push({
        id: commentElement.dataset.id,
        desiredTop: parseFloat(desiredTop),
        height: parseFloat(height),
        offsetTop: 0,
      });
    }

    if (elementPositions.length === 0) {
      if (tryCount < MAX_TRIES) {
        setTimeout(() => this.calculatePositions(tryCount + 1), 100);
      }
      return;
    }

    if (elementPositions.length > 1) {
      while (true) {
        elementPositions = elementPositions.sort((a, b) =>
          a.desiredTop + a.offsetTop > b.desiredTop + b.offsetTop ? 1 : -1
        );
        let weHaveOverlaps = false;
        for (let i = 0; i < elementPositions.length - 1; i++) {
          const element = elementPositions[i];

          const nextElement = elementPositions[i + 1];
          const distance = nextElement.desiredTop + nextElement.offsetTop - (element.desiredTop + element.offsetTop);
          const overlap = Math.max(0, element.height + 8 - distance);
          if (overlap > 0) {
            nextElement.offsetTop += overlap;
            weHaveOverlaps = true;
          }
        }

        if (!weHaveOverlaps) {
          break;
        }
      }
    }

    this.setState({ elementPositions, updateNumber: this.state.updateNumber + 1 }, () => {
      if (!this.state.areCommentsAnimated) {
        setTimeout(() => {
          this.setState({ areCommentsAnimated: true, isPositionCalculationFinished: true });
        }, 100);
      }
    });
  };

  displayComments = () => {
    const { isPositionCalculationFinished, elementPositions, areCommentsAnimated } = this.state;
    const { parent, parentType, hasNewComment, newCommentFieldName, newCommentLineItemIndex, onNewCommentClose } =
      this.props;
    const review = parent.reviews.items[0];
    let elements = review.reviewThread
      .filter((item) => item.type === "COMMENT" && !item.resolved)
      .map((comment) => {
        return (
          <LiveReviewComment
            visible={isPositionCalculationFinished}
            key={comment.id}
            {...this.props}
            {...comment}
            parent={undefined}
            pageId={parent.id}
            pageType={parentType}
            content={comment.content}
            fieldName={comment.sheetId}
            lineItemIndex={comment.sheetLabel ? parseInt(comment.sheetLabel) : undefined}
            review={review}
            offsetTop={elementPositions && elementPositions.find((element) => element.id === comment.id)?.offsetTop}
            calculatePositions={this.calculatePositions}
            isAnimated={areCommentsAnimated}
            isDisabled={!parent.isUnderReview}
          />
        );
      });

    if (hasNewComment) {
      elements.push(
        <LiveReviewComment
          visible={isPositionCalculationFinished}
          key={NEW_COMMENT_ID}
          {...this.props}
          parent={undefined}
          pageId={parent.id}
          pageType={parentType}
          id={NEW_COMMENT_ID}
          content=""
          fieldName={newCommentFieldName}
          lineItemIndex={newCommentLineItemIndex}
          isNew
          onClose={onNewCommentClose}
          review={review}
          offsetTop={elementPositions && elementPositions.find((element) => element.id === "new-comment")?.offsetTop}
          calculatePositions={this.calculatePositions}
          isAnimated={areCommentsAnimated}
          isDisabled={!parent.isUnderReview}
        />
      );
    }
    return elements;
  };
  render() {
    return (
      <div className="document-review" ref={this.containerRef}>
        <div className="document-review-inner-container">{this.displayComments()}</div>
      </div>
    );
  }
}
