import cookie from "js-cookie";

import { COOKIE_NAME_TEMPLATE_EDITOR_DISPLAY_PADDING_LIMIT } from "common/constants";

import "./PagePaddingLimit.scss";

export default function PagePaddingLimit({ pagePadding }) {
  let shouldDisplayPagePaddingLimit = cookie.get(COOKIE_NAME_TEMPLATE_EDITOR_DISPLAY_PADDING_LIMIT) !== "false";

  if (!shouldDisplayPagePaddingLimit) {
    return null;
  }

  return (
    <div
      className="page-padding-limit-container"
      style={{
        paddingTop: pagePadding.top,
        paddingBottom: pagePadding.bottom,
        paddingLeft: pagePadding.left,
        paddingRight: pagePadding.right,
      }}
    >
      <div className="page-padding-limit" />
    </div>
  );
}
