import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";

export async function createStockItemInApi(params) {
  const { name, description, organisation, type, customFields, displayOnTimeline, maintenanceIntervals, order } =
    params;
  let response = await callGraphQLSimple({
    message: `Failed to create ${getSimpleLabel("stock item")}`,
    mutation: "createStockItem",
    variables: {
      input: {
        name,
        description,
        organisation,
        type,
        customFields,
        displayOnTimeline,
        maintenanceIntervals,
        order,
      },
    },
  });

  return response.data.createStockItem;
}
