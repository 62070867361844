import Card from "../../Card/Card";
import withSubscriptions from "common/withSubscriptions";

import InfoItem from "InfoItem/InfoItem";
import BudgetBar from "BudgetBar/BudgetBar";

import "./TaskBudget.scss";

export function TaskBudget({ task, organisationDetails }) {
  function areAllQuoteLineItemsHourly({ task }) {
    let allQuoteLineItemsAreHourly = false;

    if (task.quoteLineItems.items.length > 0) {
      allQuoteLineItemsAreHourly = task.quoteLineItems.items.every((lineItem) => lineItem.isHourly);
    }

    return allQuoteLineItemsAreHourly;
  }

  let quotesInHours = organisationDetails.settings?.quote?.quotesInHours;

  let spent;
  let budget;
  if (quotesInHours) {
    spent = task.hoursSpent;
    budget = task.hoursBudget;
  } else {
    spent = task.amountSpent;
    budget = task.amountBudget;
  }

  if (!budget) {
    budget = 0;
  }

  if (!spent) {
    spent = 0;
  }

  let allQuoteLineItemsAreHourly = areAllQuoteLineItemsHourly({
    task,
  });

  let formattedSpent = quotesInHours ? `${spent} hour${spent === 1 ? "" : "s"}` : window.formatCurrency("GBP", spent);

  let formattedBudget = quotesInHours
    ? `${budget} hour${budget === 1 ? "" : "s"}`
    : window.formatCurrency("GBP", budget);

  return (
    <>
      <Card title="Budget" className="task-budget-card">
        <div className="info-items">
          <div className="info-items-column">
            <InfoItem label="Budget" value={formattedBudget} />
            <InfoItem
              label="Budget used %"
              value={
                <BudgetBar
                  hideFinancials={quotesInHours}
                  size="large"
                  amountSpent={spent}
                  amountBudget={budget}
                  replacementLabel={allQuoteLineItemsAreHourly ? `Hourly rate - ${formattedSpent} used` : undefined}
                />
              }
            />
          </div>
        </div>
      </Card>
    </>
  );
}

export default withSubscriptions({
  Component: TaskBudget,
  subscriptions: ["organisationDetails", "clients", "projects", "quotes", "apiUser"],
});
