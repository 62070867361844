import React from "react";
import _ from "lodash";

import { isVisibleOnScreen, findScrollableParent } from "common/helpers";

import "./LazyLoadList.scss";

const SUBSEQUENT_CHUNK_SIZE = 20;
const INITIAL_CHUNK_SIZE = 13;
const OVERSHOOT_SIZE = 5;

export class LazyLoadList extends React.Component {
  state = {
    itemsLoadedCount: INITIAL_CHUNK_SIZE,
  };

  constructor(props) {
    super(props);
    this.scrollMarkerId = `${Math.random()}-${Date.now()}`;
    this.containerRef = React.createRef();

    this.throttledCheckScroll = _.throttle(this.checkScroll, 500, {
      leading: false,
      trailing: true,
    });
    this.firstParentWithScroll = undefined;
  }

  componentDidMount() {
    if (typeof this.props.initialChunkSize === "number") {
      this.setState({
        itemsLoadedCount: this.props.initialChunkSize,
      });
    }

    this.addScrollListener();
  }

  addScrollListener = () => {
    if (!this.containerRef.current) {
      setTimeout(this.addScrollListener, 100);
      return;
    }

    this.firstParentWithScroll = findScrollableParent(this.containerRef.current);
    if (!this.firstParentWithScroll) {
      console.error("No scrollable parent found for LazyLoadList");
      return;
    }
    this.firstParentWithScroll.addEventListener("scroll", this.throttledCheckScroll);
  };

  componentWillUnmount() {
    if (this.firstParentWithScroll) {
      this.firstParentWithScroll.removeEventListener("scroll", this.throttledCheckScroll);
    }
  }

  checkScroll = () => {
    const { itemsLoadedCount } = this.state;
    const scrollMarkerElements = document.querySelectorAll(
      `.lazy-load-scroll-marker[data-container-id='${this.scrollMarkerId}']`
    );
    if (!scrollMarkerElements || scrollMarkerElements.length === 0) {
      return;
    }

    let markerIsVisible = false;
    for (let i = 0; i < scrollMarkerElements.length; i++) {
      if (isVisibleOnScreen(scrollMarkerElements[i])) {
        markerIsVisible = true;
        break;
      }
    }

    if (markerIsVisible) {
      this.setState({ itemsLoadedCount: itemsLoadedCount + (this.props.subsequentChunkSize || SUBSEQUENT_CHUNK_SIZE) });
    }
  };

  render() {
    const { list } = this.props;
    const { itemsLoadedCount } = this.state;
    const itemCallback = this.props.item;
    let truncatedList = list.slice(0, itemsLoadedCount);
    let itemsNotLoadedCount = list.length - truncatedList.length;

    return (
      <div className="lazy-load-list" ref={this.containerRef}>
        {/* <Typography.Text className="running-total">{truncatedList.length}</Typography.Text> */}
        {truncatedList.map((item, index) => {
          return (
            <div className="lazy-load-item" key={index}>
              {itemCallback(item, index)}
              {itemsNotLoadedCount && truncatedList.length - index <= (this.props.overshootSize || OVERSHOOT_SIZE) ? (
                <div className="lazy-load-scroll-marker" data-container-id={this.scrollMarkerId} />
              ) : null}
            </div>
          );
        })}
      </div>
    );
  }
}

export default React.memo(LazyLoadList);
