import { useState, useEffect } from "react";
import { Dropdown, Typography, Button } from "antd";
import type { MenuProps } from "antd";
import { useHistory } from "react-router-dom";
import { PlusOutlined, LogoutOutlined } from "@ant-design/icons";
import { isAuthorised } from "common/permissions";

import { PUBLIC_USER_EMAIL } from "common/constants";
import { signOutAllUsers, isExternalUser, getProviderNameForCurrentUser } from "common/authHelpers";

import Avatar from "Avatar/Avatar";
import LoggedInAccounts from "../LoggedInAccounts/LoggedInAccounts";
import UserTeamPicker from "../UserTeamPicker/UserTeamPicker";

import "./UserMenu.scss";

type Props = {
  apiUser: any;
  organisationDetails: any;
  loggedInUsers: any;
  cognitoIdentityKey: any;
};

export default function UserMenu({ apiUser, organisationDetails, loggedInUsers, cognitoIdentityKey }: Props) {
  const [userIsExternal, setUserIsExternal] = useState(false);
  const [providerName, setProviderName] = useState("");
  let history = useHistory();
  let alternateUsers = [];

  useEffect(() => {
    async function checkIfUserIsExternal() {
      const userIsExternal = await isExternalUser();
      setUserIsExternal(userIsExternal);
      if (userIsExternal) {
        const providerName = await getProviderNameForCurrentUser();
        setProviderName(providerName);
      }
    }
    checkIfUserIsExternal();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function signOut() {
    signOutAllUsers({ cognitoIdentityKey });
  }

  if (loggedInUsers) {
    alternateUsers = loggedInUsers
      .filter((user) => {
        const email = user.UserAttributes.find((x) => x.Name === "email").Value;
        return email !== PUBLIC_USER_EMAIL && email !== apiUser.id;
      })
      .map((user) => {
        const email = user.UserAttributes.find((x) => x.Name === "email").Value;
        const userDetailsString = localStorage.getItem(`${email}.userDetails`);
        const userDetails = userDetailsString && JSON.parse(userDetailsString);
        const organisation = user.UserAttributes.find((x) => x.Name === "address")?.Value;

        if (userDetails) {
          userDetails.id = email;
        }

        let displayName = `${organisation} - ${email}`;
        if (userDetails) {
          displayName = `${organisation} - ${userDetails?.firstName} ${userDetails?.lastName}`;
        }

        return {
          ...user,
          userDetails,
          email,
          displayName,
        };
      });
  }

  const menuItems = [
    {
      key: "my-account",
      label: (
        <div className="current-account-info-container">
          <Avatar size="large" user={apiUser} />
          <div className="account-info-section">
            <Typography.Text className="account-name">
              {alternateUsers.length > 0 ? `${apiUser.organisation} - ` : null}
              {apiUser.firstName} {apiUser.lastName}
            </Typography.Text>
            <Typography.Text className="account-email">{apiUser.id}</Typography.Text>
            {isAuthorised(["ACCOUNT.VIEW"]) && (
              <Button type="primary" className="my-account-button" onClick={() => history.push("/account")}>
                My Account
              </Button>
            )}
            {userIsExternal && providerName && (
              <div className="external-user-provider-name">
                <Typography.Text>Signed in via {providerName}</Typography.Text>
              </div>
            )}
            {organisationDetails.settings?.general?.usesTeams && apiUser.teams?.length > 0 && (
              <UserTeamPicker apiUser={apiUser} organisationDetails={organisationDetails} />
            )}
          </div>
        </div>
      ),
    },
    loggedInUsers && alternateUsers.length > 0
      ? {
          key: "alternate-accounts",
          label: (
            <LoggedInAccounts
              loggedInUsers={loggedInUsers}
              alternateUsers={alternateUsers}
              cognitoIdentityKey={cognitoIdentityKey}
            />
          ),
        }
      : undefined,

    {
      key: "quick-actions",
      label: (
        <div className="quick-account-actions">
          {!userIsExternal && (
            <div className="action-item" onClick={() => history.push("/sign-in")}>
              <PlusOutlined />
              Add another account
            </div>
          )}
          <div className="action-item" onClick={signOut}>
            <LogoutOutlined />
            Sign out
          </div>
        </div>
      ),
    },
  ].filter((x) => x !== undefined);

  return (
    <div className="user-menu">
      {apiUser && (
        <Dropdown
          menu={{ items: menuItems as MenuProps["items"] }}
          trigger={["click"]}
          overlayClassName="account-dropdown-overlay"
        >
          <div>
            <Typography.Text className="user-name" data-cy="account-dropdown-username">
              {alternateUsers.length > 0 ? `${apiUser.organisation} - ` : null}
              {apiUser.firstName}
            </Typography.Text>
            <Avatar disableUserCard user={apiUser} className="main-bar-avatar" />
          </div>
        </Dropdown>
      )}
    </div>
  );
}
