import addObjectToParent from "./addObjectToParent";
import removeGroupField from "./removeGroupField";
import { regenerateIdsForObjectAndChildren } from "common/sharedTemplateRenderHelpers";

export default function duplicateObject({ object, outputTemplate, isLiveCopy }) {
  let newObject = JSON.parse(JSON.stringify(removeGroupField({ ...object })));

  if (isLiveCopy) {
    newObject.isLiveCopyOf = object.custom_id;
  }

  regenerateIdsForObjectAndChildren(newObject);
  newObject.custom_name = `${newObject.custom_name || ""} copy`;

  const objectIndex = object.group.objects.findIndex((obj) => obj.custom_id === object.custom_id);

  const updatedOutputTemplate = addObjectToParent({
    currentObject: { ...outputTemplate },
    parentId: object.group ? object.group.custom_id : "root",
    newPosition: objectIndex + 1,
    child: newObject,
  });
  return { updatedOutputTemplate, newObject };
}
