import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { Modal, message } from "antd";

import { DeleteOutlined, EditOutlined, CheckCircleFilled } from "@ant-design/icons";

import { getLabel } from "common/helpers";
import { performSheetOperation, sanitiseSheetName } from "common/helpers";
import { CloseIcon } from "common/icons";
import "./SheetListItem.scss";

export default function SheetListItem({
  sheets,
  sheet,
  file,
  task,
  apiUser,
  taskRevision,
  fileIsInQueue,
  basePath,
  selected,
  history,
  organisationDetails,
}) {
  const [isEditable, setIsEditable] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    setInputValue(sheet.name);
  }, [sheet.name]);

  async function saveSheetName() {
    let sheetNameAlreadyExists = sheets.find((x) => x.id !== sheet.id && x.name === inputValue);
    if (sheetNameAlreadyExists) {
      Modal.error({
        title: "Sheet name already taken",
        content: (
          <>
            Sheet name <b>{inputValue}</b> is already used. Please choose a different name.
          </>
        ),
      });
      setInputValue(sheet.name);
      return;
    }

    inputRef.current.blur();
    setIsEditable(false);
    if (inputValue !== sheet.name) {
      await performSheetOperation({
        operation: "RENAME_SHEET",
        organisationDetails,
        sheet: {
          ...sheet,
          name: sanitiseSheetName(inputValue),
        },
        extraParams: {
          oldSheetName: sheet.name,
          newSheetName: inputValue,
        },
        apiUser,
        task,
        file,
        taskRevision,
        history,
      });
    }
  }

  function confirmRemoveSheet(sheet) {
    Modal.confirm({
      title: "Confirm delete sheet",
      content: (
        <>
          Are you sure you want to permanently delete sheet <b>{sheet.name}</b> from{" "}
          {getLabel({
            id: "task",
            defaultValue: "task",
          })}{" "}
          <b>{task.title}</b>?
          <br />
          <br />
          This will also erase the sheet's history.
        </>
      ),
      onOk: () => removeSheet(sheet),
    });
  }

  async function removeSheet(sheet) {
    try {
      await performSheetOperation({
        operation: "REMOVE_SHEET",
        organisationDetails,
        sheet,
        extraParams: {
          sheetName: sheet.name,
        },
        apiUser,
        task,
        file,
        taskRevision,
      });
    } catch (err) {
      console.error("error deleting sheet: ", err);
      message.error("Error deleting sheet" + err);
    }
  }

  return (
    <Link
      className={cx("sheet-list-item", {
        selected,
        "read-only": taskRevision.isReadOnly,
      })}
      data-sheet-id={sheet.id}
      to={`${basePath}?tab=${sheet.id}`}
    >
      <div className={cx("fade", { "is-editable": isEditable })} onClick={saveSheetName} />
      {isEditable && (
        <div
          className="close-icon-container"
          onClick={() => {
            setIsEditable(false);
            setInputValue(sheet.name);
            inputRef.current.blur();
          }}
        >
          <CloseIcon />
        </div>
      )}
      {isEditable && (
        <CheckCircleFilled
          onClick={() => {
            setIsEditable(false);
            saveSheetName();
          }}
        />
      )}
      <input
        className={cx({ "is-editable": isEditable })}
        value={inputValue}
        onChange={(e) => {
          const newSheetName = sanitiseSheetName(e.target.value, { trim: false });
          console.log(`new name:__${newSheetName}__`);
          setInputValue(newSheetName);
        }}
        onKeyUp={(e) => {
          if (e.key === "Escape") {
            setIsEditable(false);
            setInputValue(sheet.name);
            inputRef.current.blur();
          } else if (e.key === "Enter") {
            setIsEditable(false);
            saveSheetName();
          }
        }}
        ref={inputRef}
      />
      {fileIsInQueue || isEditable ? null : (
        <EditOutlined
          className="edit-sheet-name"
          onClick={(e) => {
            e.preventDefault();
            inputRef.current.focus();
            setIsEditable(true);
          }}
        />
      )}{" "}
      {fileIsInQueue ||
        (sheets.length > 1 && (
          <DeleteOutlined
            className="remove-sheet"
            onClick={() => {
              confirmRemoveSheet(sheet);
            }}
          />
        ))}
    </Link>
  );
}
