import { Divider, Modal, Button, message } from "antd";
import { Link } from "react-router-dom";
import { Storage } from "aws-amplify";
import axios from "axios";

import getS3File from "common/getS3File";
import { isAuthorised } from "common/permissions";

import { OpenIcon } from "common/icons";

type Props = {
  templateDetails: any;
  form: any;
  formFileKey: string;
  apiUser: any;
};

export default function FormTemplateActions({ templateDetails, form, formFileKey, apiUser }: Props) {
  // it has to be an async function because otherwise the modal doesn't show up for some weird reason
  async function confirmToTemplate() {
    Modal.confirm({
      title: "Are you sure you want to copy this form to the template?",
      content: "This will overwrite the template form.",
      onOk: () => toTemplate(),
    });
  }

  // it has to be an async function because otherwise the modal doesn't show up for some weird reason
  async function confirmFromTemplate() {
    Modal.confirm({
      title: "Are you sure you want to replace this form with the template?",
      content: "This will overwrite this form.",
      onOk: () => fromTemplate(),
    });
  }

  async function toTemplate() {
    const formFilePublicUrl = await getS3File(templateDetails.key);
    let templateForm = (await axios.get(formFilePublicUrl)).data;
    const duplicateForm = JSON.parse(JSON.stringify(form));
    for (let fieldName in templateForm.fields) {
      let correspondingField = duplicateForm.fields[fieldName];
      if (!correspondingField) {
        message.error("Template form has fields that are not in this form");
        return;
      }

      let templateValuesFromTemplateFile = templateForm.fields[fieldName].templateValues;
      correspondingField.templateValues = templateValuesFromTemplateFile;
    }

    try {
      await Storage.put(templateDetails.key, JSON.stringify(form));
      message.success("Template form updated successfully");
    } catch (e) {
      message.error("Template form failed to update");
      console.log(e);
    }
  }

  async function fromTemplate() {
    try {
      const formFilePublicUrl = await getS3File(templateDetails.key);
      let form = (await axios.get(formFilePublicUrl)).data;
      await Storage.put(formFileKey, JSON.stringify(form));
      message.success("Form updated from template");
      window.location.reload();
    } catch (e) {
      message.error("Failed to update form from template");
      console.log(e);
    }
  }

  if (!isAuthorised(["MANAGE_TEMPLATES"]) && !apiUser.isHidden && window.location.hostname !== "localhost") {
    return null;
  }

  return (
    <>
      <Divider />
      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", maxWidth: "20rem", margin: "0 auto" }}>
        <Button onClick={confirmToTemplate}>Make this form the template</Button>
        <Button onClick={confirmFromTemplate}>Clear form</Button>
        <Link
          to={`/templates/annotation-editor/${templateDetails.type}/${templateDetails.id}`}
          target="_blank"
          style={{ width: "100%" }}
        >
          <Button style={{ width: "100%" }} icon={<OpenIcon />}>
            Open template
          </Button>
        </Link>
      </div>
    </>
  );
}
