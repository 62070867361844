import { Auth } from "aws-amplify";
import axios from "axios";
import cookie from "js-cookie";
import awsExports from "aws-exports";

import { getCurrentUserId } from "common/authHelpers";
import { getRestEndpoint } from "common/apiHelpers";
import { makePascalCase } from "common/shared";

const LINK_URL = "http://localhost:7777";

const ROUTES = {
  run: {
    route: "run",
    method: "POST",
  },
  cleanup: {
    route: "cleanup",
    method: "POST",
  },
  renameSheet: {
    route: "rename-sheet",
    method: "POST",
  },
  addSheets: {
    route: "add-sheets",
    method: "POST",
  },
  removeSheet: {
    route: "remove-sheet",
    method: "POST",
  },
  batchEdit: {
    route: "batch-edit",
    method: "POST",
  },
  updateCredentials: {
    route: "update-credentials",
    method: "POST",
    handler: updateCredentials,
  },
  findBinaries: {
    route: "find-binaries",
    method: "GET",
  },
  isFileOpen: {
    route: "is-file-open",
    method: "POST",
  },
  checkLinkIsRunning: {
    handler: checkLinkIsRunning,
  },
  getLogStreamName: {
    route: "get-log-stream-name",
    method: "GET",
  },
};

export async function getLinkPayload(params) {
  return {
    ...(await getCredentials()),
    ...getAutoBackupParams(),
    ...params,
  };
}

function getAutoBackupParams() {
  const userHasAutoBackup = window.apiUser.featureFlags?.autoBackup;
  if (!userHasAutoBackup) {
    return {};
  }

  return {
    AutoBackup: {
      ShouldAutoBackup: true,
      NumberOfActions: 100,
      NumberOfMinutes: 60,
    },
  };
}

async function getCredentials() {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });

  const userId = await getCurrentUserId();

  return {
    accessToken: user.signInUserSession.accessToken.jwtToken,
    cognitoClientId: user.pool.clientId,
    idToken: user.signInUserSession.idToken.jwtToken,
    refreshToken: user.signInUserSession.refreshToken.token,
    restUrl: getRestEndpoint(),
    bucket: awsExports.aws_user_files_s3_bucket,
    region: awsExports.aws_user_files_s3_bucket_region,
    graphQLUrl: awsExports.aws_appsync_graphqlEndpoint,
    identityPoolId: awsExports.aws_cognito_identity_pool_id,
    userPoolid: awsExports.aws_user_pools_id,
    cognitoUsername: user.username,
    userId,
  };
}

async function checkLinkIsRunning() {
  let isRunning = false;
  try {
    await axios.get("http://localhost:7777/find-binaries");
    isRunning = true;
  } catch (e) {
    // nothing, it means Link isn't running
  }
  return isRunning;
}

async function updateCredentials() {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });
  const credentials = await Auth.currentCredentials();
  // console.log("credentials = ", credentials);

  const essentialCredentials = Auth.essentialCredentials(credentials);
  // console.log("essentialCredentials = ", JSON.stringify(essentialCredentials, null, 2));
  const { accessKeyId, secretAccessKey, sessionToken } = essentialCredentials;

  return callLink({
    payload: {
      ...(await getCredentials()),
      accessKeyId,
      secretKey: secretAccessKey,
      sessionToken,
      identityId: essentialCredentials.identityId,
      identityProviderName: user.signInUserSession.idToken.payload.iss.split("https://")[1],
      refreshTokenExpiryDatetime: cookie.get("refresh-token-expiry"),
    },
    route: ROUTES.updateCredentials.route,
  });
}

export function platformSupportsLink() {
  const platform = navigator.platform.toLowerCase();
  return platform.startsWith("mac") || platform.startsWith("win");
}

async function callLink({ params, payload, route, method = "post" }) {
  if (!payload) {
    payload = await getLinkPayload(params);
  }

  // if on iOS, do not make the request
  if (!platformSupportsLink()) {
    return;
  }

  return axios({
    method,
    url: `${LINK_URL}/${route}`,
    data: makePascalCase(payload),
  });
}

let linkApi = {};
for (let routeName in ROUTES) {
  linkApi[routeName] = (params) => {
    const routeDetails = ROUTES[routeName];

    if (routeDetails.handler) {
      return routeDetails.handler(params);
    } else {
      return callLink({
        params,
        route: routeDetails.route,
        method: routeDetails.method,
      });
    }
  };
}

export default linkApi;
