import { useState } from "react";
import { Form, Modal, Button, Space, Select, message } from "antd";

import withSubscriptions from "common/withSubscriptions";

import "./ChangeClientModal.scss";
import { callGraphQLSimple } from "common/apiHelpers";

export function ChangeClientModal({ onClose, tasks, quotes, invoices, purchaseOrders, clients, project }) {
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ clientId }) {
    let messageKey = "change-client-message";
    message.loading({ content: "Updating project ...", key: messageKey });

    let tasksInProject = tasks.filter((x) => x.projectId === project.id);
    let quotesInProject = quotes.filter((x) => x.projectId === project.id);
    let invoicesInProject = invoices.filter((x) => x.projectId === project.id);
    let purchaseOrdersInProject = purchaseOrders.filter((x) => x.projectId === project.id);

    setIsLoading(true);

    try {
      if (tasks && tasksInProject.length > 0) {
        for (let i = 0; i < tasksInProject.length; i++) {
          const task = tasksInProject[i];

          await window.callGraphQLSimple({
            message: "Failed to update task",
            queryCustom: "updateTask",
            variables: {
              input: {
                id: task.id,
                clientId,
              },
            },
          });
          message.loading({ content: `Updating tasks: ${i} / ${tasksInProject.length}`, key: messageKey, duration: 0 });
        }
      }

      if (quotesInProject && quotesInProject.length > 0) {
        for (let i = 0; i < quotesInProject.length; i++) {
          const quote = quotesInProject[i];

          await window.callGraphQLSimple({
            message: "Failed to update quote",
            queryCustom: "updateQuote",
            variables: {
              input: {
                id: quote.id,
                clientId,
              },
            },
          });
          message.loading({
            content: `Updating quotes: ${i} / ${quotesInProject.length}`,
            key: messageKey,
            duration: 0,
          });
        }
      }

      if (invoicesInProject && invoicesInProject.length > 0) {
        for (let i = 0; i < invoicesInProject.length; i++) {
          const invoice = invoicesInProject[i];

          await window.callGraphQLSimple({
            message: "Failed to update invoice",
            queryCustom: "updateInvoice",
            variables: {
              input: {
                id: invoice.id,
                clientId,
              },
            },
          });
          message.loading({
            content: `Updating invoices: ${i} / ${invoicesInProject.length}`,
            key: messageKey,
            duration: 0,
          });
        }
      }

      if (purchaseOrdersInProject && purchaseOrdersInProject.length > 0) {
        for (let i = 0; i < purchaseOrdersInProject.length; i++) {
          const purchaseOrder = purchaseOrdersInProject[i];

          await window.callGraphQLSimple({
            message: "Failed to update purchase order",
            mutation: "updatePurchaseOrder",
            variables: {
              input: {
                id: purchaseOrder.id,
                clientId,
              },
            },
          });
          message.loading({
            content: `Updating purchase orders: ${i} / ${purchaseOrdersInProject.length}`,
            key: messageKey,
            duration: 0,
          });
        }
      }
      await window.callGraphQLSimple({
        message: "Failed to update project",
        queryCustom: "updateProject",
        variables: {
          input: {
            id: project.id,
            clientId,
          },
        },
      });
      message.success({ content: "Client changed successfully", key: messageKey, duration: 3 });
    } catch (e) {
      message.error({ content: "Operation failed, please try again", key: messageKey, duration: 5 });
    }

    await callGraphQLSimple({
      mutation: "createActivityItem",
      variables: {
        input: {
          parentId: project.id,
          content: JSON.stringify({
            type: "CLIENT_CHANGED",
            oldClientId: project.clientId,
            clientId,
            oldClientName: clients.find((x) => x.id === project.clientId).name,
            clientName: clients.find((x) => x.id === clientId).name,
          }),
          type: "LIFECYCLE_EVENT",
          organisation: project.organisation,
          author: window.apiUser.id,
        },
      },
    });

    setIsLoading(false);
    onClose();
  }

  return (
    <Modal
      title={"Change client"}
      visible={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="change-project-client-modal"
    >
      <Form onFinish={onSubmit} initialValues={{ clientId: project.clientId }}>
        <Space direction="vertical" className="spread">
          <Form.Item label={"Choose a client: "} name="clientId">
            <Select className="client-picker-dropdown">
              {clients.map((client) => {
                return <Select.Option value={client.id}>{client.name}</Select.Option>;
              })}
            </Select>
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading} data-cy="change-client-submit-button">
              Submit
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}

export default withSubscriptions({
  Component: ChangeClientModal,
  subscriptions: ["organisationDetails", "tasks", "clients", "quotes", "invoices", "purchaseOrders"],
});
