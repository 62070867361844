import { useState } from "react";
import { Form, Modal, Input, Button, Select } from "antd";
import { FILE_TYPES_READABLE, FILE_TYPE_EXTENSIONS } from "common/constants";
import { FILE_TYPES_DETAILS } from "common/shared";
import { CloudUploadOutlined } from "@ant-design/icons";
import { createTemplate } from "common/templateHelpers";
import _ from "lodash";

import "./CreateTemplateModal.scss";

export default function CreateTemplateModal({ onClose, fileType, projects }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState();
  const fileTypeDetails = FILE_TYPES_DETAILS[fileType];

  async function onFileInputChange(e) {
    const fileToUpload = e.target.files[0];
    if (!fileToUpload.name.includes(".")) {
      Modal.error({
        title: "Invalid file",
        content: "The file you are trying to upload does not have an extension.",
      });
      return;
    }
    const fileExtension = fileToUpload.name.split(".").slice(-1)[0];
    const acceptedExtensions = FILE_TYPE_EXTENSIONS[fileType];

    if (!acceptedExtensions.includes(fileExtension)) {
      Modal.error({
        title: "Wrong file type",
        content: `File extension must be: ${acceptedExtensions.join(
          ", "
        )}. The file you are trying to upload has the extension: ${fileExtension}`,
      });

      return;
    }

    form.setFieldsValue({ file: fileToUpload });
    setFileToUpload(fileToUpload);
  }

  async function onSubmit({ name, outputType, parentType }) {
    setIsLoading(true);

    await createTemplate({
      fileToUpload,
      type: fileType,
      name,
      projects,
      outputType,
      parentType,
    });

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  let defaultOutputType = "PDF";

  if (fileTypeDetails.outputTypes && fileTypeDetails.outputTypes.length > 0) {
    defaultOutputType = fileTypeDetails.outputTypes[0];
  }

  let defaultParentType = "TASK";

  return (
    <Modal
      maskClosable={false}
      title={`Create ${fileTypeDetails.label} template`}
      open={true}
      onCancel={onClose}
      footer={null}
      className="create-template-modal"
    >
      <Form
        {...layout}
        form={form}
        initialValues={{ outputType: defaultOutputType, parentType: defaultParentType }}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Template name"
          name="name"
          rules={[
            {
              required: true,
              message: "You need to specify a template name",
            },
          ]}
        >
          <Input data-cy="template-name-input" />
        </Form.Item>

        <Form.Item
          label="Output format"
          name="outputType"
          hidden={!fileTypeDetails.outputTypes}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            {(!fileTypeDetails.outputTypes || fileTypeDetails.outputTypes?.includes("PDF")) && (
              <Select.Option value="PDF">PDF</Select.Option>
            )}
            {fileTypeDetails.outputTypes?.includes("SPREADSHEET") && (
              <Select.Option value="SPREADSHEET">CSV</Select.Option>
            )}
            {fileTypeDetails.outputTypes?.includes("SPREADSHEET-EXCEL") && (
              <Select.Option value="SPREADSHEET-EXCEL">Excel</Select.Option>
            )}
            {fileTypeDetails.outputTypes?.includes("APP_PAGE") && (
              <Select.Option value="APP_PAGE">Application page</Select.Option>
            )}
          </Select>
        </Form.Item>

        {fileType === "REPORT" && (
          <Form.Item
            label="Can be added to"
            name="parentType"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              <Select.Option value="ORGANISATION">Organisation</Select.Option>
              <Select.Option value="TASK">Tasks</Select.Option>
              <Select.Option value="PROJECT">Projects</Select.Option>
              <Select.Option value="CLIENT">Clients</Select.Option>
              <Select.Option value="USER">Users</Select.Option>
            </Select>
          </Form.Item>
        )}

        <Form.Item
          hidden={fileTypeDetails.isDocumentTemplate}
          label={`${FILE_TYPES_READABLE[fileType]} file`}
          name="file"
          rules={[
            {
              required: !fileTypeDetails.isDocumentTemplate,
              message: "You need to upload a template file",
            },
          ]}
        >
          <>
            <Button
              type="dark"
              icon={<CloudUploadOutlined />}
              onClick={() => {
                document.querySelector("#upload-template-file").click();
              }}
            >
              <span>{fileToUpload ? _.truncate(fileToUpload.name, { length: 50 }) : "Choose file to upload"}</span>
            </Button>

            <input id="upload-template-file" style={{ display: "none" }} type="file" onChange={onFileInputChange} />
          </>
        </Form.Item>

        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}
