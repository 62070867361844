import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import cx from "classnames";
import { Typography, Checkbox, Button } from "antd";
import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import { getInvoicePaymentTerms } from "common/invoiceHelpers/sharedInvoiceHelpers";

import Card from "Card/Card";
import Input from "Input/Input";
import ClientLogo from "ClientLogo/ClientLogo";
import EditClientLogoModal from "Modals/EditClientLogoModal/EditClientLogoModal";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";
import CreateProjectModal from "CreateProjectModal/CreateProjectModal";

import "./ClientSidebar.scss";

export class ProjectSidebar extends React.Component {
  state = {
    isEditClientLogoModalVisible: false,
    isCreateProjectModalVisible: false,
  };

  updateClientField = async ({ fieldName, value }) => {
    const { client } = this.props;
    await callGraphQLSimple({
      message: `Failed to update ${fieldName}`,
      queryName: "updateClient",
      variables: {
        input: {
          id: client.id,
          [fieldName]: value,
        },
      },
    });
  };

  render() {
    const { splitLayout, client, withSpace, organisationDetails } = this.props;
    const { isEditClientLogoModalVisible, isCreateProjectModalVisible } = this.state;

    return (
      <>
        <Card className={cx("client-sidebar", { "split-layout": splitLayout })} withSpace={withSpace}>
          <ButtonWithPermissions
            type="primary"
            permissions={["CREATE_PROJECT"]}
            className="create-project"
            onClick={() => this.setState({ isCreateProjectModalVisible: true })}
            icon={<PlusCircleOutlined />}
          >
            Create {getSimpleLabel("project")} for {getSimpleLabel("client")}
          </ButtonWithPermissions>

          <div className="client-priority-container">
            <div className="stat priority-client-container">
              <Typography.Text className="client-priority-label">Priority {getSimpleLabel("client")}: </Typography.Text>
              <Checkbox
                defaultChecked={client.isPriority}
                onChange={(e) => {
                  this.updateClientField({
                    fieldName: "isPriority",
                    value: e.target.checked,
                  });
                }}
                className="client-priority-checkbox"
              />
            </div>
          </div>
          <div className="stat">
            <Typography.Text className="stat-label">Created</Typography.Text>
            <Typography.Text className="stat-value">{moment(client.createdAt).fromNow()}</Typography.Text>
          </div>
          <div className="stat">
            <Typography.Text className="stat-label">Last updated</Typography.Text>
            <Typography.Text className="stat-value">{moment(client.updatedAt).fromNow()}</Typography.Text>
          </div>
          <div className="stat">
            <Typography.Text className="stat-label">Company number</Typography.Text>
            <Input
              showBorder
              defaultValue={client.companyNumber}
              onChange={(value) => this.updateClientField({ fieldName: "companyNumber", value })}
            />
          </div>
          <div className="stat">
            <Typography.Text className="stat-label">VAT number</Typography.Text>
            <Input
              showBorder
              onChange={(value) => this.updateClientField({ fieldName: "vatNumber", value })}
              defaultValue={client.vatNumber}
            />
          </div>
          <div className="stat">
            <Typography.Text className="stat-label">Default payment terms (number of days)</Typography.Text>
            <Input
              showBorder
              numerical
              fireOnChangeWithoutBlurWithDebounce
              onChange={(value) =>
                this.updateClientField({ fieldName: "defaultExpectPaymentAfterDays", value: value || null })
              }
              defaultValue={client.defaultExpectPaymentAfterDays}
              placeholder={getInvoicePaymentTerms({ organisationDetails })}
            />
          </div>
          <div className="stat">
            <div className="logo-and-label-container">
              <Typography.Text className="stat-label">Logo:</Typography.Text>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => this.setState({ isEditClientLogoModalVisible: true })}
                data-cy="edit-client-logo-button"
              >
                {client.key ? "Edit logo" : "Add logo"}
              </Button>
            </div>
            <Typography.Text className="stat-value">
              {client.key ? (
                <div className="client-logo-container">
                  <ClientLogo client={client} size="medium" />
                </div>
              ) : (
                <div>No logo yet</div>
              )}
            </Typography.Text>
          </div>
          {isEditClientLogoModalVisible && (
            <EditClientLogoModal
              apiUser={window.apiUser}
              client={client}
              onClose={() => this.setState({ isEditClientLogoModalVisible: false })}
            />
          )}
          {isCreateProjectModalVisible && (
            <CreateProjectModal
              onClose={() => this.setState({ isCreateProjectModalVisible: false })}
              apiUser={window.apiUser}
              predefinedFields={{ clientId: client.id }}
            />
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: ProjectSidebar,
    subscriptions: ["organisationDetails", "sprints", "tasks", "users", "clients"],
  })
);
