import React from "react";
import cookie from "js-cookie";

import { callGraphQLSimple } from "common/apiHelpers";
import _ from "lodash";

import linkApi from "common/link";

export default class ClickCapture extends React.Component {
  constructor(props) {
    super(props);
    this.throttledRecordLogStreamNameAndActiveComputer = _.throttle(
      this.recordLogStreamNameAndActiveComputer,
      1000 * 60 * 5
    );
  }
  componentDidMount() {
    window.recordActiveComputer = this.recordActiveComputer;
    this.recordActiveComputer();
    window.addEventListener("click", this.onClick);
    this.recordLogStreamNameAndActiveComputer();
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClick);
  }

  recordLogStreamNameAndActiveComputer = async () => {
    if (window.Cypress) {
      return;
    }

    const { apiUser } = this.props;
    let linkResponse;
    const computerId = cookie.get("computer-id");
    const computerDetails = (apiUser.computers || []).find((computer) => computer.id === computerId);
    if (!computerDetails) {
      return;
    }

    try {
      linkResponse = await linkApi.getLogStreamName();
    } catch (e) {
      // this call will fail if Link is not running, but there's nothing we can do other than record it
    }

    if (linkResponse) {
      if (
        !computerDetails.linkIsRunning ||
        !computerDetails.linkIsInstalled ||
        computerDetails.cloudWatchLogStreamName !== linkResponse.data.logStreamName
      ) {
        await callGraphQLSimple({
          displayError: false,
          mutation: "updateUser",
          variables: {
            input: {
              id: apiUser.id,
              computers: (apiUser.computers || []).map((computer) => {
                if (computer.id === computerId) {
                  return {
                    ...computer,
                    cloudWatchLogStreamName: linkResponse.data.logStreamName,
                    linkIsRunning: true,
                    linkIsInstalled: true,
                  };
                }
                return computer;
              }),
            },
          },
        });
      }
    } else {
      if (computerDetails.linkIsRunning) {
        await callGraphQLSimple({
          displayError: false,
          mutation: "updateUser",
          variables: {
            input: {
              id: apiUser.id,
              computers: (apiUser.computers || []).map((computer) => {
                if (computer.id === computerId) {
                  return {
                    ...computer,
                    linkIsRunning: false,
                  };
                }
                return computer;
              }),
            },
          },
        });
      }
    }
  };

  recordActiveComputer = async () => {
    if (window.Cypress) {
      // we do not want this to be triggered during Cypress tests, because it is wasteful
      return;
    }
    const { apiUser } = this.props;
    let computerId = cookie.get("computer-id");
    const computerName = cookie.get("computer-name");

    let mustRecordNewComputer = false;
    let mustUpdateComputerName = false;

    if (!computerId) {
      cookie.set("computer-id", String(Date.now()), { expires: 999999 });
      computerId = cookie.get("computer-id");
      mustRecordNewComputer = true;
    } else {
      const computerDetails =
        apiUser.computers && apiUser.computers.find((computer) => computer.id === cookie.get("computer-id"));
      if (!computerDetails) {
        mustRecordNewComputer = true;
      } else if (computerName && computerName !== computerDetails.name) {
        mustUpdateComputerName = true;
      }
    }

    if (mustRecordNewComputer) {
      await callGraphQLSimple({
        displayError: false,
        mutation: "updateUser",
        variables: {
          input: {
            id: apiUser.id,
            lastActiveComputer: computerId,
            computers: [
              ...(apiUser.computers || []),
              {
                id: computerId,
                name: computerName || "Unnamed",
                cloudWatchLogStreamName: "",
              },
            ],
          },
        },
      });
    } else if (mustUpdateComputerName) {
      await callGraphQLSimple({
        displayError: false,
        mutation: "updateUser",
        variables: {
          input: {
            id: apiUser.id,
            lastActiveComputer: computerId,
            computers: (apiUser.computers || []).map((computer) => {
              if (computer.id === computerId) {
                return {
                  ...computer,
                  name: computerName,
                };
              }
              return computer;
            }),
          },
        },
      });
    } else if (apiUser.lastActiveComputer !== computerId) {
      await callGraphQLSimple({
        displayError: false,
        mutation: "updateUser",
        variables: {
          input: {
            id: apiUser.id,
            lastActiveComputer: computerId,
          },
        },
      });
    }
  };

  onClick = async () => {
    this.recordActiveComputer();
    this.throttledRecordLogStreamNameAndActiveComputer();
  };

  render() {
    return null;
  }
}
