import { Typography, Tag } from "antd";

import withSubscriptions from "common/withSubscriptions";
import { processIdForDisplay } from "common/helpers";

import DashboardItemTags from "DashboardItemTags/DashboardItemTags";
import Card from "Card/Card";
import Avatar from "Avatar/Avatar";
import ClientLogo from "ClientLogo/ClientLogo";

import "./PurchaseOrderItem.scss";

export function PurchaseOrderItem({ purchaseOrder, projects, suppliers, users, onClick, organisationDetails }) {
  const project = projects.find((x) => x.id === purchaseOrder.projectId);
  const supplier = suppliers.find((x) => x.id === purchaseOrder.supplierId);

  if (!project) {
    return null;
  }

  const userData = users.find((x) => x.id === purchaseOrder.assignedTo);

  let PurchaseOrderElement = (
    <div className="purchase-order-item-container">
      <Card
        className="purchase-order-item"
        attributes={{ "data-title": purchaseOrder.title, "data-purchaseOrder-id": purchaseOrder.id }}
        onClick={() => onClick(purchaseOrder.id)}
        data-cy="purchaseOrder-item"
      >
        <Typography.Paragraph className="project-name">{project.title}</Typography.Paragraph>
        <div className="purchase-order-name-container">
          <Typography.Text level={4} className="purchase-order-name">
            {purchaseOrder.title}
          </Typography.Text>
        </div>
        <Typography.Text level={4} className="purchase-order-subtotal">
          {window.formatCurrency("GBP", purchaseOrder.subtotal)}
        </Typography.Text>

        <DashboardItemTags
          item={{ ...purchaseOrder, supplier, project }}
          organisationDetails={organisationDetails}
          skipCatLevel={true}
          skipInstructed={true}
          skipCustomFields={true}
          isInvoice
        />

        <div className="footer">
          <ClientLogo client={supplier} size="small" />

          <Typography.Paragraph className="task-id">
            <Tag className="purchase-order-id-tag dark-tag">{processIdForDisplay(purchaseOrder.id)}</Tag>
          </Typography.Paragraph>
          <Avatar user={userData} className="assigned-to-avatar" />
        </div>
      </Card>
    </div>
  );

  // if (!draggable) {
  return PurchaseOrderElement;
  // }

  // return (
  //   <Draggable key={id} draggableId={id} index={index} className={`draggable-task ${title.split(" ").join("_")}`}>
  //     {(provided) => {
  //       return (
  //         <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
  //           {taskItemElement}
  //         </div>
  //       );
  //     }}
  //   </Draggable>
  // );
}

export default withSubscriptions({
  Component: PurchaseOrderItem,
  subscriptions: ["users", "projects", "suppliers"],
});
