import { useEffect } from "react";
import { withRouter } from "react-router-dom";

import withSubscriptions from "common/withSubscriptions";

import Navigation from "Navigation/Navigation";

import "./SimpleMobileHomePage.scss";

export function SimpleMobileHomePage(props) {
  useEffect(() => {
    props.setBackground(false);
    return () => {
      props.setBackground(true);
    };
  }, []);
  return (
    <div className="simple-mobile-home-page">
      <Navigation {...props} isMobile />
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: SimpleMobileHomePage,
    subscriptions: ["organisationDetails"],
  })
);
