export default function findChildAndParent(currentObject, childId) {
  if (Array.isArray(currentObject.objects)) {
    const child = currentObject.objects.find((obj) => obj.custom_id === childId);
    if (child) {
      return { child, parent: currentObject };
    }

    for (let childObject of currentObject.objects) {
      const result = findChildAndParent(childObject, childId, currentObject);
      if (result.child) {
        return result;
      }
    }
  }

  return { child: null, parent: null };
}
