import { Tooltip, InputNumber, Checkbox, Tag, Button, Typography, Table } from "antd";
import { Link } from "react-router-dom";
import { processIdForDisplay, getLabel } from "common/helpers";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  RightOutlined,
  DownOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import cx from "classnames";

import { roundToQuarter, roundToDecimals } from "common/mathHelpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { getTimesheetBlocksForInvoiceLineItem } from "common/invoiceHelpers/sharedInvoiceHelpers";
import { recalculateInvoiceAmounts } from "common/invoiceHelpers";

import TimesheetBlocksTable from "TimesheetBlocksTable/TimesheetBlocksTable";
import ReviewTarget from "ReviewTarget/ReviewTarget";
import Input from "Input/Input";
import Card from "Card/Card";
import { getSimpleLabel } from "common/labels";

type Props = {
  invoiceForDisplay: any;
  invoice: any;
  refreshInvoice: () => void;
  isUnderReview: boolean;
  openCommentBox: () => void;
  isDisabled: boolean;
  setSelectedInvoiceLineItem: (item: any) => void;
  setIsAddQuoteModalVisible: (isVisible: boolean) => void;
  confirmDeleteLineItem: (item: any, index: number) => void;
  organisationDetails: any;
  invoiceRef: any;
  deletedLineItemIds: string[];
  numberForPreviewRefresh: number;
};

export default function InvoiceLineItemsTable(props: Props) {
  const {
    invoiceForDisplay,
    invoice,
    refreshInvoice,
    isUnderReview,
    isDisabled,
    setSelectedInvoiceLineItem,
    setIsAddQuoteModalVisible,
    confirmDeleteLineItem,
    organisationDetails,
    invoiceRef,
    deletedLineItemIds,
  } = props;

  const urlParams = new URLSearchParams(window.location.search);

  async function changeLineItem({
    fieldName,
    id,
    value,
    includeRecalculation,
    includeQuoteUpdate,
    quoteId,
  }: {
    fieldName: string;
    id: string;
    value: any;
    includeRecalculation?: boolean;
    includeQuoteUpdate?: boolean;
    quoteId?: string;
  }) {
    try {
      await callGraphQLSimple({
        message: "Failed to update line item",
        queryCustom: "updateInvoiceLineItem",
        variables: {
          input: {
            id,
            [fieldName]: value,
          },
        },
      });

      if (includeRecalculation) {
        await recalculateInvoiceAmounts({ invoiceId: invoiceForDisplay.id });

        if (includeQuoteUpdate) {
          await callGraphQLSimple({
            message: `Failed to refresh invoiced amount on ${getLabel({
              id: "quote",
              defaultValue: "quote",
            })}}`,
            queryCustom: "updateQuote",
            variables: {
              input: {
                id: quoteId,
                itemSubscription: Math.floor(Math.random() * 100000),
              },
            },
          });
        }
      } else {
        await refreshInvoice();
      }
    } catch (e) {
      console.error(e);
    }
  }

  function displayActionsForInvoiceLineItem(item, index) {
    let quoteLineItemButton: any = null;
    let taskButton: any = null;
    if (item.quoteLineItem) {
      quoteLineItemButton = (
        <Link to={`/quotes/${item.quoteLineItem.quoteId}`} target="_blank">
          <Button className="go-to-quote-button" data-cy="invoice-line-item-go-to-quote-button" data-row-index={index}>
            Open {getSimpleLabel("quote")}
            <br />
            <Tag className="dark-tag">{processIdForDisplay(item.quoteLineItem.quoteId)}</Tag>
          </Button>
        </Link>
      );
    } else if (item.taskId && item.taskId !== "nothing") {
      taskButton = (
        <Link to={`/tasks/${item.taskId}`} target="_blank">
          <Button className="go-to-task-button">
            Open {getSimpleLabel("task")}
            <br /> <Tag className="dark-tag">{item.taskId}</Tag>
          </Button>
        </Link>
      );
    } else {
      quoteLineItemButton = (
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          disabled={isDisabled}
          data-cy="add-quote-line-item-button"
          data-row-index={index}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedInvoiceLineItem(item);
            setIsAddQuoteModalVisible(true);
          }}
        >
          Add{" "}
          {getLabel({
            id: "quote",
            defaultValue: "quote",
          })}{" "}
          line item
        </Button>
      );
    }

    return (
      <div className="actions">
        <Button
          icon={<DeleteOutlined />}
          data-cy="delete-line-item-button"
          data-row-index={index}
          disabled={isDisabled}
          onClick={(e) => {
            e.stopPropagation();
            confirmDeleteLineItem(item, index + 1);
          }}
        >
          Delete
        </Button>

        {quoteLineItemButton}
        {taskButton}
      </div>
    );
  }

  function displayDetailsPerLineItem(invoiceLineItem) {
    let invoiceLineItemForDisplay = invoiceForDisplay.lineItems.items.find((x) => x.id === invoiceLineItem.id);
    let { quoteLineItem } = invoiceLineItemForDisplay;

    let quoteLineItemCard: any = null;
    let timesheetsCard = displayTimesheetsCard(invoiceLineItem);
    if (quoteLineItem) {
      quoteLineItemCard = displayQuoteLineItemCard(invoiceLineItem);
    }

    return (
      <div className="invoice-line-item-details">
        {quoteLineItemCard}
        {timesheetsCard}
      </div>
    );
  }

  function displayQuoteLineItemCard(invoiceLineItem) {
    let invoiceLineItemForDisplay = invoiceForDisplay.lineItems.items.find((x) => x.id === invoiceLineItem.id);
    let { quoteLineItem } = invoiceLineItemForDisplay;

    let quoteTableRows: any[] = [];

    if (quoteLineItem) {
      quoteTableRows.push(quoteLineItem);
    }

    let previouslyInvoiced;
    let invoicedInThisInvoice;
    if (quoteLineItem) {
      previouslyInvoiced = quoteLineItem.invoicedAmount;
      invoicedInThisInvoice = invoiceForDisplay.lineItems.items.reduce((sum, lineItem) => {
        if (lineItem.quoteLineItemId === quoteLineItem.id) {
          return sum + lineItem.amount;
        }
        return sum;
      }, 0);
    }

    let quoteLineItemTableTitle = (
      <>
        <Typography.Text className="table-title">
          {getLabel({
            id: "Quote",
            defaultValue: "Quote",
          })}{" "}
          line item{" "}
          {quoteLineItem && (
            <Link
              to={`/quotes/${quoteLineItem.quoteId}`}
              target="_blank"
              style={{
                marginLeft: "7rem",
                position: "relative",
                top: "0.6rem",
              }}
            >
              <Button type="primary">
                Open{" "}
                {getLabel({
                  id: "quote",
                  defaultValue: "quote",
                })}
              </Button>
            </Link>
          )}
        </Typography.Text>
        {quoteLineItem && (
          <Typography.Text className="table-subtitle" style={{ marginTop: "-0.3rem" }}>
            Previously invoiced: {window.formatCurrency("GBP", previouslyInvoiced - invoicedInThisInvoice)}
          </Typography.Text>
        )}
      </>
    );

    let quoteLineItemColumns = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        align: "left",
        width: 200,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        align: "left",
        width: 200,
      },
      {
        title: "Hourly",
        key: "hourly",
        align: "center",
        width: 80,
        render: (_, row) => <Checkbox checked={row.isHourly} disabled />,
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        align: "center",
        width: 80,
      },
      {
        title: getLabel({
          organisationDetails,
          id: "quote-unit-price",
          defaultValue: "Unit Price",
        }),
        key: "unit-price",
        align: "center",
        width: 120,
        render: (_, row) => {
          if (row.isHourly) {
            return "-";
          } else {
            return window.formatCurrency("GBP", row.unitPrice);
          }
        },
      },
    ];

    if (!organisationDetails.settings?.quote?.hiddenLineItemColumns?.includes("checkPrice")) {
      quoteLineItemColumns.push({
        title: getLabel({
          organisationDetails,
          id: "quote-check-price",
          defaultValue: "Check Price",
        }),
        key: "check-price",
        align: "center",
        width: 120,
        render: (_, row) => {
          if (row.isHourly) {
            return "-";
          } else {
            return window.formatCurrency("GBP", row.checkPrice);
          }
        },
      });
    }
    quoteLineItemColumns.push({
      title: "Amount",
      key: "amount",
      align: "right",
      width: 120,
      render: (_, row) => {
        if (row.isHourly) {
          return "Hourly rate";
        } else {
          return window.formatCurrency("GBP", row.amount);
          // getFormattedAmount({ quote: invoice, lineItem: quoteLineItem, currency: "GBP" });
        }
      },
    });

    return (
      <Card title={quoteLineItemTableTitle} withSpace>
        <Table
          rowKey="id"
          dataSource={quoteTableRows}
          className="quote-line-item-table"
          // @ts-ignore
          columns={quoteLineItemColumns}
          pagination={{ hideOnSinglePage: true, pageSize: 500 }}
        />
      </Card>
    );
  }

  function displayTimesheetsCard(invoiceLineItem) {
    let timesheetBlocksForInvoiceLineItem = getTimesheetBlocksForInvoiceLineItem({
      invoiceLineItem,
      timesheetBlocks: invoiceForDisplay.timesheetBlocks?.items,
    });

    let totalHours = timesheetBlocksForInvoiceLineItem.reduce((sum, item) => {
      return roundToQuarter(roundToQuarter(sum) + roundToQuarter(item.hours));
    }, 0);

    let totalMonetaryValue = timesheetBlocksForInvoiceLineItem.reduce((sum, item) => {
      const hours = roundToQuarter(item.hours);

      return roundToDecimals(roundToDecimals(sum) + roundToDecimals(item.rateWithoutCurrency) * hours);
    }, 0);

    let timesheetBlocksTableTitle = (
      <>
        <Typography.Text className="table-title">Timesheet blocks</Typography.Text>
        <Typography.Text className="table-subtitle">Total hours: {totalHours}</Typography.Text>
        <Typography.Text className="table-subtitle">
          Total monetary value: {window.formatCurrency("GBP", roundToDecimals(totalMonetaryValue, 2))}
        </Typography.Text>
      </>
    );

    return (
      <Card title={timesheetBlocksTableTitle}>
        <TimesheetBlocksTable
          includeActions
          blocks={timesheetBlocksForInvoiceLineItem}
          includeInvoicingStatus={false}
          triggerRefresh={refreshInvoice}
        />
      </Card>
    );
  }

  const lineItemsColumns = [
    {
      title: "",
      dataIndex: "item",
      width: 40,
      align: "center",
      render: (_, __, index) => {
        return (
          <Typography.Text data-cy="line-item-index" data-row-index={index}>
            {index + 1}
          </Typography.Text>
        );
      },
    },
    // {
    //   title: "",
    //   dataIndex: "order",
    //   width: 70,
    //   align: "center",
    // },
    {
      title: "Title",
      dataIndex: "title",
      render: (value, item, index) => {
        let defaultValue = value;
        if (item.quoteLineItem?.isHourly) {
          defaultValue = item.quoteLineItem.title;
        }

        let problems: any[] = [];
        if (item.quoteLineItemId && item.quoteLineItemId !== "nothing" && !item.quoteLineItem) {
          problems.push(
            <Tooltip
              title={
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5rem" }}>
                  {getSimpleLabel("Quote")} line item ID:
                  <br /> {item.quoteLineItemId}
                  <br />
                  <Button
                    onClick={() => {
                      changeLineItem({
                        fieldName: "quoteLineItemId",
                        id: item.id,
                        value: "nothing",
                      });
                    }}
                  >
                    Unlink from {getSimpleLabel("quote")} line item
                  </Button>
                </div>
              }
            >
              <Tag color="red">
                {getSimpleLabel("Quote")} line item not found <QuestionCircleOutlined />
              </Tag>
            </Tooltip>
          );
        }

        return (
          <>
            <ReviewTarget
              name={`invoice-line-item-title-${item.id}`}
              lineItemIndex={index}
              {...props}
              visible={isUnderReview}
              onClick={(e) => e.stopPropagation()}
            >
              {problems && problems.length > 0 && <div className="invoice-line-item-problems">{problems}</div>}
              <Input
                id={`invoice-line-item-title-${item.id}`}
                multiLine
                defaultValue={defaultValue}
                showBorder
                fullWidth
                disabled={isDisabled || item.quoteLineItem?.isHourly}
                data-cy="line-item-title-input"
                data-row-index={index}
                fireOnChangeWithoutBlurWithDebounce
                memoize
                onChange={(newValue) => {
                  changeLineItem({
                    fieldName: "title",
                    id: item.id,
                    value: newValue,
                  });
                }}
              />
            </ReviewTarget>
          </>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (value, item, index) => {
        let defaultValue = value;
        if (item.quoteLineItem?.isHourly) {
          defaultValue = item.quoteLineItem.description;
        }
        return (
          <ReviewTarget
            name={`invoice-line-item-description-${item.id}`}
            lineItemIndex={index}
            {...props}
            visible={isUnderReview}
            onClick={(e) => e.stopPropagation()}
          >
            <Input
              id={`invoice-line-item-description-${item.id}`}
              multiLine
              defaultValue={defaultValue}
              fullWidth
              showBorder
              disabled={isDisabled || item.quoteLineItem?.isHourly}
              data-cy="line-item-description-input"
              data-row-index={index}
              fireOnChangeWithoutBlurWithDebounce
              minRows={3}
              memoize
              onChange={(newValue) => {
                changeLineItem({
                  fieldName: "description",
                  id: item.id,
                  value: newValue,
                });
              }}
            />
          </ReviewTarget>
        );
      },
    },

    organisationDetails.settings?.invoice?.usesPercentagesInsteadOfQuantity
      ? {
          title: "Percentage",
          width: isUnderReview ? 130 : 90,
          render: (_, item, index) => {
            if (item.quoteLineItem?.isHourly || (item.taskId && item.taskId !== "nothing")) {
              return (
                <span data-row-index={index} data-cy="line-item-percentage-value">
                  -
                </span>
              );
            }
            let defaultValue = (item.amount / (item.quoteLineItem?.amount || item.unitPrice + item.checkPrice)) * 100;

            return (
              <ReviewTarget
                name={`invoice-line-item-percentage-${item.id}`}
                lineItemIndex={index}
                {...props}
                visible={isUnderReview}
                onClick={(e) => e.stopPropagation()}
              >
                <InputNumber
                  id={`invoice-line-item-percentage-${item.id}`}
                  min={0}
                  step={0.1}
                  disabled={isDisabled}
                  defaultValue={defaultValue}
                  data-cy="line-item-percentage-input"
                  data-row-index={index}
                  onChange={(percentage) => {
                    // doing this separately just to shut up the linter
                    if (percentage === null) {
                      percentage = 100;
                    }
                    if ([undefined, "", "0", 0].includes(percentage)) {
                      percentage = 100;
                    }
                    let originalQuantity = 1;
                    if (item.quoteLineItem) {
                      originalQuantity = item.quoteLineItem.quantity;
                    }

                    let newQuantity = originalQuantity / (100 / percentage);

                    changeLineItem({
                      fieldName: "quantity",
                      id: item.id,
                      value: newQuantity,
                      includeRecalculation: true,
                      includeQuoteUpdate: !!item.quoteLineItem,
                      quoteId: item?.quoteLineItem?.quoteId,
                    });
                  }}
                />
              </ReviewTarget>
            );
          },
        }
      : {
          title: "Quantity",
          width: isUnderReview ? 130 : 90,
          render: (_, item, index) => {
            if (item.quoteLineItem?.isHourly || (item.taskId && item.taskId !== "nothing")) {
              return (
                <span data-row-index={index} data-cy="line-item-quantity-value">
                  -
                </span>
              );
            }
            return (
              <ReviewTarget
                name={`invoice-line-item-quantity-${item.id}`}
                lineItemIndex={index}
                {...props}
                visible={isUnderReview}
                onClick={(e) => e.stopPropagation()}
              >
                <Input
                  id={`invoice-line-item-quantity-${item.id}`}
                  min={1}
                  fullWidth
                  showBorder
                  numerical
                  allowDecimals
                  disabled={isDisabled}
                  defaultValue={item.quantity}
                  data-cy="line-item-quantity-input"
                  data-row-index={index}
                  memoize
                  fireOnChangeWithoutBlurWithDebounce
                  debounceDelay={500}
                  onChange={(newValue) => {
                    if (String(newValue).length === 0) {
                      newValue = "1";
                    }

                    changeLineItem({
                      fieldName: "quantity",
                      id: item.id,
                      value: parseFloat(newValue),
                      includeRecalculation: true,
                      includeQuoteUpdate: !!item.quoteLineItem,
                      quoteId: item?.quoteLineItem?.quoteId,
                    });
                  }}
                />
              </ReviewTarget>
            );
          },
        },

    organisationDetails.settings?.invoice?.usesPercentagesInsteadOfQuantity
      ? {
          title: "Quoted amount",
          width: isUnderReview ? 100 : 100,
          render: (_, item, index) => {
            if (item.quoteLineItem?.isHourly || !item.quoteLineItem || (item.taskId && item.taskId !== "nothing")) {
              return (
                <span data-row-index={index} data-cy="line-item-quoted-amount-value">
                  -
                </span>
              );
            }

            if (item.quoteLineItem) {
              return (
                <span data-row-index={index} data-cy="line-item-quoted-amount-value">
                  {window.formatCurrency("GBP", item.quoteLineItem.amount)}
                </span>
              );
            }

            return (
              <ReviewTarget
                name={`invoice-line-item-quoted-amount-${item.id}`}
                lineItemIndex={index}
                {...props}
                visible={isUnderReview}
                onClick={(e) => e.stopPropagation()}
              >
                <Input
                  id={`invoice-line-item-quoted-amount-${item.id}`}
                  fullWidth
                  showBorder
                  data-cy="line-item-quoted-amount-input"
                  data-row-index={index}
                  disabled={isDisabled}
                />
              </ReviewTarget>
            );
          },
        }
      : {
          title: "Unit price",
          width: isUnderReview ? 140 : 100,
          render: (_, item, index) => {
            if (item.quoteLineItem?.isHourly || (item.taskId && item.taskId !== "nothing")) {
              return (
                <span data-row-index={index} data-cy="line-item-unit-price-value">
                  -
                </span>
              );
            }

            if (item.quoteLineItem) {
              return (
                <span data-row-index={index} data-cy="line-item-unit-price-value">
                  {window.formatCurrency("GBP", item.quoteLineItem.unitPrice + item.quoteLineItem.checkPrice)}
                </span>
              );
            }

            return (
              <ReviewTarget
                name={`invoice-line-item-unit-price-${item.id}`}
                lineItemIndex={index}
                {...props}
                visible={isUnderReview}
                onClick={(e) => e.stopPropagation()}
              >
                <Input
                  id={`invoice-line-item-unit-price-${item.id}`}
                  fullWidth
                  showBorder
                  data-cy="line-item-unit-price-input"
                  data-row-index={index}
                  disabled={isDisabled}
                  defaultValue={item.unitPrice || "0"}
                  fireOnChangeWithoutBlurWithDebounce
                  debounceDelay={500}
                  memoize
                  onChange={(newValue) => {
                    changeLineItem({
                      fieldName: "unitPrice",
                      id: item.id,
                      value: parseFloat(newValue),
                      includeRecalculation: true,
                      includeQuoteUpdate: !!item.quoteLineItem,
                      quoteId: item?.quoteLineItem?.quoteId,
                    });
                  }}
                />
              </ReviewTarget>
            );
          },
        },

    {
      title: "Amount",
      dataIndex: "",
      width: 100,
      align: "right",
      className: "amount-column",
      render: (_, lineItem, index) => {
        let text = window.formatCurrency("GBP", lineItem.amount);

        return (
          <Typography.Text data-cy="line-item-amount" data-row-index={index}>
            {text}
          </Typography.Text>
        );
      },
    },
    {
      title: "",
      width: 300,
      render: (_, item, index) => {
        return displayActionsForInvoiceLineItem(item, index);
      },
    },
  ];

  let dataSource = invoiceForDisplay.lineItems.items.filter((item) => !deletedLineItemIds.includes(item.id));

  let allLineItemsHaveOrderField = dataSource.every((item) => item.order !== null && item.order !== undefined);

  if (allLineItemsHaveOrderField) {
    dataSource = dataSource.sort((a, b) => (a.order < b.order ? -1 : 1));
  }

  return (
    <Table
      rowClassName={(lineItem) => {
        let isHighlighted = false;
        let classes: string[] = [];
        if (urlParams.has("lineItem")) {
          if (urlParams.getAll("lineItem").includes(lineItem.id)) {
            isHighlighted = true;
          }
        }
        if (isHighlighted) {
          classes.push("line-item-highlighted");
        }

        return cx(classes);
      }}
      rowKey="id"
      dataSource={dataSource}
      // @ts-ignore
      columns={lineItemsColumns}
      pagination={{ hideOnSinglePage: true, pageSize: 500 }}
      expandable={{
        expandedRowRender: displayDetailsPerLineItem,
        rowExpandable: (record) => record.name !== "Not Expandable",
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <Button icon={<DownOutlined />} onClick={(e) => onExpand(record, e)} />
          ) : (
            <Button icon={<RightOutlined />} onClick={(e) => onExpand(record, e)} />
          ),
      }}
      expandRowByClick={true}
    />
  );
}
