import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Typography, Input, Checkbox, Dropdown, Select, Menu } from "antd";
import query from "query-string";
import { PlusCircleOutlined, SearchOutlined, DownOutlined } from "@ant-design/icons";

import { getLabel } from "common/helpers";
import { COOKIE_NAME_PROJECT_FILTERS } from "common/constants";
import withSubscriptions from "common/withSubscriptions";
import { isAuthorised } from "common/permissions";

import AvatarList from "../AvatarList/AvatarList";
import CreateProjectModal from "CreateProjectModal/CreateProjectModal";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";

import "./ProjectFilters.scss";
import { getSimpleLabel } from "common/labels";

export class ProjectFilters extends React.Component {
  state = {
    containsText: "",
    selectedUsers: [],
    clientId: null,
    sortBy: "title",
    includeFinishedTasks: false,
    includeArchivedProjects: false,
    includeFinishedProjects: false,
    includeArchivedTasks: false,
    includeProjectsNoActiveTasks: true,
    includeProjectsNoAcceptedQuotes: true,
    isCreateProjectModalVisible: false,
    isDropdownVisible: false,
  };

  async componentDidMount() {
    const { location, sprints, apiUser } = this.props;

    const queryParams = query.parse(location.search);
    const backlog = [...sprints].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))[0];
    let newState = {};

    const cookieValue = await window.localDatabase.getItem(
      `${COOKIE_NAME_PROJECT_FILTERS}-${this.props.organisationDetails.id}`
    );

    if (cookieValue) {
      try {
        const cookieValueParsed = JSON.parse(cookieValue);
        const { isDropdownVisible, isCreateProjectModalVisible, ...existingFilters } = cookieValueParsed;
        newState = existingFilters;
      } catch (e) {
        console.error("Failed to parse local database value for ProjectFilters:", cookieValue);
      }
    }

    if (queryParams.containsText) {
      newState.containsText = queryParams.containsText;
    }

    if (queryParams.sprintId) {
      newState.sprintId = queryParams.sprintId;
    } else if (location.pathname === "/") {
      const activeSprint = sprints.find((x) => x.organisation === apiUser.organisation && x.isActive && !x.isFinished);
      if (activeSprint) {
        newState.sprintId = activeSprint.id;
      } else {
        newState.sprintId = backlog.id;
      }
    }

    if (queryParams.selectedUsers) {
      if (Array.isArray(queryParams.selectedUsers)) {
        newState.selectedUsers = queryParams.selectedUsers;
      } else {
        newState.selectedUsers = [queryParams.selectedUsers];
      }
    }

    if (newState.selectedUsers) {
      // eliminate users that are not found in the users array
      newState.selectedUsers = newState.selectedUsers.filter((userId) =>
        this.props.users.find((user) => user.id === userId)
      );
    }

    if (queryParams.includeArchivedProjects) {
      newState.includeArchivedProjects = queryParams.includeArchivedProjects === "true";
    }

    if (queryParams.includeFinishedProjects) {
      newState.includeFinishedProjects = queryParams.includeFinishedProjects === "true";
    }

    if (queryParams.includeArchivedTasks) {
      newState.includeArchivedTasks = queryParams.includeArchivedTasks === "true";
    }

    if (queryParams.includeFinishedTasks) {
      newState.includeFinishedTasks = queryParams.includeFinishedTasks === "true";
    }

    if (queryParams.includeProjectsNoActiveTasks) {
      newState.includeProjectsNoActiveTasks = queryParams.includeProjectsNoActiveTasks === "true";
    }

    if (queryParams.includeProjectsNoAcceptedQuotes) {
      newState.includeProjectsNoAcceptedQuotes = queryParams.includeProjectsNoAcceptedQuotes === "true";
    }

    if (queryParams.clientId) {
      newState.clientId = queryParams.clientId;
    }

    this.setState(newState, this.onChange);
  }

  onChange = () => {
    const {
      selectedUsers,
      containsText,
      clientId,
      includeArchivedProjects,
      includeFinishedProjects,
      includeProjectsNoActiveTasks,
      includeProjectsNoAcceptedQuotes,
      includeArchivedTasks,
      includeFinishedTasks,
      sortBy,
    } = this.state;

    const { isCreateProjectModalVisible, isDropdownVisible, ...stateForQueryParams } = this.state;

    const { history, location, tasks, quotes } = this.props;

    const nonNullKeys = Object.keys(stateForQueryParams).filter(
      (keyName) =>
        stateForQueryParams[keyName] !== null &&
        stateForQueryParams[keyName] !== undefined &&
        stateForQueryParams[keyName] !== "" &&
        stateForQueryParams[keyName] !== false
    );
    const queryParams = {};
    nonNullKeys.forEach((keyName) => {
      queryParams[keyName] = stateForQueryParams[keyName];
    });
    const queryString = query.stringify(queryParams);

    history.replace(`${location.pathname}?${queryString}`);

    let filter = {
      callbacks: [],
      sortBy,
      includeArchivedTasks,
      includeFinishedTasks,
    };

    if (!includeArchivedProjects) {
      filter.callbacks.push((project) => !project.isArchived);
    }

    if (!includeFinishedProjects) {
      filter.callbacks.push((project) => !project.isFinished);
    }

    if (!includeProjectsNoActiveTasks) {
      filter.callbacks.push((project) => {
        let activeTasksInProject = tasks.filter(
          (x) => x.projectId === project.id && !x.isArchived && !x.isFinished && !x.isExternalReference
        );
        return activeTasksInProject.length > 0;
      });
    }

    if (!includeProjectsNoAcceptedQuotes) {
      filter.callbacks.push((project) => {
        let acceptedQuotesInProject = quotes.filter(
          (x) => x.projectId === project.id && !x.isArchived && x.status === "ACCEPTED"
        );
        return acceptedQuotesInProject.length > 0;
      });
    }

    if (clientId) {
      filter.callbacks.push((project) => {
        return project.clientId === clientId;
      });
    }

    if (selectedUsers && selectedUsers.length > 0) {
      filter.callbacks.push((task) => {
        if (selectedUsers.includes("unassigned") && !task.assignedTo) {
          return true;
        } else if (selectedUsers.includes(task.assignedTo)) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (containsText) {
      filter.containsText = {
        contains: (containsText || "").toLowerCase(),
      };
    }

    window.localDatabase.setItem(
      `${COOKIE_NAME_PROJECT_FILTERS}-${this.props.organisationDetails.id}`,
      JSON.stringify(this.state)
    );

    this.props.onChange(filter);
  };

  onUserSelected = (_, user) => {
    if (!user) {
      user = { id: "unassigned" };
    }
    let selectedUsers = this.state.selectedUsers || [];
    if (selectedUsers.includes(user.id)) {
      selectedUsers = selectedUsers.filter((x) => x !== user.id);
    } else {
      selectedUsers.push(user.id);
    }
    this.setState({ selectedUsers }, this.onChange);
  };

  render() {
    const {
      containsText,
      selectedUsers,
      includeArchivedProjects,
      includeFinishedProjects,
      includeArchivedTasks,
      includeFinishedTasks,
      includeProjectsNoActiveTasks,
      includeProjectsNoAcceptedQuotes,
      isCreateProjectModalVisible,
      isDropdownVisible,
      clientId,
      sortBy,
    } = this.state;

    const { clients, orderedActiveUsers, apiUser } = this.props;

    const filterMenu = (
      <Menu>
        <Menu.Item>
          <Checkbox
            checked={includeArchivedProjects}
            onChange={(e) => this.setState({ includeArchivedProjects: e.target.checked }, this.onChange)}
          >
            Include archived{" "}
            {getLabel({
              organisationDetails: this.props.organisationDetails,
              id: "projects",
              defaultValue: "projects",
            })}
          </Checkbox>
        </Menu.Item>

        <Menu.Item>
          <Checkbox
            checked={includeFinishedProjects}
            onChange={(e) => this.setState({ includeFinishedProjects: e.target.checked }, this.onChange)}
            data-cy="include-finished-projects-checkbox"
          >
            Include finished{" "}
            {getLabel({
              organisationDetails: this.props.organisationDetails,
              id: "projects",
              defaultValue: "projects",
            })}
          </Checkbox>
        </Menu.Item>

        <Menu.Item>
          <Checkbox
            checked={includeArchivedTasks}
            onChange={(e) => this.setState({ includeArchivedTasks: e.target.checked }, this.onChange)}
          >
            Include archived{" "}
            {getLabel({
              organisationDetails: this.props.organisationDetails,
              id: "tasks",
              defaultValue: "tasks",
            })}
          </Checkbox>
        </Menu.Item>
        <Menu.Item>
          <Checkbox
            checked={includeFinishedTasks}
            onChange={(e) => this.setState({ includeFinishedTasks: e.target.checked }, this.onChange)}
          >
            Include finished{" "}
            {getLabel({
              organisationDetails: this.props.organisationDetails,
              id: "tasks",
              defaultValue: "tasks",
            })}
          </Checkbox>
        </Menu.Item>
        <Menu.Item>
          <Checkbox
            checked={includeProjectsNoActiveTasks}
            onChange={(e) => this.setState({ includeProjectsNoActiveTasks: e.target.checked }, this.onChange)}
          >
            Include{" "}
            {getLabel({
              organisationDetails: this.props.organisationDetails,
              id: "projects",
              defaultValue: "projects",
            })}{" "}
            without active{" "}
            {getLabel({
              organisationDetails: this.props.organisationDetails,
              id: "tasks",
              defaultValue: "tasks",
            })}
          </Checkbox>
        </Menu.Item>
        <Menu.Item>
          <Checkbox
            checked={includeProjectsNoAcceptedQuotes}
            onChange={(e) => this.setState({ includeProjectsNoAcceptedQuotes: e.target.checked }, this.onChange)}
          >
            Include{" "}
            {getLabel({
              organisationDetails: this.props.organisationDetails,
              id: "projects",
              defaultValue: "projects",
            })}{" "}
            without accepted{" "}
            {getLabel({
              organisationDetails: this.props.organisationDetails,
              id: "quotes",
              defaultValue: "quotes",
            })}
          </Checkbox>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item className="flex-menu-item">
          <Typography.Text className="left-label">{getSimpleLabel("Client")}:</Typography.Text>

          <Select
            showSearch
            allowClear
            placeholder={`Choose a ${getSimpleLabel("client")}`}
            className="project-filter-client"
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={(clientId) => {
              this.setState({ clientId }, this.onChange);
            }}
            value={clientId}
          >
            {clients.map((client, i) => (
              <Select.Option key={i} value={client.id}>
                {client.name}
              </Select.Option>
            ))}
          </Select>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="project-filters">
        <Input
          className="filter-input"
          placeholder={`Filter ${getLabel({
            organisationDetails: this.props.organisationDetails,
            id: "project",
            defaultValue: "project",
          })} list`}
          allowClear
          prefix={<SearchOutlined />}
          onChange={(e) => this.setState({ containsText: e.target.value }, this.onChange)}
          value={containsText}
        />
        <AvatarList
          users={orderedActiveUsers}
          onClick={this.onUserSelected}
          selectedUsers={selectedUsers}
          containerWidthToSubtract={1050}
        />

        <Dropdown
          trigger={["click"]}
          overlayClassName="project-filters-dropdown-overlay"
          overlay={filterMenu}
          visible={isDropdownVisible}
          onVisibleChange={(isDropdownVisible) => this.setState({ isDropdownVisible })}
        >
          <Button className="filter-button" data-cy="project-filters-button">
            Filters
            <DownOutlined />
          </Button>
        </Dropdown>

        <div className="sort-filters">
          <Typography.Text className="sort-by-label">Sort by: </Typography.Text>
          <Select
            placeholder="Choose a sorting type"
            className="project-filter-sort"
            onChange={(type) => {
              this.setState({ sortBy: type }, this.onChange);
            }}
            value={sortBy}
          >
            <Select.Option key={"title"} value={"title"}>
              Title
            </Select.Option>
            <Select.Option key={"id"} value={"id"}>
              ID
            </Select.Option>
          </Select>
        </div>

        {isAuthorised(["EXPORT_PROJECTS"]) && (
          <Button className="export" onClick={this.props.export} data-cy="export-to-csv-button">
            Export to CSV
          </Button>
        )}

        {this.props.includeCreateProject === false ? null : (
          <ButtonWithPermissions
            type="primary"
            className="create-project"
            permissions={["CREATE_PROJECT"]}
            onClick={() => this.setState({ isCreateProjectModalVisible: true })}
            data-cy="create-project-button"
          >
            <PlusCircleOutlined /> Create{" "}
            {getLabel({
              organisationDetails: this.props.organisationDetails,
              id: "project",
              defaultValue: "project",
            })}
          </ButtonWithPermissions>
        )}

        {isCreateProjectModalVisible && (
          <CreateProjectModal onClose={() => this.setState({ isCreateProjectModalVisible: false })} apiUser={apiUser} />
        )}
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: ProjectFilters,
    subscriptions: ["organisationDetails", "sprints", "tasks", "users", "clients", "projects", "quotes"],
  })
);
