import moment from "moment";
import { roundToQuarter } from "common/mathHelpers";

import {
  getWorkingHoursRuleForUserOnDate,
  getTotalHoursForWorkingHoursRule,
  getTotalBreakHoursForWorkingHoursRule,
} from "common/workingHoursHelpers";

import { callbackAllTimesheetBlocksInPeriod } from "../organisation";

function getFormFile({ task, startsWith }) {
  for (let crtTaskRevision of task?.revisions?.items) {
    for (let crtFile of crtTaskRevision?.files?.items) {
      if (crtFile?.name.toLowerCase().startsWith(startsWith)) {
        return [crtFile];
      }
    }
  }
  return [];
}

const fields = [
  {
    id: "files_radixClientDocumentsForms",
    fieldTypes: ["repeatFor"],
    label: "RADIX - Client documents forms",
    repeatForFieldName: "file",
    value: ({ task }) => {
      return getFormFile({ startsWith: "client documents", task });
    },
  },
  {
    id: "files_radixSurveyOfficeForms",
    fieldTypes: ["repeatFor"],
    label: "RADIX - Survey (office) forms",
    repeatForFieldName: "file",
    value: ({ task }) => {
      return getFormFile({ startsWith: "survey info (office)", task });
    },
  },
  {
    id: "files_radixSurveyOnSiteForms",
    fieldTypes: ["repeatFor"],
    label: "RADIX - Survey (on site) forms",
    repeatForFieldName: "file",
    value: ({ task }) => {
      return getFormFile({ startsWith: "survey info (on site)", task });
    },
  },
  {
    id: "files_radixTestingInformationPackOnSiteForms",
    fieldTypes: ["repeatFor"],
    label: "RADIX - Testing information pack (office) forms",
    repeatForFieldName: "file",
    value: ({ task }) => {
      return getFormFile({ startsWith: "testing information pack (office)", task });
    },
  },
  ///////////////////////////// START timesheet report /////////////////////////////////////////////
  {
    id: "radixTimesheetBlocksInPeriod",
    label: "Each timesheet block between start and end date",
    fieldTypes: ["repeatFor"],
    repeatForFieldName: "timesheetBlock",

    value: (props) => {
      const { form } = props;
      let startDateFieldDetails;
      let endDateFieldDetails;
      for (let fieldId in form.fields) {
        let field = form.fields[fieldId];
        if (field.id?.toLowerCase()?.startsWith("startdate")) {
          startDateFieldDetails = field;
        }
        if (field.id?.toLowerCase()?.startsWith("enddate")) {
          endDateFieldDetails = field;
        }
      }
      if (!startDateFieldDetails || !endDateFieldDetails) {
        return [];
      }
      return callbackAllTimesheetBlocksInPeriod({
        ...props,
        startDate: startDateFieldDetails.value,
        endDate: endDateFieldDetails.value,
      });
    },
  },
  {
    id: "radixTimesheetReportScheduledHours",
    label: "RADIX - scheduled total hours excluding break (timesheet report)",
    value: (props) => {
      const { user, groups, workingHoursRules, period } = props;
      let result = 0;
      for (let day of period.days) {
        const workingHoursRule = getWorkingHoursRuleForUserOnDate({
          userId: user.id,
          groups,
          workingHoursRules,
          date: day,
        });

        let scheduledTotalHours = getTotalHoursForWorkingHoursRule(workingHoursRule);
        let scheduledBreakHours = getTotalBreakHoursForWorkingHoursRule(workingHoursRule);
        result += roundToQuarter(scheduledTotalHours - scheduledBreakHours);
      }

      return result;
    },
  },
  {
    id: "radixTimesheetReportScheduledBreakHours",
    label: "RADIX - scheduled break hours (timesheet report)",
    value: (props) => {
      const { user, groups, workingHoursRules, period } = props;
      let result = 0;
      for (let day of period.days) {
        const workingHoursRule = getWorkingHoursRuleForUserOnDate({
          userId: user.id,
          groups,
          workingHoursRules,
          date: day,
        });

        result += getTotalBreakHoursForWorkingHoursRule(workingHoursRule);
      }
      return result;
    },
  },
  {
    id: "radixTimesheetReportHoursWorkedInDay",
    label: "RADIX - hours worked in day (timesheet report)",
    value: async (props) => {
      const { timesheetBlocks, period, user } = props;
      if (!timesheetBlocks) {
        return 0;
      }
      let result = 0;
      for (let day of period.days) {
        let timesheetBlocksForUserOnDate = timesheetBlocks.filter((timesheetBlock) => {
          return timesheetBlock.userId === user.id && moment(timesheetBlock.startAt).format("YYYY-MM-DD") === day;
        });

        let hoursWorked = timesheetBlocksForUserOnDate.reduce((sum, block) => {
          let endAt = block.isRecording ? moment() : moment(block.endAt);
          let durationHours = moment(endAt).diff(moment(block.startAt), "hours", true);
          return sum + durationHours;
        }, 0);

        result += roundToQuarter(hoursWorked);
      }
      return result;
    },
  },
  {
    id: "radixTimesheetReportVariance",
    label: "RADIX - variance between scheduled hours and worked hours (timesheet report)",
    value: async (props) => {
      const { timesheetBlocks, period, user, groups, workingHoursRules } = props;
      if (!timesheetBlocks) {
        return;
      }

      let result = 0;
      for (let day of period.days) {
        const workingHoursRule = getWorkingHoursRuleForUserOnDate({
          userId: user.id,
          groups,
          workingHoursRules,
          date: day,
        });

        let scheduledTotalHours = getTotalHoursForWorkingHoursRule(workingHoursRule);
        let scheduledBreakHours = getTotalBreakHoursForWorkingHoursRule(workingHoursRule);
        let scheduledWorkHours = roundToQuarter(scheduledTotalHours - scheduledBreakHours);

        let timesheetBlocksForUserOnDate = timesheetBlocks.filter((timesheetBlock) => {
          return timesheetBlock.userId === user.id && moment(timesheetBlock.startAt).format("YYYY-MM-DD") === day;
        });

        let hoursWorked = timesheetBlocksForUserOnDate.reduce((sum, block) => {
          let endAt = block.isRecording ? moment() : moment(block.endAt);
          let durationHours = moment(endAt).diff(moment(block.startAt), "hours", true);
          return sum + durationHours;
        }, 0);

        hoursWorked = roundToQuarter(hoursWorked);

        result += roundToQuarter(hoursWorked - scheduledWorkHours);
      }
      return result;
    },
  },
  {
    id: "radixTimesheetReportClockInTimeOnDay",
    label: "RADIX - clock in time on day (timesheet report)",
    value: async (props) => {
      const { timesheetBlocks, period, user } = props;
      if (!timesheetBlocks) {
        return;
      }

      if (!period.days?.length || period.days.length > 1) {
        return;
      }

      let day = period.days[0];

      let timesheetBlocksForUserOnDate = timesheetBlocks.filter((timesheetBlock) => {
        return timesheetBlock.userId === user.id && moment(timesheetBlock.startAt).format("YYYY-MM-DD") === day;
      });

      if (!timesheetBlocksForUserOnDate.length) {
        return;
      }

      timesheetBlocksForUserOnDate.sort((a, b) => {
        return a.startAt < b.startAt ? -1 : 1;
      });

      return moment(timesheetBlocksForUserOnDate[0].startAt).format("HH:mm");
    },
  },
  {
    id: "radixTimesheetReportClockOutTimeOnDay",
    label: "RADIX - clock out time on day (timesheet report)",
    value: async (props) => {
      const { timesheetBlocks, period, user } = props;
      if (!timesheetBlocks) {
        return 0;
      }

      if (!period.days?.length || period.days.length > 1) {
        return;
      }

      let day = period.days[0];

      let timesheetBlocksForUserOnDate = timesheetBlocks.filter((timesheetBlock) => {
        return timesheetBlock.userId === user.id && moment(timesheetBlock.startAt).format("YYYY-MM-DD") === day;
      });

      if (!timesheetBlocksForUserOnDate.length) {
        return;
      }

      timesheetBlocksForUserOnDate.sort((a, b) => {
        return a.endAt < b.endAt ? -1 : 1;
      });

      let anyBlocksAreStillRecording = timesheetBlocksForUserOnDate.some((block) => block.isRecording);

      if (anyBlocksAreStillRecording) {
        return "Still recording";
      }

      let lastBlock = timesheetBlocksForUserOnDate.slice(-1)[0];

      // if last block ends in the future, it's still recording
      if (moment(lastBlock.endAt).isAfter(moment())) {
        return "Still recording";
      }

      return moment(lastBlock.endAt).format("HH:mm");
    },
  },
  {
    id: "radixTimesheetReportOvernightStayOnDay",
    label: "RADIX - overnight stay on day (timesheet report)",
    value: async (props) => {
      const { timesheetBlocks, period, user } = props;
      if (!timesheetBlocks) {
        return 0;
      }

      if (!period.days?.length || period.days.length > 1) {
        return;
      }

      let day = period.days[0];

      let timesheetBlocksForUserOnDate = timesheetBlocks.filter((timesheetBlock) => {
        return timesheetBlock.userId === user.id && moment(timesheetBlock.startAt).format("YYYY-MM-DD") === day;
      });

      if (!timesheetBlocksForUserOnDate.length) {
        return;
      }

      let hasTag = timesheetBlocksForUserOnDate.some((block) => {
        return block.tagsReadable?.includes("Overnight stay");
      });

      return hasTag ? "Yes" : "";
    },
  },
  {
    id: "radixTimesheetReportWorkedThroughBreakOnDay",
    label: "RADIX - worked through break on day (timesheet report)",
    value: async (props) => {
      const { timesheetBlocks, period, user } = props;
      if (!timesheetBlocks) {
        return 0;
      }

      if (!period.days?.length || period.days.length > 1) {
        return;
      }

      let day = period.days[0];

      let timesheetBlocksForUserOnDate = timesheetBlocks.filter((timesheetBlock) => {
        return timesheetBlock.userId === user.id && moment(timesheetBlock.startAt).format("YYYY-MM-DD") === day;
      });

      if (!timesheetBlocksForUserOnDate.length) {
        return;
      }

      let hasTag = timesheetBlocksForUserOnDate.some((block) => {
        return block.tagsReadable?.includes("Worked through break");
      });

      return hasTag ? "Yes" : "";
    },
  },
  {
    id: "radixTimesheetReportJobOnDay",
    label: "RADIX - job on day (timesheet report)",
    value: async (props) => {
      const { timesheetBlocks, period, user, tasksWithRevisions } = props;
      if (!timesheetBlocks) {
        return 0;
      }

      if (!period.days?.length || period.days.length > 1) {
        return;
      }

      let day = period.days[0];

      let timesheetBlocksForUserOnDate = timesheetBlocks.filter((timesheetBlock) => {
        return timesheetBlock.userId === user.id && moment(timesheetBlock.startAt).format("YYYY-MM-DD") === day;
      });

      if (!timesheetBlocksForUserOnDate.length) {
        return;
      }

      timesheetBlocksForUserOnDate.sort((a, b) => {
        return a.startAt < b.startAt ? -1 : 1;
      });

      let firstTaskDetails;
      let firstTaskRevisionDetails;

      for (let timesheetBlock of timesheetBlocksForUserOnDate) {
        if (timesheetBlock.taskId) {
          firstTaskDetails = tasksWithRevisions.find((task) => task.id === timesheetBlock.taskId);
          if (firstTaskDetails) {
            firstTaskRevisionDetails = firstTaskDetails.revisions.items.find(
              (revision) => revision.id === timesheetBlock.taskRevisionId
            );
            break;
          }
        }
      }

      return `${firstTaskDetails?.title} \n ${firstTaskRevisionDetails?.name}`;
    },
  },
  {
    id: "radixTimesheetReportNotesOnDay",
    label: "RADIX - notes on day (timesheet report)",
    value: async (props) => {
      const { timesheetBlocks, period, user } = props;
      if (!timesheetBlocks) {
        return 0;
      }

      if (!period.days?.length || period.days.length > 1) {
        return;
      }

      let day = period.days[0];

      let timesheetBlocksForUserOnDate = timesheetBlocks.filter((timesheetBlock) => {
        return timesheetBlock.userId === user.id && moment(timesheetBlock.startAt).format("YYYY-MM-DD") === day;
      });

      if (!timesheetBlocksForUserOnDate.length) {
        return;
      }

      timesheetBlocksForUserOnDate.sort((a, b) => {
        return a.startAt < b.startAt ? -1 : 1;
      });

      let compiledNotes = timesheetBlocksForUserOnDate
        .filter((timesheetBlock) => {
          return timesheetBlock.description;
        })
        .map((timesheetBlock) => {
          return timesheetBlock.description;
        })
        .join("\n");

      return compiledNotes;
    },
  },
  {
    id: "radixTimesheetReportPeriodLabel",
    label: "Timesheet period label",
    value: ({ period }) => {
      return period.label;
    },
  },
  {
    id: "radixTimesheetReportEachPeriod",
    label: "Each period in timesheet report",
    fieldTypes: ["repeatFor"],
    repeatForFieldName: "period",
    value: ({ form }) => {
      let aggregateField = form.fields["aggregatedataby-1728375850507"];
      let startDateField = form.fields["startdate-1727506469965"];
      let startDateValue = startDateField.value;

      let endDateField = form.fields["enddate-1727506476597"];
      let endDateValue = endDateField.value;

      if (!startDateValue || !endDateValue) {
        return [];
      }

      let startDate = moment(startDateValue);
      let endDate = moment(endDateValue);

      let periods = [];

      let increment;
      switch (aggregateField.value) {
        case "Day":
          increment = 1;
          break;
        case "Week":
          increment = 7;
          break;
        case "Entire period":
          // number of days between start and end date
          increment = moment(endDate).diff(moment(startDate), "days") + 1;
          break;
        default:
          increment = 1;
      }

      let periodEndDate = moment(startDate).add(increment, "days");

      for (
        let periodStartDate = moment(startDate);
        periodStartDate.isSameOrBefore(endDate);
        periodStartDate.add(increment, "day")
      ) {
        let label;
        switch (aggregateField.value) {
          case "Day":
            label = periodStartDate.format("DD-MM-YYYY");
            break;
          case "Week":
            label = `week ending on ${moment(periodStartDate).add(increment, "days").format("DD-MM-YYYY")}`;
            break;
          case "Entire period":
            label = `period: ${startDate.format("DD-MM-YYYY")} to ${moment(endDate).format("DD-MM-YYYY")}`;
            break;
          default:
            label = periodStartDate.format("DD-MM-YYYY");
        }
        let period = {
          label,
          startDate: periodStartDate.format("YYYY-MM-DD"),
          endDate: periodEndDate.format("YYYY-MM-DD"),
          days: [],
        };
        for (
          let dateInPeriod = moment(periodStartDate);
          dateInPeriod.isSameOrBefore(periodEndDate);
          dateInPeriod.add(1, "day")
        ) {
          period.days.push(dateInPeriod.format("YYYY-MM-DD"));
        }
        periods.push(period);
      }

      return periods;
    },
  },
];

export function getFields() {
  return fields;
}
