import { useState, useEffect } from "react";
import { Typography, Table, Modal } from "antd";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";
import { EditOutlined, PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";

import { callGraphQLSimple } from "common/apiHelpers";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";

import AddAllowanceModal from "Modals/AddAllowanceModal/AddAllowanceModal";

import "./UserAllowances.scss";

export default function UserAllowances({
  userData,
  allowanceDefinition,
  selectedUser = undefined,
  setSelectedUser = undefined,
}) {
  const [intervals, setIntervals] = useState(undefined);
  const [isLoadingIntervals, setIsLoadingIntervals] = useState(true);
  const [isAddAllowanceModalVisible, setIsAddAllowanceModalVisible] = useState(false);
  const [selectedAllowanceType, setSelectedAllowanceType] = useState();
  const [selectedAllowance, setSelectedAllowance] = useState();
  const [isEditingAllowance, setIsEditingAllowance] = useState(false);

  useEffect(() => {
    fetchAndSetIntervals();
  }, []);

  let targetIntervals = intervals?.filter((interval) => interval.type === allowanceDefinition.intervalType);

  async function fetchAndSetIntervals() {
    setIsLoadingIntervals(true);
    const intervals = (
      await callGraphQLSimple({
        message: "Failed to fetch allowance intervals",
        query: "listIntervalsByOrganisation",
        variables: {
          organisation: userData.organisation,
          limit: 1000,
        },
      })
    ).data.listIntervalsByOrganisation.items;
    setIntervals(intervals.sort((a, b) => (a.startDate < b.startDate ? -1 : 1)));
    setIsLoadingIntervals(false);
  }

  function displayAllowanceDetailsPerUser(userData, allowanceDefinition) {
    const tableData = userData.allowances?.filter((allowance) => allowance.type === allowanceDefinition.type);

    let weNeedToDisplayYear = tableData?.some((allowance) => !allowance.intervalId);

    return (
      <div className="allowance-table-container">
        <div className="allowance-table-header">
          <Typography.Text className="allowance-table-header-title">
            {allowanceDefinition.label?.substring(0, allowanceDefinition.label?.length - 1)} allowances
          </Typography.Text>
          <ButtonWithPermissions
            permissions={["USERS.ALLOWANCES.EDIT"]}
            type="primary"
            onClick={() => {
              if (setSelectedUser && typeof setSelectedUser === "function") {
                setSelectedUser(userData);
              }
              setIsAddAllowanceModalVisible(true);
              setSelectedAllowanceType(allowanceDefinition.type);
            }}
            data-cy={`add-${allowanceDefinition.type.toLowerCase()}-allowance-button`}
            disabled={!targetIntervals || targetIntervals.length === 0}
          >
            <PlusCircleOutlined />
            Add {allowanceDefinition.type.toLowerCase()} allowance
          </ButtonWithPermissions>
        </div>
        <Table
          loading={isLoadingIntervals}
          pagination={{ hideOnSinglePage: true, pageSize: 50 }}
          expandRowByClick={true}
          columns={[
            {
              title: "Interval",
              dataIndex: "intervalId",
              key: "interval",
              render: (value, _, index) => {
                const interval = intervals?.find((interval) => interval.id === value);
                return (
                  <Typography.Text data-cy={`${allowanceDefinition.type}-interval`} data-index={index}>
                    {interval?.name || "(Interval not found)"}
                  </Typography.Text>
                );
              },
            },
            weNeedToDisplayYear && {
              title: "Year",
              dataIndex: "year",
              key: "year",
            },
            {
              title: "Number of days",
              dataIndex: "allowance",
              key: "allowance",

              render: (value, record, index) => {
                return (
                  <Typography.Text data-cy={`${allowanceDefinition.type}-allowance`} data-index={index}>{`${
                    value / 8
                  } days`}</Typography.Text>
                );
              },
            },
            {
              title: "Actions",
              key: "actions",
              width: "33%",
              render: (_, allowance, index) => {
                return (
                  <div className="allowances-actions">
                    <>
                      <ButtonWithPermissions
                        permissions={["USERS.ALLOWANCES.EDIT"]}
                        onClick={() => {
                          setSelectedAllowance(allowance);
                          if (setSelectedUser && typeof setSelectedUser === "function") {
                            setSelectedUser(userData);
                          }
                          setSelectedAllowanceType(allowance.type);
                          setIsEditingAllowance(true);
                          setIsAddAllowanceModalVisible(true);
                        }}
                        className="allowance-actions-icon"
                        data-cy="edit-allowance-button"
                        data-index={index}
                      >
                        <EditOutlined />
                      </ButtonWithPermissions>
                      <ButtonWithPermissions
                        permissions={["USERS.ALLOWANCES.EDIT"]}
                        onClick={() => {
                          deleteUserAllowance(userData, allowance);
                        }}
                        className="allowance-actions-icon"
                        data-cy="delete-allowance-button"
                        data-index={index}
                      >
                        <DeleteOutlined />
                      </ButtonWithPermissions>
                    </>
                  </div>
                );
              },
            },
          ].filter((x) => x)}
          dataSource={tableData}
        />
      </div>
    );
  }

  function deleteUserAllowance(user, allowanceToBeDeleted) {
    Modal.confirm({
      title: "Are you sure you want to delete this allowance?",
      content: (
        <>
          <Typography.Text>Year: {allowanceToBeDeleted.year}</Typography.Text>
          <br />
          <Typography.Text>Days: {allowanceToBeDeleted.allowance / TIMELINE_DEFAULT_HOURS_IN_A_DAY}</Typography.Text>
        </>
      ),
      onOk: async () => {
        const allowances = user.allowances.filter((allowance) =>
          allowance.type === allowanceToBeDeleted.type ? allowance.year !== allowanceToBeDeleted.year : allowance
        );

        await window.callGraphQLSimple({
          message: "Failed to delete allowance",
          mutation: "updateUser",
          variables: {
            input: {
              id: user.id,
              allowances,
            },
          },
        });
      },
    });
  }

  return (
    <div className="user-allowances">
      {displayAllowanceDetailsPerUser(userData, allowanceDefinition)}
      {isAddAllowanceModalVisible && selectedUser && (
        <AddAllowanceModal
          onClose={() => {
            setIsAddAllowanceModalVisible(false);
            setIsEditingAllowance(false);
            setSelectedAllowance();
          }}
          user={selectedUser}
          allowanceType={selectedAllowanceType}
          isEditingAllowance={isEditingAllowance}
          selectedAllowance={selectedAllowance}
          targetIntervals={targetIntervals}
        />
      )}
    </div>
  );
}
