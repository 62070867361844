import { getFormFieldValue } from "../aggregator";

const fields = [
  {
    id: "PSD_client_name",
    label: "PSD - Client name",
    repeatForFieldName: "file",
    value: async ({ form, client }) => {
      let fieldValue = getFormFieldValue(form, "customclientname-1731319953424");
      return `${fieldValue || client.name}`;
    },
  },
];
export function getFields() {
  return fields;
}
