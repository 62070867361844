import React from "react";
import moment from "moment";
import cx from "classnames";
import { Modal, Typography, Pagination, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import { CloseIcon } from "common/icons";
import { calculateReadableSize, downloadAttachment } from "common/helpers";

import PdfRenderer from "ReportPage/PdfRenderer";
import Zoomable from "Zoomable/Zoomable";

import "./DocumentDetailsModal.scss";

export default class DocumentDetailsModal extends React.Component {
  state = {
    currentPageNumber: 1,
    pageCount: undefined,
    isDocumentLoaded: false,
    randomNumberForZoomableRefresh: 0,
  };

  constructor(props) {
    super(props);

    this.documentContainerRef = React.createRef();
    this.documentContentRef = React.createRef();
  }

  onDocumentLoad = ({ pageCount }) => {
    this.setState({ pageCount });
    setTimeout(() => {
      this.setState({ isDocumentLoaded: true }, () => {
        setTimeout(() => {
          this.setState({ randomNumberForZoomableRefresh: Math.random() });
        }, 2000);
      });
    }, 1500);
  };

  displayContent = () => {
    const { document } = this.props;
    const { currentPageNumber } = this.state;

    return (
      <div className="zoomable-content" ref={this.documentContentRef}>
        {document && (
          <PdfRenderer
            fileData={document}
            currentPageNumber={currentPageNumber}
            includePagination={false}
            renderMode="canvas"
            onLoad={this.onDocumentLoad}
          />
        )}
      </div>
    );
  };

  render() {
    const {
      onClose,
      apiUser,
      document,
      windowWidth,
      windowHeight,
      attachment,
      showPagination,
      size,
      open = true,
      versionId,
      displayInModal = true,
      allowDownload = true,
    } = this.props;
    const { pageCount, isDocumentLoaded, randomNumberForZoomableRefresh } = this.state;
    const stateCurrentPageNumber = this.state.currentPageNumber;

    let sizeReadable = attachment.size && calculateReadableSize(attachment);

    let content = (
      <div className="content">
        <div className="document-info">
          <div className="item document-title">
            <Typography.Text className="label">Name</Typography.Text>
            <Typography.Text className="value">{attachment.name}</Typography.Text>
          </div>
          {allowDownload && (
            <div className="item document-details-modal-download-button">
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => downloadAttachment(attachment, null, versionId)}
              >
                <Typography.Text className="download-button-label">Download</Typography.Text>
              </Button>
            </div>
          )}
          <div className="item">
            <Typography.Text className="label">Type</Typography.Text>
            <Typography.Text className="value">{attachment.type}</Typography.Text>
          </div>
          <div className="item created">
            <Typography.Text className="label">Created</Typography.Text>
            <Typography.Text className="value">
              {moment(
                attachment.versions
                  ? attachment.versions[attachment.versions.length - 1].LastModified
                  : attachment.lastModified
              ).format("MMM DD YYYY, HH:mm")}
            </Typography.Text>
          </div>
          {attachment.size && (
            <div className="item">
              <Typography.Text className="label">Size</Typography.Text>
              <Typography.Text className="value">{sizeReadable}</Typography.Text>
            </div>
          )}
        </div>
        <div data-cy="document" className="document">
          {showPagination !== false && (
            <div className="document-header">
              <Pagination
                className="pagination"
                defaultCurrent={1}
                pageSize={1}
                current={stateCurrentPageNumber}
                onChange={(currentPageNumber) => this.setState({ currentPageNumber })}
                total={pageCount || 1}
                simple
              />
            </div>
          )}
          <div className="zoomable-container" ref={this.documentContainerRef}>
            <Zoomable
              key={document}
              isLoaded={document && isDocumentLoaded}
              refreshOnChange={[windowWidth, windowHeight, document, isDocumentLoaded, randomNumberForZoomableRefresh]}
              active={true}
              containerRef={this.documentContainerRef}
              content={this.displayContent}
            />
          </div>
        </div>
      </div>
    );

    if (displayInModal) {
      return (
        <Modal
          maskClosable={true}
          open={open}
          apiUser={apiUser}
          footer={null}
          className={cx("document-details-modal", {
            [`document-details-modal-${size}`]: size,
          })}
          onCancel={onClose}
          closeIcon={<CloseIcon />}
        >
          {content}
        </Modal>
      );
    } else {
      return <div className="document-details-modal">{content}</div>;
    }
  }
}
