import { useState } from "react";
import moment from "moment";
import { Form, Modal, Button, Select } from "antd";
import { CloseIcon } from "common/icons";

import withSubscriptions from "common/withSubscriptions";
import { getLabel } from "common/helpers";
import {
  createInvoiceLineItemFromQuoteLineItem,
  enhanceQuoteWithInvoicing,
} from "common/invoiceHelpers/sharedInvoiceHelpers";
import { recalculateInvoiceAmounts } from "common/invoiceHelpers";

import "./AddQuoteLineItemToInvoiceLineItemModal.scss";
import { getSimpleLabel } from "common/labels";

export function AddQuoteLineItemToInvoiceLineItemModal({
  onClose,
  quotes,
  organisationDetails,
  invoice,
  invoiceLineItem,
  fetchAndSetTimesheetBlocks,
  clients,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  let quoteLineItems = [];
  for (let i = 0; i < quotes.length; i++) {
    const quote = quotes[i];
    if (quote.projectId !== invoice.projectId) {
      continue;
    }
    for (let j = 0; j < quote.lineItems?.items.length; j++) {
      let quoteLineItem = quote.lineItems?.items[j];
      if (quoteLineItem.invoicingStatus !== "FULLY_INVOICED") {
        quoteLineItems.push(quoteLineItem);
      }
    }
  }
  quoteLineItems.sort((a, b) => (a.quoteId < b.quoteId ? -1 : 1));

  async function onSubmit({ quoteLineItemId }) {
    setIsLoading(true);
    const quoteLineItem = quoteLineItems.find((x) => x.id === quoteLineItemId);

    const timesheetBlocks = await fetchAndSetTimesheetBlocks({
      organisation: organisationDetails.id,
      startAt: moment().subtract(1, "year").toISOString(),
      endAt: moment().add(1, "year").toISOString(),
      filter: {
        quoteLineItemId: {
          eq: quoteLineItemId,
        },
        invoicingStatus: {
          ne: "INVOICED",
        },
      },
    });

    let invoicePoNumberString = invoice.poNumber || "";
    const quote = quotes.find((x) => x.id === quoteLineItem.quoteId);
    if (!invoicePoNumberString.includes(quote.poNumber)) {
      invoicePoNumberString += ` ${quote.poNumber || ""}`;
    }

    const upToDateQuote = (
      await window.callGraphQLSimple({
        message: `Failed to fetch ${getSimpleLabel("quote")} details`,
        queryCustom: "getQuote",
        variables: {
          id: quote.id,
        },
      })
    ).data.getQuote;

    const upToDateQuoteWithInvoicing = enhanceQuoteWithInvoicing(upToDateQuote);

    const updatedInvoiceLineItem = createInvoiceLineItemFromQuoteLineItem({
      organisationDetails,
      client: clients.find((x) => x.id === quote.clientId),
      quote: upToDateQuoteWithInvoicing,
      quoteLineItemId: quoteLineItem.id,
      invoice,
      invoiceLineItem,
      timesheetBlocks,
    });

    await window.callGraphQLSimple({
      message: `Failed to add ${getLabel({
        id: "quote",
        defaultValue: "quote",
      })} line item to invoice`,
      queryCustom: "updateInvoiceLineItem",
      variables: {
        input: {
          id: invoiceLineItem.id,
          ...updatedInvoiceLineItem,
        },
      },
    });

    await window.callGraphQLSimple({
      message: `Failed to refresh invoiced amount on ${getLabel({
        id: "quote",
        defaultValue: "quote",
      })}`,
      queryCustom: "updateQuote",
      variables: {
        input: {
          id: quoteLineItem.quoteId,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      },
    });

    for (let i = 0; i < timesheetBlocks.length; i++) {
      const timesheetBlock = timesheetBlocks[i];
      await window.callGraphQLSimple({
        message: "Failed to mark timesheet block as invoiced",
        mutation: "updateTimesheetBlock",
        variables: {
          input: {
            id: timesheetBlock.id,
            invoicingStatus: "INVOICED",
            invoiceId: invoiceLineItem.invoiceId,
          },
        },
      });
    }

    const updatedInvoice = (
      await window.callGraphQLSimple({
        message: "Failed to fetch invoice details",
        queryCustom: "updateInvoice",
        variables: {
          input: {
            id: invoiceLineItem.invoiceId,
            poNumber: invoicePoNumberString.trim(),
          },
        },
      })
    ).data.updateInvoice;

    await recalculateInvoiceAmounts({ invoiceId: invoiceLineItem.invoiceId });

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  function filterQuoteLineItemOption(targetText, { quoteLineItem }) {
    targetText = targetText.toLowerCase();
    try {
      if (quoteLineItem?.title.toLowerCase().includes(targetText)) {
        return true;
      } else if (quoteLineItem.description?.toLowerCase().includes(targetText)) {
        return true;
      } else if (quoteLineItem.quoteId?.toLowerCase().includes(targetText)) {
        return true;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  }

  return (
    <Modal
      maskClosable={false}
      title={`Add ${getLabel({
        id: "quote",
        defaultValue: "quote",
      })} line item`}
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="add-quote-line-item-to-invoice-line-item-modal"
      closeIcon={<CloseIcon />}
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item
          label={`${getLabel({
            id: "Quote",
            defaultValue: "Quote",
          })} line item`}
          name="quoteLineItemId"
          rules={[
            {
              required: true,
              message: `You must choose a ${getLabel({
                id: "quote",
                defaultValue: "quote",
              })} line item`,
            },
          ]}
        >
          <Select data-cy="quote-line-item-dropdown" showSearch filterOption={filterQuoteLineItemOption}>
            {quoteLineItems?.map((quoteLineItem) => {
              return (
                <Select.Option key={quoteLineItem.id} value={quoteLineItem.id} quoteLineItem={quoteLineItem}>
                  {quoteLineItem.quoteId} - {quoteLineItem.title} ({window.formatCurrency("GBP", quoteLineItem.amount)})
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <div className="submit-container">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            data-cy="add-quote-line-item-to-invoice-line-item-submit-button"
          >
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}

export default withSubscriptions({
  Component: AddQuoteLineItemToInvoiceLineItemModal,
  subscriptions: ["organisationDetails", "timesheetBlocks"],
});
