import { useState } from "react";
import { withRouter } from "react-router-dom";
import { Form, Modal, Button, Input } from "antd";

import withSubscriptions from "common/withSubscriptions";
import { FILE_TYPES_READABLE } from "common/constants";
import { removeSpecialCharactersFromForm } from "common/formValidators";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";

import "./RenameFileModal.scss";

type Props = {
  onClose?: any;
  task?: any;
  file?: any;
  visible?: any;
  apiUser: any;
  history: any;
};

export function RenameFileModal({ onClose, task, file, apiUser, visible, history }: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ name }) {
    setIsLoading(true);

    await callGraphQLSimple({
      message: "Could not update file",
      queryCustom: "updateFile",
      variables: {
        input: {
          id: file.id,
          name,
        },
      },
    });

    await callGraphQLSimple({
      message: `Could not update ${getSimpleLabel("task")}`,
      queryCustom: "updateTask",
      variables: {
        input: {
          id: task.id,
          randomNumber: Math.floor(Math.random() * 1000000),
        },
      },
    });

    setIsLoading(false);

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={`Rename ${FILE_TYPES_READABLE[file.type]} file`}
      visible={visible}
      onCancel={onClose}
      footer={null}
      className="rename-file-modal"
    >
      <Form
        {...layout}
        form={form}
        initialValues={{
          name: file.name,
        }}
        onFinish={onSubmit}
        onValuesChange={(values) => {
          removeSpecialCharactersFromForm({ form, values, targetFields: ["name"] });
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "You must choose a file name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}

export default withRouter(
  withSubscriptions({
    Component: RenameFileModal,
    subscriptions: ["organisationDetails"],
  })
);
