import { Typography, notification, message } from "antd";
import moment from "moment";

import { processIdForDisplay } from "common/helpers";
import { callRest, callGraphQLSimple } from "common/apiHelpers";
import { GENERIC_AVATAR_URL } from "common/constants";
import { PUBLIC_S3_BUCKET_URL } from "common/publicS3BucketUrl";
import getAllPermissionsForUser from "common/getAllPermissionsForUser";
import { getSimpleLabel } from "./labels";

function displayDetailsInEmail({
  taskId,
  quoteId,
  quoteTitle,
  invoiceId,
  purchaseOrderId,
  requestId,
  purchaseOrderTitle,
  taskTitle,
  requestTitle,
  projectTitle,
  clientName,
  supplierName,
  taskRevisionName,
  taskRevisionDescription,
}) {
  let taskDetails = "";
  let taskRevisionDetails = "";
  let supplierDetails = "";
  let quoteDetails = "";
  let invoiceDetails = "";
  let purchaseOrderDetails = "";
  let requestDetails = "";

  if (taskId) {
    taskDetails = `<p>${getSimpleLabel("Task")}: ${taskTitle}</p>
    <p>${getSimpleLabel("Task")} ID: <b>${processIdForDisplay(taskId)}</b></p>`;
  }

  if (requestId) {
    requestDetails = `<p>${getSimpleLabel("Request")}: ${requestTitle}</p>
    <p>${getSimpleLabel("Request")} ID: <b>${processIdForDisplay(requestId)}</b></p>`;
  }

  if (taskRevisionName) {
    taskRevisionDetails = `<p>${getSimpleLabel("Task")} revision: ${taskRevisionName} (${taskRevisionDescription})</p>`;
  }

  if (quoteId) {
    quoteDetails = `<p>${getSimpleLabel("Quote")}: ${quoteTitle}</p>
    <p>${getSimpleLabel("Quote")} ID: ${processIdForDisplay(quoteId)}</p>`;
  }

  if (invoiceId) {
    invoiceDetails = `<p>${getSimpleLabel("Invoice")} ID: ${processIdForDisplay(invoiceId)}</p>`;
  }

  if (purchaseOrderId) {
    purchaseOrderDetails = `<p>${getSimpleLabel("Purchase order")}: ${purchaseOrderTitle}</p>
    <p>${getSimpleLabel("Purchase order")} ID: ${processIdForDisplay(purchaseOrderId)}</p>`;
  }

  if (supplierName) {
    supplierDetails = `<p>${getSimpleLabel("Supplier")}: ${supplierName}</p>`;
  }

  return `
  <p>${getSimpleLabel("Client")}: ${clientName}</p> 
  <p>${getSimpleLabel("Project")}: ${projectTitle}</p> 
  ${supplierDetails}
  ${taskDetails}
  ${requestDetails}
  ${taskRevisionDetails}
  ${quoteDetails}
  ${invoiceDetails}
  ${purchaseOrderDetails}
`;
}

export async function sendReviewRequestNotification({
  users,
  apiUser,
  reviewer,
  taskId,
  taskTitle,
  clientName,
  projectTitle,
  taskRevisionId,
  taskRevisionName,
  taskRevisionDescription,
}) {
  const reviewerDetails = users.find((x) => x.id === reviewer);

  const type = "REVIEW_REQUEST";
  const link = `tasks/${taskId}/review/${taskRevisionId}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!reviewerDetails) {
    return;
  }

  let emailParams = {
    type,
    id,
    receiverDetails: {
      id: reviewerDetails.id,
    },
    subject: `${apiUser.firstName} ${apiUser.lastName} requested your review on ${getSimpleLabel(
      "task"
    )} ${projectTitle} ${taskTitle} (${processIdForDisplay(taskId)})`,
    title: `${apiUser.firstName} ${apiUser.lastName} is requesting your review`,
    message: displayDetailsInEmail({
      taskId,
      taskTitle,
      projectTitle,
      clientName,
      taskRevisionName,
      taskRevisionDescription,
    }),
    link: `${window.location.origin}/${link}?notificationId=${id}`,
  };

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.emailReviewsRequest !== false) {
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not send notification email:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.webAppReviewsRequest !== false) {
    callGraphQLSimple({
      message: "Failed to notify reviewer",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: reviewerDetails.id,
          organisation: reviewerDetails.organisation,
          link: `/${link}`,
          type,
          message: emailParams.subject,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }
}

export async function sendReviewResultNotification({
  users,
  apiUser,
  taskId,
  taskTitle,
  clientName,
  projectTitle,
  taskRevisionId,
  taskRevisionName,
  taskRevisionDescription,
  reviewStatusReadable,
  taskAssignedTo,
}) {
  const receiverDetails = users.find((x) => x.id === taskAssignedTo);

  const type = "REVIEW_RESULT";
  const link = `tasks/${taskId}/review/${taskRevisionId}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!receiverDetails) {
    return;
  }

  let emailParams = {
    type,
    id,
    receiverDetails: {
      id: taskAssignedTo,
    },
    subject: `${reviewStatusReadable} on ${getSimpleLabel("task")} ${projectTitle} ${taskTitle} (${processIdForDisplay(
      taskId
    )})`,
    title: `${reviewStatusReadable}`,
    message: displayDetailsInEmail({
      taskId,
      taskTitle,
      projectTitle,
      clientName,
      taskRevisionName,
      taskRevisionDescription,
    }),
    link: `${window.location.origin}/${link}?notificationId=${id}`,
  };

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.emailReviewsResult !== false) {
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not send notification email:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.webAppReviewsResult !== false) {
    callGraphQLSimple({
      message: "Failed to notify recipient",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: receiverDetails.id,
          organisation: receiverDetails.organisation,
          link: `/${link}`,
          type,
          message: emailParams.subject,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }
}

export async function sendExternalReviewResultNotification({
  users,
  apiUser,
  requestId,
  requestTitle,
  requestAssignedTo,
  clientName,
  projectTitle,
  reviewStatusReadable,
  fakeTaskRevisionId,
}) {
  const receiverDetails = users.find((x) => x.id === requestAssignedTo);

  const type = "REVIEW_RESULT";
  const link = `requests/${requestId}/review/${fakeTaskRevisionId}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!receiverDetails) {
    return;
  }

  let emailParams = {
    type,
    id,
    receiverDetails: {
      id: requestAssignedTo,
    },
    subject: `${getSimpleLabel("external-review-notification-prefix")} - ${reviewStatusReadable} on ${getSimpleLabel(
      "request"
    )} ${projectTitle} ${requestTitle} (${processIdForDisplay(requestId)})`,
    title: `${reviewStatusReadable}`,
    message: displayDetailsInEmail({
      requestId,
      requestTitle,
      projectTitle,
      clientName,
    }),
    link: `${window.location.origin}/${link}?notificationId=${id}`,
  };

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.emailReviewsResult !== false) {
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not send notification email:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.webAppReviewsResult !== false) {
    callGraphQLSimple({
      message: "Failed to notify recipient",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: receiverDetails.id,
          organisation: receiverDetails.organisation,
          link: `/${link}`,
          type,
          message: emailParams.subject,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }
}

export async function sendNotificationToReviewer({
  users,
  apiUser,
  reviewer,
  taskId,
  taskTitle,
  clientName,
  projectTitle,
  taskRevisionId,
  taskRevisionName,
  taskRevisionDescription,
  reviewSecondaryStatusReadable,
}) {
  const reviewerDetails = users.find((x) => x.id === reviewer);

  const type = "REVIEWER_NOTIFICATION";
  const link = `tasks/${taskId}/review/${taskRevisionId}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!reviewerDetails) {
    return;
  }

  let emailParams = {
    type,
    id,
    receiverDetails: {
      id: reviewerDetails.id,
    },
    subject: `${reviewSecondaryStatusReadable} on review for ${getSimpleLabel(
      "task"
    )} ${projectTitle} ${taskTitle} (${processIdForDisplay(taskId)})`,
    title: `${reviewSecondaryStatusReadable}`,
    message: displayDetailsInEmail({
      taskId,
      taskTitle,
      projectTitle,
      clientName,
      taskRevisionName,
      taskRevisionDescription,
    }),
    link: `${window.location.origin}/${link}?notificationId=${id}`,
  };

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.emailReviewsResult !== false) {
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not send notification email:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.webAppReviewsResult !== false) {
    callGraphQLSimple({
      message: "Failed to notify reviewer",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: reviewerDetails.id,
          organisation: reviewerDetails.organisation,
          link: `/${link}`,
          type,
          message: emailParams.subject,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }
}

///////////////////////////////////// Quotes START /////////////////////////////////////////////////

export async function sendQuoteReviewRequestNotification({
  users,
  apiUser,
  reviewer,
  quoteId,
  quoteTitle,
  clientName,
  projectTitle,
}) {
  const reviewerDetails = users.find((x) => x.id === reviewer);

  const type = "QUOTE_REVIEW_REQUEST";
  const link = `quotes/${quoteId}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!reviewerDetails) {
    return;
  }

  let emailParams = {
    type,
    id,
    receiverDetails: {
      id: reviewerDetails.id,
    },
    subject: `${apiUser.firstName} ${apiUser.lastName} requested your review on ${getSimpleLabel(
      "quote"
    )} for ${projectTitle} ${quoteTitle} (${processIdForDisplay(quoteId)})`,
    title: `${apiUser.firstName} ${apiUser.lastName} is requesting your review on a ${getSimpleLabel("quote")}`,
    message: displayDetailsInEmail({
      quoteId,
      quoteTitle,
      projectTitle,
      clientName,
    }),
    link: `${window.location.origin}/${link}?notificationId=${id}`,
  };

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.emailReviewsRequest !== false) {
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not send notification email:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.webAppReviewsRequest !== false) {
    callGraphQLSimple({
      message: "Failed to notify reviewer",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: reviewerDetails.id,
          organisation: reviewerDetails.organisation,
          link: `/${link}`,
          type,
          message: emailParams.subject,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }
}

export async function sendQuoteReviewResultNotification({
  users,
  apiUser,
  quoteId,
  quoteTitle,
  clientName,
  projectTitle,
  reviewStatusReadable,
  quoteAssignedTo,
}) {
  const receiverDetails = users.find((x) => x.id === quoteAssignedTo);

  const type = "QUOTE_REVIEW_RESULT";
  const link = `quotes/${quoteId}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!receiverDetails) {
    return;
  }

  let emailParams = {
    type,
    id,
    receiverDetails: {
      id: quoteAssignedTo,
    },
    subject: `${reviewStatusReadable} on ${getSimpleLabel(
      "quote"
    )} for ${projectTitle} ${quoteTitle} (${processIdForDisplay(quoteId)})`,
    title: reviewStatusReadable,
    message: displayDetailsInEmail({
      quoteId,
      quoteTitle,
      projectTitle,
      clientName,
    }),
    link: `${window.location.origin}/${link}?notificationId=${id}`,
  };

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.emailReviewsResult !== false) {
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not send notification email:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.webAppReviewsResult !== false) {
    callGraphQLSimple({
      message: "Failed to notify reviewer",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: receiverDetails.id,
          organisation: receiverDetails.organisation,
          link: `/${link}`,
          type,
          message: emailParams.subject,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }
}

export async function sendNotificationToQuoteReviewer({
  users,
  apiUser,
  reviewer,
  quoteId,
  quoteTitle,
  clientName,
  projectTitle,
  reviewSecondaryStatusReadable,
}) {
  const reviewerDetails = users.find((x) => x.id === reviewer);

  const type = "QUOTE_REVIEWER_NOTIFICATION";
  const link = `quotes/${quoteId}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!reviewerDetails) {
    return;
  }

  let emailParams = {
    type,
    id,
    receiverDetails: {
      id: reviewerDetails.id,
    },
    subject: `${reviewSecondaryStatusReadable} on review for ${getSimpleLabel(
      "quote"
    )} for ${projectTitle} ${quoteTitle} (${processIdForDisplay(quoteId)})`,
    title: reviewSecondaryStatusReadable,
    message: displayDetailsInEmail({
      quoteId,
      quoteTitle,
      projectTitle,
      clientName,
    }),
    link: `${window.location.origin}/${link}?notificationId=${id}`,
  };

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.emailReviewsResult !== false) {
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not send notification email:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.webAppReviewsResult !== false) {
    callGraphQLSimple({
      message: "Failed to notify reviewer",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: reviewerDetails.id,
          organisation: reviewerDetails.organisation,
          link: `/${link}`,
          type,
          message: emailParams.subject,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }
}

///////////////////////////////////// Quotes END /////////////////////////////////////////////////

///////////////////////////////////// Invoices START /////////////////////////////////////////////////

export async function sendInvoiceReviewRequestNotification({
  users,
  apiUser,
  reviewer,
  invoiceId,
  clientName,
  projectTitle,
}) {
  const reviewerDetails = users.find((x) => x.id === reviewer);

  const type = "INVOICE_REVIEW_REQUEST";
  const link = `invoices/${invoiceId}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!reviewerDetails) {
    return;
  }

  let emailParams = {
    type,
    id,
    receiverDetails: {
      id: reviewerDetails.id,
    },
    subject: `${apiUser.firstName} ${apiUser.lastName} requested your review on ${getSimpleLabel(
      "invoice"
    )} for ${projectTitle} (${processIdForDisplay(invoiceId)})`,
    title: `${apiUser.firstName} ${apiUser.lastName} is requesting your review on an ${getSimpleLabel("invoice")}`,
    message: displayDetailsInEmail({
      invoiceId,
      projectTitle,
      clientName,
    }),
    link: `${window.location.origin}/${link}?notificationId=${id}`,
  };

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.emailReviewsRequest !== false) {
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not send notification email:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.webAppReviewsRequest !== false) {
    callGraphQLSimple({
      message: "Failed to notify reviewer",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: reviewerDetails.id,
          organisation: reviewerDetails.organisation,
          link: `/${link}`,
          type,
          message: emailParams.subject,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }
}

export async function sendInvoiceReviewResultNotification({
  users,
  apiUser,
  invoiceId,
  clientName,
  projectTitle,
  reviewStatusReadable,
  invoiceAssignedTo,
}) {
  const receiverDetails = users.find((x) => x.id === invoiceAssignedTo);

  const type = "INVOICE_REVIEW_RESULT";
  const link = `invoices/${invoiceId}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!receiverDetails) {
    return;
  }

  let emailParams = {
    type,
    id,
    receiverDetails: {
      id: invoiceAssignedTo,
    },
    subject: `${reviewStatusReadable} on ${getSimpleLabel("invoice")} for ${projectTitle} (${processIdForDisplay(
      invoiceId
    )})`,
    title: reviewStatusReadable,
    message: displayDetailsInEmail({
      invoiceId,
      projectTitle,
      clientName,
    }),
    link: `${window.location.origin}/${link}?notificationId=${id}`,
  };

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.emailReviewsResult !== false) {
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not send notification email:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.webAppReviewsResult !== false) {
    callGraphQLSimple({
      message: "Failed to notify reviewer",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: receiverDetails.id,
          organisation: receiverDetails.organisation,
          link: `/${link}`,
          type,
          message: emailParams.subject,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }
}

export async function sendNotificationToInvoiceReviewer({
  users,
  apiUser,
  reviewer,
  invoiceId,
  clientName,
  projectTitle,
  reviewSecondaryStatusReadable,
}) {
  const reviewerDetails = users.find((x) => x.id === reviewer);

  const type = "INVOICE_REVIEWER_NOTIFICATION";
  const link = `invoices/${invoiceId}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!reviewerDetails) {
    return;
  }

  let emailParams = {
    type,
    id,
    receiverDetails: {
      id: reviewerDetails.id,
    },
    subject: `${reviewSecondaryStatusReadable} on review for ${getSimpleLabel(
      "invoice"
    )} for ${projectTitle} (${processIdForDisplay(invoiceId)})`,
    title: reviewSecondaryStatusReadable,
    message: displayDetailsInEmail({
      invoiceId,
      projectTitle,
      clientName,
    }),
    link: `${window.location.origin}/${link}?notificationId=${id}`,
  };

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.emailReviewsResult !== false) {
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not send notification email:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.webAppReviewsResult !== false) {
    callGraphQLSimple({
      message: "Failed to notify reviewer",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: reviewerDetails.id,
          organisation: reviewerDetails.organisation,
          link: `/${link}`,
          type,
          message: emailParams.subject,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }
}

///////////////////////////////////// Invoices END /////////////////////////////////////////////////

///////////////////////////////////// Purchase orders START /////////////////////////////////////////////////

export async function sendPurchaseOrderReviewRequestNotification({
  users,
  apiUser,
  reviewer,
  purchaseOrderId,
  purchaseOrderTitle,
  clientName,
  supplierName,
  projectTitle,
}) {
  const reviewerDetails = users.find((x) => x.id === reviewer);

  const type = "PURCHASE_ORDER_REVIEW_REQUEST";
  const link = `purchase-orders/${purchaseOrderId}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!reviewerDetails) {
    return;
  }

  let emailParams = {
    type,
    id,
    receiverDetails: {
      id: reviewerDetails.id,
    },
    subject: `${apiUser.firstName} ${apiUser.lastName} requested your review on ${getSimpleLabel(
      "purchase order"
    )} for ${projectTitle} ${purchaseOrderTitle} (${processIdForDisplay(purchaseOrderId)})`,
    title: `${apiUser.firstName} ${apiUser.lastName} is requesting your review on a ${getSimpleLabel(
      "purchase order"
    )}`,
    message: displayDetailsInEmail({
      purchaseOrderId,
      purchaseOrderTitle,
      projectTitle,
      clientName,
      supplierName,
    }),
    link: `${window.location.origin}/${link}?notificationId=${id}`,
  };

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.emailReviewsRequest !== false) {
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not send notification email:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.webAppReviewsRequest !== false) {
    callGraphQLSimple({
      message: "Failed to notify reviewer",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: reviewerDetails.id,
          organisation: reviewerDetails.organisation,
          link: `/${link}`,
          type,
          message: emailParams.subject,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }
}

export async function sendPurchaseOrderReviewResultNotification({
  users,
  apiUser,
  purchaseOrderId,
  purchaseOrderTitle,
  clientName,
  supplierName,
  projectTitle,
  reviewStatusReadable,
  purchaseOrderAssignedTo,
}) {
  const receiverDetails = users.find((x) => x.id === purchaseOrderAssignedTo);

  const type = "PURCHASE_ORDER_REVIEW_RESULT";
  const link = `purchase-orders/${purchaseOrderId}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!receiverDetails) {
    return;
  }

  let emailParams = {
    type,
    id,
    receiverDetails: {
      id: purchaseOrderAssignedTo,
    },
    subject: `${reviewStatusReadable} on ${getSimpleLabel(
      "purchase order"
    )} for ${projectTitle} ${purchaseOrderTitle} (${processIdForDisplay(purchaseOrderId)})`,
    title: reviewStatusReadable,
    message: displayDetailsInEmail({
      purchaseOrderId,
      purchaseOrderTitle,
      projectTitle,
      clientName,
      supplierName,
    }),
    link: `${window.location.origin}/${link}?notificationId=${id}`,
  };

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.emailReviewsResult !== false) {
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not send notification email:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.webAppReviewsResult !== false) {
    callGraphQLSimple({
      message: "Failed to notify reviewer",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: receiverDetails.id,
          organisation: receiverDetails.organisation,
          link: `/${link}`,
          type,
          message: emailParams.subject,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }
}

export async function sendNotificationToPurchaseOrderReviewer({
  users,
  apiUser,
  reviewer,
  purchaseOrderId,
  purchaseOrderTitle,
  clientName,
  supplierName,
  projectTitle,
  reviewSecondaryStatusReadable,
}) {
  const reviewerDetails = users.find((x) => x.id === reviewer);

  const type = "PURCHASE_ORDER_REVIEWER_NOTIFICATION";
  const link = `purchase-orders/${purchaseOrderId}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!reviewerDetails) {
    return;
  }

  let emailParams = {
    type,
    id,
    receiverDetails: {
      id: reviewerDetails.id,
    },
    subject: `${reviewSecondaryStatusReadable} on review for ${getSimpleLabel(
      "purchase order"
    )} for ${projectTitle} ${purchaseOrderTitle} (${processIdForDisplay(purchaseOrderId)})`,
    title: reviewSecondaryStatusReadable,
    message: displayDetailsInEmail({
      purchaseOrderId,
      purchaseOrderTitle,
      projectTitle,
      clientName,
      supplierName,
    }),
    link: `${window.location.origin}/${link}?notificationId=${id}`,
  };

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.emailReviewsResult !== false) {
    try {
      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not send notification email:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  if (!reviewerDetails.notificationSettings || reviewerDetails.notificationSettings.webAppReviewsResult !== false) {
    callGraphQLSimple({
      message: "Failed to notify reviewer",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: reviewerDetails.id,
          organisation: reviewerDetails.organisation,
          link: `/${link}`,
          type,
          message: emailParams.subject,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }
}

///////////////////////////////////// Purchase orders END /////////////////////////////////////////////////

export async function sendPublicUploadNotification({ type, parentId, parentType, taskTitle, projectTitle, userId }) {
  const linkPath = `${parentType}s/${parentId}?attachmentPath=${parentId}/Public uploads/${moment().format(
    "DD-MM-YYYY"
  )}/&tab=attachments`;

  const id = `${Date.now()}${Math.floor(Math.random() * 100000)}`;

  await callRest({
    route: "/sendPublicUploadNotification",
    method: "POST",
    body: {
      type,
      userId,
      taskTitle,
      projectTitle,
      parentId,
      parentType,
      webAppLink: `/${linkPath}`,
      emailLink: `${window.location.origin}/${linkPath}&notificationId=${id}`,
      id,
    },
  });
}

export async function sendNotificationToTaskAssignee({
  taskAssigner,
  taskAssignee,
  projectTitle,
  clientName,
  taskTitle,
  taskId,
}) {
  const type = "TASK_ASSIGNED";
  const link = `tasks/${taskId}`;

  const id = Date.now() + Math.floor(Math.random() * 100);

  if (!taskAssigner || !taskAssignee || taskAssigner?.id === taskAssignee?.id) {
    return;
  }

  let subject = `${getSimpleLabel("Task")} assigned: ${
    window.organisationDetails?.settings?.task?.automaticallyCreateProject ? "" : `${projectTitle} - `
  }${taskTitle} (${processIdForDisplay(taskId)})`;

  if (!taskAssignee.notificationSettings || taskAssignee.notificationSettings.webAppTaskAssigned !== false) {
    callGraphQLSimple({
      message: "Failed to notify recipient",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: taskAssignee.id,
          organisation: taskAssignee.organisation,
          link: `/${link}`,
          type,
          message: subject,
          createdAt: new Date().toISOString(),
          author: window.apiUser.id,
        },
      },
    });
  }

  if (!taskAssignee.notificationSettings || taskAssignee.notificationSettings.emailTaskAssigned !== false) {
    const emailParams = {
      type,
      senderDetails: {
        firstName: taskAssigner.firstName,
        lastName: taskAssigner.lastName,
        id: taskAssigner.id,
      },
      receiverDetails: {
        firstName: taskAssignee.firstName,
        lastName: taskAssignee.lastName,
        id: taskAssignee.id,
      },
      link: `${window.location.origin}/${link}&notificationId=${id}`,
      message: displayDetailsInEmail({
        taskId,
        taskTitle,
        projectTitle,
        clientName,
      }),
      buttonText: `View ${getSimpleLabel("task")}`,
      title: `${getSimpleLabel("Task")} assigned to you`,
      subject,
    };
    await callRest({
      route: "/sendNotificationEmail",
      method: "POST",
      body: emailParams,
      includeCredentials: false,
    });
  }
}

export async function sendNotificationAboutNewTaskRevisionToTaskAssignee({
  taskAssigner,
  taskAssignee,
  projectTitle,
  clientName,
  taskTitle,
  taskId,
  taskRevisionName,
  taskRevisionDescription,
}) {
  const type = "NEW_TASK_REVISION_CREATED";
  const link = `tasks/${taskId}`;

  const id = Date.now() + Math.floor(Math.random() * 100);

  if (!taskAssigner || !taskAssignee || taskAssigner?.id === taskAssignee?.id) {
    return;
  }

  let subject = `${getSimpleLabel("Task revision")} added: ${taskRevisionName} - ${taskRevisionDescription}: ${
    window.organisationDetails?.settings?.task?.automaticallyCreateProject ? "" : `${projectTitle} - `
  }${taskTitle} (${processIdForDisplay(taskId)})`;

  if (!taskAssignee.notificationSettings || taskAssignee.notificationSettings.webAppTaskAssigned !== false) {
    callGraphQLSimple({
      message: "Failed to notify recipient",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: taskAssignee.id,
          organisation: taskAssignee.organisation,
          link: `/${link}`,
          type,
          message: subject,
          createdAt: new Date().toISOString(),
          author: window.apiUser.id,
        },
      },
    });
  }

  if (!taskAssignee.notificationSettings || taskAssignee.notificationSettings.emailTaskAssigned !== false) {
    const emailParams = {
      type,
      senderDetails: {
        firstName: taskAssigner.firstName,
        lastName: taskAssigner.lastName,
        id: taskAssigner.id,
      },
      receiverDetails: {
        firstName: taskAssignee.firstName,
        lastName: taskAssignee.lastName,
        id: taskAssignee.id,
      },
      link: `${window.location.origin}/${link}&notificationId=${id}`,
      message: displayDetailsInEmail({
        taskId,
        taskTitle,
        projectTitle,
        clientName,
      }),
      buttonText: `View ${getSimpleLabel("task")}`,
      title: `New ${getSimpleLabel("task revision")} added`,
      subject,
    };
    await callRest({
      route: "/sendNotificationEmail",
      method: "POST",
      body: emailParams,
      includeCredentials: false,
    });
  }
}

///////////////////////////////////// Time off START /////////////////////////////////////////////////

export async function sendTimeOffRequestNotification({ requesterId, startsAt, endsAt, users, isSick }) {
  const requester = await getUserDetails(requesterId);
  let usersWithTimeOffPermission = getUsersWithTimeOffManagePermission(users);
  let targetUsers = usersWithTimeOffPermission.filter((user) => user.id !== window.apiUser.id);

  targetUsers = targetUsers.filter((user) => user.id !== requesterId);

  const notifications = targetUsers.map((user) => {
    const id = Date.now() + Math.floor(Math.random() * 1000000);
    const tab = isSick ? "sickDays" : "holidays";
    const link = `/timeoff?tab=${tab}&defaultExpandedRowUserId=${encodeURIComponent(requesterId)}`;
    const requesterFullName = `${requester.firstName} ${requester.lastName}`;
    const timeOffType = isSick ? "sick day" : "holiday";

    let message = `${requesterFullName} has requested a ${timeOffType} from ${startsAt} until ${endsAt}.`;

    return {
      id,
      userId: user.id,
      organisation: requester.organisation,
      link,
      message,
      createdAt: new Date().toISOString(),
      type: "TIME_OFF_REQUESTED",
      author: requesterId,
    };
  });

  await createNotifications(notifications);

  for (const notification of notifications) {
    const { message, link, id } = notification;
    const receiverId = notification.userId;

    await sendNotificationEmail({ receiverId, link, message, id, type: "TIME_OFF_REQUESTED" });
  }
}

export async function sendTimeOffApprovalNotification({ requesterId, approvedById, users, isSick, startsAt, endsAt }) {
  const requester = await getUserDetails(requesterId);
  const approvedByUserDetails = await getUserDetails(approvedById);
  let usersWithTimeOffPermission = getUsersWithTimeOffManagePermission(users, approvedById);
  let targetUsers = [...usersWithTimeOffPermission, requester];

  if (requesterId === approvedById) {
    targetUsers = targetUsers.filter((user) => user.id !== requesterId);
  } else {
    targetUsers = targetUsers.filter((user) => user.id !== approvedById);
  }

  const notifications = targetUsers.map((user) => {
    const id = Date.now() + Math.floor(Math.random() * 1000000);
    const approvedByFullName = `${approvedByUserDetails.firstName} ${approvedByUserDetails.lastName}`;
    const tab = isSick ? "sickDays" : "holidays";
    const link = `/timeoff?tab=${tab}&defaultExpandedRowUserId=${encodeURIComponent(requesterId)}`;
    const requesterFullName = `${requester.firstName} ${requester.lastName}`;
    const timeOffType = isSick ? "sick day" : "holiday";

    let message = `${approvedByFullName} has approved ${requesterFullName}'s request for a ${timeOffType} from ${startsAt} to ${endsAt}.`;

    if (user.id === requesterId) {
      message = `Time off request approved: ${timeOffType} from ${startsAt} to ${endsAt}`;
    }

    return {
      id,
      userId: user.id,
      organisation: requester.organisation,
      link,
      message,
      createdAt: new Date().toISOString(),
      type: "TIME_OFF_APPROVED",
      author: approvedByUserDetails?.id,
    };
  });

  await createNotifications(notifications);

  for (const notification of notifications) {
    const { message, link, id } = notification;
    const receiverId = notification.userId;

    await sendNotificationEmail({ receiverId, link, message, id, type: "TIME_OFF_APPROVED" });
  }
}

export async function sendTimeOffRejectionNotification({ requesterId, rejectedById, users, isSick, startsAt, endsAt }) {
  const requester = await getUserDetails(requesterId);
  const rejectedByUserDetails = await getUserDetails(rejectedById);
  let usersWithTimeOffPermission = getUsersWithTimeOffManagePermission(users, rejectedById);
  let targetUsers = [...usersWithTimeOffPermission, requester];

  if (requesterId === rejectedById) {
    targetUsers = targetUsers.filter((user) => user.id !== requesterId);
  } else {
    targetUsers = targetUsers.filter((user) => user.id !== rejectedById);
  }

  const notifications = targetUsers.map((user) => {
    const id = Date.now() + Math.floor(Math.random() * 1000000);
    const rejectedByFullName = `${rejectedByUserDetails.firstName} ${rejectedByUserDetails.lastName}`;
    const tab = isSick ? "sickDays" : "holidays";
    const link = `/timeoff?tab=${tab}&defaultExpandedRowUserId=${encodeURIComponent(requesterId)}`;
    const requesterFullName = `${requester.firstName} ${requester.lastName}`;
    const timeOffType = isSick ? "sick day" : "holiday";

    let message = `${rejectedByFullName} has rejected ${requesterFullName}'s request for a ${timeOffType} from ${startsAt} to ${endsAt}.`;

    if (user.id === requesterId) {
      message = `Time off request rejected: ${timeOffType} from ${startsAt} to ${endsAt}`;
    }

    return {
      id,
      userId: user.id,
      organisation: requester.organisation,
      link,
      message,
      createdAt: new Date().toISOString(),
      type: "TIME_OFF_REJECTED",
      author: rejectedById,
    };
  });

  await createNotifications(notifications);

  for (const notification of notifications) {
    const { message, link, id } = notification;
    const receiverId = notification.userId;

    await sendNotificationEmail({ receiverId, link, message, id, type: "TIME_OFF_REJECTED" });
  }
}

export async function sendTimeOffDeletionNotification({
  requesterId,
  deletedById,
  users,
  isSick,
  startsAt,
  endsAt,
  timeOffStatus,
}) {
  const requester = await getUserDetails(requesterId);
  const deletedByUserDetails = await getUserDetails(deletedById);
  let usersWithTimeOffPermission = getUsersWithTimeOffManagePermission(users, deletedById);
  let targetUsers = [...usersWithTimeOffPermission];

  if (requesterId === deletedById) {
    targetUsers = targetUsers.filter((user) => user.id !== requesterId);
  } else {
    targetUsers = targetUsers.filter((user) => user.id !== deletedById);
  }

  const notifications = targetUsers.map((user) => {
    const id = Date.now() + Math.floor(Math.random() * 1000000);
    const deletedByFullName = `${deletedByUserDetails.firstName} ${deletedByUserDetails.lastName}`;
    const tab = isSick ? "sickDays" : "holidays";
    const link = `/timeoff?tab=${tab}&defaultExpandedRowUserId=${encodeURIComponent(requesterId)}`;
    const requesterFullName = `${requester.firstName} ${requester.lastName}`;
    const timeOffType = isSick ? "sick day" : "holiday";
    const statusDescription = timeOffStatus === "APPROVED" ? "an approved" : `a ${timeOffStatus.toLowerCase()}`;

    let message = `${deletedByFullName} has deleted ${statusDescription} ${timeOffType} for ${requesterFullName} from ${startsAt} to ${endsAt}.`;

    if (user.id === requesterId) {
      message = `Time off request deleted: ${timeOffType} from ${startsAt} to ${endsAt}.`;
    }

    return {
      id,
      userId: user.id,
      organisation: requester.organisation,
      link,
      message,
      createdAt: new Date().toISOString(),
      type: "TIME_OFF_DELETED",
      author: deletedById,
    };
  });

  await createNotifications(notifications);

  for (const notification of notifications) {
    const { message, link, id } = notification;
    const receiverId = notification.userId;

    await sendNotificationEmail({ receiverId, link, message, id, type: "TIME_OFF_DELETED" });
  }
}

function getUsersWithTimeOffManagePermission(users, changedById) {
  const targetUsers = users.filter((user) => {
    const allUserPermissions = getAllPermissionsForUser(user);
    return allUserPermissions.includes("TIME_OFF.MANAGE") && user.id !== changedById;
  });

  return targetUsers;
}

///////////////////////////////////// Time off END /////////////////////////////////////////////////

///////////////////////////////////// Requests START /////////////////////////////////////////////////

export async function sendRequestFormCreatedNotification({
  users,
  request,
  formName,
  formFileId,
  apiUser,
  organisationDetails,
}) {
  const type = "NEW_REQUEST_FORM_CREATED";
  const link = `requests/${request.id}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  const requesterDetails = users.find((x) => x.id === request.requestedBy);

  if (!requesterDetails) {
    return;
  }

  if (
    organisationDetails.settings?.request?.emailsToNotifyOnNewRequest &&
    organisationDetails.settings?.request?.emailsToNotifyOnNewRequest.length > 0
  ) {
    try {
      const emailParams = {
        type,
        senderDetails: {
          firstName: apiUser.firstName,
          lastName: apiUser.lastName,
          avatar: apiUser.avatarKey ? `${PUBLIC_S3_BUCKET_URL}/public/${apiUser.avatarKey}` : GENERIC_AVATAR_URL,
          id: apiUser.id,
        },
        receiverEmails: organisationDetails.settings?.request?.emailsToNotifyOnNewRequest,
        requestTitle: request.title,
        requestId: processIdForDisplay(request.id),
        link: `${window.location.origin}/${link}?notificationId=${id}&formFileId=${formFileId}`,
        formName,
        id,
      };

      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      message.error("Failed to send email notification");
    }
  }
}

export async function sendRequestAssignedNotification({ request, apiUser, newAssigneeDetails }) {
  const type = "REQUEST_ASSIGNED";
  const link = `requests/${request.id}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  if (!newAssigneeDetails) {
    return;
  }

  if (
    !newAssigneeDetails.notificationSettings ||
    newAssigneeDetails.notificationSettings.webAppRequestActivity !== false
  ) {
    callGraphQLSimple({
      message: "Failed to notify assignee",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: newAssigneeDetails.id,
          organisation: newAssigneeDetails.organisation,
          link: `/${link}`,
          type,
          message: `${getSimpleLabel("Request")} assigned to you: ${processIdForDisplay(request.id)} (${
            request.title
          })`,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }

  if (
    !newAssigneeDetails.notificationSettings ||
    newAssigneeDetails.notificationSettings.emailRequestActivity !== false
  ) {
    try {
      const emailParams = {
        type,
        senderDetails: {
          firstName: apiUser.firstName,
          lastName: apiUser.lastName,
          avatar: apiUser.avatarKey ? `${PUBLIC_S3_BUCKET_URL}/public/${apiUser.avatarKey}` : GENERIC_AVATAR_URL,
          id: apiUser.id,
        },
        receiverDetails: {
          firstName: newAssigneeDetails.firstName,
          lastName: newAssigneeDetails.lastName,
          avatar: newAssigneeDetails.avatarKey
            ? `${PUBLIC_S3_BUCKET_URL}/public/${newAssigneeDetails.avatarKey}`
            : GENERIC_AVATAR_URL,
          id: newAssigneeDetails.id,
        },
        requestTitle: request.title,
        requestId: processIdForDisplay(request.id),
        link: `${window.location.origin}/${link}?notificationId=${id}`,
        id,
      };

      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      message.error("Failed to send email notification");
    }
  }
}

export async function sendRequestInformationSubmittedNotification({
  users,
  request,
  apiUser,
  formName,
  messageContent,
  formFileId,
}) {
  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;
  let assigneeDetails = users.find((x) => x.id === request.assignedTo);

  const type = "REQUEST_INFORMATION_SUBMITTED";
  const link = `requests/${request.id}`;

  if (!assigneeDetails) {
    return;
  }

  if (!assigneeDetails?.notificationSettings || assigneeDetails?.notificationSettings.webAppRequestActivity !== false) {
    callGraphQLSimple({
      message: "Failed to notify assignee",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: assigneeDetails.id,
          organisation: assigneeDetails.organisation,
          link: `/${link}?formFileId=${formFileId}`,
          type,
          message: `Information submitted: ${processIdForDisplay(request.id)} (${request.title})`,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }

  if (!assigneeDetails.notificationSettings || assigneeDetails.notificationSettings.emailRequestActivity !== false) {
    try {
      const emailParams = {
        type,
        senderDetails: {
          firstName: apiUser.firstName,
          lastName: apiUser.lastName,
          avatar: apiUser.avatarKey ? `${PUBLIC_S3_BUCKET_URL}/public/${apiUser.avatarKey}` : GENERIC_AVATAR_URL,
          id: apiUser.id,
        },
        receiverDetails: {
          firstName: assigneeDetails.firstName,
          lastName: assigneeDetails.lastName,
          avatar: assigneeDetails.avatarKey
            ? `${PUBLIC_S3_BUCKET_URL}/public/${assigneeDetails.avatarKey}`
            : GENERIC_AVATAR_URL,
          id: assigneeDetails.id,
        },
        requestTitle: request.title,
        requestId: processIdForDisplay(request.id),
        formName,
        message: messageContent,
        link: `${window.location.origin}/${link}?notificationId=${id}&formFileId=${formFileId}`,
        id,
      };

      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      message.error("Failed to send email notification");
    }
  }
}

export async function sendNotificationToExternalApproverAboutInformationSubmitted({
  request,
  formName,
  messageContent,
  toBeApprovedBy,
  apiUser,
  attachments,
  activityItemId,
}) {
  const link = `url/${activityItemId}`;
  try {
    const emailParams = {
      type: "REQUEST_INFORMATION_SUBMITTED_EXTERNAL_APPROVER",
      senderDetails: {
        firstName: apiUser.firstName,
        lastName: apiUser.lastName,
        avatar: apiUser.avatarKey ? `${PUBLIC_S3_BUCKET_URL}/public/${apiUser.avatarKey}` : GENERIC_AVATAR_URL,
        id: apiUser.id,
      },
      receiverDetails: {
        email: toBeApprovedBy,
      },
      requestTitle: request.title,
      requestId: processIdForDisplay(request.id),
      formName,
      link: `${window.location.origin}/${link}`,
      message: messageContent,
      attachments,
    };

    await callRest({
      route: "/sendNotificationEmail",
      method: "POST",
      body: emailParams,
      includeCredentials: false,
    });
  } catch (e) {
    message.error("Failed to send email notification");
  }
}

export async function sendRequestMoreInformationRequestedNotification({
  users,
  request,
  apiUser,
  messageContent,
  formName,
  formFileId,
  receiverId,
}) {
  const type = "REQUEST_MORE_INFORMATION_REQUESTED";
  const link = `requests/${request.id}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  const receiverDetails = users.find((x) => x.id === receiverId);

  if (!receiverDetails) {
    return;
  }

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.webAppRequestActivity !== false) {
    callGraphQLSimple({
      message: "Failed to notify requester",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: receiverDetails.id,
          organisation: receiverDetails.organisation,
          link: `/${link}?formFileId=${formFileId}`,
          type,
          message: `More information needed: ${processIdForDisplay(request.id)} (${request.title})`,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.emailRequestActivity !== false) {
    try {
      const emailParams = {
        type,
        senderDetails: {
          firstName: apiUser.firstName,
          lastName: apiUser.lastName,
          avatar: apiUser.avatarKey ? `${PUBLIC_S3_BUCKET_URL}/public/${apiUser.avatarKey}` : GENERIC_AVATAR_URL,
          id: apiUser.id,
        },
        receiverDetails: {
          firstName: receiverDetails.firstName,
          lastName: receiverDetails.lastName,
          avatar: receiverDetails.avatarKey
            ? `${PUBLIC_S3_BUCKET_URL}/public/${receiverDetails.avatarKey}`
            : GENERIC_AVATAR_URL,
          id: receiverDetails.id,
        },
        requestTitle: request.title,
        requestId: processIdForDisplay(request.id),
        formName,
        message: messageContent,
        link: `${window.location.origin}/${link}?notificationId=${id}&formFileId=${formFileId}`,
        id,
      };

      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      message.error("Failed to send email notification");
    }
  }
}

export async function sendRequestFormRejectedNotification({
  users,
  request,
  apiUser,
  messageContent,
  formName,
  formFileId,
  receiverId,
}) {
  const type = "REQUEST_FORM_REJECTED";
  const link = `requests/${request.id}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  const receiverDetails = users.find((x) => x.id === receiverId);

  if (!receiverDetails) {
    return;
  }

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.webAppRequestActivity !== false) {
    callGraphQLSimple({
      message: "Failed to notify requester",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: receiverDetails.id,
          organisation: receiverDetails.organisation,
          link: `/${link}?formFileId=${formFileId}`,
          type,
          message: `Request form rejected: ${processIdForDisplay(request.id)} (${request.title})`,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.emailRequestActivity !== false) {
    try {
      const emailParams = {
        type,
        senderDetails: {
          firstName: apiUser.firstName,
          lastName: apiUser.lastName,
          avatar: apiUser.avatarKey ? `${PUBLIC_S3_BUCKET_URL}/public/${apiUser.avatarKey}` : GENERIC_AVATAR_URL,
          id: apiUser.id,
        },
        receiverDetails: {
          firstName: receiverDetails.firstName,
          lastName: receiverDetails.lastName,
          avatar: receiverDetails.avatarKey
            ? `${PUBLIC_S3_BUCKET_URL}/public/${receiverDetails.avatarKey}`
            : GENERIC_AVATAR_URL,
          id: receiverDetails.id,
        },
        requestTitle: request.title,
        requestId: processIdForDisplay(request.id),
        formName,
        message: messageContent,
        link: `${window.location.origin}/${link}?notificationId=${id}&formFileId=${formFileId}`,
        id,
      };

      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      message.error("Failed to send email notification");
    }
  }
}

export async function sendRequestFormAcceptedNotification({
  users,
  request,
  apiUser,
  formName,
  formFileId,
  receiverId,
}) {
  const type = "REQUEST_FORM_ACCEPTED";
  const link = `requests/${request.id}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  const receiverDetails = users.find((x) => x.id === receiverId);

  if (!receiverDetails) {
    return;
  }

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.webAppRequestActivity !== false) {
    callGraphQLSimple({
      message: "Failed to notify requester",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: receiverDetails.id,
          organisation: receiverDetails.organisation,
          link: `/${link}?formFileId=${formFileId}`,
          type,
          message: `${getSimpleLabel("Request")} form accepted: ${processIdForDisplay(request.id)} (${request.title})`,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.emailRequestActivity !== false) {
    try {
      const emailParams = {
        type,
        senderDetails: {
          firstName: apiUser.firstName,
          lastName: apiUser.lastName,
          avatar: apiUser.avatarKey ? `${PUBLIC_S3_BUCKET_URL}/public/${apiUser.avatarKey}` : GENERIC_AVATAR_URL,
          id: apiUser.id,
        },
        receiverDetails: {
          firstName: receiverDetails.firstName,
          lastName: receiverDetails.lastName,
          avatar: receiverDetails.avatarKey
            ? `${PUBLIC_S3_BUCKET_URL}/public/${receiverDetails.avatarKey}`
            : GENERIC_AVATAR_URL,
          id: receiverDetails.id,
        },
        requestTitle: request.title,
        requestId: processIdForDisplay(request.id),
        formName,
        link: `${window.location.origin}/${link}?notificationId=${id}&formFileId=${formFileId}`,
        id,
      };

      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      message.error("Failed to send email notification");
    }
  }
}

export async function sendRequestFormReceivedNotification({
  users,
  request,
  apiUser,
  formName,
  formFileId,
  receiverId,
}) {
  const type = "REQUEST_FORM_RECEIVED";
  const link = `requests/${request.id}`;

  const id = `${Date.now()}${Math.floor(Math.random() * 10000)}`;

  const receiverDetails = users.find((x) => x.id === receiverId);

  if (!receiverDetails) {
    return;
  }

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.webAppRequestActivity !== false) {
    callGraphQLSimple({
      message: "Failed to notify requester",
      queryName: "createNotification",
      variables: {
        input: {
          id,
          userId: receiverDetails.id,
          organisation: receiverDetails.organisation,
          link: `/${link}?formFileId=${formFileId}`,
          type,
          message: `${getSimpleLabel("Request")} form received: ${processIdForDisplay(request.id)} (${request.title})`,
          createdAt: new Date().toISOString(),
          author: apiUser.id,
        },
      },
    });
  }

  if (!receiverDetails.notificationSettings || receiverDetails.notificationSettings.emailRequestActivity !== false) {
    try {
      const emailParams = {
        type,
        senderDetails: {
          firstName: apiUser.firstName,
          lastName: apiUser.lastName,
          avatar: apiUser.avatarKey ? `${PUBLIC_S3_BUCKET_URL}/public/${apiUser.avatarKey}` : GENERIC_AVATAR_URL,
          id: apiUser.id,
        },
        receiverDetails: {
          firstName: receiverDetails.firstName,
          lastName: receiverDetails.lastName,
          avatar: receiverDetails.avatarKey
            ? `${PUBLIC_S3_BUCKET_URL}/public/${receiverDetails.avatarKey}`
            : GENERIC_AVATAR_URL,
          id: receiverDetails.id,
        },
        requestTitle: request.title,
        requestId: processIdForDisplay(request.id),
        formName,
        link: `${window.location.origin}/${link}?notificationId=${id}&formFileId=${formFileId}`,
        id,
      };

      await callRest({
        route: "/sendNotificationEmail",
        method: "POST",
        body: emailParams,
        includeCredentials: false,
      });
    } catch (e) {
      message.error("Failed to send email notification");
    }
  }
}

export async function sendTaskFilesSentIssuedNotification({ to, cc, bcc, subject, messageContent, link, apiUser }) {
  const type = "TASK_FILES_SENT";

  try {
    const emailParams = {
      type,
      senderDetails: {
        firstName: apiUser.firstName,
        lastName: apiUser.lastName,
        id: apiUser.id,
      },
      to,
      cc,
      bcc,
      subject,
      message: messageContent,
      link,
    };

    await callRest({
      route: "/sendNotificationEmail",
      method: "POST",
      body: emailParams,
      includeCredentials: false,
    });
  } catch (e) {
    message.error("Failed to send email notification");
  }
}

///////////////////////////////////// Requests END /////////////////////////////////////////////////

async function createNotifications(notifications) {
  for (const notification of notifications) {
    callGraphQLSimple({
      message: "Failed to create notification",
      queryName: "createNotification",
      variables: {
        input: {
          ...notification,
          author: window.apiUser.id,
        },
      },
    });
  }
}

async function sendNotificationEmail({ receiverId, link, message, id, type }) {
  const receiver = await getUserDetails(receiverId);

  let completeLink = `${window.location.origin}${link}`;

  if (link.includes("?")) {
    completeLink = `${completeLink}&notificationId=${id}`;
  } else {
    completeLink = `${completeLink}?notificationId=${id}`;
  }

  const emailParams = {
    receiverDetails: {
      firstName: receiver.firstName,
      lastName: receiver.lastName,
      id: receiver.id,
    },
    link: completeLink,
    message,
    type,
    id,
  };

  await callRest({
    route: "/sendNotificationEmail",
    method: "POST",
    body: emailParams,
    includeCredentials: false,
  });
}

async function getUserDetails(userId) {
  const userDetails = (
    await window.callGraphQLSimple({
      message: "Failed to get user",
      query: "getUser",
      variables: {
        id: userId,
      },
    })
  ).data.getUser;

  return userDetails;
}
