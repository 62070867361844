import { Typography } from "antd";
import { callGraphQLSimple } from "common/apiHelpers";
import { TaskRevision } from "common/types";

import Input from "Input/Input";
import { recalculateTaskEstimatedHours } from "common/taskHelpers";

type Props = {
  taskRevision: TaskRevision;
};

export default function TaskRevisionEstimate({ taskRevision }: Props) {
  async function onChange(value) {
    await callGraphQLSimple({
      message: "Failed to update estimate",
      queryCustom: "updateTaskRevision",
      variables: {
        input: {
          id: taskRevision.id,
          estimatedHours: parseFloat(value),
        },
      },
    });

    await recalculateTaskEstimatedHours(taskRevision.taskId);
  }

  return (
    <>
      {taskRevision.isReadOnly ? (
        taskRevision.estimatedHours || 0
      ) : (
        <Input
          className="task-estimated-hours-sidebar-input"
          defaultValue={String(taskRevision.estimatedHours || "") || "0"}
          onChange={onChange}
          fireOnChangeWithoutBlurWithDebounce
          debounceDelay={1000}
        />
      )}
    </>
  );
}
