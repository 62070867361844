import { Dispatch, SetStateAction } from "react";

import { Modal } from "antd";
import Attachments from "Attachments/Attachments";

import "./RawCloudFilesModal.scss";

type Props = {
  versions: any[];
  onClose: () => Dispatch<SetStateAction<boolean>>;
  file: any;
  taskRevision: any;
  taskPath: any;
  project: any;
};

export default function CloudFileHistoryModal(props: Props) {
  const { onClose, file, taskRevision, project, taskPath } = props;

  return (
    <Modal
      maskClosable={false}
      title="Raw cloud storage"
      open={true}
      footer={null}
      className="raw-cloud-files-modal"
      onCancel={onClose}
    >
      <Attachments
        apiUser={window.apiUser}
        defaultPath={`projects/${project.id}${taskPath}/${taskRevision.name}/${file.type.toLowerCase()}/${file.id}/`}
        isDocumentLibrary={false}
        project={project}
        isInModal={true}
        readOnly
      />
    </Modal>
  );
}
