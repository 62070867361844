export function getDetailsForFormAndTaskRevision({ activityItemsByRequest, taskRevisionId }) {
  if (!activityItemsByRequest) {
    return undefined;
  }
  for (let requestId in activityItemsByRequest) {
    const activityItems = activityItemsByRequest[requestId];
    const activityItemsForTaskRevision = activityItems.filter(
      (activityItem) => activityItem.content.taskRevisionId === taskRevisionId
    );

    if (activityItemsForTaskRevision.length === 0) {
      continue;
    }

    const latestItemForAdding = [...activityItemsForTaskRevision]
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      .find((activityItem) => activityItem.content.type === "REQUEST_FORM_ADDED_TO_TASK_REVISION");

    const latestItemForRemoving = [...activityItemsForTaskRevision]
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      .find((activityItem) => activityItem.content.type === "REQUEST_FORM_REMOVED_FROM_TASK_REVISION");

    if (
      latestItemForAdding &&
      (!latestItemForRemoving || latestItemForAdding.createdAt > latestItemForRemoving.createdAt)
    ) {
      return latestItemForAdding;
    }
  }
}
