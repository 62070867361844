import { Storage } from "aws-amplify";
import { publish } from "common/helpers";
import { DOCUMENT_PAGE_SIZES } from "common/constants";
import { FILE_TYPES_DETAILS } from "common/shared";
import { NEUTRAL_COLOR } from "TemplateEditorPage/renderHelpers";

export async function createTemplate(params) {
  let {
    id,
    fileToUpload,
    annotationFile,
    sourceTemplate,
    organisationDetails,
    type,
    name,
    projects,
    apiUser,
    outputType = "PDF",
    parentType,
  } = params;
  organisationDetails = organisationDetails || window.organisationDetails;
  apiUser = apiUser || window.apiUser;
  const fileTypeDetails = FILE_TYPES_DETAILS[type];

  let extension = "json";
  if (sourceTemplate) {
    extension = sourceTemplate.key.split(".").slice(-1)[0];
    name = `${sourceTemplate.name} - copy`;
  } else if (fileToUpload) {
    extension = fileToUpload.name.split(".").slice(-1)[0];
  }

  if (fileTypeDetails.isDocumentTemplate) {
    let defaultFields = {};

    if (["QUOTE", "INVOICE", "PURCHASE_ORDER"].includes(type)) {
      defaultFields.emailText = {
        label: "Email content",
        adminOrder: 0,
        placeholder: "This is the text that will be included in the email",
        type: "textarea",
        value: "",
        textOnly: true,
      };
    }

    fileToUpload =
      fileToUpload ||
      JSON.stringify(
        {
          fields: defaultFields,
          lineItemFields: {},
          lineItems: [],
        },
        null,
        2
      );
  }

  let newTemplateDetails = (
    await window.callGraphQLSimple({
      message: "Failed to create template",
      mutation: "createTemplate",
      variables: {
        input: {
          id,
          name,
          organisation: organisationDetails.id,
          currentVersionNumber: 0,
          type,
          outputType,
          parentType,
        },
      },
    })
  ).data.createTemplate;

  let newTemplateKey = `${organisationDetails.id}/templates/${type}/${newTemplateDetails.id}_${newTemplateDetails.currentVersionNumber}.${extension}`;

  newTemplateDetails = (
    await window.callGraphQLSimple({
      message: "Failed to record template key",
      mutation: "updateTemplate",
      variables: {
        input: {
          id: newTemplateDetails.id,
          key: newTemplateKey,
        },
      },
    })
  ).data.updateTemplate;

  const projectId = `${organisationDetails.id}-TEMPLATES`;

  if (fileTypeDetails.isPartOfATask) {
    try {
      if (!projects?.find((x) => x.id === projectId)) {
        await window.callGraphQLSimple({
          queryCustom: "createProject",
          variables: {
            input: {
              id: projectId,
              clientId: "DRAUGHTHUB",
              author: apiUser.id,
              title: `${type} Template`,
              organisation: organisationDetails.id,
            },
          },
        });
      }
    } catch (e) {
      // nothing, it means the project already exists
    }

    const templateTask = (
      await window.callGraphQLSimple({
        message: "Failed to create template task",
        queryCustom: "createTask",
        variables: {
          input: {
            id: `${projectId}-${newTemplateDetails.id}`,
            projectId: projectId,
            clientId: "DRAUGHTHUB",
            status: "nothing",
            title: `${type} Template`,
            organisation: organisationDetails.id,
          },
        },
      })
    ).data.createTask;

    const templateTaskRevision = (
      await window.callGraphQLSimple({
        message: "Failed to create template task revision",
        queryCustom: "createTaskRevision",
        variables: {
          input: {
            taskId: templateTask.id,
            name: "A",
            organisation: organisationDetails.id,
            description: "Template created",
            reviewId: "nothing",
          },
        },
      })
    ).data.createTaskRevision;

    const templateFile = (
      await window.callGraphQLSimple({
        message: "Failed to create template file",
        queryCustom: "createFile",
        variables: {
          input: {
            name,
            taskRevisionId: templateTaskRevision.id,
            type,
            organisation: templateTask.organisation,
            extension,
            skipAnnotation: true,
          },
        },
      })
    ).data.createFile;

    const createFileVersionInput = {
      versionNumber: 0,
      fileId: templateFile.id,
      key: `public/${newTemplateDetails.key}`,
      organisation: apiUser.organisation,
      exports: [
        {
          extension: ".pdf",
          key: `public/${newTemplateDetails.key.split(".")[0]}.pdf`,
          rawKey: `public/${newTemplateDetails.key.split(".")[0]}_raw.pdf`,
        },
      ],
    };

    const templateFileVersion = (
      await window.callGraphQLSimple({
        message: "Failed to create template file version",
        queryCustom: "createFileVersion",
        variables: {
          input: createFileVersionInput,
        },
      })
    ).data.createFileVersion;

    const templateSheet = (
      await window.callGraphQLSimple({
        message: "Failed to create template sheet",
        mutation: "createSheet",
        variables: {
          input: {
            taskId: templateTask.id,
            fileId: templateFile.id,
            fileType: type,
            order: "0",
            name: "Sheet1",
            includeInPublish: true,
          },
        },
      })
    ).data.createSheet;

    await window.callGraphQLSimple({
      message: "Failed to create template sheet revision",
      mutation: "createSheetRevision",
      variables: {
        input: {
          sheetId: templateSheet.id,
          status: "nothing",
          author: apiUser.id,
          name: "A",
          fileVersionId: templateFileVersion.id,
          exports: [
            {
              extension: ".pdf",
              key: `public/${newTemplateDetails.key.split(".")[0]}_sheet1.pdf`,
              rawKey: `public/${newTemplateDetails.key.split(".")[0]}_sheet1_raw.pdf`,
            },
          ],
        },
      },
    });

    if (fileToUpload || sourceTemplate) {
      if (sourceTemplate) {
        await Storage.copy(
          {
            key: sourceTemplate.key,
          },
          {
            key: newTemplateDetails.key,
          }
        );
      } else {
        await Storage.put(newTemplateDetails.key, fileToUpload);
      }

      if (!fileTypeDetails.isDocumentTemplate) {
        await publish({
          file: templateFile,
          fileVersionId: templateFileVersion.id,
          task: templateTask,
          taskRevisionId: templateTaskRevision.id,
        });
      }
    }
  } else {
    if (fileToUpload) {
      if (sourceTemplate) {
        await Storage.copy(
          {
            key: sourceTemplate.key,
          },
          {
            key: newTemplateDetails.key,
          }
        );
      } else {
        await Storage.put(newTemplateDetails.key, fileToUpload);
      }
    }
  }

  let destinationJsonFileKey = `${newTemplateDetails.key.split(".")[0]}_annotation.json`;
  if (sourceTemplate) {
    let sourceJsonFileKey = `${sourceTemplate.key.split(".")[0]}_annotation.json`;
    await Storage.copy(
      {
        key: sourceJsonFileKey,
      },
      {
        key: destinationJsonFileKey,
      }
    );
  } else {
    if (!annotationFile) {
      const exportedData = {
        version: "5.2.1",
        objects: [],
      };

      if (fileTypeDetails.isDocumentTemplate) {
        exportedData.objects.push({
          type: "group",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          width: DOCUMENT_PAGE_SIZES.A4.width,
          height: DOCUMENT_PAGE_SIZES.A4.height,
          custom_pageHeight: DOCUMENT_PAGE_SIZES.A4.height,
          custom_pageWidth: DOCUMENT_PAGE_SIZES.A4.width,
          custom_pageContentHeight: DOCUMENT_PAGE_SIZES.A4.height,
          custom_pageContentWidth: DOCUMENT_PAGE_SIZES.A4.width,
          left: 0,
          top: 0,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          rx: 0,
          ry: 0,
          custom_name: "Page1",
          custom_index: 1,
          custom_type: "page",
          custom_id: "page-1",
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true,
          lockUniScaling: true,
          lockRotation: true,
          objects: [],
        });
      }
      await Storage.put(destinationJsonFileKey, JSON.stringify(exportedData, null, 2));
    } else {
      await Storage.put(destinationJsonFileKey, annotationFile);
    }
  }
  await window.callGraphQLSimple({
    message: "Failed to refresh organisation details",
    mutation: "updateOrganisation",
    variables: {
      input: {
        id: organisationDetails.id,
        itemSubscription: Math.floor(Math.random() * 1000000),
      },
    },
  });

  return newTemplateDetails;
}

export function getImageKeysFromOutputTemplate({ parent }) {
  let imageKeys = [];
  if (parent.objects) {
    for (let object of parent.objects) {
      if (object.type === "group") {
        imageKeys = [...imageKeys, ...getImageKeysFromOutputTemplate({ parent: object })];
      } else if (object.custom_imageKey) {
        imageKeys.push(object.custom_imageKey);
      }
    }
  }

  return imageKeys;
}

export function replaceOrganisationIdInImageKeys({ parent, newOrganisationId, oldOrganisationId }) {
  if (parent.objects) {
    for (let object of parent.objects) {
      if (object.type === "group") {
        replaceOrganisationIdInImageKeys({
          parent: object,
          newOrganisationId,
          oldOrganisationId,
        });
      } else if (object.custom_imageKey) {
        object.custom_imageKey = object.custom_imageKey.replace(`${oldOrganisationId}/`, `${newOrganisationId}/`);
      }
    }
  }
}

export function getFirstValidTemplateForType({ organisationDetails, type }) {
  let templatesForType = organisationDetails.templates?.items?.filter((x) => x.type === type);

  let templateId;
  const typeDetails = FILE_TYPES_DETAILS[type];

  if (templatesForType?.length) {
    let defaultInvoiceTemplate = templatesForType.find((x) => x.isDefault);
    if (defaultInvoiceTemplate) {
      templateId = defaultInvoiceTemplate.id;
    } else {
      let firstLiveInvoiceTemplate = templatesForType.find((x) => x.isLive);
      if (firstLiveInvoiceTemplate) {
        templateId = firstLiveInvoiceTemplate.id;
      } else {
        let firstValidInvoiceTemplate = templatesForType.find((x) => !x.isDeprecated);
        if (firstValidInvoiceTemplate) {
          templateId = firstValidInvoiceTemplate.id;
        }
      }
    }
  } else {
    throw new Error(`No "${typeDetails.label}" templates found. Please create one first`);
  }

  if (!templateId) {
    throw new Error(`No valid "${typeDetails.label}" templates found. Please create one first`);
  }

  return templateId;
}
