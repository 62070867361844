import { Tag } from "antd";
import cx from "classnames";
import { Link } from "react-router-dom";
import { StarFilled } from "@ant-design/icons";
import { processIdForDisplay } from "common/helpers";

import "./ProjectIdTag.scss";

export default function ProjectIdTag({ project, includeTitle = true, style }) {
  return (
    <Link to={`/projects/${project.id}`}>
      <Tag className={cx("project-id-tag", "dark-tag", { hidden: !project?.id })} style={style}>
        {project.client && project.client.isPriority && <StarFilled className="priority-marker" />}{" "}
        {processIdForDisplay(project.id)}
      </Tag>
      {includeTitle && (project.title?.length > 50 ? `${project.title?.substring(0, 50)}...` : project.title)}
    </Link>
  );
}
