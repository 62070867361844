import React from "react";

export default function withAuth(WrappedComponent) {
  return class extends React.Component {
    render() {
      if (!window.apiUser) {
        return null;
      }

      return <WrappedComponent {...this.props} />;
    }
  };
}
