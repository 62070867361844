import moment from "moment";

const fields = [
  {
    id: "invoiceLineItemTitle",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Title",
    value: ({ invoiceLineItem }) => invoiceLineItem?.title || "",
  },
  {
    id: "invoiceLineItemTitleAndTaskId",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Title and task ID",
    value: ({ invoiceLineItem }) => {
      let result = "";
      if (invoiceLineItem.resultingTaskId) {
        result += `${invoiceLineItem.resultingTaskId} - `;
      }
      result += invoiceLineItem.title;
      return result;
    },
  },
  {
    id: "invoiceLineItemTitleAndTaskIdAndDescription",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Title, task ID and description",
    value: ({ invoiceLineItem }) => {
      let result = "";
      if (invoiceLineItem.resultingTaskId) {
        result += `${invoiceLineItem.resultingTaskId} - `;
      }
      result += invoiceLineItem.title;
      if (invoiceLineItem?.description) {
        result = result ? `${result}\n${invoiceLineItem.description}` : invoiceLineItem.description;
      }
      return result;
    },
  },
  {
    id: "invoiceLineItemTitleAndDescription",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Title and description",
    value: ({ invoiceLineItem }) => {
      let result = invoiceLineItem?.title || "";
      if (invoiceLineItem?.description) {
        result = result ? `${result}\n${invoiceLineItem.description}` : invoiceLineItem.description;
      }
      return result;
    },
  },
  {
    id: "invoiceLineItemDescription",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Description",
    value: ({ invoiceLineItem }) => invoiceLineItem?.description || "",
  },

  {
    id: "invoiceLineItemQuantity",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Quantity",
    value: ({ invoiceLineItem }) => invoiceLineItem?.quantity || "",
  },
  {
    id: "invoiceLineItemTaxRate",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Tax rate",
    value: ({ invoiceLineItem, invoice }) => invoiceLineItem?.taxRate || invoice.taxRate,
  },
  {
    id: "invoiceLineItemDiscountPercent",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Discount percent",
    value: ({ invoiceLineItem }) => invoiceLineItem?.discountPercent || "",
  },
  {
    id: "invoiceLineItemQuotedAmount",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Quoted amount",
    value: ({ invoiceLineItem }) => global.formatCurrency("GBP", invoiceLineItem.quotedAmount),
  },
  {
    id: "invoiceLineItemPercentageInvoicedThisTime",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Percentage invoiced this time",
    value: ({ invoiceLineItem }) => `${invoiceLineItem.percentageInvoicedThisTime || 0}%`,
  },
  {
    id: "invoiceLineItemDate",
    type: "string",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Timesheet block date",
    value: ({ invoiceLineItem, dateFormat }) => {
      if (!invoiceLineItem) {
        return "";
      } else {
        if (!invoiceLineItem.date) {
          return "";
        } else {
          return moment(invoiceLineItem.date).format(dateFormat);
        }
      }
    },
  },
  {
    id: "invoiceLineItemStartTime",
    type: "string",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Timesheet block start time",
    value: ({ invoiceLineItem, dateFormat }) => {
      if (!invoiceLineItem) {
        return "";
      } else {
        if (!invoiceLineItem.timesheetBlockStartAt) {
          return "";
        } else {
          return moment(invoiceLineItem.timesheetBlockStartAt).format(dateFormat);
        }
      }
    },
  },
  {
    id: "invoiceLineItemEndTime",
    type: "string",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Timesheet block end time",
    value: ({ invoiceLineItem, dateFormat }) => {
      if (!invoiceLineItem) {
        return "";
      } else {
        if (!invoiceLineItem.timesheetBlockEndAt) {
          return "";
        } else {
          return moment(invoiceLineItem.timesheetBlockEndAt).format(dateFormat);
        }
      }
    },
  },
  {
    id: "invoiceLineUnitPrice",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Unit price",
    value: ({ invoiceLineItem }) => {
      if (!invoiceLineItem || invoiceLineItem.unitPrice === null || invoiceLineItem.unitPrice === undefined) {
        return "";
      }

      return global.formatCurrency("GBP", invoiceLineItem.unitPrice);
    },
  },
  {
    id: "invoiceLineUnitPriceAndFeeRole",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Unit price and fee level",
    value: ({ invoiceLineItem }) => {
      if (!invoiceLineItem || invoiceLineItem.unitPrice === null || invoiceLineItem.unitPrice === undefined) {
        return "";
      }

      return invoiceLineItem.unitPriceAndFeeRole;
    },
  },
  {
    id: "invoiceLineUnitFeeRole",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Fee level",
    value: ({ invoiceLineItem }) => {
      return invoiceLineItem.feeRole;
    },
  },
  {
    id: "invoiceLineItemAmount",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Amount excluding tax",
    value: ({ invoiceLineItem }) => {
      if (!invoiceLineItem || invoiceLineItem.amount === null || invoiceLineItem.amount === undefined) {
        return "";
      }

      return global.formatCurrency("GBP", invoiceLineItem.amount);
    },
  },
  {
    id: "invoiceLineItemAmountWithTax",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Amount including tax",
    value: ({ invoiceLineItem }) => {
      if (!invoiceLineItem) {
        return "";
      }
      let result = (invoiceLineItem.amount || 0) + (invoiceLineItem.taxAmount || 0);

      return global.formatCurrency("GBP", result);
    },
  },
  {
    id: "invoiceLineItemAmountTax",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Tax amount",
    value: ({ invoiceLineItem }) => {
      if (!invoiceLineItem || invoiceLineItem.taxAmount === null || invoiceLineItem.taxAmount === undefined) {
        return "";
      }

      return global.formatCurrency("GBP", invoiceLineItem.taxAmount);
    },
  },
];
export function getFields() {
  return fields;
}
