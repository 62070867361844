// Your main component file
import React from "react";
import { Modal } from "antd";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Map } from "./Map";
import { Marker } from "./Marker";

export default function LocationModal({ latitude, longitude, onClose }) {
  const apiKey = window.secrets?.GOOGLE_MAPS;
  const zoom = 13;

  if (!apiKey) {
    return null;
  }

  return (
    <Modal title="Location Coordinates" open onCancel={onClose} footer={null}>
      <Wrapper apiKey={apiKey}>
        <Map
          center={{
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          }}
          zoom={zoom}
          style={{ width: "100%", height: "400px" }}
        >
          <Marker
            position={{
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
            }}
          />
        </Map>
      </Wrapper>
    </Modal>
  );
}
