import { Button, Modal } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useGetSetState } from "react-use";

import { getDetailsForFormAndTaskRevision } from "common/sharedRequestHelpers";
import { getSimpleLabel } from "common/labels";

import RequestReviewModal from "Modals/RequestReviewModal/RequestReviewModal";

interface Props {
  task: any;
  organisationDetails: any;
  users: any;
  activityItemsByRequest: any;
}

export default function TaskReviewButton({ task, organisationDetails, users, activityItemsByRequest }: Props) {
  const [getState, setState] = useGetSetState({
    isRequestReviewModalVisible: false,
  });
  const latestTaskRevision = task.revisions.items.slice(-1)[0];

  async function onRequestReviewClick() {
    if (
      organisationDetails.usesReviewAuthority &&
      (task.catLevel === null || (!organisationDetails.settings?.task?.allowUseOfCatZero && task.catLevel === 0))
    ) {
      Modal.error({
        title: `${getSimpleLabel("Authority level")} required`,
        className: "cannot-request-review-missing-cat-level",
        content: (
          <>
            In order to request a review, first you must choose an {getSimpleLabel("authority level")} for the{" "}
            {getSimpleLabel("task")}
          </>
        ),
        okButtonProps: {
          "data-cy": "authority-level-required-modal-ok",
        },
      });
      return;
    }

    if (!task.assignedTo) {
      Modal.error({
        title: "Assignee required",
        className: "cannot-request-review-missing-assigned",
        content: <>In order to request a review, the {getSimpleLabel("task")} must first be assigned to someone</>,
        okButtonProps: { "data-cy": "assignee-required-modal-ok" },
      });
      return;
    }

    const latestTaskRevision = task.revisions.items.slice(-1)[0];

    let requestFormActivityItem = getDetailsForFormAndTaskRevision({
      activityItemsByRequest,
      taskRevisionId: latestTaskRevision.id,
    });

    if (organisationDetails.settings?.task?.needsRequestForTaskRevisionReview && !requestFormActivityItem) {
      Modal.error({
        title: `${getSimpleLabel("Request")} form required`,
        className: "cannot-request-review-missing-request-form",
        content: (
          <>
            In order to request a review, the latest {getSimpleLabel("task revision")} must have a{" "}
            {getSimpleLabel("request")} form associated with it
          </>
        ),
        okButtonProps: { "data-cy": "assignee-required-modal-ok" },
      });
      return;
    }

    setState({ isRequestReviewModalVisible: true });
  }

  function displayButtonContent() {
    if (!organisationDetails.usesReviews || task.isFinished || task.isArchived) {
      return null;
    }

    if (
      latestTaskRevision &&
      (latestTaskRevision.reviewStatus === "WITH_COMMENTS" ||
        latestTaskRevision.reviewStatus === "CHANGES_REQUESTED" ||
        latestTaskRevision.reviewStatus === "IN_PROGRESS")
    ) {
      return (
        <Link to={`/tasks/${task.id}/review/${latestTaskRevision.id}`} key="go-to-review-task-summary">
          <Button type="dark" className="go-to-review" icon={<EyeOutlined />}>
            See ongoing review
          </Button>
        </Link>
      );
    } else {
      return (
        <Button
          type="dark"
          className="request-review"
          icon={<EyeOutlined />}
          onClick={onRequestReviewClick}
          key="request-review"
          disabled={task.isFinished || task.isArchived || task.isUnderReview || task.isReadOnly}
        >
          Request review
        </Button>
      );
    }
  }

  const { isRequestReviewModalVisible } = getState();

  return (
    <>
      {displayButtonContent()}
      {isRequestReviewModalVisible && (
        <RequestReviewModal
          users={users}
          task={task}
          onClose={() => {
            setState({ isRequestReviewModalVisible: false });
          }}
          visible={true}
        />
      )}
    </>
  );
}
