import { message } from "antd";
import { DOCUMENT_PAGE_SIZES } from "common/constants";

import { TOOLS } from "./Toolbar/Toolbar";

export default function createNewObject({ toolName, fileTypeDetails, parent }) {
  let commonProps = {
    custom_id: `${Date.now()}-${Math.floor(Math.random() * 1000)}`,
    visible: true,
  };

  if (!fileTypeDetails.isDocumentTemplate) {
    commonProps = {
      ...commonProps,
      top: 0,
      left: 0,
    };
  }

  let newObject;
  switch (toolName) {
    case TOOLS.COMPONENT:
      newObject = {
        ...commonProps,
        width: 100,
        height: 100,
        type: "rect",
        custom_type: "component",
        custom_name: `Component`,
      };
      break;
    case TOOLS.QR_CODE:
      newObject = {
        ...commonProps,
        width: 100,
        type: "rect",
        custom_type: "qr-code",
        custom_name: `QR Code`,
      };
      break;
    case TOOLS.RECTANGLE:
      newObject = {
        ...commonProps,
        type: "rect",
        fill: "#ffffff",
        formula_width: "100",
        formula_height: "100",
        width: 100,
        height: 100,
        stroke: "#000000",
        strokeWidth: 1,
        custom_name: `Rectangle`,
      };
      break;
    case TOOLS.SECTION:
      newObject = {
        ...commonProps,
        type: "group",
        objects: [],
        custom_type: "section",
        custom_name: `Section`,
        custom_isUsingOldSystem: false,
      };
      break;
    case TOOLS.CHAPTER:
      newObject = {
        ...commonProps,
        type: "group",
        objects: [],
        custom_type: "chapter",
        custom_name: `Chapter`,
        custom_isUsingOldSystem: false,
      };
      break;
    case TOOLS.ELLIPSE:
      newObject = {
        ...commonProps,
        type: "ellipse",
        fill: "#ffffff",
        rx: 100,
        ry: 100,
        stroke: "#000000",
        strokeWidth: 1,
        custom_name: `Ellipse`,
      };
      break;
    case TOOLS.TEXT:
      newObject = {
        ...commonProps,
        text: "Text",
        type: "text",
        fill: "#000000",
        fontSize: 20,
        formula_fontSize: 20,
        custom_name: `Text`,
        width: 100,
        height: 20,

        formula_width: "100",
        formula_height: "20",
        fontFamily: "helvetica",
      };
      break;
    case TOOLS.SIGNATURE:
      newObject = {
        ...commonProps,
        type: "rect",
        width: 100,
        formula_width: 100,
        height: 100,
        formula_height: 100,
        custom_type: "signature",
        custom_name: `Signature`,
      };
      break;
    case TOOLS.DYNAMIC_FILE:
      newObject = {
        ...commonProps,
        type: "rect",
        formula_width: "100%",
        height: 100,
        formula_height: 100,
        strokeWidth: 0,
        custom_type: "dynamic_file",
        custom_name: `Dynamic file`,
      };
      break;
    case TOOLS.IMAGE:
      newObject = {
        ...commonProps,
        type: "rect",
        width: 100,
        height: 100,
        custom_type: "image_container",
        custom_name: `Dynamic image`,
      };
      break;
    case TOOLS.IMAGE_STATIC:
      newObject = {
        ...commonProps,
        type: "image",
        width: 100,
        height: 100,
        custom_type: "image",
        custom_name: `Image`,
      };
      break;
    case TOOLS.PAGE:
      newObject = {
        ...commonProps,
        type: "group",
        custom_name: `Page`,
        custom_type: "page",
        width: Math.floor(DOCUMENT_PAGE_SIZES.A4.width),
        height: Math.floor(DOCUMENT_PAGE_SIZES.A4.height),
        custom_pageHeight: Math.floor(DOCUMENT_PAGE_SIZES.A4.height),
        custom_pageWidth: Math.floor(DOCUMENT_PAGE_SIZES.A4.width),
        custom_pageContentHeight: Math.floor(DOCUMENT_PAGE_SIZES.A4.height),
        custom_pageContentWidth: Math.floor(DOCUMENT_PAGE_SIZES.A4.width),
        objects: [],
      };
      break;

    default:
      message.error("Unknown tool");
      break;
  }

  if (!fileTypeDetails.isDocumentTemplate) {
    return newObject;
  }

  if (toolName === TOOLS.PAGE) {
    if (parent.custom_id !== "root" && parent.custom_type !== "chapter") {
      message.error("Pages can only be added to the document itself or to a chapter");
      return;
    }
  } else if (toolName === TOOLS.CHAPTER) {
    if (parent.custom_id !== "root" && parent.custom_type !== "chapter") {
      message.error("Chapters can only be added to the document itself or to another chapter");
      return;
    }
  } else if (parent.custom_type === "page" && toolName !== TOOLS.SECTION) {
    message.error("Please select a page section before attempting to add an object");
    return;
  } else if (parent.type !== "group") {
    message.error("Please select a page or page section before attempting to add an object");
    return;
  }

  return newObject;
}
