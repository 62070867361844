export default function findAllMatchingObjects(currentObject, conditionCallback, result = []) {
  // Check the current object against the condition
  let foundChild = conditionCallback(currentObject);
  if (foundChild) {
    result.push(currentObject);
  }

  // If the current object has children, recursively process each child
  if (Array.isArray(currentObject.objects)) {
    currentObject.objects.forEach((child) => findAllMatchingObjects(child, conditionCallback, result));
  }

  return result;
}
