const fields = [
  {
    id: "supplierContactFullName",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Full name",
    value: ({ supplierContact }) => {
      if (!supplierContact) {
        return "";
      }

      return `${supplierContact.firstName} ${supplierContact.lastName}`;
    },
  },
  {
    id: "supplierContactFirstName",
    type: "string",
    fieldTypes: ["textfield"],
    label: "First name",
    value: ({ supplierContact }) => {
      if (!supplierContact) {
        return "";
      }

      return supplierContact.firstName || "";
    },
  },
  {
    id: "supplierContactLastName",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Last name",
    value: ({ supplierContact }) => {
      if (!supplierContact) {
        return "";
      }

      return supplierContact.lastName || "";
    },
  },
  {
    id: "supplierContactPosition",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Position",
    value: ({ supplierContact }) => {
      if (!supplierContact) {
        return "";
      }

      return supplierContact.position || "";
    },
  },
  {
    id: "supplierContactPhone",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Phone",
    value: ({ supplierContact }) => {
      if (!supplierContact) {
        return "";
      }

      return supplierContact.phone || "";
    },
  },
];
export function getFields() {
  return fields;
}

export const label = "File";
