import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "antd";

import withSubscriptions from "common/withSubscriptions";
import { tableColumns } from "./tableColumns";
import { downloadBase64, sanitiseCSVValue } from "common/helpers";

import Tabs from "reusableComponents/Tabs/Tabs";
import TimeOffForAllUsers from "../TimeOffForAllUsers/TimeOffForAllUsers";

import "./TimeOffPage.scss";

export function TimeOffPage(props) {
  const { orderedActiveUsers, apiUser, history, holidays, setBoxedLayout } = props;
  const [activeTab, setActiveTab] = useState("holidays"); // eslint-disable-line
  const [defaultExpandedRow, setDefaultExpandedRow] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // const tab = urlParams.get("tab");
    // if (tab) {
    //   setActiveTab(tab);
    // }

    const defaultExpandedUserId = urlParams.get("defaultExpandedRowUserId");

    if (defaultExpandedUserId) {
      setDefaultExpandedRow(defaultExpandedUserId);
    }
    setBoxedLayout(false);
    return () => {
      setBoxedLayout(true);
    };
  }, []);

  async function exportToCsv() {
    const columns = [...tableColumns];
    let csvContent = columns.map((column) => column.title).join(",") + "\n";

    holidays
      .filter((holiday) => {
        if (activeTab === "holidays") {
          return !holiday.isSick;
        }
        return holiday.isSick;
      })
      .forEach((timesheetBlock) => {
        csvContent +=
          columns
            .map((column) => {
              if (column.fieldFunction) {
                return sanitiseCSVValue(column.fieldFunction(timesheetBlock, props));
              } else {
                return sanitiseCSVValue(timesheetBlock[column.dataIndex]);
              }
            })
            .join(",") + "\n";
      });

    let base64CSV = `data:text/csv;base64,${btoa(unescape(encodeURIComponent(csvContent)))}`;
    console.log("activeTab =", activeTab);
    await downloadBase64({
      base64String: base64CSV,
      fileName: `${activeTab === "holidays" ? "holidays" : "sick days"}.csv`,
    });
  }

  return (
    <div className="time-off-page">
      <Tabs
        className="time-off-tabs"
        activeKey={activeTab}
        onTabClick={(tabKey) => {
          history.push(`/timeoff?tab=${tabKey}`);
          setActiveTab(tabKey);
        }}
        tabBarExtraContent={{
          right: <Button onClick={exportToCsv}>Export to CSV </Button>,
        }}
        items={[
          {
            id: "holidays",
            title: "Holidays",
            content: (
              <TimeOffForAllUsers
                apiUser={apiUser}
                holidays={holidays.filter((holiday) => !holiday.isSick)}
                users={orderedActiveUsers}
                isSick={false}
                key="holidays"
                defaultExpandedRow={defaultExpandedRow}
              />
            ),
          },
          {
            id: "sickDays",
            title: "Sick days",
            content: (
              <TimeOffForAllUsers
                apiUser={apiUser}
                holidays={holidays.filter((holiday) => holiday.isSick)}
                users={orderedActiveUsers}
                isSick={true}
                key="sick"
                defaultExpandedRow={defaultExpandedRow}
              />
            ),
          },
        ]}
      />
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TimeOffPage,
    subscriptions: ["users", "tasks", "projects", "clients", "organisationDetails", "holidays"],
  })
);
