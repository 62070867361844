import { Dropdown, Button, Typography } from "antd";
import type { MenuProps } from "antd";
import { useGetSetState } from "react-use";
import { PlusCircleOutlined } from "@ant-design/icons";
import AddFileToTaskRevisionModal from "Modals/AddFileToTaskRevisionModal/AddFileToTaskRevisionModal";

interface Props {
  adminTask: any;
  refreshAdminTask: () => void;
  parentType: string;
}

interface State {
  isAddFileModalVisible: boolean;
  fileType: string | undefined;
}

export default function AddFileButton({ adminTask, refreshAdminTask, parentType }: Props) {
  const [getState, setState] = useGetSetState<State>({
    isAddFileModalVisible: false,
    fileType: undefined,
  });

  const { isAddFileModalVisible, fileType } = getState();

  // const { revision } = this.props;
  // const { isCreatingFile } = this.state;

  // const menuItems: MenuProps["items"] = [
  //   {
  //     key: "REPORT",
  //     label: (
  //       <Typography.Text
  //         onClick={() => {
  //           setState({ isAddFileModalVisible: true, fileType: "REPORT" });
  //         }}
  //       >
  //         Report
  //       </Typography.Text>
  //     ),
  //   },
  // {
  //   key: "REPORT-SPREADSHEET",
  //   label: (
  //     <Typography.Text
  //       onClick={() => {
  //         setState({ isAddFileModalVisible: true, fileType: "REPORT-SPREADSHEET" });
  //       }}
  //     >
  //       Spreadsheet report
  //     </Typography.Text>
  //   ),
  // },
  // ];

  return (
    <>
      {/* <Dropdown menu={{ items: menuItems }} trigger={["click"]} overlayClassName="add-file-overlay"> */}
      <Button
        type="primary"
        className="add-file-button"
        onClick={() => {
          setState({ isAddFileModalVisible: true, fileType: "REPORT" });
        }}
        icon={<PlusCircleOutlined />}
      >
        <>
          <span>Add report</span>
        </>
      </Button>
      {/* </Dropdown> */}
      {isAddFileModalVisible && (
        <AddFileToTaskRevisionModal
          visible={true}
          task={adminTask}
          taskRevision={adminTask.revisions.items.slice(-1)[0]}
          fileType={fileType}
          parentType={parentType}
          onClose={() => {
            setState({ isAddFileModalVisible: false });
            refreshAdminTask();
            // fetchAndSetTask();
          }}
        />
      )}
    </>
  );
}
