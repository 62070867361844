import { useState } from "react";

import { Form, Modal, Button, Select, Row, Col } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { TASK_RELATIONSHIPS } from "common/constants";

import { useForceUpdate, linkTasksTogether, getLabel } from "common/helpers";

import CreateTaskModal from "CreateTaskModal/CreateTaskModal";
import TaskPicker from "TaskPicker/TaskPicker";

import "./LinkTaskModal.scss";

export default function LinkTaskModal({ onClose, task, apiUser, visible, tasks, organisationDetails }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateTaskModalVisible, setIsCreateTaskModalVisible] = useState(false);
  const forceUpdate = useForceUpdate();

  async function onSubmit({ linkedTask, relationship }) {
    setIsLoading(true);

    linkTasksTogether({ mainTask: task, linkedTask, relationship });

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  function checkLinkedTask(_, selectedLinkedTaskId) {
    return new Promise((resolve, reject) => {
      if (!selectedLinkedTaskId) {
        return reject("");
      }

      return resolve();
    });
  }

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };

  let tasksToSelectFrom = tasks.filter((x) => x.id !== task.id && !x.isFinished && !x.isArchived);
  let predefinedProjectId;

  if (
    form.getFieldValue("relationship") &&
    (form.getFieldValue("relationship").includes("CAT_2") || form.getFieldValue("relationship").includes("CAT_3"))
  ) {
    if (task.linkedTasks && task.linkedTasks?.length > 0) {
      for (const linkedTask of task.linkedTasks) {
        if (linkedTask.relationship.includes("CAT")) {
          tasksToSelectFrom = [];
        }
      }
    }

    tasksToSelectFrom = tasksToSelectFrom.filter((x) => {
      if (x.linkedTasks && x.linkedTasks?.length > 0) {
        for (const linkedTask of x.linkedTasks) {
          if (linkedTask.relationship.includes("CAT")) {
            return false;
          }
        }
      }

      return x.projectId === task.projectId;
    });
    predefinedProjectId = task.projectId;
  }

  return (
    <Modal
      maskClosable={false}
      title={`Add linked ${getLabel({
        id: "task",
        defaultValue: "task",
      })}`}
      open={visible}
      onOk={onSubmit}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      footer={null}
      className="link-task-modal"

      /*
        if we uncomment this, the modal needs to be instantiated along with the task sidebar
        at load, not after, otherwise it won't render. It seems to work without it
      */
      // forceRender
    >
      <Form {...layout} form={form} initialValues={{}} onFinish={onSubmit}>
        <Form.Item
          label="Relationship"
          name="relationship"
          className="relationship"
          rules={[
            {
              required: true,
              message: "You must specify a relationship",
            },
          ]}
        >
          <Select
            className="relationship-picker"
            placeholder='e.g. "Relates to"'
            onChange={(relationship) => {
              form.setFieldsValue({ relationship });
              forceUpdate();
            }}
            data-cy="relationship-picker"
          >
            {TASK_RELATIONSHIPS.filter((x) => !x.hidden).map(({ value, label }, i) => (
              <Select.Option key={i} value={value} data-cy="relationship-option">
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          hidden={!form.getFieldValue("relationship")}
          label="Choose a task"
          name="linkedTask"
          className="linked-task"
          rules={[
            {
              required: true,
              message: "You must specify a task",
              validator: checkLinkedTask,
            },
          ]}
        >
          <Row gutter={16}>
            <Col span={20}>
              <TaskPicker
                tasks={tasksToSelectFrom}
                value={form.getFieldValue("linkedTask")}
                onSearch={() => {
                  form.setFieldsValue({ linkedTask: "" });
                  forceUpdate();
                }}
                data-cy="linked-task-picker"
                onChange={(linkedTask) => {
                  form.setFieldsValue({ linkedTask });
                  forceUpdate();
                }}
              />
            </Col>
            <Col span={4}>
              <Button
                data-cy="create-linked-task-button"
                type="primary"
                className="create-task"
                onClick={() => setIsCreateTaskModalVisible(true)}
              >
                <PlusCircleOutlined /> Create{" "}
                {getLabel({
                  organisationDetails,
                  id: "task",
                  defaultValue: "task",
                })}
              </Button>
            </Col>
          </Row>
        </Form.Item>

        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="submit-modal-button">
            Submit
          </Button>
        </div>
      </Form>
      <br />
      {isCreateTaskModalVisible && (
        <CreateTaskModal
          onClose={() => setIsCreateTaskModalVisible(false)}
          apiUser={apiUser}
          onSave={(task) => {
            form.setFieldsValue({ linkedTask: task.id });
            forceUpdate();
          }}
          predefinedFields={{ projectId: predefinedProjectId }}
        />
      )}
    </Modal>
  );
}
