// Marker.js
import { useEffect, useRef } from "react";

export function Marker({ position, map }) {
  const markerRef = useRef(null);

  useEffect(() => {
    if (map && !markerRef.current) {
      markerRef.current = new window.google.maps.Marker({
        position,
        map,
      });
    }

    // Update marker position when `position` prop changes
    if (markerRef.current) {
      markerRef.current.setPosition(position);
    }

    // Clean up marker when the component unmounts
    return () => {
      if (markerRef.current) {
        markerRef.current.setMap(null);
        markerRef.current = null;
      }
    };
  }, [map, position]);

  return null;
}
