import React from "react";
import _ from "lodash";
import RichTextEditor from "react-rte";
import { callGraphQLSimple } from "common/apiHelpers";

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "bold" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export default class TaskDescription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      description: RichTextEditor.createValueFromString(this.props.task.subtitle || "", "html"),
      isDescriptionOpen: false,
    };

    this.descriptionRef = React.createRef();
    this.debouncedSendToApi = _.debounce(() => this.sendToApi(), 500);
  }

  componentDidMount() {
    window.addEventListener("click", this.onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onWindowClick);
  }

  componentDidUpdate() {
    this.descriptionRef.current = this.state.description;
  }

  onWindowClick = () => {
    this.setState({
      isDescriptionOpen: false,
    });
    this.sendToApi();
  };

  sendToApi = () => {
    const { task } = this.props;
    let newDescription = this.descriptionRef.current.toString("html");
    if (task.subtitle === newDescription || (task.subtitle === null && newDescription === "<p><br></p>")) {
      return;
    }

    callGraphQLSimple({
      message: "Failed to update description",
      queryCustom: "updateTask",
      variables: {
        input: {
          id: task.id,
          subtitle: newDescription,
        },
      },
    });
  };

  setEditorFocus = () => {
    let editorElement = document.querySelector(".public-DraftEditor-content");
    if (!editorElement || !editorElement.isContentEditable) {
      setTimeout(this.setEditorFocus, 100);
      return;
    }

    editorElement.focus();
  };

  render() {
    const { description, isDescriptionOpen } = this.state;
    const { task } = this.props;
    return (
      <div
        onClick={(e) => {
          this.setState({ isDescriptionOpen: true });
          e.stopPropagation();
          this.setEditorFocus();
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onMouseUp={(e) => {
          e.stopPropagation();
        }}
      >
        <RichTextEditor
          value={description}
          autoFocus={true}
          onChange={(value) => {
            this.setState({ description: value });
            this.debouncedSendToApi();
          }}
          className="task-description"
          readOnly={task.isFinished || task.isArchived || !isDescriptionOpen}
          toolbarConfig={toolbarConfig}
        />
      </div>
    );
  }
}
