import React from "react";
import AEC_Default from "./ClientReports/AEC/ReportDefault";
import DC_Default from "./ClientReports/DC/ReportDefault";
import AEC_DocumentRegister from "./ClientReports/AEC/ReportDocumentRegister";
import DC_DocumentRegister from "./ClientReports/DC/ReportDocumentRegister";
import AWD_DocumentRegister from "./ClientReports/AWD/ReportDocumentRegister";
// import DC_Default from "./ClientReports/DC/ReportDefault";
import AEC_Generic from "./ClientReports/AEC/ReportGeneric";
import DC_Generic from "./ClientReports/DC/ReportGeneric";
import AWD_Report from "./ClientReports/AWD/AWD_Report";
import Apollo_Report from "./ClientReports/AWD/Apollo_Report";
import DocumentOutput from "DocumentOutput/DocumentOutput";
import { getTemplateFromOrganisation } from "common/shared";

import withSubscriptions from "common/withSubscriptions";
import "./Report.scss";

function Report(props) {
  const REPORTS = {
    AEC: {
      default: AEC_Default,
      piling_platform: AEC_Default,
      engineering_statement: AEC_Generic,
      generic_report: AEC_Generic,
      document_register: AEC_DocumentRegister,
    },
    AEC2: {
      default: AEC_Default,
      piling_platform: AEC_Default,
      engineering_statement: AEC_Generic,
      generic_report: AEC_Generic,
      document_register: AEC_DocumentRegister,
    },
    AWD: {
      default: AWD_Report,
      generic_report: AWD_Report,
      document_register: AWD_DocumentRegister,
    },
    AWD2: {
      default: AWD_Report,
      generic_report: AWD_Report,
      document_register: AWD_DocumentRegister,
      apollo_generic_report: Apollo_Report,
      apollo_default: Apollo_Report,
    },
    DC: {
      default: DC_Default,
      piling_platform: DC_Default,
      engineering_statement: DC_Generic,
      generic_report: DC_Generic,
      document_register: DC_DocumentRegister,
    },
  };

  let propsToPassDown = { ...props, sheet: props.file.sheets.items[0] };

  let reportName = "DC";

  const templateDetails = getTemplateFromOrganisation({
    organisationDetails: propsToPassDown.organisationDetails,
    templateId: propsToPassDown.file.templateId,
    fileType: "REPORT",
  });

  let ReportComponent = null;
  if (templateDetails?.key) {
    ReportComponent = DocumentOutput;
  } else {
    if (REPORTS.hasOwnProperty(propsToPassDown.organisationDetails.id)) {
      reportName = propsToPassDown.organisationDetails.id;
    }
    ReportComponent = REPORTS[reportName][templateDetails.nonNamespacedTemplateId];
  }

  return <ReportComponent {...propsToPassDown} templateDetails={templateDetails} />;
}

export default React.memo(
  withSubscriptions({
    Component: Report,
    subscriptions: ["organisationDetails"],
  })
);
