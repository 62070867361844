import { useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Modal, Button, Typography, message, notification } from "antd";
import { readAndCompressImage } from "browser-image-resizer";

import { convertFileToBase64 } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";

import Upload from "Upload/Upload";

import "./UploadSignatureModal.scss";

export default function UploadSignatureModal({ onClose, user }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ signature }) {
    setIsLoading(true);
    let resizedSignature = await readAndCompressImage(signature, {
      quality: 1,
      maxWidth: 400,
      maxHeight: 200,
      mimeType: signature.type,
    });
    let signatureBase64 = await convertFileToBase64(resizedSignature);

    try {
      const { widthAfterCrop, heightAfterCrop } = await new Promise((resolve) => {
        var image = new Image();

        image.onload = function () {
          resolve({
            widthAfterCrop: image.width,
            heightAfterCrop: image.height,
          });
        };

        image.src = signatureBase64;
      });
      await callGraphQLSimple({
        message: "Could not change the signature",
        queryName: "updateUser",
        variables: {
          input: {
            id: user.id,
            signature: signatureBase64,
            signatureWidth: widthAfterCrop,
            signatureHeight: heightAfterCrop,
          },
        },
      });

      message.success({
        content: <Typography.Text>Signature has been set</Typography.Text>,
        className: "update-user-success",
      });

      form.resetFields();
      onClose();
    } catch (e) {
      let errorMessage = e.message;
      if (!e.message && Array.isArray(e)) {
        errorMessage = e[0].title;
      }
      notification.error({
        message: (
          <Typography.Text>
            Could not upload signature:
            <br />
            {errorMessage}
          </Typography.Text>
        ),
        duration: 0,
      });
      console.error("error updating user:", e);
    }
    setIsLoading(false);
  }

  async function deleteSignature() {
    try {
      await callGraphQLSimple({
        message: "Could not remove the signature",
        queryName: "updateUser",
        variables: {
          input: {
            id: user.id,
            signature: null,
          },
        },
      });

      message.success({
        content: <Typography.Text>Signature has been deleted</Typography.Text>,
        className: "update-user-success",
      });

      onClose();
    } catch (err) {
      console.error("error deleting signature:", err);
    }
  }

  function confirmDeleteSignature() {
    Modal.confirm({
      title: "Confirm remove signature",
      icon: <ExclamationCircleOutlined />,
      className: "remove-signature-modal",
      content: <>Are you sure you want to remove your existing signature?</>,
      onOk: deleteSignature,
    });
  }

  async function checkImagePath(_, file) {
    return new Promise((resolve, reject) => {
      let extension = file.name.toLowerCase().split(".").slice(-1)[0];
      if (extension.includes("jpg") || extension.includes("png") || extension.includes("jpeg")) {
        resolve();
      } else {
        reject("");
      }
    });
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title="Upload signature"
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="upload-signature-modal"
    >
      <Form {...layout} form={form} initialValues={{}} onFinish={onSubmit}>
        <Form.Item
          name="signature"
          label="Signature image"
          getValueFromEvent={(e, file) => file}
          rules={[
            { required: true, message: "You must choose an image first" },
            {
              required: true,
              validator: checkImagePath,
              message: "Image must be either .jpg, .jpeg or .png",
            },
          ]}
        >
          <Upload fullWidth placeholder={user.signature} />
        </Form.Item>

        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="submit-button">
            Submit
          </Button>
          <Button onClick={confirmDeleteSignature} className="remove-signature">
            Remove
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}
