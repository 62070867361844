import { Progress, Typography } from "antd";
import cx from "classnames";

import "./BudgetBar.scss";

export default function BudgetBar({
  amountSpent,
  amountBudget,
  replacementLabel,
  size = "normal",
  hideFinancials,
  includeLabel = true,
  includePercentage = false,
  includeBudgetUsedLabel = true,
}) {
  let totalPercentage = 0;
  if (amountBudget !== 0 && amountSpent !== 0) {
    totalPercentage = Math.round((amountSpent / amountBudget) * 100);
  } else if (amountBudget === 0 && amountSpent !== 0) {
    totalPercentage = Infinity;
  }

  if (isNaN(totalPercentage)) {
    totalPercentage = 0;
  }

  let percentageToDisplay = totalPercentage;
  let blue = "#19aae8";
  let red = "#ff4d4f";
  let gray = "#e0eef4";
  let trailColor = gray;
  let strokeColor = blue;
  let status = "normal";
  if (totalPercentage > 100) {
    percentageToDisplay = percentageToDisplay - 100;
    status = "exception";
    trailColor = blue;
    strokeColor = red;
  }

  let progressLabel = `${totalPercentage}`;
  if (isFinite(totalPercentage)) {
    progressLabel += "%";
  }

  if (includeLabel) {
    if (!hideFinancials) {
      progressLabel += ` (${window.formatCurrency("GBP", amountSpent || "0")})`;
    } else {
      progressLabel += ` (${amountSpent || "0"} hour${amountSpent === 1 ? "" : "s"})`;
    }
  }

  return (
    <div className={cx("budget-bar", `budget-bar-${size}`, { "include-percentage": includePercentage })}>
      {replacementLabel ? (
        <Typography.Text>{replacementLabel}</Typography.Text>
      ) : (
        <>
          {includePercentage && includeBudgetUsedLabel && (
            <Typography.Text className="static-label">Budget used: </Typography.Text>
          )}
          <Progress
            percent={percentageToDisplay}
            trailColor={trailColor}
            strokeColor={strokeColor}
            size="small"
            format={() => ""}
            status={status}
          />
          {(includeLabel || includePercentage) && (
            <Typography.Text className="progress-label">{progressLabel}</Typography.Text>
          )}
        </>
      )}
    </div>
  );
}
