import Avatar from "Avatar/Avatar";
import "./MessageForForm.scss";

type Props = {
  activityItem: any;
  users: any;
};

export default function MessageForForm({ activityItem, users }: Props) {
  let senderType: null | string = null;
  switch (activityItem.content.type) {
    case "INFORMATION_SUBMITTED":
      senderType = "requester";
      break;
    case "MORE_INFORMATION_REQUESTED":
      senderType = "engineer";
      break;
    default:
      break;
  }
  return (
    <div className="message-for-form">
      <Avatar user={users.find((x) => x.id === activityItem.author)} />
      <div className="message-content">
        <div className="message-title">Message{senderType ? ` from the ${senderType}` : ""}:</div>
        <div className="message-body">{activityItem.content.message}</div>
      </div>
    </div>
  );
}
