import cookie from "js-cookie";
import { Checkbox, Typography } from "antd";
import { COOKIE_NAME_SELECTED_TEAMS } from "common/constants";

interface Props {
  apiUser: any;
  organisationDetails: any;
}

export default function UserTeamPicker({ apiUser, organisationDetails }: Props) {
  function onTeamChange(selectedTeams) {
    window.selectedTeams = selectedTeams;
    cookie.set(COOKIE_NAME_SELECTED_TEAMS, JSON.stringify(selectedTeams), { expires: 99999 });
    window.reloadData();
  }

  return (
    <div className="teams">
      <Typography.Text className="teams-label">Teams to display (leave blank to see all):</Typography.Text>
      <Checkbox.Group className="user-team-picker" value={window.selectedTeams || []} onChange={onTeamChange}>
        {apiUser.teams.map((teamId) => {
          let teamDetails = organisationDetails.teams?.find((x) => x.id === teamId);
          if (!teamDetails) {
            return null;
          }
          return (
            <Checkbox key={teamDetails.id} value={teamDetails.id}>
              {teamDetails.label}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
    </div>
  );
}
