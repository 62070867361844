export default function updateObject({ objectId, objectIds, fields, outputTemplate }) {
  const newOutputTemplate = getUpdatedNestedObject({
    currentObject: { ...outputTemplate },
    objectId,
    objectIds,
    fields,
  });

  return newOutputTemplate;
}

function getUpdatedNestedObject({ currentObject, objectId, objectIds, fields }) {
  let objectMatches = false;
  if (objectId) {
    objectMatches = currentObject.custom_id === objectId;
  } else if (objectIds) {
    objectMatches = objectIds.includes(currentObject.custom_id);
  }

  if (objectMatches) {
    let newObject = {
      ...currentObject,
      ...fields,
    };
    return newObject;
  }

  if (currentObject.objects) {
    // Recursively update children
    return {
      ...currentObject,
      objects: currentObject.objects.map((child) =>
        getUpdatedNestedObject({
          currentObject: child,
          objectId,
          objectIds,
          fields,
        })
      ),
    };
  }

  // Return the object unchanged if it's not the one we're looking for
  return currentObject;
}
