import moment from "moment";

export function getFields(params) {
  // console.log("general::getFields() params", params);
  const fields = [
    {
      id: "today",
      fieldTypes: ["textfield"],
      isDate: true,
      label: "Today's date",
      value: ({ dateFormat, addDays }) => {
        return moment().add(addDays, "day").format(dateFormat);
      },
    },
    {
      id: "endOfLastMonth",
      fieldTypes: ["textfield"],
      isDate: true,
      label: "End of last month",
      value: ({ dateFormat, addDays }) => {
        return moment().subtract(1, "month").endOf("month").add(addDays, "day").format(dateFormat);
      },
    },
    {
      id: "repeatIndex",
      fieldTypes: ["textfield"],
      isIndex: true,
      label: "Repeat index",

      value: ({ numberPrefix, numberPrefixDigitsToRemove }) => {
        let wholeString = String(parseFloat(numberPrefix || 0) + parseFloat(params.repeatIndex || 0) + 1);
        return wholeString.substring(parseFloat(numberPrefixDigitsToRemove));
      },
    },
    {
      id: "repeatListIndex",
      fieldTypes: ["textfield"],
      isIndex: true,
      label: "Repeat list length",
      value: params.repeatListLength,
    },
    {
      id: "manualInput",
      fieldTypes: ["textfield"],
      label: "Manual input",
      value: ({ custom_manualInput }) => {
        // console.log("manualInput params", params.manualInput);
        // return "";
        console.log("custom_manualInput", custom_manualInput);
        return custom_manualInput;
      },
    },
    {
      id: "defaultDateFormat",
      fieldTypes: ["textfield"],
      label: "Default date format",
      value: () => {
        return "DD-MM-YYYY";
      },
    },
  ];
  return fields;
}

export const label = "General";
