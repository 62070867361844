export default function removeGroupField(currentObject) {
  // Create a new object excluding the 'group' property

  const { width, height, ...newObject } = currentObject;

  // If the current object has children, recursively process each child
  if (Array.isArray(newObject.objects)) {
    newObject.objects = newObject.objects.map((child) => removeGroupField(child));
  }

  if (currentObject.custom_type === "section") {
    return newObject;
  } else {
    return {
      ...newObject,
      width,
      height,
    };
  }
}
