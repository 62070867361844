import moment from "moment";
import { roundToQuarter } from "common/mathHelpers";
import { getSimpleLabel } from "common/labels";

export const tableColumns = [
  { title: `${getSimpleLabel("Client")} name`, fieldFunction: (block) => block.client?.name || "" },
  { title: `${getSimpleLabel("Project")} title`, fieldFunction: (block) => block.project?.title || "" },
  {
    title: `${getSimpleLabel("Task")} title`,
    fieldFunction: (block) => {
      if (block.task?.title) {
        return block.task.title;
      } else {
        return block.taskId || "";
      }
    },
  },
  { title: "Activity description", fieldFunction: (block) => block.description },
  { title: "Billable", fieldFunction: (block) => (block.billable ? "yes" : "no") },
  { title: "Variation", fieldFunction: (block) => (block.variation ? "yes" : "no") },
  { title: "On site", fieldFunction: (block) => (block.onSite ? "yes" : "no") },
  { title: "Tags", fieldFunction: (block) => block.tags?.join(" ") },
  { title: "Mileage", fieldFunction: (block) => block.mileage },
  { title: "User email", fieldFunction: (block) => block.userId },
  { title: "User name", fieldFunction: (block) => `${block.user?.firstName} ${block.user?.lastName}` },
  { title: "Date", fieldFunction: (block) => moment(block.startAt).format("DD-MM-YYYY") },
  { title: "Start time", fieldFunction: (block) => moment(block.startAt).format("HH:mm:ss") },
  { title: "End time", fieldFunction: (block) => moment(block.endAt).format("HH:mm:ss") },
  {
    title: "Duration (hours)",
    fieldFunction: (block) => roundToQuarter(moment(block.endAt).diff(block.startAt, "hours", true)),
  },
];
