import moment from "moment";

export const tableColumns = [
  { title: "Client name", fieldFunction: (_, quote) => quote.client?.name },
  { title: "PO number", fieldFunction: (_, quote) => quote.poNumber },
  {
    title: "Project title",
    fieldFunction: (_, quote) => quote.project.title,
    onFilter: (value, record) => record.name.includes(value),
  },
  { title: "Line item ID", width: 110, fieldFunction: (item) => item.id },
  { title: "Quote ID", width: 110, fieldFunction: (_, quote) => quote.id },
  { title: "Quote title", fieldFunction: (_, quote) => quote.title, width: 150 },
  { title: "Line item title", dataIndex: "title", width: 150 },
  { title: "Status", fieldFunction: (_, quote) => quote.status.split("_").join(" "), width: 170 },
  {
    title: "Design fee",
    width: 110,
    fieldFunction: (item) => {
      return item.unitPrice;
    },
  },
  {
    title: "Quantity",
    width: 110,
    fieldFunction: (item) => {
      return item.quantity;
    },
  },
  {
    title: "Design check fee",
    width: 110,
    fieldFunction: (item) => {
      return item.checkPrice;
    },
  },
  {
    title: "Amount without tax",
    width: 110,
    fieldFunction: (item) => {
      return item.amount;
    },
  },
  {
    title: "Tax amount",
    width: 110,
    fieldFunction: (item) => {
      return item.taxAmount;
    },
  },
  {
    title: "Amount with tax",
    width: 110,
    fieldFunction: (item) => {
      return item.amount + item.taxAmount;
    },
  },
  {
    title: "Created at",
    fieldFunction: (item) => moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss"),
    width: 200,
  },
  {
    title: "Last updated at",
    fieldFunction: (item) => moment(item.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
    width: 200,
  },
  {
    title: "Is under review",
    fieldFunction: (_, quote) => (quote.isUnderReview ? "yes" : "no"),
  },
  {
    title: "Has been approved",
    fieldFunction: (_, quote) => (quote.reviewStatus === "SUCCESS" ? "yes" : "no"),
  },
  {
    title: "Client contact name",
    fieldFunction: (_, quote) => {
      const fullContactDetails = quote.client?.contacts?.find((contact) => contact.id === quote.clientContact);

      const contactFullName = `${fullContactDetails?.firstName || ""} ${fullContactDetails?.lastName || ""}`;

      return contactFullName;
    },
  },
  {
    title: "Client contact email",
    fieldFunction: (_, quote) => {
      const fullContactDetails = quote.client?.contacts?.find((contact) => contact.id === quote.clientContact);

      return fullContactDetails?.email;
    },
  },
];
