import React, { useEffect, useState } from "react";
import { Form, Modal, Button, Input, Checkbox, notification, Typography, message } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { CloseIcon } from "common/icons";
import withSubscriptions from "common/withSubscriptions";
import { processIdForDisplay } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";

import { getSimpleLabel } from "common/labels";

import DatePicker from "DatePicker/DatePicker";

import "./CreateStockItemMaintenanceModal.scss";

export function CreateStockItemMaintenanceModal({
  onClose,
  apiUser,
  organisationDetails,
  windowWidth,
  history,
  predefinedFields = {},
  stockItem,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateAnother, setIsCreateAnother] = useState(false);
  const [form] = Form.useForm();
  const nameInputRef = React.createRef();

  const namePlaceholder = "Maintenance";

  function focusTitle() {
    let nameInput = nameInputRef.current;
    if (nameInput) {
      nameInput.focus();
    } else {
      setTimeout(focusTitle, 100);
    }
  }
  useEffect(() => {
    focusTitle();
  }, []); // eslint-disable-line

  // we need to force the component to re-render when we change some form values

  async function onSubmit(params) {
    setIsLoading(true);

    try {
      await callGraphQLSimple({
        message: `Failed to create ${getSimpleLabel("maintenance period")}`,
        mutation: "updateStockItem",
        variables: {
          input: {
            id: stockItem.id,
            maintenanceIntervals: [
              ...(stockItem.maintenanceIntervals || []),
              {
                id: `${Date.now()}-${Math.floor(Math.random() * 1000)}`,
                content: params.content || namePlaceholder,
                startDate: moment(params.startDate).format("YYYY-MM-DD"),
                endDate: moment(params.endDate).format("YYYY-MM-DD"),
              },
            ],
          },
        },
      });

      if (isCreateAnother) {
        form.setFieldsValue({ name: null, startDate: null, endDate: null });
        setTimeout(focusTitle, 400);
      } else {
        form.resetFields();
        onClose();
      }
    } catch (e) {
      console.error("Could not create maintenance period:");
      console.error(e);
      notification.error({
        message: (
          <>
            <Typography.Text>Failed to create {getSimpleLabel("maintenance period")}</Typography.Text>
            <br />
            <Typography.Text>Reason: {e.message}</Typography.Text>
          </>
        ),
        duration: 0,
      });
    }

    setIsLoading(false);
  }

  const tailLayout = {
    wrapperCol: {
      offset: 5,
      span: 19,
    },
  };

  let initialValues = {
    ...predefinedFields,
  };

  return (
    <>
      <Modal
        maskClosable={false}
        title={`Create ${getSimpleLabel("maintenance period")} `}
        open={true}
        onOk={onSubmit}
        onCancel={() => {
          form.resetFields();
          onClose();
        }}
        footer={null}
        closeIcon={<CloseIcon />}
        className="create-stock-item-maintenance-modal full-screen-on-mobile"
      >
        <div data-cy="create-stock-item-maintenance-modal">
          <Form
            scrollToFirstError={true}
            form={form}
            initialValues={initialValues}
            onFinish={onSubmit}
            layout="vertical"
          >
            <Form.Item label={`Name`} name="content">
              <Input autoComplete="off" className="maintenance-name" ref={nameInputRef} placeholder={namePlaceholder} />
            </Form.Item>

            <Form.Item
              label={`Start date`}
              name="startDate"
              rules={[{ required: true, message: "You must choose a start date" }]}
            >
              <DatePicker className="start-date" ref={nameInputRef} />
            </Form.Item>

            <Form.Item
              label={`End date (inclusive)`}
              name="endDate"
              rules={[{ required: true, message: "You must choose an end date" }]}
            >
              <DatePicker className="end-date" ref={nameInputRef} />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <div className="submit-button-container">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  className="submit-create-stock-item-maintenance-modal"
                  data-cy="create-stock-item-maintenance-modal-submit-button"
                >
                  {isLoading ? "Creating" : "Submit"}
                </Button>
                <div className="create-another-label-and-checkbox">
                  <Checkbox.Group>
                    <Checkbox
                      checked={isCreateAnother}
                      onChange={(e) => setIsCreateAnother(e.target.checked)}
                      name="create-another"
                    >
                      Create another {windowWidth > 800 && `${getSimpleLabel("maintenance period")} after this`}
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: CreateStockItemMaintenanceModal,
    subscriptions: ["organisationDetails"],
  })
);
