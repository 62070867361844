import { Alert, Result, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";

import { PUBLIC_S3_BUCKET_URL } from "common/publicS3BucketUrl";
import { FILE_TYPES_DETAILS } from "common/shared";
import { downloadBlob } from "common/helpers";

import Card from "Card/Card";

type Props = {
  data: any;
};

export default function PublicUrlQRCode({ data }: Props) {
  // console.log("data = ", data);

  let linkIsExpired = false;

  if (
    data?.content?.sharing &&
    data.content.sharing.expiresAt &&
    moment(data.content.sharing?.expiresAt).isBefore(moment())
  ) {
    linkIsExpired = true;
  }

  async function onDownloadClick() {
    const fileBlob = (
      await axios.get(data.content.fileVersionExportSignedUrl, {
        responseType: "blob",
      })
    ).data;

    await downloadBlob({
      blob: fileBlob,
      fileName: `${data.content.projectTitle} - ${data.content.taskTitle} - ${data.content.fileType} ${data.content.fileName}.pdf`,
    });
  }

  let subTitle: any = null;
  let detailsAboutRevisions = data.content.detailsAboutRevisions;

  if (detailsAboutRevisions) {
    if (detailsAboutRevisions.fileHasBeenRemoved) {
      subTitle = (
        <Alert
          type="warning"
          message={
            <>
              This file has been removed from the latest revision of the design package. <br />
              Please contact the person assigned to this job:
              <br /> Name: {data.content.taskAssigneeDetails?.name} <br />
              Email: {data.content.taskAssigneeDetails?.email}
            </>
          }
        />
      );
    } else {
      if (
        (detailsAboutRevisions.currentSheetRevisionName && !detailsAboutRevisions.latestSheetRevisionName) ||
        detailsAboutRevisions.currentSheetRevisionName === detailsAboutRevisions.latestSheetRevisionName
      ) {
        subTitle = (
          <Alert
            type="success"
            message={
              <>
                This is the latest revision of the file. <br />
                Revision: {detailsAboutRevisions.currentSheetRevisionName}
              </>
            }
          />
        );
      } else {
        subTitle = (
          <Alert
            type="error"
            message={
              <>
                Please note: this is not the latest revision of the file. <br />
                <br />
                Revision you have scanned: {detailsAboutRevisions.currentSheetRevisionName} <br />
                Latest revision: {detailsAboutRevisions.latestSheetRevisionName}
                <br />
                <br />
                If you want to get the latest revision, please contact the person assigned to this job:
                <br /> Name: {data.content.taskAssigneeDetails?.name} <br />
                Email: {data.content.taskAssigneeDetails?.email}
              </>
            }
          />
        );
      }
    }
  }

  let icon: any = null;
  if (data.content?.organisationLogoKey) {
    icon = (
      <img
        src={`${PUBLIC_S3_BUCKET_URL}/public/${data.content.organisationLogoKey}`}
        alt="Organisation logo"
        style={{ height: 100 }}
      />
    );
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {linkIsExpired ? (
        <Card>
          <Result status="error" title="This link has expired" />
        </Card>
      ) : (
        <Card>
          <Result
            status={"success"}
            icon={icon}
            title={
              <>
                {data.content.projectTitle} - {data.content.taskTitle}
                <br />
                {FILE_TYPES_DETAILS[data.content.fileType]?.label}: {data.content.fileName}
              </>
            }
            subTitle={subTitle}
            extra={[
              <Button type="primary" icon={<DownloadOutlined />} onClick={onDownloadClick} key="download-file">
                Download file
              </Button>,
            ]}
          />
        </Card>
      )}
    </div>
  );
}
