import React, { useState } from "react";

import { withRouter, Link } from "react-router-dom";
import jszip from "jszip";

import { Table, Button, Modal, Dropdown, Menu, Typography, notification, Tabs, message } from "antd";
import {
  PlusCircleOutlined,
  UploadOutlined,
  DeleteOutlined,
  DownOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  StarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import { downloadZip } from "client-zip";

import withSubscriptions from "common/withSubscriptions";
import {
  createTemplate,
  getImageKeysFromOutputTemplate,
  replaceOrganisationIdInImageKeys,
} from "common/templateHelpers";
import { downloadBlob, getLabel } from "common/helpers";
import getS3File from "common/getS3File";
import { FILE_TYPES_DETAILS, TEMPLATE_PARENT_TYPES } from "common/shared";
import { CopyLinkIcon } from "common/icons";

import Card from "Card/Card";
import TemplateFonts from "./TemplateFonts/TemplateFonts";
import TemplateVariables from "./TemplateVariables/TemplateVariables";
import CreateTemplateModal from "Modals/CreateTemplateModal/CreateTemplateModal";
import StartingFilesModal from "Modals/StartingFilesModal/StartingFilesModal";

import "./TemplatesPage.scss";

export function TemplatesPage({ organisationDetails, apiUser, projects, history }) {
  const [templateGroupsByStatusVisibility, setTemplateGroupsByStatusVisibility] = useState({
    Obsolete: false,
  });
  const [isCreateTemplateModalVisible, setIsCreateTemplateModalVisible] = useState(false);
  const [isStartingFilesModalOpenForTemplateId, setIsStartingFilesModalOpenForTemplateId] = useState(undefined);

  const [selectedFileType, setSelectedFileType] = useState();
  const [isCopying, setIsCopying] = useState(false);

  function onDeleteClick(template) {
    Modal.confirm({
      title: "Confirm delete",
      content: (
        <>
          Are you sure you want to delete template <b>{template.name}</b>?
        </>
      ),
      onOk: async () => {
        window.callGraphQLSimple({
          message: "Failed to delete template",
          mutation: "deleteTemplate",
          variables: {
            input: {
              id: template.id,
            },
          },
        });

        await Storage.remove(template.key);
        try {
          await Storage.remove(`${template.key.split(".")[0]}_annotation.json`);
        } catch (e) {
          // nothing to do, the annotation file probably never got created
        }

        await window.callGraphQLSimple({
          message: "Failed to refresh organisation details",
          mutation: "updateOrganisation",
          variables: {
            input: {
              id: organisationDetails.id,
              itemSubscription: Math.floor(Math.random() * 1000000),
            },
          },
        });
      },
    });
  }

  function onMakeDefaultClick(fileType, targetTemplate) {
    if (targetTemplate.isDeprecated) {
      Modal.error({
        title: "Template is marked as obsolete",
        content: "You can only mark a template as default if it is not marked as obsolete.",
      });
      return;
    }
    Modal.confirm({
      title: (
        <>
          Are you sure you want to make <b>{targetTemplate.name}</b> the default for{" "}
          {FILE_TYPES_DETAILS[fileType].label}?
        </>
      ),

      onOk: async () => {
        organisationDetails.templates.items.forEach((template) => {
          if (template.type !== fileType) {
            return;
          }

          let newIsDefault = template.id === targetTemplate.id;

          if (template.isDefault !== newIsDefault) {
            changeAttribute({
              templateId: template.id,
              fieldName: "isDefault",
              value: newIsDefault,
            });
          }
        });
      },
    });
  }

  async function changeAttribute({ templateId, fieldName, value }) {
    await window.callGraphQLSimple({
      message: "Failed to update template",
      mutation: "updateTemplate",
      variables: {
        input: {
          id: templateId,
          [fieldName]: value,
        },
      },
    });

    await window.callGraphQLSimple({
      message: "Failed to refresh organisation details",
      mutation: "updateOrganisation",
      variables: {
        input: {
          id: organisationDetails.id,
          itemSubscription: Math.floor(Math.random() * 1000000),
        },
      },
    });
  }

  function onDeprecateClick(template) {
    if (template.isDefault) {
      Modal.error({
        title: "Template is default",
        content: "Only non-default templates can be marked as obsolete.",
      });
      return;
    }
    let title;
    if (!template.isDeprecated) {
      title = (
        <>
          Are you sure you want to mark <b>{template.name}</b> as obsolete ?
        </>
      );
    } else {
      title = (
        <>
          Are you sure you want to mark <b>{template.name}</b> as active ?
        </>
      );
    }
    Modal.confirm({
      title,
      onOk: async () => {
        changeAttribute({
          templateId: template.id,
          fieldName: "isDeprecated",
          value: !template.isDeprecated,
        });
      },
    });
  }

  function onMakeLiveClick(template) {
    Modal.confirm({
      title: (
        <>
          Are you sure you want to make <b>{template.name}</b> live? This action cannot be reversed.
        </>
      ),

      onOk: async () => {
        changeAttribute({
          templateId: template.id,
          fieldName: "isLive",
          value: true,
        });
      },
    });
  }

  async function onDownloadClick({ fileTypeDetails, template, fileType }) {
    let messageKey = "download-message";
    message.loading({
      content: "Preparing download...",
      key: messageKey,
      duration: 0,
    });
    try {
      const sourceFile = await fetch(await getS3File(template.key.replace("public/", "")));
      const outputFile = await fetch(await getS3File(`public/${template.key.split(".")[0]}_annotation.json`));

      const outputFileForImages = await fetch(await getS3File(`public/${template.key.split(".")[0]}_annotation.json`));
      const outputFileForImagesBody = JSON.parse(await outputFileForImages.text());

      const imageKeys = getImageKeysFromOutputTemplate({
        parent: outputFileForImagesBody,
      });
      const imagePublicURLs = await Promise.all(
        imageKeys.map((imageKey) => {
          return getS3File(`public/${imageKey}`);
        })
      );
      const imageFiles = await Promise.all(
        imagePublicURLs.map((imagePublicURL) => {
          return fetch(imagePublicURL);
        })
      );

      const fontKeys = organisationDetails.variables?.items
        ?.filter((variable) => variable.type === "FONT")
        .map((variable) => {
          return variable.value;
        });

      const fontPublicURLs = await Promise.all(
        fontKeys.map((key) => {
          return getS3File(`public/${key}`);
        })
      );
      const fontFiles = await Promise.all(
        fontPublicURLs.map((fontPublicURL) => {
          return fetch(fontPublicURL);
        })
      );

      let files = [
        {
          name: `source.${template.key.split(".").slice(-1)[0]}`,
          input: sourceFile,
        },
        {
          name: "annotation.json",
          input: outputFile,
        },
        {
          name: "metadata.json",
          input: JSON.stringify(
            {
              organisationId: organisationDetails.id,
              fileType,
              outputType: template.outputType,
              parentType: template.parentType,
              variables: organisationDetails.variables?.items,
            },
            null,
            2
          ),
        },
        ...imageKeys.map((key, index) => {
          return {
            name: key,
            input: imageFiles[index],
          };
        }),
        ...fontKeys.map((key, index) => {
          return {
            name: key,
            input: fontFiles[index],
          };
        }),
      ];

      const resultArchive = await downloadZip(files).blob();

      await downloadBlob({
        blob: resultArchive,
        fileName: `DraughtHub - ${fileTypeDetails.label} template - ${template.name}.dhub`,
      });
      message.success({ content: "Downloaded", key: messageKey, duration: 2 });
    } catch (e) {
      console.error(e);
      message.error({
        content: "Failed to download template",
        key: messageKey,
        duration: 10,
      });
    }
  }

  async function onDuplicateClick(fileTypeDetails, template) {
    setIsCopying(true);
    try {
      await createTemplate({
        type: template.type,
        outputType: template.outputType,
        projects,
        sourceTemplate: template,
      });
      message.success({
        content: (
          <Typography.Text>
            Template <b>{template.name}</b> duplicated
          </Typography.Text>
        ),
        duration: 2,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not duplicate template:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
    setIsCopying(false);
  }

  function displayTemplatesByStatus({ tableRows, fileType, tableColumns, sectionTitle }) {
    let shouldGroupByParentType = false;
    if (fileType === "REPORT") {
      shouldGroupByParentType = true;
    }

    let contents = null;

    if (shouldGroupByParentType) {
      contents = TEMPLATE_PARENT_TYPES.map(({ label, value }, i) => {
        let tableRowsForParentType = tableRows.filter((tableRow) => {
          if (value === "TASK") {
            return !tableRow.parentType || tableRow.parentType === value;
          } else {
            return tableRow.parentType === value;
          }
        });

        if (!tableRowsForParentType.length) {
          return null;
        }

        return (
          <React.Fragment key={i}>
            <Typography.Text className="subsection-title">{label} report templates</Typography.Text>
            <div className="subsection-table">
              <Table
                columns={tableColumns}
                dataSource={tableRowsForParentType}
                pagination={{ hideOnSinglePage: true, pageSize: 50 }}
                rowKey="tableKey"
                showHeader={false}
              />
            </div>
          </React.Fragment>
        );
      }).filter((x) => x);
    } else {
      contents = (
        <Table
          columns={tableColumns}
          dataSource={tableRows}
          pagination={{ hideOnSinglePage: true, pageSize: 50 }}
          rowKey="tableKey"
          showHeader={false}
        />
      );
    }

    let templateGroupByStatusIsVisible = templateGroupsByStatusVisibility[sectionTitle];

    let hasVisibilityToggle = false;
    if (templateGroupsByStatusVisibility.hasOwnProperty(sectionTitle)) {
      hasVisibilityToggle = true;
    }

    let toggleVisibilityButton = hasVisibilityToggle ? (
      <Button
        type="link"
        style={{ marginLeft: "0.5rem" }}
        onClick={() => {
          setTemplateGroupsByStatusVisibility({
            ...templateGroupsByStatusVisibility,
            [sectionTitle]: !templateGroupByStatusIsVisible,
          });
        }}
      >
        {templateGroupByStatusIsVisible ? "Hide" : "Show"} {sectionTitle.toLowerCase()} templates
      </Button>
    ) : null;

    return (
      <>
        <Typography.Text className="section-title">
          {sectionTitle}
          {toggleVisibilityButton}
        </Typography.Text>
        {(!hasVisibilityToggle || templateGroupByStatusIsVisible) && contents}
      </>
    );
  }

  function displayTemplatesForFileType(fileType) {
    const fileTypeDetails = FILE_TYPES_DETAILS[fileType];
    const templateColumns = [
      {
        title: "Template name",
        dataIndex: "label",
        width: 400,
        align: "left",
      },

      {
        align: "right",
        render: (_, template) => {
          return (
            <div style={{ display: "flex", gap: "0.5rem", justifyContent: "flex-end" }}>
              <Link to={`/templates/annotation-editor/${template.type}/${template.id}`}>
                <Button type="primary" disabled={!template.key} icon={<EyeOutlined />}>
                  Open template
                </Button>
              </Link>
              {fileTypeDetails.isPartOfATask && (
                <Button icon={<EyeOutlined />} onClick={() => setIsStartingFilesModalOpenForTemplateId(template.id)}>
                  Manage starting files
                </Button>
              )}
              <Dropdown
                trigger="click"
                onClick={(e) => e.stopPropagation()}
                overlay={
                  <Menu>
                    {template.key && !template.isLive && (
                      <Menu.Item
                        key="delete"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          onDeleteClick(template);
                        }}
                      >
                        Delete
                      </Menu.Item>
                    )}

                    {(template.isLive || !template.key) &&
                      !template.isDefault &&
                      (fileType !== "REPORT" || template.parentType === "TASK" || !template.parentType) && (
                        <Menu.Item
                          key="make default"
                          onClick={() => onMakeDefaultClick(fileType, template)}
                          icon={<StarOutlined />}
                        >
                          Make default
                        </Menu.Item>
                      )}

                    {(template.isLive || !template.key) && (
                      <Menu.Item
                        key="deprecate"
                        onClick={() => onDeprecateClick(template)}
                        icon={template.isDeprecated ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                      >
                        {template.isDeprecated ? "Make active" : "Make obsolete"}
                      </Menu.Item>
                    )}

                    {!template.isLive && template.key && (
                      <Menu.Item
                        key="deprecate"
                        onClick={() => onMakeLiveClick(template)}
                        icon={<PlayCircleOutlined />}
                      >
                        Make live
                      </Menu.Item>
                    )}
                    {template.key && (
                      <Menu.Item
                        key="duplicate"
                        onClick={() => onDuplicateClick(fileTypeDetails, template)}
                        icon={<CopyLinkIcon />}
                      >
                        Duplicate
                      </Menu.Item>
                    )}
                    {template.key && (
                      <Menu.Item
                        key="download"
                        onClick={() => onDownloadClick({ fileTypeDetails, template, fileType })}
                        icon={<DownloadOutlined />}
                      >
                        Download
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button icon={<DownOutlined />}>Other actions</Button>
              </Dropdown>
            </div>
          );
        },
      },
    ];

    const templatesTableRows = [...organisationDetails.templates.items]
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .filter((template) => {
        return template.type === fileType;
      })
      .map((template, i) => {
        return {
          ...template,
          tableKey: template.key || i,
          label: <>{template.isDefault ? <b>{template.name} (default)</b> : template.name}</>,
        };
      });

    let liveTemplates = templatesTableRows.filter((template) => !template.isDeprecated && template.isLive);
    let draftTemplates = templatesTableRows.filter((template) => !template.isDeprecated && !template.isLive);
    let deprecatedTemplates = templatesTableRows.filter((template) => template.isDeprecated);

    return (
      <>
        {liveTemplates &&
          liveTemplates.length > 0 &&
          displayTemplatesByStatus({
            tableRows: liveTemplates,
            fileType,
            tableColumns: templateColumns,
            sectionTitle: "Live",
          })}
        {draftTemplates &&
          draftTemplates.length > 0 &&
          displayTemplatesByStatus({
            tableRows: draftTemplates,
            fileType,
            tableColumns: templateColumns,
            sectionTitle: "Draft",
          })}
        {deprecatedTemplates &&
          deprecatedTemplates.length > 0 &&
          displayTemplatesByStatus({
            tableRows: deprecatedTemplates,
            fileType,
            tableColumns: templateColumns,
            sectionTitle: "Obsolete",
          })}
      </>
    );
  }

  function displayIsCopyingModal() {
    return (
      <Modal
        maskClosable={false}
        title={
          <>
            <LoadingOutlined style={{ marginRight: "0.5rem" }} />
            Copying your template...
          </>
        }
        closable={false}
        open={true}
        footer={null}
      >
        <Typography.Paragraph>Please do not close this page until the process is complete.</Typography.Paragraph>
      </Modal>
    );
  }

  async function onTemplateFileUpload(e, fileType) {
    const uploadedFile = e.target.files[0];

    const fileTypeDetails = FILE_TYPES_DETAILS[fileType];

    if (!uploadedFile) {
      return;
    }

    if (!uploadedFile.name.endsWith(".dhub")) {
      message.error("Invalid file provided - must be a DraughtHub template file");
      return;
    }

    let messageKey = "upload-message";
    message.loading({
      content: "Creating template...",
      key: messageKey,
      duration: 0,
    });
    try {
      let templateNameFromFile;
      if (uploadedFile.name.includes("-")) {
        try {
          templateNameFromFile = uploadedFile.name.split("-").slice(-1)[0].split(".")[0];
        } catch (e) {
          // nothing, it means the file name has been modified and it doesn't respect the convention
        }
      }

      if (!templateNameFromFile) {
        templateNameFromFile = uploadedFile.name;
      }
      templateNameFromFile = templateNameFromFile.trim();

      const parsedZipFile = await jszip.loadAsync(uploadedFile);

      let annotationFilePendingData;
      let sourceFilePendingData;
      let metadataFilePendingData;
      let sourceFileName;
      let annotationFileName;

      let zipFileBufferPromisesByPath = {};

      parsedZipFile.forEach(function (relativePath, zipEntry) {
        // console.log("relativePath = ", relativePath);
        let fileName = relativePath.split("/").slice(-1)[0];
        if (fileName.startsWith("source")) {
          sourceFilePendingData = zipEntry.async("arraybuffer");
          sourceFileName = fileName;
        } else if (fileName.startsWith("annotation")) {
          annotationFilePendingData = zipEntry.async("arraybuffer");
          annotationFileName = fileName;
        } else if (fileName.startsWith("metadata")) {
          metadataFilePendingData = zipEntry.async("arraybuffer");
        } else {
          zipFileBufferPromisesByPath[relativePath] = zipEntry.async("arraybuffer");
        }
      });

      const [annotationFileBuffer, sourceFileBuffer, metadataFileBuffer] = await Promise.all([
        annotationFilePendingData,
        sourceFilePendingData,
        metadataFilePendingData,
      ]);

      const annotationFileData = JSON.parse(new TextDecoder().decode(annotationFileBuffer));
      let sourceFileString;

      if (fileTypeDetails.isDocumentTemplate) {
        sourceFileString = new TextDecoder().decode(sourceFileBuffer);
      }
      const metadataFileData = JSON.parse(new TextDecoder().decode(metadataFileBuffer));

      for (let path in zipFileBufferPromisesByPath) {
        if (path.startsWith(`${metadataFileData.organisationId}/`)) {
          let s3Key = path.replace(`${metadataFileData.organisationId}/`, `${organisationDetails.id}/`);

          const fileBuffer = await zipFileBufferPromisesByPath[path];
          const metadataVariableDetails = metadataFileData.variables.find((variable) => variable.value === path);
          if (metadataVariableDetails && metadataVariableDetails.type === "FONT") {
            let variableAlreadyExists = organisationDetails.variables?.items?.find(
              (variable) => variable.name === metadataVariableDetails.name
            );
            if (variableAlreadyExists) {
              continue;
            }
          }
          await Storage.put(s3Key, new File([fileBuffer], s3Key.split("/").slice(-1)[0]));
        }
      }

      if (metadataFileData.variables) {
        for (let metadataVariableDetails of metadataFileData.variables) {
          let variableAlreadyExists = organisationDetails.variables?.items?.find(
            (variable) => variable.name === metadataVariableDetails.name
          );
          if (variableAlreadyExists) {
            continue;
          }
          let newId = metadataVariableDetails.id.replace(
            `${metadataFileData.organisationId}_`,
            `${organisationDetails.id}_`
          );
          const { id, createdAt, updatedAt, ...graphQLInput } = metadataVariableDetails;
          if (graphQLInput.type === "FONT") {
            graphQLInput.value = graphQLInput.value.replace(
              `${metadataFileData.organisationId}/`,
              `${organisationDetails.id}/`
            );
          }
          try {
            await window.callGraphQLSimple({
              message: "Failed to create variable template",
              mutation: "createVariable",
              variables: {
                input: {
                  ...graphQLInput,
                  id: newId,
                  organisation: organisationDetails.id,
                },
              },
            });
          } catch (e) {
            console.error(e);
          }
        }
      }

      const annotationFile = new File([JSON.stringify(annotationFileData)], annotationFileName);
      const sourceFile = new File([sourceFileString || sourceFileBuffer], sourceFileName);

      replaceOrganisationIdInImageKeys({
        parent: annotationFileData,
        oldOrganisationId: metadataFileData.organisationId,
        newOrganisationId: organisationDetails.id,
      });

      await createTemplate({
        fileToUpload: sourceFile,
        annotationFile,
        type: fileType,
        outputType: metadataFileData.outputType,
        parentType: metadataFileData.parentType,
        name: templateNameFromFile,
        projects,
      });
      message.success({ content: "Template created", key: messageKey });
    } catch (e) {
      console.error(e);
      message.error({ content: "Failed to create template", key: messageKey });
    }
  }

  let fileTypes = [...(organisationDetails.fileTypesUsed || [])];
  if (organisationDetails.settings?.quote?.usesQuotes) {
    fileTypes.push("QUOTE");
  }

  if (organisationDetails.settings?.invoice?.usesInvoices) {
    fileTypes.push("INVOICE");
  }

  if (organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders) {
    fileTypes.push("PURCHASE_ORDER");
  }

  if (organisationDetails.settings?.request?.usesRequests) {
    fileTypes.push("REQUEST");
  }
  if (organisationDetails.settings?.request?.usesRequests) {
    fileTypes.push("REQUEST_CHANGE");
  }

  return (
    <div className="templates-page">
      <Tabs>
        {fileTypes.map((fileType, i) => {
          let label = FILE_TYPES_DETAILS[fileType] && FILE_TYPES_DETAILS[fileType].label;
          if (!label) {
            label = fileType;
          }
          const dynamicLabel = getLabel({
            id: label,
            defaultValue: label,
          });

          return (
            <Tabs.TabPane tab={dynamicLabel} key={dynamicLabel}>
              <Card
                title={`${dynamicLabel} templates`}
                actions={
                  <div className="actions">
                    <Button
                      type="primary"
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        setIsCreateTemplateModalVisible(true);
                        setSelectedFileType(fileType);
                      }}
                    >
                      Create template
                    </Button>

                    <Button
                      type="dark"
                      icon={<UploadOutlined />}
                      onClick={() => {
                        document.querySelector(`#upload-file-input-${fileType}`).click();
                      }}
                    >
                      <span>Upload template package</span>
                    </Button>

                    <input
                      type="file"
                      id={`upload-file-input-${fileType}`}
                      hidden
                      onChange={(e) => onTemplateFileUpload(e, fileType)}
                    />
                  </div>
                }
              >
                {displayTemplatesForFileType(fileType)}
              </Card>
            </Tabs.TabPane>
          );
        })}
        <Tabs.TabPane tab="Variables & fonts" key="variables-fonts">
          <TemplateFonts organisationDetails={organisationDetails} />
          <TemplateVariables organisationDetails={organisationDetails} />
        </Tabs.TabPane>
      </Tabs>
      {isCreateTemplateModalVisible && (
        <CreateTemplateModal
          onClose={() => setIsCreateTemplateModalVisible(false)}
          organisationDetails={organisationDetails}
          apiUser={apiUser}
          fileType={selectedFileType}
          projects={projects}
        />
      )}
      {isCopying && displayIsCopyingModal()}
      {isStartingFilesModalOpenForTemplateId && (
        <StartingFilesModal
          templateId={isStartingFilesModalOpenForTemplateId}
          onClose={() => setIsStartingFilesModalOpenForTemplateId(undefined)}
        />
      )}
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TemplatesPage,
    subscriptions: ["organisationDetails", "projects"],
  })
);
