import axios from "axios";
import { message } from "antd";
import jszip from "jszip";
import moment from "moment";
import * as XLSX from "xlsx";

import { computeDataFields, replaceDynamicFields, copyRepeatedObjects } from "common/sharedTemplateRenderHelpers";
import getS3File from "common/getS3File";

const MAX_EXCEL_SHEET_NAME_LENGTH = 31;

export async function buildAndDownloadSpreadsheets({ templateDetails, form, ...params }) {
  const outputTemplateDataWithDynamicFields = await loadAndProcessOutputTemplateDate({
    templateDetails,
    form,
    ...params,
  });

  const spreadsheets = buildSpreadsheets({ outputTemplateDataWithDynamicFields, templateDetails });

  if (spreadsheets.length === 0) {
    message.error("No spreadsheets created, nothing to download");
  } else {
    if (templateDetails.outputType === "SPREADSHEET-EXCEL") {
      await downloadExcelFile({ spreadsheets, fileName: templateDetails.name });
    } else {
      if (spreadsheets.length === 1) {
        await downloadSingleSpreadsheet({ spreadsheet: spreadsheets[0] });
      } else {
        await createZipFile({ spreadsheets, zipFileName: templateDetails.name });
      }
    }
  }
}

function buildSpreadsheets({ outputTemplateDataWithDynamicFields, templateDetails }) {
  let spreadsheets = [];
  for (let page of outputTemplateDataWithDynamicFields.objects) {
    const spreadsheet = getSpreadsheetFromPage({ page, templateDetails });
    spreadsheets.push(spreadsheet);
  }
  return spreadsheets;
}

async function downloadSingleSpreadsheet({ spreadsheet }) {
  let csvContent = "data:text/csv;charset=utf-8," + spreadsheet.rows;
  let encodedUri = encodeURI(csvContent);

  // Create a download link and click it
  const link = document.createElement("a");
  link.href = encodedUri;
  link.download = `${spreadsheet.name}.csv`;
  link.click();
}

async function downloadExcelFile({ spreadsheets, fileName }) {
  const workbook = XLSX.utils.book_new();

  // remove duplicates from spreadsheets by name
  const spreadsheetsWithoutDuplicateNames = spreadsheets
    .filter((spreadsheet, index, self) => self.findIndex((s) => s.name === spreadsheet.name) === index)
    .map((spreadsheet) => {
      // remove any characters that are not allowed in excel sheet names
      spreadsheet.name = spreadsheet.name.replace(/[^a-z0-9 ]/gi, "_");
      spreadsheet.name = spreadsheet.name.substring(0, MAX_EXCEL_SHEET_NAME_LENGTH);
      return spreadsheet;
    });

  spreadsheetsWithoutDuplicateNames.forEach((spreadsheet) => {
    const rows = spreadsheet.rows.split("\n").map((row) => row.split(","));

    // Initialize an empty object for the worksheet
    const worksheet = {};

    // Function to check if a value is numeric
    const isNumeric = (value) => {
      return !isNaN(parseFloat(value)) && isFinite(value);
    };

    rows.forEach((row, rowIndex) => {
      row.forEach((cellValue, colIndex) => {
        // Excel cell reference (e.g., A1, B2)
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });

        // Check if the cell value starts with "=" for formula
        if (cellValue.startsWith("=")) {
          worksheet[cellRef] = { t: "f", f: cellValue.substring(1) };
        } else if (isNumeric(cellValue)) {
          // If the value is numeric, mark it as a number and convert the value
          worksheet[cellRef] = { t: "n", v: +cellValue }; // Unary plus to convert string to number
        } else {
          // Treat as a string by default
          worksheet[cellRef] = { t: "s", v: cellValue };

          if (cellValue.startsWith("http")) {
            if (cellValue.includes(" ")) {
              worksheet[cellRef].l = { Target: cellValue.split(" ")[0] };
              worksheet[cellRef].v = `> ${cellValue.split(" ").slice(1).join(" ")}`;
            } else {
              worksheet[cellRef].l = { Target: cellValue };
              worksheet[cellRef].v = `> ${cellValue}`;
            }
          }
        }
      });
    });

    // Set the range for the worksheet
    const longestRow = rows.reduce((a, b) => (a.length > b.length ? a : b));

    worksheet["!ref"] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 }, // Start cell
      e: { c: longestRow.length - 1, r: rows.length - 1 }, // End cell
    });

    const colWidths = rows.reduce((widths, row) => {
      row.forEach((cell, i) => {
        // Estimate the width of the cell
        const cellWidth = cell.toString().length;
        widths[i] = Math.max(widths[i] || 0, cellWidth);
      });
      return widths;
    }, []);

    worksheet["!cols"] = colWidths.map((w) => ({ wch: w }));

    // Convert the worksheet object to a sheet
    XLSX.utils.book_append_sheet(workbook, worksheet, spreadsheet.name);
  });

  // Write the workbook to a file and trigger download
  XLSX.writeFile(workbook, `${fileName} ${moment().format("DD-MM-YYYY")}.xlsx`);
}

async function createZipFile({ spreadsheets, zipFileName }) {
  const zip = new jszip();

  spreadsheets.forEach((spreadsheet, index) => {
    let csvBlob = new Blob([spreadsheet.rows], { type: "text/csv;charset=utf-8;" });
    zip.file(`${spreadsheet.name.split("/").join("-")}.csv`, csvBlob);
  });

  // Generate the zip file and create a Blob URL for download
  const content = await zip.generateAsync({ type: "blob" });
  const url = URL.createObjectURL(content);

  // Create a download link and click it
  const link = document.createElement("a");
  link.href = url;
  link.download = `${zipFileName} ${moment().format("DD-MM-YYYY")}.zip`;
  link.click();
}

function getSpreadsheetFromPage({ page, templateDetails }) {
  let rows = "";
  let csvName = "";

  // Recursive function to iterate over the objects array
  const parseObjects = (objects) => {
    for (let i = 0; i < objects.length; i++) {
      let parent = objects[i];
      // If an object has custom_isSpreadsheetRow set to true, then process its child objects for type 'text'.
      if (parent.custom_isSpreadsheetRow === true && !parent.isHidden) {
        let row = "";
        if (parent.objects) {
          parent.objects.forEach((child) => {
            // Check for type 'text'
            if (child.type === "text" && !child.isHidden) {
              let cellValue = String(child.text || "")
                .replace(/[\[\]]/g, "")
                .split(",")
                .join(" ");
              if (templateDetails.outputType === "SPREADSHEET-EXCEL") {
                row += `${cellValue},`;
              } else {
                cellValue = cellValue.split('"').join('"""');
                row += `"${cellValue}",`;
              }
            }
          });
        }
        rows += row.slice(0, -1) + "\n"; // Remove last comma and add new line
      } else {
        if (parent.objects) {
          let csvNameFromThisObject = "";
          parent.objects.forEach((child) => {
            // Check for type 'text'
            if (child.type === "text" && child.custom_isSpreadsheetName) {
              csvNameFromThisObject = child.text || "";
            }
          });

          if (csvNameFromThisObject) {
            csvName = csvNameFromThisObject;
          }
        }
      }
      // Recursive call for nested objects
      if (objects[i].objects) {
        parseObjects(objects[i].objects);
      }
    }
  };

  parseObjects(page.objects);

  return {
    name: csvName,
    rows,
  };
}

async function loadAndProcessOutputTemplateDate({ templateDetails, form, ...params }) {
  const formFilePublicUrl = await getS3File(`${templateDetails.key.split(".")[0]}_annotation.json`);
  let outputTemplateData = (await axios.get(formFilePublicUrl)).data;

  const formCopy = JSON.parse(JSON.stringify(form));

  let keyData = {
    ...params,
    form: formCopy,
  };

  /*
    Every time we have a data source override (so far this is only an option for the "file" data source), we need to fetch the override file from the server.
    This field is used to cache the override files, so that we don't have to fetch them again if we need them.

    Since the files may change between refreshes, we need to clear this cache every time we refresh the output template.
  */
  window.documentTemplateOverrides = {};

  await computeDataFields({
    parent: outputTemplateData,
    params: keyData,
  });

  await copyRepeatedObjects({
    parent: outputTemplateData,
    params: keyData,
  });

  await replaceDynamicFields({
    parent: outputTemplateData,
    params: keyData,
  });

  return outputTemplateData;
}
