import React, { useEffect, useState } from "react";
import { Form, Modal, Button, Input, Select, Checkbox, notification, Typography, message } from "antd";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import { LexoRank } from "lexorank";

import { CloseIcon } from "common/icons";
import { STOCK_ITEM_TYPES } from "common/constants";
import withSubscriptions from "common/withSubscriptions";
import { createStockItemInApi } from "common/stockHelpers";
import { useForceUpdate, getCleanValue, processIdForDisplay } from "common/helpers";
import { getSimpleLabel } from "common/labels";

import Textarea from "DocumentForm/Textarea/Textarea";

import "./CreateStockItemModal.scss";

const CUSTOM_FIELD_MARKER = "custom__";

export function CreateStockItemModal({
  onClose,
  apiUser,
  organisationDetails,
  onSave,
  windowWidth,
  history,
  stockItems,
  predefinedFields = {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateAnother, setIsCreateAnother] = useState(false);
  const [form] = Form.useForm();
  const nameInputRef = React.createRef();

  function focusTitle() {
    let nameInput = nameInputRef.current;
    if (nameInput) {
      nameInput.focus();
    } else {
      setTimeout(focusTitle, 100);
    }
  }
  useEffect(() => {
    focusTitle();
  }, []); // eslint-disable-line

  // we need to force the component to re-render when we change some form values
  const forceUpdate = useForceUpdate();

  async function onSubmit(params) {
    setIsLoading(true);

    // let customFields = [];

    // for (let fieldName in params) {
    //   if (fieldName.startsWith(CUSTOM_FIELD_MARKER)) {
    //     let fieldId = fieldName.substring(CUSTOM_FIELD_MARKER.length);
    //     let fieldDefinition = (organisationDetails.customFields || []).find((x) => x.id === fieldId);
    //     let value = params[fieldName];

    //     if (fieldDefinition.type === "CHECKBOX") {
    //       if (value) {
    //         value = "checked";
    //       } else {
    //         value = undefined;
    //       }
    //     } else if (fieldDefinition.type === "CHECKBOX_LIST" && value) {
    //       value = JSON.stringify(value);
    //     }
    //     customFields.push({
    //       id: fieldId,
    //       value,
    //     });
    //   }
    // }

    let newStockItemOrder = LexoRank.middle();
    const sortedStockItems = stockItems.sort((a, b) => (a.order < b.order ? -1 : 1));
    try {
      if (sortedStockItems && sortedStockItems.length > 0) {
        const lastStockItemInOrganisation = sortedStockItems[sortedStockItems.length - 1];
        newStockItemOrder = LexoRank.parse(lastStockItemInOrganisation.order).genNext();
      }
    } catch (e) {
      console.error("Failed to generate new stock item order", e);
    }

    try {
      const stockItem = await createStockItemInApi({
        ...params,
        apiUser,
        organisation: organisationDetails.id,
        order: newStockItemOrder.toString(),
        // customFields,
      });

      if (onSave) {
        onSave(stockItem);
      }

      message.success({
        content: (
          <Typography.Text onClick={() => history.push(`/stock-items/${stockItem.id}`)} style={{ cursor: "pointer" }}>
            {getSimpleLabel("Stock item")} {processIdForDisplay(stockItem.name)} created. <b>Click here to go to it</b>
          </Typography.Text>
        ),
      });

      if (isCreateAnother) {
        form.setFieldsValue({ name: null, description: null });
        setTimeout(focusTitle, 400);
      } else {
        form.resetFields();
        onClose();
      }
    } catch (e) {
      console.error("Could not create stock item:");
      console.error(e);
      notification.error({
        message: (
          <>
            <Typography.Text>Could not create {getSimpleLabel("stock item")}</Typography.Text>
            <br />
            <Typography.Text>Reason: {e.message}</Typography.Text>
          </>
        ),
        duration: 0,
      });
    }

    setIsLoading(false);
  }

  const tailLayout = {
    wrapperCol: {
      offset: 5,
      span: 19,
    },
  };

  // function displayCustomFields() {
  //   return (organisationDetails.customFields || [])
  //     .filter((field) => !field.isStatic)
  //     .map((field) => {
  //       let formInputProps = {};
  //       let labelElement = field.label;
  //       let inputElement = null;

  //       switch (field.type) {
  //         case "TEXT":
  //           inputElement = <Input autoComplete="off" className={field.id} />;
  //           break;
  //         case "TEXTAREA":
  //           inputElement = (
  //             <Input.TextArea autoComplete="off" className={field.id} autoSize={{ minRows: 2, maxRows: 6 }} />
  //           );
  //           break;

  //         case "CHECKBOX":
  //           formInputProps.valuePropName = "checked";
  //           inputElement = <Checkbox className={field.id} />;
  //           break;

  //         case "NUMBER":
  //           inputElement = <InputNumber className={field.id} />;
  //           break;

  //         case "RADIO_LIST":
  //           inputElement = <Radio.Group className={field.id} options={field.options} />;
  //           break;

  //         case "CHECKBOX_LIST":
  //           inputElement = <Checkbox.Group className={field.id} options={field.options} />;
  //           break;

  //         case "DATE":
  //           inputElement = <DatePicker format="DD-MM-YYYY" className="field.id" />;
  //           break;

  //         default:
  //           break;
  //       }

  //       if (!inputElement) {
  //         return null;
  //       }
  //       return (
  //         <Form.Item
  //           key={field.id}
  //           label={labelElement}
  //           name={`${CUSTOM_FIELD_MARKER}${field.id}`}
  //           rules={[
  //             {
  //               required: field.mandatory,
  //               message: "This field is mandatory",
  //             },
  //           ]}
  //           {...formInputProps}
  //         >
  //           {inputElement}
  //         </Form.Item>
  //       );
  //     });
  // }

  let initialValues = {
    ...predefinedFields,
  };

  (organisationDetails.customFields || []).forEach((fieldDefinition) => {
    if (fieldDefinition.defaultValue) {
      initialValues[`${CUSTOM_FIELD_MARKER}${fieldDefinition.id}`] = fieldDefinition.defaultValue;
    } else if (fieldDefinition.type === "RADIO_LIST") {
      initialValues[`${CUSTOM_FIELD_MARKER}${fieldDefinition.id}`] = fieldDefinition.options[0].value;
    }
  });

  // if (!organisationDetails?.taskStatuses || organisationDetails?.taskStatuses.length === 0) {
  //   Modal.info({
  //     footer: null,
  //     closable: false,
  //     icon: null,
  //     title: `No ${getSimpleLabel("task")} statuses defined`,
  //     content: (
  //       <>
  //         <Typography.Text>
  //           Before you can create a {getSimpleLabel("task")}, you need to define at least one {getSimpleLabel("task")}{" "}
  //           status.
  //         </Typography.Text>
  //       </>
  //     ),
  //     okText: "Go to organisation settings",
  //     onOk: () => {
  //       history.push(`organisations/${organisationDetails.id}/settings?secondaryTab=Tasks`);
  //     },
  //   });

  //   return null;
  // }

  return (
    <>
      <Modal
        maskClosable={false}
        title={`Create ${getSimpleLabel("stock item")} `}
        open={true}
        onOk={onSubmit}
        onCancel={() => {
          form.resetFields();
          onClose();
        }}
        footer={null}
        closeIcon={<CloseIcon />}
        className="create-stock-item-modal full-screen-on-mobile"
      >
        <div data-cy="create-stock-item-modal">
          <Form
            scrollToFirstError={true}
            form={form}
            initialValues={initialValues}
            onFinish={onSubmit}
            layout="vertical"
          >
            <Form.Item
              label={`Name`}
              name="name"
              rules={[
                {
                  required: true,
                  message: `You must specify a name`,
                },
                {
                  validator: async (_, name) => {
                    if (!name) {
                      return;
                    }

                    const cleanName = getCleanValue(name);

                    const nameIsAlreadyUsed = stockItems
                      .filter((x) => !x.isArchived)
                      .find((stockItem) => getCleanValue(stockItem.name) === cleanName);

                    if (nameIsAlreadyUsed) {
                      throw new Error(`This name is already used (we're ignoring letter casing and spaces).`);
                    }
                  },
                },
              ]}
            >
              <Input
                autoComplete="off"
                className="stock-item-name"
                ref={nameInputRef}
                onChange={(e) => {
                  form.setFieldsValue({ name: e.target.value });
                  forceUpdate();
                  form.validateFields(["name"]);
                }}
              />
            </Form.Item>

            <Form.Item label={getSimpleLabel("Description")} name="description" rules={[]}>
              <Textarea />
            </Form.Item>

            {/* <Form.Item
              label="Assigned to"
              name="assignedTo"
              dependencies={["catLevel"]}
              rules={[
                {
                  validator: checkAssignedTo,
                  message: "The specified user does not have the required authority",
                },

                {
                  required: organisationDetails.settings?.task?.isTaskAssigneeMandatory,
                  message: `You must assign the ${getSimpleLabel("task")} to someone`,
                },
              ]}
            >
              <UsersFilter
                className="assigned-to"
                showAssignToMe={true}
                includeUnassigned={!organisationDetails.settings?.task?.isTaskAssigneeMandatory}
              />
            </Form.Item> */}

            <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: `You must specify a type`,
                },
              ]}
            >
              <Select className="type-picker">
                {STOCK_ITEM_TYPES.map((typeDefinition) => (
                  <Select.Option
                    value={typeDefinition.value}
                    key={`option-type-${typeDefinition.value}`}
                    className={cx("types-option", `option-type-${typeDefinition.value}`)}
                  >
                    {typeDefinition.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={`Display on ${getSimpleLabel("timeline")}`}
              name="displayOnTimeline"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>

            {/* {displayCustomFields()} */}

            <Form.Item {...tailLayout}>
              <div className="submit-button-container">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  className="submit-create-stock-item-modal"
                  data-cy="create-stock-item-modal-submit-button"
                >
                  {isLoading ? "Creating" : "Submit"}
                </Button>
                <div className="create-another-label-and-checkbox">
                  <Checkbox.Group>
                    <Checkbox
                      checked={isCreateAnother}
                      onChange={(e) => setIsCreateAnother(e.target.checked)}
                      name="create-another"
                    >
                      Create another{" "}
                      {windowWidth > 800 && `${getSimpleLabel("stock item")} after this with the same choices`}
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: CreateStockItemModal,
    subscriptions: ["organisationDetails", "users", "stockItems"],
  })
);
