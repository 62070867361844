export default function isTimesheetBlockASiteVisit({ timesheetBlock, timesheetTags }) {
  if (timesheetBlock.onSite) {
    return true;
  }

  if (
    timesheetBlock.tags?.some((tagId) => {
      let tag = timesheetTags?.find((tag) => tag.id === tagId);
      return tag?.label.toLowerCase().includes("site");
    })
  ) {
    return true;
  }

  let lowercaseDescription = timesheetBlock.description?.toLowerCase();

  if (timesheetBlock.taskId?.toLowerCase().includes("site")) {
    return true;
  }

  const phrasesToCheck = ["site visit", "on site"];

  for (const phrase of phrasesToCheck) {
    if ((lowercaseDescription || "").includes(phrase)) {
      return true;
    }
  }

  return false;
}
