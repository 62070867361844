import { Checkbox, InputNumber, Typography, Radio } from "antd";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";
import {
  TIMELINE_DEFAULT_SNAP_COEFFICIENT_DAYS,
  TIMELINE_DEFAULT_TASK_LENGTH_HOURS,
  TIMELINE_DEFAULT_HOURS_IN_A_DAY,
} from "common/constants";
import { TIMELINE_SETTINGS } from "common/settings";
import { getGraphQLInput } from "OrganisationPage/OrganisationSettingsPage/settingsHelpers";
import { getSimpleLabel } from "common/labels";

import Card from "Card/Card";
import InfoItem from "InfoItem/InfoItem";

import "./TimelineSettingsPage.scss";

function TimelineSettingsPage({ organisationDetails }) {
  const checked = TIMELINE_SETTINGS.filter((setting) => {
    return _.get(organisationDetails, setting.SCHEMA_FIELD);
  });

  const checkedSettings = checked.map((x) => x.SCHEMA_FIELD);
  return (
    <div className="timeline-settings-page">
      <Card withSpace title="Timeline settings">
        <Checkbox.Group defaultValue={checkedSettings}>
          {TIMELINE_SETTINGS.map((setting) => {
            const { NAME, DESCRIPTION, SCHEMA_FIELD } = setting;

            return (
              <Checkbox
                className="setting-checkbox"
                value={SCHEMA_FIELD}
                key={SCHEMA_FIELD}
                onChange={async (e) => {
                  const input = getGraphQLInput({
                    organisationDetails: {
                      settings: organisationDetails.settings || {},
                    },
                    checked: e.target.checked,
                    module: setting,
                  });
                  await callGraphQLSimple({
                    message: "Could not update setting",
                    queryName: "updateOrganisation",
                    variables: {
                      input: {
                        id: organisationDetails.id,
                        ...input,
                      },
                    },
                  });
                }}
              >
                <span className="setting-name">{NAME}</span>
                <br />
                <span className="setting-description">{DESCRIPTION}</span>
              </Checkbox>
            );
          })}
        </Checkbox.Group>
        <br />
        <div className="timeline-task-assignment-settings">
          <Typography.Text className="setting-name">
            Assigning a {getSimpleLabel("task")} to someone on the timeline assigns the {getSimpleLabel("task")} to the
            user:
          </Typography.Text>
          <div>
            <Radio.Group
              value={organisationDetails.settings?.timeline?.shouldAssignTimelineTaskToUser || "ALWAYS"}
              onChange={async (event) => {
                await callGraphQLSimple({
                  message: "Could not update the timeline settings",
                  queryName: "updateOrganisation",
                  variables: {
                    input: {
                      id: organisationDetails.id,
                      settings: {
                        ...(organisationDetails.settings || {}),
                        timeline: {
                          ...organisationDetails.settings?.timeline,
                          shouldAssignTimelineTaskToUser: event.target.value,
                        },
                      },
                    },
                  },
                });
              }}
            >
              <Radio value="NEVER" key="NEVER">
                Never
              </Radio>
              <Radio value="ALWAYS" key="ALWAYS">
                Always
              </Radio>
              <Radio value="TASK-UNASSIGNED" key="TASK-UNASSIGNED">
                Only if the {getSimpleLabel("task")} is currently unassigned
              </Radio>
            </Radio.Group>
          </div>
        </div>
        <br />
        <InfoItem
          label="Round blocks to (number of hours)"
          inline
          value={
            <InputNumber
              min={0.05}
              step={0.5}
              defaultValue={
                organisationDetails.settings?.timeline?.defaultRoundToHours ||
                TIMELINE_DEFAULT_SNAP_COEFFICIENT_DAYS * TIMELINE_DEFAULT_HOURS_IN_A_DAY
              }
              onChange={async (value) => {
                const input = getGraphQLInput({
                  organisationDetails: {
                    settings: organisationDetails.settings || {},
                  },
                  checked: value,
                  module: {
                    SCHEMA_FIELD: "settings.timeline.defaultRoundToHours",
                  },
                });
                await callGraphQLSimple({
                  message: "Could not update setting",
                  queryName: "updateOrganisation",
                  variables: {
                    input: {
                      id: organisationDetails.id,
                      ...input,
                    },
                  },
                });
              }}
            />
          }
        />
        <InfoItem
          label={`Default ${getSimpleLabel("task")} length (number of hours)`}
          inline
          value={
            <InputNumber
              min={0.05}
              step={0.5}
              defaultValue={
                organisationDetails.settings?.timeline?.defaultTaskLengthHours || TIMELINE_DEFAULT_TASK_LENGTH_HOURS
              }
              onChange={async (value) => {
                const input = getGraphQLInput({
                  organisationDetails: {
                    settings: organisationDetails.settings || {},
                  },
                  checked: value,
                  module: {
                    SCHEMA_FIELD: "settings.timeline.defaultTaskLengthHours",
                  },
                });
                await callGraphQLSimple({
                  message: "Could not update setting",
                  queryName: "updateOrganisation",
                  variables: {
                    input: {
                      id: organisationDetails.id,
                      ...input,
                    },
                  },
                });
              }}
            />
          }
        />
      </Card>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TimelineSettingsPage,
    subscriptions: ["organisationDetails"],
  })
);
