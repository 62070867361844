import findAllMatchingObjects from "./findAllMatchingObjects";

export default function replaceLiveCopyObjects(currentObject, outputTemplate) {
  // Check the current object against the condition

  if (!currentObject) {
    return;
  }

  if (currentObject.isLiveCopyOf) {
    const referencesForLiveCopy = findAllMatchingObjects(
      outputTemplate,
      (object) => object && object.custom_id === currentObject.isLiveCopyOf
    );

    if (referencesForLiveCopy.length) {
      let referenceObject = referencesForLiveCopy[0];
      for (let propertyName in referenceObject) {
        if (
          propertyName !== "custom_id" &&
          propertyName !== "custom_name" &&
          propertyName !== "custom_cssPosition" &&
          propertyName !== "group" &&
          !propertyName.includes("absolute")
        ) {
          currentObject[propertyName] = referenceObject[propertyName];
        }
      }
    }
  }

  // If the current object has children, recursively process each child
  if (Array.isArray(currentObject.objects)) {
    currentObject.objects.forEach((child) => replaceLiveCopyObjects(child, outputTemplate));
  }
}
