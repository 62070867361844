import React from "react";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";
import { message, Typography, Modal, Tooltip, Button, Empty, Select } from "antd";
import { DeleteOutlined, PlusCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";

import CreateSupplierModal from "Modals/CreateSupplierModal/CreateSupplierModal";
// import BatchCreateSupplierModal from "Modals/BatchCreateSupplierModal/BatchCreateSupplierModal";
import ClientLogo from "ClientLogo/ClientLogo";
import LazyLoadList from "LazyLoadList/LazyLoadList";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";

import "./SuppliersPage.scss";

export class SuppliersPage extends React.Component {
  _isMounted = false;

  state = {
    isCreateSupplierModalVisible: false,
    sortBy: "name",
  };

  componentDidMount() {
    this.props.setBackground(false);

    callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "SUPPLIERS",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
  }

  componentWillUnmount() {
    this.props.setBackground(true);
  }

  deleteSupplier = async (supplier) => {
    try {
      await callGraphQLSimple({
        message: "Could not delete supplier",
        queryName: "deleteSupplier",
        variables: {
          input: {
            id: supplier.id,
          },
        },
      });
    } catch (e) {
      message.error(
        <Typography.Text>
          Supplier <b>{supplier.name}</b> could not be deleted: <b>{e.message}</b>
        </Typography.Text>
      );
    }
  };

  confirmSupplierDelete = async (supplier) => {
    const { history, projects } = this.props;

    const projectsInSupplier = projects.filter((x) => x.supplierId === supplier.id);
    if (projectsInSupplier.length !== 0) {
      let modal = Modal.error({
        title: "Cannot delete supplier",
        className: "cannot-delete-supplier-modal",
        content: (
          <>
            <b>{supplier.name}</b> has the following projects associated to it, and thus cannot be deleted:
            <ul className="projects-which-use-supplier">
              {projectsInSupplier.map((project) => (
                <li
                  key={project.id}
                  onClick={() => {
                    modal.destroy();
                    history.push(`/projects/${project.id}`);
                  }}
                >
                  {project.title}
                </li>
              ))}
            </ul>
          </>
        ),
      });
      return;
    }

    Modal.confirm({
      title: "Confirm supplier delete",
      icon: <ExclamationCircleOutlined />,
      className: "delete-supplier-modal",
      content: (
        <>
          Are you sure you want to delete <b>{supplier.name}</b>?
        </>
      ),
      onOk: () => this.deleteSupplier(supplier),
    });
  };

  displayActionsForSupplier = (supplier) => {
    let actions = [];

    actions.push(
      <Tooltip title="Delete supplier" key={2} data-cy="delete-supplier-button">
        <Button
          className="delete-supplier"
          icon={<DeleteOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            this.confirmSupplierDelete(supplier);
          }}
        />
      </Tooltip>
    );

    return actions;
  };

  getSortedSuppliers = (suppliers, sortBy) => {
    console.log(suppliers);
    if (sortBy === "name") {
      const sortedSuppliers = suppliers.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
      return sortedSuppliers;
    } else if (sortBy === "creation-date") {
      const sortedSuppliers = suppliers.sort((a, b) => {
        if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
          return 1;
        } else if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
          return -1;
        } else {
          return 0;
        }
      });
      return sortedSuppliers;
    } else {
      return suppliers;
    }
  };

  displaySupplierList = () => {
    const { suppliers } = this.props;
    const { sortBy } = this.state;
    if (!suppliers || suppliers.length === 0) {
      return <Empty description="No suppliers found" />;
    }

    const sortedSuppliers = this.getSortedSuppliers(suppliers, sortBy);

    return (
      <div className="suppliers">
        <LazyLoadList
          initialChunkSize={30}
          subsequentChunkSize={30}
          overshootSize={15}
          list={sortedSuppliers}
          refreshOnChange={sortBy}
          item={(supplier) => {
            return (
              <Link to={`/suppliers/${supplier.id}`}>
                <div key={supplier.id} className="supplier-item" data-title={supplier.name}>
                  <div className="name-container">
                    <div className="details">
                      <Typography.Text className="supplier-name">{supplier.name}</Typography.Text>
                    </div>
                  </div>

                  <div className="supplier-logo-container">
                    <ClientLogo client={supplier} />
                  </div>
                </div>
              </Link>
            );
          }}
        />
      </div>
    );
  };

  getAllSupplierContacts = (suppliers) => {
    let allContacts = [];

    for (let i = 0; i < suppliers.length; i++) {
      const supplier = suppliers[i];

      if (supplier.contacts && supplier.contacts?.length > 0) {
        for (let j = 0; j < supplier.contacts.length; j++) {
          const contact = supplier.contacts[j];

          allContacts.push({ ...contact, supplierName: supplier.name });
        }
      }
    }

    return allContacts;
  };

  export = async () => {};

  render() {
    const { apiUser, suppliers } = this.props;
    const { isCreateSupplierModalVisible, sortBy } = this.state;

    return (
      <div className="suppliers-page">
        <div className="main-actions">
          <Typography.Title className="suppliers-page-title">{suppliers.length} Suppliers</Typography.Title>

          <div className="sort-filters">
            <Typography.Text className="sort-by-label">Sort by: </Typography.Text>
            <Select
              placeholder="Choose a sorting type"
              className="supplier-sort"
              onChange={(type) => {
                this.setState({ sortBy: type });
              }}
              value={sortBy}
            >
              <Select.Option key={"name"} value={"name"}>
                Name
              </Select.Option>

              <Select.Option key={"creation-date"} value={"creation-date"}>
                Creation date
              </Select.Option>
            </Select>
          </div>
          {/* {window.apiUser.isAdmin ? (
            <Button
              type="primary"
              className="export-supplier-contacts-list"
              onClick={this.export}
              data-cy="export-supplier-contacts-list"
            >
              Export contacts to CSV
            </Button>
          ) : null} */}
          <ButtonWithPermissions
            permissions={["CREATE_SUPPLIER"]}
            type="primary"
            className="create-supplier"
            onClick={() => this.setState({ isCreateSupplierModalVisible: true })}
            data-cy="create-supplier-modal"
          >
            <PlusCircleOutlined /> Create supplier
          </ButtonWithPermissions>
        </div>
        <div className="main-content">
          {this.displaySupplierList()}
          {isCreateSupplierModalVisible && (
            <CreateSupplierModal
              apiUser={apiUser}
              onClose={() => this.setState({ isCreateSupplierModalVisible: false })}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: SuppliersPage,
    subscriptions: ["suppliers", "organisationDetails", "projects"],
  })
);
