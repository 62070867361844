export const THIRD_PARTY_APPS = [
  {
    id: "XERO",
    NAME: "Xero",
    DESCRIPTION: "Add Xero integration",
  },
  {
    id: "QUICKBOOKS",
    NAME: "Quickbooks",
    DESCRIPTION: "Add Quickbooks integration",
  },
];
