import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import cx from "classnames";
import { Typography, Button } from "antd";
import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";

import CreatePurchaseOrderModal from "Modals/CreatePurchaseOrderModal/CreatePurchaseOrderModal";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";
import Card from "Card/Card";
import Input from "Input/Input";
import ClientLogo from "ClientLogo/ClientLogo";
import EditSupplierLogoModal from "Modals/EditSupplierLogoModal/EditSupplierLogoModal";

import "./SupplierSidebar.scss";

export class ProjectSidebar extends React.Component {
  state = {
    isEditSupplierLogoModalVisible: false,
    isCreatePurchaseOrderModalVisible: false,
  };

  updateSupplierField = async ({ fieldName, value }) => {
    const { supplier } = this.props;
    await callGraphQLSimple({
      message: `Failed to update ${fieldName}`,
      queryName: "updateSupplier",
      variables: {
        input: {
          id: supplier.id,
          [fieldName]: value,
        },
      },
    });
  };

  render() {
    const { organisationDetails, splitLayout, supplier } = this.props;
    const { isEditSupplierLogoModalVisible, isCreatePurchaseOrderModalVisible } = this.state;

    return (
      <>
        <Card className={cx("supplier-sidebar", { "split-layout": splitLayout })}>
          {organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders && (
            <ButtonWithPermissions
              type="primary"
              permissions={["CREATE_PURCHASE_ORDER"]}
              className="create-purchase-order"
              onClick={() => this.setState({ isCreatePurchaseOrderModalVisible: true })}
              icon={<PlusCircleOutlined />}
            >
              Create purchase order for supplier
            </ButtonWithPermissions>
          )}

          <div className="stat">
            <Typography.Text className="stat-label">Created</Typography.Text>
            <Typography.Text className="stat-value">{moment(supplier.createdAt).fromNow()}</Typography.Text>
          </div>
          <div className="stat">
            <Typography.Text className="stat-label">Last updated</Typography.Text>
            <Typography.Text className="stat-value">{moment(supplier.updatedAt).fromNow()}</Typography.Text>
          </div>
          <div className="stat">
            <Typography.Text className="stat-label">Company number</Typography.Text>
            <Input
              showBorder
              defaultValue={supplier.companyNumber}
              onChange={(value) => this.updateSupplierField({ fieldName: "companyNumber", value })}
            />
          </div>
          <div className="stat">
            <Typography.Text className="stat-label">VAT number</Typography.Text>
            <Input
              showBorder
              onChange={(value) => this.updateSupplierField({ fieldName: "vatNumber", value })}
              defaultValue={supplier.vatNumber}
            />
          </div>
          <div className="stat">
            <div className="logo-and-label-container">
              <Typography.Text className="stat-label">Logo:</Typography.Text>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => this.setState({ isEditSupplierLogoModalVisible: true })}
                data-cy="edit-supplier-logo-button"
              >
                {supplier.key ? "Edit logo" : "Add logo"}
              </Button>
            </div>
            <Typography.Text className="stat-value">
              {supplier.key ? (
                <div className="supplier-logo-container">
                  <ClientLogo client={supplier} size="medium" />
                </div>
              ) : (
                <div>No logo yet</div>
              )}
            </Typography.Text>
          </div>
          {isEditSupplierLogoModalVisible && (
            <EditSupplierLogoModal
              apiUser={window.apiUser}
              supplier={supplier}
              onClose={() => this.setState({ isEditSupplierLogoModalVisible: false })}
            />
          )}
          {isCreatePurchaseOrderModalVisible && (
            <CreatePurchaseOrderModal
              onClose={() => this.setState({ isCreatePurchaseOrderModalVisible: false })}
              apiUser={window.apiUser}
              predefinedFields={{ supplierId: supplier.id }}
            />
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: ProjectSidebar,
    subscriptions: ["organisationDetails", "sprints", "tasks", "users", "suppliers"],
  })
);
