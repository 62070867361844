import React from "react";
import { Modal, Typography } from "antd";
import { CloseIcon } from "common/icons";
import { FileOutlined } from "@ant-design/icons";
import { getAttachmentTypeFromKey } from "common/shared";
import { callRest } from "common/apiHelpers";

import "./EmailDetailsModal.scss";

type EmailDetails = {
  subject: string;
  body: string;
  attachmentsMetadata?: AttachmentMetadata[];
};

type AttachmentMetadata = {
  key: string;
  contentId?: string;
};

type Props = {
  attachment: any;
  emailData: any;
  onClose: () => void;
  openAttachment: any;
};

type State = {
  emailDetails?: EmailDetails;
};

class EmailDetailsModal extends React.Component<Props> {
  state: State = {
    emailDetails: undefined,
  };

  async getEmailDetails() {
    const { emailData } = this.props;
    const decodedFile = new TextDecoder().decode(emailData);
    const parsedJsonData = JSON.parse(decodedFile);
    let signedUrls = [];

    try {
      if (parsedJsonData.attachmentsMetadata) {
        for (let i = 0; i < parsedJsonData.attachmentsMetadata.length; i++) {
          const attachmentMetadata = parsedJsonData.attachmentsMetadata[i];

          if (attachmentMetadata.contentId) {
            const signedUrl = await callRest({
              method: "GET",
              route: `/s3?key=${attachmentMetadata.key}`,
              includeCredentials: false,
              isJson: false,
            });

            signedUrls.push({
              contentId: attachmentMetadata.contentId,
              signedUrl,
            });
          }
        }
      }

      if (signedUrls.length > 0) {
        signedUrls.forEach((signedUrlObj: { contentId: string; signedUrl: string }) => {
          const regex = new RegExp(`cid:${signedUrlObj.contentId}`, "g");
          parsedJsonData.html = parsedJsonData.html.replace(regex, signedUrlObj.signedUrl);
        });
      }

      this.setState({ emailDetails: parsedJsonData });
    } catch (err) {
      console.log("err = ", err);
    }
  }

  async componentDidMount() {
    await this.getEmailDetails();
  }

  render() {
    const { openAttachment } = this.props;
    const { emailDetails } = this.state;

    return (
      <Modal
        onCancel={this.props.onClose}
        closeIcon={<CloseIcon />}
        className="email-details-modal"
        open={true}
        footer={null}
      >
        <div className="content">
          <div className="subject-wrapper">
            <Typography.Text className="subject" data-cy="subject">
              Subject: {emailDetails?.subject}
            </Typography.Text>
          </div>

          <div className="body-wrapper">
            <Typography.Text className="body-label">Body: </Typography.Text>
            {emailDetails ? (
              <div dangerouslySetInnerHTML={{ __html: emailDetails.html }} data-cy="html-email-content" />
            ) : null}
          </div>

          <div className="attachment-list-wrapper">
            <Typography.Text className="attachments-label">Attachments: </Typography.Text>
            {emailDetails?.attachmentsMetadata?.map((attachment) => (
              <div
                className="attachment-list-item"
                onClick={() =>
                  openAttachment({
                    key: attachment.key,
                    type: getAttachmentTypeFromKey(attachment.key),
                    name: attachment.key.split("/").pop(),
                  })
                }
              >
                <FileOutlined />
                <Typography.Text data-cy="attachment-name">{attachment.key.split("/").pop()}</Typography.Text>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    );
  }
}

export default EmailDetailsModal;
