import { Button } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useGetSetState } from "react-use";

import InsertAttachmentModal from "Modals/InsertAttachmentModal/InsertAttachmentModal";

import "./AttachmentPickerForNestedFieldList.scss";

export default function AttachmentPickerForNestedFieldList(props) {
  const [getState, setState] = useGetSetState({
    isAttachmentPickerModalOpen: false,
  });
  let { value, onChange, formItemData, fieldName, form, templateDetails } = props;

  let attachments = value || [];

  const { isAttachmentPickerModalOpen } = getState();

  return (
    <div className="attachment-picker-for-nested-field-list">
      <Button onClick={() => setState({ isAttachmentPickerModalOpen: true })} className="choose-attachments-button">
        Choose attachments
      </Button>
      {attachments.map((attachmentPath) => {
        return (
          <div className="attachment-item" key={attachmentPath}>
            <div
              className="attachment-item-delete-button"
              onClick={() => {
                let newAttachments = attachments.filter((a) => a !== attachmentPath);
                // let newDates = JSON.parse(JSON.stringify(value.dates));
                // let newSizes = JSON.parse(JSON.stringify(value.sizes));
                // delete newDates[attachmentPath];
                // delete newSizes[attachmentPath];

                // onChange(newAttachments, newDates, newSizes);
                onChange(newAttachments);
              }}
            >
              <CloseCircleOutlined />
            </div>
            <div className="attachment-item-name">{attachmentPath.split("/").slice(-1)[0]}</div>
          </div>
        );
      })}
      {isAttachmentPickerModalOpen && (
        <InsertAttachmentModal
          {...props}
          defaultPath={formItemData.defaultPath}
          onSubmit={(attachments, dates, sizes) => {
            // onChange(attachments, dates, sizes);
            onChange(attachments);
          }}
          onClose={() => setState({ isAttachmentPickerModalOpen: false })}
          selectedItems={attachments}
          allowedFileTypes={formItemData.renderedInReport === false ? undefined : ["IMAGE", "PDF"]}
        />
      )}
    </div>
  );
}
