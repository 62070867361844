import { Select } from "antd";
import { getFieldsForEditorByDataSource, getFieldDetailsById } from "common/documentEditorDataSources/aggregator";
import { DOCUMENT_EDITOR_DATA_SOURCES } from "common/constants";
import {
  getParentRepeatForField,
  getRepeatFor,
  displayNoteAboutParentRepeat,
} from "TemplateEditorPage/templateEditorVariableHelpers";

import InfoItem from "InfoItem/InfoItem";
import Input from "Input/Input";
import DataSourceOverrideOptions from "../../DataSourceOverrideOptions/DataSourceOverrideOptions";

export default function DynamicInformationForVariable({
  variable,
  index,
  object,
  updateVariable,
  fileTypeDetails,
  form,
  organisationDetails,
  displayParametersForObject,
}) {
  let fieldType;
  switch (object.custom_type) {
    case "image_container":
      fieldType = "image";
      break;
    case "signature":
      fieldType = "signature";
      break;
    case "dynamic_file":
      fieldType = "file";
      break;
    default:
      switch (object.type) {
        case "text":
          fieldType = "textfield";
          break;
        default:
          break;
      }
      break;
  }

  function updateFunctionForParameters({ fieldName, value }) {
    updateVariable({
      object,
      variable,
      index,
      fields: {
        [fieldName]: value,
      },
    });
  }

  let parentRepeatForField;

  if (variable.custom_dynamicInformationDataSource) {
    parentRepeatForField = getParentRepeatForField(object);
  }

  let dynamicInfoFieldDetails;

  if (variable.custom_dynamicInformation && variable.custom_dynamicInformationDataSource) {
    const paramsForGetFieldDetails = {
      parentRepeat: parentRepeatForField,
      dataSource: variable.custom_dynamicInformationDataSource,
      id: parentRepeatForField ? `repeat_${object.custom_dynamicInformation}` : variable.custom_dynamicInformation,
      fieldId: variable.custom_dynamicInformation,
      form,
      organisationDetails,
    };
    dynamicInfoFieldDetails = getFieldDetailsById(paramsForGetFieldDetails);
  }

  let repeatFor = getRepeatFor(object);
  return (
    <>
      {displayNoteAboutParentRepeat({ object, form, organisationDetails })}
      <InfoItem
        label="Dynamic content - data source"
        value={
          <Select
            allowClear
            value={variable.custom_dynamicInformationDataSource}
            placeholder="No option selected"
            showSearch
            filterOption={(searchValue, { label }) => {
              return label?.toLowerCase().includes(searchValue?.toLowerCase());
            }}
            onChange={(_, option) => {
              updateVariable({
                object,
                variable,
                index,
                fields: {
                  custom_dynamicInformationDataSource: option?.value,
                  custom_dynamicInformation: undefined,
                  custom_dynamicInformationDataSourceOverride: undefined,
                },
              });
            }}
          >
            {fileTypeDetails.dataSources.map((dataSource, i) => {
              return (
                <Select.Option key={i} value={dataSource} label={DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}>
                  {DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}
                </Select.Option>
              );
            })}
            <Select.Option key="formula" value="formula" label="Formula">
              Formula
            </Select.Option>
          </Select>
        }
      />

      <DataSourceOverrideOptions
        object={object}
        dataSourceFieldName="custom_conditionalDisplayDataSource"
        dataSourceOverrideFieldName="custom_conditionalDisplayDataSourceOverride"
        fileTypeDetails={fileTypeDetails}
        form={form}
        organisationDetails={organisationDetails}
        onChange={({ dataSourceOverrideFieldName, value }) => {
          updateVariable({
            object,
            variable,
            index,
            fields: {
              [dataSourceOverrideFieldName]: value,
            },
          });
        }}
      />

      {variable.custom_dynamicInformationDataSource && (
        <>
          {variable.custom_dynamicInformationDataSource === "formula" ? (
            <Input
              defaultValue={variable.custom_dynamicInformation}
              placeholder="Write a formula..."
              fullWidth
              showBorder
              onChange={(value) => {
                updateVariable({
                  object,
                  variable,
                  index,
                  fields: {
                    custom_dynamicInformation: value,
                  },
                });
              }}
            />
          ) : (
            <>
              <InfoItem
                label="Field to display"
                value={
                  <Select
                    allowClear
                    value={variable.custom_dynamicInformation}
                    showSearch
                    filterOption={(searchValue, { label }) => {
                      return label?.toLowerCase().includes(searchValue?.toLowerCase());
                    }}
                    placeholder="No option selected"
                    onChange={(_, option) =>
                      updateVariable({
                        object,
                        variable,
                        index,
                        fields: {
                          custom_dynamicInformation: option?.value,
                        },
                      })
                    }
                  >
                    {getFieldsForEditorByDataSource({
                      parentRepeat: parentRepeatForField,
                      dataSource: variable.custom_dynamicInformationDataSource,
                      repeatFor,
                      form,
                      fieldType,
                      organisationDetails,
                    }).map((option, i) => {
                      return (
                        <Select.Option key={i} value={option.value} label={option.label}>
                          {option.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                }
              />
              {displayParametersForObject &&
                displayParametersForObject({
                  fieldName: "custom_dynamicInformationParameters",
                  object: variable,
                  parameters: dynamicInfoFieldDetails?.parameters,
                  updateFunction: updateFunctionForParameters,
                })}
            </>
          )}
        </>
      )}
    </>
  );
}
