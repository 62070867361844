import { Typography } from "antd";
import moment from "moment";
// import { PlusCircleOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";

import Card from "Card/Card";
import Avatar from "Avatar/Avatar";

import "./Audit.scss";

export function Audit({ apiUser, users, auditItems }) {
  return (
    <Card className="audit" title="Audit">
      {auditItems.map((auditItem) => {
        let title = `${auditItem.type[0]}${auditItem.type.toLowerCase().substring(1).split("_").join(" ")}`;
        let icon = null;

        switch (auditItem.type) {
          case "CUSTOM_FILE_UPLOAD":
            title = <>Custom file upload</>;
            icon = <Avatar user={users.find((x) => x.id === auditItem.userId)} />;
            break;
          // case "LIFECYCLE_EVENT":
          //   icon = <img src={DraughtHubLogoSymbolOnly} className="draughthub-item-logo" alt="" />;
          //   break;
          default:
            break;
        }
        return (
          <div className="audit-item" key={auditItem.id}>
            <div className="item-content">
              <Typography.Text className="item-title">
                <div className="item-icon">{icon}</div>
                {title}
              </Typography.Text>
            </div>
            <div className="item-date">{moment(auditItem.createdAt).format("DD MMM YYYY - HH:mm:ss")}</div>
          </div>
        );
      })}
    </Card>
  );
}

export default withRouter(Audit);
