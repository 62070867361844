import { Modal, Table, Button, Empty } from "antd";
import { useGetSetState } from "react-use";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LexoRank } from "lexorank";

import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";
import { STOCK_ITEM_TYPES } from "common/constants";
import { callGraphQLSimple } from "common/apiHelpers";
import { DragHandleIcon } from "common/icons";

import StockItemFilters from "StockItemFilters/StockItemFilters";
import CreateStockItemModal from "Modals/CreateStockItemModal/CreateStockItemModal";

import "./StockItemsPage.scss";

export function StockItemsPage({ stockItems, setBoxedLayout, setRightMargin, history, setProps, context }) {
  const [getState, setState] = useGetSetState({
    selectedStockItem: undefined,
    isCreateStockItemModalOpen: false,
    filter: {},
  });

  useEffect(() => {
    setBoxedLayout(false);
    setRightMargin(false);
    checkOrderOfItems();
    return () => {
      setBoxedLayout(true);
      setRightMargin(true);
    };
  }, []); //eslint-disable-line

  async function confirmDeleteStockItem(stockItem) {
    Modal.confirm({
      title: `Are you sure you want to delete ${getSimpleLabel("stock item")} "${stockItem.name}"?`,
      content: "This action cannot be undone.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        await callGraphQLSimple({
          message: `Failed to delete ${getSimpleLabel("stock item")}`,
          queryName: "deleteStockItem",
          variables: {
            input: {
              id: stockItem.id,
            },
          },
        });
      },
    });
  }

  async function checkOrderOfItems() {
    if (!stockItems.length) {
      return;
    }

    let sortedStockItems = [...stockItems];

    // sort sortedStockItems either by order or by createdAt
    sortedStockItems.sort((a, b) => {
      if (a.order && b.order) {
        return a.order < b.order ? -1 : 1;
      }

      return a.createdAt - b.createdAt;
    });

    const newStockItems = JSON.parse(JSON.stringify(sortedStockItems));

    // now that the items have been sorted, regenerate the order field starting from the first one

    newStockItems[0].order = LexoRank.middle().toString();

    for (let i = 1; i < newStockItems.length; i++) {
      let previousOrder = LexoRank.parse(newStockItems[i - 1].order);
      let newOrder = previousOrder.genNext();
      newStockItems[i].order = newOrder.toString();
    }

    for (let i = 0; i < newStockItems.length; i++) {
      let newItem = newStockItems[i];
      let oldItem = sortedStockItems[i];

      if (newItem.order === oldItem.order) {
        continue;
      }

      await callGraphQLSimple({
        message: `Failed to update ${getSimpleLabel("stock item")} order`,
        queryName: "updateStockItem",
        variables: {
          input: {
            id: newItem.id,
            order: newItem.order,
          },
        },
      });
    }
  }

  async function onDragEnd(result) {
    // debugger;

    // item dropped outside the list
    if (!result.destination) {
      return;
    }

    let newStockItems = [...stockItems].sort((a, b) => (a.order < b.order ? -1 : 1));

    const newIndex = result.destination.index;

    let destinationOrder = LexoRank.parse(newStockItems[newIndex].order);

    let newOrder;
    if (result.destination.index === result.source.index) {
      // no movement needed
      return;
    } else if (newIndex === 0) {
      newOrder = destinationOrder.genPrev();
    } else if (result.destination.index < result.source.index) {
      newStockItems = newStockItems.filter((x) => x.id !== result.draggableId);
      destinationOrder = LexoRank.parse(newStockItems[newIndex].order);
      let beforeItemOrder = LexoRank.parse(newStockItems[newIndex - 1].order);
      newOrder = destinationOrder.between(beforeItemOrder);
    } else if (newIndex === newStockItems.length - 1) {
      newOrder = destinationOrder.genNext();
    } else {
      let afterItemOrder = LexoRank.parse(newStockItems[newIndex + 1].order);
      newOrder = afterItemOrder.between(destinationOrder);
    }

    setProps({
      context: {
        ...context,
        stockItems: newStockItems.map((stockItem) => {
          if (stockItem.id !== result.draggableId) {
            return stockItem;
          }

          return {
            ...stockItem,
            order: newOrder.value,
          };
        }),
      },
    });

    await callGraphQLSimple({
      message: `Failed to update ${getSimpleLabel("stock item")} order`,
      queryName: "updateStockItem",
      variables: {
        input: {
          id: result.draggableId,
          order: newOrder.toString(),
        },
      },
    });
  }

  let includeDragging = true;

  const columns = [
    {
      title: "Drag",
      width: "50",
      align: "center",
      render: (_) => {
        return <DragHandleIcon />;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "left",
      render: (type) => STOCK_ITEM_TYPES.find((typeDefinition) => typeDefinition.value === type)?.label || type,
    },
    {
      title: `Display on ${getSimpleLabel("timeline")}`,
      dataIndex: "displayOnTimeline",
      key: "type",
      align: "left",
      render: (displayOnTimeline) => (displayOnTimeline ? "Yes" : "No"),
    },
    {
      title: "Created on",
      dataIndex: "createdAt",
      align: "left",
      render: (_, stockItem) => {
        return moment(stockItem.createdAt).format("DD-MM-YYYY");
      },
    },
    {
      title: "",
      render: (_, stockItem) => {
        return (
          <div style={{ display: "flex", gap: "0.5rem", "flex-wrap": "wrap" }}>
            <Button
              icon={<EditOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/stock-items/${stockItem.id}`);
              }}
            />
            <Button
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                confirmDeleteStockItem(stockItem);
              }}
            />
          </div>
        );
      },
    },
  ];

  function DroppableTableBody({ ...props }) {
    return (
      <Droppable droppableId={"stock-items-table"}>
        {(provided, snapshot) => (
          <tbody ref={provided.innerRef} {...props} {...provided.droppableProps} className={props.className}></tbody>
        )}
      </Droppable>
    );
  }

  function DraggableTableRow({ index, record, ...props }) {
    if (!stockItems.length) {
      return (
        <tr className="ant-table-placeholder stock-row-item no-hover" key="empty" {...props}>
          <td colSpan={columns.length} className="ant-table-cell">
            <div className="ant-empty ant-empty-normal">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </td>
        </tr>
      );
    }

    return (
      <Draggable draggableId={props["data-row-key"]?.toString()} index={index} key={record.id}>
        {(provided, snapshot) => {
          return (
            <tr
              ref={provided.innerRef}
              {...props}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={`stock-row-item no-hover ${snapshot.isDragging ? "row-dragging" : ""}`}
              onClick={() => {
                history.push(`/stock-items/${record.id}`);
              }}
            ></tr>
          );
        }}
      </Draggable>
    );
  }

  const { isCreateStockItemModalOpen } = getState();

  return (
    <div className="stock-items-page">
      <div className="page-header">
        <StockItemFilters
          onChange={(filter) => setState({ filter })}
          includeCreateQuote
          // export={exportToCSV}
        />

        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          data-cy="create-stock-item-button"
          className="create-stock-item-button"
          onClick={() => setState({ selectedStockItem: undefined, isCreateStockItemModalOpen: true })}
        >
          Create {getSimpleLabel("stock item")}
        </Button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Table
          dataSource={[...stockItems].sort((a, b) => (a.order < b.order ? -1 : 1))}
          pagination={{ pageSize: 50, hideOnSinglePage: true }}
          rowKey="id"
          components={
            includeDragging
              ? {
                  body: {
                    // Custom tbody
                    wrapper: (val) =>
                      DroppableTableBody({
                        ...val,
                      }),
                    // Custom tr
                    row: (val) =>
                      DraggableTableRow({
                        ...val,
                      }),
                  },
                }
              : undefined
          }
          onRow={(record, index) => ({
            index,
            record,
          })}
          columns={columns}
        />
      </DragDropContext>

      {isCreateStockItemModalOpen && (
        <CreateStockItemModal
          onClose={() =>
            setState({
              isCreateStockItemModalOpen: false,
              selectedStockItem: undefined,
            })
          }
        />
      )}
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: StockItemsPage,
    subscriptions: ["stockItems"],
  })
);
