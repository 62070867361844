import { useState } from "react";
import { Form, Modal, Button, AutoComplete, Row, Col } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { TIMELINE_PSEUDO_TASKS } from "common/constants";
import { getSimpleLabel } from "common/labels";
import { useForceUpdate } from "common/helpers";
import { CloseIcon } from "common/icons";

import TaskPicker from "TaskPicker/TaskPicker";
import CreateTaskModal from "CreateTaskModal/CreateTaskModal";

import "./NewTimelineBlockModal.scss";

type Props = {
  onClose: any;
  onSubmit: ({ taskId, draggableType }) => void;
  apiUser: any;
};

export default function NewTimelineBlockModal({ onClose, apiUser, onSubmit: onSubmitProp }: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateTaskModalVisible, setIsCreateTaskModalVisible] = useState(false);
  const forceUpdate = useForceUpdate();

  function onSubmit({ taskId, pseudoTaskId }) {
    onSubmitProp({ taskId: taskId || pseudoTaskId, draggableType: taskId ? "task" : "pseudo-task" });
  }

  return (
    <Modal
      maskClosable={true}
      closeIcon={<CloseIcon />}
      title={`New ${getSimpleLabel("timeline")} block`}
      open={true}
      onCancel={onClose}
      footer={null}
      className="new-timeline-block-modal full-screen-on-mobile"
    >
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="Task"
          name="taskId"
          dependencies={["pseudoTaskId"]}
          rules={[
            {
              validator: async (_, taskId) => {
                if (!taskId) {
                  return;
                }
                if (!form.getFieldValue("taskId")) {
                  return;
                }

                if (form.getFieldValue("taskId") && form.getFieldValue("pseudoTaskId")) {
                  throw new Error(
                    `You cannot choose both a real ${getSimpleLabel("task")} and a special ${getSimpleLabel(
                      "task"
                    )} at the same time`
                  );
                }
              },
            },
          ]}
        >
          <div className="task-picker-row">
            <TaskPicker
              value={form.getFieldValue("taskId")}
              onSearch={() => {
                form.setFieldsValue({ taskId: "" });
                forceUpdate();
              }}
              onChange={(taskId) => {
                form.setFieldsValue({ taskId });
                forceUpdate();
              }}
            />
            <Button
              data-cy="create-linked-task-button"
              type="primary"
              className="create-task"
              onClick={() => setIsCreateTaskModalVisible(true)}
            >
              <PlusCircleOutlined /> Create {getSimpleLabel("task")}
            </Button>
          </div>
        </Form.Item>
        <Form.Item
          label={<>Special {getSimpleLabel("task")}</>}
          name="pseudoTaskId"
          dependencies={["taskId"]}
          rules={[
            {
              validator: async (_, pseudoTaskId) => {
                if (!pseudoTaskId) {
                  return;
                }
                if (!form.getFieldValue("pseudoTaskId")) {
                  return;
                }

                if (form.getFieldValue("pseudoTaskId") && form.getFieldValue("taskId")) {
                  throw new Error(
                    `You cannot choose a ${getSimpleLabel("task")} and a special ${getSimpleLabel(
                      "task"
                    )} at the same time`
                  );
                }
              },
            },
          ]}
        >
          <AutoComplete
            options={TIMELINE_PSEUDO_TASKS.map((pseudoTask) => {
              return {
                label: pseudoTask,
                value: pseudoTask,
              };
            })}
            value={form.getFieldValue("pseudoTaskId")}
          />
        </Form.Item>
        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="new-timeline-block-modal-submit-button">
            Submit
          </Button>
        </div>
      </Form>
      {isCreateTaskModalVisible && (
        <CreateTaskModal
          onClose={() => setIsCreateTaskModalVisible(false)}
          apiUser={apiUser}
          onSave={(task) => {
            form.setFieldsValue({ taskId: task.id });
            forceUpdate();
          }}
        />
      )}
    </Modal>
  );
}
