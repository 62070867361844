import { Typography } from "antd";
import moment from "moment";
import cx from "classnames";
import {
  TIMELINE_DEFAULT_HOURS_IN_A_DAY,
  TIMELINE_DEFAULT_DAY_START_HOURS,
  TIMESHEET_HOUR_HEIGHT,
} from "common/constants";
import { taskIdToColor } from "TimelinePage/timelineHelpers";
import TaskIdTag from "TaskIdTag/TaskIdTag";

import "./PlannedActivityBlock.scss";

export default function PlannedActivityBlock({ timelineBlock, targetDate, tasks, projects }) {
  let realStartHours;
  let realDuration;
  let blockStartDate = moment(timelineBlock.startDate);

  let durationOnFirstDay = TIMELINE_DEFAULT_HOURS_IN_A_DAY - timelineBlock.startHours;
  let durationAfterFirstDay = timelineBlock.durationHours - durationOnFirstDay;
  let durationAfterFirstDayInDays = Math.ceil(durationAfterFirstDay / TIMELINE_DEFAULT_HOURS_IN_A_DAY);

  let blockEndDate = moment(blockStartDate).add(durationAfterFirstDayInDays, "days");

  if (blockEndDate.isBefore(targetDate, "day")) {
    return null;
  } else if (moment(timelineBlock.startDate).isAfter(targetDate, "day")) {
    return null;
  } else if (moment(timelineBlock.startDate).isBefore(targetDate, "day")) {
    realStartHours = TIMELINE_DEFAULT_DAY_START_HOURS;

    if (moment(blockEndDate).isAfter(targetDate, "day")) {
      realDuration = TIMELINE_DEFAULT_HOURS_IN_A_DAY;
    } else {
      let daysToSubtract = targetDate.diff(blockStartDate, "days");
      durationAfterFirstDay -= (daysToSubtract - 1) * TIMELINE_DEFAULT_HOURS_IN_A_DAY;
      realDuration = durationAfterFirstDay;
    }
  } else {
    if (timelineBlock.startHours + timelineBlock.durationHours > TIMELINE_DEFAULT_HOURS_IN_A_DAY) {
      realDuration = TIMELINE_DEFAULT_HOURS_IN_A_DAY - timelineBlock.startHours;
    } else {
      realDuration = timelineBlock.durationHours;
    }
    realStartHours = timelineBlock.startHours + TIMELINE_DEFAULT_DAY_START_HOURS;
  }

  const yPosition = realStartHours * TIMESHEET_HOUR_HEIGHT - TIMESHEET_HOUR_HEIGHT;
  const height = realDuration * TIMESHEET_HOUR_HEIGHT;
  const backgroundColor = taskIdToColor(timelineBlock.taskId);
  const task = tasks.find((x) => x.id === timelineBlock.taskId);

  function displayTaskInfo() {
    if (task) {
      let project = projects.find((x) => x.id === task.projectId);
      return (
        <div className="details">
          <Typography.Text className="project-title">{project.title}</Typography.Text>
          <Typography.Text className="task-title">{task.title} </Typography.Text>
          <TaskIdTag task={task} includeTitle={false} />
        </div>
      );
    } else {
      return <Typography.Text>{timelineBlock.taskId}</Typography.Text>;
    }
  }

  return (
    <>
      <div
        className={cx("planned-activity-block", { "pseudo-task": !task, compact: realDuration < 2 })}
        style={{ top: yPosition + 2, height: height - 3 }}
        draggable
        onDragStart={(e) => {
          e.dataTransfer.setData("timeline-block", JSON.stringify(timelineBlock));
          e.dataTransfer.setData("task-id", timelineBlock.taskId);
          e.dataTransfer.setData("draggable-type", "task");
        }}
      >
        <div className="background" style={{ backgroundColor }}></div>
        {displayTaskInfo()}
      </div>
    </>
  );
}
