import { useState } from "react";
import { Button, Typography } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { withRouter } from "react-router-dom";
import Card from "Card/Card";
import Explanation from "Explanation/Explanation";
import CreateHiddenLineItemColumnsModal from "Modals/CreateHiddenLineItemColumnModal/CreateHiddenLineItemColumnModal";
import QuoteHiddenLineItemColumnsList from "./QuoteHiddenLineItemColumnsList/QuoteHiddenLineItemColumnsList";

function QuoteHiddenLineItemColumns(props) {
  const [isCreateHiddenLineItemColumnModalVisible, setIsCreateHiddenLineItemColumnModalVisible] = useState(false);
  const { organisationDetails } = props;

  function displayHiddenLineItemColumnList() {
    const { setProps, context } = props;

    if (
      !organisationDetails.settings?.quote?.hiddenLineItemColumns ||
      organisationDetails.settings?.quote?.hiddenLineItemColumns?.length === 0
    ) {
      return null;
    }

    return (
      <QuoteHiddenLineItemColumnsList organisationDetails={organisationDetails} setProps={setProps} context={context} />
    );
  }

  return (
    <Card
      flexTitle
      title={
        <>
          <Typography.Text>Hidden line item columns</Typography.Text>
          <Explanation title="You can choose which of the default columns on the quote line items table are hidden" />
        </>
      }
      withSpace
      actions={
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          data-cy="add-hidden-line-item-column-button"
          onClick={() => setIsCreateHiddenLineItemColumnModalVisible(true)}
        >
          Add hidden line item column
        </Button>
      }
    >
      {displayHiddenLineItemColumnList()}
      <CreateHiddenLineItemColumnsModal
        visible={isCreateHiddenLineItemColumnModalVisible}
        onClose={() => setIsCreateHiddenLineItemColumnModalVisible(false)}
      />
    </Card>
  );
}

export default withRouter(
  withSubscriptions({
    Component: QuoteHiddenLineItemColumns,
    subscriptions: ["organisationDetails"],
  })
);
