const fields = [
  {
    id: "clientContactFullName",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Full name",
    value: ({ clientContact }) => {
      if (!clientContact) {
        return "";
      }

      return `${clientContact.firstName || ""} ${clientContact.lastName || ""}`;
    },
  },
  {
    id: "clientContactFirstName",
    type: "string",
    fieldTypes: ["textfield"],
    label: "First name",
    value: ({ clientContact }) => {
      if (!clientContact) {
        return "";
      }

      return clientContact.firstName || "";
    },
  },
  {
    id: "clientContactLastName",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Last name",
    value: ({ clientContact }) => {
      if (!clientContact) {
        return "";
      }

      return clientContact.lastName || "";
    },
  },
  {
    id: "clientContactPosition",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Position",
    value: ({ clientContact }) => {
      if (!clientContact) {
        return "";
      }

      return clientContact.position || "";
    },
  },
  {
    id: "clientContactPhone",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Phone",
    value: ({ clientContact }) => {
      if (!clientContact) {
        return "";
      }

      return clientContact.phone || "";
    },
  },
  {
    id: "clientContactEmail",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Email",
    value: ({ clientContact }) => {
      if (!clientContact) {
        return "";
      }

      return clientContact.email || "";
    },
  },
];
export function getFields() {
  return fields;
}

export const label = "File";
