import React from "react";
import { Tooltip } from "antd";
import cx from "classnames";
import cookie from "js-cookie";

import "./DismissableTooltip.scss";

export default class DismissableTooltip extends React.Component {
  state = {
    isHidden: false,
  };
  render() {
    const { children, title, placement, cookie: cookieName } = this.props;
    const { isHidden } = this.state;

    let visible = !isHidden;

    const cookieValue = cookie.get(cookieName);
    if (cookieValue) {
      visible = false;
    }

    if (!visible) {
      return children;
    }

    let content = (
      <div
        className={cx("dismissable-tooltip-inner-container")}
        onClick={() => {
          cookie.set(cookieName, "dismissed", { expires: 99999 });
          this.setState({ isHidden: true });
        }}
      >
        {children}
      </div>
    );

    return (
      <Tooltip
        title={title}
        visible={visible}
        placement={placement}
        trigger={[]}
        overlayClassName={cx("dismissable-tooltip-overlay", cookieName)}
      >
        {content}
      </Tooltip>
    );
  }
}

export function hasAlreadyDisplayedTooltip(cookieName) {
  const cookieValue = cookie.get(cookieName);
  let result;
  if (cookieValue) {
    result = true;
  } else {
    result = false;
  }
  return result;
}
