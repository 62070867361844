import { withRouter, Link } from "react-router-dom";
import { Typography, Checkbox, Tooltip, Modal } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { OpenIcon } from "common/icons";
import cx from "classnames";

import { callGraphQLSimple } from "common/apiHelpers";
import { getExcludedReviewerList, getSortedFiles, openFileWithLink } from "common/helpers";
import { annotateAllFilesInTaskRevision } from "ReviewPage/reviewHelpers";
import { getLatestFileVersion, HAS_SHEETS } from "common/shared";
import { sendReviewRequestNotification } from "common/notificationHelpers";
import { FILE_TYPES_READABLE, DEFAULT_FILE_NAMES } from "common/constants";
import { getSimpleLabel } from "common/labels";
import { REVIEW_MOBILE_BREAKPOINT } from "common/constants";

import Avatar from "Avatar/Avatar";
import UsersFilter from "UsersFilter/UsersFilter";

import "./ReviewFileList.scss";

export function ReviewFileList({
  users,
  task,
  taskRevision,
  apiUser,
  basePath,
  organisationDetails,
  history,
  onHide,
  onShow,
  isOpen,
  onItemClick,
  isMinimal,
  requestFormActivityItem,
  isExternalReview,
  request,
}) {
  const { review } = taskRevision;
  const excludedFileTypes = organisationDetails.settings?.review?.excludedFileTypes || [];

  const urlParams = new URLSearchParams(window.location.search);
  const selectedTab = urlParams.get("tab");

  function onToggleClick() {
    if (isOpen) {
      onHide();
    } else {
      onShow();
    }
  }

  async function changeReviewer(newReviewer) {
    sendReviewRequestNotification({
      users,
      reviewer: newReviewer,
      apiUser,
      taskId: task.id,
      taskTitle: task.title,
      clientName: task.client?.name,
      projectTitle: task.project?.title,
      taskRevisionName: taskRevision.name,
      taskRevisionDescription: taskRevision.description,
      taskRevisionId: taskRevision.id,
    });

    await callGraphQLSimple({
      message: "Failed to request review",
      queryName: "updateTaskRevision",
      variables: {
        input: {
          id: taskRevision.id,
          checkedBy: newReviewer,
        },
      },
    });

    await callGraphQLSimple({
      message: "Failed to record change of reviewer",
      queryName: "updateReview",
      variables: {
        input: {
          id: review.id,
          reviewThread: [
            ...review.reviewThread,
            {
              type: "REVIEWER_CHANGE",
              createdAt: new Date().toISOString(),
              content: newReviewer,
            },
          ],
        },
      },
    });

    await callGraphQLSimple({
      message: `Failed to update ${getSimpleLabel("task")}`,
      queryName: "updateTask",
      variables: {
        input: {
          id: task.id,
          randomNumber: Math.floor(Math.random() * 1000000),
          checkedBy: newReviewer,
        },
      },
    });

    annotateAllFilesInTaskRevision({
      task,
      taskRevision: { ...taskRevision, checkedBy: newReviewer },
      apiUser,
    });
  }

  async function openInClick(file) {
    const fileVersion = getLatestFileVersion(file);
    await openFileWithLink({
      revisionData: taskRevision,
      task,
      file,
      history,
      fileVersion,
      page: "REVIEW",
    });
  }

  async function changeSectionApproved(id) {
    const oldApprovedItems = [...(review.approvedItems || [])];
    const itemWasAlreadyApproved = oldApprovedItems.some((x) => x.id === id);
    let newApprovedItems = oldApprovedItems;
    if (itemWasAlreadyApproved) {
      newApprovedItems = newApprovedItems.filter((x) => x.id !== id);
    } else {
      newApprovedItems = [...newApprovedItems, { id, approvedAt: new Date().toISOString() }];
    }

    await callGraphQLSimple({
      message: "Failed to update review",
      queryName: "updateReview",
      variables: {
        input: {
          id: review.id,
          approvedItems: newApprovedItems,
        },
      },
    });

    await callGraphQLSimple({
      message: `Failed to update ${getSimpleLabel("task revision")}`,
      queryName: "updateTaskRevision",
      variables: {
        input: {
          id: taskRevision.id,
          randomNumber: Math.floor(Math.random() * 1000000),
        },
      },
    });
  }

  function isApproved(sheet) {
    return (review.approvedItems || []).some((x) => x.id === sheet.id);
  }

  function isApprovedOld(sheet) {
    const file = taskRevision.files.items.find((x) => x.id === sheet.fileId);

    const fileVersion = getLatestFileVersion(file);

    let approvedItem = (review.approvedItems || []).find((x) => x.id === sheet.id);
    if (!approvedItem) {
      return false;
    }

    if (!fileVersion.publishStartAt) {
      return false;
    }

    const approvedAtTimestamp = new Date(approvedItem.approvedAt).getTime();
    const changedAtTimestamp = new Date(fileVersion.publishStartAt).getTime();

    if (changedAtTimestamp > approvedAtTimestamp) {
      return true;
    }
  }

  function isUserReviewer() {
    const userIsReviewer = apiUser.id === taskRevision.checkedBy;

    return userIsReviewer;
  }

  function onFileClick() {
    if (window.innerWidth < REVIEW_MOBILE_BREAKPOINT) {
      onItemClick();
    }
  }

  function displayFileWithSheets(file, basePath) {
    let fileTitle;
    if (!["REPORT", "BLUEBEAM"].includes(file.type)) {
      fileTitle = `${FILE_TYPES_READABLE[file.type]} - ${file.name || DEFAULT_FILE_NAMES[file.type]}`;
    } else {
      fileTitle = file.name || DEFAULT_FILE_NAMES[file.type];
    }
    return (
      <div className="file-item" key={file.id}>
        <Typography.Text className="file-type">
          {fileTitle}
          {!["REPORT", "BLUEBEAM"].includes(file.type) && (
            <Tooltip title={`Open in ${FILE_TYPES_READABLE[file.type]}`}>
              <span onClick={() => openInClick(file)} className="export-file-icon">
                <OpenIcon />
              </span>
            </Tooltip>
          )}
        </Typography.Text>

        {file.sheets.items.map((sheet) => (
          <Link
            to={`${basePath}?tab=${sheet.id}`}
            key={sheet.id}
            className={cx("sheet-item", {
              selected: selectedTab === sheet.id && window.innerWidth > REVIEW_MOBILE_BREAKPOINT,
            })}
          >
            <div className="sheet-name-and-changed">
              <Typography.Text className="sheet-name" onClick={onFileClick}>
                {sheet.name}
              </Typography.Text>

              {isApprovedOld(sheet) ? <span className="approved-old"> (changed)</span> : null}
            </div>

            <div>
              <Checkbox
                className={cx("review-section-approved-checkbox", {
                  disabled: !isUserReviewer(),
                })}
                checked={isApproved(sheet)}
                onChange={() => changeSectionApproved(sheet.id)}
              />
            </div>
          </Link>
        ))}
      </div>
    );
  }

  function displayFileWithoutSheets(file, basePath) {
    const firstSheetId = file.sheets.items[0].id;

    let linkPath = `${basePath}?tab=${firstSheetId}`;

    let shouldDisplayOpenInIcon;
    if (isExternalReview) {
      shouldDisplayOpenInIcon = false;
    } else {
      shouldDisplayOpenInIcon = !["REPORT", "BLUEBEAM"].includes(file.type);
    }

    let fileTitle;
    // if (!["REPORT", "BLUEBEAM"].includes(file.type)) {
    fileTitle = `${FILE_TYPES_READABLE[file.type]} - ${file.name || DEFAULT_FILE_NAMES[file.type]}`;
    // } else {
    // fileTitle = file.name || DEFAULT_FILE_NAMES[file.type];
    // }

    return (
      <Link
        to={linkPath}
        className={cx("file-item", "file-without-sheets", {
          selected: selectedTab === firstSheetId && window.innerWidth > REVIEW_MOBILE_BREAKPOINT,
        })}
        key={file.id}
        onClick={onFileClick}
      >
        <Typography.Text className="file-type">
          {fileTitle}
          {shouldDisplayOpenInIcon && (
            <Tooltip title={`Open in ${FILE_TYPES_READABLE[file.type]}`}>
              <span onClick={() => openInClick(file)} className="export-file-icon">
                <OpenIcon />
              </span>
            </Tooltip>
          )}
        </Typography.Text>

        <Checkbox
          className={cx("review-section-approved-checkbox", {
            disabled: !isUserReviewer(),
          })}
        />
      </Link>
    );
  }

  function displayRequestFormIfPresent() {
    if (
      !organisationDetails?.settings?.request?.usesRequests ||
      !organisationDetails?.settings?.request?.displayRequestFormInTaskReview ||
      !requestFormActivityItem ||
      requestFormActivityItem.content?.isLinkingReview
    ) {
      return null;
    }

    return (
      <>
        <Link
          className={cx("file-item", "file-without-sheets", {
            selected: selectedTab === "request-form" && window.innerWidth > REVIEW_MOBILE_BREAKPOINT,
          })}
          to={`${basePath}?tab=request-form`}
          onClick={onFileClick}
        >
          <Typography.Text className="file-type sheet-item">{requestFormActivityItem.content.formName}</Typography.Text>
        </Link>
      </>
    );
  }

  return (
    <div className={cx("review-file-list", { "is-closed": !isOpen, "is-minimal": isMinimal })}>
      {window.innerWidth > REVIEW_MOBILE_BREAKPOINT && (
        <Tooltip trigger={["hover", "click"]} title={isOpen ? "Close panel" : "Open panel"}>
          <div className="toggle" onClick={onToggleClick}>
            {isOpen ? <CaretLeftOutlined /> : <CaretRightOutlined />}
          </div>
        </Tooltip>
      )}
      <div className="section">
        <p className="section-label">Reviewer</p>
        <div
          onClick={() => {
            if (request) {
              Modal.info({
                title: `Reviewer cannot be changed`,
                content: `You can't change the reviewer for this type of review.`,
              });
              return;
            }
            if (task.isFinished) {
              Modal.info({
                title: `${getSimpleLabel("Task")} is finished`,
                content: `You can't change the reviewer of a finished ${getSimpleLabel("task")}.`,
              });
              return;
            }
            if (task.isArchived) {
              Modal.info({
                title: `${getSimpleLabel("Task")} is archived`,
                content: `You can't change the reviewer of a archived ${getSimpleLabel("task")}.`,
              });
              return;
            }
            if (!task.isUnderReview) {
              Modal.info({
                title: `Review is not active`,
                content: `You can't change the reviewer if the review is not currently in progress.`,
              });
              return;
            }
          }}
        >
          {!task.isFinished && !task.isArchived && task.isUnderReview ? (
            <UsersFilter
              className="reviewer-picker"
              value={taskRevision.checkedBy}
              onChange={changeReviewer}
              excludeList={getExcludedReviewerList({
                task,
                users,
                allowUseOfCatZero: organisationDetails.settings?.task?.allowUseOfCatZero,
              })}
              includeUnassigned={false}
              suffixIcon={null}
            />
          ) : (
            <Avatar user={users.find((x) => x.id === taskRevision.checkedBy)} showLabel={true} />
          )}
        </div>
      </div>

      <div className="section section-sheets">
        <p className="section-label" style={{ marginTop: "2rem" }}>
          Review sections
        </p>
        <Link
          className={cx("file-item", "file-without-sheets conversation", {
            selected: (selectedTab === "conversation" || !selectedTab) && window.innerWidth > REVIEW_MOBILE_BREAKPOINT,
          })}
          to={`${basePath}?tab=conversation`}
          onClick={onFileClick}
        >
          <Typography.Text className="file-type sheet-item">Conversation</Typography.Text>
        </Link>
        {displayRequestFormIfPresent()}

        {getSortedFiles({ taskRevision })
          .filter((x) => !(excludedFileTypes || []).includes(x.type))
          .map((file) => {
            let fileHasSheets;
            if (isExternalReview) {
              fileHasSheets = false;
            } else {
              fileHasSheets = HAS_SHEETS[file.type];
            }
            return fileHasSheets ? displayFileWithSheets(file, basePath) : displayFileWithoutSheets(file, basePath);
          })}
      </div>
    </div>
  );
}

export default withRouter(ReviewFileList);
