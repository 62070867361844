import { useState } from "react";
import { Form, Modal, Button } from "antd";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";
import { recordActivityItem } from "QuoteDetailsPage/quoteHelpers";
import { sendQuoteReviewRequestNotification } from "common/notificationHelpers";
import { getSimpleLabel } from "common/labels";

import UsersFilter from "UsersFilter/UsersFilter";

import "./RequestQuoteReviewModal.scss";

export function RequestQuoteReviewModal({ onClose, quote, users, clients, potentialReviewers, apiUser }) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  function closeModal() {
    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  async function onSubmit({ reviewer }) {
    setIsLoading(true);

    await callGraphQLSimple({
      message: `Failed to update ${getSimpleLabel("quote")}`,
      queryName: "updateQuote",
      variables: {
        input: {
          id: quote.id,
          isUnderReview: true,
          reviewStatus: "IN_PROGRESS",
          checkedBy: reviewer,
        },
      },
    });

    await callGraphQLSimple({
      message: "Failed to start review",
      queryName: "createReview",
      variables: {
        input: {
          parentId: quote.id,
          organisation: quote.organisation,
          reviewThread: [
            { type: "START", createdAt: new Date().toISOString() },
            { type: "REVIEWER_SET", createdAt: new Date().toISOString(), content: reviewer },
          ],
        },
      },
    });

    sendQuoteReviewRequestNotification({
      users,
      apiUser,
      reviewer,
      quoteId: quote.id,
      quoteTitle: quote.title,
      clientName: quote.client?.name,
      projectTitle: quote.project?.title,
    });

    recordActivityItem({
      quote: { ...quote, checkedBy: reviewer },
      type: "REVIEW_OPENED",
      author: apiUser.id,
      clients,
      users,
    });
    closeModal();
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title="Request review"
      visible={true}
      onOk={onSubmit}
      onCancel={closeModal}
      footer={null}
      className="request-quote-review-modal"
    >
      <Form {...layout} form={form} initialValues={{}} onFinish={onSubmit}>
        <Form.Item
          label="Reviewer"
          name="reviewer"
          rules={[
            {
              required: true,
              message: "You must choose a reviewer",
            },
          ]}
        >
          <UsersFilter orderedActiveUsers={potentialReviewers} includeUnassigned={false} />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
            {isLoading ? "Requesting" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default withSubscriptions({
  Component: RequestQuoteReviewModal,
});
