import { useEffect } from "react";
import { useGetSetState } from "react-use";
import { Link } from "react-router-dom";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import getS3File from "common/getS3File";
import { Button, message, Modal, Typography, Result } from "antd";
import axios from "axios";

import { callGraphQLSimple } from "common/apiHelpers";
import { FILE_TYPES_ORDER } from "common/constants";
import { FILE_TYPES_DETAILS } from "common/shared";
import { downloadBlob } from "common/helpers";
import withSubscriptions from "common/withSubscriptions";
import downloadSelectedSheetsInTask from "common/downloadSelectedSheetsInTask";
import { isAuthorised } from "common/permissions";

import "./RequestIssuedTaskFilesModal.scss";
import { getSimpleLabel } from "common/labels";

type Props = {
  request: any;
  activityItem: any;
  users;
  organisationDetails: any;
  apiUser: any;
  tasks: any[];
  onClose: () => void;
};

type State = {
  task: any;
};

export function RequestIssuedTaskFilesModal({
  request,
  activityItem,
  users,
  organisationDetails,
  apiUser,
  tasks,
  onClose,
}: Props) {
  const [getState, setState] = useGetSetState<State>({
    task: undefined,
  });

  const { task } = getState();

  useEffect(() => {
    fetchAndSetTask();

    async function fetchAndSetTask() {
      const messageKey = "fetch-task-for-issued-files-modal";
      message.loading({ content: `Loading data...`, key: messageKey, duration: 0 });

      try {
        const task = (
          await callGraphQLSimple({
            queryCustom: "getTaskWithFiles",
            message: `Failed to fetch ${getSimpleLabel("task")} details`,
            variables: {
              id: activityItem.content.taskId,
            },
          })
        ).data.getTask;
        message.destroy(messageKey);
        setState({ task });
      } catch (e) {
        message.error({ content: "Failed to load data", key: messageKey });
        return;
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function downloadIndividualFile({ url, fileName }: { url: string; fileName: string }) {
    const messageKey = `downloading-${url}`;
    message.loading({ content: `Downloading ${fileName}`, key: messageKey, duration: 0 });
    try {
      const fileBlob = (await axios.get(url, { responseType: "blob" })).data;
      await downloadBlob({
        blob: fileBlob,
        fileName,
      });

      await callGraphQLSimple({
        mutation: "createTaskActivityItem",
        message: "Failed to record activity item",
        variables: {
          input: {
            taskId: activityItem.content.taskId,
            content: JSON.stringify({
              type: `TASK_FILES_DOWNLOADED_INDIVIDUAL`,
              fileName,
            }),
            organisation: organisationDetails.id,
            author: apiUser.id,
            type: "LIFECYCLE_EVENT",
          },
        },
      });
      message.destroy(messageKey);
    } catch (e) {
      message.error({ content: "Failed to download file", key: messageKey });
    }
  }

  async function downloadAttachmentFile(attachmentKey: string) {
    const messageKey = `downloading-${attachmentKey}`;
    message.loading({ content: `Downloading ${attachmentKey.split("/").pop()}`, key: messageKey, duration: 0 });
    try {
      const attachmentPublicUrl = await getS3File(attachmentKey);
      const fileBlob = (await axios.get(attachmentPublicUrl, { responseType: "blob" })).data;
      await downloadBlob({
        blob: fileBlob,
        fileName: attachmentKey.split("/").pop(),
      });
      message.destroy(messageKey);
    } catch (e) {
      message.error({ content: "Failed to download file", key: messageKey });
    }
  }

  async function onDownloadAllFilesClick() {
    await downloadSelectedSheetsInTask({
      taskId: activityItem.content.taskId,
      sheetIds: activityItem.content.sheetIds,
      users,
      organisationDetails,
      taskRevisionId: activityItem.content.taskRevisionId,
      attachments: activityItem.content.attachments,
    });

    await callGraphQLSimple({
      message: "Failed to record activity item",
      mutation: "createTaskActivityItem",
      variables: {
        input: {
          taskId: activityItem.content.taskId,
          content: JSON.stringify({
            type: "TASK_FILES_DOWNLOADED_ALL",
          }),
          organisation: organisationDetails.id,
          author: apiUser.id,
          type: "LIFECYCLE_EVENT",
        },
      },
    });
  }

  function displayFileList() {
    console.log("activityItem.content = ", activityItem.content);
    return (
      <div style={{ marginBottom: "1rem" }} key="extra-files">
        <Typography.Text className="files-included-title">Files included in this package:</Typography.Text>
        <div>
          {activityItem.content.publicUrls.map((publicUrlDetails) => {
            return (
              <div key={publicUrlDetails.url} style={{ marginTop: "0.5rem" }}>
                <Typography.Text>{publicUrlDetails.actualFileName}.pdf</Typography.Text>{" "}
                <Button
                  icon={<DownloadOutlined />}
                  style={{ marginLeft: "0.5rem" }}
                  onClick={() =>
                    downloadIndividualFile({
                      fileName: `${publicUrlDetails.actualFileName}.pdf`,
                      url: publicUrlDetails.url,
                    })
                  }
                />
              </div>
            );
          })}
          {activityItem.content.attachments?.map((attachment) => {
            return (
              <div key={attachment} style={{ marginTop: "0.5rem" }}>
                <Typography.Text>{attachment.split("/").pop()}</Typography.Text>
                <Button
                  icon={<DownloadOutlined />}
                  style={{ marginLeft: "0.5rem" }}
                  onClick={() => downloadAttachmentFile(attachment)}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  if (!task) {
    return null;
  }

  const taskRevisionDetails = task.revisions.items.find((x) => x.id === activityItem.content.taskRevisionId);

  if (!taskRevisionDetails) {
    return null;
  }

  let itemsToDisplayByFileType = {};

  for (let sheetId of activityItem.content.sheetIds) {
    let sheetDetails;
    let fileDetails;
    for (let file of taskRevisionDetails.files.items) {
      for (let sheet of file.sheets.items) {
        if (sheet.id === sheetId) {
          sheetDetails = sheet;
          fileDetails = file;
          break;
        }
      }
    }

    if (!sheetDetails) {
      continue;
    }

    if (!itemsToDisplayByFileType[fileDetails.type]) {
      itemsToDisplayByFileType[fileDetails.type] = [];
    }

    itemsToDisplayByFileType[fileDetails.type].push({
      fileDetails,
      sheetDetails,
    });
  }

  return (
    <Modal open={true} className="request-issued-task-files-modal" footer={null} onCancel={onClose}>
      <div>
        <Result
          status="success"
          title="Design package issued"
          subTitle={
            <>
              <Typography.Text>
                {getSimpleLabel("Request")} {request?.id} ({request?.title}) - {activityItem.content.formName}
              </Typography.Text>
            </>
          }
          extra={[
            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "10rem", margin: "0 auto" }}>
              {organisationDetails?.settings?.request?.allowExternalReviews && (
                <>
                  <Link to={`/requests/${activityItem.parentId}/review/${activityItem.id}`}>
                    <Button type="dark" icon={<EyeOutlined />}>
                      Review files
                    </Button>
                  </Link>
                  <span>or</span>
                </>
              )}
              <Button type="primary" icon={<DownloadOutlined />} onClick={onDownloadAllFilesClick}>
                Download all files
              </Button>
            </div>,
            displayFileList(),
          ]}
        />
      </div>

      {isAuthorised(["REQUESTS.EXECUTE"]) && (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography.Text>(the information below is not visible to the requester)</Typography.Text>
          <br />
          <br />
          <Typography.Text>Files issued:</Typography.Text>
          {Object.keys(itemsToDisplayByFileType)
            .sort((a, b) => (FILE_TYPES_ORDER[a] < FILE_TYPES_ORDER[b] ? -1 : 1))
            .map((fileType) => {
              const fileTypeDetails = FILE_TYPES_DETAILS[fileType];

              let content;

              if (fileTypeDetails.hasSheets) {
                content = (
                  <div className="files">
                    {itemsToDisplayByFileType[fileType].map(({ fileDetails, sheetDetails }) => {
                      return (
                        <div key={sheetDetails.id} className="file-item">
                          <Link
                            to={`/tasks/${activityItem.content.taskId}/${fileType}/${fileDetails.id}?tab=${sheetDetails.id}`}
                            target="_blank"
                          >
                            {fileDetails.name} - {sheetDetails.name}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                );
              } else {
                content = (
                  <div className="files">
                    {itemsToDisplayByFileType[fileType].map(({ fileDetails }) => {
                      return (
                        <div key={fileDetails.id} className="file-item">
                          <Link
                            to={`/tasks/${activityItem.content.taskId}/${fileType}/${fileDetails.id}`}
                            target="_blank"
                          >
                            {" "}
                            {fileDetails.name}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                );
              }

              return (
                <div key={fileType} className="file-type" style={{ marginBottom: "1rem" }}>
                  <h3>{FILE_TYPES_DETAILS[fileType].label} files</h3>
                  {content}
                </div>
              );
            })}
        </div>
      )}
    </Modal>
  );
}

export default withSubscriptions({
  Component: RequestIssuedTaskFilesModal,
  subscriptions: ["tasks"],
});
