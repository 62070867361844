import moment from "moment";
import getEndDateForTimelineBlock from "common/getEndDateForTimelineBlock";
import { processIdForDisplay } from "common/shared";
import { getDetailsForFormAndTaskRevision } from "common/sharedRequestHelpers";

const fields = [
  {
    id: "timelineBlockUserFullName",
    fieldTypes: ["textfield"],
    label: "User full name",
    value: ({ timelineBlock, users }) => {
      if (!timelineBlock) {
        return "";
      }
      let user = users.find((x) => x.id === timelineBlock.userId);
      if (!user) {
        return "";
      }
      return `${user.firstName ? `${user.firstName} ` : ""}${user.lastName || ""}`;
    },
  },
  {
    id: "timelineBlockClientName",
    fieldTypes: ["textfield"],
    label: "Client name",
    value: ({ timelineBlock }) => {
      if (!timelineBlock || !timelineBlock.client) {
        return "";
      }
      return timelineBlock.client.name;
    },
  },
  {
    id: "timelineBlockClientInitials",
    fieldTypes: ["textfield"],
    label: "Client initials",
    value: ({ timelineBlock }) => {
      if (!timelineBlock || !timelineBlock.client) {
        return "";
      }
      return timelineBlock.client.initials || "";
    },
  },
  {
    id: "timelineBlockProjectTitle",
    fieldTypes: ["textfield"],
    label: "Project title",
    value: ({ timelineBlock }) => {
      if (!timelineBlock || !timelineBlock.project) {
        return "";
      }
      return timelineBlock.project.title;
    },
  },
  {
    id: "timelineBlockProjectInitials",
    fieldTypes: ["textfield"],
    label: "Project initials",
    value: ({ timelineBlock }) => {
      if (!timelineBlock || !timelineBlock.project) {
        return "";
      }
      return timelineBlock.project.initials || "";
    },
  },
  {
    id: "timelineBlockProjectId",
    fieldTypes: ["textfield"],
    label: "Project ID",
    value: ({ timelineBlock }) => {
      if (!timelineBlock || !timelineBlock.project) {
        return "";
      }
      return timelineBlock.project.id;
    },
  },
  {
    id: "timelineBlockTaskTitle",
    fieldTypes: ["textfield"],
    label: "Task title",
    value: ({ timelineBlock }) => {
      if (!timelineBlock) {
        return "";
      }
      return timelineBlock.task?.title || timelineBlock.taskId;
    },
  },

  {
    id: "timelineBlockTaskInitials",
    fieldTypes: ["textfield"],
    label: "Task initials",
    value: ({ timelineBlock }) => {
      if (!timelineBlock) {
        return "";
      }
      return timelineBlock.task ? timelineBlock.task.initials : timelineBlock.taskId;
    },
  },

  {
    id: "timelineBlockTaskId",
    fieldTypes: ["textfield"],
    label: "Task ID",
    value: ({ timelineBlock }) => {
      if (!timelineBlock) {
        return "";
      }
      return timelineBlock.taskId;
    },
  },
  {
    id: "lastRequestID",
    fieldTypes: ["textfield"],
    label: "Last request ID",
    value: async ({ timelineBlock, activityItemsByRequest, tasks }) => {
      if (!tasks || !timelineBlock || !activityItemsByRequest) {
        return;
      }

      let task = tasks.find((x) => x.id === timelineBlock.taskId);
      let offset = 0;
      let requestFormActivityItem;
      if (!task || !task.revisions || !task.revisions.items) {
        return;
      }
      while (offset < task.revisions.items.length) {
        offset += 1;
        let taskRevision = task?.revisions?.items?.slice(-offset)[0];

        if (!taskRevision) {
          return;
        }

        requestFormActivityItem = getDetailsForFormAndTaskRevision({
          activityItemsByRequest: activityItemsByRequest,
          taskRevisionId: taskRevision.id,
        });
        if (requestFormActivityItem && requestFormActivityItem.content) {
          break;
        }
      }

      if (!requestFormActivityItem) {
        return;
      }

      return processIdForDisplay(requestFormActivityItem.parentId);
    },
  },
  {
    id: "lastTaskRevisionDueDate",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Last task revision in task - due date",
    value: ({ timelineBlock, tasks, dateFormat, addDays }) => {
      if (!tasks || !timelineBlock) {
        return;
      }

      let task = tasks.find((x) => x.id === timelineBlock.taskId);
      let taskRevision = task?.revisions?.items?.slice(-1)[0];

      if (!taskRevision) {
        return "";
      } else {
        if (!taskRevision.dueDate) {
          return "";
        } else {
          return moment(taskRevision.dueDate).add(addDays, "day").format(dateFormat);
        }
      }
    },
  },
  {
    id: "taskRevisionRequestedDate",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Last task revision in task - requested date",
    value: ({ timelineBlock, tasks, dateFormat, addDays }) => {
      if (!tasks || !timelineBlock) {
        return;
      }

      let task = tasks.find((x) => x.id === timelineBlock.taskId);
      let taskRevision = task?.revisions?.items?.slice(-1)[0];

      if (!taskRevision) {
        return "";
      } else {
        if (!taskRevision.requestedDate) {
          return "";
        } else {
          return moment(taskRevision.requestedDate).add(addDays, "day").format(dateFormat);
        }
      }
    },
  },
  {
    id: "timelineBlockStartDate",
    fieldTypes: ["textfield"],
    label: "Start date",
    isDate: true,
    value: ({ timelineBlock, dateFormat }) => {
      if (!timelineBlock) {
        return "";
      }
      return moment(timelineBlock.startDate).format(dateFormat);
    },
  },
  {
    id: "timelineBlockEndDate",
    fieldTypes: ["textfield"],
    label: "End date",
    isDate: true,
    value: ({ timelineBlock, dateFormat }) => {
      if (!timelineBlock) {
        return "";
      }

      return getEndDateForTimelineBlock({ timelineBlock, dateFormat });
    },
  },
  {
    id: "timelineBlockDurationHours",
    fieldTypes: ["textfield"],
    label: "Duration (hours)",
    isDate: true,
    value: ({ timelineBlock }) => {
      if (!timelineBlock) {
        return "";
      }
      return timelineBlock.durationHours || "";
    },
  },
];

export function getFields({ organisationDetails }) {
  let extraFields = [];
  if (organisationDetails?.customFields && organisationDetails.customFields.length > 0) {
    organisationDetails.customFields.forEach((fieldDefinition) => {
      extraFields.push({
        id: `timeline_customTaskField_${fieldDefinition.id}`,
        fieldTypes: ["textfield"],
        label: `Task - ${fieldDefinition.label}`,
        value: ({ timelineBlock }) => {
          if (!timelineBlock || !timelineBlock.task) {
            return "";
          }
          let task = timelineBlock.task;
          if (!task || !task.customFields) {
            return "";
          }
          let targetField = task.customFields.find((field) => field.id === fieldDefinition.id);
          if (!targetField) {
            return "";
          }
          return targetField.value;
        },
      });
    });
  }

  return [...fields, ...extraFields];
}

export const label = "Timeline block";
