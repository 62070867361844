import React from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

const sanitize_block = (block) => {
  if (typeof block === "string" || typeof block === "number") {
    return <Text>{block}</Text>;
  } else {
    return block;
  }
};

const tstyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
  },
  row: {
    flexDirection: "row",
    maxWidth: "95vw",
  },
  cell: {
    // padding: '3px'
  },
});

export const Table = ({ includeHeader = true, columns, data, style_function = () => {}, style = {} }) => {
  return (
    <View style={[tstyles.table, style]}>
      {includeHeader ? (
        <View key={0} style={tstyles.row} wrap={false}>
          {columns.map((cell, col_index) => (
            <View key={col_index} style={[tstyles.cell, style_function(0, col_index, data, data.length)]}>
              {sanitize_block(cell.title)}
            </View>
          ))}
        </View>
      ) : null}

      {data.map((row, rowIndex) => (
        <View key={rowIndex} style={tstyles.row} wrap={false}>
          {columns.map((_, colIndex) => (
            <View key={colIndex} style={[tstyles.cell, style_function(rowIndex + 1, colIndex, data, data.length)]}>
              {sanitize_block(row[columns[colIndex].dataIndex])}
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};
