import { Typography, Tag } from "antd";
import moment from "moment";
import { CheckCircleOutlined } from "@ant-design/icons";
import cx from "classnames";
import Avatar from "Avatar/Avatar";

import "./UserCard.scss";

export default function UserCard({ user }) {
  function displayLastSeen() {
    if (!user.lastOnlineAt) {
      return null;
    }

    const diff = moment().diff(user.lastOnlineAt, "minutes");
    if (diff < 2) {
      return (
        <Tag color="green">
          {" "}
          <CheckCircleOutlined /> Online
        </Tag>
      );
    }

    return <Tag color="#002f44">Last seen {moment(user.lastOnlineAt).fromNow()}</Tag>;
  }
  return (
    <div className={cx("user-card", { disabled: user.isDisabled })}>
      <Avatar user={user} disableUserCard size="large" />

      <Typography.Text className="name">
        {user.firstName} {user.lastName}
      </Typography.Text>
      <Typography.Text className="email">{user.id}</Typography.Text>
      {displayLastSeen()}
      {user.isHidden && <Tag color="#002f44">Hidden</Tag>}

      {user.position ? <Typography.Text className="position">{user.position}</Typography.Text> : null}

      {user.isDisabled ? (
        <Tag color="#cedce2" className="tag-disabled">
          Disabled
        </Tag>
      ) : null}
    </div>
  );
}
