import { Modal } from "antd";

export async function removeUserFromGroup(group, user) {
  Modal.confirm({
    title: "Confirm group membership removal",
    content: (
      <>
        Are you sure you want to remove{" "}
        <b>
          {user.firstName} {user.lastName}
        </b>{" "}
        from <b>{group.name}?</b>
      </>
    ),
    onOk: async () =>
      await window.callGraphQLSimple({
        message: "Could not remove user from group",
        mutation: "updateGroup",
        variables: {
          input: {
            id: group.id,
            members: group?.members?.filter((member) => member !== user.id),
          },
        },
      }),
  });
}
