import React, { MouseEvent } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Typography, Button } from "antd";
import { Link } from "react-router-dom";
import query from "query-string";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { getLabel } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { isAuthorised } from "common/permissions";
import { platformSupportsLink } from "common/link";

import Card from "Card/Card";
import Avatar from "Avatar/Avatar";
import Signature from "Signature/Signature";
import ConfigureComputer from "./ConfigureComputer/ConfigureComputer";
import UserComputers from "./UserComputers/UserComputers";
import Input from "Input/Input";
import InfoItem from "InfoItem/InfoItem";
import CatLevelPicker from "CatLevelPicker/CatLevelPicker";
import NotificationSettings from "./NotificationSettings/NotificationSettings";
import ExperimentalFeatures from "./ExperimentalFeatures/ExperimentalFeatures";
import CatLevelModal from "Modals/CatLevelModal/CatLevelModal";
import UploadAvatarModal from "../Modals/UploadAvatarModal/UploadAvatarModal";
import UploadSignatureModal from "../Modals/UploadSignatureModal/UploadSignatureModal";
import TimeOffCard from "./TimeOffCard/TimeOffCard";
import Tabs from "reusableComponents/Tabs/Tabs";

import { PageProps, RouterProps, SubscriptionProps } from "common/props";

import "./AccountPage.scss";

import { Task, User, Holiday } from "common/types";

type Props = {
  organisationDetails: any;
  onClick: (e: MouseEvent) => void;
  deviceIsInitialised: boolean;
  tasks: Task[];
  users: User[];
  isOwnAccountPage?: boolean;
  match: any;
  holidays: Holiday[];
};

type State = {
  isUploadAvatarModalVisible: boolean;
  isDesignCatLevelModalVisible: boolean;
  isReviewCatLevelModalVisible: boolean;
  isCheckCatLevelModalVisible: boolean;
  isUploadSignatureModalVisible: boolean;
  activeTab: string | undefined;
};

export class AccountPage extends React.Component<Props & PageProps & SubscriptionProps & RouterProps> {
  state: State = {
    isUploadAvatarModalVisible: false,
    isDesignCatLevelModalVisible: false,
    isReviewCatLevelModalVisible: false,
    isCheckCatLevelModalVisible: false,
    isUploadSignatureModalVisible: false,
    activeTab: undefined,
  };

  componentDidMount() {
    callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "ACCOUNT",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });

    const queryString = query.parse(this.props.location.search);
    const activeTab = queryString.tab;

    if (activeTab) {
      this.setState({ activeTab });
    }
  }

  openAvatarUserModal = () => {
    this.setState({ isUploadAvatarModalVisible: true });
  };

  openSignatureUserModal = () => {
    this.setState({ isUploadSignatureModalVisible: true });
  };

  changeAttribute = async ({ key, value }) => {
    const userDetails = this.getUserDetails();
    await callGraphQLSimple({
      message: "Failed to update account details",
      queryName: "updateUser",
      variables: {
        input: {
          id: userDetails.id,
          [key]: value,
        },
      },
    });
  };

  getUserDetails = () => {
    const { apiUser, users, match } = this.props;
    const userId = match.params.userId;

    const userDetails = this.getIsOwnAccountPage() ? apiUser : users.find((user) => user.id === userId);
    return userDetails;
  };

  getIsOwnAccountPage = () => {
    const { apiUser, match } = this.props;
    const userId = match.params.userId;
    return this.props.isOwnAccountPage || userId === apiUser.id;
  };

  render() {
    const { activeTab } = this.state;
    const { deviceIsInitialised, apiUser, organisationDetails } = this.props;
    const userDetails = this.getUserDetails();
    let isOwnAccountPage = this.getIsOwnAccountPage();
    const basePath = `/users/${userDetails.id}`;

    const supportsDraughtHubLink = platformSupportsLink();

    let isAuthorityPickerEnabled = isOwnAccountPage
      ? isAuthorised(["USERS.EDIT_OWN_ENGINEERING_AUTHORITY_LEVELS"])
      : isAuthorised(["USERS.EDIT_OTHERS_ENGINEERING_AUTHORITY_LEVELS"]);

    if (!userDetails) {
      return <p>User does not exist</p>;
    }

    return (
      <div className="account-page">
        <Card
          title={
            <>
              <Typography.Text className="title-message">Account page for </Typography.Text>
              <Avatar user={userDetails} showLabel />
            </>
          }
          noDivider
          flexTitle
          className="account-page-title-card"
        ></Card>

        <Tabs
          className="task-main-tabs"
          activeTab={activeTab}
          onTabClick={(tabKey) => {
            this.props.history.push(`${basePath}?tab=${tabKey}`);
            this.setState({ activeTab: tabKey });
          }}
          items={[
            {
              id: "account-details",
              title: "Account details",
              content: (
                <Card title="Account details" withSpace>
                  <div className="account-details" data-cy="account-details-card">
                    <div className="column">
                      <InfoItem
                        label="Avatar"
                        value={
                          <>
                            <div className="avatar-container">
                              <Avatar user={userDetails} size="large" showHoverAnimation={false} />
                            </div>
                            {(isAuthorised(["USERS.SEE_OTHERS_ACCOUNT_PAGE"]) || isOwnAccountPage) && (
                              <Button
                                style={{ marginTop: "0.5rem" }}
                                icon={<UploadOutlined />}
                                type="primary"
                                onClick={this.openAvatarUserModal}
                              >
                                Upload avatar
                              </Button>
                            )}
                          </>
                        }
                      />
                      <InfoItem
                        label="First name"
                        value={
                          <Input
                            fullWidth
                            data-cy="profile-first-name"
                            defaultValue={userDetails.firstName}
                            disabled={!isOwnAccountPage && !isAuthorised(["USERS.EDIT_OTHER_USERS_DETAILS"])}
                            onChange={(value) => this.changeAttribute({ key: "firstName", value })}
                            showBorder
                          />
                        }
                      />
                      <InfoItem
                        label="Last name"
                        value={
                          <Input
                            fullWidth
                            data-cy="profile-last-name"
                            defaultValue={userDetails.lastName}
                            disabled={!isOwnAccountPage && !isAuthorised(["USERS.EDIT_OTHER_USERS_DETAILS"])}
                            onChange={(value) => this.changeAttribute({ key: "lastName", value })}
                            showBorder
                          />
                        }
                      />
                      <InfoItem
                        label="Email"
                        value={
                          <Input fullWidth data-cy="profile-email" defaultValue={userDetails.id} disabled showBorder />
                        }
                      />
                      {(isOwnAccountPage || isAuthorised(["USERS.EDIT_OTHER_USERS_DETAILS"])) && (
                        <InfoItem
                          label="Signature"
                          value={<Signature user={userDetails} onEdit={this.openSignatureUserModal} />}
                        />
                      )}
                      {isOwnAccountPage && (
                        <InfoItem
                          label="Password"
                          value={
                            <Link to="/change-password" key="change-password">
                              <Button type="primary" icon={<EditOutlined />}>
                                Change password
                              </Button>
                            </Link>
                          }
                        />
                      )}
                      <InfoItem
                        label="Account created"
                        value={<Typography.Text> {moment(userDetails.createdAt).format("DD-MM-YYYY")}</Typography.Text>}
                      />
                    </div>
                    <div className="column">
                      <InfoItem
                        label="Job Title"
                        value={
                          <Input
                            fullWidth
                            showBorder
                            disabled={!isOwnAccountPage && !isAuthorised(["USERS.EDIT_OTHER_USERS_DETAILS"])}
                            defaultValue={userDetails.position}
                            onChange={(value) => {
                              this.changeAttribute({ key: "position", value });
                            }}
                          />
                        }
                      />
                      <InfoItem
                        label="Qualifications"
                        value={
                          <Input
                            fullWidth
                            showBorder
                            disabled={!isOwnAccountPage && !isAuthorised(["USERS.EDIT_OTHER_USERS_DETAILS"])}
                            defaultValue={userDetails.qualifications}
                            onChange={(value) => {
                              this.changeAttribute({
                                key: "qualifications",
                                value,
                              });
                            }}
                          />
                        }
                      />

                      <InfoItem
                        label={getLabel({
                          organisationDetails,
                          id: "phone-1",
                          defaultValue: "Phone 1",
                        })}
                        value={
                          <Input
                            fullWidth
                            showBorder
                            disabled={!isOwnAccountPage && !isAuthorised(["USERS.EDIT_OTHER_USERS_DETAILS"])}
                            defaultValue={userDetails.phone1}
                            onChange={(value) => {
                              this.changeAttribute({ key: "phone1", value });
                            }}
                          />
                        }
                      />

                      <InfoItem
                        label={getLabel({
                          organisationDetails,
                          id: "phone-2",
                          defaultValue: "Phone 2",
                        })}
                        value={
                          <Input
                            fullWidth
                            showBorder
                            disabled={!isOwnAccountPage && !isAuthorised(["USERS.EDIT_OTHER_USERS_DETAILS"])}
                            defaultValue={userDetails.phone2}
                            onChange={(value) => {
                              this.changeAttribute({ key: "phone2", value });
                            }}
                          />
                        }
                      />

                      <InfoItem
                        label="Office"
                        value={
                          <Input
                            fullWidth
                            showBorder
                            disabled={!isOwnAccountPage && !isAuthorised(["USERS.EDIT_OTHER_USERS_DETAILS"])}
                            defaultValue={userDetails.office}
                            onChange={(value) => {
                              this.changeAttribute({ key: "office", value });
                            }}
                          />
                        }
                      />

                      <InfoItem
                        label="Department"
                        value={
                          <Input
                            fullWidth
                            showBorder
                            disabled={!isOwnAccountPage && !isAuthorised(["USERS.EDIT_OTHER_USERS_DETAILS"])}
                            defaultValue={userDetails.department}
                            onChange={(value) => {
                              this.changeAttribute({ key: "department", value });
                            }}
                          />
                        }
                      />

                      <InfoItem
                        label="Team"
                        value={
                          <Input
                            fullWidth
                            showBorder
                            disabled={!isOwnAccountPage && !isAuthorised(["USERS.EDIT_OTHER_USERS_DETAILS"])}
                            defaultValue={userDetails.team}
                            onChange={(value) => {
                              this.changeAttribute({ key: "team", value });
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                </Card>
              ),
            },
            organisationDetails.settings?.general?.usesTimeOff &&
              (isOwnAccountPage || isAuthorised(["TIME_OFF.MANAGE"])) && {
                id: "holidays",
                title: "Holidays",
                content: <TimeOffCard isSick={false} userDetails={userDetails} key="holidays" />,
              },
            organisationDetails.settings?.general?.usesTimeOff &&
              (isOwnAccountPage || isAuthorised(["TIME_OFF.MANAGE"])) && {
                id: "sickDays",
                title: "Sick days",
                content: <TimeOffCard isSick={true} userDetails={userDetails} key="sick" />,
              },
            (organisationDetails.usesDesignAuthority || organisationDetails.usesReviewAuthority) && {
              id: "authority",
              title: "Authority",
              content: (
                <Card withSpace title="Authority settings">
                  <div className="account-details">
                    <div className="column">
                      {organisationDetails.usesDesignAuthority && (
                        <InfoItem
                          label="Can design"
                          value={
                            <CatLevelPicker
                              disabled={!isAuthorityPickerEnabled}
                              organisationDetails={organisationDetails}
                              defaultValue={userDetails.catLevelDesign}
                              onChange={(value) =>
                                this.changeAttribute({
                                  key: "catLevelDesign",
                                  value,
                                })
                              }
                            />
                          }
                        />
                      )}

                      {organisationDetails.usesReviewAuthority && (
                        <InfoItem
                          label="Check authority"
                          value={
                            <CatLevelPicker
                              disabled={!isAuthorityPickerEnabled}
                              organisationDetails={organisationDetails}
                              defaultValue={userDetails.catLevelIssue}
                              onChange={(value) =>
                                this.changeAttribute({
                                  key: "catLevelIssue",
                                  value,
                                })
                              }
                            />
                          }
                        />
                      )}
                    </div>
                  </div>
                </Card>
              ),
            },
            (isOwnAccountPage || isAuthorised(["USERS.SEE_OTHERS_ACCOUNT_PAGE"])) && {
              id: "notifications",
              title: "Notifications",
              content: (
                <NotificationSettings
                  user={userDetails}
                  disabled={!isOwnAccountPage && !isAuthorised(["USERS.EDIT_OTHER_USERS_DETAILS"])}
                />
              ),
            },
            supportsDraughtHubLink &&
              isOwnAccountPage &&
              organisationDetails.usesFiles &&
              !deviceIsInitialised && {
                id: "draughthub-link",
                title: "DraughtHub Link",
                content: <ConfigureComputer apiUser={apiUser} organisationDetails={organisationDetails} />,
              },
            {
              id: "computers",
              title: "Computers",
              content: <UserComputers user={userDetails} />,
            },
            (isOwnAccountPage || isAuthorised(["USERS.SEE_OTHERS_ACCOUNT_PAGE"])) && {
              id: "experimental-features",
              title: "Experimental features",
              content: <ExperimentalFeatures userDetails={userDetails} />,
            },
          ].filter((x) => x)}
        />

        {this.state.isUploadAvatarModalVisible ? (
          <UploadAvatarModal user={userDetails} onClose={() => this.setState({ isUploadAvatarModalVisible: false })} />
        ) : null}
        {this.state.isUploadSignatureModalVisible ? (
          <UploadSignatureModal
            user={userDetails}
            onClose={() => this.setState({ isUploadSignatureModalVisible: false })}
          />
        ) : null}

        {this.state.isDesignCatLevelModalVisible && (
          <CatLevelModal
            key={`user-design-cat-level-modal`}
            visible={this.state.isDesignCatLevelModalVisible}
            onClose={() => this.setState({ isDesignCatLevelModalVisible: false })}
            user={userDetails}
            authorityType="design"
            organisationDetails={organisationDetails}
          />
        )}
        {this.state.isReviewCatLevelModalVisible && (
          <CatLevelModal
            key={`user-review-cat-level-modal`}
            visible={this.state.isReviewCatLevelModalVisible}
            onClose={() => this.setState({ isReviewCatLevelModalVisible: false })}
            user={userDetails}
            authorityType="issue"
            organisationDetails={organisationDetails}
          />
        )}
        {this.state.isCheckCatLevelModalVisible && (
          <CatLevelModal
            key={`user-check-cat-level-modal`}
            visible={this.state.isCheckCatLevelModalVisible}
            onClose={() => this.setState({ isCheckCatLevelModalVisible: false })}
            user={userDetails}
            authorityType="check"
            organisationDetails={organisationDetails}
          />
        )}
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: AccountPage,
    subscriptions: ["organisationDetails", "tasks", "users", "holidaysByUser"],
  })
);
