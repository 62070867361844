import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import { Typography, Button, Tag, Modal, message } from "antd";
import { LineChartOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import cx from "classnames";

import { roundToQuarter } from "common/mathHelpers";
import { TIMESHEET_HOUR_HEIGHT } from "common/constants";
import withSubscriptions from "common/withSubscriptions";
import { isAuthorised } from "common/permissions";
import { getFilteredTasks } from "common/filterHelpers";
import { callGraphQLSimple } from "common/apiHelpers";

import OverallSpinner from "OverallSpinner/OverallSpinner";
import TaskFilters from "TaskFilters/TaskFilters";
import UsersFilter from "UsersFilter/UsersFilter";
import AccessedTaskList from "./AccessedTaskList/AccessedTaskList";
import TaskDetailsModal from "Modals/TaskDetailsModal/TaskDetailsModal";
import TimesheetCanvas from "./TimesheetCanvas/TimesheetCanvas";
import PlannedActivityCanvas from "./PlannedActivityCanvas/PlannedActivityCanvas";
import TargetDate from "./TargetDate/TargetDate";
import DailyLog from "./DailyLog/DailyLog";
import DatePicker from "DatePicker/DatePicker";
import RecordingSymbol from "reusableComponents/RecordingSymbol/RecordingSymbol";

import "./UserTimesheetPage.scss";

const snapCoefficientHoursEdit = 0.5;
const snapCoefficientHoursCreate = 1;

export function UserTimesheetPage({
  apiUser,
  tasks,
  projects,
  clients,
  users,
  organisationDetails,
  setBackground,
  setBoxedLayout,
  windowWidth,
  fetchAndSetTimesheetBlocks,
  timesheetBlocks: timesheetBlocksFromApi,
  match,
  history,
  setProps,
  context,
}) {
  let targetDateInUrl = match.params.date;
  const [targetDate, setTargetDate] = useState(moment(targetDateInUrl));
  const [filter, setFilter] = useState({});
  const [auditItems, setAuditItems] = useState();
  const [accessedTasks, setAccessedTasks] = useState();
  const [isTaskDetailsModalVisible, setIsTaskDetailsModalVisible] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(undefined);

  let timesheetBlocks = timesheetBlocksFromApi.filter((timesheetBlock) => {
    // extract the ones that start on the same day or are recording
    return moment(timesheetBlock.startAt).isSame(targetDate, "day") || timesheetBlock.isRecording;
  });

  let targetUserId = match.params.userId || apiUser.id;
  let targetUserDetails = users.find((user) => user.id === targetUserId);
  let viewerIsOwner = isAuthorised(["USER_TIMESHEET.EDIT"]) || apiUser.id === targetUserId; //|| window.location.hostname === 'localhost';
  const canvasRef = useRef();

  let allBlocksAreApproved =
    organisationDetails?.settings?.timesheet?.usesReview &&
    timesheetBlocks?.length > 0 &&
    timesheetBlocks.every((block) => block.reviewStatus === "APPROVED");
  let someBlocksAreApproved =
    organisationDetails?.settings?.timesheet?.usesReview &&
    timesheetBlocks?.length > 0 &&
    timesheetBlocks.some((block) => block.reviewStatus === "APPROVED");
  let allBlocksAreRejected =
    organisationDetails?.settings?.timesheet?.usesReview &&
    timesheetBlocks?.length > 0 &&
    timesheetBlocks.every((block) => block.reviewStatus === "REJECTED");

  useEffect(() => {
    setBackground(false);
    setBoxedLayout(false);
    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "USER_TIMESHEET",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
    autoScrollToStartOfDay();

    return () => {
      setBackground(true);
      setBoxedLayout(true);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    fetchAuditItems();
    fetchAndSetTimesheetBlocks({
      organisation: apiUser.organisation,
      // startAt: targetDate.startOf("day").toISOString(),
      startAt: targetDate.clone().startOf("day").toISOString(),
      endAt: targetDate.clone().endOf("day").toISOString(),
      userId: targetUserId,
    });
  }, [targetDate]); // eslint-disable-line

  function autoScrollToStartOfDay() {
    if (!canvasRef.current) {
      setTimeout(autoScrollToStartOfDay, 100);
      return;
    }
    canvasRef.current.scrollTop = TIMESHEET_HOUR_HEIGHT * 8;
  }

  async function fetchAuditItems() {
    let auditItems;
    if (!viewerIsOwner) {
      auditItems = [];
    } else {
      auditItems = (
        await window.callGraphQLSimple({
          message: "Failed to fetch event log for this user",
          query: "listAuditItemsByUser",
          variables: {
            limit: 1000,
            userId: targetUserId,
            createdAt: {
              between: [targetDate.startOf("day").toISOString(), targetDate.endOf("day").toISOString()],
            },
          },
        })
      ).data.listAuditItemsByUser.items;
    }

    let taskIds = new Set();
    auditItems.forEach((auditItem) => {
      if (auditItem.taskId && auditItem.taskId !== "nothing") {
        taskIds.add(auditItem.taskId);
      }
    });
    setAuditItems(auditItems);
    setAccessedTasks(tasks.filter((task) => taskIds.has(task.id)));
  }

  function confirmSubmitReview(reviewStatus) {
    let messageContent = reviewStatus === "APPROVED" ? "lock" : "unlock";
    let okButtonText = reviewStatus === "APPROVED" ? "Lock" : "Unlock";
    Modal.confirm({
      title: `Are you sure you want to ${messageContent} this timesheet?`,
      onOk: () => submitReview(reviewStatus),
      okText: `${okButtonText} timesheet`,
    });
  }

  async function submitReview(reviewStatus) {
    let promises = [];
    for (let i = 0; i < timesheetBlocks.length; i++) {
      let block = timesheetBlocks[i];
      promises.push(
        callGraphQLSimple({
          mutation: "updateTimesheetBlock",
          variables: {
            input: {
              id: block.id,
              reviewStatus,
            },
          },
        })
      );
      await new Promise((resolve) => setTimeout(resolve, 150));
    }
    try {
      await Promise.all(promises);
    } catch (e) {
      message.error("Failed to submit review");
      let undoPromises = [];
      for (let i = 0; i < timesheetBlocks.length; i++) {
        let block = timesheetBlocks[i];
        undoPromises.push(
          callGraphQLSimple({
            mutation: "updateTimesheetBlock",
            variables: {
              input: {
                id: block.id,
                reviewStatus,
              },
            },
          })
        );
        await new Promise((resolve) => setTimeout(resolve, 150));
      }
      try {
        await Promise.all(undoPromises);
      } catch (e) {
        // nothing we can do here
      }
    }
  }

  function displayReviewContent() {
    let tag;
    let button;
    if (allBlocksAreApproved) {
      tag = (
        <Tag color="blue">
          <LockOutlined /> Timesheet locked
        </Tag>
      );
      if (isAuthorised(["USER_TIMESHEET.REVIEW"])) {
        button = (
          <div style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
            <Button icon={<UnlockOutlined />} onClick={() => confirmSubmitReview(null)}>
              Unlock
            </Button>
          </div>
        );
      }
    } else if (allBlocksAreRejected) {
      tag = <Tag color="red">Timesheet rejected</Tag>;
    } else {
      if (timesheetBlocks.length === 0) {
        // tag = <Tag color="gray">No timesheet blocks</Tag>;
      } else {
        if (someBlocksAreApproved) {
          tag = <Tag color="orange">Partially locked</Tag>;
        }
        if (isAuthorised(["USER_TIMESHEET.REVIEW"])) {
          button = (
            <div style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
              <Button type="primary" icon={<LockOutlined />} onClick={() => confirmSubmitReview("APPROVED")}>
                Lock
              </Button>
              {/* <Button onClick={() => confirmSubmitReview("REJECTED")}>Reject</Button> */}
            </div>
          );
        }
      }
    }

    return (
      <Typography.Text className="review-status">
        {tag}
        {button}
      </Typography.Text>
    );
  }

  if (!auditItems || !accessedTasks) {
    return <OverallSpinner />;
  }

  let filteredTasks = getFilteredTasks({ projects, clients, tasks, filter });
  let filteredAccessedTasks = getFilteredTasks({
    projects,
    clients,
    tasks: accessedTasks,
    filter,
  });
  const hours = getHours();

  let billableHours = 0;
  let nonBillableHours = 0;

  for (let block of timesheetBlocks) {
    let endAt = block.endAt;
    if (block.isRecording) {
      endAt = moment().toISOString();
    }
    let durationHours = moment(endAt).diff(moment(block.startAt), "hours", true);
    if (block.billable) {
      billableHours += roundToQuarter(durationHours);
    } else {
      nonBillableHours += roundToQuarter(durationHours);
    }
  }
  billableHours = roundToQuarter(billableHours);
  nonBillableHours = roundToQuarter(nonBillableHours);

  let usesTimesheetReview = organisationDetails.settings?.timesheet.usesReview;

  let isUsingClockInClockOut = isAuthorised(["USER_TIMESHEET.USE_CLOCK_IN_OUT"], undefined, true);
  let targetUserIsUsingClockInClockOut = isAuthorised(["USER_TIMESHEET.USE_CLOCK_IN_OUT"], targetUserDetails, true);

  if (isUsingClockInClockOut) {
    return (
      <div className="user-timesheet-page">
        <TimesheetCanvas
          hours={hours}
          setPropsForPage={setProps}
          context={context}
          targetDate={targetDate}
          users={users}
          tasks={tasks}
          projects={projects}
          clients={clients}
          targetUserId={targetUserId}
          organisationDetails={organisationDetails}
          snapCoefficientHoursEdit={snapCoefficientHoursEdit}
          snapCoefficientHoursCreate={snapCoefficientHoursCreate}
          timesheetBlocks={timesheetBlocks}
          viewerIsOwner={viewerIsOwner}
          allBlocksAreApproved={allBlocksAreApproved}
          isUsingClockInClockOut={isUsingClockInClockOut}
        />
      </div>
    );
  }

  let clockedInMessage = null;

  if (targetUserIsUsingClockInClockOut) {
    let isClockedIn = timesheetBlocks.some((block) => block.isRecording);
    clockedInMessage = isClockedIn ? (
      <Tag className="accent-tag" style={{ marginLeft: "1rem" }}>
        <RecordingSymbol white />
        Clocked in
      </Tag>
    ) : (
      <Tag color="#002f44" style={{ marginLeft: "1rem" }}>
        Clocked out
      </Tag>
    );
  }

  return (
    <div className="user-timesheet-page">
      <div className="filter-bar">
        <TaskFilters
          onChange={(filter) => setFilter(filter)}
          windowWidth={windowWidth}
          includeCreateTask={false}
          previousFilter={filter}
          cookieName="task-filters-user-timesheet-page"
          avatarListWidthToSubtract={160 + 100 + 260 + 220 + 300 + 135 + 100}
        />

        <TargetDate targetDate={targetDate} setTargetDate={setTargetDate} />

        <DatePicker
          className=""
          value={targetDate}
          onChange={(date) => setTargetDate(date)}
          format="dddd, DD MMM YYYY"
          allowClear={false}
        />
        {isAuthorised(["USER_TIMESHEET.VIEW_OTHERS_TIMESHEETS"]) && (
          <UsersFilter
            includeUnassigned={false}
            activateOnHover={false}
            value={targetUserId}
            onChange={(userId) => {
              history.push(`/user-timesheet/${userId}`);
            }}
          />
        )}
        {isAuthorised(["USER_TIMESHEET.VIEW_OWN_TIMESHEET_OVERVIEW"]) && (
          <Link to="/timesheets" className="timesheets-dashboard-link">
            <Button icon={<LineChartOutlined />}>See overview</Button>
          </Link>
        )}
      </div>
      <div className="content">
        <AccessedTaskList
          accessedTasks={filteredAccessedTasks}
          tasks={filteredTasks}
          organisationDetails={organisationDetails}
          viewerIsOwner={viewerIsOwner}
          onOpenTask={(taskId) => {
            setSelectedTaskId(taskId);
            setIsTaskDetailsModalVisible(true);
          }}
        />
        <div className="timesheet-container">
          <div className="canvas" ref={canvasRef}>
            <div className={cx("header", { "uses-review": usesTimesheetReview })}>
              <Typography.Text className="hours-header">Time</Typography.Text>
              <Typography.Text className="recorded-header">
                <span>
                  {clockedInMessage}Hours worked <br />({billableHours} billable, {nonBillableHours} non-billable)
                </span>
                {usesTimesheetReview && displayReviewContent()}
              </Typography.Text>
              <Typography.Text className="planned-header">Planned activity</Typography.Text>
            </div>
            <div className="scrollable-container">
              <div className="hours">
                {hours.map((hour, i) => (
                  <div className="hour-container" key={i}>
                    {i < 23 && (
                      <Typography.Text key={hour} className="label">
                        {hour}
                      </Typography.Text>
                    )}
                  </div>
                ))}
              </div>
              <div className="recorded">
                <div
                  className="lunch-break"
                  style={{
                    top: TIMESHEET_HOUR_HEIGHT * 12 + 1,
                    height: TIMESHEET_HOUR_HEIGHT - 1,
                  }}
                />
                <TimesheetCanvas
                  hours={hours}
                  setPropsForPage={setProps}
                  context={context}
                  targetDate={targetDate}
                  users={users}
                  tasks={tasks}
                  projects={projects}
                  clients={clients}
                  targetUserId={targetUserId}
                  organisationDetails={organisationDetails}
                  snapCoefficientHoursEdit={snapCoefficientHoursEdit}
                  snapCoefficientHoursCreate={snapCoefficientHoursCreate}
                  timesheetBlocks={timesheetBlocks}
                  viewerIsOwner={viewerIsOwner}
                  allBlocksAreApproved={allBlocksAreApproved}
                />
              </div>
              <div className="planned">
                <div
                  className="lunch-break"
                  style={{
                    top: TIMESHEET_HOUR_HEIGHT * 12 + 1,
                    height: TIMESHEET_HOUR_HEIGHT - 1,
                  }}
                />
                <PlannedActivityCanvas
                  targetDate={targetDate}
                  users={users}
                  tasks={tasks}
                  targetUserId={targetUserId}
                  projects={projects}
                  clients={clients}
                />
              </div>
            </div>
          </div>
        </div>
        <DailyLog
          targetDate={targetDate}
          auditItems={auditItems}
          organisationDetails={organisationDetails}
          tasks={tasks}
          projects={projects}
          clients={clients}
          users={users}
          viewerIsOwner={viewerIsOwner}
          windowWidth={windowWidth}
        />
        {isTaskDetailsModalVisible && selectedTaskId && (
          <TaskDetailsModal
            taskId={selectedTaskId}
            onClose={() => {
              setIsTaskDetailsModalVisible(false);
              setSelectedTaskId(undefined);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: UserTimesheetPage,
    subscriptions: ["users", "tasks", "projects", "clients", "organisationDetails", "timesheetBlocks"],
  })
);

function getHours() {
  let time = moment().startOf("day").add("1", "hour");
  let hours = [];
  for (let i = 1; i <= 24; i++) {
    hours.push(time.format("HH:mm"));
    time.add(1, "hour");
  }
  return hours;
}
