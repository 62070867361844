import { useEffect, useRef } from "react";
import { useGetSetState } from "react-use";

interface Props {
  apiUser: any;
  recordAnalyticsEvent: () => void;
}

interface State {
  lastClickAt: number;
  isWindowFocused: boolean;
}

const NO_CLICK_CONSIDER_OFFLINE_AFTER = 1000 * 60 * 2; // 2 minutes
const RECORD_INTERVAL = 1000 * 60; // every minute

export default function OnlineStatusRecorder({ apiUser, recordAnalyticsEvent }: Props) {
  const [getState, setState] = useGetSetState<State>({
    lastClickAt: Date.now(),
    isWindowFocused: true,
  });
  const recordIntervalRef = useRef<any>(null);

  useEffect(() => {
    record();
    recordIntervalRef.current = setInterval(record, RECORD_INTERVAL);
    window.addEventListener("click", onWindowClick);
    window.addEventListener("focus", onWindowFocus);
    window.addEventListener("blur", onWindowBlur);

    return () => {
      window.removeEventListener("click", onWindowClick);
      window.removeEventListener("focus", onWindowFocus);
      window.removeEventListener("blur", onWindowBlur);
      if (recordIntervalRef.current) {
        clearInterval(recordIntervalRef.current);
      }
    };
  }, []);

  function onWindowFocus() {
    setState({ isWindowFocused: true });
  }

  function onWindowBlur() {
    setState({ isWindowFocused: false });
  }

  function onWindowClick() {
    setState({ lastClickAt: Date.now() });
  }

  async function record() {
    const now = Date.now();
    const { lastClickAt, isWindowFocused } = getState();

    // we don't want to record online status if the window is not focused
    if (!isWindowFocused) {
      return;
    }

    // we don't want to record online status if the user is not active
    if (now - lastClickAt >= NO_CLICK_CONSIDER_OFFLINE_AFTER) {
      return;
    }

    await window.callGraphQLSimple({
      displayError: false,
      mutation: "updateUser",
      variables: {
        input: {
          id: apiUser.id,
          lastOnlineAt: new Date().toISOString(),
        },
      },
    });

    recordAnalyticsEvent();
  }

  return null;
}
