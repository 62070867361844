import { useState } from "react";
import { Form, Modal, Button, Space, Select } from "antd";

import { callGraphQLSimple } from "common/apiHelpers";
import { QUOTE_HIDDEN_LINE_ITEM_COLUMN_OPTIONS } from "common/constants";
import withSubscriptions from "common/withSubscriptions";

import "./CreateHiddenLineItemColumnModal.scss";

function CreateHiddenLineItemColumnsModal({ onClose, visible, apiUser, organisationDetails }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ value }) {
    setIsLoading(true);

    await callGraphQLSimple({
      message: "Could not create geographical location",
      queryName: "updateOrganisation",
      variables: {
        input: {
          id: apiUser.organisation,
          settings: {
            ...organisationDetails?.settings,
            quote: {
              ...(organisationDetails.settings?.quote || {}),
              hiddenLineItemColumns: [...(organisationDetails.settings?.quote?.hiddenLineItemColumns || []), value],
            },
          },
        },
      },
    });

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  return (
    <Modal
      maskClosable={false}
      title="Create hidden line item column"
      open={visible}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-hidden-line-item-column-modal"
    >
      <Form form={form} onFinish={onSubmit}>
        <Space direction="vertical" className="spread">
          <Form.Item
            label="Field"
            name="value"
            rules={[
              {
                required: true,
                message: "You must choose a field to hide",
              },
            ]}
          >
            <Select data-cy="hidden-line-item-column-name-dropdown">
              {QUOTE_HIDDEN_LINE_ITEM_COLUMN_OPTIONS.map((option) => {
                return (
                  <Select.Option
                    key={option.value}
                    value={option.value}
                    disabled={organisationDetails.settings?.quote?.hiddenLineItemColumns?.includes(option.value)}
                  >
                    {option.label}{" "}
                    {organisationDetails.settings?.quote?.hiddenLineItemColumns?.includes(option.value)
                      ? "(already hidden)"
                      : ""}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <div className="submit-container">
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              data-cy="hidden-line-item-column-submit-button"
            >
              Submit
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}

export default withSubscriptions({
  Component: CreateHiddenLineItemColumnsModal,
  subscriptions: ["organisationDetails"],
});
