export default function getAllPermissionsForUser(userDetails) {
  const groups = window?.groups;
  const groupPermissions =
    groups
      ?.filter((group) => group?.members?.includes(userDetails?.id))
      .reduce((result, group) => {
        return [...(result || []), ...(group?.permissions || [])];
      }, []) || [];
  const userPermissions = userDetails?.permissions || [];

  const allPermissions = [...groupPermissions, ...userPermissions];

  const realPermissions = allPermissions.filter((x) => x);
  return realPermissions;
}
