import { useState } from "react";
import { Modal, Button, message, Result } from "antd";
import { withRouter } from "react-router-dom";
import { callRest } from "common/apiHelpers";
import OverallSpinner from "OverallSpinner/OverallSpinner";

import Card from "Card/Card";

import "./RequestFormExternalReviewPage.scss";

export function RequestFormExternalReviewPage({ data }) {
  const [reviewStatus, setReviewStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function onApprove() {
    Modal.confirm({
      title: "Approve request",
      content: "Are you sure you want to approve this?",
      okText: "Approve",
      onOk: async () => {
        setIsLoading(true);
        try {
          await callRest({
            message: "Failed to approve request",
            method: "POST",
            route: `/request-form-external-review-response/${data.id}?status=APPROVED`,
          });
          message.success("Request approved");
          setReviewStatus("APPROVED");
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
        }
      },
    });
  }

  function onReject() {
    Modal.confirm({
      title: "Reject request",
      content: "Are you sure you want to reject this?",
      okText: "Reject",
      onOk: async () => {
        setIsLoading(true);
        try {
          await callRest({
            message: "Failed to approve request",
            method: "POST",
            route: `/request-form-external-review-response/${data.id}?status=REJECTED`,
          });
          message.success("Request approved");
          setReviewStatus("APPROVED");
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
        }
      },
    });
  }

  function displayReviewStatus() {
    return (
      <Card title={`${data.parentId} - ${data.content.requestTitle} - ${data.content.formName}`}>
        <Result
          status={reviewStatus === "APPROVED" ? "success" : "error"}
          title={reviewStatus === "APPROVED" ? "Request approved" : "Request rejected"}
        />
      </Card>
    );
  }

  function displayOptions() {
    return (
      <Card title={`${data.parentId} - ${data.content.requestTitle} - ${data.content.formName}`}>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "0.5rem" }}>
          <Button type="primary" onClick={onApprove}>
            Approve
          </Button>
          <Button onClick={onReject}>Reject</Button>
        </div>
      </Card>
    );
  }

  return (
    <div className="request-form-external-review-page">
      {isLoading ? <OverallSpinner /> : <>{reviewStatus ? displayReviewStatus() : displayOptions()}</>}
    </div>
  );
}

export default withRouter(RequestFormExternalReviewPage);
