import moment from "moment";

const fields = [
  {
    id: "purchaseOrderId",
    type: "string",
    fieldTypes: ["textfield"],
    label: "ID",
    value: ({ purchaseOrder }) => purchaseOrder.id,
  },

  {
    id: "purchaseOrderAssigneeSignature",
    fieldTypes: ["signature"],
    label: "Assignee signature",
    value: ({ purchaseOrder, users }) => {
      const user = users.find((user) => user.id === purchaseOrder.assignedTo);
      if (!user) {
        return undefined;
      }

      return {
        image: user.signature,
        firstName: user.firstName,
        lastName: user.lastName,
      };
    },
  },
  {
    id: "purchaseOrderReviewerSignature",
    fieldTypes: ["signature"],
    label: "Reviewer signature",
    value: ({ purchaseOrder, users }) => {
      const user = users.find((user) => user.id === purchaseOrder.checkedBy);
      if (!user) {
        return undefined;
      }

      return {
        image: user.signature,
        firstName: user.firstName,
        lastName: user.lastName,
      };
    },
  },
  {
    id: "purchaseOrderAssignee",
    fieldTypes: ["textfield"],
    label: "Assignee full name",
    value: ({ purchaseOrder, users }) => {
      let user = users.find((x) => x.id === purchaseOrder.assignedTo);
      if (!user) {
        return "";
      }
      return `${user.firstName ? `${user.firstName} ` : ""}${user.lastName || ""}`;
    },
  },
  {
    id: "purchaseOrderAssigneeInitials",
    fieldTypes: ["textfield"],
    label: "Assignee initials",
    value: ({ purchaseOrder, users }) => {
      const assignee = users.find((user) => user.id === purchaseOrder.assignedTo);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.firstName[0]}${assignee.lastName[0]}`;
    },
  },
  {
    id: "purchaseOrderAssigneePosition",
    fieldTypes: ["textfield"],
    label: "Assignee position",
    value: ({ purchaseOrder, users }) => {
      const assignee = users.find((user) => user.id === purchaseOrder.assignedTo);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.position}`;
    },
  },
  {
    id: "purchaseOrderAssigneeQualifications",
    fieldTypes: ["textfield"],
    label: "Assignee qualifications",
    value: ({ purchaseOrder, users }) => {
      const assignee = users.find((user) => user.id === purchaseOrder.assignedTo);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.qualifications}`;
    },
  },

  {
    id: "purchaseOrderReviewer",
    fieldTypes: ["textfield"],
    label: "Reviewer full name",
    value: ({ purchaseOrder, users }) => {
      let user = users.find((x) => x.id === purchaseOrder.checkedBy);
      if (!user) {
        return "";
      }
      return `${user.firstName ? `${user.firstName} ` : ""}${user.lastName || ""}`;
    },
  },
  {
    id: "purchaseOrderReviewerInitials",
    fieldTypes: ["textfield"],
    label: "Reviewer initials",
    value: ({ purchaseOrder, users }) => {
      const assignee = users.find((user) => user.id === purchaseOrder.checkedBy);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.firstName[0]}${assignee.lastName[0]}`;
    },
  },
  {
    id: "purchaseOrderReviewerPosition",
    fieldTypes: ["textfield"],
    label: "Reviewer position",
    value: ({ purchaseOrder, users }) => {
      const assignee = users.find((user) => user.id === purchaseOrder.checkedBy);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.position}`;
    },
  },
  {
    id: "purchaseOrderReviewerQualifications",
    fieldTypes: ["textfield"],
    label: "Reviewer qualifications",
    value: ({ purchaseOrder, users }) => {
      const assignee = users.find((user) => user.id === purchaseOrder.checkedBy);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.qualifications}`;
    },
  },

  {
    id: "purchaseOrderTotal",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Total",
    value: ({ purchaseOrder }) => {
      return global.formatCurrency("GBP", purchaseOrder.total);
    },
  },
  {
    id: "purchaseOrderSubtotal",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Subtotal",
    value: ({ purchaseOrder }) => {
      return global.formatCurrency("GBP", purchaseOrder.subtotal);
    },
  },
  {
    id: "purchaseOrderTotalTax",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Total tax",
    value: ({ purchaseOrder }) => {
      return global.formatCurrency("GBP", purchaseOrder.totalTax);
    },
  },
  {
    id: "purchaseOrderTaxRate",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Tax rate",
    value: ({ purchaseOrder }) => {
      return `${purchaseOrder?.taxRate}%`;
    },
  },
  {
    id: "purchaseOrderReviewApprovedAt",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Review approved at",
    value: ({ purchaseOrder, dateFormat, addDays }) => {
      // debugger;
      if (!purchaseOrder || !purchaseOrder.reviewApprovedAt) {
        return "";
      }
      let result = moment(purchaseOrder.reviewApprovedAt).add(addDays, "day").format(dateFormat);
      return result;
    },
  },

  {
    id: "purchaseOrderLineItem",
    repeatForFieldName: "purchaseOrderLineItem",
    fieldTypes: ["repeatFor"],
    label: "Each line item",
    value: ({ purchaseOrder }) => {
      let result = [];
      let purchaseOrderLineItems = purchaseOrder.lineItems.items;
      for (let i = 0; i < purchaseOrderLineItems.length; i++) {
        let purchaseOrderLineItem = purchaseOrderLineItems[i];

        result.push({
          ...purchaseOrderLineItem,
        });
      }
      return result;
    },
  },
];
export function getFields() {
  return fields;
}
