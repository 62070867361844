import { fabric } from "fabric";
import pixelmatch from "pixelmatch";
import { getLabel } from "common/helpers";

export async function computeDiff(newPdf, taskRevision, sheet, file) {
  const oldPdf = await getComparisonPdfData(taskRevision, sheet, file);

  const { pdfImage: newImage, pdfImageData: newImageData, imageCanvas: newImageCanvas } = await getPdfImage(newPdf);
  let { pdfImage: oldImage, pdfImageData: oldImageData } = oldPdf ? await getPdfImage(oldPdf) : {};

  const newImageDataUrl = getDataUrl(newImage, newImageCanvas);
  let diffDataUrl;
  try {
    if (oldImage) {
      const outputImageData = new ImageData(oldImage.width, oldImage.height);

      pixelmatch(newImageData.data, oldImageData.data, outputImageData.data, newImage.width, oldImage.height, {
        threshold: 0.2,
        includeAA: true,
        alpha: 0.05,
      });

      const diffImageCanvas = document.createElement("canvas");
      const diffImageCanvasContext = diffImageCanvas.getContext("2d");

      diffImageCanvas.width = outputImageData.width;
      diffImageCanvas.height = outputImageData.height;
      diffImageCanvasContext.putImageData(outputImageData, 0, 0);
      diffDataUrl = diffImageCanvas.toDataURL("image/jpeg", 1.0);
    }
  } catch (e) {
    console.log("error when calculating diff:", e);
    diffDataUrl = "";
  }

  return { newImageDataUrl, diffDataUrl };
}

async function getComparisonPdfData(taskRevision, sheet, file) {
  try {
    const baseTaskRevisionDetails = (
      await window.callGraphQLSimple({
        message: `Couldn't retrieve ${getLabel({
          id: "task",
          defaultValue: "task",
        })} details`,
        queryCustom: "getTaskRevision",
        variables: {
          id: taskRevision.base,
        },
      })
    ).data.getTaskRevision;

    const baseFile = baseTaskRevisionDetails?.files?.items?.find(
      (baseFile) => file?.constantId === baseFile?.constantId || file?.constantId === baseFile?.id
    );
    if (!baseFile) {
      return undefined;
    }
    const sheetToCompare = baseFile?.sheets?.items?.find((currentSheet) => currentSheet?.name === sheet?.name);
    const pdfResponse = await Storage.get(
      sheetToCompare?.revisions.items.slice(-1)[0].exports[0].key?.replace("public/", ""),
      { download: true }
    );
    const pdfDataForComparison = await new Response(pdfResponse?.Body)?.arrayBuffer();

    return pdfDataForComparison;
  } catch (e) {
    return undefined;
  }
}

function readBlob(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.addEventListener("error", reject);
    reader.readAsDataURL(blob);
  });
}

export async function printPdfToImageCanvas(pdfData) {
  let base64Prefixes = [
    "data:application/octet-stream;base64,",
    "data:binary/octet-stream;base64,",
    "data:application/pdf;base64,",
  ];
  pdfData = pdfData instanceof Blob ? await readBlob(pdfData) : pdfData;

  let base64Prefix = base64Prefixes.find((prefix) => pdfData.startsWith(prefix));

  const data = atob(pdfData.substring(base64Prefix.length));
  // Using DocumentInitParameters object to load binary data.
  const pdfjsLib = window["pdfjs-dist/build/pdf"];
  const pdfDocument = await pdfjsLib.getDocument({ data }).promise;

  const pageNumber = 1;

  const page = await pdfDocument.getPage(pageNumber);
  // retina scaling
  const viewport = page.getViewport({ scale: 2 });
  // Prepare canvas using PDF page dimensions
  const imageCanvas = document.createElement("canvas");
  const context = imageCanvas.getContext("2d");
  imageCanvas.height = viewport.height;
  imageCanvas.width = viewport.width;

  // Render PDF page into imageCanvas context
  const renderContext = {
    canvasContext: context,
    viewport: viewport,
  };
  await page.render(renderContext).promise;
  return imageCanvas;
}

export async function getPdfImage(pdfData) {
  const imageCanvas = new fabric.Canvas("c1", {
    preserveObjectStacking: true,
    fireRightClick: true,
    fireMiddleClick: true,
  });

  imageCanvas.uniformScaling = false;

  const pdfImage = await printPdfToImageCanvas(new Blob([pdfData]), imageCanvas);

  imageCanvas.setWidth(pdfImage.width);
  imageCanvas.setHeight(pdfImage.height);

  const imageContext = pdfImage.getContext("2d");
  const pdfImageData = imageContext.getImageData(0, 0, pdfImage.width, pdfImage.height);

  return { pdfImage, pdfImageData, imageCanvas };
}

export function getDataUrl(pdfImage, comparisonCanvas) {
  let image = new fabric.Image(pdfImage, {
    scaleX: 1,
    scaleY: 1,
    excludeFromExport: true,
    selectable: false,
    evented: false,
    custom_isBackground: true,
    custom_name: `Highlight differences ${Date.now()}`,
    custom_id: `highlight_differences_${Date.now()}`,
    hasControls: false,
  });

  comparisonCanvas.setWidth(pdfImage.width);
  comparisonCanvas.setHeight(pdfImage.height);

  comparisonCanvas.add(image);
  const dataUrl = comparisonCanvas.toDataURL({ format: "jpeg", quality: 1.0 });

  return dataUrl;
}
