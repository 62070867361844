import React from "react";
import { Table, Typography, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import InfoItem from "InfoItem/InfoItem";
import { removeUserFromGroup } from "common/permissionOperations";
import { isAuthorised } from "common/permissions";

export default function UserGroups({ userData, groupMembership }) {
  return (
    <InfoItem
      value={
        <Table
          className="group-memberships-table"
          pagination={{ hideOnSinglePage: true }}
          expandIconColumnIndex={-1}
          showHeader={false}
          columns={[
            {
              key: "permission",
              dataIndex: "name",
              align: "left",
              render: (_, row) => {
                return <Typography.Text>{row.name}</Typography.Text>;
              },
            },
            {
              key: "actions",
              width: 50,
              render: (group, user) => {
                return (
                  <>
                    {isAuthorised(["TEAMS.EDIT_TEAM_MEMBERSHIP"]) && (
                      <Button onClick={() => removeUserFromGroup(group, userData)} icon={<DeleteOutlined />} />
                    )}
                  </>
                );
              },
            },
          ]}
          dataSource={groupMembership}
        />
      }
    />
  );
}
