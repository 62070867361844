import { Select } from "antd";
import { getUserReadableCatLevel } from "common/helpers";

export default function CatLevelPicker({ organisationDetails, ...props }) {
  return (
    <Select data-cy="cat-level-dropdown" {...props}>
      <Select.Option key={0} value={0}>
        {getUserReadableCatLevel(organisationDetails, 0)}
      </Select.Option>
      <Select.Option key={1} value={1}>
        {getUserReadableCatLevel(organisationDetails, 1)}
      </Select.Option>
      <Select.Option key={2} value={2}>
        {getUserReadableCatLevel(organisationDetails, 2)}
      </Select.Option>
      <Select.Option key={3} value={3}>
        {getUserReadableCatLevel(organisationDetails, 3)}
      </Select.Option>
    </Select>
  );
}
