import { Modal, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";

import { REVIEW_MOBILE_BREAKPOINT } from "common/constants";
import { submitReview, notifyReviewer } from "ReviewPage/reviewHelpers";
import { MOBILE_SUBTAB_OPTIONS } from "ReviewPage/ReviewPageContainer/ReviewPageContainer";

import ReviewBreadCrumbs from "ReviewPage/ReviewBreadcrumbs/ReviewBreadCrumbs";
import ReviewDesktopActions from "ReviewPage/ReviewDesktopActions/ReviewDesktopActions";
import ReviewMobileActions from "ReviewPage/ReviewMobileActions/ReviewMobileActions";
import ReviewPrimaryStatus from "ReviewPage/ReviewPrimaryStatus/ReviewPrimaryStatus";
import ReviewSecondaryStatus from "ReviewPage/ReviewSecondaryStatus/ReviewSecondaryStatus";

export function HeaderReviewPage({
  task,
  taskRevision,
  apiUser,
  containerThis,
  approveReview,
  userIsReviewer,
  history,
  getCurrentMobileSubtab,
  goToFileList,
  goToCanvas,
  goToActivity,
  isExternalReview,
}) {
  const { reviewStatus } = taskRevision;

  let userIsAuthor = apiUser.id === task.assignedTo;

  const nonArchivedTaskRevisions = task.revisions.items.filter((x) => !x.isArchived);
  let taskRevisionIsLatest = nonArchivedTaskRevisions.slice(-1)[0].id === taskRevision.id;

  function closeReview() {
    submitReview.call(containerThis, "CLOSED");
  }

  function reopenReview() {
    submitReview.call(containerThis, "IN_PROGRESS");
  }

  async function confirmCancelApproval() {
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm cancel approval",
          maskClosable: true,
          content: <>Are you sure you want to cancel the approval for this review?</>,
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }
    submitReview.call(containerThis, "IN_PROGRESS");
  }

  function handleBackButton() {
    const subtab = getCurrentMobileSubtab();

    switch (subtab) {
      case MOBILE_SUBTAB_OPTIONS.FILE_LIST:
        history.push(`/tasks/${task.id}`);
        break;
      case MOBILE_SUBTAB_OPTIONS.CANVAS:
        goToFileList();
        break;
      case MOBILE_SUBTAB_OPTIONS.ACTIVITY:
        goToCanvas();
        break;
      default:
        break;
    }
  }

  return (
    <div className="breadcrumb-bar">
      {window.innerWidth < REVIEW_MOBILE_BREAKPOINT && (
        <div className="back-button-container">
          <Button onClick={handleBackButton}>
            <LeftOutlined />
          </Button>
        </div>
      )}
      <div className="breadcrumb-container">
        {window.innerWidth < REVIEW_MOBILE_BREAKPOINT ? (
          <div className="mobile-review-status-tags">
            <ReviewPrimaryStatus taskRevision={taskRevision} />
            <ReviewSecondaryStatus taskRevision={taskRevision} />
          </div>
        ) : (
          <ReviewBreadCrumbs
            task={task}
            taskRevision={taskRevision}
            apiUser={apiUser}
            isExternalReview={isExternalReview}
          />
        )}
      </div>
      <div className="actions">
        <div className="section">
          {window.innerWidth > REVIEW_MOBILE_BREAKPOINT ? (
            <ReviewDesktopActions
              userIsAuthor={userIsAuthor}
              userIsReviewer={userIsReviewer}
              reviewStatus={reviewStatus}
              taskRevision={taskRevision}
              containerThis={containerThis}
              submitReview={submitReview}
              approveReview={approveReview}
              closeReview={closeReview}
              notifyReviewer={notifyReviewer}
              taskRevisionIsLatest={taskRevisionIsLatest}
              confirmCancelApproval={confirmCancelApproval}
              reopenReview={reopenReview}
            />
          ) : (
            <ReviewMobileActions
              reopenReview={reopenReview}
              userIsAuthor={userIsAuthor}
              userIsReviewer={userIsReviewer}
              reviewStatus={reviewStatus}
              taskRevision={taskRevision}
              containerThis={containerThis}
              submitReview={submitReview}
              approveReview={approveReview}
              closeReview={closeReview}
              notifyReviewer={notifyReviewer}
              taskRevisionIsLatest={taskRevisionIsLatest}
              confirmCancelApproval={confirmCancelApproval}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(HeaderReviewPage);
