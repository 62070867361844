import { message, notification } from "antd";

import { downloadZip } from "client-zip";
import { buildReport } from "ReportPage/Report/reportHelpers";
import getS3File from "common/getS3File";
import { processIdForDisplay, HAS_SHEETS, getFilenameFromKey, KEY_TYPES } from "./shared";
import { getSimpleLabel } from "./labels";
import { downloadBlob } from "common/helpers";
import { callGraphQLSimple } from "./apiHelpers";
import { changeFileNameAtDownloadTime } from "./naming";

export default async function downloadSelectedSheetsInTask({
  taskId,
  sheetIds,
  users,
  organisationDetails,
  taskRevisionId,
  attachments,
}) {
  const task = (
    await callGraphQLSimple({
      message: `Failed to download ${getSimpleLabel("task")} details`,
      queryCustom: "getTaskWithFiles",
      variables: { id: taskId },
    })
  ).data.getTask;

  const messageKey = `downloading-files-for-task-${task.id}`;

  message.loading({
    content: `Preparing your files...`,
    key: messageKey,
    duration: 0,
  });
  try {
    const publicUrls = await getPublicUrls({
      task,
      sheetIds,
      messageKey,
      users,
      organisationDetails,
      taskRevisionId,
      attachments,
    });
    await assembleArchive({
      taskId: task.id,
      messageKey,
      publicUrls,
      taskTitle: task.title,
      projectTitle: task.project?.title,
    });
  } catch (e) {
    console.error(e);
    notification.error({
      message: "Error downloading files",
      description: e.message,
    });
  }

  message.destroy();
}

export async function prepareSelectedSheetsInTaskForSending({
  taskId,
  sheetIds,
  users,
  organisationDetails,
  taskRevisionId,
}) {
  const messageKey = `preparing-files-for-task-${taskId}`;
  message.loading({
    content: `Preparing your files...`,
    key: messageKey,
    duration: 0,
  });
  const task = (
    await callGraphQLSimple({
      message: `Failed to download ${getSimpleLabel("task")} details`,
      queryCustom: "getTaskWithFiles",
      variables: { id: taskId },
    })
  ).data.getTask;

  let publicUrls;
  try {
    publicUrls = await getPublicUrls({ task, sheetIds, messageKey, users, organisationDetails, taskRevisionId });
  } catch (e) {
    throw e;
  }
  message.destroy(messageKey);
  return publicUrls;
}

async function getPublicUrls({ task, sheetIds, messageKey, users, organisationDetails, taskRevisionId, attachments }) {
  try {
    let publicUrlPromises = [];

    if (attachments) {
      for (let attachmentKey of attachments) {
        publicUrlPromises.push(
          new Promise(async (resolve, reject) => {
            try {
              let publicUrl = await getS3File(attachmentKey);
              resolve({
                url: publicUrl,
                fileName: attachmentKey.split("/").pop(),
                fileKey: attachmentKey,
                isIndividualSheet: false,
              });
            } catch (e) {
              reject(e);
            }
          })
        );
      }
    }

    let taskRevision = task.revisions.items.find((revision) => revision.id === taskRevisionId);
    let files = taskRevision.files.items;
    let buildReportCallCount = 0;
    for (let sheetId of sheetIds) {
      for (let file of files) {
        for (let sheet of file.sheets.items) {
          let sheetMatches = sheet.id === sheetId;
          if (sheetMatches) {
            if (file.type === "REPORT") {
              await new Promise((resolve) => setTimeout(resolve, 300));
              publicUrlPromises.push(
                new Promise(async (resolve, reject) => {
                  try {
                    const buildReportResponse = await buildReport({
                      fileId: file.id,
                      taskId: task.id,
                      users,
                      organisationDetails,
                    });
                    buildReportCallCount++;
                    resolve({
                      url: buildReportResponse.signedUrl,
                      fileName: buildReportResponse.fileName,
                      fileKey: buildReportResponse.key,
                      file,
                      sheetConstantId: sheet.constantId,
                      sheet,
                    });
                  } catch (e) {
                    reject(e);
                  }
                })
              );
            } else {
              publicUrlPromises.push(
                new Promise(async (resolve, reject) => {
                  try {
                    let targetSheet;
                    let sheetRevision;
                    let sheetRevisionName;
                    let firstExport;
                    let isIndividualSheet = false;
                    let keyForDownload;

                    if (HAS_SHEETS[file.type]) {
                      targetSheet = sheet;
                      sheetRevision = targetSheet.revisions.items.slice(-1)[0];
                      sheetRevisionName = sheetRevision.name;
                      keyForDownload = sheetRevision.exports[0].key;
                      isIndividualSheet = true;
                      firstExport = sheetRevision.exports[0];
                    } else {
                      let fileVersion = file.versions.items.slice(-1)[0];
                      targetSheet = file.sheets.items[0];
                      sheetRevision = targetSheet.revisions.items.slice(-1)[0];
                      sheetRevisionName = sheetRevision.name;
                      firstExport = fileVersion.exports[0];
                      keyForDownload = fileVersion.exports[0].key;
                    }

                    let originalFileName = firstExport.fileName || getFilenameFromKey(firstExport.key);
                    let changedFileName = targetSheet.customReference;
                    if (!changedFileName) {
                      changedFileName = await changeFileNameAtDownloadTime({
                        organisation: organisationDetails.id,
                        fileName: originalFileName,
                        sheetRevisionName: sheetRevisionName || sheetRevision.name,
                        sheetRevision: sheetRevision,
                        file,
                        type: KEY_TYPES.FILE_SHEET_EXPORT,
                        task,
                      });
                    }

                    let publicUrl = await getS3File(keyForDownload, undefined, 60 * 60 * 24 * 7);
                    resolve({
                      url: publicUrl,
                      file,
                      sheet,
                      fileName: changedFileName,
                      fileKey: firstExport.key,
                      isIndividualSheet: isIndividualSheet,
                      sheetConstantId: sheet.constantId,
                    });
                  } catch (e) {
                    reject(e);
                  }
                })
              );
            }
          }
        }
      }
    }

    return await Promise.all(publicUrlPromises);
  } catch (e) {
    message.error({
      content: `Error downloading files for ${getSimpleLabel("task")} ${processIdForDisplay(task.id)}: ${e.message}`,
      key: messageKey,
      duration: 5,
    });
    throw e;
  }
}

export async function assembleArchive({ taskId, messageKey, publicUrls, taskTitle, projectTitle }) {
  message.loading({
    content: `Building archive for ${getSimpleLabel("task")} ${processIdForDisplay(taskId)}...`,
    key: messageKey,
    duration: 0,
  });
  let filesForArchive = await Promise.all(
    publicUrls.map((fileForArchive) => {
      const { url, fileName } = fileForArchive;
      return new Promise(async (resolve) => {
        const downloadedFile = await fetch(url);
        let fileNameWithExtension = fileName;
        if (!fileNameWithExtension.includes(".")) {
          fileNameWithExtension = `${fileName}.pdf`;
        }
        resolve({
          name: fileNameWithExtension,
          input: downloadedFile,
        });
      });
    })
  );
  try {
    const resultArchive = await downloadZip(filesForArchive).blob();

    const projectAndTaskTitles = `${projectTitle} - ${taskTitle}`;

    await downloadBlob({
      blob: resultArchive,
      fileName: `${projectAndTaskTitles.substring(0, 90)} (${processIdForDisplay(taskId)}).zip`,
    });
    message.success({ content: "Archive downloaded", key: messageKey, duration: 2 });
  } catch (e) {
    console.error(e);
    message.error({
      content: "Failed to download template",
      key: messageKey,
      duration: 10,
    });
  }
}
