import { Button, Modal } from "antd";
import { SendOutlined, DeleteOutlined, FilePdfOutlined, EyeOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";

import Card from "Card/Card";
import Switch from "Switch/Switch";

import "./PurchaseOrderActions.scss";

export function PurchaseOrderActions({
  purchaseOrder,
  isPreviewEnabled,
  isDownloadingPurchaseOrder,
  isSendingPurchaseOrder,
  onPreviewSwitch,
  restorePurchaseOrder,
  archivePurchaseOrder,
  downloadPurchaseOrder,
  sendPurchaseOrder,
  windowWidth,
  onRequestReviewClick,
  history,
  hasApprovedPdf,
  showApprovedPdf,
  apiUser,
}) {
  async function deletePurchaseOrder() {
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm purchase order delete",
          maskClosable: true,
          content: (
            <>
              Are you sure you want to delete purchase order <b>{purchaseOrder.id}</b>?
            </>
          ),
          okText: "Yes, delete",
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    let deletePurchaseOrderLineItemPromises = [];
    for (let i = 0; i < purchaseOrder.lineItems.items.length; i++) {
      let lineItem = purchaseOrder.lineItems.items[i];
      deletePurchaseOrderLineItemPromises.push(
        window.callGraphQLSimple({
          message: "Failed to delete purchase order line item",
          mutation: "deletePurchaseOrderLineItem",
          variables: {
            input: {
              id: lineItem.id,
            },
          },
        })
      );
      await new Promise((resolve) => setTimeout(resolve, 30));
    }
    await Promise.all(deletePurchaseOrderLineItemPromises);

    await window.callGraphQLSimple({
      message: "Failed to delete purchase order",
      mutation: "deletePurchaseOrder",
      variables: {
        input: {
          id: purchaseOrder.id,
        },
      },
    });

    await callGraphQLSimple({
      message: "Failed to record activity item",
      mutation: "createDocumentActivityItem",
      variables: {
        input: {
          documentId: purchaseOrder.id,
          total: purchaseOrder.total,
          type: "DELETED",
          organisation: purchaseOrder.organisation,
          author: apiUser.id,
        },
      },
    });

    history.push(`/purchase-orders`);
  }

  function displayPreviewToggle() {
    if (!purchaseOrder.reviewApprovedAt) {
      return (
        <Switch
          disabled={windowWidth < 1000}
          checked={isPreviewEnabled && windowWidth >= 1000}
          onChange={onPreviewSwitch}
          className="preview-switch"
          label="Display preview"
        />
      );
    }

    return (
      <Button type="primary" onClick={showApprovedPdf} disabled={!hasApprovedPdf}>
        Show approved version
      </Button>
    );
  }

  return (
    <div className="purchase-order-actions-container">
      <Card className="purchase-order-actions" withSpace>
        <div className="actions-container">
          {purchaseOrder.isArchived ? (
            <Button onClick={restorePurchaseOrder} type="primary">
              Restore purchase order
            </Button>
          ) : (
            <Button icon={<DeleteOutlined />} onClick={deletePurchaseOrder}>
              Delete purchase order
            </Button>
          )}

          {displayPreviewToggle()}

          {/* {review && (
            <Switch
              // disabled={purchaseOrder.isArchived}
              checkedChildren="Comments visible"
              unCheckedChildren="Comments hidden"
              checked={areCommentsVisible}
              onChange={onCommentsSwitch}
              className="comments-switch"
            />
          )} */}

          <div className="download-and-send">
            {!purchaseOrder.reviews?.items?.length && (
              <Button
                data-cy="request-review-button"
                disabled={purchaseOrder.isArchived}
                icon={<EyeOutlined />}
                onClick={onRequestReviewClick}
                type="dark"
              >
                Request review
              </Button>
            )}
            <Button
              disabled={purchaseOrder.isArchived || (purchaseOrder.reviewApprovedAt && !hasApprovedPdf)}
              icon={<FilePdfOutlined />}
              type="primary"
              loading={isDownloadingPurchaseOrder}
              onClick={downloadPurchaseOrder}
              className="download-button"
              data-cy="download-purchase-order-button"
            >
              {isDownloadingPurchaseOrder ? "Downloading..." : "Download"}
            </Button>
            <Button
              icon={<SendOutlined />}
              type="primary"
              loading={isSendingPurchaseOrder}
              onClick={sendPurchaseOrder}
              disabled={purchaseOrder.isArchived || (purchaseOrder.reviewApprovedAt && !hasApprovedPdf)}
              className="send-button"
              data-cy="send-purchase-order-button"
            >
              {isSendingPurchaseOrder ? "Sending..." : "Send"}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: PurchaseOrderActions,
    subscriptions: ["apiUser", "organisationDetails", "clients", "projects", "suppliers"],
  })
);
