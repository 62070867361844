import React, { useState } from "react";

import { Button, Typography, Modal, message } from "antd";

import { withRouter } from "react-router-dom";
import withSubscriptions from "common/withSubscriptions";
import { callRest } from "common/apiHelpers";

import Card from "Card/Card";
import { XeroLogo } from "./XeroSvgAssets";
import { Organisation, Integration } from "common/types";

import moment from "moment";

import "./XeroTab.scss";

type Props = {
  organisationDetails: Organisation;
};

type OAuthCredentials = {
  redirectUris: string[];
  clientId: string;
};

export function XeroTab(props: Props) {
  const [isFetchingCredentials, setIsFetchingCredentials] = useState(false);
  const { organisationDetails } = props;
  const xeroDetails: Integration | undefined = organisationDetails.integrations?.find(
    (integration: Integration) => integration?.id === "XERO"
  );
  const xeroStatus = getXeroStatus();

  async function connectToXero() {
    try {
      setIsFetchingCredentials(true);
      let credentials: OAuthCredentials = await callRest({
        method: "POST",
        route: "/get-accountancy-integration-credentials",
        body: {
          integrationId: "xero",
        },
      });

      if (!credentials || !credentials.redirectUris || !credentials.clientId) {
        message.warning("Failed to retrieve OAuth credentials");
        return;
      }

      const redirectUri = credentials.redirectUris[0];
      const XERO_CLIENT_ID = credentials.clientId;
      const scopes =
        "openid profile email accounting.settings accounting.reports.read accounting.journals.read accounting.contacts accounting.attachments accounting.transactions offline_access files files.read";
      const xeroConsentUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${XERO_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${scopes}`;

      setIsFetchingCredentials(false);

      window.location.href = xeroConsentUrl;
    } catch (err: any) {
      if (err.response?.data) {
        Modal.warning({
          title: "Failed to retrieve the OAuth credentials for Xero",
          content: <div>Reason: {err.response?.data}</div>,
        });
      } else {
        message.warning("Failed to authorise the Xero integration");
      }
    }
  }

  function getXeroStatus() {
    if (xeroDetails && xeroDetails.authorisedAt) {
      const expirationDate = moment(xeroDetails.authorisedAt).add(1, "month").toISOString();

      if (moment().isAfter(expirationDate)) {
        return "Expired";
      } else {
        return "Active";
      }
    } else {
      return "Not connected";
    }
  }

  function displayXeroButton() {
    return (
      <Button type="primary" loading={isFetchingCredentials} onClick={async () => await connectToXero()}>
        {xeroStatus === "Active" ? "Refresh token" : "Connect to Xero"}
      </Button>
    );
  }

  return (
    <div className="xero-tab">
      <Card title={<XeroLogo />} withSpace>
        <div className="xero-status">
          <Typography.Text>Status: {getXeroStatus()}</Typography.Text>
          {xeroStatus !== "Not connected" && (
            <Typography.Text>
              {xeroDetails ? `Authorised at: ${moment(xeroDetails?.authorisedAt).format("YYYY-MM-DD HH:mm:ss")}` : null}
            </Typography.Text>
          )}
        </div>
        {displayXeroButton()}
      </Card>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: XeroTab,
    subscriptions: ["organisationDetails"],
  })
);
