import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

import { PUBLIC_S3_BUCKET_URL } from "common/publicS3BucketUrl";

import "./ClientLogo.scss";
import { Typography } from "antd";

export default class ClientLogo extends React.Component {
  render() {
    const { client, className, size = "medium", includeLink = false, displayNameIfLogoIsMissing = true } = this.props;
    if (!client) {
      return null;
    }

    let image = client && client.key && `${PUBLIC_S3_BUCKET_URL}/public/${client.key}`;
    let content = null;

    if (!image && !displayNameIfLogoIsMissing) {
      return null;
    }

    if (!image) {
      content = (
        <div className={cx("client-logo client-logo-no-image", className)}>
          <Typography.Text>{client?.name}</Typography.Text>
        </div>
      );
    } else {
      content = <img className={cx("client-logo", className, size)} src={image} alt="" />;
    }

    if (!includeLink) {
      return content;
    } else {
      return (
        <Link
          to={`/clients/${client.id}`}
          className={cx("client-logo-link", {
            "with-image": !!image,
          })}
        >
          {content}
        </Link>
      );
    }
  }
}
