import { useState } from "react";
import { Form, Modal, Button, Input, Space } from "antd";

import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";
import { callGraphQLSimple } from "common/apiHelpers";

import "./TaskPriorityModal.scss";

export function TaskPriorityModal({ onClose, visible, apiUser, organisationDetails }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ name }) {
    setIsLoading(true);

    let updatedOrganisation = JSON.parse(JSON.stringify(organisationDetails));

    if (!updatedOrganisation.settings) {
      updatedOrganisation.settings = {};
    }

    if (!updatedOrganisation.settings.task) {
      updatedOrganisation.settings.task = {};
    }

    if (!updatedOrganisation.settings.task.priorities) {
      updatedOrganisation.settings.task.priorities = [];
    }

    updatedOrganisation.settings.task.priorities.push({ name, id: Math.floor(Math.random() * 100000) });

    await callGraphQLSimple({
      message: "Could not create priority level",
      queryName: "updateOrganisation",
      variables: {
        input: {
          id: apiUser.organisation,
          settings: updatedOrganisation.settings,
        },
      },
    });

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  return (
    <Modal
      maskClosable={false}
      title={`Create ${getSimpleLabel("task")} priority level`}
      open={visible}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-task-priority-modal"
    >
      <Form form={form} initialValues={{}} onFinish={onSubmit}>
        <Space direction="vertical" className="spread">
          <Form.Item
            label="Name"
            name="name"
            className="task-priority-name"
            rules={[
              {
                required: true,
                message: "You must choose a name for the priority level",
              },
            ]}
          >
            <Input data-cy="task-priority-name-input" />
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading} data-cy="create-task-priority-button">
              Submit
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}

export default withSubscriptions({
  Component: TaskPriorityModal,
  subscriptions: ["organisationDetails"],
});
