import { Tag, Breadcrumb } from "antd";
import { Link } from "react-router-dom";

import TaskIdTag from "TaskIdTag/TaskIdTag";
import CopyLinkButton from "CopyLinkButton/CopyLinkButton";

import ReviewPrimaryStatus from "ReviewPage/ReviewPrimaryStatus/ReviewPrimaryStatus";
import ReviewSecondaryStatus from "ReviewPage/ReviewSecondaryStatus/ReviewSecondaryStatus";

export default function ReviewBreadCrumb({ task, taskRevision, isExternalReview }) {
  let projectElement;
  let taskElement;

  if (!isExternalReview) {
    projectElement = <Link to={`/projects/${task.projectId}`}>{task.project.title}</Link>;
    taskElement = <TaskIdTag task={task} />;
  } else {
    projectElement = <span style={{ marginLeft: "0.5rem" }}>{task.project.title}</span>;
    taskElement = (
      <Link to={`/requests/${task.id}`}>
        <Tag className="dark-tag">{task.id}</Tag>
        <span>{task.title}</span>
      </Link>
    );
  }

  return (
    <Breadcrumb>
      <Breadcrumb.Item>{projectElement}</Breadcrumb.Item>
      <Breadcrumb.Item className="task-id-tag">{taskElement}</Breadcrumb.Item>
      <Breadcrumb.Item>
        Review for {taskRevision.name} - {taskRevision.description}
        <ReviewPrimaryStatus taskRevision={taskRevision} />
        <ReviewSecondaryStatus taskRevision={taskRevision} />
        <CopyLinkButton />
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}
