import { useState } from "react";
import { withRouter } from "react-router-dom";
import { Typography, Form, Modal, Button, Row, Col, Select, Input, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useForceUpdate, getLabel, processIdForDisplay } from "common/helpers";

import CreateSupplierModal from "Modals/CreateSupplierModal/CreateSupplierModal";
import CreateProjectModal from "CreateProjectModal/CreateProjectModal";
import withSubscriptions from "common/withSubscriptions";
import { CURRENCIES } from "common/constants";
import ProjectPicker from "ProjectPicker/ProjectPicker";

import { createPurchaseOrder } from "common/purchaseOrderHelpers";

import "./CreatePurchaseOrderModal.scss";

export function CreatePurchaseOrderModal({
  onClose,
  onSave,
  organisationDetails,
  apiUser,
  predefinedFields = {},
  projects,
  suppliers,
  users,
  clients,
  history,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateSupplierModalVisible, setIsCreateSupplierModalVisible] = useState(false);
  const [isCreateProjectModalVisible, setIsCreateProjectModalVisible] = useState(false);
  const [form] = Form.useForm();

  const templatesForFileType = organisationDetails.templates.items.filter(
    // (template) => (template.isLive || !template.key) && !template.isDeprecated
    // TODO: re-add the restriction for template to be live
    (template) => template.type === "PURCHASE_ORDER" && !template.isDeprecated
  );

  // we need to force the component to re-render when we change some form values
  const forceUpdate = useForceUpdate();

  async function onSubmit(params) {
    const { projectId, supplierId, title, templateId } = params;
    setIsLoading(true);

    const project = projects.find((x) => x.id === projectId);

    if (!project) {
      message.error(
        <>
          {getLabel({
            id: "Project",
            defaultValue: "Project",
          })}{" "}
          <b>{params.projectId}</b> not found
        </>
      );
      setIsLoading(false);
      return;
    }

    const supplier = suppliers.find((supplier) => supplier.id === supplierId);
    if (!supplier) {
      message.error(
        <>
          {getLabel({
            id: "Supplier",
            defaultValue: "Supplier",
          })}{" "}
          <b>{supplierId}</b> not found
        </>
      );
      setIsLoading(false);
      return;
    }

    const purchaseOrder = await createPurchaseOrder({
      organisationDetails,
      suppliers,
      supplier,
      users,
      apiUser,
      project,
      templateId,
      title,
    });

    message.success({
      content: (
        <Typography.Text
          onClick={() => history.push(`/purchase-orders/${purchaseOrder.id}`)}
          style={{ cursor: "pointer" }}
        >
          Purchase order {processIdForDisplay(purchaseOrder.id)} created. <b>Click here to go to it</b>
        </Typography.Text>
      ),
      duration: window.Cypress ? 0.5 : 5,
    });

    if (onSave) {
      onSave(purchaseOrder);
    }

    onClose();
  }

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  let defaultTemplateId = templatesForFileType.find((x) => x.isDefault)?.id || templatesForFileType[0]?.id;

  return (
    <Modal
      maskClosable={false}
      title={`Create purchase order ${predefinedFields.supplierId ? "for supplier" : ""}`}
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-purchase-order-modal full-screen-on-mobile"
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          currency: CURRENCIES[0].value,
          templateId: defaultTemplateId,
          ...predefinedFields,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: "Title is required",
            },
          ]}
        >
          <Input
            autoComplete="off"
            className="purchase-order-title"
            data-cy="purchase-order-title-input"
            onChange={(e) => {
              form.setFieldsValue({ title: e.target.value });
              forceUpdate();
              form.validateFields(["title"]);
            }}
          />
        </Form.Item>
        <Form.Item
          name="projectId"
          label="Project"
          rules={[
            {
              required: true,
              message: "Purchase orders have to be associated with a project",
            },
          ]}
        >
          <Row gutter={16}>
            <Col xs={24} sm={16}>
              <ProjectPicker
                onChange={(projectId) => {
                  form.setFieldsValue({ projectId });
                  forceUpdate();
                  if (form.getFieldValue("title")) {
                    form.validateFields(["title"]);
                  }
                }}
                value={form.getFieldValue("projectId")}
                data-cy="project-id-dropdown"
                predefinedFields={predefinedFields}
              />
            </Col>
            <Col xs={24} sm={8}>
              <Button
                type="primary"
                className="create-project"
                onClick={() => setIsCreateSupplierModalVisible(true)}
                data-cy="create-project-button"
                disabled={predefinedFields.projectId}
              >
                <PlusCircleOutlined /> Create project
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          name="supplierId"
          label="Supplier"
          rules={[
            {
              required: true,
              message: "Purchase orders have to be associated with a supplier",
            },
          ]}
        >
          <Row gutter={16}>
            <Col xs={24} sm={16}>
              <Select
                showSearch
                placeholder="Choose a supplier"
                className="supplier-selector"
                disabled={predefinedFields.supplierId}
                onChange={(supplierId) => {
                  form.setFieldsValue({ supplierId });
                  forceUpdate();
                  // if (form.getFieldValue("title")) {
                  //   form.validateFields(["title"]);
                  // }
                }}
                defaultValue={predefinedFields.supplierId}
                filterOption={(input, option) => {
                  return option.supplier.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                value={form.getFieldValue("supplierId")}
                data-cy="supplier-id-dropdown"
              >
                {suppliers
                  .filter((x) => x.organisation === apiUser.organisation && !x.isArchived)
                  .map((supplier, i) => {
                    return (
                      <Select.Option key={i} value={supplier.id} supplier={supplier}>
                        {supplier.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Col>
            <Col xs={24} sm={8}>
              <Button
                type="primary"
                className="create-supplier"
                onClick={() => setIsCreateSupplierModalVisible(true)}
                data-cy="create-supplier-button"
                disabled={predefinedFields.supplierId}
              >
                <PlusCircleOutlined /> Create supplier
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label="Template"
          name="templateId"
          rules={[
            {
              required: true,
              message: "You have to choose a template",
            },
          ]}
        >
          <Select>
            {(templatesForFileType || []).map((template) => {
              return (
                <Select.Option key={template.id} value={template.id}>
                  {template.name}
                  {!template.isLive && template.key ? " (draft)" : ""}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Currency" name="currency" rules={[]} hidden>
          <Select data-cy="currency-picker-dropdown">
            {CURRENCIES.map((currency) => {
              return (
                <Select.Option key={currency.value} value={currency.value}>
                  {currency.value}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="create-supplier-submit-modal-button">
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
      {isCreateSupplierModalVisible && (
        <CreateSupplierModal
          apiUser={apiUser}
          organisation={apiUser.organisation}
          onClose={() => setIsCreateSupplierModalVisible(false)}
          onSave={(supplier) => {
            form.setFieldsValue({ supplierId: supplier.id });
            forceUpdate();
          }}
        />
      )}
      {isCreateProjectModalVisible && (
        <CreateProjectModal
          apiUser={apiUser}
          organisation={apiUser.organisation}
          onClose={() => setIsCreateProjectModalVisible(false)}
          onSave={(project) => {
            form.setFieldsValue({ projectId: project.id });
            forceUpdate();
          }}
        />
      )}
    </Modal>
  );
}

export default withRouter(
  withSubscriptions({
    Component: CreatePurchaseOrderModal,
    subscriptions: ["suppliers", "clients", "users", "projects"],
    displayPreloader: false,
  })
);
