export function getGraphQLInput({ organisationDetails, checked, module }) {
  let input = organisationDetails;
  let schemaFieldParts = module.SCHEMA_FIELD.split(".");
  if (schemaFieldParts.length === 1) {
    input = { [module.SCHEMA_FIELD]: checked };
  } else {
    let currentInputLevel = input;
    schemaFieldParts.forEach((fieldName, levelIndex) => {
      if (levelIndex === schemaFieldParts.length - 1) {
        currentInputLevel[fieldName] = checked;
      } else {
        currentInputLevel[fieldName] = { ...currentInputLevel[fieldName] };
      }
      currentInputLevel = currentInputLevel[fieldName];
    });
  }
  return input;
}
