import findChildAndParent from "./findChildAndParent";
import updateChildrenForParent from "./updateChildrenForParent";
import addObjectToParent from "./addObjectToParent";

export default function moveChildToNewParent({ childId, newParentId, newPosition, outputTemplate }) {
  let newOutputTemplate = { ...outputTemplate };
  // Extract the child and its current parent
  const { child, parent: oldParent } = findChildAndParent(outputTemplate, childId);

  if (child) {
    // Remove the child from its old parent
    const newChildrenForOldParent = oldParent.objects.filter((obj) => obj.custom_id !== childId);

    // Find the new parent and add the child at the specified index
    newOutputTemplate = addObjectToParent({
      currentObject: { ...outputTemplate },
      parentId: newParentId,
      child,
      newPosition,
    });

    // Update the old parent's children
    newOutputTemplate = updateChildrenForParent(newOutputTemplate, oldParent.custom_id, newChildrenForOldParent);
  }

  return newOutputTemplate;
}
