import { useEffect, useState } from "react";
import { Button, Typography, Modal } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";
import { updateOrganisation } from "graphql/mutations";

import Card from "Card/Card";
import CreateCustomLabelModal from "../../Modals/CreateCustomLabelModal/CreateCustomLabelModal";

import "./CustomLabelsPage.scss";

function CustomLabelsPage({ organisationDetails }) {
  const [isCreateCustomLabelModalVisible, setIsCreateCustomLabelModalVisible] = useState(false);

  useEffect(() => {
    callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "CUSTOM_LABELS",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
  }, []);

  function confirmDeleteCustomLabel(label) {
    Modal.confirm({
      title: "Confirm custom label delete",
      className: "delete-custom-label-modal",
      content: (
        <>
          Are you sure you want to delete custom label <b>{label.label}</b>?
        </>
      ),
      onOk: async () => {
        await callGraphQLSimple({
          message: "Could not delete custom label",
          queryName: "updateOrganisation",
          variables: {
            input: {
              id: organisationDetails.id,
              labels: (organisationDetails.labels || []).filter((crtLabel) => crtLabel.id !== label.id),
            },
          },
        });
      },
    });
  }

  return (
    <Card
      withSpace
      title="Custom labels"
      className="custom-labels-list"
      actions={
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          data-cy="create-custom-label-button"
          onClick={() => setIsCreateCustomLabelModalVisible(true)}
        >
          Add custom label
        </Button>
      }
    >
      {(organisationDetails.labels || []).map((field) => {
        return (
          <div className="custom-label" key={field.id} data-cy="custom-label-item">
            <Typography.Text className="id" data-cy="custom-label-id">
              {field.id}
            </Typography.Text>
            <Typography.Text className="name" data-cy="custom-label-name">
              {field.value}
            </Typography.Text>
            <Button
              icon={<DeleteOutlined />}
              className="delete-custom-label"
              data-cy="delete-custom-label-button"
              onClick={() => confirmDeleteCustomLabel(field)}
            />
          </div>
        );
      })}
      <CreateCustomLabelModal
        organisationDetails={organisationDetails}
        visible={isCreateCustomLabelModalVisible}
        onClose={() => setIsCreateCustomLabelModalVisible(false)}
      />
    </Card>
  );
}

export default withSubscriptions({
  Component: CustomLabelsPage,
  subscriptions: ["organisationDetails"],
});
