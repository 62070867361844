import { useState } from "react";
import { Storage } from "aws-amplify";
import { Form, Modal, Button, Input, Typography, Space, message, notification } from "antd";
import withSubscriptions from "common/withSubscriptions";
import { readAndCompressImage } from "browser-image-resizer";

import { getExtensionFromKey } from "common/shared";

import Upload from "Upload/Upload";

import "./CreateSupplierModal.scss";

export function CreateSupplierModal({ onClose, onSave, organisationDetails }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(params) {
    const { logo, ...paramsWithoutLogo } = params;
    let key;
    try {
      setIsLoading(true);
      if (logo) {
        // if we have a logo, then we need to upload it to S3 before
        // proceeding to create the supplier record in the database
        const extension = getExtensionFromKey(logo.name);
        console.log("extension = ", extension);
        if (!["png", "jpg", "jpeg"].includes(extension.toLowerCase())) {
          alert("Only .png, .jpg and .jpeg formats are allowed");
          setIsLoading(false);
          return;
        }
        const resizedLogo = await readAndCompressImage(logo, {
          quality: 0.8,
          maxWidth: 500,
          maxHeight: 500,
          mimeType: logo.type,
        });

        const fileName = `${organisationDetails.id}/suppliers/${Date.now()}${Math.floor(
          Math.random() * 10000
        )}_${logo.name.split(" ").join("-")}`;
        key = (
          await Storage.put(fileName, resizedLogo, {
            contentType: "image/png",
          })
        ).key;
      }

      let supplier = (
        await window.callGraphQLSimple({
          message: "Failed to create supplier",
          mutation: "createSupplier",
          variables: {
            input: {
              ...paramsWithoutLogo,
              key,
              organisation: organisationDetails.id,
            },
          },
        })
      ).data.createSupplier;

      if (onSave) {
        onSave(supplier);
      }
      message.success({
        content: (
          <Typography.Text>
            Supplier <b>{params.name}</b> has been created
          </Typography.Text>
        ),
        className: "create-supplier-success",
      });

      form.resetFields();
      setIsLoading(false);
      onClose();
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      notification.error({
        message: <Typography.Text>Could not create supplier</Typography.Text>,
        duration: 0,
      });
    }
  }

  return (
    <Modal
      maskClosable={false}
      title="Create supplier"
      open={true}
      onOk={onSubmit}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      footer={null}
      className="create-supplier-modal full-screen-on-mobile"
    >
      <Form layout="vertical" form={form} initialValues={{}} onFinish={onSubmit}>
        <Space direction="vertical">
          <Form.Item
            label="Supplier Name"
            name="name"
            rules={[
              {
                required: true,
                message: "You must specify a supplier name",
              },
            ]}
          >
            <Input autoComplete="off" className="supplier-name" />
          </Form.Item>

          <Form.Item name="logo" label="Logo" getValueFromEvent={(e, file) => file}>
            <Upload size="normal" />
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading} className="submit-create-supplier-modal">
              {isLoading ? "Creating" : "Submit"}
            </Button>
          </div>
        </Space>
      </Form>
    </Modal>
  );
}

export default withSubscriptions({
  Component: CreateSupplierModal,
  subscriptions: ["organisationDetails"],
});
