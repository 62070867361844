import { useState, useEffect } from "react";
import moment from "moment";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";
import PlannedActivityBlock from "../PlannedActivityBlock/PlannedActivityBlock";

import "./PlannedActivityCanvas.scss";

export default function PlannedActivityCanvas({ targetDate, tasks, projects, targetUserId }) {
  const [_, setTimelineBlocks] = useState(); // eslint-disable-line
  const [timelineBlocksOnTargetDate, setTimelineBlocksOnTargetDate] = useState();

  useEffect(() => {
    calculateTimelineBlocksOnTargetDate();
    fetchTimelineBlocks();
  }, [targetDate]); // eslint-disable-line

  function calculateTimelineBlocksOnTargetDate() {
    setTimelineBlocks((timelineBlocks) => {
      if (!timelineBlocks) {
        setTimeout(calculateTimelineBlocksOnTargetDate, 500);
        return;
      }
      let targetBlocks = timelineBlocks.filter((block) => {
        let blockStartDate = moment(block.startDate);
        let blockDurationOnFirstDay = Math.min(
          block.durationHours - block.startHours,
          TIMELINE_DEFAULT_HOURS_IN_A_DAY - block.startHours
        );
        let blockDurationInDays = (block.durationHours - blockDurationOnFirstDay) / TIMELINE_DEFAULT_HOURS_IN_A_DAY;
        let blockEndDate = moment(blockStartDate).add(blockDurationInDays, "days");

        let isValidStartDate = blockStartDate.isSameOrBefore(targetDate, "day");
        let isValidEndDate = blockEndDate.isSameOrAfter(targetDate, "day");
        let isInRange = isValidStartDate && isValidEndDate;

        return isInRange;
      });
      setTimelineBlocksOnTargetDate(targetBlocks);
      return timelineBlocks;
    });
  }

  async function fetchTimelineBlocks() {
    const timelineBlocks = (
      await window.callGraphQLSimple({
        message: "Failed to fetch planned activity",
        query: "listTimelineBlocksByUserAndDate",
        variables: {
          userId: targetUserId,
          startDate: {
            between: [
              moment(targetDate).subtract(1, "week").toISOString(),
              moment(targetDate).add(1, "week").toISOString(),
            ],
          },
        },
      })
    ).data.listTimelineBlocksByUserAndDate.items;

    setTimelineBlocks(timelineBlocks);
  }

  return (
    <div className="planned-activity-canvas">
      {timelineBlocksOnTargetDate?.map((timelineBlock) => (
        <PlannedActivityBlock
          key={timelineBlock.id}
          timelineBlock={timelineBlock}
          targetDate={targetDate}
          tasks={tasks}
          projects={projects}
        />
      ))}
    </div>
  );
}
