import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { Modal, Button, Typography, Divider, Space, message, Row, Col } from "antd";
import { withRouter } from "react-router-dom";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";
import { getLabel } from "common/helpers";
import { isAuthorised } from "common/permissions";

import Card from "Card/Card";

import "./ClientActions.scss";

export function ClientActions(props) {
  const { client, history, projects } = props;

  async function confirmClientDelete() {
    const projectsInClient = projects.filter((x) => x.clientId === client.id);
    if (projectsInClient.length !== 0) {
      let modal = Modal.error({
        title: "Cannot delete client",
        className: "cannot-delete-client-modal",
        content: (
          <>
            <b>{client.name}</b> has the following projects associated to it, and thus cannot be deleted:
            <ul className="projects-which-use-client">
              {projectsInClient.map((project) => (
                <li
                  key={project.id}
                  onClick={() => {
                    modal.destroy();
                    history.push(`/projects/${project.id}`);
                  }}
                >
                  {project.title}
                </li>
              ))}
            </ul>
          </>
        ),
      });
      return;
    }

    Modal.confirm({
      title: "Confirm client delete",
      icon: <ExclamationCircleOutlined />,
      className: "delete-client-modal",
      content: (
        <>
          Are you sure you want to delete <b>{client.name}</b>?
        </>
      ),
      onOk: () => deleteSelectedClient(client),
    });
  }

  async function deleteSelectedClient(client) {
    try {
      await callGraphQLSimple({
        message: "Could not delete client",
        queryName: "deleteClient",
        variables: {
          input: {
            id: client.id,
          },
        },
      });

      history.push(`/clients`);
    } catch (e) {
      message.error(
        <Typography.Text>
          Client <b>{client.name}</b> could not be deleted: <b>{e.message}</b>
        </Typography.Text>
      );
    }
  }

  return (
    <Card className="client-actions">
      <Typography.Title level={4}>
        <Space>
          <Typography.Text>
            {getLabel({
              id: "Client",
              defaultValue: "Client",
            })}{" "}
            actions
          </Typography.Text>
        </Space>
      </Typography.Title>
      <Divider />

      <>
        <Row align="middle">
          <Col span={16}>
            <Typography.Paragraph className="main-label">
              Delete this{" "}
              {getLabel({
                id: "client",
                defaultValue: "client",
              })}
            </Typography.Paragraph>
            <Typography.Paragraph className="description">
              Once you delete a{" "}
              {getLabel({
                id: "client",
                defaultValue: "client",
              })}
              , there is no going back. Please be certain.
            </Typography.Paragraph>
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Button
              disabled={!isAuthorised(["CLIENT.DELETE"])}
              icon={<DeleteOutlined />}
              type="danger"
              onClick={confirmClientDelete}
              data-cy="delete-client-button"
            >
              Delete{" "}
              {getLabel({
                id: "client",
                defaultValue: "client",
              })}
            </Button>
          </Col>
        </Row>
      </>
    </Card>
  );
}

export default withRouter(
  withSubscriptions({
    Component: ClientActions,
    subscriptions: ["organisationDetails", "client", "tasks", "users", "projects"],
  })
);
