import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Table } from "antd";

import withSubscriptions from "common/withSubscriptions";
import { getFilteredTasks } from "common/filterHelpers";
import { COOKIE_NAME_DASHBOARD_EXCLUDED_COLUMNS } from "common/constants";
import { getGroupNamesForUser } from "common/permissions";

import { ColumnPickerDropdown } from "./ColumnPickerDropdown";
import { getTableColumns } from "DashboardPage/tableColumns";

import "./TableView.scss";

const ROWS_PER_PAGE = 50;

export class TableView extends React.Component {
  state = {
    isColumnDropdownVisible: false,
    excludedColumns: {},
    currentPageNumber: 1,
  };

  componentDidMount = async () => {
    const excludedTableColumns = await window.localDatabase.getItem(
      `${COOKIE_NAME_DASHBOARD_EXCLUDED_COLUMNS}-${this.props.organisationDetails.id}`
    );

    if (excludedTableColumns) {
      this.setState({ excludedColumns: JSON.parse(excludedTableColumns) });
    }
  };

  render() {
    const { organisationDetails, tasks, filter, onSelectTask, projects, clients, groups, apiUser, users } = this.props;
    const { excludedColumns, isColumnDropdownVisible, currentPageNumber } = this.state;

    const columns = [
      {
        title: "",
        key: "index",
        width: 60,
        render: (_, __, index) => {
          return (currentPageNumber - 1) * ROWS_PER_PAGE + index + 1;
        },
      },
      ...getTableColumns(users),
    ];

    const groupNamesForUser = getGroupNamesForUser(apiUser.id, groups);

    (organisationDetails.customFields || [])
      .filter((field) => {
        if (!field.groupsThatCanSee || field.groupsThatCanSee.length === 0) {
          return true;
        }

        return field.groupsThatCanSee.some((group) => groupNamesForUser.includes(group));
      })
      .forEach((fieldDefinition) => {
        columns.push({
          title: fieldDefinition.label,
          dataIndex: fieldDefinition.id,
          isCustomField: true,
          options: fieldDefinition.options,
          type: fieldDefinition.type,
        });
      });

    let customColumnWidths = {};
    columns.forEach((column) => {
      if (!column.width) {
        customColumnWidths[column.key] = String(column.title).length * 8.5;
      }
    });

    for (let column of columns) {
      column.key = column.dataIndex;
      if (!column.key && typeof column.title === "string") {
        column.key = column.title.replace(/\s/g, "");
        column.dataIndex = column.key;
      }
    }

    let quotesInHours = organisationDetails.settings?.quote?.quotesInHours;

    let tasksWithBudgetAndSpent = tasks.map((task) => {
      let spent;
      let budget;
      if (quotesInHours) {
        spent = task.hoursSpent;
        budget = task.hoursBudget;
      } else {
        spent = task.amountSpent;
        budget = task.amountBudget;
      }
      return {
        ...task,
        spent,
        budget,
      };
    });

    let filteredTasks = getFilteredTasks({
      projects,
      clients,
      tasks: tasksWithBudgetAndSpent,
      filter,
    }).sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));

    const dataSource = filteredTasks.map((task) => {
      const row = {};

      columns.forEach((column) => {
        let value;
        if (column.isCustomField) {
          let customTaskField = (task.customFields || []).find((field) => field.id === column.dataIndex);
          if (customTaskField) {
            let readableFieldValue = customTaskField.value;
            if (column.options) {
              readableFieldValue = column.options.find((option) => option.value === customTaskField.value)?.label;
            } else if (column.type === "CHECKBOX") {
              readableFieldValue = customTaskField.value ? "yes" : "no";
            } else if (column.type === "DATE") {
              readableFieldValue = moment(customTaskField.value).format("DD-MM-YYYY");
            }
            value = readableFieldValue;
          }
          const columnWidth = Math.max(String(value).length * 7, 100);

          if (!customColumnWidths[column.key] || columnWidth > customColumnWidths[column.key]) {
            customColumnWidths[column.key] = columnWidth;
          }
        } else if (column.fieldFunction) {
          value = column.fieldFunction({ task, organisationDetails });
        } else {
          value = task[column.dataIndex];
        }

        row[column.key] = value;
        row.key = task.id;
      });
      return { ...task, ...row, id: task.id };
    });

    columns.forEach((column) => {
      if (customColumnWidths[column.key] !== undefined) {
        column.width = customColumnWidths[column.key];
      }

      if (column.width === undefined) {
        column.width = column.title.length * 12 + "px";
      }
    });

    const visibleColumns = columns.filter((column) => !excludedColumns[column.key]);

    return (
      <>
        <ColumnPickerDropdown
          organisationDetails={organisationDetails}
          isColumnDropdownVisible={isColumnDropdownVisible}
          columns={columns}
          excludedColumns={excludedColumns}
          visibleColumns={visibleColumns}
          onToggle={() => this.setState({ isColumnDropdownVisible: !isColumnDropdownVisible })}
          onChange={(newExcludedColumns, callback) => {
            this.setState({ excludedColumns: newExcludedColumns }, callback);
          }}
        />
        <div className="table-view">
          <Table
            dataSource={dataSource}
            columns={visibleColumns}
            pagination={{ hideOnSinglePage: true, pageSize: ROWS_PER_PAGE }}
            tableLayout="fixed"
            rowKey={(task) => task.id}
            bordered={true}
            onChange={(pagination) => {
              this.setState({ currentPageNumber: pagination.current });
            }}
            onRow={(task) => {
              return {
                onClick: () => {
                  onSelectTask(task.id);
                },
              };
            }}
          />
        </div>
      </>
    );
  }
}

export default withSubscriptions({
  Component: withRouter(TableView),
  subscriptions: ["tasks", "apiUser", "users", "organisationDetails", "projects", "clients", "groups"],
});
